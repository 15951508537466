import { createSlice } from '@reduxjs/toolkit';

// Cargar datos iniciales desde localStorage
const savedState = JSON.parse(localStorage.getItem('ReasignarCargaNivelDosSlice')) || {
   usuario: {
      userid: "",
      nombrecobrador: "",
      numerocontratos: "",
      contratos: []
   }
};

const initialState = savedState;

export const ReasignarCargaNivelDosSlice = createSlice({
   name: 'ReasignarCargaNivelDosSlice',
   initialState,
   reducers: {
      setUsuario: (state, action) => {
         const newState = { ...state, usuario: action.payload };
         // Guardar en localStorage
         localStorage.setItem('ReasignarCargaNivelDosSlice', JSON.stringify(newState));
         return newState;
      },
      setLimpiarUsuario: (state) => {
         const newState = {
            ...state,
            usuario: {
               userid: "",
               nombrecobrador: "",
               numerocontratos: "",
               contratos: []
            }
         };
         // Guardar en localStorage
         localStorage.setItem('ReasignarCargaNivelDosSlice', JSON.stringify(newState));
         return newState;
      }
   },
});

export const { setUsuario, setLimpiarUsuario } = ReasignarCargaNivelDosSlice.actions;

export default ReasignarCargaNivelDosSlice.reducer;
