import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SelectField } from '../SelectField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../datatable/DataTable';
import PagoUnidad from '../../Services/PagoUnidad/PagoUnidad';

const RegistrosPagoUnidadModal = ({ modalMessage, setModalMessage, showErrors, refreshData, dataSearch }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const initial = {
        tipoFiltro: 1
    }

    const [currenPage, setCurrenPage] = useState(1);
    const [initialValue, setInitialValue] = useState(initial)
    const [total, setTotal] = useState(0);

    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columnsProcesar = [
        { field: 'FolioId', headerName: 'Folio'},
        { field: 'VIN', headerName: 'VIN'},
        { field: 'Contrato', headerName: 'Contrato'},
        { field: 'Monto_de_pago_str', headerName: 'Monto'},
        { field: 'usuario', headerName: 'Usuario'},
        { field: 'fecha', headerName: 'Fecha'},
    ];

    const columnsIgnorar = [
        { field: 'FolioId', headerName: 'Folio'},
        { field: 'VIN', headerName: 'VIN'},
        { field: 'Contrato', headerName: 'Contrato'}
    ];

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const [totalRegistros, setTotalRegistros] = useState(0);
    const [totalProcesar, setTotalProcesar] = useState(0);
    const [totalPendientes, setTotalPendientes] = useState(0);

    const [totalCalculado, setTotalCalculado] = useState(0);
    const [totalCobrado, setTotalCobrado] = useState(0);
    const [totalDiferencia, setTotalDiferencia] = useState(0);

    const [visible, setVisible] = useState(false);

    const validate = Yup.object().shape({});

    useEffect(() => {
        processDataBeforeSend()
    }, []);

    const processDataBeforeSend = () => { 
        const jsonData = modalMessage.data;
        let procesar = []
        let ignorar = []
        let items = {
            dataProcesar: {},
            dataIgnorar: {},
        }

        let monto = 0;
        let aCobrar = 0;
        jsonData.forEach(item => {
            aCobrar += parseFloat(item.Precio_unidad);
            if(item.Monto_de_pago && item.Monto_de_pago > 0){
                monto += parseFloat(item.Monto_de_pago);
                item.usuario = sessionStorage.getItem('usuario');
                item.fecha = dateFormat(new Date())
                item.Monto_de_pago_str = formatMoney(parseFloat(item.Monto_de_pago));
                item.comentarios = modalMessage.comentarios;
                procesar.push(item);
            }else{
                ignorar.push(item);
            }
        });

        setTotalRegistros(jsonData.length);
        setTotalProcesar(procesar.length);
        setTotalPendientes(jsonData.length - procesar.length);

        setTotalCalculado(aCobrar);
        setTotalCobrado(monto);
        setTotalDiferencia(aCobrar - monto);

        items.dataProcesar = procesar;
        items.dataIgnorar = ignorar;
        
        setData({
            ...data,
            data: items
        });
        setVisible(true);
    }

    const options = [{ value: 1, label: 'Por procesar' }, { value: 2, label: 'Pendientes' }];

    const handlerGuardar = async () => {

        let params = data.data;
        await PagoUnidad.pagoUnidadProcesoPagadoCrear(params)
        .then(resp => {
            setModalMessage({ isOpen: false });
            let errors = 0;
            resp.data.forEach(item => {
                if(item.error){
                    errors++;
                }
            });

            if(errors > 0){
                showErrors(resp.data);
                toast.error(message("Se detectaron algunos errores en el procesamiento de datos"));
            }else{
                refreshData(dataSearch)
                toast.success(message("Los datos se procesaron correctamente"));
            }
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar agregar un costo de reparación!"));
            console.error(err);
        });
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear();
        return formattedDate;
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const [pagina, setPagina] = useState(1);

    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        // getSeguimientoPagoUnidadExternosPendientePago(refFormik.current.values, page);
    };


    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.concepto = ""
        initialValue.subtotal = ""
        initialValue.iva = ""
        setTotal(0);
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const onlyNumbers = (field, value, fn, evt) => {
        const re = /^[0-9\b]+$/;
        if (value === ''  || re.test(value) === true) {
            fn(field, value);
        }else{
            let data = value.replace(/(?!-)[^0-9.]/g, "");
            fn(field, data);
        }
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    concepto: true,
                    subtotal: true
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación se muestran los registros con la opción para confirmar o cancelar la carga
                                        </small>
                                    </div>
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            <strong>Total de registros: {totalRegistros} | Registros por procesar: {totalProcesar} | Registrados pendientes: {totalPendientes}</strong>
                                        </small>
                                    </div>
                                    <div className="row mt-4">
                                        <small id="AT_TxtBasicModalMenssage">
                                            <strong>Total calculado: { formatMoney(totalCalculado) } | Total cobrado: { formatMoney(totalCobrado) } | Diferencia: { formatMoney(totalDiferencia) }</strong>
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-3 col-12">
                                            <SelectField id={'tipoFiltro'}
                                                name={'tipoFiltro'}
                                                items={options}                                                                                            
                                                onChange={(event) => {
                                                    setFieldValue('tipoFiltro', event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {
                                            (visible &&
                                            values.tipoFiltro === 1) &&
                                            <DataTable
                                                column={columnsProcesar}
                                                data={data?.data.dataProcesar}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                            />
                                        }
                                        {
                                            (visible &&
                                            values.tipoFiltro === 2) &&
                                            <DataTable
                                                column={columnsIgnorar}
                                                data={data?.data.dataIgnorar}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                            />
                                        }
                                    </div>
                                    {
                                            (visible &&
                                            values.tipoFiltro === 1) &&
                                            <div className="row mt-2 justify-content-end">
                                                <div className="col-lg-auto col-auto">
                                                    <strong>Total: { formatMoney(totalCobrado) }</strong>
                                                </div>
                                            </div>
                                        }
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar();
                                                resetForm();
                                            }}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default RegistrosPagoUnidadModal;
