import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import ModeloAPI from '../../Services/Catalogos/Modelo';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const TipoCosto = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        tipoCostoId: item?.tipoCostoId ?? "",
        nombre: item?.nombre ?? "",
        tiempo: item?.tiempo ?? false,
        costo: item?.costo ?? false,
        requerido: item?.requerido ?? false,
        status: item?.status ?? true,
        categoriaCostoId: item?.categoriaCostoId ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [Data, setData] = useState([])

    useEffect(() => {      
        handleGetAllcategoriaCosto();
    }, [])
    const handleGetAllcategoriaCosto = async () => {
        await Catalogo.getAllCSCategoriaCosto()
            .then(res => {                
                let NewData = [];
                console.log(res.data.data)
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.categoriaCostoId,
                        label: item.categoriaCosto
                    })
                });
                setData(NewData);
              
            }).catch(e => {                
            })
    }
    const validate = Yup.object({
        nombre: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres')
    });
    const [errorMessage, setErrorMessage] = useState("");
    const handleAddEdit = async (item) => {
        if (existingData.find(x=>x.nombre ?.toLowerCase() === item.nombre?.toLowerCase() &&
                                    x.categoriaCostoId === item.categoriaCostoId)) {
            setErrorMessage("El tipo de costo ya existe.");
            return;
        }
        refbtn.current.disabled = true;

        if (action === 'add') {
            await Catalogo.addTipoCosto(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateTipoCosto(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue}) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                        <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '40rem' }}>
                        <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                        <Form>
                            <div className="mb-3 row">
                                <div className='col-sm-6'>
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className='col-sm-2'>
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Tiempo</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <Field id="Tiempo" className="form-check-input" type="checkbox" name="tiempo" />
                                    </div>
                                </div>
                                <div className='col-sm-2'>
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Costo</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <Field id="Costo" className="form-check-input" type="checkbox" name="costo" />
                                    </div>
                                </div>
                                <div className='col-sm-2'>
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Requerido</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <Field id="Requerido" className="form-check-input" type="checkbox" name="requerido" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                    <TextField id="Nombre" label="Nombre" name="nombre" type="text" holder="Escribe" />
                                </div>                                
                                <div className="col-sm-6">
                                    <SelectField id="categoriaCostoId" label="Categoria costo" name="categoriaCostoId" items={Data} onChange={(event) => {
                                        setFieldValue("categoriaCostoId", event.value);
                                    }} />
                                </div>
                            </div>
                            <span className="text-danger">{errorMessage}</span>
                            <section className='my-3'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default TipoCosto