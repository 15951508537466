import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import handlePhonNumberFormatter, { message, pageRows } from '../../../Utils/functions'
import { useNavigate, useParams } from 'react-router-dom'
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Llamada from '../../../Services/Cartera/Llamadas';
import CardGarantia from './CardGarantia';
import { setGarantiaSlice } from '../../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { setInfoLlamada } from '../../../Redux/Slice/IncidenciasSlice/llamadaSlice';

const InformacionLlamada = (props) => {
    const { datageneralId, inInspeccionRapida } = props
    const { FolioLlamada, Nombre, Telefono, Correo, MotivoLlamadaId, ComentariosCliente, ComentariosGarantia } = useSelector((state) => state.LlamadaSlice);
    const { generalId, fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, procedenciaAuto, nombreVersion, numMotor, numContrato, producto, cliente, usoUnidad, fechaUltimoPago } = useSelector((state) => state.DataVinSlice);
    const { KilometrajeActual, CarnetServicioId, FolioCarnet, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, TallerAutorizado, GeneralId } = useSelector((state) => state.GarantiaSlice);
    const [loading, setLoading] = useState(false)
    const [continuarGarantia, setContinuarGarantia] = useState(false);
    const [guardarLlamada, setGuardarLlamada] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let initial = {
        generalid: datageneralId,
        Nombre: Nombre,
        Telefono: Telefono,
        Correo: Correo,
        MotivoLlamadaId: MotivoLlamadaId,
        ComentariosCliente: ComentariosCliente,
        ComentariosGarantia: ComentariosGarantia,
        FolioLlamada: FolioLlamada
    }

    const [collpsable, setCollpsable] = useState(false);
    const [dataMotivos, setDataMotivos] = useState([]);
    let { idllamada } = useParams();
    const [initialValue, setInitialValue] = useState(initial);
    const { refFormik } = useRef();

    useEffect(() => {
        handleGetMotivos(); //Catalogo de motivos necesarios    
    }, [datageneralId])

    useEffect(() => {
        setInitialValue(initial); //Catalogo de motivos necesarios    
    }, [Nombre])

    useEffect(() => {
        if (GarantiaId === "") {
            setContinuarGarantia(false)
        } else {
            setContinuarGarantia(true)
        }
    }, [GarantiaId])

    const validate = Yup.object({
        Nombre: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        Telefono: Yup.string()
            .required("El campo es requerido.")
            .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
        Correo: Yup.string()
            .email("El correo electrónico no es válido.")
            .required("El campo es requerido."),
        ComentariosCliente: Yup.string()
            .required("El campo es requerido."),
        MotivoLlamadaId: Yup.number()
            .required("El campo es requerido."),
        ComentariosGarantia: Yup.string()
            .when("MotivoLlamadaId", {
                is: 2,
                then: Yup.string().required("El campo es requerido")
            })
    });

    const handleChangeMotivo = (value, valueState) => {
        setInitialValue({ ...valueState, MotivoLlamadaId: Number(value) });
    }

    const handleGetMotivos = async () => {
        await Catalogo.getMotivoLlamada()
            .then(res => {
                let listaMotivos = [];
                res.data.data?.map(item => {
                    listaMotivos.push({
                        value: item.motivoLlamadaId,
                        label: item.motivo
                    });
                });
                setDataMotivos(listaMotivos);
            })
            .catch(err => {
                console.log()
            });
    }
    const handleAddEdit = async (value) => {
        if (guardarLlamada === false) {
            setGuardarLlamada(true)
            setLoading(true);
            let data = {
                generalid: initial.generalid,
                Nombre: value.Nombre,
                Telefono: value.Telefono,
                Correo: value.Correo,
                MotivoLlamadaId: value.MotivoLlamadaId,
                ComentariosCliente: value.ComentariosCliente,
                ComentariosGarantia: value.ComentariosGarantia,
            }
            if (idllamada == 0) {
                await Llamada.addLlamada(data)
                    .then(res => {
                        toast.success(message("¡Correcto!", res.data.data.mensaje));
                        dispatch(setGarantiaSlice({
                            LlamadaId: res.data.data.llamadaId
                        }))

                        navigate("/garantias/incidenciasinfo/" + vin + "/" + res.data.data.llamadaId);
                        window.location.reload()
                    })
                    .catch(e => {
                        if (e.response !== undefined) {
                            toast.error(message("¡Error.!", e.response.data.message));
                        } else {
                            toast.error(message("¡Error.!", e.message));
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            } else {
                setContinuarGarantia(true)
                setLoading(false);
                setGuardarLlamada(false)
            }
        }
    }

    return (
        <>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <Formik
                    innerRef={refFormik}
                    enableReinitialize={true}
                    initialValues={initialValue}
                    validationSchema={validate}
                >
                    {({ isValid, values, setFieldValue, errors }) => (
                        <>
                            <div className='row mx-md-4 my-4'>
                                <div className="col-md-12 px-md-3">
                                    <div className="p-4 white-wrapper mb-2">
                                        <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseLlamada" role="button" aria-expanded="false" aria-controls="collapseLlamada" onClick={() => setCollpsable(!collpsable)}>
                                            <div className="col-11 col-md-11">
                                                <h6><span className={FolioLlamada ? 'semaforo semaforo-green' : 'semaforo semaforo-default'}></span> <strong>Información de llamada {FolioLlamada ? '- ' + FolioLlamada : ""}</strong></h6>
                                            </div>
                                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                <i className={!collpsable ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                            </div>
                                        </div>
                                        <div className="collapse" id="collapseLlamada">
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    
                                                    <small>
                                                        <strong>
                                                            Datos del contacto
                                                        </strong>
                                                    </small>
                                                </div>

                                                {
                                                    idllamada === "0" &&
                                                    <div className="col-12 col-md-6 col-lg-6 text-end">
                                                        <button className="btn col-4 saveInfo" onClick={(e) => handleAddEdit(values)} disabled={!(isValid || guardarLlamada) || values.MotivoLlamadaId === 0}>Guardar</button>
                                                    </div>
                                                }

                                                {
                                                    idllamada !== "0" && initialValue.MotivoLlamadaId === 2 && continuarGarantia === false && GarantiaId === "" &&
                                                    <div className="col-12 col-md-6 col-lg-6 text-end">
                                                        <button className="btn col-4 saveInfo" onClick={(e) => handleAddEdit(values)} disabled={!(isValid)}>Continuar</button>
                                                    </div>
                                                }
                                            </div>
                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="Nombre" label="Nombre" name="Nombre" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("Nombre", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="Telefono" label="Teléfono" name="Telefono" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("Telefono", handlePhonNumberFormatter(event.target.value));
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="Correo" label="Correo electrónico" name="Correo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("Correo", event.target.value);
                                                    }} />
                                                </div>
                                            </div>


                                            <div className="row mt-4 px-4">
                                                <div className="col-12">
                                                    <strong>Capturar motivo de llamada</strong>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="MotivoLlamadaId" label="Motivo de llamada" name="MotivoLlamadaId" items={dataMotivos} onChange={(event) => {
                                                        setFieldValue("MotivoLlamadaId", event.value);
                                                        handleChangeMotivo(event.value, values);
                                                    }} />
                                                </div>

                                            </div>

                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                                                    <TextAreaField id="ComentariosCliente" label="Comentarios Cliente" className="form-control col-12" rows="3" name="ComentariosCliente" type="text" holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("ComentariosCliente", event.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            {
                                                initialValue.MotivoLlamadaId === 2 &&
                                                <>
                                                    <div className="row mt-2 mb-2 px-4">
                                                        <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                                                            <TextAreaField label="Comentarios garantia" className="form-control col-12" rows="3" name="ComentariosGarantia" type="text" holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("ComentariosGarantia", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    }
                </Formik>
            </div>

            {idllamada !== "0" && initialValue.MotivoLlamadaId === 2 && (
                <>
                    <CardGarantia inInspeccionRapida={inInspeccionRapida} motivollamada={{ motivollamada: initialValue.MotivoLlamadaId, idllamadaparam: idllamada }} />
                </>
            )}
        </>
    )
}

export default InformacionLlamada 