import React, { useState, useRef } from 'react'
import Modal from './Modal/Modal';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import * as XLSX from "xlsx";
import { TextAreaField } from './TextAreaField';

const UploadFile = ({ isOpenModal, setIsOpenModal, handleSubmit }) => {
    const [IsFilePicked, setIsFilePicked] = useState();
    const [SelectedFile, setSelectedFile] = useState("");
    const [JsonData, setJsonData] = useState("");
    const fileRef = useRef();
    let initial = {
        comentarios: "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const validate = Yup.object({
        comentarios: Yup.string()
            .required("El campo es requerido.")
    });

    const changeHandler = () => {
        fileRef.current.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        );
    };

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        handleConvert(event.target.files[0])
    }
    const handleConvert = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(json);
                // setJsonData(JSON.stringify(json, null, 2));                      
            };
            reader.readAsBinaryString(file);
        }
    };
    const convertData = (values) => {
        handleSubmit({ ...values, jsonData: JsonData });
        setIsOpenModal(false)
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                convertData({ ...values, file: SelectedFile });                            
            }}
        >
            {() => (
                <Modal isOpen={isOpenModal} color='#fff'>
                    <Form>
                        <div className='container-buttons'>
                            <h4><strong>Carga de archivo</strong></h4>
                            <span>
                                Selecciona el archivo que desees subir
                            </span>
                            <div className="mt-5">
                                <span><strong>1.Subir Archivo</strong></span>
                                {/* <div className="my-3">
                                    <input className="form-control d-none" type="file" id="formFile" ref={fileRef} onChange={handleChangeFile} />
                                    <section className='section-file d-flex flex-column' onClick={changeHandler}>
                                        <i className='icon-up ri-upload-cloud-2-line'></i>
                                        <span>{SelectedFile?.name}</span>
                                    </section>
                                </div> */}
                                <div className="my-3 row">
                                    <input className="form-control d-none" type="file" id="formFile" ref={fileRef} onChange={handleChangeFile} />
                                    <section className='d-flex align-items-end'>
                                        <fieldset className='col-6 btn' onClick={changeHandler}>Seleccionar archivo</fieldset>                                        
                                        <input type="text" className='form-control' disabled value={SelectedFile?.name  ?? "Sin archivos seleccionados"}/>
                                    </section>
                                </div>
                            </div>
                            <div className="mt-4 row">
                                <span><strong>2. Comentarios</strong></span>
                                <div className="col-sm-12 mb-2">
                                    <TextAreaField
                                        id="comentarios"
                                        label="Comentarios"
                                        className="form-control"
                                        rows="3"
                                        name="comentarios"
                                        type="text"
                                        holder="Escribe"
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end mt-5'>
                                <button className='btn btn-outline col-5 mx-2' type='reset' onClick={() => { setIsFilePicked(false); setIsOpenModal(false); setSelectedFile(null) }}>Cancelar</button>
                                <button className="btn col-5" type="submit" disabled={!IsFilePicked}>Continuar</button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            )
            }
        </Formik>
    )
}

export default UploadFile
