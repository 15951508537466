import axios from '../../Utils/ApiService';

const VehiculoConvertidoService = {
    getAllVehiculosConvertidos:(page,rows,tipoCompraId,socioId,string,fechaInicio,fechafin)=> {        
        return axios.get(`/functions/GetAllVehiculosConvertidos?page=${page}&Rows=${rows}&SocioComercialId=${socioId}&TipoCompraId=${tipoCompraId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`);        
    },     
    SaveChangeInventario:(GeneralId)=>{
        return axios.put(`/functions/SaveVehiculosConvertidos?GeneralId=${GeneralId}`,);
    },
    downloadFile:(tipoCompraId,socioId,string,fechaInicio,fechafin)=>{
        return axios.get(`/functions/GetExportVehiculosConvertidos?SocioComercialId=${socioId}&TipoCompraId=${tipoCompraId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`,{responseType: 'blob'});
    },
    //Historial de contratos - inventario
    getAllHistorialContratos:(page,rows,tipoCompraId,UsoUnidadId,EtapaId,string,fechaInicio,fechafin)=> {        
        return axios.get(`/functions/GetHistContratosCancelados?page=${page}&Rows=${rows}&UsoUnidadId=${UsoUnidadId}&TipoCompraId=${tipoCompraId}&EtapaId=${EtapaId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`);        
    }, 
    DownloadHistorialContratos:(tipoCompraId,UsoUnidadId,EtapaId,string,fechaInicio,fechafin)=> {        
        return axios.get(`/functions/GetExportHistContratosCancelados?UsoUnidadId=${UsoUnidadId}&TipoCompraId=${tipoCompraId}&EtapaId=${EtapaId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`,{responseType: 'blob'});        
    }, 
    //
    //Listado de vehiculos en credito.
    getAllVehiculosEnCredito:(page,rows,tipoCompraId,TipoExternoId,UbicacionId,string,fechaInicio,fechafin)=> {        
        return axios.get(`/functions/GetAllVehiculosEnCredito?page=${page}&Rows=${rows}&TipoExternoId=${TipoExternoId}&TipoCompraId=${tipoCompraId}&UbicacionId=${UbicacionId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`);        
    }, 
    DownloadVehiculosEnCredito:(tipoCompraId,TipoExternoId,UbicacionId,string,fechaInicio,fechafin)=> {        
        return axios.get(`/functions/GetExportVehiculosEnCredito?TipoExternoId=${TipoExternoId}&TipoCompraId=${tipoCompraId}&UbicacionId=${UbicacionId}&FechaInicio=${fechaInicio}&FechaFin=${fechafin}&Buscar=${string}`,{responseType: 'blob'});        
    }, 
    
}
export default VehiculoConvertidoService;