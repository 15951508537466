import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { message, FormatNumberMoney } from '../../../Utils/functions';
import AuthLogin from '../../../Utils/AuthLogin';
import ResponsivaService from '../../../Services/Seguros/ResponsivaService';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../../../Components/TextAreaField';
import SubMenuPrealta from '../../Ventas/Prealta/subMenuPrealta';
import PlacasService from '../../../Services/Inventario/PlacasService';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import DataTable from '../../../Components/datatable/DataTable';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';


const auth = new AuthLogin();
const PlacasAddEdit = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const refbtn = useRef();
    const refbtnDownload = useRef();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);
    const refFormik = useRef();
    const columns = [
        { field: 'usuario', headerName: 'Usuario', width: 250 },
        { field: 'fecha', headerName: 'Fecha y hora', width: 250 },
        { field: 'operacion_Placas', headerName: 'Tipo operación', width: 130 },
        { field: 'numPlaca', headerName: 'No. Placas', width: 130 },
        { field: 'comentarios', headerName: 'Comentario', width: 160 },
        { field: 'motivo', headerName: 'Motivo', width: 130 },
        { field: 'aplicaCosto', headerName: 'Aplica costo', width: 130 },
        { field: 'aplicaCostoCliente', headerName: 'Costo cliente', width: 130 },
        { field: 'costoStr', headerName: 'Costo placas', width: 130 }

    ];
    let initial = {
        VIN: state?.vin,
        marca: state?.nombreMarca,
        modelo: state?.nombreModelo,
        year: state?.nombreYear,
        version: state?.nombreVersion,
        condicion: state?.condicion || state?.tipoCondicion,
        transmision: state?.transmision ?? "",

        generalId: id,
        operacion_PlacasId: "",
        numPlaca: "",
        aplicaCosto: false,
        aplicaCostoCliente: false,
        costo: "",
        comentarios: "",
        motivoBajaPlacasId: "",
        esBajaPlacas: false,
        esCapturaPlacas: false,
        vehiculoEnCredito: state?.vehiculoEnCredito
    }
    const dataAplicaCosto = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const dataAplicaCostoCliente = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        operacion_PlacasId: Yup.string()
            .required("El campo es requerido."),
        numPlaca: Yup.string()
            .when("esCapturaPlacas", (esCapturaPlacas) => {
                if (esCapturaPlacas) {
                    return Yup.string()
                        .required("El campo es requerido.");
                }
            }),
        aplicaCosto: Yup.string()
            .when("esCapturaPlacas", (esCapturaPlacas) => {
                if (esCapturaPlacas) {
                    return Yup.bool()
                        .required("El campo es requerido.");
                }
            }),
        aplicaCostoCliente: Yup.string()
            .when("aplicaCosto", (aplicaCosto) => {
                if (aplicaCosto) {
                    return Yup.bool()
                        .required("El campo es requerido.");
                }
            }),
        comentarios: Yup.string()
            .required("El campo es requerido."),
        motivoBajaPlacasId: Yup.string()
            .when("esCapturaPlacas", (esCapturaPlacas) => {
                if (!esCapturaPlacas) {
                    return Yup.string()
                        .required("El campo es requerido.");
                }
            }),
        costo: Yup.string()
            .when("aplicaCosto", (aplicaCosto) => {
                console.log(aplicaCosto)
                if (aplicaCosto === true) {
                    return Yup.string()
                        .required("El campo es requerido.");
                }
            }),
    });

    const [loading, setLoading] = useState(false);

    const [TipoResponsiva, setTipoResponsiva] = useState([]);
    const [DataMotivoBaja, setDataMotivoBaja] = useState([]);
    const [DataEstado, setDataEstado] = useState([]);
    const [DataMunicipio, setDataMunicipio] = useState([]);
    const [DataColonia, setDataColonia] = useState([]);
    const [DataBanco, setDataBanco] = useState([]);
    const [DataEmpresa, setDataEmpresa] = useState([]);
    const [DataRLegal, setDataRLegal] = useState([]);
    const [DataTribunal, setDataTribunal] = useState([]);
    const [progressBar, setProgressBar] = useState(0);
    const [dataStatus, setDataStatus] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [currenPage, setCurrenPage] = useState(1);

    //
    const [OperacionPlacas, setOperacionPlacas] = useState([]);
    const [DataHistorial, setDataHistorial] = useState([]);
    const [PageCount, setPageCount] = useState();
    const [tiemePlacaVehiculo, setTiemePlacaVehiculo] = useState(false);
    useEffect(() => {
        handleGetOperacionPlacas();
        handleGetAllMotivoBaja();
        handleGetVhiculoPlaca();
        handleGetByIdStatus();
        // handleGetAllStatusDatamovil()

    }, [])

    const handleGetByIdStatus = async () => {
        setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let data = res.data.data;
                let initial = {
                    // VIN: item?.vin,
                    // marca: item?.nombreMarca,
                    // modelo: item?.nombreModelo,
                    // year: item?.nombreYear,
                    // version: item?.nombreVersion,
                    // condicion: item?.tipoCondicion,
                    // transmision: item?.transmision ?? "",

                    // generalId: id,
                    // statusDatamovilId: data?.statusDatamovilId ?? "",
                    // serieDatamovil: data?.serieDatamovil ?? "",
                    statusDatamovil: data?.statusDatamovil ?? ""
                }
                setInitialValue({ ...initialValue, statusDatamovil: data?.statusDatamovil ?? "" });
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetById = async () => {
        setLoading(true);
        await ResponsivaService.getResponsivaById(id)
            .then(res => {
                let item = res.data.data;

                let initial = {
                    VIN: item?.vin,
                    marca: item?.nombreMarca,
                    modelo: item?.nombreModelo,
                    year: item?.nombreYear,
                    version: item?.nombreVersion,
                    condicion: item?.tipoCondicion,
                    transmision: item?.transmision ?? "",

                    generalId: id,
                    responsiva: item?.responsiva ?? false,
                    responsivaId: item?.responsivaId ?? "",
                    tipoResponsivaId: item?.tipoResponsivaId ?? "",
                    fechaRegistro: item?.fechaRegistroSF.split("T")[0] ?? "",
                    noCaptura: item?.noCaptura ?? "",
                    fechaFactura: item?.fechaFactura.split("T")[0] ?? "",
                    empresaFactura: item?.empresaFactura ?? "",
                    segundaClausula: item?.segundaClausula ?? "",
                    empresaId: item?.empresaId,
                    representanteLegalId: item?.representanteLegalId ?? "",
                    bancoId: item?.bancoId ?? "",
                    tipoPagoId: item?.tipoPagoId ?? "",
                    referenciaPago: item?.referenciaPago ?? "",
                    tribunalId: item?.tribunalId ?? "",
                    nombreVendedor: item?.nombreVendedor ?? "",
                    representanteLegalVendedor: item?.representanteLegalVendedor ?? "",
                    valorVehiculo: item?.valorVehiculo === '' ? '' : FormatNumberMoney(item.valorVehiculo),
                    poseedorVehiculo: item?.poseedorVehiculo ?? "",
                    status: true,
                    idEstado: item?.idEstado ?? "",
                    idCiudad: item?.idCiudad ?? "",
                    idColonia: item?.idColonia ?? "",
                    codigoPostal: item?.codigoPostal ?? "",
                    calle: item?.calle ?? "",
                    numeroInterior: item?.numeroInterior ?? "",
                    numeroExterior: item?.numeroExterior ?? "",
                }
                setInitialValue(initial);
                handleGetRepresentanteLegal(item.empresaId);
                handleGetAllMunicipioById(item.idEstado);
                handleGetAllColoniaById(item.idCiudad);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VehiculoService.getAllHistoryPlacas(id, page, 10)
            .then(res => {
                setDataHistorial(res.data.data);

                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetOperacionPlacas = async () => {
        setLoading(true);
        await PlacasService.getAllOperacionPlacas()
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    NewData.push({
                        value: item.operacion_PlacasId,
                        label: item.descripcion
                    })
                });

                setTipoResponsiva(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllMotivoBaja = async () => {
        await Catalogo.getAllMotivoBajaPlacas()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoBajaPlacasId,
                        label: item.motivo
                    })
                });
                setDataMotivoBaja(NewData);
            }).catch(e => {

            })
    }
    // const handleGetAllStatusDatamovil = async () => {
    //     await VehiculoService.getAllStatusDatamovil()
    //         .then(res => {
    //             let NewData = [];
    //             console.log("status",res.data.data)
    //             res.data.data?.map(item => {
    //                 if (item.status) {
    //                     NewData.push({
    //                         value: item.statusDatamovilId,
    //                         label: item.statusDatamovil
    //                     })
    //                 }

    //             });
    //             setDataStatus(NewData);
    //         }).catch(e => {
    //         })
    // }
    const handleGetVhiculoPlaca = async () => {
        await Catalogo.getVehiculoPlacas(id)
            .then(res => {

                setTiemePlacaVehiculo(res.data.data?.docPlaca)
            }).catch(e => {

            })
    }

    const status = (dataStatus) => {
        console.log(dataStatus)
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    const handleGetAllMunicipioById = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.municipioId,
                            label: item.municipio
                        })
                    }
                });
                setDataMunicipio(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllColoniaById = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.coloniaId,
                            label: item.colonia
                        })
                    }
                });
                setDataColonia(NewData);
            }).catch(e => {

            })
    }

    const handleGetRepresentanteLegal = async (id) => {
        setLoading(true);
        await ResponsivaService.getRepresentanteLegal(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.representanteLegalId,
                        label: item.representanteLegal
                    })
                });
                setDataRLegal(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const downloadFileResponsiva = async () => {
        setLoading(true);
        refbtnDownload.current.disabled = true;
        await ResponsivaService.downloadFileResponsiva(id)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `Responsiva.pdf`);
                setLoading(false);
                refbtnDownload.current.disabled = false;
            }).catch(e => {
                refbtnDownload.current.disabled = false;
                setLoading(false);
            })
    }
    const handleAddEdit = async (value) => {
        if (!tiemePlacaVehiculo) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "El vehiculo no cuenta con documento de placas.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 2000);
            return;
        }
        refbtn.current.disabled = true;
        setLoading(true);
        await ResponsivaService.saveDatosPlaca(value)
            .then(res => {
                setLoading(false);
                toast.success(message("¡Correcto.!", res.data.mensajeAcendes));
                refbtn.current.disabled = false;
                setTimeout(() => {
                    if (state?.ventaContado)
                        navigate('/inventario/venta/ventacontado');
                    else
                        navigate("/inventario/vehiculo");
                }, 2000);
            }).catch(e => {

                setLoading(false);
                refbtn.current.disabled = false;
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 6;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);
    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else if (state?.vehGestoria){
                                navigate('/inventario/gestoria')
                            }
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehiculo</strong></span>
                                            <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status(values.statusDatamovil)}{values.statusDatamovil === "" ? "Sin dispositivo" : values.statusDatamovil}</fieldset>
                                        </div>
                                        <div className='d-flex'>
                                            {
                                                !values.vehiculoEnCredito &&
                                                <>
                                                    <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                                        if (state.numIdFolder !== null) {
                                                            navigate(`/inventario/vehiculo/datadocs/${id}`, {
                                                                state: { ...state, placas: true },
                                                            });
                                                        } else
                                                            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);

                                                    }}><i className="icon ri-file-3-fill mx-1" ></i>Subir documentos</fieldset>
                                                    <button ref={refbtn} className='btn mx-2' type="submit" onClick={() => { console.log(errors) }} >{values.responsiva ? "Editar" : "Guardar"}</button>
                                                </>
                                            }
                                            {
                                                values.vehiculoEnCredito &&
                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                            }

                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2 mt-5'>
                                        <div className='d-flex'>
                                            <p><strong>Gestion de placas</strong></p>
                                            <div className="col-sm-1 mx-2">
                                                <fieldset disabled={values.vehiculoEnCredito} className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                            </div>

                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} label="Movimiento de placas" name="operacion_PlacasId" items={TipoResponsiva} onChange={(event) => {
                                                    setFieldValue("operacion_PlacasId", event.value);
                                                    {

                                                    }
                                                    event.label === 'Captura de Placas' ? setInitialValue({ ...values, esBajaPlacas: false, operacion_PlacasId: event.value, esCapturaPlacas: true }) : setInitialValue({ ...values, esBajaPlacas: true, operacion_PlacasId: event.value, esCapturaPlacas: false })
                                                    handleChageProgress(errors)
                                                }} />
                                            </div>
                                            {
                                                !values.esBajaPlacas &&
                                                <>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Numero de placas" name="numPlaca" type="text  " holder="Escribe" onChange={(event) => {
                                                            setFieldValue("numPlaca", event.target.value);
                                                            handleChageProgress(errors)
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={values.vehiculoEnCredito} label="Aplica costo" name="aplicaCosto" items={dataAplicaCosto} onChange={(event) => {
                                                            setFieldValue("aplicaCosto", event.value);
                                                            setInitialValue({ ...values, aplicaCosto: event.value })
                                                            handleChageProgress(errors)
                                                        }} />
                                                    </div>
                                                    {
                                                        values.aplicaCosto &&
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <SelectField disabled={values.vehiculoEnCredito} label="Aplica costo cliente" name="aplicaCostoCliente" items={dataAplicaCostoCliente} onChange={(event) => {
                                                                setFieldValue("aplicaCostoCliente", event.value);
                                                                setInitialValue({ ...values, aplicaCostoCliente: event.value });
                                                                handleChageProgress(errors)
                                                            }} />
                                                        </div>
                                                    }

                                                </>
                                            }
                                            {
                                                values.esBajaPlacas &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField disabled={values.vehiculoEnCredito} label="Motivo baja" name="motivoBajaPlacasId" items={DataMotivoBaja} onChange={(event) => {
                                                        setFieldValue("motivoBajaPlacasId", event.value);
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>
                                            }

                                        </div>
                                        {
                                            (!values.esBajaPlacas && values.aplicaCosto) &&
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled={values.vehiculoEnCredito} icon={"bi bi-currency-dollar"} label="Costo placas" name="costo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("costo", FormatNumberMoney(event.target.value));
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>

                                            </div>
                                        }

                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <TextAreaField disabled={values.vehiculoEnCredito} label="Comentarios" className="form-control col-12" rows="4" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                    handleChageProgress(errors)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    </div>
                    {
                        isOpenModal &&
                        <Modal isOpen={isOpenModal} color='#fff'>
                            <div className='d-flex'>
                                <span><strong>Historial de movimiento de placas</strong></span>

                            </div>
                            <DataTable
                                column={columns}
                                data={DataHistorial}
                                pageCount={PageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                            </section>
                        </Modal>
                    }
                </>
            )
            }
        </Formik >
    )
}

export default PlacasAddEdit