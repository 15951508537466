import React from 'react'
import DataTable from '../../Components/datatable/DataTable'
import Select from 'react-select';
import useUsuario from '../../Hooks/Usuarios/useUsuario';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import { pageRows } from '../../Utils/functions';
import RowPages from '../../Components/RowPages';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import useRoles from '../../Hooks/Roles/useRoles';
import NuevoRolModal from './NuevoRolModal';
import toast, { Toaster } from 'react-hot-toast'

const Roles = () => {
    let { columns,
        data,
        pageCount,
        dataRol,
        statusData,
        setIdRol,
        setStatus,
        setStr,
        loading,
        setModalMessage,
        modalMessage,
        handlePageClick,
        handleDetails,
        handleNewRol,
        page,
        setpageRows,
        setOpenModalRol,
        openModalRol } = useRoles();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-4 py-4'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Configuracíon de roles</strong></span>
                    <div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguracionDeRoles, SubModule.Roles).Agregar} >
                            <button className='btn px-4' onClick={() => { setOpenModalRol(true) }}>Nuevo rol</button>
                        </ValidatePermission>
                    </div>
                </div>
            </section>
            <section className='mt-3 mx-4'>
                <div className='col-lg-4 col-sm-12 d-flex'>
                    <div className="col-6 mx-1  inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                    </div>
                </div>
            </section>
            <ValidatePermission
                isActive={ValidatePermissionById(4, Section.ConfiguracionDeRoles, SubModule.Roles).Ver}
            >
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        detailable={ValidatePermissionById(4, Section.ConfiguracionDeRoles, SubModule.Roles).Actualizar}
                        handleDetail={handleDetails}
                        paginate={true}
                        handlePageClick={handlePageClick}
                        currentPage={page - 1}
                    />
                </div>
            </ValidatePermission>
            {
                openModalRol &&
                <NuevoRolModal
                    isOpen={openModalRol}
                    setIsOpen={setOpenModalRol}                    
                    toast={toast}      
                    action={"add"}              
                />
            }
        </>
    )
}

export default Roles