import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import { SelectField } from '../SelectField';
import Cierres from '../../Services/Resguardos/Cierres';

const AdeudosModal = ({ modalMessage, setModalMessage, cierreContratoAdeudoId, getAdeudos }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        cierreContratoAdeudoTipoId: "",
        monto: "",
        cierreContratoAdeudoId: cierreContratoAdeudoId
    };


    const [tipoAdeudos, setTipoAdeudos] = useState([]);
    const [initialValue, setInitialValue] = useState(initial);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

    useEffect(() => {
        getTipoAdeudos();
    }, []);

    // const validate = Yup.object().shape({});
    
    const validate = Yup.object({
        cierreContratoAdeudoTipoId: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        monto: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
    });

    const getTipoAdeudos = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoAdeudoTipo')
        .then(resp => {
            resp.data.forEach(item => {
                items.push({
                    value: item.CierreContratoAdeudoTipoId,
                    label: item.CierreContratoAdeudoTipo
                });
            });
            setTipoAdeudos(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const submitForm = async (values) => {
        if(values.cierreContratoAdeudoTipoId === "" || values.cierreContratoAdeudoTipoId === null) {
            toast.error(message("¡Error, El campo Adeudo es requerido!"));
            return false;
        }
        if(values.monto === "" || values.monto === null) {
            toast.error(message("¡Error, El campo Monto de adeudo es requerido!"));
            return false;
        }
        setLoading(true);
        await Cierres.cierreContratoAdeudoDetalleCrear(values)
        .then(resp => {
            toast.success(message("Se creó el Adeudo correctamente"));
            refFormik.current.resetForm();
            setModalMessage({ isOpen: false });
            getAdeudos();
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crear el Adeudo!"));
            console.error(err);
        })
        .finally(() => setLoading(false));
    };

    
    const onlyNumbers = (field, value, fn) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            fn(field, value);
        }
    }
    

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación indica al cliente los requisitos para cumplir el proceso.
                                    </small>
                                </div>
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-6">
                                        <SelectField id="cierreContratoAdeudoTipoId"
                                            label="Adeudo"
                                            disabled={false}
                                            name="cierreContratoAdeudoTipoId"
                                            items={tipoAdeudos}
                                            onChange={(event) => {
                                                setFieldValue("cierreContratoAdeudoTipoId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-6">
                                        <TextField id="monto" disabled={false} label="Monto de adeudo ($)" name="monto" type="text" holder="Escribe" onChange={(event) => {
                                            onlyNumbers("monto", event.target.value, setFieldValue);
                                        }} />
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-end">
                                    <div className="col-auto">
                                        <button className='btn btn-gray' onClick={(e) => {
                                            setModalMessage({ isOpen: false });
                                            resetForm();
                                        }}>Cancelar</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className='btn'
                                            onClick={(e) => {
                                                submitForm(values);
                                            }}
                                        >Agregar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default AdeudosModal;
