import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    url: "",
    contrato: "",
    folioLlamada: "",
    vin: "",
    modelo: "",
    ano: "",
    marca: "",
    day: "",
    hour: "",
    sucursalId: "",
    sucursalName: "",
    tipoCalendario: 0,
}

export const CalendarSlice = createSlice({
    name: 'CalendarSlice',
    initialState,
    reducers: {
        setSchedule: (state, action) => {
            return {...state,
                url: action.payload.url,
                contrato: action.payload.contrato,
                folioLlamada: action.payload.folioLlamada,
                vin: action.payload.vin,
                modelo: action.payload.modelo,
                ano: action.payload.ano,
                marca: action.payload.marca,
            }
        },
        setHour: (state, action) => {
            return {
                ...state,
                day: action.payload.day,
                hour: action.payload.hour,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        setTipoCalendario: (state, action) => {
            return {
                ...state,
                tipoCalendario: Number(action.payload),
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                day: "",
                hour: "",
            }
        }
    }
})

export const { setSchedule,  setHour, setSucursal, clearHour, setTipoCalendario } = CalendarSlice.actions

export default CalendarSlice.reducer