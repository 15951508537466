import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';

const useSeguroPendiente = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'solicitud', headerName: 'No. Solicitud', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso unidad', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        // { field: 'fechaUltimaCotizacion', headerName: 'Fecha ultima cotización', width: 250 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [tipoAd, setTipoAd] = useState("");
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [DataMarca, setDataMarca] = useState([]);
    const [DataModelo, setDataModelo] = useState([]);
    const [DataYear, setDataYear] = useState([]);
    const [DataVersion, setDataVersion] = useState([]);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [filtroDocs, setfiltroDocs] = useState("");
    //Filtro
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveAÑo, setClaveAÑo] = useState("");
    const [claveVersion, setClaveVersion] = useState("");
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [IdsEliminar, setIdsEliminar] = useState([]);

    const [defaultValueModelo, setDefaultValueModelo] = useState({label:"Modelo",value:""});
    const [defaultValueYear, setDefaultValueYear] = useState({label:"Año",value:""});

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, claveMarca, claveModelo, claveAÑo, claveVersion, claveUsoUnidad, pageRows])
    useEffect(() => {
        handleGetAllMarca();
        handleGetAllUsoUnidad()
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await SeguroFinalService.getAllSeguroFinalPendiente(page, pageRows, claveMarca, claveModelo, claveAÑo,claveUsoUnidad,str)
            .then(res => {          
                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {                
                setLoading(false);
            })
    }
    const habdleDownloadFile = async () => {
        setLoading(true);
        await SeguroFinalService.downloadFileSeguroPendiente(claveMarca, claveModelo, claveAÑo,claveUsoUnidad,str)
            .then(res => {       
                
                var FileSaver = require('file-saver');          
                FileSaver.saveAs(res.data, `SeguroFinalPendiente-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);

                setLoading(false);
            }).catch(e => {                
                setLoading(false);
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllMarca = async () => {      
        await VehiculoService.getAllMarca()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveMarca,
                        label: item.nombreMarca
                    })
                });
                setDataMarca(NewData);
            }).catch(e => {
            })
    }
    const handleChangeMarca = async (value) => {
        setClaveMarca(value);
        setDefaultValueModelo({label:"Modelo",value:""})
        setDefaultValueYear({label:"Año",value:""}) 
        setDataYear([])
        await VehiculoService.getAllModeloById(value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveModelo,
                        label: item.nombreModelo
                    })
                });
                setDataModelo(NewData);               
            }).catch(e => {
            })
    }
    const handleChangeModelo = async (value) => {
        setClaveModelo(value.value);  
        setDefaultValueYear({label:"Año",value:""})  
        setDefaultValueModelo(value)    
        await VehiculoService.getAllYearById(claveMarca, value.value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveYear,
                        label: item.nombreYear
                    })
                });
                setDataYear(NewData);
            }).catch(e => {
            })
    }
    const handleChangeYear = async (value) => {
        setClaveAÑo(value.value);
        setDefaultValueYear(value)
        await VehiculoService.getAllVersionById(claveMarca, claveModelo, value.value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveVersion,
                        label: item.nombreVersion
                    })
                });
                setDataVersion(NewData);
            }).catch(e => {
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/seguro/segurofinal/${value.generalId}`, {
            state: {...value,action:1},
        });

    }
    const handleCreateFolder = (item) => {
        
        setDataVehiculo(item);
        setIsOpenDocs(true)
    }
    const handleSort = (value) => {
        
        habdleGetAll(1, value);
    }
    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsEliminar(current => [...current, value.generalId]);
        } else {
            setIdsEliminar(IdsEliminar.filter(item => item !== value.generalId));
        }
    }
    return {
        columns,
        data,
        handleEdit,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        DataMarca,
        handleCreateFolder,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        setfiltroDocs,
        habdleGetAll,
        handleChangeMarca,
        handleChangeModelo,
        handleChangeYear,
        setClaveVersion,
        DataModelo,
        DataYear,
        DataVersion,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        habdleDownloadFile,
        defaultValueModelo,
        defaultValueYear
    }
}

export default useSeguroPendiente