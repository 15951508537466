import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Usuario from '../../Services/Users/Usuarios';
import * as Yup from 'yup';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux';
import SubmenuUser from '../Usuario/SubmenuUser';
import TablaReglas from '../Usuario/TablaReglas';
import RolesService from '../../Services/Roles/RolesService';
import NuevoRolModal from './NuevoRolModal';
import { message } from '../../Utils/functions';
import TablaReglasRol from './TablaReglasRol';

const RolAddEditPrivilegios = () => {
    let { id } = useParams();
    const { value } = useSelector((state) => state.tabNavigateUser);
    let initial = {
        idRole: id,
        role: "",
        descripcion: "",
        status: false,
        estatusRol: "",
        colorFondo: "",
        colorTexto: ""
    }
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        numeroEmpleado: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .max(150, "Maximo 150 caracteres"),
        nombreUsuario: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            // .matches(
            //     "^[a-zA-Z]+$",
            //     // "La información ingresada es incorrecta."
            // )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .max(150, "Maximo 150 caracteres"),
        email: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .email("ingrese un email valido."),
        idRol: Yup.string()
            .required("El campo es requerido."),
        sucursalId: Yup.string()
            .required("El campo es requerido."),
    });

    const { state } = useLocation();
    const navigate = useNavigate();

    const [dataRol, setDataRol] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataSuc, setDataSuc] = useState([]);
    const [dataModulo, setDataModulo] = useState([]);
    const [curretnRol, setCurretnRol] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const [str, setStr] = useState("");
    const [ModuloId, setModuloId] = useState('0');
    const [OpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        // getAllRol();
        // hangleGEtSucuarsal();
        if (id != 0) {
            handleGetRolById();
        }
    }, [])

    useEffect(() => {
        if (curretnRol !== "") {
            handleGetModuleByrol(curretnRol, value);
            setModuloId("0")
        }
        else
            setDataModulo([])
    }, [curretnRol, value])

    useEffect(() => {        
        if (ModuloId != '0' && curretnRol !== "") {
            handlegetSecctionByMmodule(ModuloId);
        } else {
            setDataTable([]);
        }
    }, [str, ModuloId, curretnRol])


    const getAllRol = async () => {
        setLoading(true);
        await Usuario.getRoles()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idRole,
                        label: item.role
                    })
                });
                setDataRol(NewData);
                setLoading(false);
                // handleGetModuleByrol(NewData.idRole,value);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }
    const handleGetRolById = async () => {
        setLoading(true);
        await RolesService.getRolById(id)
            .then(res => {
                console.log("rol", res.data.data)
                let item = res.data.data;
                let initial = {
                    idRole: id,
                    role: item?.role ?? "",
                    descripcion: item?.descripcion ?? "",
                    status: item?.status ?? false,
                    estatusRol: item?.estatusRol ?? "",
                    colorFondo: item?.colorFondo ?? "",
                    colorTexto: item?.colorTexto ?? "",
                }
                setInitialValue(initial);
                setCurretnRol(item.idRole);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }
    const hangleGEtSucuarsal = async () => {
        setLoading(true);
        await Usuario.getSucursal(1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.sucursalId,
                        label: item.nombre
                    })
                });
                setDataSuc(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }
    const handleGetModuleByrol = async (rol, menu) => {
        setLoading(true);

        await Usuario.getMmoduleByRol(value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "0",
                    label: "Seleccione"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idSubModulo,
                        label: item.subModulo
                    })
                });
                setDataModulo(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }
    const handlegetSecctionByMmodule = async (idModulo) => {
        if (idModulo == '0') {
            setDataTable([]);
            return
        }

        setLoading(true);
        await RolesService.getsectionByRol(id, value, ModuloId,str)
            .then(res => {
                setDataTable(res.data.data);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })


    }
    // change
    const handleChangeProps = (value, item, propName) => {
        console.log(value,item,propName)
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        [propName]: value
                    }
                    : data
            )
        );
    }
    // end
   
    const handleSaveRol = async () => {
        let data = {
            userId: id,
            rolId: curretnRol,
            subModuloId: ModuloId,
            permisos: dataTable
        }        
        if(data.subModuloId === "0"){
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un submodulo para continuar")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setLoading(true);
        await RolesService.SavePrivilegiosRoles(data)
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                } else {
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
                setLoading(false);
                handlegetSecctionByMmodule(ModuloId);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
        // 
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
            }}
        >{
                ({ isValid, values, handleChange, setFieldValue }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Toaster
                            position="top-right"
                            toastOptions={{
                                success: {
                                    style: {
                                        background: '#47a066',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #2f7246'
                                    },
                                },
                                error: {
                                    style: {
                                        background: '#d53f3f',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #ac241a'
                                    },
                                },
                            }}
                        />
                        <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                            <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                            Regresar a Registro {state.page}
                        </header>
                        <Form className='mx-5'>
                            <section className=' py-4'>
                                <div className='d-flex'>
                                    <span className='fs-5'><strong>Registro nuevo usuario</strong></span>
                                    <div className='px-4'>
                                        <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); }} ><i className='ri-edit-2-fill'></i></fieldset>
                                        {/* <fieldset className='btn px-4' type='submit' disabled={!(isValid)}>Guardar</fieldset> */}

                                    </div>
                                </div>
                                <span className='fs-6'>Datos personales:</span>
                            </section>
                            <div className="mb-3 row">
                                <div className='col-3'>
                                    <span><strong>Rol</strong></span> <br />
                                    <span>{values.role}</span>
                                </div>
                                <div className='col-3'>
                                    <span><strong>Descripción</strong></span> <br />
                                    <span>{values.descripcion}</span>
                                </div>
                                <div className='col-3'>
                                    <span><strong>Estatus</strong></span> <br />
                                    <span style={{ backgroundColor: `${values.colorFondo}`,width:`${values.status ? "86px":"97px"}`,borderRadius:'5px'}} className='d-flex align-items-center'><i style={{ backgroundColor: `${values.status ? "#47A066":"gray"}`}} className="icon status-icon mx-2"></i>{values.estatusRol}</span>
                                </div>
                            </div>
                            {
                                id != '0' &&
                                <section className='mt-5'>
                                    <span className='fs-5'><strong>Privilegio</strong></span>
                                    <SubmenuUser />
                                    {/* {
                                        value === '053f88fd-ae9a-4e69-87e1-e20f042ae090' &&
                                        <TablaReglasRol
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                    {
                                        value === '0030f26f-6af2-400e-a7b5-3aebf4e710ec' &&
                                        <TablaReglasRol
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                    {
                                        value === '03b1e9d4-96c5-4512-b956-dccf481bc516' &&
                                        <TablaReglasRol
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    } */}
                                                                        {
                                        value  &&
                                        <TablaReglasRol
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                </section>
                            }

                        </Form>
                        {
                            OpenModal &&
                            <NuevoRolModal
                                isOpen={OpenModal}
                                setIsOpen={setIsOpenModal}
                                toast={toast}
                                item={initialValue}
                                handleGetAll={handleGetRolById}
                                action={"edit"}
                            />
                        }
                    </>
                )
            }

        </Formik>

    )
}

export default RolAddEditPrivilegios