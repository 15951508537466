import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import Spinner from '../../Components/Loadig';
import { SelectField } from '../../Components/SelectField';
import handlePhonNumberFormatter from '../../Utils/functions';

const SucursalVendedor = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage, existingData }) => {
    let initial = {
        sucursalVendedorId: item?.sucursalVendedorId ?? "",
        socioComercialId: item?.socioComercialId ?? "",
        nombre: item?.nombre ?? "",
        calle: item?.calle ?? "",
        numeroExterior: item?.numeroExterior ?? "",
        numeroInterior: item?.numeroInterior ?? "",
        codigoPostal: item?.codigoPostal ?? "",
        estadoId: item?.estadoId ?? "",
        municipioId: item?.municipioId ?? "",
        coloniaId: item?.coloniaId ?? "",
        telefono: item?.telefono ?? "",
        matriz: item?.matriz ?? false,
        status: item?.status ?? true
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [SC, setSC] = useState([]);
    useEffect(() => {
        handleGetAllList();
        handleGetAllCSSocioComercial();
        if (item !== null) {
            handleChnageSelect(item.estadoId);
            handleChangeMun(item.municipioId);
        }
    }, [])

    const [messageErrorSocio, setMessageErrorSocio] = useState("");
    
    const validate = Yup.object({
        socioComercialId: Yup.string()
            .required("El campo es requerido."),
        nombre: Yup.string()
            .required("El campo es requerido."),
        calle: Yup.string()
            .required("El campo es requerido."),
        numeroExterior: Yup.number()
            .required("El campo es requerido."),
        // numeroInterior: Yup.number()
        //     .required("El campo es requerido."),
        codigoPostal: Yup.number()
            .required("El campo es requerido."),
        coloniaId: Yup.string()
            .required("El campo es requerido."),
        municipioId: Yup.string()
            .required("El campo es requerido."),
        estadoId: Yup.string()
            .required("El campo es requerido."),
        telefono: Yup.string()
            .required("El campo es requerido."),

    });
    const handleGetAllCSSocioComercial = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SocioComercial')
            .then(res => {
                let NewData = [];
                res.data?.map(item => {
                    NewData.push({
                        value: item.SocioComercialId,
                        label: item.Nombre
                    })
                });
                setSC(NewData);
            })
            .catch(err => {
                console.error(err);
            })
    }
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("EdoMexico", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    })
                });
                setData2(NewData);
            }).catch(e => {

            })
    }
    const handleChnageSelect = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setData1(NewData);
            }).catch(e => {

            })
    }
    const handleChangeMun = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                setData(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (data) => {
        if (action === "edit") {
            setLoading(true);
            await Catalogo.updateSucursalVendedor(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setLoading(false);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (action === 'add') {
            if (existingData.find((el) => el.nombre?.toLowerCase() === data.nombre?.toLowerCase()
                                            && el.municipioId === data.municipioId)) {
                setMessageErrorSocio("El socio comercial ya existe.");
                return;
            }
            
            setLoading(true);
            await Catalogo.addSucursalVendedor(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setLoading(false);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '40rem' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span
                                            className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="mb-3">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label
                                                className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox"
                                                   name="status"/>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div className="col-sm-4">
                                            <SelectField id="socioId" label="Socio comercial" name="socioComercialId"
                                                         items={SC} onChange={(value) => {
                                                setFieldValue("socioComercialId", value.value);
                                            }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="nombreId" label="Nombre" name="nombre" type="text"
                                                       holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="calleid" label="Calle" name="calle" type="text"
                                                       holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="NumeroExteriorId" label="No. Exterior" name="numeroExterior"
                                                       type="number" holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="numeroInteriorId" label="No. Interior" name="numeroInterior"
                                                       type="number" holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="CpId" label="Codigo Postal" name="codigoPostal" type="text"
                                                       holder="Escribe"/>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4">
                                            <SelectField id="Estado" label="Estado" name="estadoId" items={data2}
                                                         onChange={(value) => {
                                                             setFieldValue("estadoId", value.value);
                                                             handleChnageSelect(value.value);
                                                             setFieldValue("municipioId", "");
                                                             setFieldValue("coloniaId", "");
                                                         }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <SelectField id="Municipio" label="Municipio" name="municipioId"
                                                         items={data1} onChange={(value) => {
                                                setFieldValue("municipioId", value.value);
                                                handleChangeMun(value.value);
                                                setFieldValue("coloniaId", "");
                                            }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <SelectField id="Colonia" label="Colonia" name="coloniaId" items={data}
                                                         onChange={(value) => {
                                                             setFieldValue("coloniaId", value.value);
                                                         }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="CpId" label="Telefono" name="telefono" type="text"
                                                       holder="Escribe" onChange={(event) => {
                                                setFieldValue("telefono", handlePhonNumberFormatter(event.target.value));
                                            }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="mb-3">
                                                <label htmlFor="staticEmail"
                                                       className="col-sm-2 col-form-label">Matriz</label>
                                                <div className="col-ms-6 form-check form-switch">
                                                    <label
                                                        className="form-check-label">{values?.matriz ? "Activo" : "Inactivo"}</label>
                                                    <Field id="matrizId" className="form-check-input" type="checkbox"
                                                           name="matriz"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-danger">{messageErrorSocio}</span>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => {
                                        setIsOpen(false)
                                    }}>Cancelar
                                    </button>
                                    <button className='btn mx-2 px-5' type='submit' disabled={!(isValid)}>Continuar
                                    </button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}
export default SucursalVendedor