import axios from '../../Utils/ApiService';

const Parametros = {
    getTablesParams: (section) => {
        return axios.get(`/functions/Parametros?seccion=${section}`);
    },
    getTablesParamsList: (section, page, rows, string) => {
        return axios.get(`/functions/ParametrosList?seccion=${section}&page=${page}&rows=${rows}&buscar=${string}`);
    },
    getTablesList: () => {
        return axios.get(`/functions/GetAllAsycList`);
    },
    updateParam: (param, value) => {

        return axios.put(`/functions/${param}?value=${value}`);
    },
    getHistoy: (page, rows, string, fecha, seccion) => {
        return axios.get(`/functions/LogsParametros?page=${page}&rows=${rows}&buscar=${string}&fecha=${fecha}&seccion=${seccion}`);
    },
    UpdateTokenDatadocks: () => {
        return axios.put(`/functions/ActualizarTokenDataDocs`);
    },
    UpdateTokenDataAscendes: () => {
        return axios.put(`/functions/ActualizarTokenAcendes`);
    },

    // Parametros cartera
    GetAllParametrosCartera: () => {
        return axios.post(`/Cartera/GetAllParametros`);
    },
    GetAllParametrosByField: (data) => {
        return axios.post(`/Cartera/GetAllParametrosByField`, data);
    },
    CreateParametro: (data) => {
        return axios.post(`/Cartera/CreateParametro`, data);
    },


    //parametros cartera log
    GetAllCobranzaParametrosLogs: () => {
        return axios.get(`/Cartera/GetAllCobranzaParametrosLogs`);
    },
    CreateCobranzaParametrosLog: (data) => {
        return axios.post(`/Cartera/CreateCobranzaParametrosLog`, data);
    },
    
}

export default Parametros