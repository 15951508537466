import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import EliminarExternoService from '../../Services/Inventario/EliminarExternoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { message } from '../../Utils/functions';

const useEliminarExternoPendiente = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'solicitud', headerName: 'Solicitud', width: 170 },
        { field: 'ultimaEtapa', headerName: 'Ultima etapa', width: 250 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 250 },
        { field: 'externo', headerName: 'Externo', width: 250 },
        { field: 'estatusSeguro', headerName: 'Estatus seguro', width: 250 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'estatusEliminacion', headerName: 'Estatus eliminación', width: 200 },
        { field: 'usuario', headerName: 'Usuario', width: 200 },
        { field: 'fecha', headerName: 'Fecha y hora', width: 300 },
    ];
    let Statuseliminacion = [
        { value: 2, label: "Todos" },
        { value: 1, label: "Eliminado" },
        { value: 0, label: "Sin eliminar" },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //Filtro
    const [tipoCompra, settipoCompra] = useState([]);
    const [dataTipoExterno, setdataTipoExterno] = useState([])
    const [claveTipoCompra, setclaveTipoCompra] = useState("");
    const [claveTipoExterno, setClaveTipoExterno] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [claveEstatus, setclaveEstatus] = useState("");
    const [item, setItem] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);


    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, claveTipoCompra, claveTipoExterno, pageRows,claveEstatus])
    useEffect(() => {
        handleGetAllSelects();
    }, [])

    const handleGetAllSelects = async () => {
        setLoading(true)
        const [Sociocomercial, Tipocompra] = await Promise.all([handleGetTipoExterno(), handleGetTipoCompra()]).finally(() => {
            setLoading(false)
        });
    }
    const habdleGetAll = async (page) => {
        setLoading(true);
        await EliminarExternoService.getAllPendientesEliminar(page, pageRows, claveTipoCompra, str, claveTipoExterno,claveEstatus)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetTipoExterno = async () => {
        await Catalogo.getAllCSTipoExterno()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoExternoId,
                        label: item.tipoExterno
                    })
                });
                setdataTipoExterno(NewData);
            }).catch(e => {

            })
    }
    const habdleDownloadFile = async () => {
        setLoading(true);
        await EliminarExternoService.DownloadFile(claveTipoCompra, str, claveTipoExterno,claveEstatus)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `EliminarExterno-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleAction = (item,value) =>{
        if(item?.esEliminado){
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!",`No es posible ${value === 1 ? "Mantener" : "Eliminar"} el vehículo porque ya a sido elimnado`)}</div>);            
            return;
        }
        setItem({...item,action:value});
        setIsOpenModal(true);
    }
    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        dataTipoExterno,
        tipoCompra,
        setclaveTipoCompra,
        setClaveTipoExterno,
        Statuseliminacion,
        setclaveEstatus,
        handleAction,
        item,
        setIsOpenModal,
        isOpenModal,
        habdleGetAll
    }
}

export default useEliminarExternoPendiente