import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';
import TblSecundariasService from '../../Services/TablasSecundarias/TblSecundariasService';
import MultiSelect from '../../Components/DropDown';
import { MultiSelectField } from '../../Components/MultiSelectField';
import MultiSelectC from '../../Components/MultiSelect';

const Empresa = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {

    if (item !== null)
        if (item.representantes !== null) {
            var xx = item.representantes.split(",");
            // var nreRep =[];
            // xx.map(x=>{
            //     nreRep.push(
            //     {
            //         value:x
            //     })
            // })
        }
    const refbtn = useRef();
    let initial = {
        empresaId: item?.empresaId ?? "",
        empresa: item?.empresa ?? "",
        calle: item?.calle ?? "",
        numExterior: item?.numExterior ?? "",
        numInterior: item?.numInterior ?? "",
        codigoPostalId: item?.codigoPostalId ?? "",
        coloniaId: item?.coloniaId ?? "",
        municipioId: item?.municipioId ?? "",
        estadoId: item?.estadoId ?? "",
        representantes: xx ?? [],
        status: item?.status ?? true
    }
    
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [dataRep, setDataRep] = useState([]);
    const [state, setState] = useState([]);

    useEffect(() => {
        handleGetAllList();
        handleGetAllRepresentateLegal();
        if (item !== null) {
            handleChnageSelect(item.estadoId);
            handleChangeMun(item.municipioId);
        }

    }, [])

    const validate = Yup.object({
        empresa: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        calle: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        numExterior: Yup.string()
            .required("El campo es requerido."),
        numInterior: Yup.string()
            .required("El campo es requerido."),
        codigoPostalId: Yup.string()
            .required("El campo es requerido."),
        coloniaId: Yup.string()
            .required("El campo es requerido."),
        municipioId: Yup.string()
            .required("El campo es requerido."),
        estadoId: Yup.string()
            .required("El campo es requerido."),
        // representantes: Yup.string()
        //     .required("El campo es requerido."),
        // representantes: Yup.array(
        //     Yup.string().required("El campo es requerido."),
        // ).min(1, "Seleccione al menos un valor")
    });
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("EdoMExico", 1, 100)
            .then(res => {

                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    })
                });
                setData2(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllRepresentateLegal = async () => {
        await Catalogo.getdataTabla("RepresentanteLegal", 1, 1000)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.representanteLegalId,
                        label: `${item.primerNombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`
                    })
                });
                setDataRep(NewData);

                let selected = [];
                

                if (xx.length > 0) {
                    NewData.map((option) => {
                        xx.map((item) => {
                            if (item.toUpperCase() == option.value.toUpperCase()) {
                                selected.push(
                                    {
                                        value: item, label: option.label
                                    }
                                );
                            }

                        })
                    })
                    // setSelectedValue(selected);                    
                    // return selected;
                }
                //
                setState(selected);
                
            }).catch(e => {
                // setIsOpen(false);

                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addEmpresa(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateEmpresa(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    const handleChnageSelect = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setData1(NewData);
            }).catch(e => {

            })
    }
    const handleChangeMun = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                setData(NewData);
            }).catch(e => {

            })
    }
    const handleChange = value => {
        setState(value);        
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                let repre = [];
                state?.map(item => {
                    repre.push(item.value);
                })
                values.representantes = repre.join();
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, meta, setFieldValue }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '50rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3 row">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="Empresa" label="Empresa" name="empresa" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="Calle"label="Calle" name="calle" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="NumeroExterior" label="Numero Exterior" name="numExterior" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="NumeroInterior" label="Numero Interior" name="numInterior" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="CP" label="CP" name="codigoPostalId" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="Estado" label="Estado" name="estadoId" items={data2} onChange={(event) => {
                                            // handleChange(event);
                                            setFieldValue("estadoId", event.value);
                                            handleChnageSelect(event.value);
                                            setFieldValue("municipioId", "");
                                            setFieldValue("coloniaId", "");
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <SelectField id="Municipio" label="Municipio" name="municipioId" items={data1} onChange={(event) => {
                                            // handleChange(event);
                                            setFieldValue("municipioId", event.value);
                                            handleChangeMun(event.value);
                                            setFieldValue("coloniaId", "");
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="Colonia" label="Colonia" name="coloniaId" items={data} onChange={(event) => {
                                            setFieldValue("coloniaId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        {/* <MultiSelectField label="Representante legal" state={state} component={MultiSelectC}  name="representantes" items={dataRep} /> 
                                         */}
                                        <div className="mb-2">
                                            <label className='text'>{"Representante legal"}</label>
                                            <MultiSelect
                                                id="RepresentanteLegal"
                                                holder={"Seleccione"}
                                                className={'col-12 mx-1'}
                                                value={state}
                                                options={dataRep}
                                                handleChange={handleChange}
                                            />
                                            {/* {meta.error ? (
                                                <span className="error">{meta.error}</span>
                                            ) : null} */}
                                        </div>

                                    </div>
                                </div>


                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default Empresa