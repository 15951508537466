import React, { useState } from "react";
import SubmenuLogs from './SubMenuLogs'
import DataTable from '../../Components/datatable/DataTable'
import useSistema from '../../Hooks/Logs/useSistema'
import Modal from '../../Components/Modal/Modal'
import Spinner from '../../Components/Loadig'
import ErrorModal from '../../Components/Modal/ErrorModal'
import SuccessModal from '../../Components/Modal/SuccessModal'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'

const Sistema = () => {
    const navigate = useNavigate();
    let { columns,
        data,
        loading,
        modalMessage,
        setModalMessage,
        handleCheck,
        pageCount,
        handlePageClick,
        dataModulo,
        dataProceso,
        dataCategoria,
        setStr,
        setDateTime,
        setCategoriaId,
        setProcesoId,
        handleDownloadFile,
        isenablebtn,
        initialPage,
        refBtn,
        refBtnExp,
        handleExportdFile,
        setIsOpen,
        setpageRows } = useSistema();
        const [dataRow, setDataRow] = useState({});
        const handleDetails = async (row) => {
            console.log(row);
            setDataRow(row);
            setModalMessage({ isOpen: true, type: 3 });
          };
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {modalMessage.type === 3 && (
                        <div style={{ width: '60rem' }}>
                        <section className='d-flex align-items-center'>
                            <i className="bi bi-chevron-compact-left fs-5" style={{ color: '#004c97', fontWeight: 'bold' }} onClick={() => setModalMessage(prev => ({ ...prev, isOpen: false }))}>
                            </i>
                            <span className='mt-4'><strong>ID - {dataRow.logSistemaId} </strong></span>
                        </section>
                        <section className="d-flex flex-column mx-4">
                        <span style={{ fontSize: "14px" }}>
                            {" "}
                            <strong>Categoría:</strong> {dataRow?.categoria}
                        </span>
                        </section>

                        <section className="d-flex flex-column mx-4">
                        <span style={{ fontSize: "14px" }}>
                            {" "}
                            <strong>Fecha y hora: </strong>
                            {dataRow?.dateOnly} {dataRow?.hora}
                        </span>
                        </section>
                        <section className="d-flex flex-column mx-4">
                        <span style={{ fontSize: "14px" }}>
                            {" "}
                            <strong>Resultado:</strong> {dataRow?.resultado}
                        </span>
                        </section>
                        <section className="d-flex flex-column mx-4 mt-2">
                        <span style={{ fontSize: "14px" }}>
                            {" "}
                            <strong className="contenido-json">Contenido:</strong>
                        </span>
                        </section>
                        <section className="d-flex flex-column mx-4">
                        <span style={{ fontSize: "14px" }}> {dataRow?.contenido}</span>
                        </section>
                        <section className='mt-4 d-flex justify-content-end'>
                            <button className='btn'  onClick={() => setModalMessage(prev => ({ ...prev, isOpen: false }))}>
                                Aceptar
                            </button>
                        </section>
                    </div>
                )}

            </Modal>
            <SubmenuLogs />
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Registro Sistema</strong></span>
                    <div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Sistema, SubModule.Logs).Historial}>
                            <button className='icon btn btn-outline mx-2' onClick={() => {
                                navigate(`/admin/logs/sistema/eliminado`, {
                                    state: {
                                        page: 'Sistema',
                                        title: "title"
                                    },
                                });
                            }}><i className="bi bi-trash-fill" style={{ color: '#004c97' }}></i></button>
                        </ValidatePermission>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Sistema, SubModule.Logs).Descarga}>
                            <button className='btn px-4 me-1' ref={refBtnExp} onClick={handleExportdFile}>Exportar</button>
                        </ValidatePermission>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Sistema, SubModule.Logs).Ver}>
                            <button className='btn px-4 ms-1' ref={refBtn} onClick={handleDownloadFile}>Eliminar</button>
                        </ValidatePermission>
                    </div>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los registro de las tareas programadas y sin interacción humana.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.Sistema, SubModule.Logs).Ver}>
                <section className='mt-5 mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDateTime(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <Select options={dataProceso} className='col-12' onChange={(e) => { setProcesoId(e.value) }} placeholder="Todos los procesos" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <Select options={dataCategoria} className='col-12 ' onChange={(e) => { setCategoriaId(e.value) }} placeholder="Todas las categorias" />
                        </div>
                    </div>

                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        editable={false}
                        detailable={true}
                        paginate={true}
                        checkbox={true}
                        handleCheck={handleCheck}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={initialPage}
                        handleDetail={handleDetails}                     
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default Sistema