import React, { useRef, useState, useEffect } from 'react'
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { toast, Toaster } from 'react-hot-toast'
import Spinner from "../../../../Components/Loadig"
import Modal from "../../../../Components/Modal/Modal"
import { TextField } from '../../../../Components/TextField';
import { Formik, Form, Field } from 'formik'
import { TextAreaField } from '../../../../Components/TextAreaField';
import { SelectField } from '../../../../Components/SelectField';
import RowPages from '../../../../Components/RowPages';
import DataTable from '../../../../Components/datatable/DataTable'
import { FormatNumberMoney2, message } from '../../../../Utils/functions'
import CobranzaService from '../../../../Services/Cobranza/Cobranza'
import ModalCostosResguardoUnidad from './ModalCostosResguardoUnidad'
import ModalConfirmarEliminar from './ModalConfirmarEliminar'
import { v4 as uuidv4 } from 'uuid';

const ResguardoUnidadCards = React.forwardRef(({ ...props }, ref) => {
   const {itemGeneral} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const [show, setShow] = useState(props.show);
   // const { state } = useLocation();
   useEffect(() => {
      setShow(props.show);
   }, [props.show]);

   let initialPromesaPago = {
      motivoResguardo: "",
      formaArribo: "",
      costosAdicionalesTbl: [
      ],

      fechaResguardo: "",
      horaResguardo: "",
      quienEntrega: "",
      usuarioRecibe: "",
      ubicacion: "",
      ubicacionEntrega: "",
      comentarios: "",
      registroConcluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
   const [cobradorSeleccionado, setUbicacionEntrega] = useState(0);
   const [motivoResguardo, setMotivoResguardo] = useState(0);
   const [formaArribo, setFormaArribo] = useState(0);
   const [totalPagos, setTotalPagos] = useState(0);
   const [dataMotivoResguardo, setDataMotivoResguardo] = useState([]);
   const [dataFormaArribo, setDataFormaArribo] = useState([]);
   const [dataUbicacionEntrega, setDataUbicacionEntrega] = useState([]);
   const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

   const [isOpenModal, setIsOpenModal] = useState(false);
   const [selectedItem, setSelectedItem] = useState();
   const [pageRows, setpageRows] = useState(10);
   const [itemSelected, setItemSelected] = useState();
   const [openModalDelete, setOpenModalDelete] = useState(false);
   const [pageCount, setPageCount] = useState(1);
   const [currenPage, setCurrenPage] = useState(1);
   const [loading, setLoading] = useState(false);

   const columnsPagos = [
      { field: 'id', headerName: '#' },
      { field: 'fechaHora', headerName: 'Fecha y hora' },
      { field: 'ejecutivo', headerName: 'Ejecutivo' },
      { field: 'concepto', headerName: 'Concepto' },
      { field: 'subTotal', headerName: 'Subtotal' },
      { field: 'iva', headerName: 'IVA' },
      { field: 'total', headerName: 'Total' },
      { field: 'acciones', headerName: 'Acciones', width: 200 }
   ];

   useEffect(() => {
      handleGetMotivoResguardo();
      handleGetAllFormaArribo();
      handleGetAllUbicacionEntrega();
   }, []);

   const handleEdit = (item) => {
      setSelectedItem({ ...item });
      setIsOpenModal(true)
   };

   const addUpdateCostosAdicionalesTbl = (item) => {
      if (item?.idCostoAdicional == "") {
         //insert
         // const maxId = datosPromesaPago.costosAdicionalesTbl.length > 0 ? datosPromesaPago.costosAdicionalesTbl.reduce((max, item) => item.id > max ? item.id : max, datosPromesaPago.costosAdicionalesTbl[0].id) : 0;
         // const nuevoId = maxId + 1;
         const now = new Date();
         datosPromesaPago.costosAdicionalesTbl.push({
            id: uuidv4(),
            fechaHora: now.toString(),
            ejecutivo: sessionStorage.getItem('nombreUsuario').trim(),
            concepto: item?.concepto,
            subTotal: item?.subTotal,
            iva: item?.iva,
            total: item?.total,
            acciones: { subido: false }
         });
      }
      else {
         //update
         let index = datosPromesaPago.costosAdicionalesTbl.findIndex(x => x.id === item?.idCostoAdicional);
         if (index !== -1) {
            datosPromesaPago.costosAdicionalesTbl[index].concepto = item?.concepto;
            datosPromesaPago.costosAdicionalesTbl[index].subTotal = item?.subTotal;
            datosPromesaPago.costosAdicionalesTbl[index].iva = item?.iva;
            datosPromesaPago.costosAdicionalesTbl[index].total = item?.total;
         }
      }
      setTotalPagos(handleTotal(datosPromesaPago.costosAdicionalesTbl));
      setDatosPromesaPago(datosPromesaPago);
   };

   const handleTotal = (array) => {
      const total = array.reduce((previous, current) => {
          return previous + current.total; // sumar el valor de una propiedad
      }, 0);
      return total;
  }

  const deleteCostosAdicionalesTbl = (item) => {
      setLoading(true);
      datosPromesaPago.costosAdicionalesTbl = datosPromesaPago.costosAdicionalesTbl.filter(x => x.id !== item?.id);
      setDatosPromesaPago(datosPromesaPago);
      setLoading(false);
   };

   const handleViewdocs = async (item) => {
      setLoading(true);
      // setFileName(item.nombreDocumento);
      // await handleGetTokenDatadocs();
      // await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
      //    headers: {
      //       'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      //    },
      //    responseType: "blob"
      // })
      //    .then(res => {
      //       var file = new File([res.data], res.data.name, { type: res.data.type });
      //       setSelectedDocs([file])
      //       setLoading(false);
      //       setIsOpenDocs(true)
      //    }).catch(e => {
      //       setLoading(false);
      //       if (e.response !== undefined)
      //          toast.error(message("Error.!", e.response.data.errors?.error));
      //       else
      //          toast.error(message("Error.!", e.message));
      //    })
   };

   const handleUploadFile = async (value, subido) => {
      // if (subido) {
      //    await handleViewdocs(value)
      // } else {
      //    navigate(`/inventario/valuacion/datadocs/fValuacion/eRepar/${state?.contrato}/${id}`, {
      //       state: { ...value, registroId: value.idCostoAdicional, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
      //    });
      // }
   };

   const handlePageClick = (event) => {
      setCurrenPage(event.selected + 1);
      // getAll(event.selected + 1);
   };

   const handleGetMotivoResguardo = async () => {
      setLoading(true);
      const parametrosPeticion = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      };
      await CobranzaService.getAllMotivoResguardo(parametrosPeticion)
         .then(res => {
            let listadoCatalogo = [];
            res.data.responseData?.map(x => {
               listadoCatalogo.push({
                  value: x.id,
                  label: x.valor
               })
            });

            setDataMotivoResguardo(listadoCatalogo);
            setLoading(false);
         }).catch(e => {
            setLoading(false);
            if (e.data !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.responseData.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
   }

   const handleGetAllFormaArribo = async () => {
      setLoading(true);
      const parametrosPeticion = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      };
      await CobranzaService.getAllFormaArribo(parametrosPeticion)
         .then(res => {
            let listadoCatalogo = [];
            res.data.responseData?.map(x => {
               listadoCatalogo.push({
                  value: x.id,
                  label: x.valor
               })
            });

            setDataFormaArribo(listadoCatalogo);
            setLoading(false);
         }).catch(e => {
            setLoading(false);
            if (e.data !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.responseData.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
   }

   const handleGetAllUbicacionEntrega = async () => {
      setLoading(true);
      const parametrosPeticion = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      };
      await CobranzaService.getAllUbicacionEntrega(parametrosPeticion)
         .then(res => {
            let listadoCatalogo = [];
            res.data.responseData?.map(x => {
               listadoCatalogo.push({
                  value: x.id,
                  label: x.valor
               })
            });

            setDataUbicacionEntrega(listadoCatalogo);
            setLoading(false);
         }).catch(e => {
            setLoading(false);
            if (e.data !== undefined)
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.responseData.message })
            else
               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
         })
   }

   const handleGuardar = async (values) => {
      setLoading(true);

      let idRegistroPrincipal = uuidv4();

      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {
            "id": idRegistroPrincipal,
            "ruta_head_id": itemGeneral?.ruta_head_id,
            "ruta_item_id": "",
            "evento_head_id": itemGeneral?.evento_head_id,
            "evento_item_id": itemGeneral?.evento_item_id,
            "motivo_resguardo": values.motivoResguardo,
            "forma_arribo": values.formaArribo,
            "total_costo": totalPagos,
            "fecha_hora_resguardo": values.fechaResguardo + ' ' + values.horaResguardo,
            "quien_entrega": values.quienEntrega,
            "usuario_recibe": values.usuarioRecibe,
            "otro": "",
            "ubicacion_entrega": values.ubicacionEntrega,
            "comentarios": values.comentarios,
            "resguardoCostos": values.costosAdicionalesTbl.map(item => ({
               id: item.id,
               resguardo_id: idRegistroPrincipal,
               concepto: item.concepto,
               subtotal: parseFloat(item.subTotal),
               iva: parseFloat(item.iva),
               total: item.total
            }))
         }
      };

      await CobranzaService.createUpsertCobranzaResguardoUnidad(data)
         .then(res => {
            setLoading(false)
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
         }).catch(e => {
            console.log(e)
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   const validate = Yup.object({
      motivoResguardo: Yup.string().required("El campo es requerido."),
      formaArribo: Yup.string().required("El campo es requerido."),
      fechaResguardo: Yup.string().required("El campo es requerido."),
      horaResguardo: Yup.string().required("El campo es requerido."),
      quienEntrega: Yup.string().required("El campo es requerido."),
      usuarioRecibe: Yup.string().required("El campo es requerido."),
      ubicacion: Yup.string().required("El campo es requerido."),
      ubicacionEntrega: Yup.string().required("El campo es requerido."),
      comentarios: Yup.string().required("El campo es requerido."),
      fechaInicioProceso: Yup.string().required("El campo es requerido."),
      fechaFinProceso: Yup.string().required("El campo es requerido."),
    });

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
         {show && (
         <div className="row wrapper-vehiculo">
            <div className="col col-12">
               <div className="row mx-md-4 my-4">
                  <div className="col-12 px-md-3">
                     <div className="p-4 white-wrapper mb-2">
                        <div
                           className="row mt-2 px-4"
                           data-bs-toggle="collapse"
                           href="#collapseResguardoUnidad"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseResguardoUnidad"
                           onClick={() => setCollpsableGrua(!collpsableGrua)}
                        >
                           <div className="col-11">
                              <h6>
                                 <span className={`semaforo semaforo-`} ></span>{" "}
                                 <strong>Resguardo de unidad</strong>
                              </h6>
                           </div>
                           <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                              <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                           </div>
                        </div>
                        <div className="collapse" id="collapseResguardoUnidad">
                           {/* tipo promesa: garantia de pago */}
                           <Formik
                              innerRef={refFormik}
                              enableReinitialize={true}
                              initialValues={datosPromesaPago}
                              validationSchema={validate}
                           // onSubmit={(values) => {
                           //    handleGuardar(values);
                           // }}
                           >
                              {({ isValid, values, setFieldValue }) => (
                                 <Form>
                                    <div className="separator"></div>
                                    <div className="row mt-2 px-4">
                                       <div className="row">
                                          <div className="col-sm-12 col-md-6">
                                             <p className="text-base"><strong>Generar documento</strong>
                                             </p>
                                             <div className="row">
                                                <div className="col-6">
                                                   Documento de inspección de unidad
                                                </div>
                                                <div className="col-6">
                                                   <button className='btn btn-gray'>
                                                      <i className="icon-blue bx bx-download"></i>
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-sm-12 col-md-6 text-end">
                                             <button className='btn btn-gray'>
                                                <i className="icon-light fa fa-file "></i> Subir documento
                                             </button>
                                             <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registroConcluido} onClick={() => handleGuardar(values)}>
                                                Guardar
                                             </button>
                                          </div>
                                       </div>
                                       <p className="text-base"><strong>Detalle del resguardo</strong></p>
                                       <div className='my-2 mt-4'>
                                          <div className="mb-3 row">
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField id="motivoResguardo" label="Motivo resguardo" name="motivoResguardo" items={dataMotivoResguardo}
                                                   onChange={(event) => {
                                                      if (event.value == "") {
                                                         setFieldValue("motivoResguardo", "");
                                                         setMotivoResguardo(0);
                                                      }
                                                      else {
                                                         setMotivoResguardo(event.value);
                                                         setFieldValue("motivoResguardo", event.label);
                                                      }
                                                   }} />
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField id="formaArribo" label="Forma de arribo" name="formaArribo" items={dataFormaArribo}
                                                   onChange={(event) => {
                                                      if (event.value == "") {
                                                         setFieldValue("formaArribo", "");
                                                         setFormaArribo(0);
                                                      }
                                                      else {
                                                         setFormaArribo(event.value);
                                                         setFieldValue("formaArribo", event.label);
                                                      }
                                                   }} />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row mt-2 mb-2">
                                          <div className="col-auto">
                                             <strong>Costos adicionales</strong>
                                          </div>
                                          <div className="col-auto">
                                             <fieldset className='btn' onClick={() => {
                                                setIsOpenModal(true);
                                                setSelectedItem({ valuacionRegistroGastosId: "" })
                                                // setSelectedItem({ valuacionRegistroGastosId: initialValue.valuacionRegistroGastosId })
                                             }}>
                                                Registrar costo
                                             </fieldset>
                                          </div>
                                       </div>

                                       <RowPages setpageRows={setpageRows} />
                                       <div className="row mt-2 px-4 mb-2">
                                          <div className="col-12">
                                             <DataTable
                                                id="tablaPagos"
                                                name="pagos"
                                                column={columnsPagos}
                                                data={datosPromesaPago.costosAdicionalesTbl}
                                                // editable={!initialValue.concluido}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                deleteable={true}
                                                handleDelete={(item) => { setItemSelected(item); setOpenModalDelete(true); }}
                                                handleDocuments={handleViewdocs}
                                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                paginate={true}
                                                pageCount={pageCount}
                                                handlePageClick={handlePageClick}
                                                currentPage={currenPage - 1}
                                             />
                                          </div>
                                       </div>
                                       <div className="row mt-2 px-4 mb-2 justify-content-end">
                                          <div className="col-auto">
                                             <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                                          </div>
                                       </div>

                                       {/* aqui registrar costos y tabla */}
                                       <p className="text-base"><strong>Detalle de la entrega</strong></p>
                                       <div className='my-2 mt-4'>
                                          <div className="mb-3 row">
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField id="fechaResguardo" label="Fecha de resguardo" name="fechaResguardo" type="date" holder="Escribe" onChange={(event) => { setFieldValue("fechaResguardo", event.target.value); }} />
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField id="horaResguardo" label="Hora de resguardo" name="horaResguardo" type="time" holder="Escribe" onChange={(event) => { setFieldValue("horaResguardo", event.target.value); }} />
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Quien entrega" name="quienEntrega" type="text" holder="" onChange={(event) => { setFieldValue("quienEntrega", event.target.value); }} />
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                {/* <TextField disabled label="Usuario recibe" name="usuarioRecibe" type="text" holder="" /> */}
                                                <TextField label="Usuario recibe" name="usuarioRecibe" type="text" holder="" />
                                             </div>
                                          </div>
                                          <div className="mb-3 row">
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Ubicación" name="ubicacion" type="text" holder="" onChange={(event) => { setFieldValue("ubicacion", event.target.value); }} />
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField id="ubicacionEntrega" label="Ubicación entrega" name="ubicacionEntrega" items={dataUbicacionEntrega}
                                                   onChange={(event) => {
                                                      if (event.value == "") {
                                                         setFieldValue("ubicacionEntrega", "");
                                                         setUbicacionEntrega(0);
                                                      }
                                                      else {
                                                         setUbicacionEntrega(event.value);
                                                         setFieldValue("ubicacionEntrega", event.label);
                                                      }
                                                   }} />
                                             </div>
                                          </div>
                                       </div>
                                       <div className='my-2 mt-4'>
                                          <div className="mb-3 row">
                                             <div className="col-sm-12 col-md-6 col-lg-6">
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="4" name="comentarios" type="text" holder="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className='my-2 mt-4'>
                                          <div className="mb-3 row">
                                             <div className="col-sm-12 col-md-6 col-lg-3">
                                                <Field className="form-check-input" type="checkbox" name="registroConcluido" onClick={(event) => { setFieldValue("registroConcluido", event.target.value); }} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                             </div>
                                             <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik >
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         )}
         {
            isOpenModal &&
            <ModalCostosResguardoUnidad
               isOpen={isOpenModal}
               setIsOpen={setIsOpenModal}
               item={selectedItem}
               addUpdateCostosAdicionalesTbl={addUpdateCostosAdicionalesTbl}
               toast={toast}
            />
         }
         {
            openModalDelete &&
            <ModalConfirmarEliminar
               isOpen={openModalDelete}
               setIsOpen={setOpenModalDelete}
               item={itemSelected}
               toast={toast}
               deleteCostosAdicionalesTbl={deleteCostosAdicionalesTbl}
            />
         }
      </>
   )
});

export default ResguardoUnidadCards