import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../../../Components/Modal/Modal';
import { TextField } from '../../../../../Components/TextField';
import SeguroSiniestrosService from '../../../../../Services/Seguros/SeguroSiniestrosService';
import Spinner from '../../../../../Components/Loadig';
import Catalogo from "../../../../../Services/Catalogos/Catalogo";
import Parametros from '../../../../../Services/Parametros/Parametros';

const ModalCostos = ({ isOpen, setIsOpen, item, handleGetAll, toast, SiniestroResguardoUnidadId }) => {
    const refbtn = useRef();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        siniestroResguardoUnidad_CostoAdicionalId: item?.siniestroResguardoUnidad_CostoAdicionalId ?? "",
        siniestroResguardoUnidadId: SiniestroResguardoUnidadId ?? "",
        concepto: item?.concepto ?? "",
        subTotal: item?.subTotal ?? "",
        iva: item?.iva ?? "",
        total: item?.total ?? "",
    };

    const [initialValue, setInitialValue] = useState(initialValues);

    const validationSchema = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .min(3, 'Mínimo 3 caracteres.')
            .matches(
                /^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,\s]+$/,
                "Información inválida, solo letras, números y caracteres especiales."
            ),
        subTotal: Yup.number()
            .required("El campo es requerido.")
            .min(0, "Debe ser un valor positivo."),
        iva: Yup.number()
            .required("El campo es requerido.")
            .min(1, "Debe ser mayor o igual a 1.")
            .max(99, "Debe ser menor o igual a 99."),
        total: Yup.number()
            .required("El campo es requerido."),
    });

    const handleAddEdit = async (values) => {
        setLoading(true);
        try {
            if (!values.siniestroResguardoUnidad_CostoAdicionalId) {
                const res = await SeguroSiniestrosService.crearResguardoCostosSiniestro(values);
                toast.success(res.data.mensaje || "¡Costo registrado correctamente!");
            } else {
                const res = await SeguroSiniestrosService.actualizarResguardoCostosSiniestro(values);
                toast.success(res.data.mensaje || "¡Costo actualizado correctamente!");
            }
            handleGetAll();
            setIsOpen(false);
        } catch (error) {
            toast.error(error.message || "Error al procesar la solicitud.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal isOpen={loading} color="transparent"><Spinner message="" /></Modal>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                //onSubmit={handleAddEdit}
                enableReinitialize={true}
            >
                {({ isValid, values, setFieldValue }) => (
                    <Modal isOpen={isOpen} color="#fff">
                        <section className="d-flex justify-content-between mx-4">
                            <section className="py-2 d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                    <span><strong>Registrar costo</strong></span>
                                </div>
                                <span className="mt-2 fs-6">
                                    Completa todos los campos para el registro del costo.
                                </span>
                            </section>
                        </section>
                        <section className="my-4 mx-4" style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField
                                            label="Subtotal"
                                            name="subTotal"
                                            type="number"
                                            holder="Escribe"
                                            onChange={(event) => {
                                                setFieldValue("subTotal", event.target.value);
                                                let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                setFieldValue("total", total);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField
                                            label="IVA (%)"
                                            name="iva"
                                            type="number"
                                            holder="Escribe"
                                            onChange={(event) => {
                                                setFieldValue("iva", event.target.value);
                                                let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                                setFieldValue("total", total);
                                                //setFieldValue("total", total.toFixed(2));
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Total" name="total" type="text" disabled holder="Escribe" />
                                    </div>
                                </div>
                                <section className="my-3">
                                    <button className="btn btn-outline" type="reset" onClick={() => setIsOpen(false)}>
                                        Cancelar
                                    </button>
                                    <button
                                        ref={refbtn}
                                        className="btn mx-2"
                                        type="button"
                                        disabled={!isValid}
                                        onClick={() => { handleAddEdit(values) }}
                                    >
                                        {values.siniestroResguardoUnidad_CostoAdicionalId ? "Editar" : "Guardar"}
                                    </button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )}
            </Formik>
        </>
    );
};

export default ModalCostos;
