import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    idGarantiaReparacion: "",
}

export const GarantiaReparacionSlice = createSlice({
    name: 'GarantiaReparacionSlice',
    initialState,
    reducers: {
        setIdGarantiaReparacion: (state, action) => {
            return {...state,
                idGarantiaReparacion: action.payload.data,
            }
        }
    }
})

export const { setIdGarantiaReparacion } = GarantiaReparacionSlice.actions
export default GarantiaReparacionSlice.reducer