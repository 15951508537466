import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSlicePagoDesembolso = createSlice({
  name: 'PagoDesembolsoNavigate',
  initialState,
  reducers: {
    tabActivePagoDesembolso: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActivePagoDesembolso } = navigaSlicePagoDesembolso.actions

export default navigaSlicePagoDesembolso.reducer