import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { Section } from '../../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { tabSupervisionCargaMenu } from '../../../Redux/Slice/Cobranza/NavigateSupervisionCargaSlice';

const SubMenuSupervisionDeCarga = () => {
  const { value } = useSelector((state) => state.tabSupervisionCargaMenu);
  //const { cheked, item } = useSelector((state) => state.quickActionsSlice);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleNavigate(value);
  }, [value])


//   path: "/cobranza/cobradores",
//   element: <Cobradores />,

   const handleSetStateTab = (value) => {
      dispatch(tabSupervisionCargaMenu(value));
   }
   const handleNavigate = (value) => {
   //  if (ValidatePermissionById(3, Section.) && value === 1) {
      if(value === 1){
         navigate(`/cobranza/supervisioncarganiveluno`);
      }
   //  if (ValidatePermissionById(3, Section.) && value === 2) {
      if(value === 2){
      navigate(`/cobranza/supervisioncarganiveldos`);
      }
   }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* <ValidatePermission isActive={ValidatePermissionById(3, Section.VentaDeContado)}> */}
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 1 && "tab-active"}`} style={{width: "200px"}} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(1) }}>Nivel 1</button>
          </li>
        {/* </ValidatePermission> */}
        {/* <ValidatePermission isActive={ValidatePermissionById(3, Section.SeguimientoDeVentaDeContado)}> */}
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`}  style={{width: "200px"}} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(2) }}>Nivel 2</button>
          </li>
        {/* </ValidatePermission> */}
      </ul>
    </>
  )
}

export default SubMenuSupervisionDeCarga  