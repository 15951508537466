import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useRef } from 'react';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { SelectField } from '../../Components/SelectField';
import { TextField } from '../../Components/TextField';

const CondicionComponent = ({ item, initialDet, index, handleClick, handleValue }) => {    
    let initial = {
        tipoCondicionId: "",
        condicionalId: "",
        catEstatusId: "",
        plazoId: "",
        tiempo: ""
    }
    const dataTipo = [
        { value: 1, label: "Documentos" },
        { value: 2, label: "Campos" },
    ];
    const validate = Yup.object({
        tipoCondicionId: Yup.string()
            .required("El campo es requerido."),
        condicionalId: Yup.string()
            .required("El campo es requerido."),
        catEstatusId: Yup.string()
            .required("El campo es requerido."),
    });

    // const dataStatus = [
    //     { value: "Capturado", label: "Capturado" },
    //     { value: "NoCapturado", label: "No capturado" },
    //     { value: "Vigencia", label: "Vigencia" },
    // ];
    const dataPlazo = [
        { label: "Minutos", value: 1 },
        { label: "Dias", value: 2 },
        { label: "Semanas", value: 3 },
        { label: "Mes", value: 4 }
    ];

    const [initialValue, setInitialValue] = useState(initial)
    const [dataDocumento, setDataDocumento] = useState([]);
    const [dataCondiccion, setDataCondiccion] = useState([]);
    const [dataStatus, setDataStatus] = useState([]);
    const [typeValue, setTypeValue] = useState("");
    useEffect(() => {
        handleGetAllTipo();
        handleGetAllStatus();
    }, [])
    useEffect(() => {
        handleChangeTipo();
    }, [typeValue, item.etapaOrigenId])

    useEffect(() => {
        setTypeValue(initialDet?.tipoCondicionId);
        handleSetInitalState();
    }, [initialDet])

    const handleSetInitalState = () => {
        let initial = {
            tipoCondicionId: initialDet?.tipoCondicionId ?? "",
            condicionalId: initialDet?.condicionalId ?? "",
            catEstatusId: initialDet?.catEstatusId ?? "",
            plazoId: initialDet?.plazoId ?? "",
            tiempo: initialDet?.tiempo ?? ""
        }
        setInitialValue(initial);
    }
    const handleGetAllTipo = async () => {
        await EtapaService.getAllTipoCondicion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCondicionId,
                        label: item.nombre
                    })
                });
                setDataCondiccion(NewData);
            }).catch(e => {
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllStatus = async () => {
        await EtapaService.getAllStatus()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.catEstatusId,
                        label: item.estatus
                    })
                });
                setDataStatus(NewData);
            }).catch(e => {
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const sendValue = (values, tipo, val) => {
        // valuesss(values);        
        if (tipo === 1)
            handleValue({ ...values, tipoCondicionId: val });
        if (tipo === 2)
            handleValue({ ...values, condicionalId: val });
        if (tipo === 3)
            handleValue({ ...values, catEstatusId: val });
        if (tipo === 4)
            handleValue({ ...values, plazoId: val });
        if (tipo === 5)
            handleValue({ ...values, tiempo: val });


    }
    const handleChangeTipo = async (value) => {
        if (item.etapaActualId !== "") {
            await EtapaService.getAllCondicionalById(typeValue, item.etapaOrigenId, item.productoId, item.tipoCompraId)
                .then(res => {
                    let NewData = [];
                    res.data.data?.map(item => {
                        NewData.push({
                            value: item.condicionalId,
                            label: item.nombre
                        })
                    });
                    setDataDocumento(NewData);
                }).catch(e => {
                    // if (e.response !== undefined)
                    //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    // else
                    //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        // else if (value == 2)
        //     setDataDocumento(dataCondicionalTipo2);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
        // onSubmit={(values) => {
        //     // handleAddEdit(values);        
        // }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <div className='col-12 row align-items-top'>
                        <div className="col-sm-4 mb-2">
                            <SelectField className="col-12" label="Tipo:" name={`tipoCondicionId`} items={dataCondiccion} onChange={(event) => {
                                setFieldValue(`tipoCondicionId`, event.value);
                                setTypeValue(event.value);
                                sendValue(values, 1, event.value);
                            }} />
                        </div>
                        <div className="col-sm-4 mb-2">

                            <SelectField className="col-12" label="Selección condicional:" name={`condicionalId`} items={dataDocumento} onChange={(event) => {
                                setFieldValue(`condicionalId`, event.value);
                                sendValue(values, 2, event.value);
                            }} />
                        </div>
                        <div className="col-sm-4 mb-2">
                            <SelectField className="col-12" label="Estatus:" name={`catEstatusId`} items={dataStatus} onChange={(event) => {
                                setFieldValue(`catEstatusId`, event.value);
                                sendValue(values, 3, event.value);
                            }} />
                        </div>
                        <div className="col-sm-4 mb-2">
                            <SelectField className="col-12" label="Plazo:" name={`plazoId`} items={dataPlazo} onChange={(event) => {
                                setFieldValue(`plazoId`, event.value);
                                sendValue(values, 4, event.value);
                            }} />
                        </div>
                        <div className="col-sm-4 mb-2">
                            <TextField label=" " name="tiempo" type="text" holder="Escribe" onChange={(event) => {
                                setFieldValue(`tiempo`, event.target.value);
                                sendValue(values, 5, event.target.value);
                            }} />
                        </div>
                        {
                            index > 0 &&
                            <div style={{ position: 'relative', right: '-99px', top: `${Object.keys(errors).length > 0 ? '-78px' : '-55px'}` }}>
                                <span style={{ position: 'absolute', right: '70px', width: '40px', backgroundColor: 'transparent', color: 'gray' }} className="btn col-1 d-flex justify-content-center" onClick={handleClick} ><i className="bi bi-trash"></i></span>
                            </div>
                        }
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default CondicionComponent