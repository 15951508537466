import React, { useEffect, useState } from 'react'
import CalendarReact from '../../Components/React-Calendar/ReactCalendar';
import Modal from '../../Components/Modal/Modal';
import ConfiguracionDiasHabiles from '../../Components/Modal/ConfiguracionDiasHabiles';
import useDiasFestivos from '../../Hooks/DiasFestivos/useDiasFestivos';
import Loadig from '../../Components/Loadig';
import ConfirmarDiasFestivosModal from './Modal/ConfirmarDiasFestivosModal';

const DiasFestivos = () => {
    const {
        loading,
        modalMessage,
        setModalMessage,
        isOpenModalDiasHabiles,
        selectedDates,
        setSelectedDates,
        year,
        parametroYear,
        actualDay,
        decrementYear,
        incrementYear,
        handleEdit,
        calendarActive,
        setCalendarActive,
        onChange,
        fechas,
        formatWeekDate,
        tileClassName,
        toggleDate,
        changeCalendarActive,
        handleCancel,
        establecerDiasNoHabiles,
        modalConfirm,
        setModalConfirm,
        handleActualizarDiasFestivos
    } = useDiasFestivos()


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Loadig message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    isOpenModalDiasHabiles &&
                    <ConfiguracionDiasHabiles
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                        year={year}
                        parametroYear={parametroYear}
                        establecerDiasNoHabiles={establecerDiasNoHabiles}
                    />
                }
            </Modal>
            <div>
                <section className='mx-3 py-4 d-flex flex-column border-bottom border-2 border-secondary'>
                    <div className='d-flex justify-content-between'>
                        <div className='dias-festivos-titulo-container'>
                            <span className='fs-5'><strong>Calendario día festivos</strong></span>

                            <button
                                className='dias-festivos-button-hoy'
                                onClick={actualDay}
                            >Hoy</button>

                            {/* <span> &lt; </span> */}
                            <span className='icon' onClick={decrementYear}><i className='bx bx-md bx-chevron-left' ></i></span>
                            <span className='icon' onClick={incrementYear}><i className='bx bx-md bx-chevron-right' ></i> </span>
                            <span > {year} </span>
                        </div>
                        <div className='dias-festivos-titulo-container'>
                            {calendarActive ?
                                <span className='dias-festivos-button-cog'
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Configuración de días"
                                    onClick={handleEdit} >
                                    <i className=' glyphicon bx bx-cog bx-md'></i>
                                </span>
                                : null}
                            {calendarActive ?
                                <div className="col-auto px-3">
                                    <button id="AT_BtnCloseModal"
                                        className='btn btn-outline d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={handleCancel}>
                                        Cancelar</button>
                                </div>

                                : <></>}
                            {/* <button className='dias-festivos-button btn px-4' onClick={confirmarCambiosModal}> */}
                            <button className='dias-festivos-button btn px-4' onClick={changeCalendarActive}>
                                {calendarActive ? `Guardar` : `Editar`}</button>
                        </div>
                    </div>
                </section>
                <div className='dias-festivos-grid'>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Enero"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 0, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                            year={year}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Febrero"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 1, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Marzo"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 2, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Abril"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 3, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Mayo"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 4, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Junio"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 5, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Julio"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 6, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Agosto"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 7, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Septiembre"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 8, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Octubre"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 9, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Noviembre"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 10, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                    <div className='   p-3 m-1'>
                        <CalendarReact
                            title={"Diciembre"}
                            onChange={onChange}
                            value={fechas}
                            formatDate={formatWeekDate}
                            fechaInicial={new Date(year, 11, 1)}
                            tileClassName={tileClassName}
                            toggleDate={toggleDate}
                        />
                    </div>
                </div>
            </div>
            {
                modalConfirm &&
                <ConfirmarDiasFestivosModal
                    isOpen={modalConfirm}
                    setIsOpen={setModalConfirm}
                    handleConfirm={handleActualizarDiasFestivos}
                />
            }
        </>
    )
}

export default DiasFestivos