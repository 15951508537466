import imagen from '../../Assets/img/LogotipoArrendadoraAzul.png';
import Footer from '../../Components/Footer';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { Link, useNavigate,useParams } from 'react-router-dom'

import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';

import Usuario from '../../Services/Users/Usuarios';



const ChangePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    let { id } = useParams();
    const validateForm = Yup.object({
        password: Yup.string()
        .required("El campo es requerido.")
        .trim("El texto no debe empezar ni terminar con espacios")
        .strict(true)
        .max(8, 'La contaseña no puede tener mar de 8 caracteres'),
        passwordConfirmar: Yup.string()
        .required("El campo es requerido.")
        .trim("El texto no debe empezar ni terminar con espacios")
        .strict(true)
        .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
        .max(8, 'La contaseña no puede tener mar de 8 caracteres'),
    });
    const onSubmit = async (values)=>{
        let data = {
            UserId: id,
            Password:values.password
        }
        
        setLoading(true);
        await Usuario.userResetPassword(data)
            .then(res => {
                
                setLoading(false);
                if(res.data.data.ban == 0){
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje})
                }else{
                    
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "La contraseña se ha modificado exitosamente."})
                    setTimeout(() => {
                        // Código a ejecutar después del tiempo especificado
                        navigate("/")
                      }, 3000);
                   

                }
    
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const { values, errors, handleChange,touched, handleConfirmChange, handleSubmit  } = useFormik({
        initialValues:{
            password:"",
            passwordConfirmar:"",
        },
        validationSchema:validateForm,
        onSubmit
    }) 
    

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                                {
                                    modalMessage.type === 1 &&
                                    <ErrorModal
                                        modalMessage={modalMessage}
                                        setModalMessage={setModalMessage}
                                    />
                                }
                                {
                                    modalMessage.type === 2 &&
                                    <SuccessModal
                                        modalMessage={modalMessage}
                                        setModalMessage={setModalMessage}
                                    />
                                }

            </Modal>
            <div className="contenedor">            
                    <div className="col-12 col-sm-8 col-md-6 col-xl-3 container-loginpassword">
                        <figure className="logo-container">
                            <img  className="logo" src={imagen} style={{width:'15em'}} alt="logo" />
                        </figure>
                        <div className="">
                            <h5 className="text-left fs-6">NUEVA CONTRASEÑA</h5>      
                            <h3 className="text-left fs-6">Agregar contraseña nueva.</h3>                       
                            <br/>
                            <form className="mt-6" onSubmit={handleSubmit} >
                            <div>
                                    <div className="form-group">  
                                    <label className="form-label">Contraseña</label>    
                                        <input type="password" 
                                        placeholder="Escribe aquí" 
                                        name="password" id="password" 
                                        onChange={handleChange} 
                                        className="form-control mb-6 {errors.password ? 'input-error':''}" />    
                                        </div>
                                        {errors.password && touched.password && <p className='error'>{errors.password}</p>}

                                </div>  
                                <br/>
                                <div>
                                    <div className="form-group">  
                                    <label className="form-label">Confirmar contraseña</label>    
                                        <input type="password" 
                                        placeholder="Escribe aquí" 
                                        name="passwordConfirmar" id="passwordConfirmar" 
                                        onChange={handleChange} 
                                        className="form-control mb-6 {errors.passwordConfirmar ? 'input-error':''}" />    
                                    </div>
                                    {errors.passwordConfirmar && touched.passwordConfirmar && <p className='error'>{errors.passwordConfirmar}</p>}

                                </div>  
                                <br/>
                                <div className='d-grid gap-2 col-12 mx-auto'>
                                    <button type="submit" className="btn"> Confirmar </button>
                                </div>                                                                                                      
                                   
                               
                            </form>                            
                        </div>
                    </div>                               
                </div>
    
                    <Footer
                    cls={'fixed'}
                    />  
        </>
      )
}
export default ChangePassword