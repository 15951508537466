import { useEffect, useRef, useState } from "react";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";

const DSAsignacionesHook = ()  => {
    const refFormik = useRef();
    const selectRef = useRef();
    let initial = {
        buscar: '',
        tecnicoId: '',
        tipoSolicitudId: '',
        tipoUbicacion: '',
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [tecnicoId, setTecnicoId] = useState('');
    const [tipoSolicitud, setTipoSolicitud] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [tipoSolicitudes, setTipoSolicitudes] = useState([]);

    const columns = [
        { field: 'folioId', headerName: 'Folio', widht: '150px' },
        { field: 'tecnico', headerName: 'Técnico', widht: '150px' },
        { field: 'cantidadServicios', headerName: 'Cantidad de servicios', widht: '150px' },
        { field: 'fechaCita', headerName: 'Fecha de servicio', widht: '150px' }
    ];

    const tipoUbicaciones = [
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' }
    ];

    useEffect(() => {
        getTecnicos();
        getTipoSolicitudes();
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar]);

    const getTecnicos = async () => {
        await DSAsignaciones.getTecnicos()
        .then(resp => {
            let items = resp.data.map(item => {
                return {
                    value: item.userId,
                    label: item.tecnico
                }
            });

            setTecnicos(items);
        })
        .catch(err => {

        })
    }

    const getTipoSolicitudes = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoSolicitud')
        .then(resp => {
            let items = resp.data.map(item => {
                return {
                    value: item.TipoSolicitudId,
                    label: item.TipoSolicitud
                }
            });
            setTipoSolicitudes(items); 
        })
        .catch(err => {
            console.error(err);
        });
    }

    const getListado = async () => {
        setLoading(true);
        const params = { 
            buscar, 
            tecnicoId: '', 
            tipoServicioId: '', 
            tipoUbicacion: '', 
            concluido: false, 
            fechaInicio: '', 
            fechaFin: '', 
            page, 
            rows
        };
        
        await DSAsignaciones.getSeguimientoServiciosAsignados(params)
        .then(resp => {
            let items = [];

            resp.data.data.forEach(item => {
                item.fechaHoraCita = item.fechaCita ? `${getFormatDate(item.fechaCita)}` : ' - ';


                let detalles = item.detalle.map(subItem => {
                    subItem.fechaHoraCita = subItem.fechaCita ? `${getFormatDateTime(subItem.fechaCita, subItem.horaCita)}` : ' - ';
                    return subItem;
                });
                item.detalle = detalles;
                items.push(item);
            });

            console.log(resp.data.data);
            console.log(items);
            setData(items);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getFormatDate = (fecha) => {
        if(fecha === undefined || fecha === null) return false;
        return fecha.split("T")[0].split("-").reverse().join("-");
    }

    const getFormatDateTime = (fecha, hora) => {
        if(fecha === undefined || fecha === null) return false;
        return fecha.split("T")[0].split("-").reverse().join("-") + " " + hora;
    }

    
    const handleAsignar = () => {
        setModalMessage({ isOpen: true, title: 'Asignar servicios', type: 1 });
    }

    return {
        refFormik,
        selectRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setTipoSolicitud,
        setTipoUbicacion,
        setTecnicoId,
        tipoSolicitudes,
        tipoUbicaciones,
        getListado,
        handleAsignar,
        tecnicos
    }
}

export default DSAsignacionesHook;