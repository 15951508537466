import React from 'react'
import img from '../../Assets/img/uploadGestoria.png'

const GestoriaHomeGestoria = () => {
  return (
    <>
      <section className='px-4'>
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh' }}>
          <div className='row'>
            <div className='text-center'>
              <img src={img} style={{ width: '20rem' }} className='img-fluid' />
            </div>            
            <div className='fs-6 text-center'>A continuación importa el parque vehicular para cargar <br /> los vehículos en el listado.</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default GestoriaHomeGestoria