import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {  Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Cierres from '../../../Services/Resguardos/Cierres';
import DataTable from '../../../Components/datatable/DataTable';
import AutorizacionDatamovilCard from './AutorizacionDatamovilCard';
import Garantias from '../../../Services/Cartera/Garantias';
import documentsHelper from '../../../Utils/documents';
import CambioPropietarioCard from './CambioPropietarioCard';
import CitaExpedienteCard from './CitaExpedienteCard';

const ExpedienteCard
    = React.forwardRef(({ ...props }, ref) => {
        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();

        let initial = {
            cambioPropietario: "",
            cierreContratoExpedienteId: "",
            cierreContratoId: "",
            cierreContratoExpedienteStatusId: 0,
            cierreContratoExpedienteStatus: "",
            color: "",
            documentoDescargado: null,
            expedienteCompletoEntrega: null,
            expedienteCompletoTramites: null,
            ampliacion: null,
            tiempoAmpliacion: null,
            concluido: null,
            fechaConclusion: null,
            usuarioAlta: "",
            usuarioActualiza: null,
            usuarioAltaNombre: "",
            usuarioActualizaNombre: null,
            status: false,
            fechaAlta: "",
            fechaActualiza: null,
            fechaRealizoEntrega: null,
            movimientos: []
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        const [historial, setHistorial] = useState({
            totalRows: 0,
            pageSize: 0,
            currentPage: 1,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            data: [
                // { fechaMovimiento: '23-02-2024', usuario: 'César Ortiz', expediente: 'Sí', estatus: 'Recuperación', comentarios: 'La reparación se realizó éxitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente' },
                // { fechaMovimiento: '24-02-2024', usuario: 'Agustin Villanueva', expediente: '-', estatus: 'Cambio de propietario pendiente', comentarios: 'Se realiazará un cambio de propietario al vehículo' },
                // { fechaMovimiento: '05-03-2024', usuario: 'Andrés de León', expediente: 'No', estatus: 'Recuperación', comentarios: 'La reparación se realizó éxitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente' },
                // { fechaMovimiento: '24-04-2024', usuario: 'Jose Carlos Guerrero Karass ♥', expediente: '-', estatus: 'Cambio de propietario pendiente', comentarios: 'Se realiazará un cambio de propietario al vehículo' },
            ]
        });

        
        const columns = [
            { field: 'fechaMovimiento', headerName: 'Fecha y hora de movimiento'},
            { field: 'usuario', headerName: 'Usuario'},
            { field: 'estatus', headerName: 'Estatus' },
            { field: 'expediente', headerName: 'Expediente completo'},
        ];


        useEffect(() => {
            getExpedientes();    
        }, []);

        /* CONSULTAS */
        const getExpedientes = async () => {
            await Cierres.cierreContratoExpedienteGet(cierreid)
            .then(resp => {
                resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                if(resp.data.movimientos.length > 0){
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */


        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2)+" hrs.";
                if(msj !== undefined && msj !== null) return msj + format;
                else return format;
            }
        }

        const solicitarExpediente = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoExpedienteStatusId: 20,
                entregado: true,
                generalId: generalid
            };


            // Se envia correo tipo 44
            await Cierres.cierreContatoExpedienteActualizar(params)
            .then(async (resp) => {
                if(resp.data.movimientos.length > 0){
                    resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                    resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                


                const contenido = {
                    VARIABLE_CONTRATO : ""
                }
    
                await Garantias.creardatosenviar({
                    modulo: 'Solicitud de expediente',
                    tipoCorreoId: 44,
                    contenido: JSON.stringify(contenido)
                })
                .then(resp => {
                    toast.success(message("Se envió el correo de solicitud de expediente correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar enviar el correo de solicitud de expediente!"));
                    console.log("Error al enviar correo Revisión de Unidad Aprobada:", err);
                });

                setInitialValue(resp.data);
                toast.success(message("Se solicitó el expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar solicitar el expediente!"));
            });

        }

        const resguardarExpediente = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoExpedienteStatusId: 100,
                generalId: generalid
            };


            // Se envia correo tipo 50
            await Cierres.cierreContatoExpedienteActualizar(params)
            .then(async (resp) => {
                if(resp.data.movimientos.length > 0){
                    resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                    resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                


                const contenido = {
                    VARIABLE_VIN: vin,
                    VARIABLE_CONTRATO : numContrato
                }
    
                await Garantias.creardatosenviar({
                    modulo: 'Solicitud de expediente en resguardo',
                    tipoCorreoId: 50,
                    contenido: JSON.stringify(contenido)
                })
                .then(resp => {
                    toast.success(message("Se envió el correo de Solicitud de expediente en resguardo correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar enviar el correo de devolución de expediente pendiente!"));
                    console.log("Error al enviar correo Revisión de Unidad Aprobada:", err);
                });

                setInitialValue(resp.data);
                toast.success(message("Se solicitó el expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar solicitar el expediente!"));
            });
        }

        const AmpliarTiempoExpediente = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoExpedienteStatusId: 80,
                ampliacion: true,
                generalId: generalid
            };


            // Se envia correo tipo 44
            await Cierres.cierreContatoExpedienteActualizar(params)
            .then(resp => {
                if(resp.data.movimientos.length > 0){
                    resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                    resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                setInitialValue(resp.data);
                toast.success(message("Se solicitó el expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar solicitar el expediente!"));
            });
        }

        const submitForm = async (values) => {
            if(!values.expedienteCompletoEntrega){
                values.cierreContratoExpedienteStatusId = 40;
            }else{
                values.validarPropiedad = true;
                values.vin = id;
            }

            values.generalId = generalid;
            
            
            if(initialValue.cierreContratoExpedienteStatusId === 40 && values.expedienteCompletoTramites){
                values.validarPropiedad = true;
                values.vin = id;
            }
            

            // console.log(values);
            // return false;
            await Cierres.cierreContatoExpedienteActualizar(values)
            .then(resp => {
                if(resp.data.movimientos.length > 0){
                    resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                    resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                setInitialValue(resp.data);
                toast.success(message("Se solicitó el expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar solicitar el expediente!"));
            });
        }

        const downloadContratoPDF = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoExpedienteStatusId: 30,
                documentoDescargado: true,
                generalId: generalid
            }

            const today = new Date();
            const fecha = today.getFullYear()+"-"+("0"+today.getMonth()+1).slice(-2)+"-"+("0"+today.getDate()).slice(-2);
            setTimeout(() => {
                const requestBody = {
                    nombrePlantilla: "CartaEntregaPapeleria",
                    parametros: {
                        "<<VARIABLE_FECHA>>": fecha,
                        "<<VARIABLE_CLIENTE>>": cliente,
                        "<<VARIABLE_MARCA>>": nombreMarca,
                        "<<VARIABLE_TIPO>>": "",
                        "<<VARIABLE_MODELO>>": nombreModelo,
                        "<<VARIABLE_SERIE>>": "",
                        "<<VARIABLE_MOTOR>>": numMotor,
                        "<<VARIABLE_TELEFONO>>": "",
                        "<<VARIABLE_DIRECCION_UBICACION>>": "",
                    }
                };

                Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                    //Pendiente de revisar por que no jala con el file-server
                    documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                }).catch(err => {
                    console.error(err);
                });
            }, 500);

            await Cierres.cierreContatoExpedienteActualizar(params)
            .then(resp => {
                if(resp.data.movimientos.length > 0){
                    resp.data.expedienteCompletoEntrega = resp.data.expedienteCompletoEntrega === null ? null : (resp.data.expedienteCompletoEntrega ? 1 : 0);
                    resp.data.expedienteCompletoTramites = resp.data.expedienteCompletoTramites === null ? null : (resp.data.expedienteCompletoTramites ? 1 : 0);
                    let movimientos = [];
                    resp.data.movimientos.forEach(movimiento => {
                        movimientos.push({ 
                            fechaMovimiento: getDateFormat(movimiento.fechaAlta, null),
                            usuario: movimiento.usuarioAltaNombre, 
                            expediente: movimiento.expedienteCompleto === null ? "-" : (movimiento.expedienteCompleto ? 'Sí' : 'No'), 
                            estatus: movimiento.cierreContratoExpedienteStatus, 
                        });
                    });

                    setHistorial({
                        ...historial,
                        data: movimientos
                    });
                }
                setInitialValue(resp.data);
                toast.success(message("Se actualizó el expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar el expediente!"));
            });
        }
        
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseExpediente" role="button" aria-expanded="false" aria-controls="collapseExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Expediente</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small>{initialValue.cierreContratoExpedienteStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseExpediente">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar documento</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    (initialValue.cierreContratoExpedienteStatusId === 10 ||
                                                                    initialValue.cierreContratoExpedienteStatusId === 100) &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={false}
                                                                            onClick={() => solicitarExpediente()}>
                                                                            Solicitar
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    initialValue.cierreContratoExpedienteStatusId === 90 &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={false}
                                                                            onClick={() => resguardarExpediente()}>
                                                                            Resguardo
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    initialValue.cierreContratoExpedienteStatusId === 70 &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={false}
                                                                            onClick={() => AmpliarTiempoExpediente()}>
                                                                            Ampliar tiempo
                                                                        </button>
                                                                    </div>
                                                                }
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray'>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!initialValue.documentoDescargado || initialValue.cierreContratoExpedienteStatusId !== 30 && initialValue.cierreContratoExpedienteStatusId !== 40}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-auto">
                                                            <small>Checklist recepción de expediente</small>
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <small><strong>Confirmación de entrega de expediente</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="expedienteCompletoEntrega"
                                                                label="Expediente completo en entrega"
                                                                disabled={!initialValue.documentoDescargado || initialValue.cierreContratoExpedienteStatusId !== 30}
                                                                name="expedienteCompletoEntrega"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("expedienteCompletoEntrega", event.value);
                                                            }} />
                                                        </div>
                                                        {
                                                            (
                                                                initialValue.expedienteCompletoEntrega !== null &&
                                                                !initialValue.expedienteCompletoEntrega
                                                            ) &&
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="expedienteCompletoTramites"
                                                                    label="Expediente completo por trámites"
                                                                    disabled={!initialValue.documentoDescargado || initialValue.cierreContratoExpedienteStatusId !== 40}
                                                                    name="expedienteCompletoTramites"
                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("expedienteCompletoTramites", event.value);
                                                                }} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <small><strong>Registro de movimientos</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <DataTable
                                                                column={columns}
                                                                data={historial.data}
                                                                editable={false}
                                                                // pageCount={0}
                                                                // currentPage={1 - 1}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* <AutorizacionDatamovilCard /> */}
                {
                    initialValue.cambioPropietario &&
                    <CambioPropietarioCard />
                }
                {
                    (initialValue.cierreContratoExpedienteStatusId === 110  ||
                    (initialValue.cierreContratoExpedienteStatusId > 60 &&
                    initialValue.cierreContratoExpedienteStatusId < 90)) &&
                    
                    <CitaExpedienteCard getExpedientes={getExpedientes} />
                }
            </>
        )
    })
export default ExpedienteCard