import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { chekItem, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';
import AuthLogin from '../Utils/AuthLogin';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();
const ProcesosSidebar = () => {
    // const dispatch = useDispatch();
    // const {cheked,item,isOpenModalClave} = useSelector((state) => state.quickActionsSlice);      
    const { visible } = useSelector((state) => state.changeMenu);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }

    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }
    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div id="lbl_Inventario_Sidebar" className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Procesos de cierre"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Gestión cierre de contrato</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/resguardos" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-lock"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento resguardo</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/seguimientocierre" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-list-check bx-sm"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento de cierre</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/datamovil" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-remote-control-fill"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento datamovil</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/valuaciones" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-remote-control-fill"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento valuaciones</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Gestión de remate</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/unidades-en-remate" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-roadster-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Unidades en Remate</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Archivo</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/expedientes" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-folder-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"} text-sm`}>Seguimiento de expedientes</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Gestión de solicitudes</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/solicitudes" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-file-list-2-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"} text-sm`}>Autorización de solicitudes</span>
                        </NavLink >
                    </li>
                    {/* <li className="nav-heading"><strong>Gestión de citas</strong></li>
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/autorizar`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Citas por autorizar</span>
                        </NavLink >
                    </li>    
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/agenda`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-check-line"></i>
                            <span>Agenda de citas</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/inspeccionrapida`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Seguimiento de garantías</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/devoluciones`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-refresh-fill"></i>
                            <span>Cambio o devolución</span>
                        </NavLink >
                    </li> */}
                </ul>
            </aside>
        </>
    )
}

export default ProcesosSidebar