import React, { useEffect, useState } from 'react';
import './HalfCircleChart.css';

const HalfCircleChart = ({ percentage, title }) => {
    const [value, setValue] = useState(0)

    const setGaugeValue = (value) => {
        if (value < 0 || value > 1) {
            return
        }

        // Set the transform and text content based on the value
        const gaugeFill = document.querySelector(".semidonut_fill")
        const gaugeCover = document.querySelector(".semidonut_cover")
        const gaugeSubCover = document.querySelector(".semidonut_sub_cover")
        
        if (gaugeFill && gaugeCover) {
            gaugeFill.style.transform = `rotate(${value / 2}turn)`
            gaugeCover.textContent = `${Math.round(value * 100)}%`
            gaugeSubCover.textContent = `${title}`
        }
    }

    useEffect(() => {
        setGaugeValue(value)
    }, [value])

    useEffect(() => {
        setValue(percentage)
    }, [percentage])
    return (
        <>
            <div className='semidonut'>
                <div className='semidonut_body'>
                    <div className="semidonut_fill"></div>
                    <div className="semidonut_sub_cover"></div>
                    <div className="semidonut_cover"></div>
                </div>
            </div>
        </>
    );
}

export default HalfCircleChart;