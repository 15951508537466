import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import Datamovil from '../../../Services/Resguardos/Datamovil';

const Prealta
    = React.forwardRef(({ ...props }, ref) => {
        const { generalId, diagnostico } = props
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { vin, destino } = useParams();


        
        let {
            tipoOperacion,
            motivoCambioDevolucionOptions,
            getCambioDevolucion
        } = useDevolucion()

        let initial = {
            vin: vin,
            claveMarca: "",
            claveModelo: "",
            claveYear: "",
            claveVersion: "",
            color: "",
            km: "",
            numPlaca: "",
            externoId: "",
        }


        const navigate = useNavigate();
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [marcas, setMarcas] = useState([]);
        const [modelos, setModelos] = useState([]);
        const [years, setYears] = useState([]);
        const [versiones, setVersiones] = useState([]);


        useEffect(() => {
            getMarcas();
        }, []);



        const getMarcas = async () => {
            setModelos([]);
            setYears([]);
            setVersiones([]);

            setInitialValue({
                ...initialValue,
                claveModelo: "",
                claveYear: "",
                claveVersion: "",
            });

            await Catalogo.getMarcas()
            .then(resp => {
                let items = [];
                resp.data.data.forEach(item => {
                    items.push({
                        value: item.claveMarca,
                        label: item.nombreMarca
                    });
                });
                setMarcas(items);
            })
            .catch(err => {
                console.error(err);
            })
        }

        const getModelos = async (value) => {
            setYears([]);
            setVersiones([]);

            setInitialValue({
                ...initialValue,
                claveYear: "",
                claveVersion: "",
            });
            setLoading(true);
            await Catalogo.getModelos(value)
            .then(resp => {
                let items = [];
                resp.data.data.forEach(item => {
                    items.push({
                        value: item.claveModelo,
                        label: item.nombreModelo
                    });
                });
                setModelos(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        };

        const getAnio = async (values, modelo) => {
            setVersiones([]);

            setInitialValue({
                ...initialValue,
                claveVersion: "",
            });
            let params = {
                claveMarca: values.claveMarca,
                claveModelo: modelo
            }
            setLoading(true);
            await Catalogo.getYear(params)
            .then(resp => {
                let items = [];
                resp.data.data.forEach(item => {
                    items.push({
                        value: item.claveYear,
                        label: item.nombreYear
                    });
                });
                setYears(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        };

        const getVersion = async (values, anio) => {
            let params = {
                claveMarca: values.claveMarca,
                claveModelo: values.claveModelo,
                claveYear: anio
            }
            
            setLoading(true);
            await Catalogo.getVersion(params)
            .then(resp => {
                let items = [];
                resp.data.data.forEach(item => {
                    items.push({
                        value: item.claveVersion,
                        label: item.nombreVersion
                    });
                });
                setVersiones(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const submitForm = async (values) => {
            if(values.numPlaca.trim().length === 0) {
                toast.error(message("¡Error, faltan algunos datos por ingresar!"));
                return false;
            }
            setLoading(true);
            await Cierres.crearPrealta(values)
            .then(resp => {
                toast.success(message("Registro creado correctamente"));
                nextStep({destino, vin: values.vin, generalId: resp.data.generalId});
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear la alta del vehículo!"));
            })
            .finally(() => setLoading(false));
        }

        const nextStep = (data) => {
            if(data.destino === 'resguardos'){
                crearResguardo(data.vin, data.generalId);
            }else if(data.destino === 'datamovil'){
                crearDatamovil(data.generalId);
            }
        }

        const crearResguardo = async (vin, generalId) => {
            let params = {
                generalId: generalId,
                resguardoProcesoId: 70
            };

            await Cierres.crearResguardo(params)
            .then(resp => {
                const resguardo = resp.data.resguardoId;
                setLoading(false);
                crearUbicacionUnidad(resguardo, vin, generalId);
                toast.success(message("Se creó el resguardo del vehículo correctamente"));
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear el resguardo del vehículo!"));
            })
            .finally(() => setLoading(false));
        }

        const crearUbicacionUnidad = async (id, vin, generalId) => {
            let params = {
                resguardoId: id,
                resguardoUbicacionUnidadStatusId: 10
            };
            await Cierres.resguardoUbicacionUnidadCrear(params)
            .then(resp => {
                toast.success(message("Se creó la ubicación del vehículo correctamente"));
                setTimeout(() => {
                    navigate(`/procesocierre/${destino}/${vin}/${generalId}/${id}`);
                }, 1000);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la ubicación del vehículo!"));
            });
        }

        const crearDatamovil = async (generalId) => {
            let params = {
                generalId: generalId,
                procesoId: 10,
                autorizada: false,
                concluida: false
            }

            await Datamovil.datamovilCrear(params)
            .then(resp => {
                toast.success(message("Se creó la desinstalación datamovil correctamente"));
                crearOrden(resp.data.datamovilId, resp.data.vin, generalId);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la desinstalación datamovil!"));
            });
        }

        const crearOrden = async (datamovilId, vin, generalId) => {
            let params = {
                datamovilId: datamovilId,
                datamovilOrdenDesinstalacionStatusId: 20,
                tipoOrden: true,
                status: true
            }

            await Datamovil.datamovilOrdenDesinstalacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó la orden de desinstalación datamovil correctamente"));
                setTimeout(() => {
                    navigate(`/procesocierre/${destino}/${vin}/${generalId}/${datamovilId}`);
                }, 1000);
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear la orden de desinstalación datamovil!"));
            })
        }
        
        const onlyNumbers = (field, value, fn) => {
            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                fn(field, value);
            }
        }


        // const validate = Yup.object({});
        
        const validate = Yup.object({
            externoId: Yup.string()
                .required("El campo es necesario"),
            color: Yup.string()
                .required("El campo es requerido"),
            km: Yup.string()
                .required("El campo es requerido"),
            numPlaca: Yup.string()
                .required("El campo es requerido"),
            claveVersion: Yup.string()
                .required("El campo es requerido"),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                <NavLink to={`/procesocierre/resguardos`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al buscador</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {
                    
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto', minHeight: 'Calc(100vh - 80px)' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4">
                                        <div className="col-11">
                                            <h6><strong>Prealta básica</strong></h6>
                                        </div>
                                    </div>
                                    <div id="collapseDevolucion">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid = false, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Registro de prealta</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!isValid}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="vin"  label="VIN" disabled={true} name="vin" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("vin", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="externoId"  label="ID Externo" name="externoId" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("externoId", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="claveMarca"
                                                                label="Marca"
                                                                name="claveMarca"
                                                                items={marcas}
                                                                onChange={(event) => {
                                                                    setFieldValue("claveMarca", event.value);
                                                                    getModelos(event.value);
                                                            }} />
                                                        </div>
                                                        { initialValue.claveModelo }
                                                        <div className="col-3">
                                                            <SelectField id="claveModelo"
                                                                label="Modelo"
                                                                name="claveModelo"
                                                                items={modelos}
                                                                onChange={(event) => {
                                                                    setFieldValue("claveModelo", event.value);
                                                                    getAnio(values, event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="claveYear"
                                                                label="Año"
                                                                name="claveYear"
                                                                items={years}
                                                                onChange={(event) => {
                                                                    setFieldValue("claveYear", event.value);
                                                                    getVersion(values, event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="claveVersion"
                                                                    label="Versión"
                                                                    name="claveVersion"
                                                                    items={versiones}
                                                                    onChange={(event) => {
                                                                        setFieldValue("claveVersion", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="color"  label="Color" name="color" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("color", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="km"  label="Kilometraje" name="km" type="text" holder="Escribe" onChange={(event) => {
                                                                onlyNumbers("km", event.target.value, setFieldValue);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="numPlaca"  label="Placas" name="numPlaca" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("numPlaca", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default Prealta