import React from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select';
import SubMenuSupervisionDeCarga from './SubMenuSupervisionDeCarga';
import useSupervisionCargaNivel1 from '../../../Hooks/Cobranza/useSupervisionCargaNivel1';
import BusquedaAvanzadaModal from './BusquedaAvanzadaModal';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { toast } from 'react-hot-toast';
import { message } from '../../../Utils/functions'

const SupervisionDeCargaNivel1 = () =>
{
  let {
    columns,
    columnsDetail,
    currentData,
    dataCobradores,
    setCobradorSeleccionado,
    setStr,
    str,
    loading,
    handleDetails,
    distribucionDeCarga,
    dataFiltro,
    setDataFiltro,
    openModalAvanzado,
    setOpenModalAvanzado,
    handleDownloadFile,
    pageCount,
    handlePageClick,
    page,
    limpiarFiltros,
    selectRefStatus
  } = useSupervisionCargaNivel1();

  const handlerDistribucion = () =>
  {
    distribucionDeCarga().then(resp =>
    {
      toast.success(message("¡Operación exitosa!", "Distribución de carga completada"));
    }).catch(error =>
    {
      toast.error(message("¡Operación fallida!", "Error al procesar"));
      console.log(error);
    })
  }

  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <SubMenuSupervisionDeCarga />
      <section className='mx-5 py-4'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Supervisión de carga</strong></span>
          <div>
            <button id="AT_btnDistribucion" className='btn mx-2' type="button" onClick={() => { handlerDistribucion(); }} >Distribución manual de cartera</button>
            <button id="AT_btnExportar" className='btn mx-2' type="button" onClick={() => { handleDownloadFile() }} >Exportar Excel</button>
          </div>
        </div>
      </section>
      <section className='mt-3 mx-4'>
        <div className='col-lg-4 col-sm-12 d-flex'>
          <div className="col-6 mx-1  inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input type="text" value={str} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
          </div>
          <div className='col-6 d-flex'>
            <Select ref={(el) => (selectRefStatus.current.select1 = el)} options={dataCobradores} className='col-12 mx-1' onChange={(e) => { setCobradorSeleccionado(e.label) }} placeholder="Seleccionar" />
            {/* <button id="AT_btnBusquedaAvanzada" className='btn mx-2 col-8' type="button" onClick={() => {setOpenModalAvanzado(true)}} >Búsqueda Avanzada</button>
            <button id="AT_btnLimpiarFiltros" className='btn mx-2 col-8' type="button" onClick={() => {limpiarFiltros()}} >Limpiar Filtros</button> */}
          </div>
        </div>
      </section>
      {/* <ValidatePermission
        isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
      > */}
      <div className='mx-4 table-responsive' >
        <DataTable
          column={columns}
          data={currentData}
          masterDetail={true}
          detailable={true}
          handleDetail={handleDetails}
          columnDetail={columnsDetail}
          extrainfopromedio={true}
          paginate={true}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={page - 1}
        />
      </div>
      {/* </ValidatePermission> */}
      {
        openModalAvanzado &&
        <BusquedaAvanzadaModal
            isOpenModal={openModalAvanzado}
            setIsOpenModal={setOpenModalAvanzado}
            handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
        />
      }
    </>
  );
}

export default SupervisionDeCargaNivel1;