import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useAgendarCita from '../../../../Hooks/DatamovilSystems/RevisionPasos/useAgendarCita';

const CardInicioServicio = ({ handleConcluido }) => {
    const refFormik = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object({
        // vehiculoDesignado: Yup.string().required("El campo es requerido"),
        // comentarios: Yup.string().when('vehiculoDesignado', {
        //     is: 'No',
        //     then: Yup.string().required("Los comentarios son requeridos")
        // }),
        // tipoUbicacion: Yup.string().required("El tipo de ubicación es requerido"),
        // ubicacion: Yup.string().required("La ubicación es requerida"),
        // fechaCita: Yup.date().required("La fecha de cita es requerida"),
        // horaCita: Yup.string().required("La hora de cita es requerida"),
    });
    const paso = 2
    const {
        initialValue,
        loading,
        setLoading,
        submitFormUbicacionVehiculo,
        opcionesBool,
        tipoUbicaciones
    } = useAgendarCita({ handleConcluido, paso });

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseUbicacion"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseUbicacion"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><strong>Ubicación de Vehículo</strong></h6>
                                <div>
                                    {/* <span>En proceso</span> */}
                                </div>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseUbicacion">
                            <div className="divider"></div>
                            {/* <span className='my-4'>
                                <b>Detalle de ubicación</b>
                            </span> */}
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitFormUbicacionVehiculo(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="agendarCitaForm">
                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicación</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluirRegistroInicioServicio ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                                    <i className="icon-dark bx bx-hide mx-1" ></i>
                                                                    Modo lectura
                                                                </fieldset>
                                                                : <button
                                                                    className='btn'

                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Select para la ubicación del vehículo */}
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <SelectField
                                                    id="vehiculoEnUbicacion"
                                                    label="¿Vehículo estaba en la ubicación designada?"
                                                    name="vehiculoEnUbicacion"
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("vehiculoEnUbicacion", event.value)}
                                                />
                                            </div>
                                        </div>

                                        {/* Mostrar estos campos solo si vehiculoDesignado es "No" */}
                                        {values.vehiculoEnUbicacion === false && (
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-md-8">
                                                        <TextField
                                                            label="Comentarios"
                                                            name="comentariosInicioServicio"
                                                            type="text"
                                                            holder=''
                                                            placeholder="Escribe"
                                                            onChange={(event) => setFieldValue("comentariosInicioServicio", event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <h6><strong>Tipo de Ubicación</strong></h6>
                                                    <div className="col-md-4">
                                                        <SelectField
                                                            label="Tipo de Ubicación"
                                                            name="tipoUbicacion"
                                                            id="tipoUbicacion"
                                                            items={tipoUbicaciones}
                                                            onChange={(event) => setFieldValue("tipoUbicacion", event.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <TextField
                                                            label="Ubicación"
                                                            name="ubicacion"
                                                            holder=''
                                                            type="text"
                                                            onChange={(event) => setFieldValue("ubicacion", event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <h6><strong>Fecha para Reagendar cita</strong></h6>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="Fecha de cita"
                                                            name="fechaCita"
                                                            type="date"
                                                            onChange={(event) => setFieldValue("fechaCita", event.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button className="btn mt-4" type="button">
                                                            Ver calendario
                                                        </button>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField
                                                            label="Hora de cita"
                                                            name="horaCita"
                                                            type="time"
                                                            onChange={(event) => setFieldValue("horaCita", event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="row mb-3">
                                            <div className="col-md-3 d-flex align-items-center">
                                                {/* ConcluirRegistroInicioServicio */}
                                                <Field
                                                    type="checkbox"
                                                    name="concluirRegistroInicioServicio"
                                                    className="form-check-input me-2"
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    onClick={(event) => {
                                                        setFieldValue("concluirRegistroInicioServicio", event.target.value);
                                                    }} />
                                                <label className="form-check-label">Concluir registro</label>
                                            </div>


                                        </div>

                                        <div className="text-end">
                                            <small className="text-muted">Inicio de proceso: {initialValue.fechaAlta} | Fin de proceso: {initialValue.fechaConcluido}</small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardInicioServicio;
