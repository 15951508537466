import axios from '../../../Utils/ApiService';

const ComisionesEmpresaService = {
    getAllPendientesDePagoComisionEmpresa: async (page, rows, string,fechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllComisionDeEmpresa?page=${page}&Rows=${rows}&FechaInicio=${fechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    },  
    ExportPendientesDePagoComisionEmpresa: async (data) => {
        return await axios.get(`/functions/ExportarPendientesPagoComisionEmpresa?Ids=${data.ids}&Folio=${data.folio}&TotalVehiculos=${data.totalVehiculos}&TotalAPagar=${data.totalAPagar}`, { responseType: 'blob' });
    },
    getAllProcesoPagoComisionEmpresa: async (page,rows,FechaInicio,FechaFin,str) => {
        return await axios.get(`/functions/GetAllProcesoPagoComisionEmpresa?page=${page}&Rows=${rows}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`);
    },
    SaveProcesoPagoComisionEmpresa:async(data)=>{
        return axios.post('/functions/SaveProcesoDePagoComisionEmpresa',data);
    },
    getAllComisionesEmpresaPagada: async (page, rows, string,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllComisionEmpresaPagada?page=${page}&Rows=${rows}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    }, 
    ExportComisionesEmpresaPagada: async (str,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/ExportComisionEmpresaPagada?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`,{ responseType: 'blob' });
    }, 
    ReemprimirPendientesDePagoComisionEmpresa: async (folio) => {
        return await axios.get(`/functions/ReExportarPendientesPagoComisionEmpresa?Folio=${folio}`, { responseType: 'blob' });
    },
}

export default ComisionesEmpresaService