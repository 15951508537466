import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import ResguardoUnidadService from '../../Services/Inventario/ResguardoUnidadService';
import { toast } from 'react-hot-toast'
// import {  message } from '../../../../Utils/functions'
import { message } from '../../Utils/functions'
import { FormatFecha } from '../../Utils/functions'

const useResguardoUnidad = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'proceso', headerName: 'Proceso', width: 150 },
        { field: 'ultima_ubicacion', headerName: 'Ubicación', width: 250 },
        { field: 'fecha_resguardo', headerName: 'Fecha de Resguardo', width: 150 },
        { field: 'marca', headerName: 'Marca', width: 150 },
        { field: 'modelo', headerName: 'Modelo', width: 150 },
        { field: 'anio', headerName: 'Año', width: 150 },
    ];

    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState(data);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [loading, setLoading] = useState(false);
    //Filtro
    const [opcionesProceso, setOpcionesProceso] = useState([]);
    const [claveProceso, setClaveProceso] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [opcionesUbicacion, setOpcionesUbicacion] = useState([])
    const [claveUbicacion, setClaveUbicacion] = useState("");
    const [textoBusqueda, setTextoBusqueda] = useState("")

    useEffect(() => {
        habdleGetAllResguardoUnidad();
    }, []);

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(x => {
            return (textoBusqueda === '' || (x.vin && x.vin.toLowerCase().includes(textoBusqueda.toLowerCase()))) &&
                (claveProceso === 'Todos' || x.proceso.toLowerCase().includes(claveProceso.toLowerCase())) &&
                (claveUbicacion === 'Todos' || x.ultima_ubicacion.toLowerCase().includes(claveUbicacion.toLowerCase()));
        });

        const indexOfLastItem = currenPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)

        if (currenPage > newTotalPages) {
            setCurrenPage(newTotalPages)
        } else if (currenPage <= 0) {
            setCurrenPage(1)
        }

        // Solo actualiza currentPage si es necesario para evitar bucles infinitos
        // if (currenPage > newTotalPages && currenPage !== newTotalPages) {
        //     setCurrenPage(newTotalPages);
        // } else if (currenPage <= 0 && currenPage !== 1) {
        //     setCurrenPage(1);
        // }
    // }, [currenPage, pageRows, textoBusqueda, claveProceso, claveUbicacion])
    }, [ currenPage, pageRows, textoBusqueda, claveProceso, claveUbicacion])

    const habdleGetAllResguardoUnidad = async () => {
        setLoading(true);

        let data = {
            "verb": "string",
            "id": "resguardounidad",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        };

        await ResguardoUnidadService.getAllResguardoUnidad(data)
            .then(res => {

                let datosFormateados = res.data.responseData?.map((item) => {
                    const fecha_resguardo = FormatFecha(item.fecha_resguardo)
                    return {
                        ...item,
                        fecha_resguardo: fecha_resguardo
                    }
                });

                const opcionesProceso = [...new Map(datosFormateados.map((x, index) => [`${x.proceso}`, { value: index + 1, label: x.proceso }])).values()];
                const opcionesProcesoConTodo = [{ value: "", label: "Todos" }, ...opcionesProceso];
                setOpcionesProceso(opcionesProcesoConTodo);

                const opcionesUbicacion = [...new Map(datosFormateados.map((x, index) => [`${x.ultima_ubicacion}`, { value: index + 1, label: x.ultima_ubicacion }])).values()];
                const opcionesUbicacionConTodo = [{ value: "", label: "Todos" }, ...opcionesUbicacion];
                setOpcionesUbicacion(opcionesUbicacionConTodo);

                setData(datosFormateados);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                toast.error(message("¡Error!", "Error al obtener el listado resguardo de unidad"));
            })
    }

    const handlePageClick = (event) => {
        // setCurrenPage(event.selected + 1);
        // habdleGetAll(event.selected + 1);
    };

    const handleDetails = (value) => {
        navigate(`/inventario/resguardo-unidad/detalles`, {
            state: value
        })
    };

    return {
        columns,
        currentData,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        setpageRows,
        opcionesProceso,
        setClaveProceso,
        opcionesUbicacion,
        setClaveUbicacion,
        handleDetails,
        setTextoBusqueda,
    }
}

export default useResguardoUnidad