import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSlicePoliza = createSlice({
  name: 'polizaNavigate',
  initialState,
  reducers: {
    tabActivePoliza: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActivePoliza } = navigaSlicePoliza.actions

export default navigaSlicePoliza.reducer