import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const ReglaNegocio = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        reglaNegocioId: item?.reglaNegocioId ?? 0,
        regla: item?.regla ?? "",
        productoId: item?.productoId ?? 0,
        tipoCompraId: item?.tipoCompraId ?? 0,
        etapaActualId: item?.etapaActual ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    useEffect(() => {
        handleGetAllList()
    }, [])



    const validate = Yup.object({
        regla: Yup.string()
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        productoId: Yup.string()
            .required("El campo es requerido."),
        tipocompraId: Yup.string()
            .required("El campo es requerido."),
        etapaActualId: Yup.string()
            .required("El campo es requerido.")
    });
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("Producto", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.productoId,
                        name: item.producto
                    })
                });
                setData1(NewData);
            }).catch(e => {
                
            })
        await Catalogo.getdataTabla("TipoCompra", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.tipoCompraId,
                        name: item.tipoCompra
                    })
                });
                setData(NewData);
            }).catch(e => {
                
            })
        await Catalogo.getdataTabla("Etapa", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.tipoCompraId,
                        name: item.tipoCompra
                    })
                });
                setData2(NewData);
            }).catch(e => {
                
            })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addReglaNegocio(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateReglaNegocio(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3 row">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <TextField id="Regla" label="Regla" name="regla" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField id="TipoCompra" label="Tipo Compra" name="tipoCompraId" items={data} />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField id="Producto" label="Producto" name="productoId" items={data1} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <SelectField id="EtapaActual" label="Etapa Actual" name="etapaActualId" items={data2} />
                                    </div>
                                </div>

                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default ReglaNegocio