import { Formik, Form } from 'formik'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Cobranza from '../../../Services/Cobranza/Cobranza';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import DataTable from '../../../Components/datatable/DataTable';
import ReasignarColoniasDeZonaModal from './ReasignarColoniasDeZonaModal';
import useBusquedaCodigoPostal from '../../../Hooks/Cobranza/useBusquedaCodigoPostal';
import useAreasCobranza from '../../../Hooks/Cobranza/useAreasCobranza';
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField';
import { TextField } from '../../../Components/TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../Utils/functions';
import { v4 as uuidv4 } from 'uuid';
// import CobranzaService from '../../Services/Cobranza/Cobranza';
import CobranzaService from '../../../Services/Cobranza/Cobranza';

const ConsultarCodigoPostal = ({ isOpen, setIsOpen, item, setDataUpdated, estadoSeleccionado, datosZonas }) => {
    const refbtn = useRef();
    const dispatch = useDispatch();
    // Configurar los valores iniciales usando el objeto item
    let initial = {
        areaactual: item?.areaactual || '',
        idEstado: item?.item.estado || '',
        idMunicipio: item?.item.municipio || '',
        zona: item?.item.zona || '',
        item: item
    };
    const [initialValue, setInitialValue] = useState(initial);
    const {
        columns,
        dataEstado,
        dataMunicipio,
        defaultValueMunicipio,
        handleGetMunucipios,
        handleChangeMunicipio,
        handleChangeMunicipio2,
        handleChangeStatus,
        currentdataZonas,
        openModal,
        setOpenModal,
        zonaValue,
        loading,
        setLoading,
        sinZonasSeleccionadas,
        setSinZonasSeleccionadas,
        setzonaValue,
        todasZonas,
        coloniaAsignada,
        msjColoniaAsignada,
        setColoniaAsignada,
        setMsjColoniaAsignada,
        setTextoBusqueda,
        setDataMunicipio
    } = useBusquedaCodigoPostal();

    const [todasZonasSeleccionadas, setTodasZonasSeleccionadas] = useState(datosZonas.flatMap(item =>
        item.zonaItems.map(zonaItem => ({
            ...zonaItem,
            zona: item.zona
        }))
    ));

    const [nombreestado, setnombreestado] = useState(initial.idEstado);

    const handleCloseModal = async () => {
        setIsOpen(false);
        setDataUpdated(true);
        setLoading(false);
    }

    const itemReasignar = {
        nombreEstado: nombreestado,
        nombreMunicipio: defaultValueMunicipio.label,
        // zonas: zonas != 0 ? todasZonas.filter(x => x.label != zonaValue) : todasZonas,
        zonas: todasZonas.filter(x => x.label != zonaValue),
        colonias: currentdataZonas.filter(x => x.status),
        zonaOrigen: zonaValue,
        todasZonas: datosZonas
    };

    useEffect(() => {
        if (initial.idEstado) {
            handleGetMunucipios(initial.idEstado);
        }
    }, [initial.idEstado]); // Cambiar si el valor del estado cambia

    useEffect(() => {
        if (initial.idMunicipio) {
            handleChangeMunicipio({ value: initial.idEstado, label: initial.idMunicipio }, todasZonasSeleccionadas);
            // setzonaValue(initial.zona);
        }
    }, [initial.idMunicipio]);

    const validate = Yup.object({
        idEstado: Yup.string().required("El campo es requerido."),
        idMunicipio: Yup.string().required("El campo es requerido."),
        // zona: Yup.string().required("El campo es requerido.")
    });


    const handleGetAll = async (nombreestado) => {
        if (nombreestado != '') {
            setLoading(true);
            await CobranzaService.getGetZonaReport()
                .then(res => {
                    let NewData = [];
                    NewData = res.data.responseData.flatMap(item =>
                        item.zonaItems
                            .filter(zonaItem => zonaItem.estado === nombreestado) // Filtra por estado
                            .map(zonaItem => ({
                                ...zonaItem,
                                zona: item.zona
                            }))
                    );
                    setTodasZonasSeleccionadas(NewData);
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                })
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
        // onSubmit={() => {
        //     handleAdd();
        // }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-2 mx-4'>
                                <Form>
                                    <div className="mb-3 row" style={{ marginTop: 20 }}>
                                        <div className="col-sm-12">
                                            <span className='fs-4' style={{ fontWeight: 'bold', color: "#000" }}><strong>Búsqueda por código postal</strong></span>
                                        </div>
                                    </div>

                                    <div className='mb-3 row mt-5'>
                                        <div className="col-sm-6">
                                            {estadoSeleccionado && (
                                                <>
                                                    Estado
                                                    {/* <Select options={dataEstado} isDisabled={true} className='col-12' value={dataEstado.find(e => e.label === values.idEstado)} onChange={(e) => { handleGetMunucipios(e.label); setFieldValue('idEstado', e.value) }} placeholder="Seleccionar" /> */}
                                                    <Select options={dataEstado} className='col-12' value={dataEstado.find(e => e.label === values.idEstado)} onChange={(e) => { handleGetMunucipios(e.label); setFieldValue('idEstado', e.value) }} placeholder="Seleccionar" />
                                                </>
                                            )}
                                            {!estadoSeleccionado && (
                                                <SelectField label="Estado" name="idEstado" items={dataEstado}
                                                    onChange={(event) => {
                                                        if (event.value == "") {
                                                            setFieldValue("idEstado", "");
                                                            setnombreestado("");
                                                            setDataMunicipio([]);
                                                        }
                                                        else {
                                                            handleGetMunucipios(event.label);
                                                            setFieldValue("idEstado", event.label);
                                                            setnombreestado(event.label);
                                                            handleGetAll(event.label);
                                                        }
                                                    }} />
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            Municipio
                                            <Select options={dataMunicipio} value={dataMunicipio.find(e => e.label === values.idMunicipio)} className='col-12' onChange={(e) => {
                                                handleChangeMunicipio({ value: nombreestado, label: e.label }, todasZonasSeleccionadas); setFieldValue('idMunicipio', e.value)
                                            }} placeholder="Seleccionar" />
                                        </div>
                                    </div>
                                    <div className="mb-3 row mt-4">
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon fas fa-search"></i>
                                            <input type="text" className="form-control mb-4" placeholder="Buscar por colonia o código postal" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                                        </div>
                                    </div>
                                    <section className='my-2'>
                                        {sinZonasSeleccionadas &&
                                            <span className="error">Se debe seleccionar al menos 1 colonia.</span>
                                        }
                                        {coloniaAsignada &&
                                            <span className="error">{msjColoniaAsignada}</span>
                                        }

                                        <div className='mx-4 table-responsive' style={{ height: '20rem', overflowY: "scroll" }}>
                                            <DataTable
                                                column={columns}
                                                data={currentdataZonas}
                                            // isStatus={true}
                                            // handleChange={handleChangeStatus}
                                            />
                                        </div>
                                    </section>
                                    <section className='my-4 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn mx-2 col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
                                        {/* <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit">Cerrar</button> */}
                                    </section>
                                </Form>
                            </section>
                        </Modal>
                        {loading && <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>}
                        {openModal && (
                            <ReasignarColoniasDeZonaModal isOpen={openModal} setIsOpen={setOpenModal} item={itemReasignar} handleCloseModal={handleCloseModal} />
                        )}
                    </>
                )
            }
        </Formik>
    )
}

export default ConsultarCodigoPostal