import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { SelectField } from '../../../Components/SelectField';
import * as XLSX from "xlsx";
import { useEffect } from 'react';
import { Formik } from 'formik'
import { TextField } from '../../../Components/TextField';
import PagoUnidad from '../../../Services/PagoUnidad/PagoUnidad';
import PagoUnidadesSeguimiento from './PagoUnidadesSeguimiento';
import RegistrosPagoUnidadModal from '../../../Components/Modal/RegistrosPagoUnidadModal';
import { TextAreaField } from '../../../Components/TextAreaField';
import DataTable from '../../../Components/datatable/DataTable';

const SeguimientoProcesoPagados = () => {
    const refFormik = useRef();
    const fileRef = useRef();
    const [IsFilePicked, setIsFilePicked] = useState();
    const [SelectedFile, setSelectedFile] = useState("");
    const [JsonData, setJsonData] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    let initial = {
        buscar: "",
        tipoCompraId: null,
        RazonSocial: null,
        fechaInicio: "",
        fechaFin: "",
        comentarios: "",
        page: 1,
        rows: 10
    }

    const [currenPage, setCurrenPage] = useState(1);
    const [collpsableSeguro, setCollpsableSeguro] = useState(false);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'vin', headerName: 'VIN'},
        { field: 'contrato', headerName: 'Contrato'},
        { field: 'folioId', headerName: 'Folio'},
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega'},
        { field: 'strFechaConfirmaPago', headerName: 'Fecha de pago'},
        { field: 'razonSocial', headerName: 'Razón Social'},
        { field: 'nombreMarca', headerName: 'Marca'},
        { field: 'nombreModelo', headerName: 'Modelo'},
        { field: 'nombreYear', headerName: 'Año'},
        { field: 'nombreVersion', headerName: 'Versión'},
        { field: 'strValorUnidad', headerName: 'Valor de la unidad'},
        { field: 'usuarioPago', headerName: 'Usuario pagó'},
    ];

    const [tipoCompra, setTipoCompra] = useState([]);
    const [razonSocial, setRazonSocial] = useState([]);
    const [unidadesSeleccionadas, setUnidadesSeleccionadas] = useState(0);
    const [montoTotal, setMontoTotal] = useState('No hay selección');
    const [vehiculosReporte, setVehiculosReporte] = useState([]);


    useEffect(() => {
        getTipoCompras()
    }, []);


    const changeHandler = () => {
        fileRef.current.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        );
    };

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        handleConvert(event.target.files[0])
    }

    const handleConvert = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(json);                  
            };
            reader.readAsBinaryString(file);
        }
    };

    const showNextModal = (values) => {
        setModalMessage({ isOpen: true, type: 4, data: JsonData, title: "Registros por procesar", comentarios:  values.comentarios });
    }

    const getTipoCompras = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoCompra')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                if(item.TipoCompra.toLowerCase() !== 'inventario'){
                    items.push({
                        value: item.TipoCompraId,
                        label: item.TipoCompra
                    })
                }
            });

            setTipoCompra(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const getRazonesSociales = async (values) => {
        let TipoCompraId = values;
        await PagoUnidad.pagoUnidadRazonSocialGet(TipoCompraId)
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.razonSocial,
                    label: item.razonSocial
                })
            });
            setRazonSocial(items);
        })
        .catch(err => {
            console.error(err);
        })
    };

    const getSeguimientoUnidadesProcesoPagado = async (values = initialValue, page = 1) => {
        values.page = page;
        setLoading(true);
        

        if(values.RazonSocial === '')
        {
            values.RazonSocial = null;
        }
        await PagoUnidad.getSeguimientoUnidadesProcesoPagado(values)
        .then(resp => {
            let items = [];
            resp.data.data.forEach(item => {
                item.strFechaEntrega = dateFormat(item.fechaEntrega);
                item.strFechaConfirmaPago = dateFormat(item.fechaConfirmoPago);
                item.strValorUnidad = formatMoney(item.valorUnidad);
                items.push(item);
            });

            resp.data.data = items;
            setData(resp.data);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear();
        return formattedDate;
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        getSeguimientoUnidadesProcesoPagado(refFormik.current.values, page);
    };


    
    const downloadExcel = async (values = initialValue, page = 1) => {
        setLoading(true);
        values.page = page;
        await PagoUnidad.ExportarUnidadesPagadasReporte(values)
        .then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `UnidadesConProcesoPagado-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        })
        .catch(err => {
            console.error(err)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={modalMessage.type < 4 ? '600' : '980'}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        <PagoUnidadesSeguimiento />
                        <div className='pb-4'>
                            <section className='mx-4 my-3 d-flex flex-column'>
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Vehículos pagado</strong>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <button className='btn' onClick={(event) => {
                                                        downloadExcel(values);
                                                    }}>
                                                        Exportar Excel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <small className='mt-1'>El siguiente listado corresponde a la gestión de los vehículos en proceso de pago. Para visualizar los registros filtra por tipo de compra y/o razón social</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <SelectField id={'tipoCompraId'}
                                                name={'tipoCompraId'}
                                                items={tipoCompra}                                                                                            
                                                onChange={(event) => {
                                                    setFieldValue('tipoCompraId', event.value);
                                                    setFieldValue('RazonSocial', '');
                                                    getRazonesSociales(event.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <SelectField id={'RazonSocial'}
                                                name={'RazonSocial'}
                                                items={razonSocial}                                                                                            
                                                onChange={(event) => {
                                                    setFieldValue('RazonSocial', event.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-auto mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => getSeguimientoUnidadesProcesoPagado(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data?.data}
                                            pageCount={data?.totalPages}
                                            currentPage={currenPage - 1}
                                            handlePageClick={handlePageClick}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoProcesoPagados;
