import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import { TextAreaField } from '../../Components/TextAreaField';
import DataTable from '../../Components/datatable/DataTable';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import Spinner from '../../Components/Loadig';

const VehiculoUtilitarioModal = ({ isOpen, setIsOpen, item, handleGetAll, message, toast }) => {

    const [valor, setValor] = useState("");
    let initial = {
        nombreEmpleado: item?.usuarioUtilitarioId ?? "",
        comentarios: item?.comentariosUtilitario ?? "",
        generalId: item?.generalId,
        type:item?.type ?? 2
    }
    const columns = [
        { field: 'fechaHora', headerName: 'Fecha Inicio', width: 400 },
        { field: 'fechaFin', headerName: 'Fecha Fin', width: 400 },
        { field: 'nombreEmpleado', headerName: 'Nombre del empleado', width: 300 },
        { field: 'comentarios', headerName: 'Cometarios', width: 340 },
    ];
    const [initialValue, setInitialValue] = useState(initial);
    const [IsOpenModal, setIsOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState([]);
    const [dataUsuario, setDataUsuario] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(item?.type == 4 ? true : false);

    const validate = Yup.object({
        nombreEmpleado: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
    });

    useEffect(() => {
        handleGetAllUsuarios();
    }, [])
    
    const handleGetAllUsuarios = async () =>{
        setLoading(true);
        await VehiculoService.getAllUsarios()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    // if (item.status) {
                        NewData.push({
                            value: item.userId,
                            label: item.nombreUsuario
                        })
                    // }
                });
                setDataUsuario(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);            
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handleAddEdit = async (values) => {
        setLoading(true);
        await VehiculoService.addVehiculoUtilitario(values)
            .then(res => {
                setLoading(false);
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })

    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VehiculoService.getAllHistoryVehiculoUtilitario(item.generalId, page, 10)
            .then(res => {
                
                setDataModal(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);                
            }}
        >
            {({ isValid, errors, values,setFieldValue}) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <div className='d-flex'>
                                    <span className='fs-5'><strong>Asignación de vehiculo utilitario</strong></span>
                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                    {
                                        values.type == 4 &&
                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2' style={{ width: '40px' }} onClick={() => { setIsDisabled(current=>!current);}} ><i className='bx bxs-pencil'></i></fieldset>
                                    }                                    
                                </div>
                                <span style={{ fontSize: '12px' }}>A continuación se debe capturar la persona asignada al vehículo: <strong>{item.nombreMarca} {item.nombreModelo}</strong></span>
                            </div>

                            <Form>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-sm-12">
                                        <TextField disabled={isDisabled} label="Nombre del empleado:" name="nombreEmpleado" type="text" holder="Escribe" />
                                        {/* <SelectField isDisabled={isDisabled} label="Empleado:" name="nombreEmpleado" items={dataUsuario} onChange={(event) => {
                                            setFieldValue("nombreEmpleado", event.value);
                                        }} /> */}
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-12">
                                        <TextAreaField disabled={isDisabled} label="Comentario:" className="form-control col-12" rows="2" name="comentarios" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={IsOpenModal} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '50rem' }}>
                            <div className='d-flex justify-content-center fs-6'>
                                <span className='fs-5'><strong>Historial Utilitario</strong></span>

                            </div>

                            <DataTable
                                column={columns}
                                data={dataModal}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end'>
                                <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                                {/* <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button> */}
                            </section>

                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                </>
            )
            }
        </Formik>
    )
}

export default VehiculoUtilitarioModal