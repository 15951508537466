import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSlicedataDocs = createSlice({
  name: 'dataDocsNavigate',
  initialState,
  reducers: {
    tabActiveDataDocs: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveDataDocs } = navigaSlicedataDocs.actions

export default navigaSlicedataDocs.reducer