import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { FormatNumberMoney2 } from '../../../../Utils/functions'
import DataTable from '../../../../Components/datatable/DataTable'
import Spinner from '../../../../Components/Loadig'
import Modal from '../../../../Components/Modal/Modal'
import RowPages from '../../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../../Auth/Authorization'
import SubMenuComisionesInventario from '../SubMenuComisionesInventario'
import HeaderCobroComisionExterno from './HeaderCobroComisionExterno'
import TotalPPCobroComisionExternoModal from './TotalPPCobroComisionExternoModal'
import useCobroComisionesExternoPendientePago from '../../../../Hooks/Inventario/Comisiones/useCobroComisionesExternoPendientePago'
const CobroComisionExternoPendientePago = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar,
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,
        razonSocial,
        handleGetCSRazonSocial,   
        handleChangeRazonSocial,
        setopenModalRetencionPago,
        openModalRetencionPago   
    } = useCobroComisionesExternoPendientePago(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuComisionesInventario />
            <HeaderCobroComisionExterno/>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Cobro de comisiones pendientes</strong></span>
                    <div>
                        <span className='px-2' style={IdsExportar.length > 0 ? { fontSize: '13px' } : { color: 'red', fontSize: '13px' }}><strong>Unidades Seleccionadas:</strong> {IdsExportar.length > 0 ? IdsExportar.length : 0}</span>
                        <span className='px-2' style={IdsExportar.length > 0 ? { fontSize: '13px' } : { color: 'red', fontSize: '13px' }}><strong>Monto total por cobrar:</strong> {IdsExportar.length > 0 ? `$${FormatNumberMoney2(handleCostos())}` : "no hay seleccion"}</span>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesPendientesDePago, SubModule.ComisionesInventario).Descarga}>
                            <button className='btn' onClick={handleDownloadExcel}>Generar reporte</button>
                        </ValidatePermission>
                    </div>
                </div>
                <span className='mt-2'>El módulo corresponde al cálculo del pago de comisiones a los intermediarios y proveedores externos.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesPendientesDePago, SubModule.ComisionesInventario).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Tipo de compra</label> */}
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value);handleGetCSRazonSocial(e.value) }} placeholder="Tipo compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Razon social</label> */}
                            <Select ref={selectRef} options={razonSocial} className='col-12' onChange={(e) => {handleChangeRazonSocial(e)}} placeholder="Razon Social" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Procedencia del cliente</label> */}
                            <Select options={ProcedCliente} className='col-12' onChange={(e) => { setValueProcedCliente(e.value) }} placeholder="Procedencia del cliente" />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        checkbox={true}
                        handleCheck={handlechekedItem}
                        iswidth={true}
                    />
                </div>
            </ValidatePermission>
            {
                openModalTotales &&
                <TotalPPCobroComisionExternoModal
                    isOpen={openModalTotales}
                    setIsOpen={setopenModalTotales}
                    item={IdsExportar}
                    handleGetAll={habdleGetAll}
                    toast={toast}
                />
            }
            {/* {
                openModalRetencionPago &&
                <ModalRetencionPago
                    isOpen={openModalRetencionPago}
                    setIsOpen={setopenModalRetencionPago}
                />
            } */}
        </>
    )
}

export default CobroComisionExternoPendientePago