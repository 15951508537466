import React from "react"
import Modal from "../../../Components/Modal/Modal.jsx";
import Spinner from "../../../Components/Loadig.jsx";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard.jsx";
import ContratoInfoCard from "../../../Components/ContratoInfoCard.jsx";
import useUnidadesEnRemateDetalleProcesos from "../../../Hooks/GestionDeRemate/useUnidadesEnRemateDetalleProcesos.js";
import ValoracionInicialProcesosCard from "./Cards/ValoracionInicialCard.jsx";
import AutorizacionDeValorDeUnidadProcesosCard from "./Cards/AutorizacionDeValorDeUnidadCard.jsx";
import CierreDeRemateProcesosCard from "./Cards/CierreDeRemateCard.jsx";

const UnidadesEnRemateDetalleProcesos = () => {
   const {
      vehiculo,
      contrato,
      loading,
      handleReturnPage
   } = useUnidadesEnRemateDetalleProcesos();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                     <i className="bx bxs-chevron-left mx-2"></i>
                     Regresar a unidades en remate
                  </div>
               </header>
               <div className="col col-11 d-flex">
                  <div className="col col-6 px-4 py-2">
                     <VehiculoInfoCard
                        VIN={vehiculo.vin}
                        verClaves = {true}
                        datosVehiculo={vehiculo}/>
                  </div>
                  <div className="col col-6 px-4 py-2">
                     <ContratoInfoCard contrato={contrato}/>
                  </div>
               </div>
               <div className="col col-11 px-4 py-2">
                  <ValoracionInicialProcesosCard />
               </div>
               <div className="col col-11 px-4 py-2">
                  <AutorizacionDeValorDeUnidadProcesosCard />
               </div>
               <div className="col col-11 px-4 py-2">
                  <CierreDeRemateProcesosCard />
               </div>
         </div>
      </>
   )
}

export default UnidadesEnRemateDetalleProcesos