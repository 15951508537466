import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    schedules: {}, // Cambiamos a un objeto para manejar múltiples tipos de calendario
}

export const CalendarDynamicalSlice = createSlice({
    name: 'CalendarDynamicalSlice',
    initialState,
    reducers: {
        setFields: (state, action) => {
            const { tipoCalendario, fields } = action.payload;
            if (!state.schedules[tipoCalendario]) {
                state.schedules[tipoCalendario] = {};
            }
            state.schedules[tipoCalendario] = {
                ...state.schedules[tipoCalendario],
                ...fields,
            };
        },
        clearHour: (state, action) => {
            const { tipoCalendario } = action.payload;
            if (state.schedules[tipoCalendario]) { // Elimina solo la hora y el día para seleccionar un horario diferente
                state.schedules[tipoCalendario].day = "";
                state.schedules[tipoCalendario].hour = "";
            }
        },
        clearScheduleByTipoCalendario: (state, action) => {
            const { tipoCalendario } = action.payload;
            delete state.schedules[tipoCalendario]; // Elimina el objeto del tipoCalendario específico
        },
        clearOnlyScheduleByTipoCalendario: (state, action) => {
            const { tipoCalendario } = action.payload;
            state.schedules[tipoCalendario] = {
                ...state.schedules[tipoCalendario],
                fields: {
                    day: '',
                    hour: ''
                }
            }
        },
    }
})

// Selector para obtener los datos por tipoCalendario
export const selectScheduleByTipoCalendario = (state, tipoCalendario) => {
    return state.CalendarDynamicalSlice.schedules[tipoCalendario] || {};
};

export const { setFields, clearHour, clearScheduleByTipoCalendario, clearOnlyScheduleByTipoCalendario } = CalendarDynamicalSlice.actions
export default CalendarDynamicalSlice.reducer;

/* MODELO DE DATOS */
/* 
{
    tipoCalendario: 1 // El id del calendario que viene desde la Base de datos
    fields: {
        contrato: '', // Número de contrato del vehículo
        folioLlamada: '', // Folio de llamada en caso de tener 
        vin: '', // Vin del vehículo
        modelo: '', // Modelo del vehículo
        ano: '', // Año del vehículo
        marca: '', // Marca del vehículo
        day: '', // Día para agendar la cita
        hour: '', // Hora para agendar la cita
        sucursalId: '', // Id de la sucursal donde se hará la cita 
        sucursalName: '', // Nombre de la sucursal donde se hará la cita
    }
}
*/