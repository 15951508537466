import { useEffect, useState } from 'react'
import Cobranza from '../../Services/Cobranza/Cobranza';

const useConfigurarZonaCobranza = () => {
   const columns = [
      { field: 'municipio', headerName: 'Municipio', width: 200 },
      { field: 'cp', headerName: 'Código postal', width: 300 },
      { field: 'colonia', headerName: 'Colonias', width: 300 }
   ]

   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [dataEstado, setDataEstado] = useState([]);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [defaultValueMunicipio, setDefaulValudeModelo] = useState({})
   const [zonaValue, setzonaValue] = useState('');
   const [todasZonas, setTodasZonas] = useState([]);
   const [dataZonas, setdataZonas] = useState([]);
   const [currentdataZonas, setcurrentdataZonas] = useState([]);
   const [sinZonasSeleccionadas, setSinZonasSeleccionadas] = useState(false);
   const [coloniaAsignada, setColoniaAsignada] = useState(false);
   const [msjColoniaAsignada, setMsjColoniaAsignada] = useState('');
   const [textoBusqueda, setTextoBusqueda] = useState("")

   useEffect(() => {
      handleGetEstados();
   }, []);

   useEffect(() => {
      const filteredData = dataZonas.filter(x => {
         const texto = textoBusqueda.toLowerCase();
         return (
            texto === '' ||
            x.colonia.toLowerCase().includes(texto) ||
            x.cp.toLowerCase().includes(texto)
         );
      });

      // Asegúrate de que los checkboxes en las filas visibles reflejen su estado actual
      const updatedData = filteredData.map(item => ({
         ...item,
         statusCheckBox: dataZonas.find(x => x.idrow === item.idrow)?.statusCheckBox || false,
         logUsuarioId: item.idrow
      }));

      setcurrentdataZonas(updatedData);


      let table = document.querySelectorAll('#tblCodigosPostales .tbl-custome >tr');

      for (let index = 0; index < table.length; index++) {
         const element = table[index];
         if (updatedData[index]) {
            if (updatedData[index].statusCheckBox) {
               element.firstChild.firstChild.checked = true;
            } else {
               element.firstChild.firstChild.checked = false;
            }
         }
      }

   }, [textoBusqueda, dataZonas]);

   const handleGetEstados = async () => {
      setLoading(true);
      await Cobranza.getAllEstados()
         .then(res => {
            let NewData = [];
            res.data.responseData?.map(element => {
               NewData.push({
                  value: element.cpId,
                  label: element.d_estado
               })
            });
            setDataEstado(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetMunucipios = async (value) => {
      let data = {
         "verb": "string",
         "id": value,
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      }
      setLoading(true);
      await Cobranza.getMunicipios(data)
         .then(res => {
            let NewData = [];
            res.data.responseData?.map(element => {
               NewData.push({
                  value: element.cpId,
                  label: element.d_mnpio
               })
            });
            setDataMunicipio(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetdataZonas = async (nombreEstado, nombreMunicipio, zonaItems = [], zonasExcluirEdicion = []) => {
      let data = {
         "verb": "string",
         "id": nombreEstado,
         "field": "string",
         // "value": "string",
         "value": nombreMunicipio,
         "flag": true,
         "anyObject": {}
      }
      setLoading(true);
      await Cobranza.getColonias(data)
         .then(res => {
            let NewData = [];
            res.data.responseData?.map(element => {

               if (!zonasExcluirEdicion.find(item => item.sepomexid === element.cpId)) {
                  let existe = zonaItems.some(obj => obj.sepomexid === element.cpId);

                  if (existe) {
                     NewData.push({
                        item: element,
                        statusCheckBox: true,
                        idrow: element.cpId,
                        municipio: element.d_mnpio,
                        colonia: element.d_asenta,
                        cp: element.d_codigo,
                        status: true,
                        logUsuarioId: element.cpId
                     })
                  } else {
                     NewData.push({
                        item: element,
                        statusCheckBox: false,
                        idrow: element.cpId,
                        municipio: element.d_mnpio,
                        colonia: element.d_asenta,
                        cp: element.d_codigo,
                        status: false,
                        logUsuarioId: element.cpId
                     })
                  }
               }
            });

            NewData = NewData.sort((a, b) => {
               return a.cp.localeCompare(b.cp, undefined, { numeric: true });
            });

            setdataZonas(NewData);
            setcurrentdataZonas(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetzonas = async (nombreEstado, nombreMunicipio) => {
      // setLoading(true);
      await Cobranza.getAllZonaHead()
         .then(res => {
            let NewData2 = [];
            res.data.responseData.filter(x => x.estado == nombreEstado && x.municipio == nombreMunicipio)?.map(element => {
               // res.data.responseData.filter(x => x.estado == nombreEstado).map(element => {
               NewData2.push({
                  value: element.id,
                  label: element.descripcion
               })
            });
            setTodasZonas(NewData2);
            // setLoading(false);
         }).catch(() => {
            // setLoading(false);
         })
   }

   const handleChangeMunicipio = async (item, zonaItems = [], zonasExcluirEdicion = []) => {
      setDefaulValudeModelo(item);
      handleGetzonas(item.value, item.label);
      handleGetdataZonas(item.value, item.label, zonaItems, zonasExcluirEdicion);
   }

   const handleChangeMunicipio2 = async (nombreEstado, municipio, zonaItems = [], zonasExcluirEdicion = []) => {
      setDefaulValudeModelo(municipio);
      handleGetzonas(nombreEstado, municipio.label);
      handleGetdataZonas(nombreEstado, municipio.label, zonaItems, zonasExcluirEdicion);
   }

   const handleChangeStatus = (checked, row) => {
      // Actualiza los datos originales
      setdataZonas(prevData =>
         prevData.map(item =>
            item.idrow === row.idrow
               ? { ...item, statusCheckBox: checked }
               : item
         )
      );

      row.status = checked;
      row.statusCheckBox = checked;
      const newStatusList = [...currentdataZonas];
      newStatusList.forEach(item => {
         if (item == row.idrow) {
            item.status = checked;
            item.statusCheckBox = checked;
         }
      });
      setcurrentdataZonas(newStatusList);

      if (currentdataZonas.length > 0)
         setSinZonasSeleccionadas(false);
      console.log(`${checked} - ${row.idrow} - ${row.municipio} - ${row.colonia}`);
   }

   return {
      columns,
      dataEstado,
      dataMunicipio,
      defaultValueMunicipio,
      handleGetMunucipios,
      handleChangeMunicipio,
      handleChangeMunicipio2,
      handleChangeStatus,
      currentdataZonas,
      openModal,
      setOpenModal,
      zonaValue,
      loading,
      setLoading,
      sinZonasSeleccionadas,
      setSinZonasSeleccionadas,
      setzonaValue,
      todasZonas,
      coloniaAsignada,
      msjColoniaAsignada,
      setColoniaAsignada,
      setMsjColoniaAsignada,
      setTextoBusqueda,
      setDataMunicipio
   }
}

export default useConfigurarZonaCobranza