import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { SelectField } from '../../Components/SelectField';
import { TextField } from '../../Components/TextField';
import Siniestros from '../../Services/Siniestro/Siniestro';
import Catalogo from '../../Services/Catalogos/Catalogo';

const BuscadorSiniestroVINModal = ({ isOpen, setIsOpen }) => {

    const refbtn = useRef();
    const navigate = useNavigate();
    let initial = {
        vehiculo: {
            generalId: "",
            fechaEntrega: "",
            vin: "",
            nombreMarca: "",
            nombreModelo: "",
            nombreVersion: "",
            nombreYear: "",
            color: "",
            kilometraje: "",
            procedenciaAuto: "",
            numMotor: "",
            numContrato: "",
            numSolicitud: "",
            cliente: "",
            fechaUltimoPago: null,
            producto: "",
            usoUnidad: "",
            transmision: "",
            serieDatamovil: "",
            expediente: true,
            folioCarnet: "",
            taller: "",
            nombre: ""
        },
        infoAscendes: {
            sol_id: "",
            contrato: "",
            vin: "",
            periodo: null,
            saldo_credito: null,
            termino_contrato: null,
            ultimo_pago: null,
            status_contrato: null,
            fecha_ultima_clave: null,
            vigencia_clave: null,
            status: "",
            message: ""
        },
        siniestro: {
            message: null,
            ban: 1,
            siniestroId: ""
        },
        vin: "",
        tipoSiniestroId: ""
    };
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false); // Cambiado para manejar la carga global
    const [isProcessing, setIsProcessing] = useState(false); // Nuevo estado para bloquear botones
    const [bandera, setBandera] = useState(0);
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);

    useEffect(() => {
        getSiniestroTipo();
    }, [])

    const getSiniestroTipo = async () => {
        setLoading(true)
        try {
            const resp = await Catalogo.getDropdownsByTable('Catalogos.SiniestroTipo');
            let NewData = [];
            resp.data.map(item => {
                NewData.push({ value: item.SiniestroTipoId, label: item.SiniestroTipo });
            });
            setDataTipoSiniestro(NewData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false)
        }
    };

    const validate = Yup.object({
        tipoSiniestroId: Yup.string()
            .required('Campo requerido'),
        vin: Yup.string()
            .required('Campo requerido'),
    });

    const handlerPaso = async () => {
        setLoading(true)
        setIsProcessing(true); // Bloquear botones
        try {
            const res = await Siniestros.getSeguimientoSiniestro({
                page: 1,
                rows: 10,
                buscar: initialValue.vin,
                tipoSiniestroId: "",
                estatus: "",
                strAvanzado: "",
                fechaInicio: "",
                fechaFin: "",
                concluido: false,
                busquedaAvanzada: false
            });
            if (res.data.data) {
                let resData = res.data.data[0]
                navigate(`/seguro/siniestros/${resData.siniestroId}/${resData.paso}`, {
                    state: resData
                });
            } else {
                setIsOpen(false)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
            setIsProcessing(false); // Desbloquear botones
        }
    };

    const handlerRegister = async () => {
        setLoading(true)
        setIsProcessing(true); // Bloquear botones
        try {
            const params = {
                generalId: initialValue.vehiculo.generalId,
                siniestroStatusId: 10,
                siniestroProcesoId: 40,
                SiniestroTipoId: initialValue.tipoSiniestroId
            };
            const res = await Siniestros.siniestroCrear(params);
            let resdata = res.data.data;
            await handlerPaso();
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false)
            setIsProcessing(false); // Desbloquear botones
        }
    };

    const buscarVIN = async (values) => {
        setLoading(true)
        setIsProcessing(true); // Bloquear botones
        try {
            const res = await Siniestros.siniestroByVinGet(values.vin);
            let resData = res.data;
            resData.vin = values.vin;
            resData.tipoSiniestroId = values.tipoSiniestroId;
            if (resData.statusCode || (resData.infoAscendes?.status_contrato !== 'vigente' && resData.infoAscendes?.status_contrato !== 'Activo') || resData.message === null) {
                setBandera(3); // No se encontró
            } else {
                setInitialValue(resData);
                if (resData.siniestro.ban === 1 && resData.siniestro.message === 'Registrar siniestro') {
                    setBandera(1); // Registrar
                } else {
                    setBandera(2); // Continuar
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsProcessing(false); // Desbloquear botones
        }
    };

    const getSelectedLabel = (id) => {
        const selected = dataTipoSiniestro.find(item => item.value === id);
        return selected ? selected.label : '';
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    buscarVIN(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={isOpen} color='#fff' style={{ width: '40px' }}>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Buscador de VIN</strong></span>
                                </div>
                                <span className='mt-2 fs-6'>A continuación ingresa el VIN de la unidad que deseas registrar un siniestro</span>
                            </section>
                        </section>
                        <section className='my-4 mx-4'>
                            <Form>
                                {
                                    bandera === 0 &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <SelectField id="tipoSiniestroId"
                                                    label="Tipo de siniestro"
                                                    name="tipoSiniestroId"
                                                    items={dataTipoSiniestro}
                                                    onChange={(event) => {
                                                        setFieldValue("tipoSiniestroId", event.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='mb-3 row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <TextField
                                                    label="VIN"
                                                    name="vin"
                                                    type="text"
                                                    holder="Escribe VIN / Contrato"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    (bandera !== 0 && bandera !== 3) &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <b>
                                                    Siniestro : {getSelectedLabel(values.tipoSiniestroId)}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <b>
                                                    VIN ingresado : {initialValue.vin}
                                                </b>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    bandera === 1 &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <div>
                                                    ¿Deseas registrar el siniestro del siguiente vehículo?
                                                </div>

                                                <div>
                                                    <b>Contrato</b> : {initialValue?.infoAscendes?.contrato} | <b>Marca</b>: {initialValue?.vehiculo?.nombreMarca} | <b>Modelo</b>: {initialValue?.vehiculo?.nombreModelo} | <b>Año</b>: {initialValue?.vehiculo?.nombreYear}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    bandera === 2 &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <div>
                                                    ¿Deseas continuar con el siniestro del siguiente vehículo?
                                                </div>
                                                <div>
                                                    <b>Contrato</b> : {initialValue?.infoAscendes?.contrato} | <b>Marca</b>: {initialValue?.vehiculo?.nombreMarca} | <b>Modelo</b>: {initialValue?.vehiculo?.nombreModelo} | <b>Año</b>: {initialValue?.vehiculo?.nombreYear}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    bandera === 3 &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <b>
                                                    Siniestro : {getSelectedLabel(values.tipoSiniestroId)}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <b>
                                                    El contrato ingresado se encuentra cancelado / cerrado.
                                                </b>
                                            </div>
                                        </div>
                                    </>
                                }

                                <section className='my-3'>
                                    <button
                                        id="AT_btnCancelar"
                                        className='btn btn-outline'
                                        type='reset'
                                        onClick={() => { setIsOpen(false) }}
                                        disabled={isProcessing} // Bloquear durante procesamiento
                                    >
                                        Cancelar
                                    </button>
                                    {
                                        bandera === 0 &&
                                        <button
                                            className='btn mx-2'
                                            type="submit"
                                            disabled={!(isValid) || isProcessing} // Bloquear durante procesamiento
                                        >
                                            Continuar
                                        </button>
                                    }
                                    {
                                        bandera === 1 &&
                                        <button
                                            onClick={handlerRegister}
                                            className='btn mx-2'
                                            type="button"
                                            disabled={isProcessing} // Bloquear durante procesamiento
                                        >
                                            Registrar
                                        </button>
                                    }
                                    {
                                        bandera === 2 &&
                                        <button
                                            onClick={handlerPaso}
                                            className='btn mx-2'
                                            type="button"
                                            disabled={isProcessing} // Bloquear durante procesamiento
                                        >
                                            Continuar
                                        </button>
                                    }
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )}
            </Formik >
        </>
    )
}

export default BuscadorSiniestroVINModal;
