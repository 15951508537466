import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Components/datatable/DataTable";
import SeguimientoServicioUbicacionHook from "../../Hooks/ConfigCalendar/SeguimientoServicioUbicacionHook";
import { TextField } from "../../Components/TextField";
import { Formik } from "formik";
import Modal from "../../Components/Modal/Modal";
import Spinner from "../../Components/Loadig";

const DetalleServicio = () => {
    const { state } = useLocation();
    const { id } = useParams();

    const {
        refFormik,
        navigate,
        initialValue,
        page,
        rows,
        totalPages,
        totalRows,
        data,
        loading,
        setLoading,
        setBuscar,
        columns,
        handleEdit
    } = SeguimientoServicioUbicacionHook();

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                ref={refFormik}
                // validationSchema={validate}
            >{
                    ({ isValid, values, setFieldValue }) => (
                        <>
                            <Form>
                                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                                <section className='headerTab'>
                                    <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                                        <div>
                                            <i className="bx bxs-chevron-left text-xs px-4 mx-2" onClick={() => { navigate(-1) }}></i>
                                            Regresar a listado de servicios
                                        </div>
                                    </header>
                                </section>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>{state.tipo}</strong>
                                            </div>
                                            <div className="col-auto">
                                                <button className='btn' onClick={(e) => {
                                                    navigate(`/admin/servicios-datamovil/${id}/0`, {state})
                                                }}>
                                                    Agregar ubicación
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-3 inner-addon right-addon  mt-2 mr-1">
                                                <i className="glyphicon bx bx-pencil"></i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Buscar"
                                                    id="buscar"
                                                    name="buscar"
                                                    onChange={
                                                        (e) => {
                                                            // setFieldValue("buscar", e.target.value);
                                                            // setBuscar(e.target.value);
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>Ubicaciones del servicio</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-3 inner-addon right-addon  mt-2 mr-1">
                                                <i className="glyphicon fas fa-search"></i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Buscar"
                                                    id="buscar"
                                                    name="buscar"
                                                    onChange={
                                                        (e) => {
                                                            // setFieldValue("buscar", e.target.value);
                                                            // setBuscar(e.target.value);
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-12'>
                                            <DataTable
                                                column={columns}
                                                data={data}
                                                currentPage={page - 1}
                                                paginate={true}
                                                pageCount={totalRows / rows}
                                                editable={true}
                                                handleEdit={handleEdit}
                                            // handlePageClick={handlePageClick}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </Form>
                        </>
                    )
                }
            </Formik>
        </>
    );
}

export default DetalleServicio;