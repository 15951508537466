import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Form, Formik } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import { TextAreaField } from '../TextAreaField';
import { message } from '../../Utils/functions';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import * as Yup from 'yup';

const AutorizacionBoletinadoModal = ({ isOpen, setIsOpen, data, toast,getAll }) => {
  const refFormik = useRef();
  const [motivoItems, setmotivoItems] = useState([]);
  let initial = {
    siniestroClienteBoletinarId: data?.siniestroClienteBoletinarId ?? "",
    autorizado: '',
    motivo_RechazoId: "",
    motivo_Rechazo: "",
    comentariosSupervisor: '',
    //Acendes
    sol_Id:data?.sol_Id ?? "",
    contrato:data?.contrato ?? "",
    vin:data?.vin ?? "",
    nombreCliente:data?.nombreCliente ?? "",
    siniestroClienteBoletinarMotivo:data?.siniestroClienteBoletinarMotivo ?? "",
    comentariosEjecutivo:data?.comentariosEjecutivo ?? "",
    proceso:data?.proceso ?? ""
  }

  const validationSchema = Yup.object({
    autorizado: Yup.string().required('El campo es requerido'),
    motivo_RechazoId: Yup.string()
      .when('autorizado', {
        is: 'false', // Condition to check
        then: Yup.string().required('El campo es requerido'),
      }),
      comentariosSupervisor: Yup.string().required('El campo es requerido'),
  })

  const handlerGuardar = async (values) => {
    await CobranzaService.AutorizarRechazarBoletinado(values)
      .then(res => {
        if(res.data.data.ban === 1){
          toast.success(message("Correcto!",res.data.data.mensaje));
          getAll();
        }else{
          toast.error(message("Error!",res.data.data.mensaje));
        }
        setIsOpen(false);
      }).catch(e => {
        toast.error(message("Error!",e.message));
      });
  };

  const HandleGetMotivos = async () => {
    setmotivoItems([]);
    try {
      const res = await CobranzaService.GetAllBoletinadoMotivoRechazo();
      const motivos = res.data.responseData?.map(item => ({
        value: item.id,
        label: item.nombre,
      })) || [];
      // const data = [{ key: 1, value: "Inconsistencia" }, { key: 2, value: "Boletinado injustificado" }];

      setmotivoItems(motivos);
      // motivoItems(data);
    } catch (error) {
      console.error('Error al cargar motivos:', error);
    }
  };

  // useEffect para llamar a HandleGetMotivos cuando el resultado cambie
  useEffect(() => {
    HandleGetMotivos(); // Carga los motivos   
  }, []); // Se ejecuta cuando cambia el valor de resultado

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlerGuardar(values); // Envia los datos al servicio al confirmar          
        }}
      >
        {({ setFieldValue, values, isValid }) => (
          <Modal isOpen={isOpen} color='#fff' width={500}>
            <div>
              <Form>
                <div className='justify-content-start p-2 align-items-center'>
                  <div className="row">
                    <div className="col col-12 dias-festivos-modal-underline">
                      <div>
                        <h4><strong>Autorizacion modal</strong></h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-6 mt-4">
                      <SelectField
                        id="resultado"
                        label="Resultado"
                        name="autorizado"
                        items={[
                          { label: "Aprobar", value: true },
                          { label: "Rechazar", value: false }
                        ]}
                        onChange={(option) => {
                          setFieldValue("autorizado", option.value);

                        }}
                      />
                    </div>
                    {values.autorizado === false &&
                      <div className="col-lg-6 col-6 mt-4">
                        <SelectField
                          id="motivo"
                          label="Motivo"
                          name="motivo_RechazoId"
                          items={motivoItems.map(c => ({ label: c.label, value: c.value }))} // Items cargados desde el servicio
                          onChange={(option) => {
                            setFieldValue("motivo_RechazoId", option.value); // Actualiza el valor del Formik
                            setFieldValue("motivo_Rechazo", option.label);
                          }}
                        />
                      </div>
                    }
                  </div>
                  <div className="col-lg-12 col-12 mt-4" style={{ display: 'flex', flexFlow: 'column' }}>
                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentariosSupervisor" type="text" holder="Escribir" />
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                      <button
                        id="AT_BtnCloseModal"
                        className='btn btn-outline btn-block'
                        onClick={()=>{setIsOpen(false)}}
                      >
                        Cancelar
                      </button>
                    </div>
                    <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                      <button
                        id="AT_BtnConfirmModal"
                        className='btn btn-blue btn-block'
                        disabled={!(isValid)}
                        type='submit'
                      >
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AutorizacionBoletinadoModal;
