import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleRadioCheckItem, handleUnchekedColumns, message } from '../../Utils/functions';
import Catalogo from '../../Services/Catalogos/Catalogo';
import pruebaManejoService from '../../Services/Venta/Vehiculo/pruebaManejoService';

const usePruebaManejo = (toast) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refBtnExp = useRef();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'solicitud', headerName: 'No. de Solicitud', width: 230 },
    { field: 'nombreMarca', headerName: 'Marca', width: 160 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'nombreYear', headerName: 'Año', width: 150 },
    { field: 'fecha', headerName: 'Fecha y hora de prueba', width: 250 },
    { field: 'sucursal', headerName: 'Sucursal', width: 180 },
    { field: 'producto', headerName: 'Producto', width: 230 },
    { field: 'ejecutivo', headerName: 'Ejecutivo', width: 250 },
    // { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 400 },
  ];
  const columnsH = [
    { field: 'fecha', headerName: 'Fecha y hora', width: 600 },
    { field: 'folio', headerName: 'Folio', width: 600 },
    { field: 'sucursal', headerName: 'Sucursal', width: 600 },
    { field: 'vendedor', headerName: 'Vendedor', width: 640 },
    { field: 'solicitud', headerName: 'Solicitud', width: 340 },
    { field: 'cita', headerName: 'Cita', width: 640 },
    { field: 'supervisor', headerName: 'Supervisor', width: 340 },
    { field: 'mantener', headerName: 'Mantener prueba', width: 340 },
    { field: 'vigencia', headerName: 'Vigencia', width: 340 },
    { field: 'acudio', headerName: 'Asistencia a cita', width: 340 },
    { field: 'separado', headerName: 'Separado', width: 340 },
    { field: 'liberar', headerName: 'Liberar', width: 340 },

  ];

  let dataUtilitario = [
    { label: "Todos", value: "" },
    { label: "Vehículos que mantienen prueba de manejo", value: 1 },
    { label: "Vehículos con proceso estándar", value: 0 },
  ];
  let dataEndTD = [
    { label: "Mantener prueba", value: 1 },
    { label: "Liberar unidad", value: 0 },
  ];
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageCountModal, setPageCountModal] = useState();
  const [sucursal, setSucursal] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataSucursal, setDataSucursal] = useState([]);
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [dataModal, setDataModal] = useState([]);
  const [IsOpenModal, setIsOpenModal] = useState(false)
  const [currenPageH, setCurrenPageH] = useState(1);
  const [utilitarioId, setUtilitarioId] = useState("");
  const [pageRows, setpageRows] = useState(10)
  const [IdsEliminar, setIdsEliminar] = useState([]);
  const [isOpenModalTDrive, setIsOpenModalTDrive] = useState(false)
  const [isOpenModalTDriveEnd, setisOpenModalTDriveEnd] = useState(false)
  //
  const [DataHistory, setDataHistory] = useState([]);
  const [pageCountH, setPageCountH] = useState();
  const [StrH, setStrH] = useState("");
  const [dataItem, setDataItem] = useState();
  const [dataItemH, setDataItemH] = useState();
  const [isOpenModalLiberar, setIsOpenModalLiberar] = useState(false);
  const [estadoVehiculo, setEstadoVehiculo] = useState(1);
  const [item, setItem] = useState(null);
  useEffect(() => {
    handleUnchekedColumns()
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [sucursal, str, utilitarioId, pageRows])

  useEffect(() => {
    handleGetAllSucursal();
  }, [])

  useEffect(() => {
    hanldleHistoryTDrive(null, dataItemH);
  }, [StrH])

  const habdleGetAll = async (page) => {
    setLoading(true);
    handleUnchekedColumns()
    await pruebaManejoService.gatAllPruebaManejo(page, pageRows, str, sucursal, utilitarioId)
      .then(res => {

        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  // const handleGetAllSucursal = async () => {
  //   await Catalogo.getAllSucursal()
  //     .then(res => {
  //       let NewData = [];
  //       NewData.push({
  //         value: "",
  //         label: "Todos"
  //       })
  //       res.data.data?.map(item => {
  //         NewData.push({
  //           value: item.sucursalId,
  //           label: item.sucursal
  //         })
  //       });
  //       setDataSucursal(NewData);
  //     }).catch(e => {

  //     })
  // }
  const handleGetAllSucursal = async () => {
    await Catalogo.getdataTipoUbicacion("Sucursal")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.ubicacionId,
            label: item.ubicacion
          })
        });
        setDataSucursal(NewData);
      }).catch(e => {
      })
  }
  const handleLiberarPruebaManejo = async () => {
    setLoading(true);
    await pruebaManejoService.DeletePruebaManejo(dataItem.generalId)
      .then(res => {

        setisOpenModalTDriveEnd(false);
        if (res.data.data.ban !== 0)
          toast.success(message("¡Correcto.!", res.data.data.mensaje));
        else
          toast.error(message("¡Error.!", res.data.data.mensaje));

        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handlechekedItem = (cheked, value, i) => {
    handleRadioCheckItem(i);

    if (cheked) {
      setItem(value);
    } else {
      setItem(null);
    }
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };

  const handlePageClickH = (event) => {
    setCurrenPageH(event.selected + 1);
    hanldleHistoryTDrive(event.selected + 1, dataItemH);
  };
  const handleModalTDrive = (value) => {
    setDataItem(value);
    setItem(value);
    if (value.mantener)
      setisOpenModalTDriveEnd(true)
    else
      setIsOpenModalTDrive(true)
  }

  const handleUploadFile = (item) => {
    navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
      state: { ...item, pruebaManejo: true },
    });
  }
  const hanldleHistoryTDrive = async (page = null, value) => {
    if (value === undefined)
      return

    setDataItemH(value)

    setLoading(true);
    await pruebaManejoService.getAllPruebaManejoHostory(page ?? currenPageH, 10, StrH, value.generalId)
      .then(res => {
        setDataHistory(res.data.data);
        setPageCountH(res.data.totalPages);
        setLoading(false);
        setIsOpenModal(true);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleLiberarPruebaCheck = async () => {
    refBtnExp.current.disabled = true;
    setLoading(true);
    if (IdsEliminar.length > 0) {
      let newIds = IdsEliminar.join();
      await pruebaManejoService.DeletePruebaManejo(newIds)
        .then(res => {
          setIsOpenModalLiberar(false);
          habdleGetAll(1);
          handleUnchekedColumns();
          refBtnExp.current.disabled = false;
          setIdsEliminar([]);
          if (res.data.data.ban !== 0)
            toast.success(message("¡Correcto.!", res.data.data.mensaje));
          else
            toast.error(message("¡Error.!", res.data.data.mensaje));
          setLoading(false);
        }).catch(e => {
          setLoading(false);
        })
    } else {
      setLoading(false);
      refBtnExp.current.disabled = false;
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros a exportar.")}</div>);
    }

  }
  const handleModalLiberarCheck = () => {
    if (item !== null) {
      setIsOpenModalLiberar(true);
    } else {
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione un registro para continuar.")}</div>);
    }

  }
  return {
    columns,
    columnsH,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    pageCountModal,
    setSucursal,
    setStr,
    loading,
    dataSucursal,
    setIsOpenModal,
    IsOpenModal,
    handlePageClickH,
    currenPageH,
    dataUtilitario,
    setUtilitarioId,
    handlechekedItem,
    setpageRows,
    handleModalTDrive,
    hanldleHistoryTDrive,
    habdleGetAll,
    isOpenModalTDrive,
    setIsOpenModalTDrive,
    isOpenModalTDriveEnd,
    setisOpenModalTDriveEnd,
    setStrH,
    DataHistory,
    dataItem,
    handleLiberarPruebaManejo,
    refBtnExp,
    handleLiberarPruebaCheck,
    isOpenModalLiberar,
    setIsOpenModalLiberar,
    handleModalLiberarCheck,
    pageCountH,
    IdsEliminar,
    dataEndTD,
    estadoVehiculo,
    setEstadoVehiculo,
    handleUploadFile,
    item
  }
}

export default usePruebaManejo