import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Catalogo from "../../Services/Catalogos/Catalogo";

const ConfiguracionUbicacionHook = () => {
    const { id, ubicacionId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const refFormik = useRef();
    const initial = {
        ubicacionId: null
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [ubicacion, setUbicacion] = useState("");

    useEffect(() => {
        getUbicaciones();
    }, []);

    const handleChooseLocation = (value) => {
        const location = ubicaciones.filter(item => item.value === value)[0].label;
        setUbicacion(location);
    }

    const getUbicaciones = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
        .then(resp => {
            const items = resp.data.map(item => {
                return {
                    value: item.UbicacionId,
                    label: item.Nombre
                };
            });
            setUbicaciones(items);
            
            if(ubicacionId !== "0"){
                setInitialValue({ ubicacionId });
                const location = items.filter(item => item.value === ubicacionId)[0]?.label;
                setUbicacion(location);
            }
        })
        .catch(err => {

        });
    };

    return {
        refFormik,
        initialValue,
        data,
        loading,
        navigate,
        ubicaciones,
        handleChooseLocation,
        ubicacion
    }
}

export default ConfiguracionUbicacionHook;