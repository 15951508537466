import axios from '../../Utils/ApiService';

const ModeloAPI = {
    updateModelo:(data)=> {        
        return axios.put(`/ModeloUpdate`,data);
    },    
    addModelo:(data)=> {         
        return axios.post(`/ModeloAdd`,data);
    },       

}

export default ModeloAPI