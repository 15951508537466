import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';

const ModalConfirmGestoriaAutorizada = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let cambioPropietario = [
        { value: true, label: "Si" },
        { value: false, label: "No" },
    ]
    let initial = {
        generalId: item?.generalId ?? "",
        filename: item.filename,
        nombreId: "",
        tarjetaCirculacion: "",
        numPlaca: "",
        sol_id: item?.sol_id ?? "",
        vin: item?.vin ?? "",
        id_datamovil: item?.id_datamovil ?? "",
        status_datamovil: item?.status_datamovil ?? "",
        contrato: item?.contrato ?? "",
        legibilidad: false,
        costos: [],
        CostoPropietario: "",
        ANombreDe: "",
        clientePagoCambioPropietario: ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTecnico, setDataTecnico] = useState([]);
    const [ValueIva, setValueIva] = useState(0);
    const [dataTipoCosto, setdataTipoCosto] = useState([])

    let newItem = { tipoCostoId: "", subtotal: "", iva: ValueIva, total: "", isCostoProp: false };

    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
        numPlaca: Yup.string()
            .required("El campo es requerido."),
        nombreId: Yup.string()
            .required("El campo es requerido."),
        clientePagoCambioPropietario: Yup.bool()
            .required("El campo es requerido."),
        tarjetaCirculacion: Yup.string()
            .required("El campo es requerido.")
            .min(10, "Ingrese un minimo de 10 caracteres.")
            .max(20, "El maximo de caracteres es de 20."),
        costos: Yup.array().of(
            Yup.object().shape({
                tipoCostoId: Yup.string()
                    .required("El campo es requerido."),
                subtotal: Yup.number()
                    .required("El campo es requerido.")
                    .positive("ingrese un valor mayor a cero."),
                    // .test({
                    //     name: 'is-subtotal',
                    //     skipAbsent: true,
                    //     test(value, ctx) {
                    //         if (value <= 0)
                    //             return ctx.createError({ message: 'ingrese un valor mayor a cero.' })
                    //         return true
                    //     }
                    // }),
                iva: Yup.number().test({
                    name: 'is-Iva',
                    skipAbsent: true,
                    test(value, ctx) {
                        if (value < 0)
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        if (value >= 100)
                            return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                        return true
                    }
                }),
                total: Yup.string()
                    .required("El campo es requerido."),
            })
        )

    });


    useEffect(() => {
        handleGetallSelects();
    }, [])

    const handleGetallSelects = async () => {
        const [] = await Promise.all([handleGetAllANombreDe(), handleGetParameters(), handleGetTipoCosto()]).finally(() => {
            // setLoading(false);
        });

    }
    const handleGetTipoCosto = async () => {
        await Catalogo.getdataTipocosto("Cambio de Propietario")
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCostoId,
                        label: item.nombre
                    })
                });
                setdataTipoCosto(NewData);
            }).catch(e => {
            })
    }
    const handleGetParameters = async () => {
        await IntelimotorAPI.getValorParametro("IVA")
            .then(res => {
                let ivaValue = res.data.data.valor;
                setValueIva(ivaValue);
                setInitialValue({ ...initialValue, costos: [...initialValue.costos, { ...newItem,iva:ivaValue, tipoCostoId: "01c9ad91-1197-4dd8-a5f2-1404bfd92cca", isCostoProp: true }] })
            }).catch(e => {
            })
    }
    const handleGetAllANombreDe = async () => {
        await Catalogo.getAllCSANombreDe()
            .then(res => {
                let NewData = [];
                console.log(res.data.data)
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.nombreId,
                        label: item.nombre
                    })
                });
                setDataTecnico(NewData);
            }).catch(e => {

            })
    }

    const handleCostos = (items) => {
        let total = 0;
        items?.map(x => {
            if (x.total !== "")
                total = total + parseFloat(x.total)
        })
        return total;
    }
    const handleAddEdit = async (item) => {
        submit(item);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit({ ...values, CostoPropietario: handleCostos(values.costos).toFixed(2) });
            }}
        >
            {({ setFieldValue, values, errors }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons' style={{ width: '40rem' }} >
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-8 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2">
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                <label style={{ fontSize: '14px' }}><strong>2. Información de gestoría</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    A continuación debes capturar los siguientes datos de la gestoría.
                                </span>
                                <div className="mb-3 row my-2">
                                    <div className="col-sm-4">
                                        <SelectField id="tecnicoID" label="A nombre de" name="nombreId" items={dataTecnico} onChange={(event) => {
                                            setFieldValue("nombreId", event.value);
                                            setFieldValue("ANombreDe", event.label);
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="tarjetaCirculacion" label="Tarjeta de circulación" name="tarjetaCirculacion" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="numPlaca" label="Número de placas" name="numPlaca" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField label="Cliente pago cambio propietario" name="clientePagoCambioPropietario" items={cambioPropietario} onChange={(event) => {
                                            setFieldValue("clientePagoCambioPropietario", event.value);
                                            // if (event.value) {
                                            //     if (values.costos.length > 0) {
                                            //         if (values.costos.find(x => !x.isCostoProp))
                                            //             setInitialValue({ ...values, clientePagoCambioPropietario: event.value, costos: [...values.costos, { ...newItem, tipoCostoId: "01c9ad91-1197-4dd8-a5f2-1404bfd92cca", isCostoProp: true }] })
                                            //     } else {
                                            //         setInitialValue({ ...values, clientePagoCambioPropietario: event.value, costos: [...values.costos, { ...newItem, tipoCostoId: "01c9ad91-1197-4dd8-a5f2-1404bfd92cca", isCostoProp: true }] })
                                            //     }
                                            // }
                                            // else { setInitialValue({ ...values, clientePagoCambioPropietario: event.value, costos: initialValue.costos.filter((x) => !x.isCostoProp) }) }
                                        }} placeholder="Seleccione" />
                                    </div>
                                </div>
                                <label style={{ fontSize: '14px' }}><strong>3. Registro de costos</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    A continuación registra los costos de los vehiculos.
                                </span>
                                <section className='tbl-scroll'>
                                    {/* style={values.costos?.length > 3 ? { height: '25vh', overflowY: 'auto'}: {overflowY: 'auto'}} */}
                                    <table className="table">
                                        <thead>
                                            <tr className='custom-table'>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Subtotal</th>
                                                <th scope="col">IVA</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbl-custome'  >
                                            {
                                                values.costos?.length > 0 ?
                                                    values.costos?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td key={index} style={{ width: '180px' }}>
                                                                    <SelectField disabled={item.isCostoProp} label="" name={`costos.${index}.tipoCostoId`} items={dataTipoCosto} onChange={(event) => {
                                                                        setFieldValue(`costos.${index}.tipoCostoId`, event.value);
                                                                    }} />
                                                                </td>
                                                                <td>
                                                                    <TextField label="" name={`costos.${index}.subtotal`} type="number" holder="Escribe" onChange={(event) => {
                                                                        let value = event.target.value;
                                                                        setFieldValue(`costos.${index}.subtotal`, event.target.value);
                                                                        let totalIva = (values.costos[index].iva / 100) * value;

                                                                        setFieldValue(`costos.${index}.total`, value == "" ? "" : totalIva + parseFloat(value));
                                                                    }} />
                                                                </td>
                                                                <td>
                                                                    {/* <input type="text" name="iva" className='form-control' readOnly value={`${item.iva}%`} /> */}
                                                                    <TextField label="" name={`costos.${index}.iva`} type="number" holder="Escribe" onChange={(event) => {
                                                                        let value = event.target.value;
                                                                        setFieldValue(`costos.${index}.iva`, value);
                                                                        let totalIva = (value / 100) * values.costos[index].subtotal;

                                                                        setFieldValue(`costos.${index}.total`, value == "" ? "" : (totalIva + parseFloat(values.costos[index].subtotal)));
                                                                        // handleChaneSubtotal(index, event, values)
                                                                    }} />
                                                                </td>
                                                                <td>
                                                                    <TextField label="" disabled name={`costos.${index}.total`} type="text" holder="Escribe" />
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center align-items'>
                                                                        <fieldset className='btn btn mx-1' disabled={item.isCostoProp} onClick={() => {
                                                                            // setRowsCostos(items => {
                                                                            //     return items.filter((_, i) => i !== index)
                                                                            // })
                                                                            setInitialValue({ ...values, costos: values.costos.filter((_, i) => i !== index) })
                                                                        }}><i className="bx bxs-trash-alt"></i></fieldset>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </section>
                                <div className='my-2 col-3' onClick={(e) => { setInitialValue({ ...values, costos: [...values.costos, newItem] }) }}><strong>+Agregar costo</strong></div>
                                {/* setRowsCostos([...values.costos, newItem]); */}
                                <div className='d-flex justify-content-between py-2 px-2' style={{ backgroundColor: '#f5f5f5' }}>
                                    <span>Total</span>
                                    <span>${handleCostos(values.costos).toFixed(2)}</span>
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={!values.legibilidad}>Confirmar</button>
                            </section>
                        </Form>
                        {/* <div className='d-flex justify-content-around mt-5'>
                            <button className="btn btn-outline col-5" type="button" onClick={() => { setIsOpen(false) }}>Regresar</button>
                            <button className='btn mx-2' type="submit" disabled={!(isValid)}>Confirmar</button>
                        </div> */}
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalConfirmGestoriaAutorizada