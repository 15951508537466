import React, { useEffect, useState } from 'react'
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';

const useDocumentoporRol = (toast) => {
    const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
    const columns = [
        { field: 'nombre', headerName: 'Documento', width: 250 },
        { field: 'roles', headerName: 'Rol', width: 460 },
        //   { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        //   { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
        //   { field: 'etapaActual', headerName: 'Etapa', width: 150 },
        //   { field: 'fecha', headerName: 'Fecha', width: 250 },
        //   { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 400 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [rolId, setRolId] = useState("");
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [filtroDocs, setfiltroDocs] = useState("");
    const [dataRol, setdataRol] = useState([]);
    const [dataTipoCompra, setDataTipoCompra] = useState([]);
    const [pageRows, setpageRows] = useState(10)
    //
    useEffect(() => {
        habdleGetAll(currenPage);
        // setCurrenPage(1);
    }, [rolId, str,pageRows])

    useEffect(() => {
        handleGetRol();    
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await VentaVehiculoService.getlAllDocUsuario(page, pageRows,str,rolId)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleCreateFolder = (item) => {        
        setDataVehiculo(item);
        setIsOpenDocs(true);
    }
    const handleDeleteItem = async (item) => {        
        setLoading(true);
        await EtapaService.DeleteDocumentoRol(item.tipoDocumentoId)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handleGetRol = async () => {
        setLoading(true);
        await EtapaService.getAllRol()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.rolId,
                        label: item.nombre
                    })
                });
                setdataRol(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
  return {    
    habdleGetAll,
    dataVehiculo,
    setIsOpenDocs,
    isOpenDocs,
    handleDeleteItem,
    currenPage,
    handlePageClick,
    pageCount,
    handleCreateFolder,
    data,
    columns,
    setDataVehiculo,
    setRolId,
    dataRol,
    setStr,
    loading,
    setpageRows
  }
}

export default useDocumentoporRol