import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import { TextField } from "../../../Components/TextField";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import DSystemsServicios from "../../../Services/DatamovilSystems/ServiciosDatamovil";
import { message } from "../../../Utils/functions";

const AutorizacionServicioModal = ({ modalMessage, setModalMessage, getListado }) => {
    const refFormik = useRef();
    const initial = {
        servicioId: '',
        servicioAutorizado: '',
        servicioMotivoRechazo: '',
        comentariosRechazo: '',
    };

    const [initialValue, setInitialValue] = useState(initial);
    const resultados = [
        { value: '1', label: 'Aprobar' },
        { value: '0', label: 'Rechazar' }
    ];
    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        getMotivosRechazo();
    }, []);

    useEffect(() => {
        getMotivosRechazo();
    }, [modalMessage]);

    const getMotivosRechazo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ServicioMotivosRechazo')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.ServicioMotivosRechazoId,
                        label: item.ServicioMotivosRechazo
                    }
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleAutorizar = async (values) => {
        values.servicioId = modalMessage.servicioId;
        values.servicioAutorizado = values.servicioAutorizado === '1' ? true : false;
        await DSystemsServicios.updateServicio(values)
        .then(resp => {
            toast.success(message(`servicio autorizado exitosamente`));
            getListado();
        })
        .catch(err => {
            toast.error(message(`Error al intentar autorizar el servicio`))
        })
        .finally(() => {
            setModalMessage({ isOpen: false })
        });
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />

                    <section className="p-4">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="fw-bold">Autorización de solicitudes</h4>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row mt-2">
                                    <div className="col-lg-6 col-12">
                                        <SelectField
                                            id="servicioAutorizado"
                                            label="Resultado"
                                            name="servicioAutorizado"
                                            items={resultados}
                                            onChange={(event) => {
                                                setFieldValue("servicioAutorizado", event.value);
                                            }}
                                        />
                                    </div>
                                    {
                                        values.servicioAutorizado === '0' &&
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="servicioMotivoRechazo"
                                                label="Motivo"
                                                name="servicioMotivoRechazo"
                                                items={motivos}
                                                onChange={(event) => {
                                                    setFieldValue("servicioMotivoRechazo", event.value);
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentariosRechazo" type="text" holder="Escribe" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-end mt-4">
                            <div className="col-12 col-md-auto">
                                <button className="btn btn-outline" onClick={() => setModalMessage({ isOpen: false })}>
                                    Regresar
                                </button>
                            </div>
                            <div className="col-12 col-md-auto">
                                <button className="btn" onClick={() => handleAutorizar(values)}>
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default AutorizacionServicioModal;