import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Cierres from '../../../Services/Resguardos/Cierres';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const UbicacionResguardoCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, resguardo, resguardoid } = useParams(); // No es resguardo es el GeneralId, pero hubo un error al nombrar la variable

        let initial = {
            resguardoUbicacionId: "",
            resguardoId: resguardoid,
            comentarios: "",
            resguardoUbicacionStatusId: 0,
            resguardoEntregaId: "",
            nombreEntrega: "",
            nombreRecibe: "",
            fechaResguardo: "",
            horaResguardo: "",
            ubicacionId: "",
            resguardoFormaArriboId: "",
            resguardoMotivoId: "",
            valuacion: "",
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            fechaInicio: "",
            fechaConclusion: "",
            concluido: "",
            status: false,
            resguardoUbicacionStatus: "",
            resguardoFormaArribo: "",
            resguardoMotivo: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            documentoDescargado: null
        }


        const navigate = useNavigate();

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [entrega, setEntrega] = useState([]);
        const [ubicacion, setUbicacion] = useState([]);
        const [formaRecibo, setFormaRecibo] = useState([]);
        const [motivo, setMotivo] = useState([]);


        useEffect(() => {
            getUbicacionResguardo();
            getQuienEntrega();
            getFormaRecibo();
            getMotivoResguardo();
            getSucursalesByTipo();
        }, []);

        const getUbicacionResguardo = async () => {
            setLoading(true);
            await Cierres.resguardoUbicacionGet(resguardoid)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        };

        const getSucursalesByTipo = async () => {
            await Catalogo.getSucursalesByTipo(40)
            .then(resp => {
                let items = [];
                resp.data.data.forEach(item => {
                    items.push({
                        value: item.idSuc,
                        label: item.sucursal
                    });
                });
                setUbicacion(items);
            })
            .catch(err => {

            });
        };

        const getQuienEntrega = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.ResguardoEntrega')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.ResguardoEntregaId,
                        label: item.ResguardoEntrega
                    });
                });
                setEntrega(items);
            })
            .catch(err => {

            });
        };

        const getFormaRecibo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.ResguardoFormaArribo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.ResguardoFormaArriboId,
                        label: item.ResguardoFormaArribo
                    });
                });
                setFormaRecibo(items);
            })
            .catch(err => {

            });
        };

        const getMotivoResguardo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.ResguardoMotivo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.ResguardoMotivoId,
                        label: item.ResguardoMotivo
                    });
                });
                setMotivo(items);
            })
            .catch(err => {

            });
        };



        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            if(values.concluido){
                values.resguardoUbicacionStatusId = 30;
            }else{
                values.resguardoUbicacionStatusId = 20;
            }
            
            setLoading(true);
            await Cierres.resguardoUbicacionActualizar(values)
            .then(resp => {
                resp.data.fechaResguardo = resp.data.fechaResguardo?.split("T")[0];
                setInitialValue(resp.data);
                if(values.concluido){
                    cierreContrato();
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }


        const cierreContrato = async () => {
            let params = {
                generalId: resguardo,
                cierreContratoProcesoId: 70,
                cierreContratoStatusId: 10
            }

            await Cierres.cierreContratoCrear(params)
            .then(resp => {
                crearAdeudo(resp.data.cierreContratoId);
                toast.success(message("Se inició el proceso de cierre de contrato correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el proceso de cierre de contrato!"));
            });
        }

        const crearAdeudo = async (cierreContratoId) => {
            let params = {
                cierreContratoId: cierreContratoId,
                cierreContratoAdeudoStatusId: 10
            };

            await Cierres.cierreContratoAdeudoCrear(params)
            .then(resp => {
                toast.success(message("Se inició el proceso de adeudos correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el proceso de adeudos!"));
            });
        }

        const downloadPDF = async () => {
            let params = {
                resguardoId: resguardoid,
                documentoDescargado: true
            }

            setLoading(true);
            await Cierres.resguardoUbicacionActualizar(params)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));




            // const requestBody = {
            //     nombrePlantilla: "InspeccionRapida",
            //     parametros: {
            //         "<<VARIABLE_VIN>>": vin,
            //         "<<VARIABLE_CONTRATO>>": numContrato,
            //         "<<VARIABLE_FECHA>>": initialValue.fecha?.split('T')[0],
            //         "<<VARIABLE_FOLIO>>": initialValue.folioInspeccion,
            //         "<<VARIABLE_CLIENTE>>": cliente,
            //         "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
            //         "<<VARIABLE_HORA_INICIO>>": initialValue.hora.split('.')[0],
            //         "<<VARIABLE_DATAMOVIL>>": "",
            //         "<<VARIABLE_DIRECCION_UBICACION>>": ""
            //     }
            // };

            // Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            //     //Pendiente de revisar por que no jala con el file-server
            //     documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta?.split('T')[0]}.pdf`);

            // }).catch(err => {
            //     console.error(err);
            // });

        }


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseUbicacionResguardo" role="button" aria-expanded="false" aria-controls="collapseUbicacionResguardo" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.resguardoUbicacionStatusId === null ? 'semaforo semaforo-default' : ( initialValue.resguardoUbicacionStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Ubicación de resguardo</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.resguardoUbicacionStatus}</small> */}
                                            <small>{initialValue.resguardoUbicacionStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseUbicacionResguardo">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar documento</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray'>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!isValid || initialValue.resguardoUbicacionStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Documento de inspección de unidad</small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button className='btn btn-gray' onClick={() => downloadPDF()}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="resguardoEntregaId"
                                                                label="¿Quién entrega?"
                                                                disabled={initialValue.documentoDescargado === null || initialValue.concluido}
                                                                name="resguardoEntregaId"
                                                                items={entrega}
                                                                onChange={(event) => {
                                                                    setFieldValue("resguardoEntregaId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="nombreEntrega" disabled={initialValue.documentoDescargado === null || initialValue.concluido} label="Nombre entrega" name="nombreEntrega" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("nombreEntrega", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="nombreRecibe" disabled={initialValue.documentoDescargado === null || initialValue.concluido} label="Usuario recibe" name="nombreRecibe" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("nombreRecibe", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="fechaResguardo" disabled={initialValue.documentoDescargado === null || initialValue.concluido} label="Fecha resguardo" name="fechaResguardo" type="date" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("fechaResguardo", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="horaResguardo" disabled={initialValue.documentoDescargado === null || initialValue.concluido} label="Hora de resguardo" name="horaResguardo" type="time" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("horaResguardo", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="ubicacionId"
                                                                label="Ubicación"
                                                                disabled={initialValue.documentoDescargado === null || initialValue.concluido}
                                                                name="ubicacionId"
                                                                items={ubicacion}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="resguardoFormaArriboId"
                                                                label="Forma de arribo"
                                                                disabled={initialValue.documentoDescargado === null || initialValue.concluido}
                                                                name="resguardoFormaArriboId"
                                                                items={formaRecibo}
                                                                onChange={(event) => {
                                                                    setFieldValue("resguardoFormaArriboId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="resguardoMotivoId"
                                                                label="Motivo de resguardo"
                                                                disabled={initialValue.documentoDescargado === null || initialValue.concluido}
                                                                name="resguardoMotivoId"
                                                                items={motivo}
                                                                onChange={(event) => {
                                                                    setFieldValue("resguardoMotivoId", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios"
                                                                disabled={initialValue.documentoDescargado === null || initialValue.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.documentoDescargado === null || initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Resguardo de unidad concluido</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default UbicacionResguardoCard