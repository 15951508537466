import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import { useParams } from 'react-router-dom'

const ConfirmarGuardadoContratos = ({ isOpen, setIsOpen, handleConfirm, contratosCadena, cobradorDestino }) =>
{

    const [loading, setLoading] = useState(false);
    // const [item, setItem] = useState({ adeudo: false, monto: 0 });

    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-between mx-4'>
                    <span className=''><strong>¿Estás seguro de los cambios?</strong></span>
                </section>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>
                    <div className="mb-3">
                        <label htmlFor="staticEmail" className="col-sm-12 col-form-label">{"¿Esta seguro de reasignar los contratos: " + contratosCadena + " al cobrador destino " + cobradorDestino + "?. Esta acción no se puede deshacer"}</label>
                    </div>
                    <section className='my-3 d-flex justify-content-end'>
                        <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => { setIsOpen(false) }}>Regresar</button>
                        <button id="AT_btnGuardar" className='btn mx-2' onClick={handleConfirm}> Confirmar</button>
                    </section>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ConfirmarGuardadoContratos