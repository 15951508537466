import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { GarantiaReparacionSlice, setIdGarantiaReparacion } from '../../Redux/Slice/reparacionSlice';
import Garantias from '../../Services/Cartera/Garantias';
import { ListaGarantiasReparacionDetalleSlice, setlistaGarantiasReparacionDetalle } from '../../Redux/Slice/listaGarantiasReparacionDetalleSlice';

const AgregarReparacionModal = ({ modalMessage, setModalMessage, handleActualizar }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const [valoresOpcionales, setValoresOpcionales] = useState([{ value: 0, label: "No" }, { value: 1, label: "Si" }]);
    const [aTaller, setATaller] = useState([])
    const [reparacionTipo, setReparacionTipo] = useState([])
    const { idGarantiaReparacion,vin } = useSelector((state) => state.GarantiaReparacionSlice);
    const dispatch = useDispatch();

    const initial = {
        garantiaReparacionId: idGarantiaReparacion,
        reparacionExterna: null,
        tallerId: null,
        comentarios: "",
        aplicaCosto: null,
        subtotal: 0,
        iva: 0,
        total: 0,
        aplicaGarantia: null,
        tiempo: 0,
        GarantiaReparacionDetalleTipoId: null,
        tecnico: "",
    }

    const [initialValue, setInitialValue] = useState(initial)

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        tecnico: Yup.string().required('Campo requerido')
    });

    useEffect(() => {
        getTaller();
        getReparacionTipo();
    }, []);

    const handlerGuardar = (values) => {
        values.garantiaReparacionId = idGarantiaReparacion
        Garantias.reparacionDetalleCrear(values).then(resp => {
            console.log(resp)
            toast.success(message("¡Operación exitosa!", "Reparación registrada correctamente"));
            getListaDetalleReparacion();
            // handleActualizar();
            handleRegresar();
        }).catch(error => {
            toast.error(message("¡Operación fallida!", "Error al agregar una reparación"));
            console.log(error)
        })
    }

    const getListaDetalleReparacion = async (id) => {

        let data = {
            "garantiaReparacionId": idGarantiaReparacion,
            "VIN":vin,
            "page": 1,
            "rows": 100
        }

        await Garantias.garantiaListaReparacionDetalle(data)
            .then(async res => {
                //dispatch(setlistaGarantiasReparacionDetalle(res.data.data))
                let resdata = res.data.data
                let datares = []
                resdata.forEach(element => {
                    let total = (parseFloat(element.subtotal) * parseInt(element.iva) / 100) + parseFloat(element.subtotal)
                    datares.push({
                        fechaAlta: getDateFormat(element.fechaAlta, ""),
                        usuarioAltaNombre: element.usuarioAltaNombre,
                        taller: element.taller,
                        reparacionExterna: element.reparacionExterna ? 'Si' : 'No',
                        subtotal: total,
                        aplicaGarantia: element.aplicaGarantia ? 'Si' : 'No',
                        tiempo: element.tiempo,
                        comentarios: element.comentarios
                    })
                });
                dispatch(setlistaGarantiasReparacionDetalle(datares))
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })

    }

    const getTaller = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Taller')
            .then(resp => {
                let tmpList = [];
                resp.data.forEach(item => {
                    tmpList.push({ value: item.TallerId, label: item.Taller });
                });
                setATaller(tmpList)
            })
            .catch(err => {
                console.error(err);
            })
    }
    const getReparacionTipo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.GarantiaReparacionDetalleTipo')
            .then(resp => {
                let tmpList = [];
                resp.data.forEach(item => {
                    tmpList.push({ value: item.GarantiaReparacionDetalleTipoId, label: item.GarantiaReparacionDetalleTipo });
                });
                setReparacionTipo(tmpList)
            })
            .catch(err => {
                console.error(err);
            })
    }
    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }



    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }


    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            {/* <div className="row">
                                <div className="col col-12">
                                    <h5><strong>Agregar reparación</strong></h5>
                                </div>
                            </div> */}
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación captura los datos de la reparación del vehículo.
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-3 col-md-3">
                                            <TextField
                                                id="tecnico"
                                                disabled={false}
                                                label="Técnico"
                                                name="tecnico"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("tecnico", event.target.value);
                                                }} />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <SelectField
                                                id="reparacionExterna"
                                                label="Reparación externa"
                                                name="reparacionExterna"
                                                items={valoresOpcionales}
                                                onChange={(event) => {
                                                    setFieldValue("reparacionExterna", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <SelectField
                                                id="tallerId"
                                                label="Taller"
                                                name="tallerId"
                                                items={aTaller}
                                                onChange={(event) => {
                                                    setFieldValue("tallerId", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <SelectField
                                                id="GarantiaReparacionDetalleTipoId"
                                                label="Reparación"
                                                name="GarantiaReparacionDetalleTipoId"
                                                items={reparacionTipo}
                                                onChange={(event) => {
                                                    setFieldValue("GarantiaReparacionDetalleTipoId", event.value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-lg-3 col-md-3">
                                            <SelectField
                                                id="aplicaCosto"
                                                label="Aplica Costo"
                                                name="aplicaCosto"
                                                items={valoresOpcionales}
                                                onChange={(event) => {
                                                    setFieldValue("aplicaCosto", event.value);
                                                }}
                                            />
                                        </div>
                                        {
                                            values.aplicaCosto === 1 &&
                                            <>
                                                <div className="col-lg-3 col-md-3">
                                                    <TextField
                                                        id="subtotal"
                                                        disabled={false}
                                                        label="Subtotal"
                                                        name="subtotal"
                                                        type="number"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("subtotal", event.target.value);
                                                        }} />
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <TextField
                                                        id="iva"
                                                        disabled={false}
                                                        label="Iva (%)"
                                                        name="iva"
                                                        type="number"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("iva", event.target.value);
                                                        }} />
                                                </div>
                                                <div className="col-3 col-lg-3">
                                                    <span>Total</span>
                                                    <div style={containerStyle}>
                                                        {(parseFloat(values.subtotal) * parseInt(values.iva) / 100) + parseFloat(values.subtotal)}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-lg-3 col-md-3">
                                            <SelectField
                                                id="aplicaGarantia"
                                                label="Aplica garantía"
                                                name="aplicaGarantia"
                                                items={valoresOpcionales}
                                                onChange={(event) => {
                                                    setFieldValue("aplicaGarantia", event.value);
                                                }}
                                            />
                                        </div>
                                        {

                                            values.aplicaGarantia === 1 &&
                                            <>
                                                <div className="col-lg-3 col-md-3">
                                                    <TextField
                                                        id="tiempo"
                                                        disabled={false}
                                                        label="Tiempo (Días)"
                                                        name="tiempo"
                                                        type="number"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("tiempo", event.target.value);
                                                        }} />
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-lg-12 col-md-12">
                                            <TextAreaField
                                                id="comentarios"
                                                label="Comentarios de reparación"
                                                disabled={false}
                                                className="form-control col-12"
                                                rows="3"
                                                name="comentarios"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handlerGuardar(values)}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default AgregarReparacionModal;
