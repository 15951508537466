import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable';
import VisitasModal from '../../../Components/Modal/VisitasModal';
import Cierres from '../../../Services/Resguardos/Cierres';
import UbicacionResguardoCard from './UbicacionResguardo';
import CardGrua from '../../Garantias/IncidenciasInfo/CardGrua';
import GruaCard from './GruaCard';
import Llamada from '../../../Services/Cartera/Llamadas';

const RevisionCard
    = React.forwardRef(({ ...props }, ref) => {
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { resguardoid } = useParams();

        let initial = {
            resguardoVisitaRevisionUnidadId: "",
            resguardoId: resguardoid,
            comentarios: "",
            resguardoVisitaRevisionUnidadStatusId: 0,
            unidadUbicada: false,
            fechaRecoleccion: "",
            requiereGrua: null,
            confirmarTraslado: null,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            fechaInicio: "",
            fechaConclusion: "",
            concluido: null,
            status: true,
            resguardoVisitaRevisionUnidadStatus: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            detalle: []
        }

        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const [data, setData] = useState([]);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [showNext, setShowNext] = useState(false);
        
        
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const columns = [
            { field: 'fecha', headerName: 'Fecha y hora' },
            { field: 'ejecutivo', headerName: 'Ejecutivo' },
            { field: 'resultado', headerName: 'Resultado' },
            { field: 'condicion', headerName: 'Condicion' },
            { field: 'costos', headerName: 'Costos adicionales' },
        ];

        useEffect(() => {
            resguardoVisitaRevisionUnidadGet();
        }, []);

        const resguardoVisitaRevisionUnidadGet = async () => {
            setLoading(true);
            await Cierres.resguardoVisitaRevisionUnidadGet(resguardoid)
            .then(resp => {
                let detalles = [];
                if(resp.statusCode === 204) {
                    resguardoVisitaRevisionUnidadGet();
                    return false;
                }

                if(resp.data.resguardoVisitaRevisionUnidadStatusId === 40){
                    setShowNext(true);
                }

                resp.data.detalle?.forEach(item => {
                    detalles.push({
                        id: item.resguardoVisitaRevisionUnidadDetalleId,
                        fecha: item.fechaVisita?.split("T")[0]+" "+item.horaVisita+" hrs.",
                        ejecutivo: item.usuarioAltaNombre,
                        resultado: item.resguardoVisitaRevisionUnidadResultado,
                        condicion: item.resguardoCondicionesUnidad,
                        costos: formatMoney(item.costosAdicionales),
                    })
                });
                resp.data.fechaRecoleccion = resp.data.fechaRecoleccion?.split("T")[0];

                resp.data.requiereGrua = resp.data.requiereGrua ? 1 : 0;
                resp.data.concluido = resp.data.concluido ? 1 : 0;
                setInitialValue(resp.data);
                setData(detalles);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const formatMoney = (value) => {
            if(value === undefined){
                return "NA";
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const actualizaVisita = async () => {
            let params = {
                resguardoVisitaRevisionUnidadId: initialValue.resguardoVisitaRevisionUnidadId,
                resguardoVisitaRevisionUnidadStatusId: 20,
                resguardoId: resguardoid,
            }

            await Cierres.resguardoVisitaRevisionUnidadActualizar(params)
            .then(resp => {
                resguardoVisitaRevisionUnidadGet();
            })
            .catch(err => {
                console.error(err);
                // toast.error(message("¡Error, al intentar crear la visita!"));
            });

        };

        const submitForm = async (values) => {
            let params = {
                    comentarios: values.comentarios,
                    unidadUbicada: values.unidadUbicada,
                    fechaRecoleccion: values.fechaRecoleccion,
                    requiereGrua: values.requiereGrua,
                    confirmarTraslado: values.confirmarTraslado,
                    resguardoVisitaRevisionUnidadStatusId: 20,
                    resguardoId: resguardoid,
                    resguardoVisitaRevisionUnidadId: initialValue.resguardoVisitaRevisionUnidadId
                }

            if(values.unidadUbicada){
                params.resguardoVisitaRevisionUnidadStatusId = 30;
            }

            if(values.confirmarTraslado){
                params.concluido = true;
                params.resguardoVisitaRevisionUnidadStatusId = 40;
            }

            await Cierres.resguardoVisitaRevisionUnidadActualizar(params)
            .then(resp => {
                resp.data.fechaRecoleccion = resp.data.fechaRecoleccion?.split("T")[0];
                setInitialValue(resp.data);
                toast.success(message("Registro de la visita / revisión actualizado correctamente"));

                if(values.confirmarTraslado){
                    if(values.requiereGrua){
                        crearGrua();
                    }else{
                        crearUbicacionResguardo();
                    }
                }
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la visita / revisión de la unidad!"));
            })
        }

        const crearGrua = async () => {
            let params = {
                origen: "resguardo",
                origenId: resguardoid,
                gruaStatusId: 10,
            }

            await Llamada.AddGruaLlamada(params)
            .then(resp => {
                toast.success(message("Registro de grúa realizado correctamente"));
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar registrar la grúa!"));
            })
            .finally(() => {
                setTimeout(() => {
                    setShowNext(true);
                }, 1000);
            });
        }

        const crearUbicacionResguardo = async () => {
            let params = {
                resguardoId: resguardoid,
                resguardoUbicacionStatusId: 10
            }
            await Cierres.resguardoUbicacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó el registro de Ubicación resguardo correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el registro de Ubicación resguardo!"));
            })
            .finally(() => {
                setTimeout(() => {
                    setShowNext(true);
                }, 1000);
            });
        }

        const handleEdit = () => {

        };


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={860}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <VisitasModal
                            modalMessage={modalMessage}
                            resguardoVisitaRevisionUnidadId={initialValue.resguardoVisitaRevisionUnidadId}
                            reload={actualizaVisita}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRevision" role="button" aria-expanded="false" aria-controls="collapseRevision" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.resguardoVisitaRevisionUnidadStatusId === null ? 'semaforo semaforo-default' : ( initialValue.resguardoVisitaRevisionUnidadStatusId === 40 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Visita / Revisión de unidad</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.resguardoVisitaRevisionUnidadStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseRevision">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Detalle de visita</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.resguardoVisitaRevisionUnidadStatusId > 20}
                                                                        onClick={(e) => setModalMessage({isOpen: true, type: 3, title: 'Registrar visita'})}>
                                                                        Registrar visita
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!isValid || initialValue.resguardoVisitaRevisionUnidadStatusId === 40}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <DataTable
                                                                column={columns}
                                                                data={data}
                                                                editable={false}
                                                                handleEdit={handleEdit}
                                                                // pageCount={0}
                                                                // currentPage={1 - 1}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Observaciones generales"
                                                                disabled={initialValue.resguardoVisitaRevisionUnidadStatusId > 20}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.resguardoVisitaRevisionUnidadStatusId > 20} type="checkbox" name="unidadUbicada" onClick={(event) => {
                                                                setFieldValue("unidadUbicada", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad lista para traslado</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Información traslado</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="fechaRecoleccion" disabled={initialValue.resguardoVisitaRevisionUnidadStatusId < 30 || initialValue.resguardoVisitaRevisionUnidadStatusId === 40} label="Fecha de recolección" name="fechaRecoleccion" type="date" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("fechaRecoleccion", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="requiereGrua"
                                                                label="¿Se requiere grúa?"
                                                                disabled={initialValue.resguardoVisitaRevisionUnidadStatusId < 30 || initialValue.resguardoVisitaRevisionUnidadStatusId === 40}
                                                                name="requiereGrua"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("requiereGrua", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.resguardoVisitaRevisionUnidadStatusId < 30 || initialValue.resguardoVisitaRevisionUnidadStatusId === 40} type="checkbox" name="confirmarTraslado" onClick={(event) => {
                                                                setFieldValue("confirmarTraslado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Confirmar traslado</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (Number(initialValue.concluido) === 1 && Number(initialValue.requiereGrua) === 1) &&
                    showNext &&
                    <>
                        <GruaCard />
                    </>
                    // <UbicacionResguardoCard resguardoId={resguardoId} />
                }
                {
                    (Number(initialValue.concluido) === 1 && Number(initialValue.requiereGrua) === 0) &&
                    showNext &&
                    <UbicacionResguardoCard />
                }
            </>
        )
    })
export default RevisionCard