import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import CancelacionSeguroService from '../../Services/Seguros/CancelacionSeguroService';
import PolizasCanceladasService from '../../Services/Seguros/SeguroPolizasCanceladasService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const usePolizasPagadas = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'numeroPoliza', headerName: 'Póliza', width: 150 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 150 },
        { field: 'proceso', headerName: 'Proceso', width: 150 },
        { field: 'motivoCancelacion', headerName: 'Motivo cancelación', width: 150 },
        { field: 'usuarioCancelado', headerName: 'Usuario cancelado', width: 150 },
        { field: 'strFechaCancelacion', headerName: 'Fecha cancelación', width: 150 },
        { field: 'usuarioReembolso', headerName: 'Usuario Reembolso', width: 150 },
        { field: 'folioCancelacion', headerName: 'Folio Cancelación', width: 150 },
        { field: 'primaNoDevengadaStr', headerName: 'Prima no devengada', width: 150 },
        { field: 'precioStr', headerName: 'Precio', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso Unidad', width: 100 },
        { field: 'strFechaInicio', headerName: 'Fecha Inicio', width: 100 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [dataMotivo, setdataMotivo] = useState([]);
    const [claveMotivo, setclaveMotivo] = useState("");
    const [claveProceso, setClaveProceso] = useState("");
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);

    const [FechInicio, setFechInicio] = useState("");
    const [FechaFin, setFechaFin] = useState("");

    const [DataEtapa, setDataEtapa] = useState([]);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, claveUsoUnidad, pageRows, Aseguradora, claveMotivo, claveProceso,FechInicio,FechaFin])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();
        handleGetAllAseguradora();
        handleGetAllMotivoCancelacionSeguro();
        handleGetAllEtapa();
    }, [])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await PolizasCanceladasService.getAllPolizasPagadas(page, pageRows, str, claveUsoUnidad, Aseguradora, claveProceso, claveMotivo,FechInicio,FechaFin)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDownloadFile = async () => {
        setLoading(true);
        await PolizasCanceladasService.ExportPolizasPagadas(str, claveUsoUnidad, Aseguradora, claveProceso, claveMotivo,FechInicio,FechaFin)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `PolizasPagadas-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllMotivoCancelacionSeguro = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoCancelacionSeguro')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Seleccione"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.MotivoCancelacionSeguroId,
                        label: item.MotivoCancelacionSeguro
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }

    const handleGetAllEtapa = async () => {
        setLoading(true);
        await EtapaService.getAllEtapa()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaId,
                        label: item.etapa
                    })
                });
                setDataEtapa(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleDownloadFile,
        setTipoCompra,
        setAseguradora,
        DataAseguradora,
        dataMotivo,
        setclaveMotivo,
        claveMotivo,
        setClaveProceso,
        setFechInicio,
        setFechaFin,
        DataEtapa
    }
}

export default usePolizasPagadas