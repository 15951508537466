import { set } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import Logs from '../../Services/Logs/Logs';
import { message } from '../../Utils/functions';

const useSistemaDetail = (toast) => {
  const refBtn = useRef();
  let ids = [];
  const columns = [
    { field: 'logSistemaId', headerName: 'Id', width: 150 },
    { field: 'proceso', headerName: 'Proceso', width: 160 },
    { field: 'dateOnly', headerName: 'Fecha', width: 200 },
    { field: 'hora', headerName: 'Hora', width: 200 },
    { field: 'resultado', headerName: 'Resultado', width: 200 },
    { field: 'contenido', headerName: 'Contenido', width: 250 },
    { field: 'categoria', headerName: 'Categoria', width: 200 },
  ];
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [moduloId, setModuloId] = useState("");
  const [procesoId, setProcesoId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [initialPage, setInitialPage] = useState(0);
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [pageRows, setpageRows] = useState(10)

  useEffect(() => {
    handleGetProceso();
    handleGetCategoria();
  }, [])

  useEffect(() => {
    handleGetAllSistema(1);
    setInitialPage(0);
  }, [Str, dateTime, procesoId, categoriaId,pageRows])

  //selectList
  const handleGetModulo = async () => {
    setLoading(true);
    await Logs.getList("modulo")
      .then(res => {

        setDataModulo(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetProceso = async () => {
    setLoading(true);
    await Logs.getList("proceso")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos los procesos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataProceso(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetCategoria = async () => {
    setLoading(true);
    await Logs.getList("categoria")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todas las categorias"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataCategoria(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllSistema = async (page) => {
    setLoading(true);
    await Logs.getLogSistemaEliminado(page, pageRows, procesoId, categoriaId, Str, dateTime)
      .then(res => {

        setData(res.data.data);
        setPageCount(res.data.totalPages)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetAllSistema(event.selected + 1);
  };
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');


    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    setLoading(true);
    if (idsEliminar.length > 0) {
      let newIds = idsEliminar.join();
      await Logs.exportFileSistema(newIds)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `LogInterfaz-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        
          setLoading(false);
          handleGetAllSistema(1);
          handleUnchekedColumns();
          refBtn.current.disabled = false;
          setIdsEliminar([]);
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false)
      refBtn.current.disabled = false;
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros a exportar")}</div>);
    }

  }
  const handleCheck = (cheked, value) => {
    if (cheked) {      
      setIdsEliminar(current => [...current, value.logSistemaId]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item !== value.logSistemaId)); 
    }
  }
  return {
    columns,
    data,
    pageCount,
    handlePageClick,
    dataProceso,
    dataCategoria,
    setStr,
    setDateTime,
    setCategoriaId,
    setProcesoId,
    initialPage,
    handleDownloadFile,
    refBtn,
    handleCheck,
    loading,
    setpageRows
  }
}

export default useSistemaDetail