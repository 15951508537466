import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { TextField } from '../../../Components/TextField'
import MarcaAPI from '../../../Services/Catalogos/Marca'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo'

const Version = ({ isOpen, setIsOpen, item, handleGetAll, action,itemTbl}) => {

    let initial = {
        versionId: item?.versionId ?? "",
        version: item?.version ?? "",
        status: item?.status ?? false
    }
    const [initialValue, setInitialValue] = useState(initial)
    const validate = Yup.object({
        version: Yup.string()
            .required("Ingrese un nombre.")
            .min(3, 'minimo 3 caracteres')
    });

    const handleEditMarca = async (item) => {
        
        
        if (action === 'add') {
            await Catalogo.addVersion(item)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                }).catch(e => {
                    
                })
        }
        if (action === 'edit') {
            await Catalogo.updateVersion(item)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                }).catch(e => {
                    
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleEditMarca(values);
            }}
        >
            {({ isValid, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                    <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                <div className="col-ms-6 form-check form-switch">
                                    <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                    <Field className="form-check-input" type="checkbox" name="status" />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                    <TextField label="Version" name="version" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' type="submit" disabled={!(isValid)}>Guardar</button>
                                {/* onClick={formik.submitForm} */}
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default Version