import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import { SelectField } from '../SelectField';
import Cierres from '../../Services/Resguardos/Cierres';
import Gestoria from '../../Services/Gestoria/Gestoria';

const GestoriaAdeudosModal = ({ modalMessage, setModalMessage, gestoriaPagoTramitesId, getTramite }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        adeudoId: "",
        subtotal: "",
        iva: "",
        fechaAdeudo: new Date(),
        gestoriaPagoTramitesId: gestoriaPagoTramitesId
    };


    const [tipoAdeudos, setTipoAdeudos] = useState([]);
    const [initialValue, setInitialValue] = useState(initial);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getTipoAdeudos();
    }, []);

    // const validate = Yup.object().shape({});
    
    const validate = Yup.object({
        adeudoId: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        subtotal: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        iva: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
    });

    const getTipoAdeudos = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoAdeudoTipo')
        .then(resp => {
            resp.data.forEach(item => {
                items.push({
                    value: item.CierreContratoAdeudoTipoId,
                    label: item.CierreContratoAdeudoTipo
                });
            });
            setTipoAdeudos(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const submitForm = async (values) => {
        if(values.adeudoId === "" || values.adeudoId === null) {
            toast.error(message("¡Error, El campo Adeudo es requerido!"));
            return false;
        }
        if(values.subtotal === "" || values.subtotal === null) {
            toast.error(message("¡Error, El campo Subtotal es requerido!"));
            return false;
        }
        if(values.iva === "" || values.iva === null) {
            toast.error(message("¡Error, El campo IVA es requerido!"));
            return false;
        }
        setLoading(true);
        await Gestoria.PagoTramitesAdeudosCrear(values)
        .then(resp => {
            toast.success(message("Se creó el Adeudo correctamente"));
            refFormik.current.resetForm();
            getTramite();
            setModalMessage({ isOpen: false });
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crear el Adeudo!"));
            console.error(err);
        })
        .finally(() => setLoading(false));
    };

    const calculateTotal = (values) => {
        let calculo = 0;
        if(values.subtotal.length > 0)
        {
            calculo += parseFloat(values.subtotal);
        }

        if(values.subtotal.length > 0 && values.iva.length > 0)
        {
            calculo = calculo + (calculo * (values.iva/100));
        }

        setTotal(calculo);
    }

    
    const onlyNumbers = (field, value, fn) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            fn(field, value);
        }
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }
    

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación completa todos los campos para el registro de adeudo.
                                    </small>
                                </div>
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-6">
                                        <SelectField id="adeudoId"
                                            label="Tipo de adeudo"
                                            disabled={false}
                                            name="adeudoId"
                                            items={tipoAdeudos}
                                            onChange={(event) => {
                                                setFieldValue("adeudoId", event.value);
                                        }} />
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-6">
                                        <TextField id="subtotal" disabled={false} label="Subtotal ($)" name="subtotal" type="text" holder="Escribe" 
                                        onBlur={(event) => {
                                            calculateTotal(values);
                                        }}
                                        onChange={(event) => {
                                            onlyNumbers("subtotal", event.target.value, setFieldValue);
                                        }} />
                                    </div>
                                    <div className="col-6">
                                        <TextField id="iva" disabled={false} label="IVA (%)" name="iva" type="text" holder="Escribe" 
                                        onBlur={(event) => {
                                            calculateTotal(values);
                                        }}
                                        onChange={(event) => {
                                            onlyNumbers("iva", event.target.value, setFieldValue);
                                        }} />
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-end">
                                    <div className="col-auto">
                                        <strong>Total: {formatMoney(total)}</strong>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-end">
                                    <div className="col-auto">
                                        <button className='btn btn-gray' onClick={(e) => {
                                            setModalMessage({ isOpen: false });
                                            resetForm();
                                        }}>Cancelar</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className='btn'
                                            onClick={(e) => {
                                                submitForm(values);
                                            }}
                                        >Agregar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default GestoriaAdeudosModal;
