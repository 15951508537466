import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { GarantiaReparacionSlice, setIdGarantiaReparacion } from '../../Redux/Slice/reparacionSlice';
import Garantias from '../../Services/Cartera/Garantias';
import { ListaGarantiasReparacionDetalleSlice, setlistaGarantiasReparacionDetalle } from '../../Redux/Slice/listaGarantiasReparacionDetalleSlice';
import Datamovil from '../../Services/Resguardos/Datamovil';
import Gestoria from '../../Services/Gestoria/Gestoria';

const GestoriaPagosModal = ({ modalMessage, setModalMessage, gestoriaPagoTramitesId, getTramite, totalPagar }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const initial = {
        gestoriaPagoTramitesId: gestoriaPagoTramitesId,
        notas: "",
        metodoPagoId: null,
        monto: "",
    }

    const [initialValue, setInitialValue] = useState(initial)
    const [total, setTotal] = useState(0);
    const metodoPago = [{ value: 1, label: "Efectivo" },{ value: 2, label: "Depósito" },{ value: 3, label: "Transferencia" }];

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        notas: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        metodoPagoId: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        monto: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
    });

    useEffect(() => {
    }, []);

    

    const handlerGuardar = async (values) => {
        
        if(values.monto > totalPagar){
            toast.error(message(`¡Error, el monto a registrar no debe ser mayor a ${formatMoney(totalPagar)}!`));
            return false;
        }

        await Gestoria.PagoTramitesPagosCrear(values)
        .then(resp => {
            toast.success(message("Se creó el Pago correctamente"));
            refFormik.current.resetForm();
            getTramite();
            setModalMessage({ isOpen: false });

        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crear el Pago!"));
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }


    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }


    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    concepto: true,
                    metodoPago: true,
                    monto: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación registra el pago correspondiente del adeudo: { formatMoney(totalPagar) }
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="notas"
                                                disabled={false}
                                                label="Notas"
                                                name="notas"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("notas", event.target.value);
                                                }} />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="metodoPagoId"
                                                label="Método de pago"
                                                name="metodoPagoId"
                                                items={metodoPago}
                                                onChange={(event) => {
                                                    setFieldValue("metodoPagoId", event.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="monto"
                                                disabled={false}
                                                label="Monto ($)"
                                                name="monto"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("monto", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default GestoriaPagosModal;
