import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const NavigaSlice = createSlice({
  name: 'catNavigate',
  initialState,
  reducers: {
    tabActive: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActive } = NavigaSlice.actions

export default NavigaSlice.reducer