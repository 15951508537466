import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const InvExterno = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        inventarioExternoId: item?.inventarioExternoId ?? "",
        externo: item?.externo ?? "",
        tipoCompraId: item?.tipoCompraId ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);

    useEffect(() => {
        handleGetAllList()
    }, [])



    const validate = Yup.object({
        externo: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        tipoCompraId: Yup.string()
            .required("El campo es requerido.")
    });
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("TipoCompra", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                setData(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;

        if (action === 'add') {
            await Catalogo.addInventarioExterno(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateInventarioExterno(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <TextField id="Externo" label="Externo" name="externo" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField id="TipoCompra" label="Tipo Compra" name="tipoCompraId" items={data} onChange={(event) => {
                                            setFieldValue("tipoCompraId", event.value)
                                        }} />                                       
                                    </div>
                                </div>                              
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default InvExterno