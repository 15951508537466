import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleRadioCheckItem, handleUnchekedColumns, message } from '../../Utils/functions';
import vehiculoSeparadoService from '../../Services/Venta/Vehiculo/vehiculoSeparadoService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';

const useFirmaContrato = (toast) => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'contrato', headerName: 'Contrato', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 130 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },    
    { field: 'tipoCompra', headerName: 'Tipo de compra', width: 130 },
    { field: 'ubicacion', headerName: 'Ubicación', width: 200 },
    { field: 'producto', headerName: 'Producto', width: 200 },
    { field: 'fecha', headerName: 'Fecha y Hora', width: 200 },
  ];
   

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [claveTipocompra, setclaveTipocompra] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [TipoCompra, setTipoCompra] = useState([]);
  //DataDocs
  const [pageRows, setpageRows] = useState(10)
  const [item, setItem] = useState(null);
  const [isOpenModalLiberar, setIsOpenModalLiberar] = useState(false);
  const [DataUbicacion, setDataUbicacion] = useState([])
  const [claveUbicacion, setclaveUbicacion] = useState("");

  useEffect(() => {
    handleUnchekedColumns()
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [str, pageRows,claveTipocompra,claveUbicacion])
  useEffect(() => {
    handleGetTipoCompra();
    handleGetAllUbicacion();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await vehiculoSeparadoService.GetAllVehiculoFirmaContrato(page, pageRows, str,claveTipocompra,claveUbicacion)
      .then(res => {        
        setData(res.data.data);          
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetTipoCompra = async () => {
    await SeguroPolizaService.getAllTipocompra()
        .then(res => {
            let NewData = [];
            NewData.push({
                value: "",
                label: "Todos"
            })
            res.data.data?.map(item => {
                NewData.push({
                    value: item.tipoCompraId,
                    label: item.tipoCompra
                })
            });
            setTipoCompra(NewData);
        }).catch(e => {
        })
}
const handleGetAllUbicacion = async () => {
    await SeguroSinCotizarService.getlAllUbicacion()
        .then(res => {
            let NewData = [];
            NewData.push({
                value: "",
                label: "Todos"
            })
            res.data.data?.map(item => {
                NewData.push({
                    value: item.ubicacionId,
                    label: item.nombre
                })
            });
            setDataUbicacion(NewData);
        }).catch(e => {

        })
}
  const handlechekedItem = (cheked, value,i) => {
    handleRadioCheckItem(i);

    if (cheked) {
        setItem(value);
    } else {
        setItem(null);
    }
   
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleEdit = (value) => {

    navigate(`/inventario/vehiculo/datadocs/${value.generalId}`, {
        state: { ...value, firmaContrato: true},                
    });

}

  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    handlechekedItem,
    setpageRows,
    setIsOpenModalLiberar,
    isOpenModalLiberar,
    habdleGetAll,
    item,
    TipoCompra,
    setclaveTipocompra,
    DataUbicacion,
    setclaveUbicacion,
    handleEdit
  }
}

export default useFirmaContrato