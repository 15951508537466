import React from 'react'
import Select from "react-select";

const MultiSelect = ({value, options, handleChange, className,holder }) => {

    const data =[{ label: "Todos", value: "all" }, ...options];        

    return (
        <>
            <Select
                placeholder={holder}
                className={className}
                options={data}
                isMulti={true}
                value={value ? value : null}
                onChange={selected => {                    
                        selected.length &&
                        selected.find(option => option.value === "all")
                        ? handleChange(data.slice(1))                    
                        : handleChange(selected);
                }}
            />
        </>
    )
}

export default MultiSelect