import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CitaEnregaUnidadHook from '../../../Hooks/CitaEntregaUnidad/CitaEntregaUnidadHook'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import '../SeguimientoVisita/SeguimientoVisita/SeguimientoVisita.css'

import SolicitudEntregaUnidadModal from './Cards/SolicitudEntregaUnidadModal'

const CitaEntregaUnidad = () => {
    const {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        setpageRows,
        sucursales,
        setSucursal,
        cobradores,
        setCobrador,
        openModalAvanzado,
        setOpenModalAvanzado,
        modalValue,
        textoBusqueda
    } = CitaEnregaUnidadHook();

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Cita entrega de unidad</strong></span>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" /*onKeyUp={(e) => { setTextoBusqueda(e.target.value) }}*/ value={textoBusqueda} onChange={(e) => setTextoBusqueda(e.target.value)} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={sucursales} className='col-12' onChange={(e) => { setSucursal(e.label) }} placeholder="Ubicación" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={cobradores} className='col-12' onChange={(e) => { setCobrador(e.label) }} placeholder="Cobrador" />
                        </div>
                    </div>
                </section>
                <RowPages setpageRows={setpageRows} />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={currentData}
                        detailable={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas).Historial}
                        handleDetail={handleDetails}
                        paginate={true}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currentPage}
                    />
                </div>
            </ValidatePermission>
            {openModalAvanzado && (
                <SolicitudEntregaUnidadModal
                    data={modalValue}  // Aquí debes pasar el objeto `selectedData`
                    setIsOpen={setOpenModalAvanzado}
                    isOpen={openModalAvanzado}
                    toast={toast}
                />
            )}
        </>
    )
}

export default CitaEntregaUnidad