import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DataTable from '../../../../Components/datatable/DataTable';
const SeguimientoProcesoDatamovil = () => {
    const { state } = useLocation();
    const { id } = useParams();

    const [collpsableGrua, setCollpsableGrua] = useState(false);

    const columns = [
        { field: 'idx', headerName: '#', width: '30px' },
        { field: 'usuario', headerName: 'Usuario solicito', width: '150px' },
        { field: 'tipoProceso', headerName: 'Tipo de proceso', width: '200px' },
        { field: 'responsable', headerName: 'Responsable', width: '150px' },
        { field: 'fechaSolicitud', headerName: 'Fecha de solicitud', width: '150px' },
        { field: 'estatus', headerName: 'Estatus', width: '120px' },
        { field: 'ultimoMovimiento', headerName: 'Último movimiento', width: '150px' },
    ];

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        // Lógica de obtener datos
    };

    return (
        <>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div className="row mt-2" data-bs-toggle="collapse" href="#collapseProcesoDatamovil" role="button" aria-expanded="false" aria-controls="collapseProcesoDatamovil" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                            <div className="col-11">
                                <h6><strong>Seguimiento proceso Datamovil</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: 'right' }}>
                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseProcesoDatamovil">
                        <div className="separator"></div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <DataTable 
                                            data={[]}
                                            column={columns}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SeguimientoProcesoDatamovil;
