import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ListingPageComponent from '../../Components/InfinitScrollTable/ListingPageComponent';
import ListingPageContainer from '../../Components/InfinitScrollTable/ListingPageContainer';
import Table from '../../Components/InfinitScrollTable/Table';
import Loadig from '../../Components/Loadig';
import Modal from '../../Components/Modal/Modal';
import IntelimotorAPI from '../../Services/Catalogos/Intelimotor';

const IntelimotorDetail = () => {
  const listInnerRef = useRef();
  const listInnerRefMd = useRef();
  const listInnerRefTA = useRef();


  const { state } = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState([]);
  const [dataMd, setDataMd] = useState([]);
  const [dataTA, setDataTA] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageMd, setCurrentPageMd] = useState(1);
  const [currentPageTA, setCurrentPageTA] = useState(1);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [nextPageMd, setNextPageMd] = useState(true);
  const [nextPageTA, setNextPageTA] = useState(true);
  const [Str, setStr] = useState("");
  const [StrTA, setStrTA] = useState("");
  const [tiempoProces, setTiempoProces] = useState("");


  const columns = [
    { field: 'fechaString', headerName: 'Fecha y hora', width: 230 },
    { field: 'accion', headerName: 'Acción', width: 230 },
    { field: 'resultado', headerName: 'Resultado', width: 200 },
    // { field: 'syncsDetailId', headerName: 'Id', width: 230 },
  ];
  const columnsMd = [
    { field: 'marca', headerName: 'Marca', width: 230 },
    { field: 'modelo', headerName: 'Modelo', width: 230 },
    { field: 'year', headerName: 'Año', width: 200 },
    { field: 'version', headerName: 'Versión', width: 230 },
    // { field: 'syncsDetailId', headerName: 'Tipo adquisición', width: 230 },
  ];
  const columnsTA = [
    { field: 'clave', headerName: 'Clave', width: 230 },
    { field: 'tipoAdquisicion', headerName: 'Tipo Adquisición', width: 230 },
    { field: 'interfaz', headerName: 'Interfaz', width: 200 },
    { field: 'status', headerName: 'Status', width: 230 },
    // { field: 'syncsDetailId', headerName: 'Tipo adquisición', width: 230 },
  ];
  useEffect(() => {
    handleGetHistoryById(id, currentPage, 10);
  }, [currentPage])

  useEffect(() => {
    handleGetModifyList(id, currentPageMd, 10, Str)
  }, [currentPageMd])

  useEffect(() => {
    handleGetTA(id, currentPageTA, 10, Str)
  }, [currentPageTA])


  const handleGetHistoryById = async (Id, page, rows) => {
    
    if (nextPage) {
      setLoading(true);
      await IntelimotorAPI.getSyncHistoryById(Id, page, rows)
        .then(res => {
          let dat = res.data.data;
          
          
          setTiempoProces(dat[0].tiempoProceso)
          setData([...data, ...dat]);
          setNextPage(res.data.hasNextPage);
          setLoading(false);
        }).catch(e => {
          
          setLoading(false);
        })
    }

  }

  const handleGetTA = async (Id, page, rows, StrTA) => {
    if (nextPageTA) {
      setLoading(true);
      await IntelimotorAPI.getModifyTA(page, rows, Id, StrTA)
        .then(res => {
          
          let dat = res.data.data;
          setDataTA([...dataTA, ...dat]);
          setNextPageTA(res.data.hasNextPage);
          setLoading(false);
        }).catch(e => {
          
          setLoading(false);
        })
    }
  }

  const handleGetModifyList = async (Id, page, rows, str) => {
    if (nextPageMd) {
      setLoading(true);
      await IntelimotorAPI.getModifyList(page, rows, Id, str)
        .then(res => {
          
          let dat = res.data.data;
          setDataMd([...dataMd, ...dat]);
          setNextPageMd(res.data.hasNextPage);
          setLoading(false);
        }).catch(e => {
          
          setLoading(false);
        })
    }
  }

  const handleChange = async (event) => {
    setStr(event.target.value);
    if (nextPageMd) {
      setLoading(true);
      await IntelimotorAPI.getModifyList(1, 10, id, Str)
        .then(res => {
          let dat = res.data.data;
          setDataMd(dat);
          setNextPageMd(res.data.hasNextPage);
          setLoading(false);
        }).catch(e => {
          
          setLoading(false);
        })
    }
  }
  const handleChangeTA = async (event) => {
    setStrTA(event.target.value);
    if (nextPageTA) {
      setLoading(true);
      await IntelimotorAPI.getModifyTA(1, 10, id, StrTA)
        .then(res => {
          let dat = res.data.data;
          setDataTA(dat);
          setNextPageTA(res.data.hasNextPage);
          setLoading(false);
        }).catch(e => {
          
          setLoading(false);
        })
    }
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      
      if (scrollTop + clientHeight >= scrollHeight) {
        
        setCurrentPage(currentPage + 1);
      }
    }
  };
  const onScrollMd = () => {
    if (listInnerRefMd.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRefMd.current;
      
      if (scrollTop + clientHeight >= scrollHeight) {
        setCurrentPageMd(currentPageMd + 1);
      }
    }
  };

  const onScrollTA = () => {
    if (listInnerRefTA.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRefTA.current;
      
      if (scrollTop + clientHeight >= scrollHeight) {
        setCurrentPageTA(currentPageTA + 1);
      }
    }
  };
  return (
    <>
      <Modal isOpen={loading} color='transparent'><Loadig message={"Procesando información..."} /></Modal>
      <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-4'>
        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
        Regresar a catálogo {state.page}
      </header>
      <section className='mx-5 my-4'>
        <span className='fs-5'><strong>Detalles de ejecución</strong></span>

        <i className="icon bi bi-clock" style={{ marginLeft: '30px' }}></i>
        <span>Tiempo de procesamiento: {tiempoProces}</span>

      </section>
      <div className='mx-4'>
        <ListingPageComponent
          onScroll={onScroll}
          listInnerRef={listInnerRef}
          data={data}
          columns={columns}
          height={280}
        />
      </div>
      <div className='mx-4'>
        <section className='my-4'>
          <span className='fs-5'><strong>Modificaciones</strong></span>

          <div className='col-4 d-flex'>
            <div className="col-6 mx-1 form-group inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={handleChange} />
            </div>
          </div>

        </section>
        <ListingPageComponent
          onScroll={onScrollMd}
          listInnerRef={listInnerRefMd}
          data={dataMd}
          columns={columnsMd}
          height={300} />
      </div>
      <div className='mx-4 mb-5'>
        <section className='my-4'>
          <span className='fs-5'><strong>Tipo Adquisición</strong></span>

          <div className='col-4 d-flex'>
            <div className="col-6 mx-1 form-group inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={handleChange} />
            </div>
          </div>

        </section>
        <ListingPageComponent
          onScroll={onScrollTA}
          listInnerRef={listInnerRefTA}
          data={dataTA}
          columns={columnsTA}
          height={300} />
      </div>
    </>
  )
}

export default IntelimotorDetail