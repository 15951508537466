import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   strBusqueda: null,
   fechaInicio: null,
   fechaFin: null,
   concluido: null
}

export const ParametrosBusquedaSlice = createSlice({
   name: 'ParametrosBusquedaSlice',
   initialState,
   reducers: {
      setStrBusqueda: (state, action) => {
         return{
            ...state, 
            strBusqueda: action.payload,
         }
      },
      setFechaInicio: (state, action) => {
         return {
            ...state,
            fechaInicio: action.payload
         }
      },
      setFechaFin: (state, action) => {
         return {
            ...state,
            fechaFin: action.payload
         }
      },
      setConcluido: (state, action) => {
         return {
            ...state,
            concluido: action.payload
         }
      },
      setLimpiarDatos: (state, action) => {
         return {
            ...state,
            strBusqueda: null,
            fechaInicio: null,
            fechaFin: null,
            concluido: null
         }
         }
      }
})

export const { setStrBusqueda, setFechaInicio, setFechaFin, setConcluido, setLimpiarDatos } = ParametrosBusquedaSlice.actions

export default ParametrosBusquedaSlice.reducer