import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, handleUnchekedColumns, message } from '../../Utils/functions';
import ComisionesService from '../../Services/Seguros/ComisionesService';
const usePendientePagoPagoComisiones = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: "Contrato", width: 150 },
        { field: 'numeroPoliza', headerName: 'Póliza', width: 150 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 150 },
        { field: 'agenteSeguro', headerName: 'Agente de seguros', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso de unidad', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        // { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        // { field: '', headerName: 'Tipo', width: 100 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'strFechaInicio', headerName: 'Fecha inicio', width: 150 },
        { field: 'precioStr', headerName: 'Precio', width: 100 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [IdsExportar, setIdsExportar] = useState([]);
    const [openModalTotales, setopenModalTotales] = useState(false);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechaFin, setFechaFin] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, TipoCompra, claveUsoUnidad, pageRows, Aseguradora, FechInicio, FechaFin])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();
        handleGetAllAseguradora();
    }, [])

    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await ComisionesService.getAllPendientesDePagoComision(page, pageRows, str, claveUsoUnidad, TipoCompra, Aseguradora ?? "", FechInicio, FechaFin)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
                setIdsExportar([]);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                setTimeout(() => {
                    if ((Aseguradora ?? "") !== "") {
                        handleIsActiveCheck(true);
                    } else {
                        handleIsActiveCheck(false);
                    }
                }, 500);
            })
    }
    const handleDownloadExcel = () => {
        // refBtn.current.disabled = true;
        if (IdsExportar.length > 0) {
            setopenModalTotales(true);
        } else {
            //     refBtn.current.disabled = false;
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros  para exportar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });
    }

    const handlechekedItem = (cheked, value) => {       
        if(value.precioStr === 'Sin cálculo'){
            handleUnchekedColumns()
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No es posible generar el reporte, asegurese de configurar las comisiones de la aseguradora.")}</div>);
            setItem(null);
            setTimeout(() => {
              toast.remove();
            }, 2000);
            return;
          }
        if (cheked) {
            setIdsExportar(current => [...current, value]);
        } else {
            setIdsExportar(IdsExportar.filter(item => item.generalId !== value.generalId));
        }        
    }
    const handleCostos = () => {
        let total = 0;
        IdsExportar?.map(x => {
            total = total + x.precio
        })
        return total;
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar
    }
}

export default usePendientePagoPagoComisiones