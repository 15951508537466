import { useEffect, useRef, useState } from "react";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { message } from "../../../Utils/functions";

const AsignacionServiciosAsignadosHook = ({ servicioAsignacion, onUpdated }) => {
    const refFormik = useRef();
    const refSelect = useRef();
    const initial = {

    }
    const { folioId } = useParams();

    const [data, setData] = useState([]);
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [tipoServicios, setTipoServicios] = useState([]);


    /* busquedas */
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [tipoServicio, setTipoServicio] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [servicios, setServicios] = useState([]);
    const [totalServicios, setTotalServicios] = useState(0); 
    
    const tipoUbicaciones = [
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' },
    ];

    const columns = [
        { field: 'contrato', headerName: 'Contrato', width: '150px' },
        { field: 'vin', headerName: 'VIN', width: '150px' },
        { field: 'tipoServicio', headerName: 'Tipo de servicio', width: '150px' },
        { field: 'proceso', headerName: 'Proceso', width: '150px' },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', width: '150px' },
        { field: 'tipoUbicacion', headerName: 'Tipo de Ubicación', width: '150px' },
        { field: 'ubicacion', headerName: 'Ubicación', width: '150px' },
    ];

    
    useEffect(() => {
        getServiciosAsignados();
    }, []);

    useEffect(() => {
        getServiciosAsignados();
    }, [buscar]);


    const getServiciosAsignados = async () => {
        const params = {
            buscar,
            folioId,
            tecnicoId: '',
            tipoServicioId: '',
            tipoUbicacion,
            page,
            rows,
        }
        setLoading(true);
        await DSAsignaciones.getSeguimientoAsignadosTecnico(params)
        .then(resp => {
            const items = resp.data.data.map(item => {
                item.fechaHoraCita = getOnlyDateFormat(item.fechaCita)+" "+item.horaCita;
                return item;
            });
            setData(items);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getOnlyDateFormat = (date) => {
        return date.split("T")[0].split("-").reverse().join("-");
    }

    const handleCheck = (evecheck, item, int) => {
        let items = servicios;

        if(evecheck)
        {
            items.push(item);
        }else{
            const idx = (element) => element.servicioId === item.servicioId
            const idxFound = items.findIndex(idx)
            items.splice(idxFound, 1)
        }

        setTotalServicios(items.length);
    }

    const handleDesasignar = async () => {
        let ServiciosId = [];
        servicios.forEach(servicio => {
            ServiciosId.push(servicio.servicioId);
        });
        
        let params = {
            servicioAsignacion,
            servicios: ServiciosId
        }
        
        setLoading(true);
        await DSAsignaciones.postDesasignarServicios(params)
        .then(resp => {
            toast.success(message('Servicios desasignados'));
            setModalMessage({ isOpen: false });
            getServiciosAsignados();
            onUpdated();
        })
        .catch(err => {
            toast.error(message('¡Algo salió mal, no se pudieron agregar los servicios!'));
        })
        .finally(() => {
            setLoading(false);
        })
    }
    
    return {
        refFormik,
        refSelect,
        data,
        initialValue,
        modalMessage, 
        setModalMessage,
        loading, 
        setLoading,
        tipoServicios,
        tipoUbicaciones,
        columns,
        handleCheck,
        handleDesasignar,
        setBuscar,
        getServiciosAsignados
    };
}

export default AsignacionServiciosAsignadosHook;