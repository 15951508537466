import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ValuacionRegistroCostoService from "../../Services/Inventario/Valuacion/ValuacionRegistroCostoService";
import toast from "react-hot-toast";
import { message } from "../../Utils/functions";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";

const useInspeccionRapida = () => {
    const { id } = useParams();
    const { refFormik } = useRef();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        valuacionId: id,
        valuacionInspeccionUnidadId: '',
        documentoDescargado: false,
        vehiculoApto: null,
        observacionesUnidad: '',
        informacionAdicional: '',
        concluido: false,
        fechaInicio: '',
        fechaFin: '',
    };

    const [initialValue, setInitialValue] = useState(initial);
    const optionsAptoInventario = [
        { value: "1", label: 'Sí'},
        { value: "0", label: 'No'},
    ];

    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [loading, setLoading] = useState(false);
    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    const [stepe, setStepe] = useState([])
    const [stepSelected, setstepSelected] = useState();
    const [modalConfirm, setModalConfirm] = useState(false);
    const [valuacionInspeccionUnidadId, setValuacionInspeccionUnidadId]  = useState('');
    const [costos, setCostos] = useState([]);

    const columns = [
        { field: 'idx', headerName: '#', width: 180 },
        { field: 'fechaHora', headerName: 'Fecha y hora', width: 240 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 180 },
        { field: 'concepto', headerName: 'Concepto', width: 180 },
        { field: 'subtotal', headerName: 'Subtotal', width: 180 },
        { field: 'iva', headerName: 'IVA', width: 180 },
        { field: 'total', headerName: 'Total', width: 180 },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];

    
    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar]);

    useEffect(() => {
        getDataValuacion();
    }, []);

    const getDataValuacion = async () => {
        setLoading(true);
        await ValuacionesServices.getDataInspeccion(id)
        .then(resp => {
            resp.data.vehiculoApto = resp.data.vehiculoApto ? "1" : "0";
            resp.data.observacionesUnidad = resp.data.observacionesUnidad === null ? '' : resp.data.observacionesUnidad;
            resp.data.informacionAdicional = resp.data.informacionAdicional === null ? '' : resp.data.informacionAdicional;

            if(resp.data.concluido){
                setactivebtncontinuar(true);
            }

            setValuacionInspeccionUnidadId(resp.data.valuacionInspeccionUnidadId);
            getCostosInspeccion(resp.data.valuacionInspeccionUnidadId);
            setInitialValue(resp.data);
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleGetAllStep = async () => {
        setLoading(true)
        await ValuacionRegistroCostoService.getAllStepe(id,state.procesoId)
            .then((res) => {
                let step = res.data.data;
                step.map(x => {
                    if (x.pasoSubStatus === 2) {
                        setstepSelected(x);
                        return;
                    }
                })
                setStepe(step);                
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    
    const handleClickTerminar = async () => {
        setModalConfirm(true);
    }
    
    const handleClickTerminarProceso = async () => {
        setModalConfirm(false)
        setLoading(true)
        let data = {
            id: id,
            paso: stepSelected.paso,
            porcentaje: stepSelected.porcentaje,
            pasoSubStatus: 3,
            procesoId: state.procesoId
        }
        await ValuacionRegistroCostoService.updStep(data)
            .then((res) => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    //navegar al siguiente step
                    if (stepSelected.paso !== 4)
                        navigate(`/inventario/valuacion/${id}/${stepSelected.paso + 1}`, {
                            state: state
                        })
                } else {
                    toast.error(message("¡Operación fallida!", res.data.data.mensaje));
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    
    const handleChangeStatusBtn = (item) => {
        if (item.concluido) {
            setactivebtncontinuar(true)
        }
    }

    const getDateFormat = (fecha, msj) => {
        if(fecha === undefined || fecha === ''){
            return false;
        }
        let d = new Date(fecha + "Z");
        let format = "";
        if (fecha !== undefined) {
            format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
            return msj + format;
        }
    }

    const [page, setPage] = useState(1);

    const getCostosInspeccion = async (id) => {
        await ValuacionesServices.getCostos({ id: id, page: page, rows: 10 })
        .then(resp => {
            let costosTmp = [];
            resp.data.data.forEach((item, idx) => {
                costosTmp.push({
                    idx: (idx + 1),
                    ...item
                })
            });

            setCostos(costosTmp);
        })
        .catch(err => {
            console.error(err);
        })
    }

    const downloadPDF = () => {        
        // const requestBody = {
        //     nombrePlantilla: "InspeccionRapida",
        //     parametros: {
        //         "<<VARIABLE_VIN>>": ,
        //         "<<VARIABLE_CONTRATO>>": ,
        //         "<<VARIABLE_FECHA>>": ,
        //         "<<VARIABLE_FOLIO>>": ,
        //         "<<VARIABLE_CLIENTE>>": ,
        //         "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
        //         "<<VARIABLE_HORA_INICIO>>": ,
        //         "<<VARIABLE_DATAMOVIL>>": "",
        //         "<<VARIABLE_DIRECCION_UBICACION>>": ""
        //     }
        // };

        
        // Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
        //     documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
        // }).catch(err => {
        //     console.error(err);
        // });

        let values = {
            valuacionId: id,
            documentoDescargado: true,
        }

        submitForm(values);
    }

    const submitForm = async (values = {}) => {
        setLoading(true);

        if(values.vehiculoApto){
            values.vehiculoApto = values.vehiculoApto === "1" ? true : false;
        }

        if(values.concluido){
            values.procesoId = state.procesoId;
            values.paso = 2;
            values.porcentaje = 100;
            values.status = 2;
        }

        await ValuacionesServices.putDataInspeccion(values)
        .then(resp => {
            
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false);
            getDataValuacion();
        });
    }

    const handleEdit = () => {};
    const handleDelete = () => {};
    const handleViewdocs = () => {};
    const [pageCount, setPageCount] = useState(1);
    const handlePageClick = () => {};
    

    return {
        initialValue,
        setInitialValue,
        refFormik,
        optionsAptoInventario,
        getDateFormat,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        getCostosInspeccion,
        downloadPDF,
        submitForm,
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        columns,
        valuacionInspeccionUnidadId,
        costos,
        handleEdit,
        handleDelete,
        handleViewdocs,
        pageCount,
        handlePageClick,
        page
    }
}

export default useInspeccionRapida;