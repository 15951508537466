import axios from '../../Utils/ApiService';

const CancelacionSeguroService = {
    getAllSeguroSinPago: async (page, rows, string, usoUnidadId, tipoCompraId, AseguradoraId) => {
        return await axios.get(`/functions/GetAllSeguroSinPago?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&AseguradoraId=${AseguradoraId}&Buscar=${string}`);
    },
    getAllSeguroPagado: async (page, rows, string, usoUnidadId, tipoCompraId, AseguradoraId) => {
        return await axios.get(`/functions/GetAllSeguroPagado?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&AseguradoraId=${AseguradoraId}&Buscar=${string}`);
    },
    DeleteExportSinPago: async (generalIds, motivoId, comentarios, aseguradoraId) => {
        return await axios.get(`/functions/EliminarExportarSeguroSinPago?Ids=${generalIds}&Motivo=${motivoId}&Comentarios=${comentarios}&AseguradoraId=${aseguradoraId}`, { responseType: 'blob' });
    },
    ExportSeguroPagado: async (data) => {
        return await axios.get(`/functions/ExportarSeguroPagado?Ids=${data.ids}&Folio=${data.folio}&TotalVehiculos=${data.totalVehiculos}&TotalReembolso=${data.totalReembolso}&AseguradoraId=${data.aseguradoraId}`, { responseType: 'blob' });
    },
    getAllPendientesDesembolso: async (page,rows,UsoUnidadId,TipoCompraId,AseguradoraId,FechaInicio,FechaFin,str) => {
        return await axios.get(`/functions/GetAllPendientesDesembolso?page=${page}&Rows=${rows}&UsoUnidadId=${UsoUnidadId}&TipoCompraId=${TipoCompraId}&AseguradoraId=${AseguradoraId}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`);
    },
    SavePendientesDesembolsoPago:async(data)=>{
        return axios.post('/functions/RembolsoPolizasCanceladasPagadas',data);
    },
    ReExportSeguroPagado: async (folio) => {
        return await axios.get(`/functions/ReExportarSeguroPagado?Folio=${folio}`, { responseType: 'blob' });
    },
}

export default CancelacionSeguroService