import React from 'react'
import Select from 'react-select'
import { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import useResguardoUnidad from '../../../Hooks/Inventario/useResguardoUnidad'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import { useSelector } from 'react-redux'
import ModalBusquedaSiniestro from './Cards/ModalBusquedaSiniestro'
import { useState } from 'react'

const ResguardoUnidad = () => {
    const { item, isOpenModalClave } = useSelector((state) => state.quickActionsSlice);
    const { columns,
        currentData,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        setpageRows,
        opcionesProceso,
        setClaveProceso,
        opcionesUbicacion,
        setClaveUbicacion,
        handleDetails,
        setTextoBusqueda,
    } = useResguardoUnidad();

    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    const [dataFiltro, setDataFiltro] = useState({
        page: 1,
        rows: 10,
        buscar: "",
        tipoSiniestroId: "",
        estatus: "",
        //Filtor vanzado
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Resguardo de unidad</strong></span>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ResguardoUnidadSection, SubModule.ResguardoUnidad).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={opcionesProceso} className='col-12' onChange={(e) => { setClaveProceso(e.label) }} placeholder="Proceso" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={opcionesUbicacion} className='col-12' onChange={(e) => { setClaveUbicacion(e.label) }} placeholder="Ubicación" />
                        </div>
                        <div className="col-12 col-md-auto my-1 px-1">
                            <button className='btn btn-blue me-2' onClick={() => { setOpenModalAvanzado(true) }}>
                                Búsqueda Avanzada
                            </button>
                            <button className='btn btn-blue' onClick={() => {
                                setDataFiltro({
                                    page: 1,
                                    rows: 10,
                                    buscar: "",
                                    tipoSiniestroId: "",
                                    estatus: "",
                                    //Filtor vanzado
                                    strAvanzado: "",
                                    fechaInicio: "",
                                    fechaFin: "",
                                    concluido: false,
                                    busquedaAvanzada: false
                                })
                            }}>
                                Limpiar filtro
                            </button>
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={currentData}
                        detailable={ValidatePermissionById(4, Section.ResguardoUnidadSection, SubModule.ResguardoUnidad).Historial}
                        handleDetail={handleDetails}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                </div>
            </ValidatePermission>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            {
                openModalAvanzado &&
                <ModalBusquedaSiniestro
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
                />
            }
        </>
    )
}

export default ResguardoUnidad