import { Formik, Form } from 'formik'
import React, { useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import { FormatNumberMoney2, NumFolio, handleUnchekedColumns, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import Spinner from '../../../Components/Loadig';

const ModalConfirmPendienteDesembolso = ({ isOpen, setIsOpen, handleGetAll, toast, item }) => {
    const refbtn = useRef();
    const { comentarios, totalAProcesar, totalRegistro, totalSinProcesar, dataAProcesar } = item;
    let initial = {
        comentarios: comentarios ?? "",
        dataAProcesar: dataAProcesar
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (value) => {
        if (totalAProcesar == 0) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No hay registros por procesar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setLoading(true)
        await CancelacionSeguroService.SavePendientesDesembolsoPago(value)
            .then(res => {
                setLoading(false);
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", "Registros procesados correctamente."));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Registros por procesar</strong></span>
                            <span style={{ fontSize: '13px' }}>A continuación se muestran los registros con la opción para confirmar o cancelar la carga</span>
                        </div>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <span style={{ fontSize: '14px' }}><strong>Total de registros:</strong> {totalRegistro}</span>
                                    <span style={{ fontSize: '14px' }} className='py-3'><strong>Registros por procesar:</strong> {totalAProcesar}</span>
                                    <span style={{ fontSize: '14px' }}><strong>registros Pendientes:</strong> {totalSinProcesar}</span>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline col-4' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2 col-4' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default ModalConfirmPendienteDesembolso