import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayCitaDatamovil: "",
    hourCitaDatamovil: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdCitaDatamovil: "",
}

export const CitaDatamovilSlice = createSlice({
    name: 'CitaDatamovilSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayCitaDatamovil: action.payload.day,
                hourCitaDatamovil: action.payload.hour,
                calendarioIdCitaDatamovil: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayCitaDatamovil: "",
                hourCitaDatamovil: "",
                calendarioIdCitaDatamovil: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = CitaDatamovilSlice.actions

export default CitaDatamovilSlice.reducer