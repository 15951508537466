import React from 'react'
import DataTable from '../../Components/datatable/DataTable'
import EditarCorreos from './EditarCorreos';
import useCorreos from '../../Hooks/Correos/useCorreos';
import Modal from '../../Components/Modal/Modal';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import Spinner from '../../Components/Loadig';
import { pageRows } from '../../Utils/functions';
import Select from 'react-select'
import { Section, SubModule } from '../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import RowPages from '../../Components/RowPages';
const ConfCorreos = () => {
    let { columns,
        data,
        item,
        isOpenModal,
        setisOpenModal,
        handleEdit,
        modalMessage,
        setModalMessage,
        loading,
        handleGetallTipoCorreo,
        setpageRows,
        PageCount,
        handlePageClick,
        currentPage,
        setStr } = useCorreos();
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            {
                <Modal isOpen={modalMessage.isOpen} color='#fff'>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }

                </Modal>
            }
            {
                isOpenModal &&
                <EditarCorreos
                    isOpen={isOpenModal}
                    setIsOpen={setisOpenModal}
                    item={item}
                    handleGetAll={handleGetallTipoCorreo}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                />
            }
            <section className='mx-5 pt-4 d-flex flex-column'>
                <span className='fs-5'><strong>Configuración</strong></span>
                <span className='mt-2'>Se muestra la configuración de los tipos de correos registrados junto a su estatus y gravedad.</span>
            </section>
            <section className='mt-3 mx-4'>
                <div className='col-lg-4 col-sm-12 d-flex'>
                    <div className="col-6 mx-1  inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                    </div>                   
                </div>
            </section>
            <ValidatePermission
                isActive={ValidatePermissionById(4, Section.TiposDeCorreo, SubModule.Notificaciones).Ver}
            >
                <section className=''>
                    <RowPages
                        setpageRows={setpageRows}
                    />
                    <div className='mx-4 table-responsive'>
                        <DataTable
                            column={columns}
                            data={data}
                            editable={ValidatePermissionById(4, Section.TiposDeCorreo, SubModule.Notificaciones).Actualizar}
                            handleEdit={handleEdit}
                            pageCount={PageCount}
                            paginate={true}
                            currentPage={currentPage - 1}
                            handlePageClick={handlePageClick}
                        />
                    </div>
                </section>
            </ValidatePermission>
        </>
    )
}

export default ConfCorreos