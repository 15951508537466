import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import { useDispatch, useSelector } from 'react-redux';
import { chekItem } from '../../Redux/Slice/quickActionsSlice';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { message } from '../../Utils/functions';
import AuthLogin from '../../Utils/AuthLogin';

const auth = new AuthLogin();
const useInventarioSocioComercial = (toast) => {
  const { sociocomercilaId, role } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 160 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    // { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
    // { field: 'nombreUbicacion', headerName: 'Ubicación', width: 180 },
    { field: 'etapaActual', headerName: 'Etapa', width: 150 },
    { field: 'producto', headerName: 'Producto', width: 230 },
    { field: '', headerName: 'Documentos', width: 80 },
    // { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 400 },
  ];
  const columnsH = [
    { field: 'fechaHora', headerName: 'Fecha Inicio', width: 400 },
    { field: 'fechaFin', headerName: 'Fecha Fin', width: 400 },
    { field: 'nombreEmpleado', headerName: 'Nombre del empleado', width: 300 },
    { field: 'comentarios', headerName: 'Comentarios', width: 340 },
  ];
  let DataFiltroFolder = [
    { label: "Todos", value: "" },
    { label: "Vehículos con folder", value: true },
    { label: "Vehículos sin folder", value: false },
  ];
  // let dataUtilitario = [
  //   { label: "Todos", value: "" },
  //   { label: "Vehículos utilitarios", value: 1 },
  //   { label: "Vehículos no utilitarios", value: 0 },
  // ];

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [tipoAd, setTipoAd] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
  const [DataEtapa, setDataEtapa] = useState([]);
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [dataModal, setDataModal] = useState([]);
  const [IsOpenModal, setIsOpenModal] = useState(false)
  const [currenPageH, setCurrenPageH] = useState(1);
  const [utilitarioId, setUtilitarioId] = useState("");
  //Filtros
  const [EtapaId, setEtapaId] = useState("");
  const [dtaSocioComercial, setDtaSocioComercial] = useState([]);
  const [claveSocioComercial, setClaveSocioComercial] = useState(role === "DMSocioComercial" ? auth.getSesionValue("socioComercialId") ?? "" : "");
  const [pageRows, setpageRows] = useState(10)
  // const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>

  //
  useEffect(() => {
    if (str !== "" || EtapaId !== "" || claveSocioComercial !== "") {
      setCurrenPage(1);
      habdleGetAll(1);
    } else {
      habdleGetAll(currenPage);
      setCurrenPage(1);
    }
  }, [str, EtapaId, claveSocioComercial, pageRows])
  useEffect(() => {
    handleGetSocioComercial();
    handleGetAllEtapa();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await SocioComercialServices.getAllSocioComercial(page, pageRows, EtapaId, claveSocioComercial, str)
      .then(res => {

        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetSocioComercial = async () => {
    await SocioComercialServices.getAllSocioComercialList()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })

        res.data.data?.map(item => {
          NewData.push({
            value: item.socioComercialId,
            label: item.nombre
          })
        });
        setDtaSocioComercial(NewData);
      }).catch(e => {

      })
  }
  const handleGetAllEtapa = async () => {
    setLoading(true);
    await EtapaService.getAllEtapa()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.etapaId,
            label: item.etapa
          })
        });
        setDataEtapa(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleDataHistory = async (page, id) => {

    setLoading(true);
    await VehiculoService.getAllHistoryVehiculoUtilitario(id, page, 10)
      .then(res => {

        setDataModal(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        // if (e.response !== undefined)
        //     toast.error(message("¡Error.!", e.response.data.message));
        // else
        //     toast.error(message("¡Error.!", e.message));
      })
  }
  const handlePageClickH = (event) => {
    setCurrenPageH(event.selected + 1);
    handleDataHistory(event.selected + 1, dataVehiculo.generalId);
  };
  const handleEdit = (value) => {
    if (role === "DMSocioComercial") {
      navigate(`/inventario/vehiculo/prealta/${value.generalId}`, {
        state: { ...value, esSocioComercial: true }
      });
    } else {
      if (value.etapaId === 1) {
        navigate(`/inventario/vehiculo/prealta/${value.generalId}`, {
          state: { ...value, esSocioComercial: true }
        });
      } else if (value.etapaId === 2) {
        navigate(`/inventario/vehiculo/alta/${value.generalId}`, {
          state: { ...value, esSocioComercial: true }
        });
      } else if (value.etapaId === 3 || value.etapaId === 11 || value.etapaId === 5 || value.etapaId === 15) {
        navigate(`/inventario/vehiculo/valoracion/${value.generalId}`, {
          state: { ...value, esSocioComercial: true }
        });
      } else {
        navigate(`/inventario/vehiculo/prealta/${value.generalId}`, {
          state: value
        });
      }
    }
  }
  const handleViewdocs = (item) => {
    if (item.numIdFolder !== null) {
      navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
        state: {...item,esSocioComercial:true},
      });
    } else {
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);
    }

  }
  const handleCreateFolder = (item, tipo) => {
    setDataVehiculo(item);
    if (tipo === 1) {
      setIsOpenDocs(true)
    } else if (tipo === 2) {
      handleDataHistory(1, item.generalId);
      setIsOpenModal(true)
    } else if (tipo === 3) {

      handleDeleteUtilitario(item.generalId);
    }
  }
  const handleDeleteUtilitario = async (id) => {
    await VehiculoService.updDesvincularVehiculo(id)
      .then(res => {
        toast.success(message("¡Correcto.!", res.data.data.mensaje));
        habdleGetAll(1);
      }).catch(e => {
        if (e.response !== undefined)
          toast.error(message("¡Error.!", e.response.data.message));
        else
          toast.error(message("¡Error.!", e.message));
      })
  }
  const handleUnchekedColumns = (check, item, i) => {
    check ? dispatch(chekItem({ check, item: { ...item, ventaContado: false } })) : dispatch(chekItem({ check, item: null }));

    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      if (index !== i)
        element.firstChild.firstChild.checked = false;
    }
  }
  return {
    columns,
    columnsH,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    DataFiltroFolder,
    habdleGetAll,
    setIsOpenModal,
    dataModal,
    IsOpenModal,
    handlePageClickH,
    currenPageH,
    dtaSocioComercial,
    setUtilitarioId,
    handleViewdocs,
    handleUnchekedColumns,
    DataEtapa,
    setEtapaId,
    setClaveSocioComercial,
    setpageRows,
    role
  }
}

export default useInventarioSocioComercial