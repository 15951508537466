
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { useDispatch, useSelector } from 'react-redux'
import { setSeguimientoContrato } from "../../Redux/Slice/navigateSliceSeguimientoTelefonico";
import { id } from "date-fns/locale";

const SeguimientoTelefonicoHook = () => {
    const dispatch = useDispatch()

    const userName = sessionStorage.getItem('usuario')
    const userId = sessionStorage.getItem('userId').trim()

    let allContratos


    const [percentage, setPercentage] = useState(0)
    const [rangoFechas, setRangoFechas] = useState(0)
    const [generalSearch, setGeneralSearch] = useState("")
    const [contractData, setContractData] = useState([])
    const [uniqueIds, setUniqueIds] = useState([])

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    const [firstProgressVigente, setFirstProgressVigente] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [secondProgressVigentes, setSecondProgressVigentes] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })

    const [totalContratoPromesas, setTotalContratoPromesas] = useState(0)
    const [totalMontoPromesas, setTotalMontoPromesas] = useState(0)

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState([])

    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    //Dropdown
    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)

    const dropdownRef = useRef(null)


    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplida")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplida")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0)

        //Sacar el limite del periodo de fechas
        const fechaLimitePeriodo = new Date(hoy);
        const fechaTotal = fechaLimitePeriodo.setDate(hoy.getDate() - 15)
        const fechaTotalFormat = new Date(fechaTotal)

        //filtrado por periodo -15 dias
        let datosFiltrados = datos.filter(item => {
            let fechaCreacion = new Date(item.fecha_creacion)

            if (item.promesa_status_nombre === "Vigente") {
                const fechaPromesa = new Date(item.fecha_compromiso);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return (item.promesa_status_nombre === estado) && fechaCreacion >= fechaTotalFormat
        })

        const total = datosFiltrados.length;

        //Sacar el numero de contratos en los ultimos x dias
        const fechaLimite = hoy.setDate(hoy.getDate() - dias)
        const fechaLimiteFormat = new Date(fechaLimite)

        //Filtrado por x dias
        let datosFiltradosFecha = datos.filter(item => {
            let fechaCreacion = new Date(item.fecha_creacion)

            if (item.promesa_status_nombre === "Vigente") {
                const fechaPromesa = new Date(item.fecha_compromiso);
                const today = new Date()
                if (fechaPromesa < today) return false
            }

            return fechaCreacion >= fechaLimiteFormat
        })

        // Suma total de dinero
        const activos = datosFiltradosFecha.filter(obj => obj.promesa_status_nombre === estado);

        let sumaTotalDinero = activos.length > 0 ? estado === "Vigente" ?//Probar el estado
            activos.reduce((total, obj) => total + obj.monto_promesa, 0) : activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0
        // Si es Vigente devolver monto promesa, si es cumplida devolver monto recuperado.

        const sumaTotal = datosFiltrados.length > 0 ? estado === "Vigente" ?//Probar el estado
            datosFiltrados.reduce((total, obj) => total + obj.monto_promesa, 0) : datosFiltrados.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        // Número de IDs diferentes
        let idsDiferentes = activos.length

        const contratosDiferentes = datosFiltrados.length > 0 ? new Set(datosFiltrados.map(obj => obj.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        let porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        //10 para acomodar el tooltip
        const tooltipPercent = Number(porcentajeActivos.toFixed(2)) + 10

        //Si dias es 7 o 15 hacer la resta de sumaTotal de activos, de ids diferentes, y procentajeActivos
        if (dias > 0) {
            if (estado === "Vigente") {
                sumaTotalDinero = sumaTotalDinero - firstProgressVigente.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressVigente.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressVigente.porcentajeActivos
            }
            if (estado === "Cumplida") {
                sumaTotalDinero = sumaTotalDinero - firstProgressCumplidas.sumaTotalDinero
                idsDiferentes = idsDiferentes - firstProgressCumplidas.idsDiferentes
                porcentajeActivos = porcentajeActivos - firstProgressCumplidas.porcentajeActivos
            }
        }

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2),
            tooltip: tooltipPercent,
            totalMontoPromesas: sumaTotal,
            totalContratoPromesas: contratosDiferentes
        };
    }

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])


    useEffect(() => {
        GetAllData()
    }, [])

    useEffect(() => {
        const filteredData = contractData.filter(item => {
            const nameMatches = generalSearch !== '' && (item.contrato_id && item.contrato_id.toLowerCase().includes(generalSearch.toLowerCase()));

            return (
                nameMatches
            )
        })

        const uniqueIds = [...new Set(filteredData.map(item => item.nivel_cobranza))];

        setUniqueIds(uniqueIds)
    }, [generalSearch])

    const GetAllData = async () => {
        await obtenerRangodeFechas(new Date())
        await handleGetSeguimientoContrato()
        await handleGetPromesasdePago()
    }

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase())

                if (userContracts.contratos) {
                    allContratos = userContracts.contratos.map(obj => {
                        if (obj.cartera_vencida === 1) return obj.contrato_id
                    })
                    let contratosFiltrador = userContracts.contratos.filter(item => item.cartera_vencida === 0)
                    let length = userContracts.contratos.length
                    let percentage = contratosFiltrador.length > 0 ? (contratosFiltrador.length / length) : 0
                    if (userContracts?.contratos?.length > 0) {
                        const totalMontoAdeudo = userContracts.contratos
                          .filter(item => item && item.monto_adeudo)
                          .reduce((sum, item) => sum + Number(item.monto_adeudo), 0);
                        setTotalMontoVencido(totalMontoAdeudo);
                      }
                    setContractData(userContracts.contratos)
                    setTotalContratos(length)
                    setPercentage(percentage)
                }

                // const rangoFechasFormat = formatearRangoDeFechas(userContracts.rangoFechas)
                // setRangoFechas(rangoFechasFormat)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }

    function obtenerRangodeFechas(fecha) {
        const meses = [
            'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
        ];

        const fechaObj = new Date(fecha)
        const mes = fechaObj.getMonth()
        const anio = fechaObj.getFullYear()
        const dia = fechaObj.getDate();

        let fechaInicio, fechaFin

        if (dia <= 15) {
            // Si es la primera quincena
            fechaInicio = new Date(anio, mes, 1); // Primer día del mes
            fechaFin = new Date(anio, mes, 15); // 15 de ese mes
        } else {
            // Si es la segunda quincena
            fechaInicio = new Date(anio, mes, 16); // 16 de ese mes
            fechaFin = new Date(anio, mes + 1, 0); // Último día del mes (se usa mes+1 para obtener el último día del mes actual)
        }

        const formatearFecha = (fecha) => {
            const dia = fecha.getDate();
            const mes = meses[fecha.getMonth()];
            return `${dia} de ${mes}`;
        };

        //   const inicio = formatearFecha(fechaInicio);
        const inicio = fechaInicio.getDate()
        const fin = formatearFecha(fechaFin);

        let fechaFormat = `${inicio} al ${fin}`;
        setRangoFechas(fechaFormat)
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);


        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                const promesasFiltradas = res.data.filter(obj => allContratos.includes(obj.contrato_id))

                const vigente = analizarDatos(promesasFiltradas, 0, "Vigente")
                const cumplidas = analizarDatos(promesasFiltradas, 0, "Cumplida")

                const suma = promesasFiltradas.reduce((total, obj) => {
                    return {
                        montoVencido: total.montoVencido + obj.monto_vencido,
                        montoCobrado: total.montoCobrado + obj.monto_recuperado
                    };
                }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(promesasFiltradas)
                setTotalMontoCobrado(suma.montoCobrado)
                //setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        userName,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        loading,
        totalContratos,
        rangoFechas,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        totalContratos,
        setGeneralSearch,
        uniqueIds,
    }
}
export default SeguimientoTelefonicoHook