import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const CambioEtapa = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        cambioEtapaId: item?.cambioEtapaId ?? "",
        motivoCambioId: item?.motivoCambioId ?? "",
        etapa: item?.etapa ?? "",
        inventarioId: item?.inventarioId ?? "",
        usuarioSolicitoId: item?.usuarioSolicitoId ?? "",
        usuarioCambioId: item?.usuarioCambioId ?? "",
        fechaCambio: item?.fechaCambio.split("T")[0] ?? new Date().toISOString().substring(0, 10),
        hora: item?.hora ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);


    useEffect(() => {
        handleGetLists()
    }, [])

    const validate = Yup.object({
        motivoCambioId: Yup.string()
            .required("El campo es requerido."),
        etapa: Yup.string()
        .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
              )
            .required("El campo es requerido."),
        inventarioId: Yup.string()
            .required("El campo es requerido."),
        usuarioSolicitoId: Yup.string()
            .required("El campo es requerido."),
        // fechaCambio: Yup.string()
        //     .required("El campo es requerido."),
        // hora: Yup.string()
        //     .required("El campo es requerido.")
    });
    const handleGetLists = async () => {
        await Catalogo.getdataTabla("MotivoCambio", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.motivoCambioId,
                        name: item.motivoCambio
                    })
                });
                setData(NewData);
            }).catch(e => {
                
            })
        await Catalogo.getdataTabla("InventarioExterno", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.inventarioExternoId,
                        name: item.externo
                    })
                });
                setData1(NewData);
            }).catch(e => {
                
            })
        await Catalogo.GetAllUsers()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        id: item.userId,
                        name: item.names
                    })
                });
                setData2(NewData);
            }).catch(e => {
                
            })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addCambioEtapa(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateCambioEtapa(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '45rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="Etapa" label="Etapa" name="etapa" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="MotivoCambio" label="Motivo Cambio" name="motivoCambioId" items={data} />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="Inventario" label="Inventario" name="inventarioId" items={data1} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <SelectField id="UsuarioQueSolicita" label="Usuario que solicita" name="usuarioSolicitoId" items={data2} />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="FechaCambio" label="Fecha Cambio" name="fechaCambio" type="date" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="Hora" label="Hora" name="hora" type="time" step="1" />
                                    </div>
                                </div>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default CambioEtapa