import axios from '../../Utils/ApiService';

const SyncEliminarIntelimotor = {
    getSyncEliminarIntelimotor:(page,rows,fecha,result,marca,modelo,texto)=> {        
        return axios.get(`/catalogs/ListAutosFallaEliminarIntelimotor?page=${page}&rows=${rows}&FechaFiltro=${fecha}&ResultadoFiltro=${result}&ClaveMarca=${marca}&ClaveModelo=${modelo}&Buscar=${texto}`);
    }, 
    Deleteintelimotor:(data)=>{
        return axios.put(`/catalogs/ReEliminarIntelimotor`,data);
    }      
}

export default SyncEliminarIntelimotor