import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { selectedValueComisionEmpresa } from '../../../../Redux/Slice/navigateSliceComisionesInventario';
import { ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section } from '../../../../Auth/Authorization';

const HeaderComisionEmpresa = () => {
    const navigate = useNavigate();
    const { itemCEmpresa } = useSelector((state) => state.tabNavigateComisionesInventario);
    const dispatch = useDispatch();    
    useEffect(() => {
        handleRedirectPage(itemCEmpresa);
    }, [itemCEmpresa])
    const handleSetItem = (value) => {
        dispatch(selectedValueComisionEmpresa(value));
      }
    const handleRedirectPage = (item) => {
        if (item === 1)
            navigate("/inventario/comisiones/comisiondeempresa/pendientespago");
        else if (item === 2)
            navigate("/inventario/comisiones/comisiondeempresa/procesopago");
        else if (item === 3)
            navigate("/inventario/comisiones/comisiondeempresa/comisionespagadas");
        else if (item === 0)
            navigate("/inventario/comisiones/comisiondeempresa");
    }

    let dataSelect = [
        { label: "Seleccione estatus", value: 0,isActive:true },
        { label: "Comisiones pendientes de pago", value: 1 ,isActive:ValidatePermissionById(3, Section.ComisionesPendientesDePago) },
        { label: "Comisiones en proceso de pago", value: 2 ,isActive:ValidatePermissionById(3, Section.ComisionesProcesoDePago)},
        { label: "Comisiones pagadas", value: 3 ,isActive:ValidatePermissionById(3, Section.ComisionesPagadasEmpresa) }
    ];
    return (
        <>
            <section className='px-3 py-4 d-flex flex-column headerTab'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Comisión de empresas</strong></span>
                </div>
                <span className='mt-2'>A continuación selecciona el estatus correspondiente que deseas gestionar.</span> <br />

                <div className='col-lg-3 col-md-6 col-sm-12 px-1'>
                    <Select options={dataSelect.filter(x=>x.isActive)} defaultValue={dataSelect.filter(x=>x.value === itemCEmpresa)} className='col-12' onChange={(e) => { handleSetItem(e.value) }} placeholder="Listados" />
                </div>
            </section>
        </>
    )
}

export default HeaderComisionEmpresa