import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayEntregaExpediente: "",
    hourEntregaExpediente: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdEntregaExpediente: "",
}

export const EntregaExpedienteSlice = createSlice({
    name: 'EntregaExpedienteSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayEntregaExpediente: action.payload.day,
                hourEntregaExpediente: action.payload.hour,
                calendarioIdEntregaExpediente: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayEntregaExpediente: "",
                hourEntregaExpediente: "",
                calendarioIdEntregaExpediente: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = EntregaExpedienteSlice.actions

export default EntregaExpedienteSlice.reducer