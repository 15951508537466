import React from 'react'
import { useState, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import toast, { Toaster } from 'react-hot-toast';
import ValuacionesServices from '../../Services/Inventario/ValuacionesService';
import { message } from '../../Utils/functions';

const InventarioCostosInspeccionModal = ({ modalMessage, setModalMessage, getCostosInspeccion, valuacionInspeccionUnidadId }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);

    const initial = {
        concepto: "",
        subtotal: "",
        iva: "",
        total: "",
    }

    const [initialValue, setInitialValue] = useState(initial)
    const [total, setTotal] = useState(0);


    const validate = Yup.object().shape({
        concepto: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        subtotal: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        iva: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
    });

    

    const handlerGuardar = async (values) => {
        values.valuacionInspeccionUnidadId = valuacionInspeccionUnidadId;

        await ValuacionesServices.postCostos(values)
        .then(resp => {
            toast.success(message("¡Operación exitosa!", "Costo registrado correctamente"));
            getCostosInspeccion(valuacionInspeccionUnidadId);
        })
        .catch(err => {
            toast.error(message("¡Operación fallida!", "Error al agregar el costo"));
            console.error(err)
        })
        .finally(() => {
            setModalMessage({ isOpen: false });
        });
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }


    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.concepto = ""
        initialValue.subtotal = ""
        initialValue.iva = ""
        initialValue.total = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const calculateTotal = (values, fn) => {
        if((values.subtotal.trim().length === 0) || (values.iva.trim().length === 0)) return false;
        if(Number.isNaN(values.subtotal) || Number.isNaN(values.iva)) return false;

        let total = parseFloat(values.subtotal) + parseFloat(values.subtotal * (values.iva / 100));
        fn("total", total);
    }


    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    concepto: true,
                    metodoPago: true,
                    monto: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación completa todos los campos para el registro del costo.
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="concepto"
                                                disabled={false}
                                                label="Concepto"
                                                name="concepto"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("concepto", event.target.value);
                                                }} />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="subtotal"
                                                disabled={false}
                                                label="Subtotal"
                                                name="subtotal"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("subtotal", event.target.value);
                                                }}
                                                onBlur={(event) => {
                                                    calculateTotal(values, setFieldValue);
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="iva"
                                                disabled={false}
                                                label="IVA (%)"
                                                name="iva"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("iva", event.target.value);
                                                }} 
                                                onBlur={(event) => {
                                                    calculateTotal(values, setFieldValue);
                                                }}
                                                />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="total"
                                                disabled={true}
                                                label="Total"
                                                name="total"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("total", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Registrar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default InventarioCostosInspeccionModal;
