import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import Modal from './Modal';
import * as Yup from 'yup';
import Spinner from '../Loadig';
import Llamada from '../../Services/Cartera/Llamadas';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { message, FormatNumberMoney } from '../../Utils/functions';

const AgregarDatosContactoModal = ({
    isOpen,
    setIsOpen,
    relacionSolicitante,
    dataEstado,
    tipoContacto,
    subtipoContacto,
    state,
    infoContactoData,
    toast,
    GetDataContactos,
    GetDataSeguimiento,
}) => {

    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const inicial = {
        sol_id: state.sol_id,
        contrato: state.contrato_id,
        vin: state.vin,
        relacion_solicitante: "",
        nombre_ref: "",
        apellido_paterno_ref: "",
        apellido_materno_ref: "",
        curp: "",
        fecha_nacimiento: "",

        calle_ref: "",
        no_ext_ref: "",
        no_int_ref: "",
        cp_ref: "",
        colonia_ref: "",
        municipio_ref: "",
        estado_ref: "",
        pais_ref: "México",

        tipo: "",
        subtipo: "",
        valor: "",
        message: "",
    }

    // const [page, setPage] = useState(1)
    const [initialValue, setInitialValue] = useState(inicial)
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);
    const [especificaInformacion, setEspecificaInformacion] = useState([
        { value: 0, label: "Direccion adicional" },
        { value: 1, label: "Tipo de contacto" },
    ])

    const userId = sessionStorage.getItem('userId').trim()

    const HandleGetMunicpio = async (id) => {
        setDataMunicipio([]);
        await Llamada.GetMunicipio(id).then(res => {
            let Municipios = []
            res.data?.data?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setDataMunicipio(Municipios);
            //setDataEmpresaId
        })
    }
    const HandleGetColonia = async (id) => {
        setDataColonia([])
        await Llamada.GetColonia(id).then(res => {
            let Colonias = []
            res.data?.data?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setDataColonia(Colonias);
            //setDataEmpresaId
        })
    }

    const validationSchema = Yup.object({
        calle_ref: Yup.string()
            .when('especificaInformacion', {
                is: 0, // Condition to check
                then: Yup.string().required('Calle es requerido'),
            }),
        cp_ref: Yup.string()
            .when('especificaInformacion', {
                is: 0,
                then: Yup.string().required('Codigo es requerido'),
            }),
        colonia_ref: Yup.string()
            .when('especificaInformacion', {
                is: 0,
                then: Yup.string().required('Colonia es requerido'),
            }),

        tipoContacto: Yup.string()
            .when('especificaInformacion', {
                is: 1, // Condition to check
                then: Yup.string().required('Tipo es requerido'),
            }),
        subtipoContacto: Yup.string()
            .when('especificaInformacion', {
                is: 1, // Condition to check
                then: Yup.string().required('Subtipo es requerido'),
            }),

        message: Yup.string().required('Comentario es requerido')
            .test(
                'min-length-if-not-empty',
                'El comentario debe tener al menos 20 caracteres',
                function (value) {
                    // Si el valor no está vacío, debe tener al menos 20 caracteres
                    return !value || (value && value.length >= 20);
                }),

                valor: Yup.string()
                .when("especificaInformacion", {
                  is: 1, // Requiere validación solo si especificaInformacion es 1
                  then: Yup.string().required("Valor es requerido")
                    .when("tipoContacto", {
                      is: "Télefono fijo",
                      then: Yup.string().matches(/^\d{10}$/, "El número debe tener exactamente 10 dígitos"),
                    })
                    .when("tipoContacto", {
                      is: "Celular",
                      then: Yup.string().matches(/^\d{10}$/, "El número debe tener exactamente 10 dígitos"),
                    })
                    .when("tipoContacto", {
                      is: "Correo",
                      then: Yup.string().email("Debe ser un correo válido"),
                    }),
                }),
    })

    const handlerGuardar = async (values) => {
        setLoading(true);
        const today = new Date().toISOString()
        if (values.especificaInformacion === 0) {
            //Actualizar Direccion
            let data = {
                "anyObject": {
                    calle: values.calle_ref,
                    numero_exterior: values.no_ext_ref,
                    numero_interior: values.no_int_ref,
                    codigo_posta: values.cp_ref,
                    colonia_id: values.colonia_ref,
                    municipio_id: values.municipio_ref,
                    estado_id: values.estado_ref,
                    pais_id: "México",
                    es_principal: 0,
                    comentarios: values.message,
                    referencia_ascendes_id: infoContactoData.idreferencia,
                    fecha_creacion: today,
                    usuario_id: userId
                }
            }

            await CobranzaService.createContactoReferenciaDireccion(data)
                .then(res => {
                    setIsOpen(false);
                    setLoading(false)
                    toast.success(message("¡Operación exitosa!", res.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false)
                    if (e.response !== undefined)
                        toast.error(message("¡Error!", e.response.data.message));
                    else
                        toast.error(message("¡Error!", e.message));
                })
        } else {
            let data = {
                sol_id: state.sol_id,
                contrato: state.contrato_id,
                vin: state.vin,
                idpersona_acendes: infoContactoData.idreferencia,
                tipo_persona: infoContactoData.tipo, //tipo
                curp: infoContactoData.curp_ref, //curp_ref
                tipo_contacto: values.tipoContacto, // texto
                subtipo_contacto: values.subtipoContacto, //texto
                dato: values.valor, //valor
                valor: values.valor, //valor
                comentario: values.message,
                fecha_operacion: today
            }

            //Actualizar tipo
            await CobranzaService.createNuevoDatoContacto(data)
                .then(async (res) => {
                    await GetDataContactos()
                    await GetDataSeguimiento()
                    setIsOpen(false);
                    setLoading(false)
                    toast.success(message("¡Operación exitosa!", res.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false)
                    if (e.response !== undefined)
                        toast.error(message("¡Error!", e.response.data.message));
                    else
                        toast.error(message("¡Error!", e.message));
                })
        }

    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handlerGuardar(values)
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={1000}>
                            <Form style={{ maxWidth: '100%' }}>
                                <div className="row">
                                    <div className="col col-12 d-flex">
                                        <div className='px-4'>
                                            <h4><strong>Agregar información de contacto</strong></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-12 col-12 py-2"> <span>1. A continuación indica el tipo de información que deseas registrar.</span></div>
                                    <div className="col-lg-4 col-4">
                                        <SelectField
                                            disabled={false}
                                            label="Especifica la información:"
                                            id="especificaInformacion"
                                            name="especificaInformacion"
                                            items={especificaInformacion}
                                            onChange={(event) => {
                                                setFieldValue("especificaInformacion", event.value)
                                            }} />
                                    </div>
                                    {values.especificaInformacion === 0 ?
                                        <>
                                            {/* DIRECCION */}
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>2. Captura su dirección</strong></span></div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Calle:</label>
                                                <Field type="text" name="calle_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="calle_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Numero exterior:</label>
                                                <Field type="text" name="no_ext_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="no_ext_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Numero interior:</label>
                                                <Field type="text" name="no_int_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="no_int_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Código postal:</label>
                                                <Field type="text" name="cp_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="cp_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="estado_ref" label="Estado" name="estado_ref" items={dataEstado} onChange={(event) => {
                                                    setFieldValue("estado_ref", event.label);
                                                    HandleGetMunicpio(event.value);
                                                }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="MunicipioId" label="Municipio" name="municipio_ref" items={dataMunicipio} onChange={(event) => {
                                                    setFieldValue("municipio_ref", event.label);
                                                    HandleGetColonia(event.value);
                                                }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="colonia_ref" label="Colonia" name="colonia_ref" items={dataColonia} onChange={(event) => {
                                                    setFieldValue("colonia_ref", event.label);
                                                }} />
                                            </div>
                                            <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribe" />
                                            </div>
                                        </>
                                        : <></>}

                                    {values.especificaInformacion === 1 ?

                                        <>
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>3. Detalle de contacto</strong></span></div>
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="tipoContacto"
                                                    label="Tipo de Contacto:"
                                                    name="tipoContacto"
                                                    disabled={false}
                                                    items={tipoContacto}
                                                    onChange={(event) => {
                                                        setFieldValue("tipoContacto", event.label)
                                                    }} />
                                            </div>
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="subtipoContacto"
                                                    label="Subtipo:"
                                                    name="subtipoContacto"
                                                    disabled={false}
                                                    items={subtipoContacto}
                                                    onChange={(event) => {
                                                        setFieldValue("subtipoContacto", event.label)
                                                    }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Valor:</label>
                                                <Field type="text" name="valor" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                {/* <TextField
                                                    id="valor"
                                                    label="Valor:"
                                                    name="valor"
                                                    holder="Escribir"
                                                    isMoney={true}
                                                    onChange={(event) => {
                                                        setFieldValue("valor", FormatNumberMoney(event.target.value));
                                                    }}
                                                /> */}
                                                <ErrorMessage name="valor" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribe" />
                                            </div>
                                        </>
                                        : <></>}





                                    {/* BOTONES */}
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            {especificaInformacion ?
                                                (
                                                    <>
                                                        <div className="col-auto">
                                                            <button
                                                                type='reset'
                                                                id="AT_BtnCloseModal"
                                                                className='btn btn-outline d-flex justify-content-center align-items-center'
                                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                                onClick={() => {
                                                                    setIsOpen(false)
                                                                }}
                                                            >
                                                                Cancelar
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                id="AT_BtnCloseModal"
                                                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                                                disabled={!(isValid)}
                                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                                type="submit"
                                                            >
                                                                Registrar
                                                            </button>
                                                        </div>
                                                    </>

                                                ) :
                                                (
                                                    <></>
                                                )

                                            }

                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )}
            </Formik >
        </>
    )
}
export default AgregarDatosContactoModal