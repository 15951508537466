import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import handlePhonNumberFormatter, { getDateFormat, message, Normalize, pageRows } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../Components/SelectField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../../Services/Siniestro/Siniestro';

const SiniestroValuacionCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();
    const selectRefEstado = useRef()
    // let { state } = props;

    let initial = {
        siniestroValuacionAseguradoraId: "",
        siniestroValuacionAseguradoraStatusId: "",
        siniestroId: id,
        vehiculoEnTaller: "",
        nombreTaller: "",
        tallerCalleNumero: "",
        tallerCodigoPostal: "",
        tallerEstadoId: "",
        tallerMunicipioId: "",
        tallerColoniaId: "",
        tallerTelefono: "",

        vehiculoAseguradora: "",
        nombreAseguradora: "",
        aseguradoraCalleNumero: "",
        aseguradoraCodigoPostal: "",
        aseguradoraEstadoId: "",
        aseguradoraMunicipioId: "",
        aseguradoraColoniaId: "",
        aseguradoraTelefono: "",

        requirioGrua: false,
        subioDictamen: false,
        tipoDictamenId: "",
        fechaDictamen: "",
        dictamenRegistrado: true,
        autorizaDictamen: true,
        comentariosRechazoDictamen: "",
        comentariosDictamen: "",
        status: "",
        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 100,
        status: 2,
        procesoId: state.procesoId,

        contrato: "",
        dictamen: ""
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const [disabled, setDisabled] = useState(false);
    const [tipoDictamen, setTipoDictamen] = useState([]);
    const [motivoRechazoDictamen, setMotivoRechazoDictamen] = useState([]);

    const [dataEstado, setDataEstado] = useState([]);
    const [dataMun, setDataMun] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);

    const [dataEstado2, setDataEstado2] = useState([]);
    const [dataMun2, setDataMun2] = useState([]);
    const [dataColonia2, setDataColonia2] = useState([]);

    const columnsAdeudos = [
        { field: 'vin', headerName: 'No. Orden' },
        { field: 'contrato', headerName: 'Ejecutivo' },
        { field: 'marca', headerName: 'Adeudo' },
        { field: 'modelo', headerName: 'Fecha de adeudo' },
        { field: 'año', headerName: 'Subtotal' },
        { field: 'folioId', headerName: 'IVA' },
        { field: 'folioId', headerName: 'Total' },
        { field: 'fecha', headerName: 'Fecha del registro' }
    ];


    const columnsPagos = [
        { field: 'vin', headerName: 'Folio de pago' },
        { field: 'contrato', headerName: 'Fecha y hora' },
        { field: 'marca', headerName: 'Ejecutivo' },
        { field: 'modelo', headerName: 'Notas' },
        { field: 'año', headerName: 'Método de pago' },
        { field: 'folioId', headerName: 'Monto' },
        { field: 'folioId', headerName: 'Recibo' },
    ];

    const data = {}


    useEffect(() => {
        handleGetAllSelects();
        handleGetAllValuacionById();
    }, []);


    /* CONSULTAS */
    const handleGetAllValuacionById = async () => {
        setLoading(true);
        await Siniestros.siniestroValuacionAseguradoraGet(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroValuacionAseguradoraId: item?.siniestroValuacionAseguradoraId ?? "",
                    siniestroValuacionAseguradoraStatusId: item?.siniestroValuacionAseguradoraStatusId ?? 10,
                    siniestroId: item?.siniestroId ?? id,

                    tipoAseguradora: item?.tipoAseguradora ?? "Taller aseguradora", // 1.- Taller aseguradora, 2.- Patio aseguradora

                    vehiculoEnTaller: item?.vehiculoEnTaller ?? "",
                    nombreTaller: item?.nombreTaller ?? "",
                    tallerCalleNumero: item?.tallerCalleNumero ?? "",
                    tallerCodigoPostal: item?.tallerCodigoPostal ?? "",
                    tallerEstadoId: item?.tallerEstadoId ?? "",
                    tallerMunicipioId: item?.tallerMunicipioId ?? "",
                    tallerColoniaId: item?.tallerColoniaId ?? "",
                    tallerTelefono: item?.tallerTelefono ?? "",

                    vehiculoAseguradora: item?.vehiculoAseguradora ?? "",
                    nombreAseguradora: item?.nombreAseguradora ?? "",
                    aseguradoraCalleNumero: item?.aseguradoraCalleNumero ?? "",
                    aseguradoraCodigoPostal: item?.aseguradoraCodigoPostal ?? "",
                    aseguradoraEstadoId: item?.aseguradoraEstadoId ?? "",
                    aseguradoraMunicipioId: item?.aseguradoraMunicipioId ?? "",
                    aseguradoraColoniaId: item?.aseguradoraColoniaId ?? "",
                    aseguradoraTelefono: item?.aseguradoraTelefono ?? "",

                    requirioGrua: item?.requirioGrua ?? "",
                    subioDictamen: item?.subioDictamen ?? false,
                    tipoDictamenId: item?.tipoDictamenId ?? "",
                    fechaDictamen: item?.fechaDictamen?.split("T")[0] ?? "",
                    dictamenRegistrado: item?.dictamenRegistrado ?? false,
                    autorizaDictamen: item?.autorizaDictamen ?? false,
                    comentariosRechazoDictamen: item?.comentariosRechazoDictamen ?? "",
                    motivoRechazoDictamen: item?.motivoRechazoDictamen ?? "",
                    comentariosDictamen: item?.comentariosDictamen ?? "",
                    statuss: item?.statuss ?? false,
                    concluido: item?.concluido ?? false,
                    color: item?.color ?? "default",
                    codEstatus: item?.codEstatus ?? 3,  //3=valuacion , 4= PerdidaTotal , 5= Reparacion
                    codSubStatus: item?.codSubStatus ?? 1, //1=inicial 2= dictaminando  3=autoizacionDictamen 4.-inicial
                    //subioDictamen: item?.subioDictamen,
                    fechaInicio: item.fechaInicio,
                    fechaFin: item.fechaConclusion?.split("T")[0] ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state.procesoId,

                    contrato: item?.contrato ?? "",
                    dictamen: item?.dictamen ?? ""
                }
                setInitialValue(newData);
                if (initialValue.codSubStatus !== 1) {
                    handleGetAllMunicipioById(item?.tallerEstadoId, 1);
                    handleGetAllColoniaById(item?.tallerMunicipioId, 1);
                    handleGetAllMunicipioById(item?.aseguradoraEstadoId, 2);
                    handleGetAllColoniaById(item?.aseguradoraMunicipioId, 2);
                }
                handleConcluido({ tipo: 5, concluido: (item?.concluido), tipoDictamenId: item?.tipoDictamenId, tipoDictamen: item?.dictamen });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetAllEstado(), handleGetAllDictamen(), handleGetAllMotivoRechazoDictamen()]).finally(() => {
            setLoading(false)
        });
    }
    const handleGetAllDictamen = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoDictamen')
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.TipoDictamenId,
                        label: item.TipoDictamen
                    });
                });

                setTipoDictamen(newData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleGetAllMotivoRechazoDictamen = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroDictamenRechazoMotivo')
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.SiniestroDictamenRechazoMotivoId,
                        label: item.SiniestroDictamenRechazoMotivo
                    });
                });

                setMotivoRechazoDictamen(newData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    /* TERMINA CATALOGOS */

    const submitForm = async (values) => {
        if (values.dictamenRegistrado) {
            if (!values.subioDictamen) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario cargar la evidencia de Dictamen de siniestro.")}</div>);
                return;
            }
        }
        setLoading(true);
        await Siniestros.siniestroValuacionAseguradoraActualizar(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllValuacionById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        // vehiculoEnTaller: Yup.bool()
        //     .required("El campo es requerido."),
        // nombreTaller: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerCalleNumero: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerCodigoPostal: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerEstadoId: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerMunicipioId: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerColoniaId: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // tallerTelefono: Yup.string()
        //     .when("vehiculoEnTaller", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //             .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
        //     }),

        // //Aseguradora
        // vehiculoAseguradora: Yup.bool()
        //     .required("El campo es requerido."),
        // nombreAseguradora: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraCalleNumero: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraCodigoPostal: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraEstadoId: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraMunicipioId: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraColoniaId: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // aseguradoraTelefono: Yup.string()
        //     .when("vehiculoAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //             .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
        //     }),
        // REquirio grua
        // requirioGrua: Yup.bool()
        //     .required("El campo es requerido."),
        //Dictamen Aseguradora
        tipoDictamenId: Yup.string()
            .when("codSubStatus", {
                is: 2,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaDictamen: Yup.string()
            .when("codSubStatus", {
                is: 2,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentariosDictamen: Yup.string()
            .when("codSubStatus", {
                is: 2,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        dictamenRegistrado: Yup.bool()
            .when("codSubStatus", {
                is: 2,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        //Dictamen autorizacion
        // autorizaDictamen: Yup.bool()
        //     .when("codSubStatus", {
        //         is: 3,
        //         then: Yup.bool()
        //             .required("El campo es requerido.")
        //     }),
        // comentariosRechazoDictamen: Yup.string()
        //     .when("codSubStatus", {
        //         is: 3,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // motivoRechazoDictamen: Yup.string()
        //     .when(["autorizaDictamen", "codSubStatus"], (autorizaDictamen, codSubStatus) => {
        //         console.log("dictamen", autorizaDictamen, codSubStatus)
        //         if (autorizaDictamen && codSubStatus === 3) {
        //             return Yup.string().test({
        //                 name: 'is-sku',
        //                 skipAbsent: true,
        //                 test(value, ctx) {
        //                     if (value === undefined)
        //                         return ctx.createError({ message: 'El campo es requerido' })
        //                     return true
        //                 }
        //             })
        //         }

        //     }),
    });
    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };
    const handleUploadFile = () => {
        // navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${id}`, {
        //     state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }
        // });
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eDictam/${state?.folio}/${initialValue.siniestroValuacionAseguradoraId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroValuacionAseguradoraId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }

    const handleGetAllEstado = async () => {
        await Catalogo
            .getdataTabla("EdoMExico", 1, 100)
            .then(res => {

                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    })
                });
                setDataEstado(NewData);
                setDataEstado2(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllMunicipioById = async (id, tipo) => {
        if (id === "") {
            setDataMun([]);
            return;
        }
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                if (tipo === 1)
                    setDataMun(NewData);
                else
                    setDataMun2(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllColoniaById = async (id, tipo) => {
        if (id === '') {
            setDataColonia([])
            return;
        }
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                if (tipo === 1)
                    setDataColonia(NewData);
                else
                    setDataColonia2(NewData);
            }).catch(e => {

            })
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseValuacionAseguradora" role="button" aria-expanded="false" aria-controls="collapseValuacionAseguradora" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Valuación por aseguradora</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseValuacionAseguradora">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        if (values.dictamenRegistrado)
                                            values.codSubStatus = 3;
                                        else
                                            values.codSubStatus = 2;
                                        values.porcentaje = values.dictamenRegistrado ? 100 : 0;
                                        values.siniestroValuacionAseguradoraStatusId = 20;
                                        values.concluido = values.dictamenRegistrado;
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    {
                                                        values.tipoAseguradora === "Taller aseguradora" &&
                                                        <div className="col-3">
                                                            <small><strong>Detalle vehículo en taller</strong></small>
                                                        </div>
                                                    }
                                                    {
                                                        values.tipoAseguradora === "Patio aseguradora" &&
                                                        <div className="col-3">
                                                            <small><strong>Detalle vehículo en aseguradora</strong></small>
                                                        </div>
                                                    }
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    values.tipoAseguradora === "Taller aseguradora" &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="vehiculoApto"
                                                                    label="¿Vehículo en taller?"
                                                                    name="vehiculoEnTaller"
                                                                    disabled={true}
                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("vehiculoEnTaller", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="utilidad" disabled={true} label="Nombre del taller" holder="Nombre" name="nombreTaller" type="text" onChange={(event) => {
                                                                    setFieldValue("nombreTaller", event.target.value);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">

                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="utilidad" disabled={true} label="Calle y número" holder="Calle y número" name="tallerCalleNumero" type="text" onChange={(event) => {
                                                                    setFieldValue("tallerCalleNumero", event.target.value);
                                                                }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="utilidad" disabled={true} label="Código postal" holder="CP" name="tallerCodigoPostal" type="text" onChange={(event) => {
                                                                    setFieldValue("tallerCodigoPostal", event.target.value);
                                                                }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="vehiculoApto"
                                                                    label="Estado"
                                                                    name="tallerEstadoId"
                                                                    disabled={true}
                                                                    items={dataEstado}
                                                                    onChange={(event) => {
                                                                        setFieldValue("tallerEstadoId", event.value);
                                                                        handleGetAllMunicipioById(event.value, 1);
                                                                        setFieldValue("tallerMunicipioId", "");
                                                                        setFieldValue("tallerColoniaId", "");
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="vehiculoApto"
                                                                    label="Municipio"
                                                                    ref={selectRefEstado}
                                                                    name="tallerMunicipioId"
                                                                    disabled={true}
                                                                    items={dataMun}
                                                                    onChange={(event) => {
                                                                        setFieldValue("tallerMunicipioId", event.value);
                                                                        handleGetAllColoniaById(event.value, 1);
                                                                        setFieldValue("tallerColoniaId", "");
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="cumplePolitica"
                                                                    label="Colonia"
                                                                    disabled={true}
                                                                    name="tallerColoniaId"
                                                                    items={dataColonia}
                                                                    onChange={(event) => {
                                                                        setFieldValue("tallerColoniaId", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="utilidad" disabled={true} label="Telefono" holder="Telefono" name="tallerTelefono" type="text" onChange={(event) => {
                                                                    setFieldValue("tallerTelefono", handlePhonNumberFormatter(event.target.value));
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    values.tipoAseguradora === "Patio aseguradora" &&
                                                    <>
                                                        {/* <div className="row mt-2 px-4 mb-2">
                                                            <div className="col-3">
                                                                <small><strong>Detalle vehículo en aseguradora</strong></small>
                                                            </div>
                                                        </div> */}
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="vehiculoApto"
                                                                    label="¿Vehículo en aseguradora?"
                                                                    name="vehiculoAseguradora"
                                                                    disabled={true}
                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("vehiculoAseguradora", event.value);
                                                                    }} />
                                                            </div>
                                                            {
                                                                values.vehiculoAseguradora &&
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={true} label="Nombre de aseguradora" holder="Nombre de aseguradora" name="nombreAseguradora" type="text" onChange={(event) => {
                                                                        setFieldValue("nombreAseguradora", event.target.value);
                                                                    }} />
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            values.vehiculoAseguradora &&
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={true} label="Calle y número" holder="Calle y número" name="aseguradoraCalleNumero" type="text" onChange={(event) => {
                                                                        setFieldValue("aseguradoraCalleNumero", event.target.value);
                                                                    }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={true} label="Código postal" holder="CP" name="aseguradoraCodigoPostal" type="text" onChange={(event) => {
                                                                        setFieldValue("aseguradoraCodigoPostal", event.target.value);
                                                                    }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <SelectField id="vehiculoApto"
                                                                        label="Estado"
                                                                        disabled={true}
                                                                        name="aseguradoraEstadoId"
                                                                        items={dataEstado2}
                                                                        onChange={(event) => {
                                                                            setFieldValue("aseguradoraEstadoId", event.value);
                                                                            handleGetAllMunicipioById(event.value, 2);
                                                                            setFieldValue("aseguradoraMunicipioId", "");
                                                                            setFieldValue("aseguradoraColoniaId", "");
                                                                        }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <SelectField id="vehiculoApto"
                                                                        label="Municipio"
                                                                        ref={selectRefEstado}
                                                                        disabled={true}
                                                                        name="aseguradoraMunicipioId"
                                                                        items={dataMun2}
                                                                        onChange={(event) => {
                                                                            setFieldValue("aseguradoraMunicipioId", event.value);
                                                                            handleGetAllColoniaById(event.value, 2);
                                                                            setFieldValue("aseguradoraColoniaId", "");
                                                                        }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <SelectField id="cumplePolitica"
                                                                        label="Colonia"
                                                                        disabled={true}
                                                                        name="aseguradoraColoniaId"
                                                                        items={dataColonia2}
                                                                        onChange={(event) => {
                                                                            setFieldValue("aseguradoraColoniaId", event.value);
                                                                        }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={true} label="Telefono" holder="Telefono" name="aseguradoraTelefono" type="text" onChange={(event) => {
                                                                        setFieldValue("aseguradoraTelefono", handlePhonNumberFormatter(event.target.value));
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small><strong>Detalle de movilidad</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Vehículo requirió grúa?"
                                                            disabled={true}
                                                            name="requirioGrua"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("requirioGrua", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                {
                                                    // initialValue.codSubStatus >= 2 &&
                                                    <>
                                                        <div className="row mt-2 px-4 mb-2">
                                                            <div className="col-auto">
                                                                <small className='fw-bold'>Dictamen de aseguradora</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <fieldset
                                                                    disabled={initialValue?.concluido}
                                                                    onClick={handleUploadFile}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="vehiculoApto"
                                                                    label="Dictamen"
                                                                    disabled={initialValue?.concluido}
                                                                    name="tipoDictamenId"
                                                                    items={tipoDictamen}
                                                                    onChange={(event) => {
                                                                        setFieldValue("tipoDictamenId", event.value);
                                                                        setFieldValue("dictamen", event.label);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="FechadictamenId" disabled={initialValue?.concluido} label="Fecha del dictamen" holder="Fecha del dictamen" name="fechaDictamen" type="date" />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-6">
                                                                <TextAreaField id="comentarios"
                                                                    label="Comentarios"
                                                                    disabled={initialValue?.concluido}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentariosDictamen"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentariosDictamen", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-6">
                                                                <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="dictamenRegistrado" onClick={(event) => {
                                                                    setFieldValue("dictamenRegistrado", event.target.value);
                                                                }} />
                                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Dictamen registrado</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <div className="row mt-2 px-4 d-flex justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio !== "" &&
                                                            <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido &&
                                                            <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                        }
                                                    </div>
                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SiniestroValuacionCard