import axios from '../../Utils/ApiService';

const SeguroSinRenovarService = {
    getAllSinRenovarSeguro: async (page, rows, tipocompra, usoUnidad, fechaInicio, fechaFin, motivoId, string) => {
        return await axios.get(`/functions/GetAllVehiculosSinRenovarSeguro?page=${page}&Rows=${rows}&TipoCompraId=${tipocompra}&UsoUnidadId=${usoUnidad}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&MotivoNoRenovacionSeguroId=${motivoId}&Buscar=${string}`);
    },
    ExportSinRenovarSeguro: async (tipocompra, usoUnidad, fechaInicio, fechaFin, motivoId, string) => {
        return await axios.get(`/functions/GetExportVehiculosSinRenovarSeguro?TipoCompraId=${tipocompra}&UsoUnidadId=${usoUnidad}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&MotivoNoRenovacionSeguroId=${motivoId}&Buscar=${string}`,{responseType: 'blob'});
    },
    SaveSinRenovarSeguro: async (data) => {
        return await axios.put(`/functions/SaveSeguroNoRenovado`, data)
    },
    //Vehiculos Renovados
    GetAllVehiculosSeguroRenovado:async(page,rows,tipoCompra,usoUnidad,fechaInicio,fechaFin,string,tipo)=>{
        return await axios.get(`/functions/GetAllVehiculosSeguroRenovado?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompra}&UsoUnidadId=${usoUnidad}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&Buscar=${string}&Tipo=${tipo}`);
    },
    ExportVehiculosSeguroRenovado:async(tipoCompra,usoUnidad,fechaInicio,fechaFin,string,tipo)=>{
        return await axios.get(`/functions/GetExportVehiculosSeguroRenovado?TipoCompraId=${tipoCompra}&UsoUnidadId=${usoUnidad}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&Buscar=${string}&Tipo=${tipo}`,{responseType: 'blob'})
    }
}

export default SeguroSinRenovarService