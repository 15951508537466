import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { FormatNumberMoney2, message } from '../../../../Utils/functions';
import DataTable from '../../../../Components/datatable/DataTable';
import { TextAreaField } from '../../../../Components/TextAreaField';
import ModalCostosResguardo from './ModalCostosResguardo';
import RowPages from '../../../../Components/RowPages';
import ModalConfirmarEliminar from './ModalConfirmarEliminar'
import ResguardoUnidadService from '../../../../Services/Inventario/ResguardoUnidadService';
import VisorFile from "../../../../Components/VisorFile";
import { v4 as uuidv4 } from 'uuid';
import AuthLogin from '../../../../Utils/AuthLogin';
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices';
import axios from 'axios';

const auth = new AuthLogin();
const ResguardoUnidadDetalle = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const refbtn = useRef();
    const { id } = state?.id;
    const refFormik = useRef();
    let url = auth.UrlDataDocs();

    const columnsHistorialResguardo = [
        { field: 'fechaHoraInicio', headerName: 'Fecha y hora inicio', width: 200 },
        { field: 'usuarioEntrega', headerName: 'Usuario entrega', width: 200 },
        { field: 'ubicacionOrigen', headerName: 'Ubicación origen', width: 250 },
        { field: 'ubicacionDestino', headerName: 'Ubicación destino', width: 250 },
        { field: 'usuarioRecibe', headerName: 'Usuario recibe', width: 200 },
        { field: 'motivoCambio', headerName: 'Motivo cambio', width: 200 },
        { field: 'comentariosTooltip', headerName: 'Comentario', width: 150 }
    ];

    const columnsCostos = [
        { field: 'id', headerName: '#' },
        { field: 'fechaHora', headerName: 'Fecha y hora' },
        { field: 'ejecutivo', headerName: 'Ejecutivo' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subTotal', headerName: 'Subtotal' },
        { field: 'iva', headerName: 'IVA' },
        { field: 'total', headerName: 'Total' },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];

    const dataTipoDestino = [
        { value: 1, label: "Datamovil" },
        { value: 2, label: "Externo" }
    ];

    const dataMotivoCambio = [
        { value: 1, label: "Valuación" },
        { value: 2, label: "Reparación" },
        { value: 3, label: "Exhibición" }
    ];

    const dataUbicacion = [
        { value: 1, label: "Sucursal 1" },
        { value: 2, label: "Sucursal 2" }
    ];

    let initialResguardoUnidad = {
        vin: state?.vin,
        marca: state?.marca,
        modelo: state?.modelo,
        version: state?.version,
        anio: state?.anio,
        color: state?.color,
        folio: "",
        ubicacionOrigen: state?.ultima_ubicacion,
        motivoCambio: "",
        tipoDestino: "",
        estado: "",
        municipio: "",
        colonia: "",
        calle: "",
        numero: "",
        ubicacion: "",
        quienEntrega: "",
        quienRecibe: "",
        fechaEntrega: "",
        comentarios: "",
        registroConcluido: false
    };

    const [datosResguardoUnidad, setDatosResguardoUnidad] = useState(initialResguardoUnidad);
    const [dataHistorial, setDataHistorial] = useState([]);
    const [dataCostos, setDataCostos] = useState([]);
    const [dataCostosNuevos, setDataCostosNuevos] = useState([]);
    const [opcionesTipoDestino, setOpcionesTipoDestino] = useState(dataTipoDestino);

    const [loading, setLoading] = useState(false);
    const [opcionesMotivoCambio, setOpcionesMotivoCambio] = useState(dataMotivoCambio);
    const [opcionesUbicacion, setOpcionesUbicacion] = useState(dataUbicacion);
    const [dataUsoUnidad, setDataUsoUnidad] = useState([]);

    const [isOpenModalHistorial, setIsOpenModalHistorial] = useState(false);
    const [isOpenModalCostos, setIsOpenModalCostos] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [currenPage, setCurrenPage] = useState(1);

    //New state editable 
    const [editable, setEditable] = useState(false);
    // ------------------------------------------------------------------------------------
    const [selectedItem, setSelectedItem] = useState();
    const [pageRows, setpageRows] = useState(10);
    const [itemSelected, setItemSelected] = useState();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [totalPagos, setTotalPagos] = useState(0);

    const [motivoCambio, setMotivoCambio] = useState(0);
    const [tipoDestino, setTipoDestino] = useState(0);
    const [ubicacion, setUbicacion] = useState(0);
    const [estado, setEstado] = useState(0);
    const [municipio, setMunicipio] = useState(0);
    const [colonia, setColonia] = useState(0);

    const [opcionesEstados, setDataEstados] = useState([]);
    const [opcionesMunicipio, setDataMunicipio] = useState([]);
    const [opcionesColonias, setDataColonias] = useState([]);

    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);

    useEffect(() => {
        habdleGetByIdResguardoUnidad(state.id);
    }, [])

    const habdleGetByIdResguardoUnidad = async (id) => {
        setLoading(true);

        let data = {
            "verb": "string",
            "id": "resguardounidad",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {},
            "kvp": { "id": id }
        }

        await ResguardoUnidadService.getAllResguardoUnidad(data)
            .then(res => {

                let datosFormateados = {
                    vin: state?.vin ?? "",
                    marca: state?.marca ?? "",
                    modelo: state?.modelo ?? "",
                    version: state?.version ?? "",
                    anio: state?.anio ?? "",
                    color: state?.color ?? "",
                    folio: "",
                    ubicacionOrigen: state?.ultima_ubicacion,
                    motivoCambio: "",
                    tipoDestino: "",
                    ubicacion: "",
                    quienEntrega: "",
                    quienRecibe: "",
                    fechaEntrega: "",
                    comentarios: "",
                    registroConcluido: false
                };

                let tblHistorial = res.data.responseData[0]?.movimientos ? res.data.responseData[0]?.movimientos?.map(movimiento => {
                    return {
                        fechaHoraInicio: formatFechaAMPM(movimiento?.fecha_de_entrega) ?? "",
                        usuarioEntrega: movimiento?.quien_entrega ?? "",
                        ubicacionOrigen: movimiento?.ubicacion_origen ?? "",
                        ubicacionDestino:  movimiento?.tipo_destino === "Datamovil" ? movimiento?.ubicacion_destino : ([movimiento?.estado,movimiento?.municipio,movimiento?.colonia,movimiento?.calle, movimiento?.numero].filter(Boolean).join(', ')),
                        usuarioRecibe: movimiento?.quien_recibe ?? "",
                        motivoCambio: movimiento?.motivo_cambio ?? "",
                        comentariosTooltip: movimiento?.comentarios ?? "",
                        folio: movimiento?.folio,
                        tipoDestino: movimiento?.tipo_destino
                    }
                }) : [];

                tblHistorial = tblHistorial.sort((a, b) => {
                    const fechaA = new Date(convertirFormatoFecha(a.fechaHoraInicio));
                    const fechaB = new Date(convertirFormatoFecha(b.fechaHoraInicio));
                    return fechaB - fechaA;
                });

                let ultimoMovimiento = tblHistorial.reduce((max, movimiento) => {
                    return (movimiento.folio > (max?.folio ?? -Infinity)) ? movimiento : max;
                }, null);

                if (ultimoMovimiento != null) {
                    //     datosFormateados.folio = ultimoMovimiento?.folio ?? "";
                    // datosFormateados.ubicacionOrigen = ultimoMovimiento?.ubicacionOrigen ?? "";
                    datosFormateados.ubicacionOrigen = ultimoMovimiento?.ubicacionDestino ?? "";
                    //     datosFormateados.motivoCambio = ultimoMovimiento?.motivoCambio ?? "";
                    //     datosFormateados.tipoDestino = ultimoMovimiento?.ubicacionDestino ?? "";
                    //     datosFormateados.ubicacion = ultimoMovimiento?.ubicacion ?? "";
                    //     datosFormateados.quienEntrega = ultimoMovimiento?.usuarioEntrega ?? "";
                    //     datosFormateados.quienRecibe = ultimoMovimiento?.usuarioRecibe ?? "";
                    //     datosFormateados.fechaEntrega = ultimoMovimiento?.fechaHoraInicio ?? "";
                    //     datosFormateados.comentarios = ultimoMovimiento?.comentariosTooltip ?? "";
                }

                let tblCostos = res.data.responseData[0]?.costos ? res.data.responseData[0]?.costos?.map((x, idx) => {
                    return {
                        id: idx + 1,
                        idRegistro: x.id,
                        fechaHora: formatFechaAMPM(x?.fecha_hora),
                        fechaHoraSinFormato: x?.fecha_hora,
                        ejecutivo: x?.ejecutivo_id,
                        concepto: x?.concepto,
                        subTotal: x?.subtotal,
                        iva: x?.iva,
                        total: x?.total,
                        doc: x?.doc,
                        subido: x?.doc?.subido
                        // acciones: {
                        //     "subido": false
                        // }
                    }
                }) : [];

                setDataHistorial(tblHistorial);
                setDataCostos(tblCostos);
                setDatosResguardoUnidad(datosFormateados);
                setTotalPagos(handleTotal(tblCostos));
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                toast.error(message("¡Error!", "Error al obtener el listado resguardo de unidad"));
            })
    }

    function convertirFormatoFecha(fechaHora) {
        if (!fechaHora) {
            return "01-01-1970 12:00 AM";
        }
        const [fecha, hora] = fechaHora.split(' ');
        const [dia, mes, anio] = fecha.split('-');
        return `${mes}-${dia}-${anio} ${hora}`;
    }

    const formatFechaAMPM = (fechaString) => {
        if (!fechaString) return "";
        const fecha = new Date(fechaString);

        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const año = fecha.getFullYear();

        let horas = fecha.getHours();
        const minutos = fecha.getMinutes().toString().padStart(2, '0');
        const ampm = horas >= 12 ? 'PM' : 'AM';

        horas = horas % 12;
        horas = horas ? horas : 12;
        const horasStr = horas.toString().padStart(2, '0');

        return `${dia}-${mes}-${año} ${horasStr}:${minutos} ${ampm}`;
    };

    const obtenerFechaHoraActual = () => {
        const ahora = new Date();

        const formatoDosDigitos = (num) => (num < 10 ? '0' + num : num);

        const año = ahora.getFullYear();
        const mes = formatoDosDigitos(ahora.getMonth() + 1); // Los meses empiezan desde 0
        const dia = formatoDosDigitos(ahora.getDate());

        const horas = formatoDosDigitos(ahora.getHours());
        const minutos = formatoDosDigitos(ahora.getMinutes());
        const segundos = formatoDosDigitos(ahora.getSeconds());
        const milisegundos = ahora.getMilliseconds().toString().padStart(3, '0');

        const fechaHoraFormateada = `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}.${milisegundos}`;

        return fechaHoraFormateada;
    };

    const handleEdit = (item) => {
        setSelectedItem({ ...item });
        setIsOpenModalCostos(true)
    };

    const addUpdateCostosAdicionalesTbl = (item) => {
        let nuevoCosto = null;
        if (item?.idCostoAdicional == "") {
            const idNuevoResguardoCosto = generateGUID();
            nuevoCosto = {
                id: idNuevoResguardoCosto,
                resguardo_id: state?.id,
                fecha_hora: obtenerFechaHoraActual(),
                ejecutivo_id: sessionStorage.getItem('nombreUsuario').trim(),
                concepto: item?.concepto,
                subtotal: item?.subTotal,
                iva: item?.iva,
                total: item?.total
            };
        }
        else {
            const costoEditar = {
                ...dataCostos.find(x => x.idRegistro === item?.idCostoAdicional)
            };
            nuevoCosto = {
                id: costoEditar?.idRegistro,
                resguardo_id: state?.id,
                fecha_hora: costoEditar?.fechaHoraSinFormato,
                ejecutivo_id: costoEditar?.ejecutivo,
                concepto: item?.concepto,
                subtotal: item?.subTotal,
                iva: item?.iva,
                total: item?.total
            };
        }
        //registrar, update el costo
        handleGuardarResguardoCosto(nuevoCosto);
    };

    const handleTotal = (array) => {
        const total = array.reduce((previous, current) => {
            return previous + current.total; // sumar el valor de una propiedad
        }, 0);
        return total;
    }

    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const deleteCostosAdicionalesTbl = async (item) => {
        setLoading(true);
        item = item?.item;
        setLoading(false);
        let dataResguardoCosto = {
            "verb": "string",
            "id": "resguardocosto",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {

                "id": item?.idRegistro,
                "resguardo_id": state?.id,
                "fecha_hora": item?.fechaHoraSinFormato,
                "ejecutivo_id": item?.ejecutivo,
                "concepto": item?.concepto,
                "subtotal": item?.subTotal,
                "iva": item?.iva,
                "total": item?.total
            },
            "kvp": {}
        }

        await ResguardoUnidadService.deleteResguardoUnidadAnything(dataResguardoCosto)
            .then(res => {
                habdleGetCostosByIdResguardoUnidad(state?.id);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            });
    };

    const handleViewdocs = async (item) => {
        setLoading(true);
        // setFileName(item.nombreDocumento);
        setFileName(item.nombre_doc);
        await handleGetTokenDatadocs();
        // await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
        await axios.post(`${url}Documento/GetFileById/${item.binario.idFolder}/${item.binario.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    };

    const handleUploadFile = async (value, subido) => {
        if (value?.doc?.subido) {
            await handleViewdocs(value.doc)
        } else {
            // navigate(`/inventario/valuacion/datadocs/fResguardo/eResguardo/${state?.contrato}/${state?.id}`, {
            //guardar
            // const archivoId = crypto.randomUUID();
            const archivoId = value.idRegistro;

            // navigate(`/inventario/resguardo-unidad/datadocs/fResguardo/eResguardo/${state?.contrato}/${state?.id}`, {
            navigate(`/inventario/resguardo-unidad/datadocs/fResguardo/eResguardo/${state?.contrato}/${archivoId}`, {
                state: { ...value, registroId: value.idRegistro, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.anio }
            });

            //este es de otro
            // navigate(`/inventario/valuacion/datadocs/fValuacion/eRepar/${state?.contrato}/${state?.id}`, {
            //     state: { ...value, registroId: value.idRegistro, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.anio }
            // });
        }
    };

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        // getAll(event.selected + 1);
    };

    const validate = Yup.object({
        motivoCambio: Yup.string().required("El campo es requerido."),
        tipoDestino: Yup.string().required("El campo es requerido."),
        ubicacion: Yup.string().when('tipoDestino', {
            is: "Datamovil",
            then: Yup.string().required("El campo es requerido.")
        }),
        estado: Yup.string().when('tipoDestino', {
            is: "Externo",
            then: Yup.string().required("El campo es requerido.")
        }),
        municipio: Yup.string().when('tipoDestino', {
            is: "Externo",
            then: Yup.string().required("El campo es requerido.")
        }),
        colonia: Yup.string().when('tipoDestino', {
            is: "Externo",
            then: Yup.string().required("El campo es requerido.")
        }),
        calle: Yup.string().when('tipoDestino', {
            is: "Externo",
            then: Yup.string().required("El campo es requerido.")
        }),
        numero: Yup.string().when('tipoDestino', {
            is: "Externo",
            then: Yup.string().required("El campo es requerido.")
        }),
        quienEntrega: Yup.string().required("El campo es requerido."),
        quienRecibe: Yup.string().required("El campo es requerido."),
        fechaEntrega: Yup.string().required("El campo es requerido."),
        comentarios: Yup.string().required("El campo es requerido."),
    });

    const handleGuardarResguardoMovimiento = async (values) => {
        const idNuevoResguardoMovimiento = generateGUID();
        let dataResguardoMovimiento = {
            "verb": "string",
            "id": "resguardomovimiento",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {
                "id": idNuevoResguardoMovimiento,
                "resguardo_id": state.id,
                "folio": null,
                "ubicacion_origen": values.ubicacionOrigen,
                "motivo_cambio": values.motivoCambio,
                "tipo_destino": values.tipoDestino,
                "estado": values.estado,
                "municipio": values.municipio,
                "colonia": values.colonia,
                "calle": values.calle,
                "numero": values.numero,
                "quien_entrega": values.quienEntrega,
                "quien_recibe": values.quienRecibe,
                "fecha_de_entrega": values.fechaEntrega,
                "comentarios": values.comentarios,
                "ubicacion_destino": values.ubicacion
            },
            "kvp": {}
        }
        await ResguardoUnidadService.upsertResguardoUnidadAnything(dataResguardoMovimiento)
            .then(res => {
                toast.success(message("¡Correcto.!", "Información de resguardo movimiento registrada"));
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            });
    }

    const handleGuardarResguardoCosto = async (item) => {
        let dataResguardoCosto = {
            "verb": "string",
            "id": "resguardocosto",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {
                id: item.id,
                resguardo_id: item?.resguardo_id,
                fecha_hora: item?.fecha_hora,
                ejecutivo_id: item?.ejecutivo_id,
                concepto: item?.concepto,
                subtotal: item?.subtotal,
                iva: item?.iva,
                total: item?.total
            },
            "kvp": {}
        };
        ResguardoUnidadService.upsertResguardoUnidadAnything(dataResguardoCosto)
            .then(res => {
                habdleGetCostosByIdResguardoUnidad(item?.resguardo_id);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            });
    }

    const habdleGetCostosByIdResguardoUnidad = async (resguardo_id) => {
        setLoading(true);

        let data = {
            "verb": "string",
            "id": "resguardocosto",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {},
            "kvp": { "resguardo_id": resguardo_id }
        }

        await ResguardoUnidadService.getAllResguardoUnidad(data)
            .then(res => {

                let tblCostos = res.data.responseData ? res.data.responseData?.map((x, idx) => {
                    return {
                        id: idx + 1,
                        idRegistro: x.id,
                        fechaHora: formatFechaAMPM(x?.fecha_hora),
                        fechaHoraSinFormato: x?.fecha_hora,
                        ejecutivo: x?.ejecutivo_id,
                        concepto: x?.concepto,
                        subTotal: x?.subtotal,
                        iva: x?.iva,
                        total: x?.total,
                        doc: x?.doc,
                        subido: x?.doc?.subido
                    }
                }) : [];

                setDataCostos(tblCostos);
                setTotalPagos(handleTotal(tblCostos));
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                toast.error(message("¡Error!", "Error al obtener el listado de costos"));
            })
    }

    const handleGuardar = async (values) => {
        setLoading(true)
        const [] = await Promise.all([handleGuardarResguardoMovimiento(values)]).finally(() => {
            setLoading(false);
            setTimeout(() => {
                navigate('/inventario/resguardo-unidad');
            }, 2000);
        });
    }

    const generateGUID = () => {
        return uuidv4();
    };

    const getEstados = async () => {
        setLoading(true);
        await ResguardoUnidadService.getEstados()
            .then(res => {
                let NewData = [];
                res.data.responseData.map(element => {
                    NewData.push({
                        value: element.cpId,
                        label: element.d_estado
                    })
                });
                setDataEstados(NewData);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            })
    }

    const getMunicipios = async (nombreEstado) => {
        let data = {
            "verb": "string",
            "id": nombreEstado,
            "field": "string",
            "value": "string",
            "flag": true,
            "anyObject": {}
        }
        setLoading(true);
        await ResguardoUnidadService.getMunicipios(data)
            .then(res => {
                let NewData = [];
                res.data.responseData?.map(element => {
                    NewData.push({
                        value: element.cpId,
                        label: element.d_mnpio
                    })
                });
                setDataMunicipio(NewData);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const getColonias = async (nombreEstado, nombreMunicipio) => {
        let data = {
            "verb": "string",
            "id": nombreEstado,
            "field": "string",
            "value": nombreMunicipio,
            "flag": true,
            "anyObject": {}
        }
        setLoading(true);
        await ResguardoUnidadService.getColonias(data)
            .then(res => {
                let NewData = [];
                res.data.responseData?.map(element => {
                    NewData.push({
                        value: element.cpId,
                        label: element.d_asenta
                    });
                });
                setDataColonias(NewData);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }
    
    const handleChangeTipoDestino = async (opcionSeleccionada) => {
        if (opcionSeleccionada.value == 2) {
            getEstados();
        }
        else {
            setDataEstados([]);
            setDataMunicipio([]);
            setDataColonias([]);
        }
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={datosResguardoUnidad}
            validationSchema={validate}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            navigate('/inventario/resguardo-unidad')
                        }}></i>
                        Regresar a Listado de vehiculos resguardados
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <section className='col-12'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-4 me-2'><strong>Cambio de ubicaciones</strong></span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <Field className="form-check-input" required={true} type="checkbox" name="registroConcluido" disabled={!editable} onClick={(event) => { setFieldValue("registroConcluido", event.target.value); }} />
                                            <label className="form-check-label" style={{ marginLeft: '10px', marginRight: '20px', fontSize: '18px' }}>
                                                Registro concluido
                                            </label>
                                            {/* <button className='btn mx-2' disabled={values?.registroConcluido ? !isValid : false} onClick={() => (values?.registroConcluido ? handleGuardar(values) : setEditable(true))}>{!editable ? "Editar" : "Guardar"}</button> */}
                                            <button className='btn mx-2' disabled={!isValid} onClick={() => (values?.registroConcluido ? handleGuardar(values) : setEditable(true))}>{!editable ? "Editar" : "Guardar"}</button>
                                            {/* <button className='btn mx-2' onClick={() => (values?.registroConcluido ? handleGuardar(values) : setEditable(true))}>{!editable ? "Editar" : "Guardar"}</button> */}
                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <p className="fs-4 ms-2">Vehículo</p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN" name="vin" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión" name="version" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año" name="anio" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Color" name="color" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2 mt-5'>
                                        <div className='d-flex align-items-center'>
                                            <p className='fs-4 ms-2'>Resguardo</p>
                                            <div className="col-sm-1 mx-2">
                                                <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="right" title="Ver historial" style={{ width: '40px' }} onClick={() => {
                                                    setIsOpenModalHistorial(true);
                                                    // handleDataHistory(currenPage)
                                                }} >
                                                    <i className='ri-folder-history-line'></i></fieldset>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Folio" disabled={true} required={true} name="folio" type="text" holder="" onChange={(event) => {
                                                    setFieldValue("folio", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Ubicación origen" disabled={true} name="ubicacionOrigen" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Motivo del cambio" disabled={!editable} required={true} name="motivoCambio" items={opcionesMotivoCambio} onChange={(event) => {
                                                    if (event.value == "") {
                                                        setMotivoCambio(0);
                                                        setFieldValue("motivoCambio", "");
                                                    }
                                                    else {
                                                        setMotivoCambio(event.value);
                                                        setFieldValue("motivoCambio", event.label);
                                                    }
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Tipo destino" required={true} disabled={!editable} name="tipoDestino" items={opcionesTipoDestino} onChange={(event) => {
                                                    if (event.value == "") {
                                                        setTipoDestino(0);
                                                        setFieldValue("tipoDestino", "");
                                                        setEstado(0);
                                                        setFieldValue("estado", "");
                                                        setMunicipio(0);
                                                        setFieldValue("municipio", "");
                                                        setColonia(0);
                                                        setFieldValue("colonia", "");
                                                    }
                                                    else {
                                                        setTipoDestino(event.value);
                                                        setFieldValue("tipoDestino", event.label);

                                                    }
                                                    setFieldValue("calle", "");
                                                    setFieldValue("numero", "");
                                                    handleChangeTipoDestino(event);
                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            {tipoDestino == 2 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Estado" required={true} disabled={!editable} name="estado" items={opcionesEstados} onChange={(event) => {
                                                        if (event.value == "") {
                                                            setEstado(0);
                                                            setFieldValue("estado", "");
                                                            setColonia(0);
                                                            setFieldValue("colonia", "");
                                                            setDataMunicipio([]);
                                                            setDataColonias([]);
                                                        }
                                                        else {
                                                            setEstado(event.value);
                                                            setFieldValue("estado", event.label);
                                                            setMunicipio(0);
                                                            setFieldValue("municipio", "");
                                                            getMunicipios(event.label);
                                                            setColonia(0);
                                                            setFieldValue("colonia", "");
                                                        }
                                                    }} />
                                                </div>
                                            }
                                            {tipoDestino == 2 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Municipio" required={true} disabled={!editable} name="municipio" items={opcionesMunicipio} onChange={(event) => {
                                                        if (event.value == "") {
                                                            setMunicipio(0);
                                                            setFieldValue("municipio", "");
                                                            setDataColonias([]);
                                                        }
                                                        else {
                                                            setMunicipio(event.value);
                                                            setFieldValue("municipio", event.label);

                                                            getColonias(values.estado, event.label);
                                                        }
                                                    }} />
                                                </div>
                                            }
                                            {tipoDestino == 2 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Colonia" required={true} disabled={!editable} name="colonia" items={opcionesColonias} onChange={(event) => {
                                                        if (event.value == "") {
                                                            setColonia(0);
                                                            setFieldValue("colonia", "");
                                                        }
                                                        else {
                                                            setColonia(event.value);
                                                            setFieldValue("colonia", event.label);
                                                        }
                                                    }} />
                                                </div>
                                            }
                                            {tipoDestino == 2 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Calle" required={true} disabled={!editable} name="calle" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("calle", event.target.value);
                                                    }} />
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-3 row">
                                            {tipoDestino == 1 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Ubicación" required={true} disabled={!editable} name="ubicacion" items={opcionesUbicacion} onChange={(event) => {
                                                        if (event.value == "") {
                                                            setUbicacion(0);
                                                            setFieldValue("ubicacion", "");
                                                        }
                                                        else {
                                                            setUbicacion(event.value);
                                                            setFieldValue("ubicacion", event.label);
                                                        }
                                                    }} />
                                                </div>
                                            }
                                            {tipoDestino == 2 &&
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Número" required={true} disabled={!editable} name="numero" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("numero", event.target.value);
                                                    }} />
                                                </div>
                                            }
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="¿Quién entrega?" required={true} disabled={!editable} name="quienEntrega" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("quienEntrega", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="¿Quién recibe?" required={true} disabled={!editable} name="quienRecibe" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("quienRecibe", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Fecha de entrega" required={true} disabled={!editable} name="fechaEntrega" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaEntrega", event.target.value);
                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <TextAreaField label="Comentarios" required={true} name="comentarios" disabled={!editable} className="form-control col-12" rows="4" type="text" holder="" onChange={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-auto">
                                            <strong>Costos adicionales</strong>
                                        </div>
                                        <div className="col-auto">
                                            <fieldset className='btn' onClick={() => {
                                                setIsOpenModalCostos(true);
                                                setSelectedItem({ valuacionRegistroGastosId: "" })
                                            }}>
                                                Registrar costo
                                            </fieldset>
                                        </div>
                                    </div>

                                    <RowPages setpageRows={setpageRows} />
                                    <div className="row mt-2 px-4 mb-2">
                                        <div className="col-12">
                                            <DataTable
                                                id="tablaPagos"
                                                name="pagos"
                                                column={columnsCostos}
                                                data={dataCostos}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                deleteable={true}
                                                handleDelete={(item) => { setItemSelected(item); setOpenModalDelete(true); }}
                                                handleDocuments={handleViewdocs}
                                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                paginate={true}
                                                pageCount={pageCount}
                                                handlePageClick={handlePageClick}
                                                currentPage={currenPage - 1}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2 px-4 mb-2 justify-content-end">
                                        <div className="col-auto">
                                            <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    </div>
                    {
                        isOpenModalHistorial &&
                        <Modal isOpen={isOpenModalHistorial} color='#fff'>
                            <div className='d-flex'>
                                <span><strong>Historial ubicaciones vehículo: {state?.marca + ' ' + state?.modelo} </strong></span>
                            </div>
                            <DataTable
                                column={columnsHistorialResguardo}
                                data={dataHistorial}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModalHistorial(false) }}>Cancelar</fieldset>
                            </section>
                        </Modal>
                    }
                    {
                        isOpenModalCostos &&
                        <ModalCostosResguardo
                            isOpen={isOpenModalCostos}
                            setIsOpen={setIsOpenModalCostos}
                            item={selectedItem}
                            addUpdateCostosAdicionalesTbl={addUpdateCostosAdicionalesTbl}
                            toast={toast}
                        />
                    }
                    {
                        openModalDelete &&
                        <ModalConfirmarEliminar
                            isOpen={openModalDelete}
                            setIsOpen={setOpenModalDelete}
                            item={itemSelected}
                            toast={toast}
                            deleteCostosAdicionalesTbl={deleteCostosAdicionalesTbl}
                        />
                    }
                    {
                        isOpenDocs &&
                        <VisorFile
                            isOpen={isOpenDocs}
                            setIsOIpen={setIsOpenDocs}
                            fileName={fileName}
                            doc={SelectedDocs}
                        />
                    }
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik >
    )
}

export default ResguardoUnidadDetalle