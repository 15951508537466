import React, { useRef, useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast'
import BoletinadoHook from "../../../Hooks/SeguimientoBoletinado/BoletinadoHooks";
import RowPages from '../../../Components/RowPages';
import DataTable from "../../../Components/datatable/DataTable";
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { SelectField2 } from '../../../Components/SelectField2';
import { SelectField } from '../../../Components/SelectField';
import AutorizacionBoletinadoModal from '../../../Components/Modal/AutorizacionBoletinadoModal';
import CobranzaService from '../../../Services/Cobranza/Cobranza';


const SeguimientoBoletinado = () => {
  const refFormik = useRef();
  const [selectedData, setSelectedData] = useState(null);
  const [isOpenModalSolicitudVisita, setisOpenModalSolicitudVisita] = useState(false);

  // Custom hook para manejar la lógica
  const {
    tiposPersona,
    cobradores,
    setCobradorSeleccionado,
    setTipoPersonaSeleccionado,
    setName,
    setpageRows,
    columns,
    currentData, // Inicializa currentData como un array vacío para evitar el error
    handlePageClick,
    pageCount,
    currentPage,
  } = BoletinadoHook();


  const handleDetailClick = (item) => {
    setSelectedData(item);
    setisOpenModalSolicitudVisita(true); // Mostrar el modal
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />

      {isOpenModalSolicitudVisita && (
        <AutorizacionBoletinadoModal
          data={selectedData}  // Aquí debes pasar el objeto `selectedData`
          setIsOpen={setisOpenModalSolicitudVisita}
          isOpen={isOpenModalSolicitudVisita}
          toast={toast}
        />
      )}

      <Formik
        innerRef={refFormik}
        initialValues={{
          buscar: "",
          tipoPersonaSeleccionado: "",
          cobradorSeleccionado: "",
          page: 1,
          rows: 10
        }}
      >
        {({ setFieldValue }) => (
          <section>
            <div className="pb-4">
              <section className="mx-4 my-4 d-flex flex-column">
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-auto">
                      <strong className="mt-2">Solicitud de boletinado</strong>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-4">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 mt-4 mx-4 inner-addon right-addon">
                      <i className="glyphicon fas fa-search"></i>
                      <Field
                        type="text"
                        name="buscar"
                        className="form-control mb-4"
                        placeholder="Buscar"
                        onKeyUp={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 mt-4 me-1">
                      <SelectField
                        id="tipoPersonaSeleccionado"
                        name="tipoPersonaSeleccionado"
                        placeholder={'Tipo de persona'}
                        items={tiposPersona.map(c => ({ label: c.value, value: c.value }))}
                        onChange={(option) => {
                          setFieldValue("tipoPersonaSeleccionado", option.value);
                          setTipoPersonaSeleccionado(option.value); //Actualiza el estado del tipo de persona
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 mt-4 me-1">
                      <SelectField
                        id="cobradorSeleccionado"
                        name="cobradorSeleccionado"
                        placeholder="Cobrador"
                        items={cobradores.map(c => ({ label: c.usuarioAltaNombre, value: c.usuarioAltaNombre }))}
                        onChange={(option) => {
                          setFieldValue("cobradorSeleccionado", option.value);
                          setCobradorSeleccionado(option.value); // Actualiza el estado del cobrador seleccionado
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mx-4">
                  {/* Evitar renderizar la tabla si no hay datos */}
                  {currentData.length > 0 ? (
                    <DataTable
                      column={columns}
                      data={currentData} // Usar los datos filtrados
                      detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                      handleDetail={handleDetailClick}
                      paginate
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                      currentPage={currentPage}
                      commentColumn={true}
                      paginateResult={true}
                      setpageRows={setpageRows}
                    />
                  ) : (
                    <p>No hay datos disponibles.</p>
                  )}
                </div>
              </section>
            </div>
          </section>
        )}
      </Formik>
    </>
  );
};

export default SeguimientoBoletinado;