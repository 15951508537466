import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";
import * as Yup from 'yup';
import { SelectField } from "../../../Components/SelectField";
import { TextField } from "../../../Components/TextField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import ClavesServices from "../../../Services/Inventario/ClavesService";
import toast, { Toaster } from "react-hot-toast";
import { message } from "../../../Utils/functions";
import Garantias from "../../../Services/Cartera/Garantias";

const AprobarClaveModal = ({ modalMessage, setModalMessage, getSeguimiento }) => {
    const refFormik = useRef();
    const initial = {
        solicitudClaveAcendesId: modalMessage?.id,
        status: null,
        motivo_rechazo: null,
        comentario_rechazo: ""
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [motivos, setMotivos] = useState([]);

    const Resultados = [
        { value: "1", label: 'Aprobar' },
        { value: "0", label: 'Rechazar' }
    ];

    const validate = Yup.object({
        status: Yup.string()
            .required("El campo es requerido.")
    });

    useEffect(() => {
        getMotivos();
    }, []);

    const getMotivos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoRechazoClave')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.MotivoRechazoClaveId,
                        label: item.MotivoRechazoClave
                    }
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleSend = async (values) => {
        values.solicitudClaveAcendesId = modalMessage.id;
        values.status = values.status === "1" ? 1 : 0;

        await ClavesServices.updateClaveManual(values)
            .then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(resp.data.message);
                    if (values.status === 1) {
                        sendMailAprobado();
                    } else {
                        sendMailRechazado();
                    }
                    getSeguimiento();
                }
                else {
                    toast.error(resp.data.message);
                }
            })
            .catch(err => {
                toast.error(message('Ocurrió un error al intentar actualizar la solicitud de la clave'));
            })
            .finally(() => {
                setModalMessage({ isOpen: false });
            });
    }

    const sendMailAprobado = () => {

        const contenido = {
            VARIABLE_SUPERVISOR: sessionStorage.getItem('usuario'),
            VARIABLE_CONTRATO: modalMessage?.contrato,
        }

        Garantias.creardatosenviar({
            modulo: 'Clave Manual Aprobada',
            tipoCorreoId: 104,
            contenido: JSON.stringify(contenido)
        }).catch(err => {
            console.log("Error al enviar correo de Clave Manual Aprobada: ", err);
        })
    }

    const sendMailRechazado = () => {

        const contenido = {
            VARIABLE_SUPERVISOR: sessionStorage.getItem('usuario'),
            VARIABLE_CONTRATO: modalMessage?.contrato,
        }

        Garantias.creardatosenviar({
            modulo: 'Clave Manual Rechazada',
            tipoCorreoId: 103,
            contenido: JSON.stringify(contenido)
        }).catch(err => {
            console.log("Error al enviar correo de Clave Manual Rechazada: ", err);
        })
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
            >{
                    ({ isValid, values, resetForm, setFieldValue }) => (
                        <>
                            <section className='my-2 mx-2' >
                                <Form>
                                    <span className='fs-6' style={{ fontWeight: 'bold', color: "#000" }}><strong>Solicitud de clave</strong></span>
                                    <div className="mb-3 row" style={{ marginTop: 30 }}>
                                        <div className="col-sm-6">
                                            <SelectField label="Resultado" name="status" items={Resultados} onChange={(e) => {
                                                setFieldValue("status", e.value);
                                            }} />
                                        </div>
                                        {
                                            values.status === '0' &&
                                            <div className="col-sm-6">
                                                <SelectField label="Motivo" name="motivo_rechazo" items={motivos} onChange={(e) => {
                                                    setFieldValue("motivo_rechazo", e.value);
                                                }} />
                                            </div>
                                        }
                                    </div>
                                    <div className="mt-4 row">
                                        <div className="col-sm-12 mb-2">
                                            <TextAreaField
                                                id="comentario_rechazo"
                                                className="form-control"
                                                rows="3"
                                                label="Comentarios"
                                                name="comentario_rechazo"
                                                type="text"
                                                holder="Escribe"
                                            />
                                        </div>
                                    </div>
                                    <section className='d-flex row mx-1 justify-content-between mt-3'>
                                        <button id="AT_btnCancelar" className='btn btn-outline col-5 ms-1' type='reset' onClick={() => { 
                                            setModalMessage({ isOpen: false }) 
                                            resetForm();
                                        }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn col-5 ms-1' type="submit" disabled={!(isValid)} onClick={() => {
                                            handleSend(values)
                                            resetForm();
                                        }}>Enviar</button>
                                    </section>
                                </Form>
                            </section>
                        </>
                    )
                }
            </Formik>
        </>
    );
}

export default AprobarClaveModal;