import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import CardVehiculo from '../../Garantias/devoluciones/CardVehiculo';
import OrigenCard from './OrigenCard';
import UbicacionCard from './UbicacionCard';
import RevisionCard from './RevisionUnidad';
import UbicacionResguardoCard from './UbicacionResguardo';
import useCambioDevolucion from '../../../Hooks/Garantias/useCambioDevolucion';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Cierres from '../../../Services/Resguardos/Cierres';

const ResguardoCard = ({}) => {
    const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const { id, resguardo } = useParams();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [data, setData] = useState([]);

    

    let {
        vehiculo
    } = useCambioDevolucion();

    useEffect(() => {
        if(resguardo === undefined){
            return false;
        }
        getResguardo();
    }, [resguardo]);

    const getResguardo = async () => {
        await Cierres.obtenerResguardo(resguardo)
        .then(resp => {
            setData(resp.data);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    };


    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/procesocierre/resguardos';
    }


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de resguardos</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <CardVehiculo data={vehiculo}/>
                        </div>
                        <div className="col-6 px-md-3">
                            <OrigenCard data={vehiculo}/>
                        </div>
                    </div>
                </div>                
                {
                    Number(data.resguardoProcesoId) === 70 &&
                    <UbicacionCard />
                }
                {
                    Number(data.resguardoProcesoId) !== 70 &&
                    <UbicacionResguardoCard />
                }
            </section>

        </>)
}

export default ResguardoCard