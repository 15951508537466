import axios from '../../Utils/ApiService';

const CorreosService = {
    getAllCorreos:(page,rows,string)=> {        
        return axios.get(`/functions/GetAllTipoCorreos?page=${page}&rows=${rows}&Buscar=${string}`);
    },   
    getallprioridad:()=> {        
        return axios.get(`/functions/GetAllPrioridad`);
    },  
    putTipoCorreo:(data)=>{        
        return axios.put(`/functions/TipoCorreosUpd?PrioridadId=${data.prioridadId}&Correos=${data.correos}&Status=${data.status}&TablaId=${data.tipoCorreoId}`);
    }
}

export default CorreosService