import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Nombre: "",
    Telefono: "",
    Correo: "",
    MotivoLlamadaId: 0,
    ComentariosCliente: "",
    ComentariosGarantia: "",
    FolioLlamada: ""
}

export const LlamadaSlice = createSlice({
    name: 'LlamadaSlice',
    initialState,
    reducers: {
        setInfoLlamada: (state, action) => {
            return {
                ...state,
                Nombre: action.payload.Nombre,
                Telefono: action.payload.Telefono,
                Correo: action.payload.Correo,
                MotivoLlamadaId: action.payload.MotivoLlamadaId,
                ComentariosCliente: action.payload.ComentariosCliente,
                ComentariosGarantia: action.payload.ComentariosGarantia,
                FolioLlamada: action.payload.FolioLlamada
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
        clearLlamada: (state) => {
            return {
                ...state,
                Nombre: "",
                Telefono: "",
                Correo: "",
                MotivoLlamadaId: 0,
                ComentariosCliente: "",
                ComentariosGarantia: "",
                FolioLlamada: ""
            }
        }
    }
})

export const { setInfoLlamada, setEditable,clearLlamada } = LlamadaSlice.actions

export default LlamadaSlice.reducer