import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1,
  itemCEmpresa:0,
  itemCExterno:0,
  itemCCExterno:0
}
export const navigateSliceComisionesInventario = createSlice({
  name: 'ComisionesInventarioNavigate',
  initialState,
  reducers: {
    tabActiveComisionesInventario: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    },
    selectedValueComisionEmpresa: (state, action) => {
      return { ...state, itemCEmpresa: action.payload,itemCCExterno:0,itemCExterno:0 }
    },
    selectedValueComisionExterno: (state, action) => {
      return { ...state, itemCExterno: action.payload,itemCEmpresa:0,itemCCExterno:0 }
    },
    selectedValuecobroComisionExterno: (state, action) => {
      return { ...state, itemCCExterno: action.payload,itemCEmpresa:0,itemCExterno:0 }
    }   
  },
})

export const { tabActiveComisionesInventario,selectedValueComisionEmpresa,selectedValueComisionExterno,selectedValuecobroComisionExterno } = navigateSliceComisionesInventario.actions

export default navigateSliceComisionesInventario.reducer