import '../App.css';
import React from 'react';
import { ErrorMessage, Field, useField } from 'formik';

export const TextAreaField = ({ label, holder, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="mb-2">
            <label className='text' htmlFor={field.name}>{label}</label>      
            <div>
            <Field component="textarea"                
                {...field} {...props}
                placeholder={`${holder}`} 
            />
                </div>    
           
            <ErrorMessage component="span" name={field.name} className="error" />
        </div>
    )
}
