import React, { useState } from 'react'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';

const RutaConfiguradaModal = ({ isOpen, setIsOpen, nombreCobrador, handleAceptar}) => {
   const [loading, setLoading] = useState(false);

   const handledConfirmar = () => {
      handleAceptar()
   }

   return (
      <>
         <Modal isOpen={isOpen} color='#fff'>
            <section className='my-2 mx-4' style={{ width: '35rem' }}>
               <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Confirmar los cambios </strong></span>
               <p className='mt-3'><span style={{color: "#000", fontWeight: "bold"}}>¿Estás seguro de los cambios realizados en el cobrador: <span style={{fontWeight: 'bold', color: '#000'}}>{nombreCobrador}</span>?</span></p>
               <section className='d-flex justify-content-end mt-5'>
                  <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                  <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type='button' onClick={()=>{handledConfirmar()}}>Confirmar</button>
               </section>
            </section>
         </Modal>
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
      </>
   )
}

export default RutaConfiguradaModal