import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroSinRenovarService from '../../Services/Seguros/SeguroSinRenovarService';
import Catalogo from '../../Services/Catalogos/Catalogo';

const useVehiculosSinRenovar = () => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'contrato', headerName: 'Contrato', width: 110 },
    { field: 'usuario', headerName: 'Usuario', width: 150 },
    { field: 'fechaHora', headerName: 'Fecha y hora', width: 200 },
    { field: 'usoUnidad', headerName: 'Uso Unidad', width: 110 },
    { field: 'nombreMarca', headerName: 'Marca', width: 150 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'strFechaRenovacion', headerName: 'Vigencia', width: 150 },
    { field: 'motivo', headerName: 'Motivo no renovacion', width: 170 },
    { field: 'comentarios', headerName: 'Comentario', width: 250 },
  ];

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  //Filtro
  const [DataEtapa, setDataEtapa] = useState([]);
  const [tipoCompra, settipoCompra] = useState([]);
  const [DataUsoUnidad, setDataUsoUnidad] = useState([])
  const [DataSocioComercial, setDataSocioComercial] = useState([])
  const [claveTipoCompra, setclaveTipoCompra] = useState("");
  const [claveVendedor, setClaveVendedor] = useState("");
  const [pageRows, setpageRows] = useState(10);
  const [fechInicio, setFechInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [ClaveUsoUnidad, setClaveUsoUnidad] = useState("")
  const [motivoId, setmotivoId] = useState("")
  const [dataMotivo, setdataMotivo] = useState([]);
  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [pageRows, claveTipoCompra, ClaveUsoUnidad, motivoId, str, fechInicio, fechaFin])
  useEffect(() => {
    handleGetAllSelects();
  }, [])

  const handleGetAllSelects = async () => {
    setLoading(true)
    const [] = await Promise.all([handleGetAllUsoUnidad(), handleGetAllSinRenovacionSeguro(), handleGetTipoCompra()]).finally(() => {
      setLoading(false)
    });
  }
  const habdleGetAll = async (page) => {
    setLoading(true);
    await SeguroSinRenovarService.getAllSinRenovarSeguro(page, pageRows, claveTipoCompra, ClaveUsoUnidad, fechInicio, fechaFin, motivoId, str)
      .then(res => {
        console.log(res.data.data)
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  const handleGetTipoCompra = async () => {
    await SeguroPolizaService.getAllTipocompra()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        settipoCompra(NewData);
      }).catch(e => {
      })
  }
  const handleGetAllUsoUnidad = async () => {
    await SeguroSinCotizarService.getlAllUsoUnidad()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.usoUnidadId,
            label: item.usoUnidad
          })
        });
        setDataUsoUnidad(NewData);
      }).catch(e => {

      })
  }
  const habdleDownloadFile = async () => {
    setLoading(true);
    await SeguroSinRenovarService.ExportSinRenovarSeguro(claveTipoCompra, ClaveUsoUnidad, fechInicio, fechaFin, motivoId, str)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `VehiculosSinRenovar-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        habdleGetAll(1);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetAllSinRenovacionSeguro = async () => {
    await Catalogo.getAllCSMotivoNoRenovacionSeguro()
      .then(res => {
        let NewData = [];     
        NewData.push({
          value: "",
          label: "Todos"
        })   
        res.data.data?.map(item => {
          NewData.push({
            value: item.motivoNoRenovacionSeguroId,
            label: item.motivo
          })
        });
        setdataMotivo(NewData);
      }).catch(e => {
      })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };

  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    setpageRows,
    habdleDownloadFile,
    DataSocioComercial,
    tipoCompra,
    setclaveTipoCompra,
    setClaveVendedor,
    setFechInicio,
    setFechaFin,
    DataEtapa,
    DataUsoUnidad,
    setClaveUsoUnidad,
    dataMotivo,
    setmotivoId
  }
}

export default useVehiculosSinRenovar