import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigateSliceComisiones = createSlice({
  name: 'ComisionesNavigate',
  initialState,
  reducers: {
    tabActiveComisiones: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveComisiones } = navigateSliceComisiones.actions

export default navigateSliceComisiones.reducer