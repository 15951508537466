import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroAutorizado from '../../../Hooks/Seguros/useSeguroAutorizado'
import RowPages from '../../../Components/RowPages'
import useVehiculoConvertido from '../../../Hooks/Inventario/useVehiculoConvertido'
import useVehiculoEntrega from '../../../Hooks/Inventario/useVehiculoEntrega'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import ModalEstatusEntrega from './ModalEstatusEntrega'
import SubMenuEntrega from './SubMenuEntrega'

const VehiculoEntrega = () => {
    const { columns,
        data,
        seguimientoEntrega,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataUbicacion,
        tipoCompra,
        setclaveTipoCompra,
        setClaveUbicacion,
        handleUploadFile,
        handleCapturaCancelacion,
        handleSaveEntregaAutorizada,
        handleSaveEntregaRealizada,
        handleSaveEntregaCancelada,
        handleSaveEntregaRechazada
    } = useVehiculoEntrega(1);

    const [dataEntrega, setDataEntrega] = React.useState({});

    const [isOpenModal, setIsOpenModal] = React.useState(false);

    const [entrega, setEntrega] = React.useState("");

    const handleSelectTipoEntrega = async () => {

        try {
            if (entrega == "1") {
                let data = {
                    generalId: dataEntrega.generalId,
                }
                let result = await handleSaveEntregaRealizada(data);
    
                if(result.ban == 1){
                    handleUploadFile(dataEntrega, 1);
                }
            } else if (entrega == "2") {
    
                let data = {
                    generalId: dataEntrega.generalId,
                }
    
                const result = await handleSaveEntregaCancelada(data);
    
                if(result.ban == 1){
                    handleCapturaCancelacion(dataEntrega);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEntrega = async (data) => {
        setDataEntrega(data);
        // setIsOpenModal(true);
        if(data.entrega == "Sin definir")
            setIsOpenModal(true);
        else if(data.entrega == "Realizada"){
            if(data.estatus == "Carga de evidencias")
                handleUploadFile(data, 1);
        }else if(data.entrega == "Cancelada"){
            if(data.estatus == "Capturar información")
                handleCapturaCancelacion(data);
            else if(data.estatus == "En espera acendes")
                handleCapturaCancelacion(data);
                // handleUploadFile(data, 0);
            else if(data.estatus == "Carga de evidencias")
                handleUploadFile(data, 0);
        }
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuEntrega/>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Entrega de vehículos</strong></span>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a la entrega de los vehículos.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculosEnEntrega, SubModule.Entrega).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={DataUbicacion} className='col-12' onChange={(e) => { setClaveUbicacion(e.value) }} placeholder="Ubicación" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo de compra" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        detailable={ValidatePermissionById(4, Section.VehiculosEnEntrega, SubModule.Entrega).Actualizar}
                        handleDetail={handleEntrega}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                    {/* <DataTable
                        column={columns}
                        data={data}
                        autorizar={ValidatePermissionById(4, Section.EntregaDeVehículos, SubModule.Entrega).Aprobar}
                        rechazar={ValidatePermissionById(4, Section.EntregaDeVehículos, SubModule.Entrega).Rechazar}
                        handleAction={(item, action) => handleUploadFile(item, action)}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    /> */}
                </div>
            </ValidatePermission>
            <ModalEstatusEntrega
                open={isOpenModal}
                item={dataEntrega}
                setOpen={setIsOpenModal}
                seguimiento={seguimientoEntrega}
                setEntrega={setEntrega}
                handleSelectSeguimiento={handleSelectTipoEntrega}

            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default VehiculoEntrega