import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import ComisionesEmpresaService from '../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
import ComisionesExternoService from '../../../Services/Inventario/Comisiones/ComisionesExternoService';
import Catalogo from '../../../Services/Catalogos/Catalogo';
const useComisionesExternoPendientePago = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: "Contrato", width: 150 },
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega', width: 250 },
        { field: 'diasTranscurridos', headerName: 'Dias transcurridos', width: 250 },
        { field: 'razonSocial', headerName: 'Razón social', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'procedenciaCliente', headerName: 'Procedencia del cliente', width: 250 },
        { field: 'precioStr', headerName: 'Precio unidad', width: 150 },
        { field: 'montoFinanciarStr', headerName: 'Monto a financiar', width: 250 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [ProcedCliente, setProcedCliente] = useState([]);
    const [ValueProcedCliente, setValueProcedCliente] = useState("");
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [razonSocial, setrazonsocial] = useState([]);
    const [IdsExportar, setIdsExportar] = useState([]);
    const [openModalTotales, setopenModalTotales] = useState(false);
    const [claveRazonSocial, setClaveRazonSocial] = useState("");
    const [openModalRetencionPago, setopenModalRetencionPago] = useState(false);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechaFin, setFechaFin] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows, FechInicio, FechaFin, TipoCompra, ValueProcedCliente, claveRazonSocial])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllProdecenciaCliente();
        handleGetCSRazonSocial()
        // handleGetAllUsoUnidad();
        // handleGetAllAseguradora();
    }, [])
    const handleGetAllProdecenciaCliente = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Procedencia_Cliente')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.Procedencia_ClienteId,
                        label: item.Procedencia_Cliente
                    })
                });
                setProcedCliente(NewData);
            })
            .catch(err => {
                console.error(err);
            })
    }
    const handleGetCSRazonSocial = async () => {
        // if (Id === "") {
        //     setrazonsocial([]);
        //     selectRef.current.setValue([], 'clear')
        //     return;
        // }
        setLoading(true);
        await ComisionesExternoService.getAllCSRazonSocialById()
            .then(res => {                
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.RazonSocialId,
                        label: item.RazonSocial,
                        maximoNumeroAdeudos: item.MaximoNumeroAdeudos,
                        numeroAdeudosRestantes: item.NumeroAdeudosRestantes,
                        fecha:item.Fecha
                    })
                });
                setrazonsocial(NewData);
            setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await ComisionesExternoService.getAllPendientesDePagoComisionExterno(page, pageRows, str, TipoCompra, claveRazonSocial ?? "", ValueProcedCliente, FechInicio, FechaFin)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
                setIdsExportar([]);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                setTimeout(() => {
                    // if ((TipoCompra ?? "") !== "" && (claveRazonSocial ?? "") !== "") {
                    if ((claveRazonSocial ?? "") !== "") {
                        handleIsActiveCheck(true);
                    } else {
                        handleIsActiveCheck(false);
                    }
                }, 500);
            })
    }
    const handleDownloadExcel = () => {
        // refBtn.current.disabled = true;
        if (IdsExportar.length > 0) {
            setopenModalTotales(true);
        } else {
            //     refBtn.current.disabled = false;
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros  para exportar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }
    const handleChangeRazonSocial = (e) => {
        let { numeroAdeudosRestantes,value } = e;        
        if (numeroAdeudosRestantes <= 0) {
            setTimeout(() => {
                selectRef.current.setValue([], 'clear')                
            }, 500);
            setopenModalRetencionPago(true);
            handleSendNotification(e);
        } else {
            setClaveRazonSocial(value);
        }
    }
    const handleSendNotification = async (value) =>{
        let { maximoNumeroAdeudos,label,fecha } = value;        
        await ComisionesExternoService.SendMailRetencionPago({
            proveedor:label,
            fecha:fecha,
            MaximoNumeroAdeudos:maximoNumeroAdeudos
        })
        .then(res => {            
            if(res.data.data.ban === 1){
                toast.success(message("¡Correcto!","Notificación enviada."));
            }else{
                toast.error(message("¡Error!",res.data.data.mensaje));
            }
        }).catch(e => {
           console.log(e) 
        })        
    }
    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsExportar(current => [...current, value]);
        } else {
            setIdsExportar(IdsExportar.filter(item => item.generalId !== value.generalId));
        }
    }
    const handleCostos = () => {
        let total = 0;
        IdsExportar?.map(x => {
            total = total + x.calculoComision
        })
        return total;
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar,
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,
        razonSocial,
        handleGetCSRazonSocial,
        handleChangeRazonSocial,
        setopenModalRetencionPago,
        openModalRetencionPago
    }
}

export default useComisionesExternoPendientePago