import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "./Modal";
import Spinner from "../Loadig";
import DataTable from "../datatable/DataTable";
import AgregarServicioModal from "./AgregarServicioModal";
import DSystemsServicios from "../../Services/DatamovilSystems/ServiciosDatamovil";
import { useLocation } from "react-router-dom";
import { message } from "../../Utils/functions";

const SolicitarServicioModal = ({ isOpen, setIsOpen, item }) => {
    const { state } = useLocation();
    const initial = {

    };

    const columns = [
        { field: 'folioId', headerName: 'Folio' },
        { field: 'tipoServicio', headerName: 'Tipo de Servicio' },
        { field: 'tecnico', headerName: 'Técnico' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'ultimaActualizacion', headerName: 'Último movimiento' },
    ];

    useEffect(() => {
        handleGetServicesByGeneralId();
    }, [state.generalId]);

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpenModalPago, setIsOpenModalPago] = useState(false);
    const [modalAgregarServicioDatamovil, setModalAgregarServicioDatamovil] = useState({ isOpen: false, id: item.seguroSiniestroSeguimientoProcesoDatamovilId });
    const [data, setData] = useState([]);

    const handlePageClick = () => {

    }

    const [dataServicios, setDataServicios] = useState([]);

    const handleGetServicesByGeneralId = async () => {
        await DSystemsServicios.getServiciosByGeneralId(state.generalId)
        .then(resp => {
            const items = resp.data.map(item => {
                item.folioId = item.folioId ?? 'Sin folio de asignación aún';
                item.tecnico = item.tecnico ?? 'Sin Técnico asignado aún';
                item.estatus = item.estatus ?? ' - '
                item.ultimaActualizacion = getOnlyDate(item.ultimaActualizacion) ?? 'Sin Módificaciones'
                item.comentariosUI = item.comentarios;

                return item;
            });
            setData(items);
        })
        .catch(err => {
            toast.error(message('Error al intentar obtener los servicios del vehículo'))
        });
    }

    const getOnlyDate = (date) => {
        if(date === undefined || date === null)  return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
            // validationSchema={validate}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={1100}>
                            <section className='my-4 mx-4'>
                                <span className='fs-6 fw-bold'><strong>Solicitud de servicio</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <DataTable
                                                        data={data}
                                                        column={columns}
                                                        paginate={true}
                                                        pageCount={totalPages}
                                                        currentPage={currentPage - 1}
                                                        handlePageClick={handlePageClick}
                                                    />
                                                    {
                                                        dataServicios?.length <= 0 &&
                                                        <div className="col-6 d-flex align-items-center">
                                                            <fieldset disabled={initialValue?.concluido} className='btn d-flex align-items-center mx-2' onClick={() => { setIsOpenModalPago(true) }}>Agregar</fieldset>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <section className='d-flex justify-content-end'>
                                                <div className="row">
                                                    <div className="col-auto d-flex">
                                                        <button
                                                            type='reset'
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-outline me-2'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={() => { setIsOpen(false) }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        {
                            isOpenModalPago &&
                            <AgregarServicioModal
                                item={{
                                    id: item?.seguroSiniestroSeguimientoProcesoDatamovilId
                                }}
                                setIsOpenModalPago={setIsOpenModalPago}
                                isOpenModalPago={isOpenModalPago}
                                setIsOpen={setIsOpen}
                                handleGetServicesByGeneralId={handleGetServicesByGeneralId}
                            // handleGetAll={() => { GetAllProceso(1) }}
                            />
                        }
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default SolicitarServicioModal;