import { Formik, Form, Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Modal from "../../Components/Modal/Modal";
import { TextField } from "../../Components/TextField";
import * as Yup from "yup";
import ModeloAPI from "../../Services/Catalogos/Modelo";
import Catalogo from "../../Services/Catalogos/Catalogo";

const StatusDatamovil = ({
  isOpen,
  setIsOpen,
  item,
  handleGetAll,
  action,
  itemTbl,
  modalMessage,
  setModalMessage,
  existingData,
}) => {
  const refbtn = useRef();
  let initial = {
    statusDatamovilId: item?.statusDatamovilId ?? "",
    statusDatamovil: item?.statusDatamovil ?? "",
    status: item?.status ?? true,
  };
  const [initialValue, setInitialValue] = useState(initial);
  const [messageErrorSocio, setMessageErrorSocio] = useState("");
  const validate = Yup.object({
    statusDatamovil: Yup.string()
      .required("El campo es requerido.")
      .trim("El texto no debe empezar ni terminar con espacios")
      .strict(true)
      .matches(
        "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
        "La información ingresada es incorrecta."
      )
      .matches(
        "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
        "solo debe existir un espacio entre cada palabra."
      )
      .min(3, "minimo 3 caracteres"),
  });

  const cleanString = (str = "") => {
    str = str.replace(/[^a-zA-Z0-9 ]/g, "");
    return str.toLowerCase().trim();
  };

  const handleAddEdit = async (item) => {
    refbtn.current.disabled = true;

    console.log(existingData);
    if (
      existingData.find(
        (x) =>
          cleanString(x.statusDatamovil) === cleanString(item.statusDatamovil)
      )
    ) {
      setMessageErrorSocio("El estatus ya existe");
      return;
    }
    if (action === "add") {
      await Catalogo.addStatusDatamovil(item)
        .then((res) => {
          setIsOpen(false);
          handleGetAll(1);
          setModalMessage({
            ...modalMessage,
            isOpen: true,
            type: 2,
            message: "Datos guardados correctamente.",
          });
        })
        .catch((e) => {
          setIsOpen(false);

          if (e.response !== undefined)
            setModalMessage({
              ...modalMessage,
              isOpen: true,
              type: 1,
              message: e.response.data.message,
            });
          else
            setModalMessage({
              ...modalMessage,
              isOpen: true,
              type: 1,
              message: e.message,
            });
        });
    }
    if (action === "edit") {
      await Catalogo.updateStatusDatamovil(item)
        .then((res) => {
          setIsOpen(false);
          handleGetAll(1);
          setModalMessage({
            ...modalMessage,
            isOpen: true,
            type: 2,
            message: "Datos modificados correctamente.",
          });
        })
        .catch((e) => {
          setIsOpen(false);

          if (e.response !== undefined)
            setModalMessage({
              ...modalMessage,
              isOpen: true,
              type: 1,
              message: e.response.data.message,
            });
          else
            setModalMessage({
              ...modalMessage,
              isOpen: true,
              type: 1,
              message: e.message,
            });
        });
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={validate}
      onSubmit={(values) => {
        handleAddEdit(values);
      }}
    >
      {({ isValid, values }) => (
        <Modal isOpen={isOpen} color="#fff">
          <section className="d-flex justify-content-between mx-4">
            <span className="">
              <strong>
                Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla}{" "}
                {itemTbl.nombreProceso}
              </strong>
            </span>
          </section>
          <section className="my-4 mx-4" style={{ width: "30rem" }}>
            <span className="fs-5">
              <strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong>
            </span>
            <Form>
              <div className="mb-3">
                <label
                  htmlFor="staticEmail"
                  className="col-sm-2 col-form-label"
                >
                  Estatus
                </label>
                <div className="col-ms-6 form-check form-switch">
                  <label className="form-check-label">
                    {values?.status ? "Activo" : "Inactivo"}
                  </label>
                  <Field
                    id="AT_Estatus"
                    className="form-check-input"
                    type="checkbox"
                    name="status"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-sm-6">
                  <TextField
                    id="EstatusDatamovil"
                    label="Estatus Datamovil"
                    name="statusDatamovil"
                    type="text"
                    holder="Escribe"
                  />
                </div>
              </div>
              <span className="text-danger">{messageErrorSocio}</span>

              <section className="my-3">
                <button
                  id="AT_btnCancelar"
                  className="btn btn-outline"
                  type="reset"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancelar
                </button>
                <button
                  id="AT_btnGuardar"
                  className="btn mx-2"
                  ref={refbtn}
                  type="submit"
                  disabled={!isValid}
                >
                  {item == null ? "Guardar" : "Editar"}
                </button>
              </section>
            </Form>
          </section>
        </Modal>
      )}
    </Formik>
  );
};

export default StatusDatamovil;
