import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { GarantiaReparacionSlice, setIdGarantiaReparacion } from '../../Redux/Slice/reparacionSlice';
import Garantias from '../../Services/Cartera/Garantias';
import { ListaGarantiasReparacionDetalleSlice, setlistaGarantiasReparacionDetalle } from '../../Redux/Slice/listaGarantiasReparacionDetalleSlice';
import Datamovil from '../../Services/Resguardos/Datamovil';

const OrdenDesinstalacionModal = ({ modalMessage, setModalMessage, getOrdenDesinstalacion, generalid, datamovilId, tipoOrden }) => {
    const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const initial = {
        generalid: generalid,
        comentariosRechazo: ""
    }

    const [initialValue, setInitialValue] = useState(initial)
    const [total, setTotal] = useState(0);
    const metodoPago = [{ value: 1, label: "Efectivo" },{ value: 2, label: "Depósito" },{ value: 3, label: "Transferencia" }];

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        tecnico: Yup.string().required('Campo requerido')
    });

    useEffect(() => {
    }, []);
    
    const handlerRechazar = async (values) => {
        let params = {
            generalId: generalid,
            autorizada: false,
            comentariosRechazo: values.comentariosRechazo
        }
        await Datamovil.datamovilActualizar(params)
        .then(resp => {
            actualizarOrden(40);
            if(tipoOrden){
                const date = new Date();
                const localDate = date.toLocaleString();
                const contenidoEmail = {
                    VARIABLE_FECHA: ("0"+localDate.getDate()).slice(-2)+"-"+("0"+(localDate.getMonth()+1)).slice(-2)+"-"+localDate.getFullYear(),
                    VARIABLE_HORA: ("0"+localDate.getHours()).slice(-2)+":"+("0"+localDate.getMinutes()).slice(-2)+":"+("0"+localDate.getSeconds()).slice(-2),
                    VARIABLE_MARCA: nombreMarca,
                    VARIABLE_MODELO: nombreModelo,
                    VARIABLE_AÑO: nombreYear,
                    VARIABLE_VERSION: nombreVersion,
                    VARIABLE_VIN: vin,
                    VARIABLE_CONTRATO: numContrato,
                    VARIABLE_COMENTARIO: values.comentariosRechazo,
                }
                
                Garantias.creardatosenviar({
                    modulo: 'Desinstalación del Datamovil sin autorizacion',
                    tipoCorreoId: 51,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Desinstalación del Datamovil sin autorizacion:", err);
                })
            }
        })
        .catch(err => {
            
        })

    }

    const handlerAutorizar = async () => {
        let params = {
            generalId: generalid,
            autorizada: true,
        }
        await Datamovil.datamovilActualizar(params)
        .then(resp => {
            actualizarOrden(70);
        })
        .catch(err => {

        })
    }

    const actualizarOrden = async (status) => {
        let params = {
            datamovilId: datamovilId,
            datamovilOrdenDesinstalacionStatusId: status
        }
        if(status === 70){
            params.concluido = true;
        }
        await Datamovil.datamovilOrdenDesinstalacionActualizar(params)
        .then(resp => {
            if(status === 40){
                toast.success(message("Se rechazó la orden de desinstalación correctamente"));
            }else if(status === 70){
                toast.success(message("Se autorizó la orden de desinstalación correctamente"));
            }
            getOrdenDesinstalacion();

            // TipoContacto en true = Presencial, false = Llamada
            // if(resp.data.tipoContacto && resp.data.agendarCita){
            /* Si no requiere atención inmediata se le genera la cita, 
            agendar cita en false = crear cita, porque cambio la pregunta
            a atención inmediata
            */

            console.log(resp.data.agendarCita)
            if(status === 70){
                if(!resp.data.agendarCita){ // Quiere decir que no requiere atención inmediata y por ende se le genera la cita
                    crearCitaDesinstalacion();
                }else{
                    crearRevision();
                }
            }
            setModalMessage({ isOpen: false });
        })
        .catch(err => {
            if(status === 40){
                toast.error(message("¡Error, al intentar rechazar la orden de desinstalación!"));
            }else if(status === 70){
                toast.error(message("¡Error, al intentar autorizar la orden de desinstalación!"));
            }
        });
    }

    
    const crearCitaDesinstalacion = async () => {
        let params = {
            datamovilId: datamovilId,
            datamovilCitaDesinstalacionStatusId: 10,
            status: true
        }

        await Datamovil.datamovilCitaDesinstalacionCrear(params)
        .then(resp => {
            toast.success(message("Se creó la cita de desinstalación correctamente"));
            console.log(resp);
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crar la cita de desinstalación!"));
            console.error(err);
        })
    }

    const crearRevision = async () => {
        let params = {
            datamovilId: datamovilId,
            DatamovilRevisionStatusId: 20,
            status: true
        }

        await Datamovil.datamovilRevisionCrear(params)
        .then(resp => {
            toast.success(message("Se creó la revisión de datamovil correctamente"));
            console.log(resp);
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crear la revisión datamovil!"));
            console.error(err);
        })
    }

    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }


    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }


    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            {
                                modalMessage.type === 4 &&
                                <>
                                    <div className="row mt-3 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    Indica el motivo de rechazo para la solicitud de desinstalación
                                                </small>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <TextAreaField id="comentariosRechazo"
                                                        label="Comentarios ejecutivo"
                                                        disabled={false}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentariosRechazo"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("comentariosRechazo", event.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => handleRegresar()}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        handlerRechazar(values);
                                                        resetForm();
                                                    }}
                                                >
                                                    Rechazar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            }
                            {
                                modalMessage.type === 3 &&
                                <>
                                    <div className="row mt-3 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    ¿El cliente está autorizado para realizar la desinstalación?
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => handleRegresar()}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        handlerAutorizar();
                                                        resetForm();
                                                    }}
                                                >
                                                    Autorizar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            }
                            
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default OrdenDesinstalacionModal;
