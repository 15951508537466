import axios from '../../Utils/ApiService';

const EntregaService = {
    getAllListaEntrega: (page, rows, tipoCompraId, ubicacionId, string) => {
        return axios.get(`/functions/GetAllVehiculosEntrega?page=${page}&Rows=${rows}&UbicacionId=${ubicacionId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    },
    getAllListaEntregaPendiente:(page, rows, tipoCompraId, ubicacionId, string, fechaInicio, fechaFin) =>{
        return axios.get(`/functions/GetAllVehiculosPendienteCancelar?page=${page}&Rows=${rows}&UbicacionId=${ubicacionId}&TipoCompraId=${tipoCompraId}&Buscar=${string}&FechaInicio=${fechaInicio}&FechaFin=${fechaFin}`);
    },
    AddEntregaRealizada: (data) => {
        return axios.post(`/functions/RealizarEntrega`, data);
    },
    AddEntregaRechazada: (data) => {
        return axios.post(`/functions/RealizarCancelarEntrega`, data);
    },
    //EXpediente
    ConcluirExpediente: (data) => {
        return axios.post(`/functions/ConcluirExpediente`, data);
    },

    SaveEntregaCancelada: (data) => {
        return axios.post(`/functions/CancelarEntrega`, data);
    },

    SaveEntregaRealizada: (data) => {
        return axios.post(`/functions/RealizarEntregaEstatus`, data);
    },

    SaveEntregaRechazada: (data) => {
        return axios.post(`/functions/SaveEntregaAcendes`, data);
    },
    GetById: (generalId) => {
        return axios.get(`/functions/GetAVehiculosEntregaById?GeneralId=${generalId}`);
    },
    GetByIdPorCancelar: (generalId) => {
        return axios.get(`/functions/GetEntregaCanceladaInfoById?GeneralId=${generalId}`);
    },
    cambioStatusCargaEvidenciaEntrega:(data)=>{
        return axios.post(`/functions/CambioEstatusEntregaCargaEvidencia`,data)
    },
}
export default EntregaService;