import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import AutorizaCambioDevolucionModal from '../../../Components/Modal/AutorizaCambioDevolucionModal';
import RechazarCambioDevolucionModal from '../../../Components/Modal/RechazarCambioDevolucionModal';
import { setDevolucionSlice } from '../../../Redux/Slice/Devoluciones/devolucionesSlice';
import CardCalculo from './CardCalculo';

const CardDevoluciones
    = React.forwardRef(({ ...props }, ref) => {
        const { BloqueoId, FechaCita, EstatusCitaGarantia, FolioGarantia, LlamadaId, KilometrajeActual, CarnetServicioId, FolioCarnet, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, TallerAutorizado, GeneralId, TallerAutorizadoId, Ejecutivo, TipoGarantiaId, TipoGarantia, EstatusGarantia, StatusGarantiaId } = useSelector((state) => state.GarantiaSlice);
        const { cambioDevolucionId, origen, origenId, cambioDevolucionFolio, fechaAlta, usuarioAlta, usuarioActualiza, concluido, fechaActualiza, cambioDevolucionStatusId, cambioDevolucionStatus, status, usuarioAltaNombre, usuarioActualizaNombre, fechaHoraOperacion, usuarioSolicita, usuarioAprueba, usuarioSolicitaNombre, usuarioApruebaNombre, tipoDevolucion, motivoCambioDevolucionId, motivoCambioDevolucion, comentarios, fechaHoraAprobacion, aprobado, notificado, descuentos, boletinado, fechaEstimadaConclusion, comentariosAprobacion, comentariosRechazoSupervisor, comentariosRechazoEjecutivo, reagendarEntrega, razonAplica, fechaHoraNotificacion } = useSelector((state) => state.DevolucionesSlice);

        const { generalId, diagnostico } = props
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();

        const { fechaEntrega,
            nombreYear,
            vin,
            nombreMarca,
            nombreModelo,
            nombreVersion,
            numContrato,
            cliente,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);

        
        let {
            tipoOperacion,
            motivoCambioDevolucionOptions,
            getCambioDevolucion
        } = useDevolucion()

        let initial = {
            cambioDevolucionId: "",
            origen: "",
            origenId: "",
            cambioDevolucionFolio: "",
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            cambioDevolucionStatusId: Number(cambioDevolucionStatusId) || null,
            cambioDevolucionStatus: cambioDevolucionStatus,
            status: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            fechaHoraOperacion: "",
            usuarioSolicita: "",
            usuarioAprueba: "",
            usuarioSolicitaNombre: "",
            usuarioApruebaNombre: usuarioApruebaNombre,
            tipoDevolucion: "",
            motivoCambioDevolucionId: "",
            motivoCambioDevolucion: "",
            comentarios: "",
            fechaHoraAprobacion: "",
            aprobado: "",
            notificado: "",
            descuentos: "",
            boletinado: "",
            razonAplica: "",
            fechaEstimadaConclusion: "",
            comentariosAprobacion: "",
            comentariosRechazoSupervisor: "",
            comentariosRechazoEjecutivo: "",
            reagendarEntrega: "",
            fechaHoraNotificacion: "",
        }


        const navigate = useNavigate();
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        let { idllamada } = useParams();

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [verEntregas, setVerEntregas] = useState(false);
        const [EntregaStatus, setEntregaStatus] = useState('');
        const [disabled, setDisabled] = useState(false);
        const [_tipoGarantiaId, setTipoGarantiaId] = useState(null);

        useEffect(() => {
            setData();
        }, [cambioDevolucionId]);

        const setData = () => {
            setInitialValue({ 
                cambioDevolucionId, 
                origen, 
                origenId, 
                cambioDevolucionFolio, 
                fechaAlta, 
                usuarioAlta, 
                usuarioActualiza, 
                fechaActualiza, 
                cambioDevolucionStatusId, 
                cambioDevolucionStatus, 
                status, 
                usuarioAltaNombre, 
                usuarioActualizaNombre, 
                fechaHoraOperacion, 
                usuarioSolicita, 
                usuarioAprueba, 
                usuarioSolicitaNombre, 
                usuarioApruebaNombre, 
                tipoDevolucion, 
                motivoCambioDevolucionId, 
                motivoCambioDevolucion, 
                comentarios, 
                fechaHoraAprobacion, 
                aprobado, 
                notificado, 
                descuentos, 
                boletinado,
                concluido,
                razonAplica,
                fechaEstimadaConclusion, 
                comentariosAprobacion, 
                comentariosRechazoSupervisor, 
                comentariosRechazoEjecutivo, 
                reagendarEntrega,
                fechaHoraNotificacion
            });
        };



        React.useImperativeHandle(ref, () => ({
            // getReparacion
        }));

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            values.cambioDevolucionStatusId = 20;
            if(values.concluido){
                values.cambioDevolucionStatusId = 50;
            }

            if(values.notificado){
                values.cambioDevolucionStatusId = 60;
            }
            
            // console.log(values)
            // return false;
            await Garantias.garantiaCambioDevolucionActualizar(values)
            .then(resp => {
                dispatch(setDevolucionSlice(resp.data));
                toast.success(message("Cambio / Devolución actualizada correctamente"));
                getCambioDevolucion();
            })
            .catch(err => {
                toast.error(message("¡Error al intentar actualizar el Cambio / Devolución!"));
            })
            .finally(() => {
                setModalMessage({ ...modalMessage, isOpen: false });
                setLoading(false);
                
                if(values.notificado){
                    crearCalculadora();
                }
            });
        }

        const crearCalculadora = async () => {
            let params = {
                cambioDevolucionId: cambioDevolucionId,
                cambioDevolucionCalculadoraStatusId: 10,
                status: true
            }

            await Garantias.calculadoraCrear(params)
            .then(resp => {
                console.log(resp.data);
                toast.success(message("La calculadora se creo correctamente"));
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error al intentar crear la calculadora!"));
            });
        };


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const formatDate = (date) => {
            if(date !== undefined && date !== "" && date !== null) {
                const formatted = date.split("T")[0]+" "+date.split("T")[1].split(".")[0];
                return formatted;
            }
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <AutorizaCambioDevolucionModal
                            getCambioDevolucion={getCambioDevolucion}
                            GarantiaId={GarantiaId}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazarCambioDevolucionModal
                            getCambioDevolucion={getCambioDevolucion}
                            GarantiaId={GarantiaId}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseDevolucion" role="button" aria-expanded="false" aria-controls="collapseDevolucion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={!notificado ? 'semaforo semaforo-yellow' : (notificado ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Cambio / Devolución </strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{cambioDevolucionStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseDevolucion">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Detalle del cambio</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    Number(cambioDevolucionStatusId) === 10 && 
                                                                    <>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn btn-gray'
                                                                                // disabled={!isValid || (!isValid && disabled) || initialValue.reparacionStatusId === '0'}
                                                                                disabled={!isValid}
                                                                                onClick={() => setModalMessage({ isOpen: true, type: 4, title: 'Rechazar cambio o devolución' })}>
                                                                                Rechazar
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn'
                                                                                // disabled={!isValid || (!isValid && disabled) || initialValue.reparacionStatusId === '0'}
                                                                                disabled={!isValid}
                                                                                onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Confirmar autorización' })}>
                                                                                Autorizar
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    (Number(cambioDevolucionStatusId) === 20 || Number(cambioDevolucionStatusId) === 50) &&
                                                                    (!concluido || !notificado) &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            // disabled={!isValid || (!isValid && disabled) || initialValue.reparacionStatusId === '0'}
                                                                            disabled={!isValid}
                                                                            onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="tipoDevolucion"
                                                                label="Tipo de la operacion"
                                                                disabled={cambioDevolucionStatusId !== 20}
                                                                name="tipoDevolucion"
                                                                items={tipoOperacion}
                                                                onChange={(event) => {
                                                                    setFieldValue("tipoDevolucion", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="motivoCambioDevolucionId"
                                                                label="Motivo cambio o devolución"
                                                                disabled={cambioDevolucionStatusId !== 20}
                                                                name="motivoCambioDevolucionId"
                                                                items={motivoCambioDevolucionOptions}
                                                                onChange={(event) => {
                                                                    setFieldValue("motivoCambioDevolucionId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="usuarioApruebaNombre" disabled={true} label="Usuario" name="usuarioApruebaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioApruebaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <span>Fecha y hora de la solicitud</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                               <span>{fechaAlta}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="razonAplica"
                                                                label="¿Por qué aplica cambio o devolución?"
                                                                disabled={cambioDevolucionStatusId !== 20}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="razonAplica"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("razonAplica", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={cambioDevolucionStatusId !== 20} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                                // setConcluida(event.target.checked);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cambio / Devolución concluida</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Notificar al cliente</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioSolicitaNombre" disabled={true} label="Usuario notificó al cliente" name="usuarioSolicitaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioSolicitaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <span>Fecha notificó al cliente</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                <span>{formatDate(fechaHoraNotificacion)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="fechaEstimadaConclusion" disabled={cambioDevolucionStatusId !== 50 && !concluido} label="Fecha estimada proceso de evaluación" name="fechaEstimadaConclusion" type="date" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("fechaEstimadaConclusion", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios del cliente"
                                                                disabled={cambioDevolucionStatusId !== 50 && !concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={cambioDevolucionStatusId !== 50 && !concluido} type="checkbox" name="notificado" onClick={(event) => {
                                                                setFieldValue("notificado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    cambioDevolucionStatusId === 60 &&
                    <CardCalculo />
                }
            </>
        )
    })
export default CardDevoluciones