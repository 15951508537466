import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';

const EntregaExpedienteCard
    = React.forwardRef(({ ...props }, ref) => {
        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();

        let initial = {
            cierreContratoEntregaExpedienteId: "",
            cierreContratoId: "",
            cierreContratoEntregaExpedienteStatusId: 0,
            cierreContratoEntregaExpedienteStatus: "",
            color: "",
            documentoDescargado: false,
            ejecutivo: "",
            ubicacionId: "",
            sucursalName: null,
            concluido: false,
            fechaConclusion: null,
            usuarioAlta: "",
            usuarioActualiza: "",
            status: false,
            usuarioAltaNombre: null,
            usuarioActualizaNombre: null,
            fechaAlta: "",
            fechaActualiza: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [ubicaciones, setUbicaciones] = useState([]);

        useEffect(() => {
            getEntregaExpediente();
            getUbicaciones();
        }, []);

        /* CONSULTAS */
        const getEntregaExpediente = async () => {
            await Cierres.cierreContatoEntregaExpedienteGet(cierreid)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
        };

        const getUbicaciones = async () => {
            let tmp = [];
            await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                resp.data.forEach(item => {
                    tmp.push({
                        value: item.UbicacionId,
                        label: item.Nombre
                    })
                });

                setUbicaciones(tmp);
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {

            if(values.concluido){
                values.cierreContratoEntregaExpedienteStatusId = 30;
            }
            
            await Cierres.cierreContatoEntregaExpedienteAtualizar(values)
            .then(async (resp) => {
                if(values.concluido){                    
                    const contenido = {
                        VARIABLE_MARCA: nombreMarca,
                        VARIABLE_MODELO: nombreModelo,
                        VARIABLE_AÑO: nombreYear,
                        VARIABLE_VERSION: nombreVersion,
                        VARIABLE_VIN: vin,
                        VARIABLE_CONTRATO: numContrato,
                    }
        
                    await Garantias.creardatosenviar({
                        modulo: 'Armado de expediente concluido',
                        tipoCorreoId: 46,
                        contenido: JSON.stringify(contenido)
                    })
                    .then(resp => {
                        toast.success(message("Se envió el correo de armado de expediente concluido correctamente"));
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar enviar el correo de armado de expediente concluido!"));
                        console.log("Error al enviar correo Revisión de Unidad Aprobada:", err);
                    });
                }

                setInitialValue(resp.data);
                toast.success(message("Se actualizó la entrega de expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la entrega de expediente!"));
            });
        }

        const downloadContratoPDF = async () => {
            let params = {
                cierreContratoId: cierreid,
                documentoDescargado: true
            }

            if(!initialValue.documentoDescargado) {
                params.cierreContratoEntregaExpedienteStatusId = 20;
            }

            // setTimeout(() => {
            //     const requestBody = {
            //         nombrePlantilla: "ConvenioTransaccionTerminacionContrato",
            //         parametros: {
            //             "<<VARIABLE_FECHA>>" : ,
            //             "<<VARIABLE_CLIENTE>>" : ,
            //             "<<VARIABLE_DOMICILIO_REPRESENTADA>>" : ,
            //             "<<VARIABLE_DOMICILIO_CLIENTE>>" : ,
            //             "<<VARIABLE_FECHA_ENDOSO>>" : ,
            //             "<<VARIABLE_MARCA>>" : ,
            //             "<<VARIABLE_TIPO>>" : ,
            //             "<<VARIABLE_MODELO>>" : ,
            //             "<<VARIABLE_SERIE>>" : ,
            //             "<<VARIABLE_MOTOR>>" : ,
            //             "<<VARIABLE_COLOR>>" : ,
            //             "<<VARIABLE_FECHA_CONTRATO>>" : ,
            //             "<<VARIABLE_ANTICIPO>>" : ,
            //             "<<VARIABLE_RESTANTE>>" : ,
            //             "<<VARIABLE_RESTANTE_LETRA>>" : ,
            //             "<<VARIABLE_DOMICILIO_REPRESENTADA>>" : ,
            //             "<<VARIABLE_DOMICILIO_CLIENTE>>" : ,
            //             "<<VARIABLE_FECHA_CONTRATO_LETRA>>" : ,
            //         }
            //     };

            //     Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            //         //Pendiente de revisar por que no jala con el file-server
            //         documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

            //     }).catch(err => {
            //         console.error(err);
            //     });
            // }, 500);

            await Cierres.cierreContatoEntregaExpedienteAtualizar(params)
            .then(resp => {
                setInitialValue(resp.data);
                toast.success(message("Se generó el archivo PDF correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar generar el archivo PDF!"));
            });
        }
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseEntregaExpediente" role="button" aria-expanded="false" aria-controls="collapseEntregaExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Entrega de expediente a cliente</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small>{initialValue.cierreContratoEntregaExpedienteStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseEntregaExpediente">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar Documento</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray'>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!initialValue.documentoDescargado || initialValue.cierreContratoEntregaExpedienteStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-auto">
                                                            <small>Checklist</small>
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <button 
                                                                className='btn btn-gray' 
                                                                disabled={initialValue.cierreContratoEntregaExpedienteStatusId === 30}
                                                                onClick={(e) => {downloadContratoPDF();}}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Detalle de la entrega</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="ejecutivo" disabled={!initialValue.documentoDescargado || initialValue.cierreContratoEntregaExpedienteStatusId === 30} label="Ejecutivo" name="ejecutivo" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("ejecutivo", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="ubicacionId"
                                                                label="Ubicación"
                                                                disabled={!initialValue.documentoDescargado || initialValue.cierreContratoEntregaExpedienteStatusId === 30}
                                                                name="ubicacionId"
                                                                items={ubicaciones}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionId", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={!initialValue.documentoDescargado || initialValue.cierreContratoEntregaExpedienteStatusId === 30} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Entrega de expediente finalizada</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default EntregaExpedienteCard