import React, { useEffect, useState } from "react"
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard.jsx"
import CarteraInfoCard from "../../../../Components/CarteraInfoCard.jsx"
import RegistroPromesaCards from "../Cards/RegistroPromesaCards.jsx"
import ResguardoUnidadCards from "../Cards/ResguardoUnidadCards.jsx"
import SeguimientoVisitaCards from "../Cards/SeguimientoVisitaCards.jsx"
import ContratoCard from "../../../../Components/ContratoCard";
import useSeguimientoVisitaDetalle from '../../../../Hooks/SeguimientoVisita/useSeguimientoVisitaDetalle.js'
import VisitaDomiciliariaCard from "../Cards/VisitaDomiciliariaCard.jsx"
import RecoleccionUnidadCard from "../Cards/RecoleccionUnidadCard.jsx"
import Modal from "../../../../Components/Modal/Modal.jsx"
import Spinner from "../../../../Components/Loadig.jsx"
import { useSelector } from "react-redux"
import { formatearMonto, FormatFecha, FormatFechaHora } from '../../../../Utils/functions';

const SeguimientoVisitaDetalle = () => {
   const { contratoActual } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [showPromesaPago, setShowPromesaPago] = useState(false);
   const [showRecoleccionResguardo, setShowRecoleccionResguardo] = useState(false);
   const {
      userDetail,
      promesasRotas,
      loading,
      handleReturnPage,
      allContracts
   } = useSeguimientoVisitaDetalle();

   const formattedData = {
      ...contratoActual,
      monto_adeudo: formatearMonto(contratoActual.monto_adeudo),
      fecha_ultima_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave),
      saldo_credito: formatearMonto(contratoActual.saldo_credito),
      fecha_ultimo_pago: contratoActual.fecha_ultimo_pago === "1990-01-01T00:00:00" ? "-" : FormatFecha(contratoActual.fecha_ultimo_pago),
      vigencia_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave)
   };

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                     <i className="bx bxs-chevron-left mx-2"></i>
                     Regresar a listado de seguimiento de visita
                  </div>
               </header>
               <div className="col-11 col-sm-12 col-md-12 d-flex">
                  <div className="col-6 px-4 py-2">
                     <VehiculoInfoCard
                        GeneralId={contratoActual?.vehiculo?.generalId}
                        VIN={contratoActual?.vin}
                        verClaves={true}
                        solicitarClave={true} />
                  </div>
                  <div className="col-6 px-4 py-2">
                     <ContratoCard vin={contratoActual?.vin} />
                  </div>
               </div>
               <div className="col-12 px-4 py-2">
                  <CarteraInfoCard
                     contrato={formattedData}
                     user={userDetail}
                     promesasRotas={promesasRotas} />
               </div>
               <div className="col-12 px-4 py-2">
                  <SeguimientoVisitaCards />
               </div>
               <div className="col-12 px-4 py-2">
                  <VisitaDomiciliariaCard
                     handleNextSteps={showNext => {
                        setShowPromesaPago(showNext);
                        if (showNext === false) {
                           setShowRecoleccionResguardo(false);
                        }
                     }} />
               </div>
               { showPromesaPago && (
                     <div className="col-12 px-4 py-2">
                        <RegistroPromesaCards
                           show={showPromesaPago}
                           handleNextSteps={(formularioActual) => {
                              setShowRecoleccionResguardo(formularioActual > 1)
                           }}
                           allContracts={allContracts}
                        />
                     </div>
                  )
               }
               {
                  showRecoleccionResguardo && (
                     <>
                        <div className="col-12 px-4 py-2">
                           <RecoleccionUnidadCard
                              show={showRecoleccionResguardo} />
                        </div>
                        <div className="col-12 px-4 py-2">
                           <ResguardoUnidadCards
                              show={showRecoleccionResguardo} />
                        </div>
                     </>
                  )
               }
            </div>
         </div>
      </>
   )
}

export default SeguimientoVisitaDetalle