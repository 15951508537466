import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useSelector } from 'react-redux';
import { FormatNumber, FormatNumberMoney, message, pageRows } from '../../../Utils/functions';
import Select from 'react-select'
import DataTable from '../../../Components/datatable/DataTable'
import { TextAreaField } from '../../../Components/TextAreaField';
import CambioEtapaInventarioService from '../../../Services/Inventario/CambioEtapaInventarioService';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import DeleteCostosModal from './DeleteCostosmodal';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';

const CostosInventario = () => {
    const navigate = useNavigate();
    const refbtn = useRef();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);

    const refFormik = useRef();
    const columns = [
        { field: 'fechaCosto', headerName: 'Fecha', width: 250 },
        { field: 'tipoCosto', headerName: 'Tipo de costo', width: 250 },
        { field: 'subtotal', headerName: 'subtotal', width: 130 },
        { field: 'iva', headerName: 'Iva', width: 130 },
        { field: 'total', headerName: 'total', width: 160 },
        { field: 'usuario', headerName: 'usuario', width: 130 },
        { field: 'comentarios', headerName: 'Comentarios', width: 230 }
    ];

    let initial = {
        generalId: id,
        tipoCostoId: "",
        subtotal: 0,
        total: 0,
        iva: 0,
        comentarios: "",
        costosGeneralesId: "",

        VIN: item?.vin,
        marca: item?.nombreMarca,
        modelo: item?.nombreModelo,
        year: item?.nombreYear,
        version: item?.nombreVersion,
        etapaActual: item?.etapaActual,
        etapaOrigenId: item?.etapa_Id,
        color: item?.colorVehiculo,

    }

    const validate = Yup.object({
        tipoCostoId: Yup.string()
            .required("El campo es requerido."),
        subtotal: Yup.number()
            .required("El campo es requerido."),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo ' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        total: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });

    const [initialValue, setInitialValue] = useState(initial);
    const [ValueIva, setValueIva] = useState(0);
    const [dataStatus, setDataStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalCosto, setIsOpenModalCosto] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [currenPage, setCurrenPage] = useState(1);
    const [dataTipoCosto, setDataTipoCosto] = useState([]);
    const [dataCosto, setDataCosto] = useState([]);
    const [IsOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    const [OpenModalDeleted, setOpenModalDeleted] = useState(false);
    const [itemCosto, setItemCosto] = useState();

    useEffect(() => {
        handleGetAllCostosById();
    }, [])

    const handleGetAllSelects = async () => {
        const [] = await Promise.all([handleGetParameters(), handleGetTipoCosto()]).finally(() => {
            setLoading(false);
        });
    }

    const handleGetTipoCosto = async () => {
        await Catalogo.getdataTipocosto("Generales")
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCostoId,
                        label: item.nombre
                    })
                    // }
                });
                setDataTipoCosto(NewData);
            }).catch(e => {
            })
    }

    const handleGetAllCostosById = async () => {
        setLoading(true);
        await CambioEtapaInventarioService.getAllCostosById(id)
            .then(res => {
                console.log(res.data.data)

                setDataCosto(res.data.data)
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
    };
    const handleCostos = () => {
        let total = 0;
        dataCosto?.map(x => {
            if (x.total !== "")
                total = total + parseFloat(x.total)
        })
        return total;
    }

    const handleAddEdit = async (value) => {
        setLoading(true);
        await CambioEtapaInventarioService.SavecostosGenerales(value)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                setIsOpenModalConfirm(false)
                setLoading(false);
                handleGetAllCostosById();
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.statusText));
                else
                    toast.error(message("¡Error.!", e.message));
            })

    }
    const handleGetParameters = async () => {
        setLoading(true);
        await IntelimotorAPI.getValorParametro("IVA")
            .then(res => {
                setValueIva(res.data.data.valor);
                setInitialValue({ ...initialValue, iva: ValueIva })
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, dirty, values, setFieldValue, submitForm }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />

                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        {/* <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        /> */}
                        <section className='col-12'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2 mt-4'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehículo</strong></span>
                                            {/* <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset> */}
                                        </div>

                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            {/* <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Etapa:" name="etapaActual" type="text" holder="Escribe" />
                                            </div> */}
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Color:" name="color" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2 mt-5'>
                                        <div className='d-flex align-items-center'>
                                            <span className='fs-5'><strong>Registro de costos</strong></span>
                                            <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.CostosSection, AccionesRapidas.Costos))}>
                                                <div className="ms-4 col-sm-4 mx-2">
                                                    <fieldset className='btn secondary' onClick={() => { setIsOpenModal(true); }} >Agregar costos</fieldset>
                                                </div>
                                            </ValidatePermission>
                                        </div>
                                        <ValidatePermission isActive={ValidatePermissionById(4, Section.CostosSection, AccionesRapidas.Costos).Ver}>
                                            <div className="mb-3 row">
                                                <DataTable
                                                    column={columns}
                                                    data={dataCosto}
                                                    pageCount={pageCount}
                                                    handlePageClick={handlePageClick}
                                                    currentPage={currenPage - 1}
                                                    deleteable={true}
                                                    handleRender={item => {
                                                        item.subtotal = "$ " + FormatNumberMoney(item.subtotal);
                                                        item.iva = "$ " + FormatNumberMoney(item.iva);
                                                        item.total = "$ " + FormatNumberMoney(item.total);
                                                    }}
                                                    handleDelete={(item) => { setItemCosto(item); setOpenModalDeleted(true) }}
                                                />
                                                {
                                                    dataCosto.length > 0 &&
                                                    <div className='py-2 mx-1 row px-0' style={{ borderTop: '1px solid', borderBottom: '1px solid' }}>
                                                        <span className='col-6 px-0'><strong>Total</strong> </span>
                                                        <span className='col-4 px-0'><strong>${handleCostos()}</strong></span>
                                                    </div>
                                                }

                                            </div>
                                        </ValidatePermission>
                                    </div>

                                </Form>
                            </div>
                        </section>
                    </div>
                    {
                        isOpenModal &&
                        <Modal isOpen={isOpenModal} color='#fff'>
                            <div className='d-flex' style={{ width: '30rem' }}>
                                <span className='fs-6'><strong>Agregar costos</strong></span>
                            </div>
                            <div className='my-4'>
                                <span>¿Seguro que deseas agregar un nuevo costo?</span>
                            </div>
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                                <fieldset className='btn mx-1 px-5' onClick={() => { setIsOpenModalCosto(true); setIsOpenModal(false); setIsOpenModalConfirm(false); handleGetAllSelects() }}>Continuar</fieldset>
                            </section>
                        </Modal>
                    }
                    {
                        isOpenModalCosto &&
                        <Modal isOpen={isOpenModalCosto} color='#fff'>
                            <section className='m-4' style={{ width: '45rem' }}>
                                <div className='d-flex flex-column fs-6 mb-3'>
                                    <span className='fs-5 mb-2'><strong>Registrar costos</strong></span>
                                    <span style={{ fontSize: '13px' }}>El registro de los costos permite llevar un control detallado de los costos relacionados con el vehículo.</span>
                                </div>
                                <label style={{ fontSize: '14px' }}><strong>1. Indica el tipo de costo y su monto</strong></label>
                                <div className="mb-3 my-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <SelectField label="Tipo de costo" name="tipoCostoId" items={dataTipoCosto} onChange={(event) => {
                                            setFieldValue("tipoCostoId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <TextField label="Subtotal" name="subtotal" type="number" holder="Escribe" onChange={(event) => {
                                            let value = event.target.value;
                                            setFieldValue(`subtotal`, event.target.value);
                                            let totalIva = (values.iva / 100) * value;

                                            setFieldValue(`total`, value == "" ? "" : totalIva + parseFloat(value));
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <TextField label="IVA" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                            let value = event.target.value;
                                            setFieldValue(`iva`, value);
                                            let totalIva = (value / 100) * values.subtotal;

                                            setFieldValue(`total`, value == "" ? "" : (totalIva + parseFloat(values.subtotal)));
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <TextField disabled label="Total" name="total" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <label style={{ fontSize: '14px' }}><strong>2. Captura los comentarios</strong></label>
                                <div className="mb-3 mt-2 row">
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                        }} />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end mt-4'>
                                    <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpenModalCosto(false); }}>Cancelar</button>
                                    <button ref={refbtn} className='btn mx-1 px-5' disabled={!(isValid)} onClick={() => { setIsOpenModalCosto(false); setIsOpenModalConfirm(true) }}>Aceptar</button>
                                    {/* <button ref={refbtn} className='btn mx-1 px-5' disabled={!(isValid)} onClick={submitForm}>Aceptar</button> */}
                                </section>
                            </section>
                        </Modal>
                    }
                    {
                        IsOpenModalConfirm &&
                        <Modal isOpen={IsOpenModalConfirm} color='#fff'>
                            <div className='d-flex' style={{ width: '30rem' }}>
                                <span className='fs-6'><strong>Confirmar costos</strong></span>
                            </div>
                            <div className='my-4'>
                                <span>¿Seguro que deseas agregar<strong> ${FormatNumber(values.total.toString())}</strong> al costo?</span>
                            </div>
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <button className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModalConfirm(false); setIsOpenModalCosto(true) }}>Cancelar</button>
                                <button className='btn mx-1 px-5' onClick={submitForm}>Continuar</button>
                            </section>
                        </Modal>
                    }
                    {
                        OpenModalDeleted &&
                        <DeleteCostosModal
                            isOpen={OpenModalDeleted}
                            setIsOpen={setOpenModalDeleted}
                            item={itemCosto}
                            handleGetAll={handleGetAllCostosById}
                        />
                    }
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )

            }
        </Formik>
    )
}

export default CostosInventario