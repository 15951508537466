import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { EtapaBandera, message, pageRows } from '../../../Utils/functions'
import { useNavigate, useParams } from 'react-router-dom'
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Llamada from '../../../Services/Cartera/Llamadas';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import { GarantiaSlice, setGarantiaSlice } from '../../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import CardGrua from './CardGrua';
import TablaMantenimiento from './TablaMantenimiento';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import CardInspeccionRapida from './CardInspeccionRapida';
import Garantias from '../../../Services/Cartera/Garantias';
import ConfigDataDocsService from '../../../Services/ConfigDataDocs/ConfigDataDocsService';

const CardGarantia = (props) => {
    const { BloqueoId, FechaCita, EstatusCitaGarantia, FolioGarantia, LlamadaId, KilometrajeActual, CarnetServicioId, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, GeneralId, TallerAutorizadoId, Ejecutivo, TipoGarantiaId, TipoGarantia, EstatusGarantia, StatusGarantiaId } = useSelector((state) => state.GarantiaSlice);
    const { FolioCarnet, generalId, fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, procedenciaAuto, nombreVersion, numMotor, numContrato, numSolicitud, producto, cliente, usoUnidad, fechaUltimoPago, TallerAutorizado } = useSelector((state) => state.DataVinSlice);
    const { dayGarantia, hourGarantia, sucursalId, sucursalName, CalendarioIdGarantia } = useSelector((state) => state.GarantiasSlice);
    const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
    const [_tipoGarantiaId, setTipoGarantiaId] = useState(null);

    const [inspeccionRapida, setInspeccionRapida] = useState(true);
    const [responsabilidadCliente, setResponsabilidadCliente] = useState(true);
    const [nombreGarantia, setNombreGarantia] = useState(EstatusGarantia);
    const [showCarnet, setShowCarnet] = useState(true);

    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    let initial = {
        GeneralId: generalId || "",
        KilometrajeActual: KilometrajeActual || "",
        CarnetServicioId: CarnetServicioId,
        ClasificacionFallaId: ClasificacionFallaId || "",
        Gravedad: Gravedad || "",
        TipoReporteId: TipoReporteId || "",
        // TallerAutorizadoId: TallerAutorizadoId || "",
        VehiculoMover: VehiculoMover,
        OrigenGruaId: OrigenGruaId || "",
        FechaHoraCita: FechaCita || null,
        FechaCita: "",
        LlamadaId: "",
        GarantiaId: GarantiaId || "",
        EstatusCita: EstatusCitaGarantia || "Sin Agendar",
        BloqueoId: BloqueoId || null,
        TipoGarantiaId: TipoGarantiaId || _tipoGarantiaId
    }

    const [collpsableGarantia, setCollpsableGarantia] = useState(false);
    const { motivollamada, idllamadaparam, inInspeccionRapida } = props
    const [talleres, setTalleres] = useState([]);
    const [tiposGarantia, setTiposGarantia] = useState([]);
    const [garantiaCalculada, setGarntiaCalculada] = useState("");
    const [initialValue, setInitialValue] = useState(initial);
    const [dataCarnet, setDataCarnet] = useState([]);
    const [dataClasificacionFalla, setDataClasificacionFalla] = useState([]);
    const [dataTipoReporte, setDataTipoReporte] = useState([]);
    const [dataVehiculoPuedeMoverse, setDataVehiculoPuedeMoverse] = useState([{ value: 1, label: "Si" }, { value: 0, label: "No" }]);
    const [loading, setLoading] = useState(false)
    const [_bloqueoId, setBloqueoId] = useState(false);
    const [OrigenGrua, setOrigenGrua] = useState([]);
    const [verCardGruas, setVerCardGruas] = useState(false);
    const [verGravedad, setVerGravedad] = useState("");
    const [etapaFinalizado, setEtapaFinalizado] = useState(false);

    let { idllamada } = useParams();
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 1, Origin: "Listado" });
    //Documentos necesesarios para la garantia
    const [docGarantia, setDocGarantia] = useState([{
        document: 'Carnet de Servicio',
        existe: false,
        nombre: 'Sin documento'
    },
    {
        document: 'Informacion de garantia',
        existe: false,
        nombre: 'Sin documento'
    }]);

    useEffect(() => {
        handleGetCarnet()
        handleGetClasificacionFalla()
        handleGetTipoReporte()
        GetTalleres()
        verTipoGarantia()
        HandleGetOrigenGrua()
    }, [motivollamada])

    useEffect(() => {
        setInitialValue(initial);
        getEtapa()
        if (TipoGarantia !== undefined && TipoGarantia !== null) {
            setGarntiaCalculada(TipoGarantia);
            setTipoGarantiaId(TipoGarantiaId);


        } else if (initialValue.TipoGarantiaId !== null) {
            verTipoGarantia();
        } else {
            setGarntiaCalculada('Sin Garantia');
        }
    }, [GarantiaId]);

    useEffect(() => {
        if (OrigenGrua.length > 0) {
            verGrua(OrigenGruaId)
        }
    }, [OrigenGrua])
    useEffect(() => {
        if (dataClasificacionFalla.length > 0) {
            changeGravedad(ClasificacionFallaId)
        }
    }, [dataClasificacionFalla])
    useEffect(() => {
        if (tiposGarantia.length > 0 && KilometrajeActual) {
            calcularGarantia(kilometraje)
            if (initialValue.TipoGarantiaId !== null) {
                // let tipoGarantiaNombre = tiposGarantia.filter(item => item.tipoGarantiaId === initialValue.TipoGarantiaId)[0].tipoGarantia;
                //setGarntiaCalculada(tipoGarantiaNombre);
            }
        }

    }, [tiposGarantia]) //Aqui

    useEffect(() => {
        setInitialValue({
            ...initialValue,
            FechaHoraCita: FechaCita || null,
            BloqueoId: BloqueoId || "",
            EstatusCita: EstatusCitaGarantia || "Sin Agendar"
        });
    }, [EstatusCitaGarantia])

    useEffect(() => {
        setInitialValue({
            ...initialValue,
            LlamadaId: idllamada
        })
        getDocuments()
    }, [idllamada])

    useEffect(() => {
        if (hourGarantia) {
            const formattedDate = `${dayGarantia.split("T")[0].split("-").reverse().join('-')} ${hourGarantia}`;
            setInitialValue({
                ...initialValue,
                FechaHoraCita: formattedDate
            });
        }
    }, [hourGarantia]);

    useEffect(() => {
        if (tiposGarantia.length > 0) {
            calcularGarantia(KilometrajeActual || initialValue.KilometrajeActual)
        }
    }, [tiposGarantia]);

    const validate = Yup.object({
        KilometrajeActual: Yup.number()
            .typeError('Debe contener solo números y no exceder los 10 caracteres')
            .integer()
            .min(kilometraje, `El kilometraje no debe ser menor a ${kilometraje}`)
            .required("El campo es requerido."),
        // .matches(/^\d{1,10}$/, "Debe contener solo números y no exceder los 10 caracteres"),
        CarnetServicioId: Yup.number()
            .required("El campo es requerido."),
        // .matches(/^(?!0$)[0-9a-fA-F-]*$/, "El campo es requerido"),
        ClasificacionFallaId: Yup.string()
            .required("El campo es requerido"),
        TipoReporteId: Yup.string()
            .required("El campo es requerido"),
        VehiculoMover: Yup.mixed()
            .required("El campo es requerido"),
    })

    const getDocuments = async () => {
        ConfigDataDocsService.getAllDocuments('kGarantia', 'eInLlamada', idllamada).then(res => {
            let infodata = res.data.data
            // console.log(res.data.data, "Los valores")

            const documentos = infodata[0].documentos;

            // Filtrar los documentos según las condiciones
            const docCarnetServicio = documentos.find(doc => doc.binario === "Carnet de Servicio");
            const docInfoGarantia = documentos.find(doc => doc.binario === "Información de Garantia");

            // Verificar el parámetro subido y guardarlo en la variable anterior
            const docGarantiaActualizado = [
                {
                    document: docCarnetServicio ? 'Carnet de Servicio' : null,
                    existe: docCarnetServicio ? docCarnetServicio.existe : null,
                    nombre: docCarnetServicio.existe ? docCarnetServicio.documentoEnDataDocs : 'Sin documento'
                },
                {
                    document: docInfoGarantia ? 'Informacion de garantia' : null,
                    existe: docInfoGarantia ? docInfoGarantia.existe : null,
                    nombre: docInfoGarantia.existe ? docInfoGarantia.documentoEnDataDocs : 'Sin documento'
                }
            ];

            setDocGarantia(docGarantiaActualizado)

        })
    }

    const getEtapa = async () => {
        EtapaBandera("kGarantia", "eInLlamada", idllamada).then(acabado => {
            setEtapaFinalizado(acabado)
        }).catch(error => {
            // Manejar errores si la promesa es rechazada
        });
    }

    const GetTalleres = async () => {
        await Llamada.getTalleresSlice()
            .then(res => {
                if (res.data.data === null) {

                } else {
                    let dataRes = res.data.data
                    let data = []

                    dataRes.map(item => {
                        data.push({
                            value: item.tallerId,
                            label: item.taller
                        })
                    })
                    setTalleres(data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const HandleGetOrigenGrua = async () => {
        await Llamada.getOrigenGrua().then(res => {
            let OrigenGruas = []
            res.data.data?.map(item => {
                OrigenGruas.push({
                    value: item.origenGruaId,
                    label: item.origenGrua,
                    Mover: item.mover
                });
            });
            setOrigenGrua(OrigenGruas);
        })
    }

    const handleGetCarnet = async () => {
        // const res = await Catalogo.getCarnet();
        const res = await Catalogo.getDropdownsByTable('Catalogos.CarnetServicio');
        const listaCarnet = (res.data).map(item => ({
            value: item.CarnetServicioId,
            label: item.Carnet
        }));
        setDataCarnet(listaCarnet);
    }

    const handleGetClasificacionFalla = async () => {
        const res = await Catalogo.getClasificacionFalla();
        const ClasificacionFalla = (res.data.data || []).map(item => ({
            value: item.clasificacionFallaId,
            label: item.falla,
            gravedad: item.gravedad
        }));
        setDataClasificacionFalla(ClasificacionFalla);
    }

    const handleGetTipoReporte = async () => {
        const res = await Catalogo.getTipoReporte();
        const TipoReporte = (res.data.data || []).map(item => ({
            value: item.tipoReporteId,
            label: item.tipoReporte
        }));
        setDataTipoReporte(TipoReporte);
    }

    const handleAddEditGarantia = async (value) => {
        let statusGarantiaId = 1;

        if (TipoGarantiaId === null) statusGarantiaId = 50;

        if (value.FechaHoraCita !== null && value?.FechaHoraCita.trim().length > 0) statusGarantiaId = 3;


        if (value.CarnetServicioId !== 0) {
            // value.FolioCarnet = "";
        }


        const data = {
            GeneralId: value.GeneralId,
            KilometrajeActual: value.KilometrajeActual,
            carnetServicioId: value.CarnetServicioId,
            ClasificacionFallaId: value.ClasificacionFallaId,
            Gravedad: verGravedad,
            TipoReporteId: value.TipoReporteId,
            VehiculoMover: value.VehiculoMover,
            OrigenGruaId: value.OrigenGruaId,
            FechaHoraCita: value.FechaHoraCita,
            FechaCita: value.FechaCita,
            LlamadaId: idllamada,
            GarantiaId: initialValue.GarantiaId,
            TipoGarantiaId: TipoGarantiaId || _tipoGarantiaId,
            statusGarantiaId
        };


        setInitialValue({ ...initialValue, OrigenGruaId: value.OrigenGruaId })

        setLoading(true);

        //Falta obtener la garantia Folio
        try {
            if (value.GarantiaId === "" || value.GarantiaId === undefined) {
                const res = await Llamada.addGarantia(data);
                let dataRes = res.data.data
                setNombreGarantia(dataRes.statusGarantia);
                const garantiaId = res.data.data.garantiaId;
                dispatch(setGarantiaSlice({
                    GarantiaId: garantiaId,
                    KilometrajeActual: value.KilometrajeActual,
                    CarnetServicioId: value.CarnetServicioId,
                    // FolioCarnet: value.FolioCarnet,
                    ClasificacionFallaId: value.ClasificacionFallaId,
                    Gravedad: value.Gravedad,
                    VehiculoMover: value.VehiculoMover,
                    TipoReporteId: value.TipoReporteId,
                    OrigenGruaId: value.OrigenGruaId,
                    LlamadaId: idllamada,
                    FolioGarantia: dataRes.folioGarantia || "No Folio"

                }))

                setInitialValue({ ...initialValue, CarnetServicioId: res.data.data.CarnetServicioId, GarantiaId: garantiaId, OrigenGruaId: value.OrigenGruaId });
                toast.success(message("Registro agregado correctamente", res.data.data.mensaje));
            } else {
                if (dayGarantia !== "" && !BloqueoId && initialValue.FechaHoraCita) {

                    const dayParts = dayGarantia.split("T")[0].split("-");
                    const dBloqueo = {
                        ubicacionid: sucursalId,
                        fecha: dayParts.join('-'),
                        calendarioid: CalendarioIdGarantia,
                        calendariotipoid: 1,
                        relacionid: GarantiaId
                    };

                    const date = new Date();
                    const fecha = ("0"+date.getDate()).slice(-2)+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+date.getFullYear();

                    const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
                    const contenido = {
                        VARIABLE_EJECUTIVO: Ejecutivo,
                        VARIABLE_UBICACIÓN: sucursalName,
                        VARIABLE_MARCA: nombreMarca,
                        VARIABLE_MODELO: nombreModelo,
                        VARIABLE_AÑO: nombreYear,
                        VARIABLE_VERSION: nombreVersion,
                        VARIABLE_VIN: vin,
                        VARIABLE_CONTRATO: numContrato,
                        VARIABLE_FECHA: fecha,
                        VARIABLE_HORA: fragmentsDate[1],
                    }

                    const res = await Llamada.crearCita(dBloqueo)
                        .catch(err => {
                            setBloqueoId(true)
                        });
                    const _bloqueoId = res.data.data.bloqueoId;

                    if (_bloqueoId) {
                        Garantias.creardatosenviar({
                            modulo: 'Solicitud de Revisión de Unidad',
                            tipoCorreoId: 26,
                            contenido: JSON.stringify(contenido)
                        }).catch(err => {
                            console.log("Error al enviar correo Solicitud de Revisión de Unidad: ", err);
                        })
                    }

                    const garantiaData = {
                        ...data,
                        BloqueoId: _bloqueoId
                    };

                    const updateRes = await Llamada.updateGarantia(garantiaData);
                    setInitialValue({
                        ...initialValue,
                        BloqueoId: _bloqueoId
                    })


                    let updateRS = updateRes.data.data
                    setNombreGarantia(updateRS.statusGarantia);

                    // setBloqueoId(true);
                    toast.success(message("Registro actualizado correctamente", res.data.data.mensaje));
                } else {
                    const res = await Llamada.updateGarantia(data);
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                }
            }
        } catch (e) {
            if (e.response !== undefined) {
                // toast.error(message("¡Error.!", e.response.data.message));
            } else {
                // toast.error(message("¡Error.!", e.message));
            }
        } finally {
            setLoading(false);
        }

    }

    const handleChangeMoverAuto = (value, valueState) => {
        setInitialValue({ ...valueState, VehiculoMover: Number(value) });
    }

    const verGrua = (value) => {
        const valorMover = OrigenGrua.filter(element => {
            return element.value === value
        })

        if (valorMover.length > 0) {
            setVerCardGruas(Number(valorMover[0].Mover) === 0 ? false : true);
        }
    }

    const verModalAutorizarCita = (value) => {
        let clasificacion = dataClasificacionFalla.find(x => x.value == value.ClasificacionFallaId).label
        let reporte = dataTipoReporte.find(x => x.value == value.TipoReporteId).label
        let data = {
            "llamadaId": idllamada,
            "garantiaId": GarantiaId,
            "generalId": GeneralId,
            "folioCita": "",
            "folioLlamada": FolioLlamada,
            "folioGarantia": FolioGarantia,
            "numContrato": numContrato,
            "vin": vin,
            "claveMarca": "",
            "nombreMarca": nombreMarca,
            "claveModelo": nombreModelo,
            "nombreModelo": nombreModelo,
            "claveYear": nombreYear,
            "nombreYear": nombreYear,
            "ejecutivoGarantia": Ejecutivo,
            "ubicacionId": sucursalId,
            "ubicacion": sucursalName,
            "tipoGarantiaId": null,
            "tipoGarantia": garantiaCalculada || "Sin Garantia",
            "clasificacionFallaId": null,
            "falla": clasificacion || "Sin Falla",
            "gravedad": value.Gravedad,
            "tipoReporteId": value.TipoReporteId,
            "tipoReporte": reporte || "Sin reporte",
            "telefono": "",
            "fechaCita": value.FechaHoraCita,
            "estatusCita": value.EstatusCita,
            "_bloqueoId": ""
        }

        setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 1, Origin: "Listado" })
    }

    const verCalendario = async (valor, values) => {
        values.TipoGarantiaId = _tipoGarantiaId;
        values.EstatusGarantia = nombreGarantia;
        dispatch(setGarantiaSlice(values))
        dispatch(setSchedule({
            url: `garantias/incidenciasinfo/${vin}/${idllamada}`,
            contrato: numContrato,
            folioLlamada: FolioLlamada,
            vin: vin,
            modelo: nombreModelo,
            ano: nombreYear,
            marca: nombreMarca,
        }));
        navigate(`/garantias/citas/${valor}`)
    }

    const verTipoGarantia = async () => {
        try {
            const res = await Catalogo.getAllTipoGarantia();
            const dataRes = res.data.data;
            // console.table(dataRes);
            let tiposData = dataRes.map(element => {
                return element
            })
            setTiposGarantia(tiposData);
        } catch (error) {
            console.error(error);
        }
    }

    //Calcular garantia
    const calcularGarantia = async (value) => {

        let today = new Date();
        today.setHours(0, 0, 0, 0);
        const diaEntrega = new Date(fechaEntrega);
        const diffTime = Math.abs(diaEntrega - today);
        const diasTranscurridos = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


        let elementosFiltrados = tiposGarantia.filter(element => element.usoUnidad === usoUnidad.trim());
        let kilometrajeRecorrido = Number(value) - Number(kilometraje);

        if (kilometrajeRecorrido <= 0) {
            return false;
        }

        let GarantiaCercana = elementosFiltrados.filter(item => kilometrajeRecorrido <= (Number(item.km) + Number(item.kmTolerancia)));


        if (GarantiaCercana.length > 1) {
            let GarantiaEncontrada = false;

            GarantiaCercana.forEach(item => {
                if (diasTranscurridos <= Number(item.dias) && !GarantiaEncontrada) {
                    GarantiaEncontrada = true;
                    setGarntiaCalculada(item.tipoGarantia)
                    setTipoGarantiaId(item.tipoGarantiaId)
                }
            });
        } else if (GarantiaCercana.length === 1) {
            if (diasTranscurridos <= GarantiaCercana[0].dias) {
                setGarntiaCalculada(GarantiaCercana[0].tipoGarantia)
                setTipoGarantiaId(GarantiaCercana[0].tipoGarantiaId)
            }
        } else {
            setGarntiaCalculada("Sin Garantia")
            setTipoGarantiaId(null)
        }

    }
    //Calcular la garantia

    const changeGravedad = (value) => {
        //setVerGravedad()
        const clasificacion = dataClasificacionFalla.filter(element => {
            return element.value === value
        })
        if (clasificacion.length > 0) {
            setVerGravedad(clasificacion[0].gravedad);
        } else {
            setVerGravedad("");
        }
    }

    const showCarnetField = (id, values) => {
        if (id !== 0) {
            // values.FolioCarnet = "";
            // setInitialValue({
            //     ...initialValue,
            //     FolioCarnet: ''
            // });
        }
    }

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    }

    const circleStyle = {
        width: '10px',
        height: '10px',
        background: 'yellow',
        borderRadius: '50%',
        marginRight: '10px', // Espacio entre el círculo y el texto
    }

    const circleStyleGreen = {
        width: '10px',
        height: '10px',
        background: 'green',
        borderRadius: '50%',
        marginRight: '10px', // Espacio entre el círculo y el texto
    };

    return (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <DetailAuthCitaModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 4 &&
                    <RechazaCitaModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Modal isOpen={loading} color='transparent'>
                <Spinner message={""} />
            </Modal>

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
                <Formik
                    innerRef={refFormik}
                    enableReinitialize={true}
                    initialValues={initialValue}
                    validationSchema={validate}
                >

                    {({ isValid, values, setFieldValue, errors }) => (
                        <>
                            <div className='row mx-md-4 my-4' >
                                <div className="col-12 px-md-3">
                                    <div className="p-4 white-wrapper mb-2">
                                        <div className="row mt-2 px-4 mb-2" data-bs-toggle="collapse" href="#collapseGarantia" role="button" aria-expanded="false" aria-controls="collapseGarantia" onClick={() => setCollpsableGarantia(!collpsableGarantia)}>
                                            <div className="col-4">
                                                <h6>
                                                    <span className={FolioGarantia ? 'semaforo semaforo-green' : 'semaforo semaforo-default'}></span>
                                                    <strong>Información de garantia {FolioGarantia ? '- ' + FolioGarantia : ""} </strong>
                                                </h6>
                                            </div>
                                            <div className="col-7 text-left">
                                                <span className='mx-2 p-2 badge bg-primary-dm'>Tipo de garantía: {garantiaCalculada}</span>
                                                {/* <small className='mx-1'><span className='circle-focus primary-focus'></span> Garantía inicial</small> */}
                                            </div>
                                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                <i className={!collpsableGarantia ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                            </div>
                                            <div className="col-12">
                                                <small>{nombreGarantia} </small>

                                            </div>
                                        </div>
                                        {/* className={FolioLlamada ? 'collapse show' : 'collapse'}  */}
                                        <div className="collapse" id="collapseGarantia">
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-7">
                                                    <small>
                                                        <strong>Kilometraje y documentación</strong>
                                                    </small>
                                                </div>
                                                <div className="col-3 text-end">
                                                    <button
                                                        disabled={!FolioGarantia}
                                                        style={{ background: etapaFinalizado ? '#277917' : '' }}
                                                        className={etapaFinalizado ? 'btn' : 'btn btn-gray'}
                                                        onClick={() => {
                                                            navigate(`/garantias/datadocs/kGarantia/eInLlamada/${FolioGarantia}/${idllamada}`, {
                                                                state: values,
                                                            });
                                                        }}>
                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                    </button>
                                                </div>
                                                <div className="col-2">
                                                    <button className="btn col-12 saveInfo"
                                                        onClick={(e) => handleAddEditGarantia(values)}
                                                        disabled={!(isValid) || !values.KilometrajeActual || values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId}
                                                    >Guardar</button>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-2">
                                                    <TextField
                                                        disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId}
                                                        id="KilometrajeActual"
                                                        label="Kilometraje Actual"
                                                        name="KilometrajeActual"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("KilometrajeActual", event.target.value);
                                                            calcularGarantia(event.target.value)
                                                        }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <span>Evidencia kilometraje</span>
                                                    <div style={containerStyle}>
                                                        <div style={docGarantia[0].existe ? circleStyleGreen : circleStyle}></div>
                                                        {docGarantia[0].nombre}
                                                    </div>

                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-2">
                                                    <SelectField
                                                        id="CarnetServicioId"
                                                        label="Carnet"
                                                        name="CarnetServicioId"
                                                        items={dataCarnet} onChange={(event) => {
                                                            setFieldValue("CarnetServicioId", event.value);
                                                            showCarnetField(event.value, values);
                                                        }}
                                                        disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId}
                                                    />
                                                </div>
                                                {
                                                    values.CarnetServicioId === 0 &&
                                                    <div className="col-sm-12 col-md-6 col-lg-2">
                                                        <span>Folio de Carnet</span>
                                                        <div className='pt-1' style={containerStyle}>
                                                            {FolioCarnet}
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <span>Evidencia carnet</span>
                                                    <div style={containerStyle}>
                                                        <div style={docGarantia[1].existe ? circleStyleGreen : circleStyle}></div>
                                                        {docGarantia[1].nombre}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* REGISTRAR LLAMADA */}
                                            <div className="row mt-2 px-4">
                                                <div className="col-12">
                                                    <small><strong>Registrar Falla</strong></small>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-3">

                                                    <SelectField
                                                        id="ClasificacionFallaId"
                                                        label="Clasificación de la falla"
                                                        name="ClasificacionFallaId"
                                                        items={dataClasificacionFalla} onChange={(event) => {
                                                            setFieldValue("ClasificacionFallaId", event.value);
                                                            changeGravedad(event.value)
                                                        }}
                                                        disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-2">
                                                    <span>Gravedad</span>
                                                    <div className='pt-1' style={containerStyle}>
                                                        {verGravedad}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField
                                                        id="TipoReporteId"
                                                        label="Tipo reporte"
                                                        name="TipoReporteId"
                                                        items={dataTipoReporte} onChange={(event) => {
                                                            setFieldValue("TipoReporteId", event.value);
                                                        }}
                                                        disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-4">
                                                    <span>Taller Autorizado</span>
                                                    <div className='pt-1' style={containerStyle}>
                                                        {TallerAutorizado}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-12">
                                                    <small><strong>Agendar cita y traslado de unidad</strong></small>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 px-4">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField
                                                        id="VehiculoMover"
                                                        label="¿Vehículo se puede mover?"
                                                        name="VehiculoMover"
                                                        items={dataVehiculoPuedeMoverse}
                                                        disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId}
                                                        onChange={(event) => {
                                                            setFieldValue("VehiculoMover", event.value);
                                                            handleChangeMoverAuto(event.value, values);
                                                        }} />
                                                </div>
                                                {
                                                    values.VehiculoMover === 0 &&

                                                    < div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField
                                                            id="OrigenGruaId"
                                                            label="Origen grua"
                                                            name="OrigenGruaId"
                                                            items={OrigenGrua}
                                                            onChange={(event) => {
                                                                setFieldValue("OrigenGruaId", event.value);
                                                                verGrua(event.value);
                                                            }}
                                                            disabled={values.EstatusCita == 'Autorizada' || BloqueoId || values.BloqueoId} />
                                                    </div>
                                                }
                                                {/* <div className="col-sm-12 col-md-6 col-lg-4">
                                                    <TextField id="FechaHoraCita" label="Fecha y hora de cita" name="FechaHoraCita" type="text" holder="" disabled onChange={(event) => {
                                                        setFieldValue("FechaHoraCita", event.target.value);
                                                    }} />
                                                </div> */}
                                                <div className="col-sm-12 col-md-6 col-lg-4">
                                                    <span>Taller Autorizado</span>
                                                    <div className='pt-1' style={containerStyle}>
                                                        {values.FechaHoraCita}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                    <button className="btn col-12 saveInfo" onClick={(e) => verCalendario(1, values)} disabled={!GarantiaId || BloqueoId || values.BloqueoId || !etapaFinalizado}>Ver calendario</button>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-2">

                                                    <span>Estatus Cita</span>
                                                    <div className='pt-1' style={containerStyle}>
                                                        {
                                                            values.FechaHoraCita && !values.BloqueoId &&
                                                            <span>Sin Agendar</span>
                                                        }
                                                        {
                                                            values.FechaHoraCita && values.BloqueoId && values.EstatusCita === 'Sin Agendar' || values.FechaHoraCita && values.BloqueoId && values.EstatusCita === '' &&
                                                            <span>Sin Autorizar</span>
                                                        }
                                                        {
                                                            values.FechaHoraCita && values.BloqueoId && values.EstatusCita === 'Autorizada' &&
                                                            <span>Autorizada</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                    <button className="btn col-12 saveInfo" disabled={!values.BloqueoId || values.EstatusCita === 'Autorizada'} onClick={(e) => verModalAutorizarCita(values)}>Autorizar Cita</button>
                                                </div>
                                            </div>
                                            <TablaMantenimiento generalIddata={generalId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    }
                </Formik>
            </div >
            {
                verCardGruas === true && Number(StatusGarantiaId) === 4 &&
                <CardGrua vehiculomover={initialValue.VehiculoMover} />
            }
            {
                (inInspeccionRapida && inspeccionRapida) || (initialValue.EstatusCita === 'Autorizada') &&
                Number(StatusGarantiaId) === 4 &&
                <CardInspeccionRapida generalId={generalId} />
            }
        </>
    )
}

export default CardGarantia