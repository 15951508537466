import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const ModalSolFirma = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let initial = {
        generalId:item?.generalId??"",
        filename: item.filename,
        numeroSerie: "",
        contrato: "",
        contratoValid:item?.contrato,
        tecnicoId: "",
        folioServicio: "",
        sol_id:item?.numSolicitud ?? "",        
        vin:item?.vin ?? "",
        status_datamovil:item?.statusDatamovil ?? "",
        legibilidad: false
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTecnico, setDataTecnico] = useState([]);
    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
        numeroSerie: Yup.string()
        .required("El campo es requerido.")
        .trim()
        .matches(/^[0-9]{8,8}$/, "Debe contener solo números con 8 caracteres.")
        .strict(true),
        contrato: Yup.string()
            .required("El campo es requerido.")
            .oneOf([Yup.ref('contratoValid'), null], "El numero de contrato no coincide."),
        tecnicoId: Yup.string()
            .required("El campo es requerido."),
        folioServicio: Yup.string()
            .required("El campo es requerido."),

    });


    useEffect(() => {
        handleGetAllTecnico()
    }, [])

    const handleGetAllTecnico = async () => {
        await Catalogo.getAllTecnico()
            .then(res => {
                let NewData = [];
                
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tecnicoId,
                        label: item.nombre_Tecnico
                    })
                });
                setDataTecnico(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (item) => {
        submit(item);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons'>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            {/* <div className="mb-3 mt-4">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Confirmar legibilidad</label>
                                <div className="col-ms-12 form-check form-switch">
                                    <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                </div>
                            </div> */}
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2">
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                <label style={{ fontSize: '14px' }}><strong>2. Instalación dispositivo</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    A continuación debes capturar los datos del dispositivo.
                                </span>
                                <div className="mb-3 row mt-2">
                                    <div className="col-sm-6">
                                        <TextField id="nSerieId" label="Número de serie" name="numeroSerie" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField id="contratoID" label="Número de contrato" name="contrato" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <SelectField id="tecnicoID" label="Técnico" name="tecnicoId" items={dataTecnico} onChange={(event) => {
                                            setFieldValue("tecnicoId", event.value);
                                        }} />
                                    </div>

                                    <div className="col-sm-6">
                                        <TextField id="folioServicioID" label="Folio de servicio" name="folioServicio" type="text" holder="Escribe" />
                                    </div>
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={!values.legibilidad}>Confirmar</button>
                            </section>
                        </Form>
                        {/* <div className='d-flex justify-content-around mt-5'>
                            <button className="btn btn-outline col-5" type="button" onClick={() => { setIsOpen(false) }}>Regresar</button>
                            <button className='btn mx-2' type="submit" disabled={!(isValid)}>Confirmar</button>
                        </div> */}
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalSolFirma