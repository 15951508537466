import React, { useEffect, useState } from "react";
import useInfoVehiculo from "../Hooks/Inventario/useInfoVehiculo";
import Modal from "./Modal/Modal";
import ClavesModal from "./Modal/ClavesModal";
import SolicitarClaveModal from "./Modal/SolicitarClaveModal";

const VehiculoInfoCard = ({ GeneralId = '', VIN, verClaves = false, solicitarClave = false, ocultarContrato = false }) => {
    const { getInfo, data, handleClaves } = useInfoVehiculo();

    useEffect(() => {
        if (GeneralId || VIN)
            getInfo(GeneralId, VIN);

    }, [GeneralId, VIN]);

    const [modalMessage, setModalMessage] = useState({ isOpen: false });
    const [modalClave, setModalClave] = useState(false);

    const getOnlyDate = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={780}>
                {
                    modalMessage.type === 1 &&
                    <ClavesModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        vin={data?.vin}
                    />
                }
            </Modal>
            <Modal isOpen={modalClave} color={"#fff"} width={650}>
                {
                    modalClave &&
                    <SolicitarClaveModal
                        data={data}
                        isOpen={modalClave}
                        setIsOpen={setModalClave}
                    />
                }
            </Modal>
            <div className="p-4 wrapper-vehiculo">
                <div className="col col-12">
                    <div className="row align-items-center">
                        <div className="col col-auto">
                            <h1 className="fw-bold text-sm">Vehículo</h1>
                        </div>
                        {
                            verClaves &&
                            <div className="col-sm-4 col-xl-3">
                                <button className="btn position-relative" onClick={(e) => { setModalMessage({ isOpen: true, type: 1, title: 'Consulta de clave' }) }}>
                                    Ver Claves
                                    {
                                        data?.claves > 0 &&
                                        <span className="badge bg-danger badge-circle position-absolute top-0 start-100 translate-middle">
                                            {data?.claves < 9 ? '+' + data.claves : '+9'}
                                        </span>
                                    }
                                </button>
                            </div>
                        }
                        <div className="col-sm-auto col-xl-auto">
                            <button className="btn position-relative" onClick={(e) => { setModalClave(true) }}>
                                Solicitar clave
                            </button>
                        </div>
                        {/* {
                            !ocultarContrato &&
                            <div className="col col-6">
                                <span className="fw-bold">{data?.numContrato}</span>
                            </div>
                        } */}
                    </div>
                    <div className="divider"></div>
                    <div className="row gap-2">
                        <div className="col col-12">
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">VIN:</strong> {data?.vin}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Placas:</strong> {data?.numPlaca}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Vehículo a nombre de:</strong> <span className="badge bg-primary-datamovil text-xs">{data?.nombre}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Color:</strong> {data?.color}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Marca:</strong> {data?.nombreMarca}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Kilometraje:</strong> {data?.kilometraje}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Modelo:</strong> {data?.nombreModelo}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Procedencia Auto:</strong> {data?.procedenciaAuto}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Versión:</strong> {data?.nombreVersion}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">No. Motor:</strong> {data?.numMotor}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Año:</strong> {data?.nombreYear}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fecha de entrega:</strong> {getOnlyDate(data?.fechaEntrega)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VehiculoInfoCard;