import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false
}

export const MenuSlice = createSlice({
  name: 'MenuSlice',
  initialState,
  reducers: {
    handleMenu: (state, action) => {
      return { ...state, visible: action.payload }
    }
  },
})

export const { handleMenu } = MenuSlice.actions

export default MenuSlice.reducer