import React, { useEffect, useState } from 'react'
import dataDocsServices from '../../Services/DataDocs/dataDocsServices';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { message } from '../../Utils/functions';
import axios from 'axios';
import AuthLogin from '../../Utils/AuthLogin';


const auth = new AuthLogin();
const useDataDocsDeleted = (toast) => {
  let url = auth.UrlDataDocs();  
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'nombreTipoDocumento', headerName: 'Tipo de documento', width: 200 },
    { field: 'nombreDocumento', headerName: 'Nombre de documento', width: 250 },
    { field: 'nombreUsuario', headerName: 'Usuario', width: 200 },
    { field: 'fechaHora', headerName: 'Ultima modificación', width: 220 },
    // { field: 'fecha', headerName: 'Fecha', width: 250,short:true},
    // { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 400 },
  ];
  let DataFiltroFolder = [
    { label: "Todos", value: "" },
    { label: "Vehículos con folder", value: 1 },
    { label: "Vehículos sin folder", value: 0 },
  ];
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [TipoDocumentoId, setTipoDocumentoId] = useState("");
  const [fechaFiltro, setFechaFiltro] = useState("");
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [DataDocumentos, setDataDocumentos] = useState([])
  const [SelectedDocs, setSelectedDocs] = useState([]);
  const [fileName, setFileName] = useState("");
  const [pageRows, setpageRows] = useState(10)
  //
  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [TipoDocumentoId, str, fechaFiltro,pageRows])
  useEffect(() => {
    handleGetAllDocumentos();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await dataDocsServices.getListDocsDeleted(page, pageRows, str, TipoDocumentoId, fechaFiltro)
      .then(res => {        
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetAllDocumentos = async () => {
    await EtapaService.getAllDocumentos()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoDocumentoId,
            label: item.nombre
          })
        });
        setDataDocumentos(NewData);
      }).catch(e => {
      })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleDelete = async (item) => {
    setLoading(true);
    await dataDocsServices.PermanentDeleteDocs(item.documentosDataDocsId,item.generalId)
      .then(res => {        
        toast.success(message("¡Correcto.!", res.data.data.mensaje));
        setLoading(false);
        habdleGetAll(1)
      }).catch(e => {        
        setLoading(false);
        if (e.response !== undefined)
          toast.error(message("¡Correcto.!", e.response.data.message));
        else
          toast.error(message("¡Correcto.!", e.message));
      })
  }
  const handleCreateFolder = (item) => {    
    setDataVehiculo(item);
    setIsOpenDocs(true)
  }
const handleRecover = async (item) =>{
  setLoading(true);
    await dataDocsServices.FileRecovery(item.documentosDataDocsId,item.generalId)
      .then(res => {        
        toast.success(message("¡Correcto.!", res.data.data.mensaje));
        setLoading(false);
        habdleGetAll(1);
      }).catch(e => {        
        setLoading(false);
        if (e.response !== undefined)
          toast.error(message("¡Correcto.!", e.response.data.message));
        else
          toast.error(message("¡Correcto.!", e.message));
      })
}
const handleGetTokenDatadocs = async () => {
  await dataDocsServices.UpdateTokenDataDc()
    .then(res => {
      auth.handleAuthSesionDataDocs(res.data.data);      
    }).catch(e => { })
}
const handleGetFileById = async (item) => {
  setLoading(true);
  setFileName(item.nombreDocumento);
  await handleGetTokenDatadocs();
  await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
    headers: {
      'Authorization': 'Bearer ' + auth.tokenDataDocs(),
    },
    responseType: "blob"
  })
    .then(res => {
      var file = new File([res.data], res.data.name, { type: res.data.type });      
      setSelectedDocs([file])      
      setLoading(false);  
      setIsOpenDocs(true)
    }).catch(e => {
      setLoading(false);
      if (e.response !== undefined)
        toast.error(message("Error.!", e.response.data.errors?.error));
      else
        toast.error(message("Error.!", e.message));
    })
}
  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    DataDocumentos,
    habdleGetAll,
    setFechaFiltro,
    setTipoDocumentoId,
    handleDelete,
    handleRecover,
    SelectedDocs,
    handleGetFileById,
    fileName,
    setpageRows
  }
}

export default useDataDocsDeleted