import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import handlePhonNumberFormatter, { message } from '../../../../Utils/functions';
import { Field, Form, Formik } from 'formik';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import useInicioTramite from '../../../../Hooks/Gestoria/seguimiento/useInicioTramite';


const AgendarProcesoTramite = ({ handleConcluido }) => {
    const { refFormik } = useRef();

    const [collapseDivAgendar, setCollapseDivAgendar] = useState(false);

    let {
        initialValue,
        loading,
        tipoTramites,
        submitForm
    } = useInicioTramite({ toast, handleConcluido });

    const validate = Yup.object().shape({
        // telefonoMovil: Yup.string()
        //     .when("concluido", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //             .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
        //     })
    })

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAgendarCita" role="button" aria-expanded="false" aria-controls="collapseAgendarCita" onClick={() => setCollapseDivAgendar(!collapseDivAgendar)}>
                                <div className="col-11">
                                    <h6>
                                        <span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span>
                                        <strong>Agendar proceso Tramite</strong>
                                    </h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collapseDivAgendar ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAgendarCita">
                                <div className="separator"></div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => submitForm(values)}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="row mt-2 mb-2  px-4">
                                                <div className='col-4'>
                                                    <h6>
                                                        <strong>Detalle de tramite</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                                        <i className="icon-dark bx bx-hide mx-1" ></i>
                                                                        Modo lectura
                                                                    </fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Tipo de tramite"
                                                        name="tipoTramiteId"
                                                        items={tipoTramites}
                                                        disabled={true}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoTramiteId", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <small><strong>Datos de contacto</strong></small>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField
                                                        label="Persona que solicita tramite"
                                                        name="personaSolicita"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("personaSolicita", event.target.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField
                                                        label="Telefono Fijo"
                                                        name="telefonoFijo"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("telefonoFijo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField
                                                        label="Telefono Movil"
                                                        name="telefonoMovil"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField
                                                        label="Telefono Trabajo"
                                                        name="telefonoTrabajo"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("telefonoTrabajo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field
                                                        className="form-check-input"
                                                        disabled={initialValue.concluido}
                                                        type="checkbox"
                                                        name="concluido"
                                                        onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                    <label
                                                        className="form-check-label"
                                                        style={{ 'marginLeft': '10px' }}>
                                                        Iniciar tramite
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-auto">
                                                    {
                                                        initialValue.fechaInicio !== "" &&
                                                        <small className='text-body-tertiary'>
                                                            Inicio de proceso: {initialValue?.fechaInicio}
                                                        </small>
                                                    }
                                                </div>
                                                <div className="col-auto">
                                                    {
                                                        initialValue.concluido &&
                                                        <small className='text-body-tertiary'>
                                                            Fin de proceso: {initialValue?.fechaFin}
                                                        </small>
                                                    }
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AgendarProcesoTramite