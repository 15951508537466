import React, { useEffect, useState } from 'react'
import Acordeon from '../../Components/Acordeon'
import AcordeonSA from '../../Components/AcordeonEtapaSA'
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';

const ModalCambioEtapa = ({dataGroups,dataESA,dataGroupSelect,handleAddEtapaGrup,setIsOpenModalGroup,dataGrupo,setGrupoValue,setetapaValue,setIsOpenModalCambioEtapa}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setData(dataGroups);
    //   setLoading(true);      
      return ()=>{
        setLoading(false);
      }
    }, [dataGroups,dataESA,dataGroupSelect])
    
    return (
        <>
        {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
            <section className='etapa_container mt-4 d-flex'>
                <div className='mx-4 col-5' style={{ height: '480px', overflowX: 'auto' }}>
                    {
                        data?.map((item, index) => {
                            return <div key={index} >
                                <Acordeon
                                    data={item}
                                    isDeleted={true}
                                    handleModal={(data) => { setIsOpenModalGroup(true);dataGrupo(data) }}
                                    handleModalconfirm={(e) => { setGrupoValue(e); setetapaValue(""); setIsOpenModalCambioEtapa(true) }}
                                    handleModalDelete={(data, item) => { setetapaValue(item.etapaId); setGrupoValue(data.grupoId); setIsOpenModalCambioEtapa(true) }}
                                />                                
                            </div>
                        })
                    }
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center py-3'>
                    <div className='top'></div>
                    <span><i style={{ color: '#e8eaf6' }} className="ri-arrow-left-right-line"></i> </span>
                    <div className='top'></div>
                </div>
                <div className='mx-4 col-6'>
                    <AcordeonSA
                        data={dataESA}
                        heigth={400}
                        isSelected={true}
                        dataSelect={dataGroupSelect}
                        handleEtapa={handleAddEtapaGrup}
                    />
                </div>
            </section>
        </>
    )
}

export default ModalCambioEtapa