import Tab from "../../../Components/Tabs/Tab";
import Tabs from "../../../Components/Tabs/Tabs";
import SeguimientoAsignaciones from "./SeguimientoAsignaciones";
import SeguimientoSinAsignar from "./SeguimientoSinAsignar";

const AsignacionesDatamovilSystems = () => {

    return (
        <>
            <Tabs>
                <Tab title={'Servicios Datamovil'}>
                    <SeguimientoSinAsignar />
                </Tab>
                <Tab title={'Citas de Servicios Datamovil'}>
                    <SeguimientoAsignaciones />
                </Tab>
            </Tabs>
        </>
    );

}

export default AsignacionesDatamovilSystems;