import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';

const ModalNumPoliza = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    console.log(item?.numeroPoliza)
    let initial = {
        filename: `POL-${item?.numeroPoliza}`,
        poliza: "",//item?.numeroPoliza ?? "",
        sol_id:item?.solicitud ?? "",        
        legibilidad: false
    }
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        // filename: Yup.string()
        //     .required("El campo es requerido."),
        polizaF: Yup.string().default(`${item?.numeroPoliza}`),
        poliza: Yup.string()
            .required("El campo es requerido.")
            .oneOf([Yup.ref('polizaF'), null], "El numero de póliza no coincide.")
        // .required("El campo es requerido.")
        // .trim("El texto no debe empezar ni terminar con espacios")
        // .strict(true)
        // .matches(
        //     "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
        //     "La información ingresada es incorrecta."
        // )
        // .matches(
        //     "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
        //     "solo debe existir un espacio entre cada palabra."
        // )            
    });

    const dataModal = [
        { label: "Separado", value: "Separado" },
        { label: "Liberado", value: "Liberado" },
    ];

    const handleAddEdit = async (item) => {
        submit(item);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons'>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                {/* <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div> */}
                                <div className="col-ms-12 mb-2">
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                <label style={{ fontSize: '14px' }}><strong>2. Confirmar la póliza del seguro</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    A continuación debes capturar la poliza.
                                </span>
                                <div className="col-sm-12 mt-2">
                                    <TextField label="Póliza" name="poliza" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={!values.legibilidad}>Confirmar</button>
                            </section>
                        </Form>
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalNumPoliza