import { Toaster } from "react-hot-toast";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import RowPages from "../../../Components/RowPages";
import DataTable from "../../../Components/datatable/DataTable";
import useSeguimientoLecturaClaves from "../../../Hooks/Cobranza/useSeguimientoLectura";
import AprobarClaveModal from "./AprobarClaveModal";

const SeguimientoLecturaClaves = () => {
    const {
        loading,
        setLoading,
        data,
        getSeguimiento,
        buscar,
        setBuscar,
        page,
        setPage,
        rows,
        setRows,
        columns,
        modalMessage,
        setModalMessage
    } = useSeguimientoLecturaClaves();

    const handleDetail = (item) => {
        console.log(item)
        setModalMessage({
            isOpen: true,
            id: item.solicitudClaveAcendesId,
            contrato: item.contrato
        });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                <AprobarClaveModal 
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    getSeguimiento={getSeguimiento}
                />
            </Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Lectura de claves</strong></span>
                </div>

            </section>
            {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro)?.Ver}> */}
            <section className='pb-4'>
                <div className='row col-12 px-4'>
                    <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" value={buscar} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setBuscar(e.target.value) }} />
                    </div>
                </div>
            </section>
            {/* <RowPages
                setpageRows={(value) => { setDataFiltro({ ...dataFiltro, rows: value }) }}
            /> */}
            <div className='mx-4 table-responsive tbl-scroll'>
                <DataTable
                    column={columns}
                    // iswidth={true}
                    data={data}
                    // pageCount={pageCount}
                    // handlePageClick={handlePageClick}
                    // currentPage={currenPage - 1}
                    // detailable={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro)?.Ver}
                    detailable={true}
                    handleDetail={handleDetail}
                />
            </div>
            {/* </ValidatePermission> */}
        </>
    );
}

export default SeguimientoLecturaClaves;