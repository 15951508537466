import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentPage: 0,
    editable: true,
    data: {},
    hasNextPage: false,
    hasPreviousPage: false,
    pageSize: 10,
    totalPages: 0,
    totalRows: 0
}

export const InformacionLlamadaSlice = createSlice({
    name: 'InformacionLlamadaSlice',
    initialState,
    reducers: {
        setInfoLlamada: (state, action) => {
            return {...state,
                currentPage: action.payload.currentPage,
                data: action.payload.data,
                hasNextPage: action.payload.hasNextPage,
                hasPreviousPage: action.payload.hasPreviousPage,
                pageSize: action.payload.pageSize,
                totalPages: action.payload.totalPages,
                totalRows: action.payload.totalRows
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
    }
})

export const { setInfoLlamada, setEditable } = InformacionLlamadaSlice.actions

export default InformacionLlamadaSlice.reducer