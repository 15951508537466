import React, { useEffect, useState } from 'react';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../../Utils/functions';
import DataTable from '../../../../../Components/datatable/DataTable';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthLogin from '../../../../../Utils/AuthLogin';
import dataDocsServices from '../../../../../Services/DataDocs/dataDocsServices';
import ModalCostos from './ModalCostos';
import ValuacionRegistroCostoService from '../../../../../Services/Inventario/Valuacion/ValuacionRegistroCostoService';
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import VisorFile from '../../../../../Components/VisorFile';
import SeguroSiniestrosService from '../../../../../Services/Seguros/SeguroSiniestrosService';

const auth = new AuthLogin();

const ListadoCostos = ({ siniestroResguardoUnidadId,item }) => {
    let url = auth.UrlDataDocs();
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [dataValue, setDataValue] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [currenPageH, setCurrenPageH] = useState(1);
    const [pageRows, setPageRows] = useState(10);

    const columns = [
        { field: 'id', headerName: '#' },
        { field: 'fechaHora', headerName: 'Fecha y hora' },
        { field: 'ejecutivo', headerName: 'Ejecutivo' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subTotal', headerName: 'Subtotal' },
        { field: 'iva', headerName: 'IVA (%)' },
        { field: 'total', headerName: 'Total' },
        { field: 'acciones', headerName: 'Acciones' },
    ];

    useEffect(() => {
        if (siniestroResguardoUnidadId) {
            getAll(1);
        }
    }, [siniestroResguardoUnidadId]);

    const getAll = async (page) => {
        setLoading(true);
        try {
            const res = await SeguroSiniestrosService.getResguardoCostosSiniestros(siniestroResguardoUnidadId, state?.paso, pageRows, 1);
            console.log(res.data)
            setDataValue(res.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        setSelectedItem({ ...item });
        setIsOpenModal(true);
    };

    const handleDelete = async (item) => {
        setLoading(true);
        try {
            const resp = await SeguroSiniestrosService.deleteResguardoCostoSiniestro(item.siniestroResguardoUnidad_CostoAdicionalId);
            if (resp.data.ban === 1) {
                toast.success(message("¡Correcto!", resp.data.mensaje));
                getAll(currenPageH);
            } else {
                toast.error(message("¡Operación fallida!", resp.data.mensaje));
            }
        } catch (error) {
            toast.error(message("¡Operación fallida!", error.message));
        } finally {
            setLoading(false);
        }
    };

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        try {
            await handleGetTokenDatadocs();
            const res = await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
                headers: { 'Authorization': 'Bearer ' + auth.tokenDataDocs() },
                responseType: "blob"
            });
            const file = new File([res.data], res.data.name, { type: res.data.type });
            setSelectedDocs([file]);
            setIsOpenDocs(true);
        } catch (e) {
            toast.error(message("Error.!", e.response?.data.errors?.error || e.message));
        } finally {
            setLoading(false);
        }
    };

    const handleGetTokenDatadocs = async () => {
        try {
            const res = await dataDocsServices.UpdateTokenDataDc();
            auth.handleAuthSesionDataDocs(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value);
        } else {
            navigate(`/inventario/valuacion/datadocs/fValuacion/eRepar/${state?.contrato}/${id}`, {
                state: { ...value, registroId: value.valuacionRegistroGastos_AdicionalesId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    };

    const handlePageClick = (page) => {
        setCurrenPageH(page.selected + 1);
        getAll(page.selected + 1);
    };

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            {isOpenDocs && (
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={selectedDocs}
                />
            )}
            <div className="row mt-2 px-4 mb-2">
                <div className="col-auto">
                    <small><strong>Costos adicionales</strong></small>
                </div>
                <div className="col-auto">
                    <fieldset disabled={item?.concluido} className='btn' onClick={() => {{ setIsOpenModal(true);setSelectedItem({})} }}>
                        Registrar costo
                    </fieldset>
                </div>
            </div>
            <div className="row mt-2 px-4 mb-2">
                <div className="col-12">
                    <DataTable
                        id="tablaPagos"
                        name="pagos"
                        column={columns}
                        data={dataValue}
                        editable={!item?.concluido}
                        handleEdit={handleEdit}
                        deleteable={!item?.concluido}
                        handleDelete={handleDelete}
                        handleDocuments={handleViewdocs}
                        handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                        paginate={true}
                        pageCount={currenPageH}
                        handlePageClick={handlePageClick}
                        currentPage={currenPageH - 1}
                    />
                </div>
            </div>
            {isOpenModal && (
                <ModalCostos
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={selectedItem}
                    handleGetAll={getAll}
                    toast={toast}
                    SiniestroResguardoUnidadId={siniestroResguardoUnidadId}
                />
            )}
        </>
    );
};

export default ListadoCostos;
