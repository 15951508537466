import React, { useRef, useState,useEffect } from 'react';
import Select from 'react-select'
import SubMenuSeguimientoTelefonico from "./SubMenuSeguimiento"
import SeguimientoVisita2Hook from "../../../Hooks/SeguimientoTelefonico/SeguimientoVisita2Hook"
import RowPages from '../../../Components/RowPages'
import DataTable from "../../../Components/datatable/DataTable"
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermissionById } from '../../../Auth/ValidatePermission'


const SeguimientoCobranza = () => {
  const navigate = useNavigate(); 
  const {
    setName,
    setpageRows,
    columns,
    currentData,
  
    pageCount,
    currentPage,
    handlePageClick,
    ejecutivoData,
    setEjecutivo
  } = SeguimientoVisita2Hook()
  const [selectedData, setSelectedData] = useState(null);
  const handleDetails = (item) => {
    setSelectedData(item);
    navigate(`/cobranza/seguimiento-telefonico/detalles`, { state: item });
  };
  const handleNavigate = () => {
    navigate('/cobranza/generacion-expedientes');
  };


  return (
    <>
      <SubMenuSeguimientoTelefonico />
      <div>
      <div className="d-flex w-100">
          <div className="col-lg-2 col-md-6 col-sm-12 mt-4 mx-4 inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input
              type="text"
              className="form-control mb-4"
              placeholder="Buscar"
              onKeyUp={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 mt-4 mx-4">
            <Select
              options={ejecutivoData}
              className="col-12"
              onChange={(e) => {
                setEjecutivo(e.value);
              }}
              placeholder="Ejecutivo"
            />
          </div>
          <div className="ms-auto mt-4 me-4">
            <button className="btn " style={{ width: '250px' }} onClick={handleNavigate}>
              Generar Expediente
            </button>
          </div>
        </div>
        {/* <RowPages
          setpageRows={setpageRows}
        /> */}
      </div>
      <div className='mx-4'>
        <DataTable
          column={columns}
          data={currentData}
          detailable={ValidatePermissionById(4, Section.Seguimientotelefonico, SubModule.SeguimientoTelefonico).Historial}
          handleDetail={handleDetails}
          paginate={true}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage - 1}
          paginateResult={true}
          setpageRows={setpageRows}
        />
      </div>
    </>
  )
}

export default SeguimientoCobranza