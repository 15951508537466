import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import CardVehiculo from '../../Garantias/devoluciones/CardVehiculo';
import useCambioDevolucion from '../../../Hooks/Garantias/useCambioDevolucion';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Cierres from '../../../Services/Resguardos/Cierres';
import OrigenCard from '../resguardos/OrigenCard';
import ContratoCard from './ContratoCard';
import AdeudosCard from './AdeudosCard';
import NotificarClienteCard from './NotificarClienteCard';
import CitaFirmaCard from './CitaFirmaCard';
import ProcesoFirmaCard from './ProcesoFirmaCard';
import AntecedentesCard from './AntecedentesCard';
import AdeudosCambioDevolucionCard from './AdeudosCambioDevolucionCard';

const CierreCard = ({}) => {
    const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const { id, generalid, cierreid } = useParams();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [data, setData] = useState([]);

    

    let {
        vehiculo
    } = useCambioDevolucion();

    useEffect(() => {
        getDataFromCierreContrato();
    }, []);

    const getDataFromCierreContrato = () => {
        setLoading(true);
        Cierres.cierreContratoGet(cierreid)
        .then(resp => {
            setData(resp.data);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    };


    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/procesocierre/resguardos';
    }


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de cierre</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <CardVehiculo data={vehiculo}/>
                        </div>
                        
                        <div className="col-6 px-md-3">
                            <OrigenCard data={vehiculo}/>
                        </div>
                    </div>
                </div>

                {/* <ContratoCard /> */}
                <AntecedentesCard Origen={data.origen}/>
                {
                    data.origen !== 'garantia' &&
                    <AdeudosCard />
                }
                {
                    data.origen === 'garantia' &&
                    <AdeudosCambioDevolucionCard />
                }
                <NotificarClienteCard />

            </section>

        </>)
}

export default CierreCard