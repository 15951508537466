import axios from '../../Utils/ApiService';

const Valuaciones = {
    /* SEGUIMIENTO VALUACIONES */
    getSeguimientoValuaciones: (data) => {
        return axios.post(`/cartera/getSeguimientoValuaciones`, data);
    },
    crearValuaciones: (data) => {
        return axios.post(`/cartera/valuacionCrear`, data);
    },
    valuacionInspeccionUnidadCrear: (data) => {
        return axios.post(`/cartera/valuacionInspeccionUnidadCrear`, data);
    },
    valuacionInspeccionUnidadActualizar: (data) => {
        return axios.put(`/cartera/valuacionInspeccionUnidadActualizar`, data);
    },
    valuacionInspeccionUnidadGet: (data) => {
        return axios.get(`/cartera/valuacionInspeccionUnidadGet?valuacionId=${data}`);
    },
    valuacionInspeccionUnidadReparacionesCrear: (data) => {
        return axios.post(`/cartera/valuacionInspeccionUnidadReparacionesCrear`, data);
    },
    valuacionInspeccionUnidadReparacionesActualizar: (data) => {
        return axios.put(`/cartera/valuacionInspeccionUnidadReparacionesActualizar`, data);
    },
    valuacionInspeccionUnidadReparacionesGet: (data) => {
        return axios.get(`/cartera/valuacionInspeccionUnidadReparacionesGet?valuacionInspeccionUnidadReparacionesId=${data}`);
    },
    valuacionValoracionUnidadCrear: (data) => {
        return axios.post(`/cartera/valuacionValoracionUnidadCrear`, data);
    },
    valuacionValoracionUnidadActualizar: (data) => {
        return axios.put(`/cartera/valuacionValoracionUnidadActualizar`, data);
    },
    valuacionvaluacionValoracionUnidadGet: (data) => {
        return axios.get(`/cartera/valuacionValoracionUnidadGet?valuacionId=${data}`);
    },
    getEstatusLiquidacion: (data) => {
        return axios.post(`/cartera/getEstatusLiquidacion`, data);
    },
    getReporteClaves: (data) => {
        return axios.post(`/cartera/getReporteClaves`, data);
    },
    getComentariosByBitacora: (data) => {
        return axios.get(`/cartera/GetComentariosByBitacora?Bitacora=${data.Bitacora}&BitacoraId=${data.BitacoraId}`);
    },
}

export default Valuaciones