import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';

const ModalConfirmEndoso = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let initial = {
        filename: item.filename,
        legibilidad: false,
        endosada:false
    }
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
    });

    const dataModal = [
        { label: "Separado", value: "Separado" },
        { label: "Liberado", value: "Liberado" },
    ];

    const handleAddEdit = async (item) => {
        submit(item);

        // setIsOpen(false);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);                
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons' style={{ width: '30rem' }}>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2 d-flex justify-content-between">
                                    <div>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                        <span className='ms-2'>  El documento es legible</span>
                                    </div>
                                    <div>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="endosada" />
                                        <span className='ms-2'><strong>Factura endosada</strong> </span>
                                    </div>
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={(!values.legibilidad || !values.endosada)}>Confirmar</button>
                            </section>
                        </Form>
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalConfirmEndoso