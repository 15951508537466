import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { TextField } from '../../../Components/TextField';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import DataTable from '../../../Components/datatable/DataTable';
import { useSelector } from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ConfirmarReasignacionModal from './ConfirmarReasignacionModal';
import ConfirmarGuardadoContratos from '../../DiasFestivos/Modal/ConfirmarGuardadoContratos';

const ReasignarCargaNivel2 = () =>
{
   const { usuario } = useSelector((state) => state.ReasignarCargaNivelDosSlice);
   let initial = {
      userid: usuario?.userid,
      nombrecobrador: usuario?.nombrecobrador,
      numerocontratos: usuario?.numerocontratos,
      numerozonas: usuario?.numerocontratos,
      contratos: usuario?.contratos,
      tipodeintercambio: "",
      cobradordestino: ""
   }

   const [initialValue, setInitialValue] = useState(initial);
   const [dataPersonal, setDataPersonal] = useState([]);
   const [dataZonas, setDataZonas] = useState([])
   const [counterContratosZonas, setCounterContratosZonas] = useState(0);
   const [opcionIntercambio, setOpcionIntercambio] = useState({ value: 1, label: "Contratos" });
   const [loading, setLoading] = useState(false);
   const [listaContratos, setListaContratos] = useState([]);
   const [listaZonas, setListaZonas] = useState([])
   const [counterContratos, setCounterContratos] = useState(0);
   const [listaElementos, setListaElementos] = useState("")
   const [str, setStr] = useState("");
   const [currentData, setCurrentData] = useState(usuario.contratos)
   const [openModalConfirmar, setModalConfirmar] = useState(false)
   const [cobradorDestinoId, setCobradorDestinoId] = useState(null);
   const [cobradorDestinoNombre, setCobradorDestinoNombre] = useState("");
   const [guardar, setGuardar] = useState(false)
   const [detalle, setDetalle] = useState("los contratos")
   const [modalConfirm, setModalConfirm] = useState(false);
   const [contadorTipo, setContadorTipo] = useState(usuario?.numerocontratos)
   const { state } = useLocation();
   const navigate = useNavigate();

   useEffect(() =>
   {
      let filteredData = usuario?.contratos?.filter(item =>
      {
         const nameMatches = str === '' || (item?.contrato && item?.contrato?.toLowerCase().includes(str.toLowerCase()));
         return (nameMatches)
      })

      const newData = filteredData
      setCurrentData(newData)

   }, [str])

   useEffect(() =>
   {
      console.log(usuario)
      handleGetAllUsersNotInCobranza();
      handleGetZonas();
   }, []);

   useEffect(() =>
   {
      if (guardar)
      {
         handleGuardar()
         setGuardar(false)
      }
   }, [guardar])

   const handleGetAllUsersNotInCobranza = async () =>
   {
      setLoading(true)
      let NewData = [{ value: null, label: "Seleccionar" }];
      await CobranzaService.getEjecutivosFullDetail()
         .then(res =>
         {
            if (res?.data?.callSucceded)
            {
               res?.data?.responseData?.map(item =>
               {
                  if (item?.nivel_cobrador === "2" && item?.userid !== usuario?.userid && item?.status?.toLowerCase() !== "inactivo")
                  {
                     NewData.push({ value: item?.userid, label: item?.user_detail?.names })
                  }
               })
            }
         })
         .catch(e =>
         {
            console.log(`ERROR -- ${e}`)
         })

      setDataPersonal(NewData);
      setLoading(false)
   }

   const handleGetZonas = () =>
   {
      let zonas = new Set()
      let NewDataZonas = []

      usuario?.contratos?.map(item =>
      {
         zonas.add(item.zona)
      })

      for (const value of zonas)
      {
         let totalVisitas = 0
         usuario.contratos?.map(item =>
         {
            if (value === item.zona)
            {
               totalVisitas = totalVisitas + item.visitas
            }
         })
         NewDataZonas.push({ zona: value, visitas: totalVisitas })
      }
      setDataZonas(NewDataZonas)
   }

   const tipoIntercambio = [
      { value: 1, label: "Contratos" },
      { value: 2, label: "Zonas" }
   ]

   const columnsContratos = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'zona', headerName: 'Zona', width: 300 },
      { field: 'contrato', headerName: 'Contrato', width: 150 },
      { field: 'tipo_persona', headerName: 'Tipo persona', width: 130 },
      { field: 'nombre', headerName: 'Nombre', width: 300 },
      { field: 'direccion', headerName: 'Dirección', width: 400 },
      { field: 'diasvencidos', headerName: 'Días vencidos', width: 130 },
      { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar clave', width: 200 },
      { field: 'montovencido', headerName: 'Monto vencido', width: 150 },
      { field: 'visitas', headerName: 'Visitas', width: 120 }
   ];

   const columnsZonas = [
      { field: 'zona', headerName: 'Zona', width: 300 },
      { field: 'visitas', headerName: 'Visitas', width: 120 }
   ]

   const handleGuardar = async () =>
   {
      let nuevalistacont = []
      if (opcionIntercambio.value === 2)
      {
         listaZonas.map(item =>
         {
            usuario?.contratos?.map(cont =>
            {
               if (cont.zona === item.zona)
               {
                  nuevalistacont.push({ id: cont.id })
               }
            })
         })
      }
      else
      {
         listaContratos.map(item =>
         {
            nuevalistacont.push({ id: item.id })
         })
      }

      if (nuevalistacont.length > 0)
      {
         setLoading(true);
         let data = {
            "verb": "string",
            "id": usuario?.userid,
            "field": "string",
            "value": cobradorDestinoId,
            "anyObject": nuevalistacont
         };

         await CobranzaService.reAsignarCarga(data)
            .then(res =>
            {
               setLoading(false);
               navigate(-1)
            })
            .catch(e =>
            {
               setLoading(false);
               console.log(`ERROR ${e}`);
            })
      }

      setModalConfirm(false);
   }

   const handleCheck = (checked, value) =>
   {
      let newList = [];
      let stringContratos = ""
      if (checked)
      {
         stringContratos = `${value.contrato}`
         newList.push({ id: value.id, contrato: value.contrato })
         if (listaContratos.length > 0)
         {
            listaContratos.map(item =>
            {
               stringContratos = `${stringContratos}, ${item.contrato}`
               newList.push({ id: item.id, contrato: item.contrato })
            })
         }
      }
      else
      {
         listaContratos.map(item =>
         {
            if (item.contrato !== value.contrato)
            {
               stringContratos = `${value.contrato}, ${stringContratos}`
               newList.push({ id: item.id, contrato: item.contrato })
            }
         })
         setCounterContratosZonas(counterContratosZonas - 1);
      }
      setListaElementos(stringContratos)
      setCounterContratos(newList.length);
      setListaContratos(newList);
   }

   const handleCleanSets = (value) =>
   {
      if (value === 1)
      {
         setDetalle("los contratos")
         setContadorTipo(usuario?.numerocontratos)
      }
      else
      {
         setContadorTipo(dataZonas.length)
         setDetalle("las zonas")
      }
      setCounterContratos(0);
      setListaElementos('')
      setListaContratos([]);
   }

   const handleCheckZonas = (checked, value) =>
   {
      let newList = [];
      let stringZonas = ""

      if (checked)
      {
         stringZonas = `${value.zona}`
         newList.push({ zona: value.zona })
         if (listaZonas.length > 0)
         {
            listaZonas.map(item =>
            {
               stringZonas = `${stringZonas}, ${item.zona}`
               newList.push({ zona: item.zona })
            })
         }
      }
      else
      {
         listaZonas.map(item =>
         {
            if (item.zona !== value.zona)
            {
               stringZonas = `${value.zona}, ${stringZonas}`
               newList.push({ zona: item.zona });
            }
         })
      }
      setListaElementos(stringZonas)
      setCounterContratos(newList.length);
      setListaZonas(newList);
   }

   return (
      <>
         <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) =>
            {
               handleGuardar(values);
            }}
         >{
               ({ isValid, values, setFieldValue }) => (
                  <>
                     <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                     <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de supervisión de carga {state.page}
                     </header>
                     <section className='mx-5 py-4'>
                        <div className='d-flex justify-content-between'>
                           <span className='fs-5'><strong>Reasignar carga de trabajo</strong></span>
                        </div>
                     </section>
                     <section className='mt-3 mx-5' style={{ width: "60rem" }}>
                        <p><span style={{ color: "#000", fontWeight: "bold" }}>1. Indica el cobrador destino.</span></p>
                        <div className="mb-3 row" style={{ marginTop: 30 }}>
                           <div className="col-sm-4">
                              <label className='col-12 mx-1'>
                                 Tipo de intercambio
                                 <Select options={tipoIntercambio} label="Tipo de intercambio" name='tipodeintercambio' defaultValue={opcionIntercambio} placeholder="Seleccionar"
                                    onChange={(e) =>
                                    {
                                       setFieldValue("tipodeintercambio", e.value !== 0 ? e.value : "");
                                       setOpcionIntercambio(e);
                                       handleCleanSets(e.value);
                                    }} />
                              </label>
                           </div>
                           <div className="col-sm-4">
                              <label className='col-12 mx-1'>
                                 Cobrador destino
                                 <Select options={dataPersonal} label="Nombre" name='cobradordestino' placeholder="Seleccionar" onChange={(e) =>
                                 {
                                    console.log(e)
                                    setFieldValue("cobradordestino", e.value !== null ? e.value : '');
                                    setCobradorDestinoNombre(e.label);
                                    setCobradorDestinoId(e.value)
                                 }} />
                              </label>
                           </div>
                           <div className="col-sm-4">
                              <TextField label={opcionIntercambio.value === 1 ? "Contratos actuales" : "Zonas actuales"} name="numerocontratos" type="text" value={contadorTipo} disabled={true} />
                           </div>
                        </div>
                        <p><span style={{ color: "#000", fontWeight: "bold" }}>2. Selecciona los contratos que deseas reasignar.</span></p>
                        <div className="mb-3 row" style={{ marginTop: 30 }}>
                           <div className="mx-1  inner-addon right-addon col-8">
                              <i className="glyphicon fas fa-search"></i>
                              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                           </div>
                        </div>
                     </section>
                     <section className='mt-3 mx-5 mb-3 row'>
                        <div className='col-sm-6'>
                           <p><span style={{ color: "#000", fontWeight: "bold" }}>Cobrador fuente: {values.nombrecobrador}</span></p>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end'>
                           {
                              opcionIntercambio.value === 1 && (
                                 <><p>Total de contratos: {contadorTipo} <span style={{ color: "#000", fontWeight: "bold" }}> Contratos seleccionados {counterContratos} </span></p></>
                              )
                           }
                           {
                              opcionIntercambio.value === 2 && (
                                 <><p>Total de zonas: {contadorTipo} <span style={{ color: "#000", fontWeight: "bold" }}> Zonas seleccionadas {counterContratos} </span></p></>
                              )
                           }
                           <button id="AT_btnGuardar" className='btn mx-2 col-3' type="submit" disabled={counterContratos <= 0 || cobradorDestinoId === null} onClick={() => { setModalConfirmar(true) }}>Guardar</button>
                        </div>
                     </section>
                     <section className='my-2'>
                        {/* <ValidatePermission
                  isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                  > */}
                        {/* <RowPages setpageRows={setpageRows} /> */}
                        {
                           opcionIntercambio.value == 1 && (
                              <div className='mx-4 table-responsive'>
                                 <DataTable
                                    column={columnsContratos}
                                    data={currentData}
                                    // pageCount={pageCount}
                                    checkbox={true}
                                    handleCheck={handleCheck}
                                    // paginate={true}
                                    // handlePageClick={handlePageClick}
                                    // currentPage={page - 1}
                                 />
                              </div>
                           )
                        }
                        {
                           opcionIntercambio.value == 2 && (
                              <div className='mx-4 table-responsive'>
                                 <DataTable
                                    column={columnsZonas}
                                    data={dataZonas}
                                    // pageCount={pageCountZonas}
                                    checkbox={true}
                                    handleCheck={handleCheckZonas}
                                    // paginate={true}
                                    // handlePageClick={handlePageClickZonas}
                                    // currentPage={pageZonas - 1}
                                 />
                              </div>
                           )
                        }
                        {/* </ValidatePermission> */}
                     </section>
                     {
                        modalConfirm &&
                        <ConfirmarGuardadoContratos
                           isOpen={modalConfirm}
                           setIsOpen={setModalConfirm}
                           handleConfirm={handleGuardar}
                           contratosCadena={listaContratos.map(({ contrato }) => contrato).join(', ')}
                           cobradorDestino={cobradorDestinoNombre}
                        />
                     }
                  </>
               )

            }
         </Formik>
         {
            <ConfirmarReasignacionModal
               isOpen={openModalConfirmar}
               setIsOpen={setModalConfirmar}
               elementos={listaElementos}
               handleAceptar={setGuardar}
               nombreCobrador={cobradorDestinoNombre}
               detalle={detalle}
            />
         }
      </>
   )
}

export default ReasignarCargaNivel2