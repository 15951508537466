import { Formik } from "formik";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { TextField } from "../../../Components/TextField";
import Select from "react-select/base";
import DataTable from "../../../Components/datatable/DataTable";
import AsignacionServiciosAsignadosHook from "../../../Hooks/DatamovilSystems/Asignaciones/AsignacionServiciosAsignadosHook";
import { useParams } from "react-router-dom";
import { forwardRef, useImperativeHandle } from "react";
import { Toaster } from "react-hot-toast";

const AsignacionServiciosAsignados = forwardRef(({ servicioAsignacion, onUpdated }, ref) => {
    const { folioId } = useParams();
    const {
        refFormik,
        refSelect,
        data,
        initialValue,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        tipoServicios,
        tipoUbicaciones,
        columns,
        handleCheck,
        handleDesasignar,
        setBuscar,
        getServiciosAsignados
    } = AsignacionServiciosAsignadosHook({ servicioAsignacion, onUpdated });

    useImperativeHandle(ref, () => ({
        getServiciosAsignados,
    }));

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <>
                                <section className="p-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="fw-bold">Confirma los cambios</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="text-sm">¿Estás seguro de los cambios realizados en la asignación: {folioId}?</span>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-end mt-4">
                                        <div className="col-12 col-md-auto">
                                            <button className="btn btn-outline" onClick={() => setModalMessage({ isOpen: false })}>
                                                Regresar
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <button className="btn" onClick={() => handleDesasignar(folioId)}>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </>
                        }
                    </Modal>
                    <div className="row mt-4">
                        <div className="col-12 col-md-12">
                            <div className="row mb-2 justify-content-between">
                                <div className="col-12 col-md-auto">
                                    <span className="text-sm text-blue">A continuación te presentamos el listado de los nuevos servicios asignados</span>
                                </div>
                            </div>
                            <div className="row mb-2 justify-content-between">
                                <div className="col-12 col-md-auto">
                                    <strong className='mt-2'>Servicios asignados: </strong>{folioId}
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="row justify-content-end align-items-center">
                                        <div className="col-auto">
                                            <button className="btn btn-outline text-xs" onClick={() => setModalMessage({ isOpen: true, type: 1 })}>
                                                Eliminar seleccionados
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 col-md-2 mr-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                setFieldValue("buscar", event.target.value);
                                setBuscar(event.target.value)
                            }} />
                        </div>
                        <div className="col-12 col-md-2">
                            <Select ref={refSelect} options={tipoServicios} className='col-12' onChange={(e) => {
                                setFieldValue("estatus", e.value);
                                // setTipoSolicitud(e.value);
                                // getListado()
                            }} placeholder="Tipo de servicio" />
                        </div>
                        <div className="col-12 col-md-2">
                            <Select ref={refSelect} options={tipoUbicaciones} className='col-12' onChange={(e) => {
                                setFieldValue("estatus", e.value);
                                // setTipoUbicacion(e.value);
                                // getListado()
                            }} placeholder="Tipo de ubicación" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-12'>
                            {
                                loading &&
                                <DataTable
                                    column={columns}
                                    data={[]}
                                    headerColorClass={'header-primary'}
                                />
                            }
                            {
                                !loading &&
                                <DataTable
                                    column={columns}
                                    data={data}
                                    headerColorClass={'header-primary'}
                                    checkbox={true}
                                    handleCheck={handleCheck}
                                />
                            }
                        </div>
                    </div>
                </>
            )
            }
        </Formik>
    );
});

export default AsignacionServiciosAsignados;