import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import CardDevoluciones from './CardDevoluciones';
import CardCalculo from './CardCalculo';
import CardBoletinado from './CardBoletinado';

const CardAntecedentes = ({inInspeccionRapida = false}) => {
    
    const { Url } = useSelector((state) => state.UrlSlice);
    const { cliente, usoUnidad, numContrato } = useSelector((state) => state.DataVinSlice);

    const { FolioGarantia, Gravedad, TipoReporte, Falla } = useSelector((state) => state.GarantiaSlice);

    const location = useLocation();
    const loading = false;
    const { id } = useParams();

    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/garantias/incidencias';
    }


    return (
        <>
            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                    <div className="col-12 underline-border">
                        <h6>
                            <strong>Antecedentes</strong>
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Cliente:</strong> {cliente}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Folio de garantía:</strong> {FolioGarantia}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Teléfono:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Clasificación de la falla:</strong> {Falla}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Correo electrónico:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Gravedad:</strong> {Gravedad}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>No. de contrato:</strong> {numContrato}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Tipo de reporte:</strong> {TipoReporte}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Uso de unidad:</strong> {usoUnidad}</small>
                    </div>
                </div>
            </div>
        </>)
}

export default CardAntecedentes