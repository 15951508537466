import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    TallerAutorizados: [],
}

export const TallerSlice = createSlice({
    name: 'TallerSlice',
    initialState,
    reducers: {
        setTallerSlice: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                TallerAutorizados: action.payload.TallerAutorizados,
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
    }
})

export const { setTallerSlice, setEditable } = TallerSlice.actions
export default TallerSlice.reducer