import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';

const useVehiculoConvertido = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'fechaConversion', headerName: 'Fecha de Conversión', width: 170 },
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 150 },
        { field: 'vendedorExterno', headerName: 'Vendedor Externo', width: 150 },
        { field: 'usuarioConversion', headerName: 'Usuario conversión', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //Filtro
    const [tipoCompra, settipoCompra] = useState([]);
    const [DataSocioComercial, setDataSocioComercial] = useState([])
    const [claveTipoCompra, setclaveTipoCompra] = useState("");
    const [claveVendedor, setClaveVendedor] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [fechInicio, setFechInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");


    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, claveTipoCompra, claveVendedor, fechaFin, fechInicio, pageRows])
    useEffect(() => {
        handleGetAllSelects();
    }, [])

    const handleGetAllSelects = async () => {
        setLoading(true)
        const [Sociocomercial, Tipocompra] = await Promise.all([handleGetSocioComercial(), handleGetTipoCompra()]).finally(() => {
            setLoading(false)
        });
    }
    const habdleGetAll = async (page) => {
        setLoading(true);
        await VehiculoConvertidoService.getAllVehiculosConvertidos(page, pageRows, claveTipoCompra, claveVendedor, str, fechInicio, fechaFin)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetSocioComercial = async () => {
        await SocioComercialServices.getAllSocioComercialList()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.socioComercialId,
                        label: item.nombre
                    })
                });
                setDataSocioComercial(NewData);
            }).catch(e => {

            })
    }
    const habdleDownloadFile = async () => {
        setLoading(true);
        await VehiculoConvertidoService.downloadFile(claveTipoCompra, claveVendedor, str,fechInicio, fechaFin)
            .then(res => {                
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculosConvertidos-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataSocioComercial,
        tipoCompra,
        setclaveTipoCompra,
        setClaveVendedor,
        setFechInicio,
        setFechaFin
    }
}

export default useVehiculoConvertido