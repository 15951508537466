import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Modal from '../../../Components/Modal/Modal';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Spinner from '../../../Components/Loadig';
// import { toast, Toaster } from 'react-hot-toast';

const ContratoGestoriaCard = () => {
    const { vin, anombre, marca, modelo, version, anio, placas, color, kilometraje, procedencia, numMotor, fechaEntrega, numContrato, cliente, telefono, correo, producto, usoUnidad, fechaUltimoPago, fechaLiquidacion } = useSelector((state) => state.GestoriaEncabezadoSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={780}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <div className="p-4 white-wrapper" style={{ minHeight: '320px' }}>
                <div className="row px-2">
                    <div className="col-12 underline-border">
                        <div className="row">
                            <div className="col-auto" style={{ "marginTop": "5px" }}>
                                <h6><strong>Contrato</strong></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>No. de contrato:</strong> {numContrato}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Producto:</strong> {producto}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Cliente:</strong> {cliente}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Uso de unidad:</strong> {usoUnidad}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Fecha de último pago:</strong> {fechaUltimoPago}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Fecha de liquidación del crédito:</strong> {fechaLiquidacion}</small>
                    </div>
                </div>
            </div>
        </>)
}

export default ContratoGestoriaCard