import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import ComisionesEmpresaService from '../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import ComisionesExternoService from '../../../Services/Inventario/Comisiones/ComisionesExternoService';
import CobroComisionesExternoService from '../../../Services/Inventario/Comisiones/CobroComisionesExternoService';
const useCobroComisionesExternoPagadas = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: "Contrato", width: 150 },
        { field: 'folio', headerName: 'Folio', width: 150 },
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega', width: 150 },
        { field: 'razonSocial', headerName: 'Razón social', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'procedenciaCliente', headerName: 'Procedencia del cliente', width: 150 },
        { field: 'comisionStr', headerName: 'Comisión', width: 150 },
        { field: 'strFechaProcesamientoPago', headerName: 'Comisión Pagada', width: 250 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },        
        { field: 'nombreYear', headerName: 'Año', width: 100 }
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [IdsExportar, setIdsExportar] = useState([]);
    const [openModalTotales, setopenModalTotales] = useState(false);
    const [ProcedCliente, setProcedCliente] = useState([]);
    const [ValueProcedCliente, setValueProcedCliente] = useState("");
    const [claveRazonSocial, setClaveRazonSocial] = useState("");
    const [razonSocial, setrazonsocial] = useState([]);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechaFin, setFechaFin] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows, FechInicio, FechaFin,TipoCompra,claveRazonSocial])
    useEffect(() => {
        handleGetTipoCompra();
        // handleGetAllProdecenciaCliente();
        // handleGetAllUsoUnidad();
        // handleGetAllAseguradora();
    }, [])
    const handleGetAllProdecenciaCliente = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Procedencia_Cliente')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.Procedencia_ClienteId,
                        label: item.Procedencia_Cliente
                    })
                });
                setProcedCliente(NewData);
            })
            .catch(err => {
                console.error(err);
            })
    }
    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await CobroComisionesExternoService.getAllCobroComisionesExternoPagada(page, pageRows, str,TipoCompra,claveRazonSocial,FechInicio, FechaFin)
            .then(res => {        
                console.log("data->",res.data.data)        
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
                setIdsExportar([]);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                setTimeout(() => {
                    if ((Aseguradora ?? "") !== "") {
                        handleIsActiveCheck(true);
                    } else {
                        handleIsActiveCheck(false);
                    }
                }, 500);
            })
    }
    const handleDownloadExcel = async () => {
        setLoading(true);
        await CobroComisionesExternoService.ExportCobroComisionesExternoPagada(TipoCompra,claveRazonSocial,str,FechInicio, FechaFin)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `ComisionesExternasCobradas-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsExportar(current => [...current, value]);
        } else {
            setIdsExportar(IdsExportar.filter(item => item.generalId !== value.generalId));
        }        
    }
    const handleCostos = () => {
        let total = 0;
        IdsExportar?.map(x => {
            total = total + x.precio
        })
        return total;
    }
    const handleGetCSRazonSocial = async (Id) => {
        if (Id === "") {
            setrazonsocial([]);
            selectRef.current.setValue([], 'clear')
            return;
        }
        await ComisionesExternoService.getAllCSRazonSocialCCEById(Id)
            .then(res => {                
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.RazonSocialId,
                        label: item.RazonSocial,
                        maximoNumeroAdeudos: item.MaximoNumeroAdeudos,
                        numeroAdeudosRestantes: item.NumeroAdeudosRestantes,
                        fecha:item.Fecha
                    })
                });
                setrazonsocial(NewData);
            }).catch(e => {
            })
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar,
        setItem,
        item,
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,        
        handleGetCSRazonSocial,
        razonSocial     
    }
}

export default useCobroComisionesExternoPagadas