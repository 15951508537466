import { Formik } from "formik";
import { SelectField } from "../SelectField";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import Spinner from "../Loadig";
import { TextField } from "../TextField";
import Catalogo from "../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../Services/DatamovilSystems/Asignaciones";
import { message } from "../../Utils/functions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AsignarServiciosModal = ({ modalMessage, setModalMessage }) => {
    const navigate = useNavigate();
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    let initial = {
        servicioAsignacion: null,
        tecnicoAsignado: '',
        fechaAsignacion: '',
    }

    const [initialValue, setInitialValue] = useState(initial);

    const resultados = [
        { value: '0', label: 'Rechazar' },
        { value: '1', label: 'Aprobar' },
    ]
    const [tecnicos, setTecnicos] = useState([]);

    useEffect(() => {
        getTecnicos();
        getCurrentDate();
    }, []);

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
        const year = today.getFullYear();

        const currentDate = `${day}-${month}-${year}`;
        setInitialValue({
            ...initialValue,
            fechaAsignacion: currentDate
        })

    }

    const getTecnicos = async () => {
        await DSAsignaciones.getTecnicos()
            .then(resp => {
                let items = resp.data.map(item => {
                    return {
                        value: item.userId,
                        label: item.tecnico
                    }
                });

                setTecnicos(items);
            })
            .catch(err => {

            })
    }

    const handleRegresar = () => {
        setModalMessage({ isOpen: false });
    }

    const handleGuardar = async (values) => {
        setLoading(false);
        await DSAsignaciones.postAsignacion(values)
        .then(resp => {
            toast.success(message('Asignación creada correctamente, en breve será redirigido a la asignación'));
            navigate(`/datamovil/asignaciones/${resp.data.folioId}`);
        })
        .catch(err => {
            toast.error(message('Ocurrió un error al intentar agregar la asignación'));
            setModalMessage({ isOpen: false });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                // validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    tecnicoAsignado: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-10">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                                <div className="col col-2 d-flex justify-content-end">
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => handleRegresar()}>
                                        <i className="ri-close-fill"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="tecnicoAsignado"
                                                label="Técnico"
                                                name="tecnicoAsignado"
                                                items={tecnicos}
                                                onChange={(event) => {
                                                    setFieldValue("tecnicoAsignado", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="fechaAsignacion"
                                                disabled={true}
                                                label="Fecha"
                                                name="fechaAsignacion"
                                                type="text"
                                                onChange={(event) => {
                                                    setFieldValue("fechaAsignacion", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handleGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default AsignarServiciosModal;