import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setHour as setHourGarantia } from '../../Redux/Slice/garantiasSlice';
import { setHour as setHourGrua } from '../../Redux/Slice/gruasSlice';
import { setHour as setHourEntrega } from '../../Redux/Slice/entregaSlice';
import { setHour as setHourFirma } from '../../Redux/Slice/firmaSlice';
import { setHour as setHourEntregaExpediente } from '../../Redux/Slice/entregaExpedienteSlice';
import { setHour as setHourCitaDatamovil } from '../../Redux/Slice/citaDatamovilSlice';
import { setHour as setHourCitaCambioPlacas } from '../../Redux/Slice/Gestoria/cambioPlacasSlice';
import { setHour as setHourCitaAltaPlacas } from '../../Redux/Slice/Gestoria/altaPlacasSlice';
import { setHour as setHourCitaTarjetaCirculacion } from '../../Redux/Slice/Gestoria/tarjetaCirculacionSlice';
import { setHour as setHourCitaGestoriaEntrega } from '../../Redux/Slice/Gestoria/gestoriaEntregaSlice';

const ScheduleModal = ({ modalMessage, setModalMessage, schedules }) => {
    const { tipoCalendario } = useSelector((state) => state.CalendarSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        getDate();
    }, [schedules]);

    const [day, setDay] = useState("");
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const schedulesPerColumn = schedules.length <= 9 ? Math.ceil(schedules.length / 3) : Math.ceil(schedules.length / 4);
    const columns = schedules.length <= 9 ? 3 : 4;


    const getDate = () => {
        const selectedDay = schedules[0].fecha.split("T")[0];
        const date = new Date(selectedDay.replace('-', '/'));
        const strDate = days[date.getDay()] + ", " + ("0" + date.getDate()).slice(-2) + " de " + months[date.getMonth()];
        setDay(strDate);
    }

    const handleNextModal = (hour) => {
        const schedule = schedules.filter(item => item.horario.split(" ")[0] === hour)[0];

        let date = {
            day: schedule.fecha,
            hour: schedule.horario,
            calendarioId: schedule.id,
        }
        if (tipoCalendario === 1) {
            dispatch(setHourGarantia(date));
        } else if(tipoCalendario === 2) {
            dispatch(setHourGrua(date));
        }else if(tipoCalendario === 3) {
            dispatch(setHourEntrega(date));
        }else if(tipoCalendario === 6) {
            dispatch(setHourFirma(date));
        }else if(tipoCalendario === 7) {
            dispatch(setHourEntregaExpediente(date));
        }else if(tipoCalendario === 8) {
            dispatch(setHourCitaDatamovil(date));
        }else if(tipoCalendario === 9) {
            dispatch(setHourCitaCambioPlacas(date));
        }else if(tipoCalendario === 10) {
            dispatch(setHourCitaAltaPlacas(date));
        }else if(tipoCalendario === 11) {
            dispatch(setHourCitaTarjetaCirculacion(date));
        }else if(tipoCalendario === 12) {
            dispatch(setHourCitaGestoriaEntrega(date));
        }
        setModalMessage({ ...modalMessage, type: 5 })
    }

    const getSchedules = () => {
        const rows = [];
        const grid = 12 / columns;
        const currentDate = new Date();
        const currentDay = currentDate.getFullYear()+"-"+("0"+(currentDate.getMonth()+1)).slice(-2)+"-"+("0"+currentDate.getDate()).slice(-2);
        const currentHour = ("0"+currentDate.getHours()).slice(-2)+":"+("0"+currentDate.getMinutes()).slice(-2);
        const selectedDay = schedules[0].fecha.split("T")[0];
        
        let tmpHour = new Date();

        let idx = 0;
        for (var i = 1; i <= columns; i++) {
            const cells = [];
            for (var j = 0; j < schedulesPerColumn; j++) {
                if (idx < schedules.length && currentDay !== selectedDay) {
                    let hour = schedules[idx].horario.split(" ")[0];
                    cells.push(
                        <div className='row px-3 py-1' key={'cell_' + schedules[idx].horario}>
                            {
                                schedules[idx].disponible === 1 &&
                                <button className='btn btn-gray' onClick={(e) => {
                                    handleNextModal(hour)
                                }}>{hour}</button>
                            }
                            {
                                schedules[idx].disponible === 2 &&
                                <span style={{ textDecoration: 'line-through', padding: '5px' }} className='text-center'>{schedules[idx].horario.split(" ")[0]}</span>
                            }
                        </div>
                    )
                }else if(idx < schedules.length && currentDay === selectedDay){
                    let hour = schedules[idx].horario.split(" ")[0];
                    tmpHour.setHours(hour.split(":")[0],hour.split(":")[1],0,0);
                    let tmpHourMinutes = hour.split(":")[0]+":"+hour.split(":")[1];
                    cells.push(
                        <div className='row px-3 py-1' key={'cell_' + schedules[idx].horario}>
                            {
                                schedules[idx].disponible === 1 &&
                                currentHour < tmpHourMinutes &&
                                <button className='btn btn-gray' onClick={(e) => {
                                    handleNextModal(hour)
                                }}>{hour}</button>
                            }
                            {
                                (schedules[idx].disponible === 2 ||
                                currentHour >= tmpHourMinutes) &&
                                <span style={{ textDecoration: 'line-through', padding: '5px' }} className='text-center'>{schedules[idx].horario.split(" ")[0]}</span>
                            }
                        </div>
                    )
                }

                idx++;
            }

            rows.push(
                <div className={'col-' + grid} key={'row_' + grid + '_' + i}>
                    {cells}
                </div>
            )
        }
        return rows;
    }

    return (
        <div className='p-2'>
            <div className='justify-content-start align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>Horarios de cita</strong></h6>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <small>A continuación se muestran los horarios disponibles para agendar cita:</small>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-12">
                        <strong>{day}</strong>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col col-12'>
                        <div className="row">
                            {getSchedules()}
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Cerrar</button>
                </section>
            </div>
        </div>
    )
}

export default ScheduleModal