import axios from '../../Utils/ApiService';

const RolesService = {
    getAllRoles:async(page,rows,str)=> {
        return await axios.get(`/functions/GetAllRol?page=${page}&rows=${rows}&Buscar=${str}`);
    },  
    getRolById:async(id)=> {
        return await axios.get(`/functions/GetAllRolById?IdRol=${id}`);
    },   
    getsectionByRol:(rol,moduloId,submoduloId,str)=>{
        return axios.get(`/functions/GetAllPrivilegios?IdRol=${rol}&IdModulo=${moduloId}&IdSubModulo=${submoduloId}&Buscar=${str}`);
    },
    SavePrivilegiosRoles:(data)=>{
        return axios.put(`/functions/SavePrivilegiosRoles`,data);
    },
    AddNewRol:async(data)=>{
        return axios.post(`/functions/AddNewRol`,data);
    },
    UpdRol:async(data)=>{
        return axios.put(`/functions/UpdateRol`,data);
    }
}

export default RolesService