import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import { isOpenModalCheck } from '../../../Redux/Slice/quickActionsSlice'
import { useDispatch } from 'react-redux';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import ConsultaClaveService from '../../../Services/Cartera/ConsultaClaves';
import ClavesDemoService from '../../../Services/Inventario/ClavesDemoService';
import TextoCuadros from '../../../Components/CuadroTexto/TextoCuadro';

const ModalClaveVehiculo = ({ isOpenModalClave, item, demo, handleClavesDemo, filas, order = "asc" }) => {
    const dispatch = useDispatch();

    // const [data, setdata] = useState({});
    // const [claveArray, setClaveArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [claves, setClaves] = useState([])

    useEffect(() => {
        handleGetClave()
        // handleGetByIdClave();
    }, [])

    // const handleGetByIdClave = async () => {
    //     await VentaVehiculoService.getAllVehiculoClave(item.generalId)
    //         .then(res => {
    //             setdata(res.data.data);
    //             let clave = res.data.data.clave
    //             let array = [...clave]
    //             setClaveArray(array);
    //         }).catch(e => {

    //         })
    // }

    const handleGetClave = async () => {
        //si son clave demos
        if (demo) {
            //try catch
            try {
                setLoading(true);
                //Llamar al servicio de Claves demo
                const resp = await ClavesDemoService.getClaveDemo();
                const items = resp.data.map((item, index) => ({
                    index: index + 1,
                    clave: item.claveDemo,
                    vencimiento: item.fechaVencimiento,
                    tipoSolicitud: item.tipoSolicitud
                }));
                //variable para guardar el resultado 
                let result 
                //ordenar ascendente o descendiente
                const resOrdenado = items.sort((a, b) => {
                    if (order === 'asc') {
                      return new Date(a.vencimiento) - new Date(b.vencimiento)
                    } else if (order === 'desc') {
                      return new Date(a.vencimiento) - new Date(b.vencimiento)
                    }
                  })
                // Cuantas filas se tienen que ver. 
                if (filas) {
                    result = resOrdenado.slice(0, filas)
                } else {
                    result = resOrdenado
                }
                setClaves(result);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        } else {
            let vin = {
                vin: item.vin
            }
            //promise
            setLoading(true)
            await ConsultaClaveService.consultarClavesPorVin(vin)
                .then(res => {
                    if (res.data.length > 0) {
                        let servicioEnOrden = res.data?.sort((a, b) => Number(a.solId) - Number(b.solId));
                        setClaves(servicioEnOrden)
                    }
                    setLoading(false)
                })
        }

    }

    const FormatFecha = (fecha) => {
        const newDate = new Date(fecha)

        const dia = String(newDate.getDate()).padStart(2, '0')
        const mes = String(newDate.getMonth() + 1).padStart(2, '0')
        const anio = newDate.getFullYear()

        const fechaFormateada = `${dia}-${mes}-${anio}`
        return fechaFormateada
    }

    return (
        <>
            {loading ?
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                :
                <Modal isOpen={demo ? demo : isOpenModalClave} color='#fff' overflow={true}>
                    <section className='my-4 mx-4' style={{ width: '40rem' }}>

                        {
                            claves.length > 0 ?
                                <>
                                    <section className='py-2 d-flex flex-column'>
                                        <div className='d-flex justify-content-between'>
                                            <span className='fs-5'><strong>Consulta de clave</strong></span>
                                        </div>
                                        <span className='mt-2'>A continuación se muestra la clave, el tipo de solicitud y su vigencia:</span>
                                    </section>
                                    {claves.map((item, i) => {
                                        let fechaFormateada = FormatFecha(item.vencimiento)

                                        return (
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-4 col-lg-4">
                                                    <TextoCuadros
                                                        titulo={"Clave"}
                                                        texto={item.clave}
                                                        active={i === 0}
                                                    />
                                                </div>
                                                <div className={demo ? `col-sm-12 col-md-6 col-lg-6` : `col-sm-12 col-md-4 col-lg-4`}>
                                                    <span>Vigencia de la clave</span>
                                                    <input type="text" disabled className='form-control mt-2' value={fechaFormateada} />
                                                </div>
                                                {demo ?
                                                    <></>
                                                    :
                                                    <div className={demo ? `col-sm-12 col-md-6 col-lg-6` : `col-sm-12 col-md-4 col-lg-4`}>
                                                        <span>Tipo de solicitud</span>
                                                        <input type="text" disabled className='form-control mt-2' value={item.tipoSolicitud} />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <section className='py-2 d-flex flex-column'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fs-5'><strong>Error en consulta de clave</strong></span>
                                    </div>
                                    <span className='mt-2'>No se ha encontrado ninguna clave en el vehículo proporcionado.</span>
                                </section>
                        }

                        <section className='my-3 d-flex justify-content-end'>
                            <fieldset className='btn px-5' type='reset' onClick={() => {
                                demo ?
                                    handleClavesDemo(false)
                                    :
                                    dispatch(isOpenModalCheck({ check: !isOpenModalClave, consultaClave: false }))
                            }}>Cerrar</fieldset>
                        </section>

                    </section>
                </Modal >
            }
        </>
    )
}

export default ModalClaveVehiculo