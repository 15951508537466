import React from 'react'
import useProgramacionJobsCartera from "../../Hooks/ProgramacionJobs/useProgramacionJobsCartera"
import Modal from '../../Components/Modal/Modal';
import Loadig from '../../Components/Loadig';
import SubMenuJobs from './SubMenuJobs';

const ProgramacionJobsOriginacion = () => {
    let {
        loading,
    } = useProgramacionJobsCartera

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Loadig message={""} /></Modal>
            <SubMenuJobs />
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Programación de jobs</strong></span>
                </div>
                <span className='mt-2'>Programa los jobs de originación entrando al detalle del registro.</span>
            </section>
        </>
    )
}


export default ProgramacionJobsOriginacion