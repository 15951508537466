import { Formik, Form } from 'formik'
import React, { useRef, useState } from 'react'
import Modal from '../../../../Components/Modal/Modal';
import { FormatNumberMoney2, NumFolio, handleUnchekedColumns, message } from '../../../../Utils/functions';
import Spinner from '../../../../Components/Loadig';
import ComisionesService from '../../../../Services/Seguros/ComisionesService';
import ComisionesEmpresaService from '../../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
import ComisionesExternoService from '../../../../Services/Inventario/Comisiones/ComisionesExternoService';

const ModalRetencionPago = ({ isOpen, setIsOpen }) => {       
    return (
        <Formik
            enableReinitialize={true}
            // initialValues={initialValue}
            // onSubmit={(values) => {
            //     let newId = item.map((x) => x.generalId);                    
            //     handleAddEdit({ ...values,totalAPagar:handleCostos(item), ids: newId.join(), totalVehiculos: item.length });                
            // }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Retención del pago</strong></span>
                            <span>El vendedor/proveedor ha excedido la máxima cantidad <br /> de adeudos permitida.</span>
                        </div>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>                                
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn col-3' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>                                    
                                </section>
                            </Form>
                        </section>
                    </Modal>                    
                </>
            )
            }
        </Formik>
    )
}

export default ModalRetencionPago