import React from 'react';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import BasicModal from '../../../../Components/Modal/BasicModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useState } from 'react';
import SubmenuCitasCanceladas from './SubmenuCitasCanceladas';

const CitasCanceladas = () => {
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' }); 

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <BasicModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>

            <section>
                <SubmenuCitasCanceladas />
            </section>
        </>
    );
}

export default CitasCanceladas;
