import '../App.css';
import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { NumericFormat } from 'react-number-format';

export const TextField = ({ label, required, holder, icon, preffix, isMoney, ...props }) => {
    const [field, meta] = useField(props);

    const { onChange, ...rest } = props;

    const renderMoneyField = () => {
        return <NumericFormat
            type="text"
            displayType={"input"}
            decimalScale={2}
            fixedDecimalScale={true}
            className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
            {...rest} {...field}
            autoComplete="off"
            thousandSeparator={","}
            onChange={e => {
            }}
            onValueChange={values => {
                const { value } = values;
                if (props.onChange !== undefined && typeof props.onChange === 'function')
                    props.onChange({ target: { value: value } })
            }
            }

            placeholder={`${holder}`}
        />;
    }

    const renderDefaultField = () => {        
        if (isMoney !== null && isMoney === true)
            return renderMoneyField()
        else if (props.onChange !== undefined)
            return <input
                className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
                {...field} {...rest}
                autoComplete="off"
                onChange={e => props.onChange(e)}
                placeholder={`${holder}`}
            />
        else
            return <input
                className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
                {...field} {...rest}
                autoComplete="off"                
                placeholder={`${holder}`}
            />
    }

    return (
        <div className="mb-2">
            {
                label && <label className='text' htmlFor={field.name}>{label} {required ?
                    <span className='text-danger'>*</span> : ""}</label>
            }
            {
                icon !== undefined ?
                    <div className="inner-addon right-addon">
                        <i className={`glyphiconField ${icon}`} style={{ color: 'gray' }}></i>
                        {renderDefaultField()}
                    </div>
                    :
                    preffix !== undefined ?
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text" id="addon-wrapping">{preffix}</span>
                            {renderDefaultField()}
                        </div>

                        :
                        renderDefaultField()

            }


            <ErrorMessage component="span" name={field.name} className="error" />
        </div>

    )
}
