import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Formik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import PolizasPorCancelar from '../../../Services/PolizasPorCancelar/PolizasPorCancelar';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import PolizaPorCancelarModal from '../../../Components/Modal/PolizaPorCancelarModel';

const SeguimientoPolizasPorCancelar = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    let initial = {
        ubicacionId: "",
        buscar: "",
        fechaInicio: "",
        fechaFin: "",
        page: 1,
        rows: 10
    }

    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'vin', headerName: 'VIN'},
        { field: 'numContrato', headerName: 'Contrato'},
        { field: 'nombreMarca', headerName: 'Marca'},
        { field: 'nombreModelo', headerName: 'Modelo'},
        { field: 'nombreYear', headerName: 'Año'},
        { field: 'folioGarantia', headerName: 'Folio garantía'},
        { field: 'fechaAlta', headerName: 'Fecha'},
        { field: 'tipoGarantia', headerName: 'Tipo garantía'},
        { field: 'falla', headerName: 'Tipo de falla'},
        { field: 'tipoDevolucion', headerName: 'Aplica cambio/devolución'},
    ];

    useEffect(() => {
    }, []);


    const [currenPage, setCurrenPage] = useState(1);

    const SeguimientoPolizasPorCancelar = async (values = initialValue, page = 1) => {
        values.page = page;
        setLoading(true);
        
        await PolizasPorCancelar.getSeguimientoPolizasPorCancelar(values)
        .then(resp => {
            resp.data.data.forEach((item, idx) => {
                item.fechaAlta = getOnlyDateFormat(item.fechaAlta);
            });
            setData(resp.data)
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        let format = "";
        if (fecha !== undefined && fecha !== "") {
            format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
            return msj + format;
        }
    }

    const getOnlyDateFormat = (fecha) => {
        let d = new Date(fecha + "Z");
        let format = "";
        if (fecha !== undefined && fecha !== "") {
            format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear();
            return format;
        }
    }
    
    const handleDetail = (row) => {
        setModalMessage({ isOpen: true, type: 3, title: 'Cancelación de póliza', data: row })
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page
        });
        setCurrenPage(page);
        setPagina(page);
        SeguimientoPolizasPorCancelar(initialValue, page);
    };

    const getCounter = (tab) => {
        return tabInfo[tab]?.length || 0;
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={620}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <PolizaPorCancelarModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>
                    <section>
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <strong className='mt-2'>Polizas por cancelar</strong>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-3 mt-2 mr-1 inner-addon right-addon">
                                            <TextField id="buscar" label="Buscar:" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="Fecha inicial:" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="Fecha final:" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-auto mt-4 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => SeguimientoPolizasPorCancelar(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12'>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {
                                                tabs.map((tab, idx) => {
                                                    return <li className="nav-item" key={'tab_'+idx} role="presentation">
                                                        <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <DataTable
                                            column={columns}
                                            data={data?.data}
                                            detailable={true}
                                            handleDetail={handleDetail}
                                            pageCount={data?.totalPages}
                                            currentPage={currenPage - 1}
                                            handlePageClick={handlePageClick}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoPolizasPorCancelar;
