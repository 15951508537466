import axios from '../../../Utils/ApiService';

const ValoracionDeUnidadService = {
    getValoracionUnidad : (valuacionId) =>{
        return axios.get(`/functions/ValoracionUnidad?valuacionId=${valuacionId}`);
    },
    updateValoracionUnidad : (data)=>{
        return axios.put(`/functions/ValoracionUnidad`,data);
    }
}

export default ValoracionDeUnidadService