import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import Llamada from '../../../Services/Cartera/Llamadas';
import { EtapaBandera, message, pageRows } from '../../../Utils/functions'
import { setGruaSlice } from '../../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import DataTable from '../../../Components/datatable/DataTable';
import ConfirmResponsabilidadModal from '../../../Components/Modal/ConfirmResponsabilidadModal';
import Garantias from '../../../Services/Cartera/Garantias';
import CardReparacion from './CardReparacion';
import { GarantiaReparacionSlice, setIdGarantiaReparacion } from '../../../Redux/Slice/reparacionSlice';
import { GarantiaDevolucionSlice, setIdGarantiaDevolucion } from '../../../Redux/Slice/devolucionSlice';
import documentsHelper from '../../../Utils/documents';

const CardDiagnostico
    = React.forwardRef(({ ...props }, ref) => {
        const { generalId } = props

        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { FolioGarantia, GarantiaId, GeneralId} = useSelector((state) => state.GarantiaSlice);
        const { fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numMotor,
            numContrato,
            producto,
            cliente,
            usoUnidad,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);

        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        let { idllamada } = useParams();

        let initial = {
            garantiaId: GarantiaId,
            garantiaDiagnosticoId: "",
            garantiaRecepcionId: "",
            folioDiagnostico: 9,
            fechaDiagnostico: "",
            comentariosDiagnostico: null,
            aplicaGarantia: null,
            excedioTiempo: null,
            tiempoEstimadoReparacion: null,
            concluido: null,
            usuarioAlta: "",
            usuarioAltaNombre: "",
            usuarioActualiza: null,
            usuarioActualizaNombre: null,
            fechaActualiza: null,
            diagnosticoStatus: "",
            diagnosticoStatusId: "",
            status: null,
            clienteNotificado: null,
            aceptaTiempoReparacion: null,
            garantiaMotivoNoAceptaReparacionId: null,
            garantiaMotivoNoAceptaAmpliarDiagnosticoId: null,
            comentariosCliente: null,
            cambioDevolucion: null,
            comentariosCambioDevolucion: null,
            motivoResponsabilidadCliente: null,
            fechaInicio: null,
            fechaConclusion: null,
            diasDiagnostico: '',
            usuarioNotificaNombre: null,
            aceptaAmpliacion: null,
            notificacionCliente: false,
            comentariosAmpliacion: null
        }

        const [disabled, setDisabled] = useState(true);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });


        const [statusResponsabilidad, setStatusResponsabilidad] = useState(null);
        const [concluida, setConcluida] = useState(false);
        const [aplicaGarantia, setAplicaGarantia] = useState(null);
        const [motivosRechazo, setMotivosRechazo] = useState([]);
        const [diagnosticoStatus, setDiagnosticoStatus] = useState([]);
        const [diagnosticoStatusString, setDiagnosticoStatusString] = useState([]);
        const [ampliacion, setAmpliacion] = useState(null);
        const [statusSimple, setStatusSimple] = useState(0);
        const [verDiagnostico, setVerDiagnostico] = useState(false);
        const [etapaFinalizado, setEtapaFinalizado] = useState(false);

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const circleStyle = {
            width: '10px',
            height: '10px',
            background: 'yellow',
            borderRadius: '50%',
            marginRight: '10px', // Espacio entre el círculo y el texto
        };

        const opcionesBool = [
            { value: 1, label: 'Si' },
            { value: 0, label: 'No' }
        ];

        useEffect(() => {
            getEtapa()
            getDiagnosticoStatus();
            getDiagnostico();
            getMotivosRechazo();
        }, []);

        React.useImperativeHandle(ref, () => ({
            getDiagnostico
        }));

        const getEtapa = async () => {
            EtapaBandera("kGarantia", "eDiagnost", idllamada).then(acabado => {
                setEtapaFinalizado(acabado)
            }).catch(error => {
                // Manejar errores si la promesa es rechazada
            });
        }

        const getDiagnosticoStatus = async () => {
            let tmpList = [];
            await Catalogo.getDropdownsByTable('Catalogos.DiagnosticoStatus')
                .then(resp => {
                    resp.data.forEach(item => {
                        tmpList.push({
                            value: item.DiagnosticoStatusId,
                            label: item.DiagnosticoStatus
                        });
                    });
                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setDiagnosticoStatus(tmpList);
                })
                .catch(err => {
                    console.error(err);
                })
        };

        const getMotivosRechazo = async () => {
            let tmpList = [];
            await Catalogo.getDropdownsByTable('Catalogos.GarantiaMotivoNoAceptaReparacion')
                .then(resp => {
                    resp.data.forEach(item => {
                        tmpList.push({
                            value: item.GarantiaMotivoNoAceptaReparacionId,
                            label: item.GarantiaMotivoNoAceptaReparacion
                        });
                    });
                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setMotivosRechazo(tmpList);
                })
                .catch(err => {
                    console.error(err);
                })
        };

        const downloadPDF = async () => {
            if (initialValue.diagnosticoStatus.toLowerCase() === 'pendiente') {
                setLoading(true);

                const initialSave = {};
                initialSave.GarantiaId = initial.garantiaId;
                initialSave.diagnosticoStatusId = "10";
                initialSave.fechaInicio = new Date();

                await Garantias.updateDiagnostico(initialSave)
                    .then(resp => {
                        console.log(resp.data);
                        setDisabled(false);
                        setInitialValue(resp.data);
                        setDiagnosticoStatusString(resp.data.diagnosticoStatus);
                        toast.success(message("¡Operación exitosa!", "Se actualizó el diagnóstico correctamente"));

                        //No actualiza los datos de initialValue al instante, por eso se pone un timeout
                        setTimeout(() => {
                            const requestBody = {
                                nombrePlantilla: "DiagnosticoFalla",
                                parametros: {
                                    "<<VARIABLE_VIN>>": vin,
                                    "<<VARIABLE_CONTRATO>>": numContrato,
                                    "<<VARIABLE_FECHA>>": resp.data.fechaInicio.split('T')[0],
                                    "<<VARIABLE_FOLIO>>": resp.data.folioDiagnostico,
                                    "<<VARIABLE_CLIENTE>>": cliente,
                                    "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                                    "<<VARIABLE_HORA_INICIO>>": resp.data.fechaInicio.split('T')[1].split('.')[0],
                                    "<<VARIABLE_DATAMOVIL>>": "",
                                    "<<VARIABLE_DIRECCION_UBICACION>>": ""
                                }
                            };

                            Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                                //Pendiente de revisar por que no jala con el file-server
                                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                            }).catch(err => {
                                console.error(err);
                            });
                        }, 500);
                    })
                    .catch(err => {
                        toast.error(message("¡Operación fallida!", "No se pudo actualizar el diagnóstico"));
                        console.error(err);
                    })
                    .finally(() => setLoading(false));
            } else {
                const requestBody = {
                    nombrePlantilla: "DiagnosticoFalla",
                    parametros: {
                        "<<VARIABLE_VIN>>": vin,
                        "<<VARIABLE_CONTRATO>>": numContrato,
                        "<<VARIABLE_FECHA>>": initialValue.fechaDiagnostico.split('T')[0],
                        "<<VARIABLE_FOLIO>>": initialValue.folioDiagnostico,
                        "<<VARIABLE_CLIENTE>>": cliente,
                        "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                        "<<VARIABLE_HORA_INICIO>>": initialValue.fechaInicio?.split('T')[1].split('.')[0],
                        "<<VARIABLE_DATAMOVIL>>": "",
                        "<<VARIABLE_DIRECCION_UBICACION>>": ""
                    }
                };


                console.log(requestBody)
                return false;
                await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                    //Pendiente de revisar por que no jala con el file-server
                    documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                }).catch(err => {
                    console.error(err);
                });
            }
        }

        const getDiagnostico = async () => {
            setLoading(true)
            await Garantias.getDiagnostico(GarantiaId,vin)
                .then(resp => {
                    setVerDiagnostico(true)
                    if (resp.data.fechaDiagnostico !== null && (!resp.data.clienteNotificado || resp.data.clienteNotificado === null)) {
                        resp.data.diasDiagnostico = calculateTime(resp.data.fechaDiagnostico);
                    }
                    if (resp.data.fechaInicio) {
                        setDisabled(false);
                    }
                    setDiagnosticoStatusString(resp.data.diagnosticoStatus);
                    setInitialValue({
                        garantiaId: GarantiaId,
                        garantiaDiagnosticoId: resp.data.garantiaDiagnosticoId,
                        garantiaRecepcionId: resp.data.garantiaRecepcionId,
                        folioDiagnostico: resp.data.folioDiagnostico,
                        fechaDiagnostico: resp.data.fechaDiagnostico,
                        comentariosDiagnostico: resp.data.comentariosDiagnostico,
                        aplicaGarantia: resp.data.aplicaGarantia == true ? 1 : (resp.data.aplicaGarantia !== null ? 0 : null),
                        excedioTiempo: resp.data.excedioTiempo,
                        tiempoEstimadoReparacion: resp.data.tiempoEstimadoReparacion,
                        clienteNotificado: resp.data.clienteNotificado,
                        usuarioAlta: resp.data.usuarioAlta,
                        usuarioAltaNombre: resp.data.usuarioAltaNombre,
                        usuarioActualiza: resp.data.usuarioActualiza,
                        usuarioActualizaNombre: resp.data.usuarioActualizaNombre,
                        fechaActualiza: resp.data.fechaActualiza,
                        diagnosticoStatus: resp.data.diagnosticoStatus,
                        diagnosticoStatusId: resp.data.diagnosticoStatusId,
                        status: resp.data.status,
                        concluido: resp.data.concluido,
                        aceptaTiempoReparacion: resp.data.aceptaTiempoReparacion ? 1 : (resp.data.aceptaTiempoReparacion !== null ? 0 : null),
                        garantiaMotivoNoAceptaReparacionId: resp.data.garantiaMotivoNoAceptaReparacionId,
                        comentariosCliente: resp.data.comentariosCliente,
                        cambioDevolucion: resp.data.cambioDevolucion,
                        comentariosCambioDevolucion: resp.data.comentariosCambioDevolucion,
                        motivoResponsabilidadCliente: resp.data.motivoResponsabilidadCliente,
                        fechaInicio: resp.data.fechaInicio,
                        fechaConclusion: resp.data.fechaConclusion,//resp.data.clienteNotificado?
                        diasDiagnostico: resp.data.diasDiagnostico,
                        usuarioNotificaNombre: resp.data.usuarioNotificaNombre,
                        aceptaAmpliacion: resp.data.aceptaAmpliacion ? 1 : (resp.data.aceptaAmpliacion !== null ? 0 : null),
                        garantiaMotivoNoAceptaAmpliarDiagnosticoId: resp.data.garantiaMotivoNoAceptaAmpliarDiagnosticoId,
                        notificacionCliente: resp.data.notificacionCliente,
                        comentariosAmpliacion: resp.data.comentariosAmpliacion
                    });
                })
                .catch(err => {

                })
                .finally(() => setLoading(false));
        }

        const calculateTime = (inicia) => {
            let fecha = new Date(inicia + 'Z');
            let today = new Date();
            let time = Math.abs(today - fecha);
            let days = Math.ceil(time / (1000 * 60 * 60 * 24));
            days = days - 1;
            let strDate = days === 1 ? days + ' día' : days + ' días';
            return strDate;
        }

        const devolucionCrear = () => {            
            let params = {
                origen: "garantia",
                origenId: GarantiaId,                
                cambioDevolucionStatusId: 10,
                boletinado: 0,
            };

            Garantias.garantiaCambioDevolucionCrear(params).then(resp => {
                toast.success(message("¡Operación exitosa!", "Se creo devolución"));
                dispatch(setIdGarantiaDevolucion({ data: resp.data.garantiaCambioDevolucionId }))
            }).catch(error => {
                // toast.error(message("¡Operación fallida!", "Error al crear devolución"));
            })
        }


        const submitForm = async (values) => {
            if (values.notificacionCliente && values.aceptaAmpliacion === null || values.aceptaAmpliacion === "") {
                toast.error(message("¡Atención!", "Selecciona una opción de ampliación"));
                return;
            }

            if (values.notificacionCliente && values.aceptaAmpliacion === 0 && values.garantiaMotivoNoAceptaAmpliarDiagnosticoId === null) {
                toast.error(message("¡Atención!", "Selecciona un motivo de rechazo"));
                return;
            }

            if (values.clienteNotificado && values.aplicaGarantia === 1 && values.aceptaTiempoReparacion === null || values.aceptaTiempoReparacion === "") {
                console.log("Selecciona Una opción de ampliaciión")
                toast.error(message("¡Atención!", "Selecciona opción de tiempo de reparación"));
                return;
            }

            if (values.clienteNotificado && values.aplicaGarantia === 1 && values.aceptaTiempoReparacion === 0 && values.garantiaMotivoNoAceptaReparacionId === null) {
                toast.error(message("¡Atención!", "Selecciona un motivo de rechazo"));
                return;
            }

            let status = values.diagnosticoStatusId

            if (values.notificacionCliente && !values.aceptaAmpliacion && !initialValue.notificacionCliente && initialValue.diagnosticoStatusId === '20') {
                status = "60"
                //Aqui inserta una devolucion
                devolucionCrear()
            }


            if (values.concluido && values.diagnosticoStatusId === "35" && values.clienteNotificado) {
                if (values.clienteNotificado && values.aceptaTiempoReparacion) {
                    status = "40"
                } else {
                    status = "50"
                    //Aqui inserta una devolucion
                    devolucionCrear()
                }
                // console.log("Entre al segundo")
            }

            
            if (values.notificacionCliente && values.aceptaAmpliacion && !initialValue.notificacionCliente) {
                status = "30"
                // console.log("Entre al tercero")
            }

            if (values.concluido && values.diagnosticoStatusId === "10"
                || values.concluido && values.diagnosticoStatusId === "20"
                || values.concluido && values.diagnosticoStatusId === "30") {  //estiy en un estatus 10,20 o 30
                status = "35"
                //Que mande a llamar tu api de correos 
                //enviarCorreo()
                // console.log("Entre al primero")
            }

            values.diagnosticoStatusId = status
            
            await Garantias.updateDiagnostico(values)
                .then(resp => {
                    // setDisabled(false);
                    toast.success(message("¡Operación exitosa!", "Se actualizó el diagnóstico correctamente"));

                    if (Number(resp.data.diagnosticoStatusId) !== 50 || Number(resp.data.diagnosticoStatusId) !== 60 && values.clienteNotificado && values.concluido) {
                        insertarReparacion(values)
                    }

                    setInitialValue(resp.data);
                    setDiagnosticoStatusString(resp.data.diagnosticoStatus);
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "No se pudo actualizar el diagnóstico"));
                    console.error(err);
                })
                .finally(() => setLoading(false));


            const contenido = {
                VARIABLE_CONTRATO: numContrato,
                VARIABLE_TECNICO: values.usuarioAltaNombre,
                VARIABLE_TIPO_REPORTE: '',
                VARIABLE_DIAGNOSTICO: values.comentariosDiagnostico,
                VARIABLE_APLICA: values.aplicaGarantia,
                VARIABLE_TIEMPO: values.tiempoEstimadoReparacion,
            }

            if (!values.clienteNotificado && !values.concluido) {
                console.log("Enviando correo");
                Garantias.creardatosenviar({
                    modulo: 'Diagnóstico de Unidad',
                    tipoCorreoId: 13,
                    contenido: JSON.stringify(contenido)
                }).catch(err => {
                    console.log("Error al enviar correo Diagnóstico de Unidad: ", err);
                })
            }

            const contenidoEmail = {
                VARIABLE_CONTRATO: numContrato
            }

            if (values.concluido && Number(values.aceptaAmpliacion) === 1) {
                Garantias.creardatosenviar({
                    modulo: 'Reparación Aceptada',
                    tipoCorreoId: 14,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo de Reparación Aceptada: ", err);
                })
            } else if (values.concluido && Number(values.aceptaAmpliacion) === 0) {
                Garantias.creardatosenviar({
                    modulo: 'Reparación Rechazada',
                    tipoCorreoId: 15,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo de Reparación Rechazada: ", err);
                })
            }

            if (values.clienteNotificado) {
                Garantias.creardatosenviar({
                    modulo: 'Reparación Concluida',
                    tipoCorreoId: 16,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo de Reparación Concluida: ", err);
                })
            }
        }
        /*
                const enviarCorreo = () => {
                    let data = {
                        modulo: "Diagnóstico",
                        "tipoCorreoId": 13,
                        contenido: `
                        {
                            VARIABLE_CONTRATO : '${numContrato}',
                    VARIABLE_TECNICO: '${initialValue.usuarioAltaNombre}',
                    VARIABLE_TIPO_REPORTE: 'Diagnóstico de Unidad',
                    VARIABLE_DIAGNOSTICO: '1',
                    VARIABLE_APLICA: 1,
                    VARIABLE_TIEMPO: 2,
                }`
                    }
                    Garantias.creardatosenviar(data).then(res => {
                        toast.success(message("¡Operación exitosa!", "Se registro el correo"));
                    }).catch(error => {
                        toast.error(message("¡Operación fallida!", "Error al tratar de registrar el correo"));
                    })
                }
                */

        const insertarReparacion = (data) => {
            //reparacionStatusId
            // alert("Se va ainsertar a reparacion")
            // return ;
            let dataReparacion = {
                "garantiaId": data.garantiaId,
                "reparacionStatusId": 0
            }
            Garantias.reparacionCrear(dataReparacion).then(resp => {
                toast.success(message("¡Operación exitosa!", "Reparación agregada correctamente"));
                dispatch(setIdGarantiaReparacion({ data: resp.data.garantiaReparacionId }))
            })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "No se pudo insertar la reparación"));
                    console.error(err);
                })

        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const validate = Yup.object({
            tiempoEstimadoReparacion: Yup.number()
            .typeError('Debe contener solo números')
            .integer()
            .min(1, `Debe ser mayor a 0`)
            .required("El campo es requerido."),
            aplicaGarantia: Yup.mixed()
            .required("El campo es requerido."),
            comentariosDiagnostico: Yup.mixed()
            .required("El campo es requerido.")
        });

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <ConfirmResponsabilidadModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />

                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        validationSchema={validate}
                        initialValues={initialValue}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <>
                                {
                                    verDiagnostico &&

                                    <div className='row mx-md-4 my-4' >
                                        <div className="col-12 px-md-3">
                                            <div className="p-4 white-wrapper mb-2">

                                                <div className="row mt-2 px-4 mb-1" data-bs-toggle="collapse" href="#collapseDiagnostico" role="button" aria-expanded="false" aria-controls="collapseDiagnostico" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                    <div className="col-11">
                                                        <h6><span className={initialValue.folioDiagnostico !== 0 && !initialValue.clienteNotificado ? 'semaforo semaforo-yellow' : (initialValue.folioDiagnostico !== 0 && initialValue.clienteNotificado ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Diagnóstico</strong></h6>
                                                    </div>
                                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                    </div>
                                                    <div className='col-12'>
                                                        <small style={{ marginLeft: '17px', marginTop: '0px' }}>{diagnosticoStatusString}</small>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseDiagnostico">
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <small><strong>Generar Documento</strong></small>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            onClick={() => {
                                                                                navigate(`/garantias/datadocs/kGarantia/eDiagnost/${FolioGarantia}/${idllamada}`, {
                                                                                    state: values,
                                                                                });
                                                                            }}
                                                                            style={{ background: etapaFinalizado ? '#277917' : '' }} className={etapaFinalizado ? 'btn' : 'btn btn-gray'}
                                                                            disabled={!isValid || (!isValid && disabled) || disabled} >
                                                                            <i className="icon-blue fa fa-file"></i> Subir documento
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button className='btn' disabled={!isValid || (!isValid && disabled) || disabled ||  values.fechaConclusion !== null && initialValue.clienteNotificado === true } onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Documento de diagnóstico de falla</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' onClick={() => downloadPDF()}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <small><strong>Diagnóstico de unidad</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Técnico" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        {/* <div className="col-6 col-lg-3">
                                                            <span>Estatus diagnóstico</span>
                                                            <div style={containerStyle}>
                                                                <div style={circleStyle}></div>
                                                                {diagnosticoStatusString}
                                                            </div>

                                                        </div> */}
                                                        <div className="col-3">
                                                            <TextField
                                                                id="tiempoEstimadoReparacion"
                                                                disabled={disabled || values.fechaConclusion !== null || initialValue.notificacionCliente === true && !initialValue.aceptaAmpliacion}
                                                                label="Tiempo estimado de reparación"
                                                                name="tiempoEstimadoReparacion"
                                                                type="text" holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("tiempoEstimadoReparacion", event.target.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="diasDiagnostico" disabled={true} label="Tiempo de diagnóstico" name="diasDiagnostico" type="text" holder="" />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="aplicaGarantia"
                                                                label="Aplica garantía"
                                                                disabled={disabled || values.fechaConclusion !== null || initialValue.notificacionCliente === true && !initialValue.aceptaAmpliacion}
                                                                name="aplicaGarantia"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("aplicaGarantia", event.value);
                                                                    setAplicaGarantia(event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField
                                                                id="comentariosDiagnostico"
                                                                label="Diagnóstico"
                                                                disabled={disabled || values.fechaConclusion !== null || initialValue.notificacionCliente === true && !initialValue.aceptaAmpliacion}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosDiagnostico"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosDiagnostico", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={disabled || initialValue.notificacionCliente === true && !initialValue.aceptaAmpliacion} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                                // setConcluida(event.target.checked);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Diagnóstico concluido</label>
                                                        </div>
                                                    </div>
                                                    {/* || initialValue.notificacionCliente === true */}
                                                    {
                                                        initialValue.excedioTiempo &&
                                                        <>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-8">
                                                                    <small><strong>Ampliación de diagnostico</strong></small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-3">
                                                                    <SelectField id="aceptaAmpliacion" label="Ampliación de diagnóstico" disabled={disabled || initialValue.notificacionCliente} name="aceptaAmpliacion" items={opcionesBool} onChange={(event) => {
                                                                        setFieldValue("aceptaAmpliacion", event.value);
                                                                    }} />
                                                                </div>
                                                                {
                                                                    values.aceptaAmpliacion === 0 &&
                                                                    <div className="col-3">
                                                                        <SelectField
                                                                            id="garantiaMotivoNoAceptaAmpliarDiagnosticoId"
                                                                            label="Motivo rechazo"
                                                                            disabled={disabled || initialValue.notificacionCliente}
                                                                            name="garantiaMotivoNoAceptaAmpliarDiagnosticoId"
                                                                            items={motivosRechazo} onChange={(event) => {
                                                                                setFieldValue("garantiaMotivoNoAceptaAmpliarDiagnosticoId", event.value);
                                                                            }} />
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-6">
                                                                    <TextAreaField id="comentariosAmpliacion" label="Comentarios" disabled={disabled || initialValue.notificacionCliente} className="form-control col-12" rows="3" name="comentariosAmpliacion" type="text" holder="Escribe"
                                                                        onChange={(event) => {
                                                                            setFieldValue("comentariosAmpliacion", event.target.value);

                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input" disabled={disabled || initialValue.notificacionCliente} type="checkbox" name="notificacionCliente" onClick={(event) => {
                                                                        setFieldValue("notificacionCliente", event.target.value);
                                                                        setConcluida(event.target.checked);
                                                                    }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Notificación a cliente de ampliación</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <small><strong>Notificación al cliente</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioNotificaNombre"
                                                                disabled={true} label="Usuario notificó al cliente" name="usuarioNotificaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                    setFieldValue("usuarioNotificaNombre", event.target.value);
                                                                }} />
                                                        </div>

                                                        {!initialValue.clienteNotificado &&
                                                            <>
                                                                <div className="col-3 col-lg-3">
                                                                    <span>Fecha notificó al cliente</span>
                                                                    <div style={containerStyle}>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {initialValue.clienteNotificado &&
                                                            <>
                                                                <div className="col-3 col-lg-3">
                                                                    <span>Fecha notificó al cliente</span>
                                                                    <div style={containerStyle}>
                                                                        {getDateFormat(initialValue.fechaConclusion, "")}
                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-3">
                                                                    <TextField id="fechaConclusion" disabled={true} label="Fecha notificó al cliente" name="fechaConclusion" type="text" holder="Escribe" onChange={(event) => {
                                                                        setFieldValue("fechaConclusion", event.target.value);
                                                                    }} />
                                                                </div> */}
                                                            </>
                                                        }

                                                        {
                                                            aplicaGarantia == 1 &&
                                                            <>
                                                                <div className="col-3">
                                                                    <SelectField id="aceptaTiempoReparacion" label="Acepta tiempo reparación"
                                                                        disabled={!values.concluido || values.fechaConclusion == null || initialValue.clienteNotificado} name="aceptaTiempoReparacion" items={opcionesBool} onChange={(event) => {
                                                                            setFieldValue("aceptaTiempoReparacion", event.value);
                                                                        }} />
                                                                </div>
                                                            </>
                                                        }

                                                        {values.aceptaTiempoReparacion === 0 &&
                                                            <div className="col-3">
                                                                <SelectField id="garantiaMotivoNoAceptaReparacionId" label="Motivo rechazo" disabled={!values.concluido || values.fechaConclusion == null || initialValue.clienteNotificado} name="garantiaMotivoNoAceptaReparacionId" items={motivosRechazo} onChange={(event) => {
                                                                    setFieldValue("garantiaMotivoNoAceptaReparacionId", event.value);
                                                                }} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosCliente" label="Comentarios del cliente" disabled={!values.concluido || values.fechaConclusion == null || initialValue.clienteNotificado} className="form-control col-12" rows="3" name="comentariosCliente" type="text" holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosCliente", event.target.value);

                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={!values.concluido || values.fechaConclusion == null} type="checkbox" name="clienteNotificado" onClick={(event) => {
                                                                setFieldValue("clienteNotificado", event.target.value);
                                                                setConcluida(event.target.checked);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                        </div>

                                                        <div className="col-12 col-lg-6">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaInicio !== null &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                                    }
                                                                </div>

                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaConclusion !== null &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                        }
                    </Formik>
                </div>
                {/* {
                    initialValue.aceptaTiempoReparacion !== null && 
                    initialValue.aceptaTiempoReparacion === true && 
                    initialValue.concluido &&
                } */}
                {
                    (initialValue.concluido &&
                    initialValue.clienteNotificado) &&
                    <CardReparacion
                        diagnostico={initialValue.comentariosDiagnostico}
                    />
                }
            </>
        )
    })
export default CardDiagnostico