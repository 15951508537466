import React, { useState, useRef, useEffect } from 'react';
import { Field, Formik } from 'formik';
import Select from 'react-select'

import { SelectField } from '../../../../Components/SelectField';
import DataTable from '../../../../Components/datatable/DataTable';

const ListadoSeguimientoTable = ({ data }) => {

    const refFormik = useRef();

    const initial = {
        buscar: "",
        tiposiniestro: "",
        estatus: "",
        page: 1,
        rows: 10
    };

    const [currenPage, setCurrenPage] = useState(1);

    const columns = [
        { field: 'folio', headerName: 'Folio Siniestro Dataware' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'anio', headerName: 'Año' },
        { field: 'tiposiniestro', headerName: 'Tipo Siniestro' },
        { field: 'fechareporte', headerName: 'Fecha del reporte' },
        { field: 'tiempoproceso', headerName: 'Tiempo en proceso' },
        { field: 'proceso', headerName: 'Proceso' }
    ];

    const handlePageClick = event => {
        // const page = event.selected + 1;
        // setCurrenPage(page);
        // seguimientoDatamovil({ ...initial, page });
    };

    return (
        <section className='pb-4 pt-2'>
            <div className='mx-4 my-4'>
                <Formik innerRef={refFormik} enableReinitialize initialValues={initial}>
                    {({ setFieldValue, values }) => (
                        <>
                            <div className="row">
                                <div className="col-md-2 inner-addon right-addon">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <i className="glyphicon fas fa-search"></i>
                                    <Field
                                        name="buscar"
                                        className="form-control mb-4"
                                        placeholder="Buscar"
                                    />
                                </div>

                                <div className="col-md-2">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <Select options={[]}
                                        placeholder='Proceso' />

                                </div>
                                <div className="col-md-2">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <Select options={[]}
                                        placeholder='Concepto' />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <DataTable
                                        column={columns}
                                        data={data?.data}
                                        currentPage={currenPage - 1}
                                        paginate={true}
                                        pageCount={data.totalRows / 10}
                                        handlePageClick={handlePageClick}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </section>
    )
}

export default ListadoSeguimientoTable