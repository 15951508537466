import axios from '../../Utils/ApiService';

const HorariosCalendarioService = {
    getSeguimiento: ({ buscar, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `buscar=${buscar}&`; 
        query += `page=${page}&rows=${rows}`;
        return axios.get(`/functions/seguimientoServiciosDatamovilHorarios?${query}`);
    },
    getSeguimientoUbicaciones: ({ buscar, calendarioTipoId, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `buscar=${buscar}&`; 
        query += `calendarioTipoId=${calendarioTipoId}&page=${page}&rows=${rows}`;
        return axios.get(`/functions/seguimientoServiciosUbicacionDetalle?${query}`);

    }
}

export default HorariosCalendarioService;