import { Formik } from "formik";
import { TextField } from "../../../Components/TextField";
import AmpliacionExpedientesHook from "../../../Hooks/ProcesosCierre/AmpliacionExpedientesHook";
import DataTable from "../../../Components/datatable/DataTable";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import SolicitudAmpliacionExpedienteModal from "../../../Components/Modal/SolicitudAmpliacionExpedienteModal";

const ListadoAmpliacionesExpediente = () => {

    const {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail
    } = AmpliacionExpedientesHook();

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <>
                                <SolicitudAmpliacionExpedienteModal 
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            </>
                        }
                    </Modal>
                    <section className="mt-2 mx-2">
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Solicitud de ampliación expediente</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <i className="glyphicon fas fa-search"></i>
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                                setBuscar(event.target.value)
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            detailable={true}
                                            handleDetail={handleDetail}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default ListadoAmpliacionesExpediente;