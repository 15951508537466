import axios from '../../Utils/ApiService';

const EtapaService = {
    getAllTipoRegla:()=> {        
        return axios.get(`/functions/GetCSTiposDeReglas`);
    }, 
    getAllProducto:()=> {        
        return axios.get(`/catalogs/ComboSelectProducto`);
    }, 
    getAllRol:()=> {        
        return axios.get(`/functions/GetCSRolesTD`);
    }, 
    getAllTipoCompra:()=> {        
        return axios.get(`/catalogs/ComboSelectTipoCompra`);
    },    
    getAllEtapaById:(productoId,tipocompraId)=> {        
        return axios.get(`/catalogs/GetSCEtapaRDN?ProductoId=${productoId}&TipoCompraId=${tipocompraId}`);        
    },
    getAllEtapa:()=> {                
        return axios.get(`/catalogs/Etapa?page=1&rows=900`);        
    }, 
    getAllDocumentos:()=> {        
        return axios.get(`/functions/GetCSTipoDocumentoTD`);
    }, 
    getAllEtapaDestino:(etapaId,etapaACtualId,productoId,tipoCompraId)=> {        
        return axios.get(`/functions/GetCHLEtapasDestino?ReglaNegocioId=${etapaId}&EtapaId=${etapaACtualId}&ProductoId=${productoId}&TipoCompraId=${tipoCompraId}`);        
    }, 
    getAllGroups:(producto,tipoCompra)=> {        
        return axios.get(`/functions/AllGrupos?ProductoId=${producto}&TipoCompraId=${tipoCompra}`);
    }, 
    getAllEtapasbyGroup:(GroupId)=> {        
        return axios.get(`/functions/AllGrupoEetapas?GrupoId=${GroupId}`);
    }, 
    getAllEtapasSinAsignar : (producto,tipoCompra) =>{
        return axios.get(`/functions/AllEtapas?ProductoId=${producto}&TipoCompraId=${tipoCompra}`);
    },
    addEtapaGrupo:(grupoId,etapaId) =>{
        return axios.post(`/functions/AddEtapaGrupo?GrupoId=${grupoId}&EtapaId=${etapaId}`);
    },
    AddNewGroup:(producto,tipoCompra,nombreGrupo,grupoAnterior) =>{
        return axios.post(`/functions/AddGrupo?NombreGrupo=${nombreGrupo}&ProductoId=${producto}&TipoCompraId=${tipoCompra}&GrupoAnteriorId=${grupoAnterior}`);        
    },    
    DeleteGrupo:(producto,tipoCompra,grupoId) =>{
        return axios.delete(`/functions/DeleteGrupo?GrupoId=${grupoId}&ProductoId=${producto}&TipoCompraId=${tipoCompra}`);
    },
    DeleteEtapaGrupo:(grupoId,etapaId) =>{
        return axios.delete(`/functions/DeleteEtapaGrupo?GrupoId=${grupoId}&EtapaId=${etapaId}`);
    },    
    // ReglaEtapa
    getAllReglaEtapa:(productoId,tipoCompraId,etapaId)=> {        
        return axios.get(`/functions/AllReglasEtapas?ProductoId=${productoId}&TipoCompraId=${tipoCompraId}&EtapaId=${etapaId}`);
    },
    AddReglasEtapas:(data)=> {        
        return axios.post(`/functions/AddDescriptivo`,data);
    },
    UpdateReglasEtapas:(data)=> {        
        return axios.put(`/functions/UpdDescriptivo?ReglaNegocioId=${data.reglasNegocioId}`,data);
    },
    UpdateMotivo:(data)=>{
        return axios.put(`/functions/MotivoEtapa?Motivo=${data.motivo}&ProductoId=${data.producto}&TipoCompraId=${data.tipoCompra}`);
    },
    HistorialReglasEtapas:(page,rows,producto,tipoCompra,etapaId,fecha)=>{
        return axios.get(`/functions/HistorialReglasEtapas?Page=${page}&Rows=${rows}&ProductoId=${producto}&TipoCompraId=${tipoCompra}&EtapaId=${etapaId}&Fecha=${fecha}`);
    },
    //ReglaNegocio
    getAllReglaNegocio:(productoId,tipoCompraId,etapaId)=> {        
        return axios.get(`/functions/RNPorEtapa?ProductoId=${productoId}&TipoCompraId=${tipoCompraId}&EtapaId=${etapaId}`);
    },
    getAllTipoParametro:()=>{
        return axios.get(`/functions/CSParametros`);
    },
    getAllLapso:()=>{
        return axios.get(`/functions/CSLapso`);
    },
    getAllCondicion:()=>{
        return axios.get(`/functions/CSCondicion`);
    },
    getAllDocumento:()=>{
        return axios.get(`/functions/CSDocumento`);
    },
    getAllAccion:()=>{
        return axios.get(`/functions/CSAccion`);
    },
    getAllCSEtapa:()=>{
        return axios.get(`/functions/CSEtapa`);
    },  
    getAllReglaAutomaticaById:(id)=>{
        return axios.get(`/functions/GetReglasAutomaticasById?ReglaNegocioId=${id}`);
    }, 
    addReglaNegocio:(data)=>{
        return axios.post(`/functions/AddReglasNegocioPorEtapa`,data);
    },  
    addReglaNegocioAutomatica:(data)=>{
        return axios.post(`/functions/AddAutomatica`,data);
    },  
    reglaNegocioDetalle:(productoId,tipoCompraId,etapaId)=>{
        return axios.get(`/functions/RNPorEtapaDetalle?ProductoId=${productoId}&TipoCompraId=${tipoCompraId}&EtapaId=${etapaId}`);
    }, 
    updateReglaAutomatica:(data)=>{
        return axios.put(`/functions/UpdAutomatica?ReglaNegocioId=${data.reglaNegocioId}`,data);
    },
    updateReglaNegocio:(data)=>{
        return axios.put(`/functions/UpdReglasNegocioPorEtapa?ReglasNegocioId=${data.reglasNegocioId}&ProductoId=${data.productoId}&TipoCompraId=${data.tipoCompraId}&EtapaActualId=${data.etapaActualId}&Descripcion=${data.descripcion}&Status=${data.status}&ParametroId=${data.parametroId}&LapsoId=${data.lapsoId}&Tiempo=${data.tiempo}&CondicionId=${data.condicionId}&DocumentoId=${data.documentoId}&AccionId=${data.accionId}&EtapaConfigId=${data.etapaConfigId}`);
    },
    HistorialReglasNegocio:(page,rows,producto,tipoCompra,etapaId,fecha)=>{
        return axios.get(`/functions/RNHistorial?Page=${page}&Rows=${rows}&ProductoId=${producto}&TipoCompraId=${tipoCompra}&EtapaId=${etapaId}&Fecha=${fecha}`);
    },
    //REglas Tipo reversion
    AddReversion:(data)=>{
        return axios.post(`/functions/AddReversion`,data);
    },    
    UpdReversion:(data)=>{
        return axios.put(`/functions/UpdReversion?ReglaNegocioId=${data.reglasNegocioId}`,data);
    },  
    //RDN Bloqueo
    getAllEtapaCampo:(reglaNegocioId,etapaId)=> {        
        return axios.get(`/functions/GetCHLCamposObligatorios?ReglaNegocioId=${reglaNegocioId}&EtapaId=${etapaId}`);
    }, 
    getAllEtapaDoc:(reglaNegocioId,etapaId,prodId,tipocompraId)=> {        
        return axios.get(`/functions/GetCHLTipoDocumento?ReglaNegocioId=${reglaNegocioId}&EtapaId=${etapaId}&ProductoId=${prodId}&TipoCompraId=${tipocompraId}`);
    }, 
    AddTipoBloqueo:(data)=>{
        return axios.post(`/functions/AddBloqueo`,data);
    },  
    UpdTipoBloqueo:(data)=>{        
        return axios.put(`/functions/UpdBloqueo?ReglaNegocioId=${data.reglasNegocioId}`,data);
    },  
    // GetListadoReglas
    reglaAllNegocioByTipo:(productoId,tipoCompraId,TipoReglaId,etapaId)=>{
        return axios.get(`/functions/GetAllTipoDeReglas?ProductoId=${productoId}&TipoCompraId=${tipoCompraId}&TipoReglaId=${TipoReglaId}&EtapaId=${etapaId}`);
    }, 
    //RDN Automarticas
    getAllTipoCondicion:()=>{
        return axios.get(`/functions/GetCSTipoCondicion`);
    },
    getAllStatus:()=>{
        return axios.get(`/functions/GetCSStatusCondicionRDN`);
    },
    getAllTipoDetonante:()=>{
        return axios.get(`/functions/GetCSTipoDetonante`);
    },
    getAllCondicionalById:(tipo,etapaId,productoId,tipocompraId)=>{
        return axios.get(`/functions/GetCSDocumentoOCampo?TipoCondicionId=${tipo}&EtapaId=${etapaId}&ProductoId=${productoId}&TipoCompraId=${tipocompraId}`);
    },
    getAllDetonanteById:(tipo,etapaId)=>{
        return axios.get(`/functions/GetCSPlazoPorDetonante?TipoDetonanteId=${tipo}&EtapaId=${etapaId}`);
    },
    DeleteDocumentoEtapa:(id)=>{
        return axios.delete(`/functions/DelTipoDocumentoPorEtapaTD?RegistroId=${id}`);
    },
    DeleteDocumentoRol:(id)=>{
        return axios.delete(`/functions/DelTipoDocumentoPorRolTD?RegistroId=${id}`);
    },
    getAllSubMenuEtapas:(id,prodId)=>{
        return axios.get(`/functions/GetMenuEtapas?GeneralId=${id}&ProductoId=${prodId}`);
    },
}

export default EtapaService