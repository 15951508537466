import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';

const useHistorialContratos = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'solicitud', headerName: 'Solicitud', width: 170 },
        { field: 'contrato', headerName: 'Contrato', width: 250 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso Unidad', width: 280 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'procedenciaCliente', headerName: 'Procedencia del cliente', width: 150 },
        { field: 'origenCredito', headerName: 'Origen de crédito', width: 170 },
        { field: 'montoAFinanciar', headerName: 'Monto a financiar', width: 250 },
        { field: 'desembolsoInicial', headerName: 'Desembolso inicial', width: 150 },
        { field: 'montoEnganche', headerName: 'Monto del enganche', width: 250 },
        { field: 'aNombreDe', headerName: 'A nombre de', width: 250 },
        { field: 'etapa', headerName: 'Etapa', width: 150 },
        { field: 'motivo', headerName: 'Motivo', width: 100 },
        { field: 'comentarios', headerName: 'Comentarios', width: 100 },
        { field: 'fechaHora', headerName: 'Fecha y hora', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //Filtro
    const [DataEtapa, setDataEtapa] = useState([]);    
    const [tipoCompra, settipoCompra] = useState([]);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [DataSocioComercial, setDataSocioComercial] = useState([])
    const [claveTipoCompra, setclaveTipoCompra] = useState("");
    const [claveVendedor, setClaveVendedor] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [fechInicio, setFechInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const [ClaveUsoUnidad, setClaveUsoUnidad] = useState("")
    const [EtapaId, setEtapaId] = useState("")
    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [pageRows,claveTipoCompra,ClaveUsoUnidad,EtapaId,str, fechInicio, fechaFin])
    useEffect(() => {
        handleGetAllSelects();
    }, [])

    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetAllUsoUnidad(),handleGetAllEtapa(),handleGetTipoCompra()]).finally(() => {
            setLoading(false)
        });
    }
    const habdleGetAll = async (page) => {
        setLoading(true);
        await VehiculoConvertidoService.getAllHistorialContratos(page, pageRows,claveTipoCompra,ClaveUsoUnidad,EtapaId,str, fechInicio, fechaFin)
            .then(res => {
                console.log(res.data.data)

                res.data.data.forEach((data, idx) => {
                  res.data.data[idx].montoAFinanciar = formatMoney(data.montoAFinanciar);
                  res.data.data[idx].desembolsoInicial = formatMoney(data.desembolsoInicial);
                  res.data.data[idx].montoEnganche = formatMoney(data.montoEnganche);
                  if(res.data.data[idx].motivo===""){
                    res.data.data[idx].motivo = 'Tesorería y Condiciones'
                  }
                  
                });

                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    
    const formatMoney = (value) => {
      if(value === undefined){
          value = 0;
      }
      let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
      return money;
    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }   
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
          .then(res => {
            let NewData = [];
            NewData.push({
              value: "",
              label: "Todos"
            })
            res.data.data?.map(item => {
              NewData.push({
                value: item.usoUnidadId,
                label: item.usoUnidad
              })
            });
            setDataUsoUnidad(NewData);
          }).catch(e => {
    
          })
      }
    const habdleDownloadFile = async () => {
        setLoading(true);
        await VehiculoConvertidoService.DownloadHistorialContratos(claveTipoCompra,ClaveUsoUnidad,EtapaId,str, fechInicio, fechaFin)
            .then(res => {                
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `HistorialDeContratos-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllEtapa = async () => {
        setLoading(true);
        await EtapaService.getAllEtapa()
          .then(res => {
            let NewData = [];   
            NewData.push({
              value: "",
              label: "Todos"
            })  
            res.data.data?.map(item => {
              NewData.push({
                value: item.etapaId,
                label: item.etapa
              })
            });
            setDataEtapa(NewData);
            setLoading(false);
          }).catch(e => {
            setLoading(false);       
          })
      }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataSocioComercial,
        tipoCompra,
        setclaveTipoCompra,
        setClaveVendedor,
        setFechInicio,
        setFechaFin,
        DataEtapa,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setEtapaId     
    }
}

export default useHistorialContratos