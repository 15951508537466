import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import Llamada from '../../../Services/Cartera/Llamadas';
import { message, pageRows } from '../../../Utils/functions'
import { setGruaSlice } from '../../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import UbicacionResguardoCard from './UbicacionResguardo';


const GruaCard
    = (props) => {

        let initial = {
            EmpresaId: "",
            GruaAsignada: "",
            costoGrua: "",
            IVA: "",
            CostoInicial: "",
            Subtotal: "",
            Total: "",
            EstadoId: "",
            MunicipioId: "",
            ColoniaId: "",
            Calle: "",
            Numero: "",
            TallerAutorizado: "",
            ReferenciaUbicacion: "",
            FechaCitaCalendario: "",
            StatusCita: null,
            GarantiaId: "",
            GruasId: "",
            BloqueoIdG: null,
            gruaStatusId: null
        }

        useEffect(() => {
            GetGrua();
            HandleGetEstadoMx();
            HandleGetEmpresas();
            GetTalleres();

        }, []);

        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { FolioGarantia, GarantiaId, GeneralId } = useSelector((state) => state.GarantiaSlice);
        const { nombreYear, vin, nombreModelo, numContrato } = useSelector((state) => state.DataVinSlice);
        const { dayGrua, hourGrua, sucursalId, calendarioIdGrua } = useSelector((state) => state.GruasSlice);
        const [modalCosto, setModalCosto] = useState(false);
        const costoInputRef = useRef(0);
        const [talleres, setTalleres] = useState([]);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { id, resguardo, resguardoid } = useParams();


        const [initialValue, setInitialValue] = useState(initial);
        const [dataEmpresaId, setDataEmpresaId] = useState([]);
        const [dataEstado, setDataEstado] = useState([]);
        const [dataMunicipio, setDataMunicipio] = useState([]);
        const [dataColonia, setDataColonia] = useState([]);
        const [loading, setLoading] = useState(false);
        const [_bloqueoId, setBloqueoId] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [municipioDB, setMunicipioDB] = useState(0);
        const [coloniaDB, setColoniasDB] = useState(0);
        const [showNext, setShowNext] = useState(false);


        useEffect(() => {
            if (initialValue.EstadoId !== null && initialValue.EstadoId !== undefined && initialValue.EstadoId !== "") {
                HandleGetMunicpio(initialValue.EstadoId);
            }
        }, [initialValue.EstadoId]);


        useEffect(() => {
            if (initialValue.MunicipioId !== null && initialValue.MunicipioId !== undefined && initialValue.MunicipioId !== "") {
                HandleGetColonia(initialValue.MunicipioId);
            }
        }, [initialValue.MunicipioId]);

        const GetGrua = async () => {
            await Llamada.GetGruaByResguardoIdOrigen(resguardoid)
                .then(resp => {
                    const data = resp.data.data;
                    setInitialValue({
                        EmpresaId: data.empresaId,
                        GruaAsignada: data.gruaAsiganada,
                        EstadoId: data.estadoId,
                        MunicipioId: data.municipioId,
                        ColoniaId: data.coloniaId,
                        Calle: data.calle,
                        Numero: data.numero,
                        estatus: data.estatus,
                        folioGrua: data.folioGrua,
                        gruaStatusId: data.gruaStatusId,
                        TallerAutorizado: data.tallerAutorizado,
                        ReferenciaUbicacion: data.referenciaUbicacion,
                        BloqueoIdG: data.bloqueoId,
                        GruasId: data.gruasId,
                        costoGrua: data.costoGrua,
                        FechaCitaCalendario: data.fechaCitaCalendario,
                        GarantiaId: data.origenId,
                        GeneralId: data.generalId,
                    })
                    setMunicipioDB(data.municipioId);
                    setColoniasDB(data.coloniaId);

                    if (data.gruaStatusId === 30) {
                        setTimeout(() => {
                            setShowNext(true);
                        }, 1000);
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }

        useEffect(() => {
            if (hourGrua) {
                let splitday = dayGrua.split("T")
                let day = splitday[0].split("-")
                setInitialValue({
                    ...initialValue,
                    FechaCitaCalendario: day[2] + '-' + day[1] + '-' + day[0] + ' ' + hourGrua
                })
            }
        }, [hourGrua])


        const validateGrua = Yup.object({
            EmpresaId: Yup.string()
                .trim()
                .required("El campo es requerido."),
            GruaAsignada: Yup.string()
                .required("El campo es requerido."),
            EstadoId: Yup.mixed()
                .required("El campo es requerido."),
            // MunicipioId: Yup.mixed()
            //     .required("El campo es requerido."),
            // ColoniaId: Yup.mixed()
            //     .required("El campo es requerido."),
            Calle: Yup.string()
                .required("El campo es requerido."),
            // TallerAutorizado: Yup.string()
            //     .required("El campo es requerido."),
        });

        const ChangeModalCosto = (valor, values) => {
            costoInputRef.current.value = initialValue.costoGrua || "";
            if (values.EmpresaId) {
                setInitialValue({
                    ...initialValue,
                    EmpresaId: values.EmpresaId || "",
                    GruaAsignada: values.GruaAsignada || "",
                    costoGrua: values.costoGrua || "",
                    // CostoInicial: values.CostoInicial || "",
                    EstadoId: values.EstadoId || "",
                    MunicipioId: values.MunicipioId || "",
                    ColoniaId: values.ColoniaId || "",
                    Calle: values.Calle || "",
                    Numero: values.Numero || "",
                    TallerAutorizado: values.TallerAutorizado || "",
                    ReferenciaUbicacion: values.ReferenciaUbicacion || "",
                    // FechaCitaCalendario: values.FechaCitaCalendario || "",
                    StatusCita: values.StatusCita || ""
                });
            } else {

            }
            setModalCosto(valor);
        }
        const handleAddEditGrua = async (value) => {
            setLoading(true);
            try {
                const gruaData = {
                    "OrigenId": initialValue.origenId,
                    "GruasId": value.GruasId,
                    "EmpresaId": value.EmpresaId,
                    "GruaAsignada": value.GruaAsignada,
                    "costoGrua": value.costoGrua,
                    "subTotal": value.subTotal,
                    "iva": value.iva,
                    "total": value.total,
                    "EstadoId": value.EstadoId,
                    "MunicipioId": value.MunicipioId,
                    "ColoniaId": value.ColoniaId,
                    "Calle": value.Calle,
                    "Numero": value.Numero,
                    "TallerAutorizado": value.TallerAutorizado,
                    "ReferenciaUbicacion": value.ReferenciaUbicacion,
                    "FechaCitaCalendario": value.FechaCitaCalendario,
                    "horaCita": hourGrua,
                    "StatusCita": value.StatusCita,
                    "BloqueoId": value.BloqueoIdG,
                    "comentariosCancelacion": value.comentariosCancelacion
                }

                if (dayGrua !== "" && (!initialValue.BloqueoIdG || initialValue.BloqueoIdG === null) && dayGrua !== null) {
                    const dayParts = dayGrua.split("T")[0].split("-");
                    const dBloqueo = {
                        ubicacionid: sucursalId,
                        fecha: dayParts.join('-'),
                        calendarioid: calendarioIdGrua,
                        calendariotipoid: 2,
                        relacionid: value.GarantiaId,
                    };

                    try {
                        const bloqueoRes = await Llamada.crearCita(dBloqueo).catch(err => {
                            setBloqueoId(true)
                        });
                        const _bloqueoIdG = bloqueoRes.data.data.bloqueoId;
                        const gruaDataWithBloqueo = {
                            ...gruaData,
                            fechaCita: dayParts.join('-') + 'T09:11:29.119Z',
                            HoraCita: hourGrua,
                            BloqueoId: _bloqueoIdG,
                        };

                        const updateRes = await Llamada.UpdateGruabyBarantiaId(gruaDataWithBloqueo);
                        setInitialValue({
                            ...initialValue,
                            BloqueoIdG: _bloqueoIdG
                        })
                        setBloqueoId(true);
                        toast.success(message("Registro actualizado", updateRes.data.data.mensaje));
                    } catch (err) {
                        setBloqueoId(true);
                    }
                } else {
                    const updateRes = await Llamada.UpdateGruabyBarantiaId(gruaData);
                    toast.success(message("Registro actualizado!", updateRes.data.data.mensaje));
                }
            } catch (e) {
                if (e.response !== undefined) {
                    // toast.error(message("¡Error.!", e.response.data.message));
                } else {
                    // toast.error(message("¡Error.!", e.message));
                }
            } finally {
                setLoading(false);
            }
        };
        const GetTalleres = async () => {
            await Llamada.getTalleresSlice()
                .then(res => {
                    if (res.data.data === null) {

                    } else {
                        let dataRes = res.data.data
                        let data = []

                        dataRes.map(item => {
                            data.push({
                                value: item.tallerId,
                                label: item.taller
                            })
                        })
                        setTalleres(data)
                    }
                })
                .catch(e => {
                    console.error(e)
                })
        }
        const HandleGetEmpresas = async () => {
            await Llamada.GetEmpresas().then(res => {
                let Empresas = []
                res.data.data?.map(item => {
                    Empresas.push({
                        value: item.empresaId,
                        label: item.empresa
                    });
                });
                setDataEmpresaId(Empresas);
            })
        }
        const HandleGetEstadoMx = async () => {
            await Llamada.GetEstadoMx().then(res => {
                let Estados = []
                res.data.data?.map(item => {
                    Estados.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    });
                });
                setDataEstado(Estados);
                //setDataEmpresaId
            })
        }
        const HandleGetMunicpio = async (id) => {
            initialValue.MunicipioId = ""
            initialValue.MunicipioId = null
            setDataMunicipio([]);
            await Llamada.GetMunicipio(id).then(res => {
                let Municipios = []
                res.data.data?.map(item => {
                    Municipios.push({
                        value: item.municipioId,
                        label: item.municipio
                    });
                });
                setDataMunicipio(Municipios);
                //setDataEmpresaId
            })
        }
        const HandleGetColonia = async (id) => {
            if (id === municipioDB) {
                initialValue.ColoniaId = coloniaDB
            } else {
                initialValue.ColoniaId = ""
                initialValue.ColoniaId = null
            }
            setDataColonia([])
            await Llamada.GetColonia(id).then(res => {
                let Colonias = []
                res.data.data?.map(item => {
                    Colonias.push({
                        value: item.coloniaId,
                        label: item.colonia
                    });
                });
                setDataColonia(Colonias);
                //setDataEmpresaId
            })
        }
        const verModalAutorizarCita = (value) => {
            let data = {
                "llamadaId": null,
                "garantiaId": value.GarantiaId,
                "generalId": value.GeneralId,
                "folioCita": "",
                "folioLlamada": FolioLlamada,
                "folioGarantia": FolioGarantia,
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreModelo,
                "claveModelo": nombreModelo,
                "nombreModelo": "Versa",
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ejecutivoGarantia": initialValue.EjecutivoGarantia,
                "ubicacionId": "",
                "ubicacion": "Guadalupe",
                "tipoGarantiaId": null,
                "tipoGarantia": initialValue.TipoGarantia || "Sin Garantia",
                "clasificacionFallaId": null,
                "falla": initialValue.Falla || "Sin Falla",
                "gravedad": initialValue.Gravedad,
                "tipoReporteId": null,
                "tipoReporte": initialValue.TipoReporte || "Sin reporte",
                "telefono": "",
                "fechaCita": value.FechaCitaCalendario,
                "estatusCita": "",
                "_bloqueoId": ""
            }

            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 2, Origin: "Resguardo" })
        }

        const verCalendario = async (valor, values) => {
            dispatch(setGruaSlice(values))

            dispatch(setSchedule({
                url: `procesocierre/resguardos/${id}/${resguardo}/${resguardoid}`,
                contrato: numContrato,
                folioLlamada: resguardoid,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreModelo,
            }));

            navigate(`/garantias/citas/${valor}`)
        }
        const setValueCosto = (value) => {

            const costo = parseInt(value || 0);
            const iva = parseInt(costo) * 0.16;
            const subtotal = costo + iva;
            const total = costo + iva;

            setInitialValue({
                ...initialValue,
                costoGrua: total,
                IVA: `${iva.toFixed(2)}%`,
                Subtotal: formatter(subtotal),
                Total: formatter(total),
            });

        }
        const formatter = (values) => {
            let formattedAmount = new Intl.NumberFormat('es-ES', {
                style: 'decimal',
                currency: "MXN",
            }).format(values);
            return formattedAmount
        }
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            GetGrua={GetGrua}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Modal isOpen={modalCosto} color='#fff' width={560}>
                    <div className='p-4'>
                        <h5>
                            <b>
                                Costo recolección grua
                            </b>
                        </h5>
                        <div>
                            A continuación captura el costo de la recolección de grua.
                        </div>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th style={{ width: "50%" }}>Valor</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Costo grua
                                    </td>
                                    <td>
                                        $<input type="text" id="costoInput"
                                            ref={costoInputRef}
                                            onKeyPress={(event) => {
                                                const charCode = event.which ? event.which : event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    event.preventDefault(); // Evitar la entrada si no es un número
                                                }
                                            }}
                                            onChange={(event) => { setValueCosto(event.target.value); }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Subtotal
                                    </td>
                                    <td>
                                        ${initialValue.Subtotal}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        IVA
                                    </td>
                                    <td>
                                        16%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Total
                                    </td>
                                    <td>
                                        ${initialValue.Total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='row justify-content-end'>
                            <div className='col-md-3'>
                                <button className="btn btn-outline col-12" onClick={(e) => { setValueCosto("0"); ChangeModalCosto(false, {}); }}>Cancelar</button>
                            </div>
                            <div className='col-md-3 text-end'>
                                <button className="btn saveInfo col-12" onClick={(e) => ChangeModalCosto(false, {})}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        initialValues={initialValue}
                        validationSchema={validateGrua}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <>
                                <div className='row mx-md-4 my-4' >
                                    <div className="col-12 px-md-3">
                                        <div className="p-4 white-wrapper mb-2">
                                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseGrua" role="button" aria-expanded="false" aria-controls="collapseGrua" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                <div className="col-11">
                                                    <h6><span className={initialValue.StatusCita === null ? 'semaforo semaforo-default' : (initialValue.StatusCita === 2 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Información de grua - {initialValue.folioGrua}</strong></h6>
                                                </div>
                                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                </div>
                                                <div className='col-12'>
                                                    <small>{initialValue.StatusCita == 2 ? 'Autorizado' : ''}</small>
                                                </div>
                                            </div>
                                            <div className="collapse" id="collapseGrua">
                                                <div className="separator"></div>


                                                {/* {Object.keys(initialValue).map((key) => (
                                                    <div key={key}>
                                                        <strong>{key}:</strong> {initialValue[key]}
                                                    </div>
                                                ))} */}


                                                <div className="row mt-2 px-4">
                                                    <div className="col-10">
                                                        <small><strong>Asignación de grua</strong></small>
                                                    </div>
                                                    <div className="col-2 text-end">
                                                        <button className="btn col-12 saveInfo"
                                                            onClick={(e) => handleAddEditGrua(values)} disabled={(!(isValid) || !values.GruaAsignada || values.StatusCita == 'Autorizada' || initialValue.BloqueoIdG || values.BloqueoIdG || initialValue.gruaStatusId === 30)}>Guardar</button>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField id="EmpresaId" label="Empresa" name="EmpresaId" items={dataEmpresaId} onChange={(event) => {
                                                            setFieldValue("EmpresaId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                                        <TextField id="GruaAsignada" label="Grua Asignada" name="GruaAsignada" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("GruaAsignada", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField id="costoGrua" label="Costo para cliente" name="costoGrua" type="text" holder="Escribe" disabled onChange={(event) => {
                                                            setFieldValue("costoGrua", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-2 mt-4 text-end">
                                                        <button className="btn col-12 saveInfo" disabled={(!initialValue.GruasId || initialValue.BloqueoIdG || values.BloqueoIdG) && initialValue.costoGrua !== null} onClick={(e) => ChangeModalCosto(true, values)}>Registrar costo</button>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <small><strong>Indicar ubicación de recolección y destino</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 mb-2 px-4">
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        <SelectField id="EstadoId" label="Estado" name="EstadoId" items={dataEstado} onChange={(event) => {
                                                            setFieldValue("EstadoId", event.value);
                                                            HandleGetMunicpio(event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        <SelectField id="MunicipioId" label="Municipio" name="MunicipioId" items={dataMunicipio} onChange={(event) => {
                                                            setFieldValue("MunicipioId", event.value);
                                                            HandleGetColonia(event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        <SelectField id="ColoniaId" label="Colonia" name="ColoniaId" items={dataColonia} onChange={(event) => {
                                                            setFieldValue("ColoniaId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        <TextField id="Calle" label="Calle" name="Calle" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("Calle", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        <TextField id="Numero" label="Número" name="Numero" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("Numero", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2 col-lg-2">
                                                        {/* <TextField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("TallerAutorizado", event.target.value);
                                                            }} /> */}
                                                        <SelectField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" items={talleres} onChange={(event) => {
                                                            setFieldValue("TallerAutorizado", event.value);
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <small><strong>Referencia de ubicación</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 mb-2 px-4">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                                                        <TextAreaField id="ReferenciaUbicacion" label="Referencia de ubicación" className="form-control col-12" rows="3" name="ReferenciaUbicacion" type="text" holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("ReferenciaUbicacion", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>


                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <small><strong>Programar recolección</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 mb-2 px-4">
                                                    <div className="col-sm-12 col-md-2 col-lg-3">
                                                        <TextField id="FechaCitaCalendario" label="Fecha y hora de cita" name="FechaCitaCalendario" type="text" holder="" disabled onChange={(event) => {
                                                            setFieldValue("FechaCitaCalendario", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                        <button className="btn col-12 saveInfo" onClick={(e) => verCalendario(2, values)} disabled={!initialValue.GruasId || initialValue.BloqueoIdG || values.BloqueoIdG || initialValue.costoGrua === null}>Ver calendario</button>
                                                    </div>

                                                    {/* <div className="col-sm-12 col-md-2 col-lg-3">

                                                            <span>Estatus cita</span>
                                                            <input type="text" className='form-control' disabled value={initialValue.StatusCita != "2" ? "Sin Asignar" : "Autorizada"} />
                                                        </div> */}
                                                    <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                        <button className="btn col-12 saveInfo" disabled={!values.BloqueoIdG || values.StatusCita === '2' || initialValue.gruaStatusId === 30} onClick={(e) => verModalAutorizarCita(values)}>Autorizar Cita</button>
                                                        {/* <button className="btn col-12 saveInfo" onClick={(e) => verModalAutorizarCita(values)}>Autorizar Cita</button> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </Formik>
                </div>
                {
                    initialValue.gruaStatusId === 30 &&
                    showNext &&
                    <UbicacionResguardoCard />
                }
            </>
        )
    }
export default GruaCard