import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoGestoriaService from '../../../Services/Gestoria/SeguimientoGestoria';
import { message } from '../../../Utils/functions';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const useInicioTramite = ({ toast, handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial = {
        gestoriaRegistroInicialId: "",
        tipoTramiteId: "",
        tipoTramite: "",
        personaSolicitaTramite: null,
        telefonoFijo: null,
        telefonoMovil: "",
        telefonoTrabajo: null,
        concluido: null,
        fechaInicio: "",
        fechaFin: null
    }

    const [loading, setloading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [tipoTramites, setTipoTramites] = useState([]);

    useEffect(() => {
        getData();
        getTipoTramite();
    }, []);


    const getData = async () => {
        setloading(true)
        await SeguimientoGestoriaService.RegistroTramiteInicialGet(id)
            .then(resp => {
                setInitialValue({ ...initialValue, ...resp.data });
                handleConcluido({ concluido: resp.data.concluido })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setloading(false)
            })
    };

    const getTipoTramite = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.TipoTramiteId,
                        label: item.TipoTramite
                    })
                });

                setTipoTramites(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const submitForm = async (values) => {
        setloading(true)
        await SeguimientoGestoriaService.RegistroTramiteInicialActualizar(values)
            .then(resp => {
                toast.success(message("Se Actualizó el Registro Inicial correctamente"));
                getData()
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar Actualizar el Registro Inicial!"));
            })
            .finally(() => {
                setloading(false);
            });
    }

    return {
        loading,
        tipoTramites,
        initialValue,
        submitForm
    };
};

export default useInicioTramite;
