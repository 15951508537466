import React from 'react'
import Select from 'react-select'
import SubMenuResponsiva from './SubMenuResponsiva'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import DataTable from '../../../Components/datatable/DataTable'
import SuccessModal from '../../../Components/Modal/SuccessModal'
import ErrorModal from '../../../Components/Modal/ErrorModal'
import useResponsivaHistorial from '../../../Hooks/Inventario/useResponsivaHistorial';
import RowPages from '../../../Components/RowPages';
const ResponsivaHistorial = () => {
    let { columns,
        data,
        loading,
        modalMessage,
        setModalMessage,        
        pageCount,
        handlePageClick,        
        tipoResponsiva,        
        setStr,
        setPageRows,
        Dataempresa,
        setClaveTipoResponsiva,
        setClaveEmpresa} = useResponsivaHistorial();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <SubMenuResponsiva />
            <section className='mx-4 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Historial responsiva</strong></span>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos que requieren una cotización mensual.</span>
            </section>
            <section className='mt-5 mx-4'>
                <div className='row col-12 mx-0'>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-0 mx-1 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 px-0 mx-1'>
                        <Select options={tipoResponsiva} className='col-12' onChange={(e) => { setClaveTipoResponsiva(e.value) }} placeholder="Tipo responsiva" />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 px-0 mx-1'>
                        <Select options={Dataempresa} className='col-12' onChange={(e) => { setClaveEmpresa(e.value) }} placeholder="Empresa" />
                    </div>
                </div>

            </section>
           <RowPages
                setpageRows={setPageRows}
            />
            <div className='tbl-scroll mx-4 table-responsive'>
                <DataTable
                    iswidth={true}
                    column={columns}
                    data={data}
                    pageCount={pageCount}                
                    paginate={true}
                    handlePageClick={handlePageClick}
                />
            </div>
        </>
    )
}

export default ResponsivaHistorial