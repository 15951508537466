import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { FormatNumberMoney2, FormatNumberToDecimal, message } from '../../../Utils/functions';
import PagoDesembolsoService from '../../../Services/Seguros/PagoDesembolsoService';
import AuthLogin from '../../../Utils/AuthLogin';
import ComisionesEmpresaService from '../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
import ComisionesExternoService from '../../../Services/Inventario/Comisiones/ComisionesExternoService';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const auth = new AuthLogin();
const useComisionesExternoProcesoPago = (toast) => {
    const selectRef = useRef();
    const navigate = useNavigate();
    const columns = [
        { field: 'folio', headerName: 'Folio', width: 150 },
        { field: 'strFechaSolicitud', headerName: 'Fecha de solicitud', width: 150 },
        { field: 'cantidadVehiculos', headerName: 'Cant. Vehículos', width: 150 },
        { field: 'strMontoTotalCalculado', headerName: 'Monto  total calculado', width: 250 },
    ];
    const columnsDetail = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega', width: 150 },
        { field: 'razonSocial', headerName: 'Razón social', width: 250 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'procedenciaCliente', headerName: 'Procedencia del cliente', width: 150 },
        { field: 'precioStr', headerName: 'Precio unidad', width: 150 },
        { field: 'montoFinanciarStr', headerName: 'Monto a financiar', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreMarca', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 150 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [ProcedCliente, setProcedCliente] = useState([]);
    const [ValueProcedCliente, setValueProcedCliente] = useState("");
    const [claveRazonSocial, setClaveRazonSocial] = useState("");
    const [razonSocial, setrazonsocial] = useState([]);
    //DataDocs
    const [isOpenModalFile, setIsOpenModalFile] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechFin, setFechFin] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [dataPD, setDataPD] = useState();

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows, FechInicio, FechFin, ValueProcedCliente, TipoCompra, claveRazonSocial])

    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllProdecenciaCliente();
        handleGetCSRazonSocial()
        // handleGetAllUsoUnidad();
        // handleGetAllAseguradora();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await ComisionesExternoService.getAllProcesoPagoComisionExterno(page, pageRows, TipoCompra, claveRazonSocial, ValueProcedCliente, FechInicio, FechFin, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleUploadFile = async () => {

    }
    const handleGetCSRazonSocial = async () => {
        // if (Id === "") {
        //     setrazonsocial([]);
        //     selectRef.current.setValue([], 'clear')
        //     return;
        // }
        setLoading(true);
        await ComisionesExternoService.getAllCSRazonSocialById()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.RazonSocialId,
                        label: item.RazonSocial,
                        maximoNumeroAdeudos: item.MaximoNumeroAdeudos,
                        numeroAdeudosRestantes: item.NumeroAdeudosRestantes,
                        fecha: item.Fecha
                    })
                });
                setrazonsocial(NewData);
            setLoading(false);
            }).catch(e => {
            setLoading(false);
            })
    }
    const handleGetAllProdecenciaCliente = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Procedencia_Cliente')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.Procedencia_ClienteId,
                        label: item.Procedencia_Cliente
                    })
                });
                setProcedCliente(NewData);
            })
            .catch(err => {
                console.error(err);
            })
    }
    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }
    const handlePocesarInfo = (data) => {

        try {
            let { jsonData } = data;
            if (jsonData.length <= 0) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
            let dataValida = [];
            let dataNoValida = [];
            let Data = [];

            if (!jsonData[0]?.hasOwnProperty('Precio_unidad') && !jsonData[0]?.hasOwnProperty('Pago')) {            
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.`)}</div>);
                return;
            }

            jsonData.filter((x) => {
                if (Number(x.Pago) > 0) {
                    let newData = {
                        ...x,
                        Precio_unidad: FormatNumberToDecimal(x.Precio_unidad.replace("$", " ")),
                        PagoStr: `$${FormatNumberMoney2(x.Pago)}`,
                        isValid: true,
                        usuario: auth.getNombreUsuario()
                    };
                    dataValida.push(newData);
                    Data.push(newData);
                } else {
                    let newData = {
                        ...x,
                        Precio_unidad: FormatNumberToDecimal(x.Precio_unidad.replace("$", " ")),
                        PagoStr: x.Calculo_comision,
                        isValid: false,
                        usuario: auth.getNombreUsuario()
                    };
                    dataNoValida.push(newData);
                    Data.push(newData);
                }
            })
            setDataPD({ ...data, jsonData: Data, totalRegistro: jsonData.length, totalAProcesar: dataValida.length, totalSinProcesar: dataNoValida.length, dataAProcesar: dataValida });
            setOpenModalConfirm(true);
        } catch (error) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }
    const handleReimprimirReporte = async (item) => {
        setLoading(true);
        await ComisionesExternoService.ReemprimirPendientesDePagoComisionExterno(item.folio)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `ComisionExterno-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false)
                toast.success(message("¡Correcto.!", "Registros exportados correctamente."));
            }).catch(e => {
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        columnsDetail,
        DataAseguradora,
        setAseguradora,
        setFechInicio,
        setFechFin,
        setIsOpenModalFile,
        isOpenModalFile,
        handlePocesarInfo,
        openModalConfirm,
        setOpenModalConfirm,
        dataPD,
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,
        razonSocial,
        selectRef,
        handleGetCSRazonSocial,
        handleReimprimirReporte
    }
}

export default useComisionesExternoProcesoPago