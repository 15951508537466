import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceCancelacionSeguro = createSlice({
  name: 'CancelacionSeguroNavigate',
  initialState,
  reducers: {
    tabActiveCancelacionSeguro: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveCancelacionSeguro } = navigaSliceCancelacionSeguro.actions

export default navigaSliceCancelacionSeguro.reducer