import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Parametros from '../../Services/Parametros/Parametros';
import { TextAreaField } from '../../Components/TextAreaField';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { SelectField } from '../../Components/SelectField';

const ParametrosModal = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage }) => {



    const [valor, setValor] = useState(item.valor);
    const [valorDos, setValorDos] = useState("");


    let initial = {
        valor: item?.valor ?? "",
        valorDos: item?.valorDos ?? "",
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [isOpenModalconf, setIsOpenModalconf] = useState(false);
    const [DataEtapa, setDataEtapa] = useState([]);
    const [message, setMessage] = useState("");
    let validate;

    useEffect(() => {
        if (item.parametroBd === 'EtapaBaseProducto') {
            handleGetAllEtapa();
        }

    }, [])

    const handleGetAllEtapa = async () => {
        await EtapaService.getAllEtapa()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaId,
                        label: item.etapa
                    })
                });
                setDataEtapa(NewData);
            }).catch(e => {
            })
    }
    switch (item.parametroBd) {
        case 'Tenencia':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").matches("^[0-9]{1,5}\.[0-9]{1,2}$", 'Se requiere formato correcto (Ejemplo: 123.45, del 0.00 al 99,999.99)') });
            break;
        case 'ToleranciaPruebaManejo':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(9, "maximo 9 digitos") });
            break;
        case 'TokenDataDocs':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'HoraJobRenovacion':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasVigenciaSeguroProporcional':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HoraJobSeguroProporcional':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HorarioCancelacionEntrega':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HorarioCancelacionGestoria':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HorarioCancelacionSeguro':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HorarioEliminarExterno':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'HorarioEliminadoFisicoExterno':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'MaximoNumeroAdeudos':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'IntentToken':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'CantidadMantenerPruebaManejo':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'CantidadDeClavesDemo':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'PeriodoDeRevisionClavesDemo':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'DiasProporcional':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'ObtencionTokenDataDocs':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'DiasIntentoFolio':
            validate = Yup.object({ valor: Yup.number().required("El campo es requerido.") });
            break;
        case 'DiasMantenerPruebaManejo':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasFechaEntrega':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasVencimientoCredito':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasCancelacionGestoria':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasCancelacionEntrega':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'DiasVigenciaEliminadoExterno':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'urlAcendes':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'urlDataDocs':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'PasswordAcendes':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'EtapaBaseProducto':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'MontoMinimoCheque':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'MontoMaximoCheque':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'urlIntelimotor':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.") });
            break;
        case 'ApiSecretIntelimotor':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(100, "Maximo 100 caracteres") });
            break;
        case 'ApiKeyIntelimotor':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(100, "Maximo 100 caracteres") });
            break;
        case 'CostoReagendaInstalacion':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").matches("^[0-9]{1,5}\.[0-9]{1,2}$", 'Se requiere formato correcto (Ejemplo: 123.45, del 0.00 al 99,999.99)') });
            break;
        case 'IVA':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < -1) {
                            return ctx.createError({ message: 'ingrese un valor entre 0.0  y 99.99' })
                        }
                        if (value > 99.9999) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 99.99' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'ParametroDataDocs' || 'TipoFicheroId':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(9, "Maximo 9 caracteres") });
            break;
        case 'TipoFicheroId':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(9, "Maximo 9 caracteres") });
            break;
        case 'OrigenDataDocs':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(100, "Maximo 100 caracteres") });
            break;
        case 'BdAcendes':
            validate = Yup.object({
                valor: Yup.string().required("El campo es requerido.").matches("^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$", "no se permiten espacios en blanco.")
                    .max(50, "el campo debe contener 50 caracteres.").trim("El texto no debe empezar ni terminar con espacios").strict(true),
            });
            break;
        case 'SeparadoVehiculo':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 31) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'ComisionTotal':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 0) {
                            return ctx.createError({ message: 'ingrese un valor positivo' })
                        }
                        if (value > 100) {
                            return ctx.createError({ message: 'ingrese un valor entre 0 y 100' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'Cuota':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 0) {
                            return ctx.createError({ message: 'ingrese un valor positivo' })
                        }
                        if (value > 100) {
                            return ctx.createError({ message: 'ingrese un valor entre 0 y 100' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'DiaCancelacionSeguro':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 31) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'DiaEliminarExterno':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 31) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'DiasBorradoFisicoExterno':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        // if (value > 31) {
                        //     return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        // }
                        return true
                    }
                })
            });
            break;
        case 'PeriodoCancelacion':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 31) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'Enganche':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 31) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'RenovacionSeguro':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        // if (value > 31) {
                        //     return ctx.createError({ message: 'ingrese un valor entre 1 y 31' })
                        // }
                        return true
                    }
                })
            });
            break;
        case 'CambioPropietario':
            validate = Yup.object({
                valor: Yup.number().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {

                        if (value < 1) {
                            return ctx.createError({ message: 'ingrese un valor positivo ' })
                        }
                        if (value > 100) {
                            return ctx.createError({ message: 'ingrese un valor entre 1 y 100' })
                        }
                        return true
                    }
                })
            });
            break;
        case 'HorarioReenvioDePoliza':
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido."), valorDos: Yup.string().required("El campo es requerido.") });
            break;
        default:
            validate = Yup.object({ valor: Yup.string().required("El campo es requerido.").max(30, "Maximo 30 caracteres").trim("El texto no debe empezar ni terminar con espacios").strict(true) });
            break;
    }

    const handleAddEdit = async (value) => {
        if (item.parametroBd === 'PasswordAcendes')
            value.valor = base64_encode(value.valor)
        if (item.parametroBd === 'HorarioReenvioDePoliza')
            value.valor = value.valor + "|" + value.valorDos
        await Parametros.updateParam(item.parametroBd, value.valor)
            .then(res => {
                setIsOpen(false);
                handleGetAll(1);
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

    }

    const handleOpenModalConf = (item) => {
        if (item.valor === valor)
            setMessage("No se realizaron cambios.");
        // setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "No se realizaron cambios." });
        else
            setIsOpenModalconf(true);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, submitForm, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5'><strong>Editar Parámetro</strong></span>
                                <span>A continuación se puede modificar el parámetro seleccionado según su tipo.</span>
                            </div>

                            <Form>
                                <div className="mb-3 row my-4">
                                    <div className="col-sm-12">
                                        {
                                            item.parametroBd === 'Tenencia' || item.parametroBd === 'CambioPropietario' || item.parametroBd === 'RenovacionSeguro' || item.parametroBd === 'CostoReagendaInstalacion' ||
                                                item.parametroBd === 'ToleranciaPruebaManejo' || item.parametroBd === 'SeparadoVehiculo' || item.parametroBd === 'Enganche' || item.parametroBd === 'PeriodoCancelacion' ||
                                                item.parametroBd === 'ParametroDataDocs' || item.parametroBd === 'TipoFicheroId' || item.parametroBd === 'ObtencionTokenDataDocs' || item.parametroBd === 'IntentToken' || item.parametroBd === 'IVA'
                                                || item.parametroBd === 'CantidadMantenerPruebaManejo' || item.parametroBd === 'DiasMantenerPruebaManejo' || item.parametroBd === 'DiasIntentoFolio' || item.parametroBd === 'DiasProporcional' || item.parametroBd === 'DiasFechaEntrega'
                                                || item.parametroBd === 'DiasVencimientoCredito' || item.parametroBd === 'DiasCancelacionGestoria' || item.parametroBd === 'DiasCancelacionEntrega' ||
                                                item.parametroBd === 'DiasVigenciaSeguroProporcional' || item.parametroBd === 'DiaCancelacionSeguro' || item.parametroBd === 'DiasBorradoFisicoExterno' || item.parametroBd === 'DiaEliminarExterno' || item.parametroBd === 'ComisionTotal'
                                                || item.parametroBd === 'Cuota' || item.parametroBd === 'MaximoNumeroAdeudos' || item.parametroBd === 'MontoMinimoCheque' || item.parametroBd === 'MontoMaximoCheque' || item.parametroBd === 'CantidadDeClavesDemo'
                                                || item.parametroBd === 'PeriodoDeRevisionClavesDemo' || item.parametroBd === 'DiasVigenciaEliminadoExterno' ?
                                                <TextField name="valor" type="number" step="any" holder="Escribe" /> : null
                                        }
                                        {
                                            item.parametroBd === 'CotizacionSeguro' || item.parametroBd === 'EliminacionSocioCI' || item.parametroBd === 'InicioPruebaManejo' || item.parametroBd === 'HoraSeparadoVehiculo' ||
                                                item.parametroBd === 'EliminarPruebaManejo' || item.parametroBd === 'MantenerPruebaManejo' || item.parametroBd === 'HoraEnganche' || item.parametroBd === 'HoraCancelacion' ||
                                                item.parametroBd === 'DescargaCatIntelimotor' || item.parametroBd === 'EliminarVehiculoIntelimotor' || item.parametroBd === 'ResetearSegurosCotizados' || item.parametroBd === 'HoraJobRenovacion'
                                                || item.parametroBd === 'HoraJobSeguroProporcional' || item.parametroBd === 'HorarioCancelacionSeguro' || item.parametroBd === 'HorarioEliminarExterno' || item.parametroBd === 'HorarioEliminadoFisicoExterno'
                                                || item.parametroBd === 'HorarioCancelacionEntrega' || item.parametroBd === 'HorarioCancelacionGestoria' ?
                                                <TextField name="valor" type="time" step="1" holder="Escribe" /> : null
                                        }
                                        {
                                            item.parametroBd === 'BusinessUnit' || item.parametroBd === 'OrigenDataDocs' || item.parametroBd === 'TipoFicheroDataDocs' || item.parametroBd === 'Consecutivo' ||
                                                item.parametroBd === 'ApiKeyIntelimotor' || item.parametroBd === 'ApiSecretIntelimotor' || item.parametroBd === 'UsuarioAcendes' || item.parametroBd === 'PasswordAcendes'
                                                || item.parametroBd === 'UsuarioDataDocs' || item.parametroBd === 'PasswordDataDocs' || item.parametroBd === 'urlAcendes' || item.parametroBd === 'urlDataDocs' || item.parametroBd === 'urlIntelimotor' ?
                                                <TextField name="valor" type="text" holder="Escribe" /> : null
                                        }
                                        {
                                            item.parametroBd === 'TokenDataDocs' || item.parametroBd === 'TokenAcendes' || item.parametroBd === 'BdAcendes' ?
                                                <TextAreaField label="" className="form-control col-12" rows={item.parametroBd === 'BdAcendes' ? '3' : '10'} name="valor" type="text" holder="Escribe" /> : null
                                        }
                                        {
                                            item.parametroBd === 'EtapaBaseProducto' ?
                                                <SelectField id="" label="" name="valor" items={DataEtapa} onChange={(value) => {
                                                    setFieldValue("valor", value.label)
                                                }} />
                                                : null
                                        }
                                        {
                                            item.parametroBd === 'HorarioReenvioDePoliza' ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <TextField name="valor" type="time" step="1" label="Horario 1" holder="Escribe" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <TextField name="valorDos" type="time" step="2" label="Horario 2" holder="Escribe" />
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            <span style={{ color: 'red', fontSize: '12px' }}>{message}</span>
                                        }
                                    </div>
                                </div>
                                <span style={{ fontSize: '12px' }}>Los cambios realizados en el parámetro afectarán en el flujo de los procesos correspondientes.</span>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <fieldset className='btn mx-2 px-5' disabled={!(isValid)} onClick={() => { handleOpenModalConf(values) }}>Continuar</fieldset>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={isOpenModalconf} color='#fff'>
                        <section className='m-4' style={{ width: '20rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5 mb-2'><strong>Confirmar cambio</strong></span>
                                <span style={{ fontSize: '12px' }}>¿Seguro que deseas modificar el parámetro? Todos los cambios se registrarán en un historial.</span>
                            </div>
                            <section className='my-3 d-flex justify-content-betwen mt-4'>
                                <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpen(false); setIsOpenModalconf(false) }}>Cancelar</button>
                                <button className='btn mx-1 px-5' onClick={submitForm}>Aceptar</button>
                            </section>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ParametrosModal