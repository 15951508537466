import React, { useEffect } from 'react'
import SubmenuCambioEtapa from './SubmenuCambioEtapa'
import Select from 'react-select';
import img from '../../Assets/img/ConfEtapas.svg'
import useHistorialEtapa from '../../Hooks/controlDeEtapas/useHistorialEtapa';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import { useSelector } from 'react-redux';
import Acordeon from '../../Components/Acordeon';
import AcordeonSA from '../../Components/AcordeonEtapaSA';
import ModalCambioEtapa from './ModalCambioEtapa';
import CambioEtapaDataOnly from './CambioEtapaDataOnly';
import DataTable from '../../Components/datatable/DataTable';
import useReglaEtapa from '../../Hooks/controlDeEtapas/useReglaEtapa';
import ModalReglaEtapa from './ModalReglaEtapa';
import ModalMotivoCambio from './ModalMotivoCambio';
import HistorialEtapa from './historialEtapa';
import useHistorialCtrlEtapa from '../../Hooks/controlDeEtapas/useHistorialCtrlEtapa';
import useHistorialReglas from '../../Hooks/controlDeEtapas/useHistorialReglas';
import ModalReglaNegocio from './ModalReglaNegocio';
import toast, { Toaster } from 'react-hot-toast';
import ModalReglaTipoReversion from './ModalReglaTipoReversion';
import ModalReglaTipoBloqueo from './ModalReglaTipoBloqueo';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';


const CambioEtapa = () => {
  const { Loading,
    setLoading,
    dataProd,
    dataTipoCompra,
    modalMessage,
    setModalMessage,
    handleChange,
    selectedValue,
    defArea,
    defEtapa,
    dataEtapa,
    dataTipoRegla,
    handleChangeProduct,
    isEnabledTipoCompra,
    handleChangeTipocompra,
    dataGroups,
    handleGetEtapasSinAsignar,
    dataESA,
    setIsOpenModalEtapa,
    isOpenModalEtapa,
    dataGroupSelect,
    handleAddEtapaGrup,
    isOpenModalGroup,
    setIsOpenModalGroup,
    handleNewGroup,
    nameGroup,
    setNameGroup,
    setIsOpenModalCambioEtapa,
    isOpenModalCambioEtapa,
    handleAddEtapa,
    setGrupoValue,
    grupoValue,
    handleDelGrupo,
    handleDelEtapaGrupo,
    setetapaValue,
    etapaValue,
    setDataESA,
    setDataGroups,
    productValue,
    tipoCompraValue,
    setTipoCompraValue,
    setProductValue,
    setDataGrupo } = useHistorialEtapa();

  const { handleGetAllReglaEtapa,
    setProductoId,
    productoId,
    setTipocompraId,
    tipocompraId,
    setEtapaId,
    etapaId,
    setTipoReglaId,
    tipoReglaId,
    dataReglaEtapa,
    handleEditRegla,
    isOpenModalReglaEtapa,
    setIsOpenModalReglaEtapa,
    item,
    setItem,
    columnsReglaEtapa,
    columnsReglaNegocio,
    dataReglaNegocio,
    setDataReglaEtapa,
    setDataReglaNegocio,
    setIsOpenModalMotivo,
    isOpenModalMotivo,
    handleEditReglaNegocio,
    setIsOpenModalReglaNegocio,
    isOpenModalReglaNegocio,
    columnsTipoRevision,
    dataReglaTipoRevision,
    setDataReglaTipoRevision,
    columnsTipoBloqueo,
    dataReglaTipoBloqueo,
    setDataReglaTipoBloqueo,
    handleEditReglaTipoReversion,
    isOpenModalReglaTReversion,
    setIsOpenModalReglaTReversion,
    handleEditReglaTipoBloqueo,
    setIsOpenModalReglaTBloqueo,
    isOpenModalReglaTBloqueo } = useReglaEtapa(setLoading);

  const {
    columnsHistorialEtapa,
    setDataHistorialEtapa,
    dataHistorialEtapa,
    setfiltroProductoId,
    setfiltroTipocompraId,
    setFiltroFecha,
    pageCount,
    currentPage,
    handlePageClick,
    handleGetAllHistory
  } = useHistorialCtrlEtapa(setLoading, setModalMessage, modalMessage);
  const {
    columnsHistorialReglaEtapa,
    columnsHistorialReglaNegocio,
    dataHistorialReglaEtapa,
    setDataHistorialReglaEtapa,
    dataHistorialReglaNegocio,
    setDataHistorialReglaNegocio,
    setfiltroHistorialProductoId,
    setfiltroHistorialTipocompraId,
    setFiltroHistorialFecha,
    setFiltroHistorialEtapaId,
    pageCountEtapa,
    handlePageClickEtapa,
    currentPageEtapa,
    currentPageNegocio,
    pageCountNegocio,
    handlePageClickNegocio
  } = useHistorialReglas(setLoading);

  const message = (title, message) => <p style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></p>
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#f1f7f3',
              color: '#000',
              border: '2px solid #b1d6bf'
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      />
      {isOpenModalEtapa &&
        <Modal isOpen={isOpenModalEtapa} color='#fff' width={1200}>
          <section className='d-flex justify-content-between pb-3' style={{ borderBottom: '1px solid #e8eaf6' }}>
            <span><strong>Editar grupos de etapas</strong></span>
            <div>
              <button className='btn btn-outline mx-2' onClick={() => { setIsOpenModalEtapa(false) }}>cancelar</button>
              <button className='btn mx-2' onClick={() => { setIsOpenModalMotivo(true) }}>Continuar</button>
            </div>
          </section>
          <div className='row mt-4'>
            <section className='mx-4 col-5 d-flex flex-column'>
              <span className='mb-2'><strong>Grupo de etapas</strong></span>
              <span>A continuación se enlistan las etapas asignadas a un grupo.</span>
            </section>
            <section className='mx-4 col-5 d-flex flex-column'>
              <span className='mb-2'><strong>Etapas sin asignar</strong></span>
              <span>Las etapas que no son asignadas a un grupo, no serán visibles en el flujo correspondiente.</span>
            </section>
          </div>

          <ModalCambioEtapa
            dataGroups={dataGroups}
            dataESA={dataESA}
            dataGroupSelect={dataGroupSelect}
            handleAddEtapaGrup={handleAddEtapaGrup}
            setIsOpenModalGroup={setIsOpenModalGroup}
            dataGrupo={setDataGrupo}
            setGrupoValue={setGrupoValue}
            setetapaValue={setetapaValue}
            setIsOpenModalCambioEtapa={setIsOpenModalCambioEtapa}
          />
        </Modal>
      }
      {
        isOpenModalReglaEtapa &&
        <ModalReglaEtapa
          isOpen={isOpenModalReglaEtapa}
          setIsOpen={setIsOpenModalReglaEtapa}
          item={item}
          handleGetAll={handleGetAllReglaEtapa}
          message={message}
          toast={toast}
        />
      }
      {
        isOpenModalReglaNegocio &&
        <ModalReglaNegocio
          isOpen={isOpenModalReglaNegocio}
          setIsOpen={setIsOpenModalReglaNegocio}
          item={item}
          handleGetAll={handleGetAllReglaEtapa}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      }
      {
        isOpenModalReglaTReversion &&
        <ModalReglaTipoReversion
          isOpen={isOpenModalReglaTReversion}
          setIsOpen={setIsOpenModalReglaTReversion}
          item={item}
          handleGetAll={handleGetAllReglaEtapa}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      }
      {
        isOpenModalReglaTBloqueo &&
        <ModalReglaTipoBloqueo
          isOpen={isOpenModalReglaTBloqueo}
          setIsOpen={setIsOpenModalReglaTBloqueo}
          item={item}
          handleGetAll={handleGetAllReglaEtapa}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      }
      {
        <Modal isOpen={isOpenModalGroup} color='#fff'>
          <section className='d-flex flex-column mx-4'>
            <span className='mb-2'><strong>Nuevo grupo</strong></span>
            <span style={{ fontSize: '12px' }}>A continuación se puede escribir el nombre del nuevo grupo</span>
          </section>
          <section className='my-4 mx-4' style={{ width: '30rem' }}>
            <span className=''>Nombre del grupo:</span>
            <div className="mb-3 row">
              <div className="col-sm-12">
                <input className='form-control' value={nameGroup} onChange={(e) => { setNameGroup(e.target.value) }} type="text" placeholder='Escribe.' />
              </div>
            </div>
            <section className='my-4 d-flex justify-content-end'>
              <button className='btn btn-outline' onClick={() => { setIsOpenModalGroup(false); setNameGroup("") }}>Cancelar</button>
              <button className='btn mx-2' onClick={handleNewGroup}>Guardar</button>
            </section>

          </section>
        </Modal>
      }
      {
        isOpenModalMotivo &&
        <ModalMotivoCambio
          isOpen={isOpenModalMotivo}
          setIsOpen={setIsOpenModalMotivo}
          setIsOpenModalEtapa={setIsOpenModalEtapa}
          item={{ motivo: null, tipoCompra: tipoCompraValue, producto: productValue }}
          // handleGetAll={handleGetAllReglaEtapa}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      }
      {
        <Modal isOpen={isOpenModalCambioEtapa} color='#fff'>
          <section className='d-flex flex-column mx-4'>
            <span className='mb-2'><strong>Confirmar Cambios</strong></span>
            <span style={{ fontSize: '12px' }}>{grupoValue !== "" && etapaValue === "" ? '¿Seguro que deseas eliminar el grupo.?' : etapaValue !== "" ? '¿Seguro que deseas eliminar la etapa.?' : '¿Seguro que deseas cambiar la posicion?'} <br /> Todos los cambios que realices se registrarán en un historial.</span>
          </section>
          <section className='my-4 mx-4' style={{ width: '30rem' }}>
            <section className='my-4 d-flex justify-content-end'>
              <button className='btn btn-outline col-4' onClick={() => { setIsOpenModalCambioEtapa(false) }}>Cancelar</button>
              {
                grupoValue !== "" && etapaValue === "" ? <button className='btn mx-2 col-4' onClick={handleDelGrupo}>Continuar</button>
                  : grupoValue !== "" && etapaValue !== "" ? <button className='btn mx-2 col-4' onClick={handleDelEtapaGrupo}>Continuar</button>
                    : <button className='btn mx-2 col-4' onClick={handleAddEtapa}>Continuar</button>

              }
              {/* <button className='btn mx-2 col-4' onClick={handleAddEtapa}>Continuar</button> */}
            </section>

          </section>
        </Modal>
      }
      <Modal isOpen={Loading} color='transparent'><Spinner message={""} /></Modal>
      <Modal isOpen={modalMessage.isOpen} color='#fff'>
        {
          modalMessage.type === 1 &&
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }
        {
          modalMessage.type === 2 &&
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }

      </Modal>
      <SubmenuCambioEtapa
        handleSetStateTab={(e) => {
          handleChange(e.target.value); setDataGroups([]); setDataReglaTipoRevision([]);
          setDataESA([]); setDataReglaEtapa([]); setDataReglaNegocio([]); setDataReglaTipoBloqueo([]);
          setDataHistorialEtapa([]); setDataHistorialReglaEtapa([]); setDataHistorialReglaNegocio([])
        }}
        defaultArea={defArea}
        defaultEtapa={defEtapa}
      />
      {

      }
      <div className='headerTab pb-4'>
        {
          selectedValue == 1 &&
          <div>
            <section className='mx-4 my-4 d-flex flex-column'>
              <span className='mt-2'>Para visualizar las reglas, a continuación selecciona el producto, tipo de compra y regla que desea configurar.</span>
            </section>
            <section className='mt-3 mx-4'>
              <div className=''>
                <div className='row col-12'>
                  <div className='col-lg-2 col-md-6 col-sm-12'>
                    <label>Producto</label>
                    <Select options={dataProd} className='col-12' placeholder="Seleccione" onChange={(e) => { setProductoId(e.value); }} />
                  </div>
                  <div className='col-lg-2 col-md-6 col-sm-12'>
                    <label>Tipo de compra</label>
                    <Select options={dataTipoCompra} className='col-12' placeholder="Seleccione" onChange={(e) => { setTipocompraId(e.value); }} />
                  </div>
                  <div className='col-lg-2 col-md-6 col-sm-12'>
                    <label>Tipo de Regla</label>
                    <Select options={dataTipoRegla} className='col-12' placeholder="Seleccione" onChange={(e) => { setTipoReglaId(e.value); console.log(e.value) }} />
                  </div>
                  <div className='col-lg-2 col-md-6 col-sm-12'>
                    <label>Etapa</label>
                    <Select options={dataEtapa} className='col-12' placeholder="Seleccione" onChange={(e) => { setEtapaId(e.value); }} />
                  </div>
                  {
                    tipoReglaId === 2 &&
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Agregar}>
                      <div className='d-flex flex-column justify-content-end col-lg-2 col-md-6 col-sm-12 mt-2'>
                        <button className='btn' onClick={() => { handleEditRegla(null) }}>Nueva Regla</button>
                      </div>
                    </ValidatePermission>
                  }
                  {
                    tipoReglaId === 1 &&
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Agregar}>
                      <div className='d-flex flex-column justify-content-end col-lg-2 col-md-6 col-sm-12 mt-2'>
                        <button className='btn' onClick={() => { handleEditReglaNegocio(null) }}>Nueva Regla</button>
                      </div>
                    </ValidatePermission>
                  }
                  {
                    tipoReglaId === 3 &&
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Agregar}>
                      <div className='d-flex flex-column justify-content-end col-lg-2 col-md-6 col-sm-12 mt-2'>
                        <button className='btn' onClick={() => { handleEditReglaTipoReversion(null) }}>Nueva Regla</button>
                      </div>
                    </ValidatePermission>

                  }
                  {
                    tipoReglaId === 4 &&
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Agregar}>
                      <div className='d-flex flex-column justify-content-end col-lg-2 col-md-6 col-sm-12 mt-2'>
                        <button className='btn' onClick={() => { handleEditReglaTipoBloqueo(null) }}>Nueva Regla</button>
                      </div>
                    </ValidatePermission>
                  }
                </div>
              </div>
            </section>
          </div>

        }
        {
          selectedValue == 4 &&
          <div>
            <section className='mx-4 my-4 d-flex flex-column'>
              <span className='mt-2'>Para comenzar selecciona a continuación el producto que deseas modificar y el tipo de compra correspondiente.</span>
            </section>
            <section className='mt-3 mx-4'>
              <div className=''>
                <div className='row col-12'>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Producto</label>
                    <Select options={dataProd} className='col-12 mx-1' placeholder="Seleccionar producto" onChange={(e) => { handleChangeProduct(e.value); setfiltroProductoId("") }} />
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Tipo de compra</label>
                    <Select options={dataTipoCompra} className='col-12 mx-1' placeholder="Tipo de compra" onChange={(e) => { setTipoCompraValue(e.value); setfiltroTipocompraId("") }} />
                  </div>
                  <div className='d-flex flex-column justify-content-end col-lg-2 mt-2 col-md-6 col-sm-12'>
                    {/* <label>Editar</label> */}
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Agregar}>
                      <button className='btn' onClick={() => { setIsOpenModalEtapa(true) }}>Editar orden de etapas</button>
                    </ValidatePermission>
                  </div>
                </div>
              </div>
            </section>
          </div>

        }
        {
          selectedValue == 5 &&
          <div>
            <section className='mx-4 my-4 d-flex flex-column'>
              <span className='mt-2'>Para comenzar selecciona a continuación el producto que deseas modificar y el tipo de compra correspondiente.</span>
            </section>
            <section className='mt-3 mx-4'>
              <div className=''>
                <div className='row col-12'>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Producto</label>
                    <Select options={dataProd} className='col-12 mx-1' placeholder="Seleccionar producto" onChange={(e) => { setfiltroProductoId(e.value); setProductValue("") }} />
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Tipo de compra</label>
                    <Select options={dataTipoCompra} className='col-12 mx-1' placeholder="Tipo de compra" onChange={(e) => { setfiltroTipocompraId(e.value); setTipoCompraValue("") }} />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <label>Filtro por Fechas</label>
                    <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setFiltroFecha(e.target.value) }} />
                  </div>
                  {/* <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Filtro por etapa</label>
                    <Select options={dataEtapa} className='col-12 mx-1' placeholder="Tipo de compra" onChange={(e) => { setFiltroEtapaId(e.value); }} />
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        }
        {
          selectedValue == 2 &&
          <div>
            <section className='mx-4 my-4 d-flex flex-column'>
              <span className='mt-2'>Para comenzar selecciona a continuación el producto que deseas modificar y el tipo de compra correspondiente.</span>
            </section>
            <section className='mt-3 mx-4'>
              <div className=''>
                <div className='row col-12'>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Producto</label>
                    <Select options={dataProd} className='col-12' placeholder="Seleccionar producto" onChange={(e) => { setfiltroHistorialProductoId(e.value) }} />
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Tipo de compra</label>
                    <Select options={dataTipoCompra} className='col-12' placeholder="Tipo de compra" onChange={(e) => { setfiltroHistorialTipocompraId(e.value); }} />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <label>Filtro por Fechas</label>
                    <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setFiltroHistorialFecha(e.target.value) }} />
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12'>
                    <label>Filtro por etapa</label>
                    <Select options={dataEtapa} className='col-12' placeholder="Tipo de compra" onChange={(e) => { setFiltroHistorialEtapaId(e.value); }} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </div>
      {
        dataGroups.length > 0 &&
        <CambioEtapaDataOnly
          dataGroups={dataGroups}
          dataESA={dataESA}
        />
      }

      {
        //* Tipo de Regla de etapas*/
        dataReglaEtapa.length > 0 &&
        <div className='mx-4 my-2 table-responsive'>
          <DataTable
            column={columnsReglaEtapa}
            data={dataReglaEtapa}
            editable={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Actualizar}
            handleEdit={handleEditRegla}
          // pageCount={pageCount}
          // handlePageClick={handlePageClick}
          // currentPage={currentPage}
          />
        </div>
      }
      {
        dataReglaNegocio.length > 0 &&
        <div className='mx-4 my-2 table-responsive'>
          <DataTable
            column={columnsReglaNegocio}
            data={dataReglaNegocio}
            editable={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Actualizar}
            handleEdit={handleEditReglaNegocio}
          // pageCount={pageCount}
          // handlePageClick={handlePageClick}
          // currentPage={currentPage}
          />
        </div>
      }
      {
        dataReglaTipoRevision.length > 0 &&
        <div className='mx-4 my-2 table-responsive'>
          <DataTable
            column={columnsTipoRevision}
            data={dataReglaTipoRevision}
            editable={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Actualizar}
            handleEdit={handleEditReglaTipoReversion}
          // pageCount={1}
          // handlePageClick={handlePageClick}
          // currentPage={currentPage}
          />
        </div>
      }
      {
        dataReglaTipoBloqueo.length > 0 &&
        <div className='mx-4 my-2 table-responsive'>
          <DataTable
            column={columnsTipoBloqueo}
            data={dataReglaTipoBloqueo}
            editable={ValidatePermissionById(4, Section.ConfiguraciónDeReglas, SubModule.ConfiguracionDeEtapas).Actualizar}
            handleEdit={handleEditReglaTipoBloqueo}
            pageCount={1}
          // handlePageClick={handlePageClick}
          // currentPage={currentPage}
          />
        </div>
      }
      {
        dataHistorialEtapa.length > 0 &&
        <div className='mx-4 my-2 table-responsive'>
          <DataTable
            column={columnsHistorialEtapa}
            data={dataHistorialEtapa}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            paginate={true}
            currentPage={currentPage - 1}
          />
        </div>
      }
      {
        dataHistorialReglaEtapa.length > 0 ||
          dataHistorialReglaNegocio.length > 0 ?
          <div className='mx-4 my-4 table-responsive'>
            <span className='fs-5'><strong>Reglas de Negocio</strong></span>

            <DataTable
              column={columnsHistorialReglaNegocio}
              data={dataHistorialReglaNegocio}
              pageCount={pageCountNegocio}
              handlePageClick={handlePageClickNegocio}
              paginate={true}
              currentPage={currentPageNegocio - 1}
            />
            <span className='fs-5'><strong>Reglas de Etapa</strong></span>
            <DataTable
              column={columnsHistorialReglaEtapa}
              data={dataHistorialReglaEtapa}
              pageCount={pageCountEtapa}
              handlePageClick={handlePageClickEtapa}
              paginate={true}
              currentPage={currentPageEtapa - 1}
            />
          </div> : null
      }
      {
        dataGroups.length == 0 &&
        dataReglaEtapa.length == 0 &&
        dataReglaNegocio.length == 0 &&
        dataHistorialEtapa.length == 0 &&
        dataHistorialReglaNegocio.length == 0 &&
        dataHistorialReglaEtapa.length == 0 &&
        dataReglaTipoRevision.length == 0 &&
        dataReglaTipoBloqueo.length == 0 &&
        <section className='d-flex flex-column align-items-center mt-5'>
          <img src={img} style={{ width: '23rem' }} />

        </section>
      }

    </>
  )
}

export default CambioEtapa