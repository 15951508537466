import React from 'react'
import ValuacionDetalle from '../ValuacionDetalle'
import { toast, Toaster } from 'react-hot-toast';
import useValoracionDeUnidad from '../../../../Hooks/Inventario/Valuacion/useValoracionDeUnidad';
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard';
import ContratoHeaderCard from '../../../Seguro/Siniestros/ContratoHeaderCard';
import ModalConfirm from '../ModalConfirm';
import ValoracionInicial from './ValoracionInicial';

const ValoracionDeUnidad = () => {
  const {
    stepe,
    handleClickTerminar,
    activebtncontinuar,
    state,
    handleClickTerminarProceso,
    setModalConfirm,
    modalConfirm,
    handleChangeStatusBtn
  } = useValoracionDeUnidad(toast);
  return (
    <>
      <ValuacionDetalle
        dataStep={stepe}
        handleClickContinuar={handleClickTerminar}
        activeBtn={activebtncontinuar}
      />
      <div className='d-flex flex-column background-grey' style={{ height: '140vh' }}>
        <div className='row mx-md-4 my-4'>
          <div className="col-6 px-md-3">
            <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
          </div>
          <div className="col-6 px-md-3">
            <ContratoHeaderCard />
          </div>
        </div>
        <ValoracionInicial
          handleConcluido={handleChangeStatusBtn} />
        {
          modalConfirm &&
          <ModalConfirm
            isOpen={modalConfirm}
            setIsOpen={setModalConfirm}
            title={"Registro de gastos"}
            handleConfirm={handleClickTerminarProceso}
          />
        }
      </div>
    </>
  )
}

export default ValoracionDeUnidad