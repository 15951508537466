import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import useBoletinado from '../../../Hooks/Garantias/useBoletinado';
import { setBoletinadoSlice } from '../../../Redux/Slice/Devoluciones/boletinadoSlice';
import AutorizaBoletinadoModal from '../../../Components/Modal/AutorizarBoletinadoModal';
import RechazarBoletinadoModal from '../../../Components/Modal/RechazarBoletinadoModal';


const CardBoletinado
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
       
        const { cambioDevolucionBoletinadoId, cambioDevolucionBoletinadoMotivoId, 
            cambioDevolucionBoletinadoMotivo, cambioDevolucionBoletinadoMotivoRechazoId, 
            cambioDevolucionBoletinadoMotivoRechazo, 
            cambioDevolucionBoletinadoMotivoAutorizacionId, 
            cambioDevolucionBoletinadoMotivoAutorizacion, fechaAlta, usuarioAlta, 
            usuarioAltaNombre, usuarioActualiza, usuarioActualizaNombre, fechaActualiza, 
            cambioDevolucionBoletinadoStatusId, cambioDevolucionBoletinadoStatus, 
            fechaSolicitud, fechaOperacion, fechaAutorizacion, fechaRechazo, 
            comentariosSupervidor, comentariosEjecutivo, status, boletinado,
            comentariosRechazo, comentariosAutorizacion } = useSelector((state) => state.BoletinadoSlice);
        const { cambioDevolucionId } = useSelector((state) => state.DevolucionesSlice);

        let initial = {
            cambioDevolucionBoletinadoId, 
            cambioDevolucionId, 
            cambioDevolucionBoletinadoMotivoId, 
            cambioDevolucionBoletinadoMotivo, 
            cambioDevolucionBoletinadoMotivoRechazoId, 
            cambioDevolucionBoletinadoMotivoRechazo, 
            cambioDevolucionBoletinadoMotivoAutorizacionId, 
            cambioDevolucionBoletinadoMotivoAutorizacion, 
            fechaAlta, 
            usuarioAlta, 
            usuarioAltaNombre, 
            usuarioActualiza, 
            usuarioActualizaNombre, 
            fechaActualiza, 
            cambioDevolucionBoletinadoStatusId, 
            cambioDevolucionBoletinadoStatus, 
            fechaSolicitud, 
            fechaOperacion, 
            fechaAutorizacion, 
            fechaRechazo, 
            comentariosSupervidor, 
            comentariosEjecutivo, 
            status, 
            boletinado: boletinado ? 1 : 0,
            comentariosRechazo,
            comentariosAutorizacion
        }


        let { idllamada } = useParams();

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [disabled, setDisabled] = useState(false);
        const [_tipoGarantiaId, setTipoGarantiaId] = useState(null);


        let {
            Motivos,
            getBoletinado
        } = useBoletinado()

        useEffect(() => {
            setInitialValue({
                ...initialValue,
                cambioDevolucionId
            })
        }, [cambioDevolucionId]);

        useEffect(() => {
            setInitialValue({
                ...initialValue,
                boletinado,
                cambioDevolucionBoletinadoMotivoId,
                comentariosSupervidor
            })
        }, [boletinado]);

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        const submitForm = async (values) => {
            if(!values.boletinado){
                values.cambioDevolucionBoletinadoStatusId = 20
            }else{
                values.cambioDevolucionBoletinadoStatusId = 40
            }

            setLoading(true);
            await Garantias.boletinadoActualizar(values)
            .then(resp => {
                dispatch(setBoletinadoSlice(resp.data))
                toast.success(message("Boletinado actualizado correctamente"));
                getBoletinado();
            })
            .catch(err => {
                toast.error(message("¡Error al intentar actualizar el boletinado!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const getDateFormatInput = (date) => {
            // console.log(date)
            if(date !== undefined && date !== "") {
                const dateFormatted = date?.split("T")[0]+" "+date?.split("T")[1].split(".")[0];
                return dateFormatted;
            }
        }


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <AutorizaBoletinadoModal
                            getBoletinado={getBoletinado}
                            cambioDevolucionId={cambioDevolucionId}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazarBoletinadoModal
                            getBoletinado={getBoletinado}
                            cambioDevolucionId={cambioDevolucionId}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseBoletinado" role="button" aria-expanded="false" aria-controls="collapseBoletinado" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={(cambioDevolucionBoletinadoStatusId === 10 || cambioDevolucionBoletinadoStatusId === 40)? 'semaforo semaforo-yellow' : ((cambioDevolucionBoletinadoStatusId === 20 || cambioDevolucionBoletinadoStatusId === 30 || cambioDevolucionBoletinadoStatusId === 50 || cambioDevolucionBoletinadoStatusId === 60) ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Boletinar cliente</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{cambioDevolucionBoletinadoStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseBoletinado">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Detalle del boletinado</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    boletinado === null &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid}
                                                                            onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    boletinado === 1 &&
                                                                    cambioDevolucionBoletinadoStatusId === 40 &&
                                                                    <>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn btn-gray'
                                                                                disabled={!isValid}
                                                                                onClick={() => setModalMessage({ isOpen: true, type: 4, title: 'Rechazar boletinado'})}>
                                                                                Rechazar
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn'
                                                                                disabled={!isValid}
                                                                                onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Autorizar boletinado'})}>
                                                                                Autorizar
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="boletinado"
                                                                label="Boletinar cliente"
                                                                disabled={cambioDevolucionBoletinadoStatusId !== 10}
                                                                name="boletinado"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("boletinado", event.value);
                                                            }} />
                                                        </div>
                                                        {
                                                            values.boletinado === 1 && 
                                                            <div className="col-3">
                                                                <SelectField id="cambioDevolucionBoletinadoMotivoId"
                                                                    label="Motivo boletinado"
                                                                    name="cambioDevolucionBoletinadoMotivoId"
                                                                    disabled={cambioDevolucionBoletinadoStatusId !== 10}
                                                                    items={Motivos}
                                                                    onChange={(event) => {
                                                                        setFieldValue("cambioDevolucionBoletinadoMotivoId", event.value);
                                                                }} />
                                                            </div>
                                                        }
                                                        <div className="col-3">
                                                            <span>Usuario</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                               <span>{usuarioAltaNombre}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <span>Fecha y hora de la solicitud</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                               <span>{getDateFormatInput(fechaAlta)}</span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-3">
                                                            <TextField id="fechaSolicitud" disabled={true} label="Fecha y hora de la solicitud" name="fechaSolicitud" type="text" holder="" onChange={(event) => {
                                                                setFieldValue("fechaSolicitud", event.target.value);
                                                            }} />
                                                        </div> */}
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosSupervidor"
                                                                label="Comentarios del ejecutivo o supervisor"
                                                                disabled={cambioDevolucionBoletinadoStatusId !== 10}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosSupervidor"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosSupervidor", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default CardBoletinado