import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    generalId: "",
    fechaEntrega: "",
    vin: "",
    nombreMarca: "",
    nombreModelo: "",
    nombreVersion: "",
    nombreYear: "",
    color: "",
    kilometraje: "",
    procedenciaAuto: "",
    numMotor: "",
    numContrato: "",
    numSolicitud:"",
    cliente: "",
    fechaUltimoPago: "",
    producto: "",
    usoUnidad: ""
}

export const ContratoSlice = createSlice({
    name: 'ContratoSlice',
    initialState,
    reducers: {
        contrato: (state, action) => {
            return {...state,
                generalId: action.payload.generalId,
                fechaEntrega: action.payload.fechaEntrega,
                vin: action.payload.vin,
                nombreMarca: action.payload.nombreMarca,
                nombreModelo: action.payload.nombreModelo,
                nombreVersion: action.payload.nombreVersion,
                nombreYear: action.payload.nombreYear,
                color: action.payload.color,
                kilometraje: action.payload.kilometraje,
                procedenciaAuto: action.payload.procedenciaAuto,
                numMotor: action.payload.numMotor,
                numContrato: action.payload.numContrato,
                numSolicitud: action.payload.numSolicitud,
                cliente: action.payload.cliente,
                fechaUltimoPago: action.payload.fechaUltimoPago,
                producto: action.payload.producto,
                usoUnidad: action.payload.usoUnidad
            }
        }
    }
})

export const { contrato } = ContratoSlice.actions

export default ContratoSlice.reducer