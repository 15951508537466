import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import AutorizarDictamenService from '../../../Services/Seguros/AutorizarDictamenService';
import { Proceso } from '../../../Utils/constan';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const useAutorizacionDesolicitudes = (toast) => {    
    const columns = [
        { field: 'folio', headerName: 'Folio cita', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },        
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', width: 200 },
        { field: 'nombreUbicacion', headerName: 'Ubicación', width: 200 },
        { field: 'tipoCita', headerName: 'Tipo de cita', width: 150 },
        // { field: 'nombreMarca', headerName: 'Marca', width: 100 },
        // { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
        // { field: 'nombreYear', headerName: 'Año', width: 100 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false); 
    const [pageRows, setpageRows] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState();
    //filtro
    const [dataUbicacion, setDataUbicacion] = useState([]);
    const [dataEjecutivo, setDataEjecutivo] = useState([]);
    const [dataTipoCita, setDataTipoCita] = useState([]);

    const [ubicacionId, setUbicacionId] = useState("");
    const [ejecutivoId, setEjecutivoId] = useState("");
    const [tipoCitaId, setTipoCitaId] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str,ubicacionId,ejecutivoId,tipoCitaId,pageRows])
    useEffect(() => {
        handleGetAllSelects();  
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await AutorizarDictamenService.GetAllAutorizarCitas(page, pageRows, str,Proceso.Gestoria,ubicacionId,ejecutivoId,tipoCitaId)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }   
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([
            handleGetAllTipocita(),
            handleGetAllUbicacion(),
            handleGetAllEjecutivo()
        ]).finally(() => {
            setLoading(false)
        });
    }
    const handleGetAllTipocita = async () => {
        await Catalogo.getDropdownsByTable('Sistema.CalendarioTipo')
            .then(resp => {
                let items = [];
                items.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.forEach(item => {
                    items.push({
                        value: item.CalendarioTipoId,
                        label: item.Tipo
                    })
                });
                setDataTipoCita(items);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleGetAllUbicacion = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                let items = [];
                items.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.forEach(item => {
                    items.push({                      
                        value: item.UbicacionId,
                        label: item.Nombre
                    })
                });
                setDataUbicacion(items);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleGetAllEjecutivo = async () => {
        await Catalogo.getDropdownsByTable('Users.Users')
            .then(resp => {
                let items = [];
                items.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.forEach(item => {
                    items.push({
                        value: item.UserId,
                        label: item.Names
                    })
                });
                setDataEjecutivo(items);
            })
            .catch(err => {
                console.error(err);
            });
    }
    
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        setOpenModal,
        openModal,
        habdleGetAll,
        setItemSelected,
        itemSelected,
        dataUbicacion,
        dataEjecutivo,
        dataTipoCita,
        setEjecutivoId,
        setUbicacionId,
        setTipoCitaId
    }
}

export default useAutorizacionDesolicitudes