import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import PagoUnidad from '../../../Services/PagoUnidad/PagoUnidad';
import { handleUnchekedColumns, message } from '../../../Utils/functions';
import { toast, Toaster } from 'react-hot-toast';

const SeguimientoInventarioPendiente = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    let initial = {
        buscar: "",
        estatus: null,
        fechaInicio: "",
        fechaFin: "",
        procesoId: null,
        concluido: "",
        page: 1,
        rows: 10
    }

    const [currenPage, setCurrenPage] = useState(1);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'vin', headerName: 'VIN'},
        { field: 'contrato', headerName: 'Contrato'},
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega'},
        { field: 'precioUnidadStr', headerName: 'Valor de la unidad'},
        { field: 'nombreMarca', headerName: 'Marca'},
        { field: 'nombreModelo', headerName: 'Modelo'},
        { field: 'nombreYear', headerName: 'Año'},
        { field: 'nombreVersion', headerName: 'Versión'},
    ];

    const [unidadesSeleccionadas, setUnidadesSeleccionadas] = useState(0);
    const [montoTotal, setMontoTotal] = useState('No hay selección');
    const [vehiculosReporte, setVehiculosReporte] = useState([]);

    useEffect(() => {

    }, []);


    const getSeguimientoPagoUnidadInventarioPendientePago = async (values = initialValue, page = 1) => {
        values.page = page;
        setMontoTotal('No hay selección');
        setUnidadesSeleccionadas(0);
        setVehiculosReporte([]);

        setLoading(true);
        let items = [];
        await PagoUnidad.getSeguimientoPagoUnidadInventarioPendientePago(values)
        .then(resp => {
            handleUnchekedColumns();
            items = resp.data.data.map((item) => {
                item.precioUnidadStr = formatMoney(item.precioUnidad)
                item.ValorUnidad = item.precioUnidad
                return item
            });
            setData({
                ...data,
                data: items
            });
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear()+" "+("0"+dateBD.getHours()).slice(-2)+":"+("0"+dateBD.getMinutes()).slice(-2)+" hrs.";
        return formattedDate;
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        getSeguimientoPagoUnidadInventarioPendientePago(refFormik.current.values, page);
    };

    const handleCheck = (evecheck, item, int) => {
        let items = vehiculosReporte

        if(evecheck){
            items.push(item)
        }else{
            const idx = (element) => element.generalId === item.generalId
            const idxFound = items.findIndex(idx)
            items.splice(idxFound, 1)
        }

        let amount = 0;
        if(items.length > 0){
            items.forEach(item => {
                amount += parseFloat(item.precioUnidad)
            });
            amount = formatMoney(amount)
        }else{
            setVehiculosReporte([]);
            amount = 'No hay selección'
        }

        setMontoTotal(amount);
        setUnidadesSeleccionadas(items.length);
        setVehiculosReporte(items);
    }

    const generateReport = async (values) => {
        const referencia = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
        let MontoTotal = montoTotal.replace(/[$,]/g,'');
        
        const form = {
            CantidadVehiculos: unidadesSeleccionadas,
            MontoTotal,
            Referencia: referencia,
            Detalles: vehiculosReporte
        }

        setModalMessage({ isOpen: false });
        await PagoUnidad.pagoUnidadProcesoPagoCrear(form)
        .then(resp => {
            getSeguimientoPagoUnidadInventarioPendientePago()
            ExportarExcel(resp.data.folioId);
            

            setUnidadesSeleccionadas(0)
            setMontoTotal('No hay selección')
            setVehiculosReporte([]);
            toast.success(message(`Se se generó el reporte de pago correctamente, los vehículos seleccionados ahora están en la pestaña de proceso de pago`));
        })
        .catch(err => {
            toast.error(message(`¡Error, al intentar generar el reporte de pago!`));
            console.error(err);
        })
    }

    const ExportarExcel = async (folio) => {
        const params = { folio }
        await PagoUnidad.ExportPendientesPagoProceso(params)
        .then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `PendientesPagoProcesoPago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        })
        .catch(err => {
            console.error(err)
        })
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <>
                                <div>
                                    <div className='justify-content-start px-4 py-3 align-items-center'>
                                        <div className="row">
                                            <div className="col col-12">
                                                <h6><strong>{modalMessage.title}</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2">
                                                <small>¿Estás seguro de que deseas generar el siguiente reporte de pagos?</small>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <small><strong>El total de vehículos: </strong> { unidadesSeleccionadas }</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <small><strong>Monto Total de Pagos Calculados: </strong> { montoTotal }</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <section className='d-flex justify-content-end'>
                                            <button id="AT_BtnCloseModal" className='btn btn-gray d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px', marginRight: '10px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Regresar</button>
                                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { generateReport(modalMessage.data) }}>Generar reporte</button>
                                        </section>
                                    </div>
                                </div>
                            </>
                        }
                    </Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section>
                        {/* <SubmenuCitasAgendadas /> */}
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Inventario</strong>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <small><strong>Unidades seleccionadas: </strong> <span>{ unidadesSeleccionadas }</span></small>
                                                </div>
                                                <div className="col-auto">
                                                    <small><strong>Monto total por pagar: </strong> <span>{ montoTotal }</span></small>
                                                </div>
                                                <div className="col-auto">
                                                    <button className='btn' onClick={(event) => {
                                                        setModalMessage({ isOpen: true, title: 'Generar reporte de pago', type: 3, data: values });
                                                    }}>
                                                        Procesar pagos
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-auto mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => getSeguimientoPagoUnidadInventarioPendientePago(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data?.data}
                                            pageCount={data?.totalPages}
                                            paginate={true}
                                            currentPage={currenPage - 1}
                                            handlePageClick={handlePageClick}
                                            checkbox={true}
                                            handleCheck={handleCheck}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoInventarioPendiente;
