import React , { useEffect, useState } from 'react'
import './ProgressBarHalfCircle.css'


const ProgressBarHalfCircle = ( {initialPercentage } ) => {
    const [value, setValue] = useState(0)

    const setGaugeValue = (value) => {
        if (value < 0 || value > 1) {
            return
        }

        // Set the transform and text content based on the value
        const gaugeFill = document.querySelector(".gauge_fill")
        const gaugeCover = document.querySelector(".gauge_cover")
        const gaugeSubCover = document.querySelector(".gauge_sub_cover")
        
        if (gaugeFill && gaugeCover) {
            gaugeFill.style.transform = `rotate(${value / 2}turn)`
            gaugeCover.textContent = `${Math.round(value * 100)}%`
            gaugeSubCover.textContent = `Contratos atendidos`
        }
    }

    useEffect(() => {
        setGaugeValue(value)
    }, [value])

    useEffect(() => {
        setValue(initialPercentage)
    }, [initialPercentage])

    return (
        <>
            <div className=' gauge'>
                <div className='gauge_body'>
                    <div className="gauge_fill"></div>
                    <div className="gauge_sub_cover"></div>
                    <div className="gauge_cover"></div>
                </div>
            </div>
        </>
    )


}

export default ProgressBarHalfCircle