import { useEffect, useState } from "react";
import SeguroPolizaService from "../../Services/Seguros/SeguroPolizaService";
import SeguroSinCotizarService from "../../Services/Seguros/seguroSinCotizarService";
import PendienteEnviarAcendesService from "../../Services/Gestoria/PendienteEnviarAcendesService";
import { useNavigate } from "react-router-dom";
import { message } from "../../Utils/functions";

const useReenvioPoliza = (toast) => {

    const navigate = useNavigate();

    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'solicitud', headerName: 'No. Solicitud', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso de Unidad', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 150 },
        { field: 'fechaSeparado', headerName: 'Fecha Separado', width: 150 },
        { field: 'etapa', headerName: 'Etapa', width: 150 },
        { field: 'marca', headerName: 'Marca', width: 150 },
        { field: 'modelo', headerName: 'Modelo', width: 150 },
        { field: '', headerName: 'Reenviar póliza', width: 150 },
    ];

    const [str, setStr] = useState("");
    const [usoUnidad, setUsoUnidad] = useState([]);
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [tipoCompra, setTipoCompra] = useState([]);
    const [claveTipoCompra, setClaveTipoCompra] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [item, setItem] = useState(null);

    useEffect(() => {
        handleGetPolizas(page);
    }, [str, claveUsoUnidad, claveTipoCompra, page]);

    useEffect(() => {
        handleGetAllUsoUnidad();
        handleGetTipoCompra();
    }, []);

    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setUsoUnidad(NewData);
            }).catch(e => {

            })
    };


    const handleViewdocs = (item) => {
        if (item.numIdFolder !== null) {
            if (item.entrega === 'Cancelada') {
                navigate(`/seguro/vehiculo/datadocs/${item.generalId}`, {
                    state: { ...item, entregaRealizada: 0, reenvioPoliza: true },
                });
            } else {
                navigate(`/seguro/vehiculo/datadocs/${item.generalId}`, {
                    state: { ...item, datamovil: true, reenvioPoliza: true },
                });
            }
        } else {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);
        }

    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                setTipoCompra(NewData);
            }).catch(e => {
            })
    };

    const handleGetPolizas = async (currentPage) => {
        setLoading(true);
        await SeguroPolizaService.getPolizasPorReenviar(currentPage, rows, str, claveUsoUnidad, claveTipoCompra)
            .then(resp => {
                let datos = [];
                setPageCount(resp.data.totalPages);
                resp.data.data.forEach(item => {
                    datos.push({
                        vin: item.vin,
                        solicitud: item.solicitud,
                        usoUnidad: item.usoUnidad,
                        tipoCompra: item.tipoCompra,
                        fechaSeparado: item.fechaSeparado !== null ? dateFormat(item.fechaSeparado) : '',
                        etapa: item.etapaActual,
                        marca: item.nombreMarca,
                        modelo: item.nombreModelo,
                        nombreMarca: item.nombreMarca,
                        nombreModelo: item.nombreModelo,
                        nombreVersion: item.nombreVersion,
                        nombreYear: item.nombreYear,
                        generalId: item.generalId,
                        numIdFolder: item.numIdFolder,
                        tipoCompraId: item.tipoCompraId,
                        productoId: item.productoId
                    });
                });

                setData(datos);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    const dateFormat = (d) => {
        let dateBD = new Date(d + "Z");
        let formattedDate = ("0" + dateBD.getDate()).slice(-2) + "-" + ("0" + dateBD.getMonth()).slice(-2) + "-" + dateBD.getFullYear();
        return formattedDate;
    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
        handleGetPolizas(event.selected + 1);
    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }

    return {
        columns,
        usoUnidad,
        tipoCompra,
        setStr,
        str,
        setLoading,
        loading,
        setClaveUsoUnidad,
        setClaveTipoCompra,
        data,
        page,
        pageCount,
        handlePageClick,
        handlechekedItem,
        handleViewdocs
    };
};


export default useReenvioPoliza;