import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import PagoRefrendoPlacas from '../../../Pages/Gestoria/PagoRefrendoYPlacas/PagoRefrendoYPlacas';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';

const usePagoRefrendoPlacas = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'tipoTramite', headerName: 'Tipo de trámite', width: 170 },
        { field: 'year', headerName: 'Año', width: 70},        
        { field: 'vehiculos', headerName: 'Vehículos', width: 100 },
        { field: 'montoPorPagarStr', headerName: 'Monto por pagar', width: 150 },
        { field: 'montoPadoStr', headerName: 'Monto pagado', width: 150 },     
        { field: 'estatus', headerName: 'Estatus', width: 250 },        
    ];
    let dataTramite = [
        { label: "Todos", value: "" },
        { label: "Pago de refrendo", value: 1 },
        { label: "Cambio de placas", value: 2 },
    ];
    let dataStatusTramite = [
        { label: "Pendiente parque vehícular", value: 1 },
        { label: "En proceso", value: 2 },
        { label: "Pagados", value: 3 },
        { label: "Concluido", value: 4 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);            
    const [pageRows, setpageRows] = useState(10);            
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [state, setState] = useState();
    const [clavetipoTramite, setClavetipoTramite] = useState("");
    const [claveStatusTipoTramite, setClaveStatusTipoTramite] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);        
    }, [str, pageRows, clavetipoTramite,claveStatusTipoTramite])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await PagoRefrendoPlacasService.getAllPagoRefrendoPlacas(page, pageRows, str,clavetipoTramite,claveStatusTipoTramite)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }  

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleDetail = (item) => {
        navigate(`/gestoria/pagorefrendoyplacas/seguimiento/${item.gestoriaPagoRefrendoPlacasId}`, {
            state: item,
        });
    }
    const handleChange = value => {        
        setState(value);
        let stringValue = [];
        value?.map(x => {
          stringValue.push(x.value);
        })        
        setClaveStatusTipoTramite(stringValue.join());
      }
    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,    
        setpageRows,        
        selectRef,
        handleDetail,     
        setIsOpenModal,
        isOpenModal,
        dataTramite,
        setClavetipoTramite,
        state,
        handleChange,
        dataStatusTramite
    }
}

export default usePagoRefrendoPlacas