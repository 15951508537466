import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ClavesModal from '../../../Components/Modal/ClavesModal';
import Modal from '../../../Components/Modal/Modal';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Spinner from '../../../Components/Loadig';
// import { toast, Toaster } from 'react-hot-toast';

const CardVehiculo = ({ claves }) => {
    const { Url } = useSelector((state) => state.UrlSlice);
    const { fechaEntrega,
        nombreYear,
        vin,
        color,
        nombreMarca,
        kilometraje,
        nombreModelo,
        procedenciaAuto,
        nombreVersion,
        numContrato,
        numMotor,
        nombre,
        clienteAcendes } = useSelector((state) => state.DataVinSlice);        

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={780}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <ClavesModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                    <div className="col-12 underline-border">
                        <div className="row">
                            <div className="col-auto" style={{ "marginTop": "5px" }}>
                                <h6><strong>Vehículo</strong></h6>
                            </div>
                            {/* <div className="col-auto" style={{ "marginBottom": "5px" }}>
                                <button className='btn btn-sm' onClick={(event) => {
                                    setModalMessage({isOpen: true, title: 'Consulta de Claves', type: 3})

                                }}>
                                    Ver Claves
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>VIN:</strong> {vin}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Año:</strong> {nombreYear}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Vehículo a nombre de:</strong> <span className='badge bg-primary-datamovil'>{nombre}</span></small>
                    </div>
                    <div className="col-6">
                        <small><strong>Color:</strong> {color}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Marca:</strong> {nombreMarca}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Kilometraje:</strong> {kilometraje}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Modelo:</strong> {nombreModelo}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Procedencia Auto:</strong> {procedenciaAuto}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Versión:</strong> {nombreVersion}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>No. Motor:</strong> {numMotor}</small>
                    </div>
                </div>
            </div>
        </>)
}

export default CardVehiculo