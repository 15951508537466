import axios from '../../Utils/ApiService';

const EliminarExternoService = {
    getAllPendientesEliminar:(page,rows,tipoCompraId,string,externo,estatus)=> {        
        return axios.get(`/functions/GetAllPendientesEliminar?page=${page}&Rows=${rows}&Externo=${externo}&TipoCompraId=${tipoCompraId}&EstatusElminacion=${estatus}&Buscar=${string}`);
    },
    DownloadFile:(tipoCompraId,string,externo,estatus)=> {        
        return axios.get(`/functions/ExportarEliminarExterno?Externo=${externo}&TipoCompraId=${tipoCompraId}&EstatusElminacion=${estatus}&Buscar=${string}`,{responseType: 'blob'});
    },
    EliminarMantenerExterno :(generalId,action) =>{
        return axios.post(`/functions/MantenerEliminarExterno?GeneralId=${generalId}&Accion=${action}`);
    }
}
export default EliminarExternoService;