import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "./Modal";
import * as Yup from 'yup';
import Spinner from "../Loadig";
import { TextField } from "../TextField";
import { SelectField } from "../SelectField";
import Catalogo from "../../Services/Catalogos/Catalogo";
import { IsNullOrEmpty, message } from "../../Utils/functions";
import { TextAreaField } from "../TextAreaField";
import SeguroSiniestrosService from "../../Services/Seguros/SeguroSiniestrosService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Llamada from "../../Services/Cartera/Llamadas";
import AuthLogin from "../../Utils/AuthLogin";
import ModalCostosRecoleccion from "../../Pages/Seguro/Siniestros/Valuacion/ModalCostosRecoleccion";

const auth = new AuthLogin();
const SolicitarRecoleccionUnidadModal = ({ isOpen, setIsOpen, item }) => {
    const { id } = useParams();
    const { state } = useLocation();    
    const navigate = useNavigate();
    const initial = {
        valuacionInicio_RecoleccionUnidadId: "",
        seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
        siniestroid: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
        paso: 0,
        folio: "",
        usuarioProgramo: auth.getSesionValue("nombreUsuario"),
        grua: "",
        empresaId: "",
        gruaAsignada: "",
        subTotal: 0,
        iva: 0,
        total: 0,
        origenFechaRecoleccion: "",
        origenHoraRecoleccion: "",
        quienEntrega: "",
        quienRecibe: "",
        calle: "",
        numero: "",
        codigoPostal: "",
        paisId: "",
        edoMexicoId: "",
        municipioId: "",
        coloniaId: "",
        referenciaUbicacion: "",
        destinoTipoUbicacion: "",
        destinoUbiccion: "",
        destinoFechaRecoleccion: "",
        destinoHoraRecoleccion: ""
    };

    const columns = [];

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [tipoGrua, setTipoGrua] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [Estados, setEstados] = useState([]);
    const [Municipios, setMunicipios] = useState([]);
    const [Colonias, setColonias] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getTipoGruas();
        getEmpresas();
        getEdoMex();
        getUbicaciones();
        getRecoleccion();
    }, [isOpen]);

    const getRecoleccion = async () => {
        await SeguroSiniestrosService.getRecoleccionUnidad(item?.seguroSiniestroSeguimientoProcesoDatamovilId)
            .then(resp => {
                let itemx = resp.data;
                let newData = {
                    siniestroId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
                    valuacionInicio_RecoleccionUnidadId: itemx?.valuacionInicio_RecoleccionUnidadId ?? "",
                    folio: itemx?.folio ?? "",
                    usuarioProgramo: itemx?.usuarioProgramo ?? auth.getSesionValue("nombreUsuario"),
                    grua: itemx?.grua.toLowerCase() ?? "",
                    empresaId: itemx?.empresaId ?? "",
                    gruaAsignada: itemx?.gruaAsignada ?? "",
                    origenFechaRecoleccion: !IsNullOrEmpty(itemx?.origenFechaRecoleccion) ? itemx?.origenFechaRecoleccion.split("T")[0] : "",
                    origenHoraRecoleccion: itemx?.origenHoraRecoleccion ?? "",
                    quienEntrega: itemx?.quienEntrega ?? "",
                    quienRecibe: itemx?.quienRecibe ?? "",
                    calle: itemx?.calle ?? "",
                    numero: itemx?.numero ?? "",
                    codigoPostal: itemx?.codigoPostal ?? "",
                    edoMexicoId: itemx?.edoMexicoId ?? "",
                    municipioId: itemx?.municipioId ?? "",
                    coloniaId: itemx?.coloniaId ?? "",
                    referenciaUbicacion: itemx?.referenciaUbicacion ?? "",
                    destinoUbiccion: itemx?.destinoUbiccion ?? "",
                    destinoFechaRecoleccion: !IsNullOrEmpty(itemx?.destinoFechaRecoleccion) ? itemx?.destinoFechaRecoleccion.split("T")[0] : "",
                    destinoHoraRecoleccion: itemx?.destinoHoraRecoleccion ?? "",
                    subTotal: itemx?.subTotal ?? "",
                    iva: itemx?.iva ?? "",
                    total: itemx?.total ?? "",
                    subido: itemx?.subido ?? "",

                    concluido: itemx?.concluido ?? false,
                    fechaInicio: itemx.fechaInicio ?? "",
                    fechaFin: itemx.fechaFin ?? ""
                }
                setInitialValue(newData);

                if (resp.data.edoMexicoId !== null) {
                    getMunicipio(resp.data.edoMexicoId);
                }
                if (resp.data.municipioId !== null) {
                    getColonia(resp.data.municipioId);
                }
            })
            .catch(err => {

            })
    }

    const submitForm = async (values) => {
        if (values.concluido) {
            if (!values.subido) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir evidencia.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }
        setLoading(true);
        await SeguroSiniestrosService.addOrUpdateRecoleccion(values)
            .then(resp => {
                console.log(resp.data.ban)
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));                    
                    if (values.concluido) {
                        setIsOpen(false);
                        navigate(`/seguro/siniestros`)
                    } else {
                        getRecoleccion();
                    }
                } else {
                    toast.error(message(resp.data.message));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message('Ocurrió un error al intentar guardar la recolección de la unidad'));
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const validate = Yup.object().shape({
        grua: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        empresaId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        gruaAsignada: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        origenFechaRecoleccion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        origenHoraRecoleccion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        quienEntrega: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        quienRecibe: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        calle: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        numero: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        codigoPostal: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        edoMexicoId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

        municipioId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        coloniaId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        referenciaUbicacion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        destinoUbiccion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        destinoFechaRecoleccion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        destinoHoraRecoleccion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });
    const getTipoGruas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.OrigenGrua')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.OrigenGruaId,
                        label: item.OrigenGrua
                    }
                })
                setTipoGrua(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    const getEmpresas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Empresa')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.EmpresaId,
                        label: item.Empresa
                    }
                })
                setEmpresas(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    const getUbicaciones = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.UbicacionId,
                        label: item.Nombre,
                        responsable: item.Responsable
                    }
                })
                setUbicaciones(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    const getEdoMex = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipio = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data?.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonia = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data?.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }
    const handleUploadFileRecoleccion = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCntPRec/${state?.folio}/${id}`, {
            state: { registroId: item?.seguroSiniestroSeguimientoProcesoDatamovilId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    submitForm(values)
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={800}>
                            <section className='my-4 mx-4'>
                                <span className='fs-6 fw-bold'><strong>Recolección de unidad</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row" style={{ overflowY: 'scroll', maxHeight: '450px' }}>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Asignación de grúa</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Folio" name="folio" type="text" holder={'Folio'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Usuario Programó" name="usuarioProgramo" type="text" holder={'Usuario programó'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Grúa"
                                                                disabled={initialValue?.concluido}
                                                                name="grua"
                                                                items={tipoGrua}
                                                                onChange={(event) => {
                                                                    setFieldValue("grua", event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Empresa Grúa"
                                                                disabled={initialValue?.concluido}
                                                                name="empresaId"
                                                                items={empresas}
                                                                onChange={(event) => {
                                                                    setFieldValue("empresaId", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Grúa asignada" name="gruaAsignada" type="text" holder={'Grúa asígnada'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField isMoney={true} icon={"bi bi-currency-dollar"} disabled={true} label="Costo de grúa" name="total" type="text" holder={'Costo de grúa'} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-auto d-flex">
                                                            <fieldset className="btn" onClick={() => { setOpenModal(true) }} disabled={initialValue.concluido}>
                                                                Agregar costo grúa
                                                            </fieldset>
                                                            <fieldset className='ms-4 btn btn-gray' onClick={handleUploadFileRecoleccion}>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Indicar ubicación de origen</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Fecha de resguardo" name="origenFechaRecoleccion" type="date" holder={'Fecha de resguardo'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Horas de resguardo" name="origenHoraRecoleccion" type="time" holder={'Horar de resguardo'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="¿Quién entrega?" name="quienEntrega" type="text" holder={'¿Quién entrega?'} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">                                                      
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Calle" name="calle" type="text" holder={'Calle'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Número" name="numero" type="text" holder={'Número'} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Código Postal" name="codigoPostal" type="text" holder={'Código Postal'} />
                                                            {/* <TextField disabled={initialValue?.concluido} label="País" name="paisId" type="text" /> */}
                                                        </div>
                                                        <div className="col-12 col-lg-4">

                                                            <SelectField
                                                                label="Estado"
                                                                disabled={initialValue?.concluido}
                                                                name="edoMexicoId"
                                                                items={Estados}
                                                                onChange={(event) => {
                                                                    setFieldValue("edoMexicoId", event.value);
                                                                    getMunicipio(event.value);
                                                                }} />
                                                            {/* <TextField disabled={initialValue?.concluido} label="Estado" name="edoMexicoId" type="text" /> */}
                                                        </div>
                                                        <div className="col-12 col-lg-4">

                                                            <SelectField
                                                                label="Municipio"
                                                                disabled={initialValue?.concluido}
                                                                name="municipioId"
                                                                items={Municipios}
                                                                onChange={(event) => {
                                                                    setFieldValue("municipioId", event.value);
                                                                    getColonia(event.value);
                                                                }} />
                                                            {/* <TextField disabled={initialValue?.concluido} label="Municipio" name="municipioId" type="text" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">

                                                            <SelectField
                                                                label="Colonia"
                                                                disabled={initialValue?.concluido}
                                                                name="coloniaId"
                                                                items={Colonias}
                                                                onChange={(event) => {
                                                                    setFieldValue("coloniaId", event.value);
                                                                }} />
                                                            {/* <TextField disabled={initialValue?.concluido} label="Colonia" name="coloniaId" type="text" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-12">
                                                            <TextAreaField
                                                                id="referenciaUbicacion"
                                                                label="Referencia de Ubicación"
                                                                className="form-control"
                                                                disabled={initialValue?.concluido}
                                                                rows="3"
                                                                name="referenciaUbicacion"
                                                                type="text"
                                                                holder="Escribe"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Indicar ubicación de destino</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Ubicación"
                                                                disabled={initialValue?.concluido}
                                                                name="destinoUbiccion"
                                                                items={ubicaciones}
                                                                onChange={(event) => {
                                                                    setFieldValue("destinoUbiccion", event.value);
                                                                    setFieldValue(`quienRecibe`, IsNullOrEmpty(event.value) ? "" : event.responsable ?? "");
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="¿Quién recibe?" name="quienRecibe" type="text" holder={'¿Quién recibe?'} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Fecha de recolección" name="destinoFechaRecoleccion" type="date" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={initialValue?.concluido} label="Hora de recolección" name="destinoHoraRecoleccion" type="time" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-between mt-2">
                                                <div className="col-auto">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                                                </div>
                                                <div className="col-auto d-flex">
                                                    <button
                                                        type='reset'
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-outline me-2'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => { setIsOpen(false) }}
                                                    >
                                                        Cerrar
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        id="AT_BtnCloseModal"
                                                        className='btn'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        {
                            openModal &&
                            <ModalCostosRecoleccion
                                isOpen={openModal}
                                setIsOpen={setOpenModal}
                                item={initialValue}
                                handleGetAll={(item) => {
                                    setInitialValue({ ...values, total: item?.total, subTotal: item?.subTotal, iva: item?.iva })
                                }}
                            />
                        }
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default SolicitarRecoleccionUnidadModal;