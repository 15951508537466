import React from 'react'
import useValuacionDetalle from '../../../Hooks/SeguimientoDeValuacion/useValuacionDetalle'
import Stepper, { status } from '../../../Components/Stepper/Stepper';
import { useLocation, useParams } from 'react-router-dom';
const data = {
    step1: status.Active,
    porcentage1: 100,
    step2: status.Inactive,
    porcentage2: 0,
    step3: status.Inactive,
    porcentage3: 0,
    step4: status.Inactive,
    porcentage4: 100,
};
const ValuacionDetalle = ({ handleClickContinuar, dataStep = data, activeBtn = false }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const { navigate } = useValuacionDetalle();    
    const handleNavigate = (step) => {
        navigate(`/inventario/valuacion/${id}/${step.paso}`, {
            state: state
        })
    }
    return (
        <>
            <section className='headerTab'>
                <header style={{ height: '55px'}} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                    <div>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de seguimiento de valuación
                    </div>
                    {
                        state.estatus !== 'Concluido' &&
                        <button disabled={!activeBtn} className='btn' onClick={handleClickContinuar}>Continuar</button>
                    }
                </header>
                <div className='d-flex align-items-center row'>
                    {
                        dataStep.map(x =>
                            <Stepper
                                key={x.paso}
                                status={x.pasoSubStatus}
                                module={x.pasoDescripcion}
                                nameStep={`Paso ${x.paso}`}
                                percentage={x.porcentaje}
                                item={x}
                                handleClick={() => { handleNavigate(x) }}
                            />
                        )
                    }
                    
                </div>
            </section>
        </>
    )
}

export default ValuacionDetalle