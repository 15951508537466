import axios from '../../Utils/ApiService';

const Garantia = {
    getContract: async(str) => {
        return await axios.get(`/cartera/vehiculobyvincontrato_new?buscar=${str}`);
    },
    getLlamadas: async(generalId) => {
        return await axios.get(`/cartera/llamadabygeneralid?generalid=${generalId}&page=1&rows=100`);
    },
    getGarantias: async(generalId) => {
        return await axios.get(`/cartera/garantiasbygeneralid?generalid=${generalId}&page=1&rows=100`);
    },
    getMantenimientos: async(generalId) => {
        return await axios.get(`/cartera/mantenimientosbygeneralid?generalid=${generalId}&page=1&rows=200`);
    }
    // getUsuario:async(page,rows,Rol,status,str)=> {
    //     return await axios.get(`/functions/GetAllUsuarios?page=${page}&rows=${rows}&IdRol=${Rol}&Status=${status}&Buscar=${str}`);
    // }, 
    // getRoles:()=>{
    //     return axios.get(`/functions/GetAllRoles`);
    // },
}

export default Garantia