import axios from '../../Utils/ApiService';

const SeguroPolizaService = {

    getAllTipocompra: async () => {
        return await axios.get(`/catalogs/ComboSelectTipoCompra`);
    },
    getAllPolizaSeguroAutorizado: async (page, rows,string,usoUnidadId,tipoCompraId) => {
        return await axios.get(`/functions/GetAllEmisionPolizaSeguroAutorizadoSF?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    }, 
    getAllPolizaSeguroRenovado: async (page, rows,string,usoUnidadId,tipoCompraId) => {
        return await axios.get(`/functions/GetAllEmisionPolizaSeguroRenovadoSF?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    }, 
    getAllPolizaUtilitario: async (page, rows,string,usoUnidadId,tipoCompraId) => {
        return await axios.get(`/functions/GetAllEmisionPolizaVehiculosUtilitariosSF?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    }, 
    getAllAseguradora: async () => {
        return await axios.get(`/catalogs/GetCSAseguradora`);
    },
    getDocumentExport: async (folio) => {
        return await axios.get(`/functions/ExportarPendientesPagoXLSX?Folio=${folio}`, { responseType: 'blob' });
    },
    getPolizasPorReenviar: async (page, rows, buscar, usoUnidadId, tipoCompraId) => {
        return await axios.get(`/functions/GetAllEmisionPolizaReenviar?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&Buscar=${buscar}`)
    },
}

export default SeguroPolizaService