import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';
import ExpedienteService from '../../Services/Inventario/ExpedienteService';
import { message } from '../../Utils/functions';
import AuthLogin from '../../Utils/AuthLogin';

const auth = new AuthLogin();
const useExpedienteEnProceso = (toast) => {
    const navigate = useNavigate();
    const role = auth.getSesionValue("role");
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 170 },
        { field: 'strFechaEntrega', headerName: 'Fecha de Entrega', width: 170 },
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 150 },
        { field: 'tipoExterno', headerName: 'Externo', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //Filtro
    const [tipoCompra, settipoCompra] = useState([]);
    const [DataSocioComercial, setDataSocioComercial] = useState([])
    const [claveTipoCompra, setclaveTipoCompra] = useState("");
    const [claveVendedor, setClaveVendedor] = useState("");
    const [pageRows, setpageRows] = useState(10);


    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, claveTipoCompra, claveVendedor, pageRows])
    useEffect(() => {
        handleGetAllSelects();
    }, [])

    const handleGetAllSelects = async () => {
        setLoading(true)
        const [Sociocomercial, Tipocompra] = await Promise.all([handleGetSocioComercial(), handleGetTipoCompra()]).finally(() => {
            setLoading(false)
        });
    }
    const habdleGetAll = async (page) => {
        setLoading(true);
        await ExpedienteService.getAllVehiculosExpedienteArmado(page, pageRows, claveTipoCompra, claveVendedor, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetSocioComercial = async () => {
        await SocioComercialServices.getAllSocioComercialList()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.socioComercialId,
                        label: item.nombre
                    })
                });
                setDataSocioComercial(NewData);
            }).catch(e => {

            })
    }
    const habdleDownloadFile = async () => {
        setLoading(true);
        await ExpedienteService.downloadFileExpediente(claveTipoCompra, claveVendedor, str)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculosEnProceso-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                toast.error(message("error.!", e.message));
                setLoading(false);
            })
    }
    const handleUploadFile = async (item) => {
        // if (role === "Administrador") {
        //     navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
        //         state: { ...item, expediente: true },
        //     });
        // } else if (role === "Compras" && (item.tipoCompra === 'Independiente' || item.tipoCompra === 'Socio Comercial')) {
        //     navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
        //         state: { ...item, expediente: true },
        //     });
        // } else if (role === "Coordinador Entrega" && (item.tipoCompra === 'Inventario' || item.tipoCompra === 'Consignación')) {
        //     navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
        //         state: { ...item, expediente: true },
        //     });
        // }else{
        //     toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No tienes permisos para generar el expediente.")}</div>);
        // }
        navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
            state: { ...item, expediente: true },
        });
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const downloadFile = async (item) => {
        setLoading(true);
        await ExpedienteService.downloadFilePDF(item.generalId)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `Expediente-${item.vin}.pdf`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataSocioComercial,
        tipoCompra,
        setclaveTipoCompra,
        setClaveVendedor,
        handleUploadFile,
        downloadFile
    }
}

export default useExpedienteEnProceso