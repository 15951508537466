import axios from '../../Utils/ApiService';

const SeguroCotizadoService = {
 
    getlAllVehiculoSegurocotizado: (page, rows, marca, modelo, año, version, string,usoUnidad) => {
        return axios.get(`/functions/GetAllVehiculosSeguroInicialCotizados?page=${page}&Rows=${rows}&Marca=${marca}&Modelo=${modelo}&Year=${año}&Version=${version}&Buscar=${string}&UsoUnidadId=${usoUnidad}`);
    },
    getById: async(generalId) => {
        return await axios.get(`/functions/GetByIdVehiculoSeguroCotizado?GeneralId=${generalId}`);
    },
    getByIdRenovacionSeguro: async(generalId) => {
        return await axios.get(`/functions/GetByIdVehiculoRenovacionSeguros?GeneralId=${generalId}`);
    },
    UpdSeguroCotizado: (data) => {
        return axios.put(`/functions/UpdSeguroInicial`,data);
    },  
    getAllSeguroCotizacionMensual:(page,rows,claveMarca,claveModelo,claveAño,claveVersion,string,usoUnidadId)=>{
        return axios.get(`/functions/GetAllVehiculosSeguroMensual?page=${page}&Rows=${rows}&Marca=${claveMarca}&Modelo=${claveModelo}&Year=${claveAño}&Version=${claveVersion}&Buscar=${string}&UsoUnidadId=${usoUnidadId}`)
    },
    getAllRenovacionSeguro:(page,rows,claveMarca,claveModelo,claveAño,string,usoUnidadId)=>{
        return axios.get(`/functions/GetAllVehiculosRenovacionSeguros?page=${page}&Rows=${rows}&Marca=${claveMarca}&Modelo=${claveModelo}&Year=${claveAño}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`)
    },
    getAllRenovacionSeguroUtilitario:(page,rows,claveMarca,claveModelo,claveAño,string,usoUnidadId)=>{
        return axios.get(`/functions/GetAllVehiculosUtilitariosRenovacionSeguros?page=${page}&Rows=${rows}&Marca=${claveMarca}&Modelo=${claveModelo}&Year=${claveAño}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`)
    },
    UpdRenovacionSeguro: (data) => {
        return axios.put(`/functions/SaveRenovacionSeguro`,data);
    }, 
    DownloadRenovacionSeguro: (claveMarca,claveModelo,claveAño,string,usoUnidadId,Utilitario) => {
        return axios.get(`/functions/GetExportRenovacionSeguros?Marca=${claveMarca}&Modelo=${claveModelo}&Year=${claveAño}&UsoUnidadId=${usoUnidadId}&Buscar=${string}&Utilitario=${Utilitario}`,{responseType: 'blob'});
    },
    DownloadRenovacionSeguroUtilitario: (ids) => {
        return axios.get(`/functions/GetExportRenovacionSegurosUtilitarios?GeneralIds=${ids}`,{responseType: 'blob'});
    },
}

export default SeguroCotizadoService