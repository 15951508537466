import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import Llamada from '../../../Services/Cartera/Llamadas';
import ProcesoFirmaCard from './ProcesoFirmaCard';
import RequisitosModal from '../../../Components/Modal/RequisitosModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';

const CitaFirmaCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const refFormik = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();


        let initial = {
            cierreContratoCitaId: "",
            cierreContratoId: cierreid,
            cierreContratoCitaStatusId: 0,
            cierreContratoCitaStatus: "",
            fechaCita: "",
            horaCita: "",
            autorizado: false,
            concluido: false,
            status: false,
            ubicacionId: "",
            bloqueoId: "",
            ubicacion: "",
            fechaAlta: "",
            fechaConclusion: "",
        }

        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const { dayFirma, hourFirma, sucursalId, sucursalName, calendarioIdFirma,   } = useSelector((state) => state.FirmaSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [cita, setCita] = useState('');
        const [habitual, setHabitual] = useState("");
        const [fisica, setFisica] = useState("");
        const [moral, setMoral] = useState("");

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getContratoCita();
            getRequisitos('req_pf_cond');
            getRequisitos('req_pfisica');
            getRequisitos('req_pmoral');
        }, []);

        useEffect(() => {
            if(hourFirma){
                const formattedDate = `${dayFirma.split("T")[0].split("-").reverse().join('-')} ${hourFirma}`;
                setInitialValue({
                    ...initialValue,
                    fechaCita: `${dayFirma.split("T")[0].split("-").reverse().join('-')}`,
                    horaCita: hourFirma,
                    ubicacion: sucursalName
                });
                setCita(formattedDate);
            }else{
                setInitialValue({
                    ...initialValue,
                    fechaCita: '',
                    horaCita: '',
                    ubicacion: ''
                });
                setCita('');
            }
        },[hourFirma]);

        /* CONSULTAS */
        const getContratoCita = async () => {
            await Cierres.cierreContratoCitaGet(cierreid)
            .then(resp => {
                if(resp.data.fechaCita !== null && resp.data.fechaCita !== ""){
                    const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                    setCita(formattedDate);
                }
                setInitialValue(resp.data);
            })
            .catch(err => {

            });
        }

        const getRequisitos = async (clave) => {
            await Cierres.parametroCarteraByClaveGet(clave)
            .then(resp => {
                if(clave === 'req_pf_cond'){
                    setHabitual(resp.data.valor);
                }else if(clave === 'req_pfisica'){
                    setFisica(resp.data.valor);
                }else if(clave === 'req_pmoral'){
                    setMoral(resp.data.valor);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }



        const submitForm = async (values) => {
            if((hourFirma !== null && hourFirma !== undefined) && initialValue.fechaCita === null){
                const dBloqueo = {
                    ubicacionid: sucursalId,
                    fecha: `${dayFirma.split("T")[0]}`,
                    calendarioid: calendarioIdFirma,
                    calendariotipoid: 6,
                    relacionid: cierreid
                }
                await Llamada.crearCita(dBloqueo)
                .then(resp => {
                    const data = resp.data.data;
                    setInitialValue({
                        ...initialValue,
                        fechaCita: dayFirma.split("T")[0],
                        horaCita: hourFirma,
                    })
                    toast.success(message("Se agendó la cita correctamente"));
                    actualizaCitaFirma(data.bloqueoId, values)
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar agendar la cita!"));
                    console.error(err);
                });
            }else{
                let params = {
                    cierreContratoCitaStatusId: 20,
                    cierreContratoId: cierreid,
                    autorizado: values.autorizado,
                }

                if(values.autorizado){
                    params.concluido = true;
                    params.cierreContratoCitaStatusId = 30
                }

                await Cierres.cierreContratoCitaActualizar(params)
                .then(resp => {
                    setInitialValue(resp.data);
                    toast.success(message("Se actualizaron los datos de la cita correctamente"));
                    if(values.autorizado){
                        crearProcesoFirma();
                    }
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar actualizar los datos de la cita!"));
                });
            }
        }

        const actualizaCitaFirma = async (bloqueoId, values) => {
            let params = {
                ubicacionId: sucursalId,
                bloqueoId: bloqueoId,
                cierreContratoCitaStatusId: 20,
                cierreContratoId: cierreid,
                fechaCita: `${dayFirma.split("T")[0]}`,
                horaCita: hourFirma
            }

            if(values.autorizado){
                params.cierreContratoCitaStatusId = 30;
                params.autorizado = values.autorizado;
                params.concluido = true;
            }

            await Cierres.cierreContratoCitaActualizar(params)
            .then(resp => {
                setInitialValue(resp.data);
                toast.success(message("Se actualizaron los datos de la cita correctamente"));
                if(values.autorizado){
                    crearProcesoFirma();
                }
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar los datos de la cita!"));
            });
        };

        const crearProcesoFirma = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoFirmaStatusId: 10
            }
            
            setModalMessage({ isOpen: false })
            await Cierres.cierreContratoFirmaCrear(params)
            .then(resp => {
                toast.success(message("Se creó el proceso de la firma correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el proceso de la firma!"));
            });
        };


        const verCalendario = async (valor, values) => {
            dispatch(setSchedule({
                url: `procesocierre/cierres/${id}/${generalid}/${cierreid}`,
                contrato: numContrato,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        
        const verModalAutorizarCita = async (values) => {
            let formattedDate = "";
            if(initialValue.fechaCita === null || initialValue.fechaCita === undefined || initialValue.fechaCita === "") {
                formattedDate = `${dayFirma.split("T")[0].split("-").reverse().join('-')} ${hourFirma}`;
            }else{
                formattedDate = `${initialValue.fechaCita.split("T")[0].split("-").reverse().join('-')} ${initialValue.horaCita}`;
            }

            let data = {
                "generalId": generalid,
                "garantiaId": cierreid,
                "folioCita": "",
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreMarca,
                "claveModelo": nombreModelo,
                "nombreModelo": nombreModelo,
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ubicacionId": sucursalId,
                "ubicacion": sucursalName,
                "tipoGarantiaId": null,
                "clasificacionFallaId": null,
                "telefono": "",
                "fechaCita": formattedDate,
                "_bloqueoId": initialValue.bloqueoId,
            }
    
            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 6, Origin: "Listado" })
        }

        const resetForm = () => {
            refFormik.current.resetForm();
            setCita("");
            setInitialValue({
                ...initialValue,
                ubicacion: "",
            });
        }


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={860}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            getContratoCita={getContratoCita}
                            crearProcesoFirma={crearProcesoFirma}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            getContratoCita={getContratoCita}
                            modalMessage={modalMessage}
                            reset={resetForm}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 5 &&
                        <RequisitosModal
                            habitual={habitual}
                            fisica={fisica}
                            moral={moral}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCita" role="button" aria-expanded="false" aria-controls="collapseCita" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.cierreContratoCitaStatusId === null ? 'semaforo semaforo-default' : ( initialValue.cierreContratoCitaStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Cita de firma</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.cierreContratoCitaStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseCita">
                                        <Formik
                                            enableReinitialize={true}
                                            innerRef={refFormik}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Programar cita</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!isValid || initialValue.cierreContratoCitaStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-2">
                                                            <span>Fecha y hora de cita</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                               <span>{cita}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                className='btn mt-4'
                                                                disabled={initialValue.cierreContratoCitaStatusId === 30 || initialValue.fechaCita !== null}
                                                                onClick={(e) => verCalendario(6, values)}>
                                                                Ver Calendario
                                                            </button>
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="ubicacion" disabled={true} label="Sucursal" name="ubicacion" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("ubicacion", event.target.value);
                                                            }} />
                                                        </div>
                                                        {/* <div className="col-3">
                                                            <span>Estatus Cita</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                {
                                                                    initialValue.fechaCita === null && 
                                                                    <span>Sin cita agendada</span>
                                                                }
                                                                {
                                                                    (initialValue.fechaCita !== null && initialValue.autorizado) && 
                                                                    <span>Autorizado</span>
                                                                }
                                                                {
                                                                    (initialValue.fechaCita !== null && !initialValue.autorizado) && 
                                                                    <span>Pendiente de autorización</span>
                                                                }
                                                            </div>
                                                        </div> */}
                                                        <div className="col-2">
                                                            <button className='btn mt-4 col-12' disabled={initialValue.BloqueoId === null || initialValue.cierreContratoCitaStatusId !== 20} onClick={(e) => verModalAutorizarCita(values)}>Autorizar cita</button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <button
                                                                className='btn mt-4 px-4'
                                                                disabled={false}
                                                                onClick={() => setModalMessage({ isOpen: true, type: 5, title: 'Requisitos de trámite' })}>
                                                                Ver requisitos
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 alert alert-primary" role="alert">
                                                            <div className="row">
                                                                <div className="col-1 mt-2 text-center">
                                                                    <i className="icon-dark bx bx-info-circle bx-sm"></i>
                                                                </div>
                                                                <div className="col-11">
                                                                    Nuestra sucursal se encuentra en Av. Humberto Lobo #520 Locales F18, G1 y G2, 66220 San Pedro Garza García, N.L. (Adentro de la plaza San Pedro). <br/>
                                                                    Horarios de Lunes a Viernes de 9:00 a 19:00 hrs y los sábados de 10:00 a 13:00 hrs.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.cierreContratoCitaStatusId === 30} type="checkbox" name="autorizado" onClick={(event) => {
                                                                setFieldValue("autorizado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Concluido</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.cierreContratoCitaStatusId === 30 &&
                    <ProcesoFirmaCard
                        getContratoCita={getContratoCita}
                    />
                }
            </>
        )
    })
export default CitaFirmaCard