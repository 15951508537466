import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const CatColonia = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        coloniaId: item?.coloniaId ?? 0,
        colonia: item?.colonia ?? "",
        municipioId: item?.municipioId ?? "",
        codigoPostal: item?.codigoPostal ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataCol, setDataCol] = useState([]);
    const [data2, setData2] = useState([]);

    useEffect(() => {
        handleGetMunicipio()
    }, [])

    const validate = Yup.object({
        colonia: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
              )
            .min(3, 'minimo 3 caracteres'),
        codigoPostal: Yup.number()
            .required("El campo es requerido."),
        municipioId: Yup.string()
            .required("El campo es requerido.")
    });
    const handleGetMunicipio = async () => {
        await Catalogo.getdataTabla("EdoMExico", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    })
                });
                setData2(NewData);
            }).catch(e => {
                
            })
        // await Catalogo.GetAllMunicipio(1, 100)
        //     .then(res => {
        //         let NewData = [];
        //         res.data.data?.map(item => {
        //             NewData.push({
        //                 value: item.municipioId,
        //                 label: item.municipio
        //             })
        //         });
        //         setDataCol(NewData);
        //     }).catch(e => {
        //         
        //     })
    }
    const handleChnageSelect = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setDataCol(NewData);
            }).catch(e => {
                
            })
    }
    const [errorMessage, setErrorMessage] = useState("");
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (existingData.find(x=>
            x.colonia?.toLowerCase() === item.colonia?.toLowerCase() &&
            x.municipioId === item.municipioId &&
            x.codigoPostal === item.codigoPostal)) {
            
            setErrorMessage("La colonia ya existe.");
            refbtn.current.disabled = false;
            return;
        }
        if (action === 'add') {
            await Catalogo.addColonia(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateColonia(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, setFieldValue}) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <TextField id="Colonia" label="Colonia" name="colonia" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField id="Estado" label="Estado" name="estadoId" items={data2} onChange={(event) => {                                            
                                            setFieldValue("estadoId",event.value);
                                            handleChnageSelect(event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField id="Municipio" label="Municipio" name="municipioId" items={dataCol} onChange={(value)=>{
                                            setFieldValue("municipioId",value.value)
                                        }}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField id="CodigoPostal" label="Codigo Postal" name="codigoPostal" type="number" holder="Escribe" />
                                    </div>  
                                </div>
                                {/* <div className="mb-3 row">
                                                                     
                                </div> */}
                                <span className="text-danger">{errorMessage}</span>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default CatColonia