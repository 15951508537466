import axios from '../../../Utils/ApiService';

const ValuacionRegistroCostoService = {
    /* Regsitro Costos */
    getAllRegistroCostos: (page,rows,id) => {
        return axios.get(`/functions/GetAllCostosAdicionales?page=${page}&Rows=${rows}&valuacionId=${id}`);
    },
    addCostosAdicionales: (data) => {
        return axios.post(`/functions/AddCostosAdicionales`, data);
    },   
    UpdCostosAdicionales: (data) => {
        return axios.put(`/functions/UpdCostosAdicionales`, data);
    },   
    DeleteCostosAdicionales: (id) => {
        return axios.delete(`/functions/DeleteCostosAdicionales?id=${id}`);
    },
    //Card
    SaveRegistroCostosCard : (data) =>{
        return axios.put(`/functions/SaveRegistroCostos`, data);
    },
    //Stepe
    getAllStepe : (Id,procesoId) =>{
        return axios.get(`/functions/GetAllStep?Id=${Id}&ProcesoId=${procesoId}`);
    },
    updStep : (data)=>{
        return axios.put(`/functions/UpdStep`,data);
    }
}

export default ValuacionRegistroCostoService