import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const Ubicacion = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage, existingData }) => {
    const refbtn = useRef();
    let initial = {
        ubicacionId: item?.ubicacionId ?? "",
        nombre: item?.nombre ?? "",
        tipoUbicacionId: item?.tipoUbicacionId ?? "",
        calle: item?.calle ?? "",
        numExterior: item?.numExterior ?? "",
        numInterior: item?.numInterior ?? "",
        codigoPostalId: item?.codigoPostalId ?? "",
        coloniaId: item?.coloniaId ?? "",
        municipioId: item?.municipioId ?? "",
        estadoId: item?.estadoId ?? "",
        latitud: item?.latitud ?? "",
        longitud: item?.longitud ?? "",
        status: item?.status ?? true,
        responsable: item?.responsable ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);


    useEffect(() => {
        handleGetLists()
        if (item !== null) {
            handleChnageSelect(item.estadoId);
            handleChangeMun(item.municipioId);
        }
    }, [])



    const validate = Yup.object({
        nombre: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        tipoUbicacionId: Yup.string()
            .required("El campo es requerido."),
        calle: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        numExterior: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        numInterior: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            ),
        // .required("El campo es requerido."),
        codigoPostalId: Yup.string()
            .required("El campo es requerido."),
        coloniaId: Yup.string()
            .required("El campo es requerido."),
        municipioId: Yup.string()
            .required("El campo es requerido."),
        estadoId: Yup.string()
            .required("El campo es requerido."),
        latitud: Yup.number()
            // .trim("El texto no debe empezar ni terminar con espacios")
            // .strict(true)
            // .matches(
            //    "^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?[,]\s?)+(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$",
            //     "La información ingresada es incorrecta."
            // )
            // .matches(
            //     "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
            //     "solo debe existir un espacio entre cada palabra."
            // )
            .required("El campo es requerido."),
        longitud: Yup.number()
            // .trim("El texto no debe empezar ni terminar con espacios")
            // .strict(true)
            // .matches(
            //    "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
            //     "La información ingresada es incorrecta."
            // )
            // .matches(
            //     "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
            //     "solo debe existir un espacio entre cada palabra."
            // )
            .required("El campo es requerido."),
        responsable: Yup.string()
            .required("El campo es requerido.")

    });
    const handleGetLists = async () => {
        await Catalogo.getdataTabla("TipoUbicacion", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoUbicacionId,
                        label: item.tipoUbicacion
                    })
                });
                setData(NewData);
            }).catch(e => {

            })
        // await Catalogo.getdataTabla("Colonia", 1, 100)
        //     .then(res => {
        //         let NewData = [];
        //         res.data.data?.map(item => {
        //             NewData.push({
        //                 value: item.coloniaId,
        //                 label: item.colonia
        //             })
        //         });
        //         setData1(NewData);
        //     }).catch(e => {
        //         
        //     })
        // await Catalogo.getdataTabla("Municipio", 1, 100)
        //     .then(res => {
        //         let NewData = [];
        //         res.data.data?.map(item => {
        //             NewData.push({
        //                 value: item.municipioId,
        //                 label: item.municipio
        //             })
        //         });
        //         setData2(NewData);
        //     }).catch(e => {
        //         
        //     })
        await Catalogo.getdataTabla("EdoMexico", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    })
                });
                setData3(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addUbicacion(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateUbicacion(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    const handleChnageSelect = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setData2(NewData);
            }).catch(e => {

            })
    }
    const handleChangeMun = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                setData1(NewData);
            }).catch(e => {

            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {

                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '45rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="Nombre" label="Nombre" name="nombre" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="TipoUbicacion" label="Tipo Ubicación" name="tipoUbicacionId" items={data} onChange={(value) => {
                                            setFieldValue("tipoUbicacionId", value.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="Calle" label="Calle" name="calle" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="NumExterior" label="Num. Exterior" name="numExterior" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="NumInterior" label="Num. Interior" name="numInterior" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="CP" label="CP" name="codigoPostalId" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className='mb-3 row'>
                                    <div className="col-sm-4">
                                        <SelectField id="Estado" label="Estado" name="estadoId" items={data3} onChange={(value) => {
                                            setFieldValue("estadoId", value.value);
                                            handleChnageSelect(value.value);
                                            setFieldValue("municipioId", "");
                                            setFieldValue("coloniaId", "");
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="Municipio" label="Municipio" name="municipioId" items={data2} onChange={(value) => {
                                            setFieldValue("municipioId", value.value);
                                            handleChangeMun(value.value);
                                            setFieldValue("coloniaId", "");
                                        }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField id="Colonia" label="Colonia" name="coloniaId" items={data1} onChange={(value) => {
                                            setFieldValue("coloniaId", value.value);
                                        }} />
                                    </div>
                                </div>
                                <div className='mb-3 row'>
                                    <div className="col-sm-4">
                                        <TextField id="Latitud" label="Latitud" name="latitud" type="number" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="Longitud" label="Longitud" name="longitud" type="number" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="Longitud" label="Responsable" name="responsable" type="text" holder="Escribe" />
                                    </div>
                                </div>

                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default Ubicacion