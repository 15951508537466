import { useEffect, useState } from "react";

   const useScreenSize = () => {
      const [width, setWidth] = useState(window.innerWidth);
      const [height, setHeight] = useState(window.innerHeight);
    
      const handleResize = () => {
         setWidth(window.innerWidth);
         setHeight(window.innerHeight);
      };
      useEffect(() => {
         setWidth(window.innerWidth);
         setHeight(window.innerHeight);
      
         window.addEventListener("resize", handleResize);
      }, [window.innerWidth, window.innerHeight]);
   
      return { 
         width 
      }
   }
 
   export default useScreenSize;