import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Formik } from 'formik';
import Modal from '../../../../Components/Modal/Modal';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';

const SolicitudEntregaUnidadModal = ({ isOpen, setIsOpen, data, toast }) => {
  const refFormik = useRef();
  const [resultado, setResultado] = useState('');
  const [motivoId, setMotivoId] = useState('');
  const [motivoItems, setmotivoItems] = useState([]);


  const handleRechazar = () => {
    setIsOpen(false);
  };

  const handlerGuardar = async (values) => {
    // Verifica que data no sea undefined
    if (!data) {
      console.error("No se han proporcionado datos para el modal.");
      return;
    }

    let requestData = {
      anyObject: {
        id: data.id,
        id_cita: data.id_cita,
        contrato: data.contrato,
        vin: data.vin,
        marca: data.marca,
        modelo: data.modelo,
        anio: data.anio,
        cobradorid: data.cobradorid,
        fechacita: data.fechacita,
        ubicacion: data.ubicacion,
        estatus: data.estatus,
        comentarios: values.message,
        motivo: values.motivo,
        motivoid: motivoId
      }
    }

    const intento = {
      "anyObject": {
        id: data.id_cita,
        bloqueoid: false
      }
    }

    if (values.resultado === "2") {
      await UnidadesRecuperadasService.upsertCitasEntregaUnidad(intento)
    }

    const response = await UnidadesRecuperadasService.upsertCitasEntregaUnidadxVehiculo(requestData)



    if (response.status === 200) {
      const successMessage = response.data?.mensaje || "¡Operación exitosa!";
      toast.success(successMessage);
      setIsOpen(false); // Cierra el modal si todo va bien
    } else {
      console.error('Error al enviar los datos:', response);
    }
  };

  const HandleGetMotivos = async () => {
    setmotivoItems([]);
    try {
      const res = await UnidadesRecuperadasService.getAllMotivoRechazoEntregaUnidad()

      const motivos = res.data.responseData?.map(item => ({
        value: item.id,
        label: item.nombre,
      })) || [];

      setmotivoItems(motivos);
    } catch (error) {
      console.error('Error al cargar motivos:', error);
    }
  };

  // useEffect para llamar a HandleGetMotivos cuando el resultado cambie
  useEffect(() => {
    if (resultado === '2') { // Si se selecciona "Rechazar"
      HandleGetMotivos(); // Carga los motivos
    } else {
      setmotivoItems([]); // Limpiamos los motivos si no es "Rechazar"
    }
  }, [resultado]); // Se ejecuta cuando cambia el valor de resultado

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={{
          resultado: '', // Valor inicial de 'resultado'
          motivo: '', // Agrega motivo al initialValues
          message: '' // Agrega message al initialValues
        }}
        onSubmit={(values) => {
          handlerGuardar(values); // Envia los datos al servicio al confirmar
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Modal isOpen={isOpen} color='#fff' width={500}>
            <div>
              <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                  <div className="col col-12 dias-festivos-modal-underline">
                    <div>
                      <h4><strong>Solicitud de visita</strong></h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-6 mt-4">
                    <SelectField
                      id="resultado"
                      label="Resultado"
                      name="resultado"
                      items={[
                        { label: "Aprobar", value: "1" },
                        { label: "Rechazar", value: "2" }
                      ]}
                      onChange={(option) => {
                        setResultado(option.value); // Actualiza el estado de resultado
                        setFieldValue("resultado", option.value);
                      }}
                    />
                  </div>
                  {resultado === "2" && (
                    <div className="col-lg-6 col-6 mt-4">
                      <SelectField
                        id="motivo"
                        label="Motivo"
                        name="motivo"
                        items={motivoItems} // Items cargados desde el servicio
                        onChange={(option) => {
                          setFieldValue("motivo", option.label); // Actualiza el valor del Formik
                          setMotivoId(option.value)
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-12 mt-4" style={{ display: 'flex', flexFlow: 'column' }}>
                  <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribir" />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnCloseModal"
                      className='btn btn-outline btn-block'
                      onClick={handleRechazar}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnConfirmModal"
                      className='btn btn-blue btn-block'
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default SolicitudEntregaUnidadModal;
