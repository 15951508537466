import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { TextField } from '../../../Components/TextField'
import MarcaAPI from '../../../Services/Catalogos/Marca'
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField'
import VehiculoService from '../../../Services/Vehiculo/vehiculo'

const Vehiculo = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage,existingData }) => {
    
    let initial = {
        vehiculosId: item?.vehiculosId ?? "",
        claveMarca: item?.claveMarca ?? "",
        claveModelo: item?.claveModelo ?? "",
        claveVersion: item?.claveVersion ?? "",
        claveYear: item?.claveYear ?? "",
        status: item?.status ?? true,
        marca: "",
        modelo: "",
        year: "",
        version: "",
        statusM: false
    }
    const [initialValue, setInitialValue] = useState(initial)
    const [dataMarca, setDataMarca] = useState([]);
    const [dataModelo, setDataModelo] = useState([]);
    const [dataYear, setDataYear] = useState([]);
    const [dataVersion, setDataVersion] = useState([]);

    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveYear, setClaveYear] = useState("");

    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const validate = Yup.object({
        claveMarca: Yup.string()
            .required("El campo es requerido."),
        claveModelo: Yup.string()
            .required("El campo es requerido."),
        claveVersion: Yup.string()
            .required("El campo es requerido."),
        claveYear: Yup.string()
            .required("El campo es requerido."),

    });

    useEffect(() => {
        handleGetAllMarca();
        if (item !== null) {
            handleChangeMarca(item.claveMarca);
            handleChangeModelo(item.claveModelo);
            handleChangeYear(item.claveYear);
        }
    }, [])


    const handleGetAllMarca = async () => {
        await VehiculoService.getAllMarca()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveMarca,
                        label: item.nombreMarca
                    })
                });
                setDataMarca(NewData);
            }).catch(e => {
            })
    }
    const handleChangeMarca = async (value) => {
        setClaveMarca(value);
        setDataModelo([]);
        setDataYear([]);
        setDataVersion([]);        
        await VehiculoService.getAllModeloById(value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveModelo,
                        label: item.nombreModelo
                    })
                });
                setDataModelo(NewData);
            }).catch(e => {
            })
    }
    const handleChangeModelo = async (value) => {
        setClaveModelo(value);
        if(value === "")
            setDataYear([]);       
        await VehiculoService.getAllYearById(claveMarca, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveYear,
                        label: item.nombreYear
                    })
                });
                setDataYear(NewData);
            }).catch(e => {
            })
    }
    const handleChangeYear = async (value) => {
        setClaveYear(value);
       
        await VehiculoService.getAllVersionById(claveMarca, claveModelo, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveVersion,
                        label: item.nombreVersion
                    })
                });
                setDataVersion(NewData);
            }).catch(e => {
            })
    }
    
    const [errorMessage, setErrorMessage] = useState("");
    const handleEditVehiculo = async (item) => {
        
        if (existingData.find(x=>x.motivo?.toLowerCase() === item.motivo?.toLowerCase())) {
            setErrorMessage(" ya existe.");
            return;
        }
        if (action === 'add') {
            await VehiculoService.addVehiculo(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await VehiculoService.updateVehiculo(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    const handleSaveCat = async (values) => {
        if (selectedValue === "Marca") {
            await VehiculoService.addMarca(values.marca)
                .then(res => {
                    setIsOpenModalAdd(false);
                    handleGetAllMarca();
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpenModalAdd(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (selectedValue === "Modelo") {
            await VehiculoService.addModelo(values.modelo, claveMarca)
                .then(res => {
                    setIsOpenModalAdd(false);
                    handleChangeMarca(claveMarca);
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpenModalAdd(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (selectedValue === "Año") {
            await VehiculoService.addYear(values.year, claveModelo, claveMarca)
                .then(res => {
                    setIsOpenModalAdd(false);
                    handleChangeModelo(claveModelo);
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpenModalAdd(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (selectedValue === "Version") {
            await VehiculoService.addVersion(values.version, claveModelo, claveMarca, claveYear)
                .then(res => {
                    setIsOpenModalAdd(false);
                    handleChangeYear(claveYear);
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpenModalAdd(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleEditVehiculo(values);
            }}
        >
            {({ isValid, values, setFieldValue, setFieldTouched }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row d-flex align-items-center">
                                    <div className="col-sm-11">
                                        <SelectField label="Marca" name="claveMarca" items={dataMarca} onBlur={() => setFieldTouched("claveMarca", true)} onChange={(event) => {
                                            setFieldValue("claveMarca", event.value);
                                            handleChangeMarca(event.value);
                                            setFieldValue("claveModelo", "");
                                            setFieldValue("claveYear", "");
                                            setFieldValue("claveVersion", "");
                                            
                                            // initial.claveModelo="";
                                        }} />
                                    </div>
                                    <fieldset className='col-sm-2 btn btn-add' onClick={() => { setIsOpenModalAdd(true); setSelectedValue("Marca") }}>+</fieldset>

                                </div>
                                <div className="mb-3 row d-flex align-items-center">
                                    <div className="col-sm-11">
                                        <SelectField label="Modelo" name="claveModelo" items={dataModelo} onBlur={() => setFieldTouched("claveModelo", true)} onChange={(event) => {
                                            setFieldValue("claveModelo", event.value);
                                            handleChangeModelo(event.value);
                                            setFieldValue("claveYear", "");
                                            setFieldValue("claveVersion", "");

                                        }} />
                                    </div>
                                    <fieldset className='col-sm-2 btn btn-add' disabled={claveMarca !== "" ? false : true} onClick={() => { setIsOpenModalAdd(true); setSelectedValue("Modelo") }}>+</fieldset>
                                </div>
                                <div className="mb-3 row d-flex align-items-center">
                                    <div className="col-sm-11">
                                        <SelectField label="Año" name="claveYear" items={dataYear} onBlur={() => setFieldTouched("claveYear", true)} onChange={(event) => {
                                            setFieldValue("claveYear", event.value);
                                            handleChangeYear(event.value);
                                            setFieldValue("claveVersion", "");
                                        }} />
                                    </div>
                                    <fieldset className='col-sm-2 btn btn-add' disabled={claveModelo !== "" ? false : true} onClick={() => { setIsOpenModalAdd(true); setSelectedValue("Año") }}>+</fieldset>
                                </div>
                                <div className="mb-3 row d-flex align-items-center">
                                    <div className="col-sm-11">
                                        <SelectField label="Versión" name="claveVersion" items={dataVersion}  onChange={(event) => {
                                            setFieldValue("claveVersion", event.value);
                                        }} />
                                    </div>
                                    <fieldset className='col-sm-2 btn btn-add' disabled={claveYear !== "" ? false : true} onClick={() => { setIsOpenModalAdd(true); setSelectedValue("Version") }}>+</fieldset>
                                </div>
                                <section className='my-3'>
                                    <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2' type="submit" disabled={!(isValid)}>{item !== null ? 'Editar' : 'Guardar'}</button>
                                    {/* onClick={formik.submitForm} */}
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={isOpenModalAdd} color='#fff'>
                        <section className='m-4' style={{ width: '20rem' }}>
                            <section className='d-flex justify-content-between'>
                                <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                            </section>
                            {/* <div className="mb-3">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                <div className="col-ms-6 form-check form-switch">
                                    <label className="form-check-label">{values?.statusM ? "Activo" : "Inactivo"}</label>
                                    <Field className="form-check-input" type="checkbox" name="statusM" />
                                </div>
                            </div> */}
                            {
                                selectedValue === "Marca" &&
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8">
                                            <TextField label="Marca" name="marca" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selectedValue === "Modelo" &&
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8">
                                            <TextField label="Modelo" name="modelo" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selectedValue === "Año" &&
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8">
                                            <TextField label="Año" name="year" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selectedValue === "Version" &&
                                <div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8">
                                            <TextField label="Version" name="version" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <span className="text-danger">{errorMessage}</span>
                            <section className='my-3 d-flex justify-content-betwen mt-4'>
                                <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpenModalAdd(false) }}>Cancelar</button>
                                <fieldset className='btn mx-1 px-5' onClick={() => { handleSaveCat(values) }}>Guardar</fieldset>
                            </section>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default Vehiculo