import React from 'react'
import { useState, useEffect } from 'react';
import Llamada from '../../Services/Cartera/Llamadas';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { useDispatch } from 'react-redux';
import useLlamada from '../../Hooks/Incidencias/useLlamada';
import useGarantia from '../../Hooks/Incidencias/useGarantia';
import Garantias from '../../Services/Cartera/Garantias';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../Utils/functions'
import Cierres from '../../Services/Resguardos/Cierres';
import { setGruaSlice } from '../../Redux/Slice/IncidenciasSlice/gruaSlice';

const DetailAuthCitaModal = ({ modalMessage, setModalMessage, notificacion, getEntrega, getContratoCita, crearProcesoFirma, GetGrua, getCitaDesinstalacion, getEntregaGestoria,getCambioPlacas, getAltaPlacas, getTarjetaCirculacion, reset }) => { // Prop => AuthorizationType; 1 Garantia, 2 Grua;
    const dispatch = useDispatch();

    const [cita, setCita] = useState({});
    const [loading, setLoading] = useState(false);
    const useLlamda = useLlamada();
    const Garantia = useGarantia();

    useEffect(() => {
        setCita(modalMessage.data);
    }, [modalMessage.data]);

    const handleRechazar = () => {
        let res = {}
        setModalMessage({ isOpen: true, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
    }

    /*
    AutorizarGarantia => 1
    AutorizarGrua => 2
    */

    const acciones = {
        1: 'AutorizarGarantia',
        2: 'AutorizarGrua',
        3: 'AutorizarEntrega',
        4: '',
        5: '',
        6: 'AutorizarCierreCita',
        7: 'AutorizaEntregaExpediente',
        8: 'AutorizarDesinstalacionDatamovil',
        9: 'AutorizaCambioPlacas',
        10: 'AutorizaAltaPlacas',
        11: 'AutorizaTarjetaCirculacion',
        12: 'AutorizaGestoriaEntrega'
    }

    const handleAutorizar = () => { // 1 
        setLoading(true);

        let payload = {
            accion: acciones[modalMessage.authorizationType],
            generalid: cita.generalId,
            garantiaid: cita.garantiaId,
            cierreContratoId: cita.garantiaId
        }

        const date = new Date();
        const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");

        const contenido = {
            VARIABLE_FECHA: fragmentsDate[0],
            VARIABLE_HORA: fragmentsDate[1],
            VARIABLE_UBICACIÓN: cita.ubicacion,
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_AÑO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreYear,
            VARIABLE_VIN: cita.vin,
            VARIABLE_CONTRATO: cita.numContrato
        }


        Llamada.AutorizarRechazar(payload).then(res => { //Actualiza la llamada
            if (modalMessage.Origin !== "Listado" && modalMessage.Origin !== "Resguardo") {
                if (modalMessage.authorizationType === 2) {
                    Garantia.handlerGetGruas(cita.garantiaId)
                } else {
                    useLlamda.GetGarantiaByLlamadaIdasync();
                }
            } else if (modalMessage.Origin === "Resguardo") {
                if (modalMessage.authorizationType === 2) {
                    actualizarGrua();
                    crearUbicacionResguardo();
                    GetGrua();
                    setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })

                }
            } else {
                if (modalMessage.authorizationType === 1) {
                    Garantias.creardatosenviar({
                        modulo: 'Revisión de Unidad Aprobada',
                        tipoCorreoId: 27,
                        contenido: JSON.stringify(contenido)
                    }).catch(err => {
                        console.log("Error al enviar correo Revisión de Unidad Aprobada:", err);
                    })
                }
            }

            if (modalMessage.authorizationType === 2) {
                const contenidoEmail = {
                    VARIABLE_FECHA: fragmentsDate[0],
                    VARIABLE_HORA: fragmentsDate[1],
                    VARIABLE_UBICACIÓN: cita.ubicacion,
                    VARIABLE_MARCA: cita.nombreMarca,
                    VARIABLE_MODELO: cita.nombreModelo,
                    VARIABLE_AÑO: cita.nombreYear,
                    VARIABLE_VERSION: cita.nombreYear,
                    VARIABLE_VIN: cita.vin,
                    VARIABLE_CONTRATO: cita.numContrato,
                    VARIABLE_EJECUTIVO: "",
                }
                Garantias.creardatosenviar({
                    modulo: 'Recolección de Unidad',
                    tipoCorreoId: 29,
                    contenido: JSON.stringify(contenidoEmail)
                }).then(res => {
                    dispatch(setGruaSlice({
                        "llamadaId":cita.llamadaId,
                        "garantiaId":cita.garantiaId,
                        "generalId":cita.generalId,
                        "folioCita":cita.folioCita,
                        "folioLlamada":cita.folioLlamada,
                        "folioGarantia":cita.folioGarantia,
                        "numContrato":cita.numContrato,
                        "vin":cita.vin,
                        "claveMarca":cita.claveMarca,
                        "nombreMarca":cita.nombreMarca,
                        "claveModelo":cita.claveModelo,
                        "nombreModelo":cita.nombreModelo,
                        "claveYear":cita.claveYear,
                        "nombreYear":cita.nombreYear,
                        "ejecutivoGarantia":cita.ejecutivoGarantia,
                        "ubicacionId":cita.ubicacionId,
                        "ubicacion":cita.ubicacion,
                        "tipoGarantiaId":cita.tipoGarantiaId,
                        "tipoGarantia":cita.tipoGarantia,
                        "clasificacionFallaId":cita.clasificacionFallaId,
                        "falla":cita.falla,
                        "tipoReporteId":cita.tipoReporteId,
                        "tipoReporte":cita.tipoReporte,
                        "telefono":cita.telefono,
                        "fechaCita":cita.fechaCita,
                        "estatusCita":cita.estatusCita,
                        "_bloqueoId":cita._bloqueoId,
                        "gruaStatusId" : 30
                    }))
                })
                    .catch(err => {
                        console.log("Error al enviar correo Recolección de Unidad:", err);
                    })
            }

            if (modalMessage.authorizationType === 3) {
                actualizarEntrega();
                notificacion();
                getEntrega();
            }

            if (modalMessage.authorizationType === 6) {
                actualizarCita();
                crearProcesoFirma();
                setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
            }

            if (modalMessage.authorizationType === 7) {
                const contenidoEmail = {
                    VARIABLE_FECHA : fragmentsDate[0],
                    VARIABLE_HORA : fragmentsDate[1],
                    VARIABLE_MARCA : cita.nombreMarca,
                    VARIABLE_MODELO : cita.nombreModelo,
                    VARIABLE_AÑO : cita.nombreYear,
                    VARIABLE_VERSION : cita.nombreYear,
                    VARIABLE_VIN : cita.vin,
                    VARIABLE_CONTRATO : cita.numContrato,
                    VARIABLE_COMENTARIO: "",
                }
                Garantias.creardatosenviar({
                    modulo: 'Cita Aprobada Entrega Documentos',
                    tipoCorreoId: 49,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Cita Aprobada Entrega Documentos:", err);
                })

                getContratoCita(); 
                setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
            }

            if(modalMessage.authorizationType === 8){
                const contenidoEmail = {
                    VARIABLE_FECHA : fragmentsDate[0],
                    VARIABLE_HORA : fragmentsDate[1],
                    VARIABLE_MARCA : cita.nombreMarca,
                    VARIABLE_MODELO : cita.nombreModelo,
                    VARIABLE_AÑO : cita.nombreYear,
                    VARIABLE_VERSION : cita.nombreVersion,
                    VARIABLE_VIN : cita.vin,
                    VARIABLE_CONTRATO : cita.numContrato,
                    VARIABLE_COMENTARIO: "",
                }
                Garantias.creardatosenviar({
                    modulo: 'Datamovil Aprobado',
                    tipoCorreoId: 54,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Datamovil Aprobado:", err);
                })
                setModalMessage({ isOpen: false });
                getCitaDesinstalacion();
            }

            if(modalMessage.authorizationType === 9){
                getCambioPlacas();
                sendNotCambioPlacas();
            }
            if(modalMessage.authorizationType === 10){
                getAltaPlacas();
                sendNotAltaPlacas();
            }
            if(modalMessage.authorizationType === 11){
                getTarjetaCirculacion();
                sendNotTarjetaCirculacion();
            }
            if(modalMessage.authorizationType === 12){
                getEntregaGestoria();
                // sendNotificationGestoriaEntrega();
            }

            setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

    }

    const crearUbicacionResguardo = async () => {
        let params = {
            resguardoId: cita.garantiaId,
            resguardoUbicacionStatusId: 10
        }
        await Cierres.resguardoUbicacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó el registro de Ubicación resguardo correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el registro de Ubicación resguardo!"));
            });
    }

    const actualizarGrua = async () => {
        let params = {
            concluido: true,
            origenId: cita.garantiaId,
            GruaStatusId: 30,
            origen: "resguardo"
        }

        await Llamada.UpdateGruabyBarantiaId(params)
            .then(resp => {
                toast.success(message("Se autorizó la cita correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar autorizar la cita!"));
            });
    }

    const actualizarCita = async () => {
        let params = {
            cierreContratoId: cita.garantiaId,
            autorizado: true,
            cierreContratoCitaStatusId: 30,
            concluido: true,
        }

        await Cierres.cierreContratoCitaActualizar(params)
            .then(resp => {
                getContratoCita();
                toast.success(message("Se actualizaron los datos de la cita correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar los datos de la cita!"));
            });
    }

    const actualizarEntrega = async () => {

        let params = {
            garantiaId: cita.garantiaId,
            garantiaEntregaStatusId: 30,
            statusCita: 2
        };

        await Garantias.garantiaEntregaActualizar(params)
            .then(resp => {
                toast.success(message("La entrega se autorizó correctamente"));
            })
            .catch(err => {

                toast.error(message("¡Oops, hubo un error al autorizar la entrega!"));
            });
    }

    const sendNotCambioPlacas = () => {

        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
        }
        Garantias.creardatosenviar({
            modulo: 'Cita aprobada de extravío de placas',
            tipoCorreoId: 60,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Cita aprobada de extravío de placas:", err);
        })
    }

    const sendNotAltaPlacas = () => {

        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
        }
        Garantias.creardatosenviar({
            modulo: 'Cita aprobada de entrega placas',
            tipoCorreoId: 63,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Cita aprobada de entrega placas:", err);
        })
    }

    const sendNotTarjetaCirculacion = () => {

        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
        }
        Garantias.creardatosenviar({
            modulo: 'Cita aprobada de tarjeta de circulación',
            tipoCorreoId: 66,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Cita aprobada de tarjeta de circulación:", err);
        })
    }


    return (
        <div>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
            </section>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>{modalMessage.title}</strong> <div className="badge badge-blue text-capitalize">{cita.gravedad}</div></h6>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <div className="row">
                            <small id="AT_TxtBasicModalMenssage">A continuación se muestra el detalle de la cita agendada para el vehículo:</small>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <small><strong>Contrato: </strong>{cita.numContrato}</small>
                            </div>
                            <div className="col-auto">
                                <small><strong>Folio llamada: </strong>{cita.folioLlamada}</small>
                            </div>
                        </div>
                        <div className="row mt-4 mb-3">
                            <div className="col-1">
                                <i className="icon-dark bx bx-car"></i>
                            </div>
                            <div className="col-11">
                                <div className="row">
                                    <div className="col-auto">
                                        <small>VIN: {cita.vin}</small>
                                    </div>
                                    <div className="col-auto">
                                        <small>Marca: {cita.nombreMarca}</small>
                                    </div>
                                    <div className="col-auto">
                                        <small>Modelo: {cita.nombreModelo}</small>
                                    </div>
                                    <div className="col-auto">
                                        <small>Año: {cita.nombreYear}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-1">
                                <i className="icon-dark bx bx-time-five"></i>
                            </div>
                            <div className="col-11">
                                <div className="row">
                                    <div className="col-auto">
                                        <small>{cita.fechaCita}</small>
                                    </div>
                                    <div className="col-auto">
                                        <small>{cita.ubicacion}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            modalMessage.authorizationType !== 3 &&
                            modalMessage.authorizationType !== 9 &&
                            modalMessage.authorizationType !== 10 &&
                            modalMessage.authorizationType !== 11 &&
                            modalMessage.authorizationType !== 12 &&
                            <div className="row mb-3">
                                <div className="col-1">
                                    <i className="icon-dark ri-eye-line"></i>
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-auto">
                                            <small>Tipo de garantía: {cita.tipoGarantia} | Clasificación de la falla: {cita.falla}</small>
                                        </div>
                                        <div className="col-auto">
                                            <small><strong>Tipo de reporte: {cita.tipoReporte}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            cita.ejecutivoGarantia &&
                            <div className="row mb-1">
                                <div className="col-1">
                                    <i className="icon-dark ri-user-3-line"></i>
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-auto">
                                            <small>Ejecutivo: {cita.ejecutivoGarantia}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleRechazar() }}>Rechazar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleAutorizar() }}>Autorizar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default DetailAuthCitaModal