import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Llamada from '../../../Services/Cartera/Llamadas';

const useAgendarCita = ({ handleConcluido, paso }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        servicioId: "",
        generalId: "",
        tipoServicioId: 0,
        procesoId: 0,
        tipoSolicitudId: "",
        comentarios: "",
        paso: 0,
        servicioAutorizado: true,
        servicioMotivoRechazo: null,
        comentariosRechazo: null,
        asignado: false,
        fechaCita: "",
        horaCita: "",
        tipoUbicacion: null,
        ubicacion: null,
        bloqueoId: null,
        citaAutorizada: null,
        arriboCita: null,
        usuarioAutorizo: null,
        vehiculoEnUbicacion: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaAlta: "",
        fechaUpdate: null,
        concluido: null,
        fechaConcluido: null,
        estadoId: null,
        municipioId: null,
        coloniaId: null,
        calle: null,
        numeroExt: null,
        fechaAutorizacion: null,
        tipoOrden: "",
        tipoServicio: "",
        proceso: "",
        usuarioSolicita: "",
        usuarioAprobo: null,
        usuarioActualizaStr: null,
        concluirRegistroInicioServicio: false
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getEstados();
        getDataCita();
    }, []);

    const mapResponseData = (data, valueKey, labelKey) =>
        data.map(item => ({ value: item[valueKey], label: item[labelKey] }));

    const getDataCita = async () => {
        try {
            setLoading(true);
            const res = await DSystemsServicios.getServicio(id);
            const data = res.data;

            if (data.fechaCita) data.fechaCita = data.fechaCita.split("T")[0];
            if (data.estadoId) await getMunicipios(data.estadoId);
            if (data.municipioId) await getColonias(data.municipioId);
            setInitialValue({ ...initialValue, ...data });

            if (paso === 1) {
                handleConcluido({ concluido: data.arriboCita });
            } else {
                handleConcluido({ concluido: data.concluirRegistroInicioServicio })
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormAgendarCita = async (values) => {
        console.log(values, "Envio de datos")
        try {
            setLoading(true);
            await DSystemsServicios.updateServicio(values);
            getDataCita()
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormUbicacionVehiculo = async (values) => {
        try {
            setLoading(true);
            await DSystemsServicios.updateServicioInicio(values);
            getDataCita()
            //Aqui se agrega el insertar costo
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getEstados = async () => {
        try {
            const res = await Llamada.GetEstadoMx();
            setEstados(mapResponseData(res.data.data, 'edoMexicoId', 'estado'));
        } catch (error) {
            console.error(error);
        }
    };

    const getMunicipios = async (estadoId) => {
        if (!estadoId) return;
        try {
            const res = await Llamada.GetMunicipio(estadoId);
            setMunicipios(mapResponseData(res.data.data, 'municipioId', 'municipio'));
        } catch (error) {
            console.error(error);
        }
    };

    const getColonias = async (municipioId) => {
        if (!municipioId) return;
        try {
            const res = await Llamada.GetColonia(municipioId);
            setColonias(mapResponseData(res.data.data, 'coloniaId', 'colonia'));
        } catch (error) {
            console.error(error);
        }
    };

    return {
        initialValue,
        loading,
        setLoading,
        submitFormAgendarCita,
        submitFormUbicacionVehiculo,
        getEstados,
        getMunicipios,
        getColonias,
        estados,
        municipios,
        colonias,
        opcionesBool,
        tipoUbicaciones
    };
};

export default useAgendarCita;
