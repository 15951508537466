import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import CorreosService from '../../Services/Correos/Correos';
import { TextAreaField } from '../../Components/TextAreaField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const ModalMotivoCambio = ({ isOpen, setIsOpen, item, modalMessage, setModalMessage,setIsOpenModalEtapa }) => {
    
    let initial = {
        motivo: item?.motivo ?? "",
        producto: item?.producto ?? "",
        tipoCompra: item?.tipoCompra ?? "",
    }
    const [initialValue, setInitialValue] = useState(initial);

    useEffect(() => {
    }, [])

    const validate = Yup.object({
        motivo: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        // .matches(
        //     "^[a-z0-9.]+@[a-z]+\.[a-z]{2,4}((,\s?){1}[a-z0-9.]+@[a-z]+\.[a-z]{2,4})*$",
        //     "ingrese correos separados por coma"
        // // ),
        // etapaId: Yup.string()
        //     .required("El campo es requerido."),

    });

    const handleAddEdit = async (value) => {
        
        await EtapaService.UpdateMotivo(value)
            .then(res => {
                
                setIsOpen(false);
                // handleGetAll(1);
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                setIsOpenModalEtapa(false);
            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5 mb-3'><strong>Guardar motivo</strong></span>
                                <span style={{ fontSize: '12px' }}>Todos los cambios que realices se verán reflejados en el flujo correspondiente de las etapas editadas.</span>
                            </div>
                            <Form>
                                <div className="mb-3 row my-4">
                                    <div className="col-sm-12">
                                        <TextAreaField label="A continuación escribe el motivo del cambio:" className="form-control col-12" rows="3" name="motivo" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' type="submit" disabled={!(isValid)}>Guardar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalMotivoCambio