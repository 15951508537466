import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import SeguimientoGestoriaService from '../../../../Services/Gestoria/SeguimientoGestoria';
import DataTable from '../../../../Components/datatable/DataTable';

const GestoriaRegistroTramiteCard = ({ reload }) => {
    const { refFormik } = useRef();
    const { id } = useParams();  
    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [data, setdata] = useState([]);
    let initial = {

    }
    const columns = [
        { field: 'usuario', headerName: 'Usuario' },
        { field: 'fechaTramite', headerName: 'Fecha de trámite' },
        { field: 'tipoTramite', headerName: 'Tipo de trámite' },
        { field: 'motivoRechazo', headerName: 'Motivo rechazado' },
        { field: 'comentariosRU', headerName: 'Comentarios' },
        { field: 'estatusTramite', headerName: 'Estatus' },
        { field: 'acciones', headerName: 'Acciones' },
    ];
    const [initialValue, setInitialValue] = useState(initial);

    useEffect(() => {
        getData()
    }, [reload]);

    const getData = async () => {
        setLoading(true)
        await SeguimientoGestoriaService.GetProcesoTramiteHistorialById(id)
            .then(resp => {
                let data = resp?.data.data;
                let newData = data.map((item) => {
                    return { ...item, colorTexto: item?.codEstatus === 1 ? "#47A066" : "#CE2222", colorFondo: item?.codEstatus === 1 ? "#D8F2CD" : "#F4D4D4" }
                })                                
                setdata(newData);
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const validate = Yup.object().shape({
    });

    const submitForm = async (values) => {
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRegistroTramite" role="button" aria-expanded="false" aria-controls="collapseRegistroTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span></span> <strong>Registro de Tramite</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    {/* <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small> */}
                                </div>
                            </div>
                            <div className="collapse" id="collapseRegistroTramite">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-12 table-responsive'>
                                                    <DataTable
                                                        column={columns}
                                                        data={data}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GestoriaRegistroTramiteCard