import React, { useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useSelector } from 'react-redux'
// import { toast, Toaster } from 'react-hot-toast';

const InformacionUltimoCreditoCard = ({claves}) => {
    const { Url } = useSelector((state) => state.UrlSlice);
    const { numContrato,usoUnidad } = useSelector((state) => state.DataVinSlice);
    const { comentarioTecnico } = useSelector((state) => state.InformacionUltimoCreditoSlice);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <>
                        <div>
                            <div className='justify-content-start px-4 py-3 align-items-center'>
                                <div className="row">
                                    <div className="col col-12">
                                        <h6><strong>{modalMessage.title}</strong></h6>
                                    </div>
                                </div>
                                <div className="row px-2 mt-3 mb-2">
                                    <div className="col col-12 py-2" style={{"background": "#F5F5F5"}}>
                                        <span id="AT_TxtBasicModalMenssage">{modalMessage.message}</span>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Cerrar</button>
                                </section>
                            </div>
                        </div>
                    </>
                }
            </Modal>
            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                    <div className="col-12 underline-border">
                        <div className="row">
                            <div className="col-auto" style={{"marginTop": "5px"}}>
                                <h6><strong>Información último crédito</strong></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>No. de contrato:</strong> {numContrato}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Origen:</strong> Garantía</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Cliente:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Proceso:</strong> Cambio o devolución</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Último pago: :</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Subproceso:</strong> Proceso de firma</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Uso de unidad:</strong> {usoUnidad}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Tipo de falla:</strong> </small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        {
                            (
                                comentarioTecnico !== null &&
                                // comentarioTecnico !== undefined &&
                                comentarioTecnico?.trim().length > 0
                            ) &&
                            <button 
                                className='btn'
                                onClick={(event) => setModalMessage({ isOpen: true, type: 1, title: 'Comentarios', message: comentarioTecnico})}
                                >
                                Ver comentarios
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>)
}

export default InformacionUltimoCreditoCard