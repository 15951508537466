import { set } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import Logs from '../../Services/Logs/Logs';
import { useNavigate } from 'react-router-dom';
import FileSaver, { saveAs } from 'file-saver';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SyncEliminarIntelimotor from '../../Services/SyncInventario/SynEliminarIntelimotor';

const useEliminarIntelimotor = () => {
  const refBtn = useRef();
  const refBtnExp = useRef();
  let ids = [];
  const navigate = useNavigate();
  const refresh = () => window.location.reload(true)
  const columns = [
    { field: 'fechaHora', headerName: 'Fecha y hora', width: 250 },
    { field: 'vin', headerName: 'Vin', width: 160 },
    { field: 'nombreMarca', headerName: 'Marca', width: 200 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 200 },
    { field: 'nombreVersion', headerName: 'Versión', width: 200 },
    { field: 'resultado', headerName: 'Error registrado', width: 250 },
  ];  
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [moduloId, setModuloId] = useState("");
  const [procesoId, setProcesoId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [item, setItem] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isenablebtn, setIsenablebtn] = useState(true);
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [initialPage, setInitialPage] = useState(0);  
  const [pageRows, setpageRows] = useState(10)
  const [DataMarca, setDataMarca] = useState([]);
  const [DataModelo, setDataModelo] = useState([]);
  const [claveMarca, setClaveMarca] = useState("");
  const [claveModelo, setClaveModelo] = useState("");

  useEffect(() => {
    handleGetAllMarca();
  }, [])

  useEffect(() => {
    handleGetAllInterfaz(1);
    setInitialPage(0);
  }, [Str, dateTime, claveMarca, claveModelo,pageRows])

  //selectList
  const handleGetAllMarca = async () => {
    await VehiculoService.getAllMarca()
      .then(res => {
        let NewData = [];        
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveMarca,
            label: item.nombreMarca
          })
        });
        setDataMarca(NewData);
      }).catch(e => {
      })
  }
  const handleChangeMarca = async (value) => {
    setClaveMarca(value);
    await VehiculoService.getAllModeloById(value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveModelo,
            label: item.nombreModelo
          })
        });
        setDataModelo(NewData);
      }).catch(e => {
      })
  }
  const handleGetModulo = async () => {
    setLoading(true);
    await Logs.getList("modulo")
      .then(res => {
        setDataModulo(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetProceso = async () => {
    setLoading(true);
    await Logs.getList("proceso")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos los procesos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });

        setDataProceso(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetCategoria = async () => {
    setLoading(true);
    await Logs.getList("categoria")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todas las categorias"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataCategoria(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllInterfaz = async (page) => {
    setLoading(true);
    await SyncEliminarIntelimotor.getSyncEliminarIntelimotor(page, pageRows,dateTime,"",claveMarca,claveModelo,Str)
      .then(res => {        
        setData(res.data.data);
        setPageCount(res.data.totalPages)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetAllInterfaz(event.selected + 1);
  };
  const handleCheck = (cheked, value) => {
    if (cheked) {
      // ids.push({ generalId: value.generalId });
      setIdsEliminar(current => [...current, { intelimotorId: value.intelimotorId }]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item.intelimotorId !== value.intelimotorId));
    }
  }

 
  const handleClick = async () => {
    refBtn.current.disabled = true;
    setLoading(true);
    if (idsEliminar.length > 0) {      
      await SyncEliminarIntelimotor.Deleteintelimotor({ ids: idsEliminar })
        .then(res => {
          
          handleUnchekedColumns();
          handleGetAllInterfaz(1);
          setLoading(false);
          setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.message })
          refBtn.current.disabled = false;
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtn.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  return {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleCheck,
    pageCount,
    handlePageClick,
    setStr,
    setDateTime,
    initialPage,
    refBtn,        
    setpageRows,
    DataMarca,
    DataModelo,
    handleChangeMarca,
    setClaveModelo,
    handleClick 
  }

}

export default useEliminarIntelimotor