import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import { FormatNumberMoney2, NumFolio, handleUnchekedColumns, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import Spinner from '../../../Components/Loadig';
import DataTableCheck from '../../../Components/datatable/DataTableCheck';
import Select from 'react-select';
import PagoDesembolsoService from '../../../Services/Seguros/PagoDesembolsoService';
import ComisionesService from '../../../Services/Seguros/ComisionesService';

const ModalConfirmProcesoPagoComision = ({ isOpen, setIsOpen, handleGetAll, toast, item }) => {
    const refbtn = useRef();
    const { comentarios, totalAProcesar, totalRegistro, totalSinProcesar, dataAProcesar, jsonData } = item;    
    let initial = {
        comentarios: comentarios ?? "",
        dataAProcesar: dataAProcesar,
        totalCalculado: "",
        totalCobrado: "",
        diferencia: "",
        aseguradora: jsonData[0]?.Aseguradora,
        ejecutivo:jsonData[0]?.usuario,
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [str, setStr] = useState("");
    const [DataSelect, setDataSelect] = useState(false);
    const [data, setdata] = useState(jsonData);

    let columns = [
        { field: 'Folio', headerName: 'Folio', width: 120 },
        { field: 'VIN', headerName: 'VIN', width: 120 },
        { field: 'Contrato', headerName: 'Contrato', width: 150 },
        { field: 'comisionStr', headerName: 'Monto', width: 150 },
        { field: 'usuario', headerName: 'Usuario', width: 280 },
        { field: 'Fecha_inicio', headerName: 'Fecha', width: 250 },

    ];
    let dataSelect = [
        { label: "todos", value: "" },
        { label: "Pendientes", value: false },
        { label: "Por Procesar", value: true }
    ];

    useEffect(() => {
        handleChangeData();
    }, [DataSelect, str])

    const handleChangeData = () => {
        if (DataSelect === "" && str === "")
            setdata(jsonData);
        else if (DataSelect !== "" || str !== "")
            setdata(jsonData.filter(x => {
                if (str === "" && DataSelect !== "") {
                    return (x.isValid === DataSelect)
                } else if (str !== "" && DataSelect === "") {
                    return (x.VIN.toLowerCase().includes(str.toLowerCase()))
                } else if (str !== "" && DataSelect !== "") {
                    return (x.isValid === DataSelect && x.VIN.toLowerCase().includes(str.toLowerCase()))
                }
            }
            ));
    }
    const handleGetTotales = () => {
        let totales = { calculado: 0, cobrado: 0, diferencia: 0 };
        jsonData.map(item => {
            totales.calculado += item.Precio;
            if (item.isValid)
                totales.cobrado += item.Monto_Pagado
        });
        return { ...totales, diferencia: totales.calculado - totales.cobrado };
    }
    const handleSubmit = async (value) => {
        if (totalAProcesar == 0) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No hay registros por procesar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setLoading(true)
        await ComisionesService.SaveProcesoPagoComision(value)
            .then(res => {
                setLoading(false);
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", "Registros procesados correctamente."));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) => {            
                handleSubmit({
                    ...values,
                    totalCalculado: handleGetTotales().calculado,
                    totalCobrado: handleGetTotales().cobrado,
                    diferencia: handleGetTotales().diferencia,
                    dataNoProcesado:jsonData.filter(x=>!x.isValid)
                });             
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Registros por procesar</strong></span>
                            <span style={{ fontSize: '13px' }}>A continuación se muestran los registros con la opción para confirmar o cancelar la carga</span>
                        </div>
                        <section className='mx-4' style={{ width: '55rem' }}>
                            <Form>
                                <div className="mb-3">
                                    <span style={{ fontSize: '13px' }}><strong>Total de registros:</strong> {totalRegistro} | <strong>Registros por procesar:</strong> {totalAProcesar} | <strong>Registros Pendientes:</strong> {totalSinProcesar}</span> <br />
                                    <span style={{ fontSize: '13px' }}><strong>Total calculado:</strong> ${FormatNumberMoney2(handleGetTotales().calculado)} | <strong>Total cobrado:</strong> ${FormatNumberMoney2(handleGetTotales().cobrado)} | <strong>Diferencia:</strong> ${FormatNumberMoney2(handleGetTotales().diferencia)}</span>
                                </div>
                                <section className='pb-4 px-2'>
                                    <div className='row col-12'>
                                        <div className="col-lg-3 col-md-6 col-sm-12 px-1 inner-addon right-addon">
                                            <i className="glyphicon fas fa-search"></i>
                                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                                        </div>
                                        <div className='col-lg-3 col-md-6 col-sm-12 px-1'>
                                            <Select options={dataSelect} defaultValue={{ label: "Pendientes", value: false }} className='col-12' onChange={(e) => { setDataSelect(e.value) }} placeholder="Aseguradora" />
                                        </div>
                                    </div>
                                </section>
                                <section className='row col-12'>
                                    <div className='table-responsive tbl-scroll'>
                                        <DataTableCheck
                                            column={columns}
                                            data={data}
                                        />
                                    </div>
                                </section>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline col-2' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2 col-2' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default ModalConfirmProcesoPagoComision