import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useSelector } from 'react-redux';
import { message, pageRows } from '../../../Utils/functions';
import Select from 'react-select'
import DataTable from '../../../Components/datatable/DataTable'
import { TextAreaField } from '../../../Components/TextAreaField';
import CambioEtapaInventarioService from '../../../Services/Inventario/CambioEtapaInventarioService';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';

const CambioEtapaInventario = () => {
    const navigate = useNavigate();
    const refbtn = useRef();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);

    const refFormik = useRef();
    const columns = [
        { field: 'fecha', headerName: 'Fecha y hora', width: 250 },
        { field: 'usuario', headerName: 'Usuario', width: 250 },
        { field: 'nombreEtapaOrigen', headerName: 'Etapa origen', width: 130 },
        { field: 'nombreEtapaDestino', headerName: 'Etapa destino', width: 130 },
        { field: 'motivoCambio', headerName: 'Motivos', width: 160 },
        { field: 'comentarios', headerName: 'Comentario', width: 130 },
        // { field: 'costo', headerName: 'costos', width: 130 }
    ];
    const columnsCambioEtapa = [
        // { field: 'agenteSeguro', headerName: '', width: 40 },
        { field: 'nombreEtapa', headerName: 'Etapas disponibles para el cambio:', width: 350 },
    ];
    const columnsCambioEtapaHistorial = [
        { field: 'nombreEtapa', headerName: 'Etapa', width: 200 },
        { field: 'fecha', headerName: 'Fecha y hora', width: 350 },
    ];

    let initial = {
        generalId: id,
        etapaActualizada: "",
        etapaDestinoId: "",
        motivoCambioId: "",
        comentarios: "",

        VIN: item?.vin,
        marca: item?.nombreMarca,
        modelo: item?.nombreModelo,
        year: item?.nombreYear,
        version: item?.nombreVersion,
        etapaActual: item?.etapaActual || item?.etapa || item?.etapaId,
        etapaOrigenId: item?.etapa_Id,
        productoId: item?.productoId
    }

    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        motivoCambioId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });

    const [dataStatus, setDataStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [currenPage, setCurrenPage] = useState(1);
    const [dataHistorial, setDataHistorial] = useState([]);
    const [isOpenModalCofirm, setIsOpenModalCofirm] = useState(false);

    const [enablebtn, setEnablebtn] = useState(false);
    const [dataEtapa, setDataEtapa] = useState([]);
    const [dataMotivo, setDataMotivo] = useState([]);

    //
    const [pageCountEtapa, setPageCountEtapa] = useState();
    const [currentPageEtapa, setCurrentPageEtapa] = useState(1);
    const [dataHistorialEtapa, setDataHistorialEtapa] = useState([]);
    const [pageRowss, setpageRows] = useState(10)

    useEffect(() => {
        handleGetAllMotivo();
        handleGetById();
    }, [])
    useEffect(() => {
        handleDataHistoryCambioEtapa(currentPageEtapa);
    }, [pageRowss])

    const handlechekedItem = (check, item, i) => {

        setEnablebtn(check)
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (check) {
            setInitialValue({ ...initial, etapaActualizada: item.nombreEtapa, etapaDestinoId: item.etapaId })
        } else
            setInitialValue({ ...initial, etapaActualizada: "" })

    }
    const handleGetById = async () => {
        setLoading(true);
        await CambioEtapaInventarioService.getAllListaEtapas(id, item?.etapa_Id || item?.etapaId, item?.productoId)
            .then(res => {
                let item = res.data.data;
                setDataEtapa(item);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }


    const handleGetAllMotivo = async () => {
        setLoading(true);
        await CambioEtapaInventarioService.getAllMotivoCambio()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoCambioId,
                        label: item.motivoCambio
                    })

                });
                setDataMotivo(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }

    const handleDataHistoryCambioEtapa = async (page) => {
        setLoading(true);
        await CambioEtapaInventarioService.getAllHistoryCambios(id, page, pageRowss)
            .then(res => {
                setDataHistorialEtapa(res.data.data);
                setPageCountEtapa(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await CambioEtapaInventarioService.getAllHistory(id, page, 10)
            .then(res => {

                setDataHistorial(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handlePageClickEtapa = (event) => {
        setCurrentPageEtapa(event.selected + 1);
        handleDataHistoryCambioEtapa(event.selected + 1);
    };


    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        setLoading(true);
        await CambioEtapaInventarioService.AddCambioEtapaInventario(value)
            .then(res => {

                setLoading(false);
                toast.success(message("¡Correcto.!", res.data.mensaje));
                setTimeout(() => {
                    navigate('/inventario/vehiculo');
                }, 2000);
            }).catch(e => {

                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.data.mensaje));
                else
                    toast.error(message("¡Error.!", e.message));
            })

    }

    const status = () => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        >
            {({ isValid, dirty, values, setFieldValue, submitForm }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />

                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        {/* <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        /> */}
                        <section className='col-12'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2 mt-4'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehículo</strong></span>
                                            {/* <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset> */}
                                        </div>
                                        <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.CambioDeEtapasSection, AccionesRapidas.CambioDeEtapas))}>
                                            <div className='d-flex'>
                                                {/* <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => { handleGetFileById() }}><i className="icon ri-file-3-fill mx-1" ></i>Ver documento</fieldset> */}
                                                {/* <button className='btn mx-2' type="submit" >{state.action === 1 ? "Editar" : "Guardar"}</button> */}
                                                <fieldset className='btn mx-2 px-5' disabled={!enablebtn} onClick={() => { setIsOpenModalCofirm(true) }}>{"Cambiar etapa"}</fieldset>
                                            </div>
                                        </ValidatePermission>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Etapa:" name="etapaActual" type="text" holder="Escribe" />
                                            </div>

                                        </div>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.CambioDeEtapasSection, AccionesRapidas.CambioDeEtapas).Ver}>
                                        <div className='my-2 mt-5'>
                                            <div className='d-flex align-items-center'>
                                                <span><strong>Cambio de etapas</strong></span>
                                                <ValidatePermission isActive={ValidatePermissionById(4, Section.CambioDeEtapasSection, AccionesRapidas.CambioDeEtapas).Historial}>
                                                    <div className="col-sm-1 mx-2">
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                                    </div>
                                                </ValidatePermission>

                                            </div>

                                            <div className="mb-3 row">
                                                <DataTable
                                                    checkbox={true}
                                                    handleCheck={handlechekedItem}
                                                    column={columnsCambioEtapa}
                                                    data={dataEtapa}
                                                    pageCount={pageCount}
                                                    handlePageClick={handlePageClick}
                                                    currentPage={currenPage - 1}
                                                />
                                            </div>
                                            <div className="mb-3 row">
                                                <div className='d-flex justify-content-between align-items-center me-4 mt-4'>
                                                    <span className='fs-6'><strong>Historial de etapas</strong></span>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        <span style={{ fontSize: '12px' }}>Resultado por pagina</span>
                                                        <div className='mx-1'>
                                                            <Select options={pageRows()} className='col-12' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <DataTable
                                                    column={columnsCambioEtapaHistorial}
                                                    data={dataHistorialEtapa}
                                                    pageCount={pageCountEtapa}
                                                    handlePageClick={handlePageClickEtapa}
                                                    currentPage={currentPageEtapa - 1}
                                                />
                                            </div>
                                        </div>
                                    </ValidatePermission>
                                </Form>
                            </div>
                        </section>
                    </div>
                    {
                        isOpenModal &&
                        <Modal isOpen={isOpenModal} color='#fff'>
                            <div className='d-flex'>
                                <span><strong>Historial de cambio de etapas manuales</strong></span>

                            </div>
                            <DataTable
                                column={columns}
                                data={dataHistorial}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                            </section>
                        </Modal>
                    }

                    {
                        isOpenModalCofirm &&
                        <Modal isOpen={isOpenModalCofirm} color='#fff'>
                            <section className='m-4' style={{ width: '30rem' }}>
                                <div className='d-flex flex-column fs-6'>
                                    <span className='fs-5 mb-2'><strong>Motivo de cambio de etapa</strong></span>
                                    <span style={{ fontSize: '15px' }}>A continuación se debe capturar el motivo del cambio realizado: <strong>{`${values?.VIN} - ${values?.marca} ${values?.modelo}`}</strong> </span>
                                </div>
                                <div className="mb-3 mt-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField disabled label="Etapa anterior" name="etapaActual" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField disabled label="Etapa actualizada" name="etapaActualizada" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <SelectField label="Motivo" name="motivoCambioId" items={dataMotivo} onChange={(event) => {
                                            setFieldValue("motivoCambioId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                        }} />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end mt-4'>
                                    <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpenModalCofirm(false) }}>Cancelar</button>
                                    <button ref={refbtn} className='btn mx-1 px-5' disabled={!(isValid)} onClick={submitForm}>Aceptar</button>
                                </section>
                            </section>
                        </Modal>
                    }
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default CambioEtapaInventario