import React, { useEffect, useState, useRef } from 'react'
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from './Modal';
import Spinner from '../Loadig';
import { TextAreaField } from '../TextAreaField';
import { TextField } from '../TextField';
import { SelectField } from '../SelectField';
import { message, FormatNumberMoney2, FormatFecha, TextMoneyToNumber } from '../../Utils/functions';
import CobranzaService from '../../Services/Cobranza/Cobranza';
const RegistroLlamadaModal = ({
    isOpen,
    setIsOpen,
    motivosVisita,
    infoContactoData,
    user,
    state,
    toast,
    GetDataContactos,
    GetDataPromesa,
    GetDataSeguimiento,
    directorioValue,
    generadoEn,
    currentDataSeguimiento,
    montoAdeudo
}) => {
    const today = new Date()
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const inicial = {
        operacion_id: "",
        operacion_resultado_id: "",
        comentario_resultado: "",
        aplica_visita: 0,
        motivo_visita_id: "",
        comentario_visita: "",
        evidencia: "",
        motivo_ingreso_cartera_id: "",
        comentario_ingreso_cartera: "",
        comentario_promesa: "",
        promesaPago: "",
        fecha_compromiso: FormatFecha(today),
        hora_compromiso: "00:00"
        // Monto vencida Y Dias vencidas
    }
    const [initialValue, setInitialValue] = useState(inicial)
    const [promesaPago, setPromesaPago] = useState()
    const [motivoPromesaNombre, setMotivoPromesaNombre] = useState()
    const [motivoPromesa, setMotivoPromesa] = useState([])
    const [motivoIngresoCartera, setMotivoIngresoCartera] = useState([])
    const [tipoOperacion, setTipoOperacion] = useState([])
    const [tipoOperacionSelected, setTipoOperacionSelected] = useState("")
    const [promesasStatus, setPromesasStatus] = useState([])
    const [tipoOperacionResultado, setTipoOperacionResultado] = useState([])

    useEffect(() => {
        HandleMotivoPromesa()
        HandleMotivoIngresoCartera()
        HandleTipoOperaciones()
        HandlePromesasStatus()
    }, [])

    const HandleMotivoPromesa = async () => {
        await CobranzaService.getAllMotivosPromesa()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoPromesa(motivo);
            })
    }

    const HandleMotivoIngresoCartera = async () => {
        await CobranzaService.getAllMotivoIngresoCartera()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoIngresoCartera(motivo);
            })
    }

    const HandleTipoOperaciones = async () => {

        await CobranzaService.getAllOperacionResultado()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    operacion.push({
                        value: item.operacion_id,
                        label: item.operacion,
                        nivel: item.nivel
                    });
                });
                let sortedData = operacion.filter((item) => Number(item.nivel) === Number(state.nivel_cobranza))
                sortedData = sortedData.filter((data, index, array) => {
                    return array.findIndex((item) => item.label === data.label) === index
                })
                sortedData = [...sortedData].sort((a, b) => a.label.localeCompare(b.label));
                sortedData =
                    setTipoOperacion(sortedData);
            })

        // await CobranzaService.getAllTipoOperacion()
        //     .then(res => {
        //         let operacion = []
        //         res.data?.map(item => {
        //             // if (item.activo && Number(currentDataSeguimiento.nivel_cobranza) === Number(item.nivel)) {
        //             if (item.activo) {
        //                 operacion.push({
        //                     value: item.id,
        //                     label: item.nombre
        //                 });
        //             }
        //         });
        //         const sortedData = [...operacion].sort((a, b) => a.label.localeCompare(b.label));
        //         setTipoOperacion(sortedData);
        //     })
    }

    const HandlePromesasStatus = async () => {
        await CobranzaService.getAllEstatusPromesa()
            .then(res => {
                let promesa = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        promesa.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setPromesasStatus(promesa);
            })
    }

    const HandleTipoOperacionesResultados = async (id) => {
        let data = {
            "nivel": currentDataSeguimiento.nivel_cobranza,
            "operacion_id": id
        }

        if (id === undefined || id === "" || id === null) return [{}]

        await CobranzaService.getxNivelOperacionResultado(data)
            .then(res => {

                let operacion = []
                res.data?.map(item => {
                    operacion.push({
                        value: item.resultado_id,
                        label: item.resultado
                    });
                });
                setTipoOperacionResultado(operacion);
            })
    }

    const RequiereVisita = [
        { value: 0, label: "No" },
        { value: 1, label: "Si" },
    ]

    const validationSchema = Yup.object({
        //primera
        operacion_id: Yup.string().required('Operacion es requerido'),

        otraOperacion: Yup.string()
            .when("nombreOperacion", {
                is: (nombreOperacion) => nombreOperacion === "Otros", // Condition to check
                then: Yup.string().required('Operacion es requerido'),
            }),

        operacion_resultado_id: Yup.string().required('Resultado es requerido'),
        comentario_resultado: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),

        //segunda
        // motivo_ingreso_cartera_id: Yup.string().required('Motivo ingreso es requerido'),
        comentario_ingreso_cartera: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),
        //tercera
        motivoPromesa: Yup.string()
            .when("promesaPago", {
                is: (promesaPago) => promesaPago === "Promesa de Pago", // Condition to check
                then: Yup.string().required('Motivo es requerido'),
            }),
        fecha_compromiso: Yup.string()
            .when("promesaPago", {
                is: (promesaPago) => promesaPago === "Promesa de Pago", // Condition to check
                then: Yup.string().required('Fecha es requerido'),
            }),
        hora_compromiso: Yup.string()
            .when("promesaPago", {
                is: (promesaPago) => promesaPago === "Promesa de Pago", // Condition to check
                then: Yup.string().required('Hora es requerido'),
            }),
        monto_promesa: Yup.string()
            .when("promesaPago", {
                is: (promesaPago) => promesaPago === "Promesa de Pago", // Condition to check
                then: Yup.string().required('Monto es requerido')
                    .test('is-positive', 'El monto debe ser mayor a 0', value => {
                        const monto = parseFloat(value); // Convierte el valor a número
                        return monto > 0 && !isNaN(monto); // Verifica si es mayor a 0
                    }),
                otherwise: Yup.string(),

            }),

        comentario_promesa: Yup.string()
            .when("promesaPago", {
                is: (promesaPago) => promesaPago === "Promesa de Pago", // Condition to check
                then: Yup.string().test(
                    'min-length-if-not-empty',
                    'El comentario debe tener al menos 20 caracteres',
                    function (value) {
                        // Si el valor no está vacío, debe tener al menos 20 caracteres
                        return !value || (value && value.length >= 20);
                    }),
            }),
        //cuarta
        // aplica_visita: Yup.boolean().required('Este campo es obligatorio'),

        motivo_visita_id: Yup.string().when('aplica_visita', {
            is: 1,  // Si aplica_visita es 1
            then: Yup.string().required('Motivo de visita es obligatorio'),  // Se requiere motivo_visita_id
            otherwise: Yup.string(),  // No requerido si aplica_visita no es 1
        }),

        comentario_visita: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),

    })

    const CrearPromesadePago = async (promesaData, PromesaAscendesData) => {
        let response = await CobranzaService.creaPromesa_de_Pago(promesaData)
        PromesaAscendesData.folio_promesa_dataware = response.data.folio_promesa

        await CobranzaService.creaAgendaPromesaPago(PromesaAscendesData)
            .then(res => {
                response.data.folio_promesa_ascendes = res.data.responseData.folio_promesa_acendes
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
                return null
            })

        await CobranzaService.actualizaPromesa_de_Pago(response.data)
    }

    const CrearContactoLogs = async (data) => {
        await CobranzaService.createContactoSeguimientoLog(data)
            .then(res => {
                toast.success(message("¡Operación exitosa!", res.data.mensaje));
            })

    }

    const handlerGuardar = async (values) => {
        const promesaStatus = promesasStatus.find(promesa => promesa.label === "Vigente");
        // const today = 
        const data = {
            anyObject: {
                contrato: state.contrato_id || state.contrato,
                cobrador: user.names,
                cobrador_id: user.userId,
                id_referencia_ascendes: infoContactoData.value.idreferencia,
                id_contacto_ascendes: infoContactoData.idcontacto_acendes,
                contacto: infoContactoData.tipo_contacto,
                nombre: infoContactoData.value.nombre_ref,
                valor: infoContactoData.dato,
                // Primer parte
                operacion_id: values.operacion_id,
                operacion_resultado_id: values.operacion_resultado_id,
                comentario_resultado: values.comentario_resultado,
                // Segunda Parte
                motivo_ingreso_cartera_id: values.motivo_ingreso_cartera_id,
                comentario_ingreso_cartera: values.comentario_ingreso_cartera,
                //Cuarta Parte
                aplica_visita: values.aplica_visita,
                motivo_visita_id: values.motivo_visita_id,
                comentario_visita: values.comentario_visita,
                evidencia: "",

                //MOTIVO VISITA.
            }
        }

        const [year, month, day] = values.fecha_compromiso.split("-");
        const [hours, minutes] = values.hora_compromiso.split(":");
        let fechaCompromisoFormat = new Date(Date.UTC(year, month - 1, day, hours, minutes)).toUTCString();

        let promesaData = {
            contrato_id: state.contrato_id || state.contrato,
            folio_promesa: "",
            cobrador_id: user.userId,
            periodo: state.periodo || 1,
            motivo_promesa_id: values.motivoPromesa,
            monto_vencido: TextMoneyToNumber(state.monto_adeudo),
            monto_promesa: values.monto_promesa,
            monto_recuperado: 0,
            fecha_compromiso: fechaCompromisoFormat,
            promesa_status_id: promesaStatus.value,
            vigencia: values.fecha_compromiso,
            persona: infoContactoData.value.tipo,
            nombre_persona: infoContactoData.value.nombre_ref,
            fecha_promesa: values.fecha_compromiso,
            comentarios: values.comentario_promesa,
            // activo: 0,
            folio_promesa_ascendes: "",
            fecha_ultima_actualizacion: null,
            generado_en: generadoEn,
            persona: directorioValue
        }

        const PromesaAscendesData = {
            sol_id: state.sol_id,
            contrato: state.contrato_id || state.contrato,
            vin: state.vin,
            // "folio_promesa_dataware": "00001",
            persona: infoContactoData.value.tipo,
            motivo: motivoPromesaNombre,
            comentario: values.comentario_promesa,
            monto_promesa: values.monto_promesa,
            garantia: false,
            fecha_compromiso: values.fecha_compromiso
        }

        setLoading(true)

        try {
            if (promesaPago === "Promesa de Pago") {
                //crear un UUID
                await CrearPromesadePago(promesaData, PromesaAscendesData)
            }
            await CrearContactoLogs(data)
            await GetDataContactos()

            await GetDataPromesa()
            await GetDataSeguimiento()

            setIsOpen(false);
            setLoading(false)
        } catch (error) {
            setIsOpen(false);
            setLoading(false)
            toast.error(message("¡Error!"));
        }
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                validateOnChange={true}  // Ensure validation runs on field change
                validateOnBlur={true}    // Ensure validation runs on blur
                onSubmit={(values) => {
                    handlerGuardar(values)
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={1000} overflow={true}>
                            <Form style={{ maxWidth: '100%', padding: '1rem' }}>
                                <div className="row">
                                    <div className="col col-12 d-flex">
                                        <h4><strong>Registro Llamada</strong></h4>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-12 col-12 py-2"> <span>A continuación completa los campos para el registro del seguimiento.</span></div>
                                    <div className="col-lg-12 col-12 py-2">
                                        <span className='col-lg-4 col-4' ><strong>Tipo:</strong> {infoContactoData.value.tipo}</span>
                                        <span className='col-lg-4 col-4 px-2'>|   <strong>Nombre:</strong> {`${infoContactoData.value.nombre_ref} ${infoContactoData.value.apellido_paterno_ref}`}</span>
                                        <span className='col-lg-4 col-4 px-2'>|   <strong>Tipo de contacto:</strong> {infoContactoData.tipo_contacto}</span>
                                    </div>
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>Valor:</strong> {infoContactoData.dato}</span></div>

                                    {/* Primer parte */}
                                    <>
                                        <div className="col-lg-12 col-12 py-2"> <span><strong>Detalle de contacto</strong></span></div>
                                        <div className="col-lg-4 col-4">
                                            <SelectField
                                                id="operacion_id"
                                                label="Operación:"
                                                name="operacion_id"
                                                disabled={false}
                                                items={tipoOperacion}
                                                onChange={(event) => {
                                                    setFieldValue("operacion_id", event.value)
                                                    setFieldValue("nombreOperacion", event.label)
                                                    setTipoOperacionSelected(event.label)
                                                    HandleTipoOperacionesResultados(event.value)
                                                }} />
                                        </div>
                                        {tipoOperacionSelected === "Otros" ?
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Otro:</label>
                                                <Field type="text" name="otraOperacion" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="otraOperacion" component="div" className="error" />
                                            </div>
                                            : null
                                        }
                                        <div className="col-lg-4 col-4">
                                            <SelectField
                                                id="operacion_resultado_id"
                                                label="Resultado:"
                                                name="operacion_resultado_id"
                                                disabled={false}
                                                items={tipoOperacionResultado}
                                                onChange={(event) => {
                                                    setFieldValue("operacion_resultado_id", event.value)
                                                    setFieldValue("promesaPago", event.label)
                                                    setPromesaPago(event.label)
                                                }} />
                                        </div>
                                        <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                            <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario_resultado" type="text" holder="Escribe" />
                                        </div>
                                    </>
                                    {/* Segunda Parte */}
                                    <>
                                        <div className="col-lg-12 col-12 py-2"> <span><strong>Ingreso a cartera</strong></span></div>
                                        <div className="col-lg-4 col-4">
                                            <SelectField
                                                id="motivo_ingreso_cartera_id"
                                                label="Motivo de ingreso:"
                                                name="motivo_ingreso_cartera_id"
                                                disabled={false}
                                                items={motivoIngresoCartera}
                                                onChange={(event) => {
                                                    setFieldValue("motivo_ingreso_cartera_id", event.value)
                                                }} />
                                        </div>
                                        <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                            <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario_ingreso_cartera" type="text" holder="Escribe" />
                                        </div>
                                    </>
                                    {/* Tercer Parte */}
                                    {promesaPago === "Promesa de Pago" &&
                                        <>
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>Promesa de Pago</strong></span></div>
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="motivoPromesa"
                                                    label="Motivo Promesa:"
                                                    name="motivoPromesa"
                                                    disabled={false}
                                                    items={motivoPromesa}
                                                    onChange={(event) => {
                                                        setFieldValue("motivoPromesa", event.value)
                                                        setMotivoPromesaNombre(event.label)
                                                    }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Fecha compromiso:</label>
                                                <Field type="date" name="fecha_compromiso" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="fecha_compromiso" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Hora compromiso:</label>
                                                <Field type="time" name="hora_compromiso" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="hora_compromiso" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                {/* <label className='text'>Monto compromiso:</label> */}
                                                <TextField
                                                    id="monto_promesa"
                                                    label="Monto prometido:"
                                                    name="monto_promesa"
                                                    holder="Escribir"
                                                    isMoney={true}
                                                    onChange={(event) => {
                                                        setFieldValue("monto_promesa", FormatNumberMoney2(event.target.value));
                                                    }}
                                                />
                                                {/* <Field type="text" name="monto_promesa" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' /> */}
                                                {/* <ErrorMessage name="monto_promesa" component="div" className="error" /> */}
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <span className='mt-1'><strong>Monto Vencido:</strong></span>
                                                <span className='mt-1'> {montoAdeudo}</span>
                                            </div>

                                            <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario_promesa" type="text" holder="Escribe" />
                                            </div>
                                        </>
                                    }

                                    {/* Cuarta Parte */}
                                    <>
                                        <div className="col-lg-12 col-12 py-2"> <span><strong>Solicitud visita</strong></span></div>
                                        <div className="col-lg-4 col-4">
                                            <SelectField
                                                id="aplica_visita"
                                                label="Requiere visita:"
                                                name="aplica_visita"
                                                disabled={false}
                                                items={RequiereVisita}
                                                onChange={(event) => {
                                                    setFieldValue("aplica_visita", event.value)
                                                }} />
                                        </div>
                                        {values.aplica_visita === 1 ?
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="motivo_visita_id"
                                                    label="Motivo visita:"
                                                    name="motivo_visita_id"
                                                    disabled={false}
                                                    items={motivosVisita}
                                                    onChange={(event) => {
                                                        setFieldValue("motivo_visita_id", event.value)
                                                    }} />
                                            </div>
                                            : null
                                        }
                                        {values.aplica_visita === 1 ?
                                            <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario_visita" type="text" holder="Escribe" />
                                            </div>
                                            : null
                                        }
                                    </>
                                    {/* BOTONES */}
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setIsOpen(false)
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    disabled={!(isValid)}
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    type="submit"
                                                >
                                                    Registrar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )}
            </Formik >
        </>
    )
}
export default RegistroLlamadaModal