import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayCitaGestoriaEntrega: "",
    hourCitaGestoriaEntrega: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdCitaGestoriaEntrega: "",
    EntregaConcluida: false
}

export const GestoriaEntregaSlice = createSlice({
    name: 'GestoriaEntregaSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayCitaGestoriaEntrega: action.payload.day,
                hourCitaGestoriaEntrega: action.payload.hour,
                calendarioIdCitaGestoriaEntrega: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayCitaGestoriaEntrega: "",
                hourCitaGestoriaEntrega: "",
                calendarioIdCitaGestoriaEntrega: ""
            }
        },
        setConcluido: (state) => {
            return {
                ...state,
                EntregaConcluida: true
            }
        }
    }
})

export const { setHour, setSucursal, clearHour, setConcluido } = GestoriaEntregaSlice.actions

export default GestoriaEntregaSlice.reducer