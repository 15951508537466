import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useEliminarExternoPendiente from '../../../Hooks/Inventario/useEliminarExternoPendiente'
import ModalMantenerEliminarRegistro from './ModalMantenerEliminarRegistro'
const EliminarExterno = () => {
    const { columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        dataTipoExterno,
        tipoCompra,
        setclaveTipoCompra,
        setClaveTipoExterno,
        Statuseliminacion,
        setclaveEstatus,
        handleAction,
        item,
        setIsOpenModal,
        isOpenModal,
        habdleGetAll
    } = useEliminarExternoPendiente(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos externos pendientes de eliminar</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesPorEliminar, SubModule.EliminarExterno).Descarga}>
                        <button className='btn' onClick={habdleDownloadFile}>Exportar Excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos externos pendientes de eliminar.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesPorEliminar, SubModule.EliminarExterno).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={dataTipoExterno} className='col-12' onChange={(e) => { setClaveTipoExterno(e.value) }} placeholder="Externo" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo de compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={Statuseliminacion} className='col-12' onChange={(e) => { setclaveEstatus(e.value) }} placeholder="Estatus eliminación" />
                        </div>

                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        autorizar={true}
                        labelAutorizar={"Mantener Registro"}
                        rechazar={true}
                        labelRechazar={"Eliminar Registro"}
                        iswidth={true}
                        handleAction={handleAction}
                    />
                </div>
            </ValidatePermission>
            {
                <ModalMantenerEliminarRegistro
                    isOpenModal={isOpenModal}
                    SetIsOpenModal={setIsOpenModal}
                    item={item}
                    toast={toast}                    
                    handleGetAll={habdleGetAll}
                />
            }
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
        </>
    )
}

export default EliminarExterno