import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { chekItem, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';

const GarantiasSidebar = () => {
    const dispatch = useDispatch();
  const {cheked,item,isOpenModalClave} = useSelector((state) => state.quickActionsSlice);      
    return (
        <>
            <aside id="sidebar" className="sidebar">
                <div id="lbl_Inventario_Sidebar" className='fs-2 mb-2'>Garantías</div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Gestión de garantías</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/garantias/incidencias" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-message-x"></i>
                            <span>Incidencias del contrato</span>
                        </NavLink >
                    </li>    
                    {/* <li className="nav-item"  onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/garantias/gruas" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-time-five"></i>
                            <span>Grúas pendientes</span>
                        </NavLink >
                    </li>          */}
                    <li className="nav-heading"><strong>Gestión de citas</strong></li>
                    {/* <li className={`nav-item ${cheked ? "": 'disabled'}`}> */}
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/autorizar`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Citas por autorizar</span>
                        </NavLink >
                    </li>    
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/agenda`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-check-line"></i>
                            <span>Agenda de citas</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/inspeccionrapida`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Seguimiento de garantías</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/devoluciones`} className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-refresh"></i>
                            <span>Cambio o devolución</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/polizas-por-cancelar`} className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-shield-x"></i>
                            <span>Polizas por cancelar</span>
                        </NavLink >
                    </li> 
                    {/* <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/canceladas`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-close-circle-line"></i>
                            <span>Citas canceladas</span>
                        </NavLink >
                    </li>                                      */}
                </ul>
            </aside>
        </>
    )
}

export default GarantiasSidebar