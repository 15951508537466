import axios from '../../Utils/ApiService';

const ResponsivaService = {
 
    getResponsivaHistorial: (generalId,page,rows,string,TipoResponsivaId,EmpresaId) => {
        return axios.get(`/functions/GetHistorialResponsiva?Page=${page}&Rows=${rows}&Buscar=${string}&TipoResponsivaId=${TipoResponsivaId}&EmpresaId=${EmpresaId}&GeneralId=${generalId}`);
    },
    getResponsivaById:(generalId)=>{
        return axios.get(`/functions/GetByIdResponsiva?GeneralId=${generalId}`);
    },
    getTipoResponsiva: () => {
        return axios.get(`/catalogs/GetCSTipoResponsiva`);
    },
    getEmpresa: () => {
        return axios.get(`/catalogs/GetCSEmpresa`);
    },
    getTipoPago: () => {
        return axios.get(`/catalogs/GetCSTipoPago`);
    },  
    getBanco:()=>{
        return axios.get(`/catalogs/GetCSBanco`)
    },
    getRepresentanteLegal:(idEmpresa)=>{
        return axios.get(`/catalogs/GetCSRepresentanteLegal?EmpresaId=${idEmpresa}`);
    },
    getLugarTribunal:()=>{
        return axios.get(`/catalogs/GetCSTribunal`);
    },
    addResponsiva:(data)=>{
        return axios.post(`/functions/AddResponsiva`,data);
    },
    UpdResponsiva:(data)=>{
        return axios.put(`/functions/UpdResponsiva`,data);
    },
    downloadFileResponsiva : async (id) =>{
        return await axios.get(`/functions/downloadResponsiva?GeneralId=${id}`,{responseType: 'blob'});
    },
    addDispoData:(data)=>{
        return axios.post(`/functions/SaveDispositivoDatamovil`,data);
    },
    saveDatosPlaca:(data)=>{
        return axios.post(`/functions/SaveDatosPlacas`,data);
    },

}

export default ResponsivaService