import { useEffect, useRef, useState } from "react";
import DSAutorizaciones from "../../../Services/DatamovilSystems/Autorizaciones";

const DSAutorizarCitasHook = () => {
    const refFormik = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const columns = [
        { field: 'folioId', headerName: 'Folio', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'marca', headerName: 'Marca', widht: '150px' },
        { field: 'modelo', headerName: 'Modelo', widht: '150px' },
        { field: 'anio', headerName: 'Año', widht: '150px' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', widht: '150px' },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', widht: '150px' },
        { field: 'ubicacion', headerName: 'Ubicación', widht: '150px' },
    ];

    useEffect(() => {
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar]);

    const getListado = async () => {
        setLoading(true);
        const params = { buscar, page, rows };

        await DSAutorizaciones.getSegumientoCitas(params)
        .then(resp => {
            let items = resp.data.data.map(item => {
                item.comentariosTooltip2 = item.comentarios;
                return item;
            });
            setData(items);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDetail = () => {
        setModalMessage({ isOpen: true, title: 'Solicitud de ampliación expediente', type: 1 });
    }

    return {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail
    }
}

export default DSAutorizarCitasHook;