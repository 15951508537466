import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceGestoria = createSlice({
  name: 'GestoriaNavigate',
  initialState,
  reducers: {
    tabActiveGestoria: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveGestoria } = navigaSliceGestoria.actions

export default navigaSliceGestoria.reducer