import React, { useEffect, useState } from 'react'
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const UseDocumentoEtapa = (toast) => {
    const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
    const columns = [
        { field: 'etapa', headerName: 'Etapa', width: 250 },
        { field: 'nombre', headerName: 'Documento', width: 260 }
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [tipoCompraId, setTipoCompraId] = useState("");
    const [tipoProductoId, setTipoProductoId] = useState("")
    const [loading, setLoading] = useState(false);
    const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [filtroDocs, setfiltroDocs] = useState("");
    const [dataProd, setDataProd] = useState([]);
    const [dataTipoCompra, setDataTipoCompra] = useState([]);
    const [pageRows, setpageRows] = useState(10)
    //
    useEffect(() => {
        if (tipoCompraId !== "" && tipoProductoId !== "") {
            habdleGetAll(currenPage);
            setCurrenPage(1);
        }
    }, [tipoCompraId, tipoProductoId,pageRows])

    useEffect(() => {
        handleGetProducto();
        handleGetAllTipoCompra();
    }, [])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await VentaVehiculoService.getAllTipoDocEtapa(tipoProductoId, tipoCompraId, page, pageRows)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDeleteItem = async (item) => {
        setLoading(true);
        await EtapaService.DeleteDocumentoEtapa(item.documentos_EtapaId)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleCreateFolder = (item) => {        
        setDataVehiculo(item);
        setIsOpenDocs(true);
    }

    const handleGetProducto = async () => {
        setLoading(true);
        await EtapaService.getAllProducto()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.productoId,
                        label: item.producto
                    })
                });
                setDataProd(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllTipoCompra = async () => {
        setLoading(true);
        await EtapaService.getAllTipoCompra()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                setDataTipoCompra(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    return {
        loading,
        dataProd,
        setTipoProductoId,
        dataTipoCompra,
        setTipoCompraId,
        tipoCompraId,
        tipoProductoId,
        setDataVehiculo,
        setIsOpenDocs,
        columns,
        data,
        handleCreateFolder,
        pageCount,
        handlePageClick,
        currenPage,
        isOpenDocs,
        setIsOpenDocs,
        dataVehiculo,
        habdleGetAll,
        handleDeleteItem,
        setpageRows
    }
}

export default UseDocumentoEtapa