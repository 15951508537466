import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    generalId: "",
    fechaEntrega: "",
    vin: "",
    nombreMarca: "",
    nombreModelo: "",
    nombreVersion: "",
    nombreYear: "",
    color: "",
    kilometraje: "",
    procedenciaAuto: "",
    numMotor: "",
    numContrato: "",
    numSolicitud: "",
    cliente: "",
    fechaUltimoPago: "",
    producto: "",
    usoUnidad: "",
    TallerAutorizado: "",
    FolioCarnet: "",
    Expediente: false,
    serieDatamovil :  "",
    transmision : "",
    nombre:"",
    fechaUltimoPagAcendes:"",
    clienteAcendes:"",
    fechaLiquidacionAcendes:"",
}

export const DataVinSlice = createSlice({
    name: 'DataVinSlice',
    initialState,
    reducers: {
        setInfoVin: (state, action) => {
            
            return {
                ...state,
                generalId: action.payload.generalId,
                fechaEntrega: action.payload.fechaEntrega,
                vin: action.payload.vin,
                nombreMarca: action.payload.nombreMarca,
                nombreModelo: action.payload.nombreModelo,
                nombreVersion: action.payload.nombreVersion,
                nombreYear: action.payload.nombreYear,
                color: action.payload.color,
                kilometraje: action.payload.kilometraje,
                procedenciaAuto: action.payload.procedenciaAuto,
                numMotor: action.payload.numMotor,
                numContrato: action.payload.numContrato,
                numSolicitud: action.payload.numSolicitud,
                cliente: action.payload.cliente,
                fechaUltimoPago: action.payload.fechaUltimoPago,
                producto: action.payload.producto,
                usoUnidad: action.payload.usoUnidad,
                TallerAutorizado: action.payload.TallerAutorizado,
                FolioCarnet: action.payload.FolioCarnet,
                Expediente: action.payload.Expediente,
                serieDatamovil : action.payload.serieDatamovil,
                transmision : action.payload.transmision,
                nombre:action.payload.nombre,
                fechaUltimoPagAcendes: action.payload.fechaUltimoPagAcendes,
                clienteAcendes: action.payload.clienteAcendes,
                fechaLiquidacionAcendes:action.payload.fechaLiquidacionAcendes,
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
    }
})

export const { setInfoVin, setEditable } = DataVinSlice.actions

export default DataVinSlice.reducer