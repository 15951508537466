import React, { useEffect, useRef, useState } from 'react'
import useLlamada from '../../../Hooks/Garantias/useLlamada';
import useGarantia from '../../../Hooks/Garantias/useGarantia';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { NavLink } from 'react-router-dom';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom'
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../../Utils/functions'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import Llamada from '../../../Services/Cartera/Llamadas';
import DataTable from '../../../Components/datatable/DataTable'

import Catalogo from '../../../Services/Catalogos/Catalogo';
import { useDispatch, useSelector } from 'react-redux'

import { setSchedule } from '../../../Redux/Slice/calendarSlice';


//Esta pantalla es para mostrar los datos de la llamada
const IncidenciasLlamada = () => {
  const { refFormik, refFormGar, refFormGrua, refFormMantenimiento } = useRef();
  const dispatch = useDispatch();

  const { day, hour, tipoCalendario } = useSelector((state) => state.CalendarSlice);

  const navigate = useNavigate();
  let { idllamada } = useParams();

  let {
    setStr,
    str,
    loading,
    setLoading,
    setModalMessage,
    modalMessage,
    setDataVin,
    dataVin,
    busquedaRealizada,
    infoTabs,
  } = useLlamada()



  let initial = {
    generalid: dataVin.generalId,
    Nombre: "",
    Telefono: "",
    Correo: "",
    MotivoLlamadaId: 0,
    ComentariosCliente: "",
    ComentariosGarantia: "",
  }
  //Valores de garantia
  let initialGarantia = {
    GeneralId: "",
    KilometrajeActual: "",
    CarnetServicioId: "",
    FolioCarnet: "",
    ClasificacionFallaId: "",
    Gravedad: "",
    TipoReporteId: "",
    TallerAutorizado: "",
    VehiculoMover: "",
    OrigenGruaId: "",
    FechaHoraCita: "",
    FechaCita: "",
    LlamadaId: "",
    GarantiaId: ""
  }

  //Valores de la grua
  let initialGrua = {
    EmpresaId: "",
    GruaAsignada: "",
    CostoCliente: 0,
    IVA: 0,
    CostoInicial: 0,
    Subtotal: 0,
    Total: 0,
    EstadoId: "",
    MunicipioId: "",
    ColoniaId: "",
    Calle: "",
    Numero: "",
    TallerAutorizado: "",
    ReferenciaUbicacion: "",
    FechaHoraCita: "",
    EstatusCita: "",
    GarantiaId: "",
    GruasId: ""
  }
  //Valores de mantenimiento
  let initialMantenimiento = {
    GeneralId: "",
    TallerId: "",
    Kilometraje: "",
    FechaMantenimiento: "",
    Autorizacion: 1,
  }

  // const { currentPage, list, hasNextPage, hasPreviousPage, pageSize, totalPages, totalRows } = useSelector((state) => state.listaLlamadas);
  const list = [{
    usuario: "Cesar Ortiz",
    taller: "Test de taller",
    kilometraje: "11000Km",
    fechaHoraRegistro: "SF",
    supervisor: "Test de supervisor",
    fechaHoraAutorizacion: "2023-10-12",
    statusMantenimiento: "2023-10-12",
  },
  {
    usuario: "Braulio 1",
    taller: "Test de taller 2",
    kilometraje: "144000Km",
    fechaHoraRegistro: "SF",
    supervisor: "Supervisor",
    fechaHoraAutorizacion: "2023-10-12",
    statusMantenimiento: "2023-10-12",
  }]
  const columns = [
    { field: 'usuario', headerName: 'Usuario', width: 150 },
    { field: 'taller', headerName: 'Taller', width: 150 },
    { field: 'kilometraje', headerName: 'Kilometraje', width: 250 },
    { field: 'fechaHoraRegistro', headerName: 'Fecha y hora registro', width: 250 },
    { field: 'supervisor', headerName: 'Supervisor', width: 250 },
    { field: 'fechaHoraAutorizacion', headerName: 'Fecha y hora autorización', width: 250 },
    { field: 'statusMantenimiento', headerName: 'Estatus', width: 250 },
  ];

  const columMatment = [
    { field: 'fechaMantenimiento', headerName: 'fechaMantenimiento', width: 150 },
    { field: 'usuario', headerName: 'usuario', width: 150 },
    { field: 'tallerId', headerName: 'tallerId', width: 150 },
    { field: 'taller', headerName: 'taller', width: 150 },
    { field: 'kilometraje', headerName: 'kilometraje', width: 150 },
    { field: 'fechaHoraRegistro', headerName: 'fechaHoraRegistro', width: 150 },
    { field: 'supervisor', headerName: 'supervisor', width: 150 },
    { field: 'autorizado', headerName: 'autorizado', width: 150 },
    { field: 'fechaHoraAutorizacion', headerName: 'fechaHoraAutorizacion', width: 150 },
  ]

  //Valores para la validacion si existe una garantia
  const [continuarGarantia, setContinuarGarantia] = useState(false); //Var para validar si ya existe una garantia en caso de que no, mostrar continuar

  const [initialValue, setInitialValue] = useState(initial);
  const [editable, setEditable] = useState(0);
  const [dataMotivos, setDataMotivos] = useState([]);

  //Datos de Garantia
  const [initialValueGarantia, setInitialValueGarantia] = useState(initialGarantia);
  const [dataCarnet, setDataCarnet] = useState([]);
  const [dataClasificacionFalla, setDataClasificacionFalla] = useState([]);
  const [dataTipoReporte, setDataTipoReporte] = useState([]);
  const [dataVehiculoPuedeMoverse, setDataVehiculoPuedeMoverse] = useState([{ value: 1, label: "Si" }, { value: 0, label: "No" }]);
  const [dataOrienGruas, setdataOrienGruas] = useState([{ value: "AD2BEBDE-8648-4346-BAE4-E904CE52E885", label: "Datamovil" }]);
  const [dataTaller, setDataTaller] = useState([{ value: "1DFDAD16-1544-4EEC-AF00-1CDF4761EA51", label: "Taller 1" }]);

  //Datos de Grua
  const [initialValueGrua, setInitialValueGrua] = useState(initialGrua);
  const [dataEmpresaId, setDataEmpresaId] = useState([]);
  const [dataEstado, setDataEstado] = useState([]);
  const [dataMunicipio, setDataMunicipio] = useState([]);
  const [dataColonia, setDataColonia] = useState([]);

  //
  const [modalCosto, setModalCosto] = useState(false);
  const [modalRegistrarMantenimiento, setModalRegistrarMantenimiento] = useState(false);
  const [modalAutorizarCita, setModalAutorizarCita] = useState(false);
  const costoInputRef = useRef(null);
  const Taller = useRef("");
  const [mantenimientos, setMantenimientos] = useState([]);
  const [dataMantenimiento, setDataMantenimiento] = useState(initialMantenimiento)

  const validate = Yup.object({
    Nombre: Yup.string()
      .required("El campo es requerido.")
      .trim("El texto no debe empezar ni terminar con espacios")
      .strict(true),
    Telefono: Yup.string()
      .matches(/^\d{10}$/, { message: "El teléfono debe contener exactamente 10 números." })
      .required("El campo es requerido."),
    Correo: Yup.string()
      .email("El correo electrónico no es válido.")
      .required("El campo es requerido."),
    ComentariosCliente: Yup.string()
      .required("El campo es requerido."),
    MotivoLlamadaId: Yup.number()
      .required("El campo es requerido."),
  });

  const validateGarantia = Yup.object({
    KilometrajeActual: Yup.string()
      .required("El campo es requerido.")
      .trim()
      .matches(/^[0-9]{1,10}$/, "Debe contener solo números y no exceder los 10 caracteres.")
      .strict(true),
    CarnetServicioId: Yup.string()
      .matches(/^(?!0$)[0-9a-fA-F-]*$/, "El campo es requerido.")
      .strict(true),
    FolioCarnet: Yup.string()
      .required("El campo es requerido.")
      .matches(/^[a-zA-Z0-9]*$/, "No se permiten caracteres especiales en el campo")
      .strict(true),
    ClasificacionFallaId: Yup.string()
      .required("El campo es requerido.")
      .strict(true),
    TipoReporteId: Yup.string()
      .required("El campo es requerido.")
      .strict(true),
    VehiculoMover: Yup.mixed()
      .required("El campo es requerido.")
      .strict(true),
  });

  const validateGrua = Yup.object({
    EmpresaId: Yup.string()
      .trim()
      .required("El campo es requerido.")
      .strict(true),
    GruaAsignada: Yup.string()
      .required("El campo es requerido.")
      .strict(true),
    // CostoCliente: Yup.string()
    //   .required("El campo es requerido.")
    //   .strict(true),
    EstadoId: Yup.mixed()
      .required("El campo es requerido.")
      .strict(true),
    MunicipioId: Yup.mixed()
      .required("El campo es requerido.")
      .strict(true),
    ColoniaId: Yup.mixed()
      .required("El campo es requerido.")
      .strict(true),
    Calle: Yup.string()
      .required("El campo es requerido.")
      .strict(true),
    TallerAutorizado: Yup.string()
      .required("El campo es requerido.")
      .strict(true),
  });

  const validateMantenimiento = Yup.object({
    TallerId: Yup.string()
      .trim()
      .required("El campo es requerido.")
      .strict(true)
  });

  useEffect(() => {

    handleGetMotivos(); //Catalogo de motivos necesarios    
    if (idllamada !== "0") {
      handlerGetLlamadaId(); //es una llamada registrada obtener los datos de la llamada
      GetGarantiaByLlamadaIdasync(); //Busca si tengo garantia registrada a esta llamada
      setInitialValueGarantia({
        ...initialValueGarantia, GeneralId: dataVin.generalId, LlamadaId: idllamada
      })
    }
    setInitialValue({
      ...initialValue, generalid: dataVin.generalId
    })
    setInitialValueGarantia(
      {
        ...initialValueGarantia,
        GeneralId: dataVin.generalId,
        LlamadaId: idllamada
      }
    )
    setDataMantenimiento(
      {
        ...dataMantenimiento,
        GeneralId: dataVin.generalId,
      }
    )
    if (dataVin.generalId) {
      handleGetMantenimientos(dataVin.generalId)
    }
  }, [dataVin])

  useEffect(() => {
    if (continuarGarantia) {
      handleGetCarnet();
      handleGetClasificacionFalla();
      handleGetTipoReporte();
    }
  }, [continuarGarantia])

  const handleGetMantenimientos = async (id) => {
    await Llamada.getMantenimientos(id)
      .then(res => {
        const data = res.data.data;
        let datar = []
        data.forEach(element => {
          datar.push(
            {
              totalRows: element.totalRows,
              generalId: element.generalId,
              mantenimientoId: element.mantenimientoId,
              fechaMantenimiento: element.fechaMantenimiento,
              usuario: element.usuario,
              tallerId: element.tallerId,
              taller: element.taller,
              kilometraje: element.kilometraje,
              fechaHoraRegistro: element.fechaHoraRegistro,
              supervisor: element.supervisor,
              fechaHoraAutorizacion: element.fechaHoraAutorizacion,
              statusMantenimiento: element.statusMantenimiento,
              autorizado: element.autorizado,

            }
          )
        });
        setMantenimientos(datar);

      }).catch(err => {
        return err;
      });
  }

  //Agrega la llamada que es el primer metodo
  const handleAddEdit = async (value) => {

    setLoading(true);
    if (idllamada == 0) {
      await Llamada.addLlamada(value)
        .then(res => {
          toast.success(message("¡Correcto!", res.data.data.mensaje));
          navigate("/garantias/incidencias/" + dataVin.vin + "/" + res.data.data.llamadaId);
        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            // console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        });

    } else {
      setContinuarGarantia(true)
    }
    setLoading(false);

  }
  const verCalendario = async (valor) => {
    dispatch(setSchedule({
      url: `garantias/incidencias/${dataVin.vin}/${idllamada}`,
      contrato: "151443",
      folioLlamada: "1232",
      vin: "MR0EX8DD6J0256868",
      modelo: "Hilux",
      ano: "2017",
      marca: "Toyota",
    }));
    navigate(`/garantias/citas/${valor}`)
  }
  //Garantia paso para guardar la garantia de la llamada
  const handleAddEditGarantia = async (value) => {
    // console.log(value)

    setLoading(true);
    if (initialValueGarantia.GarantiaId === "") {
      await Llamada.addGarantia(value)
        .then(res => {
          toast.success(message("¡Correcto!", res.data.data.mensaje));
        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      await Llamada.addGarantia(value)
        .then(res => {
          toast.success(message("¡Correcto!", res.data.data.mensaje));
        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })
      setLoading(false);
    }


  }
  // Grua paso 2 para agregar la grua
  const handleAddEditGrua = async (value) => {
    setLoading(true)

    if (initialValueGarantia.GarantiaId !== "" && initialValueGrua.GruasId === "") {
      await Llamada.AddGruaLlamada(value)
        .then(res => {
          toast.success(message("¡Correcto!", res.data.data.mensaje));
        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })
      console.log("Vamos a agregar")
    } else {
      await Llamada.UpdateGruabyBarantiaId(value)
        .then(res => {
          toast.success(message("¡Correcto!", res.data.data.mensaje));
        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })

      console.log(value, "Vamos a actualizar")
    }
  }
  const handleAddMantenimiento = async (value) => {

    setLoading(true);
    await Llamada.AddMantenimiento(value)
      .then(res => {
        toast.success(message("¡Correcto!", res.data.data.mensaje));
        handleGetMantenimientos(dataVin.generalId)
      })
      .catch(e => {
        if (e.response !== undefined) {
          toast.error(message("¡Error.!", e.response.data.message));
          console.log(e.response);
        } else {
          console.log(e.response)
          toast.error(message("¡Error.!", e.message));
        }
      })
      .finally(() => {
        setDataMantenimiento({
          ...dataMantenimiento,
          Kilometraje: "",
          FechaMantenimiento: "",
          Autorizacion: 1,
        })
        setLoading(false);
      })

  }
  const handleGetMotivos = async () => {
    await Catalogo.getMotivoLlamada()
      .then(res => {
        let listaMotivos = [];
        res.data.data?.map(item => {
          listaMotivos.push({
            value: item.motivoLlamadaId,
            label: item.motivo
          });
        });
        setDataMotivos(listaMotivos);
      })
      .catch(err => {
        console.log(err);
        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: err });
      });
  }
  const handleGetCarnet = async () => {
    await Catalogo.getCarnet()
      .then(res => {
        console.log(res.data.data)
        let listaCarnet = [];
        res.data.data?.map(item => {
          listaCarnet.push({
            value: item.carnetServicioId,
            label: item.carnet
          });
        });
        setDataCarnet(listaCarnet);
      })
  }
  const handleGetClasificacionFalla = async () => {
    await Catalogo.getClasificacionFalla()
      .then(res => {
        console.log(res.data.data)
        let ClasificacionFalla = [];
        res.data.data?.map(item => {
          ClasificacionFalla.push({
            value: item.clasificacionFallaId,
            label: item.falla
          });
        });
        setDataClasificacionFalla(ClasificacionFalla);
      });
  }
  const handleGetTipoReporte = async () => {
    await Catalogo.getTipoReporte()
      .then(res => {
        console.log(res.data.data)
        let TipoReporte = [];
        res.data.data?.map(item => {
          TipoReporte.push({
            value: item.tipoReporteId,
            label: item.tipoReporte
          });
        });
        setDataTipoReporte(TipoReporte);
        console.log(TipoReporte)
      });
  }
  const handleGetVehiculoSePuedeMover = async () => {

  }
  const HandleGetEmpresas = async () => {
    await Llamada.GetEmpresas().then(res => {
      let Empresas = []
      res.data.data?.map(item => {
        Empresas.push({
          value: item.empresaId,
          label: item.empresa
        });
      });
      setDataEmpresaId(Empresas);
    })
  }
  const HandleGetEstadoMx = async () => {
    await Llamada.GetEstadoMx().then(res => {
      let Estados = []
      res.data.data?.map(item => {
        Estados.push({
          value: item.edoMexicoId,
          label: item.estado
        });
      });
      setDataEstado(Estados);
      //setDataEmpresaId
    })
  }
  const HandleGetMunicpio = async () => {
    await Llamada.GetMunicipio(1).then(res => {
      let Municipios = []
      res.data.data?.map(item => {
        Municipios.push({
          value: item.municipioId,
          label: item.municipio
        });
      });
      setDataMunicipio(Municipios);
      //setDataEmpresaId
    })
  }
  const HandleGetColonia = async () => {
    await Llamada.GetColonia(1001).then(res => {
      let Colonias = []
      res.data.data?.map(item => {
        Colonias.push({
          value: item.coloniaId,
          label: item.colonia
        });
      });
      setDataColonia(Colonias);
      //setDataEmpresaId
    })
  }
  const handlerGetLlamadaId = async () => {
    await Llamada.getLlamadaById(idllamada)
      .then(res => {
        setInitialValue({
          ...initialValue,
          Nombre: res.data.data.nombre ? res.data.data.nombre : "",
          Telefono: res.data.data.telefono ? res.data.data.telefono : "",
          Correo: res.data.data.correo ? res.data.data.correo : "",
          MotivoLlamadaId: res.data.data.motivoLlamadaId ? res.data.data.motivoLlamadaId : "",
          ComentariosCliente: res.data.data.comentariosCliente ? res.data.data.comentariosCliente : "",
          ComentariosGarantia: res.data.data.comentariosGarantia ? res.data.data.comentariosGarantia : ""
        }
        )
      })
      .catch(e => {
        if (e.response !== undefined) {
          toast.error(message("¡Error.!", e.response.data.message));
        } else {
          console.log(e.response)
        }
      })
      .finally(() => {

      });
  }
  //Obtiene la garantia opr id de llamada registrada
  const GetGarantiaByLlamadaIdasync = async () => {
    await Llamada.GetGarantiaByLlamadaId(idllamada)
      .then(res => {
        if (res.data.data === null) {
          setContinuarGarantia(false) //Validacion del boton continuar en falso ya que aun no hay garantia
        } else {
          HandleGetEmpresas()
          HandleGetEstadoMx()
          HandleGetMunicpio()
          HandleGetColonia()

          setContinuarGarantia(true) //Pone en verdadero para continuar a la pantalla de guardar garantia

          let dataRes = res.data.data
          const _origenGruaId = dataRes.origenGruaId.toUpperCase();
          setInitialValueGarantia({
            ...initialValueGarantia,
            KilometrajeActual: dataRes.kilometrajeActual,
            CarnetServicioId: dataRes.carnet,
            FolioCarnet: dataRes.folioCarnet,
            ClasificacionFallaId: dataRes.clasificacionFallaId,
            Gravedad: dataRes.gravedad,
            GarantiaId: dataRes.garantiaId,
            VehiculoMover: dataRes.vehiculoMover,
            CarnetServicioId: dataRes.carnetServicioId,
            TipoReporteId: dataRes.tipoReporteId,
            OrigenGruaId: _origenGruaId,
            TallerAutorizado: dataRes.tallerAutorizado,
            FechaHoraCita: hour && tipoCalendario === 1 ? hour : ""
          })

          setInitialValueGrua({
            ...initialValueGrua,
            GarantiaId: res.data.data.garantiaId,
          })

          handleGetVehiculoSePuedeMover();

        }
        console.log(res, "Respuesta de las garantias")
      })
      .catch(e => {
        if (e.response !== undefined) {
          toast.error(message("¡Error.!", e.response.data.message));
          // console.log(e.response);
        } else {
          console.log(e.response)
          // toast.error(message("¡Error.!", e.message));
        }
      })
      .finally(() => {
        if (initialValueGrua.GarantiaId !== "") {
          handleGetGruabyBarantiaId(initialValueGrua.GarantiaId)
        }
      });
  }
  const handleGetGruabyBarantiaId = async (payload) => {

    await Llamada.GetGruabyBarantiaId(payload)
      .then(res => {
        let dataRes = res.data.data
        const empresaIdEnMinusculas = dataRes.empresaId.toLowerCase();
        setInitialValueGrua({
          ...initialValueGrua,
          EmpresaId: empresaIdEnMinusculas,
          GruaAsignada: dataRes.gruaAsiganada,
          CostoCliente: dataRes.costoGrua ? dataRes.costoGrua : "",
          Total: dataRes.total,
          IVA: dataRes.iva,
          EstadoId: dataRes.estadoId,
          MunicipioId: dataRes.municipioId,
          ColoniaId: dataRes.coloniaId,
          Calle: dataRes.calle,
          Numero: dataRes.numero,
          TallerAutorizado: dataRes.tallerAutorizado,
          ReferenciaUbicacion: dataRes.referenciaUbicacion,
          FechaHoraCita: dataRes.fechaHoraCita ? dataRes.fechaHoraCita : "",
          EstatusCita: dataRes.estatusCita ? dataRes.estatusCita : "",
          GruasId: dataRes.gruasId ? dataRes.gruasId : "",
          GruaStatusId: dataRes.gruaStatusId ? dataRes.gruaStatusId : null
        })

        // console.log(res, "Mis datos de la grua de esta garantia")

      })
      .catch(e => {
        if (e.response !== undefined) {
          toast.error(message("¡Error.!", e.response.data.message));
          console.log(e.response);
        } else {
          console.log(e.response)
          toast.error(message("¡Error.!", e.message));
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }
  const handleChangeMotivo = (value, valueState) => {
    setInitialValue({ ...valueState, MotivoLlamadaId: Number(value) });
  }
  const handleChangeMoverAuto = (value, valueState) => {
    setInitialValueGarantia({ ...valueState, VehiculoMover: Number(value) });
  }
  const ChangeModalCosto = (valor) => {
    costoInputRef.current.value = initialValueGrua.CostoInicial;
    setModalCosto(valor)
  }
  const setValueCosto = (value) => {
    let valorCosto = value

    if (valorCosto.trim() === "") {
      valorCosto = 0
    }

    let costo = parseInt(valorCosto)
    let iva = (costo * 0.16)
    let subtotal = costo + iva
    let total = costo + iva

    setInitialValueGrua({
      ...initialValueGrua,
      CostoInicial: valorCosto,
      CostoCliente: total,
      IVA: iva,
      Subtotal: subtotal,
      Total: total
    })
  }
  const setMantenimiento = (valor) => {
    setModalRegistrarMantenimiento(valor)
  }
  const verModalAutorizarCita = (tipo, valor) => {
    setModalAutorizarCita(valor)
  }
  const AutorizarCita = async (tipo) => {
    if (tipo === 1) {
      let data = {
        generalId: initialValue.generalid,
        garantiaid: initialValueGarantia.GarantiaId,
        tipo: 1
      }
      await Llamada.AutorizarCita(data)
        .then(res => {

        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      let data = {
        generalId: initialValue.generalid,
        garantiaid: initialValueGarantia.GarantiaId,
        tipo: 2
      }
      await Llamada.AutorizarCita(data)
        .then(res => {

        })
        .catch(e => {
          if (e.response !== undefined) {
            toast.error(message("¡Error.!", e.response.data.message));
            console.log(e.response);
          } else {
            console.log(e.response)
            toast.error(message("¡Error.!", e.message));
          }
        })
        .finally(() => {
          setLoading(false);
        })
    }

  }
  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <Modal isOpen={modalCosto} color='#fff' width={560}>
        <div className='p-4'>
          <h5>
            <b>
              Costo recolección grua
            </b>
          </h5>
          <div>
            A continuación captura el costo de la recolección de grua.
          </div>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Valor</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Costo grua
                </td>
                <td>
                  $<input type="text" id="costoInput" ref={costoInputRef} onChange={(event) => { setValueCosto(event.target.value); }} />
                </td>
              </tr>
              <tr>
                <td>
                  Subtotal
                </td>
                <td>
                  ${initialValueGrua.Subtotal}
                </td>
              </tr>
              <tr>
                <td>
                  IVA
                </td>
                <td>
                  ${initialValueGrua.IVA}
                </td>
              </tr>
              <tr>
                <td>
                  Total
                </td>
                <td>
                  ${initialValueGrua.Total}
                </td>
              </tr>
            </tbody>
          </table>
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <button className="btn btn-outline col-12" onClick={(e) => { setValueCosto("0"); ChangeModalCosto(false); }}>Cancelar</button>
            </div>
            <div className='col-md-4'>
              <button className="btn saveInfo col-12" onClick={(e) => ChangeModalCosto(false)}>Guardar</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={modalRegistrarMantenimiento} color='#fff' width={560}>
        <div className='p-4'>
          <span><b>Registrar Mantenimiento</b></span>
          <p>
            A continuación captura los datos del mantenimiento del vehículo de acuerdo al carnet de servicio.
          </p>

          <Formik
            innerRef={refFormMantenimiento}
            enableReinitialize={true}
            initialValues={dataMantenimiento}
            validationSchema={validateMantenimiento}
          >
            {({ isValid, values, setFieldValue, errors }) => (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <SelectField id="TallerId" label="Taller" name="TallerId" items={dataTaller} onChange={(event) => {
                      setFieldValue("TallerId", event.value);
                    }} />
                  </div>
                  <div className='col-md-6'>
                    <TextField id="Kilometraje" label="Kilometraje" name="Kilometraje" type="text" holder="Escribe" onChange={(event) => {
                      setFieldValue("Kilometraje", event.target.value);
                    }} />
                  </div>
                </div>

                <div className="row pt-3">
                  <div className='col-md-6'>
                    <SelectField id="FechaMantenimiento" label="Fecha mantenimiento" name="FechaMantenimiento" items={[]} onChange={(event) => {
                      setFieldValue("FechaMantenimiento", event.value);
                    }} />
                  </div>
                </div>
                <div className='row pt-3'>
                  <div className='col-md-4'></div>
                  <div className='col-md-4'>
                    <button className="btn btn-outline col-12" onClick={(e) => { setMantenimiento(false) }}>Cancelar</button>
                  </div>
                  <div className='col-md-4'>
                    <button className="btn saveInfo col-12" onClick={(e) => { setMantenimiento(false); handleAddMantenimiento(values) }}>Guardar</button>
                  </div>
                </div>
              </>
            )
            }
          </Formik>

        </div>
      </Modal>

      <Modal isOpen={modalAutorizarCita} color='#fff' width={560}>
        <div className='p-4'>
          <h5><b>Detalle de cita</b></h5>
          <small>
            A continuación se muestra el detalle de la cita agendada para el vehículo: Contrato: 000299298811 Folio llamada: 0001999288841
          </small>
          <div className='pt-4'>
            <small>
              VIN: 000101099294 Modelo: Galaxy Año: 2022
            </small>
            <div>
              Marca: Toyota Motors GM
            </div>
          </div>
          <div className='pt-4'>
            <small>
              Mié., 08-10-2023 <b>11:00AM - 11:30AM</b>
            </small>
            <div>
              Taller San Nicolás de los Garza
            </div>
          </div>

          <div className='pt-4'>
            <small>
              Tipo de garantía: satisfacción 1 Clasificación de la falla: Calentamiento
              <div>
                <b>Tipo reporte: Motor</b>
              </div>
            </small>
          </div>
          <div className='pt-4'>
            <small>
              Ejecutivo: Miguel López
            </small>
          </div>
          <div className='row pt-3'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <button className="btn btn-outline col-12" onClick={(e) => { verModalAutorizarCita(1, false) }}>Rechazar</button>
            </div>
            <div className='col-md-4'>
              <button className="btn saveInfo col-12" onClick={(e) => { verModalAutorizarCita(1, false); AutorizarCita(1); }}>Autorizar</button>
            </div>
          </div>
        </div>
      </Modal>

      <section >
        <div className='headerTab'>
          <section className='mx-4 my-4 d-flex flex-column'>
            <div className='row d-flex justify-content-between'>
              <div className="col-6 mt-2 mr-1 pb-1">
                <NavLink to={'/garantias'} className={'text-black'}>
                  <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de incidencias</small>
                </NavLink>
              </div>
              <div className="col-4 mt-2 mr-1 pb-1">
                <div className="row justify-content-end">
                  <div className="col-auto">
                    <small>Última actualización por: </small>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
          <div className='row mx-md-4 my-4'>
            <div className="col-6 px-md-3">
              <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                  <div className="col-12 underline-border">
                    <h6><strong>Información del vehículo</strong></h6>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Fecha de Entrega:</strong> {dataVin.fechaEntrega}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Año:</strong> {dataVin.nombreYear}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>VIN:</strong> {dataVin.vin}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Color:</strong> {dataVin.color}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Marca:</strong> {dataVin.nombreMarca}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Kilometraje:</strong> {dataVin.kilometraje}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Modelo:</strong> {dataVin.nombreModelo}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Procedencia Auto:</strong> {dataVin.procedenciaAuto}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Versión:</strong> {dataVin.nombreVersion}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>No. Motor:</strong> {dataVin.numMotor}</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 px-md-3">
              <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                  <div className="col-12 underline-border">
                    <h6>
                      <strong>Información del contrato</strong>
                      <span className='mx-2 badge bg-success-dm'>Activo</span>
                    </h6>

                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>No. de Contrato:</strong> {dataVin.numContrato}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Producto:</strong> {dataVin.producto}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Cliente:</strong> {dataVin.cliente}</small>
                  </div>
                  <div className="col-6">
                    <small><strong>Uso unidad:</strong> {dataVin.usoUnidad}</small>
                  </div>
                </div>
                <div className="row mt-2 px-4">
                  <div className="col-6">
                    <small><strong>Fecha de último pago:</strong> {dataVin.fechaUltimoPago}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
        <Formik
          innerRef={refFormik}
          enableReinitialize={true}
          initialValues={initialValue}
          validationSchema={validate}
        >
          {({ isValid, values, setFieldValue, errors }) => (
            <>
              <div className='row mx-md-4 my-4'>
                <div className="col-md-12 px-md-3">
                  <div className="p-4 white-wrapper mb-2">
                    <div className={initialValue?.MotivoLlamadaId === 0 ? "row px-4" : "row px-4 justify-content-between"}>
                      <div className="col-12 col-md-6">
                        <h6><strong>Información de llamada - {idllamada}</strong></h6>
                        <pre>

                        </pre>
                      </div>
                      {
                        idllamada === "0" &&
                        <div className="col-12 col-md-6 col-lg-2">
                          <button className="btn col-12 saveInfo" onClick={(e) => handleAddEdit(values)} disabled={!(isValid) || initialValue.MotivoLlamadaId === 0}>Guardar</button>
                        </div>
                      }
                      {
                        idllamada !== "0" && initialValue.MotivoLlamadaId === 2 && continuarGarantia == false &&
                        <div className="col-12 col-md-6 col-lg-2">
                          <button className="btn col-12 saveInfo" onClick={(e) => handleAddEdit(values)} disabled={!(isValid)}>Continuar</button>
                        </div>
                      }
                    </div>
                    <div className="row mt-2 px-4">
                      <div className="col-12">
                        <small><strong>Datos del contacto</strong></small>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2 px-4">
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <TextField id="Nombre" label="Nombre" name="Nombre" type="text" holder="Escribe" onChange={(event) => {
                          setFieldValue("Nombre", event.target.value);
                        }} />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <TextField id="Telefono" label="Teléfono" name="Telefono" type="text" holder="Escribe" onChange={(event) => {
                          setFieldValue("Telefono", event.target.value);
                        }} />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <TextField id="Correo" label="Correo electrónico" name="Correo" type="text" holder="Escribe" onChange={(event) => {
                          setFieldValue("Correo", event.target.value);
                        }} />
                      </div>
                    </div>

                    {/* CAPTURA MOTIVO DE LLAMADA */}
                    <div className="row mt-4 px-4">
                      <div className="col-12">
                        <strong>Capturar motivo de llamada</strong>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2 px-4">
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <SelectField id="MotivoLlamadaId" label="Motivo de llamada" name="MotivoLlamadaId" items={dataMotivos} onChange={(event) => {
                          setFieldValue("MotivoLlamadaId", event.value);
                          handleChangeMotivo(event.value, values);
                        }} />
                      </div>

                    </div>
                    {
                      initialValue.MotivoLlamadaId !== 0 &&
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                          <TextAreaField id="ComentariosCliente" label="Comentarios Cliente" className="form-control col-12" rows="3" name="ComentariosCliente" type="text" holder="Escribe"
                            onChange={(event) => {
                              setFieldValue("ComentariosCliente", event.target.value);
                            }} />
                        </div>
                      </div>
                    }
                    {
                      initialValue.MotivoLlamadaId === 2 &&
                      <>
                        <div className="row mt-2 mb-2 px-4">
                          <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                            <TextAreaField label="Comentarios garantia" className="form-control col-12" rows="3" name="ComentariosGarantia" type="text" holder="Escribe"
                              onChange={(event) => {
                                setFieldValue("ComentariosGarantia", event.target.value);
                              }} />
                          </div>
                        </div>
                      </>
                    }
                    {/* TERMINA CAPTURA MOTIVO LLAMADA */}
                  </div>
                </div>
              </div>
            </>
          )
          }
        </Formik>
      </div>

      {idllamada !== "0" && initialValue.MotivoLlamadaId === 2 && continuarGarantia == true && (
        <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
          <Formik
            innerRef={refFormGar}
            enableReinitialize={true}
            initialValues={initialValueGarantia}
            validationSchema={validateGarantia}
          >

            {({ isValid, values, setFieldValue, errors }) => (
              <>
                {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
                <div className='row mx-md-4 my-4' >
                  <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                      <div className={initialValue?.MotivoLlamadaId === 2 ? "row px-4" : "row px-4 justify-content-between"}>
                        <div className="col-12 col-md-8">
                          <div className="row">
                            <div className="col-6">
                              <h6><strong>Información de garantia - {initialValue?.MotivoLlamadaId} </strong></h6>
                            </div>
                            <div className="col-6">
                              <span className='mx-2 p-2 badge bg-primary-dm'>Tipo de garantía: Sin calcular</span>
                              <small className='mx-1'><span className='circle-focus primary-focus'></span> Garantía inicial</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="row">
                            <div className="col-6 col-lg-7">
                              <button className='btn btn-gray'>
                                <i className="icon-light fa fa-file "></i> Subir documento
                              </button>
                            </div>
                            <div className="col-6 col-lg-5">
                              {/* <button className="btn col-12 saveInfo" onClick={(e) => handleAddEditGarantia(values)} disabled={!(isValid)}>Guardar</button> */}
                              <button className="btn col-12 saveInfo" onClick={(e) => handleAddEditGarantia(values)} disabled={!(isValid) || JSON.stringify(values) === JSON.stringify(initialValueGarantia)}>Guardar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Kilometraje y documentación</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="KilometrajeActual" label="Kilometraje Actual" name="KilometrajeActual" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("KilometrajeActual", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          {/* <TextField id="estatusKilometraje" label="Estatus Carnet" name="estatusKilometraje" type="text" holder="Sin documento" disabled /> */}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                          <SelectField id="CarnetServicioId" label="Carnet" name="CarnetServicioId" items={dataCarnet} onChange={(event) => {
                            setFieldValue("CarnetServicioId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="FolioCarnet" label="Folio de Carnet" name="FolioCarnet" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("FolioCarnet", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          {/* <TextField id="estatusCarnet" label="Estatus Carnet" name="estatusCarnet" type="text" holder="Sin documento" disabled /> */}
                        </div>
                      </div>

                      {/* REGISTRAR LLAMADA */}
                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Registrar Falla</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <SelectField id="ClasificacionFallaId" label="Clasificación de la falla" name="ClasificacionFallaId" items={dataClasificacionFalla} onChange={(event) => {
                            setFieldValue("ClasificacionFallaId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="Gravedad" label="Gravedad" name="Gravedad" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("Gravedad", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <SelectField id="TipoReporteId" label="Tipo reporte" name="TipoReporteId" items={dataTipoReporte} onChange={(event) => {
                            setFieldValue("TipoReporteId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("TallerAutorizado", event.target.value);
                          }} />
                        </div>
                      </div>

                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Agendar cita y traslado de unidad</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <SelectField id="VehiculoMover" label="¿Vehículo se puede mover?" name="VehiculoMover" items={dataVehiculoPuedeMoverse} onChange={(event) => {
                            setFieldValue("VehiculoMover", event.value);
                            handleChangeMoverAuto(event.value, values);
                          }} />
                        </div>
                        {
                          values.VehiculoMover === 0 &&
                          <div className="col-sm-12 col-md-6 col-lg-2">
                            <SelectField id="OrigenGruaId" label="Origen grua" name="OrigenGruaId" items={dataOrienGruas} onChange={(event) => {
                              setFieldValue("OrigenGruaId", event.value);
                            }} />
                          </div>
                        }
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="FechaHoraCita" label="Fecha y hora de cita" name="FechaHoraCita" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("FechaHoraCita", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                          <button className="btn col-12 saveInfo" onClick={(e) => verCalendario(1)}>Ver calendario</button>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2">
                          <TextField id="FechaCita" label="Estatus cita" name="FechaCita" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("FechaCita", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                          <button className="btn col-12 saveInfo" disabled={values.FechaHoraCita !== "" ? false : true} onClick={(e) => verModalAutorizarCita(1, true)}>Autorizar Cita</button>
                        </div>
                      </div>
                      {/* TERMINA AGENDAR CITA Y TRASLADO DE UNIDAD */}
                    </div>
                  </div>
                </div>
              </>
            )
            }
          </Formik>
        </div>
      )}

      {initialValueGarantia?.VehiculoMover == 0 && initialValueGrua.GarantiaId !== "" && (
        <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
          <Formik
            innerRef={refFormGrua}
            enableReinitialize={true}
            initialValues={initialValueGrua}
            validationSchema={validateGrua}
          >

            {({ isValid, values, setFieldValue, errors }) => (
              <>
                {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
                <div className='row mx-md-4 my-4' >
                  <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-10 col-lg-10">
                            <h6><strong>Información de grua - {initialValue?.MotivoLlamadaId} </strong></h6>
                          </div>
                          <div className="col-lg-2 col-md-2">
                            <button className="btn col-12 saveInfo" onClick={(e) => handleAddEditGrua(values)} disabled={!(isValid) || values.ColoniaId === 0}>Guardar</button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Asignación de grua</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">

                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <SelectField id="EmpresaId" label="Empresa" name="EmpresaId" items={dataEmpresaId} onChange={(event) => {
                            setFieldValue("EmpresaId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                          <TextField id="GruaAsignada" label="Grua Asignada" name="GruaAsignada" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("GruaAsignada", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                          <TextField id="CostoCliente" label="Costo para cliente" name="CostoCliente" type="text" holder="Escribe" disabled onChange={(event) => {
                            setFieldValue("CostoCliente", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                          <button className="btn col-11 saveInfo" onClick={(e) => ChangeModalCosto(true)}>Registrar costo</button>
                        </div>
                      </div>

                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Indicar ubicación de recolección y destino</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <SelectField id="EstadoId" label="Estado" name="EstadoId" items={dataEstado} onChange={(event) => {
                            setFieldValue("EstadoId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <SelectField id="MunicipioId" label="Municipio" name="MunicipioId" items={dataMunicipio} onChange={(event) => {
                            setFieldValue("MunicipioId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <SelectField id="ColoniaId" label="Colonia" name="ColoniaId" items={dataColonia} onChange={(event) => {
                            setFieldValue("ColoniaId", event.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <TextField id="Calle" label="Calle" name="Calle" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("Calle", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <TextField id="Numero" label="Número" name="Numero" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("Numero", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <TextField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("TallerAutorizado", event.target.value);
                          }} />
                        </div>
                      </div>

                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Referencia de ubicación</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                          <TextAreaField id="ReferenciaUbicacion" label="Referencia de ubicación" className="form-control col-12" rows="3" name="ReferenciaUbicacion" type="text" holder="Escribe"
                            onChange={(event) => {
                              setFieldValue("ReferenciaUbicacion", event.target.value);
                            }} />
                        </div>
                      </div>


                      <div className="row mt-2 px-4">
                        <div className="col-12">
                          <small><strong>Programar recolección</strong></small>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2 px-4">
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          <TextField id="FechaHoraCita" label="Fecha y hora de cita" name="FechaHoraCita" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("FechaHoraCita", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                          <button className="btn col-11 saveInfo">Ver calendario</button>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          <TextField id="EstatusCita" label="Estatus cita" name="EstatusCita" type="text" holder="Escribe" onChange={(event) => {
                            setFieldValue("EstatusCita", event.target.value);
                          }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                          <button className="btn col-11 saveInfo" disabled={values.FechaHoraCita !== "" ? false : true}>Autorizar cita</button>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </>
            )
            }
          </Formik>
        </div>
      )}

      <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
        <div className='row mx-md-4 my-4' >
          <div className="col col-12 px-md-3">
            <div className="p-4 white-wrapper mb-2">
              <div className="row">
                <div className="col-9">
                  <b><span>Mantenimientos</span></b>
                </div>
                <div className="col-3">
                  <button className="btn col-8 saveInfo" onClick={(e) => setMantenimiento(true)}>Registrar mantenimiento</button>
                </div>
              </div>
              {
                list.length === 0 &&
                <div className="col-12 text-center">
                  <span>No hay datos que mostrar</span>
                </div>
              }
              {
                list.length > 0 &&
                <>
                  <DataTable
                    column={columMatment}
                    data={mantenimientos}
                    editable={true}
                    // handleEdit={handleEdit}
                    pageCount={0}
                    currentPage={1 - 1}
                  // checkbox={true}
                  // handleCheck={handlechekedItem}
                  />
                </>
              }
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default IncidenciasLlamada