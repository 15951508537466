import React, { useState } from 'react'

const useModal = (initialValue =false) => {
    const [isOpen, setIsOpen] = useState(initialValue);
    
    const OpenModal = () => setIsOpen(true);
    const CloseModal =() => setIsOpen(false);

  return [isOpen,OpenModal,CloseModal];
}

export default useModal