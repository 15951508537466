import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import documentsHelper from '../../../Utils/documents';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Llamada from '../../../Services/Cartera/Llamadas';
import { clearHour } from '../../../Redux/Slice/entregaSlice';

const CardEntrega
    = React.forwardRef(({ ...props }, ref) => {
        const { BloqueoId, FechaCita, EstatusCitaGarantia, FolioGarantia, LlamadaId, KilometrajeActual, CarnetServicioId, FolioCarnet, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, TallerAutorizado, GeneralId, TallerAutorizadoId, Ejecutivo, TipoGarantiaId, TipoGarantia, EstatusGarantia, StatusGarantiaId } = useSelector((state) => state.GarantiaSlice);
        const { dayEntrega, hourEntrega, sucursalId, sucursalName, calendarioIdEntrega } = useSelector((state) => state.EntregaSlice);
        const { generalId, diagnostico } = props
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();

        const { fechaEntrega,
            nombreYear,
            vin,
            nombreMarca,
            nombreModelo,
            nombreVersion,
            numContrato,
            kilometraje,
            cliente,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);

        let initial = {
            garantiaId: GarantiaId,
            BloqueoId: null,
            garantiaEntregaId: "",
            entregaFolio: 0,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: null,
            fechaActualiza: null,
            garantiaEntregaStatusId: 0,
            garantiaEntregaStatus: "",
            status: null,
            usuarioAltaNombre: "",
            usuarioActualizaNombre: null,
            fechaHoraCita: null,
            citaAutorizada: null,
            usuarioEntregaUnidad: null,
            usuarioEntregaUnidadNombre: null,
            pruebaManejo: null,
            km: null,
            clienteConforme: null,
            garantiaEntregaMotivoRechazoId: null,
            garantiaEntregaMotivoRechazo: null,
            comentariosCliente: null,
            concluida: null,
            garantiaEntregaMotivoCancelacionCitaId: null,
            garantiaEntregaMotivoCancelacionCita: null,
            comentariosCancelacionCita: null,
            documentoDescargado: false

        }


        const navigate = useNavigate();
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        let { idllamada } = useParams();

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [verEntregas, setVerEntregas] = useState(false);
        const [EntregaStatus, setEntregaStatus] = useState('');
        const [disabled, setDisabled] = useState(false);
        const [_tipoGarantiaId, setTipoGarantiaId] = useState(null);
        const [bloqueoId, setBloqueoId] = useState(null);
        const [cita, setCita] = useState('');
        const [motivosRechazo, setMotivosRechazo] = useState([]);



        useEffect(() => {
            if (hourEntrega) {
                const formattedDate = `${dayEntrega.split("T")[0].split("-").reverse().join('-')} ${hourEntrega}`;
                setInitialValue({
                    ...initialValue,
                    fechaHoraCita: formattedDate
                });
                setCita(formattedDate);
            }
        }, [hourEntrega]);



        useEffect(() => {
            getEntrega();
            getMotivosRechazaEntrega();
        }, []);

        React.useImperativeHandle(ref, () => ({
            // getReparacion
        }));

        const getEntrega = async () => {
            setLoading(true)
            await Garantias.garantiaEntregaGet(GarantiaId)
                .then(resp => {
                    const data = resp.data;
                    setEntregaStatus(data.garantiaEntregaStatus);
                    setInitialValue({
                        garantiaEntregaId: data.garantiaEntregaId,
                        garantiaId: data.garantiaId,
                        entregaFolio: data.entregaFolio,
                        fechaAlta: data.fechaAlta,
                        BloqueoId: data.bloqueoId,
                        usuarioAlta: data.usuarioAlta,
                        usuarioActualiza: data.usuarioActualiza,
                        fechaActualiza: data.fechaActualiza,
                        garantiaEntregaStatusId: data.garantiaEntregaStatusId,
                        garantiaEntregaStatus: data.garantiaEntregaStatus,
                        status: data.status,
                        usuarioAltaNombre: data.usuarioAltaNombre,
                        usuarioActualizaNombre: data.usuarioActualizaNombre,
                        fechaCita: data.fechaCita,
                        horaCita: data.horaCita,
                        statusCita: data.statusCita,
                        citaAutorizada: data.citaAutorizada,
                        usuarioEntregaUnidad: data.usuarioEntregaUnidad,
                        usuarioEntregaUnidadNombre: data.usuarioEntregaUnidadNombre,
                        pruebaManejo: data.pruebaManejo ? 1 : 0,
                        km: data.km,
                        clienteConforme: data.clienteConforme ? 1 : 0,
                        garantiaEntregaMotivoRechazoId: data.garantiaEntregaMotivoRechazoId,
                        garantiaEntregaMotivoRechazo: data.garantiaEntregaMotivoRechazo,
                        comentariosCliente: data.comentariosCliente,
                        concluida: data.concluida,
                        garantiaEntregaMotivoCancelacionCitaId: data.garantiaEntregaMotivoCancelacionCitaId,
                        garantiaEntregaMotivoCancelacionCita: data.garantiaEntregaMotivoCancelacionCita,
                        comentariosCancelacionCita: data.comentariosCancelacionCita,
                        documentoDescargado: data.documentoDescargado
                    });
                    setBloqueoId(data.bloqueoId);
                    setCita(data.fechaCita?.split("T")[0].split("-").reverse().join('-') + " " + data.horaCita);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const getMotivosRechazaEntrega = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.GarantiaEntregaMotivoRechazo')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({
                            value: item.GarantiaEntregaMotivoRechazoId,
                            label: item.GarantiaEntregaMotivoRechazo,
                        });
                    });
                    setMotivosRechazo(items);
                })
                .catch(err => {
                    console.error(err);
                });
        }



        const downloadPDFEntrega = async () => {
            const requestBody = {
                nombrePlantilla: "EntregaUnidad",
                parametros: {
                    "<<VARIABLE_CONTRATO>>": numContrato,
                    "<<VARIABLE_VIN>>": vin,
                    "<<VARIABLE_DATAMOVIL>>": "",
                    "<<VARIABLE_CLIENTE>>": cliente,
                    "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                    "<<VARIABLE_TRANSMISION>>": "",
                    "<<VARIABLE_FOLIO>>": initialValue.entregaFolio,
                    "<<VARIABLE_DIAGNOSTICO>>": diagnostico,
                }
            };

            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
                if (!initialValue.documentoDescargado) {
                    actualizarPDF();
                }

            }).catch(err => {
                console.error(err);
            });

        }

        const downloadPDFUnidad = async () => {
            const requestBody = {
                nombrePlantilla: "Garantia",
                parametros: {
                    "<<VARIABLE_CONTRATO>>": numContrato,
                    "<<VARIABLE_VIN>>": vin,
                    "<<VARIABLE_DATAMOVIL>>": "",
                    "<<VARIABLE_CLIENTE>>": cliente,
                    "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                    "<<VARIABLE_TRANSMISION>>": "",
                    "<<VARIABLE_FOLIO>>": initialValue.entregaFolio,
                }
            };
            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

            }).catch(err => {
                console.error(err);
            });
        }

        const actualizarPDF = async () => {
            let params = {
                garantiaId: GarantiaId,
                documentoDescargado: true
            }
            await Garantias.garantiaEntregaActualizar(params)
                .then(resp => {
                    const data = resp.data;
                    setInitialValue({
                        garantiaEntregaId: data.garantiaEntregaId,
                        garantiaId: data.garantiaId,
                        entregaFolio: data.entregaFolio,
                        fechaAlta: data.fechaAlta,
                        usuarioAlta: data.usuarioAlta,
                        usuarioActualiza: data.usuarioActualiza,
                        fechaActualiza: data.fechaActualiza,
                        garantiaEntregaStatusId: data.garantiaEntregaStatusId,
                        garantiaEntregaStatus: data.garantiaEntregaStatus,
                        status: data.status,
                        usuarioAltaNombre: data.usuarioAltaNombre,
                        usuarioActualizaNombre: data.usuarioActualizaNombre,
                        fechaHoraCita: data.fechaHoraCita,
                        citaAutorizada: data.citaAutorizada,
                        usuarioEntregaUnidad: data.usuarioEntregaUnidad,
                        usuarioEntregaUnidadNombre: data.usuarioEntregaUnidadNombre,
                        pruebaManejo: data.pruebaManejo ? 1 : 0,
                        km: data.km,
                        clienteConforme: data.clienteConforme ? 1 : 0,
                        garantiaEntregaMotivoRechazoId: data.garantiaEntregaMotivoRechazoId,
                        garantiaEntregaMotivoRechazo: data.garantiaEntregaMotivoRechazo,
                        comentariosCliente: data.comentariosCliente,
                        concluida: data.concluida,
                        fechaCita: data.fechaCita,
                        garantiaEntregaMotivoCancelacionCitaId: data.garantiaEntregaMotivoCancelacionCitaId,
                        garantiaEntregaMotivoCancelacionCita: data.garantiaEntregaMotivoCancelacionCita,
                        comentariosCancelacionCita: data.comentariosCancelacionCita,
                        documentoDescargado: data.documentoDescargado
                    });
                })
                .catch(err => {
                    console.error(err);
                    // toast.error(message("¡Error.!", err.response.data.message));
                });
        };

        const notificacionSolicitudEntregaUnidad = async () => {
            const date = new Date();
            const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
            const contenido = {
                VARIABLE_EJECUTIVO: Ejecutivo,
                VARIABLE_UBICACIÓN: sucursalName,
                VARIABLE_MARCA: nombreMarca,
                VARIABLE_MODELO: nombreModelo,
                VARIABLE_ANO: nombreYear,
                VARIABLE_VERSION: nombreVersion,
                VARIABLE_VIN: vin,
                VARIABLE_CONTRATO: numContrato,
                VARIABLE_FECHA: fragmentsDate[0],
                VARIABLE_HORA: fragmentsDate[1],
            }

            Garantias.creardatosenviar({
                modulo: 'Solicitud de Entrega Unidad',
                tipoCorreoId: 31,
                contenido: JSON.stringify(contenido)
            }).catch(err => {
                console.log("Error al enviar correo Solicitud de Entrega Unidad: ", err);
            })
        }

        const notificacionEntregaAprobada = async () => {
            const date = new Date();
            const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
            const contenido = {
                VARIABLE_FECHA: fragmentsDate[0],
                VARIABLE_HORA: fragmentsDate[1],
                VARIABLE_UBICACION: sucursalName,
                VARIABLE_MARCA: nombreMarca,
                VARIABLE_MODELO: nombreModelo,
                VARIABLE_AÑO: nombreYear,
                VARIABLE_VERSION: nombreVersion,
                VARIABLE_VIN: vin,
                VARIABLE_CONTRATO: numContrato,
            }
            getEntrega();
            Garantias.creardatosenviar({
                modulo: 'Entrega de Unidad Aprobada',
                tipoCorreoId: 32,
                contenido: JSON.stringify(contenido)
            }).catch(err => {
                console.log("Error al enviar correo Entrega de Unidad Aprobada: ", err);
            })
        }

        const notificacionEntregaCancelada = async () => {
            const date = new Date();
            const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
            const contenido = {
                VARIABLE_UBICACION: sucursalName,
                VARIABLE_FECHA: fragmentsDate[0],
                VARIABLE_HORA: fragmentsDate[1],
                VARIABLE_MOTIVO: "",
                VARIABLE_MARCA: nombreMarca,
                VARIABLE_MODELO: nombreModelo,
                VARIABLE_AÑO: nombreYear,
                VARIABLE_VERSION: nombreVersion,
                VARIABLE_VIN: vin,
                VARIABLE_CONTRATO: numContrato,
            }

            dispatch(clearHour());
            getEntrega();
            Garantias.creardatosenviar({
                modulo: 'Entrega de Unidad Cancelada',
                tipoCorreoId: 33,
                contenido: JSON.stringify(contenido)
            }).catch(err => {
                console.log("Error al enviar correo Entrega de Unidad Cancelada: ", err);
            })
        }

        const notificacionEntregaUnidad = async () => {
            const date = new Date();
            const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
            const contenido = {
                VARIABLE_CONTRATO: numContrato,
                VARIABLE_FECHA: fragmentsDate[0],
                VARIABLE_HORA: fragmentsDate[1],
                VARIABLE_QUIEN_ENTREGA: "",
            }

            Garantias.creardatosenviar({
                modulo: 'Entrega de Unidad',
                tipoCorreoId: 34,
                contenido: JSON.stringify(contenido)
            }).catch(err => {
                console.log("Error al enviar correo Entrega de Unidad: ", err);
            })
        }

        const notificacionCambioDevolucion = async () => {
            const contenido = {
                VARIABLE_EJECUTIVO: "",
                VARIABLE_CONTRATO: numContrato,
                VARIABLE_MOTIVO: ""
            }

            Garantias.creardatosenviar({
                modulo: 'Solicitud Cambio / Devolución',
                tipoCorreoId: 35,
                contenido: JSON.stringify(contenido)
            }).catch(err => {
                console.log("Error al enviar correo Solicitud Cambio / Devolución: ", err);
            })
        }

        const submitForm = async (values) => {
            // console.log(values);
            // return false;
            if (values?.fechaCita === null || values?.fechaCita === undefined) {
                const dBloqueo = {
                    ubicacionid: sucursalId,
                    fecha: `${dayEntrega.split("T")[0]}`,
                    calendarioid: calendarioIdEntrega,
                    calendariotipoid: 3,
                    relacionid: GarantiaId
                }

                await Llamada.crearCita(dBloqueo)
                    .then(resp => {
                        const data = resp.data.data;
                        console.log(data);
                        setInitialValue({
                            ...initialValue,
                            fechaCita: dayEntrega.split("T")[0],
                            horaCita: hourEntrega,
                        })
                        actualizaCitaEntrega(data.bloqueoId);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                if (values.concluida) {
                    let status = 0;

                    if (values.clienteConforme === 1) {
                        status = 40;
                    } else {
                        status = 50;
                    }

                    if (!values.clienteConforme) {
                        if (values.garantiaEntregaMotivoRechazoId === null) {
                            toast.error(message("¡Error!, debes seleccionar un motivo de rechazo"));
                            return false;
                        }

                        if (values.comentariosCliente === null || values.comentariosCliente.trim().length === 0) {
                            toast.error(message("¡Error!, debes ingresar algún comentario del cliente"));
                            return false;
                        }
                    }

                    let params = {
                        garantiaId: GarantiaId,
                        pruebaManejo: values.pruebaManejo,
                        km: values.km,
                        garantiaEntregaStatusId: status,
                        clienteConforme: values.clienteConforme,
                        garantiaEntregaMotivoRechazoId: values.garantiaEntregaMotivoRechazoId,
                        comentariosCliente: values.comentariosCliente,
                        concluida: values.concluida,
                        motivoCancelacionId: values.motivoCancelacionId,
                        comentariosCancelacionCita: values.comentariosCancelacionCita,

                    }

                    await Garantias.garantiaEntregaActualizar(params)
                        .then(resp => {
                            const data = resp.data;
                            setEntregaStatus(data.garantiaEntregaStatus);
                            setInitialValue({
                                garantiaEntregaId: data.garantiaEntregaId,
                                garantiaId: data.garantiaId,
                                entregaFolio: data.entregaFolio,
                                fechaAlta: data.fechaAlta,
                                usuarioAlta: data.usuarioAlta,
                                usuarioActualiza: data.usuarioActualiza,
                                fechaActualiza: data.fechaActualiza,
                                garantiaEntregaStatusId: data.garantiaEntregaStatusId,
                                garantiaEntregaStatus: data.garantiaEntregaStatus,
                                status: data.status,
                                usuarioAltaNombre: data.usuarioAltaNombre,
                                usuarioActualizaNombre: data.usuarioActualizaNombre,
                                fechaHoraCita: data.fechaHoraCita,
                                citaAutorizada: data.citaAutorizada,
                                usuarioEntregaUnidad: data.usuarioEntregaUnidad,
                                usuarioEntregaUnidadNombre: data.usuarioEntregaUnidadNombre,
                                pruebaManejo: data.pruebaManejo ? 1 : 0,
                                km: data.km,
                                fechaCita: data.fechaCita,
                                clienteConforme: data.clienteConforme ? 1 : 0,
                                garantiaEntregaMotivoRechazoId: data.garantiaEntregaMotivoRechazoId,
                                garantiaEntregaMotivoRechazo: data.garantiaEntregaMotivoRechazo,
                                comentariosCliente: data.comentariosCliente,
                                concluida: data.concluida,
                                garantiaEntregaMotivoCancelacionCitaId: data.garantiaEntregaMotivoCancelacionCitaId,
                                garantiaEntregaMotivoCancelacionCita: data.garantiaEntregaMotivoCancelacionCita,
                                comentariosCancelacionCita: data.comentariosCancelacionCita,
                                documentoDescargado: data.documentoDescargado
                            });

                            toast.success(message("Registro cerrado correctamente"));

                            if (values.clienteConforme === 0) {
                                crearCambioDevolucion();
                                notificacionCambioDevolucion();
                            } else {
                                notificacionEntregaUnidad();
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            toast.error(message("¡Error.!", err.response.data.message));
                        });


                } else {
                    let params = {
                        garantiaId: GarantiaId,
                        pruebaManejo: values.pruebaManejo,
                        km: values.km,
                        clienteConforme: values.clienteConforme,
                        garantiaEntregaMotivoRechazoId: values.garantiaEntregaMotivoRechazoId,
                        comentariosCliente: values.comentariosCliente,
                        concluida: values.concluida,
                        motivoCancelacionId: values.motivoCancelacionId,
                        comentariosCancelacionCita: values.comentariosCancelacionCita,

                    }

                    await Garantias.garantiaEntregaActualizar(params)
                        .then(resp => {
                            const data = resp.data;
                            setEntregaStatus(data.garantiaEntregaStatus);
                            setInitialValue({
                                garantiaEntregaId: data.garantiaEntregaId,
                                garantiaId: data.garantiaId,
                                entregaFolio: data.entregaFolio,
                                fechaAlta: data.fechaAlta,
                                usuarioAlta: data.usuarioAlta,
                                usuarioActualiza: data.usuarioActualiza,
                                fechaActualiza: data.fechaActualiza,
                                garantiaEntregaStatusId: data.garantiaEntregaStatusId,
                                garantiaEntregaStatus: data.garantiaEntregaStatus,
                                status: data.status,
                                usuarioAltaNombre: data.usuarioAltaNombre,
                                usuarioActualizaNombre: data.usuarioActualizaNombre,
                                fechaHoraCita: data.fechaHoraCita,
                                citaAutorizada: data.citaAutorizada,
                                usuarioEntregaUnidad: data.usuarioEntregaUnidad,
                                usuarioEntregaUnidadNombre: data.usuarioEntregaUnidadNombre,
                                pruebaManejo: data.pruebaManejo,
                                km: data.km,
                                fechaCita: data.fechaCita,
                                clienteConforme: data.clienteConforme ? 1 : 0,
                                garantiaEntregaMotivoRechazoId: data.garantiaEntregaMotivoRechazoId,
                                garantiaEntregaMotivoRechazo: data.garantiaEntregaMotivoRechazo,
                                comentariosCliente: data.comentariosCliente,
                                concluida: data.concluida,
                                garantiaEntregaMotivoCancelacionCitaId: data.garantiaEntregaMotivoCancelacionCitaId,
                                garantiaEntregaMotivoCancelacionCita: data.garantiaEntregaMotivoCancelacionCita,
                                comentariosCancelacionCita: data.comentariosCancelacionCita,
                                documentoDescargado: data.documentoDescargado
                            });

                            toast.success(message("Registro actualizado correctamente"));
                        })
                        .catch(err => {
                            console.error(err);
                            toast.error(message("¡Error.!", err.response.data.message));
                        });
                }

            }
        }

        const crearCambioDevolucion = async () => {
            let params = {
                origen: "garantia",
                origenId: GarantiaId,
                cambioDevolucionStatusId: 10,
                boletinado: 1,
            };
            await Garantias.garantiaCambioDevolucionCrear(params)
                .then(resp => {
                    crearBoletinado(resp.data.cambioDevolucionId);
                    toast.success(message("Se creo el registro en Cambio/Devolución Correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error al crear la devolución!"));
                });
        }

        const crearBoletinado = async (cambioDevolucionId) => {
            let params = {
                cambioDevolucionId: cambioDevolucionId,
                cambioDevolucionBoletinadoMotivoId: 0,
                cambioDevolucionBoletinadoStatusId: 10
            }

            await Garantias.boletinadoCrear(params)
                .then(resp => {

                    toast.success(message("Se creo el registro en Boletinado Correctamente"));
                    console.log(resp);
                })
                .catch(err => {
                    toast.error(message("¡Error al crear el boletinado!"));
                    console.error(err);
                });
        }

        const actualizaCitaEntrega = async (bloqueoId) => {
            let params = {
                garantiaId: GarantiaId,
                garantiaEntregaStatusId: 10,
                fechaCita: dayEntrega.split("T")[0],
                horaCita: hourEntrega,
                bloqueoId,
                statusCita: 1
            };

            await Garantias.garantiaEntregaActualizar(params)
                .then(resp => {
                    const data = resp.data;
                    setEntregaStatus(data.garantiaEntregaStatus);
                    setInitialValue({
                        garantiaEntregaId: data.garantiaEntregaId,
                        garantiaId: data.garantiaId,
                        entregaFolio: data.entregaFolio,
                        fechaAlta: data.fechaAlta,
                        usuarioAlta: data.usuarioAlta,
                        usuarioActualiza: data.usuarioActualiza,
                        fechaActualiza: data.fechaActualiza,
                        garantiaEntregaStatusId: data.garantiaEntregaStatusId,
                        garantiaEntregaStatus: data.garantiaEntregaStatus,
                        status: data.status,
                        usuarioAltaNombre: data.usuarioAltaNombre,
                        usuarioActualizaNombre: data.usuarioActualizaNombre,
                        fechaHoraCita: data.fechaHoraCita,
                        citaAutorizada: data.citaAutorizada,
                        usuarioEntregaUnidad: data.usuarioEntregaUnidad,
                        usuarioEntregaUnidadNombre: data.usuarioEntregaUnidadNombre,
                        pruebaManejo: data.pruebaManejo,
                        km: data.km,
                        clienteConforme: data.clienteConforme ? 1 : 0,
                        garantiaEntregaMotivoRechazoId: data.garantiaEntregaMotivoRechazoId,
                        garantiaEntregaMotivoRechazo: data.garantiaEntregaMotivoRechazo,
                        comentariosCliente: data.comentariosCliente,
                        concluida: data.concluida,
                        garantiaEntregaMotivoCancelacionCitaId: data.garantiaEntregaMotivoCancelacionCitaId,
                        garantiaEntregaMotivoCancelacionCita: data.garantiaEntregaMotivoCancelacionCita,
                        comentariosCancelacionCita: data.comentariosCancelacionCita,
                        documentoDescargado: data.documentoDescargado
                    });
                    notificacionSolicitudEntregaUnidad();

                    toast.success(message("Registro actualizado correctamente"));
                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error.!", err.response.data.message));
                });
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear() + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }



        const verCalendario = async (valor, values) => {
            dispatch(setSchedule({
                url: `garantias/incidenciasinfo/${vin}/${idllamada}`,
                contrato: numContrato,
                folioLlamada: FolioLlamada,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }

        const verModalAutorizarCita = async (values) => {
            const formattedDate = `${dayEntrega.split("T")[0].split("-").reverse().join('-')} ${hourEntrega}`;
            let data = {
                "llamadaId": idllamada,
                "garantiaId": GarantiaId,
                "generalId": GeneralId,
                "folioCita": "",
                "folioLlamada": FolioLlamada,
                "folioGarantia": FolioGarantia,
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreMarca,
                "claveModelo": nombreModelo,
                "nombreModelo": nombreModelo,
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ejecutivoGarantia": Ejecutivo,
                "ubicacionId": sucursalId,
                "ubicacion": sucursalName,
                "tipoGarantiaId": null,
                "clasificacionFallaId": null,
                "telefono": "",
                "fechaCita": formattedDate,
                "_bloqueoId": bloqueoId
            }

            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 3, Origin: "Listado" })
        }


        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const validate = Yup.object({
            garantiaEntregaStatusId: Yup.number(),
            km: Yup.number()
                .when("garantiaEntregaStatusId", {
                    is: 30,
                    then: Yup.number()
                        .typeError('Debe contener solo números y no exceder los 10 caracteres')
                        .integer()
                        .min(kilometraje, `El kilometraje no debe ser menor a ${kilometraje}`)
                        .required("El campo es requerido")

                }),
            pruebaManejo: Yup.boolean()
                .required("El campo es requerido"),
            clienteConforme: Yup.number()
                .required("El campo es requerido")
        });


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={960}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            notificacion={notificacionEntregaAprobada}
                            getEntrega={getEntrega}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            notificacion={notificacionEntregaCancelada}
                            getEntrega={getEntrega}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseEntrega" role="button" aria-expanded="false" aria-controls="collapseEntrega" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={!initialValue.concluida ? 'semaforo semaforo-yellow' : (initialValue.concluida ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Entrega</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{EntregaStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseEntrega">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Cita de entrega</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button className='btn btn-gray'>
                                                                            <i className="icon-blue fa fa-file"></i> Subir documento
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid}
                                                                            onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <span>Fecha y hora de cita</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                <span>{cita}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <button className="btn mt-4 col-12 saveInfo" disabled={initialValue.garantiaEntregaStatusId != 0} onClick={(e) => verCalendario(3, values)}>Ver calendario</button>
                                                        </div>
                                                        <div className="col-3">
                                                            <span>Estatus Cita</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                {
                                                                    values.fechaCita && bloqueoId === null &&
                                                                    <span>Sin Agendar</span>
                                                                }
                                                                {
                                                                    (values.fechaCita && bloqueoId !== null && values.statusCita === 1 || values.fechaCita && bloqueoId !== null && values.statusCita === '') &&
                                                                    <span>Solicitado</span>
                                                                }
                                                                {
                                                                    values.fechaCita && bloqueoId !== null && values.statusCita === 2 &&
                                                                    <span>Autorizada</span>
                                                                }
                                                                {
                                                                    values.fechaCita && bloqueoId !== null && values.statusCita === 3 &&
                                                                    <span>Reagendar</span>
                                                                }
                                                                {
                                                                    values.fechaCita && bloqueoId !== null && values.statusCita === 4 &&
                                                                    <span>Cancelado</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <button className='btn btn-gray mt-4 col-12' disabled={initialValue.BloqueoId === null || initialValue.garantiaEntregaStatusId !== 10} onClick={(e) => verModalAutorizarCita(values)}>Autorizar cita</button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar documento</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Entrega de unidad</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' onClick={() => downloadPDFEntrega()} disabled={initialValue.garantiaEntregaStatusId !== 30}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Garantía</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' onClick={() => downloadPDFUnidad()} disabled={initialValue.garantiaEntregaStatusId !== 30}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Registrar entrega de unidad</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioEntregaUnidadNombre" disabled={true} label="Usuario entrega unidad" name="usuarioEntregaUnidadNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioEntregaUnidadNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="pruebaManejo"
                                                                label="Prueba de manejo"
                                                                disabled={!initialValue.documentoDescargado}
                                                                name="pruebaManejo"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("pruebaManejo", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="km" label="Km de entrega" name="km" disabled={!initialValue.documentoDescargado} type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("km", event.target.value);
                                                                // forceKm(event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="clienteConforme"
                                                                label="Cliente conforme"
                                                                disabled={!initialValue.documentoDescargado}
                                                                name="clienteConforme"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("clienteConforme", event.value);
                                                                    // forceData(values,event.value);
                                                                }} />
                                                        </div>
                                                        {
                                                            values.clienteConforme === 0 &&
                                                            <div className="col-3">
                                                                <SelectField id="garantiaEntregaMotivoRechazoId"
                                                                    label="Motivo rechazo"
                                                                    disabled={!initialValue.documentoDescargado}
                                                                    name="garantiaEntregaMotivoRechazoId"
                                                                    items={motivosRechazo}
                                                                    onChange={(event) => {
                                                                        setFieldValue("garantiaEntregaMotivoRechazoId", event.value);
                                                                    }} />
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        values.clienteConforme === 0 &&
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-6">
                                                                <TextAreaField id="comentariosCliente"
                                                                    label="Comentarios cliente"
                                                                    disabled={!initialValue.documentoDescargado}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentariosCliente"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentariosCliente", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={!initialValue.documentoDescargado} type="checkbox" name="concluida" onClick={(event) => {
                                                                setFieldValue("concluida", event.target.value);
                                                                // setConcluida(event.target.checked);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Finalizar proceso</label>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaAlta !== "" &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaConclusion !== "" && initialValue.concluida &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default CardEntrega