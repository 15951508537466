import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const RequisitosModal = ({ modalMessage, setModalMessage, habitual, fisica, moral }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        buscar: ""
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [tabIndex, setTabIndex] = useState(1);

    useEffect(() => {
    }, []);

    const validate = Yup.object().shape({});
    

    const cerateMarkup = (value) => {
        return { __html: value };
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación indica al cliente los requisitos para cumplir con el proceso.
                                    </small>
                                </div>
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${tabIndex == 1 ? 'tab-active' : ''}`} value={1} type="button" role="tab" onClick={(e) => { setTabIndex(1); }}>Persona Física con conductor habitual</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${tabIndex == 2 ? 'tab-active' : ''}`} value={2} type="button" role="tab" onClick={(e) => { setTabIndex(2); }}>Persona Física</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${tabIndex == 3 ? 'tab-active' : ''}`} value={3} type="button" role="tab" onClick={(e) => { setTabIndex(3); }}>Persona Moral</button>
                                            </li>
                                        </ul>
                                        {
                                            tabIndex === 1 &&
                                            <>
                                                <div dangerouslySetInnerHTML={cerateMarkup(habitual)}></div>
                                            </>
                                        }
                                        {
                                            tabIndex === 2 &&
                                            <>
                                                <div dangerouslySetInnerHTML={cerateMarkup(fisica)}></div>
                                            </>
                                        }
                                        {
                                            tabIndex === 3 &&
                                            <>
                                                <div dangerouslySetInnerHTML={cerateMarkup(moral)}></div>
                                            </>
                                        }
                                    </div>
                                    <div className="col-12 pt-1" style={{ 'border': 'solid', 'borderWidth': 'thin 0px 0px 0px', 'borderColor': '#EFEFEF' }}>
                                        <div className="row d-flex align-content-center">
                                            <div className="col-1 mt-3 text-center">
                                                <i className="icon-dark fas fa-exclamation-triangle x2"></i>
                                            </div>
                                            <div className="col-11">
                                                <small>Los pagos que se requieren en efectivo, se le piden exactos, no manejamos cambio, ni pagos con transferencia o tarjeta. Se debe acudir con los requisitos completos para realizar el trámite correctamente</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default RequisitosModal;
