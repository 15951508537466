import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleRadioCheckItem, handleUnchekedColumns, message } from '../../Utils/functions';
import vehiculoSeparadoService from '../../Services/Venta/Vehiculo/vehiculoSeparadoService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';

const useVehiculoSeparado = (toast) => {
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'solicitud', headerName: 'No. de Solicitud', width: 160 },
    { field: 'usoUnidad', headerName: 'Uso unidad', width: 160 },
    { field: 'nombreMarca', headerName: 'Marca', width: 160 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'tipoCompra', headerName: 'Tipo compra', width: 130 },
    { field: 'pendiente', headerName: 'Etapa Pendiente', width: 130 },
    { field: 'producto', headerName: 'Producto', width: 230 },
    { field: 'tipoSeguro', headerName: 'Tipo de seguro', width: 180 },
    { field: 'fechaSeparado', headerName: 'Fecha de separado', width: 200 },
  ];


  let dataPendiente = [
    { label: "Todos", value: "" },
    { label: "Seguro Final", value: 0 },
    { label: "Cotización Final", value: 1 },
  ];

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [clavePendiente, setclavePendiente] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataUsoUnidad, setDataUsoUnidad] = useState([]);
  //DataDocs
  const [usoUnidadValue, setusoUnidadValue] = useState("");
  const [pageRows, setpageRows] = useState(10)
  const [IdsEliminar, setIdsEliminar] = useState([]);
  const [item, setItem] = useState(null);
  const [isOpenModalLiberar, setIsOpenModalLiberar] = useState(false);
  //
  useEffect(() => {
    handleUnchekedColumns()
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [str, usoUnidadValue, pageRows, clavePendiente])
  useEffect(() => {
    handleGetAllUsoUnidad();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await vehiculoSeparadoService.GetAllVehiculoSeparado(page, pageRows, str, usoUnidadValue, clavePendiente)
      .then(res => {
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetAllUsoUnidad = async () => {
    await SeguroSinCotizarService.getlAllUsoUnidad()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.usoUnidadId,
            label: item.usoUnidad
          })
        });
        setDataUsoUnidad(NewData);
      }).catch(e => {

      })
  }
  const handlechekedItem = (cheked, value, i) => {
    if (value.pendiente === "Cotización Final") {
      handleUnchekedColumns()
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No es posible realizar la liberación de unidad en esta etapa.")}</div>);
      setItem(null);
      setTimeout(() => {
        toast.remove();
      }, 2000);
      return;
    }
    handleRadioCheckItem(i);

    if (cheked) {
      setItem(value);
    } else {
      setItem(null);
    }

  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };


  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    handlechekedItem,
    setpageRows,
    dataUsoUnidad,
    setusoUnidadValue,
    setIsOpenModalLiberar,
    isOpenModalLiberar,
    habdleGetAll,
    item,
    dataPendiente,
    setclavePendiente
  }
}

export default useVehiculoSeparado