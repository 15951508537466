import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Datamovil from './Datamovil'
import Intelimotor from './Intelimotor'
import SocioComercial from './SocioComercial'
import SubmenuCat from '../../Components/SubmenuCat'

const CatalogoLST = () => {
    return (
        <>           
            <SubmenuCat/>
            {/* <div className="tab-content" id="myTabContent">
                <Outlet/>
            </div>            */}
        </>
    )
}

export default CatalogoLST