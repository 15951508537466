import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import Catalogo from '../../Services/Catalogos/Catalogo';
import ComisionesExternoService from '../../Services/Inventario/Comisiones/ComisionesExternoService';
import RevisionUnidadRechazadaService from '../../Services/Inventario/RevisionUnidadRechazadaService';
import { message } from '../../Utils/functions';

const useRevisionUnidadRechazada = (toast) => {
  const navigate = useNavigate();
  const selectRef = useRef();
  const refBtn = useRef();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
    { field: 'externo', headerName: 'Externo', width: 200 },
    { field: 'nombre_Tecnico', headerName: 'Mecánico', width: 200 },
    { field: 'strFechaRevision', headerName: 'Fecha de la revisión', width: 150 },
    { field: 'comentariosRU', headerName: 'Comentarios', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 160 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'nombreVersion', headerName: 'Versión', width: 180 },
  ];

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [tipoAd, setTipoAd] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
  const [ClaveEtapa, setClaveEtapa] = useState("");
  const [razonSocial, setrazonsocial] = useState([]);
  const [claveSocioComercial, setClaveSocioComercial] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [dataModal, setDataModal] = useState([]);
  const [IsOpenModal, setIsOpenModal] = useState(false)
  const [currenPageH, setCurrenPageH] = useState(1);
  const [utilitarioId, setUtilitarioId] = useState("");
  const [pageRows, setpageRows] = useState(10);
  //
  useEffect(() => {
    if (tipoAd !== "" || str !== "" || claveSocioComercial !== "") {
      setCurrenPage(1);
      habdleGetAll(1);
    }
    else {
      habdleGetAll(currenPage);
      setCurrenPage(1);
    }
  }, [tipoAd, str, claveSocioComercial, pageRows])
  useEffect(() => {
    handleGetAllSelects();
  }, [])

  const handleGetAllSelects = async () => {
    setLoading(true)
    const [] = await Promise.all([handleGetTipoAdquisicio()]).finally(() => {
    });
  }
  const habdleGetAll = async (page) => {
    setLoading(true);
    await RevisionUnidadRechazadaService.GetallRevisionUnidadRechazada(page, pageRows, tipoAd, claveSocioComercial ?? "", str)
      .then(res => {
        console.log("data->", res.data.data)
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        // setLoading(false);
      }).finally(() => {
        // setLoading(false)
      })
  }

  const handleGetTipoAdquisicio = async () => {
    await Catalogo.getdataTabla("TipoCompra", 1, 100, "")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        setDataTipoAdquisicion(NewData);
      }).catch(e => {

      })
  }

  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleGetCSRazonSocial = async (Id) => {
    if (Id === "") {
      setrazonsocial([]);
      selectRef.current.setValue([], 'clear')
      return;
    }
    await RevisionUnidadRechazadaService.getAllCSRazonSocialById(Id)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.RazonSocialId,
            label: item.RazonSocial
          })
        });
        setrazonsocial(NewData);
      }).catch(e => {
      })
  }
  const handleDownloadExcel = async () => {
    refBtn.current.disabled = true;
    setLoading(true)
    await RevisionUnidadRechazadaService.ExportRevisionUnidadRechazada(tipoAd, claveSocioComercial ?? "", str)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `RevisionUnidadRechazada-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);        
        setLoading(false);
        habdleGetAll(1);
        toast.success(message("¡Correcto.!", "Registros exportados correctamente."));
      }).catch(e => {
        setLoading(false)
        if (e.response !== undefined)
          toast.error(message("¡Error.!", e.response.data.message));
        else
          toast.error(message("¡Error.!", e.message));    
      }).finally(()=>{
        refBtn.current.disabled = false;
      }
      );
  }
  return {
    columns,
    data,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    habdleGetAll,
    setIsOpenModal,
    dataModal,
    IsOpenModal,
    currenPageH,
    setUtilitarioId,
    setpageRows,
    setClaveEtapa,
    setClaveSocioComercial,
    razonSocial,
    handleGetCSRazonSocial,
    selectRef,
    setOpenModalDelete,
    openModalDelete,
    setItemSelected,
    itemSelected,
    handleDownloadExcel,
    refBtn
  }
}

export default useRevisionUnidadRechazada