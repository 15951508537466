import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "../../../Components/TextField";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import { toast, Toaster } from "react-hot-toast";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SubHeaderEntrega from "./SubHeaderEntrega";
import {
  FormatNumberMoney,
  FormatNumberMoney2,
} from "../../../Utils/functions";
import GestoriaServices from "../../../Services/Inventario/GestoriaServices";
import EntregaService from "../../../Services/Inventario/EntregaService";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import VentaVehiculoService from "../../../Services/Venta/Vehiculo/ventaVehiculoService";
import { set } from "date-fns";
import Select from "react-select";

const EntregaCanceladaCaptura = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const refbtn = useRef();
  const refbtnDownload = useRef();
  const refFormik = useRef();
  let { id } = useParams();

  let informacionGeneal = {
    vin: state?.vin,
    sol_id: state?.sol_id,
    id_datamovil: state?.id_datamovil,
    status_datamovil: state?.status_datamovil,
    contrato: state?.contrato,
    nombreMarca: state?.nombreMarca,
    nombreModelo: state?.nombreModelo,
    nombreYear: state?.nombreYear,
    nombreVersion: state?.nombreVersion,
    desembolso_inicial: state?.desembolso_inicial,
  };
  console.log("state", state);
  let tipoDescuento = [
    { value: "", label: "Seleccione" },
    { value: "1", label: "Monto fijo" },
    { value: "2", label: "Porcentaje" },
  ];

  //Valores iniciales para el formulario
  let initial = {
    generalId: id,
    motivoCancelacionId: "",
    comentarios: "",
    clientePagoCambioPropietario: state?.clientePagoCambioPropietario
      ? "si"
      : "no",
    cambioPropietario: state?.cambioPropietario ? "si" : "no",
    aplicaDescuento: "",
    costoCambioPropietario: state?.costoCambioPropietario ?? "",
    montoPorDescontarCliente: "",
    montoADescontarCambioPropietario: "",
    moral: state?.moral,
    esforaneo: state?.esforaneo,
  };

  let cambioPropietario = [
    { value: "", label: "Seleccione" },
    { value: "si", label: "Si" },
    { value: "no", label: "No" },
  ];

  let aplicaDescuento = [
    { value: "", label: "Seleccione" },
    { value: "si", label: "Si" },
    { value: "no", label: "No" },
  ];

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [dataMotivosCancelacion, setDataMotivosCancelacion] = useState([]);
  const [idPaso, setIdPaso] = useState(1);
  const [soloLectura, setSoloLectura] = useState(false);
  const [conceptos, setConceptos] = useState([]);

  const validate = Yup.object({
    motivoCancelacionId: Yup.string().required("El campo es requerido."),
    comentarios: Yup.string().required("El campo es requerido."),
    clientePagoCambioPropietario: Yup.string().required(
      "El campo es requerido."
    ),
    cambioPropietario: Yup.string().required("El campo es requerido."),
    aplicaDescuento: Yup.string().required("El campo es requerido."),
    costoCambioPropietario: Yup.string().required("El campo es requerido."),
    // montoPorDescontarCliente: Yup.string().required("El campo es requerido."),
    montoADescontarCambioPropietario: Yup.string()
      .when(["costoCambioPropietario", "aplicaDescuento"], (costoCambioPropietario, aplicaDescuento) => {
        return Yup.string().test({
          name: "is-sku",
          skipAbsent: true,
          test(value, ctx) {
            if (aplicaDescuento === 'si') {
              if (value === undefined)
                return ctx.createError({ message: "El campo es requerido" });
              if (costoCambioPropietario === undefined) return true;
              if (Number(value.replace(/[$,]/g, "")) > Number(costoCambioPropietario.replace(/[$,]/g, ""))) {
                return ctx.createError({
                  message:
                    "EL valor debe ser menor a Costo cambio de propietario",
                });
              }
            }
            return true;
          },
        });
      }
      ),
  });

  useEffect(() => {
    let lectura = false;
    handleGetMotivosCancelacion();    
    if (state?.estatus == "Capturar información") {
      setIdPaso(1);
    } else if (state?.estatus == "En espera acendes") {
      setIdPaso(2);
      setSoloLectura(true);
      lectura = true;
      if (state.porCancelar === true) {
        handleGetByIdPorCancelar();
      } else {
        handleGetById();
      }
    } else if (state?.estatus == "Carga de evidencia") {
      setIdPaso(3);
      setSoloLectura(true);
      lectura = true;
    }
    handleGetAllConceptos(lectura);
  }, []);

  const handleGetById = async () => {
    setLoading(true);
    await EntregaService.GetById(id)
      .then((res) => {
        let item = res.data.data;
        let initial = {
          generalId: id,
          motivoCancelacionId: item?.motivoCancelacionEntregaId ?? "",
          comentarios: item?.comentarios ?? "",
          clientePagoCambioPropietario: item?.clientePagoCambioPropietario
            ? "si"
            : "no",
          cambioPropietario: item?.cambioPropietario ? "si" : "no",
          aplicaDescuento: item?.aplicaDescuento ? "si" : "no",
          costoCambioPropietario: item?.costoCambioPropietario ?? "",
          montoPorDescontarCliente: item?.montoPorDescontarCliente ?? "",
          montoADescontarCambioPropietario:
            item?.montoDescontarCambioPropietario ?? "",
          moral: state?.moral,
          esforaneo: state?.esforaneo,
        };
        setInitialValue(initial);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.data.mensaje);
        setLoading(false);
      });
  };
  const handleGetByIdPorCancelar = async () => {
    setLoading(true);
    await EntregaService.GetByIdPorCancelar(id)
      .then((res) => {
        let item = res.data.data;
        let initial = {
          generalId: id,
          motivoCancelacionId: item?.motivoCancelacionEntregaId ?? "",
          comentarios: item?.comentarios ?? "",
          clientePagoCambioPropietario: item?.clientePagoCambioPropietario
            ? "si"
            : "no",
          cambioPropietario: item?.cambioPropietario ? "si" : "no",
          aplicaDescuento: item?.aplicaDescuento ? "si" : "no",
          costoCambioPropietario: item?.costoCambioPropietario ?? "",
          montoPorDescontarCliente: item?.montoPorDescontarCliente ?? "",
          montoADescontarCambioPropietario:
            item?.montoDescontarCambioPropietario ?? "",
        };
        setInitialValue(initial);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.data.mensaje);
        setLoading(false);
      });
  };
  const handleTotalDescontar = (item) => {
    // if (!state?.moral && !state?.esforaneo)
    //     return (handleObtenerSumatoriaValorPorTipoDescuento() + (item.costoCambioPropietario - Number(item.montoADescontarCambioPropietario.replace(/[$,]/g, ""))))
    // else
    //     return (handleObtenerSumatoriaValorPorTipoDescuento() + Number(item.montoADescontarCambioPropietario))
    return (
      item.costoCambioPropietario -
      Number(item.montoADescontarCambioPropietario.replace(/[$,]/g, ""))
    );
  };
  const handleAddEdit = async (values) => {
    setLoading(true);
    let payload = {
      generalId: id,
      kilometraje: state?.kilometraje,
      folioCarnet: state?.folioCarnet,
      folioGarantia: state?.folioGarantia,
      folioCartaEntrega: state?.folioCartaEntrega,
      fechaEntrega: state?.fechaEntrega,
      tallerAutorizadoId: state?.tallerAutorizadoId,
      motivoCancelacionEntregaId: values.motivoCancelacionId,
      contrato: state?.contrato,
      comentarios: values.comentarios,
      sol_id: state?.sol_id,
      vin: state?.vin,
      id_datamovil: state?.id_datamovil,
      status_datamovil: state?.status_datamovil,
      intelimotorId: state?.intelimotorId,
      clientePagoCambioPropietario:
        values.clientePagoCambioPropietario == "si" ? true : false,
      costoCambioPropietario: values.costoCambioPropietario, //Number(values.costoCambioPropietario.replace(/[$,]/g, "")),
      cambioPropietario: values.cambioPropietario == "si" ? true : false,
      aplicaDescuento: values.aplicaDescuento == "si" ? true : false,
      montoPorDescontarCliente:
        Number(values.montoADescontarCambioPropietario.replace(/[$,]/g, "")) +
        handleObtenerSumatoriaValorPorTipoDescuento(), //values.montoPorDescontarCliente,
      montoADescontarCambioPropietario: values.aplicaDescuento == "si" ? values.montoADescontarCambioPropietario : 0, //Number(values.montoADescontarCambioPropietario.replace(/[$,]/g, "")),
      motivo: dataMotivosCancelacion.find(
        (item) => item.value == values.motivoCancelacionId
      )?.label,
      total_devolver:casosTotalDevolver(values),
      desembolso_inicial: state?.desembolso_inicial,
      total_descontar: casosTotalDescontar(values),
      conceptos: {
        abono_capital:
          conceptos?.find((item) => item.concepto == "abono_capital")?.monto -
          conceptos?.find((item) => item.concepto == "abono_capital")
            ?.devolverCliente || 0,
        gastos_administrativos:
          conceptos?.find((item) => item.concepto == "gastos_administrativos")
            ?.monto -
          conceptos?.find((item) => item.concepto == "gastos_administrativos")
            ?.devolverCliente || 0,
        gastos_investigacion:
          conceptos?.find((item) => item.concepto == "gastos_investigacion")
            ?.monto -
          conceptos?.find((item) => item.concepto == "gastos_investigacion")
            ?.devolverCliente || 0,
        gastos_notariales:
          conceptos?.find((item) => item.concepto == "gastos_notariales")
            ?.monto -
          conceptos?.find((item) => item.concepto == "gastos_notariales")
            ?.devolverCliente || 0,
        instalacion_datamovil:
          conceptos?.find((item) => item.concepto == "instalacion_datamovil")
            ?.monto -
          conceptos?.find((item) => item.concepto == "instalacion_datamovil")
            ?.devolverCliente || 0,
        revision_documentos:
          conceptos?.find((item) => item.concepto == "revision_documentos")
            ?.monto -
          conceptos?.find((item) => item.concepto == "revision_documentos")
            ?.devolverCliente || 0,
        seguro:
          conceptos?.find((item) => item.concepto == "seguro")?.monto -
          conceptos?.find((item) => item.concepto == "seguro")
            ?.devolverCliente || 0,
      },
      lstConceptos: conceptos,
    };    
    await EntregaService.SaveEntregaRechazada(payload)
      .then((result) => {
        if (result.data?.data?.ban == 1) {
          toast.success(result.data?.data?.mensaje);
          navigate("/inventario/entrega");
        } else {
          toast.error(result.data?.data?.mensaje);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response.data.data.mensaje);
        setLoading(false);
      });
  };
  const casosTotalDevolver = (values) =>{    
    if(values.clientePagoCambioPropietario === "si" && values.aplicaDescuento === "si"){
      return Number((conceptos?.reduce((a, b) => a + b.devolverCliente, 0) + (values.costoCambioPropietario - Number(values.montoADescontarCambioPropietario.replace(/[$,]/g, "")))).toFixed(2))
    }else if(values.clientePagoCambioPropietario === "no" && values.aplicaDescuento === "si"){
      return Number((conceptos?.reduce((a, b) => a + b.monto, 0) - (handleObtenerSumatoriaValorPorTipoDescuento() +Number(values.montoADescontarCambioPropietario.replace(/[$,]/g, "")))).toFixed(2))
    }else if(values.clientePagoCambioPropietario === "no" && values.aplicaDescuento === "no"){
      return Number(conceptos?.reduce((a, b) => a + b.monto, 0).toFixed(2))
    }else if(values.clientePagoCambioPropietario === "si" && values.aplicaDescuento === "no"){
      return Number((conceptos?.reduce((a, b) => a + b.monto, 0) + values.costoCambioPropietario).toFixed(2))
    }
   
  }
  const casosTotalDescontar = (values) =>{    
    if(values.clientePagoCambioPropietario === "no" && values.aplicaDescuento === "no"){
      return 0;
    }else if(values.clientePagoCambioPropietario === "si" && values.aplicaDescuento === "no"){
      return 0;
    }else{
      return  handleObtenerSumatoriaValorPorTipoDescuento() + Number(values.montoADescontarCambioPropietario.replace(/[$,]/g, ""));
    }
   
  }
  const handleGetMotivosCancelacion = async () => {
    await Catalogo.getAllCSMotivoCancelacionGestoria()
      .then((result) => {
        console.log(result.data);
        let NewData = [];
        NewData.push({
          value: "",
          label: "Seleccione",
        });
        result.data.data?.map((item) => {
          NewData.push({
            value: item.motivoCancelacionGestoriaId,
            label: item.motivo,
          });
        });
        setDataMotivosCancelacion(NewData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetAllConceptos = async (lectura) => {
    await Catalogo.getAllCSConceptos(id)
      .then((result) => {
        let NewData = [];
        console.log("dataconceptos",result.data.data)
        result.data.data?.map((item) => {
          NewData.push({
            concepto: item.concepto,
            monto: item.monto,
            entrega_ConceptoId: item.entrega_ConceptoId,
            tipoDescuento: item.tipoDescuento !== "" ? item.tipoDescuento : "1",
            // devolverCliente: lectura
            //   ? item.devolverCliente
            //   : item.devolverCliente !== 0
            //     ? item.devolverCliente
            //     : item.monto,
            devolverCliente: obtenerCaluloDv(item),
            valor: item.valor,
            icon: item.icon !== "1" ? "fa fa-dollar-sign" : "fa fa-percent",
          });
        });

        setConceptos(NewData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const obtenerCaluloDv = (item) =>{  
    if(item.tipoDescuento === "" || item.tipoDescuento === '1'){
      if(item.valor > 0){
        return item.monto - item.valor;
      }else{
        return item.monto
      }
    }else if(item.tipoDescuento === '2'){
      return (item.monto * item.valor) / 100
    }
  }
  const handleDevolverCliente = (concepto, tipoDescuento, valor) => {
    let result = 0;    
    if (valor <= concepto.monto) {
      if (tipoDescuento == "1") {
        result = concepto.monto - Number(valor);
      } else if (tipoDescuento == "2") {
        result = concepto.monto - (concepto.monto * Number(valor)) / 100;
      }
    }    
    return result;
  };

  const handleObtenerSumatoriaValorPorTipoDescuento = () => {
    let sumatoria = 0;
    conceptos?.map((item) => {
      if (item.tipoDescuento == "1") {
        sumatoria = sumatoria + Number(item.valor);
      } else if (item.tipoDescuento == "2") {
        sumatoria = sumatoria + (item.monto * Number(item.valor)) / 100;
      }
    });
    return sumatoria;
  };

  const handleChangeStatus = async () => {
    setLoading(true);
    await EntregaService.cambioStatusCargaEvidenciaEntrega({ generalId: id })
      .then((res) => {
        navigate(`/inventario/vehiculo/datadocs/${id}`, {
          state: { ...state, entregaRealizada: 0 },
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.data.mensaje);
        setLoading(false);
      });
  };
  return (
    <Formik
      innerRef={refFormik}
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={validate}
      onSubmit={(values) => {
        handleAddEdit(values);
        console.log(values);
      }}
    >
      {({ isValid, values, setFieldValue, errors }) => (
        <>
          {
            console.log("errors->", errors)
          }
          <Toaster
            position="top-right"
            toastOptions={{
              success: {
                style: {
                  background: "#47a066",
                  color: "#FFFF",
                  borderLeft: "10px solid #2f7246",
                },
              },
              error: {
                style: {
                  background: "#d53f3f",
                  color: "#FFFF",
                  borderLeft: "10px solid #ac241a",
                },
              },
            }}
          />
          <Modal isOpen={loading} color="transparent">
            <Spinner message={""} />
          </Modal>
          <header
            className="heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2"
            style={{ height: "50px" }}
          >
            <div style={{ width: "100%" }}>
              <div className="row" style={{}}>
                <div
                  className="col-md-3"
                  style={{ fontSize: "10pt", padding: "0px 0px" }}
                >
                  <i
                    className="bx bxs-chevron-left mx-2"
                    onClick={() => {
                      navigate("/inventario/entrega");
                    }}
                  ></i>
                  Regresar a listado general
                  {/* <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span> */}
                </div>
                <div
                  className="col-md-3"
                  style={{ fontSize: "10pt", padding: "0px 0px" }}
                >
                  <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                    {" "}
                    Vehiculo:{" "}
                  </span>
                  <span style={{ marginRight: "20px" }}>
                    {state?.vin} - {state?.nombreMarca} {state?.nombreModelo}{" "}
                    {state?.nombreVersion} {state?.nombreYear}
                  </span>
                </div>
                <div
                  className="col-md-6"
                  style={{ fontSize: "10pt", padding: "0px 0px" }}
                >
                  <SubHeaderEntrega idPaso={idPaso} />
                </div>
              </div>
            </div>
          </header>
          <div className="row m-0 py-2" style={{ height: "70vh" }}>
            <section className="col-12">
              <div className="mx-4">
                <Form>
                  <section className="d-flex justify-content-between py-2">
                    <div className="d-flex">
                      <span className="fs-5 me-2">
                        <strong>Entrega cancelada</strong>
                      </span>
                    </div>
                    {!soloLectura && (
                      <div className="d-flex">
                        <button
                          ref={refbtn}
                          className="btn mx-2"
                          type="submit"
                          onClick={() => {
                            console.log(errors);
                          }}
                        >
                          {"Guardar"}
                        </button>
                      </div>
                    )}
                    {soloLectura && (
                      <div className="d-flex">
                        <div className="d-flex">
                          <button
                            className="btn mx-2"
                            style={{ background: "#F5F5F5", color: "Black" }}
                            disabled
                          >
                            <i
                              className="glyphicon fa fa-eye"
                              style={{ color: "black" }}
                            ></i>{" "}
                            {"Modo lectura"}
                          </button>
                        </div>
                        <div className="d-flex">
                          <fieldset
                            className="btn mx-2"
                            onClick={() => {
                              handleChangeStatus();
                            }}
                          >
                            {"Continuar"}
                          </fieldset>
                        </div>
                      </div>
                    )}
                  </section>
                  <span className="mt-2">
                    A continuación debes capturar los siguientes datos de la
                    entrega:{" "}
                  </span>
                  <div className="my-2 mt-4">
                    <p>
                      <strong>1. Detalle de cancelación de entrega</strong>
                    </p>
                    <div className="mb-3 row">
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <SelectField
                          disabled={soloLectura}
                          label="Motivo de cancelación"
                          name="motivoCancelacionId"
                          items={dataMotivosCancelacion}
                          onChange={(event) => {
                            setFieldValue("motivoCancelacionId", event.value);
                          }}
                          placeholder="Seleccione"
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <TextAreaField
                          disabled={soloLectura}
                          label="Comentarios"
                          className="form-control col-12"
                          rows="3"
                          name="comentarios"
                          type="text"
                          holder="Escribe"
                          onKeyUp={(event) => {
                            setFieldValue("comentarios", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <SelectField
                          disabled
                          label="¿Cliente pago cambio propietario?"
                          name="clientePagoCambioPropietario"
                          items={cambioPropietario}
                          onChange={(event) => {
                            setFieldValue(
                              "clientePagoCambioPropietario",
                              event.value
                            );
                            // setFieldValue("aplicaDescuento", "");
                            // setFieldValue("costoCambioPropietario", "");
                          }}
                          placeholder="Seleccione"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <TextField
                          disabled
                          icon={"bi bi-currency-dollar"}
                          label="Costo cambio de propietario"
                          name="costoCambioPropietario"
                          type="text"
                          holder="Escribe"
                          onKeyUp={(event) => {
                            setFieldValue(
                              "costoCambioPropietario",
                              FormatNumberMoney(event.target.value)
                            );
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <SelectField
                          disabled
                          label="Cambio de propietario"
                          name="cambioPropietario"
                          items={cambioPropietario}
                          onChange={(event) => {
                            setFieldValue("cambioPropietario", event.value);
                            // setFieldValue("aplicaDescuento", "");
                            // setFieldValue("costoCambioPropietario", "");
                          }}
                          placeholder="Seleccione"
                        />
                      </div>
                      {/* {
                                                values.cambioPropietario == "si" &&
                                                <> */}
                      <div className="col-sm-12 col-md-6 col-lg-3">
                        <SelectField
                          disabled={soloLectura}
                          label="Aplica descuento"
                          name="aplicaDescuento"
                          items={aplicaDescuento}
                          onChange={(event) => {
                            setFieldValue("aplicaDescuento", event.value);
                            setFieldValue("montoADescontarCambioPropietario", "");
                          }}
                          placeholder="Seleccione"
                        />
                      </div>
                      {/* <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={soloLectura} icon={"bi bi-currency-dollar"} label="Monto por descontar cliente" name="montoPorDescontarCliente" type="text" holder="Escribe" onKeyUp={(event) => {
                                                    setFieldValue("montoPorDescontarCliente", FormatNumberMoney(event.target.value));
                                                }} />
                                            </div> */}
                      {/* </>
                                                
                                            } */}
                      {values.aplicaDescuento == "si" &&
                        <div className="col-sm-12 col-md-6 col-lg-3">
                          <TextField
                            disabled={soloLectura}
                            icon={"bi bi-currency-dollar"}
                            label="Monto a descontar cambio propietario"
                            name="montoADescontarCambioPropietario"
                            type="text"
                            holder="Escribe"
                            onKeyUp={(event) => {
                              setFieldValue(
                                "montoADescontarCambioPropietario",
                                FormatNumberMoney(event.target.value)
                              );
                            }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </Form>
              </div>
              {
                values.aplicaDescuento == "si" &&
                <div className="mx-4 pb-4">
                  <div className="row">
                    <div
                      className="d-flex col-sm-12 col-md-10 col-lg-9"
                      style={{}}
                    >
                      <p>
                        <strong>1. Desembolso inicial</strong>
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-10 col-lg-9">
                      <div className="row px-3">
                        <div className="col-sm-2 col-md-2 col-lg-2">Nombre</div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          Monto pagado
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          Tipo de descuento
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2">Valor</div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          Devolver al cliente
                        </div>
                        <div
                          className="col-sm-10 col-md-10 col-lg-10"
                          style={{
                            borderBottom: "3px solid #f5f5f5",
                            marginTop: "5px",
                            marginBottom: "10px",
                          }}
                        ></div>
                      </div>
                    </div>
                    {conceptos?.map((item, index) => {
                      return (
                        <div className="col-sm-12 col-md-10 col-lg-9" key={index}>
                          <div className="row px-3">
                            <div
                              className="col-sm-2 col-md-2 col-lg-2"
                              style={{ marginTop: "5px" }}
                            >
                              {item.concepto.replace("_", " ")}
                            </div>
                            <div
                              className="col-sm-2 col-md-2 col-lg-2"
                              style={{ marginTop: "5px" }}
                            >
                              ${FormatNumberMoney2(item.monto.toString())}
                            </div>
                            <div
                              className="col-sm-2 col-md-2 col-lg-2"
                              style={{ marginBottom: "5px" }}
                            >
                              <Select
                                options={tipoDescuento}
                                isDisabled={soloLectura}
                                defaultValue={tipoDescuento.find(
                                  (x) => x.value == item.tipoDescuento
                                )}
                                onChange={(e) => {
                                  let data = [...conceptos];
                                  data[index].tipoDescuento = e.value;
                                  conceptos[index].valor =0;
                                  data[index].icon =
                                    e.value == "1"
                                      ? "fa fa-dollar-sign"
                                      : "fa fa-percent";
                                  setConceptos(data);
                                  let devolverCliente = handleDevolverCliente(
                                    item,
                                    item.tipoDescuento,
                                    item.valor
                                  );
                                  data[index].devolverCliente =
                                  devolverCliente;
                                }}
                              // value={tipoDescuento ? tipoDescuento[index].tipoDescuento : ""}
                              />
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2">
                              <div className="mb-2">
                                <div className="inner-addon right-addon">
                                  <i
                                    className={`glyphicon ${item.icon}`}
                                    style={{ color: "gray" }}
                                  ></i>
                                  <TextField
                                    type="number"
                                    holder={0}
                                    onFocus={(e) => {
                                      if (e.target.value == 0) {
                                        e.target.value = "";
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (e.target.value == "") {
                                        e.target.value = 0;
                                      }
                                    }}
                                    name={`conceptos.${index}.valor`}
                                    min={0}
                                    max={item.monto}
                                    disabled={soloLectura}
                                    onChange={(e) => {
                                      let data = [...conceptos];
                                      let nuevoValor = 0;
                                      const value = e.target.value.replace(
                                        /^0+/,
                                        ""
                                      );

                                      if(item.tipoDescuento == "2"){
                                        if(value > 100){
                                          e.target.value = 0;
                                          return;
                                          e.preventDefault()
                                          
                                        }
                                      }
                                      if (value > 0 && value <= item.monto) {
                                        nuevoValor = value;
                                      } else {
                                        e.preventDefault();
                                      }
                                      data[index].valor = nuevoValor;
                                      let devolverCliente = handleDevolverCliente(
                                        item,
                                        item.tipoDescuento,
                                        nuevoValor
                                      );
                                      data[index].devolverCliente =
                                      devolverCliente;
                                      // if (devolverCliente === 0) {
                                      //   data[index].devolverCliente =
                                      //     data[index].valor;
                                      // } else {
                                      //   data[index].devolverCliente =
                                      //     devolverCliente;
                                      // }
                                      // data[index].valor = item.tipoDescuento == "1" ? FormatNumberMoney(e.target.value) : Number(e.target.value) > 100 ? "100" : e.target.value;
                                      // data[index].devolverCliente = item.tipoDescuento == "1" && (Number(e.target.value) == 100 || Number(e.target.value) > 100) ? handleDevolverCliente(item, item.tipoDescuento, "100") : handleDevolverCliente(item, item.tipoDescuento, e.target.value);
                                      setConceptos(data);
                                    }}
                                    value={
                                      conceptos ? conceptos[index].valor : ""
                                    }
                                    style={{
                                      border: "none",
                                      backgroundColor: "#f5f5f5",
                                      width: "85%",
                                      height: "37px",
                                      borderRadius: "3px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-sm-2 col-md-2 col-lg-2"
                              style={{ marginTop: "5px" }}
                            >
                              $
                              {FormatNumberMoney2(
                                (item.devolverCliente = ""
                                  ? "0"
                                  : item.devolverCliente)
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* Totales */}
                    <div className="col-sm-12 col-md-10 col-lg-9">
                      <div className="row px-3">
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          <strong>Total</strong>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          <strong>
                            $
                            {
                              //total de conceptos.monto
                              FormatNumberMoney2(
                                conceptos
                                  ?.reduce(
                                    (a, b) => parseFloat(a) + parseFloat(b.monto),
                                    0
                                  )
                                  .toString()
                              )
                            }
                          </strong>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2"></div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          <strong>
                            $
                            {FormatNumberMoney2(
                              handleObtenerSumatoriaValorPorTipoDescuento().toString()
                            ) || "0"}
                          </strong>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2">
                          <strong>
                            $
                            {FormatNumberMoney2(
                              conceptos
                                ?.reduce(
                                  (a, b) =>
                                    parseFloat(a) + parseFloat(b.devolverCliente),
                                  0
                                )
                                .toString() || "0"
                            )}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

            </section>
          </div>
        </>
      )}
    </Formik>
  );
};

export default EntregaCanceladaCaptura;
