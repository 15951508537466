import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveLog } from '../../../Redux/Slice/NavigateSliceLogs';

const PagoUnidadesSeguimiento = () => {
    const { value } = useSelector((state) => state.tabNavigateLogs);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        handleVigate(value);
    }, [value])


    const handleSetStateTab = (value) => {
        dispatch(tabActiveLog(value));
    }
    const handleVigate = (value) => {
        if (value === 1) {
            navigate(`/cobranza/pagoUnidad/pendientes`);
        }
        if (value === 2) {
            navigate(`/cobranza/pagoUnidad/proceso`);
        }
        if (value === 3) {
            navigate(`/cobranza/pagoUnidad/pagados`);
        }
    }

    return (
        <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pendientes de pago</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Proceso de pago</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos pagados</button>
                </li>
            </ul>
        </>
    )
}

export default PagoUnidadesSeguimiento