import React from 'react'
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer';
import '../Login/Login.css';
import imagen from '../../Assets/img/LogotipoArrendadoraAzul.png';
import { useEffect, useState } from 'react'
import Usuario from '../../Services/Users/Usuarios';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import AuthLogin from '../../Utils/AuthLogin';
import useLogin from '../../Hooks/Login/useLogin';
import { rolSession } from '../../Redux/Slice/rolSlice';
import { useDispatch } from 'react-redux';
import useClave from '../../Hooks/Login/useClave';

const Clave = () => {
   
    const { clave,
        loading,
        modalMessage,
        setModalMessage,
        validateForm,
        onSubmit,
        navigate,
        values, 
        errors, 
        handleChange,
        touched, 
        handleSubmit,
        validateClaveGo,
        iniciarSessionGo,
        validateHeader}= useClave()
 
  return (
    <>
        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff'>
                            {
                                modalMessage.type === 1 &&
                                <ErrorModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }
                            {
                                modalMessage.type === 2 &&
                                <SuccessModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }

        </Modal>
        <div className="contenedor">            
                <div className="col-12 col-sm-8 col-md-6 col-xl-3 container-login">
                    <figure className="logo-container">
                        <img  className="logo img-fluid" src={imagen} style={{width:'15em'}} alt="logo" />
                    </figure>
                    <div className="">
                        <h5 className="text-left fs-4">Revisa tu email</h5>   
                        <h3 className="text-left fs-6">Te enviaremos una clave para ingresar al sistema.</h3>                       
                        <br/>                    
                        <form className="mt-4"  onSubmit={handleSubmit}>
                            <div>
                                <div className="form-group">  
                                <label className="form-label">Clave</label>
                                    {/* <i className="glyphicon fas fa-user"></i> */}

                                    <input type="text" 
                                    placeholder="Escribe aquí" 
                                    name="clave" id="clave" 
                                    onChange={handleChange} 
                                    className="form-control mb-4 {errors.clave ? 'input-error':''}" />    
                                </div>
                                {errors.clave && touched.clave && <p className='error'>{errors.clave}</p>}
                            </div>  
                            <div className='d-grid gap-2 col-12 mx-auto'>
                                <button type="submit" className="btn"> Iniciar sesión </button>
                            </div>                                                                                                      
                           
                           
                        </form>                            
                    </div>
                </div>                               
            </div>
                <Footer
                cls={'fixed'}
                />  
    </>
  )
}

export default Clave