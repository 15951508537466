import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Cierres from '../../Services/Resguardos/Cierres';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import Siniestros from '../../Services/Siniestro/Siniestro';
import Gestoria from '../../Services/Gestoria/Gestoria';
import { SelectField } from '../SelectField';
import Catalogo from '../../Services/Catalogos/Catalogo';

const BuscadorGestoriaModal = ({ modalMessage, setModalMessage,handleGetAll }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        buscar: "",
        tipoTramiteId: "",
        tipoTramite: ""
    };


    const [busquedaRealizada, setBusquedaRealizada] = useState(false);
    const [data, setData] = useState({});
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTipoTramite, setDataTipoTramite] = useState([]);

    useEffect(() => {
        getTipoTramite();
    }, []);

    const validate = Yup.object({
        tipoTramiteId: Yup.string()
            .required("El campo es requerido."),
        buscar: Yup.string()
            .required("El campo es requerido.")
    });

    const handleBuscarVIN = async (values) => {
        setLoading(true);
        await Gestoria.FindGestoriaByVIN(values)
            .then(resp => {
                setData(resp.data);
                setBusquedaRealizada(true);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }


    const goToGestoria = () => {        
        navigate(`/gestoria/seguimiento/${data?.gestoriaRegistroInicialId}/${data?.paso}`,{
            state: {...data }
        })
    }

    const goToCreateGestoria = async (value) => {
        let params = {
            generalId: data.generalId,
            tipoTramiteId:value.tipoTramiteId
        }

        setLoading(true);
        await Gestoria.RegistroTramiteInicialCrear(params)
            .then(resp => {
                if(resp.data.data.ban === 1){
                    toast.success(message("¡Correcto!",resp.data.data.mensaje));   
                    setModalMessage({ isOpen:false});          
                    navigate(`/gestoria/seguimiento`);
                    handleGetAll(1)
                }else{
                    toast.error(message("Error!",resp.data.data.mensaje));                
                }   
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                toast.error(message("¡Error, al intentar crear el registro de trámite inicial!"));
                setTimeout(() => {
                    toast.remove();
                    setModalMessage({ isOpen:false});                              
                }, 1500);               
            });
    }

    const crearPagos = async (id) => {
        let params = {
            gestoriaPagoTramitesStatusId: 10,
            gestoriaRegistroInicialId: id
        }
        await Gestoria.PagoTramitesCrear(params)
            .then(resp => {
                toast.success(message("Se creó el pago de trámites correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el pago de trámites!"));
                console.error(err);
            });
    }

    const crearGestoria = async (id) => {
        let params = {
            gestoriaEntregaStatusId: 10,
            gestoriaRegistroInicialId: id
        }
        await Gestoria.GestoriaEntregaCrear(params)
            .then(resp => {
                toast.success(message("Se creó la entrega de gestoría correctamente"));
                setTimeout(() => {
                    navigate(`/gestoria/seguimiento/${data.vin}/${id}`)
                }, 1000);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la entrega de gestoría!"));
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const getTipoTramite = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.TipoTramiteId, label: item.TipoTramite });
                });
                setDataTipoTramite(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px', outline: 'none !important' }} onClick={() => {
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            setData({});
                            setBusquedaRealizada(false);
                            setInitialValue({
                                buscar: "",
                                tipoTramiteId: "",
                                tipoTramite: ""
                            });
                        }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                {
                                    !busquedaRealizada &&
                                    <h6><strong>{modalMessage.title}</strong></h6>
                                }
                                {
                                    busquedaRealizada &&
                                    data.error &&
                                    <h6><strong>{modalMessage.title}</strong></h6>
                                }
                                {
                                    busquedaRealizada &&
                                    !data.error &&
                                    data.gestoriaRegistroInicialId === null &&
                                    <h6><strong>{modalMessage.title}</strong></h6>
                                }
                                {
                                    busquedaRealizada &&
                                    !data.error &&
                                    data.gestoriaRegistroInicialId !== null &&
                                    <h6><strong>{modalMessage.title}</strong></h6>
                                }
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                {
                                    !busquedaRealizada &&
                                    <>
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">
                                                A continuación ingresa el VIN de la unidad que deseas dar seguimiento a su trámite de gestoría:
                                            </small>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-sm-8">
                                                <SelectField label="Tipo de trámite" name="tipoTramiteId" items={dataTipoTramite} onChange={(event) => {
                                                    setFieldValue("tipoTramiteId", event.value);
                                                    setFieldValue("tipoTramite", event.label);

                                                }} />
                                            </div>
                                            <div className="col-8">
                                                <TextField label="VIN" name="buscar" type="text" holder="Escribe VIN/Contrato" onChange={(event) => {
                                                    setFieldValue("buscar", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-auto mt-4">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        handleBuscarVIN(values);
                                                    }}
                                                    disabled={!(isValid)}
                                                >
                                                    Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    busquedaRealizada &&
                                    Object.keys(data).length > 0 &&
                                    <>
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">
                                                A continuación ingresa el VIN de la unidad que deseas dar seguimiento a su trámite de gestoría:
                                            </small>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>VIN ingresado: {values.buscar}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            {
                                                data.error &&
                                                <div className="col-12 mb-2">
                                                    <span>{data.messageError}</span>
                                                </div>
                                            }
                                            {
                                                !data.error &&
                                                data.gestoriaRegistroInicialId === null &&
                                                <>
                                                    <div className="col-12">
                                                        <span>¿Deseas iniciar un trámite: <strong>{values.tipoTramite}</strong> ?</span>
                                                    </div>
                                                    <div className="col-12">
                                                        <strong>Contrato: {data.contrato} | Marca: {data.nombreMarca} | Modelo: {data.nombreModelo} | Año: {data.nombreYear}</strong>
                                                    </div>
                                                </>
                                            }
                                            {
                                                !data.error &&
                                                data.gestoriaRegistroInicialId !== null &&
                                                <>
                                                    <div className="col-12">
                                                        <span>¿Deseas continuar con el trámite del siguiente vehículo?</span>
                                                    </div>
                                                    <div className="col-12">
                                                        <strong>Contrato: {data.contrato} | Marca: {data.nombreMarca} | Modelo: {data.nombreModelo} | Año: {data.nombreYear}</strong>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-gray d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        resetForm();
                                                        setData({});
                                                        setBusquedaRealizada(false);
                                                    }}
                                                >
                                                    Regresar
                                                </button>
                                            </div>
                                            {
                                                !data.error &&
                                                data.gestoriaRegistroInicialId === null &&
                                                <div className="col-auto">
                                                    <button
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            goToCreateGestoria(values);
                                                        }}
                                                    >
                                                        Registrar
                                                    </button>
                                                </div>
                                            }
                                            {
                                                !data.error &&
                                                data.gestoriaRegistroInicialId !== null &&
                                                <div className="col-auto">
                                                    <button
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            goToGestoria(values);
                                                        }}
                                                    >
                                                        Continuar
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default BuscadorGestoriaModal;
