import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import usePruebaManejo from '../../../Hooks/Inventario/usePruebaManejo'
import { message, pageRows } from '../../../Utils/functions'
import useVehiculoSeparado from '../../../Hooks/Inventario/useVehiculoSeparado'
import LiberarPruebaManeModal from '../PruebaManejo/LiberarPruebaModal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const VehiculoSeparado = () => {
    const { columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        handlechekedItem,
        setpageRows,
        dataUsoUnidad,
        setusoUnidadValue,
        setIsOpenModalLiberar,
        isOpenModalLiberar,
        habdleGetAll,
        item,
        dataPendiente,
        setclavePendiente
    } = useVehiculoSeparado(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos separados</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculoSeparado, SubModule.VehículosSeparados).Liberar}>
                        <div>
                            <button className='btn px-4' onClick={() => {
                                if (item)
                                    setIsOpenModalLiberar(true)
                                else
                                    toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione un registro para continuar.")}</div>);
                            }}>Liberar unidad</button>
                        </div>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos separados, en espera del seguro y cotización final.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculoSeparado, SubModule.VehículosSeparados).Ver}>
                <section className='mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-3 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select options={dataPendiente} className='col-12' onChange={(e) => { setclavePendiente(e.value) }} placeholder="Pendiente" />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select options={dataUsoUnidad} className='col-12' onChange={(e) => { setusoUnidadValue(e.value) }} placeholder="Uso de unidad" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        type={2}
                        checkbox={true}
                        handleCheck={handlechekedItem}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenModalLiberar &&
                <LiberarPruebaManeModal
                    isOpen={isOpenModalLiberar}
                    setIsOpen={setIsOpenModalLiberar}
                    item={{ ...item, separado: true }}
                    handleGetAll={() => { habdleGetAll(1) }}
                    toast={toast}
                />
            }
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

        </>
    )
}

export default VehiculoSeparado