import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    MotivoLlamada: 0,
}

export const MotivoLlamadaSlice = createSlice({
    name: 'MotivoLlamadaSlice',
    initialState,
    reducers: {
        setMotivoLlamada: (state, action) => {
            return {
                ...state,
                MotivoLlamada: action.payload.MotivoLlamada,
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
    }
})

export const { setMotivoLlamada, setEditable } = MotivoLlamadaSlice.actions

export default MotivoLlamadaSlice.reducer