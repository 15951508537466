import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../Utils/functions';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import Solicitudes from '../../../Services/ProcesosCierre/Solicitudes';


const MontoDevolverModal = ({ modalMessage, setModalMessage, getListado }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);

    const inicial = {
        cierreContratoDevolucionesId: modalMessage?.id,
        resultado: null,
        comentariosSolicitud: null,
        montoDevuelto: null
    };

    const [initialValue, setInitialValue] = useState(inicial);
    const Resultados = [
        {value: '0', label: 'Rechazar'},
        {value: '1', label: 'Aprobar'},
    ];

    useEffect(() => {
        // getTipoPago();
    }, [modalMessage?.id]);

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        // monto: Yup.number()
        //     .positive('El valor debe ser positivo')
        //     .typeError('Debe ser un número')
        //     .test('decimal-places', 'Máximo 2 decimales', value => {
        //         if (value) {
        //             const decimalPlaces = value.toString().split('.')[1];
        //             return !decimalPlaces || decimalPlaces.length <= 2;
        //         }
        //         return true;
        //     })
        //     .required('Campo requerido')
    });



    /* CATALOGOS */
    /* TERMINA CATALOGOS */

    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.subtotal = 0
        initialValue.iva = 0

        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const handlerGuardar = async (values) => {
        values.cierreContratoDevolucionesId = modalMessage?.id;
        values.resultado = values.resultado ? true : false;
        await Solicitudes.putDevolucion(values)
        .then(resp => {
            setModalMessage({ isOpen: false });
            getListado();
        })
        .catch(error => {
            toast.error(message('Error al intentar actualizar la solicitud de devolución, Intente nuevamente.'));
        })
        .finally(() => {
            setModalMessage({ isOpen: false });
        });
    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    nota: true,
                    monto: true
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        {/* <Modal isOpen={isOpenModalAdeudo} color='transparent'><Spinner message={""} /></Modal> */}
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => setModalMessage({ isOpen: false })}>
                                <i className="ri-close-fill"></i>
                            </button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField id="resultado"
                                                label="Resultado"
                                                disabled={false}
                                                name="resultado"
                                                items={Resultados}
                                                onChange={(event) => {
                                                    setFieldValue("resultado", event.value);
                                                }} />
                                        </div>
                                        {
                                            values.resultado === '0' &&
                                            <div className="col-lg-6 col-12">
                                                <TextField
                                                    id="montoDevuelto"
                                                    disabled={false}
                                                    label="Monto"
                                                    name="montoDevuelto"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        setFieldValue("montoDevuelto", event.target.value);
                                                    }} />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextAreaField
                                                id="comentariosSolicitud"
                                                label="Comentarios de reparación"
                                                disabled={false}
                                                className="form-control col-12"
                                                rows="3"
                                                name="comentariosSolicitud"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("comentariosSolicitud", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => setModalMessage({ isOpen: false })}>
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default MontoDevolverModal;
