import axios from '../../../Utils/ApiService';

const pruebaManejoService = {
    gatAllPruebaManejo:(page,rows,string,sucursalId,estadoPrueba)=>{
        return axios.get(`/functions/GetAllPruebaManejo?Page=${page}&Rows=${rows}&Buscar=${string}&SucursalId=${sucursalId}&EstadoPrueba=${estadoPrueba}`);
    },
    getAllPruebaManejoHostory:(page,rows,string,generalId)=>{
        return axios.get(`/functions/GetHistorialPruebaManejo?Page=${page}&Rows=${rows}&Buscar=${string}&GeneralId=${generalId}`);
    },
    getAllMotivoMantener:()=>{
        return axios.get(`/catalogs/GetCSMotivoMantener`);
    },
    DeletePruebaManejo:(ids)=>{
        return axios.delete(`/functions/DelMantenerPruebaManejo?GeneralIds=${ids}`);
    },
    AddPruebaManejoMantener:(data)=>{
        return axios.post(`/functions/SaveMantenerPruebaManejo`,data);
    },
    UploadFileAscendes:(data)=>{        
        return axios.post(`/functions/CargarDocumentoPruebaManejo`,data);
    },
    concluirPruebaManejo:(data)=>{
        
        return axios.post(`/functions/ConcluirPruebaManejo`,data);
    },
    getAllMotivoLiberar:()=>{
        return axios.get(`/catalogs/GetCSMotivoLiberacionSeparado`);
    },  
    AddLiberarPruebaManejo:(data)=>{
        return axios.post(`/functions/DelMantenerPruebaManejo`,data);
    },
    AddLiberarSeparado:(data)=>{
        return axios.post(`/functions/LiberarSeparado`,data);
    },
    saveEmisionPoliza:(data)=>{
        return axios.post(`/functions/SaveEmisionPoliza`,data);
    },
    saveSolicitudFirma:(data)=>{
        return axios.post(`/functions/SaveDispositivoDatamovil`,data);
    },
    getAllMotivoLiberarEnganche:()=>{
        return axios.get(`/catalogs/GetCSMotivoLiberacionEnganche`);
    },  
    AddLiberarSeparadoEnganche:(data)=>{
        return axios.post(`/functions/SaveLiberarEnganche`,data);
    },
    SaveInstalacionDatamovil:(data)=>{        
        return axios.post(`/functions/SaveDispositivoDatamovil`,{...data, status_datamovil: 'contrato'});
    }
}

export default pruebaManejoService