import React from 'react';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import BasicModal from '../../../../Components/Modal/BasicModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Citas from '../../../../Services/Cartera/Citas';
import SubmenuCitasAgendadas from './SubmenuCitasAgendadas';
import { SelectField } from '../../../../Components/SelectField';
import DataTable from '../../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Formik } from 'formik'
import { NavLink, useNavigate } from 'react-router-dom';
import { TextField } from '../../../../Components/TextField';

const CitasAgendaGarantias = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [ubicacionId, setUbicacionId] = useState("");
    const [sucursales, setSucursales] = useState([]);
    let initial = {
        ubicacionId: "",
        EstatusId: "",
        buscar: "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    const validate = Yup.object({
        ubicacionId: Yup.string()
            .required("El campo es requerido."),
    });

    const columns = [
        { field: 'folioGarantia', headerName: 'Folio Garantia', width: 150 },
        { field: 'folioLlamada', headerName: 'Folio Llamada Garantía', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 150 },
        { field: 'numContrato', headerName: 'Contrato', width: 150 },
        { field: 'ejecutivoGarantia', headerName: 'Ejecutivo de Garantía', width: 150 },
        { field: 'fechaCita', headerName: 'Fecha y Hora de cita', width: 150 },
        { field: 'estatusCita', headerName: 'Estatus Cita', width: 150 },
    ];

    useEffect(() => {
        handleGetSucursal();
    }, []);


    // const handleGetSucursal = async () => {
    //     await Catalogo.getAllSucursal()
    //         .then(resp => {
    //             let listSucursales = [];
    //             resp.data?.data.forEach(item => {
    //                 listSucursales.push({
    //                     value: item.sucursalId,
    //                     label: item.sucursal
    //                 });
    //             });
    //             setSucursales(listSucursales);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    //         .finally(() => setLoading(false));
    // }

    const handleGetSucursal = async () => {
        await Catalogo.getAllUbicacion(data.currentPage, 10)
            .then(resp => {
                let listSucursales = [];
                resp.data?.data.forEach(item => {
                    listSucursales.push({
                        value: item.ubicacionId,
                        label: item.nombre
                    });
                });
                setSucursales(listSucursales);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }

    const getCitas = async (values) => {
        console.log(values)

        setLoading(true);
        await Citas.getAgendaCitas('GetAgendaGarantia', values.ubicacionId, values.buscar, 1)
        .then(resp => {
            setData(resp.data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => setLoading(false));


    }

    const handleEdit = (item) => {
        console.log(item, "=>Z")
        navigate(`/garantias/incidenciasinfo/${item.vin}/${item.llamadaId}`);
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BasicModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        <SubmenuCitasAgendadas />
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <strong className='mt-2'>Agenda de citas para garantía</strong>
                                <span className='mt-2'>A continuación, se muestran la gestión de todas las citas de garantías de acuerdo a su estatus.</span>
                                <div className='col-lg-8 col-sm-12'>
                                    <div className="row">
                                        <div className="col-4 mt-2 mr-1 inner-addon right-addon">
                                            {/* <i className="glyphicon fas fa-search"></i> */}
                                            {/* <input type="text" className="form-control mb-4" id="buscar" name="buscar" placeholder="Buscar" onChange={(event) => {
                                                setFieldValue("buscar", event.value);
                                                // handleBuscar(event.value, values);
                                            }}/> */}
                                            <TextField id="buscar" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-4 mt-2 mr-1">
                                            <SelectField id="ubicacionId" name="ubicacionId" items={sucursales} onChange={(event) => {
                                                setFieldValue("ubicacionId", event.value);
                                                // handleChangeUbicacion(event.value, values);
                                            }} />
                                        </div>
                                        <div className="col-4 mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => getCitas(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-12">
                                        <DataTable
                                            column={columns}
                                            data={data?.data}
                                            editable={true}
                                            // pageCount={1}
                                            // handlePageClick={handlePageClick}
                                            // currentPage={data?.currentPage - 1}
                                            // checkbox={true}
                                            handleEdit={handleEdit}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default CitasAgendaGarantias;
