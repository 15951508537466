import axios from '../../Utils/ApiService';

const ResguardoUnidadService = {

    getAllResguardoUnidad: async (data) => {
        return await axios.post(`/cartera/GetResguardoUnidadAnything`, data);
    },
    upsertResguardoUnidadAnything: async (data) => {
        return await axios.post(`/cartera/UpsertResguardoUnidadAnything`, data);
    },
    deleteResguardoUnidadAnything: async (data) => {
        return await axios.post(`/cartera/DeleteResguardoUnidadAnything`, data);
    },
    getEstados: async () => {
        return axios.post(`/cartera/GetEstados`);
    },
    getMunicipios: async (data) => {
        return axios.post(`/cartera/GetMunicipios`, data);
    },
    getColonias: async (data) => {
        return axios.post(`/cartera/GetColonias`, data);
    },
}

export default ResguardoUnidadService