import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import pruebaManejoService from '../../../Services/Venta/Vehiculo/pruebaManejoService';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { AddDays_CurrentDate, message } from '../../../Utils/functions';

const MantenerPruebaManejo = ({ isOpen, setIsOpen, item, handleGetAll, toast }) => {
    const refbtn = useRef();
    let initial = {
        mantenerPrueba: true,
        pruebadeManejoId: item?.pruebadeManejoId ?? "",
        generalId: item?.generalId ?? "",
        diasMantenerPruebaManejo: AddDays_CurrentDate(1),// new Date().toISOString().split('T')[0],
        motivoMantenerId: "",
        motivo:"",
        comentarios: "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataMotivo, setdataMotivo] = useState([]);
    const [dataEndPM, setDataEndPM] = useState("");
    const validate = Yup.object({
        diasMantenerPruebaManejo: Yup.string()
            .required("El campo es requerido."),
        motivoMantenerId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+(?: [a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        diasMantenerPruebaManejo: Yup.date().test({
            name: 'is-Sunday',
            skipAbsent: true,
            test(value, ctx) {
                if (value.getDay() === 0) {
                    return ctx.createError({ message: 'Seleccione una fecha diferente al dia domingo.' })
                }
                return true
            }
        })
    });

    useEffect(() => {
        handleGetAllMotivoMantener();
        handleGetParameters();
    }, [])

    const handleGetParameters = async () => {
        await IntelimotorAPI.getValorParametro("DiasMantenerPruebaManejo")
            .then(res => {
                let date = AddDays_CurrentDate(res.data.data.valor);
                setDataEndPM(date);
            }).catch(e => {
            })
    }
    const handleGetAllMotivoMantener = async () => {
        await pruebaManejoService.getAllMotivoMantener()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoMantenerId,
                        label: item.motivo
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        await pruebaManejoService.AddPruebaManejoMantener(item)
            .then(res => {
                setIsOpen(false);
                handleGetAll(1);
                if(res.data.data.ban === 1)
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                else
                    toast.error(message("Error.!", res.data.data.mensaje));

            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));

            })

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
                
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='d-flex flex-column  mx-4'>
                        <span className='fs-5 mb-2'><strong>Mantener prueba de manejo</strong></span>
                        <span style={{ fontSize: '13px' }}>A continuación debes capturar los siguientes datos para mantener la prueba.</span>
                    </div>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <TextField id="diasMantenerId" label="Vigencia" min={AddDays_CurrentDate(1)} max={dataEndPM} name="diasMantenerPruebaManejo" type="date" holder="Escribe" />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <SelectField label="Motivo" name="motivoMantenerId" items={dataMotivo} onChange={(event) => {
                                        setFieldValue("motivoMantenerId", event.value);
                                        setFieldValue("motivo", event.label);
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                    }} />
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default MantenerPruebaManejo