import React, { useEffect, useState } from 'react';
import img from '../Assets/img/ConfEtapas_Orden.png'

const Acordeon = ({ data, isDeleted = false, handleModal, handleModalconfirm,handleModalDelete }) => {
    const [open, setOpen] = useState(true);    
    useEffect(() => {   
    }, [data])
        
    const handleDeleteGroup = (data) =>{
        handleModalconfirm(data.grupoId);
    }
    return (
        <>
            <article className='group-containers p-3 my-3'>
                <div className='d-flex justify-content-between header-acordeon'>
                    <div>
                        <button className='btn-etapa' onClick={() => { setOpen(current => !current) }}><i className={`ri-arrow-drop-${open ? 'up' : 'down'}-line`}></i></button>
                        <i style={{ color: '#004c97' }} className="mx-2 bi bi-menu-button"></i>
                        <span><strong>{data?.grupo}</strong></span>
                    </div>
                    <fieldset disabled={data.fijo} className='btn btn-light secondary d-flex align-items-center' onClick={() => { handleDeleteGroup(data) }} >{data?.etapa.length == 0 ? <i onClick={()=>{handleDeleteGroup(data)}} className="bi bi-trash-fill"></i> : data?.etapa.length > 1 ? `${data?.etapa.length} etapas asignadas` : `${data?.etapa.length} etapa asignada`} </fieldset>
                    {/* <span className='count_etapas'>{data?.etapa.length == 0 ? <i onClick={()=>{handleDeleteGroup(data)}} className="bi bi-trash-fill"></i> : data?.etapa.length > 1 ? `${data?.etapa.length} etapas asignadas` : `${data?.etapa.length} etapa asignada`} </span> */}
                </div>
                <div >               
                    {
                        open && data?.etapa.length > 0 ?
                            data.etapa.map((item, index) => {
                                return <div key={index} className='mt-3 d-flex justify-content-between items-etapa content'>
                                    <span className='mx-4'>{item.etapa}</span>
                                    {isDeleted && !data.fijo && <span onClick={(e) => { handleModalDelete(data,item) }}><i className="ri-close-line"></i></span>}
                                </div>
                            }) :  open && <div className=' d-flex align-items-center row'>
                                    <div className='col-3'>
                                        <img src={img} style={{ width: '6rem' }} />
                                    </div>
                                    <div className='col-8 d-flex flex-column'>
                                        <span style={{ fontSize: '14px' }}><strong>No hay etapas asignadas</strong></span>
                                        <span style={{ fontSize: '12px' }}>Para asignar una etapa a este grupo dirigete a la columna "Etapas sin asignar"</span>
                                    </div>
                            </div>
                    }
                </div>
            </article>

            <button className='btn btn-outline col-12' onClick={()=>{handleModal(data)}}>+ Crear nuevo grupo</button>
        </>
    )
}

export default Acordeon