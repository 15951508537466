import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cambioDevolucionId: "",
    origen: "",
    origenId: "",
    cambioDevolucionFolio: "",
    fechaAlta: "",
    usuarioAlta: "",
    usuarioActualiza: "",
    fechaActualiza: "",
    cambioDevolucionStatusId: "",
    cambioDevolucionStatus: "",
    status: "",
    usuarioAltaNombre: "",
    usuarioActualizaNombre: "",
    fechaHoraOperacion: "",
    usuarioSolicita: "",
    usuarioAprueba: "",
    usuarioSolicitaNombre: "",
    usuarioApruebaNombre: "",
    tipoDevolucion: "",
    motivoCambioDevolucionId: "",
    motivoCambioDevolucion: "",
    comentarios: "",
    fechaHoraAprobacion: "",
    aprobado: "",
    notificado: null,
    descuentos: "",
    boletinado: "",
    concluido: null,
    razonAplica: "",
    fechaHoraNotificacion: "",
    CambioDevolucionMotivoRechazoId: "",
    CambioDevolucionMotivoRechazo: "",
    fechaEstimadaConclusion: "",
    comentariosAprobacion: "",
    comentariosRechazoSupervisor: "",
    comentariosRechazoEjecutivo: "",
    reagendarEntrega: ""
}

export const DevolucionesSlice = createSlice({
    name: 'DevolucionesSlice',
    initialState,
    reducers: {
        setDevolucionSlice: (state, action) => {
            return {
                ...state,
                cambioDevolucionId: action.payload.cambioDevolucionId,
                origen: action.payload.origen,
                origenId: action.payload.origenId,
                cambioDevolucionFolio: action.payload.cambioDevolucionFolio,
                fechaAlta: action.payload.fechaAlta?.split("T")[0]+" "+action.payload.fechaAlta?.split("T")[1].split(".")[0],
                usuarioAlta: action.payload.usuarioAlta,
                usuarioActualiza: action.payload.usuarioActualiza,
                fechaActualiza: action.payload.fechaActualiza,
                cambioDevolucionStatusId: action.payload.cambioDevolucionStatusId,
                cambioDevolucionStatus: action.payload.cambioDevolucionStatus,
                status: action.payload.status,
                usuarioAltaNombre: action.payload.usuarioAltaNombre,
                usuarioActualizaNombre: action.payload.usuarioActualizaNombre,
                fechaHoraOperacion: action.payload.fechaHoraOperacion,
                usuarioSolicita: action.payload.usuarioSolicita,
                usuarioAprueba: action.payload.usuarioAprueba,
                usuarioSolicitaNombre: action.payload.usuarioSolicitaNombre,
                usuarioApruebaNombre: action.payload.usuarioApruebaNombre,
                tipoDevolucion: action.payload.tipoDevolucion === null ? null: Number(action.payload.tipoDevolucion),
                motivoCambioDevolucionId: action.payload.motivoCambioDevolucionId,
                motivoCambioDevolucion: action.payload.motivoCambioDevolucion,
                comentarios: action.payload.comentarios,
                fechaHoraAprobacion: action.payload.fechaHoraAprobacion,
                aprobado: action.payload.aprobado,
                notificado: action.payload.notificado,
                descuentos: action.payload.descuentos,
                boletinado: action.payload.boletinado,
                concluido: action.payload.concluido,
                razonAplica: action.payload.razonAplica,
                fechaHoraNotificacion: action.payload.fechaHoraNotificacion,
                CambioDevolucionMotivoRechazoId: action.payload.CambioDevolucionMotivoRechazoId,
                CambioDevolucionMotivoRechazo: action.payload.CambioDevolucionMotivoRechazo,
                fechaEstimadaConclusion: action.payload.fechaEstimadaConclusion?.split("T")[0],
                comentariosAprobacion: action.payload.comentariosAprobacion,
                comentariosRechazoSupervisor: action.payload.comentariosRechazoSupervisor,
                comentariosRechazoEjecutivo: action.payload.comentariosRechazoEjecutivo,
                reagendarEntrega: action.payload.reagendarEntrega
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
        clearDevolucion: (state) => {
            return {
                ...state,
                cambioDevolucionId: "",
                origen: "",
                origenId: "",
                cambioDevolucionFolio: "",
                fechaAlta: "",
                usuarioAlta: "",
                usuarioActualiza: "",
                fechaActualiza: "",
                cambioDevolucionStatusId: "",
                cambioDevolucionStatus: "",
                status: "",
                usuarioAltaNombre: "",
                usuarioActualizaNombre: "",
                fechaHoraOperacion: "",
                usuarioSolicita: "",
                usuarioAprueba: "",
                usuarioSolicitaNombre: "",
                usuarioApruebaNombre: "",
                tipoDevolucion: "",
                motivoCambioDevolucionId: "",
                motivoCambioDevolucion: "",
                comentarios: "",
                fechaHoraAprobacion: "",
                aprobado: null,
                notificado: null,
                descuentos: "",
                boletinado: "",
                concluido: null,
                razonAplica: "",
                fechaHoraNotificacion: "",
                CambioDevolucionMotivoRechazoId: "",
                CambioDevolucionMotivoRechazo: "",
                fechaEstimadaConclusion: "",
                comentariosAprobacion: "",
                comentariosRechazoSupervisor: "",
                comentariosRechazoEjecutivo: "",
                reagendarEntrega: ""
            }
        }
    }
})

export const { setDevolucionSlice, setEditable, clearDevolucion } = DevolucionesSlice.actions

export default DevolucionesSlice.reducer