import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import AutorizarDictamenService from '../../../Services/Seguros/AutorizarDictamenService';

const useAutorizarDictamen = (toast) => {    
    const columns = [
        { field: 'siniestroTipo', headerName: 'Tipo de siniestro', width: 150 },
        { field: 'folio', headerName: 'Folio de siniestro', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },        
        { field: 'nombreMarca', headerName: 'Marca', width: 100 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'tipoDictamen', headerName: 'Dictamen', width: 150 },
        { field: 'fechaDictamen', headerName: 'Fecha del dictamen', width: 100 },        
        { field: 'comentariosDictamen', headerName: 'Comentarios', width: 100 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'fechaHoraRegistro', headerName: 'Fecha y hora de registro', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false); 
    const [pageRows, setpageRows] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState();
    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();        
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await AutorizarDictamenService.getAllAutorizarDictamen(page, pageRows, str)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }   
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                // setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                // settipoCompra(NewData);
            }).catch(e => {
            })
    }   

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        setOpenModal,
        openModal,
        habdleGetAll,
        setItemSelected,
        itemSelected
    }
}

export default useAutorizarDictamen