import { Formik, Form, Field } from 'formik'
import React, { useRef, useState } from 'react';
import Modal from '../../Components/Modal/Modal';
import * as Yup from 'yup';
import { TextField } from '../../Components/TextField';
import { TextAreaField } from '../../Components/TextAreaField';
import RolesService from '../../Services/Roles/RolesService';
import { message } from '../../Utils/functions';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/Loadig';

const NuevoRolModal = ({ isOpen, setIsOpen, toast, item, action, handleGetAll }) => {
    const refbtn = useRef();
    const navigate = useNavigate();
    let initial = {
        idRole: item?.idRole ?? "",
        role: item?.role ?? "",
        descripcion: item?.descripcion ?? "",
        status: item?.status ?? false
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const validate = Yup.object({
        role: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        descripcion: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres')
    });

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        setLoading(true);
        if (action == 'add') {
            const {role,descripcion,status} = item;
            await RolesService.AddNewRol({role,descripcion,status})
                .then(res => {
                    if (res.data.data.Ban === 1) {
                        toast.success(message("¡Correcto!", res.data.data.Mensaje));
                        setTimeout(() => {
                            setIsOpen(false);
                            setLoading(false);
                            navigate(`/admin/rol/${res.data.data.IdRole}`, {
                                state: {
                                    page: 'Rol',
                                    title: "Editar usuario"
                                },
                            });
                        }, 3000);

                    } else {
                        toast.error(message("¡Error!", res.data.data.Mensaje));
                        setIsOpen(false);
                        setLoading(false);
                    }
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error!", e.response.data.message));
                    else
                        toast.error(message("¡Error!", e.message));
                })
        } else if (action == 'edit') {
            await RolesService.UpdRol(item)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("¡Correcto!", res.data.data.mensaje));
                    } else {
                        toast.error(message("¡Error!", res.data.data.mensaje));
                    }
                    setIsOpen(false);
                    setLoading(false);
                    handleGetAll(1);
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error!", e.response.data.message));
                    else
                        toast.error(message("¡Error!", e.message));
                })
        }

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>{action === "add" ? "Agregar rol" : "Editar rol"}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                {
                                    action === "edit" &&
                                    <div className="mb-3">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                        </div>
                                    </div>
                                }

                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <TextField id="nombreRol" label="Nombre del rol" name="role" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-12 col-sm-12">
                                        <TextAreaField id="descripcionrol" label="Descripción" className="form-control col-12" rows="2" name="descripcion" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{"Guardar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default NuevoRolModal