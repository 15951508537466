import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ClavesModal from '../../../Components/Modal/ClavesModal';
import Modal from '../../../Components/Modal/Modal';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Spinner from '../../../Components/Loadig';


const VehiculoGestoriaCard = ({ claves, vin }) => {
    const { anombre, marca, modelo, version, anio, placas, color, kilometraje, procedencia, numMotor, fechaEntrega, numContrato, cliente, telefono, correo, producto, usoUnidad, fechaUltimoPago, fechaLiquidacion } = useSelector((state) => state.GestoriaEncabezadoSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={780}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <ClavesModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        vin={vin}
                    />
                }
            </Modal>
            <div className="p-4 white-wrapper" style={{ minHeight: '320px' }}>
                <div className="row px-2">
                    <div className="col-12 underline-border">
                        <div className="row">
                            <div className="col-auto" style={{ "marginTop": "5px" }}>
                                <h6><strong>Vehículo</strong></h6>
                            </div>
                            {/* <div className="col-auto" style={{ "marginBottom": "5px" }}>
                                <button className='btn btn-sm' onClick={(event) => {
                                    setModalMessage({isOpen: true, title: 'Consulta de Claves', type: 3})

                                }}>
                                    Ver Claves
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>VIN:</strong> {vin}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Placas:</strong> {placas}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Vehículo a nombre de:</strong> <span className='badge bg-primary-datamovil'>{anombre}</span></small>
                    </div>
                    <div className="col-6">
                        <small><strong>Color:</strong> {color}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Marca:</strong> {marca}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Kilometraje:</strong> {kilometraje}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Modelo:</strong> {modelo}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Procedencia Auto:</strong> {procedencia}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Versión:</strong> {version}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>No. Motor:</strong> {numMotor}</small>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className="col-6">
                        <small><strong>Año:</strong> {anio}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Fecha de entrega:</strong> {fechaEntrega}</small>
                    </div>
                </div>
            </div>
        </>)
}

export default VehiculoGestoriaCard