import { set } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import Logs from '../../Services/Logs/Logs';
import { message } from '../../Utils/functions';

const useUsuarioDetail = (toast) => {
  const refBtn = useRef();
  let ids = [];
  const columns = [
    { field: 'logUsuarioId', headerName: 'Id', width: 100 },
    { field: 'vin', headerName: 'VIN', width: 200 },
    { field: 'nombreUsuario', headerName: 'Usuario', width: 200 },
    { field: 'modulo', headerName: 'Modulo', width: 150 },
    { field: 'dateOnly', headerName: 'Fecha', width: 160 },
    { field: 'hora', headerName: 'Hora', width: 150 },
    { field: 'categoria', headerName: 'Categoria', width: 200 },
    { field: 'resultado', headerName: 'Resultado', width: 200 },
    { field: 'contenido', headerName: 'contenido', width: 350 },
  ];
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [moduloId, setModuloId] = useState("");
  const [procesoId, setProcesoId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [pageRows, setpageRows] = useState(10)
  useEffect(() => {
    handleGetModulo();
    handleGetCategoria()
  }, [])

  useEffect(() => {
    handleGetAllUsuario(1);
  }, [Str, dateTime, moduloId, categoriaId,pageRows])

  //selectList
  const handleGetModulo = async () => {
    setLoading(true);
    await Logs.getList("modulo")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos los Modulos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataModulo(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetProceso = async () => {
    setLoading(true);
    await Logs.getList("proceso")
      .then(res => {

        setDataProceso(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetCategoria = async () => {
    setLoading(true);
    await Logs.getList("categoria")
      .then(res => {

        let NewData = [];
        NewData.push({
          value: "",
          label: "Todas las categorias"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataCategoria(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllUsuario = async (page) => {
    setLoading(true);
    await Logs.getLogsUsuarioEliminado(page, pageRows, moduloId, categoriaId, Str, dateTime)
      .then(res => {

        setData(res.data.data);
        setPageCount(res.data.totalPages)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    handleGetAllUsuario(event.selected + 1);
  };
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');


    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    setLoading(true);
    if (idsEliminar.length > 0) {
      let newIds = idsEliminar.join();
      await Logs.exportFileUsuario(newIds)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `LogUsuario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
         
          setLoading(false);
          handleGetAllUsuario(1);
          handleUnchekedColumns();
          refBtn.current.disabled = false;
          setIdsEliminar([]);
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false)
      refBtn.current.disabled = false;
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros a exportar")}</div>);
    }

  }
  const handleCheck = (cheked, value) => {
    if (cheked) {      
      setIdsEliminar(current => [...current, value.logUsuarioId]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item !== value.logUsuarioId)); 
    }
  }
  return {
    columns,
    data,
    pageCount,
    handlePageClick,
    dataModulo,
    dataCategoria,
    setStr,
    setDateTime,
    setCategoriaId,
    setModuloId,
    refBtn,
    handleDownloadFile,
    handleCheck,
    loading,
    setpageRows
  }
}

export default useUsuarioDetail