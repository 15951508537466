import React from 'react';
import useGarantia from '../../Hooks/Garantias/useGarantia';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import BasicModal from '../../Components/Modal/BasicModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import img from '../../Assets/img/Garantías.png';
import imgSinIncidencias from '../../Assets/img/SinIncidencias.png';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Llamada from '../../Services/Cartera/Llamadas';
import Garantias from '../../Services/Garantias/Garantias';
import { useEffect, useState } from 'react';
import DataTable from '../../Components/datatable/DataTable'
import { clearHour } from '../../Redux/Slice/gruasSlice';
import { clearHour as clearHourGarantia } from '../../Redux/Slice/garantiasSlice';
import { clearGrua } from '../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { clearGarantia } from '../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { clearLlamada } from '../../Redux/Slice/IncidenciasSlice/llamadaSlice';
import { setUrlSlice } from '../../Redux/Slice/IncidenciasSlice/urlSlice';
import { setInfoVin } from '../../Redux/Slice/IncidenciasSlice/dataVinSlice';

const IncidenciasHome = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        setStr,
        str,
        loading,
        setModalMessage,
        modalMessage,
        setDataVin,
        dataVin,
        busquedaRealizada,
        infoTabs,
        llamadasInfo,
        setLlamadasInfo,
        garantias,
        setGarantias,
        mantenimientos,
        setMantenimientos
    } = useGarantia();

    const { id } = useParams();

    const handleChange = (e) => {
        if (e.key === 'Enter') {
            setStr(e.target.value);
            if (e.target.value.trim().length > 0) {
                setBusqueda(true);
            } else {
                setBusqueda(false);
            }
        }

    }
    const [totalPage, setTotalPage] = useState(0);
    const [type, setType] = useState(1);
    const [busqueda, setBusqueda] = useState(false);
    const [btnActivo, setBtnActivo] = useState(true);
    const [tabIndex, setTabIndex] = useState(1);

    const handleClickRedirectCall = (e) => {

        dispatch(clearHour())
        dispatch(clearHourGarantia())
        dispatch(clearGrua())
        dispatch(clearGarantia())
        dispatch(clearLlamada())

        navigate("/garantias/incidenciasinfo/" + dataVin.vin + "/0");
        //setCalls()
        //console.log(handleGetLlamadas())
    };

    const columnGrant = [
        { field: 'folioGarantia', headerName: 'Folio de garantía', width: 150 },
        { field: 'fechaReporte', headerName: 'Fecha de Reporte', width: 150 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'tipoGarantia', headerName: 'Tipo garantía', width: 150 },
        { field: 'tipoReporte', headerName: 'Tipo reporte', width: 150 },
        { field: 'reparacionUnidad', headerName: 'Recepción de unidad', width: 250 },
        { field: 'estatusGarantia', headerName: 'Estatus garantía', width: 150 },
        { field: 'ultimaActualizacion', headerName: 'Última actualización', width: 200 }
    ];
    const columCalls = [
        { field: 'folioLlamada', headerName: 'Folio de llamada', width: 200 },
        { field: 'fechaReporte', headerName: 'Fecha de reporte', width: 150 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'motivo', headerName: 'Motivo llamada', width: 150 },
        { field: 'comentariosCliente', headerName: 'Comentarios cliente', width: 300 },
    ]
    const columMatment = [
        { field: 'fechaMantenimiento', headerName: 'Fecha', width: 150 },
        { field: 'usuario', headerName: 'Usuario', width: 150 },
        { field: 'taller', headerName: 'Taller', width: 150 },
        { field: 'kilometraje', headerName: 'kilometraje', width: 150 },
        { field: 'fechaHoraRegistro', headerName: 'Fecha y hora de registro', width: 250 },
        { field: 'supervisor', headerName: 'Supervisor', width: 150 },
        { field: 'autorizado', headerName: 'Autorizo', width: 150 },
        { field: 'fechaHoraAutorizacion', headerName: 'Fecha y hora de autorización', width: 250 },
    ]

    const [dataAdeudo, setAdeudo] = useState(
        {
            "sol_id": "",
            "contrato": "",
            "vin": "",
            "saldo_credito": 0,
            "periodo": 0,
            "termino_contrato": "",
            "ultimo_pago": "",
            "status_contrato": "",
            "cliente": "",
            "fecha_ultima_clave": "",
            "vigencia_clave": "",
            "status": "",
            "message": ""
        }
    );


    const [columns, setColumns] = useState(columnGrant);
    const [dataInfo, setDataInfo] = useState(garantias);

    const setdata = (data) => {
        setColumns(data)
    }

    useEffect(() => {
        if (garantias.length > 0) {
            setDataInfo(garantias);
            verificarGarantias();
        }
    }, [garantias]);

    useEffect(() => {
        if (dataVin.vin !== "") {
            getAdeudoCredito();
        }

    }, [dataVin.numSolicitud, dataVin.vin, dataVin.numContrato]);

    const handleEdit = (data) => {
        let url = location.pathname;
        if (id === undefined) {
            url += `/${str}`
        }

        const dataUrl = { url };

        if (data?.llamadaId) {
            dispatch(setUrlSlice(dataUrl));
            //Setear el calendario
            dispatch(clearHour())
            dispatch(clearHourGarantia())
            dispatch(clearGrua())
            dispatch(clearGarantia())
            dispatch(clearLlamada())
            navigate(`/garantias/incidenciasinfo/${str}/${data.llamadaId}`);
        }
        // console.log(data);
    };


    const getAdeudoCredito = async () => {
        await Llamada.getAdeudoCredito({
            numeroSolicitud: dataVin.numSolicitud,
            vin: dataVin.vin,
            contrato: dataVin.numContrato
        }).then(res => {
            let resp = res.data.data
            if (resp.status == 'proceso_correcto') {
                setAdeudo({
                    "sol_id": resp.sol_id,
                    "contrato": resp.contrato,
                    "vin": resp.vin,
                    "saldo_credito": resp.saldo_credito,
                    "periodo": resp.periodo,
                    "termino_contrato": resp.termino_contrato,
                    "ultimo_pago": resp.ultimo_pago,
                    "status_contrato": resp.status_contrato,
                    "cliente": resp.cliente,
                    "fecha_ultima_clave": resp.fecha_ultima_clave,
                    "vigencia_clave": resp.vigencia_clave,
                    "status": resp.status,
                    "message": resp.message,
                })

            }
        })
    }


    const myStyle = {
        borderColor: '#004C97',
    };

    const verificarGarantias = () => {
        const tieneStatusDiferente = garantias.filter(item => item.statusGarantiaId !== 4 && item.statusGarantiaId !== 50);
        setBtnActivo(tieneStatusDiferente.length > 0)

    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <BasicModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>

            <section>
                <div className='headerTab pb-4'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <strong className='mt-2'>Incidencias del contrato </strong>
                        <span className='mt-2'>Para visualizar las Incidencias registradas, indica a continuación el VIN o No de contrato del vehículo correspondiente</span>
                        <div className='col-lg-8 col-sm-12 d-flex'>
                            <div className="col-6 mt-2 mr-1 inner-addon right-addon">
                                <i className="glyphicon fas fa-search"></i>
                                <input type="text" className="form-control mb-4" placeholder="Buscar por vin o contrato" onKeyUp={handleChange} />
                            </div>

                            <div className="col-3 mx-2 mt-2">
                                {
                                    dataVin.generalId !== "" && dataVin.numContrato !== "" && btnActivo === true && dataVin.Expediente === true &&
                                    <button className="btn col-12" type="button" onClick={(e) => handleClickRedirectCall(e)} >Registrar llamada</button>
                                }
                            </div>

                        </div>
                    </section>
                </div>
                {
                    (llamadasInfo.length === 0 && mantenimientos.length === 0 && garantias.length === 0 && !busqueda) &&
                    (<div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className='row'>
                            <div className='text-center'>
                                <img src={img} style={{ width: '25rem' }} className='img-fluid' />
                            </div>
                        </div>
                    </div>)
                }
                {
                    (llamadasInfo.length === 0 && mantenimientos.length === 0 && garantias.length === 0 && busqueda) &&
                    (<div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className='row'>
                            <div className='text-center'>
                                <img src={imgSinIncidencias} style={{ width: '15rem' }} className='img-fluid' />
                                <p><small>No hay incidencias registradas para el vehículo con <br />VIN: {str} No. Contrato: {str}</small></p>
                            </div>
                        </div>
                    </div>)
                }

                {
                    (llamadasInfo.length > 0 || mantenimientos.length > 0 || garantias.length > 0) &&
                    (
                        <div className='d-flex flex-column' style={{ height: '70vh' }}>
                            <div className='row'>
                                <div>
                                    <div className='mx-4 mt-4'>
                                        <div className="row">
                                            <h5 className='font-weight-bold'>Reporte de Garantías</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12">
                                                <small>A continuación se muestra el reporte de garantías registradas para el usuario con <strong>Cliente: {dataAdeudo.cliente}, VIN: {dataVin.vin}, Marca: {dataVin.nombreMarca} y No. Contrato: {dataVin.numContrato}</strong></small>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className='col-12'>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${tabIndex == 1 ? 'tab-active' : ''}`} value={1} type="button" role="tab" onClick={(e) => { setdata(columnGrant); setDataInfo(garantias); setTabIndex(1); }}>Garantías</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${tabIndex == 2 ? 'tab-active' : ''}`} value={2} type="button" role="tab" onClick={(e) => { setdata(columCalls); setDataInfo(llamadasInfo); setTabIndex(2); }}>Llamadas</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${tabIndex == 3 ? 'tab-active' : ''}`} value={3} type="button" role="tab" onClick={(e) => { setdata(columMatment); setDataInfo(mantenimientos); setTabIndex(3); }}>Mantenimientos</button>
                                                    </li>
                                                </ul>
                                                {
                                                    tabIndex !== 3 &&
                                                    <DataTable
                                                        column={columns}
                                                        data={dataInfo}
                                                        editable={true}
                                                        handleEdit={handleEdit}
                                                        pageCount={0}
                                                        currentPage={1 - 1}
                                                    // checkbox={true}
                                                    // handleCheck={handlechekedItem}
                                                    />
                                                }
                                                {
                                                    tabIndex === 3 &&
                                                    <DataTable
                                                        column={columns}
                                                        data={dataInfo}
                                                        editable={false}
                                                        handleEdit={handleEdit}
                                                        pageCount={0}
                                                        currentPage={1 - 1}
                                                    // checkbox={true}
                                                    // handleCheck={handlechekedItem}
                                                    />
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }

            </section>
        </>
    );
}



export default IncidenciasHome;
