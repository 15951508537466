import React from 'react';
import '../Components/LoadingBar.css';

const SpinnerBar = ({message}) => {   
    return(
        <>
        <div className="loaderBar-background">
            <div className="loaderBar"></div>
            <span className='messageBar'>{message}</span>
        </div>         
        </>
    )
}

export default SpinnerBar