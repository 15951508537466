import React from 'react'
import img from '../../Assets/img/Gestoria.png'

const GestoriaHome = () => {
  return (
    <>
    <section >
      <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'90vh'}}>
       <div className='row'>
        <div className='text-center'>
       <img src={img} style={{width:'30rem'}} className='img-fluid' />

        </div>
        <div className='fs-4 text-center mb-3'>Gestoría</div>
        <div className='fs-6 text-center'>Aquí podrás darle seguimiento a los trámites de gestoría. Para comenzar, selecciona una opción del menú.</div>
       </div>
      </div>
    </section>
    </>
  )
}

export default GestoriaHome