import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { useDispatch } from 'react-redux';
import { setContrato, setItemGeneral } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import { formatearMonto, FormatFecha } from '../../Utils/functions';

const useSeguimientoVisita = () => {
  const navigate = useNavigate();
  const [textoBusqueda, setTextoBusqueda] = useState("")
  const [pageRows, setpageRows] = useState(10);
  const [data, setData] = useState([{}]);
  const dispatch = useDispatch();

  const userName = sessionStorage.getItem('usuario')
  const userId = sessionStorage.getItem('userId').trim()

  const [percentage, setPercentage] = useState(0)

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'tipo_persona', headerName: 'Tipo de persona' },
    { field: 'nombre', headerName: 'Nombre' },
    { field: 'monto_Adeudo', headerName: 'Monto Vencido' },
    { field: 'fecha', headerName: 'Fecha', width: 100 },
    { field: 'colonia', headerName: 'Dirección' },
    { field: 'tipo_visita', headerName: 'Tipo de visita' },
    { field: 'contrato_status', headerName: 'Estatus' },
  ];

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(data);
  let allContratos
  const [active7, setActive7] = useState(false)
  const [active15, setActive15] = useState(false)
  const [firstProgressVigente, setFirstProgressVigente] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [secondProgressVigentes, setSecondProgressVigentes] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })
  const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
    porcentajeActivos: 0,
    sumaTotalDinero: 0,
    idsDiferentes: 0
  })

  const [totalContratos, setTotalContratos] = useState('0')
  const [totalMontoVencido, setTotalMontoVencido] = useState('0')
  const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')
  const [dataTipoVisita, setDataTipoVisita] = useState([])
  const [tipoSeleccionado, setTipoSeleccionado] = useState("")
  const [estatusSeleccionado, setEstatusSeleccionado] = useState("")

  const [allPromesas, setAllPromesas] = useState()
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, SetIsVisible] = useState(true)
  const dropdownRef = useRef(null)

  const dataEstatus = [
    { value: 0, label: "Estatus" },
    { value: 1, label: "Concluido" },
    { value: 2, label: "Pendiente" },
    { value: 3, label: "Proceso" }
  ]

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleVisible = (visible) => {
    SetIsVisible(visible)
    setIsOpen(false)
  }

  // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
  const toggleActive7 = () => {

    setActive15(false)
    const vigente = analizarDatos(allPromesas, 7, "Vigente")
    const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive7(!active7)
  }

  const toggleActive15 = () => {
    setActive7(false)
    const vigente = analizarDatos(allPromesas, 15, "Vigente")
    const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive15(!active15)
  }

  function analizarDatos(datos, dias, estado) {

    // Función para obtener la fecha límite
    const obtenerFechaLimite = () => {
      const hoy = new Date();
      const fechaLimite = new Date(hoy);
      fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
      return fechaLimite;
    };

    const fechaLimite = obtenerFechaLimite();

    // Filtrar datos según la fecha
    const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
    const total = datosFiltrados.length;

    // Suma total de dinero
    const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
    const sumaTotalDinero = activos.length > 0 ?
      activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

    // Número de IDs diferentes
    const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size : 0

    // Número de estado activo y cálculo del porcentaje
    const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

    return {
      sumaTotalDinero,
      idsDiferentes,
      porcentajeActivos: porcentajeActivos.toFixed(2)
    };
  }

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)
    return () => document.removeEventListener('mousedown', closeDropdown)
  }, [])

  useEffect(() => {
    let filteredData = []
    let montovencido = 0;
    let montocobrado = 0;
    let contratosActivos = 0;
    if (data !== undefined && data !== null && data.length !== 0) {
      filteredData = data?.filter(item => {

        const nameMatches = textoBusqueda === '' || (item?.contrato_id && item?.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));

        return nameMatches
          && (estatusSeleccionado === "" || estatusSeleccionado === "Estatus" || item?.item?.items[0]?.estatus === estatusSeleccionado)
          && (tipoSeleccionado === "" || tipoSeleccionado === "Tipo de visita" || item?.tipo_visita === tipoSeleccionado)
      })
    }

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData?.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = (filteredData === undefined || filteredData === null) ? 1 : Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)

    filteredData.map(m => {
      // if(m?.item.items.contrato.estatus === "Activo") {
      //   contratosActivos++
      // }
      montocobrado = montocobrado + m.pago_periodo;
      montovencido = montovencido + m.monto_adeudo
    })
    setTotalMontoCobrado(Number(montocobrado.toFixed(2)))
    setTotalMontoVencido(Number(montovencido.toFixed(2)))

    // let length = filteredData.length
    // let percentage = contratosActivos > 0 ? filteredData.length / contratosActivos : 0

    //   setTotalContratos(length)
    //   setPercentage(percentage)

    setTotalContratos(filteredData.length)

  }, [currentPage, textoBusqueda, pageRows, data, estatusSeleccionado, tipoSeleccionado])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    // handleGetSeguimientoContrato()
    // handleGetPromesasdePago()

    setLoading(true);
    handleCargaInicial()
  }, [])

  const handleCargaInicial = async () => {
    const [] = await Promise.all([handleGetSeguimientoContrato(), handleGetPromesasdePago()]).finally(() => {
      setLoading(false);
    });
  }

  const handleGetSeguimientoContrato = async () => {
    // setLoading(true);
    let dataFormateada = []
    let filtrarTipoVisita = new Set()
    let newDataTipo = [{ value: 0, label: "Tipo de visita" }];
    let counterTipo = 1;
    let contratosActivos = 0;

    await CobranzaService.GetAllRutasCobranza()
      .then(res => {
        res?.data?.responseData?.map(item=>{
          //if(item?.userid.toLowerCase() === userId.toLowerCase()){
           if(item.userid === "91c793e4-5348-4d64-afc2-c94def209771"){
            for (const i of item?.items) {
              if (i?.contrato !== null &&
                i?.contrato.cartera_vencida === 1 &&
                i?.contrato.nivel_cobranza === "2" &&
                i?.estatus === "Pendiente") {

                if (i?.contrato.estatus === "Activo") {
                  contratosActivos++
                }

                let colorFondo = ""
                let colorTexto = ""
                switch (i?.contrato?.estatus) {
                  case "Cumplida":
                    colorFondo = "#D8F2CD"
                    colorTexto = "#47A066"
                    break;
                  case "Rota":
                    colorFondo = "#F5CECE"
                    colorTexto = "#CE2222"
                    break;
                  case "Vigente":
                    colorFondo = "#FAE7BC"
                    colorTexto = "#B46B16"
                    break;
                  default:
                    break;
                }
                filtrarTipoVisita.add(i?.tipo_visita)
                dataFormateada.push({
                  item: item,
                  contrato_id: i?.contrato?.contrato_id,
                  tipo_persona: i?.contrato.tipo_persona,
                  nombre: `${i?.contrato?.nombre} ${i?.contrato?.apellido_paterno} ${i?.contrato?.apellido_materno}`,
                  monto_adeudo: i?.contrato?.monto_adeudo,
                  monto_Adeudo: formatearMonto(i?.contrato?.monto_adeudo),
                  fecha: FormatFecha(i?.fecha),
                  colonia: `${i?.contrato?.calle} #${i?.contrato?.no_ext} Col. ${i?.contrato?.colonia}`,
                  tipo_visita: i?.tipo_visita,
                  contrato_status: `${i?.estatus}`,
                  colorFondo: colorFondo,
                  colorTexto: colorTexto,
                  pago_periodo: i?.contrato?.pago_periodo,
                })
              }
            }
          }
        })

        for (const value of filtrarTipoVisita) {
          newDataTipo.push({ value: counterTipo, label: value })
          counterTipo++
        }

      }).catch(e => {
        // setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

    let length = dataFormateada.length
    let percentage = contratosActivos > 0 ? dataFormateada.length / contratosActivos : 0

    setTotalContratos(length)
    setPercentage(percentage)
    setDataTipoVisita(newDataTipo)
    setData(dataFormateada)
    // setLoading(false);
  }

  const handleDetails = (value) => {

   let detalle = value?.item?.items?.find(item => item.contrato?.contrato_id === value.contrato_id);

    // dispatch(setContrato(value?.item?.items[0]?.contrato));
    dispatch(setContrato(detalle?.contrato));
    // dispatch(setItemGeneral(value?.item?.items[0]));
    dispatch(setItemGeneral(detalle));
    navigate(`/cobranza/seguimiento-visita/detalle`);
  }

  const handleGetPromesasdePago = async () => {
    // setLoading(true);
    await CobranzaService.getAllPromesasDePago()
      .then(res => {

        const promesasFiltradas = res.data.filter(obj => allContratos.includes(obj.contrato_id))

        const vigente = analizarDatos(promesasFiltradas, 0, "Vigente")
        const cumplidas = analizarDatos(promesasFiltradas, 0, "Cumplida")

        const suma = promesasFiltradas.reduce((total, obj) => {
          return {
            montoVencido: total.montoVencido + obj.monto_vencido,
            montoCobrado: total.montoCobrado + obj.monto_recuperado
          };
        }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

        setAllPromesas(promesasFiltradas)
        // setTotalMontoCobrado(suma.montoCobrado)
        // setTotalMontoVencido(suma.montoVencido)
        setFirstProgressVigente(vigente)
        setFirstProgressCumplidas(cumplidas)

        // setLoading(false);

      }).catch(e => {
        // setLoading(false);
      })
  }

  return {
    setTextoBusqueda,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    dataTipoVisita,
    dataEstatus,
    totalContratos,
    userName,
    dropdownRef,
    toggleDropdown,
    toggleVisible,
    percentage,
    active7,
    toggleActive7,
    active15,
    toggleActive15,
    isOpen,
    isVisible,
    loading,
    firstProgressVigente,
    firstProgressCumplidas,
    secondProgressVigentes,
    secondProgressCumplidas,
    totalMontoCobrado,
    totalMontoVencido,
    handlePageClick,
    setTipoSeleccionado,
    setEstatusSeleccionado
  }
}
export default useSeguimientoVisita