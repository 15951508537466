import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import { SelectField } from '../../Components/SelectField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const DocumentoEtapaModal = ({ isOpen, setIsOpen, item, handleGetAll, message, toast }) => {



    const [valor, setValor] = useState("");
    let initial = {
        documentos_EtapaId: item?.documentos_EtapaId ?? "",
        etapaId: item?.etapaId ?? "",
        tipoDocumentoId: item?.tipoDocumentoId ?? "",
        tipoCompraId: item.tipoCompraId,
        productoId: item.tipoProductoId,
        docGestoria: item.docGestoria ?? '',
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [DataEtapa, setDataEtapa] = useState([]);
    const [dataDocuemento, setDataDocuemento] = useState([]);

    const DataDocGestoria = [
        {value:"",label:"Seleccione"},
        {value:1,label:"Autorizada"},
        {value:0,label:"Rechazada"},
    ]

    const validate = Yup.object({
        tipoDocumentoId: Yup.string()
            .required("El campo es requerido."),
        etapaId: Yup.string()
            .required("El campo es requerido."),
        docGestoria: Yup.string()
            .when("etapaId", (etapaId) => {
                if (etapaId == 22 || etapaId == 15) {
                    return Yup.string()
                        .required("El campo es requerido.");
                }
            }),
    });

    useEffect(() => {
        handleGetAllEtapa();
        handleGetAllDocumentos();
    }, [])

    const handleGetAllEtapa = async () => {
        await EtapaService.getAllEtapa()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaId,
                        label: item.etapa
                    })
                });
                setDataEtapa(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Correcto.!", e.response.data.message));
                else
                    toast.error(message("¡Correcto.!", e.message));
            })
    }
    const handleGetAllDocumentos = async () => {
        await EtapaService.getAllDocumentos()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoDocumentoId,
                        label: item.nombre
                    })
                });
                setDataDocuemento(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Correcto.!", e.response.data.message));
                else
                    toast.error(message("¡Correcto.!", e.message));
            })
    }
    const handleAddEdit = async (data) => {
        if (initialValue.documentos_EtapaId !== "") {
            await VentaVehiculoService.UpdDocumentoEtapa(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else {
            await VentaVehiculoService.addDocumentoEtapa(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit({...values,docGestoria:values.docGestoria === '' ? 2 : values.docGestoria});            
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5'><strong>Asignar documento a etapa</strong></span>
                                <span style={{ fontSize: '12px' }}>A continuación se puede asignar un documento por etapa.</span>
                            </div>

                            <Form>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-sm-6 mb-2">
                                        <SelectField className="col-12" label="Etapa:" name="etapaId" items={DataEtapa} onChange={(event) => {
                                            setFieldValue("etapaId", event.value);                                            
                                        }} />
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <SelectField className="col-12" label="Documento:" name="tipoDocumentoId" items={dataDocuemento} onChange={(event) => {
                                            setFieldValue("tipoDocumentoId", event.value);
                                        }} />
                                    </div>
                                    {console.log(values.etapaId)}
                                    {(values.etapaId === 13 || values.etapaId === 15) &&
                                        <div className="col-sm-6 mb-2">
                                            <SelectField className="col-12" label="Accion:" name="docGestoria" items={DataDocGestoria} onChange={(event) => {
                                                setFieldValue("docGestoria", event.value);
                                            }} />
                                        </div>
                                    }
                                </div>

                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default DocumentoEtapaModal