import React from 'react'
import Select from 'react-select'
import { useEffect } from 'react';
import DataTable from '../../Components/datatable/DataTable';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import SubMenuParametrosCartera from '../../Components/SubMenuParametrosCartera';
// import AcendesCus from '../../Hooks/Parametros/Acendes';
// import TenenciaModal from './ParametrosModal';
import { pageRows } from '../../Utils/functions';
// import RowPages from '../../Components/RowPages';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import ParametrosCarteraHistorialHooks from '../../Hooks/ParametrosCartera/ParametrosCarteraHistorialHooks';


const ParametrosCarteraHistorial = () => {
    let {
        columns,
        strFiltro,
        setStrFiltro,
        dataParametrosCarteraLogs,
        setDateTime
    } = ParametrosCarteraHistorialHooks();

    return (
        <>
            <SubMenuParametrosCartera />

            <section className='mx-5 my-4 d-flex'>
                <div className='d-grid justify-content-between'>
                    <span className='fs-5'><strong>Parámetros cartera</strong></span>
                    <span className='mt-2'>A continuación, se presentan los parametros agrupados por proceso</span>
                </div>

                <div className='ms-auto justify-content-between'>
                    <button className='btn btn-blue' onClick={console.log()}>
                        Exportar Excel
                    </button>
                </div>


            </section>

            <section className='mt-3 mx-4'>
                <div className='col-lg-12 col-sm-12 d-flex'>
                    <div className="col-4 mx-1  inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStrFiltro(e.target.value) }} />
                    </div>
                    <div className='col-4'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <input type="date" className="form-control mb-4" placeholder="Fecha" onChange={(e) => { setDateTime(e.target.value) }} />

                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-3 mx-4'>
                <DataTable
                    column={columns}
                    data={dataParametrosCarteraLogs}
                // editable={true}
                // handleEdit={handleEdit}
                />
            </section>


        </>
    )

}


export default ParametrosCarteraHistorial