import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cheked:false,
  item:null,
  isOpenModalClave:false,
  isOpenModalClaveCambioProd:false
}
export const quickActionsSlice = createSlice({
  name: 'quickActions',
  initialState,
  reducers: {
    chekItem: (state, action) => {        
      return { ...state, cheked: action.payload.check,item:action.payload.item }
    }  ,
    isOpenModalCheck: (state, action) => {        
      return { ...state, isOpenModalClave: action.payload.check }
    },
    isOpenModalCambioProd: (state, action) => {        
      return { ...state, isOpenModalClaveCambioProd: action.payload.check }
    } 
  },
})

export const { chekItem,isOpenModalCheck,isOpenModalCambioProd} = quickActionsSlice.actions

export default quickActionsSlice.reducer