import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dataGarantiasReparacionDetalle: {},
}

export const ListaGarantiasReparacionDetalleSlice = createSlice({
    name: 'ListaGarantiasReparacionDetalleSlice',
    initialState,
    reducers: {
        setlistaGarantiasReparacionDetalle: (state, action) => {
            return {...state,
                dataGarantiasReparacionDetalle: action.payload,
            }
        }
    }
})

export const { setlistaGarantiasReparacionDetalle } = ListaGarantiasReparacionDetalleSlice.actions
export default ListaGarantiasReparacionDetalleSlice.reducer