import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const useReglaEtapa = (setLoading) => {
  const columnsReglaEtapa = [
    { field: 'reglasNegocioId', headerName: 'No. Regla', width: 150 },
    { field: 'regla', headerName: 'Regla', width: 350 },
    { field: 'etapaOrigen', headerName: 'Etapa', width: 250 },
    { field: 'status', headerName: 'Estatus', width: 70 },
    { field: 'fecha', headerName: 'Fecha', width: 150 }
  ];
  const columnsReglaNegocio = [
    { field: 'reglasNegocioId', headerName: 'No. Regla', width: 150 },
    { field: 'etapaOrigen', headerName: 'Origen', width: 150 },
    { field: 'etapaDestino', headerName: 'Destino', width: 150 },
    { field: 'descripcion', headerName: 'Descripcion', width: 350 },
    { field: 'fecha', headerName: 'Fecha', width: 250 },
    { field: 'status', headerName: 'Estatus', width: 70 },
  ];
  const columnsTipoRevision = [
    { field: 'reglasNegocioId', headerName: 'No. Regla', width: 150 },
    { field: 'etapaOrigen', headerName: 'Etapa origen', width: 150 },
    { field: 'etapaDestino', headerName: 'Etapa destino', width: 450 },
    { field: 'status', headerName: 'Estatus', width: 70 },
    { field: 'fecha', headerName: 'Fecha', width: 250 }
  ];
  const columnsTipoBloqueo = [
    { field: 'reglasNegocioId', headerName: 'No. Regla', width: 150 },
    { field: 'etapaOrigen', headerName: 'Etapa origen', width: 150 },
    { field: 'status', headerName: 'Estatus', width: 70 },
    { field: 'fecha', headerName: 'Fecha', width: 250 }
  ];
 

  const [LoadingSpinner, setLoadingSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [productoId, setProductoId] = useState("");
  const [tipocompraId, setTipocompraId] = useState("");
  const [tipoReglaId, setTipoReglaId] = useState("");
  const [etapaId, setEtapaId] = useState("");
  const [isOpenModalReglaEtapa, setIsOpenModalReglaEtapa] = useState(false);
  const [isOpenModalReglaNegocio, setIsOpenModalReglaNegocio] = useState(false);
  const [isOpenModalReglaTReversion, setIsOpenModalReglaTReversion] = useState(false);
  const [isOpenModalReglaTBloqueo, setIsOpenModalReglaTBloqueo] = useState(false);
  const [isOpenModalMotivo, setIsOpenModalMotivo] = useState(false);
  const [item, setItem] = useState(null);
  const [dataReglaEtapa, setDataReglaEtapa] = useState([]);
  const [dataReglaNegocio, setDataReglaNegocio] = useState([]);
  const [dataReglaTipoBloqueo, setDataReglaTipoBloqueo] = useState([]);
  const [dataReglaTipoRevision, setDataReglaTipoRevision] = useState([]);

  const [currentPageRNegocio, setCurrentPageRNegocio] = useState(1);
  const [pageCountReglaNegocio, setPageCountReglaNegocio] = useState();

  useEffect(() => {
    if (productoId !== "" && tipocompraId !== "" && tipoReglaId !== "")
      handleGetAllReglaEtapa();
  }, [productoId, tipocompraId, tipoReglaId, etapaId])

  const handleGetAllReglaEtapa = async () => {  
    if (tipoReglaId === 2) {
      setLoading(true);
      // await EtapaService.getAllReglaEtapa(productoId, tipocompraId, etapaId)
      await EtapaService.reglaAllNegocioByTipo(productoId, tipocompraId, tipoReglaId,etapaId)
        .then(res => {          
          setDataReglaEtapa(res.data.data);
          setDataReglaNegocio([]);
          setDataReglaTipoRevision([]);
          setDataReglaTipoBloqueo([]);
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else if (tipoReglaId === 1) {
      setLoading(true);
      await EtapaService.reglaAllNegocioByTipo(productoId, tipocompraId, tipoReglaId,etapaId)
        .then(res => {
          setDataReglaEtapa([]);
          setDataReglaTipoRevision([]);
          setDataReglaTipoBloqueo([]);
          setDataReglaNegocio(res.data.data);          
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else if (tipoReglaId === 3) {
      setLoading(true);      
      await EtapaService.reglaAllNegocioByTipo(productoId, tipocompraId, tipoReglaId,etapaId)
        .then(res => {
          setDataReglaEtapa([]);
          setDataReglaNegocio([]);
          setDataReglaTipoBloqueo([])
          setDataReglaTipoRevision(res.data.data);
          
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
              
    }else if(tipoReglaId === 4){
      setLoading(true);
      await EtapaService.reglaAllNegocioByTipo(productoId, tipocompraId, tipoReglaId,etapaId)
        .then(res => {
          setDataReglaEtapa([]);
          setDataReglaNegocio([]);
          setDataReglaTipoRevision([]);
          setDataReglaTipoBloqueo(res.data.data);
          
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })           
    }


  }
  const handleEditRegla = (item) => {
    setItem(item)
    if (item === null)
      setItem({ productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'add' });
    else
      setItem({ ...item,productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'edit' });

    setIsOpenModalReglaEtapa(true);
  }

  const handleEditReglaNegocio = (item) => {
    setItem(item)
    if (item === null)
      setItem({ productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'add' });
    else
      setItem({ ...item,productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'edit' });
    
    setIsOpenModalReglaNegocio(true);
  }

  const handleEditReglaTipoReversion = (item) => {
    setItem(item)
    if (item === null)
      setItem({ productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'add' });
    else
      setItem({ ...item,productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'edit' });
    
    setIsOpenModalReglaTReversion(true);
  }
  const handleEditReglaTipoBloqueo = (item) => {
    setItem(item)
    if (item === null)
      setItem({ productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'add' });
    else
      setItem({ ...item,productoId: productoId, tipoCompraId: tipocompraId,tipoReglaId, action: 'edit' });
    
    setIsOpenModalReglaTBloqueo(true);
  }
  // const handlePageClickReglaNegocio = (event) => {
  //   setCurrentPage(event.selected + 1);
  //   handleGetAllHistory();
  // };
  return {
    handleGetAllReglaEtapa,
    setProductoId,
    productoId,
    setTipocompraId,
    tipocompraId,
    setEtapaId,
    etapaId,
    setTipoReglaId,
    tipoReglaId,
    dataReglaEtapa,
    handleEditRegla,
    isOpenModalReglaEtapa,
    setIsOpenModalReglaEtapa,
    item,
    setItem,
    columnsReglaEtapa,
    columnsReglaNegocio,
    dataReglaNegocio,
    setDataReglaEtapa,
    setDataReglaNegocio,
    setIsOpenModalMotivo,
    isOpenModalMotivo,
    handleEditReglaNegocio,
    setIsOpenModalReglaNegocio,
    isOpenModalReglaNegocio,
    handleEditReglaNegocio,
    columnsTipoRevision,
    dataReglaTipoRevision,
    setDataReglaTipoRevision,
    columnsTipoBloqueo,
    dataReglaTipoBloqueo,
    setDataReglaTipoBloqueo,
    handleEditReglaTipoReversion,
    isOpenModalReglaTReversion,
    setIsOpenModalReglaTReversion,
    handleEditReglaTipoBloqueo,
    setIsOpenModalReglaTBloqueo,
    isOpenModalReglaTBloqueo
  }
}

export default useReglaEtapa