import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import { TextField } from '../../../Components/TextField';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import Llamada from '../../../Services/Cartera/Llamadas';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Garantias from '../../../Services/Cartera/Garantias';
import documentsHelper from '../../../Utils/documents';
const CambioPlacasCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { vin, id } = useParams();
        let initial = {
            gestoriaCambioPlacasId: null,
            gestoriaCambioPlacasStatusId: 0,
            generalId: "",
            nombreMarca: null,
            nombreModelo: null,
            nombreVersion: null,
            nombreYear: null,
            gestoriaCambioPlacasStatus: "Pendiente",
            color: "default",
            gestoriaRegistroInicialId: null,
            denunciaCODE: null,
            fechaDenuncia: null,
            cantidadPlacasExtraviadas: null,
            comentariosDenuncia: null,
            denunciaConcluida: null,
            fechaCita: null,
            horaCita: null,
            citaAprobada: null,
            sucursalId: null,
            sucursalName: null,
            bloqueoId: null,
            responsivaDescargada: null,
            papeleriaCompleta: null,
            comentarioPapeleria: null,
            papeleriaConcluida: null,
            tipoContactoId: null,
            fechaTramite: null,
            nuevasPlacas: null,
            concluido: null,
            usuarioAlta: "",
            usuarioActualiza: null,
            usuarioAltaNombre: "",
            usuarioActualizaNombre: null,
            fechaAlta: "",
            fechaActualiza: null,
            fechaConclusion: null
        }

        const { nombreYear, nombreMarca, nombreModelo, nombreVersion, numContrato } = useSelector((state) => state.DataVinSlice);
        const { dayCitaCambioPlacas, hourCitaCambioPlacas, sucursalId, sucursalName, calendarioIdCitaCambioPlacas } = useSelector((state) => state.CambioPlacasSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [cita, setCita] = useState('');
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const opcionesPlacas = [{ value: 0, label: '1 Placa' }, { value: 1, label: 'Ambas Placas' }]
        const opcionesContacto = [{ value: 0, label: 'Presencial' }, { value: 1, label: 'Virtual' }];
        const [sucursalNameStr, setSucursalNameStr] = useState('');


        useEffect(() => {
            if (hourCitaCambioPlacas) {
                const formattedDate = `${dayCitaCambioPlacas.split("T")[0].split("-").reverse().join('-')} ${hourCitaCambioPlacas}`;
                setInitialValue({
                    ...initialValue,
                    fechaCita: `${dayCitaCambioPlacas.split("T")[0].split("-").reverse().join('-')}`,
                    horaCita: hourCitaCambioPlacas,
                    ubicacion: sucursalName
                });
                setCita(formattedDate);
                setSucursalNameStr(sucursalName);
            } else {
                setInitialValue({
                    ...initialValue,
                    fechaCita: '',
                    horaCita: '',
                    ubicacion: ''
                });
                setCita('');
            }
        }, [hourCitaCambioPlacas]);

        useEffect(() => {
            getCambioPlacas();
        }, []);

        /* CONSULTAS */
        const getCambioPlacas = async () => {
            await Gestoria.CambioPlacasGet(id)
                .then(resp => {
                    if (resp.data.fechaDenuncia !== null) {
                        resp.data.fechaDenuncia = resp.data.fechaDenuncia.split('T')[0];
                    }
                    resp.data.denunciaCODE = resp.data.denunciaCODE === null ? null : (resp.data.denunciaCODE ? 1 : 0);
                    
                    if (resp.data.fechaCita !== null && resp.data.fechaCita !== "") {
                        const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                        setSucursalNameStr(resp.data.sucursalName);
                        setCita(formattedDate);
                    }

                    console.log(resp.data);
                    setInitialValue(resp.data);
                })
                .catch(err => {
                    console.error(err);
                })
        }
        /*  TERMINA CONSULTAS */
        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const formatMoney = (value) => {
            if (value === undefined) {
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        const submitForm = async (values) => {
            setLoading(true);

            if ((hourCitaCambioPlacas !== null && hourCitaCambioPlacas !== undefined && hourCitaCambioPlacas !== '') && initialValue.fechaCita === null) {
                const dBloqueo = {
                    ubicacionid: sucursalId,
                    fecha: `${dayCitaCambioPlacas.split("T")[0]}`,
                    calendarioid: calendarioIdCitaCambioPlacas,
                    calendariotipoid: 6,
                    relacionid: id
                }
                await Llamada.crearCita(dBloqueo)
                    .then(resp => {
                        const data = resp.data.data;
                        setInitialValue({
                            ...initialValue,
                            fechaCita: dayCitaCambioPlacas.split("T")[0],
                            horaCita: hourCitaCambioPlacas,
                        })
                        toast.success(message("Se agendó la cita correctamente"));
                        actualizaCitaCambioPlacas(data.bloqueoId, values)
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar agendar la cita!"));
                        console.error(err);
                    })
                    .finally(() => setLoading(false));
            } else {
                if (values.concluido) {
                    values.gestoriaCambioPlacasStatusId = 60;
                } else {
                    values.gestoriaCambioPlacasStatusId = 20;
                }
                await Gestoria.CambioPlacasActualizar(values)
                    .then(resp => {
                        resp.data.fechaDenuncia = resp.data.fechaDenuncia.split('T')[0];
                        resp.data.denunciaCODE = resp.data.denunciaCODE === null ? null : (resp.data.denunciaCODE ? 1 : 0);
                        if (resp.data.fechaCita !== null && resp.data.fechaCita !== "") {
                            const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                            setSucursalNameStr(resp.data.sucursalName);
                            setCita(formattedDate);
                        }
                        setInitialValue(resp.data);
                        toast.success(message("Se Actualizó el Cambio de Placas correctamente"));
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar Actualizar el Cambio de Placas!"));
                        console.error(err);
                    })
                    .finally(() => {
                        setLoading(false)
                        if (values.concluido) {
                            crearEntregaGestoria();
                        }
                    });
            }

        }

        const actualizaCitaCambioPlacas = async (bloqueoId, values) => {

            let params = {
                sucursalId: sucursalId,
                sucursalName: sucursalName,
                bloqueoId: bloqueoId,
                gestoriaCambioPlacasStatusId: 30,
                gestoriaRegistroInicialId: id,
                fechaCita: `${dayCitaCambioPlacas.split("T")[0]}`,
                horaCita: hourCitaCambioPlacas
            }

            await Gestoria.CambioPlacasActualizar(params)
                .then(resp => {
                    resp.data.fechaDenuncia = resp.data.fechaDenuncia.split('T')[0];
                    resp.data.denunciaCODE = resp.data.denunciaCODE === null ? null : (resp.data.denunciaCODE ? 1 : 0);

                    if (resp.data.fechaCita !== null && resp.data.fechaCita !== "") {
                        const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                        setSucursalNameStr(resp.data.sucursalName);
                        setCita(formattedDate);
                    }

                    enviarMail();
                    setInitialValue(resp.data);
                    toast.success(message("Se Actualizó el Cambio de Placas correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar Actualizar el Cambio de Placas!"));
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }

        const enviarMail = async () => {
            const contenidoEmail = {
                VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
                VARIABLE_MARCA: nombreMarca,
                VARIABLE_MODELO: nombreModelo,
                VARIABLE_ANIO: nombreYear,
                VARIABLE_VERSION: nombreVersion,
                VARIABLE_VIN: vin,
                
            }
            
            Garantias.creardatosenviar({
                modulo: 'Aprobación de cita de extravío de placas',
                tipoCorreoId: 58,
                contenido: JSON.stringify(contenidoEmail)
            }).catch(err => {
                console.log("Error al enviar correo de Aprobación de cita de extravío de placas:", err);
            })
        }

        const crearEntregaGestoria = async () => {
            let params = {
                gestoriaEntregaStatusId: 10,
                gestoriaRegistroInicialId: id
            }
            setLoading(true);
            await Gestoria.GestoriaEntregaCrear(params)
                .then(resp => {
                    console.log(resp.data);
                    toast.success(message("Se creó la entrega de gestoría correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar crear la entrega de gestoría!"));
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        const verCalendario = async (valor, values) => {
            dispatch(setSchedule({
                url: `gestoria/seguimiento/${vin}/${id}`,
                contrato: numContrato,
                vin: vin,
                modelo: initialValue.nombreModelo,
                ano: initialValue.nombreYear,
                marca: initialValue.nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }

        const verModalAutorizarCita = async (values) => {
            let formattedDate = "";
            if (initialValue.fechaCita === null || initialValue.fechaCita === undefined || initialValue.fechaCita === "") {
                formattedDate = `${dayCitaCambioPlacas.split("T")[0].split("-").reverse().join('-')} ${hourCitaCambioPlacas}`;
            } else {
                formattedDate = `${initialValue.fechaCita.split("T")[0].split("-").reverse().join('-')} ${initialValue.horaCita}`;
            }

            let data = {
                "generalId": initialValue.generalId,
                "garantiaId": id,
                "folioCita": "",
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": initialValue.nombreMarca,
                "claveModelo": initialValue.nombreModelo,
                "nombreModelo": initialValue.nombreModelo,
                "claveYear": initialValue.nombreYear,
                "nombreYear": initialValue.nombreYear,
                "ubicacionId": initialValue.sucursalId,
                "ubicacion": initialValue.sucursalName,
                "tipoGarantiaId": null,
                "clasificacionFallaId": null,
                "telefono": "",
                "fechaCita": formattedDate,
                "_bloqueoId": initialValue.bloqueoId,
            }

            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 9, Origin: "Listado" })
        }


        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const initRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || initialValue.denunciaConcluida) return true;
            return false;
        }

        const denunciaConcluirRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || (initialValue.denunciaConcluida || !initialValue.denunciaCODE)) return true;
            return false;
        }

        const denunciaRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || (!initialValue.denunciaConcluida || initialValue.citaAprobada || !initialValue.denunciaCODE)) return true;
            return false;
        }

        const citaRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || (!initialValue.citaAprobada)) return true;
            return false;
        }

        const pdfRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || (!initialValue.responsivaDescargada || initialValue.papeleriaConcluida)) return true;
            return false;
        }

        const papeleriaRule = () => {
            if (initialValue.gestoriaCambioPlacasStatusId === 60 || (!initialValue.papeleriaConcluida || initialValue.concluido)) return true;
            return false;
        }

        const downloadPDF = async () => {
            if (!initialValue.responsivaDescargada) {
                setLoading(true);
                let params = {
                    responsivaDescargada: true,
                    gestoriaRegistroInicialId: id
                }

                await Gestoria.CambioPlacasActualizar(params)
                    .then(resp => {
                        resp.data.fechaDenuncia = resp.data.fechaDenuncia.split('T')[0];
                        resp.data.denunciaCODE = resp.data.denunciaCODE === null ? null : (resp.data.denunciaCODE ? 1 : 0);

                        setInitialValue(resp.data);
                        toast.success(message("Se Actualizó el Cambio de Placas correctamente"));

                        generatePDF();
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar Actualizar el Cambio de Placas!"));
                        console.error(err);
                    })
                    .finally(() => setLoading(false));

            } else {
                generatePDF();
            }
        }

        const generatePDF = async () => {
            const date = new Date();
            const formattedDate = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

            const requestBody = {
                nombrePlantilla: "CartaAutorizacionBajaVehiculoPersonaMoral",
                parametros: {
                    "<<VARIABLE_EMPRESA>>": "",
                    "<<VARIABLE_APODERADO>>": "",
                    "<<VARIABLE_CLIENTE>>": "",
                    "<<VARIABLE_DOMICILIO>>": "",
                    "<<VARIABLE_MARCA>>": initialValue.nombreMarca,
                    "<<VARIABLE_MODELO>>": initialValue.nombreModelo,
                    "<<VARIABLE_VERSION>>": initialValue.nombreVersion,
                    "<<VARIABLE_ANIO>>": initialValue.nombreYear,
                    "<<VARIABLE_VIN>>": vin,
                    "<<VARIABLE_CORREO_GESTOR>>": "",
                    "<<VARIABLE_FECHA>>": formattedDate
                }
            };


            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            }).catch(err => {
                console.error(err);
            });
        }


        const validate = Yup.object({});
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            getCambioPlacas={getCambioPlacas}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            getCambioPlacas={getCambioPlacas}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">
                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCambioPlacas" role="button" aria-expanded="false" aria-controls="collapseCambioPlacas" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cambio de placas extravío</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.gestoriaCambioPlacasStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseCambioPlacas">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-2">
                                                        <small><strong>Detalle denuncia</strong></small>
                                                    </div>
                                                    <div className="col-12 col-lg-2">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn btn-gray'
                                                                    onClick={() => {
                                                                        navigate(`/garantias/datadocs/fGestoria/eSeguimie/${vin}/${id}`, {
                                                                            state: values,
                                                                        });
                                                                    }}>
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-8">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.gestoriaCambioPlacasStatusId === 60}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-4">
                                                        <SelectField id="denunciaCODE"
                                                            label="¿Se realizó denuncia ante CODE?"
                                                            name="denunciaCODE"
                                                            disabled={initRule()}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("denunciaCODE", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <TextField id="fechaDenuncia" disabled={initRule()} label="Fecha de denuncia" holder="" name="fechaDenuncia" type="date" onChange={(event) => {
                                                            setFieldValue("fechaDenuncia", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <SelectField id="cantidadPlacasExtraviadas"
                                                            label="Cantidad de placas extraviadas"
                                                            name="cantidadPlacasExtraviadas"
                                                            disabled={initRule()}
                                                            items={opcionesPlacas}
                                                            onChange={(event) => {
                                                                setFieldValue("cantidadPlacasExtraviadas", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosDenuncia"
                                                            label="Comentarios"
                                                            disabled={initRule()}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosDenuncia"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosDenuncia", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={denunciaConcluirRule()} type="checkbox" name="denunciaConcluida" onClick={(event) => {
                                                            setFieldValue("denunciaConcluida", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Denuncia concluida</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Programar cita</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <span>Fecha y hora de cita</span>
                                                        <div className='pt-1' style={containerStyle}>
                                                            <span>{cita}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn mt-4'
                                                            disabled={denunciaRule()}
                                                            onClick={(e) => verCalendario(9, values)}>
                                                            Ver Calendario
                                                        </button>
                                                    </div>
                                                    <div className="col-3">
                                                        <span>Estatus cita</span>
                                                        <div className='pt-1' style={containerStyle}>
                                                            <span>
                                                                {
                                                                    initialValue.fechaCita === null &&
                                                                    'Sin Cita Agendada'
                                                                }
                                                                {
                                                                    initialValue.fechaCita !== null &&
                                                                    initialValue.citaAprobada === null &&
                                                                    'Cita Pendiente de Aprobación'
                                                                }
                                                                {
                                                                    initialValue.fechaCita !== null &&
                                                                    initialValue.citaAprobada &&
                                                                    'Cita Aprobada'
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className='btn mt-4 col-12' disabled={initialValue.BloqueoId === null || initialValue.gestoriaCambioPlacasStatusId !== 30} onClick={(e) => verModalAutorizarCita(values)}>Autorizar cita</button>
                                                    </div>
                                                    <div className="col-3">
                                                        <span>Sucursal</span>
                                                        <div className='pt-1' style={containerStyle}>
                                                            <span>
                                                                {sucursalNameStr}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Cita de entrega de papalería para trámite</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn btn-gray'
                                                                    onClick={() => {
                                                                        navigate(`/garantias/datadocs/fGestoria/eCaPlEn/${vin}/${id}`, {
                                                                            state: values,
                                                                        });
                                                                    }}>
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small>Responsiva del trámite</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className='btn btn-gray'
                                                            disabled={citaRule()}
                                                            onClick={() => downloadPDF()}>
                                                            <i className="icon-blue bx bx-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="papeleriaCompleta"
                                                            label="Papelería completa"
                                                            name="papeleriaCompleta"
                                                            disabled={pdfRule()}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("papeleriaCompleta", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarioPapeleria"
                                                            label="Comentarios"
                                                            disabled={pdfRule()}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarioPapeleria"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarioPapeleria", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={pdfRule()} type="checkbox" name="papeleriaConcluida" onClick={(event) => {
                                                            setFieldValue("papeleriaConcluida", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Papelería completa para iniciar trámite</label>
                                                    </div>
                                                </div>

                                                <div className="row mt-4 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Trámite de placas</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn btn-gray'
                                                                    onClick={() => {
                                                                        navigate(`/garantias/datadocs/fGestoria/eCaPlTr/${vin}/${id}`, {
                                                                            state: values,
                                                                        });
                                                                    }}>
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-4">
                                                        <SelectField id="tipoContactoId"
                                                            label="Tipo de contacto"
                                                            name="tipoContactoId"
                                                            disabled={papeleriaRule()}
                                                            items={opcionesContacto}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoContactoId", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <TextField id="fechaTramite" disabled={papeleriaRule()} label="Fecha de realización del trámite" holder="" name="fechaTramite" type="date" onChange={(event) => {
                                                            setFieldValue("fechaTramite", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <TextField id="nuevasPlacas" disabled={papeleriaRule()} label="Nuevas placas" holder="Escribe" name="nuevasPlacas" type="text" onChange={(event) => {
                                                            setFieldValue("nuevasPlacas", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={papeleriaRule()} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Trámites concluidos</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default CambioPlacasCard