import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { setUsuario, setZonasCobranza } from '../../Redux/Slice/Cobranza/UsuarioSeleccionadoSlice';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from './useLocalStorage';

const useCobradoresNivel2 = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { setItem } = useLocalStorage('usuario_seleccionado')

   const columns = [
      { field: 'nombrecobrador', headerName: 'Nombre', width: 215 },
      { field: 'statuscobrador', headerName: 'Estatus', width: 150 },
      { field: 'fechainhabilitado', headerName: 'Fecha inhabilitado', width: 205 },
      { field: 'estatulaboral', headerName: 'Estatus laboral', width: 140 },
      { field: 'zona', headerName: 'Zonas asignadas', width: 350 },
      { field: 'numContratos', headerName: 'Contratos', width: 150 },
   ];

   const dataEstatus= [
      { value: 0, label: 'Estatus' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Permisos' },
      { value: 3, label: 'Vacaciones' },
      { value: 4, label: 'Enfermedad' },
      { value: 5, label: 'Inactivo' }
   ];

   const dataEstadoLaboral = [
      { value: 0, label: "Estado laboral"},
      { value: 1, label: "Activo"},
      { value: 2, label: "Baja"}
   ]

   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [dataZonas, setDataZonas] = useState([])
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10)
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [ actualizar, setActualizar ] = useState("");
   const [nivelDefault, setNivelDefault] = useState({value: 2, label: "Nivel 2"});
   const [currentPage, setCurrentPage] = useState(1);
   const [currentData, setCurrentData] = useState(data)
   const [filtroEstadoLaboral, setFiltroEstadoLaboral]=useState("")
   const [filtroEstatus, setFiltroEstatus] = useState("")
   const [filtroZonas, setFiltroZonas]=useState("")
   // const [dataContratos, setDataContratos] = useState([])
   // const [dataZonasFiltro, setDataZonasFiltro]= useState([])

   useEffect(() => {
      setItem("")
      handleGetAllData();
      setActualizar("");
      setPage(1);
   }, [actualizar])

   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      if(data.length > 0){
         let filteredData = data?.filter(item => {
            const nameMatches = str === '' || (item?.nombrecobrador && item?.nombrecobrador.toLowerCase().includes(str.toLowerCase()));
   
            return (
               nameMatches &&
               (filtroEstadoLaboral === "" || item?.estatulaboral === filtroEstadoLaboral || filtroEstadoLaboral === "Estado laboral") &&
               (filtroEstatus === "" || item?.statuscobrador === filtroEstatus || filtroEstatus=== "Estatus") &&
               (filtroZonas === "" || item?.zona.toLowerCase().includes(filtroZonas.toLowerCase()) || filtroZonas === "Zonas")
            )
         })
   
         const indexOfLastItem = currentPage * pageRows
         const indexOfFirstItem = indexOfLastItem - pageRows
         const newData = filteredData?.slice(indexOfFirstItem, indexOfLastItem)
         setCurrentData(newData)
   
         const newTotalPages = Math.ceil(filteredData.length / pageRows)
         setPageCount(newTotalPages)
         // if (currentPage > newTotalPages) {
         //   setCurrentPage(newTotalPages)
         // } else if (currentPage <= 0) {
         //   setCurrentPage(1)
         // }
      }

   }, [currentPage, str, pageRows, data, filtroEstadoLaboral,filtroEstatus, filtroZonas])


   const handlePageClick = (event) => {
      setPage(event.selected + 1);
   };

   const handleGetAllData = async () => {
      setLoading(true)
      let NewData = [];
      let newDataDetalles = [];
      let counterZonas = 1;
      let newDataZonas = [{ value: 0, label: "Zonas"}];
      let filtrarZonas = new Set()
      let newDataContratos = []
      await Cobranza.getEjecutivosFullDetail()
         .then(res => {
            // if(res.data.callSucceded){
               if(res?.data?.callSucceded){
                  res?.data?.responseData?.map(item => {
                     if(item?.nivel_cobrador === "2"){
                        let zonasItem = new Set()
                        let count = 1;
                        let zona = ""
                        let dataCont = []
                        item?.contratos?.map(contrat =>{
                           if (contrat?.cartera_vencida === 1 && contrat?.nivel_cobranza === "2") {
                              dataCont.push(contrat)
                              zonasItem.add(contrat?.zona)
                              newDataContratos.push(contrat)
                              newDataDetalles.push({numero: count, estado: contrat?.estado, municipio: contrat?.municipio, colonia: contrat?.colonia});
                              count++;
                           }
                        })
                        for(const z of zonasItem){
                           filtrarZonas.add(z)
                           zona = zona + `${z} `
                        }
                        NewData.push({
                           userid: item?.userid,
                           nombrecobrador: item?.user_detail?.names,
                           usuariocobrador: item?.user_detail?.email,
                           nivel_cobrador: {value:item?.nivel_cobrador, label:"Nivel 2"},
                           estatusdesistema: item?.disponible === null ? "" : item?.disponible,
                           statuscobrador: item?.status === null ? "" : item?.status === "Baja" ? "Inactivo" :  item?.status,
                           fechainhabilitado: item?.rangoFechas === null ? "" : item?.rangoFechas ,
                           estatulaboral: item?.activo === 1 ? "Activo" : "Baja",
                           statuslab: item?.activo === 1,
                           zona: zona,
                           numContratos: dataCont.length,
                           contratos: dataCont,
                           status: false,
                           telefono: item?.numTelefono,
                           data:[
                              {zonacobranza: item?.zona, constratosasignados: item?.numContratos, zonaasignadatooltip: item?.user_detail?.names, detail: newDataDetalles}
                           ],
                           historialStatus: item?.historialStatus === null ? [] : item?.historialStatus
                        });
                     }
                  })
                  for (const value of filtrarZonas) {
                     newDataZonas.push({value: counterZonas, label:value})
                     counterZonas++
                  }
               }
            // }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      dispatch(setZonasCobranza(newDataContratos))
      setData(NewData);
      setLoading(false);
      setDataZonas(newDataZonas)
   }

   const handleDetails = (item) =>{
      // dispatch(setUsuario(item));
      dispatch(setUsuario(item));
      navigate(`/cobranza/cobradoresdetalle`, {
         state: {
            page: 'Detalle',
            title: "Editar usuario",
            data: data
         },
      });
   }

   const handleNewCobrador = async () =>{
      setIsOpenModal(true);
   }

   return {
      columns,
      currentData,
      pageCount,
      dataEstadoLaboral,
      dataEstatus,
      dataZonas,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      setActualizar,
      nivelDefault,
      setFiltroEstadoLaboral,
      setFiltroEstatus,
      setFiltroZonas
   }
}

export default useCobradoresNivel2