import axios from '../../Utils/ApiService';

const SeguroFinalService = {

    getAllSeguroFinalPendiente: async (page, rows, marca, modelo, año, usoUnidadId, string) => {
        return await axios.get(`/functions/GetAllVehiculosPendientesSF?page=${page}&Rows=${rows}&Marca=${marca}&Modelo=${modelo}&Year=${año}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`);
    }, 
    downloadFileSeguroPendiente: async (marca, modelo, año, usoUnidadId, string) =>{
        return await axios.get(`/functions/GetExportVehiculosPendientesSF?Marca=${marca}&Modelo=${modelo}&Year=${año}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`,{responseType: 'blob'});
    },
    downloadFileSeguroAutorizado: async (marca, modelo, año, usoUnidadId, string) =>{
        return await axios.get(`/functions/GetExportVehiculosAutorizadosSF?Marca=${marca}&Modelo=${modelo}&Year=${año}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`,{responseType: 'blob'});
    },
    getAllSeguroFinalAutorizado: async (page, rows, marca, modelo, año, usoUnidadId, string) => {
        return await axios.get(`/functions/GetAllVehiculosAutorizadosSF?page=${page}&Rows=${rows}&Marca=${marca}&Modelo=${modelo}&Year=${año}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`);
    }, 
    getById: async (id) => {
        return await axios.get(`/functions/GetByIdVehiculosPendientesSF?GeneralId=${id}`);
    }, 
    getAllSeguroProporcional: async (page, rows, marca, modelo, año, usoUnidadId, string) => {
        return await axios.get(`/functions/GetAllVehiculosProporcionalSF?page=${page}&Rows=${rows}&Marca=${marca}&Modelo=${modelo}&Year=${año}&UsoUnidadId=${usoUnidadId}&Buscar=${string}`);
    }, 
    AddSeguroFinal:async(data)=>{
        return axios.post('/functions/AddSeguroFinal',data);
    },
    getByIdAutorizado: async (id) => {
        return await axios.get(`/functions/GetByIdVehiculosAutorizadosSF?GeneralId=${id}`);
    }, 
    getByIdProporcional: async (id) => {
        return await axios.get(`/functions/GetByIdVehiculosProporcionalSF?GeneralId=${id}`);
    }, 
}

export default SeguroFinalService