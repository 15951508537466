import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import HistorialEtapa from '../../Pages/CambioEtapa/historialEtapa';
import HistorialService from '../../Services/ControlEtapa/historialEtapas';

const useHistorialCtrlEtapa = (setLoading,setModalMessage,modalMessage) => {
    const columnsHistorialEtapa = [
        { field: 'fecha', headerName: 'Fecha y hora', width: 230 },
        { field: 'usuario', headerName: 'Usuario', width: 230 },
        { field: 'motivo', headerName: 'Motivo', width: 330 },
    ];
    const dataHistory = [
        {fechaHora:"28 de abril 2023 12:30",user:"Admin",motivo:"Cambio de etapas"},
        {fechaHora:"27 de abril 2023 12:30",user:"Ventas",motivo:"Cambio de etapas"},
        {fechaHora:"26 de abril 2023 12:30",user:"Compras",motivo:"Cambio de etapas"},
        {fechaHora:"25 de abril 2023 12:30",user:"Admin",motivo:"Cambio de etapas"},
    ];
    const [dataHistorialEtapa, setDataHistorialEtapa] = useState([]);
    const [filtroProductoId, setfiltroProductoId] = useState("");
    const [filtroTipocompraId, setfiltroTipocompraId] = useState("");
    const [filtroFecha, setFiltroFecha] = useState("");
    const [filtroEtapaId, setFiltroEtapaId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    useEffect(() => {      
        if(filtroProductoId !== "" && filtroTipocompraId !== ""){
          handleGetAllHistory();
        }       
    }, [filtroProductoId,filtroTipocompraId,filtroFecha])
    
    const handleGetAllHistory = async () =>{                
        setLoading(true)
        await HistorialService.getHistoryOrdenEtapa(currentPage,10,filtroProductoId,filtroTipocompraId,filtroFecha)
        .then(res => {
          setDataHistorialEtapa(res.data.data);
          setPageCount(res.data.totalPages);          
          setLoading(false);
        }).catch(e => {
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })                        
    }
    const handlePageClick = (event) => {
      setCurrentPage(event.selected + 1);
      handleGetAllHistory();
    };
  return {
    columnsHistorialEtapa,
    dataHistorialEtapa,
    setDataHistorialEtapa,
    setfiltroProductoId,
    setfiltroTipocompraId,
    setFiltroFecha,
    setFiltroEtapaId,
    pageCount,
    currentPage,
    handlePageClick,
    handleGetAllHistory
  }
}

export default useHistorialCtrlEtapa