import { Link, Navigate, redirect, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Usuario from '../../Services/Users/Usuarios';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import AuthLogin from '../../Utils/AuthLogin';
import { rolSession } from '../../Redux/Slice/rolSlice';
import { useDispatch } from 'react-redux';
import { AuthExpireSesion } from '../../Utils/functions';
import { ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Module } from '../../Auth/Authorization';

const auth = new AuthLogin();
const usuario = auth.getUser()
const useClave = (toast) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [clave, setClave] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const [dataToken, setdataToken] = useState()
    const claveLogin = auth.getSesionValue("claveIS");
    const idRole = auth.getSesionValue("idRole");
    const userId = auth.getSesionValue("userId");

    var inicioSesion;

    let permisosMenu = [
        { modulo: "inventario", value: ValidatePermissionById(1, Module.Inventario) ?? false },
        { modulo: "seguro", value: ValidatePermissionById(1, Module.Seguros) ?? false },
        { modulo: "admin", value: ValidatePermissionById(1, Module.PanelAdministrativo) ?? false },       
        { modulo: "garantias", value: ValidatePermissionById(1, Module.Garantia) ?? false },
        { modulo: "cobranza", value: ValidatePermissionById(1, Module.Cobranza) ?? false },
        { modulo: "gestoria", value: ValidatePermissionById(1, Module.Gestoria) ?? false },
        { modulo: "procesocierre", value: ValidatePermissionById(1, Module.ProcesoDeCierre) ?? false },
        { modulo: "datamovil", value: ValidatePermissionById(1, Module.DatamovilSystems) ?? false },
    ]
    var permisosMenuInicio = []
    useEffect(() => {
        if (!state?.esLogin && !AuthExpireSesion()) {
            validateHeader()
            return;
        }
    }, [])

    const validateForm = Yup.object({
        clave: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
    });
    const onSubmit = async (values) => {

        if (claveLogin == values.clave) {
            auth.setIngresarSistema(true);
            validateClaveGo(claveLogin, idRole);
        } else {
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "La clave no coincide." })
        }
    }

    const { values, errors, handleChange, touched, handleSubmit } = useFormik({
        initialValues: {
            clave: "",
        },
        validationSchema: validateForm,
        onSubmit
    })
    const validateClaveGo = async (claveLogin, idRole) => {

        let data = {
            claveLogin: claveLogin
        }
        setLoading(true);
        await Usuario.validateClave(data)
            .then(res => {
                setLoading(false);
                if (res.data.data.ban == 0) {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje })
                } else {
                    iniciarSessionGo(claveLogin, idRole,)
                }
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    };
    const iniciarSessionGo = async (claveLogin, idRole) => {
        let data = {
            claveLogin: claveLogin,
            RolId: await auth.getSesionValue("idRole"),
            UserId: await auth.getSesionValue("userId")
        }
        inicioSesion = true;
        setLoading(true);
        await Usuario.userAllRoll(data)
            .then(async res => {
                setdataToken(res.data.data);
                let token = res.data.data;
                permisosMenuInicio = [
                    { modulo: "inventario", value: getModule(token, Module.Inventario) ?? false },
                    { modulo: "seguro", value: getModule(token, Module.Seguros) ?? false },
                    { modulo: "admin", value: getModule(token, Module.PanelAdministrativo) ?? false },
                    { modulo: "garantias", value: getModule(token, Module.Garantia) ?? false },
                    { modulo: "cobranza", value: getModule(token, Module.Cobranza) ?? false },
                    { modulo: "gestoria", value: getModule(token, Module.Gestoria) ?? false },
                    { modulo: "procesocierre", value: getModule(token, Module.ProcesoDeCierre) ?? false },
                    { modulo: "datamovil", value: getModule(token, Module.DatamovilSystems) ?? false },
                ]
                await auth.handleAuthSesionLogin(res.data.data)
                validateHeader();
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
        // 
    };
    const validateHeader = () => {        
        if (inicioSesion) {
            if (permisosMenuInicio.length > 0) {
                permisosMenuInicio.forEach((item) => {
                    if (item.value) {
                        navigate(`/${item.modulo}`);
                        throw "";
                    }
                })
            } else {
                return navigate(`/404`);
            }
        } else {
            if (permisosMenu.length > 0) {
                permisosMenu.map((item) => {
                    if (item.value) {
                        navigate(`/${item.modulo}`);
                        throw "";
                    }
                })
            } else {
                return navigate(`/404`);
            }
        }

    }
    const getModule = (token, idModule) => {
        var menuJson = auth.parseJwt(Object.entries(token)[1][1]);
        let Modulo = JSON.parse(menuJson.modulo);
        return Modulo.find(x => x.IdModulo === idModule);
    }
    return {
        clave,
        loading,
        modalMessage,
        setModalMessage,
        validateForm,
        onSubmit,
        navigate,
        values,
        errors,
        handleChange,
        touched,
        handleSubmit,
        validateClaveGo,
        iniciarSessionGo,
        validateHeader
    }

}
export default useClave