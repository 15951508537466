import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';

const useInstaladoEnInventario = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'fechaEnganche', headerName: 'Fecha de enganche', width: 250 },
        { field: 'fechaContrato', headerName: 'Fecha de contrato', width: 250 },
        { field: 'ubicacion', headerName: 'Ubicación', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'statusDatamovil', headerName: 'Estatus Datamovil', width: 200 },
        { field: 'producto', headerName: 'Producto', width: 200 },
        { field: 'nombreVersion', headerName: 'Version', width: 150 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    //
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUbicacion, setDataUbicacion] = useState([])
    const [claveUbicacion, setclaveUbicacion] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [dataStatus, setDataStatus] = useState([]);
    const [claveStatus, setClaveStatus] = useState("");
    const [item, setItem] = useState(null);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, TipoCompra, claveUbicacion,claveStatus, pageRows])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUbicacion()
        handleGetAllStatusDatamovil();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await InstalacionDatamovilService.getAllVehiculosInventario(page, pageRows, str,TipoCompra,claveUbicacion,claveStatus)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleUploadFile = async () => {
        if (item !== null) {
            navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
                state: { ...item, emisionPoliza: true,instalacionDM:true },
            });
          } else {                        
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione un registro para continuar.")}</div>);
          }
       
    }
    const handleDownloadFile = async () => {
        setLoading(true);
        await InstalacionDatamovilService.downloadVehiculosInventario(str,TipoCompra,claveUbicacion,claveStatus)
            .then(res => {       
                
                var FileSaver = require('file-saver');          
                FileSaver.saveAs(res.data, `VehiculoDeInventario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);

                setLoading(false);
            }).catch(e => {                
                setLoading(false);
            })
    }
    const handleGetAllUbicacion = async () => {
        await SeguroSinCotizarService.getlAllUbicacion()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.nombre
                    })
                });
                setDataUbicacion(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllStatusDatamovil = async () => {
        await SeguroSinCotizarService.getlAllStatusDatamovil()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.statusDatamovilId,
                        label: item.statusDatamovil
                    })
                });
                setDataStatus(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/inventario/vehiculo/datadocs/${value.generalId}`, {
            // state: { ...value, action: 1 },
            state: { ...value, instalacionDatamovil: true },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setclaveUbicacion,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        DataUbicacion,
        dataStatus,
        setClaveStatus,
        handleDownloadFile
    }
}

export default useInstaladoEnInventario