import { useState } from "react";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";
import { getOnlyDateFormat } from '../../Utils/functions';

const useInfoVehiculo = () => {
    const initial = {
        vin: '',
        placas: '',
        nombre: '',
        color: '',
        nombreMarca: '',
        kilometraje: '',
        nombreModelo: '',
        procedenciaAuto: '',
        nombreVersion: '',
        numMotor: '',
        nombreYear: '',
        fechaAdquisicion: '',
        claves: '',
        numContrato: '',
        numPlaca: '',
    };

    const [data, setData] = useState(initial);

    const getInfo = async (generalId, vin) => {
        await ValuacionesServices.getInfoVehiculo(generalId, vin)
        .then(resp => {
            //resp.data.fechaEntrega = getOnlyDateFormat(resp.data.fechaEntrega);
            setData(resp.data);
            console.log(resp.data);
        })
        .catch(err => {
            console.error(err);
        });
    }

    const handleClaves = () => {

    }

    return {
        getInfo,
        data,
        handleClaves
    }

}

export default useInfoVehiculo;