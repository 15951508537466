import { Form, Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useState } from 'react';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import {FormatNumberMoney} from "../../../Utils/functions";

const ModalCostos = ({ setisOpenModalcostos, setValue, value, costoProp, isOpen }) => {  
    
    let tenenciaValue = value?.costoPropietarioValue
    let initial = {
        costos: value?.registroCostos ?? [],        
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [rowsCostos, setRowsCostos] = useState([]);
    const [dataTipoCosto, setdataTipoCosto] = useState([]);
    const [ValueIva, setValueIva] = useState(0);
    const [loading, setLoading] = useState(false);
    let newItem = { tipoCostoId: "", subtotal: "", iva: ValueIva, total: "" };
    const validate = Yup.object({
        costos: Yup.array().of(
            Yup.object().shape({
                tipoCostoId: Yup.string()
                    .required("El campo es requerido."),
                subtotal: Yup.string()
                    .required("El campo es requerido.")
                    .test({
                        name: "is-decimal",
                        skipAbsent: true,
                        test(value, ctx) {
                            if (/^\d+\.\d{0,2}$/.test(value) || /^\d/.test(value)) return true
                            return ctx.createError({message: "ingrese un monto valido"})
                        }
                    }),
                iva: Yup.number().test({
                    name: 'is-Iva',
                    skipAbsent: true,
                    test(value, ctx) {
                        if (value < 0)
                            return ctx.createError({ message: 'ingrese un valor positivo' })                        
                        if (value >= 100) 
                            return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })                        
                        return true
                    }
                }),
                total: Yup.string()
                    .required("El campo es requerido."),
            })
        )
    });

    useEffect(() => {       
        handleGetParameters(); 
        handleGetTipoCosto();
        if (value.registroCostos?.length > 0) {            
            setRowsCostos(value.registroCostos);
            setInitialValue({ ...initialValue, costos: value.registroCostos })
        }
    }, [isOpen])

    const handleGetTipoCosto = async () => {
        // setLoading(true);
        await Catalogo.getdataTipocosto("Adquisición")
            .then(res => {
                let NewData = [];                
                res.data.data?.map(item => {
                    // if (item?.status === true) {
                    NewData.push({
                        value: item.tipoCostoId,
                        label: item.nombre
                    })
                    // }
                });
                setdataTipoCosto(NewData);
            // setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetParameters = async ()=>{
        setLoading(true);
        await IntelimotorAPI.getValorParametro("IVA")
          .then(res => {                    
            setValueIva(res.data.data.valor);            
            setLoading(false);
          }).catch(e => {      
            setLoading(false);
          })
      }
   
    const handleCostos = (items) => {      
        let total = 0;
        items?.map(x => {
            if (x.total !== "")
                total = total + parseFloat(x.total)
        })
        return total.toFixed(2);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                // handleAddEdit(values);
                let cambioPro = (handleCostos(values.costos) + parseFloat(value.costo)) * parseFloat(value.costoPropietarioValue)         
                let costoM =  handleCostos(values.costos); //+ (values.costo !== undefined ? parseFloat(value.costoPrealta): parseFloat(value.costoVehiculoValue) )                    
                setValue({ ...value, registroCostos: values.costos ?? [], costo: costoM,cambioPropietario:parseFloat(tenenciaValue)/100*values.costos[0].total});
                setisOpenModalcostos(false)
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Form>
                        <section className='m-4' style={{ width: '60rem' }}>
                            <div className='d-flex flex-column fs-4'>
                                <span className='fs-5 mb-2'><strong>Registrar costos</strong></span>
                                <span style={{ fontSize: '12px' }}>El registro de los costos permite llevar un control detallado de los gastos relacionados con los vehículos.</span>
                            </div>
                            <section>
                                <table className="table">
                                    <thead>
                                        <tr className='custom-table'>
                                            <th scope="col">Valor</th>
                                            <th scope="col">Subtotal</th>
                                            <th scope="col">IVA %</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbl-custome'>
                                        {
                                            values.costos.length > 0 ?
                                                values.costos?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td key={index} style={{ width: '180px' }}>
                                                                <SelectField label="" name={`costos.${index}.tipoCostoId`} items={dataTipoCosto} onChange={(event) => {
                                                                    setFieldValue(`costos.${index}.tipoCostoId`, event.value);
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <TextField label="" name={`costos.${index}.subtotal`} isMoney={true} type="text" icon="bi bi-currency-dollar" holder="Escribe" onChange={(event) => {
                                                                    let value = event.target.value;
                                                                    setFieldValue(`costos.${index}.subtotal`, event.target.value);                                                                    
                                                                    let totalIva = (values.costos[index].iva / 100) * value;
                                                                    
                                                                    setFieldValue(`costos.${index}.total`, value == "" ? "" : parseFloat(totalIva.toFixed(2)) + parseFloat(value));
                                                                }} />
                                                            </td>
                                                            <td>
                                                                {/* <input type="text" name="iva" className='form-control' readOnly value={`${item.iva}%`} /> */}
                                                                <TextField label="" name={`costos.${index}.iva`} type="number" holder="Escribe" onChange={(event) => {
                                                                    let value = event.target.value;
                                                                    setFieldValue(`costos.${index}.iva`, value);
                                                                    let totalIva = (value / 100) * values.costos[index].subtotal;
                                                                    
                                                                    setFieldValue(`costos.${index}.total`, value == "" ? "" : (totalIva + parseFloat(values.costos[index].subtotal)).toFixed(2));
                                                                    // handleChaneSubtotal(index, event, values)
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <TextField label="" isMoney={true} disabled name={`costos.${index}.total`} icon="bi bi-currency-dollar" type="text" holder="Escribe" />
                                                            </td>
                                                            <td>
                                                                <div className='d-flex justify-content-center align-items'>
                                                                    <fieldset className='btn btn mx-1' disabled={item.fijo} onClick={() => {
                                                                        // setRowsCostos(items => {
                                                                        //     return items.filter((_, i) => i !== index)
                                                                        // })
                                                                        setInitialValue({ ...values, costos: values.costos.filter((_, i) => i !== index) })
                                                                    }}><i className="bx bxs-trash-alt"></i></fieldset>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </section>
                            <div className='my-2 col-3' onClick={(e) => { setInitialValue({ ...values.costos, costos: [...values.costos, newItem] }) }}><strong>+Agregar costo</strong></div>
                            {/* setRowsCostos([...values.costos, newItem]); */}
                            <div className='d-flex justify-content-between py-2 px-2' style={{ backgroundColor: '#f5f5f5' }}>
                                <span>Total</span>
                                <span>${FormatNumberMoney(handleCostos(values.costos))}</span>
                            </div>
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setisOpenModalcostos(false) }}>Cancelar</fieldset>
                                <button className='btn mx-1 px-5' >Aceptar</button>
                            </section>
                        </section>
                    </Form>
                </>
            )
            }
        </Formik>
    )
}

export default ModalCostos;

