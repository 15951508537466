import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveDataDocs } from '../../Redux/Slice/navigateSliceDDocs';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section } from '../../Auth/Authorization';

const SubmenuDatadocs = () => {
  const { value } = useSelector((state) => state.navigaSlicedataDocs);
  const dispatch = useDispatch();

  let Reglas = [
    { value: 2, label: "Documentos por etapa",active:ValidatePermissionById(3, Section.DocumentosPorEtapa)},
    { value: 3, label: "Documentos por usuario",active:ValidatePermissionById(3, Section.DocumentosPorUsuario) },
  ];
  const navigate = useNavigate();
  const [defaultRegla, setDefaultRegla] = useState(1);

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveDataDocs(value));
  }
  const handleVigate = (value) => {

    if (ValidatePermissionById(3, Section.Folder) && value === 1) {
      navigate(`/admin/datadocs/folder`);
    }
    if (ValidatePermissionById(3, Section.DocumentosPorEtapa) && value === 2) {
      navigate(`/admin/datadocs/etapa`);
    }
    if (ValidatePermissionById(3, Section.DocumentosPorUsuario) && value === 3) {
      navigate(`/admin/datadocs/usuario`);
    }
    if (ValidatePermissionById(3, Section.ArchivosEliminados) && value === 4) {
      navigate(`/admin/datadocs/eliminado`);
    }
  }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.Folder)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Folder</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.Folder)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Documentos por etapa</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.Folder)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Documentos por usuario</button>
          </li>
        </ValidatePermission>
        {/* <li className="nav-item" role="presentation">
          <select className={`text-dark select-custome ${value == 2 || value == 3 ? "nav-link tab-active" : ''}`} style={{ height: '42px' }} value={value} onChange={(e) => { handleSetStateTab(e.target.value); setDefaultRegla(e.target.value) }}>
            <option value="1">Seleccione</option>
            {
              Reglas?.map((item, index) => {
                return <option key={index} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </li> */}
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ArchivosEliminados)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 4 && "tab-active"}`} value={4} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Archivos eliminados</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubmenuDatadocs