import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { Toaster } from 'react-hot-toast';

const BusquedaAvanzadaModal = ({ setIsOpenModal, isOpenModal,handleBusqueda}) => {
   const refFormik = useRef();

   const inicial = {
      page: 1,
      rows: 10,
      buscar: "",
      tipoSiniestroId: "",
      estatus: "",
      strAvanzado: "",
      fechaInicio: "",
      fechaFin: "",
      concluido: false,
      busquedaAvanzada: true
   };

   const [initialValue, setInitialValue] = useState(inicial);

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
                  success: {
                     style: {
                        background: '#47a066',
                        color: '#FFFF',
                        borderLeft: '10px solid #2f7246'
                     },
                  },
                  error: {
                     style: {
                        background: '#d53f3f',
                        color: '#FFFF',
                        borderLeft: '10px solid #ac241a'
                     },
                  },
            }}
         />

         <Formik
            enableReinitialize={true}
            innerRef={refFormik}
            initialValues={initialValue}
            isInitialValid={false}
            onSubmit={(values) => {
                  handleBusqueda(values)
                  setIsOpenModal(false)
            }}
         >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                  <Modal isOpen={isOpenModal} color='#fff'>
                     <div className='row mx-2' style={{ width: '20rem' }}>
                        <Form>
                              <div>
                                 <h5 className='p-2 fw-bold'>Búsqueda avanzada</h5>
                              </div>
                              <div style={{ 'border': 'solid', 'borderBottomWidth': '1px', 'borderTopWidth': '0px', 'marginBottom': '10px', 'borderBottomColor': '#CFCFCF' }}></div>
                              <div className='mt-4'>
                                 <div className='inner-addon right-addon'>
                                    <i className="glyphicon fas fa-search"></i>
                                    <TextField id="buscarAvanzada" name="strAvanzado" type="text" holder="Buscar..." onChange={(event) => {
                                          setFieldValue("strAvanzado", event.target.value);
                                    }} />
                                 </div>
                                 <div className='mt-4'>
                                    <TextField id="fechaInicio" label="Fecha de inicio" name="fechaInicio" type="date" onChange={(event) => {
                                          setFieldValue("fechaInicio", event.target.value);
                                    }} />
                                 </div>
                                 <div className='mt-4'>
                                    <TextField id="fechaFin" label="Fecha de fin" name="fechaFin" type="date" onChange={(event) => {
                                          setFieldValue("fechaFin", event.target.value);
                                    }} />
                                 </div>
                                 <div className='mt-4'>
                                    <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                          setFieldValue("concluido", event.target.value);
                                    }} />
                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Concluido</label>
                                 </div>
                              </div>
                              <div className='p-3 mt-2 mb-4'>
                                 <div className="d-flex gap-2">
                                    <button className='btn btn-outline-secondary col cols-6' type='reset' onClick={() => { setIsOpenModal(false) }}>
                                          Cancelar
                                    </button>
                                    <button className='btn btn col cols-6' type="submit" disabled={!values.concluido}>
                                          Confirmar
                                    </button>
                                 </div>
                              </div>
                        </Form>
                     </div>
                  </Modal>
            )}
         </Formik>
      </>
   );
}

export default BusquedaAvanzadaModal