import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable';
import { Field, Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import Select from 'react-select'
import RowPages from '../../../Components/RowPages';

const AccesoriosSeguimiento = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    // Initial Values
    const initial = {
        buscar: "",
        codProceso: "",
        codConcepto: "",
        fechaInicio: "",
        fechaFin: "",
        page: 1,
        rows: 10
    };
    

    const iConcepto = [
        { value: "Cobrar Seguro", label: "Cobrar Seguro" },
        { value: "Cobrar Refrendo", label: "Cobrar Refrendo" },
        { value: "Cobrar Deducible", label: "Cobrar Deducible" },
        { value: "Cobrar Corralon", label: "Cobrar Corralon" },
        { value: "Cobrar Multa", label: "Cobrar Multa" },
        { value: "Cobrar Reparaciones/Mantenimiento", label: "Cobrar Reparaciones/Mantenimiento" },
        { value: "Cobrar Constancia Registro Vehicular", label: "Cobrar Constancia Registro Vehicular" },
        { value: "Cobrar Cambio de Propietario", label: "Cobrar Cambio de Propietario" },
        { value: "Cobrar Tenencia", label: "Cobrar Tenencia" },
        { value: "Cobrar Replaqueo", label: "Cobrar Replaqueo" }
    ];

    const iProceso = [
        { value: "Seguro", label: "Seguro" },
        { value: "Gestoría", label: "Gestoría" },
        { value: "Cobranza", label: "Cobranza" },
        { value: "Proceso de Cierre", label: "Proceso de Cierre" },
        { value: "Garantía", label: "Garantía" }
    ];

    // States
    const [concepto, setConcepto] = useState(iConcepto);
    const [proceso, setProceso] = useState(iProceso);
    const [data, setData] = useState({
        totalRows: 0,
        data: [],
        page: 1
    });
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [currenPage, setCurrenPage] = useState(1);
    const [loading, setLoading] = useState(false);

    // Columns
    const columns = [
        { field: 'vin', headerName: 'VIN' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'cliente', headerName: 'Cliente' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'costoAccesorio', headerName: 'Costo de accesorio' },
        { field: 'montoFinanciar', headerName: 'Monto a financiar' },
        { field: 'fechaFinanciamiento', headerName: 'Fecha del financiamiento' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'año', headerName: 'Año' }

    ];
    const [pageRows, setpageRows] = useState(10);

    useEffect(() => {
        seguimientoDatamovil();
    }, []);

    useEffect(() => {
        initial.rows = pageRows;
        seguimientoDatamovil();
    }, [pageRows]);

    // Function to fetch data
    const seguimientoDatamovil = async (values = initial) => {
        try {
            setLoading(true);
            await CobranzaService.seguimientoAccesorios(values)
                .then(response => {
                    const processedData = response.data.map(data => ({
                        vin: data.vin,
                        contrato: data.contrato,
                        cliente: data.cliente,
                        proceso: data.proceso,
                        valorFinanciado: formatMoney(data.valorFinanciado),
                        marca: data.nombreMarca,
                        modelo: data.nombreModelo,
                        año: data.nombreYear,
                        fechaFinanciamiento: data.fechaFinanciamiento,
                        costoAccesorio: formatMoney(data.costoAccesorio),
                        montoFinanciar: formatMoney(data.montoFinanciar),
                        concepto: data.concepto
                    }));

                    let totalR = response.data.map(data => ({
                        totalRows: data.totalRows,
                    }));

                    let letRestotal = 0

                    if (totalR.length > 0) {
                        console.log(totalR, "-----")
                        letRestotal = totalR[0].totalRows
                    }

                    setData({ ...response.data, data: processedData, totalRows: letRestotal });
                    console.log(data)
                })

        } catch (error) {
            console.error("No e posible ver los registros", error);
        } finally {
            setLoading(false);
        }
    };

    // Format functions
    const formatMoney = value => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value ?? 0);
    const dateFormat = d => {
        const date = new Date(d + "Z");
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} hrs.`;
    };

    const handlePageClick = event => {
        const page = event.selected + 1;
        setCurrenPage(page);
        seguimientoDatamovil({ ...initial, page });
    };

    const getReporteExcel = async values => {
        setLoading(true);
        try {
            const res = await CobranzaService.reporteAccesorios(values);
            const FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `AccesoriosFinanciado_${new Date().toLocaleDateString()}.xlsx`);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };


    return (
        <Formik innerRef={refFormik} enableReinitialize initialValues={initial}>
            {({ setFieldValue, values }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {modalMessage.type === 1 && <ErrorModal modalMessage={modalMessage} setModalMessage={setModalMessage} />}
                        {modalMessage.type === 2 && <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} />}
                    </Modal>

                    <section className='pb-4 pt-2'>
                        <div className='mx-4 my-4'>
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <strong>Financiamiento de accesorios</strong>
                                </div>
                                <div className="col-auto">
                                    <button className='btn' onClick={() => getReporteExcel(values)}>Exportar Excel</button>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-2 inner-addon right-addon">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <i className="glyphicon fas fa-search"></i>
                                    <Field
                                        name="buscar"
                                        className="form-control mb-4"
                                        placeholder="Buscar"
                                        onChange={e => {
                                            setFieldValue("buscar", e.target.value);
                                            seguimientoDatamovil({ ...values, buscar: e.target.value });
                                        }}
                                    />
                                </div>

                                <div className="col-md-2">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <Select options={proceso}
                                        onChange={e => {
                                            setFieldValue("codProceso", e.value)
                                            seguimientoDatamovil({ ...values, codProceso: e.label });
                                        }
                                        }
                                        placeholder='Proceso' />

                                </div>
                                <div className="col-md-2">
                                    <div style={{ paddingTop: '21px' }}></div>
                                    <Select options={concepto}
                                        onChange={e => {
                                            setFieldValue("codConcepto", e.value)
                                            seguimientoDatamovil({ ...values, codConcepto: e.label });
                                        }
                                        }
                                        placeholder='Concepto' />

                                </div>
                                <div className="col-md-2">
                                    <TextField
                                        name="fechaInicio"
                                        label="Fecha inicio"
                                        type="date"
                                        onChange={e => {
                                            setFieldValue("fechaInicio", e.target.value)
                                            seguimientoDatamovil({ ...values, fechaInicio: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <TextField
                                        name="fechaFin"
                                        label="Fecha fin"
                                        type="date"
                                        onChange={e => {
                                            setFieldValue("fechaFin", e.target.value)
                                            seguimientoDatamovil({ ...values, fechaFin: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <RowPages
                                setpageRows={setpageRows}
                            />
                            <div className="mt-4">
                                <DataTable
                                    column={columns}
                                    data={data?.data}
                                    currentPage={currenPage - 1}
                                    paginate={true}
                                    pageCount={data.totalRows / 10}
                                    handlePageClick={handlePageClick}
                                    type={2}
                                />
                            </div>
                        </div>
                    </section>
                </>
            )}
        </Formik>
    );
};

export default AccesoriosSeguimiento;
