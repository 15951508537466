import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useOrdenServicio from '../../../../Hooks/DatamovilSystems/RevisionPasos/useOrdenServicio';

const CardOrdenServicio = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object({
        // departamento: Yup.string().required("El departamento es requerido"),
        // motivoServicio: Yup.string().required("El motivo de servicio es requerido"),
        // fechaSolicitud: Yup.date().required("La fecha de solicitud es requerida"),
        // horaSolicitud: Yup.string().required("La hora de solicitud es requerida"),
        // comentarios: Yup.string().optional(),
    });

    const {
        initialValue,
        loading,
        setLoading,
        submitForm,
        departamentos,
        motivoServicio
    } = useOrdenServicio(handleConcluido)

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseOrdenServicio"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseOrdenServicio"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6>
                                    <strong>Orden de servicio</strong>
                                    {/* <div>En proceso</div> */}
                                </h6>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseOrdenServicio">
                            <div className='divider'></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitForm(values)}
                            >
                                {({ isValid, values, setFieldValue, errors  }) => (
                                    <Form id="ordenServicioForm">

                                        <div className="row mt-2 mb-2">
                                            <div className="col-12">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.concluido}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <SelectField
                                                    label="Departamento"
                                                    name="departamentoId"
                                                    items={departamentos}
                                                    onChange={(event) => setFieldValue("departamentoId", event.value)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <SelectField
                                                    label="Motivo de servicio"
                                                    name="motivoSercicioId"
                                                    items={motivoServicio}
                                                    onChange={(event) => setFieldValue("motivoSercicioId", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Fecha de solicitud"
                                                    name="fechaSolicitud"
                                                    type="date"
                                                    onChange={(event) => setFieldValue("fechaSolicitud", event.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Hora de solicitud"
                                                    name="horaSolicitud"
                                                    type="time"
                                                    onChange={(event) => setFieldValue("horaSolicitud", event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <TextField
                                                    label="Comentarios"
                                                    name="comentarios"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => setFieldValue("comentarios", event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 d-flex align-items-center">
                                            <Field type="checkbox" name="concluido" className="form-check-input me-2" onClick={(event) => {
                                                setFieldValue("concluido", event.target.value);
                                            }} />
                                            <label className="form-check-label">Concluir solicitud de servicio</label>
                                        </div>
                                        <div className="text-end">
                                            <small className="text-muted">Inicio de proceso: {initialValue.fechaAlta} | Fin de proceso: {initialValue.fechaConcluido}</small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardOrdenServicio;
