import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    KilometrajeActual: "",
    CarnetServicioId: "",
    FolioCarnet: "",
    ClasificacionFallaId: "",
    Gravedad: "",
    TipoReporteId: "",
    FolioGarantia: "",
    GeneralId: "",
    LlamadaId: "",
    FechaReporte: "",
    Ejecutivo: "",
    TipoGarantiaId: "",
    TipoGarantia: "",
    TipoReporte: "",
    ReparacionUnidad: "",
    StatusGarantiaId: "",
    EstatusGarantia: "",
    UltimaActualizacion: "",
    EstatusIncidencia: "",
    StatusKilometraje: "",
    Carnet: "",
    StatusCarnet: "",
    Falla: "",
    GravedadId: "",
    TallerAutorizadoId: "",
    FechaCita: "",
    EstatusCitaGarantia: "",
    OrigenGruaId: "",
    TallerAutorizado: "",
    VehiculoMover: "",
    GarantiaId: "",
    BloqueoId: ""
}

export const GarantiaSlice = createSlice({
    name: 'GarantiaSlice',
    initialState,
    reducers: {
        setGarantiaSlice: (state, action) => {
            return {
                ...state,
                KilometrajeActual: action.payload.KilometrajeActual,
                CarnetServicioId: action.payload.CarnetServicioId,
                FolioCarnet: action.payload.FolioCarnet,
                ClasificacionFallaId: action.payload.ClasificacionFallaId,
                Gravedad: action.payload.Gravedad,
                TipoReporteId: action.payload.TipoReporteId,
                FolioGarantia: action.payload.FolioGarantia,
                GeneralId: action.payload.GeneralId,
                LlamadaId: action.payload.LlamadaId,
                FechaReporte: action.payload.FechaReporte,
                Ejecutivo: action.payload.Ejecutivo,
                TipoGarantiaId: action.payload.TipoGarantiaId,
                TipoGarantia: action.payload.TipoGarantia,
                TipoReporte: action.payload.TipoReporte,
                ReparacionUnidad: action.payload.ReparacionUnidad,
                StatusGarantiaId: action.payload.StatusGarantiaId,
                EstatusGarantia: action.payload.EstatusGarantia,
                UltimaActualizacion: action.payload.UltimaActualizacion,
                EstatusIncidencia: action.payload.EstatusIncidencia,
                StatusKilometraje: action.payload.StatusKilometraje,
                Carnet: action.payload.Carnet,
                StatusCarnet: action.payload.StatusCarnet,
                Falla: action.payload.Falla,
                GravedadId: action.payload.GravedadId,
                TallerAutorizadoId: action.payload.TallerAutorizadoId,
                FechaCita: action.payload.FechaCita,
                EstatusCitaGarantia: action.payload.EstatusCita,
                OrigenGruaId: action.payload.OrigenGruaId,
                TallerAutorizado: action.payload.TallerAutorizado,
                VehiculoMover: action.payload.VehiculoMover,
                GarantiaId: action.payload.GarantiaId,
                BloqueoId: action.payload.BloqueoId,
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
        clearGarantia: (state) => {
            return {
                ...state,
                KilometrajeActual: "",
                CarnetServicioId: "",
                FolioCarnet: "",
                ClasificacionFallaId: "",
                Gravedad: "",
                TipoReporteId: "",
                FolioGarantia: "",
                GeneralId: "",
                LlamadaId: "",
                FechaReporte: "",
                Ejecutivo: "",
                TipoGarantiaId: "",
                TipoGarantia: "",
                TipoReporte: "",
                ReparacionUnidad: "",
                StatusGarantiaId: "",
                EstatusGarantia: "",
                UltimaActualizacion: "",
                EstatusIncidencia: "",
                StatusKilometraje: "",
                Carnet: "",
                StatusCarnet: "",
                Falla: "",
                GravedadId: "",
                TallerAutorizadoId: "",
                FechaCita: "",
                EstatusCitaGarantia: "",
                OrigenGruaId: "",
                TallerAutorizado: "",
                VehiculoMover: "",
                GarantiaId: "",
                BloqueoId: ""
            }
        }
    }
})

export const { setGarantiaSlice, setEditable,clearGarantia } = GarantiaSlice.actions

export default GarantiaSlice.reducer