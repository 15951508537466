import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayGarantia: "",
    hourGarantia: "",
    sucursalId: "",
    sucursalName: "",
    CalendarioIdGarantia: "",
}

export const GarantiasSlice = createSlice({
    name: 'GarantiasSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayGarantia: action.payload.day,
                hourGarantia: action.payload.hour,
                CalendarioIdGarantia: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayGarantia: "",
                hourGarantia: "",
                CalendarioIdGarantia: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = GarantiasSlice.actions

export default GarantiasSlice.reducer