import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate } from 'react-router-dom';
import { FormatNumberMoney2, message } from '../../../Utils/functions';
import Spinner from '../../../Components/Loadig';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';

const DesagruparModalConfim = ({ isOpen, setIsOpen,item,toast,habdleGetAll }) => {
    const refbtn = useRef();
    const navigate = useNavigate();
    let initial = {
        gestoriaPagoRefrendoPlacasGrupoId: item?.gestoriaPagoRefrendoPlacasGrupoId ?? "",
        vin: item?.vin ?? "",
        gestoriaPagoRefrendoPlacasSeguimientoProcesoPagoId:item?.gestoriaPagoRefrendoPlacasSeguimientoProcesoPagoId ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
       
    const handleAddEdit = async (item) => {
        handleDesagrupar(item)
    }
    const handleDesagrupar = async ({gestoriaPagoRefrendoPlacasGrupoId,vin,gestoriaPagoRefrendoPlacasSeguimientoProcesoPagoId}) => {
        setLoading(true);
        await PagoRefrendoPlacasService.DesagruparPagoRefrendoPlacas(gestoriaPagoRefrendoPlacasSeguimientoProcesoPagoId,vin,gestoriaPagoRefrendoPlacasGrupoId)
            .then(res => {
                let {ban,mensaje} = res.data.data;                
                if (ban === 1) {
                    setIsOpen(false);
                    toast.success(message("¡Correcto!", mensaje));
                    habdleGetAll(1);
                    setLoading(false);
                } else {
                    setIsOpen(false);
                    toast.error(message("¡Error!", mensaje));
                    setLoading(false);
                }
            }).catch(e => {
                setIsOpen(false);
                setLoading(false);
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}            
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-2 mx-4' style={{ width: '28rem' }}>
                                <Form>
                                    <span className='fs-5'><strong>Desagrupar vehículos</strong></span>
                                    <div className="mb-3">
                                        <label style={{ fontSize: '13px' }} className="col-form-label">¿Estas seguro que deseas desagrupar el vehículo seleccionado?</label>
                                    </div>                                  
                                    <section className='my-3 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Desagrupar</button>
                                    </section>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )

            }

        </Formik>
    )
}

export default DesagruparModalConfim