import React, { useRef, useEffect, useState } from "react";
import ListingPageComponent from "./ListingPageComponent";
import IntelimotorAPI from "../../Services/Catalogos/Intelimotor";


function ListingPageContainer(props) {
  const listInnerRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
//   const [prevPage, setPrevPage] = useState(0);
  const [data, setData] = useState([]);
  const [nextPage, setNextPage] = useState(true);
let Id = '5cc3eab4-bc60-40e9-9e21-c7b8b6be56f2';
  useEffect(() => {
    handleGetHistoryById(Id,currentPage,10);
  }, [currentPage]);
  
  const handleGetHistoryById = async (Id, page, rows) => {    
   if(nextPage){
    await IntelimotorAPI.getSyncHistoryById(Id, page, rows)
    .then(res => {
      let dat = res.data.data;    //   setPrevPage(res.data.currentPage);
      setData([...data, ...dat]);
    //   setData(newData);
    //   setCurrentPage(res.data.currentPage);
      setNextPage(res.data.hasNextPage);
    //   setLoading(false);
    }).catch(e => {
      
    //   setLoading(false);
    })
   }
    //   setLoading(true);
      
  

  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  return (
    <ListingPageComponent
      onScroll={onScroll}
      listInnerRef={listInnerRef}
      data={data}
    />
  );
}

export default ListingPageContainer;
