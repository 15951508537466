import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { Section, SubModule } from '../../Auth/Authorization'
import Modal from '../../Components/Modal/Modal'
import DataTable from '../../Components/datatable/DataTable'
import useSeguroAutorizadoPoliza from '../../Hooks/Seguros/useSeguroAutorizadoPoliza'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import Spinner from '../../Components/Loadig'
import RowPages from '../../Components/RowPages'
import img from '../../Assets/img/configDatadocs.png'
import useConfigDataDocs from '../../Hooks/AdminDataDocs/useConfigDataDocs'
import ConfigFichero from './ConfigFichero'
import AddDocumento from './AddDocumento'

const ConfigDataDocs = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        DataFichero,
        setClaveFichero,
        setpageRows,
        setClaveEtapa,
        DataEtapa,
        handleGetAllEtapaPorFichero,
        setOpenModal,
        openModal,
        habdleGetAll,
        ClaveFichero,
        ClaveEtapa,
        setSelectedFichero,
        SelectedFichero,
        setSelectedEtapa,
        SelectedEtapa,
        item,
        setItem,
        handleDelete,
        setReload,
        setStr
    } = useConfigDataDocs(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <span className='mt-2'>Para configurar los documentos, selecciona el módulo y etapa correspondiente.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.DataDocsConfig, SubModule.DataDocsConfig).Ver}>
                <section className='headerTab pb-4'>
                    <div className='row col-12 px-4'>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <span>Fichero</span>
                            <Select options={DataFichero} className='col-12' onChange={(e) => { setSelectedFichero(e); handleGetAllEtapaPorFichero(e.value); }} placeholder="Fichero" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <span>Etapa</span>
                            <Select options={DataEtapa} value={SelectedEtapa} className='col-12' onChange={(e) => { setSelectedEtapa(e); setClaveEtapa(e.value) }} placeholder="Etapa" />
                        </div>
                    </div>
                </section>
                {
                    (ClaveFichero !== "" && ClaveEtapa !== "") &&
                    <div className='pt-4'>
                        <ConfigFichero
                            item={SelectedFichero}
                            toast={toast}
                            handleGetAll={() => { setReload(true) }}
                        />
                        <section className='mx-4 d-flex align-items-end'>
                            <span className='fs-5 pe-2'><strong>Configuración documentos para: {SelectedEtapa.label}</strong></span>
                            <ValidatePermission isActive={ValidatePermissionById(4, Section.DataDocsConfig, SubModule.DataDocsConfig).Agregar}>
                                <button className='btn' onClick={() => { setOpenModal(true); setItem({ action: "Add", ficheroId: ClaveFichero, etapaId: ClaveEtapa }) }}>Agregar documento</button>
                            </ValidatePermission>
                        </section>
                        <section className='mt-5 mx-4'>
                            <div className='row col-12'>
                                <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
                                    <i className="glyphicon fas fa-search"></i>
                                    <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                                </div>
                            </div>

                        </section>
                        <RowPages
                            setpageRows={setpageRows}
                        />
                        <div className='mx-4 table-responsive'>
                            <DataTable
                                column={columns}
                                data={data}
                                editable={ValidatePermissionById(4, Section.DataDocsConfig, SubModule.DataDocsConfig).Actualizar}
                                // deleteable={ValidatePermissionById(4, Section.DataDocsConfig, SubModule.DataDocsConfig).ActivarDesactivar}
                                handleEdit={(item) => { handleEdit(item) }}
                                // handleDelete={handleDelete}
                                isStatus={true}
                                handleChange={handleDelete}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                        </div>
                    </div>
                }
                {
                    (ClaveFichero === "" || ClaveEtapa === "") &&
                    <section className='d-flex flex-column align-items-center mt-5'>
                        <img src={img} style={{ width: '23rem' }} />
                    </section>
                }
            </ValidatePermission>
            {
                openModal &&
                <AddDocumento
                    isOpen={openModal}
                    setIsOpen={setOpenModal}
                    item={item}
                    toast={toast}
                    handleGetAll={() => { habdleGetAll(1) }}
                />
            }
        </>
    )
}

export default ConfigDataDocs