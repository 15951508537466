import React from 'react'
import { useState, useEffect } from 'react';
import Llamada from '../../Services/Cartera/Llamadas';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { useDispatch } from 'react-redux';
import useLlamada from '../../Hooks/Incidencias/useLlamada';
import useGarantia from '../../Hooks/Incidencias/useGarantia';


const ConfirmarModal = ({ modalMessage, setModalMessage, handleActualizar }) => { // Prop => AuthorizationType; 1 Garantia, 2 Grua;
    const dispatch = useDispatch();

    const [cita, setCita] = useState({});
    const [loading, setLoading] = useState(false);
    const useLlamda = useLlamada();
    const Garantia = useGarantia();

    useEffect(() => {
        setCita(modalMessage.data);
    }, []);

    const handleRechazar = () => {
        let res = {}
        setModalMessage({ isOpen: false, type: 5, title: '', message: '' })
    }

    /*
    AutorizarGarantia => 1
    AutorizarGrua => 2
    */

    const handleAutorizar = () => { // 1 
        setLoading(true);

        let payload = {
            accion: modalMessage.authorizationType === 1 ? "AutorizarGarantia" : "AutorizarGrua",
            generalid: cita.generalId,
            garantiaid: cita.garantiaId
        }

        Llamada.AutorizarRechazar(payload).then(res => { //Actualiza la llamada
            if (modalMessage.Origin !== "Listado") {
                if (modalMessage.authorizationType === 2) {
                    Garantia.handlerGetGruas(cita.garantiaId)
                } else {
                    useLlamda.GetGarantiaByLlamadaIdasync()
                }
            }
            setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

    }

    return (
        <div>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
            </section>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>{modalMessage.title}</strong></h6>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <div className="row">
                            <small id="AT_TxtBasicModalMenssage">¿Estás seguro de marcar como concluida la inspección rápida?</small>
                        </div>
                    </div>
                    <div className="col col-12">
                        <div className="row">
                            <small id="AT_TxtBasicModalMenssage">Al concluir la etapa, se cerrará el registro y no se podrán hacer cambios</small>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleRechazar() }}>Cancelar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleActualizar(modalMessage.data) }}>Confirmar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ConfirmarModal