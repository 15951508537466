import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import EntregaService from '../../Services/Inventario/EntregaService';
import { act } from 'react-dom/test-utils';

const useVehiculoEntrega = (action) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 200 },
        { field: 'encargadoEntrega', headerName: 'Encargado de entrega', width: 200 },
        { field: 'strFechaEntrega', headerName: 'Fecha y hora de entrega', width: 200 },
        { field: 'nombreUbicacion', headerName: 'Ubicación', width: 150 },        
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'entrega', headerName: 'Entrega', width: 100 },        
        { field: 'estatus', headerName: 'Estatus', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },  
    ];
    

    const columnsPendientes = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 200 },
        { field: 'strFechaCancelacionEntrega', headerName: 'Fecha de cancelacion de entrega', width: 200 },
        { field: 'strFechaEntrega', headerName: 'Fecha y hora de entrega', width: 200 },
        { field: 'nombreUbicacion', headerName: 'Ubicación', width: 150 },        
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'estatus', headerName: 'Estatus', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [dataPendientes, setDataPendientes] = useState([])
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //Filtro
    const [tipoCompra, settipoCompra] = useState([]);
    const [DataUbicacion, setDataUbicacion] = useState([])
    const [claveTipoCompra, setclaveTipoCompra] = useState("");
    const [claveUbicacion, setClaveUbicacion] = useState("");
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const [pageRows, setpageRows] = useState(10);

    const [seguimientoEntrega, setSeguimientoEntrega] = useState([]);

    useEffect(() => {
        if(action ==1){
            console.log(action);
            habdleGetAll(currenPage);
        }
            
        else if(action==2){
            console.log(action);
            handleGetAllPendientes(currenPage)
        }
            
        setCurrenPage(1);
    }, [str, claveTipoCompra, claveUbicacion, pageRows, fechaInicio])
    useEffect(() => {
        handleGetAllSelects();
        handleGetSeguimientoGestoria();
    }, [])

    const handleGetAllSelects = async () => {
        setLoading(true)
        const [ubicacion, Tipocompra] = await Promise.all([handleGetAllUbicacion(), handleGetTipoCompra()]).finally(() => {
            setLoading(false)
        });
    }

    const handleGetSeguimientoGestoria = async (id) => {
        setSeguimientoEntrega([{
            value: "",
            label: "Seleccione"
        },
        {
            value: "1",
            label: "Realizada"
        },
        {
            value: "2",
            label: "Cancelada"
        }
        ]);
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await EntregaService.getAllListaEntrega(page, pageRows, claveTipoCompra,claveUbicacion, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllPendientes = async (page) => {
        setLoading(true);
        await EntregaService.getAllListaEntregaPendiente(page, pageRows, claveTipoCompra,claveUbicacion, str,fechaInicio, fechaFin)
            .then(res => {
                console.log(res.data.data)
                setDataPendientes(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handleGetAllUbicacion = async () => {
        await SeguroSinCotizarService.getlAllUbicacion()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.nombre
                    })
                });
                setDataUbicacion(NewData);
            }).catch(e => {
    
            })
    }
        
    const handleUploadFile = async (item, action) => {
        navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
            state: {...item,entregaRealizada:action,entrega:true},
        });
       

    }

    const handleCapturaCancelacion = (value) => {
        navigate(`/inventario/entrega/entregacancelada/${value.generalId}`, {
            state: { ...value,porCancelar:true},
        });
    }

    const handleSaveEntregaRealizada = async (data) => {
        setLoading(true);
        try {
            let result = await EntregaService.SaveEntregaRealizada(data);

            console.log(result.data);
            setLoading(false);

            return result.data.data;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return error;
        }
    }

    const handleSaveEntregaCancelada = async (data) => {
        setLoading(true);
        try {
            let result = await EntregaService.SaveEntregaCancelada(data);

            console.log(result.data);
            setLoading(false);

            return result.data.data;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return error;
        }
    }

    const handleSaveEntregaRechazada = async (data) => {
        setLoading(true);
        try {
            let result = await EntregaService.SaveEntregaRechazada(data);

            console.log(result.data);
            setLoading(false);

            return result.data.data;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return error;
        }
    }

    const handleSaveEntregaAutorizada = async (data) => {
        setLoading(true);
        try {
            let result = await EntregaService.SaveEntregaAutorizada(data);

            console.log(result.data);
            setLoading(false);

            return result.data.data;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return error;
        }
    }

    const habdleDownloadFile = async () => {
        setLoading(true);
        // await SeguroFinalService.downloadFileSeguroAutorizado(claveMarca, claveModelo, claveAÑo,claveUsoUnidad,str)
        //     .then(res => {       

        //         var FileSaver = require('file-saver');          
        //         FileSaver.saveAs(res.data, `SeguroFinalAutorizado-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        //         habdleGetAll(1);

        //         setLoading(false);
        //     }).catch(e => {                
        //         setLoading(false);
        //     })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    
    return {
        columns,
        columnsPendientes,
        data,
        dataPendientes,
        seguimientoEntrega,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataUbicacion,
        tipoCompra,
        setclaveTipoCompra,
        setClaveUbicacion,
        handleUploadFile,
        handleCapturaCancelacion,
        handleSaveEntregaAutorizada,
        handleSaveEntregaRechazada,
        handleSaveEntregaCancelada,
        handleSaveEntregaRealizada,
        setFechaInicio,
        setFechaFin
    }
}

export default useVehiculoEntrega