import React, { useEffect, useState } from 'react';
import Garantias from '../../Services/Garantias/Garantias';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams  } from 'react-router-dom';

const useGarantia = () => {
    const [str, setStr] = useState("");
    const [dataVin, setDataVin] = useState({ generalId: "",serieDatamovil:"", fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "",numSolicitud:"", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" });
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [infoTabs, setInfoTabs] = useState(false);
    const [busquedaRealizada, setBusquedaRealizada] = useState(false);

    /*Se agrego lo nuevo para la llamada*/
    const { id } = useParams(); //Dato de busqueda por idVIN de la informacion de la garantia
    

    useEffect(() => {
        handleGetContract(id);
    },[id])

    const handleGetContract = async (str) => {
        //Validacion de espaciado
        if (str.trim().length == 0) {
            //No se realiza busqueda
            setBusquedaRealizada(false);
            setLoading(false);
            return false;
        }

        setLoading(true);
        setBusquedaRealizada(true);

        //Llama a los servicios para la busqueda
        await Garantias.getContract(str?.trim())
            .then(async res => {
                if (res.data.statusCode === 204) {
                    const msgNotVin = "El VIN ingresado no se encuentra relacionado a ninguna incidencia.||Revisa el VIN o No. de contrato de tu vehículo e intenta reingresarlo nuevamente para tener acceso a las incidencias registradas.";
                    setStr("");
                    setModalMessage({ ...modalMessage, isOpen: true, type: 3, title: 'No se encontró el vehículo', message: msgNotVin });
                    setDataVin({ ...dataVin, serieDatamovil : "",generalId: "", fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "",numSolicitud:"", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" })
                    return false;
                }
                console.log("UseLLamada 1")
                //agregamos al array los datos de la garantia
                setDataVin({
                    ...dataVin,
                    generalId: res.data.data.generalId,
                    fechaEntrega: res.data.data.fechaEntrega,
                    vin: res.data.data.vin,
                    nombreMarca: res.data.data.nombreMarca,
                    nombreModelo: res.data.data.nombreModelo,
                    nombreVersion: res.data.data.nombreVersion,
                    nombreYear: res.data.data.nombreYear,
                    color: res.data.data.color,
                    kilometraje: res.data.data.kilometraje,
                    procedenciaAuto: res.data.data.procedenciaAuto,
                    numMotor: res.data.data.numMotor,
                    numContrato: res.data.data.numContrato,
                    numSolicitud: res.data.data.numSolicitud,
                    cliente: res.data.data.cliente,
                    fechaUltimoPago: res.data.data.fechaUltimoPago,
                    producto: res.data.data.producto,
                    usoUnidad: res.data.data.usoUnidad,
                    serieDatamovil : res.data.data.serieDatamovil
                })

                setLoading(false);
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })
    }
    
    return {
        dataVin,
        setDataVin,
        loading,
        setLoading,
        setModalMessage,
        modalMessage,
        setStr,
        str,
        busquedaRealizada,
        infoTabs,
    }

}

export default useGarantia