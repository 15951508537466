import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import { TextAreaField } from '../../Components/TextAreaField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const ModalReglaEtapa = ({ isOpen, setIsOpen, item, handleGetAll, message, toast }) => {
    const refbtn = useRef();
    let initial = {
        reglasNegocioId: item?.reglasNegocioId ?? "",
        tipoReglaId: item?.tipoReglaId ?? "",
        productoId: item?.productoId ?? "",
        tipoCompraId: item?.tipoCompraId ?? "",
        etapaOrigenId: item?.etapaOrigenId ?? "",
        regla: item?.regla ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataEtapa, setDataEtapa] = useState([]);
    useEffect(() => {
        handleGetAllEtapa();
    }, [])

    const validate = Yup.object({
        regla: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        // .matches(
        //     "^[a-z0-9.]+@[a-z]+\.[a-z]{2,4}((,\s?){1}[a-z0-9.]+@[a-z]+\.[a-z]{2,4})*$",
        //     "ingrese correos separados por coma"
        // ),
        etapaOrigenId: Yup.string()
            .required("El campo es requerido."),

    });

    const handleGetAllEtapa = async () => {
        await EtapaService.getAllEtapaById(item.productoId, item.tipoCompraId)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaDestinoId,
                        label: item.nombre
                    })
                });
                setDataEtapa(NewData);
                // setDataTable(res.data.data); 
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Correcto.!", e.response.data.message));
                else
                    toast.error(message("¡Correcto.!", e.message));
            })

    }
    // const message = (message) =>{
    //     return <p><strong>¡Correcto.!</strong> <br />{message}</p>
    // }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        if (item.action === "edit") {
            await EtapaService.UpdateReglasEtapas(value)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Correcto.!", e.response.data.message));
                    else
                        toast.error(message("¡Correcto.!", e.message));
                })
        } else {
            await EtapaService.AddReglasEtapas(value)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message(res.data.data.mensaje));
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Correcto.!", e.response.data.message));
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        toast.error(message("¡Correcto.!", e.message));
                    // setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })

        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5 mb-3'><strong>{item.action === "edit" ? 'Editar Regla' : 'Nueva Regla'}</strong></span>
                                <span style={{ fontSize: '12px' }}>A continuación se muestran los campos para configurar la nueva regla.</span>
                            </div>
                            <Form>
                                <div className="mb-3 row my-4">
                                    {/* {
                                        item !== null &&
                                        <div className="mb-3 col-12">
                                        <label className="col-sm-6 col-form-label">Etapa: {item?.etapa}</label>                                        
                                    </div>
                                    }
                                   */}
                                    <div className="mb-3 col-12">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                            <Field className="form-check-input" type="checkbox" name="status" />
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField className="col-6" label="Etapa" name="etapaOrigenId" items={dataEtapa} onChange={(event) => {
                                            setFieldValue("etapaOrigenId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextAreaField label="Regla" className="form-control col-12" rows="3" name="regla" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' ref={refbtn} type="submit" disabled={!(isValid)}>{item.action === "add" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalReglaEtapa