const Comentario = ({ comentario }) => {

    const getAvatar = (name) => {
        const names = name.split(" ");
        let avatar = '';

        if(names.length > 1){
            avatar = names[0].substring(0,1).trim()+names[1].substring(0,1).trim();
        }else{
            avatar = names[0].substring(0,2).trim();
        }
        return avatar;
    }

    return (
        <div className="row comment py-3">
            <div className="col-2">
                <span className="avatar">{ getAvatar(comentario?.usuarioComento) }</span>
            </div>
            <div className="col-10">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-7">
                                <span className="fw-bold">{ comentario?.usuarioComento }</span>
                            </div>
                            <div className="col-5">
                                <span className="folio">ID { comentario?.folioId }</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <span className="comment-content">{ comentario?.comentario }</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Comentario;