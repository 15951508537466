import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayGrua: "",
    hourGrua: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdGrua: "",
}

export const GruasSlice = createSlice({
    name: 'GruasSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayGrua: action.payload.day,
                hourGrua: action.payload.hour,
                calendarioIdGrua: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayGrua: "",
                hourGrua: "",
                calendarioIdGrua: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = GruasSlice.actions

export default GruasSlice.reducer