import React from 'react'
import Select from 'react-select';

const TablaReglasRol = ({ setStr, setModuloId, dataModulo, handleSaveRol, dataTable, handleChangeProps, id }) => {
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    return (
        <>
            <div className='mt-2'>
                <section className='row'>
                    <div className='col-lg-6 col-sm-12 d-flex'>
                        <div className="col-6 mx-2 form-group inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className="col-6 mx-2">
                            <Select
                                options={dataModulo} className='col-12 mx-1'
                                placeholder="seleccione un submodulo"
                                onChange={(e) => { setModuloId(e.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-12 mx-2 d-flex justify-content-end">
                        <fieldset className='btn px-4' onClick={handleSaveRol} disabled={id != '0' ? false : true}>Guardar rol</fieldset>
                    </div>
                </section>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr className='custom-table'>
                            <th scope="col" style={{ width: '300px' }}>Acciones</th>
                            <th scope="col" style={{ width: '150px' }}>Agregar</th>
                            <th scope="col" style={{ width: '150px' }}>Ver</th>
                            <th scope="col" style={{ width: '150px' }}>Actualizar</th>
                            <th scope="col" style={{ width: '150px' }}>Activar/Desactivar</th>
                            <th scope="col" style={{ width: '150px' }}>Aprobar</th>
                            <th scope="col" style={{ width: '150px' }}>Mantener</th>
                            <th scope="col" style={{ width: '150px' }}>Liberar</th>
                            <th scope="col" style={{ width: '150px' }}>Concluir</th>
                            <th scope="col" style={{ width: '150px' }}>Rechazar</th>
                            <th scope="col" style={{ width: '150px' }}>Historial</th>
                            <th scope="col" style={{ width: '150px' }}>Descarga</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataTable.length > 0 ?
                                dataTable?.map((item, index) => {
                                    return <tr key={index}>
                                        <th scope="row">{item.seccion}</th>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.agregar} value={"agregar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.ver} value={"ver"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.actualizar} value={"actualizar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.activarDesactivar} value={"activarDesactivar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.aprobar} value={"aprobar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.mantener} value={"mantener"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.liberar} value={"liberar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.concluir} value={"concluir"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.rechazar} value={"rechazar"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.historial} value={"historial"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch mx-1">
                                                <input className="form-check-input" type="checkbox" checked={item.descarga} value={"descarga"} onChange={(e) => { handleChangeProps(e.target.checked, item, e.target.value) }} />
                                            </div>
                                        </td>
                                    </tr>
                                }) : <tr><td colSpan="12" className="text-center">No se encontrarón registros</td></tr>
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TablaReglasRol