import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Formik } from 'formik'
import { SelectField } from '../../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import BasicModal from '../../../Components/Modal/BasicModal';
import PagoUnidadesSeguimiento from './PagoUnidadesSeguimiento';
import SeguimientoExternosPendiente from './SeguimientoExternosPendiente';
import SeguimientoInventarioPendiente from './SeguimientoInventarioPendientes';

const EncabezadoPendientesPago = (props) => {
    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    

    let initial = {}
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 1, Origin: "Listado" });

    const optionsTypes = [{ value: 1, label: 'Vehículos inventario'}, { value: 2, label: 'Vehículos externos' }];


    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <BasicModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <PagoUnidadesSeguimiento />
            <div className='mx-4 mt-3'>
                <strong className='mt-2'>Vehículos pendientes de pago</strong>
            </div>
            <section className='mx-4 mt-2'>
                A continuación selecciona el tipo de vehículo que deseas gestionar
            </section>
            <div className='my-3' style={{'boxShadow': '0px 7px 10px #D8D8D8'}}>
                <div className='mx-2 row col-12'>
                    <div className='col-12 col-lg-3'>
                        <SelectField id={'tipoVehiculo'}
                            name={'tipoVehiculo'}
                            items={optionsTypes}
                            onChange={(event) => {
                                setFieldValue('tipoVehiculo', event.value);
                        }} />
                    </div>
                </div>
            </div>
            {
                (values.tipoVehiculo !== null && values.tipoVehiculo === 1) &&
                <SeguimientoInventarioPendiente />
            }
            {
                (values.tipoVehiculo !== null && values.tipoVehiculo === 2) &&
                <SeguimientoExternosPendiente />
            }
        </>
            )
            }
        </Formik>
    )
}

export default EncabezadoPendientesPago