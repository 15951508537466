import axios from '../../Utils/ApiService';

const PendienteEnviarAcendesService = {
    getAllPendienteEnviarAcendes: (page, rows, string) => {
        return axios.get(`/gestoria/GetAllPendienteEnviarAcendes?page=${page}&Rows=${rows}&Buscar=${string}`);
    },
    ReenviarAcendes: (data) => {
        return axios.post('/gestoria/ReenviarPagoGestoriaAcendes', data);
    },
}
export default PendienteEnviarAcendesService;