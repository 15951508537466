import axios from '../../Utils/ApiService';

const Llamada = {
    addLlamada: (data) => {
        return axios.post(`/cartera/crearllamada`, data);
    },
    getLlamadaById: async (llamadaId) => {
        return await axios.get(`/cartera/llamadabyid?llamadaid=${llamadaId}`);
    },
    addGarantia: async (payload) => {
        return await axios.post(`/cartera/crearGarantia`, payload);
    },
    GetGarantiaByLlamadaId: async (payload) => {
        return await axios.get(`/cartera/getGarantiaByLlamadaId?llamadaid=${payload}`);
    },
    GetEmpresas: async () => {
        return await axios.get(`/catalogs/GetCSEmpresa`);
    },
    GetEstadoMx: async () => {
        return await axios.get(`/catalogs/EdoMexico?page=1&rows=63`);
    },
    GetMunicipio: async (payload) => {
        if(payload){
            return await axios.get(`/catalogs/MunicipioByEdo?EdoId=${payload}`);
        }else{
            return[]
        } 
    },
    GetColonia: async (payload) => {
        if(payload){
            return await axios.get(`/catalogs/ColoniaByMunicipio?MunicipioId=${payload}`);
        }else{
            return[]
        }
    },
    AddGruaLlamada: async (payload) => {
        return await axios.post(`/cartera/crearGrua`, payload);
    },
    GetGruabyBarantiaId: async (payload,vin) => {
        return await axios.get(`/cartera/gruabybarantiaid?buscar=${payload}&vin=${vin}`);
    },
    GetGruaByResguardoIdOrigen: async (payload) => {
        return await axios.get(`/cartera/gruaByResguardoIdOrigen?buscar=${payload}`);
    },
    UpdateGruabyBarantiaId: async (payload) => {
        return await axios.post(`/cartera/actualizarGrua`, payload);
    },
    updateGarantia: async (payload) => {
        return await axios.post(`/cartera/actualizarGarantia`, payload);
    },
    AutorizarCita: async (payload) => {
        return await axios.post(`/cartera/autorizarCita`, payload);
    },
    AddMantenimiento: async (payload) => {
        return await axios.post(`/cartera/crearMantenimiento`, payload);
    },
    AutorizarMantenimiento: async (payload) => {
        return await axios.post(`/cartera/updateestatusmantenimiento?mantenimientoid=${payload.mantenimientoid}&autorizacion=${payload.autorizacion}`);
    },
    getMantenimientos: async (generalId, curr) => {
        return await axios.get(`/cartera/mantenimientosbygeneralid?generalid=${generalId}&rows=10&page=${curr}`);
    },
    getTalleresSlice: async () => {
        return await axios.get(`/catalogs/TallerAutorizado`);
    },
    crearCita: async (payload) => {
        return await axios.post(`/cartera/crearcita?ubicacionid=${payload.ubicacionid}&fecha=${payload.fecha}&calendarioid=${payload.calendarioid}&calendariotipoid=${payload.calendariotipoid}&relacionid=${payload.relacionid}`);
    },
    getAdeudoCredito: async (payload) => {
        return await axios.get(`cartera/garantiaCreditoAcendesInfoGet?numeroSolicitud=${payload.numeroSolicitud}&vin=${payload.vin}&contrato=${payload.contrato}`);
    },
    AutorizarRechazar: async (payload) => {
        if(payload.type === 6){
            return await axios.post(`/cartera/updateestatuscitagarantiagrua?accion=${payload.accion}&generalid=${payload.generalid}&cierreContratoId=${payload.garantiaid}&motivocancelacionid=${payload.motivocancelacionid}&comentarioscancelacion=${payload.comentarioscancelacion}`);
        }else if (payload.motivocancelacionid) {
            return await axios.post(`/cartera/updateestatuscitagarantiagrua?accion=${payload.accion}&generalid=${payload.generalid}&garantiaid=${payload.garantiaid}&motivocancelacionid=${payload.motivocancelacionid}&comentarioscancelacion=${payload.comentarioscancelacion}`);
        } else {
            return await axios.post(`/cartera/updateestatuscitagarantiagrua?accion=${payload.accion}&generalid=${payload.generalid}&garantiaid=${payload.garantiaid}&comentarioscancelacion=${payload.comentarioscancelacion}`);
        }
    },
    getTipoGarantia: async () => {
        return await axios.get(`/catalogs/TipoGarantia`);
    },
    getOrigenGrua: async () => {
        return await axios.get(`/catalogs/GetOrigenGrua`);
    },
}

export default Llamada