import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RolesService from '../../Services/Roles/RolesService';

const useValuacionDetalle = () => {
  const navigate = useNavigate();

    return {
        navigate
    }
}

export default useValuacionDetalle