import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextAreaField } from '../../../Components/TextAreaField';
import CambioEtapaInventarioService from '../../../Services/Inventario/CambioEtapaInventarioService';
import Spinner from '../../../Components/Loadig';

const DeleteCostosModal = ({ isOpen, setIsOpen, item, handleGetAll }) => {
    const refbtn = useRef();

    let initial = {
        generalId: item?.generalId,
        comentarios: "",
        costosGeneralesId: item?.costosGeneralesId,
        vin:item?.vin
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setloading] = useState(false)

    const validate = Yup.object({
        comentarios: Yup.string()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        setloading(true)
        await CambioEtapaInventarioService.DeleteCostosGeneral(item)
            .then(res => {
                setIsOpen(false);
                setloading(false)
                handleGetAll();
            }).catch(e => {
                setIsOpen(false);
                setloading(false)
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex' style={{ width: '30rem' }}>
                            <span className='fs-6'><strong>Desactivar costos</strong></span>
                        </div>
                        <div className='my-4'>
                            <span style={{ fontSize: '13px' }}>Al desactivar el costo seleccionado, cambiara en el calculo total del vehículo.</span>
                        </div>
                        <Form>
                            <div className="mb-3 mt-4 row">
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <button className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-1 px-5' type="submit">Continuar</button>
                            </section>
                        </Form>

                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default DeleteCostosModal