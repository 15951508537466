import React, { useEffect, useState } from "react";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "./Componentes/ContratoHeaderCard";
import { useLocation, useParams } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from './Componentes/ModalConfirm'
import RevisionStepper from "./Componentes/RevisionStepper";
import useRevisionPasos from "../../../Hooks/DatamovilSystems/RevisionPasos/useRevisionPasos";
import CardGeneral from "./AgendarCita/CardGeneral";
import AgendarCitaCard from "./AgendarCita/AgendarCita";
import CardInicioServicio from "./InicioServicio/CardInicioServicios";
import CardOrdenServicio from "./OrdenServicio/CardOrdenServicio";

const OrdenServicio = React.forwardRef(({ ...props }, ref) => {
    const { id } = useParams();

    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useRevisionPasos(toast);


    return (
        <>
            <RevisionStepper
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />

            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={null} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                
                <CardOrdenServicio handleConcluido={handleChangeStatusBtn} />

                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Registro de gastos"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }
            </div>
        </>
    )
});

export default OrdenServicio;