import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import CobranzaService from '../Services/Cobranza/Cobranza';
import { FormatFecha } from '../Utils/functions';
import { message } from '../Utils/functions';

const TablaAnidada = ({
    title,
    state,
    data,
    column,
    addColumns,
    buttonText,
    handleClick,
    children,
    childColumn,
    childData,
    childCommentColumn,
    moreDirections,
    interacciones,
    handleMoreDirections,
    childRegistroColumn,
    registroColumnText,
    childActionColumns,
    handleRegistro,
    handleChange,
    setLoading,
    toast
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const columnsNumber = columns.length + 1
    const columnsNumberFinal = columnsNumber + addColumns

    const [filteredData, setFilteredData] = useState([])
    const [pruebafilteredData, setPruebaFilteredData] = useState([])


    useEffect(() => {
        setColumns(column);
        if (data === null)
            setRows([]);
        else
            setRows(data);
    }, [data])

    useEffect(() => {
        try {
            getAllData()
        } catch (error) {
            alert("error")
            setLoading(false)
        }
    }, [rows])

    const handleToggle = (id) => {
        setExpandedRows(prevExpandedRows => {
            if (prevExpandedRows.includes(id)) {
                return prevExpandedRows.filter(rowId => rowId !== id); // Remove id
            } else {
                return [...prevExpandedRows, id]; // Add id
            }
        });
    };

    const getAllData = async () => {
        setLoading(true)
        if (rows.length > 0) {
            let AllLogs = await AddDate()
            for (const [index, item] of rows.entries()) {
                let data = await handleFilteredData(item, index)
                if (data.length === 0) {
                    setPruebaFilteredData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[index] = data;
                        return updatedArray;
                    });
                }
                for (const newItem of data) {
                    const interaccion = await AddInteracciones(newItem)
                    const validacion = await AddValidation(newItem)

                    newItem.interacciones = interaccion
                    newItem.validacion = validacion
                    newItem.comentariosUI = newItem.comentario
                    let newFecha = findMostRecentDateById(AllLogs, newItem.idcontacto_acendes)
                    newFecha === 0 ? newItem.fecha = "" : newItem.fecha = FormatFecha(newFecha)
                    setPruebaFilteredData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[index] = data;
                        return updatedArray;
                    });
                }
            }
        }
        setLoading(false)

    }

    const handleFilteredData = async (value) => {
        // let validCurp = validarCURP(value.curp_ref)
        // if (!validCurp){
        //     toast.error(message(`La persona ${value.nombre_ref} ${value.apellido_paterno_ref} no tiene curp valido.`));
        //     return []
        // }
        data = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            vin: state.vin,
            idpersona_acendes: value.idreferencia,
            tipo_persona: value.tipo,
            curp: value.curp_ref
        }
        let responseData = await CobranzaService.getDatosdeContactos(data)
            .then(res => {
                let data = res.data.responseData.datos_contacto || []

                let dataFormato = data.map((item) => {
                    if (item) {
                        let estatus = item.activo ? "Activo" : "Inactivo"
                        let isStatusText = item.activo ? "activo" : "inactivo"
                        return {
                            ...item,
                            message: item.comentario,
                            estatus: estatus,
                            status: item.activo,
                            value,
                            isStatusText: isStatusText,
                        }
                    }
                })
                return dataFormato
            })
        return responseData
    }

    const AddInteracciones = async (item) => {
        let data = {
            field: "id_contacto_ascendes",
            value: item.idcontacto_acendes
        }
        const response = await CobranzaService.getRowsIteractions(data)
        return response.data.responseData;
    };


    const AddValidation = async (item) => {
        let data = {
            contrato: state.contrato_id,
            id_contacto_ascendes: item.idcontacto_acendes
        }
        const response = await CobranzaService.getValidacionTipoResultado(data)
        const validado = response.data.validacion ? "Si" : "No"
        return validado;
    };

    const AddDate = async () => {
        const data = {
            "value": state.contrato_id
        }

        let responseData = await CobranzaService.getAllSeguimientoContactosLogs(data)

        return responseData.data.responseData
    }

    const findMostRecentDateById = (array, targetId) => {
        let newDate = array.filter(item => item.id_contacto_ascendes === targetId)

        if (newDate.length > 0) {
            let recentDate = newDate.reduce((recent, current) => {
                if (recent) {
                    const fecha = new Date(current.fecha) > new Date(recent.fecha) ? current : recent
                    return fecha
                }
            })
            return recentDate.fecha
        }
        else {
            return 0
        }

    };


    const handlegetPropertyValue = (object, field, handler) => {
        let value = object[field];
        return value
    }

    const validarCURP = (curp) => {
        return curp !== null && curp !== undefined && curp !== "";
        // // Expresión regular para validar el formato de la CURP
        // const regex = /^[A-Z\d]{18}$/

        // if (!regex.test(curp)) {
        //   return false; // El CURP no tiene el formato válido
        // }

        // return true;
    }


    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th></th>
                    {
                        columns?.map((item, ind) => {
                            // if (!item.hasOwnProperty('isActive') || item?.isActive) {
                            return <th key={ind} scope="col" style={{ width: `${item.width}px` }}>{item.headerName}</th>
                            // }
                        })
                    }
                    {
                        moreDirections &&
                        <th scope="col">Direcciones Adicionales</th>
                    }
                    {
                        interacciones &&
                        <th scope="col" style={{ width: `80px` }} >Interacciones</th>
                    }
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows.length > 0 ?
                    rows?.map((rowObj, index) => {
                        //aqui poner el childrenProps
                        if (rowObj.filter === false) {
                            rowObj.filter = true
                        }

                        const childWithProps = React.cloneElement(children, {
                            column: childColumn,
                            data: pruebafilteredData[index],
                            // commentColumn: childCommentColumn,
                            registroColumn: childRegistroColumn,
                            registroColumnText: registroColumnText,
                            actionColumns: childActionColumns,
                            handleRegistro: handleRegistro,
                            isStatus: true,
                            handleChange: handleChange
                        });


                        return <Fragment key={index}>
                            <tr>
                                <td>
                                    <button
                                        style={{
                                            background: "none",
                                            borderColor: "transparent",
                                            cursor: "pointer",
                                            color: "#1A3D7E",
                                        }}
                                        onClick={() => handleToggle(index)}
                                    >
                                        {expandedRows.includes(index) ? '-' : '+'}
                                    </button>
                                </td>
                                {
                                    columns?.map((column, index) => {
                                        return <td key={index}><span className=''
                                            style={
                                                { borderRadius: '5px', padding: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? '3px 10px 3px 10px' : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none' }}>
                                            {handlegetPropertyValue(rowObj, column.field)}</span>
                                        </td>
                                    })
                                }
                                {moreDirections &&
                                    <td>
                                        <span onClick={(e) => handleMoreDirections(rowObj)}
                                            style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            <i className='bx bx-receipt'></i> Ver Direcciones</span>
                                    </td>
                                }
                                {interacciones &&
                                    <td key={index}><span className=''
                                        style={
                                            { borderRadius: '5px' }}>
                                        {rowObj.interacciones}</span>
                                    </td>
                                }
                                {buttonText &&
                                    <td>
                                        <button
                                            className="btn"
                                            onClick={(e) => handleClick(rowObj)}
                                        >
                                            {buttonText}
                                        </button>
                                    </td>
                                }
                            </tr>
                            {
                                expandedRows.includes(index) && (
                                    <tr>
                                        <td colSpan={columnsNumberFinal}>
                                            {childWithProps}
                                        </td>
                                    </tr>
                                )
                            }
                        </Fragment>
                    })
                    : <tr><td colSpan={(columns?.length + 1)} className="text-center">No se encontrarón registros</td></tr>
                }
            </tbody >
        </table >
    );
};

export default TablaAnidada;