import React, { useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import { Section, SubModule } from '../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { read, utils, writeFile } from "xlsx";
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';

const ColoniasSepomex = () => {
    const [loading, setLoading] = useState(false);
    const [openModalExcel, setOpenModalExcel] = useState(false);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const fileRef = useRef();
    const [jsonData, setJsonData] = useState([]);
    const userId = sessionStorage.getItem('userId').trim()

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json = utils.sheet_to_json(worksheet);
            setJsonData(json);
        };
        if (file) {
            reader.readAsArrayBuffer(file);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        let json = JSON.stringify(jsonData);
        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": userId,
            "level": 0,
            "flag": true,
            "anyObject": json,
            "kvp": {}
        };
        setOpenModalExcel(false);
        // console.log(data);
        await CobranzaService.UpLoadNewColonias(data)
            .then(res => {
                // setOpenModalExcel(false);
                setIsFilePicked(false);
                setLoading(false);
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.message })
                setSelectedFile(null);
                setJsonData([]);
            }).catch(e => {
                setLoading(false);
                // setOpenModalExcel(false);
                setSelectedFile(null);
                setIsFilePicked(false);
                setJsonData([]);
                if (e.response !== undefined) {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                }
                else {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                }
            });
    }

    const changeHandler = () => {
        fileRef.current.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        );
    };

    const handleChangeFile = (event) => {
        handleFileUpload(event);
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    }

    const descargarPlantillaSepomex = () => {
        // Define la estructura de la plantilla (opcional: valores iniciales vacíos)
        const worksheetData = [
            ['Estado', 'Municipio', 'Cp', 'Colonia'], // Encabezados
            // Puedes agregar filas vacías o datos iniciales aquí si lo necesitas
        ];

        // Crea un worksheet (hoja de cálculo)
        const worksheet = utils.aoa_to_sheet(worksheetData);

        // Crea un workbook (libro de trabajo)
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'ColumnasSepomex');

        // Exporta el archivo Excel
        writeFile(workbook, 'PlantillaSepomex.xlsx');
    };

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={modalMessage.type < 4 ? '600' : '980'}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>

            <section className='mx-5 py-4'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Colonias Sepomex</strong></span>
                    <div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.ColoniasSepomex, SubModule.ColoniasSepomex).Ver}>
                            <button className="btn btn-outline mx-4 d-flex align-items-center" onClick={() => { setOpenModalExcel(true); }}>
                                <i className="ri-file-excel-2-line mx-2" style={{ color: "green", fontSize: "1.3rem" }}></i>{" "}
                                Carga Masiva
                            </button><br></br>
                            <button className="btn btn-outline mx-4 d-flex align-items-center" onClick={() => { descargarPlantillaSepomex() }}>
                                <i className="ri-file-excel-2-line mx-2" style={{ color: "green", fontSize: "1.3rem" }}></i>{" "}
                                Descargar Plantilla
                            </button>
                        </ValidatePermission>
                    </div>
                </div>
            </section>
            {openModalExcel && (
                <Modal isOpen={openModalExcel} color="#fff">
                    <div className="container-buttons">
                        <div className="d-flex justify-content-end">
                            <i className="icon ri-close-circle-line fs-3"
                                onClick={() => {
                                    setIsFilePicked(false);
                                    setOpenModalExcel(false);
                                    setSelectedFile(null);
                                }}></i>
                        </div>
                        <h4>
                            <strong>Subir Archivos</strong>
                        </h4>
                        <span>Selecciona el archivo que desees subir</span>
                        <div className="mt-5">
                            <div className="my-3">
                                <input className="form-control d-none" type="file" id="formFile" ref={fileRef} onChange={handleChangeFile} accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                                <section className="section-file d-flex flex-column" onClick={changeHandler}>
                                    <i className="icon-up ri-upload-cloud-2-line"></i>
                                    <span>{selectedFile?.name}</span>
                                </section>
                            </div>
                        </div>
                        <div className="d-flex justify-content-around mt-5">
                            <button className="btn col-5" type="button" disabled={!isFilePicked} onClick={handleSubmit}>
                                Aceptar
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default ColoniasSepomex