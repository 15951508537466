import React, { useEffect, useState } from "react";
import SiniestroDetalle from "../../../Seguro/Siniestros/Detalle/SiniestroDetalle";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "../ContratoHeaderCard";
import InformacionSeguroCard from "../InformacionSeguroCard";
import SegumientoProcesoDatamovil from "../Detalle/SeguimientoProcesoDatamovil";
import RecoleccionUnidadCard from "../Detalle/Registro/RecoleccionUnidadCard";
import ResguardoUnidadCard from "../Detalle/Registro/ResguardoUnidadCard";
import { useLocation, useParams } from "react-router-dom";
import { Normalize } from "../../../../Utils/functions";
import RegistroSiniestroRoboCard from "../Detalle/Registro/RegitroSiniestroRoboCard";
import useRegistroSiniestro from "../../../../Hooks/Inventario/Siniestro/useRegistroSiniestro";
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from '../Detalle/ModalConfirm'
import CardLiberacionDeUnidad from "./CardLiberacionDeUnidad";
import SiniestroCitaAcreditacionRoboCard from "./SiniestroCitaAcreditacionRoboCard";
import SiniestroAcreditacionRoboCard from "./SiniestroAcreditacionRoboCard";
import ContratoCard from "../../../../Components/ContratoCard";
const DetalleRegistroSiniestro = React.forwardRef(({ ...props }, ref) => {
    const { id } = useParams();
    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        codStatus,
        requiereCards,
        setRequiereCards
    } = useRegistroSiniestro(toast);



    return (
        <>
            <SiniestroDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />

            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoCard vin={state?.vin} />
                    </div>
                </div>
                <InformacionSeguroCard generalId={state?.generalId} />
                <SegumientoProcesoDatamovil Id={state?.siniestroId} />
                {
                    Normalize(state?.siniestroTipo) === "percance" &&
                    <>
                        <CardLiberacionDeUnidad requiereCards={setRequiereCards}/>

                        {
                            requiereCards.recoleccion &&
                            <RecoleccionUnidadCard handleConcluido={handleChangeStatusBtn} stepId={2} />
                        }
                        {
                            requiereCards.resguardo &&
                            <ResguardoUnidadCard handleConcluido={handleChangeStatusBtn} stepId={2} />
                        }
                    </>

                }
                {
                    Normalize(state?.siniestroTipo) === "robo" &&
                    <>
                        <SiniestroCitaAcreditacionRoboCard
                            handleConcluido={handleChangeStatusBtn}
                        />
                        {
                            codStatus === 2 &&
                            <SiniestroAcreditacionRoboCard
                                handleConcluido={handleChangeStatusBtn}
                            />
                        }

                    </>
                }

                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Registro de gastos"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }



            </div>
        </>
    )
});

export default DetalleRegistroSiniestro;
