import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cambioDevolucionBoletinadoId: "",
    cambioDevolucionId: "",
    cambioDevolucionBoletinadoMotivoId: "",
    cambioDevolucionBoletinadoMotivo: "",
    cambioDevolucionBoletinadoMotivoRechazoId: "",
    cambioDevolucionBoletinadoMotivoRechazo: "",
    cambioDevolucionBoletinadoMotivoAutorizacionId: "",
    cambioDevolucionBoletinadoMotivoAutorizacion: "",
    fechaAlta: "",
    usuarioAlta: "",
    usuarioAltaNombre: "",
    usuarioActualiza: "",
    usuarioActualizaNombre: "",
    fechaActualiza: "",
    cambioDevolucionBoletinadoStatusId: "",
    cambioDevolucionBoletinadoStatus: "",
    fechaSolicitud: "",
    fechaOperacion: "",
    fechaAutorizacion: "",
    fechaRechazo: "",
    comentariosSupervidor: "",
    comentariosEjecutivo: "",
    status: "",
    boletinado: "",
    comentariosRechazo: "",
    comentariosAutorizacion: ""
}

export const BoletinadoSlice = createSlice({
    name: 'BoletinadoSlice',
    initialState,
    reducers: {
        setBoletinadoSlice: (state, action) => {
            return {
                ...state,
                cambioDevolucionBoletinadoId: action.payload.cambioDevolucionBoletinadoId,
                cambioDevolucionId: action.payload.cambioDevolucionId,
                cambioDevolucionBoletinadoMotivoId: action.payload.cambioDevolucionBoletinadoMotivoId,
                cambioDevolucionBoletinadoMotivo: action.payload.cambioDevolucionBoletinadoMotivo,
                cambioDevolucionBoletinadoMotivoRechazoId: action.payload.cambioDevolucionBoletinadoMotivoRechazoId,
                cambioDevolucionBoletinadoMotivoRechazo: action.payload.cambioDevolucionBoletinadoMotivoRechazo,
                cambioDevolucionBoletinadoMotivoAutorizacionId: action.payload.cambioDevolucionBoletinadoMotivoAutorizacionId,
                cambioDevolucionBoletinadoMotivoAutorizacion: action.payload.cambioDevolucionBoletinadoMotivoAutorizacion,
                fechaAlta: action.payload.fechaAlta,
                usuarioAlta: action.payload.usuarioAlta,
                usuarioAltaNombre: action.payload.usuarioAltaNombre,
                usuarioActualiza: action.payload.usuarioActualiza,
                usuarioActualizaNombre: action.payload.usuarioActualizaNombre,
                fechaActualiza: action.payload.fechaActualiza,
                cambioDevolucionBoletinadoStatusId: action.payload.cambioDevolucionBoletinadoStatusId,
                cambioDevolucionBoletinadoStatus: action.payload.cambioDevolucionBoletinadoStatus,
                fechaSolicitud: action.payload.fechaSolicitud,
                fechaOperacion: action.payload.fechaOperacion,
                fechaAutorizacion: action.payload.fechaAutorizacion,
                fechaRechazo: action.payload.fechaRechazo,
                comentariosSupervidor: action.payload.comentariosSupervidor,
                comentariosEjecutivo: action.payload.comentariosEjecutivo,
                status: action.payload.status,
                boletinado: action.payload.boletinado === null ? null : Number(action.payload.boletinado),
                comentariosRechazo: "",
                comentariosAutorizacion: ""
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
        clearBoletinado: (state) => {
            return {
                ...state,
                cambioDevolucionBoletinadoId: "",
                cambioDevolucionId: "",
                cambioDevolucionBoletinadoMotivoId: "",
                cambioDevolucionBoletinadoMotivo: "",
                cambioDevolucionBoletinadoMotivoRechazoId: "",
                cambioDevolucionBoletinadoMotivoRechazo: "",
                cambioDevolucionBoletinadoMotivoAutorizacionId: "",
                cambioDevolucionBoletinadoMotivoAutorizacion: "",
                fechaAlta: "",
                usuarioAlta: "",
                usuarioAltaNombre: "",
                usuarioActualiza: "",
                usuarioActualizaNombre: "",
                fechaActualiza: "",
                cambioDevolucionBoletinadoStatusId: "",
                cambioDevolucionBoletinadoStatus: "",
                fechaSolicitud: "",
                fechaOperacion: "",
                fechaAutorizacion: "",
                fechaRechazo: "",
                comentariosSupervidor: "",
                comentariosEjecutivo: "",
                status: "",
                boletinado: "",
                comentariosRechazo: "",
                comentariosAutorizacion: ""
            }
        }
    }
})

export const { setBoletinadoSlice, setEditable, clearBoletinado } = BoletinadoSlice.actions

export default BoletinadoSlice.reducer