import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { tabActiveLog } from '../../Redux/Slice/NavigateSliceJobs';
import { useNavigate } from 'react-router';

const useProgramacionJobsCarteraDetalle = (data) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: 'fecha', headerName: 'Fecha y hora' },
    { field: 'sinc', headerName: 'Tipo de sincronización' },
    { field: 'res', headerName: 'Resultado de ejecución' },
  ];

  // const [data, setData] = useState([]);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [isOpenModalActualizar, setisOpenModalActualizar] = useState(false)

  const [pageCount, setPageCount] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([])

  useEffect(() => {
    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = data.slice(indexOfFirstItem, indexOfLastItem)
    const newTotalPages = Math.ceil(data.length / pageRows)
    setPageCount(newTotalPages)
    setPaginatedData(newData)
  }, [currentPage, pageRows, data])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows])

  const FormatearFecha = (fechaISO) => {
    if (!fechaISO)
      return 'Fecha no válida';

    const fecha = new Date(fechaISO);
    if (isNaN(fecha))
      return 'Fecha no válida';

    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const anio = fecha.getFullYear();
    let horas = fecha.getHours();
    const minutos = String(fecha.getMinutes()).padStart(2, '0');
    const segundos = String(fecha.getSeconds()).padStart(2, '0');
    const ampm = horas >= 12 ? 'PM' : 'AM';
    horas = horas % 12 || 12;
    const horasFormateadas = String(horas).padStart(2, '0');
    return `${dia}-${mes}-${anio} ${horasFormateadas}:${minutos}:${segundos} ${ampm}`;
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  }

  //Modal
  const handleModal = (item) => {
    setisOpenModalActualizar(true)
  }

  const handleReturnPage = () => {
    dispatch(tabActiveLog({ value: 2 }))

    navigate(`/admin/jobs/cartera`)
  }

  return {
    modalMessage,
    setModalMessage,
    columns,
    data,
    handleModal,
    isOpenModalActualizar,
    setisOpenModalActualizar,
    handleReturnPage,

    setpageRows,
    pageCount,
    handlePageClick,
    paginatedData,
    currentPage,

    FormatearFecha,
  }
}
export default useProgramacionJobsCarteraDetalle