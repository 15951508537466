import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';

const ModalCostoGrua = ({ isOpen, setIsOpen, item, initCosto, setCosto }) => {

    const refbtn = useRef();
    // let initial = {
    //     iva: initCosto?.iva,
    //     subTotal: initCosto?.subTotal,
    //     total: initCosto?.total
    // };
    //const [initialValue, setInitialValue] = useState(initial);
    const [costo, setCostoState] = useState(initCosto);

    const validate = Yup.object({
        subTotal: Yup.number()
            .required("El campo es requerido."),
        iva: Yup.number()
            .required("El campo es requerido."),
        total: Yup.number()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        setCosto(item);
        setIsOpen(false);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={costo}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                        <section className='py-2 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className=''><strong>Registrar costo</strong></span>
                            </div>
                            <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costo.</span>
                        </section>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    Valor
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    Total
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    Costo grua
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    {/* <TextField label="Subtotal" name="subTotal" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("subTotal", event.target.value)
                                        let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                        setFieldValue("total", total);
                                    }} /> */}
                                    <TextField label="" name="subTotal" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("subTotal", event.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    Total
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="" name="total" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("total", event.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    IVA
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("iva", event.target.value);
                                    }} />
                                </div>
                            </div>
                            <section className='my-3'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refbtn} type="submit">Guardar</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalCostoGrua