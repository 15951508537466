import axios from '../../Utils/ApiService';

const PolizasCanceladasService = {
    getAllPolizasNoPagadas: async (page, rows,string,usoUnidadId,AseguradoraId,proceso,motivoId,FechInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllPolizasNoPagadas?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&ProcesoId=${proceso}&AseguradoraId=${AseguradoraId}&MotivoCancelacionId=${motivoId}&FechaInicio=${FechInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    }, 
    ExportPolizasNoPagadas: async (string,usoUnidadId,AseguradoraId,proceso,motivoId,FechInicio,FechaFin) => {
        return await axios.get(`/functions/ExportarPolizasNoPagadas?UsoUnidadId=${usoUnidadId}&ProcesoId=${proceso}&AseguradoraId=${AseguradoraId}&MotivoCancelacionId=${motivoId}&FechaInicio=${FechInicio}&FechaFin=${FechaFin}&Buscar=${string}`,{responseType: 'blob'});
    },
    getAllPolizasPagadas: async (page, rows,string,usoUnidadId,tipoCompraId,AseguradoraId,FechInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllPolizasPagadas?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&TipoCompraId=${tipoCompraId}&AseguradoraId=${AseguradoraId}&FechaInicio=${FechInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    }, 
    ExportPolizasPagadas: async (string,usoUnidadId,AseguradoraId,proceso,motivoId,FechInicio,FechaFin) => {
        return await axios.get(`/functions/ExportarPolizasPagadas?UsoUnidadId=${usoUnidadId}&ProcesoId=${proceso}&AseguradoraId=${AseguradoraId}&MotivoCancelacionId=${motivoId}&FechaInicio=${FechInicio}&FechaFin=${FechaFin}&Buscar=${string}`,{responseType: 'blob'});
    },
}

export default PolizasCanceladasService