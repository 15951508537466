import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActive } from '../Redux/Slice/NavigateSlice';
import { ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { Section } from '../Auth/Authorization';

const SubMenuParametrosCartera = () => {
  const { value } = useSelector((state) => state.tabNavigate);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActive(value));
  }
  const handleVigate = (value) => {
    if (value === 2) {
      navigate(`/admin/parametros-cartera-historial`);
    }
    if (ValidatePermissionById(3, Section.Intelimotor) && value === 1) {
      navigate(`/admin/parametros-cartera`);
    }
  }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">

        <li className="nav-item" role="presentation">
          <button className={`nav-link ${value === 1 && "tab-active"}`} value={1} type="button" role="tab" aria-controls="intelimotor" onClick={(e) => { handleSetStateTab(e.target.value) }}>Parámetros</button>
        </li>

        <li className="nav-item" role="presentation">
          <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" aria-controls="intelimotor" onClick={(e) => { handleSetStateTab(e.target.value) }}>Historial</button>
        </li>

      </ul>
    </>
  )
}

export default SubMenuParametrosCartera