import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { tabActiveLog } from '../../../Redux/Slice/NavigateSliceLogs';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';

const SubMenuResponsiva = () => {
  const { value } = useSelector((state) => state.tabNavigateLogs);
  const { cheked, item } = useSelector((state) => state.quickActionsSlice);
  let { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveLog(value));
  }
  const handleVigate = (value) => {
    console.log(value, Section.ResponsivaSection)
    // return;
    switch (value) {
      case (ValidatePermissionById(3, Section.ResponsivaSection) && 1):
        navigate(`/inventario/responsiva/${item?.generalId ?? id}`);
        break;
      case (ValidatePermissionById(4, Section.ResponsivaSection, AccionesRapidas.Responsiva).Historial && 2):
        navigate(`/inventario/responsiva/historial/${item?.generalId ?? id}`);
        break;
    }
  }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ResponsivaSection)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Responsiva</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(4, Section.ResponsivaSection, AccionesRapidas.Responsiva).Historial}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Historial</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuResponsiva