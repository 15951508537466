import axios from '../../../Utils/ApiService';

const vehiculoSeparadoService = {
    GetAllVehiculoSeparado:(page,rows,string,usoUnidadId,clvePendiente)=>{
        return axios.get(`/functions/GetAllSeparadoPM?Page=${page}&Rows=${rows}&Buscar=${string}&UsoUnidadId=${usoUnidadId}&Pendiente=${clvePendiente}`);
    },
    GetAllVehiculoEnganche:(page,rows,string,Tipocompra)=>{
        return axios.get(`/functions/GetAllVehiculosEnganche?Page=${page}&Rows=${rows}&Buscar=${string}&TipocompraId=${Tipocompra}`);
    },
    GetAllVehiculoFirmaContrato:(page,rows,string,Tipocompra,Ubicacion)=>{
        return axios.get(`/functions/GetAllVehiculosFirmaContrato?page=${page}&Rows=${rows}&TipoCompraId=${Tipocompra}&UbicacionId=${Ubicacion}&Buscar=${string}`);
    },
    GetAllVehiculoPendienteEnganche:(page,rows,string,Tipocompra,UsoUnidadId)=>{
        return axios.get(`/functions/GetAllVehiculosPendienteEnganche?Page=${page}&Rows=${rows}&Buscar=${string}&TipocompraId=${Tipocompra}&UsoUnidadId=${UsoUnidadId}`);
    },
}

export default vehiculoSeparadoService