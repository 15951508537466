import axios from '../../Utils/ApiService';

const Garantias = {
    addInspeccionRapida: (data) => {
        return axios.post(`/cartera/crearInspeccionRapida`, data);
    },
    getInspeccionRapida: (garantiaId) => {
        return axios.get(`/cartera/getInspeccionRapida?garantiaId=${garantiaId}`);
    },
    updateInspeccionRapida: (data) => {
        return axios.put(`/cartera/actualizarInspeccionRapida`, data);
    },
    getResponsabilidadCliente: (garantiaId) => {
        return axios.get(`/cartera/getResponsabilidadCliente?garantiaId=${garantiaId}`);
    },
    crearResponsabilidadCliente: (data) => {
        return axios.post(`/cartera/crearResponsabilidadCliente`, data);
    },
    autorizarResponsabilidadCliente: (data) => {
        return axios.put(`/cartera/autorizarResponsabilidadCliente`, data);
    },
    reactivarResponsabilidadCliente: (data) => {
        return axios.put(`/cartera/reactivarResponsabilidadCliente`, data);
    },
    getSeguimientoGarantias: (data) => {
        return axios.post(`/cartera/getSeguimientoGarantias`, data);
    },
    crearRecepcion: (garantiaId) => {
        return axios.post(`/cartera/garantiaRecepcionCrear`, garantiaId);
    },
    getRecepcion: (garantiaId) => {
        return axios.get(`/cartera/garantiaRecepcionGet?garantiaId=${garantiaId}`);
    },
    updateRecepcion: (data) => {
        return axios.put(`/cartera/garantiaRecepcionConcluir`, data);
    },
    crearDiagnostico: (data) => {
        return axios.post(`/cartera/garantiaDiagnosticoCrear`, data);
    },
    getDiagnostico: (garantiaId,vin) => {
        return axios.get(`/cartera/garantiaDiagnosticoGet?garantiaId=${garantiaId}&vin=${vin}`);
    },
    updateDiagnostico: (data) => {
        return axios.put(`/cartera/garantiaDiagnosticoActualizar`, data);
    },
    reparacionCrear: (data) => {
        return axios.post(`/cartera/garantiaReparacionCrear`, data);
    },
    reparacionDetalleCrear: (data) => {
        return axios.post(`/cartera/garantiaReparacionDetalleCrear`, data);
    },
    getReparacion: (data,vin) => {
        return axios.get(`/cartera/garantiaReparacionGet?garantiaid=${data}&vin=${vin}`);
    },
    reparacionActualizar: (data) => {
        return axios.put(`/cartera/garantiaReparacionActualizar`, data);
    },
    garantiaListaReparacionDetalle: (data) => {
        return axios.post(`/cartera/garantiaListaReparacionDetalleGet`, data);
        // public string VIN { get; set; }
    },
    garantiaCambioDevolucionCrear: (data) => {
        return axios.post(`/cartera/garantiaCambioDevolucionCrear`, data);
    },
    garantiaCambioDevolucionActualizar: (data) => {
        return axios.put(`/cartera/garantiaCambioDevolucionActualizar`, data);
    },
    garantiaCambioDevolucionGet: (data) => {
        return axios.get(`/cartera/garantiaCambioDevolucionGet?garantiaid=${data}`);
    },
    garantiaEntregaCrear: (data) => {
        return axios.post(`/cartera/garantiaEntregaCrear`, data);
    },
    garantiaEntregaGet: (data) => {
        return axios.get(`/cartera/garantiaEntregaGet?garantiaid=${data}`);
    },
    garantiaEntregaActualizar: (data) => {
        return axios.put(`/cartera/garantiaEntregaActualizar`, data);
    },
    creardatosenviar: (data) => {
        return axios.post(`/cartera/creardatosenviar`,data);
    },
    obtenerDocumentoRecepcion: (data) => {
        return axios.post(`/cartera/generarPlantillaPDF`,data);
    },
    boletinadoCrear : (data) => {
        return axios.post(`/cartera/cambioDevolucionBoletinadoCrear`,data);
    },
    boletinadoGet: (id) => {
        return axios.get(`/cartera/cambioDevolucionBoletinadoGet?cambioDevolucionId=${id}`);
    },
    boletinadoActualizar : (data) => {
        return axios.put(`/cartera/cambioDevolucionBoletinadoActualizar`, data);
    },
    seguimientoCambioDevoluciones: (data) => {
        return axios.post(`/cartera/getSeguimientoGarantiasCambioDevolucion`, data);
    },
    pagosRealizadosGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionPagosRealizadosGet?sol_id=${data.solId}&contrato=${data.contrato}&vin=${data.vin}&cambioDevolucionId=${data.cambioDevolucionId}`);
    },
    segurosGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionSeguroGet?vin=${data}`);
    },
    gestoriaGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionGestoriaConceptosGet?vin=${data}`);
    },
    calculadoraGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionCalculadoraGet?cambioDevolucionId=${data}`);
    },
    calculadoraUpdate: (data) => {
        return axios.put(`/cartera/cambioDevolucionCalculadoraActualizar`, data);
    },
    calculadoraCrear: (data) => {
        return axios.post(`/cartera/cambioDevolucionCalculadoraCrear`, data);
    },
}

export default Garantias