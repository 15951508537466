import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { selectScheduleByTipoCalendario, clearScheduleByTipoCalendario, clearOnlyScheduleByTipoCalendario } from '../../Redux/Slice/Calendario/calendarDynamicalSlice';

import { useLocation, useNavigate } from 'react-router-dom';

const DetailScheduleDynamicalModal = ({ modalMessage, setModalMessage, tipoCalendario }) => {
    const { state } = useLocation();
    const scheduleData = useSelector((state) => selectScheduleByTipoCalendario(state, tipoCalendario));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setHour(scheduleData.hour);
        setDay(scheduleData.day);
    }, []);

    useEffect(() => {
        setHour(scheduleData.hour);
        setDay(scheduleData.day);
    }, [scheduleData]);

    const [daySelected, setDaySelected] = useState("");
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const [hour, setHour] = useState("");
    const [day, setDay] = useState("");

    const getDate = () => {
        if(!day) return false;
            
        const date = new Date(day.split("T")[0].replace('-', '/'));
        const strDate = days[date.getDay()] + ", " + ("0" + date.getDate()).slice(-2) + " de " + months[date.getMonth()];
        return strDate;
    }

    // setModalMessage({ ...modalMessage, isOpen: false })
    const handleCloseAndCancel = () => {
        dispatch(clearOnlyScheduleByTipoCalendario({ tipoCalendario }));
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const handleCloseAndRedirect = () => {
        navigate(-1, { state });
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    return (
        <div className='p-2'>
            <div className='justify-content-start align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>Detalle de cita</strong></h6>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-12">
                        <small>A continuación se muestra el detalle de la cita agendada para el vehículo:</small>
                    </div>
                    <div className="col col-12">
                        <small>
                            <span className='px-0'><strong>Contrato: </strong>{scheduleData?.contrato}</span>
                        </small>
                    </div>
                </div>
                <div className="row mt-4 mb-2">
                    <div className="col col-1">
                        <i className="icon-dark bx bx-car"></i>
                    </div>
                    <div className="col col-11">
                        <div className="row">
                            <div className="col-auto">
                                <span>VIN: {scheduleData?.vin}</span>
                            </div>
                            <div className="col-auto">
                                <span>Marca: {scheduleData?.marca}</span>
                            </div>
                            <div className="col-auto">
                                <span>Modelo: {scheduleData?.modelo}</span>
                            </div>
                            <div className="col-auto">
                                <span>Año: {scheduleData?.ano}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-1">
                        <i className="icon-dark bx bx-time-five"></i>
                    </div>
                    <div className="col col-11">
                        <div className='row'>
                            <div className="col-auto">
                                <span>{getDate()}</span>
                            </div>
                            <div className="col-auto">
                                <strong>{scheduleData?.hour}</strong>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-auto">
                                <strong>{scheduleData?.sucursalName}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleCloseAndCancel() }}>Cancelar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleCloseAndRedirect() }}>Agendar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default DetailScheduleDynamicalModal