import axios from '../../Utils/ApiService';

const Comentarios = {
    addComment: (payload) => {
        return axios.post(`/cartera/CierreContratoComentarios`, payload);
    },
    getComments: (id) => {
        return axios.get(`/cartera/CierreContratoComentarios?CierreContratoId=${id}`);
    }
}

export default Comentarios;