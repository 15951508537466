import axios from '../../../Utils/ApiService';

const ComisionesExternoService = {
    getAllPendientesDePagoComisionExterno: async (page, rows, string,TipoCompraId,RazonSocial,ProcedenciaClienteId,fechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllComisionExterno?page=${page}&Rows=${rows}&TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&ProcedenciaClienteId=${ProcedenciaClienteId}&FechaInicio=${fechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    },  
    ExportPendientesDePagoComisionExterno: async (data) => {
        return await axios.get(`/functions/ExportarPendientesPagoComisionExterno?Ids=${data.ids}&Folio=${data.folio}&TotalVehiculos=${data.totalVehiculos}&TotalAPagar=${data.totalAPagar}`, { responseType: 'blob' });
    },
    getAllProcesoPagoComisionExterno: async (page,rows,TipoCompraId,RazonSocial,ProcedenciaClienteId,FechaInicio,FechaFin,str) => {
        return await axios.get(`/functions/GetAllProcesoPagoComisionExterno?page=${page}&Rows=${rows}&TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&ProcedenciaClienteId=${ProcedenciaClienteId}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`);
    },
    SaveProcesoPagoComisionExterno:async(data)=>{
        return axios.post('/functions/SaveProcesoDePagoComisionExterno',data);
    },
    getAllComisionesExternoPagada: async (page, rows, string,TipoCompraId,RazonSocial,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllComisionExternoPagada?page=${page}&Rows=${rows}&TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    }, 
    ExportComisionesExternoPagada: async (TipoCompraId,RazonSocial,str,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/ExportComisionExternoPagada?TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`,{ responseType: 'blob' });
    }, 
    getAllCSRazonSocialById: async () => {
        return await axios.get(`/functions/GetAllCSRazonSocialById`);
        // return await axios.get(`/functions/GetAllCSRazonSocialById?TipoCompraId=${Id}`);

    },
    getAllCSRazonSocialCCEById: async (Id) => {
        return await axios.get(`/functions/GetAllCSRazonSocialCCEById?TipoCompraId=${Id}`);
    }, 
    SendMailRetencionPago:async(data)=>{
        return await axios.post(`/functions/SendMailRetencionPago`,data);
    },
    ReemprimirPendientesDePagoComisionExterno: async (folio) => {
        return await axios.get(`/functions/ReExportarPendientesPagoComisionExterno?Folio=${folio}`, { responseType: 'blob' });
    },
}

export default ComisionesExternoService