import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { message } from '../../../../Utils/functions';
import { SelectField } from '../../../../Components/SelectField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';

const DesactivarContactoModal = ({ isOpen, setIsOpen, item, toast, state, GetDataContactos }) => {

    let initial = {
        comentario: ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [motivosSelect, setMotivosSelect] = useState([{}])
    const userId = sessionStorage.getItem('userId')

    useEffect(() => {
        handleGetMotivos();
    }, [])

    const handleGetMotivos = async () => {
        await CobranzaService.getAllMotivoEliminacionDatoContacto().then(res => {
            let motivos = []
            res.data.responseData?.map(item => {
                motivos.push({
                    value: item.id,
                    label: item.nombre
                });
            });
            setMotivosSelect(motivos);
        })
    }


    const validationSchema = Yup.object({
        comentario: Yup.string().required('Comentario es requerido')
            .test(
                'min-length-if-not-empty',
                'El comentario debe tener al menos 20 caracteres',
                function (value) {
                    // Si el valor no está vacío, debe tener al menos 20 caracteres
                    return !value || (value && value.length >= 20);
                }),
        motivo_Cancelacion: Yup.string().required('Motivo es requerido')
    })

    const handleDelete = async (value) => {
        let today = new Date();

        setLoading(true);
        const data = {
            "sol_id": state.sol_id,
            "contrato": state.contrato_id,
            "vin": state.vin,
            "idcontacto_acendes": item.idcontacto_acendes,
            "idpersona_acendes": item?.value?.idreferencia,
            "tipo_contacto": item.tipo_contacto,
            "subtipo_contacto": item.subtipo_contacto,
            "tipo_persona": item.value?.tipo,
            "curp": item.value?.curp_ref,
            "motivo_eliminacion": value.motivo_Cancelacion,
            "comentario": value.comentario,
            "fecha_operacion": today ,
            "activo": false
        }

        try {
            await CobranzaService.eliminaDatoContacto(data)
            await GetDataContactos()
            setLoading(false)
            setIsOpen(false)
            toast.success(message("¡Operación exitosa!"));
        } catch (e) {
            setLoading(false)
            toast.error(message("¡Error!", e.message));
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleDelete(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Desactivar contacto</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '28rem' }}>
                            <Form>
                                <div className="col-lg-12 col-12">
                                    <SelectField
                                        id="motivo_Cancelacion"
                                        label="Motivo"
                                        name="motivo_Cancelacion"
                                        disabled={false}
                                        items={motivosSelect}
                                        onChange={(event) => {
                                            setFieldValue("motivo_Cancelacion", event.label)
                                        }} />
                                </div>
                                <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario" type="text" holder="Escribe" />
                                </div>

                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar"  
                                    disabled={!isValid} 
                                    className='btn mx-2' 
                                    type="submit">Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default DesactivarContactoModal