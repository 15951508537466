import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    comentarioTecnico: "",
}

export const InformacionUltimoCreditoSlice = createSlice({
    name: 'InformacionUltimoCreditoSlice',
    initialState,
    reducers: {
        setComentarioTecnico: (state, action) => {
            return {...state,
                comentarioTecnico: action.payload,
            }
        },
        clearComentarioTecnico: (state) => {
            return {
                ...state,
                comentarioTecnico: "",
            }
        }
    }
})

export const { setComentarioTecnico,  clearComentarioTecnico } = InformacionUltimoCreditoSlice.actions

export default InformacionUltimoCreditoSlice.reducer