import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import SubmenuDatadocs from './SubMenuDatadocs'
import Modal from '../../Components/Modal/Modal'
import Spinner from '../../Components/Loadig'
import DataTable from '../../Components/datatable/DataTable'
import DocumentoEtapaModal from './ModalDocumentoEtapaa'
import UseDocumentoEtapa from '../../Hooks/AdminDataDocs/UseDocumentoEtapa'
import { pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'
const TipoDocumentoEtapa = () => {
    const {
        loading,
        dataProd,
        setTipoProductoId,
        dataTipoCompra,
        setTipoCompraId,
        tipoCompraId,
        tipoProductoId,
        setDataVehiculo,
        setIsOpenDocs,
        columns,
        data,
        handleCreateFolder,
        pageCount,
        handlePageClick,
        currenPage,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        handleDeleteItem,
        setpageRows
    } = UseDocumentoEtapa(toast);
    const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
    return (
        <>
            <SubmenuDatadocs />
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                {/* <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Folder DataDocs</strong></span>
        </div> */}
                <span className='mt-2'>El siguiente listado corresponde a la configuración de documentos por etapa.</span>
            </section>
            <section className='mt-3 headerTab pb-4'>
                <div className='mx-3'>
                    <div className='row col-12 my-1'>
                        {/* d-flex flex-column mx-1 col-2 */}
                        <div className='col-lg-3 col-md-3 col-sm-12'>
                            <label>Producto</label>
                            <Select options={dataProd} className='col-12' placeholder="Seleccione" onChange={(e) => { setTipoProductoId(e.value); }} />
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12'>
                            <label>Tipo de compra</label>
                            <Select options={dataTipoCompra} className='col-12' placeholder="Seleccione" onChange={(e) => { setTipoCompraId(e.value); }} />
                        </div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.DocumentosPorEtapa, SubModule.DataDocs).Agregar}>
                            <div className='d-flex flex-column justify-content-end col-lg-2 my-1 col-md-3 col-sm-12'>
                                <button disabled={tipoCompraId !== "" && tipoProductoId !== "" ? false : true} className='btn' onClick={() => { setDataVehiculo({}); setIsOpenDocs(true); }}>Asignar</button>
                            </div>
                        </ValidatePermission>
                    </div>
                </div>

            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.DocumentosPorEtapa, SubModule.DataDocs).Ver}>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        editable={ValidatePermissionById(4, Section.DocumentosPorEtapa, SubModule.DataDocs).Actualizar}
                        handleEdit={handleCreateFolder}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        deleteable={ValidatePermissionById(4, Section.DocumentosPorEtapa, SubModule.DataDocs).Ver}
                        handleDelete={handleDeleteItem}
                    />
                </div>
            </ValidatePermission>

            {
                isOpenDocs &&
                <DocumentoEtapaModal
                    isOpen={isOpenDocs}
                    setIsOpen={setIsOpenDocs}
                    item={{ ...dataVehiculo, tipoCompraId, tipoProductoId }}
                    handleGetAll={habdleGetAll}
                    message={message}
                    toast={toast}
                />
            }
        </>
    )
}

export default TipoDocumentoEtapa