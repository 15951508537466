import { useEffect, useRef, useState } from "react";
import Catalogo from "../../Services/Catalogos/Catalogo";
import CalendarService from "../../Services/Calendar/Calendar";
import { useLocation, useParams } from "react-router-dom";
import { setFields } from "../../Redux/Slice/Calendario/calendarDynamicalSlice";
import { useDispatch } from "react-redux";

const useCalendar = ({ sundayOn = false, type = 1, halfDay = 12 }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const parentDiv = useRef(null);
    const initial = {
        sucursalId: null,
        vista: 2
    }

    /***** V A R I A B L E S *****/
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [sucursales, setSucursales] = useState([]);
    const [vista, setVista] = useState([
        { value: 1, label: "Mes" },
        { value: 2, label: "Semana" }
    ]);
    const Week = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    const daysInWeek = 7;
    const [initialValue, setInitialValue] = useState(initial);
    const [today, setToday] = useState(initialDate);
    const [tamanoCasilla, setTamanoCasilla] = useState(0);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [dataScheduleDay, setDataScheduleDay] = useState([]);
    const [maxHour, setMaxHour] = useState(0);
    const [diasFestivosDelPeriodo, setDiasFestivosDelPeriodo] = useState([]);

    /***** T E R M I N A N    V A R I A B L E S *****/


    /***** T R I G G E R S *****/
    useEffect(() => {
        getFestivos();
        setLoading(true);
        handleGetCurrentMonthString();
        handleGetCalendar();
        handleGetSucursal();
        handleGetWidth(initialValue.vista);
    }, []);

    useEffect(() => {
        handleGetWidth(initialValue.vista);
        setCurrentDate(new Date());
        handleGetCalendar();
    }, [initialValue.vista]);

    useEffect(() => {
        handleGetCalendar();
    }, [initialValue.sucursalId]);

    useEffect(() => {
        getFestivos();
        handleGetCalendar();
    }, [currentDate]);
    /***** T E R M I N A N    T R I G G E R S *****/


    /***** F U N C T I O N S *****/
    const getFestivos = async () => {
        let inicial, final;
        if (initialValue.vista === 1) {
            inicial = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
            final = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
        } else {
            const dayIn = currentDate.getDay();
            const dayEnd = 6 - dayIn;
            inicial = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayIn));
            final = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + dayEnd));
        }

        await CalendarService.getDiasFestivos(inicial, final)
        .then(resp => {

            let festivos = [];
            resp.data.forEach(item => {
                if(!festivos.includes(item.diaFestivo)) festivos.push(item.diaFestivo);
            });
            setDiasFestivosDelPeriodo(festivos);
        })
        .catch(err => {
            console.error(err);
        });
    }

    const handleGetCalendar = async () => {
        if (initialValue.sucursalId === null || (initialValue.sucursalId !== null && initialValue.sucursalId.trim() === "")) {
            return false;
        }
        setLoading(true);
        const sucursalId = initialValue.sucursalId;

        let inicial, final;
        if (initialValue.vista === 1) {
            inicial = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
            final = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
        } else {
            const dayIn = currentDate.getDay();
            const dayEnd = 6 - dayIn;
            inicial = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayIn));
            final = localeStringDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + dayEnd));
        }

        await CalendarService.getCalendario(type, inicial, final, sucursalId)
            .then(resp => {
                setData(resp.data.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    // Función para obtener los días del mes
    const daysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        return new Date(year, month, 0).getDate();
    };

    // Función para comparar las fechas en formato string
    const localeStringDate = (date) => {
        return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    }


    const getDisabled = (day) => {
        let total = data.filter((item) => item.fecha === day + "T00:00:00");
        let ocupados = data.filter((item) => item.fecha === day + "T00:00:00" && item.disponible === 2);
        let nodisponibles = data.filter((item) => item.fecha === day + "T00:00:00" && item.disponible === 0);

        if (ocupados.length === total.length || nodisponibles.length === total.length || diasFestivosDelPeriodo.includes(day)) {
            return true;
        }
        return false;
    }

    // Función para armar el calendario mensual
    const generateMonthyCalendar = () => {
        const days = [];
        const daysCount = daysInMonth(currentDate);
        const initialDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const firstDay = initialDay.getDay();
        const todayStr = localeStringDate(today);
        const isSucursalIdPresent = initialValue.sucursalId !== null;

        // Generar los días no visibles (días del mes anterior)
        for (let i = 1; i <= firstDay; i++) {
            days.push(
                <div key={`daysoff_${i}`} className="calendar-day cell-calendar cell-calendar-disabled calendar-header-montly" style={{ maxWidth: tamanoCasilla, minHeight: '40px', lineHeight: '40px' }} />
            );
        }

        // Generar los días del mes actual
        for (let i = 1; i <= daysCount; i++) {
            const dayInProgress = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
            const day = localeStringDate(dayInProgress);
            const isPast = dayInProgress.getTime() < today.getTime();
            const isSundayDisabled = (dayInProgress.getDay() === 0 && !sundayOn);
            const isDisabled = isSundayDisabled || getDisabled(day);

            days.push(
                <div key={`dayson_${i}`} className={`calendar-day cell-calendar cell-montly ${isPast || isDisabled ? 'cell-calendar-disabled' : ''}`}>
                    <div className="row justify-content-end m-0 mt-2 mb-2">
                        <div className="col-2 px-2 mx-1">
                            <small className={`${todayStr === day ? 'today-montly' : ''} text-xs`}>{i}</small>
                        </div>
                    </div>
                    {isSucursalIdPresent && !isPast && !isDisabled && (
                        <>
                            <div className="row justify-content-center mt-4 mb-1 text-sm">
                                <div className="col-12 px-2">
                                    {getDataForDay(day, 2)}
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2 mb-1 text-sm">
                                <div className="col-12 px-2">
                                    {getDataForDay(day, 1)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            );
        }

        return days;
    }


    const handleOpenScheduleModal = (day) => {
        let disponibles = data.filter((item) => item.fecha === day + "T00:00:00");
        setDataScheduleDay(disponibles);
        setModalMessage({ ...modalMessage, isOpen: true, type: 4 });
    }

    const getDataForDay = (day, type) => {
        const today = new Date();
        const fecha = today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
        const currentHour = today.getHours().toString().padStart(2, '0') + ':' + today.getMinutes().toString().padStart(2, '0');
        const items = data.filter(item => item.fecha === `${day}T00:00:00`);

        let disponibles = 0;

        const updateDisponibles = (item) => {
            if (item.disponible === 1) {
                const [horario] = item.horario.split(" ");
                const [horas, minutos] = horario.split(":").map(Number);
                const tmpHour = new Date().setHours(horas, minutos, 0, 0);

                if ((type === 1 && currentHour < tmpHour) || (type === 2 && currentHour >= tmpHour)) {
                    disponibles++;
                }
            } else if (item.disponible === 2 && type === 2) {
                disponibles++;
            }
        };

        if (fecha === day) {
            items.forEach(updateDisponibles);
        } else {
            disponibles = items.filter(item => item.disponible === type).length;
        }

        const badgeClass = type === 1 ? (disponibles > 0 ? 'badge-success' : 'badge-danger') : 'badge-secondary';
        const badgeText = type === 1 ? `Disponibles: ${disponibles}` : `Agendadas: ${disponibles}`;

        return (
            <span className={`col-12 badge ${badgeClass}`} onClick={type === 1 && disponibles > 0 ? (e) => handleOpenScheduleModal(day) : undefined}>
                {badgeText}
            </span>
        );
    };

    const handleGetWidth = (vista) => {
        let cells = vista === 1 ? 7 : 8;
        const width = parentDiv.current ? (vista === 1 ? (parentDiv.current.offsetWidth + 10) : (parentDiv.current.offsetWidth)) : 0;
        const widthDiv = Math.ceil((width / cells) - 10);
        setTamanoCasilla(widthDiv);
    };


    const handleGetSucursal = async () => {
        const sucursalUser = sessionStorage.getItem('ubicacionId');
        await Catalogo.getUbicacionesCalendario(id)
            .then(resp => {
                const listSucursales = resp.data.map(item => {
                    return {
                        value: item.ubicacionId,
                        label: item.nombre
                    };
                });
                
                const sucursalEnlistada = listSucursales.some(item => item.value === sucursalUser);
                if(sucursalEnlistada){
                    setInitialValue({
                        ...initialValue,
                        sucursalId: sucursalUser
                    });

                    const sucursalData = listSucursales.filter(item => item.value === sucursalUser)[0];
                    let data = {
                        tipoCalendario: state?.tipoCalendario,
                        fields: {
                            sucursalId: sucursalData.value,
                            sucursalName: sucursalData.label,
                        }
                    }

                    dispatch(setFields(data));
                }

                setSucursales(listSucursales);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const handleGetCurrentMonthString = () => {
        const monthString = new Intl.DateTimeFormat('es-MX', { month: 'long' }).format(currentDate) + " " + currentDate.getFullYear();
        return monthString;
    }

    const handleSucursalId = (value, valueState) => {
        if (!value) return false;

        const name = sucursales.filter(sucursal => sucursal.value === value)[0];
        const dataSucursal = {
            sucursalId: value,
            sucursalName: name.label
        };

        dispatch(setFields({
            tipoCalendario: state.tipoCalendario,
            fields: dataSucursal
        }));
        setInitialValue({ ...valueState, sucursalId: value });
    }

    const handleVista = (vista) => {
        setInitialValue({
            ...initialValue,
            vista
        });
    }

    const handleToday = () => {
        setCurrentDate(new Date());
    }

    const handlePrevButton = () => {
        if (initialValue.vista === 1) {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
        } else {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - daysInWeek))
        }
    }

    const handleNextButton = () => {
        if (initialValue.vista === 1) {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
        } else {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysInWeek))
        }
    }

    // Función para construir los encabezados de 
    const generateDaysOfWeek = () => {
        const days = [];
        const weekStart = new Date(currentDate);
        const todayStr = localeStringDate(today);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay());


        const dayIn = currentDate.getDay();
        const inicial = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - dayIn);

        // Celda sola para la columna de horarios
        days.push(
            <div key={'schedule_cell'} className="cell-calendar calendar-header-cell" style={{ maxWidth: '100px', minHeight: '40px', lineHeight: '40px' }}>

            </div>
        );
        for (let i = 0; i < daysInWeek; i++) {
            const dayInProgress = new Date(inicial.getFullYear(), (inicial.getMonth()), weekStart.getDate() + i);
            const dayInProgressToCompare = new Date(inicial.getFullYear(), (inicial.getMonth()), weekStart.getDate() + (i + 1));
            const dayInProgressStr = localeStringDate(dayInProgress);
            const dayInProgressToCompareStr = localeStringDate(dayInProgressToCompare);
            const disponibilidad = getAvailabilityDayWeekly(dayInProgressStr);

            const day = new Date(weekStart);
            day.setDate(weekStart.getDate() + i);
            days.push(
                <div key={i} className="cell-calendar calendar-header-cell calendar-header-day text-center d-flex align-items-center justify-content-center gap-1">
                    {
                        todayStr < dayInProgressToCompareStr && dayInProgress.getDay() !== 0 && <span className={`availability mx-1 ${disponibilidad > 5 ? ' availability-success' : (disponibilidad === 0 ? 'availability-danger' : 'availability-warning')}`}></span>
                    }
                    <strong className={`${todayStr === dayInProgressStr ? 'today' : ''} text-xs`}>{("0" + day.getDate()).slice(-2)}</strong> <small>{new Intl.DateTimeFormat('es-MX', { weekday: 'short' }).format(day)}</small>
                </div>
            );
        }
        

        return days;
    };
        
    const getAvailabilityWeekly = (day, hour) => {
        let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.horario.split(" ")[0] === hour)[0];

        if(rs !== undefined) {
            return rs.disponible;
        }else{
            return 0;
        }
    };
    
    const getAvailabilityDayWeekly = (day) => {
        let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.disponible === 1);
        if(rs !== undefined) {
            return rs.length;
        }else{
            return 0;
        }
    };

    
    const handleDetail = (day, hour) => {
        let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.horario.split(" ")[0] === hour)[0];
        let date = {
            tipoCalendario: state?.tipoCalendario,
            fields: {
                day,
                hour,
                calendarioId: rs.id,
            }
        }
        dispatch(setFields(date));
        setModalMessage({ ...modalMessage, isOpen: true, type: 5 });
    }

    // Función para construir los horarios del calendario semanal
    const generateScheduleRows = () => {
        const rows = [];
        let schedulesPerDay = [];
        let endTime = 19;


        if (data.length === 0) {
            return false;
        }

        // Preparación de horarios en base a los horarios proporcionados por la configuración
        data.forEach(item => {
            let hour = item.horario.split(" ")[0];
            if (!schedulesPerDay.includes(hour)) {
                schedulesPerDay.push(hour);
            }
        });

        const last = schedulesPerDay[schedulesPerDay.length - 1].split(":");
        setMaxHour(Number(last[0]));
        const differnce = endTime - Number(last[0]);
        if (last[1] === "30") {
            for (let i = 1; i <= differnce; i++) {
                if ((Number(last[0]) + i) < endTime) {
                    schedulesPerDay.push((Number(last[0]) + i) + ":00");
                    schedulesPerDay.push((Number(last[0]) + i) + ":30");
                } else {
                    schedulesPerDay.push((Number(last[0]) + i) + ":00");
                }
            }
        } else {
            for (let i = 1; i <= differnce; i++) {
                schedulesPerDay.push((Number(last[0]) + i) + ":00");
            }
        }

        
        // Preparación de horarios en base a los horarios proporcionados por la configuración
        const weekStart = new Date(currentDate);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay());

        for (let hour = 0; hour <= schedulesPerDay.length - 1; hour++) {
            const cells = [];
            for (let day = 0; day < daysInWeek; day++) {
                const dayInProgress = new Date(weekStart.getFullYear(), (weekStart.getMonth()), weekStart.getDate() + day);
                const todayStr = localeStringDate(today);
                const dayInProgressStr = localeStringDate(dayInProgress);
                const availability = getAvailabilityWeekly(dayInProgressStr, schedulesPerDay[hour]);

                let cellDay;

                if (day === 0 && !sundayOn) {
                    cellDay = <div key={day} className="cell-calendar schedule-cell calendar-header-day cell-calendar-disabled">
                    </div>
                } else if (Number(schedulesPerDay[hour].split(":")[0]) > maxHour) {
                    cellDay = <div key={day} className="cell-calendar schedule-cell calendar-header-day cell-calendar-disabled">
                    </div>
                } else if (todayStr > dayInProgressStr) {
                    cellDay = <div key={day} className="cell-calendar schedule-cell calendar-header-day cell-calendar-disabled">
                    </div>
                } else if (dayInProgressStr === todayStr && Number(schedulesPerDay[hour].split(":")[0]) < new Date().getHours() && !diasFestivosDelPeriodo.includes(dayInProgressStr)) {
                    cellDay = <div key={day} className="cell-calendar schedule-cell calendar-header-day cell-calendar-disabled">
                    </div>
                } else if (availability !== 1) {
                    cellDay = <div key={day} className={`cell-calendar schedule-cell calendar-header-day ${availability === 0 ? 'cell-calendar-disabled' : ''}`}>
                        {
                            availability === 2 &&
                            <span className='badge badge-danger col-12 py-2'>Ocupado</span>
                        }
                    </div>
                } else {
                    cellDay = <div key={day} className={`cell-calendar schedule-cell calendar-header-day ${availability === 1 && !diasFestivosDelPeriodo.includes(dayInProgressStr) ? "" :  "cell-calendar-disabled"}`} style={{ cursor: 'pointer' }} onClick={() => {
                        if(!diasFestivosDelPeriodo.includes(dayInProgressStr)){
                            handleDetail(dayInProgressStr, schedulesPerDay[hour]);
                        }
                    }}>
                        {
                            !diasFestivosDelPeriodo.includes(dayInProgressStr) &&
                            <span className='badge badge-success col-12 py-2'>Disponible</span>
                        }
                    </div>
                }

                cells.push(cellDay);
            }

            rows.push(
                <div key={hour} className='row'>
                    <div className="cell-calendar hour-cell text-center" style={{ maxWidth: '100px', minHeight: '40px', lineHeight: '40px' }}><small className='font-weight-bold'>{schedulesPerDay[hour]}</small></div>
                    {cells}
                </div>
            );
        }
        return rows;
    }
    /***** T E R M I N A N    F U N C T I O N S *****/

    return {
        sucursales,
        initialValue,
        Week,
        tamanoCasilla,
        parentDiv,
        vista,
        handleGetCurrentMonthString,
        handleSucursalId,
        handleVista,
        handlePrevButton,
        handleNextButton,
        handleToday,
        generateMonthyCalendar,
        dataScheduleDay,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        generateDaysOfWeek,
        generateScheduleRows
    }
}

export default useCalendar;