import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Select from 'react-select';

const ReasignacionCobradorModal = ({ isOpen, setIsOpen, item }) => {
   const refbtn = useRef();
   let initial = {
      cobradorfuente: item?.nombrecobrador,
      cobradordestino: null
   }

   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const [showDetails, setShowDetails] = useState(false)

   const dataPersonal = [
      { value: 0, label: "Seleccionar"},
      { value: 1, label: 'Adrian Gómez'},
      { value: 2, label: 'Agustin Villanueva'},
      { value: 3, label: 'Maximiliano Luna'},
      { value: 4, label: 'Andrea González'},
   ]
   const validate = Yup.object({
      cobradordestino: Yup.string()
         .required("El campo es requerido."),
   });

   const handleAdd = async (values) => {
      console.log(values);
      //dispatch(setNuevaArea(item));
      //setNewArea(item);
      setIsOpen(false);

      // setLoading(true);
      // await Cobranza.addCobrador(item)
      //     .then(res => {
      //         setNuevaArea(item);
      //         setIsOpen(false);
      //         setLoading(false);
      //     }).catch(e => {
      //         setLoading(false);
      //     })
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
               handleAdd(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
               <Modal isOpen={isOpen} color='#fff'>
                  <section className='my-2 mx-4' style={{ width: '40rem', height: '28rem' }}>
                     <Form>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Reasignar área o zona de trabajo </strong></span>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <div className="col-sm-12">
                                 <label className='col-12 mx-1'>
                                    Selecciona el cobrador al que se reasignaran las áreas o zonas de trabajo.
                                    <Select options={dataPersonal} label="Nombre" name='cobradordestino' onChange={(e) => { 
                                       console.log(e);
                                       if(e.value === 0)
                                          setShowDetails(false)
                                       else
                                          setShowDetails(true)
                                       setFieldValue("cobradordestino", e.value !== 0 ? e.label : "");
                                       }} 
                                       placeholder="Seleccionar" />
                                 </label>
                           </div>
                        </div>
                        <div className="row" style={{margin:6, width: "40rem", height: "12rem", backgroundColor: "#f8f8f8"}}>
                           <div className="col-sm-6 my-4">
                              <span className='fs-5' style={{fontWeight:'bold', color:"#000"}}><strong>Cobrador fuente</strong></span>
                              <p><span style={{fontWeight: "bold"}}>{values.cobradorfuente}</span></p>
                              <span>Número de contratos</span>
                              <br/>
                              <span>23</span>
                           </div>
                           <div style={{marginTop: 15,borderLeft: "2px solid #dcdcdc", height: "33%", left: "50%", position: "absolute"}}></div>
                           <div className="col-sm-6 my-4">
                              <span className='fs-5' style={{fontWeight:'bold', color:"#000"}}><strong>Cobrador destino</strong></span>
                              <p><span style={{fontWeight: "bold"}}>{values.cobradordestino}</span></p>
                              {
                                 showDetails &&(
                                 <>
                                    <span>Número de contratos</span>
                                    <br/>
                                    <span>26</span>
                                 </>)
                              }
                           </div>
                        </div>
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                        </section>
                     </Form>
                  </section>
               </Modal>
               {
                  loading &&
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
               }
            </>
         )
      }
   </Formik>
   )
}

export default ReasignacionCobradorModal