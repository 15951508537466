import axios from '../../Utils/ApiService';

const SyncInventarioService = {
    getSyncHistory:(page,rows,filtro,fecha,texto)=> {        
        return axios.get(`/catalogs/getAllSyncInvHistory?page=${page}&rows=${rows}&Filtrar=${filtro}&Fecha=${fecha}&Buscar=${texto}`);
    },   
    getSyncHistoryById:(Id,page,rows,filtro,texto,regStatus)=> {        
        return axios.get(`/catalogs/GetInvBySyncIdIntelimotor?id=${Id}&page=${page}&rows=${rows}&Filtrar=${filtro}&Buscar=${texto}&EstatusReg=${regStatus}`);
    },    
    downloadCatalog:()=> {     
        return axios.get(`/functions/config/getIntelimotor`);
    },       
    getModifyList:(page,rows,id,string)=> {     
        return axios.get(`/functions/TblModificadas?page=${page}&rows=${rows}&sid=${id}&txtf=${string}`);       
    },     
    getParameterIntelimotor:(section)=> {     
        return axios.get(`/functions/Parametros?seccion=${section}`);       
    }, 
    updateParameterIntelimotor:(value)=> {     
        return axios.put(`/functions/DescargaCatIntelimotor?value=${value}`);       
    }, 
    getModifyTA:(page,rows,id,string)=> {     
        return axios.get(`/functions/TblModificadasTA?page=${page}&rows=${rows}&sid=${id}&txtf=${string}`);       
    }, 
    DownloadSyncinv:(id,filtro,texto,status)=> {     
        return axios.get(`/catalogs/ExportInvBySyncIdIntelimotor?id=${id}&Filtrar=${filtro}&Buscar=${texto}&EstatusReg=${status}`,{responseType: 'blob'});       
    }, 
    getAllDataware:(page,rows,fechaFiltro,resultFiltro,marca,modelo,string)=> {        
        return axios.get(`/catalogs/ListAutosAltaFallaIntelimotor?page=${page}&rows=${rows}&FechaFiltro=${fechaFiltro}&ResultadoFiltro=${resultFiltro}&ClaveMarca=${marca}&ClaveModelo=${modelo}&Buscar=${string}`);
    }, 
    saveIntelimotor:(data)=>{
        return axios.put(`/catalogs/ReAltaIntelimotor`,data)
    }    
}

export default SyncInventarioService