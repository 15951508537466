import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import Llamada from '../../../Services/Cartera/Llamadas';
import DataTable from '../../../Components/datatable/DataTable'
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import Catalogo from '../../../Services/Catalogos/Catalogo';

import { message, pageRows } from '../../../Utils/functions'

const TablaMantenimiento = (props) => {
    const { generalIddata } = props
    const { generalId, fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, procedenciaAuto, nombreVersion, numMotor, numContrato, producto, cliente, usoUnidad, fechaUltimoPago } = useSelector((state) => state.DataVinSlice);
    const { FolioLlamada, Nombre, Telefono, Correo, MotivoLlamadaId, ComentariosCliente, ComentariosGarantia } = useSelector((state) => state.LlamadaSlice);
    const { EstatusCitaGarantia, FolioGarantia, LlamadaId, KilometrajeActual, CarnetServicioId, FolioCarnet, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, TallerAutorizado, GeneralId } = useSelector((state) => state.GarantiaSlice);

    const [mantenimiento, setMantenimiento] = useState();
    const [loading, setLoading] = useState(false);
    const [modalRegistrarMantenimiento, setModalRegistrarMantenimiento] = useState(false);
    const { refFormik } = useRef();
    // const [dataTaller, setDataTaller] = useState([{ value: "1DFDAD16-1544-4EEC-AF00-1CDF4761EA51", label: "Taller 1" }]);
    const [dataTaller, setDataTaller] = useState([]);
    const [configRows, setConfigRows] = useState({ totalRows: 0, currentPage: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [autMantenimiento, setAutMantenimiento] = useState({});

    const [modalAut, setModalAut] = useState(false);
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        if (generalIddata !== "" && generalIddata !== undefined) {
            handleGetMantenimientos(generalIddata, 1)
        }
    }, [generalIddata]);

    useEffect(() => {
        getTalleres();
    }, []);

    const getTalleres = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Taller')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.TallerId,
                        label: item.Taller
                    });
                });

                setDataTaller(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const columMatment = [
        { field: 'fechaMantenimiento', headerName: 'Fecha', width: 150 },
        { field: 'usuario', headerName: 'Usuario', width: 150 },
        { field: 'taller', headerName: 'Taller', width: 150 },
        { field: 'kilometraje', headerName: 'Kilometraje', width: 150 },
        { field: 'fechaHoraRegistro', headerName: 'Fecha y hora de registro', width: 250 },
        { field: 'supervisor', headerName: 'Supervisor', width: 150 },
        { field: 'fechaHoraAutorizacion', headerName: 'Fecha y hora de autorización', width: 250 },
        { field: 'autorizado', headerName: 'Estatus', width: 80 },
    ]
    let initialMantenimiento = {
        GeneralId: generalIddata,
        TallerId: "",
        Kilometraje: "",
        FechaMantenimiento: "",
        Autorizacion: 1,
    }
    const validateMantenimiento = Yup.object({
        TallerId: Yup.string()
            .trim()
            .required("El campo es requerido."),
        Kilometraje: Yup.string()
            .trim()
            .required("El campo es requerido.")
    });
    useEffect(() => {
        if (generalIddata != "") {
            setDataMantenimiento({ ...dataMantenimiento, GeneralId: generalIddata })
        }
    }, [generalIddata])
    const [mantenimientos, setMantenimientos] = useState([]);
    const [dataMantenimiento, setDataMantenimiento] = useState(initialMantenimiento)
    const setInfoMantenimiento = (valor) => {
        setModalRegistrarMantenimiento(valor)
    }
    const handleGetMantenimientos = async (id, selected) => {
        await Llamada.getMantenimientos(id, selected)
            .then(res => {
                const data = res.data.data;
                console.log(data, ";;;;;;;;;;;;;;;;;;")
                if (data) {
                    let datar = []
                    data.forEach(element => {
                        datar.push(
                            {
                                totalRows: element.totalRows,
                                generalId: element.generalId,
                                mantenimientoId: element.mantenimientoId,
                                fechaMantenimiento: formatearFecha(element.fechaMantenimiento),
                                usuario: element.usuario,
                                tallerId: element.tallerId,
                                taller: element.taller,
                                kilometraje: element.kilometraje,
                                fechaHoraRegistro: element.fechaHoraRegistro,
                                supervisor: element.supervisor,
                                fechaHoraAutorizacion: element.fechaHoraAutorizacion ? getDateFormat(element.fechaHoraAutorizacion) : "Sin registro",
                                statusMantenimiento: element.statusMantenimiento,
                                autorizado: element.autorizado,
                            }
                        )
                    });
                    setMantenimientos(datar);
                    let totalRows = parseInt(datar[0].totalRows)
                    let totalPaginas = Math.ceil(totalRows / 10)

                    setConfigRows({
                        ...configRows,
                        totalRows: totalPaginas
                    })
                }
            }).catch(err => {
                return err;
            });
    }

    function formatearFecha(fecha) {

        if (fecha) {
            var partesFecha = fecha.split('/');
            var dia = partesFecha[0];
            var mes = partesFecha[1];
            var año = partesFecha[2];
            return dia + '-' + mes + '-' + año;
        }
    }

    const getDateFormat = (fecha) => {
        if (fecha !== undefined) {
            // Dividir la cadena en partes para extraer día, mes, año, hora, minuto y segundo
            const parts = fecha.match(/(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+) ([APMapm]{2})/);

            if (parts) {
                // Extraer partes correspondientes
                const day = parts[1];
                const month = parts[2] - 1; // Restar 1 al mes (los meses en JavaScript van de 0 a 11)
                const year = parts[3];
                const hour = parts[4];
                const minute = parts[5];
                const second = parts[6];
                const meridian = parts[7].toUpperCase();

                const adjustedHour = meridian === "PM" ? parseInt(hour, 10) + 12 : hour;
                const d = new Date(year, month, day, adjustedHour, minute, second);
                const format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + " " + meridian;

                return format;
            } else {
                return "Formato de fecha incorrecto";
            }
        } else {
            return ""; // O manejar el caso de fecha indefinida según tus necesidades
        }
    }

    const handleAddMantenimiento = async (value) => {
        //FechaMantenimiento
        let data = {
            "GeneralId": value.GeneralId,
            "TallerId": value.TallerId,
            "Kilometraje": value.Kilometraje,
            "FechaMantenimiento": dataMantenimiento.FechaMantenimiento,
            "Autorizacion": value.Autorizacion
        }
        console.log(data, "Guardar")

        setLoading(true);
        await Llamada.AddMantenimiento(data)
            .then(res => {
                toast.success(message("¡Correcto!", res.data.data.mensaje));
                handleGetMantenimientos(generalIddata, 1)
                setDataMantenimiento({
                    GeneralId: generalIddata,
                    TallerId: "",
                    Kilometraje: "",
                    FechaMantenimiento: "",
                    Autorizacion: 1,
                })
            })
            .catch(e => {
                if (e.response !== undefined) {
                    toast.error(message("¡Error.!", e.response.data.message));
                } else {
                    toast.error(message("¡Error.!", e.message));
                }
            })
            .finally(() => {
                setLoading(false);
                toast.success(message("Registro de mantenimiento exitoso", ""));
            })


    }
    const handlePageClick = async (selected) => {
        let sele = selected.selected + 1
        handleGetMantenimientos(generalIddata, sele);
    };
    const handleEdit = async (data) => {
        setAutMantenimiento(data)
        setModalAut(true)
        console.log(data)
    }

    const setDateTime = (value) => {
        console.log(value)
        setDataMantenimiento({
            ...dataMantenimiento,
            FechaMantenimiento: value
        })
    }

    const handlMantenimiento = async (valor) => {

        let data = {
            mantenimientoid: autMantenimiento.mantenimientoId,
            autorizacion: valor
        }

        setLoading(true);
        await Llamada.AutorizarMantenimiento(data)
            .then(res => {
                handleGetMantenimientos(generalIddata, 1)
            })
            .catch(e => {
                if (e.response !== undefined) {
                    toast.error(message("¡Error.!", e.response.data.message));
                } else {
                    toast.error(message("¡Error.!", e.message));
                }
            })
            .finally(() => {
                setModalAut(false);
                setLoading(false);
                toast.success(message("Mantenimiento actualizado", ""));
            })

    }

    const isRowEditable = (item) => {
        return item.autorizado === "Pendiente";
    }
    return <>
        <Toaster
            position="top-right"
            toastOptions={{
                success: {
                    style: {
                        background: '#47a066',
                        color: '#FFFF',
                        borderLeft: '10px solid #2f7246'
                    },
                },
                error: {
                    style: {
                        background: '#d53f3f',
                        color: '#FFFF',
                        borderLeft: '10px solid #ac241a'
                    },
                },
            }}
        />
        <Modal isOpen={modalRegistrarMantenimiento} color='#fff' width={560}>
            <div>
                <Formik
                    innerRef={refFormik}
                    enableReinitialize={true}
                    initialValues={initialMantenimiento}
                    validationSchema={validateMantenimiento}
                >
                    {({ isValid, values, setFieldValue, resetForm, errors }) => (
                        <>
                            <section className='d-flex justify-content-end'>
                                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setInfoMantenimiento(false); resetForm(); }}><i className="ri-close-fill"></i></button>
                            </section>
                            <div className='p-4'>
                                <span><b>Registrar Mantenimiento </b></span>
                                <p>
                                    A continuación captura los datos del mantenimiento del vehículo de acuerdo al carnet de servicio.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <SelectField id="TallerId" label="Taller" name="TallerId" items={dataTaller} onChange={(event) => {
                                            setFieldValue("TallerId", event.value);
                                        }} />
                                    </div>
                                    <div className='col-md-6'>
                                        <TextField id="Kilometraje" label="Kilometraje" name="Kilometraje" type="text" holder="Escribe"
                                            onKeyPress={(event) => {
                                                const charCode = event.which ? event.which : event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    event.preventDefault(); // Evitar la entrada si no es un número
                                                }
                                            }}
                                            onChange={(event) => {
                                                setFieldValue("Kilometraje", event.target.value);
                                            }} />
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className='col-md-6'>
                                        <span>Fecha Mantenimiento</span>
                                        <input type="date" max={today} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDateTime(e.target.value) }} />
                                        {/* <SelectField id="FechaMantenimiento" label="Fecha mantenimiento" name="FechaMantenimiento" items={[]} onChange={(event) => {
                                        setFieldValue("FechaMantenimiento", event.value);
                                    }} /> */}
                                    </div>
                                </div>
                                <div className='row pt-3'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'>
                                        <button className="btn btn-outline col-12" onClick={(e) => { setInfoMantenimiento(false); resetForm(); }}>Cancelar</button>
                                    </div>
                                    <div className='col-md-4'>
                                        <button className="btn saveInfo col-12" disabled={!values.TallerId || !values.Kilometraje} onClick={(e) => { setInfoMantenimiento(false); handleAddMantenimiento(values); resetForm(); }}>Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </Modal>

        <Modal isOpen={modalAut} color='#fff' width={800}>
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalAut(false); }}><i className="ri-close-fill"></i></button>
            </section>
            <div className='p-4'>
                <h5><b>Detalle de mantenimiento</b></h5>
                <div>
                    A continuación muestran los datos del mantenimiento del vehículo para autorizar.
                </div>
                <div className="row pt-1">
                    <div className="col-4"><b>Contrato:</b> {numContrato}</div>
                    <div className="col-6"><b>Folio llamada:</b> {FolioLlamada}</div>
                </div>
                <div className="row pt-4">
                    <div className="col-1">
                        <i className="icon-dark bx bx-car"></i>
                    </div>
                    <div className="col-auto">VIN: {vin}</div>
                    <div className="col-auto">Marca: {nombreMarca}</div>
                    <div className="col-auto">Modelo: {nombreModelo}</div>
                    <div className="col-auto">Año: {nombreYear}</div>
                </div>

                <div className="row pt-2">
                    <div className="col-6">Taller: {autMantenimiento.taller}</div>
                    <div className="col-6">Kilometraje: {autMantenimiento.kilometraje} KM</div>
                </div>
                <div className="row pt-4">
                    <div className="col-1">
                        <i className="icon-dark bx bx-time-five"></i>
                    </div>
                    <div className="col-auto">Fecha mantenimiento: {autMantenimiento.fechaMantenimiento}</div>
                    <div className="col-auto">Fecha registro: {autMantenimiento.fechaHoraRegistro}</div>
                </div>
                <div className="row pt-4">
                    <div className="col-1">
                        <i className="icon-dark ri-user-3-line"></i>
                    </div>
                    <div className="col-6">Ejecutivo: {autMantenimiento.usuario}</div>
                </div>
                <div className="row pt-3 justify-content-end">
                    <div className="col-4 text-end">
                        <button className="btn col-12 btn-outline" onClick={(e) => handlMantenimiento(0)}>Rechazar</button>
                    </div>
                    <div className="col-4 text-end">
                        <button className="btn col-12 saveInfo" onClick={(e) => handlMantenimiento(1)}>Autorizar</button>
                    </div>
                </div>
            </div>
        </Modal>

        {<div className='d-flex flex-column' style={{ height: 'auto' }}>
            <div className='row mx-md-0 my-4' >
                <div className="col col-12 px-md-0">
                    <div className="p-4 white-wrapper mb-2">
                        <div className="row">
                            <div className="col-9">
                                <b><span>Mantenimientos</span></b>
                            </div>
                            <div className="col-3 text-end">
                                <button disabled={FolioGarantia ? false : true} className="btn col-8 col-lg-12 saveInfo" onClick={(e) => setInfoMantenimiento(true)}>Registrar mantenimiento</button>
                            </div>
                        </div>

                        {
                            mantenimientos.length === 0 &&
                            <div className="col-12 text-center">
                                <span>No hay datos que mostrar</span>
                            </div>
                        }
                        {
                            mantenimientos.length > 0 &&
                            <>
                                <DataTable
                                    column={columMatment}
                                    data={mantenimientos}
                                    // editable={ item => item.autorizado === "Pendiente" ? true : false}
                                    editable={isRowEditable}
                                    pageCount={configRows.totalRows}
                                    currentPage={currentPage - 1}
                                    handlePageClick={handlePageClick}
                                    handleEdit={item => item.autorizado === "Pendiente" ? handleEdit(item) : null}
                                />
                            </>
                        }
                    </div>

                </div>
            </div>
        </div>}
    </>
}

export default TablaMantenimiento