import { useEffect, useRef, useState } from "react";
import Solicitudes from "../../Services/ProcesosCierre/Solicitudes";

const AmpliacionExpedientesHook = () => {
    const refFormik = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const columns = [
        { field: 'folio', headerName: 'Folio Expediente', widht: '150px' },
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', widht: '150px' },
        { field: 'motivoAmpliacion', headerName: 'Motivo ampliación', widht: '150px' },
        { field: 'fechaAmpliacion', headerName: 'Fecha ampliación', widht: '150px' },
        { field: 'comentariosTooltip2', headerName: 'Comentarios', widht: '150px' },
    ];

    useEffect(() => {
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar]);

    const getListado = async () => {
        setLoading(true);

        const params = { buscar, page, rows }
        await Solicitudes.getAmpliacionesExpediente(params)
        .then(resp => {
            let items = resp.data.data.map(item => {
                item.comentariosTooltip2 = item.comentariosAmpliacion;
                return item;
            });
            setData(items);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDetail = () => {
        setModalMessage({ isOpen: true, title: 'Solicitud de ampliación expediente', type: 1 });
    }

    return {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail
    }
}

export default AmpliacionExpedientesHook;