import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Section } from '../../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { tabActiveComisionesInventario } from '../../../Redux/Slice/navigateSliceComisionesInventario';

const SubMenuComisionesInventario = () => {
  const { value } = useSelector((state) => state.tabNavigateComisionesInventario);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])

  const handleSetStateTab = (value) => {
    dispatch(tabActiveComisionesInventario(value));
  }
  const handleVigate = (value) => {    
    if (ValidatePermissionById(3, Section.ComisionesDeEmpresa) && value === 1) {           
        navigate(`/inventario/comisiones/comisiondeempresa`);              
    }
    else if (ValidatePermissionById(3, Section.ComisionExterno) && value === 2) {        
        navigate(`/inventario/comisiones/comisionexterno`);              
    }
    else if (ValidatePermissionById(3, Section.CobroComisionExterno) && value === 3) {
      navigate(`/inventario/comisiones/cobrocomisionexterno`);
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ComisionesDeEmpresa)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Comisión de empresa</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ComisionExterno)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Comisión externo</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.CobroComisionExterno)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Cobro comisión externo</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuComisionesInventario