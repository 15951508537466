import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import CardVehiculo from '../../Garantias/devoluciones/CardVehiculo';
import useCambioDevolucion from '../../../Hooks/Garantias/useCambioDevolucion';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import InspeccionUnidadCard from './InspeccionUnidadCard';
import InformacionUltimoCreditoCard from './InformacionUltimoCreditoCard';
import { setComentarioTecnico } from '../../../Redux/Slice/Credito/InformacionUltimoCreditoSlice';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';

const ValuacionesCard = ({}) => {
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const dispatch = useDispatch();
    const { id, generalid, valuacionid } = useParams();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [data, setData] = useState([]);


    let {
        vehiculo
    } = useCambioDevolucion();

    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/procesocierre/valuaciones';
    }

    useEffect(() => {
        getComentarios();
    },[]);

    const getComentarios = async () => {
        let params = {
            Bitacora: 'Valuacion',
            BitacoraId: valuacionid
        }

        await Valuaciones.getComentariosByBitacora(params)
        .then(resp => {
            dispatch(setComentarioTecnico(resp.data.comentariosTecnico));

        })
        .catch(err => {
            console.error(err);
        })
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de valuaciones</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <CardVehiculo data={vehiculo} claves={true}/>
                        </div>
                        <div className="col-6 px-md-3">
                            <InformacionUltimoCreditoCard />
                        </div>
                    </div>
                </div>
            </section>
            <InspeccionUnidadCard />
        </>)
}

export default ValuacionesCard