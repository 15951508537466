import React from 'react'

const BasicModal = ({modalMessage,setModalMessage}) => {
    return (
        <div>
            <div className='justify-content-start align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>{modalMessage.title}</strong></h6>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <span id="AT_TxtBasicModalMenssage">{modalMessage.message.split("||").map((i,idx) => {
                            return <p key={idx}>{i}</p>
                        })}</span>
                    </div>
                </div>
                
                <section className='d-flex justify-content-end'>
                    <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Aceptar</button>
                </section>
            </div>
        </div>
    )
}

export default BasicModal