import React from 'react'
import Garantias from '../../Services/Garantias/Garantias';
import { useEffect } from 'react';
import { useState } from 'react';
import Llamada from '../../Services/Cartera/Llamadas';
import { setGruaSlice } from '../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { useDispatch, useSelector } from 'react-redux'

const useGarantia = () => {
    const dispatch = useDispatch();
    const [str, setStr] = useState("");
    const [llamadasInfo, setLlamadasInfo] = useState([]);
    const [mantenimientos, setMantenimientos] = useState([]);
    const [garantias, setGarantias] = useState([]);
    const [dataVin, setDataVin] = useState({ generalId: "", serieDatamovil: "", fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" });
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [infoTabs, setInfoTabs] = useState(false);
    const [busquedaRealizada, setBusquedaRealizada] = useState(false);

    useEffect(() => {
        if (str.length !== 0) {
            handleGetContract(str);
        }
    }, [str])

    const handleGetContract = async (str) => {
        if (str.trim().length == 0) {
            setBusquedaRealizada(false);
            setLoading(false);
            return false;
        }

        setLoading(true);
        setBusquedaRealizada(true);

        //Llama a los servicios para la busqueda
        await Garantias.getContract(str?.trim())
            .then(async res => {
                if (res.data.statusCode === 204) {
                    const msgNotVin = "El VIN ingresado no se encuentra relacionado a ninguna incidencia.||Revisa el VIN o No. de contrato de tu vehículo e intenta reingresarlo nuevamente para tener acceso a las incidencias registradas.";
                    setStr("");
                    setModalMessage({ ...modalMessage, isOpen: true, type: 3, title: 'No se encontró el vehículo', message: msgNotVin });
                    setDataVin({ ...dataVin, generalId: "", serieDatamovil: "", fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "", numSolicitud: "", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" })
                    return false;
                }

                console.log("UseLLamada 3")

                setDataVin({
                    ...dataVin,
                    generalId: res.data.data.generalId,
                    fechaEntrega: res.data.data.fechaEntrega,
                    vin: res.data.data.vin,
                    nombreMarca: res.data.data.nombreMarca,
                    nombreModelo: res.data.data.nombreModelo,
                    nombreVersion: res.data.data.nombreVersion,
                    nombreYear: res.data.data.nombreYear,
                    color: res.data.data.color,
                    kilometraje: res.data.data.kilometraje,
                    procedenciaAuto: res.data.data.procedenciaAuto,
                    numMotor: res.data.data.numMotor,
                    numContrato: res.data.data.numContrato,
                    numSolicitud: res.data.data.numSolicitud,
                    cliente: res.data.data.cliente,
                    fechaUltimoPago: res.data.data.fechaUltimoPago,
                    producto: res.data.data.producto,
                    usoUnidad: res.data.data.usoUnidad,
                    serieDatamovil: res.data.data.serieDatamovil
                })
                handleGetLlamadas(res.data.data.generalId)
                handleGetGarantias(res.data.data.generalId)
                handleGetMantenimientos(res.data.data.generalId)

                setLoading(false);
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })
    }

    const handleGetLlamadas = async (id) => {
        return await Garantias.getLlamadas(id)
            .then(res => {
                const data = res.data.data;

                const datai = []

                data.map(element => {
                    datai.push({
                        folioLlamada: element.folioLlamada,
                        fechaReporte: element.fechaReporte,
                        ejecutivo: element.ejecutivo,
                        motivo: element.motivo,
                        comentariosCliente: element.comentariosCliente,
                    })
                });

                setLlamadasInfo(datai);
            })
            .catch(err => {
                // Manejo de errores
                console.log(err)
            });
    }

    const handleGetGarantias = async (id) => {
        await Garantias.getGarantias(id)
            .then(res => {
                const data = res.data.data;
                let datar = []
                data.forEach(element => {
                    datar.push(
                        {
                            folioGarantia: element.folioGarantia,
                            fechaReporte: element.fechaReporte,
                            ejecutivo: element.ejecutivo,
                            tipoGarantia: element.tipoGarantia,
                            tipoReporte: element.tipoReporte,
                            reparacionUnidad: element.reparacionUnidad,
                            estatusGarantia: element.estatusGarantia,
                            ultimaActualizacion: element.ultimaActualizacion,
                            estatusIncidencia: element.estatusIncidencia,
                        }
                    )
                });
                setGarantias(datar);

            }).catch(err => {
                return [];
            });
    }

    const handleGetMantenimientos = async (id) => {
        await Garantias.getMantenimientos(id)
            .then(res => {
                const data = res.data.data;
                let datar = []
                data.forEach(element => {
                    datar.push(
                        {
                            totalRows: element.totalRows,
                            generalId: element.generalId,
                            mantenimientoId: element.mantenimientoId,
                            fechaMantenimiento: element.fechaMantenimiento,
                            usuario: element.usuario,
                            tallerId: element.tallerId,
                            taller: element.taller,
                            kilometraje: element.kilometraje,
                            fechaHoraRegistro: element.fechaHoraRegistro,
                            supervisor: element.supervisor,
                            fechaHoraAutorizacion: element.fechaHoraAutorizacion,
                            statusMantenimiento: element.statusMantenimiento,
                            autorizado: element.autorizado,

                        }
                    )
                });
                setMantenimientos(datar);
            }).catch(err => {
                return [];
            });
    }

    const handlerGetGruas = async (GarantiaId) => {
        await Llamada.GetGruabyBarantiaId(GarantiaId)
            .then(res => {
                let dataRes = res.data.data
                const empresaIdEnMinusculas = dataRes.empresaId.toLowerCase();
                dispatch(setGruaSlice({
                    EmpresaId: empresaIdEnMinusculas,
                    GruaAsignada: dataRes.gruaAsiganada,
                    costoGrua: dataRes.costoGrua ? dataRes.costoGrua : "",
                    Total:dataRes.total,
                    IVA : dataRes.iva,
                    EstadoId: dataRes.estadoId,
                    MunicipioId: dataRes.municipioId,
                    ColoniaId: dataRes.coloniaId,
                    Calle: dataRes.calle,
                    Numero: dataRes.numero,
                    TallerAutorizado: dataRes.tallerAutorizado,
                    ReferenciaUbicacion: dataRes.referenciaUbicacion,
                    FechaCitaCalendario: dataRes.fechaCitaCalendario ? dataRes.fechaCitaCalendario : "",
                    StatusCita: dataRes.statusCita,
                    GruasId: dataRes.gruasId ? dataRes.gruasId : "",
                    BloqueoIdG: dataRes.bloqueoId || null,
                    FolioGrua: dataRes.folioGrua || "Sin Folio",
                    EjecutivoGarantia: dataRes.ejecutivoGarantia,
                    GruaStatusId: dataRes.gruaStatusId || null
                }))
            })
            .catch(e => {
                if (e.response !== undefined) {
                    // toast.error(message("¡Error.!", e.response.data.message));
                } else {
                    // toast.error(message("¡Error.!", e.message));
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        dataVin,
        setDataVin,
        loading,
        setModalMessage,
        modalMessage,
        setStr,
        str,
        busquedaRealizada,
        infoTabs,
        llamadasInfo,
        setLlamadasInfo,
        garantias,
        setGarantias,
        mantenimientos,
        setMantenimientos,
        handleGetContract,
        handleGetGarantias,
        handlerGetGruas
    }

}

export default useGarantia