import axios from '../../Utils/ApiService';
import serviceDataDocs from '../../Utils/ApiServiceDataDocs';

const dataDocsServices = {
    getListDocs:(generalId,TipoCompraId,gestoria,productoId)=> {        
        return axios.get(`/functions/GetListaDocumentos?GeneralId=${generalId}&TipoCompraId=${TipoCompraId}&DocGestoria=${gestoria}&ProductoId=${productoId}`);
    },   
    UploadDocumentDataDocs:(data)=> {        
        return serviceDataDocs.post(`/functions/Documento`,data);
    },   
    UpdateTokenDataDc :() =>{
        return axios.put(`/functions/UsarTokenDataDocs`);
    },
    addInfoDataDocs :(data) =>{
        return axios.post(`/functions/AddDocumentosCargados`,data);
    },
    DeleteDocs :(idDocs,generalId) =>{
        return axios.delete(`/functions/DelDocumentosCargados?DocumentosDataDocsId=${idDocs}&GeneralId=${generalId}`);
    },
    DeleteDocDatadocs :(binarioid) =>{
        return axios.delete(`/functions/DelDocumentoDataDocs?RelacionBinarioId=${binarioid}`);
    },
    getListDocsDeleted : (page,rows,string,TipoDocumentoId,fecha) =>{
        return axios.get(`/functions/GetListaPapeleraDocumentos?Page=${page}&Rows=${rows}&Buscar=${string}&TipoDocumentoId=${TipoDocumentoId}&FiltroFecha=${fecha}`);
    },
    PermanentDeleteDocs :(idDocs,generalId) =>{
        return axios.delete(`/functions/EliminarRegistroDocumento?DocumentosDataDocsId=${idDocs}&GeneralId=${generalId}`);
    },
    FileRecovery:(idDocs,generalId) =>{
        return axios.put(`/functions/RecuperarDocumentoEliminado?DocumentosDataDocsId=${idDocs}&GeneralId=${generalId}`);        
    },
    LogsDatadocs:(data) =>{
        return axios.post(`/functions/LogsDataDocs`,data);        
    },
    getBanderaEtapa:(fichero,etapa,origen) =>{
        return axios.get(`/functions/GetBanderaEtapa?FicheroId=${fichero}&EtapaId=${etapa}&OrigenId=${origen}`);        
    },
    getPermisoById : async (documentoId,rolId) =>{
        return await axios.get(`/functions/GetPermisoById?TipoDocumentoId=${documentoId}&RolId=${rolId}`);
    },
}

export default dataDocsServices