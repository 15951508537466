import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import pruebaManejoService from '../../../Services/Venta/Vehiculo/pruebaManejoService';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { AddDays_CurrentDate, message } from '../../../Utils/functions';

const LiberarPruebaManeModal = ({ isOpen, setIsOpen, handleGetAll, toast, item }) => {
    const refbtn = useRef();

    let initial = {
        // mantenerPrueba: true,
        // diasMantenerPruebaManejo: AddDays_CurrentDate(1),// new Date().toISOString().split('T')[0],
        pruebadeManejoId: item?.pruebadeManejoId ?? "",
        motivoLiberacionSeparadoId: "",
        comentarios: "",
        generalId: item.generalId,
        desc_liberacion:""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataMotivo, setdataMotivo] = useState([]);
    const [dataEndPM, setDataEndPM] = useState("");
    const validate = Yup.object({
        // diasMantenerPruebaManejo: Yup.string()
        //     .required("El campo es requerido."),
        motivoLiberacionSeparadoId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+(?: [a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
    });

    useEffect(() => {
        if (item.separado)
            handleGetAllMotivoLiberarSeparado();
        else
            handleGetAllMotivoLiberarPrubaManejo();

        handleGetParameters();
    }, [])

    const handleGetParameters = async () => {
        await IntelimotorAPI.getValorParametro("DiasMantenerPruebaManejo")
            .then(res => {
                let date = AddDays_CurrentDate(res.data.data.valor);
                setDataEndPM(date);
            }).catch(e => {
            })
    }
    const handleGetAllMotivoLiberarSeparado = async () => {
        await pruebaManejoService.getAllMotivoLiberar()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoLiberacionSeparadoId,
                        label: item.motivoSeparado
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllMotivoLiberarPrubaManejo = async () => {
        await Catalogo.getAllMotivoLiberarPM()
            .then(res => {                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoLiberacionPruebaManejoId,
                        label: item.motivo
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        if (item.separado) {
            await pruebaManejoService.AddLiberarSeparado(value)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    if (res.data.data.ban === 0) {
                        toast.error(message("¡Error.!", res.data.data.mensaje));
                    } else
                        toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));

                })
        } else {
            await pruebaManejoService.AddLiberarPruebaManejo(value)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message("¡Correcto.!", res.data.data.message));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));

                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='d-flex flex-column  mx-4'>
                        <span className='fs-5 mb-2'><strong>Liberar unidad</strong></span>
                        <span style={{ fontSize: '13px' }}>A continuación debes capturar los siguientes datos para liberar la unidad.</span>
                    </div>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <SelectField label="Motivo" name="motivoLiberacionSeparadoId" items={dataMotivo} onChange={(event) => {
                                        setFieldValue("motivoLiberacionSeparadoId", event.value);
                                        setFieldValue("desc_liberacion", event.label);
                                        
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default LiberarPruebaManeModal