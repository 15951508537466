import React from 'react'

const ErrorModal = ({modalMessage,setModalMessage}) => {
    return (
        <div>
            <section className='d-flex justify-content-end'>
                <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
            </section>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <i className="ri-close-circle-line" style={{ fontSize: '70px', color: 'red' }}></i>
                <span id="AT_TxtModalMenssage" className='fs-6'><strong>{modalMessage.message}</strong></span>
            </div>
        </div>
    )
}

export default ErrorModal