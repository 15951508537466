import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useSeguimientoProcesoPagoPR from '../../../Hooks/Gestoria/PagoRefrendoPlacas/useSeguimientoProcesoPagoPR'
import UploadFileGestoria from '../../../Components/UploadFileGestoria'
import GestoriaHomeGestoria from '../GestoriaHomeSeguimiento'
import CrearGrupoModal from './CrearGrupoModal'
import DesagruparModalConfim from './DesagruparModalConfim'
import DesagruparModalNotificacion from './DesagruparModalNotificacion'
import RealizarPagoModal from './RealizarPagoModal'
const SeguimientoProcesoPagoPR = () => {
    const {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        handleDownloadExcel,
        selectRef,
        navigate,
        setIsOpenModalFile,
        isOpenModalFile,
        handleGetAlllist,
        estatusVehiculoData,
        tipoVehiculoData,
        setClaveStatusSeguimiento,
        setClaveTipoVehiculo,
        newState,
        infoData,
        setOpenModalGrupo,
        openModalGrupo,
        handleCrearGrupo,
        handleActionGestoria,
        setOpenModalDesagrupar,
        openModalDesagrupar,
        itemSelected,
        setOpenModalPago,
        openModalPago,
        habdleGetAll,
        setOpenModalNotificacion,
        openModalNotificacion,
        dataNotificacion,
        handleRealizarPago,
        role
    } = useSeguimientoProcesoPagoPR(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                <i className="bx bxs-chevron-left mx-2" onClick={() => {
                    navigate(-1)
                }}></i>
                Regresar a listado de incidencias
            </header>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Seguimiento de proceso de pago</strong></span>
                    <div>
                        {
                            (role === "Administrador" || role === "Gestor") &&
                            <button className='btn mx-1' onClick={() => { setIsOpenModalFile(true) }}>Parque vehícular</button>
                        }
                        {
                            (data && newState.estatusId !== 1) &&
                            <>
                                <button className='btn mx-1' onClick={() => { setOpenModalGrupo(true) }}>Crear grupos</button>
                                <button className='btn mx-1' onClick={handleDownloadExcel}>Exportar excel</button>
                            </>
                        }
                    </div>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={selectRef} options={estatusVehiculoData} className='col-12' onChange={(e) => { setClaveStatusSeguimiento(e.value) }} placeholder="Estatus" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={selectRef} options={tipoVehiculoData} className='col-12' onChange={(e) => { setClaveTipoVehiculo(e.value) }} placeholder="Tipo de vehículo" />
                        </div>
                    </div>
                </section>
                <section className='px-4 row'>
                    <div className='py-3 px-1 d-flex justify-content-around col-lg-4 col-md-6 col-sm-12' style={{ background: '#F5F5F5', borderRadius: '4px' }}>
                        <section className='px-2'>
                            <span><strong>Parque Vehícular</strong></span> <br />
                            <span className='fw-bold fs-5'>{infoData?.parqueVehicular ?? '-'}</span>
                        </section>
                        <span className='d-block' style={{ background: '#BDBDBD', width: '1px', height: '50px' }}></span>
                        <section className='px-2'>
                            <span><strong>Sin agrupar</strong></span> <br />
                            <span className='fw-bold fs-5'>{infoData?.sinAgrupar ?? '-'}</span>
                        </section>
                        <span className='d-block' style={{ background: '#BDBDBD', width: '1px', height: '50px' }}></span>
                        <section className='px-2'>
                            <span><strong>Pagados</strong></span> <br />
                            <span className='fw-bold fs-5'>{infoData?.pagados ?? '-'}</span>
                        </section>
                    </div>
                    <div className='py-3 px-1 d-flex col-lg-3 col-md-4 col-sm-12 justify-content-around ms-2' style={{ background: '#F5F5F5', borderRadius: '4px' }}>
                        <section className='px-2'>
                            <span><strong>Monto por pagar</strong></span> <br />
                            <span className='fs-5'> <strong>{infoData?.montoPorPagarStr ?? '-'}</strong></span>
                        </section>
                        <span className='d-block' style={{ background: '#BDBDBD', width: '1px', height: '50px' }}></span>
                        <section className='px-2' style={{ color: 'green' }}>
                            <span><strong>Monto pagado</strong></span> <br />
                            <span className='fw-bold fs-5'>{infoData?.montoPagadoStr ?? '-'}</span>
                        </section>
                    </div>
                </section>
                {
                    (newState.estatusId !== 1) &&
                    <>
                        <RowPages
                            setpageRows={setpageRows}
                        />
                        <div className='mx-4 table-responsive tbl-scroll'>
                            <DataTable
                                column={columns}
                                data={data}
                                iswidth={true}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                                pagadoPR={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Ver}
                                desagrupable={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Ver}
                                handleAction={handleActionGestoria}
                                isStycky={true}
                            />
                        </div>
                    </>

                }
                {
                    (newState.estatusId === 1) &&
                    <GestoriaHomeGestoria />
                }
            </ValidatePermission>
            {
                isOpenModalFile &&
                <UploadFileGestoria
                    isOpenModal={isOpenModalFile}
                    setIsOpenModal={setIsOpenModalFile}
                    handleSubmit={handleGetAlllist}
                />
            }
            {
                openModalGrupo &&
                <CrearGrupoModal
                    isOpen={openModalGrupo}
                    setIsOpen={setOpenModalGrupo}
                    handleSubmit={handleCrearGrupo}
                    tipoVehiculo={tipoVehiculoData}
                />
            }
            {
                openModalDesagrupar &&
                <DesagruparModalConfim
                    isOpen={openModalDesagrupar}
                    setIsOpen={setOpenModalDesagrupar}
                    item={itemSelected}
                    toast={toast}
                    habdleGetAll={habdleGetAll}
                />
            }
            {
                openModalNotificacion &&
                <DesagruparModalNotificacion
                    isOpen={openModalNotificacion}
                    setIsOpen={setOpenModalNotificacion}
                    item={dataNotificacion}
                />
            }
            {
                openModalPago &&
                <RealizarPagoModal
                    isOpen={openModalPago}
                    setIsOpen={setOpenModalPago}
                    item={itemSelected}
                    handleSubmit={handleRealizarPago}
                />
            }
        </>
    )
}

export default SeguimientoProcesoPagoPR