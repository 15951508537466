import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { tabActivePrealta } from '../../../Redux/Slice/navigateSlicePrealta';
import handlePhonNumberFormatter, { FormatNumberMoney, message } from '../../../Utils/functions';
import SubMenuPrealta from '../Prealta/subMenuPrealta';

const VehiculoValoracion = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const { value } = useSelector((state) => state.navigaSlicePrealta);
    const dispatch = useDispatch();
    const refFormik = useRef();

    let initial = {
        generalId: id,
        valorCompraGuia: "",
        valorVentaGuia: "",
        nombreUltimoPropietario: "",
        poseedorDelVehiculo: "",
        aplicaIntermediario: "",
        calle: "",
        numExterior: "",
        numInterior: "",
        codigoPostal: "",
        coloniaId: "",
        municipioId: "",
        estadoId: "",
        telIntermediario: "",
        correoIntermediario: "",
        ventaContado: state?.ventaContado,
        vehiculoEnCredito: state?.vehiculoEnCredito
    }
    const dataAplica = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        valorCompraGuia: Yup.string()
            .required("El campo es requerido."),
        // .positive("Ingrese valores positivos"),
        // .max(4,"El campo debe contener como minimo 4 caracteres"),
        valorVentaGuia: Yup.string()
            .required("El campo es requerido."),
        // .positive("Ingrese valores positivos"),
        // .max(4,"El campo debe contener como minimo 4 caracteres"),
        nombreUltimoPropietario: Yup.string()
            .required("El campo es requerido."),
        poseedorDelVehiculo: Yup.string()
            .required("El campo es requerido."),
        aplicaIntermediario: Yup.bool()
            .required("El campo es requerido."),
        calle: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        numExterior: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        // numInterior: Yup.string()
        //     .when("aplicaIntermediario", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        codigoPostal: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        coloniaId: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        municipioId: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        estadoId: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        telIntermediario: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)")
                    // .positive("Ingrese un valor positivo.")
                    .typeError("Ingrese valores numericos"),
            }),
        correoIntermediario: Yup.string()
            .when("aplicaIntermediario", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
                    .email("Ingrese un correo valido.")
            }),

    });
    const [dataEstado, setDataEstado] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);
    const [dataStatus, setDataStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveYear, setClaveYear] = useState("");
    const [activeComision, setActiveComision] = useState(false);
    const [isconsignacion, setIsconsignacion] = useState(false);

    const [progressBar, setProgressBar] = useState(0);

    useEffect(() => {
        handleGetAllEdoMexico();
        handleGetAllStatusDatamovil();

        // if (state.etapaId === 3 || state.etapaId === 11 || state.etapaId === 5 || state.etapaId === 15) {
        if (state.etapaId >= 3) {
            handleGetById();
            handleChageProgress(refFormik.current.errors);
        }
    }, [])

    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getlAllVehiculoValoracionById(id)
            .then(res => {
                let item = res.data.data;

                let initial = {
                    generalId: id,
                    valorCompraGuia: item?.valorCompraGuia ?? "",
                    valorVentaGuia: item?.valorVentaGuia ?? "",
                    nombreUltimoPropietario: item?.nombreUltimoPropietario ?? "",
                    poseedorDelVehiculo: item?.poseedorDelVehiculo ?? "",
                    aplicaIntermediario: item?.aplicaIntermediario ?? "",
                    calle: item?.calle ?? "",
                    numExterior: item?.numExterior ?? "",
                    numInterior: item?.numInterior ?? "",
                    codigoPostal: item?.codigoPostal ?? "",
                    coloniaId: item?.coloniaId ?? "",
                    municipioId: item?.municipioId ?? "",
                    estadoId: item?.estadoId ?? "",
                    telIntermediario: item?.telIntermediario ?? "",
                    correoIntermediario: item?.correoIntermediario ?? "",
                    ventaContado: state?.ventaContado,
                    vehiculoEnCredito: state?.vehiculoEnCredito
                }
                setInitialValue(initial);
                if (item?.aplicaIntermediario) {
                    handleGetAllMunicipioById(item.estadoId);
                    handleGetAllColoniaById(item.municipioId);
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllStatusDatamovil = async () => {
        setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let item = res.data.data;

                setDataStatus(item.statusDatamovil);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllEdoMexico = async () => {
        await Catalogo.getdataTabla("EdoMExico", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.edoMexicoId,
                            label: item.estado
                        })
                    }
                });
                setDataEstado(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllMunicipioById = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.municipioId,
                            label: item.municipio
                        })
                    }
                });
                setDataMunicipio(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllColoniaById = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.coloniaId,
                            label: item.colonia
                        })
                    }
                });
                setDataColonia(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (value) => {
        // refbtn.current.disabled = true;

        if (state.etapaId !== 3) {
            setLoading(true);
            await VentaVehiculoService.addVehiculoValoracion(value)
                .then(res => {
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    setLoading(false);
                    if (state?.esSocioComercial)
                        navigate('/inventario/sociocomercial')
                    else if (state?.ventaContado)
                        navigate('/inventario/venta/ventacontado');
                    else
                        navigate('/inventario/vehiculo')
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else if (state.etapaId === 3) {
            setLoading(true);
            await VentaVehiculoService.UpdVehiculoValoracion(value)
                .then(res => {
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    setLoading(false);
                    if (state?.esSocioComercial)
                        navigate('/inventario/sociocomercial')
                    else
                        navigate('/inventario/vehiculo')
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }
    const handleChange = (value, valuesState) => {
        setActiveComision(value.value !== "" ? true : false);
        if (value.label === "Monto Fijo")
            setInitialValue({ ...valuesState, isMontoFijo: true, tipoComisionId: value.value, comision: "" });
        else
            setInitialValue({ ...valuesState, isMontoFijo: false, tipoComisionId: value.value, comision: "" });


    }

    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 14;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const status = () => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                // Eliminar el formato moneda para que esta pueda ser almacenada en base de datos.
                console.log(values);
                values.valorCompraGuia = values.valorCompraGuia.replace(/,/g, "");
                values.valorVentaGuia = values.valorVentaGuia.replace(/,/g, "");
                handleAddEdit(values);

            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial')
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehiculo</strong></span>
                                            <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset>
                                        </div>
                                        <div className='d-flex'>
                                            {
                                                !values.vehiculoEnCredito &&
                                                <>
                                                    <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                                        if (state.numIdFolder !== null) {
                                                            navigate(`/inventario/vehiculo/datadocs/${id}`, {
                                                                state: state,
                                                            });
                                                        } else
                                                            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);

                                                    }}><i className="icon ri-file-3-fill mx-1" ></i>Subir documentos</fieldset>
                                                    <button className='btn mx-2' type="submit" >{state.etapaId === 3 ? "Editar" : "Guardar"}</button>
                                                </>
                                            }
                                            {
                                                values.vehiculoEnCredito &&
                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                            }

                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <p><strong>Valores de guía</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} label="Valor compra guía EBC:" name="valorCompraGuia" icon="bi bi-currency-dollar" isMoney={true} type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("valorCompraGuia", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} label="Valor de venta guía:" name="valorVentaGuia" icon="bi bi-currency-dollar" isMoney={true} type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("valorVentaGuia", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Historial de propietarios</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} label="Nombre del último dueño:" name="nombreUltimoPropietario" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("nombreUltimoPropietario", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} label="Poseedor material de vehículo:" name="poseedorDelVehiculo" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("poseedorDelVehiculo", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Intermediario</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} label="Aplica intermediario:" name="aplicaIntermediario" items={dataAplica} onChange={(event) => {
                                                    setFieldValue("aplicaIntermediario", event.value);

                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            {
                                                values.aplicaIntermediario &&
                                                <>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={values.vehiculoEnCredito} label="Estado:" name="estadoId" items={dataEstado} onChange={(event) => {
                                                            setFieldValue("estadoId", event.value);
                                                            setFieldValue("municipioId", "");
                                                            setFieldValue("coloniaId", "");
                                                            handleGetAllMunicipioById(event.value);
                                                            handleChageProgress(errors);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={values.vehiculoEnCredito} label="Municipio:" name="municipioId" items={dataMunicipio} onChange={(event) => {
                                                            setFieldValue("municipioId", event.value);
                                                            setFieldValue("coloniaId", "");
                                                            handleGetAllColoniaById(event.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        {
                                            values.aplicaIntermediario &&
                                            <>
                                                <div className="mb-3 row">
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={values.vehiculoEnCredito} label="Colonia:" name="coloniaId" items={dataColonia} onChange={(event) => {
                                                            setFieldValue("coloniaId", event.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Calle:" name="calle" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("calle", event.target.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Número exterior:" name="numExterior" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("numExterior", event.target.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Número interior:" name="numInterior" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("numInterior", event.targetvalue);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="CP:" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("codigoPostal", event.target.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Télefono intermediario:" name="telIntermediario" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("telIntermediario", handlePhonNumberFormatter(event.target.value));
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">

                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={values.vehiculoEnCredito} label="Correo intermediario:" name="correoIntermediario" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("correoIntermediario", event.target.value);
                                                            handleChageProgress(errors);

                                                        }} />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                </Form>

                            </div>

                        </section>
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default VehiculoValoracion