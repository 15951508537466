import axios from '../../Utils/ApiService';

const UnidadesRemateService  ={
   GetAllValuaciones: (data) =>{
      return axios.post(`/cartera/GetValuacionAnything`,data)
   },
   CreateValuacionUnidadesRemate: (data) => {
      return axios.post(`/cartera/UpsertValuacionAnything`,data)
   },
}

export default UnidadesRemateService;