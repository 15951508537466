import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';
import { tabActivePolizasCancelada } from '../../../Redux/Slice/navigateSlicePolizasCanceladas';

const SubMenuPolizasCanceladas = () => {
  const { value } = useSelector((state) => state.tabNavigatePolizaCancelada);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActivePolizasCancelada(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.PolizasNoPagadas) && value === 1) {
      navigate(`/seguro/polizacancelada/polizasnopagadas`);
    }
    if (ValidatePermissionById(3, Section.PolizasPagadas) && value === 2) {
      navigate(`/seguro/polizacancelada/polizaspagadas`);
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PolizasNoPagadas)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pólizas no pagadas</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PolizasPagadas)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pólizas pagadas</button>
          </li>
        </ValidatePermission>       
      </ul>
    </>
  )
}

export default SubMenuPolizasCanceladas