import axios from '../../Utils/ApiService';

const GeneracionExpedientesService = {    
    GetAllDocumentoVisita: async () => {
        return await axios.post(`/Cartera/GetAllDocumentoVisita`);
    },
    GetAllGeneracionExpedientes :async(page,rows,nivelCoranza,cobrador,tipoPersona)=>{
        return await axios.get(`/Cartera/GetAllGeneracionExpedientes?page=${page}&Rows=${rows}&NivelCobranza=${nivelCoranza}&Cobrador=${cobrador}&TipoPersona=${tipoPersona}`);
    },
    GenerarPlantillaExpediente: async (data) =>{
        return await axios.post(`/Cartera/GenerarPlantillaExpediente`,data,{ responseType: 'blob' });
    }
}

export default GeneracionExpedientesService