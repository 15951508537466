import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Citas from '../../../Services/Cartera/Citas';
import SubmenuCitasPendientes from './SubmenuCitasPendientes';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Formik } from 'formik'
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';


const CitasPorAutorizarGruas = () => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', authorizationType: 1, Origin: "Listado" });
    const [getData, setGetData] = useState(false);
    let initial = {
        buscar: "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });

    useEffect(() => {
        if (getData === true) {
            getCitas()
        }
    }, [modalMessage]);

    const validate = Yup.object({});

    const columns = [
        { field: 'folioLlamada', headerName: 'Folio Llamada', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 150 },
        { field: 'numContrato', headerName: 'Contrato', width: 150 },
        { field: 'ejecutivoGarantia', headerName: 'Ejecutivo de Garantía', width: 150 },
        { field: 'fechaCita', headerName: 'Fecha y Hora de cita', width: 150 },
        { field: 'ubicacion', headerName: 'Ubicación', width: 150 },
    ];

    const handleBuscar = (value, valueState) => {
        setInitialValue({ ...valueState, buscar: value });
    }

    const getCitas = async () => {
        setLoading(true);
        await Citas.getAgendaCitas('GetAgendaGruas', null, initialValue.buscar, 0)
            .then(resp => {
                setData(resp.data);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }

    const handleAutorizaCita = (data) => {
        console.log(data)
        setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 2, Origin: "Listado" })
        setGetData(true)
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <DetailAuthCitaModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 4 &&
                            <RechazaCitaModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        <SubmenuCitasPendientes />
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <strong className='mt-2'>Citas de grúas por autorizar</strong>
                                <span className='mt-2'>A continuación, se muestran todas las citas de garantías pendientes por autorizar.</span>
                                <div className='col-lg-8 col-sm-12'>
                                    <div className="row">
                                        <div className="col-4 mt-2 mr-1 inner-addon right-addon">
                                            <i className="glyphicon fas fa-search"></i>
                                            <input type="text" className="form-control mb-4" id="buscar" name="buscar" placeholder="Buscar" onBlur={(event) => {
                                                setFieldValue("buscar", event.value);
                                                handleBuscar(event.value, values);
                                            }} />
                                        </div>
                                        <div className="col-4 mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => getCitas()}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-12">
                                        <DataTable
                                            column={columns}
                                            data={data?.data}
                                            editable={false}
                                            pageCount={1}
                                            autorizaCita={true}
                                            handleAutorizaCita={handleAutorizaCita}
                                            // handlePageClick={handlePageClick}
                                            currentPage={data?.currentPage - 1}
                                        // checkbox={true}
                                        // handleCheck={handlechekedItem}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default CitasPorAutorizarGruas;
