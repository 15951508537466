import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { hasFormSubmit } from '@testing-library/user-event/dist/utils';

const ModalExpediente = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let initial = {
        generalId: item?.generalId ?? "",
        filename: item.filename,
        contrato: item?.contrato,
        sol_id: item?.sol_id ?? "",
        vin: item?.vin ?? "",
        status_datamovil: item?.statusDatamovil ?? "",
        legibilidad: false,
        tipoExpedienteId: "d994665a-651f-49c2-8ea9-e798eeb2e247",
        tipoExpediente: "Vehículo",
        ubicacionId: "",
        ubicacion:"",
        entregadoPor: "",
        aNombreDeId: "",
        anombreDe: "",
        aNombreDeTest: item?.aNombreDe ?? "",
        recibidoAtencionClientes: "",
        fechaRecibidoAtencionClientes: "",
        recibidoContraloria: "",
        fechaRecibidoContraloria: "",
        endosado: false,
        endosadoTest: item?.endosado,
        facturado: false,
        facturadoTest: item?.facturado,
        id_datamovil: item?.id_datamovil,
        status_datamovil: item?.status_datamovil


    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataExpediente, setdataExpediente] = useState([]);
    const [TipoUbicacion, setTipoUbicacion] = useState([]);
    const [dataAnombreDe, setDataAnombreDe] = useState([]);
    const [openModalconfirm, setOpenModalconfirm] = useState(false);

    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
        ubicacionId: Yup.string()
            .required("El campo es requerido."),
        tipoExpedienteId: Yup.string()
            .required("El campo es requerido."),
        entregadoPor: Yup.string()
            .required("El campo es requerido."),
        aNombreDeId: Yup.string()
            .required("El campo es requerido."),
        recibidoAtencionClientes: Yup.string()
            .required("El campo es requerido."),
        fechaRecibidoAtencionClientes: Yup.string()
            .required("El campo es requerido."),
        recibidoContraloria: Yup.string()
            .required("El campo es requerido."),
        fechaRecibidoContraloria: Yup.string()
            .required("El campo es requerido."),
    });


    useEffect(() => {
        handleGetAllTipoExpediente();
        handleGetTipoUbicacion();
        handleGetAllANombreDe();
    }, [])

    const handleGetAllTipoExpediente = async () => {
        await Catalogo.getAllCSTipoExpediente()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoExpedienteId,
                        label: item.tipoExpediente
                    })
                });
                setdataExpediente(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllANombreDe = async () => {
        await Catalogo.getAllCSANombreDe()
            .then(res => {
                let NewData = [];
                console.log(res.data.data)
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.nombreId,
                        label: item.nombre
                    })
                });
                setDataAnombreDe(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (item) => {
        submit(item);
    }
    const handleGetTipoUbicacion = async () => {
        await Catalogo.getdataTipoUbicacion("Archivo")
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.ubicacion
                    })
                });
                setTipoUbicacion(NewData);
            }).catch(e => {
            })
    }
    const handleIsValid = (values) =>{
        return (values.legibilidad && values.endosado && values.facturado) ? false:true;
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {                
                if (values.aNombreDeTest !== values.anombreDe || values.facturadoTest !== values.facturado || values.endosadoTest !== values.endosado) {
                    setOpenModalconfirm(true);                    
                } else
                    handleAddEdit(values);
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons' style={{ width: '35rem' }}>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2">
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                <label style={{ fontSize: '14px' }}><strong>2. Detalle de expediente</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    Captura los siguientes datos y recuerda validar la fecha de entrega.
                                </span>
                                <div className="mb-3 row mt-2">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <SelectField id="tecnicoID" label="Tipo de Expediente" name="tipoExpedienteId" items={dataExpediente} onChange={(event) => {
                                            setFieldValue("tipoExpedienteId", event.value);
                                            setFieldValue("tipoExpediente", event.label);
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <SelectField id="tecnicoID" label="Ubicación del Expediente" name="ubicacionId" items={TipoUbicacion} onChange={(event) => {
                                            setFieldValue("ubicacionId", event.value);
                                            setFieldValue("ubicacion", event.label);
                                            
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="nSerieId" label="Entregado por" name="entregadoPor" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <SelectField id="tecnicoID" label="A nombre de" name="aNombreDeId" items={dataAnombreDe} onChange={(event) => {
                                            setFieldValue("aNombreDeId", event.value);
                                            setFieldValue("anombreDe", event.label);
                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="contratoID" label="Recibido por Atención a Clientes" name="recibidoAtencionClientes" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="contratoID" label="Fecha recibido atención a cliente" name="fechaRecibidoAtencionClientes" type="date" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="contratoID" label="Recibido por Contraloría" name="recibidoContraloria" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <TextField id="contratoID" label="Fecha recibido contraloría" name="fechaRecibidoContraloria" type="date" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="endosado" />
                                        <span className='ms-2'>  Auto endosado</span>
                                    </div>
                                    <div className="ccol-sm-12 col-md-6 col-lg-6">
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="facturado" />
                                        <span className='ms-2'>  Auto facturado</span>
                                    </div>
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={handleIsValid(values)}>Confirmar</button>
                            </section>
                        </Form>
                    </div>
                    {
                        openModalconfirm &&
                        <Modal isOpen={openModalconfirm} color='#fff'>
                            <section className='my-4 mx-4' style={{width:'27rem'}}>
                                <h5><strong>Información no coincide</strong></h5>

                                <span style={{ fontSize: '13px' }}>
                                    El endoso, a nombre de y facturado que se capturaron no coincide.
                                    Revisa la información capturada nuevamente si deseas hacer algún cambio,
                                    o confirma los cambios para terminar el proceso.
                                </span>

                                <section className='my-3 d-flex justify-content-end'>
                                    <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setOpenModalconfirm(false);}}>Cancelar</fieldset>
                                    <fieldset className='btn mx-2 px-5' onClick={()=>{handleAddEdit(values)}} >Continuar</fieldset>
                                </section>

                            </section>
                        </Modal>
                    }
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalExpediente