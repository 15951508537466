import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { TextAreaField } from '../../../Components/TextAreaField';

const ModalConfirmGestoriaRechazada = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let initial = {
        generalId: item?.generalId ?? "",
        filename: item.filename,
        sol_id: item?.sol_id ?? "",
        vin: item?.vin ?? "",
        id_datamovil: item?.id_datamovil ?? "",
        status_datamovil: item?.status_datamovil ?? "",
        contrato: item?.contrato ?? "",
        legibilidad: false,
        motivoCancelacionGestoriaId: "",
        motivo: "",
        comentarios: "",
        nombreMarca: item?.nombreMarca ?? "",
        nombreModelo: item?.nombreModelo ?? "",
        nombreYear: item?.nombreYear ?? "",
        nombreVersion: item?.nombreVersion ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataMotivo, setdataMotivo] = useState([])
    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
        // motivoCancelacionGestoriaId: Yup.string()
        //     .required("El campo es requerido."),
        // comentarios: Yup.string()
        //     .required("El campo es requerido.")

    });


    useEffect(() => {
        handleMotivoGestoriaRechazada();
    }, [])
    
    const handleMotivoGestoriaRechazada = async () => {
        await Catalogo.getAllCSMotivoCancelacionGestoria()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoCancelacionGestoriaId,
                        label: item.motivo
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }

    const handleAddEdit = async (item) => {
        submit(item);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);                
            }}
        >
            {({ setFieldValue, values, errors }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons'>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2">
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                {/* <label style={{ fontSize: '14px' }}><strong>2. Información de gestoría</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    A continuación debes capturar los siguientes datos de la gestoría.
                                </span>
                                <div className="mb-3 row my-2">
                                    <div className="col-sm-8">
                                        <SelectField id="motivoCancelacionGestoriaId" label="Motivo de cancelación" name="motivoCancelacionGestoriaId" items={dataMotivo} onChange={(event) => {
                                            setFieldValue("motivoCancelacionGestoriaId", event.value);
                                            setFieldValue("motivo", event.label);
                                        }} />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextAreaField id="comentariosId" label="Comentarios" className="form-control col-12" rows="2" name="comentarios" type="text" holder="Escribe" />
                                    </div>
                                </div> */}
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={!values.legibilidad}>Confirmar</button>
                            </section>
                        </Form>                     
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalConfirmGestoriaRechazada