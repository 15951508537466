import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveDataDocs } from '../../../Redux/Slice/navigateSliceDDocs';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubmenuRenovacionSeguro = () => {
  const { value } = useSelector((state) => state.navigaSlicedataDocs);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveDataDocs(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.VehículosEnContrato) && value === 1) {
      navigate(`/seguro/renovacionseguro/contrato`);
    }
    if (ValidatePermissionById(3, Section.VehículosUtilitarios) && value === 2) {
      navigate(`/seguro/renovacionseguro/utilitario`);
    }
    if (ValidatePermissionById(3, Section.SegurosRenovados) && value === 3) {
      navigate(`/seguro/renovacionseguro/segurosrenovados`);
    }
  }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehículosEnContrato)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos en contrato</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehículosUtilitarios)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos utilitarios</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.SegurosRenovados)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguros renovados</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubmenuRenovacionSeguro