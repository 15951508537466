import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import IntelimotorAPI from '../../Services/Catalogos/Intelimotor';
import useModal from '../Modal/useModal';
import ConfigService from '../../Services/config/configService';
import { getDateFormat } from '../../Utils/functions';
import AuthLogin from '../../Utils/AuthLogin';

const auth = new AuthLogin();
const useIntelimotor = () => {
  const navigate = useNavigate();
  const [isOpen, openModal, closeModal] = useModal();
  const [isOpenEdit, openModaEdit, closeModalEdit] = useModal();
  const columns = [
    { field: 'fechaString', headerName: 'Fecha y hora', width: 230 },
    { field: 'tipoSincronizacion', headerName: 'Tipo de sincronización', width: 230 },
    // { field: 'cantActualizaciones', headerName: 'Cant. Actualizaciones', width: 200 },
    { field: 'registrosNuevos', headerName: 'Registros nuevos', width: 200 },
    { field: 'resultadoEjecucion', headerName: 'Resultados de ejecución', width: 230 },
  ];

  let text = ["Iniciando proceso descarga...", "Seguimos trabajando...", "Espera un poco, un poquito mas...", "no actualices la pagina...", "ya casi esta listo..."]
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [message, setMessage] = useState("");
  const [initial, setInitial] = useState(0);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [valueIntelimotor, setValueIntelimotor] = useState("");
  const [valueIntelimotor1, setValueIntelimotor1] = useState("");
  const [newParamValue, setNewParamValue] = useState("");
  const [pageRows, setpageRows] = useState(10)  

  useEffect(() => {
    handleGetAllHistory(currentPage, pageRows);
    handleGetParameters();
  }, [currentPage,pageRows])

  useEffect(() => {
    setMessage(text[initial]);
  }, [initial])

  const handleclickEvent = () => {
    // 
    // setMessage(text[initial]);
    setInitial(prevCount => prevCount + 1);
  }
  const handleGetParameters = async () => {
    await IntelimotorAPI.getValorParametro("DescargaCatIntelimotor")
      .then(res => {
        setValueIntelimotor(res.data.data.valor);
        setValueIntelimotor1(res.data.data.valor);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetAllHistory = async (page, rows) => {
    setLoading(true);
    await IntelimotorAPI.getSyncHistory(page, rows)
      .then(res => {
        let results = res.data.data.map(item => {
          item.fechaString = getDateFormat(item.fechaAlta, '');
          return item;
        });


        setData(results);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }


  const handleDetail = (item) => {
    navigate(`/admin/catalogo/intelimotor/${item.syncsId}`, {
      state: {
        page: 'intelimotor',
        title: "title"
      },
    });
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const handleUpdateParameters = async () => {
    await IntelimotorAPI.updateParameterIntelimotor(newParamValue)
      .then(res => {
        closeModalEdit();
        setValueIntelimotor(newParamValue);
        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "la hora se a actualizado con éxito." })
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleChangeValueParam = (value) => {
    setNewParamValue(value);
    setValueIntelimotor1(value)
  }
  const handleClick = async () => {
    setLoadingReq(true);
    const inter = setInterval(() => {
      setInitial((prevCounter) => prevCounter + 1);
    }, 120000);

    await ConfigService.SyncIntelimotor(auth.getSesionValue("userId"))
      .then(res => {
        handleGetAllHistory(1, 10);
        setInitial(0);
        setLoadingReq(false);
        clearInterval(inter);
        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.message })
      }).catch(e => {
        setLoadingReq(false);
        if (e.response !== undefined) {
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        }
        else {
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })

        }
      })

  }
  return {
    columns,
    data,
    pageCount,
    loading,
    handlePageClick,
    handleDetail,
    handleClick,
    isOpen,
    isOpenEdit,
    closeModal,
    openModaEdit,
    closeModalEdit,
    message,
    initial,
    loadingReq,
    modalMessage,
    setModalMessage,
    valueIntelimotor,
    handleUpdateParameters,
    handleChangeValueParam,
    newParamValue,
    valueIntelimotor1,
    setpageRows
  }
}

export default useIntelimotor