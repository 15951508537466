import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import Llamada from '../../../Services/Cartera/Llamadas';
import { message, pageRows } from '../../../Utils/functions'
import { setGruaSlice } from '../../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import IntelimotorAPI from "../../../Services/Catalogos/Intelimotor";

const CardGrua
    = (props) => {
        const { vehiculomover } = props
        //Garantia
        const {
            EmpresaId,
            GruaAsignada,
            costoGrua,
            EstadoId,
            MunicipioId,
            ColoniaId,
            Calle,
            Numero,
            TallerAutorizado,
            ReferenciaUbicacion,
            GruasId,
            IVA,
            CostoInicial,
            Subtotal,
            Total,
            BloqueoIdG,
            FechaCitaCalendario,
            StatusCita,
            FolioGrua,
            Ejecutivo,
            Gravedad,
            EjecutivoGarantia,
            GruaStatusId
        } = useSelector((state) => state.GruaSlice);

        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { FolioGarantia, GarantiaId, GeneralId, TipoGarantia,Falla,TipoReporte } = useSelector((state) => state.GarantiaSlice);
        const { nombreYear, vin, nombreModelo, numContrato,nombreMarca } = useSelector((state) => state.DataVinSlice);
        const { dayGrua, hourGrua, sucursalId, calendarioIdGrua } = useSelector((state) => state.GruasSlice);
        const [modalCosto, setModalCosto] = useState(false);
        const costoInputRef = useRef(0);
        const costoIVA = useRef(0);
        const [talleres, setTalleres] = useState([]);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        let { idllamada } = useParams();


        let initial = {
            EmpresaId: EmpresaId || "",
            GruaAsignada: GruaAsignada || "",
            costoGrua: costoGrua || "",
            IVA: IVA || null,
            CostoInicial: CostoInicial || "",
            Subtotal: Subtotal || "",
            Total: Total || "",
            EstadoId: EstadoId || "",
            MunicipioId: MunicipioId || "",
            ColoniaId: ColoniaId || "",
            Calle: Calle || "",
            Numero: Numero || "",
            TallerAutorizado: TallerAutorizado || "",
            ReferenciaUbicacion: ReferenciaUbicacion || "",
            FechaCitaCalendario: FechaCitaCalendario || "",
            StatusCita: StatusCita || null,
            GarantiaId: GarantiaId || "",
            GruasId: GruasId || "",
            BloqueoIdG: BloqueoIdG || null,
            gruaStatusId: GruaStatusId || null
        }
        const [initialValue, setInitialValue] = useState(initial);
        const [dataEmpresaId, setDataEmpresaId] = useState([]);
        const [dataEstado, setDataEstado] = useState([]);
        const [dataMunicipio, setDataMunicipio] = useState([]);
        const [dataColonia, setDataColonia] = useState([]);
        const [loading, setLoading] = useState(false);
        const [_bloqueoId, setBloqueoId] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const [mcosto, setMCosto] = useState(0)
        const [miva, setMIva] = useState(0)
        const [IVAInitial, setIVAInitial] = useState(0)

        useEffect(() => {
            HandleGetEstadoMx();
            HandleGetMunicpio(EstadoId);
            HandleGetColonia(MunicipioId);
            HandleGetEmpresas();
            GetTalleres();
            if (GruasId != "") {
                setInitialValue(initial)
            }

        }, [GruasId]);


        useEffect(() => {
            if (GruasId != "") {
                setInitialValue({
                    ...initialValue,
                    EmpresaId: EmpresaId || "",
                    GruaAsignada: GruaAsignada || "",
                    costoGrua: costoGrua || "",
                    IVA: IVA || "",
                    CostoInicial: CostoInicial || "",
                    Subtotal: Subtotal || "",
                    Total: Total || null,
                    EstadoId: EstadoId || "",
                    MunicipioId: MunicipioId || "",
                    ColoniaId: ColoniaId || "",
                    Calle: Calle || "",
                    Numero: Numero || "",
                    TallerAutorizado: TallerAutorizado || "",
                    ReferenciaUbicacion: ReferenciaUbicacion || "",
                    StatusCita: StatusCita || null,
                    GarantiaId: GarantiaId || "",
                    GruasId: GruasId || "",
                    BloqueoIdG: BloqueoIdG || null,
                    // FechaCitaCalendario: FechaCitaCalendario,
                    // BloqueoId: BloqueoIdG,
                    // StatusCita: StatusCita
                });
                if (dayGrua == "" && dayGrua == null) {
                    let splitday = dayGrua.split("T")
                    let day = splitday[0].split("-")
                    setInitialValue({
                        ...initialValue,
                        FechaCitaCalendario: day[2] + '-' + day[1] + '-' + day[0] + ' ' + hourGrua
                    })
                }

            }
        }, [StatusCita]);

        useEffect(() => {
            if (hourGrua) {
                let splitday = dayGrua.split("T")
                let day = splitday[0].split("-")
                setInitialValue({
                    ...initialValue,
                    FechaCitaCalendario: day[2] + '-' + day[1] + '-' + day[0] + ' ' + hourGrua
                })
            }
        }, [hourGrua])


        const validateGrua = Yup.object({
            EmpresaId: Yup.string()
                .trim()
                .required("El campo es requerido."),
            GruaAsignada: Yup.string()
                .required("El campo es requerido."),
            EstadoId: Yup.mixed()
                .required("El campo es requerido."),
            // MunicipioId: Yup.mixed()
            //     .required("El campo es requerido."),
            // ColoniaId: Yup.mixed()
            //     .required("El campo es requerido."),
            Calle: Yup.string()
                .required("El campo es requerido."),
            // TallerAutorizado: Yup.string()
            //     .required("El campo es requerido."),
        });

        const handleGetMontoMinimoCheque = async (valor, values) => {

            if (initialValue.IVA != "" && initialValue.IVA != null && initialValue.IVA != 0) {
                ChangeModalCosto(valor, values, 99)
            } else {
                await IntelimotorAPI.getValorParametro("Iva")
                    .then(res => {
                        ChangeModalCosto(valor, values, parseInt(res.data.data.valor))
                        // setMontoMinimoCheque(res.data.data.valor);
                    }).catch(e => {
                        console.log("Error: ", e)
                    })
            }

        }

        const ChangeModalCosto = async (valor, values, _iva = null) => {
            try {
                // await handleGetMontoMinimoCheque();

                // El resto de tu código que necesita el valor actualizado de IVAInitial aquí
                console.log(initialValue.IVA, _iva)
                costoInputRef.current.value = initialValue.costoGrua ?? 0;
                costoIVA.current.value = initialValue.IVA != "" && initialValue.IVA != null && initialValue.IVA != 0 ? initialValue.IVA : _iva;

                if (values.EmpresaId) {
                    setInitialValue({
                        ...initialValue,
                        EmpresaId: values.EmpresaId || "",
                        GruaAsignada: values.GruaAsignada || "",
                        costoGrua: values.costoGrua || "",
                        // CostoInicial: values.CostoInicial || "",
                        EstadoId: values.EstadoId || "",
                        MunicipioId: values.MunicipioId || "",
                        ColoniaId: values.ColoniaId || "",
                        Calle: values.Calle || "",
                        Numero: values.Numero || "",
                        TallerAutorizado: values.TallerAutorizado || "",
                        ReferenciaUbicacion: values.ReferenciaUbicacion || "",
                        // FechaCitaCalendario: values.FechaCitaCalendario || "",
                        StatusCita: values.StatusCita || ""
                    });
                } else {
                    // Lógica para otro caso aquí
                }

                setModalCosto(valor);
            } catch (error) {
                console.error("Error al cambiar el modal de costo:", error);
                // Manejo de errores aquí
            }
        };

        const handleAddEditGrua = async (value) => {
            setLoading(true);
            try {
                const gruaData = {
                    "GarantiaId": GarantiaId,
                    "GruasId": GruasId,
                    "EmpresaId": value.EmpresaId,
                    "GruaAsignada": value.GruaAsignada,
                    "costoGrua": value.costoGrua,
                    "subTotal": initial.Subtotal,
                    "iva": initialValue.IVA,
                    "total": initialValue.Total,
                    "EstadoId": value.EstadoId,
                    "MunicipioId": value.MunicipioId,
                    "ColoniaId": value.ColoniaId,
                    "Calle": value.Calle,
                    "Numero": value.Numero,
                    "TallerAutorizado": value.TallerAutorizado,
                    "ReferenciaUbicacion": value.ReferenciaUbicacion,
                    "FechaCitaCalendario": value.FechaCitaCalendario,
                    "horaCita": hourGrua,
                    "StatusCita": value.StatusCita,
                    "BloqueoId": value.BloqueoIdG,
                    "comentariosCancelacion": value.comentariosCancelacion
                }

                if (!GruasId) {
                    const res = await Llamada.AddGruaLlamada(gruaData);
                    const resData = res.data.data;
                    const gruaId = res.data.data.gruasId;
                    const gruaSliceData = {
                        ...gruaData,
                        GruasId: gruaId,
                        FolioGrua: resData.folioGrua || "Sin Folio"
                    };
                    dispatch(setGruaSlice(gruaSliceData));
                    toast.success(message("Registro de grua exitoso", res.data.data.mensaje));
                } else {
                    if (dayGrua !== "" && !BloqueoIdG && initialValue.FechaCitaCalendario) {
                        const dayParts = dayGrua.split("T")[0].split("-");
                        const dBloqueo = {
                            ubicacionid: sucursalId,
                            fecha: dayParts.join('-'),
                            calendarioid: calendarioIdGrua,
                            calendariotipoid: 2,
                            relacionid: value.GarantiaId,
                        };

                        try {
                            const bloqueoRes = await Llamada.crearCita(dBloqueo).catch(err => {
                                setBloqueoId(true)
                            });
                            const _bloqueoIdG = bloqueoRes.data.data.bloqueoId;
                            const gruaDataWithBloqueo = {
                                ...gruaData,
                                fechaCita: dayParts.join('-') + 'T09:11:29.119Z',
                                HoraCita: hourGrua,
                                BloqueoId: _bloqueoIdG,
                            };

                            const updateRes = await Llamada.UpdateGruabyBarantiaId(gruaDataWithBloqueo);
                            setInitialValue({
                                ...initialValue,
                                BloqueoIdG: _bloqueoIdG
                            })
                            setBloqueoId(true);
                            toast.success(message("Registro actualizado", updateRes.data.data.mensaje));
                        } catch (err) {
                            setBloqueoId(true);
                        }
                    } else {
                        const updateRes = await Llamada.UpdateGruabyBarantiaId(gruaData);
                        toast.success(message("Registro actualizado!", updateRes.data.data.mensaje));
                    }
                }
            } catch (e) {
                if (e.response !== undefined) {
                    // toast.error(message("¡Error.!", e.response.data.message));
                } else {
                    // toast.error(message("¡Error.!", e.message));
                }
            } finally {
                setLoading(false);
            }
        };
        const GetTalleres = async () => {
            await Llamada.getTalleresSlice()
                .then(res => {
                    if (res.data.data === null) {

                    } else {
                        let dataRes = res.data.data
                        let data = []

                        dataRes.map(item => {
                            data.push({
                                value: item.tallerId,
                                label: item.taller
                            })
                        })
                        setTalleres(data)
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        }
        const HandleGetEmpresas = async () => {
            await Llamada.GetEmpresas().then(res => {
                let Empresas = []
                res.data.data?.map(item => {
                    Empresas.push({
                        value: item.empresaId,
                        label: item.empresa
                    });
                });
                setDataEmpresaId(Empresas);
            })
        }
        const HandleGetEstadoMx = async () => {
            await Llamada.GetEstadoMx().then(res => {
                let Estados = []
                res.data.data?.map(item => {
                    Estados.push({
                        value: item.edoMexicoId,
                        label: item.estado
                    });
                });
                setDataEstado(Estados);
                //setDataEmpresaId
            })
        }
        const HandleGetMunicpio = async (id) => {
            initialValue.MunicipioId = ""
            initialValue.MunicipioId = null
            setDataMunicipio([]);
            await Llamada.GetMunicipio(id).then(res => {
                let Municipios = []
                res.data?.data?.map(item => {
                    Municipios.push({
                        value: item.municipioId,
                        label: item.municipio
                    });
                });
                setDataMunicipio(Municipios);
                //setDataEmpresaId
            })
        }
        const HandleGetColonia = async (id) => {
            initialValue.ColoniaId = ""
            initialValue.ColoniaId = null
            setDataColonia([])
            await Llamada.GetColonia(id).then(res => {
                let Colonias = []
                res.data?.data?.map(item => {
                    Colonias.push({
                        value: item.coloniaId,
                        label: item.colonia
                    });
                });
                setDataColonia(Colonias);
                //setDataEmpresaId
            })
        }
        const verModalAutorizarCita = (value) => {
            console.log(Falla,"..........")
            let data = {
                "llamadaId": null,
                "garantiaId": GarantiaId,
                "generalId": GeneralId,
                "folioCita": "",
                "folioLlamada": FolioLlamada,
                "folioGarantia": FolioGarantia,
                "numContrato": numContrato,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreModelo,
                "claveModelo": nombreModelo,
                "nombreModelo": nombreModelo,
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ejecutivoGarantia": EjecutivoGarantia,
                "ubicacionId": "",
                "ubicacion": "Guadalupe",
                "tipoGarantiaId": null,
                "tipoGarantia": TipoGarantia || "Sin Garantia",
                "clasificacionFallaId": null,
                "falla": Falla,
                "gravedad": Gravedad,
                "tipoReporteId": null,
                "tipoReporte": TipoReporte || "Sin reporte",
                "telefono": "",
                "fechaCita": value.FechaCitaCalendario,
                "estatusCita": "",
                "_bloqueoId": ""
            }

            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 2, Origin: "Listado" })
        }
        const verCalendario = async (valor, values) => {
            dispatch(setGruaSlice(values))

            dispatch(setSchedule({
                url: `garantias/incidenciasinfo/${vin}/${idllamada}`,
                contrato: numContrato,
                folioLlamada: FolioLlamada,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }
        const setValueCosto = (campo, value) => {
            let costo = mcosto;
            let iva = costoIVA.current.value

            if (campo === 'costo') {
                costo = parseInt(value || 0);
                setMCosto(costo);
            } else if (campo === 'iva') {
                iva = parseInt(value || 0);
                setMIva(iva);
            }

            const tiva = (costo * iva) / 100;
            const total = costo + tiva;

            setInitialValue({
                ...initialValue,
                costoGrua: costo,
                IVA: iva,
                Total: total,
            });
        }


        const formatter = (values) => {
            let formattedAmount = new Intl.NumberFormat('es-ES', {
                style: 'decimal',
                currency: "MXN",
            }).format(values);
            return formattedAmount
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>

                <Modal isOpen={modalCosto} color='#fff' width={560}>
                    <div className='p-4'>
                        <h5>
                            <b>
                                Costo recolección grua
                            </b>
                        </h5>
                        <div>
                            A continuación captura el costo de la recolección de grua.
                        </div>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th style={{ width: "50%" }}>Valor</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Costo grua
                                    </td>
                                    <td>
                                        $<input type="text" id="costoInput"
                                            ref={costoInputRef}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                const numericValue = inputValue.replace(/\D/g, ''); // Remueve todos los caracteres que no sean dígitos
                                                setValueCosto('costo', numericValue);
                                            }}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        Subtotal
                                    </td>
                                    <td>
                                        ${initialValue.Subtotal}
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>
                                        IVA
                                    </td>
                                    <td>%
                                        <input
                                            type="text"
                                            ref={costoIVA}
                                            onKeyPress={(event) => {
                                                const charCode = event.which ? event.which : event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    event.preventDefault(); // Evitar la entrada si no es un número
                                                }
                                            }}
                                            onChange={(event) => { setValueCosto('iva', event.target.value); }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Total
                                    </td>
                                    <td>
                                        ${initialValue.Total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='row justify-content-end'>
                            <div className='col-md-3'>
                                <button className="btn btn-outline col-12" onClick={(e) => { setValueCosto("0"); ChangeModalCosto(false, {}); }}>Cancelar</button>
                            </div>
                            <div className='col-md-3 text-end'>
                                <button className="btn saveInfo col-12" onClick={(e) => ChangeModalCosto(false, {})}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {vehiculomover == 0 && GarantiaId && (

                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <Formik
                            innerRef={refFormik}
                            enableReinitialize={true}
                            initialValues={initialValue}
                            validationSchema={validateGrua}
                        >
                            {({ isValid, values, setFieldValue, errors }) => (
                                <>
                                    <div className='row mx-md-4 my-4' >
                                        <div className="col-12 px-md-3">
                                            <div className="p-4 white-wrapper mb-2">

                                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseGrua" role="button" aria-expanded="false" aria-controls="collapseGrua" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                    <div className="col-11">
                                                        <h6><span className={FolioGrua ? 'semaforo semaforo-green' : 'semaforo semaforo-default'}></span> <strong>Información de grua - {FolioGrua}</strong></h6>
                                                    </div>
                                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                    </div>
                                                    <div className='col-12'>
                                                        <small>{initialValue.StatusCita == 2 ? 'Cita Autorizada' : ''}</small>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseGrua">
                                                    {/* <div className="separator"></div>


                                                    {Object.keys(initialValue).map((key) => (
                                                        <div key={key}>
                                                            <strong>{key}:</strong> {initialValue[key]}
                                                        </div>
                                                    ))} */}


                                                    <div className="row mt-2 px-4">
                                                        <div className="col-10">
                                                            <small><strong>Asignación de grua</strong></small>
                                                        </div>
                                                        <div className="col-2 text-end">
                                                            <button className="btn col-12 saveInfo"
                                                                onClick={(e) => handleAddEditGrua(values)} disabled={!(isValid) || !values.GruaAsignada || values.StatusCita == 'Autorizada' || BloqueoIdG || values.BloqueoIdG}>Guardar</button>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <SelectField id="EmpresaId" label="Empresa" name="EmpresaId" items={dataEmpresaId} onChange={(event) => {
                                                                setFieldValue("EmpresaId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                                            <TextField id="GruaAsignada" label="Grua Asignada" name="GruaAsignada" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("GruaAsignada", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <TextField id="Total" label="Costo para cliente" name="Total" type="text" holder="Escribe" disabled onChange={(event) => {
                                                                setFieldValue("Total", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-2 mt-4 text-end">
                                                            {/*  */}
                                                            <button className="btn col-12 saveInfo" disabled={!GruasId || BloqueoIdG || values.BloqueoIdG} onClick={(e) => handleGetMontoMinimoCheque(true, values)}>Registrar costo</button>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <small><strong>Indicar ubicación de recolección y destino</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-2 px-4">
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            <SelectField id="EstadoId" label="Estado" name="EstadoId" items={dataEstado} onChange={(event) => {
                                                                setFieldValue("EstadoId", event.value);
                                                                HandleGetMunicpio(event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            <SelectField id="MunicipioId" label="Municipio" name="MunicipioId" items={dataMunicipio} onChange={(event) => {
                                                                setFieldValue("MunicipioId", event.value);
                                                                HandleGetColonia(event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            <SelectField id="ColoniaId" label="Colonia" name="ColoniaId" items={dataColonia} onChange={(event) => {
                                                                setFieldValue("ColoniaId", event.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            <TextField id="Calle" label="Calle" name="Calle" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("Calle", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            <TextField id="Numero" label="Número" name="Numero" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("Numero", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-2 col-lg-2">
                                                            {/* <TextField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("TallerAutorizado", event.target.value);
                                                            }} /> */}
                                                            <SelectField id="TallerAutorizado" label="Taller Autorizado" name="TallerAutorizado" items={talleres} onChange={(event) => {
                                                                setFieldValue("TallerAutorizado", event.value);
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <small><strong>Referencia de ubicación</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-2 px-4">
                                                        <div className="col-sm-12 col-md-6 col-lg-6 strong-label">
                                                            <TextAreaField id="ReferenciaUbicacion" label="Referencia de ubicación" className="form-control col-12" rows="3" name="ReferenciaUbicacion" type="text" holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("ReferenciaUbicacion", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>


                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <small><strong>Programar recolección</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-2 px-4">
                                                        <div className="col-sm-12 col-md-2 col-lg-3">
                                                            <TextField id="FechaCitaCalendario" label="Fecha y hora de cita" name="FechaCitaCalendario" type="text" holder="" disabled onChange={(event) => {
                                                                setFieldValue("FechaCitaCalendario", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                            <button className="btn col-12 saveInfo" onClick={(e) => verCalendario(2, values)} disabled={!GruasId || BloqueoIdG || values.BloqueoIdG}>Ver calendario</button>
                                                        </div>

                                                        <div className="col-sm-12 col-md-2 col-lg-3">

                                                            <span>Estatus cita</span>
                                                            <input type="text" className='form-control' disabled value={StatusCita != "2" ? "Sin Asignar" : "Autorizada"} />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-2 mt-4">
                                                            <button className="btn col-12 saveInfo" disabled={!values.BloqueoIdG || values.StatusCita === '2'} onClick={(e) => verModalAutorizarCita(values)}>Autorizar Cita</button>
                                                            {/* <button className="btn col-12 saveInfo" onClick={(e) => verModalAutorizarCita(values)}>Autorizar Cita</button> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            }
                        </Formik>
                    </div>
                )}
            </>
        )
    }
export default CardGrua