import React from 'react'
import AcordeonSA from '../../Components/AcordeonEtapaSA'
import Acordeon from '../../Components/Acordeon'
import { useEffect } from 'react'
import { useState } from 'react'

const CambioEtapaDataOnly = ({dataGroups,dataESA}) => {
const [dataGRups, setDataGRups] = useState([]);
    useEffect(() => {
        setDataGRups(dataGroups);    
    }, [dataGroups])
    
    return (
        <>
            <section className='etapa_container mt-4 d-flex'>
                <div className='mx-4 col-4'>
                    {
                        dataGroups.map((item, index) => {
                            return <div key={index}>
                                    <Acordeon data={item} heigth={500} />
                                </div>
                        })
                    }
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center py-3'>
                    <div className='top'></div>
                    <span><i style={{ color: '#e8eaf6' }} className="ri-arrow-left-right-line"></i> </span>
                    <div className='top'></div>
                </div>
                <div className='mx-4 col-4'>
                    {
                        dataESA.length > 0 &&
                        <AcordeonSA data={dataESA} />
                    }
                </div>
            </section>
        </>
    )
}

export default CambioEtapaDataOnly