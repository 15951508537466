import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { TextField } from '../../../Components/TextField'
import MarcaAPI from '../../../Services/Catalogos/Marca'
import * as Yup from 'yup';
import ModeloAPI from '../../../Services/Catalogos/Modelo'
import Catalogo from '../../../Services/Catalogos/Catalogo'
import { SelectField } from '../../../Components/SelectField'

const Modelo = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl }) => {    
    let initial = {
        modeloId: item?.modeloId ?? "",
        marcaId:item?.marcaId ?? "",
        modelo: item?.modelo ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial)
    const [data, setData] = useState([]);

    const validate = Yup.object({
        modelo: Yup.string()
            .required("Ingrese un nombre.")
            .min(3, 'minimo 3 caracteres'),
        marcaId: Yup.string()
            .required("Ingrese un nombre.")
    });
    useEffect(() => {
        handleGetDataMarca();
    }, [])
    
    const handleEdit = async (item) => {
        
        if (action === 'add') {
            await ModeloAPI.addModelo(item)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                }).catch(e => {
                    
                })
        }
        if (action === 'edit') {
            await ModeloAPI.updateModelo(item)
                .then(res => {
                    
                    setIsOpen(false);
                    handleGetAll(1);
                }).catch(e => {
                    
                })
        }
    }
    const handleGetDataMarca = async () =>{
        await Catalogo.getdataTabla("Marca", 1,99999)
            .then(res => {        
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.marcaId,
                        label: item.marca
                    })
                });        
                
                setData(NewData);
            }).catch(e => {
                
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                        <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                <div className="col-ms-6 form-check form-switch">
                                    <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                    <Field className="form-check-input" type="checkbox" name="status" />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                    <TextField label="Modelo" name="modelo" type="text" holder="Escribe" />
                                </div>
                                <div className="col-sm-6">
                                    <SelectField label="Marca" name="marcaId" items={data} onChange={(value) => {
                                        setFieldValue("marcaId", value.value)
                                    }} />
                                </div>
                            </div>
                            <section className='my-3'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' type="submit" disabled={!(isValid)}>Guardar</button>
                                {/* onClick={formik.submitForm} */}
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default Modelo