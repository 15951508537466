import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import pruebaManejoService from '../../../Services/Venta/Vehiculo/pruebaManejoService';
import { message } from '../../../Utils/functions';
import SeguroSinRenovarService from '../../../Services/Seguros/SeguroSinRenovarService';

const ModalSinRenovarSeguro = ({ isOpen, setIsOpen, handleGetAll, toast, item }) => {
    const refbtn = useRef();
    console.log("item->",item);
    let initial = {
        pruebadeManejoId: item?.pruebadeManejoId ?? "",
        motivoNoRenovacionSeguroId: "",
        motivo:"",
        comentarios: "",
        generalId: item.generalId,        
        vin:item?.vin,
        sol_Id:item?.sol_Id,
        contrato:item?.contrato 

    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataMotivo, setdataMotivo] = useState([]);
    const validate = Yup.object({
        motivoNoRenovacionSeguroId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .required("El campo es requerido."),
    });

    useEffect(() => {
        handleGetAllSinRenovacionSeguro();
    }, [])

    const handleGetAllSinRenovacionSeguro = async () => {
        await Catalogo.getAllCSMotivoNoRenovacionSeguro()
            .then(res => {
                let NewData = [];
                console.log(res.data.data)
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.motivoNoRenovacionSeguroId,
                        label: item.motivo
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }

    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        await SeguroSinRenovarService.SaveSinRenovarSeguro(value)
            .then(res => {                
                setIsOpen(false);
                handleGetAll(1);
                if(res.data.data.ban === 1)
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                else
                    toast.error(message("!Error.!", res.data.data.mensaje));

            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);                
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='d-flex flex-column  mx-4'>
                        <span className='fs-5 mb-2'><strong>Cancelar renovación de seguro</strong></span>
                        <span style={{ fontSize: '13px' }}>A continuación debes capturar el detalle de la cancelación.</span>
                    </div>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <SelectField label="Motivo" name="motivoNoRenovacionSeguroId" items={dataMotivo} onChange={(event) => {
                                        setFieldValue("motivoNoRenovacionSeguroId", event.value);
                                        setFieldValue("motivo", event.label);
                                    }} />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-12">
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Cancelar renovación</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalSinRenovarSeguro