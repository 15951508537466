import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import { SelectField } from '../../../Components/SelectField';

const ModalConfirmAlta = ({ isOpen, setIsOpen, item, handleGetAll }) => {
    const refbtn = useRef();
    console.log("item", item)
    let initial = {
        numSolicitudExterno: item?.numSolicitudExterno ?? "",
        numSolicitud: item?.numSolicitud ?? "",
        usoUnidadIdExterno: item?.usoUnidadIdExterno ?? "",
        usoUnidadId: item?.usoUnidadId ?? "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([]);
    useEffect(() => {
        handleGetAllUsoUnidad()
    }, [])


    const handleAddEdit = async (item) => {
        handleGetAll();
        setIsOpen(false)
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {
                // setLoading(false);
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values,setFieldValue}) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <h5><strong>Confirmar captura de información</strong></h5>
                        <span style={{ fontSize: '13px' }}>
                            El <strong>número de solicitud</strong> capturado no coincide, ¿estas seguro que deseas cargar la información?
                        </span>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                    <TextField disabled label="No de solicitud - Externo:" name="numSolicitudExterno" type="text" holder="Escribe" />
                                </div>
                                <div className="col-sm-6">                                    
                                    <SelectField isDisabled id="usoUnidadIdExterno" label="Uso de unidad:" name="usoUnidadIdExterno" items={DataUsoUnidad} onChange={(event) => {
                                    setFieldValue("usoUnidadIdExterno", event.value);                                    
                                }} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                    <TextField disabled label="No de solicitud - Compras:" name="numSolicitud" type="text" holder="Escribe" />
                                </div>
                                <div className="col-sm-6">
                                <SelectField isDisabled id="usoInidadId" label="Uso de unidad:" name="usoUnidadId" items={DataUsoUnidad} onChange={(event) => {
                                    setFieldValue("usoUnidadId", event.value);                                    
                                }} />
                                </div>
                                
                            </div>
                            <section className='my-3'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalConfirmAlta