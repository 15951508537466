import { createSlice } from '@reduxjs/toolkit';
import AuthLogin from '../../Utils/AuthLogin';
const auth = new  AuthLogin();

const initialState = {
  nombreUsuario:auth.getSesionValue("nombreUsuario") ?? "",
  numeroEmpleado:auth.getSesionValue("numeroEmpleado") ?? "",
  userId:auth.getSesionValue("userId") ?? "",
  idRole:auth.getSesionValue("idRole") ?? "",
  role:auth.getSesionValue("role") ?? "",
  sociocomercilaId:auth.getSesionValue("socioComercialId") ?? "",
}
export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    loginSession: (state, action) => {     
      
      return { ...state, 
        nombreUsuario: action.payload.nombreUsuario,
        numeroEmpleado:action.payload.numeroEmpleado,
        userId:action.payload.userId, 
        idRole:action.payload.idRole, 
        role:action.payload.role,
        sociocomercilaId:action.payload.socioComercialId
      }
    }   
  },
})

export const { loginSession } = loginSlice.actions

export default loginSlice.reducer