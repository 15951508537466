import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Formik } from 'formik'
import { TextField } from '../../Components/TextField';
import { SelectField } from '../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import Catalogo from '../../Services/Catalogos/Catalogo';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import useTablasSecundariasDinamicas from '../../Hooks/TablasSecundarias/useTablasSecundariasDinamicas';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import BasicModal from '../../Components/Modal/BasicModal';
import DataTableDinamica from '../../Components/datatable/DataTableDinamica';
import { message } from '../../Utils/functions'
import { Buffer } from 'buffer';

const ElementoDinamico = ({ tabla, configuration }) => {
    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { columns,
        data,
        pageCount,
        dataList,
        handleChangeList,
        handleChange,
        handlePageClick,
        loading,
        setLoading,
        currentPage,
        EditRow
    } = useTablasSecundariasDinamicas();

    let initial = {}
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 1, Origin: "Listado" });
    const [config, setConfig] = useState({});
    const [templateRows, setTemplateRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [dropdownOptions, setDropdownOptions] = useState({});

    useEffect(() => {
        setRows([]);
        if (configuration.length > 0) {
            let tmp = JSON.parse(configuration);
            setConfig(tmp);
            drawTemplate(tmp);
        }
    }, [configuration]);

    const drawTemplate = (cnf) => {
        let template = [];
        setRows([]);
        Object.keys(cnf).forEach(key => {
            if (cnf[key].primaryKey) {
                template.push({
                    field: key,
                    insert: cnf[key].insert,
                    label: cnf[key].descripcion,
                    input: cnf[key].tipo,
                    table: cnf[key]?.table,
                    option: cnf[key]?.option,
                    text: cnf[key]?.text,
                    autoload: cnf[key]?.autoload,
                    childComponent: cnf[key]?.childComponent,
                    value: 'NEWID()'
                });
            } else {
                template.push({
                    field: key,
                    insert: cnf[key].insert,
                    label: cnf[key].descripcion,
                    input: cnf[key].tipo,
                    table: cnf[key]?.table,
                    option: cnf[key]?.option,
                    text: cnf[key]?.text,
                    autoload: cnf[key]?.autoload,
                    childComponent: cnf[key]?.childComponent,
                    value: cnf[key].tipo === 'boolean' || cnf[key].tipo === 'bit' ? false : ''
                });
            }
        });

        console.log(template);
        setRows(template);
    }

    const addRow = () => {
        let tmpRows = rows;
        tmpRows.push(templateRows);
        setRows(tmpRows);
        forceUpdate();
    }

    const getInputType = (property) => {
        let type = "";
        switch (property) {
            case 'nvarchar':
            case 'varchar':
            case 'text':
            case 'longtext':
            case 'shorttext':
                type = "text";
                break;
            case 'integer':
            case 'number':
            case 'bigint':
            case 'int':
                type = "number";
                break;
            case 'date':
            case 'datetime':
                type = "date";
                break;
            case 'time':
                type = "time";
                break;
            case 'boolean':
            case 'bit':
                type = "checkbox";
                break;
        }

        return type;
    }

    const handleSetField = (row, value) => {
        console.log(value)
        row.value = value;
    }

    const handleSetCheckbox = (row, value) => {
        row.value = !row.value;
    }

    const saveRows = async () => {
        let flag = true;
        setLoading(true);
        if (rows.length > 0) {
            rows?.forEach(row => {
                if ((row?.input !== 'boolean' && row?.input !== 'bit') && row?.value.trim().length === 0 && row?.insert) {
                    flag = false;
                }

            });
        }

        if (flag === false) {
            toast.error(message("¡Operación fallida!", `Los campos son requeridos`));
            return false;
        }

        setLoading(true);
        const cnf = JSON.parse(configuration);
        let fields = [];

        Object.keys(cnf).forEach(key => {
            if (cnf[key].insert || cnf[key].primaryKey) {
                fields.push(key);
            }
        });

        let orderFields = fields;
        fields = fields.join(', ');

        let tmp = [];
        const TextFields = ['nvarchar', 'varchar', 'text', 'longtext', 'shorttext'];
        rows.forEach(row => {
            let idx = orderFields.indexOf(row.field);
            if (row.input === 'boolean' || row.input === 'bit') {
                tmp[idx] = row.value ? 1 : 0;
            } else if (TextFields.includes(row.input)) {
                tmp[idx] = `'${row.value}'`;
            } else {
                tmp[idx] = row.value;
            }
        });
        tmp = tmp.join(', ')

        let query = `INSERT INTO ${tabla} (${fields}) VALUES(${tmp})`;
        query = Buffer.from(query).toString('base64');

        const params = {
            nombreTabla: tabla,
            query
        }

        Catalogo.mantenimientoCatalogos(params)
            .then(resp => {
                console.log(resp.data);
                toast.success(message("¡Operación exitosa!", "Se insertó correctamente el registro"));

                setRows([]);
                addRow();
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al intentar crear el registro"));
            })
            .finally(() => setLoading(false))
    };

    const loadCatalog = async (item, value) => {
        const dropdownData = dropdownOptions;
        dropdownData[item.childComponent] = []


        if (item) {
            let childComponent = rows.find(x => x.field === item.childComponent);
            let response;

            if (item.field === 'EstadoId') {
                response = await Catalogo.getMunicipiobyId(value);

            }
            if (item.field === 'MunicipioId') {
                response = await Catalogo.getColoniaById(value);
            }

            try {

                if (response?.data?.data) {
                    response = response.data.data
                } else if (response?.data) {
                    response = response.data
                }

                if (response !== undefined) {
                    response.forEach(element => {
                        dropdownData[item.childComponent].push({
                            value: element[childComponent.option],
                            text: element[childComponent.text]
                        })
                    });
                }

            } catch (error) {
                console.error(`Error fetching data for:`, error);
            }
            setDropdownOptions({
                ...dropdownData
            })
        } else {
            console.log("Sin dato", item)
        }
    }

    useEffect(() => {
        const fetchDropdowns = async () => {
            const dropdownData = [];

            for (const item of rows) {
                dropdownData[item.field] = []
                if (item.table) {
                    if (item.autoload) {
                        try {
                            const response = await Catalogo.getDropdownsByTable(item.table);
                            response.data.forEach(element => {
                                dropdownData[item.field].push({
                                    value: element[item.option],
                                    text: element[item.text]
                                })
                            });
                        } catch (error) {
                            console.error(`Error fetching data for table ${item.table}:`, error);
                        }
                    }

                }
            }
            setDropdownOptions(dropdownData);
        };
        fetchDropdowns();
    }, [rows]);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className=''>
                <div className='fs-5 mt-3'>
                    <div className="row">
                        <div className="col-10">
                            Nuevo Elemento
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {
                        rows?.map((item, index) => {
                            return (
                                item.insert &&
                                <div className='col-3' key={'row_' + index}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label style={{ fontSize: '16px' }}>{item.label}</label>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                item.table && dropdownOptions[item.field] ? (
                                                    // Si hay opciones para este campo, muestra un select
                                                    <select
                                                        className="form-control mb-4"
                                                        onChange={(event) => {
                                                            handleSetField(item, event.target.value);
                                                            loadCatalog(item, event.target.value);
                                                        }
                                                        }
                                                    >
                                                        <option value="">Seleccione una opción</option>
                                                        {dropdownOptions[item.field].map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    // Si no es un dropdown, muestra el input correspondiente
                                                    getInputType(item.input) !== 'checkbox' ? (
                                                        <input
                                                            className="form-control mb-4"
                                                            type={getInputType(item.input)}
                                                            onChange={(event) => handleSetField(item, event.target.value)}
                                                        />
                                                    ) : (
                                                        <input
                                                            className="mb-4"
                                                            type={getInputType(item.input)}
                                                            onClick={(event) => handleSetCheckbox(item, event.target.value)}
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='row justify-content-end mt-2'>
                    <div className='col-auto'>
                        <button className='btn' onClick={saveRows}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ElementoDinamico