import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../../Components/Loadig';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import { SelectField } from '../../../../Components/SelectField';

const ModalPagos = ({ isOpen, setIsOpen, item, handleGetAll, toast, valuesInit }) => {
    const refbtn = useRef();
    const { id } = useParams();

    let initial = {
        servicioCostosAdicionalesPagoId: item?.servicioCostosAdicionalesPagoId ?? "",
        servicioId: item?.servicioId ?? "",
        folioId: item?.folioId ?? "",
        notas: item?.notas ?? "",
        metodoPagoId: item?.metodoPagoId ?? 0,
        metodoPago: item?.metodoPago ?? "",
        monto: item?.monto ?? 0,
        usuarioAlta: item?.usuarioAlta ?? "",
        usuarioAltaStr: item?.usuarioAltaStr ?? "",
        usuarioActualiza: item?.usuarioActualiza ?? "",
        usuarioActualizaStr: item?.usuarioActualizaStr ?? null,
        fechaAlta: item?.fechaAlta ?? "",
        fechaActualiza: item?.fechaActualiza ?? null,
        id: item?.id ?? 0
    };
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTipoCosto, setDataTipoCosto] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
    }, [])

    const tipoPagos = [
        { value: 1, label: 'Efectivo' },
        { value: 2, label: 'Tarjeta de Débito' },
        { value: 2, label: 'Tarjeta de Crédito' },
    ];

    const validate = Yup.object({
        notas: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
    });

    const handleAddEdit = async (item) => {
        setLoading(true)
        item.servicioId = id
        if (item.servicioCostosAdicionalesPagoId === null || item.servicioCostosAdicionalesPagoId === "") {
            DSystemsServicios.agregarServicioCostosAdicionalesPago(item)
                .then(res => {
                    handleGetAll()
                })
                .catch(error => {

                })
                .finally(() => {
                    setLoading(false)
                    setIsOpen(false);
                })
        } else {
            DSystemsServicios.updateServicioCostosAdicionalesPago(item)
                .then(res => {
                    handleGetAll()
                })
                .catch(error => {

                })
                .finally(() => {
                    setLoading(false)
                    setIsOpen(false);
                })
        }
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Registrar costo</strong></span>
                                </div>
                                <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costo.</span>
                            </section>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField label="Nota" name="notas" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <TextField
                                            label="Subtotal"
                                            name="monto"
                                            type="text"
                                            holder="Escribe"
                                            icon={"bi bi-currency-dollar"}
                                            isMoney={true}
                                            onChange={(event) => {
                                                setFieldValue("monto", event.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className='mb-3 row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <SelectField id="metodoPagoId"
                                            label="Metodo de pago"
                                            disabled={false}
                                            name="metodoPagoId"
                                            items={tipoPagos}
                                            onChange={(event) => {
                                                setFieldValue("metodoPagoId", event.value);
                                            }} />
                                    </div>
                                </div>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{initialValue.servicioCostosAdicionalesPagoId == "" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )
                }
            </Formik >
        </>
    )
}

export default ModalPagos