import { Formik } from "formik";
import DSAsignacionesHook from "../../../Hooks/DatamovilSystems/Asignaciones/DSAsignacionesHook";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import BarraEstadistica from "./BarraEstadistica";
import { TextField } from "../../../Components/TextField";
import Select from 'react-select';
import AsignarServiciosModal from "../../../Components/Modal/AsignarServiciosModal";
import DataTableCollapsable from "./DataTableCollapsable";

const SeguimientoAsignaciones = () => {
    const {
        refFormik,
        selectRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setTipoSolicitud,
        setTipoUbicacion,
        setTecnicoId,
        tipoSolicitudes,
        tipoUbicaciones,
        getListado,
        handleAsignar,
        tecnicos
    } = DSAsignacionesHook();

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <AsignarServiciosModal 
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>
                    <section className="mt-2 mx-2">
                        <div className='pb-4'>
                            <section className='mx-4 my-4'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Asignación de servicios</strong>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn" onClick={() => handleAsignar()}>Asignar servicios</button>
                                        </div>
                                    </div>
                                </div>
                                <BarraEstadistica />
                                <div className="row mt-4">
                                    <div className="col-12 col-md-2 mr-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                            setFieldValue("buscar", event.target.value);
                                            setBuscar(event.target.value)
                                        }} />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={tecnicos} className='col-12' onChange={(e) => {
                                            setFieldValue("tecnicoId", e.value);
                                            setTecnicoId(e.value);
                                            getListado()
                                        }} placeholder="Técnico" />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={tipoSolicitudes} className='col-12' onChange={(e) => {
                                            setFieldValue("tipoSolicitudId", e.value);
                                            setTipoSolicitud(e.value);
                                            getListado()
                                        }} placeholder="Tipo de solicitud" />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={tipoUbicaciones} className='col-12' onChange={(e) => {
                                            setFieldValue("tipoUbicacion", e.value);
                                            setTipoUbicacion(e.value);
                                            getListado()
                                        }} placeholder="Tipo de ubicación" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTableCollapsable 
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoAsignaciones;