import React, { useEffect, useState } from 'react'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { useParams } from 'react-router-dom'

const ModalInfo = ({ isOpen, setIsOpen, elementSelected }) => {

    const [loading, setLoading] = useState(false);

    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-end'>
                    <button
                        className='btn btn-outline d-flex justify-content-center align-items-center'
                        style={{ width: '23px', height: '23px' }}
                        onClick={() => { setIsOpen(false) }}>
                        <i className="ri-close-fill"></i>
                    </button>
                </section>
                <section className='d-flex justify-content-between mx-4'>
                    <span className=''>
                        <strong>
                            Información de contrato / vehículo
                        </strong>
                    </span>
                </section>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>

                    <div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>A nombre de: </b>
                            {elementSelected.aNombreDe}
                        </div>
                    </div>
                    <div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>Contrato: </b>
                            {elementSelected.numContrato}

                        </div>
                    </div><div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>VIN: </b>
                            {elementSelected.vin}
                        </div>
                    </div>
                    <div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>Marca: </b>
                            {elementSelected.marca}
                        </div>
                    </div>
                    <div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>Modelo: </b>
                            {elementSelected.modelo}
                        </div>
                    </div>
                    <div className='row mt-2 mb-2'>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <b>Año: </b>
                            {elementSelected.año}
                        </div>
                    </div>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ModalInfo