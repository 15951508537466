import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from "yup";
import { TextField } from '../../../Components/TextField';
import ModalComments from '../../../Components/Modal/ModalComments';
import { FormatFecha, message } from '../../../Utils/functions';
import UnidadesRemateService from '../../../Services/Inventario/UnidadesEnRemateService';
import toast from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const CajaDeComentariosProcesosModal = ({ isOpen, setIsOpen, item, id, handleUpdate }) => {
   const {itemUnidadesRemate, dataCobradores} = useSelector((state)=> state.UnidadesEnRemateSlice)
   const [dataComentarios, setDataComentarios] = useState([])
   const [loading, setLoading] = useState(false);
   const userId = sessionStorage.getItem('userId').trim()

   let initial = {
      nuevo_comentario: ""
   }
   const [initialValue, setInitialValue] = useState(initial);

   const validate = Yup.object({
      nuevo_comentario: Yup.string().required("El campo es requerido.")
   })

   useEffect(()=>{
      if(itemUnidadesRemate?.remate !== null && itemUnidadesRemate?.remate?.comments !== null){
         handleCargarDatos(itemUnidadesRemate?.remate?.comments)
      }
   },[])

   const handleCargarDatos = (data) =>{
      let NewData = []
      data?.map(comentario => {
         dataCobradores?.map(cobrador=>{
            if(cobrador?.id?.toLowerCase() === comentario?.userId.toLowerCase()){
               let fecha = new Date(comentario?.fecha_hora)
               let strArray = cobrador?.nombre.split(' ')
               NewData.push(
                  {usuario: cobrador?.nombre, iniciales: `${strArray[0]?.charAt(0)}${strArray[1]?.charAt(0)}` , fecha: FormatFecha(comentario?.fecha_hora), hora: `${fecha.getHours()}:${fecha.getMinutes()}` ,comentario: comentario?.comentario},
               )
            }
         })
      })
      let arregloOrdenado = NewData.sort(function (a, b) {
         if (a.fecha > b.fecha) {
            return 1;
         }
         if (a.fecha < b.fecha) {
            return -1;
         }
         // a must be equal to b
         return 0;
      });

      setDataComentarios(arregloOrdenado)
   }

   // const handleGetNombreEjecutivos = async()=>{
   //    setLoading(true)
   // let NewData = [];
   // await CobranzaService.getAllEjecutivosWithFullDetail()
   //    .then(res => {
   //       if(res.data.callSucceded){
   //          res.data.responseData?.map(item => {
   //             NewData.push({id:item.userid, nombre:item.user_detail.names})
   //          })
   //       }
   //       setLoading(false);
   //    })
   //    .catch(e => {
   //       setLoading(false);
   //       console.log(`ERROR -- ${e}`)
   //    })
   //    setData(NewData);
   // }

   const handleGuardarComentario = async(values, actions) =>{
      setLoading(true)
      const guidId = uuidv4();
      const fechaActual = new Date()
      let data = 
         {
            "verb": "string",
            "id": "comentarios",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject":
            {
               id: guidId,
               external_id: id,
               proceso: null,
               sub_proceso: null,
               fecha_hora: fechaActual,
               userId: userId,
               comentario : values.nuevo_comentario
            },
            "kvp":{}
         }

      await UnidadesRemateService.CreateValuacionUnidadesRemate(data)
         .then(res=>{
            toast.success(message("Registro realizado correctamente"));
            handleUpdate()
            actions.resetForm();
            handleActualizarData()
         })
         .catch(e => {
            setLoading(false)
            if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
            else
            toast.error(message("¡Error!", e.message));
         })
   }

   const handleActualizarData = async()=>{
         let data = {
            "verb": "string",
            "id": "remates",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {},
            "kvp": {
               "id": id
            }
         }

         await UnidadesRemateService.GetAllValuaciones(data)
         .then(res=>{
            handleCargarDatos(res.data.responseData[0].comments)
            setLoading(false)
         })
         .catch(e => {
            setLoading(false)
            if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
            else
            toast.error(message("¡Error!", e.message));
         })
   }

   return (
      <>
         <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
         <ModalComments isOpen={isOpen} color="#fff">
         <section className='my-2 mx-2 row'>
            <span className='fs-5 col-10' style={{fontWeight:'bold', color:"#000"}}><strong>Comentarios de siniestros</strong></span>
            <div className="col-auto">
               <button
                  className="btn col-12"
                  type="button"
                  onClick={() => { setIsOpen(false) }}
               >
                  <i className="ri-close-fill fs-5" style={{ color: "#fff" }} ></i>
               </button>
            </div>
         </section>
         <section className='my-2 mx-2' >
            <div className='mx-4 table-responsive' style={{ width: '100%', height: '55vh', overflowY: "scroll"}}>
               {
                  dataComentarios.length > 0
                  ? dataComentarios?.map((item,idx)=>{
                     return <div style={{ width: '26rem'}}>
                           <div className='row mb-1' style={{background:"#f3f3f3", height: "35px", alignItems:"center"}}>
                              <span style={{marginLeft:"20px"}}>{item.fecha}</span>
                           </div>
                           <div className='mb-1' style={{ width: '26rem'}}>
                              <div className="row px-4 mb-1" style={{textAlign: "left"}} >
                                 <div className='col-1 btn' style={{borderRadius:"50%", width:"45px"}}>
                                    <span style={{color:"white"}}>{item.iniciales}</span>
                                 </div>
                                 <div className='col-10 '>
                                    <span style={{fontWeight:"bold"}}>{item.usuario} {item.hora}</span>
                                 </div>
                              </div>
                              <div className="row px-4" >
                                 <p>{item.comentario}</p>
                              </div>
                           </div>
                        </div>
                     })
                  : <div style={{textAlign: "center", width: '26rem'}}>
                     <p><span style={{fontSize: "18px", fontWeight: "bold"}}>No se encontro información para mostrar</span></p>
                  </div>
               }
            </div>
            <div className="separator"></div>
            <Formik
               enableReinitialize={true}
               validationSchema={validate}
               initialValues={initialValue}
               onSubmit={(values, actions) => {
                  handleGuardarComentario(values, actions);
               }}
            >{
               ({ isValid, values, setFieldValue, actions }) => (
                  <>
                  <Form>
                     <div className="mb-3 row px-4 mt-2" >
                        <div className="col-12 col-lg-10 mt-1">
                           <TextField  holder="Escribe un comentario" name="nuevo_comentario" type="text" 
                              onChange={(event) => { setFieldValue("nuevo_comentario", event.target.value); }}/>
                        </div>
                        <div className="col-2">
                           <div className="col-auto">
                              <button
                                 className="btn col-12"
                                 type="submit"
                                 disabled={!(isValid) || (values.nuevo_comentario.trim() === "")}
                                 >
                                 <i className="ri-send-plane-fill fs-5" style={{ color: "#fff" }} ></i>
                              </button>
                           </div>
                        </div>
                     </div>
                  </Form>
               </>
            )
            }
         </Formik>
         </section>
      </ModalComments>
      </>
      
   )
}

export default CajaDeComentariosProcesosModal