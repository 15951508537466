import { useEffect, useRef, useState } from 'react'
import Catalogo from '../../Services/Catalogos/Catalogo';
import Marca from '../../Services/Catalogos/Marca';
import TblSecundariasService from '../../Services/TablasSecundarias/TblSecundariasService';
import useModal from '../Modal/useModal';
import { Buffer } from 'buffer';

const useTablasSecundariasDinamicas = () => {
  const listInnerRef = useRef();

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TableId, setTableId] = useState(0);
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [dataMarca, setDataMarca] = useState([]);
  const [currentPageMarca, setcurrentPageMarca] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [initialPage, setInitialPage] = useState(0);
  const [configuration, setConfiguration] = useState([]);
  const [pageRows, setpageRows] = useState(10);
  const [tablaName, setTablaName] = useState('');

  useEffect(() => {
    hangleGetAllTables();
  }, [])

  const hangleGetAllTables = async () => {
    setLoading(true);
    await Catalogo.getDropdownsByTable('Catalogos.TablasSecundarias')
    .then(resp => {
        setDataList(resp.data);
    })
    .catch(err => {
        console.log(err)
    })
    .finally(() => setLoading(false));
  }
  

  const handleChange = (event) => {
    if (event.key === 'Enter') {
        setStr(event.target.value);
    }
  }

  const EditRow = async (obj) => {
    // console.log("EditRow", obj);
    setLoading(true);
    let query = `UPDATE ${tablaName} SET `;
    let cnf = JSON.parse(configuration);
    console.log(cnf,"-->")
    let fragments = [];

    const quotes = ['nvarchar', 'varchar', 'text', 'longtext', 'shorttext', 'date', 'datetime', 'time'];
    Object.keys(cnf).forEach(key => {
        if(cnf[key].editable){
            if(quotes.includes(cnf[key].tipo.toLowerCase())){
                fragments.push(`${key} = '${obj[key]}'`);
            }else if(cnf[key].tipo === 'bool' || cnf[key].tipo === 'boolean' || cnf[key].tipo === 'bit'){
              const bool = obj[key] ? 1 : 0;
              fragments.push(`${key} = ${bool}`);
            }else{
                fragments.push(`${key} = ${obj[key]}`);
            }
        }
    });

    const finalFragment = fragments.join(", ");
    query += finalFragment;


    Object.keys(cnf).forEach(key => {
        if(cnf[key].primaryKey){
            query += ` WHERE ${key} = '${obj[key]}'`
        }
    });


    // console.log(cnf);
    // console.log(query);
    // return false;
    console.log(query);
    query = Buffer.from(query).toString('base64');
    const params = {
        nombreTabla: tablaName,
        query
    }

    await Catalogo.mantenimientoCatalogos(params)
    .then(resp => {
        console.log(resp);
    })
    .catch(err => {
        console.error(err);
    })
    .finally(() => setLoading(false));
  }

  const handleChangeList = async (tabla) => {
    setLoading(true);

    let config = dataList.filter(item => item.NombreTabla === tabla)[0].JSONConfig;
    setConfiguration(config);
    config = JSON.parse(config);
    createColumns(config);
    setTablaName(tabla);

    await Catalogo.getDropdownsByTable(tabla)
    .then(resp => {
        setData(resp.data);
    })
    .catch(err => {
        console.error(err);
    })
    .finally(() => setLoading(false));
  }

  const createColumns = (config) => {
    let tmp = [];
    Object.keys(config).forEach(item => {
        if(config[item].editable){
            tmp.push({ field: item, headerName: config[item].descripcion });
        }
    });
    setColumns(tmp);
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    hangleGetAllTables(event.selected + 1, pageRows, TableId, str);
  };


  return {
    columns,
    data,
    pageCount,
    dataList,
    handleChange,
    handleChangeList,
    handlePageClick,
    // handleEdit,
    loading,
    isOpen,
    setIsOpen,
    dataMarca,
    listInnerRef,
    setcurrentPageMarca,
    setLoading,
    currentPage: initialPage,
    setpageRows,
    configuration,
    EditRow,
    tablaName
  }
}

export default useTablasSecundariasDinamicas