import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayCitaAltaPlacas: "",
    hourCitaAltaPlacas: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdCitaAltaPlacas: "",
}

export const AltaPlacasSlice = createSlice({
    name: 'AltaPlacasSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayCitaAltaPlacas: action.payload.day,
                hourCitaAltaPlacas: action.payload.hour,
                calendarioIdCitaAltaPlacas: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayCitaAltaPlacas: "",
                hourCitaAltaPlacas: "",
                calendarioIdCitaAltaPlacas: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = AltaPlacasSlice.actions

export default AltaPlacasSlice.reducer