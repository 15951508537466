import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';

const CrearFolderModal = ({ isOpen, setIsOpen, item, handleGetAll, message, toast }) => {
    const [valor, setValor] = useState("");
    let initial = {
        vin: item?.vin ?? "",
        folderId: item?.numIdFolder ?? "",
        generalId: item?.generalId
    }
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        folderId: Yup.string()
            .required("El campo es requerido.")
            .matches(
                "^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$",
                "no se permiten espacios en blanco."
              )
            .min(36, "el campo debe contener 36 caracteres.")
            .max(36, "el campo debe contener 36 caracteres.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        vin: Yup.string()
            .required("El campo es requerido."),
    });


    const handleAddEdit = async ({ vin, folderId, generalId }) => {

        await VentaVehiculoService.putIdFolder(vin, folderId, generalId)
            .then(res => {
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, errors, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5'><strong>Alta manual folder datadocs</strong></span>
                                <span style={{ fontSize: '12px' }}>A continuación se muestran el VIN para asignar un folder al vehículo selecionado además se debe capturar el ID del folder:</span>
                            </div>

                            <Form>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-sm-12">
                                        <TextField label="VIN:" disabled name="vin" type="text" holder="Escribe" />
                                    </div>
                                    {/* navigator.clipboard.writeText(values.vin) */}
                                    <CopyToClipboard text={values.vin}>
                                        <div style={{ position: 'relative', right: '-59px', top: `${Object.keys(errors).length > 0 ? '-45px' : '-45px'}` }} onClick={() => { toast.success(message("VIN Copiado.!", "Se copio la información correctamente.")); }}>
                                            <span style={{ position: 'absolute', right: '70px', width: '40px', backgroundColor: 'transparent', color: 'black' }} className="btn col-1 d-flex justify-content-center"><i className="ri-file-copy-fill"></i></span>
                                        </div>
                                    </CopyToClipboard>

                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-12">
                                        <TextField label="ID Folder:" name="folderId" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default CrearFolderModal