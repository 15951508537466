import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import SubmenuDatadocs from './SubMenuDatadocs'
import Modal from '../../Components/Modal/Modal'
import Spinner from '../../Components/Loadig'
import DataTable from '../../Components/datatable/DataTable'
import useDataDocsDeleted from '../../Hooks/AdminDataDocs/useDocsDeleted'
import { message, pageRows } from '../../Utils/functions'
import Watermark from '@uiw/react-watermark'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import VisorFile from '../../Components/VisorFile'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'
const DocsEliminados = () => {
    const { columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setIsOpenDocs,
        isOpenDocs,
        DataDocumentos,
        setFechaFiltro,
        setTipoDocumentoId,
        handleDelete,
        handleRecover,
        SelectedDocs,
        handleGetFileById,
        fileName,
        setpageRows } = useDataDocsDeleted(toast);
    return (
        <>
            <SubmenuDatadocs />
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                {/* <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Folder DataDocs</strong></span>
        </div> */}
                <span className='mt-2'>A continuación se pueden visualizar los archivos eliminados.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ArchivosEliminados, SubModule.DataDocs).Ver}>
                <section className='headerTab pb-4'>
                    <div className='mx-2'>
                        <div className='row col-12'>
                            <div className="col-lg-4 col-md-6 col-sm-12 form-group inner-addon right-addon">
                                <i className="glyphicon fas fa-search"></i>
                                <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm12d-flex'>
                                <Select options={DataDocumentos} className='col-12' onChange={(e) => { setTipoDocumentoId(e.value) }} placeholder="Tipo de Documento" />

                            </div>
                            <div className="col-lg-3 col-md-6 col-sm12d-flex">
                                <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setFechaFiltro(e.target.value); }} />
                            </div>
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        deleteable={ValidatePermissionById(4, Section.ArchivosEliminados, SubModule.DataDocs).Ver}
                        handleDelete={handleDelete}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        handleDocuments={handleGetFileById}
                        recover={ValidatePermissionById(4, Section.ArchivosEliminados, SubModule.DataDocs).Ver}
                        handleRecover={handleRecover}
                    />
                </div>
            </ValidatePermission>

            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
                // <Modal isOpen={isOpenDocs} color='#fff' width={1000}>
                //     <div>
                //         <h6><strong>Nombre del archivo: {fileName}</strong></h6>
                //         <section>
                //             <Watermark
                //                 content="DataMovil"
                //                 fontSize={20}
                //                 fontColor="red"
                //                 style={{ background: '#fff' }}
                //             >
                //                 <DocViewer
                //                     documents={SelectedDocs?.map((file) => ({
                //                         uri: window.URL.createObjectURL(file),
                //                         fileName: file.name,
                //                     }))}
                //                     pluginRenderers={DocViewerRenderers}
                //                 />
                //             </Watermark>
                //         </section>

                //         <div className='d-flex justify-content-end mt-5'>
                //             <button className="btn col-2" type="button" onClick={() => { setIsOpenDocs(false) }}>Cerrar</button>
                //             {/* <button className="btn col-5" type="button" onClick={() => { handleSubmitDelete() }}>Aceptar</button> */}
                //         </div>
                //     </div>
                // </Modal>
            }
        </>
    )
}

export default DocsEliminados