import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { SelectField } from '../../../Components/SelectField';
import * as XLSX from "xlsx";
import { useEffect } from 'react';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import PagoUnidad from '../../../Services/PagoUnidad/PagoUnidad';
import PagoUnidadesSeguimiento from './PagoUnidadesSeguimiento';
import RegistrosPagoUnidadModal from '../../../Components/Modal/RegistrosPagoUnidadModal';
import { TextAreaField } from '../../../Components/TextAreaField';
import DataTable from '../../../Components/datatable/DataTable';
import ReactPaginate from 'react-paginate';

const SeguimientoProcesoPago = () => {
    const refFormik = useRef();
    const fileRef = useRef();
    const [IsFilePicked, setIsFilePicked] = useState();
    const [SelectedFile, setSelectedFile] = useState("");
    const [JsonData, setJsonData] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    let initial = {
        buscar: "",
        tipoCompraId: null,
        RazonSocial: null,
        fechaInicio: "",
        fechaFin: "",
        comentarios: "",
        page: 1,
        rows: 10
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [collpsableSeguro, setCollpsableSeguro] = useState(false);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });

    const [dataErrors, setDataErrors] = useState([]);
    
    const columns = [
        { field: 'vin', headerName: 'VIN'},
        { field: 'contrato', headerName: 'Contrato'},
        { field: 'fechaEntrega', headerName: 'Fecha de entrega'},
        { field: 'razonSocial', headerName: 'Razón Social'},
        { field: 'tipoCompra', headerName: 'Tipo de compra'},
        { field: 'precioUnidadStr', headerName: 'Valor de la unidad'},
        { field: 'nombreMarca', headerName: 'Marca'},
        { field: 'nombreModelo', headerName: 'Modelo'},
        { field: 'nombreYear', headerName: 'Año'},
        { field: 'nombreVersion', headerName: 'Versión'},
    ];

    const columnsErrors = [
        { field: 'folioId', headerName: 'Folio'},
        { field: 'vin', headerName: 'VIN'},
        { field: 'messageError', headerName: 'Error'},
    ];

    const [tipoCompra, setTipoCompra] = useState([]);
    const [razonSocial, setRazonSocial] = useState([]);
    const [TotalPage, setTotalPage] = useState(0);
    const [vehiculosReporte, setVehiculosReporte] = useState([]);


    useEffect(() => {
        getTipoCompras()
        // getRazonesSociales()
    }, []);


    const changeHandler = () => {
        fileRef.current.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        );
    };

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        handleConvert(event.target.files[0])
    }

    const handleConvert = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(json);                  
            };
            reader.readAsBinaryString(file);
        }
    };

    const showNextModal = (values) => {
        setModalMessage({ isOpen: true, type: 4, data: JsonData, title: "Registros por procesar", comentarios:  values.comentarios });
    }

    const getTipoCompras = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoCompra')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                if(item.TipoCompra.toLowerCase() !== 'inventario'){
                    items.push({
                        value: item.TipoCompraId,
                        label: item.TipoCompra
                    })
                }
            });

            setTipoCompra(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const getRazonesSociales = async (values) => {
        let TipoCompraId = values;
        await PagoUnidad.pagoUnidadRazonSocialGet(TipoCompraId)
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.razonSocial,
                    label: item.razonSocial
                })
            });
            setRazonSocial(items);
        })
        .catch(err => {
            console.error(err);
        })
    };

    const getSeguimientoUnidadesProcesoPago = async (values = initialValue, page = 1) => {
        values.page = page;
        setLoading(true);
        let items = [];

        if(values.RazonSocial === '')
        {
            values.RazonSocial = null;
        }

        await PagoUnidad.getSeguimientoUnidadesProcesoPago(values)
        .then(resp => {
            let items = [];
            setTotalPage(resp.data.totalPages);
            setCurrentPage(resp.data.currentPage - 1);
            resp.data.data.forEach(item => {
                const idx = (element) => element.pagoUnidadProcesoPagoId === item.pagoUnidadProcesoPagoId
                const idxFound = items.findIndex(idx);

                if(idxFound !== -1) {
                    items[idxFound].detalles.push({
                        vin: item.vin,
                        contrato: item.contrato,
                        fechaEntrega: item.fechaEntrega,
                        valorUnidad: item.valorUnidad,
                        nombreMarca: item.nombreMarca,
                        nombreModelo: item.nombreModelo,
                        nombreYear: item.nombreYear
                    });
                }else{
                    const reporte = {
                        pagoUnidadProcesoPagoId: item.pagoUnidadProcesoPagoId,
                        folioId: item.folioId,
                        tipoCompra: item.tipoCompra === null ? '-' : item.tipoCompra,
                        razonSocial: item.razonSocial === null ? '-' : item.razonSocial,
                        cantidadVehiculos: item.cantidadVehiculos,
                        montoTotal: item.montoTotal,
                        fechaAlta: item.fechaAlta,
                        collapsedDiv: true,
                        detalles: [{
                            vin: item.vin,
                            contrato: item.contrato,
                            fechaEntrega: item.fechaEntrega,
                            valorUnidad: item.valorUnidad,
                            nombreMarca: item.nombreMarca,
                            nombreModelo: item.nombreModelo,
                            nombreYear: item.nombreYear
                        }]
                    };

                    items.push(reporte);
                }

            }); 
            setVehiculosReporte(items)
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const CollapseDiv = (item) => {
        const data = vehiculosReporte;
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear();
        return formattedDate;
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrentPage(page);
        setPagina(page);
        getSeguimientoUnidadesProcesoPago(refFormik.current.values, page);
    };

    const showErrors = (data) => {
        let errors = [];
        data.forEach(item => {
            if(item.error){
                errors.push(item)
            }
        });

        setDataErrors(errors);
        setModalMessage({ isOpen: true, title: 'Listado de Errores', type: 5 });
    }

    

    const ExportarExcel = async (folio) => {
        const params = { folio }
        setLoading(true);
        await PagoUnidad.ExportPendientesPagoProceso(params)
        .then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `PendientesPagoProcesoPago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        })
        .catch(err => {
            console.error(err)
        })
        .finally(() => setLoading(false))
    }
        
    const validate = Yup.object({
        tipoCompraId: Yup.string()
            .required("El campo es requerido."),
    });
    
    const cardStyle = {
        "boxShadow": "none", 
        "border": "solid", 
        "borderWidth": "thin",
        "borderColor": "#BDBDBD"
    }

    const [archivosCargados, setArchivosCargados] = useState(0);

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={modalMessage.type < 4 ? '600' : '980'}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <>
                                <div>
                                    <div className='justify-content-start px-4 py-3 align-items-center'>
                                        <div className="row">
                                            <div className="col col-12">
                                                <h6><strong>{modalMessage.title}</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2">
                                                <strong><small>1. Subir archivos</small></strong>
                                            </div>
                                            <div className="my-3 row">
                                                <input className="form-control d-none" type="file" id="formFile" ref={fileRef} onChange={handleChangeFile} />
                                                <section className='d-flex align-items-end'>
                                                    <fieldset className='col-6 btn' onClick={changeHandler}>Seleccionar archivo</fieldset>                                        
                                                    <input type="text" className='form-control' disabled value={SelectedFile?.name  ?? "Sin archivos seleccionados"}/>
                                                </section>
                                            </div>
                                        </div>                                        
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 mb-1">
                                                <strong><small>2. Comentarios</small></strong>
                                            </div>
                                            <div className="col-sm-12 mb-2">
                                                <TextAreaField
                                                    id="comentarios"
                                                    className="form-control"
                                                    rows="3"
                                                    name="comentarios"
                                                    type="text"
                                                    holder="Escribe"
                                                />
                                            </div>
                                        </div>                                        
                                        <section className='d-flex justify-content-end'>
                                            <button 
                                                id="AT_BtnCloseModal" 
                                                className='btn btn-gray d-flex justify-content-center align-items-center' 
                                                style={{ paddingLeft: '25px', paddingRight: '25px', marginRight: '10px' }} 
                                                onClick={() => { 
                                                    setModalMessage({ ...modalMessage, isOpen: false }) 
                                                    setSelectedFile('');
                                                }}
                                                >Regresar
                                            </button>
                                            <button 
                                                id="AT_BtnCloseModal" 
                                                className='btn btn-blue d-flex justify-content-center align-items-center' 
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }} 
                                                onClick={() => { 
                                                    setModalMessage({ ...modalMessage, isOpen: false }) 
                                                    showNextModal(values);
                                                    setSelectedFile('');
                                                }}>
                                                    Continuar
                                            </button>
                                        </section>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            modalMessage.type === 4 &&
                            <RegistrosPagoUnidadModal
                                refreshData={getSeguimientoUnidadesProcesoPago}
                                dataSearch={values}
                                showErrors={showErrors}
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            // dataErrors
                            modalMessage.type === 5 && 
<>
                                <div>
                                    <div className='justify-content-start px-4 py-3 align-items-center'>
                                        <div className="row">
                                            <div className="col col-12">
                                                <h6><strong>{modalMessage.title}</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2">
                                                <div className="alert alert-danger" role="alert">
                                                <i className="bx bx-x-circle"></i> Los siguientes registros no se procesaron correctamente, a continuación se detallan los motivos
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2">
                                                <DataTable
                                                    column={columnsErrors}
                                                    data={dataErrors}
                                                />
                                            </div>
                                        </div>
                                        <section className='d-flex justify-content-end'>
                                            <button 
                                                id="AT_BtnCloseModal" 
                                                className='btn btn-blue d-flex justify-content-center align-items-center' 
                                                style={{ paddingLeft: '25px', paddingRight: '25px', marginRight: '10px' }} 
                                                onClick={() => { 
                                                    setModalMessage({ ...modalMessage, isOpen: false }) 
                                                    setSelectedFile('');
                                                }}
                                                >Cerrar
                                            </button>
                                        </section>
                                    </div>
                                </div>
                            </>
                        }
                    </Modal>

                    <section>
                        <PagoUnidadesSeguimiento />
                        <div className='pb-4'>
                            <section className='mx-4 my-3 d-flex flex-column'>
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Vehículos en proceso de pago</strong>
                                        </div>
                                        <div className="col-auto">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <button className='btn' onClick={(event) => {
                                                        setModalMessage({ isOpen: true, title: 'Pago de vehículos', type: 3 });
                                                    }}>
                                                        Registrar pago
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <small className='mt-1'>El siguiente listado corresponde a la gestión de los vehículos en proceso de pago. Para visualizar los registros filtra por tipo de compra y/o razón social</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <SelectField id={'tipoCompraId'}
                                                name={'tipoCompraId'}
                                                items={tipoCompra}                                                                                            
                                                onChange={(event) => {
                                                    setFieldValue('tipoCompraId', event.value);
                                                    setFieldValue('RazonSocial', '');
                                                    getRazonesSociales(event.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <SelectField id={'RazonSocial'}
                                                name={'RazonSocial'}
                                                items={razonSocial}                                                                                            
                                                onChange={(event) => {
                                                    setFieldValue('RazonSocial', event.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-auto mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => getSeguimientoUnidadesProcesoPago(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                    <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0", "borderColor": "#E0E0E0"}}>
                                        <div className="row">
                                            <div style={{"width": "50px"}}>
                                            </div>
                                            <div className="col-1">
                                                <strong><small>Folio</small></strong>
                                            </div>
                                            <div className="col-2">
                                                <strong><small>Tipo de Compra</small></strong>
                                            </div>
                                            <div className="col-2">
                                                <strong><small>Razón Social</small></strong>
                                            </div>
                                            <div className="col-2">
                                                <strong><small>Cant. Vehículos</small></strong>
                                            </div>
                                            <div className="col-2">
                                                <strong><small>Monto Total</small></strong>
                                            </div>
                                            <div className="col-2">
                                                <strong><small>Fecha de solicitud</small></strong>
                                            </div>
                                            <div style={{"width": "50px"}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    vehiculosReporte.length === 0 && 
                                    <div className="row px-4 mt-2" style={{"height": "40px", "lineHeight": "40px"}}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <small>No hay datos que mostrar</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    vehiculosReporte.length > 0 &&
                                    vehiculosReporte.map((item, idx) => {
                                        return <>
                                            <div className="row px-2" key={`item_${idx}`}>
                                                <div className="card col-12 p-0 m-0" style={cardStyle}>
                                                    <div className="card-header p-0 pt-2 pb-2 m-0" style={{"background": "#F4F4F4", "boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                        <div 
                                                            className="row justify-content-between p-0 m-0" 
                                                            >
                                                            <div className="col-12" >
                                                                <div className="row">
                                                                    <div 
                                                                        style={{"width": "50px","textAlign": "right"}} 
                                                                        data-bs-toggle="collapse" 
                                                                        href={`#collapseItem${idx}`} 
                                                                        role="button" 
                                                                        aria-expanded="false" 
                                                                        aria-controls={`collapseItem${idx}`} 
                                                                        onClick={() => 
                                                                            CollapseDiv(item)
                                                                        }>
                                                                        <i className={!collpsableSeguro ? "icon-blue bx bx-plus" : "icon-blue bx bx-minus"}></i>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <small>{item.folioId}</small> 
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <small>{item.tipoCompra}</small> 
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <small>{item.razonSocial}</small> 
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <small>{item.cantidadVehiculos}</small> 
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <small>{ formatMoney(item.montoTotal) }</small> 
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <small>{ dateFormat(item.fechaAlta) }</small> 
                                                                    </div>
                                                                    <div style={{"width": "50px"}}>
                                                                        <button 
                                                                            className='btn'
                                                                            onClick={(event) => {
                                                                                ExportarExcel(item.folioId);
                                                                            }}
                                                                        ><i className="bx bx-download"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="collapse mb-4" id={`collapseItem${idx}`}>
                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div style={{"width": "40px","textAlign": "right"}}>
                                                                        <strong>#</strong>
                                                                    </div>
                                                                    <div style={{"width": "190px"}}>
                                                                        <strong><small>VIN</small></strong>
                                                                    </div>
                                                                    <div style={{"width": "160px"}}>
                                                                        <strong><small>Contrato</small></strong>
                                                                    </div>
                                                                    <div style={{"width": "170px"}}>
                                                                        <strong><small>Fecha de entrega</small></strong>
                                                                    </div>
                                                                    <div  style={{"width": "170px"}}>
                                                                        <strong><small>Valor de la unidad</small></strong>
                                                                    </div>
                                                                    <div style={{"width": "170px"}}>
                                                                        <strong><small>Marca</small></strong>
                                                                    </div>
                                                                    <div  style={{"width": "170px"}}>
                                                                        <strong><small>Modelo</small></strong>
                                                                    </div>
                                                                    <div style={{"width": "80px"}}>
                                                                        <strong><small>Año</small></strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.detalles.map((subitem, subidx) => {
                                                                return <>
                                                                    <div className="row px-4" key={`subitem_${subidx}`} style={{"height": "30px", "lineHeight": "30px"}}>
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div style={{"width": "40px","textAlign": "right"}}>
                                                                                    <small>{subidx+1}</small>
                                                                                </div>
                                                                                <div  style={{"width": "190px"}}>
                                                                                    <small>{ subitem.vin }</small>
                                                                                </div>
                                                                                <div style={{"width": "160px"}}>
                                                                                    <small>{ subitem.contrato }</small>
                                                                                </div>
                                                                                <div style={{"width": "170px"}}>
                                                                                    <small>{ dateFormat(subitem.fechaEntrega) }</small>
                                                                                </div>
                                                                                <div  style={{"width": "170px"}}>
                                                                                    <small>{ formatMoney(subitem.valorUnidad) }</small>
                                                                                </div>
                                                                                <div  style={{"width": "170px"}}>
                                                                                    <small>{ subitem.nombreMarca }</small>
                                                                                </div>
                                                                                <div  style={{"width": "170px"}}>
                                                                                    <small>{ subitem.nombreModelo }</small>
                                                                                </div>
                                                                                <div style={{"width": "80px"}}>
                                                                                    <small>{ subitem.nombreYear }</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                                {
                                    vehiculosReporte.length > 0 &&
                                    <div className="row">
                                        <div className='col-12'>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={TotalPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                }
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoProcesoPago;
