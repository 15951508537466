import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../Utils/functions'
import Garantias from '../../Services/Cartera/Garantias';
import { setDevolucionSlice } from '../../Redux/Slice/Devoluciones/devolucionesSlice';
import Catalogo from '../../Services/Catalogos/Catalogo';

const ConfirmarCalculadoraModal = ({ modalMessage, setModalMessage, getBoletinado, cambioDevolucionId, submitForm }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        cambioDevolucionBoletinadoStatusId: 50,
        CambioDevolucionBoletinadoMotivoAutorizacionId: "",
        comentariosAutorizacion: "",
        cambioDevolucionId
    };

    useEffect(() => {
    }, []);


    const validate = Yup.object().shape({});

    const handleAutorizar = async () => {
        submitForm(modalMessage.data);
        setModalMessage({ isOpen: false });
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initial}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        ¿Estás seguro de que deseas {modalMessage.data.detallePoliza ? 'Mantener' : 'Cancelar'} la póliza? 
                                    </small>
                                </div>
                            </div>
                        </div>
                        <section className='d-flex justify-content-end'>
                            <div className="row">
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-outline d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            setModalMessage({ ...modalMessage, isOpen: false })
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            handleAutorizar(values);
                                        }}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default ConfirmarCalculadoraModal;
