import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Cierres from '../../../Services/Resguardos/Cierres';
import DataTable from '../../../Components/datatable/DataTable';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AdeudosModal from '../../../Components/Modal/AdeudosModal';
import NotificarClienteCard from './NotificarClienteCard';
import { TextField } from '../../../Components/TextField';

const AdeudosCambioDevolucionCard
    = React.forwardRef(({ ...props }, ref) => {
        const { refFormik } = useRef();
        const location = useLocation();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();
        
        let initial = {
            origen: "",
            desembolso: 0,
            descuento: 0,
            devolucion: 0,
            adeudos: 0,
            total: 0
        }
        
        const [total, setTotal] = useState(0);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        useEffect(() => {
            getAdeudos();
        }, []);

        /* CONSULTAS */
        const getAdeudos = async () => {
            await Cierres.cierreContratoAdeudosCambioDevolucionGet(cierreid)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            })
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <AdeudosModal
                            cierreContratoAdeudoId={initialValue.cierreContratoAdeudoId}
                            modalMessage={modalMessage}
                            getAdeudos={getAdeudos}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAdeudosCambioDevolucion" role="button" aria-expanded="false" aria-controls="collapseAdeudosCambioDevolucion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.cierreContratoAdeudoStatusId === null ? 'semaforo semaforo-default' : ( initialValue.cierreContratoAdeudoStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Adeudos de cambio/devolución</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small>{initialValue.cierreContratoAdeudoStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseAdeudosCambioDevolucion">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-4 px-4">
                                                        <div className="col-9">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 col-lg-4">
                                                                    <TextField  
                                                                        style={{ "textTransform": "capitalize" }}
                                                                        id="origen" disabled={true} 
                                                                        label="Origen" 
                                                                        holder="" 
                                                                        name="origen" 
                                                                        type="text" 
                                                                        onChange={(event) => {
                                                                            setFieldValue("origen", event.target.value);
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-6">
                                                                <table className="table" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                    <thead style={{"backgroundColor":"#E0E0E0"}}>
                                                                        <tr>
                                                                            <th scope="col">Concepto</th>
                                                                            <th scope="col">Monto</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">Desembolso (+)</th>
                                                                            <td>{ formatMoney(values.desembolso) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Descuento (-)</th>
                                                                            <td>{ formatMoney(values.descuento) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Saldo a favor (=)</th>
                                                                            <td>{ formatMoney(values.devolucion) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Adeudos (-)</th>
                                                                            <td>{ formatMoney(values.adeudos) }</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot style={{"backgroundColor":"#E0E0E0"}}>
                                                                        <tr>
                                                                            <th scope="col">Total a pagar (=)</th>
                                                                            <th scope="col">{ formatMoney(values.total) }</th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.cierreContratoAdeudoStatusId === 30 &&
                    <NotificarClienteCard />
                }
            </>
        )
    })
export default AdeudosCambioDevolucionCard