import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigateSliceEntrega = createSlice({
  name: 'EntregaNavigate',
  initialState,
  reducers: {
    tabActiveEntrega: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveEntrega } = navigateSliceEntrega.actions

export default navigateSliceEntrega.reducer