
const stylePasoActivo = {
    display: 'flex',
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '10px',
    width: '160px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    padding: '0px',
}

const stylePasoInactivo = {
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    width: '160px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    padding: '0px',
    // fontWeight: 'bold',
    // borderRadius: '10px',
}

const arrowStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    padding: '0px',
}

const SubHeaderGestoria = ({ idPaso, state }) => {
    return (
        <>
            <div className="row" id="myTab" role="tablist" style={{padding:'0px'}}>
                <div className="col-md-4" style={{padding:'0px'}}>
                    <div>
                    <div className="row" style={{padding:'0px'}}>
                        <div className="col-md-9" style={idPaso == 1 ? stylePasoActivo : stylePasoInactivo}>
                            <span>Captura información</span>
                        </div>
                        <div style={arrowStyle} className="col-md-3">{">"}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4" style={{padding:'0px'}}>
                    <div>
                    <div className="row" style={{padding:'0px'}}>
                        <div className="col-md-9" style={idPaso == 2 ? stylePasoActivo : stylePasoInactivo}>
                            <span>Confirmación Acendes</span>
                        </div>
                        <div style={arrowStyle} className="col-md-3">{">"}</div>
                    </div>
                    </div>
                    
                </div>
                <div className="col-md-4" style={{padding:'0px'}}>
                    <div>
                    <div className="row" style={{padding:'0px'}}>
                        <div className="col-md-10" style={idPaso == 3 ? stylePasoActivo : stylePasoInactivo}>
                            <span>Carga de evidencia</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubHeaderGestoria;