import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   estadoActualSeleccionado: 0,
   nuevaArea: "",
   respData: [
      {
         estado: 1,
         zonadecobranza: 'Monterrey-Country-A',
         coloniasasignadas: 0,
         contratos: 5
      },
      {
         estado: 1,
         zonadecobranza: 'Monterrey-Country-B',
         coloniasasignadas: 10,
         contratos: 5
      },
      {
         estado: 1,
         zonadecobranza: 'Nuevo león centro-A',
         coloniasasignadas: 32,
         contratos: 5
      },
      {
         estado: 2,
         zonadecobranza: 'Azcapotzalco',
         coloniasasignadas: 8,
         contratos: 5
      },
      {
            estado: 2,
            zonadecobranza: 'Benito Juarez',
            coloniasasignadas: 12,
            contratos: 5
      },
      {
         estado: 2,
         zonadecobranza: 'Gustavo A. Madero',
         coloniasasignadas: 30,
         contratos: 5
      },{
         estado: 3,
         zonadecobranza: 'Guadalajara',
         coloniasasignadas: 20,
         contratos: 5
      },
      {
         estado: 3,
         zonadecobranza: 'Guadalajara-centro',
         coloniasasignadas: 17,
         contratos: 5
      }
   ]
}

export const NuevaAreaSlice = createSlice({
   name: 'NuevaAreaSlice',
   initialState,
   reducers: {
      setAddArea: (state, action) => {
         return {
            ...state, nuevaArea: action.payload
         }
      },
      setEstadoActualSeleccionado: (state, action) => {
         return {
            ...state, estadoActualSeleccionado: action.payload
         }
      },
      setRespData: (state, action) => {
         return{
            ...state, respData: action.payload
         }
      }
   }
})

export const { setAddArea, setEstadoActualSeleccionado, setRespData } = NuevaAreaSlice.actions

export default NuevaAreaSlice.reducer