import React, { useState } from "react";
import SubmenuLogs from "./SubMenuLogs";
import useUsuario from "../../Hooks/Logs/useUsuario";
import DataTableLog from "../../Components/datatable/DataTableLog";

import Modal from "../../Components/Modal/Modal";
import Spinner from "../../Components/Loadig";
import ErrorModal from "../../Components/Modal/ErrorModal";
import SuccessModal from "../../Components/Modal/SuccessModal";
import { json, useNavigate } from "react-router-dom";
import Select from "react-select";
import RowPages from "../../Components/RowPages";
import {
  ValidatePermission,
  ValidatePermissionById,
} from "../../Auth/ValidatePermission";
import { Section, SubModule } from "../../Auth/Authorization";
import DataTable from "../../Components/datatable/DataTable";

const Usuario = () => {
  const navigate = useNavigate();
  let {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleCheck,
    pageCount,
    handlePageClick,
    dataModulo,
    dataCategoria,
    setStr,
    setDateTime,
    setCategoriaId,
    setIsOpen,
    item,
    isOpen,
    setModuloId,
    handleDownloadFile,
    initialPage,
    refBtn,
    setpageRows,
    handleExportFile
  } = useUsuario();

  console.log(data);

  const [dataRow, setDataRow] = useState({});

  const handleDetail = async (row) => {
    console.log(row);
    setDataRow(row);
    setModalMessage({ isOpen: true, type: 3, message: "test" });
  };

  return (
    <>
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff">
        {modalMessage.type === 1 && (
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {modalMessage.type === 2 && (
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {modalMessage.type === 3 && (
          <div style={{ width: "60rem" }}>
            <section className="d-flex align-items-center">
              <i
                className="bi bi-chevron-compact-left fs-5"
                style={{ color: "#004c97", fontWeight: "bold" }}
                onClick={() => {
                  setIsOpen(false);
                }}
              ></i>
              <span className="">
                <strong>
                  ID {dataRow?.logUsuarioId} - Módulo: {dataRow?.modulo}{" "}
                </strong>
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong>VIN:</strong> {dataRow?.vin}
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong>Categoría:</strong> {dataRow?.categoria}
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong>Fecha y hora: </strong>
                {dataRow?.dateOnly} {dataRow?.hora}
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong>Usuario:</strong> {dataRow?.nombreUsuario}
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong>Resultado:</strong> {dataRow?.resultado}
              </span>
            </section>
            <br></br>
            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}>
                {" "}
                <strong className="contenido-json">Contenido:</strong>
              </span>
            </section>

            <section className="d-flex flex-column mx-4">
              <span style={{ fontSize: "14px" }}> {dataRow?.contenido}</span>
            </section>

            <section className="mt-5 d-flex justify-content-end">
              <button
                className="btn"
                onClick={() => {
                  setModalMessage({ isOpen: false });
                }}
              >
                Aceptar
              </button>
            </section>
          </div>
        )}
      </Modal>

      <SubmenuLogs />
      <section className="mx-5 my-4 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <span className="fs-5">
            <strong>Registro Usuario</strong>
          </span>
          <div>
            <ValidatePermission
              isActive={
                ValidatePermissionById(4, Section.Usuario, SubModule.Logs)
                  .Historial
              }
            >
              <button
                className="icon btn btn-outline mx-2"
                onClick={() => {
                  navigate(`/admin/logs/usuario/eliminado`, {
                    state: {
                      page: "Usuario",
                      title: "title",
                    },
                  });
                }}
              >
                <i
                  className="bi bi-trash-fill"
                  style={{ color: "#004c97" }}
                ></i>
              </button>
            </ValidatePermission>
            <ValidatePermission
              isActive={
                ValidatePermissionById(4, Section.Usuario, SubModule.Logs)
                  .Descarga
              }
            >
              <button
                className="btn px-4 me-1"
                ref={refBtn}
                onClick={handleExportFile}
              >
                Exportar
              </button>
            </ValidatePermission>
            <ValidatePermission
              isActive={
                ValidatePermissionById(4, Section.Usuario, SubModule.Logs).Ver
              }
            >
              <button
                className="btn px-4 ms-1"
                ref={refBtn}
                onClick={handleDownloadFile}
              >
                Eliminar
              </button>
            </ValidatePermission>
          </div>
        </div>
        <span className="mt-2">
          El siguiente listado corresponde a los registros de movimientos de las
          interacciones del usuario con el sistema.
        </span>
      </section>
      <ValidatePermission
        isActive={
          ValidatePermissionById(4, Section.Usuario, SubModule.Logs).Ver
        }
      >
        <section className="mt-5 mx-4">
          <div className="row col-12">
            <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input
                type="text"
                className="form-control mb-4"
                placeholder="Buscar"
                onChange={(e) => {
                  setStr(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <input
                type="date"
                className="form-control mb-4"
                placeholder="Buscar"
                onChange={(e) => {
                  setDateTime(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Select
                options={dataModulo}
                className="col-12"
                onChange={(e) => {
                  setModuloId(e.value);
                }}
                placeholder="Todos los modulos"
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <Select
                options={dataCategoria}
                className="col-12"
                onChange={(e) => {
                  setCategoriaId(e.value);
                }}
                placeholder="Todos las categorias"
              />
            </div>
          </div>
        </section>
        <RowPages setpageRows={setpageRows} />

        <div className="mx-4 table-responsive tbl-scroll">
          <DataTable
            column={columns}
            data={data}
            editable={false}
            detailable={true}
            handleDetail={handleDetail}
            handleCheck={handleCheck}
            paginate={true}
            checkbox={true}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={initialPage}
          />
        </div>
      </ValidatePermission>
    </>
  );
};

export default Usuario;
