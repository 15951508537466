import Modal from "../../../Components/Modal/Modal"

const PagodeUnidadLimiteModal = ({
    isOpen,
    setIsOpen,
    state,
}) => {

    return (
        <>
            <Modal isOpen={isOpen} color='#fff' width={500}>
                <div className="row" style={{
                    margin: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                }}>
                    <div className="col col-12" style={{
                            display: "flex",
                            placeContent: "center"
                    }}>
                    <i className='bx bx-task-x' style={{
                           fontSize: "3rem",
                           background: "#004C97",
                           color: "white",
                           padding: ".5rem",
                           borderRadius: "50px",
                           marginBottom: "1rem"
                    }}></i>
                    </div>
                    <div className="col col-12 d-flex">
                        <div className='px-4'>
                            <h4><strong>Haz llegado al límite de tu presupuesto: ${state.presupuesto}</strong></h4>
                        </div>
                    </div>
                    <div className="my-4">
                        <button
                            id="AT_BtnCloseModal"
                            className='btn btn-blue d-flex justify-content-center align-items-center'
                            style={{ paddingLeft: '25px', paddingRight: '25px', width: "100%"}}
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default PagodeUnidadLimiteModal