import React from 'react'
import Garantias from '../../Services/Cartera/Garantias';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setInfoVin } from '../../Redux/Slice/IncidenciasSlice/dataVinSlice';
import { setGarantiaSlice } from '../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { setDevolucionSlice } from '../../Redux/Slice/Devoluciones/devolucionesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Llamada from '../../Services/Cartera/Llamadas';
import Catalogo from '../../Services/Catalogos/Catalogo';


const useDevolucion = () => {
    const [str, setStr] = useState("");
    const [vehiculo, setVehiculo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [motivoCambioDevolucionOptions, setMotivoCambioDevolucionOptions] = useState([]);
    
    const { GarantiaId } = useSelector((state) => state.GarantiaSlice);
    
    const tipoOperacion = [{ value: 0, label: 'Cambio Unidad' }, { value: 1, label: 'Devolucion' }];
    const { id, idllamada } = useParams();
    const datos = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        getMotivoCambioDevolucion();
    }, []);

    useEffect(() => {
        getCambioDevolucion();
    }, [GarantiaId]);

    const getCambioDevolucion = async () => {
        if(GarantiaId.trim() === "") {
            return false;
        }
        await Garantias.garantiaCambioDevolucionGet(GarantiaId)
        .then(resp => {
            dispatch(setDevolucionSlice(resp.data));
        })
        .catch(err => {
            console.error(err);
        });
    }

    const getMotivoCambioDevolucion = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoCambioDevolucion')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.MotivoCambioDevolucionId,
                    label: item.MotivoCambioDevolucion
                })
            });

            setMotivoCambioDevolucionOptions(items);
        })
        .catch(err => {

        });
    }
    

    return {
        loading,
        setModalMessage,
        modalMessage,
        tipoOperacion,
        motivoCambioDevolucionOptions,
        getCambioDevolucion
    }

}

export default useDevolucion