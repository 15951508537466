import axios from '../../Utils/ApiService';

const InstalacionDatamovilService = {
    getAllVehiculosInventario:(page,rows,string,TipoCompra,ubicacion,statusId)=> {        
        return axios.get(`/functions/GetAllVehiculosInventarioDD?Page=${page}&Rows=${rows}&Buscar=${string}&TipoCompraId=${TipoCompra}&UbicacionId=${ubicacion}&StatusDatamovilId=${statusId}`);        
    },   
    downloadVehiculosInventario:(string,TipoCompra,ubicacion,statusId)=> {        
        return axios.get(`/functions/GetExportVehiculosInventarioDD?Buscar=${string}&TipoCompraId=${TipoCompra}&UbicacionId=${ubicacion}&StatusDatamovilId=${statusId}`,{responseType: 'blob'});        
    },  
    getAllVehiculosSociComercial:(page,rows,string,socioId)=> {        
        return axios.get(`/functions/GetAllVehiculosSocioComercialDD?Page=${page}&Rows=${rows}&Buscar=${string}&SocioComercialId=${socioId}`);        
    },   
    downloadVehiculosSocioComercial:(string,socioId)=> {        
        return axios.get(`/functions/GetExportVehiculosSocioComercialDD?Buscar=${string}&SocioComercialId=${socioId}`,{responseType: 'blob'});        
    },  
    getAllVehiculosEncontrato:(page,rows,string,tipocompraId)=> {        
        return axios.get(`/functions/GetAllVehiculosEnContratoDD?Page=${page}&Rows=${rows}&Buscar=${string}&TipoCompraId=${tipocompraId}`);        
    },   
    downloadVehiculosEncontrato:(string,tipocompraId)=> {        
        return axios.get(`/functions/GetExportVehiculosEnContratoDD?Buscar=${string}&TipoCompraId=${tipocompraId}`,{responseType: 'blob'});        
    }, 
    getAllVehiculosPendientePlaca:(page,rows,string,tipocompraId)=> {        
        return axios.get(`/functions/GetAllVehiculoPendientesPlacas?page=${page}&Rows=${rows}&TipoCompraId=${tipocompraId}&Buscar=${string}`);        
    },   
}
export default InstalacionDatamovilService;