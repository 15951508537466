import { set } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import Logs from '../../Services/Logs/Logs';
import { useNavigate, useParams } from 'react-router-dom';
import FileSaver, { saveAs } from 'file-saver';
import ResponsivaService from '../../Services/Seguros/ResponsivaService';
import { getDateFormat } from '../../Utils/functions';
const useResponsivaHistorial = () => {
  const refBtn = useRef();
  const refBtnExp = useRef();
  let ids = [];
  const navigate = useNavigate();
  let { id } = useParams();
  const refresh = () => window.location.reload(true)
  const columns = [
    { field: 'fechaHora', headerName: 'Fecha y hora de registro', width: 780 },
    { field: 'usuario', headerName: 'Usuario', width: 480 },
    { field: 'tipoResponsiva', headerName: 'Tipo responsiva', width: 200 },
    // { field: 'fechaRegistro', headerName: 'Fecha registro', width: 200 },
    { field: 'noCaptura', headerName: 'No. Factura', width: 200 },
    { field: 'empresaFactura', headerName: 'Empresa que factura', width: 250 },
    { field: 'empresa', headerName: 'Empresa', width: 200 },
    { field: 'representanteLegal', headerName: 'Representante legal', width: 200 },
    { field: 'banco', headerName: 'Banco', width: 200 },
    { field: 'tipoPago', headerName: 'Tipo de pago', width: 200 },
    { field: 'referenciaPago', headerName: 'Referencia de pago', width: 200 },
    { field: 'lugarTribunal', headerName: 'Lugar del tribunal', width: 200 },
    { field: 'nombreVendedor', headerName: 'Nombre del vendedor', width: 200 },
    { field: 'domicilioVendedor', headerName: 'Domicilio del vendedor', width: 200 },
    { field: 'representanteLegalVendedor', headerName: 'Repr. legal del vendedor', width: 200 },
    { field: 'valorVehiculo', headerName: 'Valor del vehículo', width: 200 },
    { field: 'poseedorVehiculo', headerName: 'Poseedor del vehículo', width: 200 },



  ];
  const columnsDetail = [
    { field: 'logInterfazId', headerName: 'Id', width: 150 },
    { field: 'fechaAlta', headerName: 'Fecha', width: 160 },
    { field: 'accion', headerName: 'Accion', width: 200 },
    { field: 'etiquetas', headerName: 'Etiqueta', width: 200 },
    { field: 'resultado', headerName: 'Resultado', width: 200 }
  ];
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [tipoResponsiva, setTipoResponsiva] = useState([]);
  const [Dataempresa, setDataempresa] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [claveEmpresa, setClaveEmpresa] = useState("");
  const [claveTipoResponsiva, setClaveTipoResponsiva] = useState("");
  const [item, setItem] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isenablebtn, setIsenablebtn] = useState(true);
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [pageRows, setPageRows] = useState(10)

  useEffect(() => {
    handleGetTipoResponsiva();
    handleGetEmpresa()
  }, [])

  useEffect(() => {
    handleGetAllResponsivaHistorial(1);
    setInitialPage(0);
  }, [Str, claveEmpresa, claveTipoResponsiva, pageRows])

  //selectList
  const handleGetModulo = async () => {
    setLoading(true);
    await Logs.getList("modulo")
      .then(res => {
        setDataModulo(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetTipoResponsiva = async () => {
    setLoading(true);
    await ResponsivaService.getTipoResponsiva()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoResponsivaId,
            label: item.tipoResponsiva
          })
        });

        setTipoResponsiva(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetEmpresa = async () => {
    setLoading(true);
    await ResponsivaService.getEmpresa()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.empresaId,
            label: item.empresa
          })
        });
        setDataempresa(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllResponsivaHistorial = async (page) => {
    setLoading(true);
    await ResponsivaService.getResponsivaHistorial(id,page, pageRows, Str, claveTipoResponsiva, claveEmpresa)
      .then(res => {
        let dataRes = res.data.data

        dataRes.map(item => {
          // item.fechaHora = getDateFormat(item.fechaHora,"")
          item.fechaRegistro = item.fechaRegistro.replace(/\//g,"-")
        })

        setData(dataRes);

        setPageCount(res.data.totalPages)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetAllResponsivaHistorial(event.selected + 1);
  };
  const handleCheck = (cheked, value) => {
    if (cheked) {
      ids.push(value.logInterfazId);
      setIdsEliminar(current => [...current, value.logInterfazId]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item !== value.logInterfazId));
    }
  }
  const handleDetails = async (item) => {
    setLoading(true);
    await Logs.getLogsDetailInterfaz(item.logInterfazId)
      .then(res => {
        setDataDetail(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

    setItem(item);
    setIsOpen(true);
  }
  const handlepageDetail = () => {
    navigate(`/admin/logs/interfaces/eliminado`, {
      state: {
        page: 'Interfaces',
        title: "title"
      },
    });
  }
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    setLoading(true);
    if (idsEliminar.length > 0) {
      let newIds = idsEliminar.join();
      await Logs.downloadFileInterfaz(newIds)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `LogInterfaz-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

          setLoading(false);
          handleUnchekedColumns();
          refBtn.current.disabled = false;
          setIdsEliminar([]);
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtn.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }

  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  return {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    pageCount,
    handlePageClick,
    tipoResponsiva,
    setStr,
    setPageRows,
    Dataempresa,
    setClaveTipoResponsiva,
    setClaveEmpresa
  }
}

export default useResponsivaHistorial