import React from 'react'
import { useSelector } from 'react-redux'

const OrigenCard = ({}) => {
    const { cliente, usoUnidad, numContrato } = useSelector((state) => state.DataVinSlice);
    const { FolioGarantia, Gravedad, TipoReporte, Falla } = useSelector((state) => state.GarantiaSlice);

    return (
        <>
            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                    <div className="col-12 underline-border">
                        <h6>
                            <strong>Origen del proceso</strong>
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Fecha de último pago:</strong> {cliente}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Uso de unidad:</strong> {usoUnidad}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Cliente:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Folio de garantía:</strong> {FolioGarantia}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Teléfono:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Clasificación de la falla:</strong> {Falla}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Correo electrónico:</strong> </small>
                    </div>
                    <div className="col-6">
                        <small><strong>Gravedad:</strong> {Gravedad}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>No. de contrato:</strong> {numContrato}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Tipo de reporte:</strong> {TipoReporte}</small>
                    </div>
                </div>
            </div>
        </>)
}

export default OrigenCard