import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import useDataDocs from '../../../Hooks/DataDocs/useDataDocs'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroCotizado from '../../../Hooks/Seguros/useSeguroCotizado'
import useSeguroSinCotizar from '../../../Hooks/Seguros/useSeguroSinCotizar'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const SeguroSinCotizar = () => {
    const { columns,
        data,
        handleEdit,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        DataMarca,
        handleCreateFolder,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        setfiltroDocs,
        habdleGetAll,
        handleChangeMarca,
        handleChangeModelo,
        handleChangeYear,
        setClaveVersion,
        DataModelo,
        DataYear,
        DataVersion,
        defaultValueModelo,
        defaultValueYear,
        defaultValueVersion,
        setDefaultValueVersion,
        setpageRows } = useSeguroSinCotizar();
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos sin cotización</strong></span>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos sin cotización del seguro inicial para poder capturarlo.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosSinCotización, SubModule.VehículosSinCotización).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataMarca} className='col-12' onChange={(e) => { handleChangeMarca(e.value) }} placeholder="Marca" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataModelo} value={defaultValueModelo} className='col-12' onChange={(e) => { handleChangeModelo(e) }} placeholder="Modelo" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataYear} value={defaultValueYear} className='col-12' onChange={(e) => { handleChangeYear(e) }} placeholder="Año" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataVersion} value={defaultValueVersion} className='col-12' onChange={(e) => { setClaveVersion(e.value); setDefaultValueVersion(e) }} placeholder="Versión" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        editable={ValidatePermissionById(4, Section.VehículosSinCotización, SubModule.VehículosSinCotización).Actualizar}
                        handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenDocs &&
                <CrearFolderModal
                    isOpen={isOpenDocs}
                    setIsOpen={setIsOpenDocs}
                    item={dataVehiculo}
                    handleGetAll={habdleGetAll}
                    message={message}
                    toast={toast}
                />
            }
        </>
    )
}

export default SeguroSinCotizar