
import { useFloating, shift, offset, arrow } from '@floating-ui/react';
import React, { useRef, useState } from "react";
import './TooltipUI.css';

const TooltipUI = ({ value, position = 'top right' }) => {
    const arrowRef = useRef();
    const { refs, floatingStyles, update } = useFloating({
        placement: position,
        middleware: [
            offset(30),
            shift(),
            arrow({ element: arrowRef }),
        ],
    });
    const [isVisible, setIsVisible] = useState(false);

    const toggleTooltip = (visible) => {
        setIsVisible(visible);
        if (visible) {
            update();
        }
    };

    return (
        <>
            <span
                ref={refs.setReference}
                className='icon-blue icon-background'
                onMouseEnter={() => toggleTooltip(true)}
                onMouseLeave={() => toggleTooltip(false)}
            >
                <i className='ri-message-2-fill'></i>
            </span>

            {isVisible && (
                <div ref={refs.setFloating} style={floatingStyles} className="tooltipUI">
                    {value}
                    {/* <div ref={arrowRef} style={{ position: 'absolute', width: '10px', height: '10px', background: 'black' }} /> */}
                </div>
            )}
        </>
    );
}

export default TooltipUI;