import React, { useEffect, useState } from 'react'
import Parametros from '../../Services/Parametros/Parametros';

const DataDocsCus = () => {
  const columns = [
    { field: 'parametro', headerName: 'Párametro', width: 220 },
    { field: 'proceso', headerName: 'Proceso', width: 200 },
    { field: 'valor', headerName: 'Valor', width: 200 },
    { field: 'ultimoUpd', headerName: 'Ultima Modificación', width: 200 },
  ];
  const columnsH = [
    // { field: 'parametrosId', headerName: 'Id', width: 220 },
    { field: 'parametro', headerName: 'Parámetro', width: 280 },
    { field: 'valorAnterior', headerName: 'Valor Anterior', width: 100 },
    { field: 'valorNuevo', headerName: 'Valor Nuevo', width: 200 },
    { field: 'nombreUsuario', headerName: 'Usuario', width: 100 },
    { field: 'fechaString', headerName: 'Fecha', width: 250 },

  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [item, setItem] = useState(null);
  const [isOpenModalParams, setIsOpenModalParams] = useState();
  const [dataHistory, setDataHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);   
  const [totalPage, setTotalPage] = useState();
  const [str, setStr] = useState("");
  const [dateTime, setDateTime] = useState(""); 
  const [pageRows, setpageRows] = useState(10)

  const [strFiltro, setStrFiltro] = useState("")
  const [pageRowsData, setpageRowsData] = useState(10)
  const [initialPageData, setInitialPageData] = useState(0);
  const [totalPageData, setTotalPageData] = useState();

  useEffect(() => {
    handleGetDataDocs(1);
  }, [strFiltro,pageRowsData])

  useEffect(() => {
    handleGetHistory(1);
    setCurrentPage(1);
  }, [str,dateTime,pageRows])

  const handleGetDataDocs = async (page) => {
    setLoading(true);
    await Parametros.getTablesParamsList("datadocs",page,pageRowsData,strFiltro)
      .then(res => {
        setTotalPageData(res.data.totalPages);
        setData(res.data.data);        
        handleGetHistory(1);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetHistory = async (page) =>{      
    setLoading(true);
    await Parametros.getHistoy(page,pageRows,str,dateTime,"datadocs")
      .then(res => {          
        setTotalPage(res.data.totalPages);
        setDataHistory(res.data.data);      
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleUpdateToken = async() =>{
    setLoading(true);
    await Parametros.UpdateTokenDatadocks()
      .then(res => {                
        setLoading(false);
        handleGetDataDocs();
        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Tocken actualizado exitosamente." })
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    handleGetHistory(event.selected + 1);
  };
  const handleEdit = (item) => {
    
    setItem(item)
    setIsOpenModalParams(true);
  }
  const handlePageClickData = (event) => {
    setInitialPageData(event.selected);
    handleGetDataDocs(event.selected + 1);
  };
  return {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleEdit,
    handleGetDataDocs,
    isOpenModalParams,
    setIsOpenModalParams,
    item,    
    columnsH,
    dataHistory,
    totalPage,
    handlePageClick,
    setStr,
    setDateTime,
    currentPage,
    handleUpdateToken,
    setpageRows,
    setStrFiltro,
    totalPageData,
    handlePageClickData,
    initialPageData,
    setpageRowsData
  }
}

export default DataDocsCus