import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import PagoRefrendoPlacas from '../../../Pages/Gestoria/PagoRefrendoYPlacas/PagoRefrendoYPlacas';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';
import PendienteEnviarAcendesService from '../../../Services/Gestoria/PendienteEnviarAcendesService';
import { AddDays_CurrentDate, message } from '../../../Utils/functions';

const usePendienteEnviarAcendes = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 170 },
        { field: 'contrato', headerName: 'Contrato', width: 70 },
        { field: 'accesorio', headerName: 'Accesoio', width: 100 },
        { field: 'montoStr', headerName: 'Monto', width: 150 },
        { field: 'fechaUltimoIntento', headerName: 'Fecha del último intento', width: 250 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageRows, setpageRows] = useState(10);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [state, setState] = useState();
    const [clavetipoTramite, setClavetipoTramite] = useState("");
    const [claveStatusTipoTramite, setClaveStatusTipoTramite] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await PendienteEnviarAcendesService.getAllPendienteEnviarAcendes(page, pageRows, str)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleDetail = async (item) => {
        setLoading(true);
        await PendienteEnviarAcendesService.ReenviarAcendes({...item,fechaRegistro:AddDays_CurrentDate(item.refrendo)})
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    setLoading(false);
                } else {
                    toast.error(message("¡Error!", res.data.data.mensaje));
                    habdleGetAll(1);
                    setLoading(false);
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleChange = value => {
        setState(value);
        let stringValue = [];
        value?.map(x => {
            stringValue.push(x.value);
        })
        setClaveStatusTipoTramite(stringValue.join());
    }
    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        handleDetail
    }
}

export default usePendienteEnviarAcendes