import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import DataTable from '../../../Components/datatable/DataTable';
import { useSelector } from 'react-redux';

const HistorialCitasProcesosModal = ({ isOpen, setIsOpen }) => {
   const {itemUnidadesRemate, dataCobradores} = useSelector((state)=> state.UnidadesEnRemateSlice)
   const [historial, setHistorial] = useState([]);
   const columns = [
      { field: 'folio_cita', headerName: 'Folio de cita', width: 150 },
      { field: 'ubicacion', headerName: 'Ubicación', width: 220 },
      { field: 'fecha_hora', headerName: 'Fecha y hora de cita', width: 250 },
      { field: 'usuario', headerName: 'Usuario', width: 200 },
      { field: 'estatus_cita', headerName: 'Estatus', width: 170 },
   ]

   useEffect(()=>{
      let newData = []
      if(itemUnidadesRemate?.remate !== null && itemUnidadesRemate?.remate?.citas !== null && itemUnidadesRemate?.remate?.citas.length > 0){
         itemUnidadesRemate?.remate?.citas?.map(cita=>{
            let cobrador = dataCobradores?.filter(cob=>{
               const match = cob?.id.toLowerCase() === cita?.usuarioAlta.toLowerCase()
               return(match)
            })
            newData.push({folio_cita: cita?.folio, ubicacion: cita?.ubicacion, fecha_hora: cita?.fechaHoraCita, usuario: cobrador[0]?.nombre, estatus_cita: cita?.estatus })
         })
         setHistorial(newData)
      }
   },[itemUnidadesRemate])

   return (
      <Modal isOpen={isOpen} color='#fff'>
         <section className='my-2 mx-4'>
            <span className='fs-5' style={{fontWeight:'bold', color:"#000"}}><strong>Historial de Citas</strong></span>
         </section>
         <section className='my-2 mx-4' >
            <div className='mx-4 table-responsive' style={{ width: '50rem', height: '25rem', overflowY: "scroll"}}>
               <DataTable
                  column={columns}
                  data={historial}
                  placeholderSelectField = {"Seleccionar"}
               />
            </div>
            <section className='my-4 d-flex justify-content-end'>
               <button id="AT_btnCancelar" className='btn col-2 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
            </section>
         </section>
      </Modal> 
   )
}

export default HistorialCitasProcesosModal