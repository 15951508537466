import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import CorreosService from '../../Services/Correos/Correos';
import { TextAreaField } from '../../Components/TextAreaField';

const EditarCorreos = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage }) => {
    
    let initial = {
        tipoCorreoId: item?.tipoCorreoId ?? "",
        prioridadId: item?.prioridadId ?? "",
        correos: item?.correos ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataP, setDataP] = useState([])

    useEffect(() => {
        handleGetPrioridad();
    }, [])

    const validate = Yup.object({
        correos: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-z0-9.]+@[a-z]+\.[a-z]{2,4}((,\s?){1}[a-z0-9.]+@[a-z]+\.[a-z]{2,4})*$",
                "ingrese correos separados por coma"
            ),
        prioridadId: Yup.string()
            .required("El campo es requerido."),

    });


    const handleAddEdit = async (value) => {
        await CorreosService.putTipoCorreo(value)
            .then(res => {
                setIsOpen(false);
                handleGetAll(1);
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
            }).catch(e => {
                setIsOpen(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

    }
    const handleGetPrioridad = async () => {
        await CorreosService.getallprioridad()
            .then(res => {
                let NewData = [];
                // NewData.push({
                //     value: null,
                //     label: "Seleccione"
                // })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.prioridadId,
                        label: item.prioridad
                    })
                });

                setDataP(NewData);
                // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "la hora se a actualizado con éxito." })
            }).catch(e => {
                // setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, submitForm, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5 mb-3'><strong>{item.tipoCorreo}</strong></span>
                                <span style={{ fontSize: '12px' }}>{item.descripcion}</span>
                            </div>
                            <Form>
                                <div className="mb-3 row my-4">
                                    <div className="mb-3 col-12">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                            <Field className="form-check-input" type="checkbox" name="status" />
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField className="col-6" label="Gravedad" name="prioridadId" items={dataP} onChange={(event) => {
                                            setFieldValue("prioridadId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextAreaField label="Para:" className="form-control col-12" rows="3" name="correos" type="text" holder="Escribe" />
                                        <span style={{fontSize:'12px'}}>Agregar destinatarios de correo separados por coma (,)</span>

                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' disabled={!(isValid)} type='submit'>Guardar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {/* <Modal isOpen={isOpenModalconf} color='#fff'>
                        <section className='m-4' style={{ width: '20rem' }}>
                            <div className='d-flex flex-column fs-6'>
                                <span className='fs-5 mb-2'><strong>Confirmar cambio</strong></span>
                                <span style={{ fontSize: '12px' }}>¿Seguro que deseas modificar el parámetro? Todos los cambios se registrarán en un historial.</span>
                            </div>
                            <section className='my-3 d-flex justify-content-betwen mt-4'>
                                <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpen(false); setIsOpenModalconf(false) }}>Cancelar</button>
                                <button className='btn mx-1 px-5' onClick={submitForm}>Aceptar</button>
                            </section>
                        </section>
                    </Modal> */}
                </>
            )
            }
        </Formik>
    )
}

export default EditarCorreos