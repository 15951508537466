import axios from '../../Utils/ApiService';

const Usuario = {
    getUsuario:async(page,rows,Rol,status,str)=> {
        return await axios.get(`/functions/GetAllUsuarios?page=${page}&rows=${rows}&IdRol=${Rol}&Status=${status}&Buscar=${str}`);
    }, 
    getRoles:()=>{
        return axios.get(`/functions/GetAllRoles`);
    },
    getRoleBydoc:(documentoId)=>{
        return axios.get(`/functions/GetCheckRolesConfigTD?TipoDocumentoId=${documentoId}`);
    },
    getUsuarioById : (id) =>{
        return axios.get(`/functions/GetUsuarioById?id=${id}`);
    },
    getSucursal:(page,rows)=>{
        return axios.get(`/functions/GetAllSucursal?page=${page}&rows=${rows}`);
    },
    getMmoduleByRol:(modul,rolId) =>{
        return axios.get(`/functions/GetAllSubModulos?IdModulo=${modul}`);
        // return axios.get(`/functions/GetAllSubModulos?IdRol=${rolId}&IdModulo=${modul}`);
    },
    getAllModules:()=>{
        return axios.get(`/functions/GetAllModulos`);
    },
    getSectionByMModule:(str,mod,rolId) =>{
        return axios.get(`/functions/GetAllSecciones?Buscar=${str}&IdSubModulo=${mod}&IdRol=${rolId}`);
    },
    getsectionByUser:(userId,moduloId,rolId)=>{
        return axios.get(`/functions/GetAllPermisosUsuario?UserId=${userId}&IdSubModulo=${moduloId}&IdRol=${rolId}`);
    },
    updateUser:(data) =>{
        return axios.put(`/functions/UsuarioUpd?Names=${data.nombreUsuario}&Email=${data.email}&SucursalId=${data.sucursalId}&Status=${data.status}&EmployeeNumber=${data.numeroEmpleado}&TablaId=${data.userId}&IdRol=${data.idRol}&Password=${data.password}`);        
    },
    AddUser:(data) =>{
        return axios.post(`/functions/UsuarioAddNew?Names=${data.nombreUsuario}&Email=${data.email}&SucursalId=${data.sucursalId}&Status=${data.status}&EmployeeNumber=${data.numeroEmpleado}&IdRol=${data.idRol}&Password=${data.password}&SocioCId=${data.socioId}`);
    },
    SavePermisosRoles:(data)=>{
        return axios.put(`/functions/SavePermisosRoles`,data);
    },
    userLogin:(data)=>{
        return axios.get(`/functions/LoginUser?Email=${data.username}&Password=${data.password}`);
    },
    userResetEmail:(data)=>{
        return axios.get(`/functions/ResetPasswordEmail?Email=${data.username}`);
    },
    userResetPassword:(data)=>{
        return axios.get(`/functions/ResetPassword?UserId=${data.UserId}&Password=${data.Password}`);
    },
    validateClave:(data)=>{
        return axios.get(`/functions/ValidateClave?ClaveLogin=${data.claveLogin}`);
    },
    userAllRoll:(data)=>{        
        return axios.get(`/functions/UserAllRoll?ClaveLogin=${data.claveLogin}&RolId=${data.RolId}&UserId=${data.UserId}`);
    }
}

export default Usuario