import axios from '../../Utils/ApiService';

const Citas = {
    getAgendaCitas: async(accion, ubicacionId, buscar, estatusCita, page = 1, rows=10) => {
        let uri = `/cartera/getagendacitas?accion=${accion}`;

        console.log(buscar)
        if(ubicacionId !== undefined && ubicacionId !== null && ubicacionId.trim() !== ""){
            uri += '&ubicacionId=' + ubicacionId;
        }

        if(buscar !== undefined && buscar.trim() !== ""){
            uri += '&buscar=' + buscar;
        }

        uri += `&estatuscita=${estatusCita}&page=${page}&rows=${rows}`
        return await axios.get(uri);
    },
    getAgendaCitasPorAutorizar: async(accion, ubicacionId, buscar, estatusCita, page = 1, rows=10) => {
        let uri = `/cartera/getcitasporautorizar?accion=${accion}`;

        console.log(ubicacionId)
        if(ubicacionId !== undefined && ubicacionId !== null && ubicacionId.trim() !== ""){
            uri += '&ubicacionId=' + ubicacionId;
        }

        if(buscar !== undefined && buscar.trim() !== ""){
            uri += '&buscar=' + buscar;
        }

        uri += `&estatuscita=${estatusCita}&page=${page}&rows=${rows}`
        return await axios.get(uri);
    },
}

export default Citas