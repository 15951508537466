import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list: [],
}

export const rolSlice = createSlice({
    name: 'rolSlice',
    initialState,
    reducers: {
        rolSession: (state, action) => {        
        return { ...state, list:action.payload.list}
      }   
    },
  })
  
  export const { rolSession } = rolSlice.actions
  
  export default rolSlice.reducer