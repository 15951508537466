import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import CorreosService from '../../Services/Correos/Correos';
import { TextAreaField } from '../../Components/TextAreaField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { TextField } from '../../Components/TextField';

const ModalReglaTipoReversion = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage }) => {  
    const refbtn = useRef();
    let initial = {
        reglasNegocioId: item?.reglasNegocioId ?? "",
        tipoReglaId:item?.tipoReglaId ?? "",
        etapaOrigenId: item?.etapaOrigenId ?? "",     
        productoId: item?.productoId ?? "",
        tipoCompraId: item?.tipoCompraId ?? "",   
        status: item?.status ?? true,
        etapasDestino:[]
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataEtapa, setDataEtapa] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [etapaActualId, setEtapaActualId] = useState("");

    useEffect(() => {
        if (item.action === "edit"){
            setEtapaActualId(item.etapaOrigenId);
        }
        handleGetAllEtapa();
    }, [])
    useEffect(() => {
        handleGetAllEtapasDEstino();
    }, [etapaActualId])
    

    const validate = Yup.object({      
        etapaOrigenId: Yup.string()
            .required("El campo es requerido.")       

    });

    const handleGetAllEtapasDEstino = async () =>{                 
        if(etapaActualId == ""){
            setDataTable([]);            
            return
        }        
        await EtapaService.getAllEtapaDestino(etapaActualId !== item.etapaOrigenId ? "" : item.reglasNegocioId,etapaActualId,item.productoId,item.tipoCompraId)
        .then(res => {            
            setDataTable(res.data.data);  
            setInitialValue({...initialValue,etapaOrigenId:etapaActualId,etapasDestino:dataTable});          
        }).catch(e => {            
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    }
    const handleGetAllEtapa = async () => {
        await EtapaService.getAllEtapaById(item.productoId,item.tipoCompraId)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaDestinoId,
                        label: item.nombre
                    })
                });
                setDataEtapa(NewData);                
                // setDataTable(res.data.data); 
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleChanagestatus = (value, item) => {        
        setDataTable((current) =>
            current?.map((data) =>
                data.etapaDestinoId === item.etapaDestinoId
                    ? {
                        ...data,
                        check: value
                    }
                    : data
            )
        );
    }
    const handleAddEdit = async (value) => {    
        refbtn.current.disabled = true;    
        if (item.action === "edit") {
            await EtapaService.UpdReversion(value)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            await EtapaService.AddReversion(value)
                .then(res => {                    
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })

        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit({...values,etapasDestino:dataTable});
                
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem',height:'auto' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fs-5 mb-3'><strong>{item.action === "edit" ? 'Editar Regla' : 'Nueva Regla'}</strong></span>
                                        <div className="mb-3">
                                            {/* <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label> */}
                                            <div className="col-ms-6 form-check form-switch">
                                                <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                                <Field className="form-check-input" type="checkbox" name="status" />
                                            </div>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: '12px' }}>A continuación se puede configurar la etapa destino.</span>
                                </div>
                                <div className="mb-3 row my-4">
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>1. Seleccionar la etapa correspondiente:</strong></label>
                                        <div className="col-sm-6 mb-2">
                                            <SelectField className="col-12" label="" name="etapaOrigenId" items={dataEtapa} onChange={(event) => {
                                                setEtapaActualId(event.value);
                                                setFieldValue("etapaOrigenId", event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>2. Seleccionar las etapas destino:</strong></label>
                                        <div className='tbl-scroll' style={{ overflowY: 'auto', height: '300px' }}>
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr className='custom-table'>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataTable.length > 0 ?
                                                            dataTable?.map((itemx, index) => {
                                                                return <tr key={index}>
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.check} onChange={(e) => { handleChanagestatus(e.target.checked, itemx) }} />
                                                                        </div>
                                                                    </td>
                                                                    <th scope="row">{itemx.nombre}</th>

                                                                </tr>
                                                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' ref={refbtn} type="submit" disabled={!(isValid)}>{item.action === "add" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalReglaTipoReversion
