import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { selectScheduleByTipoCalendario } from '../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useSelector } from 'react-redux';

const Test = ({}) => {
    const scheduleDataGruas = useSelector((state) => selectScheduleByTipoCalendario(state, 2));
    const scheduleDataTarjeta = useSelector((state) => selectScheduleByTipoCalendario(state, 13));
    const navigate = useNavigate();

    const sendCalendarioGruas = () => {
        navigate('/garantias/citas/v2/1', { state: { title: 'Gruas', tipoCalendario: 2, automatic: true, relacionId: '8A872676-EDDE-4106-99FF-05A0CB0A3F3A' } });
    }

    const sendCalendarioTarjeta = () => {
        navigate('/garantias/citas/v2/1', { state: { title: 'Entrega De Unidad' , tipoCalendario: 13 } });
    }

    return (
        <>

            <section>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-12 px-md-3 mt-2 mb-2">
                            <div className="row">
                                <div className="col-6">
                                    datos: <pre>{ JSON.stringify(scheduleDataGruas, null, 2) }</pre>
                                </div>
                                <div className="col-6">
                                    <button className='btn' onClick={() => {
                                        sendCalendarioGruas();
                                    }}>Ver calendario</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-md-3 mt-2 mb-2">
                            <div className="row">
                                <div className="col-6">
                                    datos: <pre>{ JSON.stringify(scheduleDataTarjeta, null, 2) }</pre>
                                </div>
                                <div className="col-6">
                                    <button className='btn' onClick={() => {
                                        sendCalendarioTarjeta();
                                    }}>Ver calendario</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Test