import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceEtapa = createSlice({
  name: 'etapaNavigate',
  initialState,
  reducers: {
    tabActiveEtapa: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveEtapa } = navigaSliceEtapa.actions

export default navigaSliceEtapa.reducer