import React, { useEffect, useState } from 'react'
import DataTable from '../../Components/datatable/DataTable'
import Loadig from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import SubmenuCat from '../../Components/SubmenuCat';
import useIntelimotor from '../../Hooks/Catalogos/useIntelimotor'
import Select from 'react-select'
import LoadingBar from '../../Components/LoadingBar';
import { pageRows } from '../../Utils/functions';
import { Section, SubModule } from '../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';

const Intelimotor = () => {
   const { columns,
      data,
      pageCount,
      loading,
      handlePageClick,
      handleDetail,
      handleClick,
      isOpenEdit,
      openModaEdit,
      closeModalEdit,
      message,
      loadingReq,
      modalMessage,
      setModalMessage,
      valueIntelimotor,
      handleUpdateParameters,
      handleChangeValueParam,
      newParamValue,
      valueIntelimotor1,
      loadingBar,
      setpageRows
   } = useIntelimotor();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Loadig message={""} /></Modal>
         <Modal isOpen={modalMessage.isOpen} color='#fff'>
            {
               modalMessage.type === 1 &&
               <ErrorModal
                  modalMessage={modalMessage}
                  setModalMessage={setModalMessage}
               />
            }
            {
               modalMessage.type === 2 &&
               <SuccessModal
                  modalMessage={modalMessage}
                  setModalMessage={setModalMessage}
               />
            }

         </Modal>
         <Modal isOpen={loadingReq} color='transparent'><LoadingBar message={message} /></Modal>
         <SubmenuCat />
         <div className='fs-5 mx-4 mt-5'>
            <strong>
               Sincronización automática
            </strong>
         </div>
         <div className='row mx-3 my-4'>
            <div className='col-lg-3 col-md-5 col-sm-12'>
               <div className="input-group mb-3">
                  <input type="time" className="form-control" value={valueIntelimotor} aria-describedby="button-addon2" readOnly />
                  <ValidatePermission isActive={ValidatePermissionById(4, Section.Intelimotor, SubModule.Catalogos).Actualizar} >
                     <button className="btn-addon" type="button" id="button-addon2" onClick={openModaEdit}><i className="bx bxs-pencil"></i></button>
                  </ValidatePermission>
               </div>
            </div>

            <ValidatePermission
               isActive={ValidatePermissionById(3, Section.SincronizarCatálogos)}
            >
               <div className='col-lg-3 col-md-5 col-sm-12'>
                  <button type="submit" className="btn w-100" onClick={handleClick}> Sincronizar ahora </button>
               </div>
            </ValidatePermission>

         </div>
         <ValidatePermission
            isActive={ValidatePermissionById(4, Section.Intelimotor, SubModule.Catalogos).Ver}
         >
            <div className='d-flex justify-content-end align-items-center me-4 mt-4'>
               <span style={{ fontSize: '12px' }}>Resultado por pagina</span>
               <div className='mx-1 col-lg-1 col-md-6 col-sm-12'>
                  <Select options={pageRows()} className='col-8' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} />
               </div>
            </div>
            <div className='mx-4 table-responsive'>
               <div className='title fs-3'>
                  Historial de sincronizaciones
               </div>
               <DataTable
                  column={columns}
                  data={data}
                  detailable={ValidatePermissionById(4, Section.Intelimotor, SubModule.Catalogos).Actualizar}
                  handleDetail={handleDetail}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
               // currentPage={currentPage-1}
               />
            </div>
         </ValidatePermission>

         <Modal isOpen={isOpenEdit} closeModal={closeModalEdit} color='#fff'>
            <form className='container-buttons'>
               <h4>Programar Sincronización</h4>
               <span className='' style={{ fontSize: '14px' }}>A continuación se puede modificar el horario en el que se realiza la sincronización automática todos los días.</span>
               <div className="mt-5">
                  <h6 className="form-label">En los siguientes horarios</h6>
                  <div className='col-12'>
                     <input className='form-control mb-4' label="Hora" name="hora" value={valueIntelimotor1} type="time" step="1" onChange={(e) => { handleChangeValueParam(e.target.value) }} />
                  </div>
                  <span style={{ fontSize: '12px' }}>Los cambios realizados en el horario afectarán a la sincronización de catálogos a Intelimotor.</span>
               </div>
               <div className='d-flex justify-content-around mt-5'>
                  <button className="btn btn-outline col-5" type="button" onClick={closeModalEdit}>Cancelar</button>
                  <button className="btn col-5" type="button" onClick={handleUpdateParameters}>Aceptar</button>
               </div>
            </form>
         </Modal>
      </>
   )
}

export default Intelimotor