import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';
import Garantias from '../../../../Services/Cartera/Garantias';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import documentsHelper from '../../../../Utils/documents';
import { getDateNow, message, FormatFecha } from '../../../../Utils/functions';
const EntregaUnidad = ({
    dataEstado,
    toast,
    state
}) => {
    // const { state } = useLocation();
    // const { id } = useParams();
    const { refFormik } = useRef();
    const navigate = useNavigate();
    const userId = sessionStorage.getItem('userId')

    let initial = {
        contrato: state.contrato,
        recibe_id: "",
        parentesco_id: "",
        fechaentrega: "",
        horaentrega: "",
        bloqueoid: false,
        unidad_entregada: 0
    }

    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);

    const [relacionSolicitante, setRelacionSolicitante] = useState([]);
    const [tipoPersona, setTipoPersona] = useState([]);

    useEffect(() => {
        HandleGetRelacionSolicitante()
        HandleTipoPersona()
        getAllData()
    }, [])

    const getAllData = async () => {
        setLoading(true)
        try {
            await handleGetCita()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleGetCita = async (id) => {
        await UnidadesRecuperadasService.getAllEntregaUnidad()
            .then(res => {

                let entrega = []
                res.data?.responseData?.map(item => {
                    if (item.contrato === state.contrato) {
                        entrega.push(item)
                    }
                });
                
                if (entrega[0]) {
                    setInitialValue((prevValues) => ({
                        ...prevValues,
                        id: entrega[0].id,
                        recibe_id: entrega[0].recibe_id,
                        parentesco_id: entrega[0].parentesco_id,
                        fechaentrega: getDateNow(entrega[0].fechaentrega),
                        horaentrega: entrega[0].horaentrega,
                        fechainicioproceso: entrega[0].fechainicioproceso,
                        fechafinproceso: entrega[0].fechafinproceso,
                        bloqueoid: entrega[0].unidad_entregada === 1 ? true : false
                    }));
                }

            })
    }

    const HandleGetRelacionSolicitante = async (id) => {
        setRelacionSolicitante([]);
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relacion = []
                res.data?.responseData?.map(item => {
                    relacion.push({
                        value: item.id,
                        label: item.nombre
                    });
                });
                setRelacionSolicitante(relacion);
            })
    }

    const HandleTipoPersona = async (id) => {
        setTipoPersona([])
        await UnidadesRecuperadasService.getAllTipoPersona().then(res => {
            let tipo = []
            res.data?.responseData?.map(item => {
                tipo.push({
                    value: item.id,
                    label: item.nombre
                });
            });
            setTipoPersona(tipo);
        })
    }

    const validationSchema = Yup.object({
        recibe_id: Yup.string().required('Requerido'),
        parentesco_id: Yup.string().required('Requerido'),
        fechaentrega: Yup.string().required('Requerido'),
        horaentrega: Yup.string().required('Requerido'),
        // concluido: Yup.boolean()
        //     .oneOf([true])
    })

    const downloadPDF = async (num) => {
        if (num === 1) {
            const requestBody = {
                nombrePlantilla: "ResepcionDeUnidad",
                parametros: {
                    // "<<VARIABLE_VIN>>": vin,
                    // "<<VARIABLE_CONTRATO>>": numContrato,
                    // "<<VARIABLE_FECHA>>": initialValue.fechaDiagnostico.split('T')[0],
                    // "<<VARIABLE_FOLIO>>": initialValue.folioDiagnostico,
                    // "<<VARIABLE_CLIENTE>>": cliente,
                    // "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                    // "<<VARIABLE_HORA_INICIO>>": initialValue.fechaInicio?.split('T')[1].split('.')[0],
                    // "<<VARIABLE_DATAMOVIL>>": "",
                    // "<<VARIABLE_DIRECCION_UBICACION>>": ""
                }
            };
            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

            }).catch(err => {
                console.error(err);
            });
        } else if (num === 2) {
            const requestBody = {
                nombrePlantilla: "RecepcionUnidad",
                parametros: {
                    // "<<VARIABLE_VIN>>": vin,
                    // "<<VARIABLE_CONTRATO>>": numContrato,
                    // "<<VARIABLE_FECHA>>": initialValue.fechaDiagnostico.split('T')[0],
                    // "<<VARIABLE_FOLIO>>": initialValue.folioDiagnostico,
                    // "<<VARIABLE_CLIENTE>>": cliente,
                    // "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                    // "<<VARIABLE_HORA_INICIO>>": initialValue.fechaInicio?.split('T')[1].split('.')[0],
                    // "<<VARIABLE_DATAMOVIL>>": "",
                    // "<<VARIABLE_DIRECCION_UBICACION>>": ""
                }
            };
            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

            }).catch(err => {
                console.error(err);
            });
        }
    }


    const submitForm = async (values) => {

        const fechaHoy = new Date()
        if (values.fechainicioproceso) {

        } else {
            values.fechainicioproceso = fechaHoy
        }
        if (values.concluido) {
            values.unidad_entregada = 1
            values.bloqueoid = values.concluido
            values.fechafinproceso = fechaHoy
        }
        const data = { anyObject: values }

        const notificacion = {
            anyObject: {
                usuarioid: userId, //El usuario que inicio sesión
                modulo: "pago_unidad", //pantalla origen
                tipocorreo: "Autorización de Cita Entrega de Unidad",
                data: JSON.stringify(values), //Objeto JSON que se va a usar en la notificacion 
                enviado: 0 //Cuando se crea va en 0
            }
        }

        try {
            setLoading(true)
            await UnidadesRecuperadasService.upsertEntregaUnidad(data)
            await UnidadesRecuperadasService.createNotificacionesCobranza(notificacion)
            setLoading(false)
            toast.success(message("¡Operación exitosa!"));

        } catch (error) {
            setLoading(false)
            toast.error(message("¡Error!"));
        }
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div>
                                <div className="row mb-2">
                                    <div className='col-4'>
                                        <h6><strong>Generar documento</strong></h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    className='btn btn-gray'
                                                    onClick={() => {
                                                        navigate(`/garantias/datadocs/fRecupera/eEntrega/${state.contrato}/${state.id}`, {
                                                            state: values,
                                                        });
                                                    }}>
                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.concluido ?
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                        : <button
                                                            className='btn'
                                                            disabled={!(isValid)}
                                                            type='submit'>
                                                            Guardar
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <h6><strong>Detalle de entrega</strong></h6>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-auto">
                                        <small>Checklist de entrega</small>
                                    </div>
                                    <div className="col-3">
                                        <button className='btn btn-gray' onClick={() => downloadPDF(1)}>
                                            <i className="icon-blue bx bx-download"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-auto">
                                        <small>Carta de recepción de unidad</small>
                                    </div>
                                    <div className="col-3">
                                        <button className='btn btn-gray' onClick={() => downloadPDF(2)}>
                                            <i className="icon-blue bx bx-download"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <SelectField
                                            id="recibe_id"
                                            label="¿Quién recibe?"
                                            name="recibe_id"
                                            items={relacionSolicitante}
                                            disabled={initialValue.bloqueoid}
                                            onChange={(event) => {
                                                setFieldValue("recibe_id", event.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <SelectField
                                            id="parentesco_id"
                                            label="Parentesco(en caso de aplicar)"
                                            name="parentesco_id"
                                            items={tipoPersona}
                                            disabled={initialValue.bloqueoid}
                                            onChange={(event) => {
                                                setFieldValue("parentesco_id", event.value);
                                            }}
                                        />
                                    </div>

                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <TextField label="Otro(en caso de aplicar)" name="fechacita" type="text" holder="Escribe" onChange={(event) => {
                                            setFieldValue("fechacita", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                    </div>
                                </div>
                                <div className='d-flex row'>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <TextField label="Fecha de entrega" name="fechaentrega" type="date" holder="Escribe" onChange={(event) => {
                                            setFieldValue("fechaentrega", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12 px-4'>
                                        <TextField label="Hora de entrega" name="horaentrega" type="time" holder="Escribe" onChange={(event) => {
                                            setFieldValue("horaentrega", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                    </div>
                                </div>
                                <div className="row mt-2 p-4">
                                    <div className="col-12 col-lg-6">
                                        <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                            setFieldValue("concluido", event.target.value);
                                        }} disabled={initialValue.unidad_entregada === 1} />
                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad entregada</label>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                {
                                                    values.fechainicioproceso &&
                                                    <small className='text-body-tertiary'>Fecha Inicio: {FormatFecha(values.fechainicioproceso)}</small>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.concluido &&
                                                    <small className='text-body-tertiary'>Fecha Fin: {FormatFecha(values.fechafinproceso)}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )
                }
            </Formik >
        </>
    )

}
export default EntregaUnidad