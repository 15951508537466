import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const AcordeonSA = ({ data,isSelected=false,heigth,dataSelect, handleEtapa}) => {
    
    const [dataEtapa, setDataEtapa] = useState([]);
    const [valueSelect, setValueSelect] = useState(null)
    useEffect(() => {           
        setDataEtapa(data);
    }, [data])
    
    const [open, setOpen] = useState(true);   
    return (
        <>
            <article className='group-containers p-3 my-3'>
                <div className='d-flex justify-content-between header-acordeon'>
                    <div>
                        <button className='btn-etapa' onClick={() => { setOpen(current => !current) }}><i className={`ri-arrow-drop-${open ? 'up' : 'down'}-line`}></i></button>
                        {/* <i style={{ color: '#004c97' }} className="mx-2 bi bi-menu-button"></i> */}
                        <span className='mx-1'><strong>Etapas sin Asignar</strong></span>
                    </div>
                    <span className='count_etapas'>{data?.length > 1 ? `${data?.length} etapas sin asignar`: `${data?.length} etapa sin asignada`} </span>
                </div>
                <div  style={setOpen ? {height:`${heigth}px`,overflowX:'auto'}: {}}>
                {
                    open &&
                    dataEtapa?.map((item, index) => {                        
                       return <div key={index} className='mt-3 d-flex justify-content-between align-items-center items-etapa content'>
                            <span className='mx-4'>{item.etapa}</span>
                            {isSelected && <Select options={dataSelect} className='col-5 mx-1' value={valueSelect} placeholder="Asignar a grupo" onChange={(e)=>{handleEtapa(e.value,item);setValueSelect(e.value)}} />}                            
                        </div>
                    })
                }  
                </div>                            
            </article>
        </>
    )
}

export default AcordeonSA