import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import DataTable from '../../../Components/datatable/DataTable';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import CambioDevoluciones from '../../../Services/CambioDevoluciones/CambioDevoluciones';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import Cierres from '../../../Services/Resguardos/Cierres';
import ConfirmarCalculadoraModal from '../../../Components/Modal/ConfirmarCalculadoraModal';

const CardCalculo
    = React.forwardRef(({ ...props }, ref) => {
        const { vin, numContrato, generalId, garantia } = useSelector((state) => state.DataVinSlice);
        const { cambioDevolucionId, origen, origenId } = useSelector((state) => state.DevolucionesSlice);
        const dispatch = useDispatch();
        const { refFormik } = useRef();
        const navigate = useNavigate();
        
        let initial = {
            desembolsos: [],
            pagos: [],
            seguros: [],
            gestorias: [],
            adeudos: [],
            comentarios: "",
            concluido: false,
            cambioDevolucionId
        }
        
        const [initialValue, setInitialValue] = useState(initial);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const [collpsableDesembolso, setCollpsableDesembolso] = useState(false);
        const [collpsablePagos, setCollpsablePagos] = useState(false);
        const [collpsableSeguro, setCollpsableSeguro] = useState(false);
        const [collpsableGestoria, setCollpsableGestoria] = useState(false);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [data, setData] = useState([]);
        
        
        /* CALCULOS */
        const [total, setTotal] = useState({total : 0, descuento: 0, desembolso: 0});
        /* TERMINA CALCULOS */

        /* DATOS */
        const [desembolsoList, setDesembolsoList] = useState([]);
        const [pagosList, setPagosList] = useState([]);
        const [seguroList, setSeguroList] = useState([]);
        const [gestoriaList, setGestoriaList] = useState([]);
        const [adeudosList, setAdeudosList] = useState([]);

        const [desembolsoListTotal, setDesembolsoListTotal] = useState({desembolso: 0, descuento: 0, devolucion: 0});
        const [pagosListTotal, setPagosListTotal] = useState({desembolso: 0, monto: 0, devolucion: 0});
        const [seguroListTotal, setSeguroListTotal] = useState({desembolso: 0, devolucion: 0});
        const [gestoriaListTotal, setGestoriaListTotal] = useState({desembolso: 0, descuento: 0, devolucion: 0});
        /* TERMINA DATOS */

        /* HASH TABLES */
        const conceptosDesembolso = {
            "abonoCapital": "Abono Capital",
            "gastosAdministrativos": "Gastos Administrativos",
            "gastosInvestigacion": "Gastos de Investigación",
            "gastosNotariales": "Gastos Notariales",
            "instalacionDatamovil": "Instalación Datamovil",
            "revisionDocumentos": "Revisión Documentos",
            "seguro": "Seguro",
            "gastos_investigacion": "Gastos de Investigación",
            "desembolso_inicial": "Desembolso Inicial",
        }
        /*  TERMINA HASH TABLES */
        
        
        const opcionesBool = [{ value: 1, label: 'Monto fijo' }, { value: 0, label: 'Monto porcentual' }];
        const opcionesBoolTrue = [{ value: 1, label: 'Permanecer activa' }, { value: 0, label: 'Cancelar' }];
        const [tipoAdeudos, setTipoAdeudos] = useState([]);
        const [showAdeudos, setShowAdeudos] = useState(false);

        /* ZONA DE USEEFFECT */
        useEffect(() => {
            getCalculosFromDB();
            getAdeudos();
            getTipoAdeudos();
        },[]);
        
        useEffect(() => {
            console.log(adeudosList)
            setInitialValue({
                ...initialValue,
                desembolsos: desembolsoList,
                pagos: pagosList,
                seguros: seguroList,
                gestorias: gestoriaList,
                adeudos: adeudosList
            });
            
            calculatePagos();
            calculaTotales();
        }, [desembolsoList, pagosList, seguroList, gestoriaList, adeudosList]);
        /* TERMINA ZONA DE USEEFFECT */

        /* REQUEST FUNCTIONS */
        const getCalculosFromDB = async () => {
            setLoading(true);
            await Garantias.calculadoraGet(cambioDevolucionId)
            .then(resp => {
                const pagosRealizados = resp.data.detalles;
                
                setInitialValue({
                    ...initialValue,
                    detallePoliza: resp.data.detallePoliza === null ? null : (resp.data.detallePoliza ? 1 : 0),
                    comentarios: resp.data.comentarios,
                    concluido: resp.data.concluido,
                });
                creaDatosCalculados(pagosRealizados, resp.data);    
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const getAdeudos = async () => {
            await CambioDevoluciones.cambioDevolucionAdeudosListaGet(cambioDevolucionId)
            .then(resp => {
                if(resp.status === 200){
                    let items = [];
                    let montoAdeudoTotal = 0;
                    let adeudoIvaTotal = 0;
                    let adeudoSubtotalTotal = 0;
                    resp.data.forEach(item => {
                        let total = 0;
                        if(item.iva !== null && !isNaN(item.iva)){
                            adeudoIvaTotal += parseFloat(item.iva);
                            total += parseFloat(item.iva);
                        }
                        if(item.subtotal !== null && !isNaN(item.subtotal)){
                            adeudoSubtotalTotal += parseFloat(item.subtotal);
                            total += parseFloat(item.subtotal);
                        }

                        montoAdeudoTotal += parseFloat(total);

                        items.push({
                            cambioDevolucionAdeudosId: item.cambioDevolucionAdeudosId,
                            noOrden: item.folioId,
                            adeudo: item.adeudoTipoId !== null ? item.adeudoTipoId : "",
                            monto: total,
                            iva: item.iva !== null ? item.iva : "",
                            subtotal: item.subtotal !== null ? item.subtotal : "",
                            fechaRegistro: getOnlyDateFormat(item.fechaAlta, ""),
                        })
                    });
                    setAdeudosList(items);
                    setMontoAdeudo(montoAdeudoTotal);
                    setAdeudoIva(adeudoIvaTotal);
                    setAdeudoSubtotal(adeudoSubtotalTotal);
                    setShowAdeudos(true);
                    setInitialValue({
                        ...initialValue,
                        adeudos: items
                    });
                }
            })
            .catch(err => {
                console.error(err);
            });
        }

        const getInfoFromAcendes = async () => {
            setLoading(true);
            const params = {
                solId: '123',
                contrato: numContrato,
                vin,
                cambioDevolucionId
            }
            await Garantias.pagosRealizadosGet(params)
            .then(resp => {
                const pagosRealizados = resp.data;
                setInitialValue({
                    ...initialValue,
                })
                creaDatosCalculados(pagosRealizados, resp.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const getTipoAdeudos = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.CierreContratoAdeudoTipo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.CierreContratoAdeudoTipoId,
                        label: item.CierreContratoAdeudoTipo
                    });
                });
                setTipoAdeudos(items);
            })
            .catch(err => {
                console.error(err);
            });
        }
        /* TERMINA REQUEST */

        /* ADEUDOS */
        const addAdeudo = async () => {
            let params = {
                cambioDevolucionId: cambioDevolucionId,
                status: true,
            }

            await CambioDevoluciones.cambioDevolucionAdeudosCrear(params)
            .then(resp => {
                let items = [];
                let montoAdeudoTotal = 0;
                let adeudoIvaTotal = 0;
                let adeudoSubtotalTotal = 0;

                resp.data.forEach(item => {

                    if(item.monto !== null && !isNaN(item.monto)){
                        montoAdeudoTotal += parseFloat(item.monto);
                    }
                    if(item.iva !== null && !isNaN(item.iva)){
                        adeudoIvaTotal += parseFloat(item.iva);
                    }
                    if(item.subtotal !== null && !isNaN(item.subtotal)){
                        adeudoSubtotalTotal += parseFloat(item.subtotal);
                    }
                    
                    items.push({
                        cambioDevolucionAdeudosId: item.cambioDevolucionAdeudosId,
                        noOrden: item.folioId,
                        adeudo: item.adeudoTipoId !== null ? item.adeudoTipoId : "",
                        monto: item.monto !== null ? item.monto : "",
                        iva: item.iva !== null ? item.iva : "",
                        subtotal: item.subtotal !== null ? item.subtotal : "",
                        fechaRegistro: getOnlyDateFormat(item.fechaAlta, ""),
                    })

                });
                setAdeudosList(items);
                setMontoAdeudo(montoAdeudoTotal);
                setAdeudoIva(adeudoIvaTotal);
                setAdeudoSubtotal(adeudoSubtotalTotal);
            })
            .catch(err => {
                console.error(err);
            });
        }

        const deleteAdeudo = async (id) => {
            setLoading(true);
            let params = {
                cambioDevolucionAdeudosId: id,
                status: false
            };
            await CambioDevoluciones.cambioDevolucionAdeudosActualizar(params)
            .then(resp => {
                getAdeudos();
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const updateAdeudo = async (id, row) => {
            setLoading(true);
            let params = {
                cambioDevolucionAdeudosId: row.cambioDevolucionAdeudosId,
                adeudoTipoId: row.adeudo,
                monto: row.monto,
                iva: row.iva,
                subtotal: row.subtotal,
                status: true,
            }
            await CambioDevoluciones.cambioDevolucionAdeudosActualizar(params)
            .then(resp => {
                toast.success(message(`Se actualizó el adeudo con no. de orden ${row.noOrden} correctamente`));
                getAdeudos();
            })
            .catch(err => {
                toast.error(message(`¡Error, al intentar actualizar el adeudo con no. de orden ${row.noOrden}!`));
                console.error(err);
            })
            .finally(() => setLoading(false));
        }

        const [montoAdeudo, setMontoAdeudo] = useState(0);
        const [adeduoIva, setAdeudoIva] = useState(0);
        const [adeudoSubtotal, setAdeudoSubtotal] = useState(0);

        const calculateAdeudos = (seccion, values) => {
            let montoAdeudoTotal = 0;
            let adeudoIvaTotal = 0;
            let adeudoSubtotalTotal = 0;
            values.forEach(item => {
                if(seccion === 'monto' && item[seccion] !== "" && !isNaN(item[seccion])){
                    montoAdeudoTotal += parseFloat(item[seccion]);
                }
                if(seccion === 'iva' && item[seccion] !== "" && !isNaN(item[seccion])){
                    adeudoIvaTotal += parseFloat(item[seccion]);
                }
                if(seccion === 'subtotal' && item[seccion] !== "" && !isNaN(item[seccion])){
                    adeudoSubtotalTotal += parseFloat(item[seccion]);
                }
            })

            if(seccion === 'monto'){
                setMontoAdeudo(montoAdeudoTotal);
            }
            if(seccion === 'iva'){
                setAdeudoIva(adeudoIvaTotal);
            }
            if(seccion === 'subtotal'){
                setAdeudoSubtotal(adeudoSubtotalTotal);
            }
        }

        // const calculateMonto = (row) => {
        //     console.log(row);
        //     let montoCalculado = 0;
        //     if(!isNaN(row.subtotal) && !isNaN(row.iva)){
        //         montoCalculado = parseFloat(row.subtotal) + parseFloat(row.iva);
        //     }
        //     row.monto = montoCalculado;            
        //     setAdeudosList({
        //         ...adeudosList,
        //         row
        //     });
        // }
        const calculateMonto = (values, idx, value, data) => {
            let subtotal = parseFloat(data[idx].subtotal);
            let iva = parseFloat(data[idx].iva);
            
            initialValue[values][idx].subtotal = !isNaN(subtotal) ? subtotal : "";
            initialValue[values][idx].iva = !isNaN(iva) ? iva : "";
            if(!isNaN(subtotal) && !isNaN(iva)){
                initialValue[values][idx].monto = subtotal+iva;
            }
            console.log(initialValue[values][idx]);
            let dataTmp = initialValue[values][idx];
            setInitialValue({
                ...initialValue,
                values: {
                    ...values,
                    dataTmp
                }
            })
            setAdeudosList(initialValue[values]);
            calculateAdeudos('subtotal', initialValue[values])
            calculateAdeudos('iva', initialValue[values])
            calculateAdeudos('monto', initialValue[values])
        }
        /* TERMINA ADEUDOS */

        const containerStyleAdeudoSmall = {
            width: '150px',
            minWidth: '150px',
            maxWidth: '300px',
            height: '40px',
            lineHeight: '40px',
        };
        const containerStyleAdeudoActions = {
            width: '110px',
            minWidth: '110px',
            maxWidth: '300px',
            height: '40px',
            lineHeight: '40px',
        };
        const containerStyleAdeudo = {
            width: '180px',
            minWidth: '180px',
            maxWidth: '300px',
            height: '40px',
            lineHeight: '40px',
        };

        const containerStyleAdeudoFields = {
            width: '180px',
            minWidth: '180px',
            maxWidth: '300px',
            height: '20px',
            lineHeight: '20px',
        };
        const containerStyleAdeudoLabels = {
            width: '150px',
            minWidth: '150px',
            maxWidth: '300px',
            height: '50px',
            lineHeight: '50px',
        };

        /* STATE CALCULOS INICIALES */
        const creaDatosCalculados = (data, info) => {
            const pagosRealizados = data;
            
            let desembolsoTmp = pagosRealizados.filter(item => item.seccion === 1);
            let pagosTmp = pagosRealizados.filter(item => item.seccion === 2);
            let seguroTmp = pagosRealizados.filter(item => item.seccion === 3);
            let gestoriaTmp = pagosRealizados.filter(item => item.seccion === 4);


            let totalDesembolso = 0;
            let totalDevolucionDesembolso = 0; 
            let totalPagos = 0;
            let totalDevolucionPagos = 0;
            let totalSeguro = 0;
            let totalDevolucionSeguro = 0;
            let totalGestoria = 0;
            let totalDevolucionGestoria = 0;

            let preDesembolso = [];
            desembolsoTmp.forEach((item, idx) => {
                let montoDescuento = 0;
                if(!item.esPorcentaje){
                    montoDescuento = item.desembolso * (item.descuento/100);
                }else{
                    montoDescuento = item.descuento;
                }

                preDesembolso[idx] = { 
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    concepto: item.concepto,
                    desembolso: item.desembolso,
                    descuento: item.esPorcentaje === null ? null : (item.esPorcentaje ? 1 : 0),
                    montoDescuento,
                    monto: item.descuento !== null ? item.descuento : '',
                    devolucion: item.devolucion !== null ? item.devolucion : item.desembolso
                };
                totalDesembolso += item.desembolso;
                totalDevolucionDesembolso += item.devolucion !== null ? item.devolucion : item.desembolso;
            });
            setDesembolsoListTotal({desembolso: totalDesembolso, descuento: 0, devolucion: totalDevolucionDesembolso})
            setDesembolsoList(preDesembolso);
            
            let prePagos = [];
            pagosTmp.forEach((item, idx) => {
                let montoDescuento = 0;
                if(!item.esPorcentaje){
                    montoDescuento = item.desembolso * (item.descuento/100);
                }else{
                    montoDescuento = item.descuento;
                }

                prePagos[idx] = {
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    concepto: item.concepto,
                    pagos: item.pagos,
                    desembolso: item.desembolso,
                    descuento: item.esPorcentaje === null ? null : (item.esPorcentaje ? 1 : 0),
                    montoDescuento,
                    monto: item.descuento !== null ? item.descuento : '',
                    devolucion: item.devolucion !== null ? item.devolucion : item.desembolso
                };
                totalPagos += item.desembolso;
                totalDevolucionPagos += item.devolucion !== null ? item.devolucion : item.desembolso;
            });
            setPagosListTotal({desembolso: totalPagos, monto: 0, devolucion: totalDevolucionPagos});
            setPagosList(prePagos);

            let preSeguro = [];
            seguroTmp.forEach((item,idx) => {
                let montoDescuento = 0;
                if(!item.esPorcentaje){
                    montoDescuento = item.desembolso * (item.descuento/100);
                }else{
                    montoDescuento = item.descuento;
                }

                preSeguro[idx] = {
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    poliza: item.poliza,
                    emision: item.emisionPoliza,
                    desembolso: item.desembolso,
                    descuento: item.esPorcentaje === null ? null : (item.esPorcentaje ? 1 : 0),
                    montoDescuento,
                    monto: item.descuento !== null ? item.descuento : '',
                    devolucion: item.devolucion !== null ? item.devolucion : item.desembolso
                }
                totalSeguro += item.desembolso;
                totalDevolucionSeguro += item.devolucion !== null ? item.devolucion : item.desembolso;
            });
            setSeguroListTotal({desembolso: totalSeguro, devolucion: totalDevolucionSeguro});
            setSeguroList(preSeguro);

            let preGestoria = [];
            gestoriaTmp.forEach((item, idx) => {
                let montoDescuento = 0;
                if(!item.esPorcentaje){
                    montoDescuento = item.desembolso * (item.descuento/100);
                }else{
                    montoDescuento = item.descuento;
                }

                preGestoria[idx] = {
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    gestoria: item.gestoriaPagada,
                    concepto: conceptosDesembolso[item.concepto],
                    desembolso: item.desembolso,
                    descuento: item.esPorcentaje === null ? null : (item.esPorcentaje ? 1 : 0),
                    montoDescuento,
                    monto: item.descuento !== null ? item.descuento : '',
                    devolucion: item.devolucion !== null ? item.devolucion : item.desembolso
                };
                totalGestoria += item.desembolso;
                totalDevolucionGestoria += item.devolucion !== null ? item.devolucion : item.desembolso;
            });
            setGestoriaListTotal({desembolso: totalGestoria, descuento: 0, devolucion: totalDevolucionGestoria});
            setGestoriaList(preGestoria);

            setInitialValue({
                ...initialValue,
                comentarios: info.comentarios || "",
                concluido: info.concluido || false,
                desembolsos: preDesembolso,
                pagos: prePagos,
                seguros: preSeguro,
                gestorias: preGestoria
            });
            calculaTotales();
        }

        const calculaTotales = () => {
            let desembolso = 0;
            let descuento = 0;
            let devolucion = 0;

            initialValue.desembolsos.forEach(item => {
                desembolso += item.desembolso;
                let amount = item.montoDescuento;

                if(item.descuento !== null){
                    descuento += amount;
                }
            });
            initialValue.pagos.forEach(item => {
                desembolso += item.desembolso;
                let amount = item.montoDescuento;

                if(item.descuento !== null){
                    descuento += amount;
                }
            });
            initialValue.seguros.forEach(item => {
                desembolso += item.desembolso;
                let amount = item.montoDescuento;

                if(item.descuento !== null){
                    descuento += amount;
                }
            });
            initialValue.gestorias.forEach(item => {
                desembolso += item.desembolso;
                let amount = item.montoDescuento;

                if(item.descuento !== null){
                    descuento += amount;
                }
            });

            devolucion = desembolso - descuento;
            setTotal({total: desembolso, descuento, devolucion})
        }
        /* TERMINA STATE CALCULOS INICIALES */

        /* CALCULOS */
        const calculatePagos = () => {
            let conceptos = [];
            let counters = [];
            pagosList.forEach(item => {
                Object.keys(item).forEach(key => {
                    if(!conceptos.includes(key) && (key !== 'desc' && key !== 'fecha')){
                        conceptos.push(key);
                        counters[key] = 1;
                    }else if(key !== 'desc' && key !== 'fecha'){
                        let count = counters[key] + 1;
                        counters[key] = count;
                    }
                });
            });
        }

        useEffect(() => {
            setInitialValue({
                ...initialValue,
                desembolsos: desembolsoList,
            });
        }, [desembolsoListTotal]);

        const calculateDesembolsoDevolucion = (values, idx, value) => {
            console.log("Entrando")
            initialValue[values][idx].monto = value;
            if(initialValue[values][idx].descuento === 1){
                if(value.trim().length > 0 && value <= initialValue[values][idx].desembolso){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - parseFloat(value);
                    initialValue[values][idx].montoDescuento = parseFloat(value);
                }else{
                    toast.error(message(`El monto debe ser menor a ${initialValue[values][idx].desembolso}`));
                    return false;
                }
            }else{
                if(value.trim().length > 0 && value <= 100){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - (initialValue[values][idx].desembolso * (value/100));
                    initialValue[values][idx].montoDescuento = (initialValue[values][idx].desembolso * (value/100));
                }else{
                    toast.error(message("El porcentaje debe ser menor a 100%!"));
                    return false;
                }
            }

            let dataTmp = initialValue[values][idx];
            let data = initialValue[values];


            setTimeout(() => {
                setInitialValue({
                    ...initialValue,
                    values: {
                        ...data,
                        dataTmp
                    }
                });
            }, 500);
            
            let total = 0;
            let totalDevolucion = 0;
            initialValue.desembolsos.forEach(item => {
                total += item.desembolso;
                totalDevolucion += item.devolucion;
            });

            
            setDesembolsoListTotal({
                desembolso: total,
                devolucion: totalDevolucion
            })
            calculatePagos();
            calculaTotales();
        }

        const calculatePagosDevolucion = (values, idx, value) => {
            initialValue[values][idx].monto = value;
            if(initialValue[values][idx].descuento === 1){
                if(value.trim().length > 0 && value <= initialValue[values][idx].desembolso){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - parseFloat(value);
                    initialValue[values][idx].montoDescuento = parseFloat(value);
                }else{
                    toast.error(message(`El monto debe ser menor a ${initialValue[values][idx].desembolso}`));
                    return false;
                }
            }else{
                if(value.trim().length > 0 && value <= 100){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - (initialValue[values][idx].desembolso * (value/100));
                    initialValue[values][idx].montoDescuento = (initialValue[values][idx].desembolso * (value/100));
                }else{
                    toast.error(message("El porcentaje debe ser menor a 100%!"));
                    return false;
                }
            }

            let dataTmp = initialValue[values][idx];
            setInitialValue({
                ...initialValue,
                values: {
                    ...values,
                    dataTmp
                }
            })
            
            let total = 0;
            let totalDevolucion = 0;
            initialValue.pagos.forEach(item => {
                total += item.desembolso;
                totalDevolucion += item.devolucion;
            });

            setPagosListTotal({
                desembolso: total,
                devolucion: totalDevolucion
            })
            calculatePagos();
            calculaTotales();
        }

        const calculateSegurosDevolucion = (values, idx, value) => {
            initialValue[values][idx].monto = value;
            if(initialValue[values][idx].descuento === 1){
                if(value.trim().length > 0 && value <= initialValue[values][idx].desembolso){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - parseFloat(value);
                    initialValue[values][idx].montoDescuento = parseFloat(value);
                }else{
                    toast.error(message(`El monto debe ser menor a ${initialValue[values][idx].desembolso}`));
                    return false;
                }
            }else{
                if(value.trim().length > 0 && value <= 100){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - (initialValue[values][idx].desembolso * (value/100));
                    initialValue[values][idx].montoDescuento = (initialValue[values][idx].desembolso * (value/100));
                }else{
                    toast.error(message("El porcentaje debe ser menor a 100%!"));
                    return false;
                }
            }
            
            let dataTmp = initialValue[values][idx];
            setInitialValue({
                ...initialValue,
                values: {
                    ...values,
                    dataTmp
                }
            })
            
            let total = 0;
            let totalDevolucion = 0;
            initialValue.seguros.forEach(item => {
                total += item.desembolso;
                totalDevolucion += item.devolucion;
            });

            // console.log("Opera", initialValue.seguros)
            setSeguroListTotal({
                desembolso: total,
                devolucion: totalDevolucion
            })
            calculatePagos();
            calculaTotales();
        }

        const calculateGestoriasDevolucion = (values, idx, value) => {
            initialValue[values][idx].monto = value;
            if(initialValue[values][idx].descuento === 1){
                if(value.trim().length > 0 && value <= initialValue[values][idx].desembolso){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - parseFloat(value);
                    initialValue[values][idx].montoDescuento = parseFloat(value);
                }else{
                    toast.error(message(`El monto debe ser menor a ${initialValue[values][idx].desembolso}`));
                    return false;
                }
            }else{
                if(value.trim().length > 0 && value <= 100){
                    initialValue[values][idx].devolucion = initialValue[values][idx].desembolso - (initialValue[values][idx].desembolso * (value/100));
                    initialValue[values][idx].montoDescuento = (initialValue[values][idx].desembolso * (value/100));
                }else{
                    toast.error(message("El porcentaje debe ser menor a 100%!"));
                    return false;
                }
            }
            
            let dataTmp = initialValue[values][idx];
            setInitialValue({
                ...initialValue,
                values: {
                    ...values,
                    dataTmp
                }
            })
            
            let total = 0;
            let totalDevolucion = 0;
            initialValue.gestorias.forEach(item => {
                total += item.desembolso;
                totalDevolucion += item.devolucion;
            });

            setGestoriaListTotal({
                desembolso: total,
                devolucion: totalDevolucion
            })
            calculatePagos();
            calculaTotales();
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        const getOnlyDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear();
                return msj + format;
            }
        }

        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const setDisabled = (seccion, object, id) => {

            if(id !== 0 && id !== 1){
                object.descuento = null;
            }else{
                object.descuento = id;
            }
            object.monto = "";
            
            // console.log(seccion);
            setInitialValue({
                ...initialValue,
                seccion: {
                    ...seccion,
                    object
                }
            });
        }

        const setDisabledAdeudos = (seccion, object, id) => {
            if(id === null){
                object.adeudo = null;
            }else{
                object.adeudo = id;
            }

            setInitialValue({
                ...initialValue,
                seccion: {
                    ...seccion,
                    object
                }
            });
        }
        

        const prevSubmitForm = async (values) => {
            if(values.concluido){
                setModalMessage({ isOpen: true, title: 'Confirmar cierre de calculadora', type: 3, data: values })
                return false;
            }else{
                submitForm(values);
            }
            
        }


        const submitForm = async (values) => {
            let detalles = [];
            let msg = [];
            let flag = true;
            values.desembolsos.forEach(item => {
                detalles.push({
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    seccion: 1,
                    concepto: item.concepto,
                    descuento: item.monto,
                    monto: 0,
                    esPorcentaje: item.descuento, // descuento es el boolean de si es porcentaje o no
                    desembolso: item.desembolso,
                    devolucion: item.devolucion
                })

                if(item.descuento === null){
                    msg.push(`El tipo de descuento de ${item.concepto} no puede ir nulo, por favor selecciona un tipo de descuento`);
                    flag = false;
                }else if(item.descuento === 1 && item.desembolso < item.monto){
                    msg.push(`El monto de ${item.concepto} en la sección de desembolso inicial debe ser menor a ${item.desembolso}`);
                    flag = false;
                }else if(item.descuento === 0 && Number(item.monto) >= 100){
                    msg.push(`El porcentaje de ${item.concepto} en la sección de desembolso inicial debe ser menor a 100`);
                    flag = false;
                }
            });

            values.pagos.forEach(item => {
                detalles.push({
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    seccion: 2,
                    concepto: item.concepto,
                    descuento: item.monto,
                    pagos: item.pagos,
                    esPorcentaje: item.descuento, // descuento es el boolean de si es porcentaje o no
                    desembolso: item.desembolso,
                    devolucion: item.devolucion

                });

                
                if(item.descuento === null){
                    msg.push(`El tipo de descuento de ${item.concepto} no puede ir nulo, por favor selecciona un tipo de descuento`);
                    flag = false;
                }else if(item.descuento === 1 && item.desembolso < item.monto){
                    msg.push(`El monto de ${item.concepto} en la sección de pagos debe ser menor a ${item.desembolso}`);
                    flag = false;
                }else if(item.descuento === 0 && Number(item.monto) >= 100){
                    msg.push(`El porcentaje de ${item.concepto} en la sección de pagos debe ser menor a 100`);
                    flag = false;
                }
            });

            values.seguros.forEach(item => {
                detalles.push({
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    seccion: 3,
                    concepto: item.concepto,
                    descuento: item.monto,
                    esPorcentaje: item.descuento, // descuento es el boolean de si es porcentaje o no
                    desembolso: item.desembolso,
                    devolucion: item.devolucion,
                    poliza: item.poliza,
                    emisionPoliza: item.emision
                });

                
                if(item.descuento === null){
                    msg.push(`El tipo de descuento de ${item.concepto} no puede ir nulo, por favor selecciona un tipo de descuento`);
                    flag = false;
                }else if(item.descuento === 1 && item.desembolso < item.monto){
                    msg.push(`El monto de ${item.concepto} en la sección de seguros debe ser menor a ${item.desembolso}`);
                    flag = false;
                }else if(item.descuento === 0 && Number(item.monto) >= 100){
                    msg.push(`El porcentaje de ${item.concepto} en la sección de seguros debe ser menor a 100`);
                    flag = false;
                }
            });

            values.gestorias.forEach(item => {
                detalles.push({
                    cambioDevolucionCalculadoraId: item.cambioDevolucionCalculadoraId,
                    cambioDevolucionCalculadoraDetalleId: item.cambioDevolucionCalculadoraDetalleId,
                    seccion: 4,
                    concepto: item.concepto,
                    descuento: item.monto,
                    esPorcentaje: item.descuento, // descuento es el boolean de si es porcentaje o no
                    desembolso: item.desembolso,
                    devolucion: item.devolucion,
                    gestoriaPagada: item.gestoria
                });

                
                if(item.descuento === null){
                    msg.push(`El tipo de descuento de ${item.concepto} no puede ir nulo, por favor selecciona un tipo de descuento`);
                    flag = false;
                }else if(item.descuento === 1 && item.desembolso < item.monto){
                    msg.push(`El monto de ${item.concepto} en la sección de gestoria debe ser menor a ${item.desembolso}`);
                    flag = false;
                }else if(item.descuento === 0 && Number(item.monto) >= 100){
                    msg.push(`El porcentaje de ${item.concepto} en la sección de gestoria debe ser menor a 100`);
                    flag = false;
                }
            });

            if(msg.length > 0){
                msg.forEach(item => {
                    toast.error(message(item));
                });
            }

            if(!flag){
                return false;
            }

            let params = {
                cambioDevolucionId: values.cambioDevolucionId,
                comentarios: values.comentarios,
                concluido: values.concluido,
                detalles: detalles,
            }

            setLoading(true);


            Garantias.calculadoraUpdate(params)
            .then(resp => {
                console.log(resp.data);
                const pagosRealizados = resp.data.detalles;
                setInitialValue({
                    ...initialValue,
                    detallePoliza: resp.data.detallePoliza === null ? null : (resp.data.detallePoliza ? 1 : 0),
                    comentarios: resp.data.comentarios,
                    concluido: resp.data.concluido,
                });
                toast.success(message("Cálculos actualizados correctamente"));
                creaDatosCalculados(pagosRealizados, resp.data);  
            })
            .catch(err => {
                toast.error(message("¡Error al intentar actualizar los cálculos!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
                if(values.concluido){
                    // crearDatamovil();
                    crearCierreContrato();
                    // crearValuacion();
                }
            });
        }

        const crearDatamovil = async () => {
            let params = {
                generalId: generalId,
                procesoId: 20,
                autorizada: false,
                concluida: false
            }

            await Datamovil.datamovilCrear(params)
            .then(resp => {
                toast.success(message("Se creó la desinstalación datamovil correctamente"));
                crearOrden(resp.data.datamovilId, resp.data.vin, generalId);

                /* AQUI PONER POLIZAS */
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la desinstalación datamovil!"));
            });
        }

        const crearOrden = async (datamovilId, vin, generalId) => {
            let params = {
                datamovilId: datamovilId,
                datamovilOrdenDesinstalacionStatusId: 20,
                motivosDesinstalacionId: 5,
                usuarioSolicita: sessionStorage.getItem("nombreUsuario") || "",
                tipoOrden: false,
                status: true
            }

            await Datamovil.datamovilOrdenDesinstalacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó la orden de desinstalación datamovil correctamente"));
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear la orden de desinstalación datamovil!"));
            })
        }

        const crearCierreContrato = async () => {
            let params = {
                cierreContratoStatusId: 10,
                cierreContratoProcesoId: 20,
                GeneralId: generalId,
                origen: origen,
                origenId: origenId,
                status: true,
                concluido: false
            }

            await Cierres.cierreContratoCrear(params)
            .then(resp => {
                toast.success(message("Se creó el cierre de contrato correctamente"));
                notificarClienteCierreContrato(resp.data.cierreContratoId);
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear el cierre de contrato!"));
            });
        }

        
        // const cierreContratoAdeudoCrear = async (cierreContratoId) => {
        //     let params = {
        //         cierreContratoId: cierreContratoId,
        //         cierreContratoAdeudoStatusId: 10
        //     };

        //     await Cierres.cierreContratoAdeudoCrear(params)
        //     .then(resp => {
        //         toast.success(message("Se inició el proceso de adeudos correctamente"));
        //     })
        //     .catch(err => {
        //         toast.error(message("¡Error, al intentar iniciar el proceso de adeudos!"));
        //     });
        // }

        const notificarClienteCierreContrato = async (cierreContratoId) => {
            let params = {
                cierreContratoId: cierreContratoId,
                cierreContratoNotificacionStatusId: 10,
                status: 1
            }

            await Cierres.cierreContratoNotificacionCrear(params)
            .then(resp => {
                toast.success(message("Se inició el proceso de notificación de cliente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el proceso de notificación de cliente!"));
            });
        }

        // const cierreContratoNotificacionCrear = async (cierreContratoId) => {
        //     let params = {
        //         cierreContratoId: cierreContratoId,
        //         cierreContratoNotificacionStatusId: 10,
        //         status: true
        //     }

            
        //     await Cierres.cierreContratoNotificacionCrear(params)
        //     .then(resp => {
        //         toast.success(message("Se creó la notificación de cierre de contrato correctamente"));
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         toast.error(message("¡Error, al intentar crear la notificación de cierre de contrato!"));
        //     });
        // }

        const crearValuacion = async () => {
            let params = {
                generalId: generalId,
                procesoId: 20,
                origen: origen,
                origenId: origenId,
                concluido: false
            }

            await Valuaciones.crearValuaciones(params)
            .then(resp => {
                toast.success(message("Se creó la valuación correctamente"));
                crearInspeccionUnidad(resp.data.valuacionId);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la valuación!"));
            });
        }

        const crearInspeccionUnidad = async (valuacionId) => {
            let params = {
                valuacionId: valuacionId,
                valuacionInspeccionUnidadStatusId: 10,
                status: true
            }

            await Valuaciones.valuacionInspeccionUnidadCrear(params)
            .then(resp => {
                toast.success(message("Se creó la inspección de unidad correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la inspección de unidad!"));
            });
        }


        const validate = Yup.object({});

        const cardStyle = {
            "boxShadow": "none", 
            "border": "solid", 
            "borderWidth": "thin",
            "borderColor": "#BDBDBD"
        }


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <ConfirmarCalculadoraModal
                            submitForm={submitForm}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCalculo" role="button" aria-expanded="false" aria-controls="collapseCalculo" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={!initialValue.concluido ? 'semaforo semaforo-yellow' : (initialValue.concluido ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Cálculos de devolución</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseCalculo">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Acendes</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {
                                                                    !initialValue.concluido &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid}
                                                                            onClick={() => prevSubmitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <span>Sincronizar pagos</span>
                                                        </div>
                                                        <div className="col-auto">
                                                            {/* <button className='btn btn-gray col-12' disabled={false} onClick={() => getInfoFromAcendes()}> */}
                                                            <button className='btn btn-gray col-12' disabled={values.concluido} onClick={() => getInfoFromAcendes()}>
                                                                <i className="ri-refresh-fill"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Cálculos de devolución</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <div className="row px-2">
                                                                <div className="card col-12 mb-0" style={cardStyle} >
                                                                    <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                        <div className="row justify-content-between" data-bs-toggle="collapse" href="#collapseDesembolso" role="button" aria-expanded="false" aria-controls="collapseDesembolso" onClick={() => setCollpsableDesembolso(!collpsableDesembolso)}>
                                                                            <div className="col-6">
                                                                                <i className={!collpsableDesembolso ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i> Desembolso inicial
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="collapse mb-4" id="collapseDesembolso">
                                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                            <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <strong>Concepto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Desembolso</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>Descuento</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Monto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Devolución</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            values.desembolsos.length === 0 && 
                                                                            <div className="row px-4 mt-2" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-center">
                                                                                            <small>No hay datos que mostrar</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            initialValue.desembolsos.length > 0 &&
                                                                            initialValue.desembolsos.map((item, idx) => {
                                                                                return <div className='row px-2 mx-2' key={`${idx}_desembolso`} style={{"height": "50px", "lineHeight": "50px", "border": "solid #DFDFDF", "borderWidth": "0px 0px thin 0px"}}>
                                                                                    <div className="col-3">
                                                                                        <span>{item.concepto}</span>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <span>{formatMoney(item.desembolso)}</span>
                                                                                    </div>
                                                                                    <div className="col-3 py-2" style={{"lineHeight": "20px", "height": "20px"}}>
                                                                                        <SelectField id={`desembolsos[${idx}][descuento]`}
                                                                                            name={`desembolsos[${idx}][descuento]`}
                                                                                            disabled={values.concluido}
                                                                                            items={opcionesBool}                                                                                            
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`desembolsos[${idx}][descuento]`, event.value);
                                                                                                setDisabled(initialValue.desembolsos, initialValue.desembolsos[idx], event.value);
                                                                                        }} />
                                                                                    </div>
                                                                                    <div className="col-2 mt-2">
                                                                                        <TextField id={`desembolsos[${idx}][monto]`} disabled={item.descuento === null || values.concluido}  name={`desembolsos[${idx}][monto]`} type="text" holder="0" 
                                                                                        onBlur={(event) => {
                                                                                            calculateDesembolsoDevolucion('desembolsos', idx, event.target.value);
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            setFieldValue(`desembolsos[${idx}][monto]`, event.target.value);
                                                                                        }} />
                                                                                    </div> 
                                                                                    <div className="col-2">
                                                                                        <span>{formatMoney(item.devolucion)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {
                                                                            values.desembolsos.length > 0 &&
                                                                            <div className="row px-4 mt-2 mb-4" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                                <div className="col-12 alert alert-secondary" role="alert">
                                                                                    <div className="row">
                                                                                        <div className="col-3">
                                                                                            <strong>Total</strong>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <strong>{formatMoney(desembolsoListTotal.desembolso)}</strong>
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                            <strong></strong>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <strong></strong>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <strong>{formatMoney(desembolsoListTotal.devolucion)}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row px-2">
                                                                <div className="card col-12 mb-0" style={cardStyle}>
                                                                    <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                        <div className="row justify-content-between"  data-bs-toggle="collapse" href="#collapsePagos" role="button" aria-expanded="false" aria-controls="collapsePagos" onClick={() => setCollpsablePagos(!collpsablePagos)}>
                                                                            <div className="col-6">
                                                                                <i className={!collpsablePagos ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i> Pagos realizados 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="collapse mb-4" id="collapsePagos">
                                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                            <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Concepto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Pagos</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Desembolso</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Descuento</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Monto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Devolución</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            values.pagos.length === 0 && 
                                                                            <div className="row px-4 mt-2" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-center">
                                                                                            <small>No hay datos que mostrar</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            initialValue.pagos.length > 0 &&
                                                                            initialValue.pagos.map((item, idx) => {
                                                                                return <div className="row px-2 mx-2" style={{"height": "50px", "lineHeight": "50px", "border": "solid #DFDFDF", "borderWidth": "0px 0px thin 0px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            <span>{item.concepto}</span>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <span>{item.pagos}</span>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <span>{formatMoney(item.desembolso)}</span>
                                                                                        </div>
                                                                                        <div className="col-2 py-2" style={{"lineHeight": "20px", "height": "20px"}}>
                                                                                            <SelectField id={`pagos[${idx}][descuento`}
                                                                                                name={`pagos[${idx}][descuento`}
                                                                                                items={opcionesBool}                                                                                            
                                                                                                disabled={values.concluido}
                                                                                                onChange={(event) => {
                                                                                                    setFieldValue(`pagos[${idx}][descuento]`, event.value);
                                                                                                    setDisabled(initialValue.pagos, initialValue.pagos[idx], event.value);
                                                                                            }} />
                                                                                        </div>
                                                                                        <div className="col-2 mt-2">
                                                                                            <TextField id={`pagos[${idx}][monto]`} disabled={item.descuento === null || values.concluido}  name={`pagos[${idx}][monto]`} type="text" holder="0" 
                                                                                            onBlur={(event) => {
                                                                                                calculatePagosDevolucion('pagos', idx, event.target.value);
                                                                                            }}
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`pagos[${idx}][monto]`, event.target.value);
                                                                                            }} />
                                                                                        </div> 
                                                                                        <div className="col-2">
                                                                                            <span>{formatMoney(item.devolucion)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            })
                                                                        }
                                                                        <div className="row px-4 mt-2 mb-2" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                            <div className="col-12 alert alert-secondary" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Total</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(pagosListTotal.desembolso)}</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(pagosListTotal.devolucion)}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row px-2">
                                                                <div className="card col-12 mb-0" style={cardStyle}>
                                                                    <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                        <div className="row justify-content-between" data-bs-toggle="collapse" href="#collapseSeguro" role="button" aria-expanded="false" aria-controls="collapseSeguro" onClick={() => setCollpsableSeguro(!collpsableSeguro)}>
                                                                            <div className="col-6">
                                                                                <i className={!collpsableSeguro ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i> Seguro 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="collapse mb-4" id="collapseSeguro">
                                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                            <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Póliza</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Emisión póliza</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Desembolso</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Descuento</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Monto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Devolución</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            values.seguros.length === 0 && 
                                                                            <div className="row px-4 mt-2" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-center">
                                                                                            <small>No hay datos que mostrar</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            initialValue.seguros.length > 0 &&
                                                                            initialValue.seguros.map((item, idx) => {
                                                                                return <div className="row px-2 mx-2" style={{"height": "50px", "lineHeight": "50px", "border": "solid #DFDFDF", "borderWidth": "0px 0px thin 0px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            <span>{item.poliza}</span>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <span>{item.emision}</span>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <span>{formatMoney(item.desembolso)}</span>
                                                                                        </div>
                                                                                        <div className="col-2 py-2" style={{"lineHeight": "20px", "height": "20px"}}>
                                                                                            <SelectField id={`seguros[${idx}][descuento]`}
                                                                                                name={`seguros[${idx}][descuento]`}
                                                                                                items={opcionesBool}
                                                                                                disabled={values.concluido}
                                                                                                onChange={(event) => {
                                                                                                    setFieldValue(`seguros[${idx}][descuento]`, event.value);
                                                                                                    setDisabled(initialValue.seguros, initialValue.seguros[idx], event.value);
                                                                                            }} />
                                                                                        </div>
                                                                                        <div className="col-2 mt-2">
                                                                                            <TextField id={`seguros[${idx}][monto]`} disabled={item.descuento === null || values.concluido}  name={`seguros[${idx}][monto]`} type="text" holder="0" 
                                                                                                onBlur={(event) => {
                                                                                                    calculateSegurosDevolucion('seguros', idx, event.target.value);
                                                                                                }}
                                                                                                onChange={(event) => {
                                                                                                    setFieldValue(`seguros[${idx}][monto]`, event.target.value);
                                                                                                }} />
                                                                                        </div> 
                                                                                        <div className="col-2">
                                                                                            <span>{formatMoney(item.devolucion)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            })
                                                                        }
                                                                        <div className="row px-4 mt-2 mb-4" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                            <div className="col-12 alert alert-secondary" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Total</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(seguroListTotal.desembolso)}</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(seguroListTotal.devolucion)}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row px-2">
                                                                <div className="card col-12 mb-0" style={cardStyle}>
                                                                    <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                        <div className="row justify-content-between" data-bs-toggle="collapse" href="#collapseGestoria" role="button" aria-expanded="false" aria-controls="collapseGestoria" onClick={() => setCollpsableGestoria(!collpsableGestoria)}>
                                                                            <div className="col-6">
                                                                                <i className={!collpsableGestoria ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i> Proceso de gestoría 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="collapse mb-4" id="collapseGestoria">
                                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                            <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Gestoría pagada</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Concepto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Desembolso</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Descuento</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Monto</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>Devolución</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            values.gestorias.length === 0 && 
                                                                            <div className="row px-4 mt-3" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-center">
                                                                                            <small>No hay datos que mostrar</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            initialValue.gestorias.length > 0 &&
                                                                            initialValue.gestorias.map((item, idx) => {
                                                                                return <div className="row px-4 mt-2 mb-2" style={{"height": "50px", "lineHeight": "50px"}}>
                                                                                    <div className="col-2">
                                                                                        <span>{item.gestoria ? 'Sí' : 'No'}</span>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <span>{item.concepto}</span>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <span>{formatMoney(item.desembolso)}</span>
                                                                                    </div>
                                                                                    <div className="col-2 py-2" style={{"lineHeight": "20px", "height": "20px"}}>
                                                                                        <SelectField id={`gestorias[${idx}][descuento]`}
                                                                                            name={`gestorias[${idx}][descuento]`}
                                                                                            items={opcionesBool}
                                                                                            disabled={values.concluido}
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`gestorias[${idx}][descuento]`, event.value);
                                                                                                setDisabled(initialValue.gestorias, initialValue.gestorias[idx], event.value);
                                                                                        }} />
                                                                                    </div>
                                                                                    <div className="col-2 mt-2">
                                                                                        <TextField id={`gestorias[${idx}][monto]`} disabled={item.descuento === null || values.concluido}  name={`gestorias[${idx}][monto]`} type="text" holder="0" 
                                                                                        onBlur={(event) => {
                                                                                            calculateGestoriasDevolucion('gestorias', idx, event.target.value);
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            setFieldValue(`gestorias[${idx}][monto]`, event.target.value);
                                                                                        }} />
                                                                                    </div> 
                                                                                    <div className="col-2">
                                                                                        <span>{formatMoney(item.devolucion)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        <div className="row px-4 mt-2 mb-4" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                            <div className="col-12 alert alert-secondary" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <strong>Total</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(gestoriaListTotal.desembolso)}</strong>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <strong>{formatMoney(gestoriaListTotal.devolucion)}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row px-2">
                                                                <div className="card col-12" style={cardStyle}>
                                                                    <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                Cálculos totales 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-4">
                                                                        <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                            <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <strong></strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>Desembolso</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>Descuento</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>Devolución</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-4 mt-2 mb-4" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                            <div className="col-12 alert alert-secondary" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <strong>Total</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>{formatMoney(total.total)}</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>{formatMoney(total.descuento)}</strong>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <strong>{formatMoney(total.devolucion)}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-6">
                                                                <TextAreaField id="comentarios"
                                                                    label="Comentarios de cálculos de devolución"
                                                                    disabled={values.concluido}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentarios"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentarios", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-2">
                                                            <div className="col-3">
                                                                <small><strong>Adeudos</strong></small>
                                                            </div>
                                                        </div>
                                                        {
                                                            showAdeudos && 
                                                            <div className="row mt-2 px-4">
                                                                <div className="row px-2">
                                                                    <div className="card col-12 mb-0" style={cardStyle}>
                                                                        <div className="card-header" style={{"boxShadow": "0px 0 10px rgba(1, 41, 112, 0.1) !important", "color":"#000"}}>
                                                                            <div className="row justify-content-between" data-bs-toggle="collapse" href="#collapseAdeudos" role="button" aria-expanded="false" aria-controls="collapseAdeudos" onClick={() => setCollpsableGestoria(!collpsableGestoria)}>
                                                                                <div className="col-6">
                                                                                    <i className={!collpsableGestoria ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i> Adeudos 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="collapse mb-4" id="collapseAdeudos" >
                                                                            <div className="row px-4" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                <div className="col-12" style={{"border": "solid", "borderWidth": "0 0 thin 0"}}>
                                                                                    <div className="row">
                                                                                        <div style={containerStyleAdeudoSmall}>
                                                                                            <strong>No. Orden</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudo}>
                                                                                            <strong>Adeudo</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoSmall}>
                                                                                            <strong>Subtotal</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoSmall}>
                                                                                            <strong>IVA</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoSmall}>
                                                                                            <strong>Monto adeudo</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudo}>
                                                                                            <strong>Fecha de registro</strong>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoActions}>
                                                                                            <strong>Acciones</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                initialValue.adeudos.length
                                                                            }
                                                                            {
                                                                                initialValue.adeudos.length === 0 && 
                                                                                <div className="row px-4 mt-3" style={{"height": "40px", "lineHeight": "40px"}}>
                                                                                    <div className="col-12">
                                                                                        <div className="row">
                                                                                            <div className="col-12 text-center">
                                                                                                <small>No hay datos que mostrar</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                initialValue.adeudos.length > 0 &&
                                                                                initialValue.adeudos.map((item, idx) => {
                                                                                    return <div className="row px-4 mt-2 mb-2" key={`item_${idx}`} style={{"height": "50px", "lineHeight": "50px"}}>
                                                                                        <div style={containerStyleAdeudoLabels}>
                                                                                            <span>{item.noOrden}</span>
                                                                                        </div>
                                                                                        <div className="mt-2" style={containerStyleAdeudoFields}>
                                                                                            <SelectField id={`adeudos[${idx}][adeudo]`}
                                                                                                name={`adeudos[${idx}][adeudo]`}
                                                                                                items={tipoAdeudos}
                                                                                                disabled={values.concluido}
                                                                                                onChange={(event) => {
                                                                                                    setFieldValue(`adeudos[${idx}][adeudo]`, event.value);
                                                                                                    setDisabledAdeudos(initialValue.adeudos, initialValue.adeudos[idx], event.value);
                                                                                            }} />
                                                                                        </div>
                                                                                        <div className="mt-2" style={containerStyleAdeudoLabels}>
                                                                                            <TextField id={`adeudos[${idx}][subtotal]`} disabled={item.adeudo === null || values.concluido}  name={`adeudos[${idx}][subtotal]`} type="text" holder="0" 
                                                                                            onBlur={(event) => {
                                                                                                calculateAdeudos('subtotal', values.adeudos);
                                                                                                calculateMonto('adeudos', idx, event.target.value, values.adeudos);
                                                                                                // calculateMonto(initialValue.adeudos[idx]);
                                                                                            }}
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`adeudos[${idx}][subtotal]`, event.target.value);
                                                                                            }} />
                                                                                        </div> 
                                                                                        <div className="mt-2" style={containerStyleAdeudoLabels}>
                                                                                            <TextField id={`adeudos[${idx}][iva]`} disabled={item.adeudo === null || values.concluido}  name={`adeudos[${idx}][iva]`} type="text" holder="0" 
                                                                                            onBlur={(event) => {
                                                                                                calculateAdeudos('iva', values.adeudos);
                                                                                                calculateMonto('adeudos', idx, event.target.value, values.adeudos);
                                                                                                // calculateMonto(initialValue.adeudos[idx]);
                                                                                            }}
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`adeudos[${idx}][iva]`, event.target.value);
                                                                                            }} />
                                                                                        </div>
                                                                                        <div className="mt-2" style={containerStyleAdeudoLabels}>
                                                                                            <TextField id={`adeudos[${idx}][monto]`} 
                                                                                            disabled={true}  
                                                                                            name={`adeudos[${idx}][monto]`} 
                                                                                            type="text" 
                                                                                            holder="0" 
                                                                                            onBlur={(event) => {
                                                                                                calculateAdeudos('monto', values.adeudos);
                                                                                            }}
                                                                                            onChange={(event) => {
                                                                                                setFieldValue(`adeudos[${idx}][monto]`, event.target.value);
                                                                                            }} />
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoLabels}>
                                                                                            <span>{item.fechaRegistro}</span>
                                                                                        </div>
                                                                                        <div style={containerStyleAdeudoActions}>
                                                                                            <button
                                                                                                className='btn mx-1'
                                                                                                onClick={(event) => {
                                                                                                    updateAdeudo(item.cambioDevolucionAdeudosId, values.adeudos[idx]);
                                                                                                }}
                                                                                            >
                                                                                                <i className="icon-light fa fa-save fa-1x"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className='btn'
                                                                                                onClick={(event) => {
                                                                                                    deleteAdeudo(item.cambioDevolucionAdeudosId);
                                                                                                }}
                                                                                            >
                                                                                                <i className="icon-light fa fa-trash fa-1x"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            <div className="row px-4 mt-2 mb-2">
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            <button 
                                                                                                className='btn btn-gray'
                                                                                                onClick={(event) => {
                                                                                                    addAdeudo();
                                                                                                }}
                                                                                            >
                                                                                                <i className="icon-light fa fa-plus fa-1x"></i> Agregar
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row px-4 mt-2 mb-5" style={{"height": "35px", "lineHeight": "10px"}}>
                                                                                <div className="col-12 alert alert-secondary" role="alert">
                                                                                    <div className="row">
                                                                                        <div style={{"width": "150px", "height": "20px", "lineHeight": "20px"}}>
                                                                                            <strong>Total</strong>
                                                                                        </div>
                                                                                        <div style={{"width": "180px", "height": "20px", "lineHeight": "20px"}}>
                                                                                        </div>
                                                                                        <div style={{"width": "150px", "height": "20px", "lineHeight": "20px"}}>
                                                                                            <strong>{formatMoney(adeudoSubtotal)}</strong>
                                                                                        </div>
                                                                                        <div style={{"width": "150px", "height": "20px", "lineHeight": "20px"}}>
                                                                                            <strong>{formatMoney(adeduoIva)}</strong>
                                                                                        </div>
                                                                                        <div style={{"width": "150px", "height": "20px", "lineHeight": "20px"}}>
                                                                                            <strong>{formatMoney(montoAdeudo)}</strong>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="row mt-4 px-2">
                                                            <div className="col-3">
                                                                <small><strong>Balance de cálculos</strong></small>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4 px-2">
                                                            <div className="col-3">
                                                                <table className="table" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                    <thead style={{"backgroundColor":"#E0E0E0"}}>
                                                                        <tr>
                                                                            <th scope="col">Concepto</th>
                                                                            <th scope="col">Monto</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">Desembolso (+)</th>
                                                                            <td>{ formatMoney(total.total) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Descuento (-)</th>
                                                                            <td>{ formatMoney(total.descuento) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Saldo a favor (=)</th>
                                                                            <td>{ formatMoney(total.devolucion) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Adeudos (-)</th>
                                                                            <td>{ formatMoney(montoAdeudo) }</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot style={{"backgroundColor":"#E0E0E0"}}>
                                                                        <tr>
                                                                            <th scope="col">Total a pagar (=)</th>
                                                                            <th scope="col">{ formatMoney((total.devolucion-montoAdeudo)) }</th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4 px-2">
                                                            <div className="col-3">
                                                                <small><strong>Detalle de póliza</strong></small>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4 px-2">
                                                            <div className="col-3">
                                                                <SelectField id={'detallePoliza'}
                                                                    name={'detallePoliza'}
                                                                    items={opcionesBoolTrue}
                                                                    disabled={values.concluido}
                                                                    onChange={(event) => {
                                                                        setFieldValue('detallePoliza', event.value);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-6">
                                                                <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                                    setFieldValue("concluido", event.target.value);
                                                                }} />
                                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cálculos de devolución concluidos</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default CardCalculo