import React, { useEffect, useState } from "react";
import useInfoContrato from "../Hooks/Inventario/useInfoContrato";
import Siniestros from "../Services/Siniestro/Siniestro";

const ContratoInfoCard = ({ contrato }) => {
    const [vehiculo, setVehiculo] = useState({});
    const [acendes, setAcendes] = useState({});
    useEffect(()=>{
        getContratoAcendes()
    })

    const getContratoAcendes = async () => {
        await Siniestros.siniestroByVinGet(contrato.vin)
            .then(resp => {
                // console.log(resp)
                setVehiculo(resp.data.vehiculo);
                setAcendes(resp.data.infoAscendes);
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <>
            <div className="row p-4 wrapper-vehiculo">
                <div className="col col-12">
                    <div className="row align-items-center justify-content-between">
                        <div className="col col-auto">
                            <h1 className="fw-bold text-sm">Contrato</h1>
                        </div>

                        <div className="col col-6">
                            <span className="badge text-xs">
                                {contrato.estatus === "Activo" ?
                                    <span style={{ borderRadius: '5px', backgroundColor: "#D8F2CD", color: "#47A066",  padding: ".35em .65em" }} className='d-flex align-items-center justify-content-center'>Activo</span>
                                    :
                                    <span style={{ borderRadius: '5px', backgroundColor: "#F5CECE", color: "#CE2222", padding: ".35em .65em" }} className='d-flex align-items-center justify-content-center'>Inactivo</span>
                                }</span>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row gap-2">
                        <div className="col col-12">
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">No. de contrato:</strong> {contrato.contrato_id}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Término de crédito:</strong> -{ acendes?.termino_contrato} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Cliente:</strong> {contrato.nombre} {contrato.apellido_paterno}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Pago de periodo:</strong> {contrato.pago_periodo}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Uso de unidad:</strong> - {vehiculo?.usoUnidad}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Frecuencia de pago:</strong> {contrato.frecuencia_pago}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Saldo Insoluto:</strong> {contrato.saldo_credito}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fideicomiso:</strong> -{contrato.fideicomisario}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fecha de último pago:</strong> {contrato.fecha_ultimo_pago}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContratoInfoCard;