import axios from '../../Utils/ApiService';

const ExpedienteService = {
    getAllVehiculosExpedienteArmado:(page,rows,tipoCompraId,externoId,string)=> {        
        return axios.get(`/functions/GetAllVehiculosExpedienteArmado?page=${page}&Rows=${rows}&TipoExternoId=${externoId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);        
    },    
    getAllVehiculosExpedienteEntregado:(page,rows,tipoCompraId,externoId,string)=> {        
        return axios.get(`/functions/GetAllVehiculosExpedienteEntregado?ppage=${page}&Rows=${rows}&TipoExternoId=${externoId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);        
    }, 
    SaveChangeExpediente:(data)=>{
        return axios.put(`/functions/ConcluirExpediente`,data);
    },
    downloadFileExpediente:(tipoCompraId,externoId,string)=>{
        return axios.get(`/functions/GetExportVehiculosExpedienteArmado?TipoExternoId=${externoId}&TipoCompraId=${tipoCompraId}&Buscar=${string}`,{responseType: 'blob'});
    },
    downloadFilePDF : async (id) =>{
        return await axios.get(`/functions/downloadExpediente?GeneralId=${id}`,{responseType: 'blob'});
    },
}
export default ExpedienteService;