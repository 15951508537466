import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';
import { tabActiveAutorizacionSolicitudes } from '../../../Redux/Slice/navigateSliceAutorizacion';

const SubMenuAutorizacionDeSolicitudes = () => {
  const { value } = useSelector((state) => state.navigateSliceAutorizacion);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])

  const handleSetStateTab = (value) => {
    dispatch(tabActiveAutorizacionSolicitudes(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.SolicitudesDeCitas) && value === 1) {
      navigate(`/seguro/autorizacion/solicituddecitas`);
    }else if (ValidatePermissionById(3, Section.AutorizarDictamen) && value === 2) {
      navigate(`/seguro/autorizacion/autorizaciondictamen`);
    }else if(ValidatePermissionById(3, Section.ValuacionEnRobo) && value === 3){
        navigate(`/seguro/autorizacion/veluacionenrobo`);
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.SolicitudesDeCitas)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Solicitudes de citas</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.AutorizarDictamen)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Autorizar dictamen</button>
          </li>
        </ValidatePermission> 
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ValuacionEnRobo)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Valuación en robo</button>
          </li>
        </ValidatePermission>          
      </ul>
    </>
  )
}

export default SubMenuAutorizacionDeSolicitudes