import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayEntrega: "",
    hourEntrega: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdEntrega: "",
}

export const EntregaSlice = createSlice({
    name: 'EntregaSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayEntrega: action.payload.day,
                hourEntrega: action.payload.hour,
                calendarioIdEntrega: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayEntrega: "",
                hourEntrega: "",
                calendarioIdEntrega: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = EntregaSlice.actions

export default EntregaSlice.reducer