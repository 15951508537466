import axios from '../../Utils/ApiService';

const Logs = {
    getLogsUsuario:(page,rows,modulo,categoria,str,fecha)=> {
        return  axios.get(`/functions/GetAllLogUsuario?page=${page}&rows=${rows}&ModuloId=${modulo}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);
    }, 
    getLogsUsuarioEliminado:(page,rows,modulo,categoria,str,fecha)=> {
        return axios.get(`/functions/GetAllLogUsuarioEliminados?page=${page}&rows=${rows}&ModuloId=${modulo}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);
    },   
    getLogsInterfaz:(page,rows,proceso,categoria,str,fecha)=> {
        return axios.get(`/functions/GetAllLogInterfaz?page=${page}&rows=${rows}&ProcesoId=${proceso}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);
    }, 
    getLogsInterfazEliminado:(page,rows,proceso,categoria,str,fecha)=> {
        return axios.get(`/functions/GetAllLogInterfazEliminados?page=${page}&rows=${rows}&ProcesoId=${proceso}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);
    }, 
    getLogsDetailInterfaz:(id)=> {
        return axios.get(`/functions/GetAllLogInterfazDetalle?TablaId=${id}`);
    }, 
    getLogSistema:(page,rows,proceso,categoria,str,fecha)=> {
        return axios.get(`/functions/GetAllLogSistema?page=${page}&rows=${rows}&ProcesoId=${proceso}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);        
    }, 
    getLogSistemaEliminado:(page,rows,proceso,categoria,str,fecha)=> {
        return axios.get(`/functions/GetAllLogSistemaEliminados?page=${page}&rows=${rows}&ProcesoId=${proceso}&CategoriaId=${categoria}&Buscar=${str}&Fecha=${fecha}`);        
    },   
    getList:(name)=> {        
        return axios.get(`/functions/GetAllCatalogosLog?Catalogo=${name}`);
    },  
    updateParam:(param,value)=> {        
        return axios.put(`/functions/${param}?value=${value}`);
    },  
    getHistoy :(page,rows,string,fecha,seccion) =>{
        return axios.get(`/functions/LogsParametros?page=${page}&rows=${rows}&buscar=${string}&fecha=${fecha}&seccion=${seccion}`);
    },
    downloadFileInterfaz:(ids) =>{
        return axios.get(`/functions/LogInterfazExportyEliminar?Ids=${ids}`,{responseType: 'blob'});
    },
    ExportFileInterfaz:(ids) =>{
        return axios.get(`/functions/LogInterfazExport?Ids=${ids}`,{responseType: 'blob'});
    },
    downloadFileUsuario:(ids) =>{
        return axios.get(`/functions/LogUsuarioExportyEliminar?Ids=${ids}`,{responseType: 'blob'});
    },
    exportFileUsuario:(ids) =>{
        return axios.get(`/functions/LogUsuarioExport?Ids=${ids}`,{responseType: 'blob'});
    },
    downloadFileSistema:(ids) =>{
        return axios.get(`/functions/LogSistemaExportyEliminar?Ids=${ids}`,{responseType: 'blob'});
    },
    exportFileSistema:(ids) =>{
        return axios.get(`/functions/LogSistemaExport?Ids=${ids}`,{responseType: 'blob'});
    }
}

export default Logs