import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useAgendarCita from '../../../../Hooks/DatamovilSystems/RevisionPasos/useAgendarCita';

const AgendarCitaCard = ({ handleConcluido }) => {
    const { refFormik } = useRef();

    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object({
        // ubicacion: Yup.string().required("Ubicación es requerida"),
        // estadoId: Yup.string().required("Estado es requerido"),
        // municipioId: Yup.string().required("Municipio es requerido"),
        // coloniaId: Yup.string().required("Colonia es requerida"),
        // calle: Yup.string().required("Calle es requerida"),
        // numero: Yup.string().required("Número es requerido"),
        // fechaCita: Yup.date().required("Fecha de cita es requerida"),
        // horaCita: Yup.string().required("Hora de cita es requerida")
    });
    const paso = 1;
    const {
        initialValue,
        loading,
        setLoading,
        submitFormAgendarCita,
        getEstados,
        getMunicipios,
        getColonias,
        estados,
        municipios,
        colonias,
        tipoUbicaciones
    } = useAgendarCita({handleConcluido,paso});

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseAgendarCita"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseAgendarCita"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><strong>Agendar Cita</strong> <span>En proceso</span></h6>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseAgendarCita">
                            <div className="divider"></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitFormAgendarCita(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="agendarCitaForm">
                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicacion</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.arriboCita ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.arriboCita}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    id="tipoUbicacion"
                                                    label="Tipo de Ubicación"
                                                    name="tipoUbicacion"
                                                    items={tipoUbicaciones}
                                                    onChange={(event) => {
                                                        setFieldValue("tipoUbicacion", event.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Ubicación"
                                                    name="ubicacion"
                                                    id="ubicacion"
                                                    type="text"
                                                    holder="Escribir"
                                                    onChange={(event) => {
                                                        setFieldValue("ubicacion", event.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField
                                                    id="estadoId"
                                                    label="Estado"
                                                    name="estadoId"
                                                    items={estados}
                                                    onChange={(event) => {
                                                        setFieldValue("estadoId", event.value);
                                                        getMunicipios(event.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField
                                                    id="municipioId"
                                                    label="Municipio"
                                                    name="municipioId"
                                                    items={municipios}
                                                    onChange={(event) => {
                                                        setFieldValue("municipioId", event.value);
                                                        getColonias(event.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    id="coloniaId"
                                                    label="Colonia"
                                                    name="coloniaId"
                                                    items={colonias}
                                                    onChange={(event) => {
                                                        setFieldValue("coloniaId", event.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Calle"
                                                    name="calle"
                                                    type="text"
                                                    holder="Escribir"
                                                    onChange={(event) => {
                                                        setFieldValue("calle", event.target.value);
                                                    }} />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Número"
                                                    name="numeroExt"
                                                    type="text"
                                                    holder="Escribir"
                                                    onChange={(event) => {
                                                        setFieldValue("numeroExt", event.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <h6><strong>Fecha para agendar cita</strong></h6>
                                            <div className="col-md-3">
                                                <TextField label="Fecha de cita" name="fechaCita" type="date"
                                                    onChange={(event) => {
                                                        setFieldValue("fechaCita", event.target.value);
                                                    }} />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Hora de cita"
                                                    name="horaCita"
                                                    type="time"
                                                    onChange={(event) => {
                                                        setFieldValue("horaCita", event.target.value);
                                                    }} />
                                            </div>
                                            <div className="col-md-12 d-flex align-items-center">
                                                <Field type="checkbox" name="arriboCita" className="form-check-input me-2" onClick={(event) => {
                                                    setFieldValue("arriboCita", event.target.value);
                                                }} />
                                                <label className="form-check-label">Cliente arribó a cita</label>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <small className="text-muted">Inicio de proceso: {initialValue.fechaAlta} | Fin de proceso: {initialValue.fechaConcluido}</small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgendarCitaCard;
