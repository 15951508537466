import axios from '../../../Utils/ApiService';

const LiberacionUnidadService = {
    getLiberacionunidadSiniestro: (id) => {
        return axios.get(`/functions/GetLiberacionunidadSiniestro?SiniestroId=${id}`);
    },
    updateLiberacionUnidadSiniestro: (data) => {
        return axios.put(`/functions/UpdateLiberacionUnidadSiniestro`,data);
    }
}
export default LiberacionUnidadService