import React, { useEffect, useState } from 'react'
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { current } from '@reduxjs/toolkit';

const useHistorialReglas = (setLoading) => {
    const columnsHistorialReglaEtapa = [
        { field: 'fecha', headerName: 'Fecha y hora', width: 230 },
        { field: 'usuario', headerName: 'Usuario', width: 230 },
        { field: 'etapa', headerName: 'Etapa', width: 230 },
        { field: 'tipoRegla', headerName: 'Parámetros', width: 230 },
        { field: 'regla', headerName: 'Movimiento', width: 330 },
    ];
    const columnsHistorialReglaNegocio = [
        { field: 'fecha', headerName: 'Fecha y hora', width: 230 },
        { field: 'usuario', headerName: 'Usuario', width: 230 },
        { field: 'etapaActual', headerName: 'Etapa', width: 230 },
        { field: 'descripcion', headerName: 'Movimiento', width: 330 },
    ];
 
    const [dataHistorialReglaEtapa, setDataHistorialReglaEtapa] = useState([]);
    const [dataHistorialReglaNegocio, setDataHistorialReglaNegocio] = useState([]);
    const [filtroHistorialProductoId, setfiltroHistorialProductoId] = useState("");
    const [filtroHistorialTipocompraId, setfiltroHistorialTipocompraId] = useState("");
    const [filtroHistorialFecha, setFiltroHistorialFecha] = useState("");
    const [filtroHistorialEtapaId, setFiltroHistorialEtapaId] = useState("");

    const [currentPageEtapa, setCurrentPageEtapa] = useState(1);
    const [currentPageNegocio, setCurrentPageNegocio] = useState(1);
    const [pageCountEtapa, setPageCountEtapa] = useState();
    const [pageCountNegocio, setPageCountNegocio] = useState();



    useEffect(() => {
        if(filtroHistorialProductoId !== "" && filtroHistorialTipocompraId !== ""){
            handleGetAllHistoryEtapa();
            handleGetAllHistoryNegocio();
        }          
    }, [filtroHistorialProductoId,filtroHistorialTipocompraId,filtroHistorialFecha,filtroHistorialEtapaId])
    
    const handleGetAllHistoryEtapa = async () =>{        
        setLoading(true)
        await EtapaService.HistorialReglasEtapas(currentPageEtapa,10,filtroHistorialProductoId,filtroHistorialTipocompraId,filtroHistorialEtapaId,filtroHistorialFecha)
            .then(res => {                
                setDataHistorialReglaEtapa(res.data.data);
                setPageCountEtapa(res.data.totalPages);
                setLoading(false);
                // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })        
        setLoading(false)
    }
    const handleGetAllHistoryNegocio = async () =>{        
        setLoading(true)
        await EtapaService.HistorialReglasNegocio(currentPageNegocio,10,filtroHistorialProductoId,filtroHistorialTipocompraId,filtroHistorialEtapaId,filtroHistorialFecha)
            .then(res => {                
                setDataHistorialReglaNegocio(res.data.data);
                setPageCountNegocio(res.data.totalPages);
                setLoading(false);
                // setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })        
        setLoading(false)
    }
    const handlePageClickEtapa = (event) => {
        setCurrentPageEtapa(event.selected + 1);
        handleGetAllHistoryEtapa();
      };
      const handlePageClickNegocio = (event) => {
        setCurrentPageNegocio(event.selected + 1);
        handleGetAllHistoryNegocio();
      };
  return {
    columnsHistorialReglaEtapa,
    columnsHistorialReglaNegocio,
    dataHistorialReglaEtapa,
    setDataHistorialReglaEtapa,
    dataHistorialReglaNegocio,
    setDataHistorialReglaNegocio,
    setfiltroHistorialProductoId,
    setfiltroHistorialTipocompraId,
    setFiltroHistorialFecha,
    setFiltroHistorialEtapaId,
    pageCountEtapa,
    handlePageClickEtapa,
    currentPageEtapa,
    currentPageNegocio,
    pageCountNegocio,
    handlePageClickNegocio
  }
}

export default useHistorialReglas