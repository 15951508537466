import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceInstalacion = createSlice({
  name: 'InstalacionNavigate',
  initialState,
  reducers: {
    tabActiveInstalaciones: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveInstalaciones } = navigaSliceInstalacion.actions

export default navigaSliceInstalacion.reducer