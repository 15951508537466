import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import Llamada from '../../Services/Cartera/Llamadas';
import useLlamada from '../../Hooks/Incidencias/useLlamada';
import useGarantia from '../../Hooks/Incidencias/useGarantia';
import Garantias from '../../Services/Cartera/Garantias';
import { toast } from 'react-hot-toast';
import Cierres from '../../Services/Resguardos/Cierres';
import { message } from '../../Utils/functions';
import { setGarantiaSlice } from '../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { clearHour as clearHourGarantia } from '../../Redux/Slice/garantiasSlice';

const RechazaCitaModal = ({ modalMessage, setModalMessage, notificacion, getEntrega, getContratoCita, reset, getCitaDesinstalacion, getEntregaGestoria, getCambioPlacas, getAltaPlacas, getTarjetaCirculacion }) => {
    const useLlamda = useLlamada();
    const dispatch = useDispatch();
    const Garantia = useGarantia();

    const [cita, setCita] = useState({});
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        motivoId: "",
        comentario: ""
    };

    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        setCita(modalMessage.data);
        handleGetMotivosCancelacion();
    }, []);

    const validate = Yup.object().shape({
        motivoId: Yup.string().required('Campo requerido'),
        comentario: Yup.string()
    });

    const handleGetMotivosCancelacion = async () => {
        setLoading(true);
        try {
            const resp = await Catalogo.getMotivoCancelacion();
            const listMotivos = resp.data?.data.map(item => ({
                value: item.motivoCancelacionId,
                label: item.motivo
            }));
            setMotivos(listMotivos);
        } catch (error) {
            // Manejar errores adecuadamente
        } finally {
            setLoading(false);
        }
    }

    const handleRegresar = () => {
        setModalMessage({
            isOpen: true,
            type: 3,
            title: 'Detalle de cita',
            message: '',
            data: cita,
            authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin
        });
    }

    const acciones = {
        1: 'RechazarGarantia',
        2: 'RechazarGrua',
        3: 'RechazarEntrega',
        4: '',
        5: '',
        6: 'RechazarCierreCita',
        7: 'RechazarEntregaExpediente',
        8: 'RechazarDesinstalacionDatamovil',
        9: 'RechazarCambioPlacas',
        10: 'RechazarAltaPlacas',
        11: 'RechazarTarjetaCirculacion',
        12: 'RechazarGestoriaEntrega'
    }

    const handleCancelar = () => {
        const payload = {
            comentarioscancelacion: refFormik.current.values.comentario,
            motivocancelacionid: refFormik.current.values.motivoId,
            accion: acciones[modalMessage.authorizationType], //RechazarGrua - RechazarGarantia
            generalid: cita.generalId,
            garantiaid: cita.garantiaId,
            cierreContratoId: cita.garantiaId,
            type: modalMessage.authorizationType
        };


        Llamada.AutorizarRechazar(payload).then(res => {
            if (modalMessage.Origin !== "Listado") {
                if (modalMessage.authorizationType === 2) {
                    Garantia.handlerGetGruas(cita.garantiaId)
                } else {
                    useLlamda.GetGarantiaByLlamadaIdasync()
                }
            } else {
                if (modalMessage.authorizationType === 1) {
                    const motivo = motivos.filter(item => item.value === refFormik.current.values.motivoId)[0].label;
                    const date = new Date();
                    const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");

                    const contenido = {
                        VARIABLE_FECHA: fragmentsDate[0],
                        VARIABLE_HORA: fragmentsDate[1],
                        VARIABLE_UBICACIÓN: cita.ubicacion,
                        VARIABLE_MARCA: cita.nombreMarca,
                        VARIABLE_MODELO: cita.nombreModelo,
                        VARIBLE_MOTIVO: motivo,
                        VARIABLE_AÑO: cita.nombreYear,
                        VARIABLE_VERSION: cita.nombreYear,
                        VARIABLE_VIN: cita.vin,
                        VARIABLE_CONTRATO: cita.numContrato
                    }

                    if (modalMessage.authorizationType === 1) {
                        //Aqui va garantiaa
                        dispatch(clearHourGarantia())
                        useLlamda.GetGarantiaByLlamadaIdasync();
                        Garantias.creardatosenviar({
                            modulo: 'Revisión de Unidad Cancelada',
                            tipoCorreoId: 28,
                            contenido: JSON.stringify(contenido)
                        }).catch(err => {
                            console.log("Error al enviar correo Revisión de Unidad Cancelada: ", err);
                        })
                    }


                }
            }

            if (modalMessage.authorizationType === 2) {
                const date = new Date();
                const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");
                const contenidoEmail = {
                    VARIABLE_FECHA: fragmentsDate[0],
                    VARIABLE_HORA: fragmentsDate[1],
                    VARIABLE_UBICACIÓN: cita.ubicacion,
                    VARIABLE_MARCA: cita.nombreMarca,
                    VARIABLE_MODELO: cita.nombreModelo,
                    VARIABLE_AÑO: cita.nombreYear,
                    VARIABLE_VERSION: cita.nombreYear,
                    VARIABLE_VIN: cita.vin,
                    VARIABLE_CONTRATO: cita.numContrato,
                    VARIABLE_EJECUTIVO: "",
                    VARIABLE_MOTIVO: refFormik.current.values.motivoId,
                    VARIABLE_COMENTARIO: refFormik.current.values.comentario,
                }

                Garantias.creardatosenviar({
                    modulo: 'Cancelación de Recolección de Unidad',
                    tipoCorreoId: 30,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Cancelación de Recolección de Unidad:", err);
                })
            }

            if (modalMessage.authorizationType === 3) {
                notificacion();
                getEntrega();
            }

            if (modalMessage.authorizationType === 6) {
                // actualizarCita();
                getContratoCita();
                reset();
            }

            if (modalMessage.authorizationType === 7) {
                const date = new Date();
                const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");

                const contenidoEmail = {
                    VARIABLE_FECHA: fragmentsDate[0],
                    VARIABLE_HORA: fragmentsDate[1],
                    VARIABLE_UBICACIÓN: cita.ubicacion,
                    VARIABLE_MARCA: cita.nombreMarca,
                    VARIABLE_MODELO: cita.nombreModelo,
                    VARIABLE_AÑO: cita.nombreYear,
                    VARIABLE_VERSION: cita.nombreYear,
                    VARIABLE_VIN: cita.vin,
                    VARIABLE_CONTRATO: cita.numContrato,
                    VARIABLE_EJECUTIVO: "",
                    VARIABLE_MOTIVO: refFormik.current.values.motivoId,
                    VARIABLE_COMENTARIO: refFormik.current.values.comentario,
                }

                Garantias.creardatosenviar({
                    modulo: 'Cita Rechazada Entrega Documentos',
                    tipoCorreoId: 48,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Cita Rechazada Entrega Documentos:", err);
                })
                // getContratoCita();
                // reset();
            }


            if (modalMessage.authorizationType === 8) {
                getCitaDesinstalacion();
                const date = new Date();
                const fragmentsDate = date.toLocaleString().replaceAll(" ", "").split(",");

                const contenidoEmail = {
                    VARIABLE_FECHA: fragmentsDate[0],
                    VARIABLE_HORA: fragmentsDate[1],
                    VARIABLE_UBICACIÓN: cita.ubicacion,
                    VARIABLE_MARCA: cita.nombreMarca,
                    VARIABLE_MODELO: cita.nombreModelo,
                    VARIABLE_AÑO: cita.nombreYear,
                    VARIABLE_VERSION: cita.nombreYear,
                    VARIABLE_VIN: cita.vin,
                    VARIABLE_CONTRATO: cita.numContrato,
                    VARIABLE_EJECUTIVO: "",
                    VARIABLE_MOTIVO: refFormik.current.values.motivoId,
                    VARIABLE_COMENTARIO: refFormik.current.values.comentario,
                }

                Garantias.creardatosenviar({
                    modulo: 'Datamovil Rechazada',
                    tipoCorreoId: 53,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Datamovil Rechazada:", err);
                })
                setModalMessage({ isOpen: false })
            }

            if (modalMessage.authorizationType === 9) {
                getCambioPlacas();
                sendNotCambioPlacas();
            }
            if (modalMessage.authorizationType === 10) {
                getAltaPlacas();
                sendNotAltaPlacas();
            }
            if (modalMessage.authorizationType === 11) {
                getTarjetaCirculacion();
                sendNotTarjetaCirculacion();
            }
            if (modalMessage.authorizationType === 12) {
                getEntregaGestoria();
                // sendNotificationGestoriaEntrega();
            }

            setModalMessage({ isOpen: false, type: 4, title: 'Cancelación de cita', message: '', data: cita, authorizationType: modalMessage.authorizationType, Origin: modalMessage.Origin })
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    };

    const sendNotCambioPlacas = () => {
        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
            VARIABLE_MOTIVO: refFormik.current.values.motivoId,
            VARIABLE_COMENTARIO: refFormik.current.values.comentario,
        }
        
        Garantias.creardatosenviar({
            modulo: 'Rechazo de cita de extravío de placas',
            tipoCorreoId: 59,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Rechazo de cita de extravío de placas:", err);
        })
    }

    const sendNotAltaPlacas = () => {
        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
            VARIABLE_MOTIVO: refFormik.current.values.motivoId,
            VARIABLE_COMENTARIO: refFormik.current.values.comentario,
        }
        
        Garantias.creardatosenviar({
            modulo: 'Rechazo de cita de entrega placas',
            tipoCorreoId: 62,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Rechazo de cita de entrega placas:", err);
        })
    }

    const sendNotTarjetaCirculacion = () => {
        const contenidoEmail = {
            VARIABLE_USUARIO: sessionStorage.getItem('nombreUsuario'),
            VARIABLE_MARCA: cita.nombreMarca,
            VARIABLE_MODELO: cita.nombreModelo,
            VARIABLE_ANIO: cita.nombreYear,
            VARIABLE_VERSION: cita.nombreVersion,
            VARIABLE_VIN: cita.vin,
            VARIABLE_MOTIVO: refFormik.current.values.motivoId,
            VARIABLE_COMENTARIO: refFormik.current.values.comentario,
        }
        
        Garantias.creardatosenviar({
            modulo: 'Rechazo de cita de tarjeta de circulación',
            tipoCorreoId: 65,
            contenido: JSON.stringify(contenidoEmail)
        }).catch(err => {
            console.log("Error al enviar correo Rechazo de cita de tarjeta de circulación:", err);
        })
    }



    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initial}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación debes capturar el motivo de cancelación de cita
                                    </small>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-6">
                                        <SelectField
                                            id="motivoId"
                                            label="Motivo cancelación"
                                            name="motivoId"
                                            items={motivos}
                                            onChange={(event) => {
                                                setFieldValue("motivoId", event.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <TextAreaField
                                            label="Comentarios"
                                            className="form-control col-12"
                                            rows="3"
                                            name="comentario"
                                            id="comentario"
                                            type="text"
                                            holder="Escribe"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='d-flex justify-content-end'>
                            <div className="row">
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-outline d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            handleRegresar();
                                        }}
                                    >
                                        Regresar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            handleCancelar();
                                        }}
                                    >
                                        Cancelar Cita
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default RechazaCitaModal;
