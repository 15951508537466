import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../Utils/functions'
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import DataTable from '../../../../Components/datatable/DataTable'
import ModalCostos from './ModalCostos'
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';
import ModalPagos from './ModalPagos';

const RegistrosPagosTable = ({ submitButton }) => {
    const { id } = useParams();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState(false);
    const [totalPagos, setTotalPagos] = useState(0);
    const [initialValue, setInitialValue] = useState();
    const [data, setData] = useState([]);

    const columnsPagos = [
        { field: 'id', headerName: '#' },
        { field: 'fechaAlta', headerName: 'Fecha y hora' },
        { field: 'usuarioAltaStr', headerName: 'Ejecutivo' },
        { field: 'notas', headerName: 'Notas' },
        { field: 'metodoPago', headerName: 'Metodo de pago' },
        { field: 'monto', headerName: 'Monto' },
        { field: 'recibo', headerName: 'Recibo' }
    ];

    useEffect(() => {
        getAll()
    }, [])

    const getAll = async () => {
        setLoading(true);
        DSystemsServicios.getServicioCostosAdicionalesPago(id)
            .then(res => {
                const updatedData = res.data.map((item, index) => ({
                    ...item,
                    id: index + 1
                }));
                const totalPagos = updatedData.reduce((sum, item) => sum + (item.monto || 0), 0);

                setData(updatedData);
                setTotalPagos(totalPagos);

            })
            .catch(error => {
                console.error("Error al obtener los datos:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleEdit = (item) => {
        console.log(item, "-----")
        setSelectedItem({ ...item });
        setIsOpenModal(true)
    }

    const handleDelete = (item) => {
        setLoading(true);
        DSystemsServicios.deleteServicioCostosAdicionalesPago(item.servicioCostosAdicionalesPagoId)
            .then(res => {
                getAll()
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className="row mt-2 mb-2 pt-4">
                <div className="col-auto">
                    <small><strong>costos adicionales</strong></small>
                </div>
                <div className="col-auto">
                    <fieldset
                        className='btn'
                        onClick={() => { setIsOpenModal(true); setSelectedItem({}) }}>
                        Registrar pago
                    </fieldset>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-12">
                    <DataTable
                        column={columnsPagos}
                        data={data}
                        editable={true}
                        handleEdit={handleEdit}
                        deleteable={true}
                        handleDelete={handleDelete}
                    />
                </div>
            </div>
            <div className="row mt-2 px-4 mb-2 justify-content-end">
                <div className="col-auto">
                    <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                </div>
            </div>
            {
                isOpenModal &&
                <ModalPagos
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={selectedItem}
                    valuesInit={initialValue}
                    handleGetAll={getAll}
                    toast={toast}
                />
            }
        </>
    );
};

export default RegistrosPagosTable;
