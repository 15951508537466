import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    adeudos: "",
    pagos: "",
    pendiente: "",
}

export const BalanceSlice = createSlice({
    name: 'BalanceSlice',
    initialState,
    reducers: {
        setBalance: (state, action) => {
            console.log(action)
            return {
                ...state,
                adeudos: action.payload.adeudos,
                pagos: action.payload.pagos,
                pendiente: action.payload.pendiente,
            }
        },
        clearBalance: (state) => {
            return {
                ...state,
                adeudos: "",
                pagos: "",
                pendiente: ""
            }
        }
    }
})

export const { setBalance, clearBalance } = BalanceSlice.actions

export default BalanceSlice.reducer