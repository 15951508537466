import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    vin: "",
    anombre: "",
    marca: "",
    modelo: "",
    version: "",
    anio: "",
    placas: "",
    color: "",
    kilometraje: "",
    procedencia: "",
    numMotor: "",
    fechaEntrega: "",
    numContrato: "",
    cliente: "",
    telefono: "",
    correo: "",
    producto: "",
    usoUnidad: "",
    fechaUltimoPago: "",
    fechaLiquidacion: ""
}

export const GestoriaEncabezadoSlice = createSlice({
    name: 'GestoriaEncabezadoSlice',
    initialState,
    reducers: {
        setEncabezado: (state, action) => {
            return {
                ...state,
                vin: action.payload.vin,
                anombre: action.payload.anombre,
                marca: action.payload.marca,
                modelo: action.payload.modelo,
                version: action.payload.version,
                anio: action.payload.anio,
                placas: action.payload.placas,
                color: action.payload.color,
                kilometraje: action.payload.kilometraje,
                numMotor: action.payload.numMotor,
                fechaEntrega: action.payload.fechaEntrega,
                numContrato: action.payload.numContrato,
                cliente: action.payload.cliente,
                telefono: action.payload.telefono,
                correo: action.payload.correo,
                producto: action.payload.producto,
                usoUnidad: action.payload.usoUnidad,
                fechaUltimoPago: action.payload.fechaUltimoPago,
                fechaLiquidacion: action.payload.fechaLiquidacion,
            }
        },
        setContrato: (state, action) => {
            return {
                ...state,
                procedencia: action.payload.procedencia,
            }
        },
        setCredito: (state, action) => {
            return {
                ...state,
                procedencia: action.payload.procedencia,
            }
        },
        clearEncabezado: (state) => {
            return {
                ...state,
                vin: "",
                anombre: "",
                marca: "",
                modelo: "",
                version: "",
                anio: "",
                placas: "",
                color: "",
                kilometraje: "",
                procedencia: "",
                numMotor: "",
                fechaEntrega: "",
                numContrato: "",
                cliente: "",
                telefono: "",
                correo: "",
                producto: "",
                usoUnidad: "",
                fechaUltimoPago: "",
                fechaLiquidacion: ""
            }
        }
    }
})

export const { setEncabezado, setContrato, clearEncabezado } = GestoriaEncabezadoSlice.actions

export default GestoriaEncabezadoSlice.reducer