import React from 'react'
import img from '../../Assets/img/resguardo.png';

const ProcesosHome = () => {

  return (
    <>
    <section >
        <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'70vh'}}>
            <div className='row'>
                <div className='text-center'>
                  <img src={img} style={{width:'20rem'}} className='img-fluid'/>
                  <h5 className='mt-4 mb-4'>Proceso de cierre</h5>
                  <p>Aquí podrás encontrar las herramientas para darle seguimiento a los procesos de cierre para <br/> comenzar, selecciona una opción del menú.</p>
                </div>
            </div>       
        </div>
    </section>
    </>
  )
}

export default ProcesosHome