import axios from '../../Utils/ApiService';

const Cierres = {
    /* SEGUIMIENTO RESGUARDOS */
    seguimientoResguardos: (data) => {
        return axios.post(`/cartera/getSeguimientoResguardo`, data);
    },
    /* PREALTA DE VEHÍCULOS CON VIN NO ENCONTRADO */
    crearPrealta: (data) => {
        return axios.post(`/cartera/resguardoPrealtaCrear`, data);
    },
    /* CREAR RESGUARDO */
    crearResguardo: (data) => {
        return axios.post(`/cartera/resguardoCrear`, data);
    },
    /* OBTENER RESGUARDO */
    obtenerResguardo: (data) => {
        return axios.get(`/cartera/resguardoGet?generalId=${data}`);
    },
    /* OBTENER UBICACION UNIDAD */
    resguardoUbicacionUnidadGet: (data) => {
        return axios.get(`/cartera/resguardoUbicacionUnidadGet?resguardoId=${data}`);
    },
    /* CREAR UBICACION UNIDAD */
    resguardoUbicacionUnidadCrear: (data) => {
        return axios.post(`/cartera/resguardoUbicacionUnidadCrear`, data);
    },
    /* ACTUALIZAR UBICACION UNIDAD */
    resguardoUbicacionUnidadActualizar: (data) => {
        return axios.put(`/cartera/resguardoUbicacionUnidadActualizar`, data);
    },
    /* CREAR REVISION UNIDAD  */
    resguardoVisitaRevisionUnidadCrear: (data) => {
        return axios.post(`cartera/ResguardoVisitaRevisionUnidadCrear`, data);
    },
    /* OBTENER REVISION UNIDAD  */
    resguardoVisitaRevisionUnidadGet: (data) => {
        return axios.get(`cartera/ResguardoVisitaRevisionUnidadGet?resguardoId=${data}`);
    },
    /* ACTUALIZAR REVISION UNIDAD  */
    resguardoVisitaRevisionUnidadActualizar: (data) => {
        return axios.put(`cartera/ResguardoVisitaRevisionUnidadActualizar`, data);
    },
    /* CREAR DETALLE REVISION UNIDAD */
    resguardoVisitaRevisionUnidadDetalleCrear: (data) => {
        return axios.post(`cartera/ResguardoVisitaRevisionUnidadDetalleCrear`, data);
    },
    /* OBTENER DETALLE REVISION UNIDAD */
    resguardoVisitaRevisionUnidadDetalleGet: (data) => {
        return axios.get(`cartera/ResguardoVisitaRevisionUnidadDetalleGet?resguardoVisitaRevisionUnidadDetalleId=${data}`);
    },
    /* ACTUALIZAR DETALLE REVISION UNIDAD */
    resguardoVisitaRevisionUnidadDetalleActualizar: (data) => {
        return axios.put(`cartera/ResguardoVisitaRevisionUnidadDetalleActualizar`, data);
    },
    /* CREAR RESGUARDO UBICACIÓN */
    resguardoUbicacionCrear: (data) => {
        return axios.post(`cartera/resguardoUbicacionCrear`, data);
    },
    /* OBTENER RESGUARDO UBICACIÓN */
    resguardoUbicacionGet: (data) => {
        return axios.get(`cartera/resguardoUbicacionGet?resguardoId=${data}`);
    },
    /* ACTUALIZAR RESGUARDO UBICACIÓN */
    resguardoUbicacionActualizar: (data) => {
        return axios.put(`cartera/resguardoUbicacionActualizar`, data);
    },
    /* SEGUIMIENTO CIERRES */
    seguimientoCierres: (data) => {
        return axios.post(`/cartera/getSeguimientoCierreContrato`, data);
    },
    /* CREAR CIERRES */
    cierreContratoCrear: (data) => {
        return axios.post(`/cartera/CierreContratoCrear`, data);
    },
    /* OBTENER CIERRES */
    cierreContratoGet: (data) => {
        return axios.get(`/cartera/cierreContratoGet?cierreContratoId=${data}`);
    },
    /* ACTUALIZAR CIERRES */
    cierreContratoActualizar: (data) => {
        return axios.put(`/cartera/CierreContratoActualizar`, data);
    },
    /* CREAR ADEDUOS */
    cierreContratoAdeudoCrear: (data) => {
        return axios.post(`/cartera/cierreContratoAdeudoCrear`, data);
    },
    /* OBTENER ADEDUOS */
    cierreContratoAdeudoGet: (data) => {
        return axios.get(`/cartera/cierreContratoAdeudoGet?cierreContratoId=${data}`);
    },
    /* ACTUALIZAR ADEDUOS */
    cierreContratoAdeudoActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoAdeudoActualizar`, data);
    },
    /* CREAR DETALLE ADEDUOS */
    cierreContratoAdeudoDetalleCrear: (data) => {
        return axios.post(`/cartera/cierreContratoAdeudoDetalleCrear`, data);
    },
    /* OBTENER DETALLE ADEDUOS */
    cierreContratoAdeudoDetalleGet: (data) => {
        return axios.get(`/cartera/cierreContratoAdeudoDetalleGet?cierreContratoAdeudoDetalleId=${data}`);
    },
    /* ACTUALIZAR DETALLE ADEDUOS */
    cierreContratoAdeudoDetalleActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoAdeudoDetalleActualizar`, data);
    },
    /* PARAMETROS CARTERA */
    parametroCarteraByClaveGet: (data) => {
        return axios.get(`/cartera/parametroCarteraByClaveGet?clave=${data}`);
    },
    /* CREAR CONTRATO NOTIFICACION */
    cierreContratoNotificacionCrear: (data) => {
        return axios.post(`/cartera/cierreContratoNotificacionCrear`, data);
    },
    /* OBTENER CONTRATO NOTIFICACION */
    cierreContratoNotificacionGet: (data) => {
        return axios.get(`/cartera/cierreContratoNotificacionGet?cierreContratoId=${data}`);
    },
    /* ACTUALIZAR CONTRATO NOTIFICACION */
    cierreContratoNotificacionActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoNotificacionActualizar`, data);
    },
    /* CREAR CONTRATO CITA */
    cierreContratoCitaCrear: (data) => {
        return axios.post(`/cartera/cierreContratoCitaCrear`, data);
    },
    /* OBTENER CONTRATO CITA */
    cierreContratoCitaGet: (data) => {
        return axios.get(`/cartera/cierreContratoCitaGet?cierreContratoId=${data}`);
    },
    /* ACTUALIZAR CONTRATO CITA */
    cierreContratoCitaActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoCitaActualizar`, data);
    },
    /* CREAR CONTRATO FIRMA */
    cierreContratoFirmaCrear: (data) => {
        return axios.post(`/cartera/cierreContratoFirmaCrear`, data);
    },
    /* OBTENER CONTRATO FIRMA */
    cierreContratoFirmaGet: (data) => {
        return axios.get(`/cartera/cierreContratoFirmaGet?cierreContratoId=${data}`);
    },
    /* ACTUALIZAR CONTRATO FIRMA */
    cierreContratoFirmaActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoFirmaActualizar`, data);
    },
    /*** CierreContratoCambioPropietario ***/
    /* CREAR CAMBIO PROPIETARIO */
    cierreContratoCambioPropietarioCrear: (data) => {
        return axios.post(`/cartera/cierreContratoCambioPropietarioCrear`, data);
    },
    /* ACTUALIZAR CAMBIO PROPIETARIO */
    cierreContratoCambioPropietarioActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoCambioPropietarioActualizar`, data);
    },
    /* OBTENER CAMBIO PROPIETARIO */
    cierreContratoCambioPropietarioGet: (data) => {
        return axios.get(`/cartera/cierreContratoCambioPropietarioGet?cierreContratoId=${data}`);
    },
    /*** CierreContratoArmadoExpediente ***/
    /* CREAR ARMADO EXPEDIENTE */
    cierreContratoArmadoExpedienteCrear: (data) => {
        return axios.post(`/cartera/cierreContratoArmadoExpedienteCrear`, data);
    },
    /* ACTUALIZAR ARMADO EXPEDIENTE */
    cierreContratoArmadoExpedienteActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoArmadoExpedienteActualizar`, data);
    },
    /* OBTENER ARMADO EXPEDIENTE */
    cierreContratoArmadoExpedienteGet: (data) => {
        return axios.get(`/cartera/cierreContratoArmadoExpedienteGet?cierreContratoId=${data}`);
    },
    /*** CierreContratoCitaEntregaExpediente ***/
    /* CREAR CITA ENTREGA EXPEDIENTE */
    cierreContatoCitaEntregaExpedienteCrear: (data) => {
        return axios.post(`/cartera/cierreContratoCitaEntregaExpedienteCrear`, data);
    },
    /* ACTUALIZAR CITA ENTREGA EXPEDIENTE */
    cierreContatoCitaEntregaExpedienteAtualizar: (data) => {
        return axios.put(`/cartera/cierreContratoCitaEntregaExpedienteActualizar`, data);
    },
    /* OBTENER CITA ENTREGA EXPEDIENTE */
    cierreContatoCitaEntregaExpedienteGet: (data) => {
        return axios.get(`/cartera/cierreContratoCitaEntregaExpedienteGet?cierreContratoId=${data}`);
    },
    /*** CierreContratoEntregaExpediente ***/
    /* CREAR  ENTREGA EXPEDIENTE */
    cierreContatoEntregaExpedienteCrear: (data) => {
        return axios.post(`/cartera/cierreContratoEntregaExpedienteCrear`, data);
    },
    /* ACTUALIZAR  ENTREGA EXPEDIENTE */
    cierreContatoEntregaExpedienteAtualizar: (data) => {
        return axios.put(`/cartera/cierreContratoEntregaExpedienteActualizar`, data);
    },
    /* OBTENER  ENTREGA EXPEDIENTE */
    cierreContatoEntregaExpedienteGet: (data) => {
        return axios.get(`/cartera/cierreContratoEntregaExpedienteGet?cierreContratoId=${data}`);
    },
    /*** CierreContratoExpediente ***/
    /* CREAR EXPEDIENTE */
    cierreContratoExpedienteCrear: (data) => {
        return axios.post(`/cartera/cierreContratoExpedienteCrear`, data);
    },
    /* ACTUALIZAR EXPEDIENTE */
    cierreContatoExpedienteActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoExpedienteActualizar`, data);
    },
    /* OBTENER EXPEDIENTE */
    cierreContratoExpedienteGet: (data) => {
        return axios.get(`/cartera/cierreContratoExpedienteGet?cierreContratoId=${data}`)
    },
    /* OBTENER LOS CALCULOS DE CAMBIO/DEVOLUCION */
    cierreContratoAdeudosCambioDevolucionGet: (data) => {
        return axios.get(`/cartera/cierreContratoAdeudosCambioDevolucionGet?cierreContratoId=${data}`);
    },
    /* REGRESAR DE PROCESO DE FIRMA A CITA DE FIRMA */
    cierreContratoFirmaCancelar: (data) => {
        return axios.get(`/cartera/cierreContratoFirmaCancelar?cierreContratoId=${data}`);
    },
    /* OBTENER ANTECEDENTES DE CIERRE DE CONTRATO */
    getEstatusLiquidacion: (data) => {
        return axios.post(`/cartera/getEstatusLiquidacion`, data);
    }
}

export default Cierres