import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Cierres from '../../Services/Resguardos/Cierres';
import { SelectField } from '../SelectField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';

const VisitasModal = ({ modalMessage, setModalMessage, reload, resguardoVisitaRevisionUnidadId }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        resguardoVisitaRevisionUnidadDetalleId: "",
        resguardoVisitaRevisionUnidadId: resguardoVisitaRevisionUnidadId,
        resguardoVisitaRevisionUnidadResultadoId: null,
        fechaVisita: "",
        horaVisita: "",
        resguardoCondicionesUnidadId: null,
        costosAdicionales: "",
        status: true
    };


    const [data, setData] = useState({});
    const [initialValue, setInitialValue] = useState(initial);
    const [resultados, setResultados] = useState([]);
    const [condiciones, setCondiciones] = useState([]);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

    useEffect(() => {
        getResultados();
        geCondicionesUnidad();
    }, []);

    const getResultados = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ResguardoVisitaRevisionUnidadResultado')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.ResguardoVisitaRevisionUnidadResultadoId,
                    label: item.ResguardoVisitaRevisionUnidadResultado
                })
            });
            setResultados(items);
        })
        .catch(err => {
            console.error(err);
        });
    }
    
    const geCondicionesUnidad = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ResguardoCondicionesUnidad')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.ResguardoCondicionesUnidadId,
                    label: item.ResguardoCondicionesUnidad
                })
            });
            setCondiciones(items);
        })
        .catch(err => {
            console.error(err);
        });
    }

    const validate = Yup.object({
        fechaVisita: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        horaVisita: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        resguardoVisitaRevisionUnidadResultadoId: Yup.number()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        resguardoCondicionesUnidadId: Yup.number()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        costosAdicionales: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
    });

    const submitDetalle = async (values) => {

        if(values.fechaVisita === "" || values.fechaVisita === null) {
            toast.error(message("¡Error, El campo Fecha de visita es requerido!"));
            return false;
        }
        
        if(values.horaVisita === "" || values.horaVisita === null) {
            toast.error(message("¡Error, El campo Hora de visita es requerido!"));
            return false;
        }
        
        if(values.costosAdicionales === "" || values.costosAdicionales === null) {
            toast.error(message("¡Error, El campo Costo adicional es requerido!"));
            return false;
        }

        
        setLoading(true);
        await Cierres.resguardoVisitaRevisionUnidadDetalleCrear(values)
        .then(resp => {
            toast.success(message("Registro de visita creado correctamente"));
            refFormik.current.resetForm();
            setModalMessage({ isOpen: false })
            reload();
        })
        .catch(err => {
            console.error(err);
            toast.error(message("¡Error, al intentar crear la visita!"));
        })
        .finally(() => setLoading(false));
    }

    const onlyNumbers = (field, value, fn) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            fn(field, value);
        }
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <TextField id="fechaVisita" label="Fecha de visita" name="fechaVisita" type="date" holder="Escribe" onChange={(event) => {
                                            setFieldValue("fechaVisita", event.target.value);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <TextField id="horaVisita" label="Hora de visita" name="horaVisita" type="time" holder="Escribe" onChange={(event) => {
                                            setFieldValue("horaVisita", event.target.value);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <SelectField id="resguardoVisitaRevisionUnidadResultadoId"
                                            label="Resultado"
                                            disabled={false}
                                            name="resguardoVisitaRevisionUnidadResultadoId"
                                            items={resultados}
                                            onChange={(event) => {
                                                setFieldValue("resguardoVisitaRevisionUnidadResultadoId", event.value);
                                        }} />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-4">
                                        <SelectField id="resguardoCondicionesUnidadId"
                                            label="Condición de la unidad"
                                            disabled={false}
                                            name="resguardoCondicionesUnidadId"
                                            items={condiciones}
                                            onChange={(event) => {
                                                setFieldValue("resguardoCondicionesUnidadId", event.value);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <TextField id="costosAdicionales" label="Costos adicionales" name="costosAdicionales" type="text" holder="Escribe"
                                        
                                        onChange={(event) => {
                                            onlyNumbers("costosAdicionales", event.target.value, setFieldValue);
                                        }}/>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-end">
                                    <div className="col-auto mt-4">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-gray d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={(e) => { 
                                                setModalMessage({ ...modalMessage, isOpen: false });
                                                resetForm();
                                                }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto mt-4">
                                        <button
                                            id="AT_BtnCloseModal"
                                            disabled={!isValid}
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                submitDetalle(values);
                                            }}
                                        >
                                            Registrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default VisitasModal;
