import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import { SelectField } from '../../Components/SelectField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import Usuario from '../../Services/Users/Usuarios';

const DocumentoUsuarioModal = ({ isOpen, setIsOpen, item, handleGetAll, message, toast }) => {
    const [valor, setValor] = useState("");
    let initial = {
        tipoDocumentoId: item?.tipoDocumentoId ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataRol, setDataRol] = useState([]);
    const [dataDocuemento, setDataDocuemento] = useState([]);
    const [documentoId, setDocumentoId] = useState(item?.tipoDocumentoId ?? "");


    const validate = Yup.object({
        tipoDocumentoId: Yup.string()
            .required("El campo es requerido."),
    });

    useEffect(() => {
        handleGetAllDocumentos();
    }, [])

    useEffect(() => {
        if (documentoId !== "")
            getAllRol()
    }, [documentoId])


    const getAllRol = async () => {
        await Usuario.getRoleBydoc(documentoId)
            .then(res => {

                setDataRol(res.data.data);
            }).catch(e => {

            })
    }
    const handleGetAllDocumentos = async () => {
        await EtapaService.getAllDocumentos()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoDocumentoId,
                        label: item.nombre
                    })
                });
                setDataDocuemento(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Correcto.!", e.response.data.message));
                else
                    toast.error(message("¡Correcto.!", e.message));
            })
    }
    const handleAddEdit = async (data) => {
        if (initialValue.tipoDocumentoId !== "") {
            await VentaVehiculoService.UpdDocumentoRol(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else {
            await VentaVehiculoService.AddDocumentoRol(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }


    }
    const handleChanagestatus = (value, item,propName) => {
        setDataRol((current) =>
            current?.map((data) =>
                data.rolId === item.rolId
                    ? {
                        ...data,
                        [propName]: value
                    }
                    : data
            )
        );
    }    
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                // console.log({ ...values, roles: dataRol })
                handleAddEdit({ ...values, roles: dataRol });
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '40rem' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fs-5'><strong>Asignar documento a usuario</strong></span>
                                        <div className="mb-3">
                                            <div className="col-ms-6 form-check form-switch">
                                                <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                                <Field className="form-check-input" type="checkbox" name="status" />
                                            </div>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: '12px' }}>A continuación se presenta la configuración para asignar documentos por usuario.</span>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-sm-6 mb-2">
                                        <SelectField className="col-12" label="Documento:" name="tipoDocumentoId" items={dataDocuemento} onChange={(event) => {
                                            setFieldValue("tipoDocumentoId", event.value);
                                            setDocumentoId(event.value);
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-12 row mb-3">
                                        {/* <label style={{ fontSize: '13px' }}><strong>2. Seleccionar las etapas destino:</strong></label> */}
                                        <div className='tbl-scroll' style={{ overflowY: 'auto', height: '300px' }}>
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr className='custom-table'>
                                                        <th scope="col" style={{ width: '400px' }}>Rol</th>                                                        
                                                        <th scope="col" style={{ width: '150px' }}>Ver</th>
                                                        <th scope="col" style={{ width: '150px' }}>Reenviar</th>
                                                        <th scope="col" style={{ width: '150px' }}>Editar</th>
                                                        <th scope="col" style={{ width: '150px' }}>Agregar</th>
                                                        <th scope="col" style={{ width: '150px' }}>Eliminar</th>
                                                        <th scope="col" style={{ width: '150px' }}>Descarga</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataRol.length > 0 ?
                                                            dataRol?.map((itemx, index) => {
                                                                return <tr key={index}>
                                                                    <th scope="row">{itemx.nombre}</th>
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.ver} value={"ver"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td> 
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.reenviar} value={"reenviar"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td> 
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.editar} value={"editar"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td> 
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.agregar} value={"agregar"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td> 
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.eliminar} value={"eliminar"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td> 
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.descarga} value={"descarga"} onChange={(e) => { handleChanagestatus(e.target.checked, itemx,e.target.value) }} />
                                                                        </div>
                                                                    </td>                                                                    
                                                                </tr>
                                                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default DocumentoUsuarioModal