import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayCitaTarjetaCirculacion: "",
    hourCitaTarjetaCirculacion: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdCitaTarjetaCirculacion: "",
}

export const TarjetaCirculacionSlice = createSlice({
    name: 'TarjetaCirculacionSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayCitaTarjetaCirculacion: action.payload.day,
                hourCitaTarjetaCirculacion: action.payload.hour,
                calendarioIdCitaTarjetaCirculacion: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayCitaTarjetaCirculacion: "",
                hourCitaTarjetaCirculacion: "",
                calendarioIdCitaTarjetaCirculacion: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = TarjetaCirculacionSlice.actions

export default TarjetaCirculacionSlice.reducer