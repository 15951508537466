import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../../Components/datatable/DataTable'
import Spinner from '../../../../Components/Loadig'
import Modal from '../../../../Components/Modal/Modal'
import RowPages from '../../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../../Auth/Authorization'
import SubMenuComisionesInventario from '../SubMenuComisionesInventario'
import Select from 'react-select'
import HeaderCobroComisionExterno from './HeaderCobroComisionExterno'
import ModalDetalleCobroComisionesCobradas from './ModalDetalleCobroComisionesCobradas'
import useCobroComisionesExternoPagadas from '../../../../Hooks/Inventario/Comisiones/useCobroComisionesExternoPagadas'
const ComisionesExternasCobradas = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar,
        setItem,
        item,
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,
        handleGetCSRazonSocial,
        razonSocial       
    } = useCobroComisionesExternoPagadas(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuComisionesInventario />
            <HeaderCobroComisionExterno />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Comisiones externas cobradas</strong></span>
                    <div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesExternasCobradas, SubModule.ComisionesInventario)?.Descarga}>
                            <button className='btn' onClick={handleDownloadExcel}>Exportar excel</button>
                        </ValidatePermission>
                    </div>
                </div>
                <span className='mt-2'>El módulo corresponde a las comisiones externas que ya fueron cobradas a los intermediarios y proveedores externos.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesExternasCobradas, SubModule.ComisionesInventario)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Tipo de compra</label> */}
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value);handleGetCSRazonSocial(e.value) }} placeholder="Tipo compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Razon social</label> */}
                            <Select ref={selectRef} options={razonSocial} className='col-12' onChange={(e) => {setClaveRazonSocial(e.value)}} placeholder="Razon Social" />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label>Procedencia del cliente</label>
                            <Select options={ProcedCliente} className='col-12' onChange={(e) => { setValueProcedCliente(e.value) }} placeholder="Procedencia del cliente" />
                        </div>     */}
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        detailable={true}
                        handleDetail={(value) => { setItem(value);setopenModalTotales(true) }}
                    />
                </div>
            </ValidatePermission>
            {
                openModalTotales &&
                <ModalDetalleCobroComisionesCobradas
                    isOpen={openModalTotales}
                    setIsOpen={setopenModalTotales}
                    item={item}                 
                />
            }
        </>
    )
}

export default ComisionesExternasCobradas