import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import CardDevoluciones from './CardDevoluciones';
import CardCalculo from './CardCalculo';
import CardBoletinado from './CardBoletinado';
import CardVehiculo from './CardVehiculo';
import CardAntecedentes from './CardAntecedentes';
import useCambioDevolucion from '../../../Hooks/Garantias/useCambioDevolucion';

const DevolucionesInfo = ({}) => {
    
    const { generalId,fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();

    const { id } = useParams();

    let {
        loading,
        setModalMessage,
        modalMessage,
        vehiculo
    } = useCambioDevolucion();

    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/garantias/incidencias';
    }


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de incidencias</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <CardVehiculo data={vehiculo}/>
                        </div>
                        <div className="col-6 px-md-3">
                            <CardAntecedentes data={vehiculo}/>
                        </div>
                    </div>
                </div>
                <CardDevoluciones />
                <CardBoletinado />
            </section>

        </>)
}

export default DevolucionesInfo