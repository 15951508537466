import { Form, Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useState } from 'react';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { FormatNumberMoney, FormatNumberToDecimal } from '../../../Utils/functions';

const ModalCostosSeguro = ({ setisOpenModalcostos, setValue, value, isOpen, editable }) => {

    let initial = value.costos;
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const validate = Yup.object({
        primaNeta: Yup.string()
            .required("El campo es requerido."),
        financiamiento: Yup.string()
            .required("El campo es requerido."),
        iva: Yup.string()
            .required("El campo es requerido."),
        gastosExpedicion: Yup.string()
            .required("El campo es requerido."),
    })

    const handleCostos = (items, type) => {  
        let valor = 0;
        if (type === 1) {
            valor = parseFloat(items.primaNeta === "" ? 0 : FormatNumberToDecimal(items.primaNeta)) + parseFloat(items.financiamiento === "" ? 0 : FormatNumberToDecimal(items.financiamiento)) + parseFloat(items.gastosExpedicion === "" ? 0 : FormatNumberToDecimal(items.gastosExpedicion)) //- parseFloat(items.iva === "" ? 0 : items.iva)
        } else if (type === 2) {
            valor = parseFloat(items.primaNeta === "" ? 0 : FormatNumberToDecimal(items.primaNeta)) + parseFloat(items.financiamiento === "" ? 0 : FormatNumberToDecimal(items.financiamiento)) + parseFloat(items.gastosExpedicion === "" ? 0 : FormatNumberToDecimal(items.gastosExpedicion)) + parseFloat(items.iva === "" ? 0 : FormatNumberToDecimal(items.iva))
        }
        return valor;
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {

                setValue({ ...value, costo: values.total, costos: values })
                
                setisOpenModalcostos(false);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Form>
                        <section className='m-4' style={{ width: '40rem' }}>
                            <div className='d-flex flex-column fs-4'>
                                <span className='fs-5 mb-2'><strong>Precio de seguro</strong></span>
                                <span style={{ fontSize: '12px' }}>El registro de los precios permite llevar un control detallado del seguro asignado.</span>
                            </div>
                            <section>
                                <table className="table">
                                    <thead>
                                        <tr className='custom-table'>
                                            <th scope="col">Valor</th>
                                            <th scope="col">Total</th>
                                            {/* <th scope="col">Acciones</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className='tbl-custome'>
                                        <tr>
                                            <td>Prima neta</td>
                                            <td> <TextField icon={"bi bi-currency-dollar"} label="" disabled={editable} isMoney={true} name="primaNeta" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("primaNeta",FormatNumberMoney(event.target.value));
                                                setFieldValue("subTotal", handleCostos({ ...values, primaNeta:FormatNumberMoney(event.target.value) }, 1).toFixed(2));
                                                setFieldValue("total", handleCostos({ ...values, primaNeta: FormatNumberMoney(event.target.value) }, 2).toFixed(2));
                                            }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Financiamiento</td>
                                            <td> <TextField icon={"bi bi-currency-dollar"} label="" disabled={editable} isMoney={true} name="financiamiento" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("financiamiento", FormatNumberMoney(event.target.value));
                                                setFieldValue("subTotal", handleCostos({ ...values, financiamiento: FormatNumberMoney(event.target.value) }, 1).toFixed(2));
                                                setFieldValue("total", handleCostos({ ...values, financiamiento: FormatNumberMoney(event.target.value) }, 2).toFixed(2));
                                            }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Gasto de Expedición</td>
                                            <td> <TextField icon={"bi bi-currency-dollar"} label="" disabled={editable} isMoney={true} name="gastosExpedicion" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("gastosExpedicion", FormatNumberMoney(event.target.value));
                                                setFieldValue("subTotal", handleCostos({ ...values, gastosExpedicion: FormatNumberMoney(event.target.value)}, 1).toFixed(2));
                                                setFieldValue("total", handleCostos({ ...values, gastosExpedicion:FormatNumberMoney(event.target.value) }, 2).toFixed(2));
                                            }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>SubTotal</strong></td>
                                            <td> <TextField disabled icon={"bi bi-currency-dollar"} label="" name="subTotal" isMoney={true} type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("subTotal", handleCostos(values, 1));
                                            }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>IVA</strong></td>
                                            <td> <TextField icon={"bi bi-currency-dollar"} label="" disabled={editable} isMoney={true} name="iva" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("iva", FormatNumberMoney(event.target.value));
                                                setFieldValue("subTotal", handleCostos({ ...values, iva: FormatNumberMoney(event.target.value)}, 1).toFixed(2));
                                                setFieldValue("total", handleCostos({ ...values, iva: FormatNumberMoney(event.target.value) }, 2).toFixed(2));                                                
                                            }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Total</strong></td>
                                            <td> <TextField disabled icon={"bi bi-currency-dollar"} label="" name="total" isMoney={true} type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("total", handleCostos(values, 2));
                                            }} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setisOpenModalcostos(false) }}>Cancelar</fieldset>
                                <button className='btn mx-1 px-5' disabled={editable} >Aceptar</button>
                            </section>
                        </section>
                    </Form>
                </>
            )
            }
        </Formik >
    )
}

export default ModalCostosSeguro;

