import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { IsNullOrEmpty, message, pageRows } from '../../Utils/functions';
import { useParams } from 'react-router-dom';
import Siniestros from '../../Services/Siniestro/Siniestro';
import Parametros from '../../Services/Parametros/Parametros';



const PagoResguardoUnidadModal = ({ item, setIsOpenModalPago, isOpenModalPago, handleGetAll }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const { id, generalid, siniestroid } = useParams();
    const dispatch = useDispatch();

    const inicial = {
        siniestroResguardoUnidad_CostoAdicionalId: item?.siniestroResguardoUnidad_CostoAdicionalId ?? "",
        siniestroResguardoUnidadId: item.siniestroResguardoUnidadId ?? "",
        concepto: item?.concepto ?? "",
        subTotal: item?.subTotal ?? "",
        iva: item?.iva ?? "",
        total: item?.total ?? ""
    };

    const [initialValue, setInitialValue] = useState(inicial);
    const [ivaParams, setIvaParams] = useState(0);
    useEffect(() => {
        if (IsNullOrEmpty(item?.iva))
            getIva();
    }, [isOpenModalPago])

    const getIva = async () => {
        await Parametros.getTablesParamsList("dataware", 1, 1, "iva").then(res => {
            setInitialValue({
                ...initialValue,
                iva: res.data?.data[0].valor ?? ""
            })
            // setIvaParams(res.data?.data[0].valor ?? 0);
        })
    }
    const validate = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        subTotal: Yup.number()
            .required("El campo es requerido."),
        // iva: Yup.number()
        //     .required("El campo es requerido.")
        //     .positive("ingrese un valor mayor a cero.")
        //     .max(99, "El valor no debe ser mayor a 99."),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo ' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        total: Yup.number()
            .required("El campo es requerido.")
    });


    const handleAddEdit = async (values) => {
        if (values.siniestroResguardoUnidad_CostoAdicionalId !== "") {
            setLoading(true)
            await Siniestros.UpdSiniestroResguardoUnidadCosto(values)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        setIsOpenModalPago(false);
                        toast.success(message("¡correcto!", res.data.data.mensaje));
                    } else {
                        toast.error(message("Error!", res.data.data.mensaje));
                    }
                    setLoading(false)
                    handleGetAll(1);
                }).catch(e => {
                    setLoading(false)
                    setIsOpenModalPago(false);
                    if (e.response !== undefined)
                        toast.error(message("Error!", e.response.data.message));
                    else
                        toast.error(message("Error!", e.message));
                })
        } else {
            setLoading(true)
            await Siniestros.AddSiniestroResguardoUnidadCosto(values)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        setIsOpenModalPago(false);
                        toast.success(message("¡correcto!", res.data.data.mensaje));
                    } else {
                        toast.error(message("Error!", res.data.data.mensaje));
                    }
                    setLoading(false)
                    handleGetAll(1);
                }).catch(e => {
                    setLoading(false)
                    setIsOpenModalPago(false);
                    if (e.response !== undefined)
                        toast.error(message("Error!", e.response.data.message));
                    else
                        toast.error(message("Error!", e.message));
                })
        }
    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpenModalPago} color='#fff'>
                            <div>
                                {/* <Modal isOpen={isOpenModalAdeudo} color='transparent'><Spinner message={""} /></Modal> */}
                                <section className='d-flex justify-content-end'>
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => setIsOpenModalPago(false)}><i className="ri-close-fill"></i></button>
                                </section>
                                <Form>
                                    <div className="mb-3 row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <TextField label="Subtotal" name="subTotal" type="number" holder="Escribe" onChange={(event) => {
                                                setFieldValue("subTotal", event.target.value)
                                                let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                setFieldValue("total", total);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <TextField label="IVA (%)" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                                setFieldValue("iva", event.target.value);
                                                let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                                setFieldValue("total", total);
                                            }} />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <TextField label="Total" disabled name="total" type="number" holder="Escribe" />
                                        </div>
                                    </div>
                                    <section className='my-3'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpenModalPago(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2' type="submit" disabled={!(isValid)}>{initialValue.siniestroResguardoUnidad_CostoAdicionalId == "" ? "Guardar" : "Editar"}</button>
                                    </section>
                                </Form>
                            </div>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>

                )}
            </Formik>
        </>
    );
}

export default PagoResguardoUnidadModal;
