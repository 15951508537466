import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';

const RepresentanteLegal = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {
    const refbtn = useRef();
    let initial = {
        representanteLegalId: item?.representanteLegalId ?? "",
        // empresaId: item?.empresaId ?? "",
        primerNombre: item?.primerNombre ?? "",
        segundoNombre: item?.segundoNombre ?? "",
        apellidoPaterno: item?.apellidoPaterno ?? "",
        apellidoMaterno: item?.apellidoMaterno ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);

    useEffect(() => {
        handleGetAllList()
    }, [])



    const validate = Yup.object({
        // empresaId: Yup.string()
        //     .required("El campo es requerido."),
        primerNombre: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        // segundoNombre: Yup.string()
        //     .trim("El texto no debe empezar ni terminar con espacios")
        //     .strict(true)
        //     .matches(
        //        "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
        //         "La información ingresada es incorrecta."
        //     )
        //     .matches(
        //         "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
        //         "solo debe existir un espacio entre cada palabra."
        //     )
        //     .required("El campo es requerido."),
        apellidoPaterno: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        apellidoMaterno: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
               "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido.")
    });
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("Empresa", 1, 100)
            .then(res => {
                
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.empresaId,
                        label: item.empresa
                    })
                });
                setData(NewData);
            }).catch(e => {
                
            })
    }
    const cleanString = (str = "") => {
        str = str.replace(/[^a-zA-Z0-9 ]/g, "");
        return str.toLowerCase().trim();
    };
    const [errorMessage, setErrorMessage] = useState("");
    const handleAddEdit = async (item) => {
        if (existingData.find(x=>
                cleanString(x.primerNombre) === cleanString(item.primerNombre) &&
                cleanString(x.segundoNombre) === cleanString(item.segundoNombre) &&
                cleanString(x.apellidoPaterno) === cleanString(item.apellidoPaterno) &&
                cleanString(x.apellidoMaterno) === cleanString(item.apellidoMaterno)
            )) {
            setErrorMessage("El represenante legal ya existe.");
            return;
        }
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addRepresentanteLegal(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateRepresentanteLegal(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values,setFieldValue }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '50rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3 row">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="PrimerNombre" label="Primer Nombre" name="primerNombre" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="SegundoNombre" label="Segundo Nombre" name="segundoNombre" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField id="ApellidoPaterno" label="Apellido Paterno" name="apellidoPaterno" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-4">
                                        <TextField id="ApellidoMaterno" label="Apellido Materno" name="apellidoMaterno" type="text" holder="Escribe" />
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <SelectField label="Empresa" name="empresaId" items={data} onChange={(event) => {                                         
                                            setFieldValue("empresaId",event.value)}} />
                                    </div> */}
                                </div>
                                <span className="text-danger">{errorMessage}</span>
                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default RepresentanteLegal