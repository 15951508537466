import { Formik, Form} from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';

const CambioRutaModal = ({ isOpen, setIsOpen, setConfirmar, tipoUsuario}) => {
   const [dataMotivosEC, setDatMotivosEC]= useState([]);
   const [dataMotivosJC, setDatMotivosJC]= useState([]);

   let initial = {
      motivo: '',
      comentarios: ''
   }
   const [initialValue, setInitialValue] = useState(initial);

   const validate = Yup.object({
      motivo: Yup.string()
         .required("El campo es requerido."),
   });

   const handleGetAllMotivoEC = async () => {
      let NewData = []
      setLoading(true);
      await CobranzaService.GetAllMotivosEliminacionContratoEC()
         .then(res=>{
            if(res?.data?.callSucceded){
            res?.data?.responseData?.map((item) => {
               if(item?.status){
                  NewData.push({
                     value: item?.id,
                     label: item?.motivo
                  })
               }
            })
            }
            setLoading(false);
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
         setDatMotivosEC(NewData);
   }

   const handleGetAllMotivoJC = async () => {
      let NewData = [{value:'test',label:"TEST"}]
      setLoading(true);
      await CobranzaService.GetAllMotivosEliminacionContratoJC()
         .then(res=>{
            if(res?.data?.callSucceded){
               res?.data?.responseData?.map(item => {
                  if(item?.status){
                     NewData.push({
                        value: item?.id,
                        label: item?.motivo
                     })
                  }
               })
            }
            setLoading(false);
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
         setDatMotivosJC(NewData);
   }

   useEffect(()=>{
      if(tipoUsuario === 1){
         handleGetAllMotivoJC();
      }
      else{
         handleGetAllMotivoEC();
      }
   },[])

   const [loading, setLoading] = useState(false);

   const handleAdd = (setFieldValue,values) => {
      setConfirmar(values);
      setFieldValue('motivo', '')
      setFieldValue('comentarios', '')
      setIsOpen(false);
   }

   const handleCancel = (setFieldValue) => {
      setFieldValue('motivo', '')
      setFieldValue('comentarios', '')
      setIsOpen(false);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         // onSubmit={(values) => {
         //    // handleAdd(setFieldValue,values);
         // }}
      >{
         ({ isValid, values, setFieldValue }) => (
      <>
         <Modal isOpen={isOpen} color='#fff'>
            <Form>
            <section className='my-2 mx-4' style={{ width: '30rem' }}>
               <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Cambio de ruta</strong></span>
               {
                  tipoUsuario === 1 &&(
                     <SelectField 
                     label='Motivo'
                     name='motivo'
                     className='col-6 mt-3'
                     items={dataMotivosJC} 
                     onChange={(e) => {
                        if(e.value !== ''){
                           setFieldValue('motivo', e.label)
                        }
                        else{
                           setFieldValue('motivo', '')
                        }
                     }}/>
                  )
               }
               {
                  tipoUsuario === 2 &&(
                     <SelectField 
                        label='Motivo'
                        name='motivo'
                        className='col-6 mt-3'
                        items={dataMotivosEC} 
                        onChange={(e) => {
                           if(e.value !== ''){
                              setFieldValue('motivo', e.label)
                           }
                           else{
                              setFieldValue('motivo', '')
                           }
                     }}/>
                  )
               }
               <TextAreaField
                  label="Comentarios de ingreso a cartera:"
                  rows="4"
                  className='col-12 mt-3'
                  name="comentarios"
                  type="text"
                  holder="Escribe"
                  style={{
                     border: "none",
                     backgroundColor: "#f0f0f0",
                     resize: "none",
                  }}
               />
               <section className='d-flex justify-content-center mt-5'>
                  <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' 
                     onClick={() => { 
                        handleCancel(setFieldValue)
                     }}>Cancelar</button>
                  <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type="button" disabled={!(isValid) || (values.motivo === '')} 
                     onClick={() => {
                        handleAdd(setFieldValue, values)
                     }}>Confirmar</button>
               </section>
            </section>
            </Form>
         </Modal>
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
      </>
      )
   }
   </Formik>
   )
}

export default CambioRutaModal