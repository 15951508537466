import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Formik } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import { TextAreaField } from '../TextAreaField';
import { message } from '../../Utils/functions';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import * as Yup from 'yup';

const SolicitudVisitaModal = ({ isOpen, setIsOpen, data, toast,onReload }) => {
  const refFormik = useRef();
  const [resultado, setResultado] = useState('');
  const [motivoItems, setmotivoItems] = useState([]);


  const handleRechazar = () => {
    setIsOpen(false);
  };

  const validationSchema = Yup.object({
    resultado: Yup.number().required("Resultado es requerido"),

    motivo: Yup.string()
      .when('resultado', {
        is: "3", // Condition to check
        then: Yup.string().required('Motivo es requerido'),
      }),

    message: Yup.string().required("Comentario es requerido")

  })

  const handlerGuardar = async (values) => {
    // Verifica que data no sea undefined
    if (!data) {
      console.error("No se han proporcionado datos para el modal.");
      return;
    }

    // Cambia el nombre del objeto `data` dentro de esta función para evitar sobrescribir
    let requestData = {
      "anyObject": {
        id:data.id,
        contrato: data.contrato,
        id_contacto_ascendes: data.id_contacto_ascendes,
        cobrador: data.cobrador,
        contacto: data.contacto,
        nombre: data.nombre,
        valor: data.valor,
        operacion_id: data.operacion_id,
        operacion_resultado_id: data.operacion_resultado_id,
        comentario_resultado: data.comentario_resultado,
        aplica_visita: values.resultado,
        motivo_visita_id: data.motivo_visita_id,
        comentario_visita: data.comentario_visita,
        evidencia: data.evidencia,
        motivo_ingreso_cartera_id: data.motivo_ingreso_cartera_id,
        comentario_ingreso_cartera: data.comentario_ingreso_cartera,
        cobrador_id: data.cobrador_id,
        estatus:0,
        comentario_solicitud:null,
        promesa_pago_id: data.promesa_pago_id,
        motivo_eliminacion_id: values.motivo, // Valor de Formik
        comentario_eliminacion: values.message,
        dias_vencidos:0,
        monto_adeudo:0,
        id_referencia_ascendes:data.id_referencia_ascendes,
        ubicacion:null,
        fecha_de_cita:null,
        proceso:null
         // Valor de Formik
      }
    };
    let response1 = {
      contrato: data.contrato
    };

    const response = await CobranzaService.createContactoSeguimientoLog(requestData);
    if (response.status === 200) {

      const successMessage = response.data?.mensaje || "¡Operación exitosa!";

      toast.success(successMessage);
      const responseFixedId = await CobranzaService.actualizaVisitabyContrato(response1);

      if (responseFixedId.status === 200) {
        console.log("Datos enviados exitosamente .");
      } else {
        console.error("Error al enviar datos:", responseFixedId);
      }

      setIsOpen(false); // Cierra el modal si todo va bien
      if (onReload) {
        onReload();
      }
    } else {
      console.error('Error al enviar los datos:', response);
    }
  };

  const HandleGetMotivos = async () => {
    setmotivoItems([]);
    try {
      const res = await CobranzaService.GetMotivoEliminacion();
      const motivos = res.data.responseData?.map(item => ({
        value: item.id,
        label: item.nombre,
      })) || [];
      setmotivoItems(motivos);
    } catch (error) {
      console.error('Error al cargar motivos:', error);
    }
  };

  // useEffect para llamar a HandleGetMotivos cuando el resultado cambie
  useEffect(() => {
    if (resultado === "3") { // Si se selecciona "Rechazar"
      HandleGetMotivos(); // Carga los motivos
    } else {
      setmotivoItems([]); // Limpiamos los motivos si no es "Rechazar"
    }
  }, [resultado]); // Se ejecuta cuando cambia el valor de resultado

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={{
          resultado: '', // Valor inicial de 'resultado'
          motivo: '', // Agrega motivo al initialValues
          message: '' // Agrega message al initialValues
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlerGuardar(values); // Envia los datos al servicio al confirmar
        }}
      >
        {({ setFieldValue, handleSubmit, isValid }) => (
          <Modal isOpen={isOpen} color='#fff' width={500}>
            <div>
              <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                  <div className="col col-12 dias-festivos-modal-underline">
                    <div>
                      <h4><strong>Solicitud de visita</strong></h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-6 mt-4">
                    <SelectField
                      id="resultado"
                      label="Resultado"
                      name="resultado"
                      items={[
                        { label: "Aprobar", value: "2" },
                        { label: "Rechazar", value: "3" }
                      ]}
                      onChange={(option) => {
                        setResultado(option.value); // Actualiza el estado de resultado
                        setFieldValue("resultado", option.value);
                      }}
                    />
                  </div>
                  {resultado === "3" && (
                    <div className="col-lg-6 col-6 mt-4">
                      <SelectField
                        id="motivo"
                        label="Motivo"
                        name="motivo"
                        items={motivoItems} // Items cargados desde el servicio
                        onChange={(option) => {
                          setFieldValue("motivo", option.value); // Actualiza el valor del Formik
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-12 mt-4" style={{ display: 'flex', flexFlow: 'column' }}>
                  <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribir" />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnCloseModal"
                      className='btn btn-outline btn-block'
                      onClick={handleRechazar}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnConfirmModal"
                      className='btn btn-blue btn-block'
                      onClick={handleSubmit}
                      disabled={!(isValid)}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default SolicitudVisitaModal;
