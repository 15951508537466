import React, { useState } from 'react';
import Calendar, { MonthView } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ReactCalendar.css'

function ReactCalendar({
    title,  //Titulo o Nombre que lleva arriba del calendario.
    onChange,  //Funcion Onchange, que hace con cada clic, lo utilizo para saber el active
    value, // Valor de la fecha activa
    formatDate,  //Funcion que le da formato a los dias del Encabezado
    fechaInicial, //Fecha que se mostrara al inicio
    tileClassName, //Funcion que cambia el css o clase de los recuadros. 
    toggleDate, //funcion que guarda o elimina la fecha del array
}) {

    return (
        <>
            <span className={"react-calendar-title"}>{title}</span>
            <Calendar
                onChange={onChange}

                formatShortWeekday={(locale, date) => formatDate(date, "d")}
                value={value}
                activeStartDate={fechaInicial}

                next2Label={null}
                nextLabel={null}
                prevLabel={null}
                prev2Label={null}

                tileClassName={tileClassName}
                onClickDay={(value, event) => toggleDate(value, event)}

                showNeighboringMonth={false}
                showNavigation={false}
            />
        </>
    );
}

export default ReactCalendar 