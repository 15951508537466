import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../Utils/functions';
import CancelacionSeguroService from '../../Services/Seguros/CancelacionSeguroService';
import ComisionesService from '../../Services/Seguros/ComisionesService';

const usePolizasVigentes = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'numeroPoliza', headerName: 'Póliza', width: 150 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 150 },
        { field: 'agenteSeguro', headerName: 'Agente de seguro', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso Unidad', width: 100 },
        // { field: 'usoUnidad', headerName: 'Tipo', width: 100 },
        { field: 'nombreMarca', headerName: 'Marca', width: 100 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'strFechaInicio', headerName: 'Fecha Inicio', width: 100 },
        { field: 'precioStr', headerName: 'Precio', width: 150 },
        { field: 'estatus', headerName: 'Estatus', width: 150 },
        { field: 'condicionPoliza', headerName: 'Condición de Póliza', width: 100 },
    ];
    let dataCondicionPoliza = [
        { label: "Todos", value: "" },
        { label: "Vigente", value: 1 },
        // { label: "Cancelada", value: 0 },
        { label: "Vencida", value: 0 },
    ];

    let dataEstatus = [
        { label: "Todos", value: "" },
        { label: "Completa", value: 1 },
        { label: "Cotización", value: 2 },
        { label: "Cotizada", value: 3 },
        { label: "Emitida", value: 4 },
        { label: "Pagada", value: 5 },
        { label: "Pendiente de comisión", value: 6 },
        { label: "Pendiente de pago", value: 7 },
        { lbel: "Por Renovar", value: 8 }
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [condicionPoliza, setcondicionPoliza] = useState("")
    const [estatus, setEstatus] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    //DataDocs
    const [openModalTotales, setopenModalTotales] = useState(false)
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [aseradoraLabel, SetAseradoraLabel] = useState("");
    const [IdsExportar, setIdsExportar] = useState([]);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
        setIdsExportar([]);
    }, [str, TipoCompra, claveUsoUnidad, pageRows, Aseguradora, condicionPoliza,estatus])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();
        handleGetAllAseguradora();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await ComisionesService.getAllPolizasVigentesComision(page, pageRows, str, claveUsoUnidad, condicionPoliza, Aseguradora ?? "", "",estatus)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                if ((Aseguradora ?? "") !== "") {
                    handleIsActiveCheck(true);
                } else {
                    handleIsActiveCheck(false);
                }
            })
    }

    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleDetail = (value) => {
        navigate(`/seguro/seguro/segurofinal/${value.generalId}`, {
            state: { ...value, action: 2, polizavigente: true, desgloce: true },
        });
    }

    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsExportar(current => [...current, value]);
        } else {
            setIdsExportar(IdsExportar.filter(item => item !== value.generalId));
        }
    }
    const handleDownloadExcel = async () => {
        setLoading(true);
        await ComisionesService.ExportPolizasVigentesComision(str, claveUsoUnidad, condicionPoliza, Aseguradora ?? "", "",estatus)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `PolizasVigentes-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                if ((Aseguradora ?? "") !== "") {
                    handleIsActiveCheck(true);
                } else {
                    handleIsActiveCheck(false);
                }
            })
    }
    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        setAseguradora,
        DataAseguradora,
        handleDownloadExcel,
        selectRef,
        dataCondicionPoliza,
        setcondicionPoliza,
        handleDetail,
        dataEstatus,
        setEstatus
    }
}

export default usePolizasVigentes