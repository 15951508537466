import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import useVentaVehiculos from '../../../Hooks/Ventas/Prealta/useVentaVehiculos'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import VehiculoUtilitarioModal from '../ModalVehiculoutilitario'
import { useSelector } from 'react-redux'
import ModalClaveVehiculo from '../../Inventario/GestoriaVehiculos/ModalClaveVehiculo'
import RowPages from '../../../Components/RowPages'
import ModalConfirmVehiculoConvertido from '../../Inventario/VehiculoConvertido/ModalConfirmVehiculoConvertido'
import { ValidatePermission, ValidatePermissionById, validateManyPermissions } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const VehiculoPrealta = () => {
  const { item, isOpenModalClave, isOpenModalClaveCambioProd } = useSelector((state) => state.quickActionsSlice);
  const { columns,
    columnsH,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    habdleGetAll,
    setIsOpenModal,
    dataModal,
    IsOpenModal,
    handlePageClickH,
    currenPageH,
    dataUtilitario,
    setUtilitarioId,
    handleViewdocs,
    handlechekedItem,
    setpageRows,
    setClaveEtapa,
    dataEtapa,

    handleClavesDemo,
    clavesDemo,
  } = useVentaVehiculos(toast);

  const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />

      <section className='mx-3 py-4 d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <span className='fs-5 mx-4'><strong>Seguimiento de Vehículos</strong></span>
            <ValidatePermission
              isActive={ValidatePermissionById(2, SubModule.ClavesDemo)}
            >
              <div className="col-auto">
                <button className='btn px-4'
                  onClick={(e) => { handleClavesDemo(true) }}              >
                  Ver Claves
                </button>
              </div>
            </ValidatePermission>
          </div>
          <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil).Agregar}>
            <div>
              <button className='btn px-4' onClick={() => {
                navigate(`/inventario/vehiculo/prealta/0`, {
                  state: {
                    page: 'Vehiculo',
                    title: "Nuevo Vehiculo",
                    etapa: 0
                  },
                });
              }}>Nuevo vehículo</button>
            </div>
          </ValidatePermission>
        </div>
        <span className='mt-2'>El siguiente listado corresponde a los vehículos registrados y su etapa actual.</span>
      </section>
      <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil).Ver}>
        <section className='mx-4'>
          <div className='row col-12'>
            <div className="col-lg-3 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
              <Select options={dataTipoAdquisicion} className='col-12' onChange={(e) => { setTipoAd(e.value) }} placeholder="Tipo Compra" />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
              <Select options={dataEtapa} className='col-12' onChange={(e) => { setClaveEtapa(e.value) }} placeholder="Etapa" />
            </div>
          </div>
        </section>
        <RowPages
          setpageRows={setpageRows}
        />

        <div className='mx-4 table-responsive'>
          <DataTable
            column={columns}
            data={data}
            editable={ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil).Actualizar}
            handleEdit={handleEdit}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currenPage - 1}
            actions={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil))}
            type={2}
            checkbox={validateManyPermissions(["Agregar", "Actualizar", "Ver"], ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil))}
            handleCheck={handlechekedItem}
            handleAction={handleCreateFolder}
            handleDocuments={handleViewdocs}
            tagCovert={true}
          />
        </div>
      </ValidatePermission>
      {
        isOpenDocs &&
        <VehiculoUtilitarioModal
          isOpen={isOpenDocs}
          setIsOpen={setIsOpenDocs}
          item={dataVehiculo}
          handleGetAll={habdleGetAll}
          message={message}
          toast={toast}
        />
      }
      {
        IsOpenModal &&
        <Modal isOpen={IsOpenModal} color='#fff'>
          <section className='my-4 mx-4' style={{ width: '50rem' }}>
            <div className='d-flex justify-content-center fs-6'>
              <span className='fs-5'><strong>Historial Utilitario</strong></span>

            </div>

            <DataTable
              column={columnsH}
              data={dataModal}
              pageCount={pageCount}
              handlePageClick={handlePageClickH}
              currentPage={currenPageH - 1}
            />
            <section className='my-3 d-flex justify-content-end'>
              <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
              {/* <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button> */}
            </section>

          </section>
        </Modal>
      }
      {
        (isOpenModalClave || clavesDemo) &&
        <ModalClaveVehiculo
          isOpenModalClave={isOpenModalClave}
          item={item}
          demo={clavesDemo}
          filas={1}
          order={"desc"}
          handleClavesDemo={handleClavesDemo}
        />
      }
      {
        isOpenModalClaveCambioProd &&
        <ModalConfirmVehiculoConvertido
          toast={toast}
          isOpenModalClave={isOpenModalClaveCambioProd}
          item={item}
        />
      }
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

    </>
  )
}

export default VehiculoPrealta