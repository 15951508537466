import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import RevisionCard from './RevisionUnidad';

const UbicacionCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { resguardoid } = useParams();

        let initial = {
            resguardoUbicacionUnidadId: "",
            resguardoId: resguardoid,
            resguardoMotivoAbandonoId: "",
            comentarios: "",
            proveedorUnidad: "",
            telefonoFijo: "",
            telefonoMovil: "",
            telefonoTrabajo: "",
            otrosTelefonos: "",
            direccion: "",
            codigoPostal: "",
            coloniaId: "",
            colonia: "",
            municipioId: null,
            municipio: "",
            estadoId: null,
            estado: "",
            pais: "",
            resguardoUbicacionUnidadStatusId: null,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            fechaInicio: "",
            fechaConclusion: "",
            concluido: false,
            status: true,
            resguardoMotivoAbandono: "",
            resguardoUbicacionUnidadStatus: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [motivosAbandono, setMotivosAbandono] = useState([]);
        const [colonias, setColonias] = useState([]);
        const [showNext, setShowNext] = useState(false);

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getUbicacionResguardo();
            getMotivosAbandono();
        }, []);

        /* CONSULTAS */
        const getUbicacionResguardo = async () => {
            await Cierres.resguardoUbicacionUnidadGet(resguardoid)
            .then(resp => {
                if(resp.status === 200){
                    setInitialValue(resp.data);
                    if(resp.data.resguardoUbicacionUnidadStatusId === 30){
                        setShowNext(true);
                    }
                }
            })
            .catch(err => {
                console.error(err);
            });
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getMotivosAbandono = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.ResguardoMotivoAbandono')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.ResguardoMotivoAbandonoId,
                        label: item.ResguardoMotivoAbandono
                    });
                });

                setMotivosAbandono(items);
            })
            .catch(err => {
                console.error(err);
            });
        }

        const getDataFromCP = async (values, cp) => {
            if(cp.length === 0) return false;

            setLoading(true);
            await Catalogo.ubicacionPorCP(cp)
            .then(resp => {
                let data = resp.data[0];
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.coloniaId,
                        label: item.colonia
                    });
                });

                setInitialValue({
                    ...values,
                    municipioId: data.municipioId,
                    municipio: data.municipio,
                    estadoId: data.edoMexicoId,
                    estado: data.estado,
                });

                setColonias(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }



        const submitForm = async (values) => {
            if(values.resguardoUbicacionUnidadId.length > 0){
                actualizarUbicacion(values);
            }else{
                crearUbicacion(values);
            }
        }

        const crearUbicacion = async (values) => {
            values.resguardoUbicacionUnidadStatusId = 20;
            if(values.concluido){
                values.resguardoUbicacionUnidadStatusId = 30;
            }
            setLoading(true);
            await Cierres.resguardoUbicacionUnidadCrear(values)
            .then(resp => {
                const data = resp.data;
                setInitialValue({
                    ...initialValue,
                    resguardoUbicacionUnidadId: data.resguardoUbicacionUnidadId,
                    resguardoId: data.resguardoId,
                    resguardoMotivoAbandonoId: data.resguardoMotivoAbandonoId !== null ? data.resguardoMotivoAbandonoId : 0,
                    comentarios: data.comentarios,
                    proveedorUnidad: data.proveedorUnidad !== null ? data.proveedorUnidad : "",
                    telefonoFijo: data.telefonoFijo,
                    telefonoMovil: data.telefonoMovil,
                    telefonoTrabajo: data.telefonoTrabajo,
                    otrosTelefonos: data.otrosTelefonos,
                    direccion: data.direccion,
                    codigoPostal: data.codigoPostal,
                    coloniaId: data.coloniaId,
                    colonia: data.colonia,
                    municipioId: data.municipioId,
                    municipio: data.municipio,
                    estadoId: data.estadoId,
                    estado: data.estado,
                    pais: data.pais,
                    resguardoUbicacionUnidadStatusId: data.resguardoUbicacionUnidadStatusId,
                    fechaAlta: data.fechaAlta,
                    usuarioAlta: data.usuarioAlta,
                    usuarioActualiza: data.usuarioActualiza,
                    fechaActualiza: data.fechaActualiza,
                    fechaInicio: data.fechaInicio,
                    fechaConclusion: data.fechaConclusion,
                    concluido: data.concluido,
                    status: data.status,
                    resguardoMotivoAbandono: data.resguardoMotivoAbandono,
                    resguardoUbicacionUnidadStatus: data.resguardoUbicacionUnidadStatus,
                    usuarioAltaNombre: data.usuarioAltaNombre,
                    usuarioActualizaNombre: data.usuarioActualizaNombre
                });
                toast.success(message("Registro de Ubicación de unidad creado correctamente"));
                
                if(values.concluido){
                    crearVisitaRevision();
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar crear la ubicación de la unidad!"));
            })
            .finally(() => setLoading(false));
        }

        const actualizarUbicacion = async (values) => {
            values.resguardoUbicacionUnidadStatusId = 20;
            if(values.concluido){
                values.resguardoUbicacionUnidadStatusId = 30;
            }
            setLoading(true);
            await Cierres.resguardoUbicacionUnidadActualizar(values)
            .then(resp => {
                const data = resp.data;
                setInitialValue({
                    ...initialValue,
                    resguardoUbicacionUnidadId: data.resguardoUbicacionUnidadId,
                    resguardoId: data.resguardoId,
                    resguardoMotivoAbandonoId: data.resguardoMotivoAbandonoId,
                    comentarios: data.comentarios,
                    proveedorUnidad: data.proveedorUnidad,
                    telefonoFijo: data.telefonoFijo,
                    telefonoMovil: data.telefonoMovil,
                    telefonoTrabajo: data.telefonoTrabajo,
                    otrosTelefonos: data.otrosTelefonos,
                    direccion: data.direccion,
                    codigoPostal: data.codigoPostal,
                    coloniaId: data.coloniaId,
                    colonia: data.colonia,
                    municipioId: data.municipioId,
                    municipio: data.municipio,
                    estadoId: data.estadoId,
                    estado: data.estado,
                    pais: data.pais,
                    resguardoUbicacionUnidadStatusId: data.resguardoUbicacionUnidadStatusId,
                    fechaAlta: data.fechaAlta,
                    usuarioAlta: data.usuarioAlta,
                    usuarioActualiza: data.usuarioActualiza,
                    fechaActualiza: data.fechaActualiza,
                    fechaInicio: data.fechaInicio,
                    fechaConclusion: data.fechaConclusion,
                    concluido: data.concluido,
                    status: data.status,
                    resguardoMotivoAbandono: data.resguardoMotivoAbandono,
                    resguardoUbicacionUnidadStatus: data.resguardoUbicacionUnidadStatus,
                    usuarioAltaNombre: data.usuarioAltaNombre,
                    usuarioActualizaNombre: data.usuarioActualizaNombre
                });
                
                toast.success(message("Registro de Ubicación de unidad actualizado correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la ubicación de la unidad!"));
            })
            .finally(() => {
                if(values.concluido){
                    crearVisitaRevision();
                }
                setLoading(false)
            });
        }

        const crearVisitaRevision = async () => {
            let params = {
                resguardoId: resguardoid,
                resguardoVisitaRevisionUnidadStatusId: 10
            }

            await Cierres.resguardoVisitaRevisionUnidadCrear(params)
            .then(resp => {
                if(resp.data.resguardoId){

                }
                toast.success(message("Registro de la visita / revisión de la unidad creado correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la visita / revisión de la unidad!"));
            })
            .finally(() => {
                setTimeout(() => {
                    setShowNext(true);
                }, 1000);
            });

        }


        const validate = Yup.object({
            resguardoMotivoAbandonoId: Yup.number()
                .typeError("El campo es requerido.")
                .required("El campo es requerido."),
            proveedorUnidad: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido")
                .strict(true),
            telefonoFijo: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido")
                .strict(true),
            direccion: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido")
                .strict(true),
            codigoPostal: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido")
                .strict(true),
            coloniaId: Yup.number()
                .typeError("El campo es requerido.")
                .required("El campo es requerido."),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const onlyNumbers = (field, value, fn) => {
            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                fn(field, value);
            }
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseUbicacion" role="button" aria-expanded="false" aria-controls="collapseUbicacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.resguardoUbicacionUnidadStatusId === null ? 'semaforo semaforo-default' : ( initialValue.resguardoUbicacionUnidadStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Ubicación de unidad</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.resguardoUbicacionUnidadStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseUbicacion">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Ubicación de unidad</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={!isValid || initialValue.resguardoUbicacionUnidadStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="resguardoMotivoAbandonoId"
                                                                label="Motivo del abandono"
                                                                disabled={initialValue.resguardoUbicacionUnidadStatusId === 30}
                                                                name="resguardoMotivoAbandonoId"
                                                                items={motivosAbandono}
                                                                onChange={(event) => {
                                                                    setFieldValue("resguardoMotivoAbandonoId", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios del ejecutivo"
                                                                disabled={initialValue.resguardoUbicacionUnidadStatusId === 30}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="proveedorUnidad" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} label="Poseedor de la unidad" name="proveedorUnidad" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("proveedorUnidad", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="telefonoFijo" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} max={10} label="Teléfono fijo" name="telefonoFijo" type="text" holder="Escribe" onChange={(event) => {
                                                                onlyNumbers("telefonoFijo", event.target.value, setFieldValue);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="telefonoMovil" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} max={10} label="Teléfono móvil" name="telefonoMovil" type="text" holder="Escribe" onChange={(event) => {
                                                                onlyNumbers("telefonoMovil", event.target.value, setFieldValue);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="telefonoTrabajo" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} max={10} label="Teléfono de trabajo" name="telefonoTrabajo" type="text" holder="Escribe" onChange={(event) => {
                                                                onlyNumbers("telefonoTrabajo", event.target.value, setFieldValue);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="direccion" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} label="Calle y número" name="direccion" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("direccion", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="codigoPostal" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} label="Código postal" name="codigoPostal" type="text" holder="Escribe" 
                                                            onChange={(event) => {
                                                                setFieldValue("codigoPostal", event.target.value);
                                                            }}
                                                            onBlur={(event) => {
                                                                getDataFromCP(values, event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="pais" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} label="País" name="pais" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("pais", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="estado" disabled={true} label="Estado" name="estado" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("estado", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="municipio" disabled={true} label="Municipio" name="municipio" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("municipio", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="coloniaId"
                                                                label="Colonia"
                                                                disabled={initialValue.resguardoUbicacionUnidadStatusId === 30}
                                                                name="coloniaId"
                                                                items={colonias}
                                                                onChange={(event) => {
                                                                    setFieldValue("coloniaId", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.resguardoUbicacionUnidadStatusId === 30} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.resguardoUbicacionUnidadStatusId === 30 &&
                    showNext &&
                    <RevisionCard />
                }
            </>
        )
    })
export default UbicacionCard