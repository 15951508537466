import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../Utils/functions'
import Garantias from '../../Services/Cartera/Garantias';
import { setDevolucionSlice } from '../../Redux/Slice/Devoluciones/devolucionesSlice';
import Catalogo from '../../Services/Catalogos/Catalogo';

const AutorizaBoletinadoModal = ({ modalMessage, setModalMessage, getBoletinado, cambioDevolucionId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        cambioDevolucionBoletinadoStatusId: 50,
        CambioDevolucionBoletinadoMotivoAutorizacionId: "",
        comentariosAutorizacion: "",
        cambioDevolucionId
    };

    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        getMotivos();
    }, []);


    const validate = Yup.object().shape({
        CambioDevolucionBoletinadoMotivoAutorizacionId: Yup.string().required('Campo requerido'),
        comentariosAutorizacion: Yup.string()
    });

    const getMotivos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CambioDevolucionBoletinadoMotivoAutorizacion')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.CambioDevolucionBoletinadoMotivoAutorizacionId,
                    label: item.CambioDevolucionBoletinadoMotivoAutorizacion
                })
            });
            setMotivos(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const handleAutorizar = async (values) => {
        console.log(values);
        if(values.CambioDevolucionBoletinadoMotivoAutorizacionId === undefined){
            return false;
        }


        if(values.comentariosAutorizacion === undefined){
            return false;
        }

        setLoading(true);
        
        await Garantias.boletinadoActualizar(values)
        .then(resp => {
            dispatch(setDevolucionSlice(resp.data));
            toast.success(message("Cambio / Devolución autorizada correctamente"));
            getBoletinado();
        })
        .catch(err => {
            toast.error(message("¡Error al intentar autorizar el Cambio / Devolución!"));
        })
        .finally(() => {
            setModalMessage({ ...modalMessage, isOpen: false });
            setLoading(false);
        });
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initial}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        ¿Estás seguro de autorizar el <span style={{"color": "#CE2222"}}>cambio / devolución </span> del cliente seleccionado? <br/><br/>
                                        Este cambio impactará en el flujo de garantía de la unidad
                                    </small>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-6">
                                        <SelectField
                                            id="CambioDevolucionBoletinadoMotivoAutorizacionId"
                                            label="Motivo autorización"
                                            name="CambioDevolucionBoletinadoMotivoAutorizacionId"
                                            items={motivos}
                                            onChange={(event) => {
                                                setFieldValue("CambioDevolucionBoletinadoMotivoAutorizacionId", event.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <TextAreaField
                                            label="Comentarios"
                                            className="form-control col-12"
                                            rows="3"
                                            name="comentariosAutorizacion"
                                            id="comentariosAutorizacion"
                                            type="text"
                                            holder="Escribe"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='d-flex justify-content-end'>
                            <div className="row">
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-outline d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            setModalMessage({ ...modalMessage, isOpen: false })
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        id="AT_BtnCloseModal"
                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                        onClick={() => {
                                            handleAutorizar(values);
                                        }}
                                    >
                                        Autorizar
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default AutorizaBoletinadoModal;
