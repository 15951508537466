import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Llamada from '../../../Services/Cartera/Llamadas';

const useRevisionDatamovil = ({ handleConcluido, setRequiereCosto }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        servicioRevisionDatamovilId: "",
        servicioId: "",
        alteracionDatamovil: null,
        comentariosAlteracion: null,
        datamovilAlterado: null,
        equipoCompleto: null,
        folioId: null,
        formatoHojaId: null,
        funciona: null,
        requiereReparacion: null,
        teclado: null,
        bloqueador: null,
        gps: null,
        aplicaCobro: null,
        observaciones: null,
        ejecutivo: null,
        concluido: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaConcluido: null,
        fechaAlta: "",
        fechaActualiza: null
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getDataRevision();
    }, []);

    const getDataRevision = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioRevision(id)
            .then(res => {
                let resData = res.data
                setInitialValue({ ...initialValue, ...resData })
                handleConcluido({ concluido: resData.concluido })

                if (resData.alteracionDatamovil && resData.concluido) {
                    setRequiereCosto(true)
                }

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitForm = async (values) => {
        setLoading(true);
        DSystemsServicios.updateServicioRevision(values)
            .then(res => {
                if (values.alteracionDatamovil && values.concluido) {
                    setRequiereCosto(true)
                    submitCostoAdicional({ servicioId: values.servicioId })
                }
                // handleConcluido({ concluido: values.concluido })
                // setInitialValue(values)
                getDataRevision();
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitCostoAdicional = async (values) => {
        setLoading(true);
        DSystemsServicios.agregarServicioCostosAdicionales(values)
            .then(res => {

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm,
        opcionesBool
    };
};

export default useRevisionDatamovil;
