import React from 'react'
import img from '../Assets/img/PanelAdmin.svg'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Zoom } from 'react-awesome-reveal';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/admin', { replace: true })
  }, [])

  return (
    <>
      <Zoom>
        <section>
          <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '90vh' }}>
            <div className='row'>
              <div className='text-center'>
                <img src={img} style={{ width: '30rem' }} className='img-fluid' />
              </div>
              <div className='fs-4 text-center mb-3'>Panel administrativo</div>
              <div className='fs-6 text-center' >Aquí podrás configurar el funcionamiento de toda la <br /> plataforma, para comenzar, selecciona una opción del menú.</div>
            </div>

          </div>
        </section>
      </Zoom>
    </>
  )
}

export default Home