import React from 'react'
import { useEffect, useState } from 'react';
import VehiculoInfoCard from '../../../Components/VehiculoInfoCard';
import ContratoHeaderCard from '../../Seguro/Siniestros/ContratoHeaderCard';
import SeguimientoStepper from './Componentes/SeguimientoStepper'
import SeguimientoProcesoDatamovil from './Componentes/SeguimientoProcesoDatamovil';
import AgendarProcesoTramite from './InicioTramite/AgendarProcesoTramite';
import PreparacionPapeleria from './AgendarCita/PreparacionPapeleria';
import useSeguimientoGestoria from '../../../Hooks/Gestoria/seguimiento/useSeguimientoGestoria';
import toast from 'react-hot-toast';
import ModalConfirm from './Componentes/ModalConfirm';

const SeguimientoAgendarCita = () => {
    let {
        stepe,
        setStepe,
        handleClickTerminar,
        activebtncontinuar,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
    } = useSeguimientoGestoria(toast)
    return (
        <>
            <SeguimientoStepper
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={'7ab11f4d-cff7-48d7-9848-4c8b19c9dbc1'} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                <SeguimientoProcesoDatamovil />
                <PreparacionPapeleria handleConcluido={handleChangeStatusBtn} stepId={2} />
            </div>
            {
                modalConfirm &&
                <ModalConfirm
                    isOpen={modalConfirm}
                    setIsOpen={setModalConfirm}
                    title={"Seguimiento Gestoria"}
                    handleConfirm={handleClickTerminarProceso}
                />
            }
        </>
    )
}
export default SeguimientoAgendarCita