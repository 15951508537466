import React, { useState } from 'react'
import SubmenuCambioEtapa from './SubmenuCambioEtapa';
import MultiSelect from '../../Components/DropDown';
import DataTable from '../../Components/datatable/DataTable'
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';

const HistorialEtapa = () => {
    let options = [
        // { label: "Select All", value: "all" },
        { value: 1, label: "uno" },
        { value: 2, label: "dos" },
        { value: 3, label: "tres" },
        { value: 4, label: "cuatr0" }

    ];
    const columns = [
        { field: 'yearId', headerName: 'Fecha y hora', width: 230 },
        { field: 'year', headerName: 'Usuario', width: 230 },
        { field: 'interfaz', headerName: 'Etapa', width: 230 },
        { field: 'clave', headerName: 'Movimiento registrado', width: 200 },
        { field: 'status', headerName: 'Motivo', width: 130 },
    ];

    const [state, setState] = useState();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });

    const handleChange = value => {
        setState(value);
        
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <SubmenuCambioEtapa />
            <section className='mx-4 my-4 d-flex flex-column'>
                <span className='fs-5'><strong>Historial de cambios</strong></span>
                <span className='mt-2'>Configuración del proceso de seguimiento de los productos con sus distintas variantes resultantes al tipo de compra.</span>
            </section>
            <section className='mt-5 mx-4'>
                <div className='col-lg-4 col-sm-12 d-flex'>
                    <div className="col-6 mx-1  inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" />
                    </div>
                    <div className="col-6 mx-1 ">
                        <input type="date" className="form-control mb-4" placeholder="Buscar" />
                    </div>
                    <div className='col-6 d-flex'>
                        <MultiSelect
                            holder={"Seleccione"}
                            className={'col-12 mx-1'}
                            value={state}
                            options={options}
                            handleChange={handleChange}
                        />
                    </div>
                </div>               
                <DataTable
                    column={columns}
                    data={[]}
                    pageCount={1}
                    // handlePageClick={handlePageClick}
                    paginate={true}
                    currentPage={0}
                />
            </section>
        </>
    )
}

export default HistorialEtapa