import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {selectedValueComisionExterno } from '../../../../Redux/Slice/navigateSliceComisionesInventario';
import { ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section } from '../../../../Auth/Authorization';

const HeaderComisionExterno = () => {
    const navigate = useNavigate();
    const { itemCExterno } = useSelector((state) => state.tabNavigateComisionesInventario);
    const dispatch = useDispatch();    
    useEffect(() => {
        handleRedirectPage(itemCExterno);
    }, [itemCExterno])
    const handleSetItem = (value) => {
        dispatch(selectedValueComisionExterno(value));
      }
    const handleRedirectPage = (item) => {
        if (item === 1)
            navigate("/inventario/comisiones/comisionexterno/comisionespendientes");
        else if (item === 2)
            navigate("/inventario/comisiones/comisionexterno/comisionesprocesopago");
        else if (item === 3)
            navigate("/inventario/comisiones/comisionexterno/comisionespagadas");
        else if (item === 0)
            navigate("/inventario/comisiones/comisionexterno");
    }

    let dataSelect = [
      { label: "Seleccione estatus", value: 0, isActive: true },
      {
        label: "Comisiones pendientes",
        value: 1,
        isActive: ValidatePermissionById(3, Section.ComisionesPendientes),
      },
      {
        label: "Comisiones en proceso",
        value: 2,
        isActive: ValidatePermissionById(3, Section.PagoComisionesEnProceso),
      },
      {
        label: "Comisiones pagadas",
        value: 3,
        isActive: ValidatePermissionById(3, Section.ComisionesPagadasExterno),
      },
    ];
    return (
        <>            
            <section className='px-3 py-4 d-flex flex-column headerTab'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Comisión externos</strong></span>
                </div>
                <span className='mt-2'>A continuación selecciona el estatus correspondiente que deseas gestionar.</span> <br />

                <div className='col-lg-3 col-md-6 col-sm-12 px-1'>
                    <Select options={dataSelect.filter(x=>x.isActive)} defaultValue={dataSelect.filter(x=>x.value === itemCExterno)} className='col-12' onChange={(e) => { handleSetItem(e.value) }} placeholder="Listados" />
                </div>
            </section>
        </>
    )
}

export default HeaderComisionExterno