import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import EntregaExpedienteCard from './EntregaExpedienteCard';
import Llamada from '../../../Services/Cartera/Llamadas';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';

const CitaExpedienteCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();


        let initial = {
            cierreContratoCitaEntregaExpedienteId: "",
            cierreContratoId: "",
            cierreContratoCitaEntregaExpedienteStatusId: 0,
            cierreContratoCitaEntregaExpedienteStatus: "",
            color: "",
            notificado: false,
            fechaNotificacion: "",
            usuarioNotifico: "",
            usuarioNotificoNombre: "",
            fechaCita: "",
            horaCita: "",
            ubicacionId: "",
            sucursalName: null,
            bloqueoId: "",
            autorizado: null,
            fechaAutorizacion: null,
            concluido: false,
            fechaConclusion: null,
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            status: false,
            fechaAlta: "",
            fechaActualiza: ""
        }

        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const { dayEntregaExpediente, hourEntregaExpediente, sucursalId, sucursalName, calendarioIdEntregaExpediente  } = useSelector((state) => state.EntregaExpedienteSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [cita, setCita] = useState('');

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getCitaEntregaExpediente();
        }, []);
        
        useEffect(() => {
            if(hourEntregaExpediente){
                const formattedDate = `${dayEntregaExpediente.split("T")[0].split("-").reverse().join('-')} ${hourEntregaExpediente}`;
                setInitialValue({
                    ...initialValue,
                    fechaCita: `${dayEntregaExpediente.split("T")[0].split("-").reverse().join('-')}`,
                    horaCita: hourEntregaExpediente,
                    sucursalName: sucursalName
                });
                setCita(formattedDate);
            }
        },[hourEntregaExpediente]);

        /* CONSULTAS */
        const getCitaEntregaExpediente = async () => {
            await Cierres.cierreContatoCitaEntregaExpedienteGet(cierreid)
            .then(resp => {
                if(resp.data.fechaCita !== null && resp.data.fechaCita !== ""){
                    const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                    setCita(formattedDate);
                }
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.log(err);
            });
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            if(initialValue.cierreContratoCitaEntregaExpedienteStatusId === 10){
                if((hourEntregaExpediente !== null && hourEntregaExpediente !== undefined) && initialValue.fechaCita === null){
                    const dBloqueo = {
                        ubicacionid: sucursalId,
                        fecha: `${dayEntregaExpediente.split("T")[0]}`,
                        calendarioid: calendarioIdEntregaExpediente,
                        calendariotipoid: 7,
                        relacionid: cierreid
                    }
                    await Llamada.crearCita(dBloqueo)
                    .then(resp => {
                        const data = resp.data.data;
                        setInitialValue({
                            ...initialValue,
                            fechaCita: dayEntregaExpediente.split("T")[0],
                            horaCita: hourEntregaExpediente,
                        });
                        
                        const contenidoEmail = {
                            VARIABLE_EJECUTIVO: sessionStorage.nombreUsuario,
                            VARIABLE_CONTRATO : numContrato,
                            VARIABLE_MARCA : nombreMarca,
                            VARIABLE_MODELO : nombreModelo,
                            VARIABLE_AÑO : nombreYear,
                            VARIABLE_VERSION: nombreYear,
                            VARIABLE_VIN : vin,
                        }
                        
                        Garantias.creardatosenviar({
                            modulo: 'Aprobación Cita Entrega Documentos',
                            tipoCorreoId: 47,
                            contenido: JSON.stringify(contenidoEmail)
                        }).catch(err => {
                            console.log("Error al enviar correo Aprobación Cita Entrega Documentos:", err);
                        })

                        toast.success(message("Se agendó la cita correctamente"));
                        actualizaCitaEntregaExpediente(data.bloqueoId, values)
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar agendar la cita!"));
                        console.error(err);
                    });
                }
            }else{
                if(values.notificado){
                    values.concluido = true;
                    values.cierreContratoCitaEntregaExpedienteStatusId = 60;
                }

                await Cierres.cierreContatoCitaEntregaExpedienteAtualizar(values)
                .then(resp => {
                    setInitialValue(resp.data);
                    toast.success(message("Se actualizó la cita de entrega de expediente correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar actualizar la cita de entrega de expediente!"));
                    console.error(err);
                })
                .finally(() => {
                    if(values.notificado){
                        creaEntregaExpediente();
                    }
                    setLoading(false);
                });
            }
        }

        const actualizaCitaEntregaExpediente = async (bloqueoId, values) => {
            setLoading(true);
            let params = {
                ubicacionId: sucursalId,
                bloqueoId: bloqueoId,
                cierreContratoCitaEntregaExpedienteStatusId: 20,
                cierreContratoId: cierreid,
                fechaCita: `${dayEntregaExpediente.split("T")[0]}`,
                horaCita: hourEntregaExpediente
            }

            await Cierres.cierreContatoCitaEntregaExpedienteAtualizar(params)
            .then(resp => {
                setInitialValue(resp.data);
                toast.success(message("Se actualizó la cita de entrega de expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la cita de entrega de expediente!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }

        const creaEntregaExpediente = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoEntregaExpedienteStatusId: 10,
                status: true
            }

            props.getExpedientes();
            await Cierres.cierreContatoEntregaExpedienteCrear(params)
            .then(resp => {
                toast.success(message("Se inició la entrega de expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la entrega de expediente!"));
                console.error(err);
            });
        }

        const verCalendario = async (valor, values) => {
            dispatch(setSchedule({
                url: `procesocierre/cierres/${id}/${generalid}/${cierreid}`,
                contrato: numContrato,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }

        
        const verModalAutorizarCita = async (values) => {
            let formattedDate = "";
            if(initialValue.fechaCita === null || initialValue.fechaCita === undefined || initialValue.fechaCita === "") {
                formattedDate = `${dayEntregaExpediente.split("T")[0].split("-").reverse().join('-')} ${hourEntregaExpediente}`;
            }else{
                formattedDate = `${initialValue.fechaCita.split("T")[0].split("-").reverse().join('-')} ${initialValue.horaCita}`;
            }

            let data = {
                "generalId": generalid,
                "garantiaId": cierreid,
                "folioCita": "",
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreMarca,
                "claveModelo": nombreModelo,
                "nombreModelo": nombreModelo,
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ubicacionId": sucursalId,
                "ubicacion": sucursalName,
                "tipoGarantiaId": null,
                "clasificacionFallaId": null,
                "telefono": "",
                "fechaCita": formattedDate,
                "_bloqueoId": initialValue.bloqueoId,
            }
    
            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 7, Origin: "Listado" })
        }
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const resetForm = () => {
            refFormik.current.resetForm();
            setCita("");
            setInitialValue({
                ...initialValue,
                sucursalName: "",
            });
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            getContratoCita={getCitaEntregaExpediente}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            getContratoCita={getCitaEntregaExpediente}
                            modalMessage={modalMessage}
                            reset={resetForm}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCitaExpediente" role="button" aria-expanded="false" aria-controls="collapseCitaExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cita entrega expediente a cliente</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small>{initialValue.cierreContratoCitaEntregaExpedienteStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseCitaExpediente">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Programar entrega</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.cierreContratoCitaEntregaExpedienteStatusId === 60}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-2">
                                                            <span>Fecha y hora de cita</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                <span>{cita}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                className='btn mt-4'
                                                                disabled={(initialValue.cierreContratoCitaEntregaExpedienteStatusId !== 10 && initialValue.cierreContratoCitaEntregaExpedienteStatusId !== 30) || initialValue.fechaCita !== null}
                                                                onClick={(e) => verCalendario(7, values)}>
                                                                Ver Calendario
                                                            </button>
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="sucursalName" disabled={true} label="Sucursal" name="sucursalName" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("sucursalName", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-2">
                                                            <button className='btn mt-4 col-12' disabled={initialValue.BloqueoId === null || initialValue.cierreContratoCitaEntregaExpedienteStatusId !== 20} onClick={(e) => verModalAutorizarCita(values)}>Autorizar cita</button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Notificar al cliente</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioNotificoNombre" disabled={true} label="Usuario notificó al cliente" name="usuarioNotificoNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioNotificoNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="fechaNotificacion" disabled={true} label="Fecha notificó al cliente" name="fechaNotificacion" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("fechaNotificacion", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.cierreContratoCitaEntregaExpedienteStatusId === 60} type="checkbox" name="notificado" onClick={(event) => {
                                                                setFieldValue("notificado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.cierreContratoCitaEntregaExpedienteStatusId === 60 &&
                    <EntregaExpedienteCard 
                        getExpedientes={props.getExpedientes()}
                    />
                }
            </>
        )
    })
export default CitaExpedienteCard