import React from 'react'
import { useState } from 'react';
import CorreosService from '../../Services/Correos/Correos';
import { useEffect } from 'react';
import RowPages from '../../Components/RowPages';

const   useCorreos = () => {
    const columns = [
        { field: 'tipoCorreo', headerName: 'Tipo de Correo', width: 380 },
        { field: 'descripcion', headerName: 'Descripción', width: 500 },
        { field: 'status', headerName: 'status', width: 100 },
    ];

    const dataSelect = [
        { value: 1, label: "Activo" },
        { value: 2, label: "Inactivo" },
    ]

    const [item, setItem] = useState(null);
    const [isOpenModal, setisOpenModal] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const [PageCount, setPageCount] = useState()
    const [pageRows, setpageRows] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [str, setStr] = useState("");

    const handleEdit = (item) => {
        setItem(item)
        setisOpenModal(true);
    }
    useEffect(() => {
        handleGetallTipoCorreo(1)
        setCurrentPage(1);
    }, [pageRows,str])

    const handleGetallTipoCorreo = async (page) => {
        setLoading(true);
        await CorreosService.getAllCorreos(page,pageRows,str)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
  const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        handleGetallTipoCorreo(event.selected + 1);
    };
    return {
        columns,
        data,
        item,
        isOpenModal,
        setisOpenModal,
        handleEdit,
        modalMessage,
        setModalMessage,
        loading,
        handleGetallTipoCorreo,
        PageCount,
        handlePageClick,
        currentPage,
        setpageRows,
        setStr
    }
}

export default useCorreos