import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import DataTable from '../../../../Components/datatable/DataTable';
import { clearScheduleByTipoCalendario, selectScheduleByTipoCalendario } from '../../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useDispatch, useSelector } from 'react-redux';
import AgregarAdeudoModal from '../../../../Components/Modal/AgregarAdeudoModal';
import ModalCostosAdicionales from './ModalCostosAdicionales';
import ModalPagosAseguradora from './ModalPagosAseguradora';
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices';
import AuthLogin from '../../../../Utils/AuthLogin';
import Llamada from '../../../../Services/Cartera/Llamadas';
import Garantias from '../../../../Services/Cartera/Garantias';
import documentsHelper from '../../../../Utils/documents';
import { initAsyncCompiler } from 'sass';

const auth = new AuthLogin();
const CierreSiniestroAseguradoraCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let url = auth.UrlDataDocs();
    const scheduleDataCita = useSelector((state) => selectScheduleByTipoCalendario(state, 4));

    let initial = {
        seguroSeguimientoSiniestroAseguradoraId: "",
        siniestroId: id,

        tipoTramiteId: "",
        tipoTramite: "",
        codEstatusTramite: "",
        estatusTramite: "",
        facturaSolicitada: "",
        numeroFactura: "",

        codEstatusProcesoCierre: "",
        estatusProcesoCierre: "",

        observaciones: "",

        realizarDevolucion: "",
        montoDevolver: "",
        codEstatusSaldoFavor: "",
        estatusSaldoFavor: "",

        codEstatus: "",
        estatus: "",
        ubicacionId: "",
        ubicacion: "",
        bloqueoId: "",
        fechaHoraCita: "",
        devolucionEfectivo: "",
        comentarios: "",

        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 4,
        porcentaje: 100,
        status: 2,
        procesoId: state?.procesoId,
    }
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);
    //tblAdeudos
    const [totalCosto, setTotalCosto] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);
    //modal Costos
    const [isOpenModalCosto, setIsOpenModalCosto] = useState(false);
    const [itemSelectedCostos, setItemSelectedCostos] = useState({});
    const [itemSelectedPagos, setItemSelectedPagos] = useState({});
    const [costosData, setCostosData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageRows, setpageRows] = useState(10);
    const [pageCount, setPageCount] = useState();
    const [currenPage, setCurrenPage] = useState(1);
    //Modal PAgos
    const [isOpenModalPago, setIsOpenModalPago] = useState(false);
    const [pagosData, setPagosData] = useState([]);
    const [pagePago, setPagePago] = useState(1);
    const [pageRowsPago, setpageRowsPago] = useState(10);
    const [pageCountPago, setPageCountPago] = useState();
    const [currenPagePago, setCurrenPagePago] = useState(1);
    //history
    const [openModalHistory, setOpenModalHistory] = useState(false);
    const [pageCountH, setPageCountH] = useState();
    const [currenPageH, setCurrenPageH] = useState(1);
    const [dataHistorial, setDataHistorial] = useState([]);

    const columnsCostosAdicionales = [
        { field: 'orden', headerName: 'No. Orden', width: '120' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: '120' },
        { field: 'concepto', headerName: 'Tipo de gasto', width: '100' },
        { field: 'subTotalStr', headerName: 'Subtotal', width: '140' },
        { field: 'iva', headerName: 'IVA', width: '100' },
        { field: 'totalStr', headerName: 'Total', width: '100' },
        { field: 'fechaHora', headerName: 'Fecha de registro', width: '300' },
        { field: 'estatus', headerName: 'Estatus', width: '100' },
    ];
    const columnsPagoAseguradora = [
        { field: 'orden', headerName: 'Folio de pago', width: '120' },
        { field: 'fechaHora', headerName: 'Fecha y hora', width: '250' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: '120' },
        { field: 'concepto', headerName: 'Notas', width: '100' },
        { field: 'monto', headerName: 'Monto', width: '140' },
        { field: 'acciones', headerName: 'Acciones', width: '100' },
    ];
    const columnsH = [
        { field: 'agenteSeguro', headerName: 'Folio cita', width: 150 },
        { field: 'aseguradora', headerName: 'Ubicación', width: 150 },
        { field: 'usoUnidad', headerName: 'Fecha y hora de cita', width: 230 },
        { field: 'cobertura', headerName: 'Usuario', width: 130 },
        { field: 'frecuencia', headerName: 'Estatus', width: 160 }
    ];
    useEffect(() => {
        handleGetAllAseguradoraById();
    }, []);

    const handleGetAllCostos = async (page, cardId) => {
        setLoading(true);
        await Siniestros.GetAllCostosAseguradora(page, pageRows, cardId)
            .then(res => {
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                let totalCosto = 0;
                let newData = [];
                res.data.data.map((item, index) => {
                    console.log("dataitem", item)
                    item.orden = index + 1;
                    totalCosto += item.total;
                    newData.push(item);
                });
                setTotalCosto(totalCosto);
                setCostosData(newData);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllPagos = async (page, cardId) => {
        setLoading(true);
        await Siniestros.GetAllPagosAseguradora(page, pageRowsPago, cardId)
            .then(res => {
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                let totalPagos = 0;
                let newData = [];
                res.data.data.map((item, index) => {
                    item.orden = index + 1;
                    totalPagos += item.monto;
                    newData.push(item);
                });
                setTotalPagos(totalPagos);
                setPagosData(newData);
                setPageCountPago(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleGetAllCostos(event.selected + 1);
    };
    const handlePageClickPagos = (event) => {
        setCurrenPagePago(event.selected + 1);
        handleGetAllPagos(event.selected + 1);
    };
    const handlePageClickH = (event) => {
        setCurrenPageH(event.selected + 1);
        handleGetAllHistory(event.selected + 1);
    };

    const handleGetAllHistory = async (pageH) => {
        setLoading(true);
        await Siniestros.GetAllHistorialCita(pageH, 10, initialValue?.seguroSeguimientoSiniestroAseguradoraId)
            .then(res => {
                setDataHistorial(res.data.data)
                setPageCountH(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDeteleCostos = (value) => {
        if (value.codEstatus === 1) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No es posible eliminar un registro con estatus Enviado")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setLoading(true);
        Siniestros.DeleteCostosAseguradoraById(value.seguroSeguimientoSiniestroAseguradoraCostosId)
            .then(resp => {
                let { ban, mensaje } = resp.data.data;
                if (ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", mensaje));
                    handleGetAllCostos(1, initialValue.seguroSeguimientoSiniestroAseguradoraId)
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", mensaje));
                }
            }).catch(error => {
                setLoading(false);
                toast.error(message("¡Operación fallida!", "Error al actualizado un adeudo"));
                console.log(error)
            })
    }
    const handleEditCosto = async (value) => {
        if (value.codEstatus === 1) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No es posible eliminar un registro con estatus Enviado")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setIsOpenModalCosto(true);
        setItemSelectedCostos(value);
    }
    /* CONSULTAS */
    const handleGetAllAseguradoraById = async () => {
        setLoading(true);
        await Siniestros.GetSiniestroRoboAseguradoraById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    seguroSeguimientoSiniestroAseguradoraId: item.seguroSeguimientoSiniestroAseguradoraId ?? "",
                    siniestroId: id,

                    tipoTramiteId: item?.tipoTramiteId ?? "",
                    tipoTramite: item?.tipoTramite ?? "",
                    codEstatusTramite: item?.codEstatusTramite ?? "",
                    estatusTramite: item?.estatusTramite ?? "",
                    facturaSolicitada: item?.facturaSolicitada ?? "",
                    numeroFactura: item?.numeroFactura ?? "",

                    codEstatusProcesoCierre: item?.codEstatusProcesoCierre ?? "",
                    estatusProcesoCierre: item?.estatusProcesoCierre ?? "",

                    observaciones: item?.observaciones ?? "",

                    realizarDevolucion: item?.realizarDevolucion ?? "",
                    montoDevolver: item?.montoDevolver ?? "",
                    codEstatusSaldoFavor: item?.codEstatusSaldoFavor ?? "", //1.-Solicitud 2.-Autorizado 3.-Reagendar 4.-Rechazado
                    estatusSaldoFavor: item?.estatusSaldoFavor ?? "",
                    saldoInsolutoCredito: item?.saldoInsolutoCredito ?? 0,

                    codEstatus: item?.codEstatus ?? "",
                    estatus: item?.estatus ?? "",
                    ubicacionId: item?.ubicacionId ?? "",
                    ubicacion: item?.ubicacion ?? scheduleDataCita?.sucursalName,
                    bloqueoId: item?.bloqueoId ?? "",
                    fechaHoraCita: item?.fechaHoraCita ?? scheduleDataCita?.day,
                    devolucionEfectivo: item?.devolucionEfectivo ?? "",
                    comentarios: item?.comentarios ?? "",
                    clienteLlegoCita: item?.clienteLlegoCita ?? false,

                    concluido: item?.concluido ?? false,
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    // AgendarCita
                    calendarioId: scheduleDataCita?.calendarioId ?? "",
                    day: scheduleDataCita?.day ?? "",
                    hour: scheduleDataCita?.hour ?? "",
                    sucursalId: scheduleDataCita?.sucursalId ?? "",
                    sucursalName: scheduleDataCita?.sucursalName ?? "",
                    //Stepper Update        
                    paso: 4,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state?.procesoId,

                    //contrato
                    contrato: state?.contrato
                }
                setInitialValue(newData);
                handleGetAllCostos(1, item.seguroSeguimientoSiniestroAseguradoraId);
                handleGetAllPagos(1, item.seguroSeguimientoSiniestroAseguradoraId)
                handleConcluido({ concluido: item?.concluido });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    const submitForm = async (values) => {
        if (values?.realizarDevolucion) {
            let totalDevolver = (totalPagos - values.saldoInsolutoCredito);
            console.log("save", parseFloat(values.montoDevolver), totalDevolver)
            if (parseFloat(values.montoDevolver) > totalDevolver) {
                toast.error(message("¡Error!", "El monto a devolver no debe ser mayor a la diferencia del Balance."));
                return
            }
        }
        else if (values?.codEstatusSaldoFavor === 0 || values?.codEstatusSaldoFavor === 1) {
            if (!scheduleDataCita.calendarioId) {
                toast.error(message("¡Error!", "Es necesario agendar una cita en el calendario."));
                return
            }
        }
        if (values.concluido) {
            dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 4 }));
        }
        setLoading(true);
        await Siniestros.UpdateSiniestroRoboAseguradora(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllAseguradoraById();
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        // facturaSolicitada: Yup.bool()
        //     .required("El campo es requerido."),
        // numeroFactura: Yup.string()
        //     .when("facturaSolicitada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),

        // observaciones: Yup.string()
        //     .when("codEstatusProcesoCierre", {
        //         is: 1,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // realizarDevolucion: Yup.bool()
        //     .when("codEstatusProcesoCierre", {
        //         is: 1,
        //         then: Yup.bool()
        //             .required("El campo es requerido.")
        //     }),
        // montoDevolver: Yup.number()
        //     .when("realizarDevolucion", {
        //         is: true,
        //         then: Yup.number().required("El campo es requerido.")
        //             .typeError('Debe ser un número')
        //             .positive("ingrese un valor mayor a cero.")
        //     }),
        // comentarios: Yup.string()
        //     .when("clienteLlegoCita", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        //////////////////////
        numeroFactura: Yup.string()
            .when(["concluido", "facturaSolicitada"], {
                is: (concluido, facturaSolicitada) => {
                    return (concluido && facturaSolicitada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        facturaSolicitada: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        observaciones: Yup.string()
            .when(["concluido", "codEstatusProcesoCierre"], {
                is: (concluido, codEstatusProcesoCierre) => {
                    return (concluido && codEstatusProcesoCierre === 1);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        realizarDevolucion: Yup.bool()
            .when(["concluido", "codEstatusProcesoCierre"], {
                is: (concluido, codEstatusProcesoCierre) => {
                    return (concluido && codEstatusProcesoCierre === 1);
                },
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        montoDevolver: Yup.number()
            .when(["concluido", "realizarDevolucion"], {
                is: (concluido, realizarDevolucion) => {
                    return (concluido && realizarDevolucion);
                },
                then: Yup.number()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when(["concluido", "clienteLlegoCita"], {
                is: (concluido, clienteLlegoCita) => {
                    return (concluido && clienteLlegoCita);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });
    const verCalendario = async () => {
        navigate(`/seguro/siniestros/Citas/v2/${4}`, {
            state: { title: 'Cita Devolución', tipoCalendario: 4, ...state }
        });
    }
    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }
    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCrrSinRbo/${state?.folio}/${id}`, {
                state: { ...value, registroId: value.seguroSeguimientoSiniestroAseguradoraPagosId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    }
    const EnviarGastosAcendes = async () => {
        if (costosData.length <= 0) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No hay costos a procesar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 2000);
            return;
        } else {
            if (!costosData.filter(x => x.codEstatus === 0)) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Tocos los costos se encuentran en Estatus Enviado.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 2000);
                return;
            }
        }
        await Siniestros.SaveCostosAcendes({
            seguroSeguimientoSiniestroAseguradoraId: initialValue?.seguroSeguimientoSiniestroAseguradoraId,
            sol_Id: state?.sol_Id,
            contrato: state?.contrato,
            vin: state?.vin
        })
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllCostos(1, initialValue?.seguroSeguimientoSiniestroAseguradoraId)
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar enviar costos a acendes!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const getSaldoInsoluto = async () => {
        setLoading(true);
        await Llamada.getAdeudoCredito({
            numeroSolicitud: state?.sol_Id,
            vin: state?.vin,
            contrato: state?.contrato
        }).then(res => {
            let resp = res.data.data;
            if (resp.status === 'proceso_correcto') {
                setLoading(false);
                toast.success(message("Acendes!", resp.message));
            } else {
                setLoading(false);
                toast.error(message("¡Error Acendes!", resp.message));
            }
        }).catch(error => {
            setLoading(false);
        })
    }
    const generatePDFChecklist = async () => {
        setLoading(true)
        const date = new Date();
        const formattedDate = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

        const requestBody = {
            nombrePlantilla: "CheckListDocumentosSiniestro",
            parametros: {
                "<<VARIABLE_CONTRATO>>": state?.contrato,
                "<<VARIABLE_VIN>>": state?.vin,
                "<<VARIABLE_DATOS>>": `${state?.marca}, ${state?.modelo}, ${state?.año} ${state?.nombreVersion}, ${state?.colorVehiculo}`,
                "<<VARIABLE_CLIENTE>>": state?.cliente,
                "<<VARIABLE_FECHA>>": formattedDate,
                "<<VARIABLE_FOLIO>>": state?.folio,
                "<<VARIABLE_UBICACION>>": state?.nombreUbicacion
            }
        };

        await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            submitForm({
                SeguroSeguimientoSiniestroAseguradoraId: initialValue.seguroSeguimientoSiniestroAseguradoraId,
                clienteLlegoCita: true,
                concluido: false
            });
        }).catch(err => {
            setLoading(false)
            console.error(err);
        });
    }
    const handleUploadFileDevolucion = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCrrSinRbo/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.seguroSeguimientoSiniestroAseguradoraId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAseguradora" role="button" aria-expanded="false" aria-controls="collapseAseguradora" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue.concluido ? "green" : "default"}`}></span> <strong>Aseguradora</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAseguradora">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        values.concluido ? values.porcentaje = 100 : values.porcentaje = 50;
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-3">
                                                        <small><strong>Trámite de aseguradora</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <fieldset
                                                                    disabled={initialValue?.concluido}
                                                                    onClick={EnviarGastosAcendes}
                                                                    className='btn'>Enviar gastos a Acendes
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField disabled={true} label="Tipo de trámite" holder="Escribe" name="tipoTramite" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField disabled={true} label="Estatus" holder="Escribe" name="estatusTramite" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField
                                                            label="¿Factura solicitada?"
                                                            name="facturaSolicitada"
                                                            disabled={initialValue.concluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("facturaSolicitada", event.value);
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        values.facturaSolicitada &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField disabled={initialValue?.concluido} label="No. de factura" holder="Escribe" name="numeroFactura" type="text" />
                                                        </div>
                                                    }

                                                </div>
                                                <div className="row mt-2 px-4 mt-4">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <small><strong>Costos adicionales</strong></small>
                                                            <fieldset disabled={initialValue.concluido} onClick={() => { setIsOpenModalCosto(true) }} className='btn d-flex align-items-center mx-2'>Registrar adeudo</fieldset>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <DataTable
                                                                data={costosData}
                                                                column={columnsCostosAdicionales}
                                                                editable={!initialValue?.concluido}
                                                                handleEdit={handleEditCosto}
                                                                deleteable={!initialValue?.concluido}
                                                                handleDelete={handleDeteleCostos}
                                                                pageCount={pageCount}
                                                                handlePageClick={handlePageClick}
                                                                currentPage={page - 1}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <div>
                                                                    <strong>Total: ${FormatNumberMoney2(totalCosto)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mt-4">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <small><strong>Pago de aseguradora</strong></small>
                                                            <fieldset disabled={initialValue.concluido} onClick={() => { setIsOpenModalPago(true) }} className='btn d-flex align-items-center mx-2'>Registrar pago</fieldset>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <DataTable
                                                                data={pagosData}
                                                                column={columnsPagoAseguradora}
                                                                pageCount={pageCountPago}
                                                                handlePageClick={handlePageClickPagos}
                                                                currentPage={pagePago - 1}
                                                                handleDocuments={handleViewdocs}
                                                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <div>
                                                                    <strong>Total: ${FormatNumberMoney2(totalPagos)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mt-4">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <small><strong>Proceso de cierre</strong></small>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-12 col-lg-3">
                                                                <TextField disabled={true} label="Estatus" holder="Escribe" name="estatusProcesoCierre" type="text" />
                                                            </div>
                                                        </div>
                                                        {
                                                            values.codEstatusProcesoCierre === 1 &&
                                                            <>
                                                                <div className="row mt-2">
                                                                    <div className="col-4">
                                                                        <div className="row">
                                                                            <strong>Balance</strong>
                                                                        </div>
                                                                        <div className="col-auto my-2">
                                                                            <fieldset
                                                                                disabled={initialValue?.concluido}
                                                                                onClick={getSaldoInsoluto}
                                                                                className='btn'>Obtener saldo insoluto
                                                                            </fieldset>
                                                                        </div>
                                                                        <div className="row mt-3">
                                                                            <div className="col-12">
                                                                                <table id='tbl_balance' className='col-12'>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Concepto</th>
                                                                                            <th>Monto</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Monto pagado de aseguradora</td>
                                                                                            <td>${FormatNumberMoney2(totalPagos)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Saldo insoluto de crédito</td>
                                                                                            <td>${FormatNumberMoney2(values.saldoInsolutoCredito)}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                        <tr>
                                                                                            <td>Diferencia</td>
                                                                                            <td>${FormatNumberMoney2(totalPagos - values.saldoInsolutoCredito)}</td>
                                                                                        </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                                                                    <TextAreaField
                                                                        label="Observaciones"
                                                                        disabled={initialValue.concluido}
                                                                        className="form-control col-12"
                                                                        rows="3"
                                                                        name="observaciones"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    values.codEstatusProcesoCierre === 1 &&
                                                    <>
                                                        <div className="row mt-2 px-4 mb-2">
                                                            <div className="col-3">
                                                                <small><strong>Saldo a favor</strong></small>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField
                                                                    label="¿Realizar devolución?"
                                                                    name="realizarDevolucion"
                                                                    disabled={initialValue.concluido ? true : (initialValue.codEstatusSaldoFavor === 1 && initialValue.codEstatusSaldoFavor === 0) ? true : false}
                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("realizarDevolucion", event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                values.realizarDevolucion &&
                                                                <>
                                                                    <div className="col-12 col-lg-3">
                                                                        <TextField icon={"bi bi-currency-dollar"} isMoney={true}
                                                                            disabled={initialValue.concluido ? true : (initialValue.codEstatusSaldoFavor === 1 && initialValue.codEstatusSaldoFavor === 0) ? true : false}
                                                                            holder="Escribe" label="Monto a devolver" name="montoDevolver" type="text" onChange={(event) => {
                                                                                setFieldValue("montoDevolver", event.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <TextField disabled={true} holder="Escribe" label="Estatus" name="estatusSaldoFavor" type="text" />
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                        {
                                                            (values.codEstatusSaldoFavor === 0 || values.codEstatusSaldoFavor === 1) &&
                                                            <>
                                                                {/* cita Devolucion */}
                                                                <div className="row mt-2 px-4 mb-2">
                                                                    <div className="col-3">
                                                                        <small><strong>Agendar cita devolución</strong></small>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2 px-4">
                                                                    <div className="col-auto d-flex align-items-center">
                                                                        <TextField disabled={true} holder="Escribe" label="Fecha y hora de cita" name="fechaHoraCita" type="text" />
                                                                        <fieldset disabled={initialValue.concluido} onClick={verCalendario} className='btn d-flex align-items-center mx-2 mt-3'>Ver calendario</fieldset>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <TextField disabled={true} holder="Escribe" label="Estatus cita" name="estatus" type="text" />
                                                                    </div>
                                                                    <div className="col-auto d-flex align-items-center">
                                                                        <TextField disabled={true} holder="Escribe" label="Ubicación" name="ubicacion" type="text" />
                                                                        <fieldset disabled={initialValue.concluido} onClick={() => { setOpenModalHistory(true); handleGetAllHistory(1) }} className=' ms-2'><i className="icon-dark ri-file-list-line mt-4"></i> Ver historial</fieldset>

                                                                    </div>
                                                                </div>
                                                                {
                                                                    values.codEstatus === 2 &&
                                                                    <>
                                                                        <div className="row mt-2 px-4 mb-2 mt-4">
                                                                            <div className="col-3 d-flex align-items-center">
                                                                                <small><strong>Devolución de efectivo</strong></small>
                                                                                {/* <fieldset disabled={initialValue?.acreditacionConcluido} onClick={generatePDFChecklist} className='btn btn-light d-flex align-items-center mx-2'><i className="icon fs-4 bx bx-download" ></i></fieldset> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-2 col-lg-12 col-md-12 col-sm-12 mx-3">
                                                                            <div className="col-4">
                                                                                <div className="row">
                                                                                    <strong>Balance</strong>
                                                                                </div>
                                                                                <div className="row mt-3 ">
                                                                                    <div className="col-12">
                                                                                        <table id='tbl_balance' className='col-12'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Evidencia</th>
                                                                                                    <th>Acción</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>Devolución de efectivo</td>
                                                                                                    <td><fieldset disabled={false} className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content', color: 'black' }} ><i className="icon fa fa-file"></i> Subir Documentos</fieldset></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                            {/* <tfoot>
                                                                        <tr>
                                                                            <td>Diferencia</td>
                                                                            <td>${FormatNumberMoney2(totalAdeudos - totalPagos)}</td>
                                                                        </tr>
                                                                    </tfoot> */}
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-2 px-4">
                                                                            <div className="col-lg-8 col-md-12 col-sm-12 mt-2">
                                                                                <TextAreaField
                                                                                    label="comentarios"
                                                                                    disabled={initialValue.concluido ? true : !initialValue.clienteLlegoCita}
                                                                                    className="form-control col-12"
                                                                                    rows="3"
                                                                                    name="comentarios"
                                                                                    type="text"
                                                                                    holder="Escribe"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                            </>
                                                        }
                                                    </>
                                                }
                                                {
                                                    (values.realizarDevolucion === false || values.codEstatus === 2) &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="row mt-2">
                                                            <div className="col-12 col-lg-6">
                                                                <Field className="form-check-input" disabled={initialValue.concluido ? true : !initialValue.clienteLlegoCita} type="checkbox" name="concluido" />
                                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Concluir registro</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row mt-2 d-flex justify-content-end">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row flex-column align-items-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaInicio !== "" &&
                                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>Fin de proceso: {initialValue.fechaFin}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                isOpenModalCosto &&
                <ModalCostosAdicionales
                    setIsOpenModalAdeudo={setIsOpenModalCosto}
                    isOpenModalAdeudo={isOpenModalCosto}
                    handleGetAll={() => { handleGetAllCostos(1, initialValue.seguroSeguimientoSiniestroAseguradoraId) }}
                    item={{ ...itemSelectedCostos, seguroSeguimientoSiniestroAseguradoraId: initialValue.seguroSeguimientoSiniestroAseguradoraId }}
                />
            }
            {
                isOpenModalPago &&
                <ModalPagosAseguradora
                    setIsOpenModalAdeudo={setIsOpenModalPago}
                    isOpenModalAdeudo={isOpenModalPago}
                    handleGetAll={() => { handleGetAllPagos(1, initialValue.seguroSeguimientoSiniestroAseguradoraId) }}
                    item={{ ...itemSelectedPagos, seguroSeguimientoSiniestroAseguradoraId: initialValue.seguroSeguimientoSiniestroAseguradoraId }}
                />
            }
            {
                openModalHistory &&
                <Modal isOpen={openModalHistory} color='#fff'>
                    <div classNameName='d-flex'>
                        <span><strong>Historial de citas</strong></span>

                    </div>
                    <DataTable
                        column={columnsH}
                        data={dataHistorial}
                        pageCount={pageCountH}
                        handlePageClick={handlePageClickH}
                        currentPage={currenPageH - 1}
                    />
                    <section className='my-3 d-flex justify-content-end mt-4'>
                        <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setOpenModalHistory(false) }}>Cancelar</fieldset>
                    </section>
                </Modal>
            }
        </>
    )
}
export default CierreSiniestroAseguradoraCard