import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceGarantias = createSlice({
  name: 'GarantiaNavigate',
  initialState,
  reducers: {
    tabActiveGarantias: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveGarantias } = navigaSliceGarantias.actions

export default navigaSliceGarantias.reducer