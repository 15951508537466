import axios from '../../Utils/ApiService';

const MarcaAPI = {
    getdata:(page,rows)=> {        
        return axios.get(`/Marca?page=${page}&rows=${rows}`);
    },   
    updateMarca:(data)=> {        
        return axios.put(`/MarcaUpdate`,data);
    },    
    addMarca:(data)=> {         
        return axios.post(`/MarcaAdd`,data);
    },       

}

export default MarcaAPI