import axios from 'axios';
import AuthLogin from './AuthLogin';

const auth = new AuthLogin();

const ApiServiceDataDocs = axios.create({
    baseURL: process.env.REACT_APP_API_DATADOCS,    
    headers: {
        'Authorization':'Bearer '+ auth.tokenDataDocs(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        "Accept":"*/*",
        // 'Accept-Encoding':'gzip, deflate'
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'credentials': 'same-origin'
    }
});

export default ApiServiceDataDocs;