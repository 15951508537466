import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../SelectField';
import { toast, Toaster } from 'react-hot-toast';
import ResguardoDeUnidadModal from './ResguardoDeUnidadModal';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { useParams } from 'react-router-dom';
import { message } from '../../Utils/functions';
import RecuperacionDeUnidadModal from './RecuperacionDeUnidadModal';
import SolicitarExpedienteModal from './SolicitarExpedienteModal';
import TramiteDeGestoriaModal from './TramiteDeGestoriaModal';
import SolicitarServicioModal from './SolicitarServicioModal';
import SolicitarRecoleccionUnidadModal from './solicitarRecoleccionUnidadModal';

const DatamovilModal = ({ modalMessage, setModalMessage, handleOk }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const btnRef = useRef();
    const initial = {
        tipoProceso:""
    }
    const [initialValue, setInitialValue] = useState(initial);

    const [modalResguardoUnidad, setModalResguardoUnidad] = useState({ status: false, id: "" });
    const [modalRecuperacionUnidad, setModalRecuperacionUnidad] = useState({ status: false, id: "", tipoProceso: 0 });
    const [modalTramiteGestoria, setModalTramiteGestoria] = useState({ status: false, id: "", tipoProceso: 0 });
    const [modalSolicitudExpediente, setModalSolicitudExpediente] = useState({ status: false, id: "", tipoProceso: 0 });
    const [modalRecoleccionUnidad, setModalRecoleccionUnidad] = useState({ status: false, id: "", tipoProceso: 0 });
    const [modalServicioDatamovil, setModalServicioDatamovil] = useState({ status: false, id: "", tipoProceso: 0 });

    const [dataProceso, setDataProceso] = useState([]);

    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const validate = Yup.object().shape({
        tipoProceso: Yup.string()
            .required('Campo requerido')
    });
    useEffect(() => {
        getSiniestroTipo();
        setInitialValue(initial);
    }, [modalMessage])

    const getSiniestroTipo = async () => {
        setLoading(true);
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroProcesoDatamovil')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.SiniestroProcesoDatamovilId, label: item.Proceso });
                });
                setDataProceso(NewData);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };
    const handleModal = async (value) => {
        if(value.tipoProceso === 2 || value.tipoProceso ===3){            
            handleModalById(value.tipoProceso,"")
        }else{            
            setLoading(true);
            let data = {
                siniestroId: id,
                siniestroProcesoDatamovilId: value.tipoProceso
            }
            await Siniestros.AddProcesoDatamovil(data)
                .then(res => {
                    let { Ban, Mensaje, SeguroSiniestroSeguimientoProcesoDatamovilId } = res.data.data;
                    if (Ban !== 0) {
                        setLoading(false);
                        handleModalById(value.tipoProceso, SeguroSiniestroSeguimientoProcesoDatamovilId);
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error!", Mensaje));
                    }
    
                })
                .catch(error => {
                    setLoading(false);
                    console.log(error)
                })
        }
        
    }
    const handleModalById = (value, procesoDatamovilId) => {
        switch (value) {
            case 1:
                setModalRecuperacionUnidad({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            case 2:
                setModalTramiteGestoria({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            case 3:
                setModalSolicitudExpediente({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            case 4:
                setModalRecoleccionUnidad({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            case 5:
                setModalServicioDatamovil({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            case 6:
                setModalResguardoUnidad({ status: true, id: procesoDatamovilId, tipoProceso: value })
                break;
            default: 
                break;
        }
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {                    
                    handleModal(values);
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        {
                            modalResguardoUnidad.status &&
                            <ResguardoDeUnidadModal
                                isOpen={modalResguardoUnidad.status}
                                setIsOpen={() => { setModalResguardoUnidad({ ...modalResguardoUnidad, status: false });handleRegresar()}}
                                item={{ seguroSiniestroSeguimientoProcesoDatamovilId: modalResguardoUnidad.id }}
                            />
                        }
                        {
                            modalRecuperacionUnidad.status &&
                            <RecuperacionDeUnidadModal
                                isOpen={modalRecuperacionUnidad.status}
                                setIsOpen={() => { setModalRecuperacionUnidad({ ...modalRecuperacionUnidad, status: false });handleRegresar() }}
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: modalRecuperacionUnidad.id,
                                    tipoProceso: modalRecuperacionUnidad?.tipoProceso
                                }}
                            />
                        }
                        {
                            modalTramiteGestoria.status &&
                            <TramiteDeGestoriaModal
                                isOpen={modalTramiteGestoria.status}
                                setIsOpen={() => { setModalTramiteGestoria({ ...modalTramiteGestoria, status: false });handleRegresar() }}
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: modalTramiteGestoria.id,
                                    tipoProceso: modalTramiteGestoria?.tipoProceso
                                }}
                            />
                        }
                        {
                            modalSolicitudExpediente.status &&
                            <SolicitarExpedienteModal
                                isOpen={modalSolicitudExpediente.status}
                                setIsOpen={() => { setModalSolicitudExpediente({ ...modalSolicitudExpediente, status: false });handleRegresar() }}
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: modalSolicitudExpediente.id,
                                    tipoProceso: modalSolicitudExpediente?.tipoProceso
                                }}
                            />
                        }
                        {
                            modalRecoleccionUnidad.status &&
                            <SolicitarServicioModal
                                isOpen={modalRecoleccionUnidad.status}
                                setIsOpen={() => { setModalRecoleccionUnidad({ ...modalRecoleccionUnidad, status: false });handleRegresar() }}
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: modalRecoleccionUnidad.id,
                                    tipoProceso: modalSolicitudExpediente?.tipoProceso
                                }}
                            />
                        }
                        {
                            modalServicioDatamovil.status &&
                            <SolicitarRecoleccionUnidadModal
                                isOpen={modalServicioDatamovil.status}
                                setIsOpen={() => { setModalServicioDatamovil({ ...modalServicioDatamovil, status: false });handleRegresar() }}
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: modalServicioDatamovil.id,
                                    tipoProceso: modalServicioDatamovil?.tipoProceso
                                }}
                            />
                        }
                        <div className='justify-content-start p-2 align-items-center'>
                            <Form>
                                <div className="row">
                                    <div className="col col-12">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col col-12">
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <SelectField ref={btnRef} className="col-12" label="Tipo de proceso:" name={`tipoProceso`} items={dataProceso} onChange={(event) => {
                                                    setFieldValue(`tipoProceso`, event.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto d-flex">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline me-2'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleRegresar()}
                                            >
                                                Cerrar
                                            </button>
                                            <button
                                                type='submit'
                                                id="AT_BtnCloseModal"
                                                disabled={!isValid}
                                                className='btn'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleOk(values)}
                                            >
                                                Continuar
                                            </button>
                                        </div>

                                    </div>
                                </section>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default DatamovilModal;
