import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';
import Usuario from '../../Services/Users/Usuarios';

const TipoDocumentoUsuario = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage , existingData}) => {    
    const refbtn = useRef();
    let initial = {
        tipoDocumentoUsuarioId: item?.tipoDocumentoUsuarioId ?? "",
        usuarioId: item?.usuarioId ?? "",
        tipoDocumentoId: item?.tipoDocumentoId ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [dataUSer, setDataUser] = useState([]);


    useEffect(() => {
        handleGetAllList();
        handleGetAllUsers();
    }, [])



    const validate = Yup.object({
        // usuarioId: Yup.string()
        //     .required("El campo es requerido."),
        tipoDocumentoId: Yup.string()
            .required("El campo es requerido.")
    });
    const handleGetAllList = async () => {
        await Catalogo.getdataTabla("TipoDocumento", 1, 100)
            .then(res => {

                let NewData = [];

                res.data.data?.map(item => {
                    // NewData.push({
                    //     id: item.tipoDocumentoId,
                    //     name: item.nombre
                    // })
                    NewData.push({
                        value: item.tipoDocumentoId,
                        label: item.nombre
                    })
                });
                setData(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllUsers = async ()=>{
        await Usuario.getUsuario(1, 1000,"","","")
        .then(res => {
            
            let NewData = [];
            res.data.data?.map(item => {           
                NewData.push({
                    value: item.userId,
                    label: item.nombreUsuario
                })
            });
            setDataUser(NewData);
        }).catch(e => {

        })
    }
    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (action === 'add') {
            await Catalogo.addTipoDocumentoUsuario(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        if (action === 'edit') {
            await Catalogo.updateTipoDocumentoUsuario(item)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
                }).catch(e => {
                    setIsOpen(false);

                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                
                handleAddEdit(values);
            }}
        >{
                ({ isValid, values, setFieldValue, field, handleChange }) => (
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-5'><strong>{item == null ? "Nuevo valor" : "Editar Valor"}</strong></span>
                            <Form>
                                <div className="mb-3 row">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field id="Estatus" className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <SelectField id="Usuario" label="Usuario" name="usuarioId" items={dataUSer}  onChange={(event) => {
                                            setFieldValue("usuarioId", event.value);
                                        }} />
                                    </div>
                                    {
                                        
                                    }
                                    <div className="col-sm-6">
                                        <SelectField id="TipoDocumento" label="Tipo Documento" name="tipoDocumentoId" items={data} onChange={(event) => {
                                            setFieldValue("tipoDocumentoId", event.value);
                                        }} />
                                    </div>
                                </div>

                                <section className='my-3'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item == null ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )

            }

        </Formik>
    )
}

export default TipoDocumentoUsuario