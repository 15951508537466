import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import Llamada from '../../../Services/Cartera/Llamadas';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import DataTable from '../../../Components/datatable/DataTable';
import ReparacionUnidadModal from '../../../Components/Modal/ReparacionUnidadModal';

const ValoracionUnidadCard
    = React.forwardRef(({ ...props }, ref) => {
        const { nombreYear } = useSelector((state) => state.DataVinSlice);
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, valuacionid } = useParams();


        let initial = {
            valuacionValoracionUnidadId: "",
            valuacionId: "",
            valuacionValoracionUnidadStatusId: 0,
            valuacionValoracionUnidadStatus: "",
            color: "default",
            valorMercado: null,
            costoReparacion: null,
            adeudos: null,
            utilidad: null,
            valorUnidad: null,
            cumplePolitica: null,
            unidadParaInventario: null,
            comentarios: "",
            confirmaValorMercado: null,
            confirmaUnidadParaInventario: null,
            comentariosValorUnidad: "",
            valorUnidadAutorizado: null,
            fechaValorAutorizado: "",
            concluido: null,
            fechaConclusion: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: null,
            usuarioActualizaNombre: null,
            status: null,
            fechaAlta: "",
            fechaAltaField: "",
            fechaActualiza: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [disabled, setDisabled] = useState(false);


        useEffect(() => {
            getValoracionUnidad();
        }, []);

        useEffect(() => {
            let cumplePolitica = null, unidadParaInventario = null, confirmaUnidadParaInventario = null;
            
            if(nombreYear !== ""){
                if(Number(nombreYear) >= Number(initialValue.politicaParam)){
                    cumplePolitica = 1;
                    setDisabled(false);
                }else{
                    cumplePolitica = 0;
                    unidadParaInventario = 0;
                    confirmaUnidadParaInventario = 0;
                    setDisabled(true);
                }
                setInitialValue({
                    ...initialValue,
                    cumplePolitica,
                    unidadParaInventario,
                    confirmaUnidadParaInventario
                });
            }
        }, [nombreYear]);
        

        /* CONSULTAS */
        const getValoracionUnidad = async () => {
            await Valuaciones.valuacionvaluacionValoracionUnidadGet(valuacionid)
            .then(resp => {
                resp.data.cumplePolitica = resp.data.cumplePolitica === null ? null : (resp.data.cumplePolitica ? 1 : 0);
                resp.data.unidadParaInventario = resp.data.unidadParaInventario === null ? null : (resp.data.unidadParaInventario ? 1 : 0);
                resp.data.confirmaUnidadParaInventario = resp.data.confirmaUnidadParaInventario === null ? null : (resp.data.confirmaUnidadParaInventario ? 1 : 0);
                resp.data.fechaAltaField = getDateFormat(resp.data.fechaAlta, "");

                resp.data.valorMercado = resp.data.valorMercado === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.valorMercado);
                resp.data.costoReparacion =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.costoReparacion);
                resp.data.adeudos =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.adeudos);
                resp.data.utilidad =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.utilidad);
                resp.data.valorUnidad =  resp.data.valorUnidad === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.valorUnidad);
                resp.data.confirmaValorMercado =  resp.data.confirmaValorMercado === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.confirmaValorMercado);

                if(Number(nombreYear) >= Number(resp.data.politicaParam))
                {
                    resp.data.cumplePolitica = 1;
                    setDisabled(false);
                }else{
                    resp.data.cumplePolitica = 0;

                    resp.data.unidadParaInventario = 0;
                    resp.data.confirmaUnidadParaInventario = 0;
                    setDisabled(true);
                }
                setInitialValue(resp.data);
            })
            .catch(err => {

            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const [valorConfirmar, setValorConfirmar] = useState(0);
        const prevSubmitForm = (values) => {
            if(values.valorUnidadAutorizado){
                setModalMessage({ isOpen: true, type: 3, data: values });
            }else{
                submitForm(values);
            }
        }

        const submitForm = async (values) => {
            values.valuacionValoracionUnidadStatusId = 20;
            if(values.concluido){
                values.valuacionValoracionUnidadStatusId = 30;
            }
            if(values.valorUnidadAutorizado){
                values.valuacionValoracionUnidadStatusId = 40;
            }

            // return false;
            values.valorMercado = values.valorMercado.replace(/[$,]/g,'');
            values.costoReparacion = values.costoReparacion.replace(/[$,]/g,'');
            values.adeudos = values.adeudos.replace(/[$,]/g,'');
            values.utilidad = values.utilidad.replace(/[$,]/g,'');
            values.valorUnidad = values.valorUnidad.replace(/[$,]/g,'');
            values.confirmaValorMercado = values.confirmaValorMercado.replace(/[$,]/g,'');

            setLoading(true);
            await Valuaciones.valuacionValoracionUnidadActualizar(values)
            .then(resp => {
                resp.data.cumplePolitica = resp.data.cumplePolitica === null ? null : (resp.data.cumplePolitica ? 1 : 0);
                resp.data.unidadParaInventario = resp.data.unidadParaInventario === null ? null : (resp.data.unidadParaInventario ? 1 : 0);
                resp.data.confirmaUnidadParaInventario = resp.data.confirmaUnidadParaInventario === null ? null : (resp.data.confirmaUnidadParaInventario ? 1 : 0);
                resp.data.fechaAltaField = getDateFormat(resp.data.fechaAlta, "");

                resp.data.valorMercado = resp.data.valorMercado === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.valorMercado);
                resp.data.costoReparacion =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.costoReparacion);
                resp.data.adeudos =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.adeudos);
                resp.data.utilidad =  Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.utilidad);
                resp.data.valorUnidad =  resp.data.valorUnidad === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.valorUnidad);
                resp.data.confirmaValorMercado =  resp.data.confirmaValorMercado === null ? "" : Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(resp.data.confirmaValorMercado);

                if(Number(nombreYear) >= Number(resp.data.politicaParam))
                {
                    resp.data.cumplePolitica = 1;
                    setDisabled(false);
                }else{
                    resp.data.cumplePolitica = 0;

                    resp.data.unidadParaInventario = 0;
                    resp.data.confirmaUnidadParaInventario = 0;
                    setDisabled(true);
                }
                setInitialValue(resp.data);


                if(!values.valorUnidadAutorizado){
                    toast.success(message("Se actualizó el proceso de valoración correctamente"));
                }else{
                    toast.success(message("Se finalizó el proceso de valuación correctamente"));
                }
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar el proceso de valoración!"));
            })
            .finally(() => setLoading(false));
        }

        const calculateValorUnidad = (value, fn) => {
            if(value === "" || value === 0){
                return false;
            }

            let compra = 0;
            let costoReparacion = initialValue.costoReparacion;
            let adeudos = initialValue.adeudos;
            let utilidad = initialValue.utilidad;

            costoReparacion = costoReparacion.replace(/[$,]/g,'');
            adeudos = adeudos.replace(/[$,]/g,'');
            utilidad = utilidad.replace(/[$,]/g,'');

            compra = parseFloat(value) - parseFloat(costoReparacion) - parseFloat(adeudos) - parseFloat(utilidad);
            compra = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(compra);
            let newValue = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            
            fn("valorMercado", newValue);
            
            setInitialValue({
                ...initialValue,
                valorUnidad: compra
            })

        }

        const removeChars = (field, value, fn) => {
            let newValue = value.replace(/[$,]/g,'');
            fn(field, newValue);
        }

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <>
                        <div>
                            <div className='justify-content-start px-4 py-3 align-items-center'>
                                <div className="row">
                                    <div className="col col-12">
                                        <h6><strong>Confirmar valor final de la unidad</strong></h6>
                                    </div>
                                </div>
                                <div className="row px-2 mt-3 mb-2">
                                    <div className="col col-12 py-2">
                                        <span id="AT_TxtBasicModalMenssage">¿Estás seguro que deseas autorizar el valor final de la unidad por un monto de { modalMessage.data.confirmaValorMercado }?</span>
                                    </div>
                                </div>
                                
                                <section className='d-flex justify-content-end'>
                                    <button id="AT_BtnConfirmModal" className='btn btn-gray d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px', marginRight: '10px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Regresar</button>
                                    <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { 
                                        setModalMessage({ ...modalMessage, isOpen: false })
                                        submitForm(modalMessage.data)
                                    }}>Confirmar</button>
                                </section>
                            </div>
                        </div>
                    </>
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseValoracion" role="button" aria-expanded="false" aria-controls="collapseValoracion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Valoración de unidad</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.valuacionValoracionUnidadStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseValoracion">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Primera valoración</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.valuacionValoracionUnidadStatusId === 40}
                                                                    onClick={() => prevSubmitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="valorMercado" disabled={initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40} label="Valor de mercado ($)" holder="" name="valorMercado" type="text" 
                                                        onBlur={(event) => {
                                                            calculateValorUnidad(event.target.value, setFieldValue);
                                                        }}
                                                        onClick={(event) => {
                                                            removeChars("valorMercado", event.target.value, setFieldValue);
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="costoReparacion" disabled={true} label="Costo de reparaciones ($)" holder="" name="costoReparacion" type="text" onChange={(event) => {
                                                            setFieldValue("costoReparacion", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="adeudos" disabled={true} label="Adeudos ($)" holder="" name="adeudos" type="text" onChange={(event) => {
                                                            setFieldValue("adeudos", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={true} label="Utilidad ($)" holder="" name="utilidad" type="text" onChange={(event) => {
                                                            setFieldValue("utilidad", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="valorUnidad" disabled={true} label="Valor de la unidad ($)" holder="" name="valorUnidad" type="text" onChange={(event) => {
                                                            setFieldValue("valorUnidad", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="cumplePolitica"
                                                            label="Cumple política de año?"
                                                            disabled={true}
                                                            name="cumplePolitica"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("cumplePolitica", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="unidadParaInventario"
                                                            label="Unidad para inventario"
                                                            disabled={disabled || initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            name="unidadParaInventario"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadParaInventario", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="usuarioAltaNombre" disabled={true} label="Usuario" holder="" name="usuarioAltaNombre" type="text" onChange={(event) => {
                                                            setFieldValue("usuarioAltaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="fechaAltaField" disabled={true} label="Fecha y hora de registro" holder="" name="fechaAltaField" type="text" onChange={(event) => {
                                                            setFieldValue("fechaAltaField", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Valor unidad concluido</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Confirmar valor de unidad</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="confirmaValorMercado" disabled={!initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40} label="Valor Final de la Unidad ($)" holder="" name="confirmaValorMercado" type="text" 
                                                        onBlur={(event) => {
                                                            let newValue = 0;
                                                            if(event.target.value !== ""){
                                                                newValue = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(event.target.value);
                                                            }
                                                            setFieldValue("confirmaValorMercado", newValue)
                                                        }}
                                                        onClick={(event) => {
                                                            removeChars("confirmaValorMercado", event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="confirmaUnidadParaInventario"
                                                            label="Unidad para inventario"
                                                            disabled={disabled || !initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            name="confirmaUnidadParaInventario"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("confirmaUnidadParaInventario", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosValorUnidad"
                                                            label="Comentarios"
                                                            disabled={!initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosValorUnidad"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosValorUnidad", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={!initialValue.concluido || initialValue.valuacionValoracionUnidadStatusId === 40} type="checkbox" name="valorUnidadAutorizado" onClick={(event) => {
                                                            setFieldValue("valorUnidadAutorizado", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Valor unidad autorizado</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default ValoracionUnidadCard