import axios from '../../Utils/ApiService';

const VehiculoService = {
    getAllMarca:()=> {
        return axios.get(`/catalogs/Marca`);
    }, 
    getAllModeloById:(marca)=> {
        return axios.get(`/catalogs/Modelo?ClaveMarca=${marca}`);
    }, 
    getAllYearById:(marca,modelo)=> {
        return axios.get(`/catalogs/Year?ClaveMarca=${marca}&ClaveModelo=${modelo}`);
    },
    getAllVersionById:(marca,modelo,year)=> {
        return axios.get(`/catalogs/Version?ClaveMarca=${marca}&ClaveModelo=${modelo}&ClaveYear=${year}`);
    },
    addVehiculo:(data)=>{
        return axios.post(`/catalogs/VehiculoAdd`,data);
    },
    updateVehiculo:(data)=>{
        return axios.put(`/catalogs/VehiculoUpd`,data);
    },
    addMarca:(marca)=>{
        return axios.post(`/catalogs/MarcaAdd?NombreMarca=${marca}`);
    },
    addModelo:(modelo,claveMarca)=>{
        return axios.post(`/catalogs/ModeloAdd?NombreModelo=${modelo}&ClaveMarca=${claveMarca}`);
    },
    addYear:(year,claveModelo,claveMarca)=>{
        return axios.post(`/catalogs/YearAdd?NombreYear=${year}&ClaveMarca=${claveMarca}&ClaveModelo=${claveModelo}`);
    },  
    addVersion:(version,claveModelo,claveMarca,claveYear)=>{
        return axios.post(`/catalogs/VersionAdd?NombreVersion=${version}&ClaveMarca=${claveMarca}&ClaveModelo=${claveModelo}&ClaveYear=${claveYear}`);
    },   
    getAllStatusDatamovil:()=> {
        return axios.get(`/catalogs/StatusDatamovil?page=1&rows=100`);
    }, 
    getAllHistoryDataMovil:(id,page,rows)=> {
        return axios.get(`/functions/GetHistorialDatamovil?GeneralId=${id}&page=${page}&Rows=${rows}`);
    }, 
    getAllHistoryCambioUbicacion:(id,page,rows)=> {
        return axios.get(`/functions/GetHistorialCambioUbicacion?GeneralId=${id}&page=${page}&Rows=${rows}`);
    }, 
    getAllHistoryVehiculoUtilitario:(id,page,rows)=> {
        return axios.get(`/functions/GetHistorialUtilitarios?GeneralId=${id}&page=${page}&Rows=${rows}`);
    }, 
    addVehiculoUtilitario:(data)=>{
        return axios.post(`/catalogs/AddVehiculoUtilitario`,data);
    },  
    getAllUsarios:()=> {
        return axios.get(`/catalogs/GetCSUsuariosUtilitarios`);
    },   
    updDesvincularVehiculo:(id)=>{
        return axios.put(`/catalogs/DesvincularVehiculoUtilitario?GeneralId=${id}`);
    },    
    getAllHistoryCotizacionMensual:(id,page,rows)=> {
        return axios.get(`/functions/GetHistorialSegurosMensual?page=${page}&Rows=${rows}&GeneralId=${id}`);
    },   
    getAllHistoryRenovacionSeguro:(id,page,rows)=> {
        return axios.get(`/functions/HistorialRenovacionSeguro?page=${page}&Rows=${rows}&GeneralId=${id}`);
    },  
    getAllHistoryPlacas:(id,page,rows)=> {
        return axios.get(`/functions/GetHistorialPlacas?page=${page}&Rows=${rows}&GeneralId=${id}`);
    },  
}

export default VehiculoService