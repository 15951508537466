import Modal from "../../../Components/Modal/Modal";
import { useDispatch } from "react-redux";
import Select from "react-select";

const ModalEstatusGestoria = ({
  open,
  setOpen,
  item,
  seguimiento,
  handleSelectSeguimiento,
  setGestoria,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal isOpen={open} color="#fff">
      <section className="my-4 mx-4" style={{ width: "25rem" }}>
        <section className="py-2 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <span className="fs-5">
              <strong>Estatus de gestoría</strong>
            </span>
          </div>
          <span className="mt-2">
            A continuación selecciona el estatus de gestoría del vehículo:{" "}
          </span>
        </section>
        <br />

        <div className="mb-3 row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <ul style={{ "padding-left": "0px", "list-style": "none" }}>
              <li>
                <span style={{ fontWeight: "bold" }}>VIN: </span>
                <span>{item.vin}</span>
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Marca:</span>
                <span>{item.nombreMarca}</span>
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Modelo:</span>
                <span>{item.nombreModelo}</span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: "bold" }}>Año:</span>
                <span>{item.nombreYear}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-sm-12 col-md-8 col-lg-8">
            <span>Gestoria</span>
            <Select
              options={seguimiento}
              className="col-12"
              onChange={(e) => {
                setGestoria(e.value);
              }}
              placeholder="Seleccione"
            />
          </div>
        </div>
        <br />
        <section className="my-3 d-flex justify-content-end">
          <fieldset
            className="btn btn-outline mx-1 px-5"
            type="reset"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancelar
          </fieldset>
          <button
            className="btn mx-1 px-5"
            onClick={() => {
              handleSelectSeguimiento();
            }}
          >
            Continuar
          </button>
        </section>
      </section>
    </Modal>
  );
};

export default ModalEstatusGestoria;
