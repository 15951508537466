import { useEffect, useState } from "react";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";

const EstadisticasHook = () => {
    const [estadistica, setEstadistica] = useState([]);
    const [servicios, setServicios] = useState(0);
    const [progreso, setProgreso] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getEstadistica();
    }, []);

    const getEstadistica = async () => {
        setLoading(true);
        await DSAsignaciones.getEstadisticas()
            .then(resp => {
                let cantidadServicios = resp.data[0].totalServicios;
                let progresoPorciento = resp.data[0].progresoPorciento / 100;


                let items = [];
                resp.data.forEach(item => {
                    if(item.tipoServicioId !== null) items.push(item);
                });
                setEstadistica(items);
                setServicios(cantidadServicios);
                setProgreso(progresoPorciento);
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        estadistica,
        loading,
        servicios,
        progreso,
    }
}

export default EstadisticasHook;