import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import DataTable from '../datatable/DataTable';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatNumberMoney2, getDateFormat, message } from '../../Utils/functions';
import AgregarAdeudoModal from './AgregarAdeudoModal';
import AgregarPagoModal from './AgregarPagoModal';
import AuthLogin from '../../Utils/AuthLogin';
import dataDocsServices from '../../Services/DataDocs/dataDocsServices';
import { useSelector } from 'react-redux';
import Garantias from '../../Services/Cartera/Garantias';
import documentsHelper from '../../Utils/documents';
import VisorFile from '../VisorFile';

const auth = new AuthLogin();
const PagoTramitesModal = ({ modalMessage, setModalMessage, handleOk }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    let url = auth.UrlDataDocs();
    const { clienteSiniestro,
        contratoAcendes } = useSelector((state) => state.reloadSlice);
    let userName = auth.getNombreUsuario();
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);

    const initial = {}
    const [initialValue, setInitialValue] = useState(initial)
    const [totalAdeudos, setTotalAdeudos] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);
    const [montoFinanciar, setMontoFinanciar] = useState(0);
    const [pagosData, setPagosData] = useState([]);
    const [adeudosData, setAdeudosData] = useState([]);
    const [idAdeudo, setidAdeudo] = useState("");
    const [isOpenModalAdeudo, setIsOpenModalAdeudo] = useState(false);
    const [isOpenModalPago, setIsOpenModalPago] = useState(false);

    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);

    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const columnsAdeudos = [
        { field: 'orden', headerName: 'No. Orden', width: '120' },
        { field: 'usuarioEjecutivoNombre', headerName: 'Ejecutivo', width: '120' },
        { field: 'tipoAdeudo', headerName: 'Adeudo', width: '100' },
        // { field: 'fechaAdeudo', headerName: 'Fecha de adeudo', width: '300' },
        { field: 'subtotal', headerName: 'Subtotal', width: '140' },
        { field: 'iva', headerName: 'IVA', width: '100' },
        { field: 'total', headerName: 'Total', width: '100' },
        { field: 'fechaAlta', headerName: 'Fecha de registro', width: '300' },
    ];

    const columnsPagos = [
        { field: 'folio', headerName: 'Folio de pago', width: '100' },
        { field: 'fechaAlta', headerName: 'Fecha y hora', width: '300' },
        { field: 'usuarioEjecutivoNombre', headerName: 'Ejecutivo', width: '100' },
        { field: 'nota', headerName: 'Notas', width: '180' },
        { field: 'tipoPago', headerName: 'Método de pago', width: '180' },
        { field: 'monto', headerName: 'Monto', width: '120' },
        { field: 'descarga', headerName: 'Recibo' },
        // { field: 'verEvidencia', headerName: 'ver Evidencia', width: 120 },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ];

    const validate = {}

    const motivos = [
        { value: 1, label: 'Dummy 1' },
        { value: 2, label: 'Dummy 2' },
    ];
    useEffect(() => {
        getPagoTramites();
    }, [])

    const getPagoTramites = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesGet(id)
            .then((response) => {

                let pagos = [];
                let adeudos = [];
                let totalPagos = 0;
                let totalAdeudos = 0;
                response.data.pagos.map((item, index) => {
                    item.folio = index + 1;
                    totalPagos += item.monto;
                    pagos.push(item);
                });
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                response.data.adeudos.map((item, index) => {
                    item.orden = index + 1;
                    item.total = (item.subtotal * item.iva) / 100 + item.subtotal;
                    totalAdeudos += item.total;
                    adeudos.push(item);
                });
                //Actualizar los valores a formato moneda en data.pagos
                response.data.pagos.map((item) => {
                    item.monto = FormatNumberMoney2(item.monto);
                    item.fechaAlta = getDateFormat(item.fechaAlta, '');
                });

                //Actualizar los valores a formato moneda en data.adeudos
                response.data.adeudos.map((item) => {
                    item.subtotal = FormatNumberMoney2(item.subtotal);
                    item.iva = FormatNumberMoney2(item.iva);
                    item.total = FormatNumberMoney2(item.total);
                    item.fechaAlta = getDateFormat(item.fechaAlta, "");
                    item.fechaAdeudo = getDateFormat(item.fechaAdeudo, "");
                });

                setTotalAdeudos(totalAdeudos);
                setTotalPagos(totalPagos);
                setMontoFinanciar(totalAdeudos - totalPagos);
                // response.data.sol_id = response.data?.sol_id ?? "";
                // response.data.Contrato = state?.numContrato;
                // response.data.vin = state?.vin;
                // response.data.aplicaFinanciamiento = response.data.aplicaFinanciamiento ?? "";

                // response.data.fechaAlta = response.data.fechaAlta?.split("T")[0];
                // response.data.fechaActualiza = response.data.fechaActualiza?.split("T")[0];
                // response.data.fechaInicio = response.data.fechaInicio?.split("T")[0];
                // response.data.fechaFin = response.data.fechaFin?.split("T")[0];

                //setInitialValue(response.data); //3=valuacion , 4= PerdidaTotal , 5= Reparacion  

                setPagosData(pagos);
                setAdeudosData(adeudos);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }
    const handleEdit = (item) => {
        setidAdeudo(item.siniestroPagoTramitesAdeudoId);
        setIsOpenModalAdeudo(true)
    }
    const handleDelete = (item) => {
        Siniestros.siniestroPagoTramitesAdeudoDelete(item.siniestroPagoTramitesAdeudoId)
            .then(resp => {
                if (resp.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", resp.data.data.mensaje));
                    getPagoTramites();
                } else {
                    toast.error(message("¡Operación fallida!", resp.data.data.mensaje));
                }
            }).catch(error => {
                toast.error(message("¡Operación fallida!", "Error al agregar un adeudo"));
                console.log(error)
            })
    }
    //tbl Pagos
    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }
    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/seguro/siniestros/datadocs/fSiniestro/eContPago/${state?.folio}/${id}`, {
                state: { ...value, registroId: value.siniestroPagoTramitesPagoId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    }
    const handleDownload = async (data) => {
        const requestBody = {
            nombrePlantilla: "Recibo",
            parametros: {
                "<<VARIABLE_FECHA>>": data.fechaAlta ? data.fechaAlta.split(" ")[0] : "",
                "<<VARIABLE_NOMBRE>>": clienteSiniestro,
                "<<VARIABLE_NOMBRE_USUARIO>>": userName,
                "<<VARIABLE_CONTRATO>": contratoAcendes,
                "<<VARIABLE_CANTIDAD>>": data.monto,
                "<<VARIABLE_CONCEPTO>>": data.nota,
                "<<VARIABLE_TELEFONO>>": "",
                "<<VARIABLE_DIRECCION_UBICACION>>": "",
            }
        };

        await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            //Pendiente de revisar por que no jala con el file-server                
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
        }).catch(err => {
            console.error(err);
        });
    };
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}


            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <Tabs>
                                            <Tab title="Adeudos">
                                                <DataTable
                                                    data={adeudosData}
                                                    column={columnsAdeudos}
                                                    editable={true}
                                                    handleEdit={handleEdit}
                                                    deleteable={true}
                                                    handleDelete={handleDelete}
                                                />
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <button
                                                        type='reset'
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-outline me-2'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            setidAdeudo("");
                                                            setIsOpenModalAdeudo(true)
                                                        }}
                                                    >
                                                        Registrar adeudo
                                                    </button>
                                                    <div>
                                                        <strong>Total: ${FormatNumberMoney2(totalAdeudos)}</strong>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab title="Pagos">
                                                <DataTable
                                                    data={pagosData}
                                                    column={columnsPagos}
                                                    handleDownload={handleDownload}
                                                    handleDocuments={handleViewdocs}
                                                    handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                />
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <button
                                                        type='reset'
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-outline me-2'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => { setIsOpenModalPago(true) }}
                                                    >
                                                        Registrar pago
                                                    </button>
                                                    <div>
                                                        <strong>Total: ${FormatNumberMoney2(totalPagos)}</strong>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-4">
                                            <div className="row">
                                                <strong>Balance</strong>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <table id='tbl_balance' className='col-12'>
                                                        <thead>
                                                            <tr>
                                                                <th>Concepto</th>
                                                                <th>Monto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Adeudos</td>
                                                                <td>${FormatNumberMoney2(totalAdeudos)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pagos cliente</td>
                                                                <td>${FormatNumberMoney2(totalPagos)}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td>Pendiente</td>
                                                                <td>${FormatNumberMoney2(totalAdeudos - totalPagos)}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto d-flex">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline me-2'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cerrar
                                        </button>
                                        {/* <button
                                            type='submit'
                                            id="AT_BtnCloseModal"
                                            className='btn'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleOk(values)}
                                        >
                                            Guardar
                                        </button> */}
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>

            {
                isOpenModalAdeudo &&
                <AgregarAdeudoModal
                    modalMessage={modalMessage}
                    getPagoTramites={getPagoTramites}
                    setModalMessage={setModalMessage}
                    siniestroPagosData={{ ...state, siniestroId: id }}
                    idAdeudo={idAdeudo}
                    setIsOpenModalAdeudo={setIsOpenModalAdeudo}
                    isOpenModalAdeudo={isOpenModalAdeudo}
                />
            }
            {
                isOpenModalPago &&
                <AgregarPagoModal
                    modalMessage={modalMessage}
                    getPagoTramites={getPagoTramites}
                    setModalMessage={setModalMessage}
                    siniestroPagosData={{ ...state, siniestroId: id }}
                    idAdeudo={idAdeudo}
                    setIsOpenModalPago={setIsOpenModalPago}
                    isOpenModalPago={isOpenModalPago}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
        </>
    );
}

export default PagoTramitesModal;
