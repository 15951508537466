import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useSeguroPagado from '../../../Hooks/Seguros/useSeguroPagado'
import usePolizasVigentes from '../../../Hooks/Seguros/usePolizasVigentes'
import usePagoRefrendoPlacas from '../../../Hooks/Gestoria/PagoRefrendoPlacas/usePagoRefrendoPlacas'
import MultiSelect from '../../../Components/DropDown'
import NuevoTramiteModal from './NuevoTramiteModal'
const PagoRefrendoPlacas = () => {
    const {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,    
        setpageRows,        
        selectRef,
        handleDetail,     
        setIsOpenModal,
        isOpenModal,
        dataTramite,
        setClavetipoTramite,
        state,
        handleChange,
        dataStatusTramite
    } = usePagoRefrendoPlacas(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Pago refrendo y placas</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Agregar}>
                        <button className='btn' onClick={()=>{setIsOpenModal(true)}}>Agregar trámite</button>
                    </ValidatePermission>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={selectRef} options={dataTramite} className='col-12' onChange={(e) => { setClavetipoTramite(e.value) }} placeholder="Tipo de trámite" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 my-1 px-1'>
                            {/* <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" /> */}
                            <MultiSelect
                                holder={"Estatus"}
                                className={'col-12 mx-1'}
                                value={state}
                                options={dataStatusTramite}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        detailable={ValidatePermissionById(4, Section.PagoRefrendoPlacasSeccion, SubModule.PagoRefrendoPlacas)?.Ver}
                        handleDetail={handleDetail}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenModal &&
                <NuevoTramiteModal
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    tipoTramite={dataTramite}
                    toast={toast}
                />
            }
        </>
    )
}

export default PagoRefrendoPlacas