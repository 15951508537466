import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActiveEnganche } from '../../../Redux/Slice/NavigateSliceEnganche';

const SubMenuEnganche = () => {
  const {value} = useSelector((state) => state.tabActiveEnganche);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  
  useEffect(() => {
    handleVigate(value);
  }, [value])
  

  const handleSetStateTab = (value) =>{
    dispatch(ActiveEnganche(value));   
  }
  const handleVigate = (value) =>{
    if(value === 1){
      navigate(`/inventario/enganche/pendienteenganche`);
    }  
    if(value === 2){
      navigate(`/inventario/enganche/enganchecompleto`);
    }        
  }

  return (
    <>

    <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab"  onClick={(e)=>{handleSetStateTab(e.target.value)}}>Pendiente de enganche</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab"  onClick={(e)=>{handleSetStateTab(e.target.value)}}>Enganche completo</button>
                </li>                          
            </ul>
    </>
  )
}

export default SubMenuEnganche