import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoCierre from '../../Services/Cierre/SeguimientoCierre';
import Catalogo from '../../Services/Catalogos/Catalogo';

const useSeguimientoCierre = (toast) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial =
    {
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    }


    const [dataValue, setDataValue] = useState(initial);
    const [loading, setloading] = useState(false);
    const [cierreContrato, setCierreContrato] = useState([])

    const columns = [
        { field: 'vin', headerName: 'VIN' },
        { field: 'numContrato', headerName: 'Contrato' },
        { field: 'cierreContratoProceso', headerName: 'Proceso' },
        { field: 'subproceso', headerName: 'SubProceso' },
        { field: 'fecha', headerName: 'Fecha' },
        { field: 'estatus', headerName: 'Estatus', width: 350 },
        { field: 'subestatus', headerName: 'Subestatus' },
        { field: 'ultimaActualizacion', headerName: 'Últ. Actualización' },
    ];

    useEffect(() => {
        getData({
            page: 1,
            rows: 10
        });
        getCierreContratoProceso();
    }, [])

    const getData = async (values) => {
        setloading(true)
        values.page = values.selected + 1;
        await SeguimientoCierre.getSeguimientoCierre(values) //state.procesoId
            .then((res) => {
                setDataValue(res.data)
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setloading(false)
            })
    }

    const handleEdit = () => {

    }

    const downloadExcel = () => {

    }

    const getCierreContratoProceso = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoProceso')
            .then(res => {
                const cierreContratoArray = res.data.map(item => ({
                    value: item.CierreContratoProcesoId,
                    label: item.CierreContratoProceso.trim()  // Usamos trim para eliminar espacios extras si es necesario
                }));
                setCierreContrato(cierreContratoArray);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    };

    return {
        columns,
        dataValue,
        setDataValue,
        getData,
        handleEdit,
        downloadExcel,
        loading,
        cierreContrato
    }
}

export default useSeguimientoCierre