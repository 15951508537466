import axios from '../../Utils/ApiService';

const Solicitudes = {
    getAmpliacionesExpediente: ({ buscar, page, rows }) => {
        return axios.get(`/cartera/getSeguimientoCierreContratoAmpliacionExpediente?buscar=${buscar}&page=${page}&rows=${rows}`);
    },
    getDevolucionesSeguimiento: ({ buscar, procesoId, fechaInicio, fechaFin, concluido, page, rows }) => {
        let query = '';

        if(buscar.trim().length > 0) query += `Buscar=${buscar}&`;
        if(procesoId.trim().length > 0) query += `ProcesoId=${procesoId}&`;
        if(fechaInicio.trim().length > 0) query += `FechaInicio=${fechaInicio}&`;
        if(fechaFin.trim().length > 0) query += `FechaFin=${fechaFin}&`;
        if(concluido === true) query += `Concluido=1&`;

        query += `Page=${page}&Rows=${rows}`;
        return axios.get(`/cartera/SeguimientoCierreContratoDevoluciones?${query}`);
    },
    putDevolucion: (data) => {
        return axios.put(`/cartera/CierreContratoDevolucion`, data);
    }
};

export default Solicitudes;