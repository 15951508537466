import React, { useState, useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import { useNavigate } from 'react-router-dom';
import { message } from '../../../../Utils/functions';
import CobroComisionesExternoService from '../../../../Services/Inventario/Comisiones/CobroComisionesExternoService';


const UploadFileDetail = ({ isOpenModal, setIsOpenModal, handleSubmit, item, toast }) => {
    const [IsFilePicked, setIsFilePicked] = useState();
    const [SelectedFile, setSelectedFile] = useState("");
    const [JsonData, setJsonData] = useState("");
    const fileRef = useRef();
    const navigate = useNavigate();
    let initial = {
        comentarios: "",
        pago: "",
        folio: item?.folio ?? "",
        vin: item?.vin ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const validate = Yup.object({
        comentarios: Yup.string()
            .required("El campo es requerido."),
        pago: Yup.number()
            .required("El campo es requerido.")
    });

    const handleUploadFile = () => {     
        navigate(`/inventario/comisiones/datadocs/${item?.generalId}`, {
            state: item,
          });
    };

    const convertData = async (value) => {
        if (!item.subido) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir documento de evidencia.")}</div>);
            return;
        }
        await CobroComisionesExternoService.SaveProcesoCobroComisionExternoDetalle(value)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setIsOpenModal(false)
                    handleSubmit(1);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                }else{
                    setIsOpenModal(false);                    
                    toast.error(message("!Error.!", res.data.data.mensaje));
                }
            }).catch(e => {
                setIsOpenModal(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                convertData(values);
            }}
        >
            {() => (
                <Modal isOpen={isOpenModal} color='#fff'>
                    <Form>
                        <div className='container-buttons'>
                            <h4><strong>Cobro de comisiones</strong></h4>
                            <span style={{ fontSize: '13px' }}>
                                ¿Estás seguro de que deseas capturar el cobro de comisión de: <br /> <strong>Folio: {item?.folio} VIN: {item?.vin} Razón Social: {item?.razonSocial} Comisión: {item?.comisionStr} </strong>
                            </span>
                            <div className="mt-3">
                                <div className="col-sm-6">
                                    <TextField id="montoPagadoId" label="Monto pagado" name="pago" type="number" holder="Escribe" />
                                </div>

                                <span><strong>1.Subir Archivo</strong></span>
                                <div className="my-3 row">
                                    <section className='d-flex align-items-end'>
                                        <fieldset className='col-6 btn' onClick={handleUploadFile}>Subir archivo</fieldset>
                                        {/* <input type="text" className='form-control' disabled value={SelectedFile?.name ?? "Sin archivos seleccionados"} /> */}
                                    </section>
                                </div>
                            </div>
                            <div className="mt-4 row">
                                <span><strong>2. Comentarios</strong></span>
                                <div className="col-sm-12 mb-2">
                                    <TextAreaField
                                        id="comentarios"
                                        label="Comentarios"
                                        className="form-control"
                                        rows="3"
                                        name="comentarios"
                                        type="text"
                                        holder="Escribe"
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end mt-5'>
                                <button className='btn btn-outline col-5 mx-2' type='reset' onClick={() => { setIsFilePicked(false); setIsOpenModal(false); setSelectedFile(null) }}>Cancelar</button>
                                <button className="btn col-5" type="submit">Continuar</button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            )
            }
        </Formik>
    )
}

export default UploadFileDetail
