import React from 'react'
import { pageRows } from '../Utils/functions'
import Select from 'react-select'

const RowPages = ({setpageRows, expand_col_size=false}) => {
    return (
        <div>
            <div className='d-flex justify-content-end align-items-center row col-12 px-4 mx-auto'>
                <div className={`${expand_col_size === false ? "col-lg-2" : "col-lg-3"}  col-md-4 col-sm-12 d-flex justify-content-end`} style={{minWidth: "10rem"}}>
                    <span style={{ fontSize: '12px' }} className=''>Resultado por página</span>
                </div>
                <div className={` ${expand_col_size === false ? "col-lg-1" : "col-lg-2"} col-md-6 col-sm-8 col-12 p-0`} style={{minWidth:"6rem"}}>
                    <Select options={pageRows()} className='col-lg-10' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} />
                </div>
            </div>
        </div>
    )
}

export default RowPages