import Tab from "../../../Components/Tabs/Tab";
import Tabs from "../../../Components/Tabs/Tabs";
import SeguimientoAutorizaciones from "./SeguimientoAutorizaciones";
import SeguimientoCitasServicios from "./SeguimientoCitasServicios";

const SolicitudesDatamovilSystems = () => {

    return (
        <>
            <Tabs>
                <Tab title={'Servicios Datamovil'}>
                    <SeguimientoAutorizaciones />
                </Tab>
                <Tab title={'Citas de Servicios Datamovil'}>
                    <SeguimientoCitasServicios />
                </Tab>
            </Tabs>
        </>
    );

}

export default SolicitudesDatamovilSystems;