import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '053f88fd-ae9a-4e69-87e1-e20f042ae090'
}
export const navigaSliceUser = createSlice({
  name: 'userNavigate',
  initialState,
  reducers: {
    tabActiveUser: (state, action) => {
      return { ...state, value: action.payload }
    }   
  },
})

export const { tabActiveUser } = navigaSliceUser.actions

export default navigaSliceUser.reducer