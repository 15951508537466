import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable';
import PagoAdeudosCard from './PagoAdeudosCard';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import CobroDatamovilModal from '../../../Components/Modal/CobroDatamovilModal';

const RevisionDatamovilCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, datamovilid } = useParams();


        let initial = {
            datamovilRevisionId: "",
            datamovilId: "",
            datamovilRevisionStatusId: 0,
            datamovilRevisionStatus: "",
            color: "default",
            dispositivoAlterado: "",
            comentarios: "",
            equipoCompleto: "",
            comentariosEquipoCompleto: "",
            funciona: "",
            comentariosFuncionamiento: "",
            requiereReparacion: "",
            comentariosReparaciones: "",
            aplicaCosto: "",
            ejecutivo: "",
            boletinado: "",
            comentariosBoletinado: "",
            fechaBoletinado: "",
            concluido: "",
            fechaConclusion: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            status: false,
            fechaAlta: "",
            fechaActualiza: "",
            costos: []
        }

        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [total, setTotal] = useState(0);
        
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [cobros, setCobros] = useState({
            totalRows: 0,
            pageSize: 0,
            currentPage: 1,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            data: [
                { id: 1, fechaMovimiento: "23-02-2024", ejecutivo: "César Ortiz", tipoCosto: "Reparación", concepto: "-",  subtotal: "1000", iva: "150", total: "1150"}
            ]
        });

        
        const columns = [
            { field: 'id', headerName: '#'},
            { field: 'fechaCosto', headerName: 'Fecha y hora'},
            { field: 'ejecutivo', headerName: 'Ejecutivo'},
            { field: 'tipoCosto', headerName: 'Tipo de costo' },
            { field: 'concepto', headerName: 'Concepto'},
            { field: 'subtotal', headerName: 'Subtotal ($)'},
            { field: 'iva', headerName: 'IVA ($)'},
            { field: 'total', headerName: 'Total ($)'},
        ];

        useEffect(() => {
            getRevision();
        }, []);
        

        const [showAdeudo, setShowAdeudo] = useState(false);

        /* CONSULTAS */
        const getRevision = async () => {
            await Datamovil.datamovilRevisionGet(datamovilid)
            .then(resp => {
                setInitialValue(resp.data);
                let items = [];
                let sum = 0;
                resp.data.costos.forEach((item, idx) => {
                    let total = 0;
                    if(item.iva === null){
                        total = parseFloat(item.subtotal);
                    }else{
                        total = parseFloat(item.subtotal)+parseFloat(item.iva);
                    }
                    items.push({
                        id: (idx+1),
                        datamovilRevisionCostoId: item.datamovilRevisionCostoId,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        tipoCosto: item.tipoCosto,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total)
                    })
                    sum += total;
                });
                setTotal(sum);
                resp.data.dispositivoAlterado = resp.data.dispositivoAlterado === null ? null : (resp.data.dispositivoAlterado ? 1 : 0);
                resp.data.equipoCompleto = resp.data.equipoCompleto === null ? null : (resp.data.equipoCompleto ? 1 : 0);
                resp.data.funciona = resp.data.funciona === null ? null : (resp.data.funciona ? 1 : 0);
                resp.data.requiereReparacion = resp.data.requiereReparacion === null ? null : (resp.data.requiereReparacion ? 1 : 0);
                resp.data.aplicaCosto = resp.data.aplicaCosto === null ? null : (resp.data.aplicaCosto ? 1 : 0);
                resp.data.boletinado = resp.data.boletinado === null ? null : (resp.data.boletinado ? 1 : 0);
                setCobros({
                    ...cobros,
                    data: items
                });

                if(resp.data.datamovilRevisionStatusId > 20 && !resp.data.aplicaCosto){
                    setShowAdeudo(true);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const submitForm = async (values) => {
            if(values.concluido){
                if(!values.dispositivoAlterado && !values.boletinado){
                    values.datamovilRevisionStatusId = 40;
                }else if(!values.dispositivoAlterado && values.boletinado){
                    values.datamovilRevisionStatusId = 30;
                }else if(values.dispositivoAlterado && !values.boletinado){
                    values.datamovilRevisionStatusId = 40;
                }else if(values.dispositivoAlterado && values.boletinado){
                    values.datamovilRevisionStatusId = 30;
                }
            }

            if(values.dispositivoAlterado){
                const date = new Date();
                const localDate = date.toLocaleString();
                const dateFormat = localDate.split(",")[0];
                const hourFormat = localDate.split(",")[1].trim();
                
                const contenidoEmail = {
                    VARIABLE_CONTRATO: numContrato,
                    VARIABLE_VIN: vin,
                    VARIABLE_MARCA: nombreMarca,
                    VARIABLE_MODELO: nombreModelo,
                    VARIABLE_AÑO: nombreYear,
                    VARIABLE_VERSION: nombreVersion,
                    VARIABLE_FECHA: ("0"+dateFormat[0]).slice(-2)+"-"+("0"+dateFormat[1]).slice(-2)+"-"+dateFormat[2],
                    VARIABLE_HORA: ("0"+hourFormat[0]).slice(-2)+":"+("0"+hourFormat[1]).slice(-2)+":"+("0"+hourFormat[2]).slice(-2),
                    VARIABLE_MOTIVO: "",
                    VARIABLE_COMENTARIO: "",
                }
                
                Garantias.creardatosenviar({
                    modulo: 'Dispositivo Alterado',
                    tipoCorreoId: 55,
                    contenido: JSON.stringify(contenidoEmail)
                }).catch(err => {
                    console.log("Error al enviar correo Dispositivo Alterado:", err);
                })
            }


            await Datamovil.datamovilRevisionActualizar(values)
            .then(resp => {
                setInitialValue(resp.data);
                let items = [];
                let sum = 0;
                resp.data.costos.forEach((item, idx) => {
                    let total = 0;
                    if(item.iva === null){
                        total = parseFloat(item.subtotal);
                    }else{
                        total = parseFloat(item.subtotal)+parseFloat(item.iva);
                    }
                    items.push({
                        id: (idx+1),
                        datamovilRevisionCostoId: item.datamovilRevisionCostoId,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        tipoCosto: item.tipoCosto,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total)
                    })
                    sum += total;
                });
                setTotal(sum);
                resp.data.boletinado = resp.data.boletinado === null ? null : (resp.data.boletinado ? 1 : 0);
                resp.data.dispositivoAlterado = resp.data.dispositivoAlterado === null ? null : (resp.data.dispositivoAlterado ? 1 : 0);
                resp.data.equipoCompleto = resp.data.equipoCompleto ? 1 : 0;
                resp.data.funciona = resp.data.funciona ? 1 : 0;
                resp.data.requiereReparacion = resp.data.requiereReparacion ? 1 : 0;
                resp.data.aplicaCosto = resp.data.aplicaCosto ? 1 : 0;
                setCobros({
                    ...cobros,
                    data: items
                });
                toast.success(message("Se actualizó la revisión datamovil correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la revisión datamovil!"));
                console.error(err);
            }).finally(() => {
                if(values.concluido && values.dispositivoAlterado && !values.aplicaCosto){
                    crearAdeudos();
                }
            });
        }

        const submitFormAplicaCosto = async (values) => {
            if(initialValue.aplicaCosto !== values.aplicaCosto){
                await Datamovil.datamovilRevisionActualizar(values)
            .then(resp => {
                setInitialValue(resp.data);
                let items = [];
                let sum = 0;
                resp.data.costos.forEach((item, idx) => {
                    let total = 0;
                    if(item.iva === null){
                        total = parseFloat(item.subtotal);
                    }else{
                        total = parseFloat(item.subtotal)+parseFloat(item.iva);
                    }
                    items.push({
                        id: (idx+1),
                        datamovilRevisionCostoId: item.datamovilRevisionCostoId,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        tipoCosto: item.tipoCosto,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total)
                    })
                    sum += total;
                });
                setTotal(sum);
                resp.data.boletinado = resp.data.boletinado === null ? null : (resp.data.boletinado ? 1 : 0);
                resp.data.dispositivoAlterado = resp.data.dispositivoAlterado === null ? null : (resp.data.dispositivoAlterado ? 1 : 0);
                resp.data.equipoCompleto = resp.data.equipoCompleto ? 1 : 0;
                resp.data.funciona = resp.data.funciona ? 1 : 0;
                resp.data.requiereReparacion = resp.data.requiereReparacion ? 1 : 0;
                resp.data.aplicaCosto = resp.data.aplicaCosto ? 1 : 0;
                setCobros({
                    ...cobros,
                    data: items
                });
                toast.success(message("Se actualizó la revisión datamovil correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la revisión datamovil!"));
                console.error(err);
            });
            }
        }
        const crearAdeudos = async () => {
            let params = {
                datamovilId: datamovilid,
                datamovilAdeudosStatusId: 20,
                adeudo: total,
                status: true
            };

            await Datamovil.datamovilAdeudosCrear(params)
            .then(resp => {
                setTimeout(() => {
                    setShowAdeudo(true);
                }, 1000);
            })
            .catch(err => {
                console.error(err);
            });
        }

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const resetForm = () => {
            refFormik.current.resetForm();
            setInitialValue({
                ...initialValue,
                sucursalName: "",
            });
        }

        const [disabledBoletinado, setDisabledBoletinado] = useState(false);
        useEffect(() => {
            setInitialValue({
                ...initialValue,
            })
        }, [initialValue.equipoCompleto]);
        
        useEffect(() => {
            setInitialValue({
                ...initialValue,
            })
        }, [initialValue.funciona]);
        
        useEffect(() => {
            setInitialValue({
                ...initialValue,
            })
        }, [initialValue.requiereReparacion]);

        const forzarBoletinadoEquipo = (value, values) => {
            if(value === 0){
                setInitialValue({
                    ...values,
                    equipoCompleto: value,
                    boletinado : 1
                })
                setDisabledBoletinado(true);
            }else if(value === 1){
                if(value === 1 && values.funciona === 1 && values.requiereReparacion === 1){
                    setInitialValue({
                        ...values,
                        equipoCompleto: value,
                        boletinado : null
                    })
                    setDisabledBoletinado(false);
                }else{
                    setInitialValue({
                        ...values,
                        equipoCompleto: value
                    })
                }
            }
        }

        const forzarBoletinadoFunciona = (value, values) => {
            if(value === 0){
                setInitialValue({
                    ...values,
                    funciona: value,
                    boletinado : 1
                })
                setDisabledBoletinado(true);
            }else if(value === 1){
                if(values.equipoCompleto === 1 && value === 1 && values.requiereReparacion === 1){
                    setInitialValue({
                        ...values,
                        funciona: value,
                        boletinado : null
                    })
                    setDisabledBoletinado(false);
                }else{
                    setInitialValue({
                        ...values,
                        funciona: value
                    })
                }
            }
        }

        const forzarBoletinadoReparacion = (value, values) => {
            if(value === 0){
                setInitialValue({
                    ...values,
                    requiereReparacion: value,
                    boletinado : 1
                })
                setDisabledBoletinado(true);
            }else if(value === 1){
                if(values.equipoCompleto === 1 && values.funciona === 1 && value === 1){
                    setInitialValue({
                        ...values,
                        requiereReparacion: value,
                        boletinado : null
                    })
                    setDisabledBoletinado(false);
                }else{
                    setInitialValue({
                        ...values,
                        requiereReparacion: value
                    })
                }
            }
        }

        

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <CobroDatamovilModal
                            modalMessage={modalMessage}
                            handleActualizar={getRevision}
                            revisionId={initialValue.datamovilRevisionId}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRevision" role="button" aria-expanded="false" aria-controls="collapseRevision" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Revisión datamovil</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilRevisionStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseRevision">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de la revisión</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button className='btn btn-gray'>
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.datamovilRevisionStatusId > 20}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="dispositivoAlterado"
                                                            label="Dispositivo datamovil alterado"
                                                            disabled={initialValue.datamovilRevisionStatusId > 20}
                                                            name="dispositivoAlterado"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("dispositivoAlterado", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue.datamovilRevisionStatusId > 20}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Alteración en dispositivo datamovil</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="equipoCompleto"
                                                            label="Equipo completo"
                                                            disabled={initialValue.datamovilRevisionStatusId > 20}
                                                            name="equipoCompleto"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("equipoCompleto", event.value);
                                                                forzarBoletinadoEquipo(event.value, values);
                                                        }} />
                                                    </div>
                                                    {
                                                        values.equipoCompleto === 0 &&
                                                        <div className="col-12 col-lg-7">
                                                            <TextField id="comentariosEquipoCompleto" disabled={initialValue.datamovilRevisionStatusId > 20} label="Comentarios equipo incompleto" name="comentariosEquipoCompleto" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("comentariosEquipoCompleto", event.target.value);
                                                            }} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="funciona"
                                                            label="Equipo funciona"
                                                            disabled={initialValue.datamovilRevisionStatusId > 20}
                                                            name="funciona"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("funciona", event.value);
                                                                forzarBoletinadoFunciona(event.value, values);
                                                        }} />
                                                    </div>
                                                    {
                                                        values.funciona === 0 &&
                                                        <div className="col-12 col-lg-7">
                                                            <TextField id="comentariosFuncionamiento" disabled={initialValue.datamovilRevisionStatusId > 20} label="Comentarios del funcionamiento" name="comentariosFuncionamiento" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("comentariosFuncionamiento", event.target.value);
                                                            }} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="requiereReparacion"
                                                            label="¿Equipo libre de reparaciones?"
                                                            disabled={initialValue.datamovilRevisionStatusId > 20}
                                                            name="requiereReparacion"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("requiereReparacion", event.value);
                                                                forzarBoletinadoReparacion(event.value, values);
                                                        }} />
                                                    </div>
                                                    {
                                                        values.requiereReparacion === 0 &&
                                                        <div className="col-12 col-lg-7">
                                                            <TextField id="comentariosReparaciones" disabled={initialValue.datamovilRevisionStatusId > 20} label="Comentarios de reparación" name="comentariosReparaciones" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("comentariosReparaciones", event.target.value);
                                                            }} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="aplicaCosto"
                                                                label="¿Continuar sin costos adicionales?"
                                                                disabled={initialValue.datamovilRevisionStatusId > 20}
                                                                name="aplicaCosto"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("aplicaCosto", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="usuarioAltaNombre" disabled={true} label="Ejecutivo" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioAltaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Boletinar cliente</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="boletinado"
                                                            label="¿Boletinar cliente?"
                                                            disabled={disabledBoletinado || initialValue.datamovilRevisionStatusId > 20}
                                                            name="boletinado"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("boletinado", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                {
                                                    (initialValue.boletinado === 1 || values.boletinado === 1) &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosBoletinado"
                                                                label="Comentarios ejecutivo"
                                                                disabled={initialValue.datamovilRevisionStatusId > 20}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosBoletinado"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosBoletinado", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    values.aplicaCosto === 0 && // Se cambio a false 
                                                    <>
                                                        <div className="row mt-4 px-4">
                                                            <div className="col-auto">
                                                                <small><strong>Cobros</strong></small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button 
                                                                    className='btn'
                                                                    disabled={initialValue.datamovilRevisionStatusId > 20}
                                                                    onClick={() => {
                                                                        submitFormAplicaCosto(values)
                                                                        setModalMessage({ isOpen: true, type: 3, title: 'Registrar costo' });
                                                                    }}
                                                                    >
                                                                    Registrar cobro
                                                                </button>
                                                            </div>
                                                        </div>                                                
                                                        <div className="row px-4">
                                                            <div className="col-12">
                                                                <DataTable
                                                                    column={columns}
                                                                    data={cobros.data}
                                                                    // editable={true}
                                                                    // deleteable={true}
                                                                    // pageCount={0}
                                                                    // currentPage={1 - 1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4 justify-content-end">
                                                            <div className="col-auto">
                                                                <strong>Total ($): {formatMoney(total)}</strong>
                                                            </div>
                                                        </div>
                                                    </>
                                                }                                                
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.datamovilRevisionStatusId > 20} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Revisión datamovil finalizada</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (initialValue.datamovilRevisionStatusId > 20 &&
                    initialValue.aplicaCosto === 0 &&
                    showAdeudo) &&
                    <PagoAdeudosCard />
                }
            </>
        )
    })
export default RevisionDatamovilCard