import { useEffect, useState } from "react";
import Llamada from "../../Services/Cartera/Llamadas";
import CobranzaService from "../../Services/Cobranza/Cobranza";

const useReferenciasAgregar = () => {
   const [dataEstado, setDataEstado] = useState([])
   // const [dataTipoContacto, setDataTipoContacto] = useState([]);
   // const [dataSubTipoContacto, setDataSubTipoContacto] = useState([]);
   const [relacionSolicitante, setRelacionSolicitante] = useState([{}])

   // const HandleGetTipoContacto = async () => {
   //    await CobranzaService.getAllTipoContacto()
   //       .then(res => {
   //          let tipos = []
   //          res.data.responseData?.map(item => {
   //             if (item.activo) {
   //                   tipos.push({
   //                      value: item.id,
   //                      label: item.nombre
   //                   });
   //             }
   //          });
   //          setDataTipoContacto(tipos);
   //       })
   // }

   // const HandleGetSubtiposContacto = async () => {
   //    await CobranzaService.getAllSubTipoContacto()
   //       .then(res => {
   //          let subtipo = []
   //          res.data?.map(item => {
   //             if (item.activo) {
   //                   subtipo.push({
   //                      value: item.id,
   //                      label: item.nombre
   //                   });
   //             }
   //          });
   //          setDataSubTipoContacto(subtipo);
   //       })
   // }

   const HandleGetEstadoMx = async () => {
      await Llamada.GetEstadoMx().then(res => {
          let Estados = []
          res.data.data?.map(item => {
              Estados.push({
                  value: item.edoMexicoId,
                  label: item.estado
              });
          });
          setDataEstado(Estados);
      })
  }

  const HandleGetAllRelacionSolicitante = async (id) => {
   await CobranzaService.getAllRelacionSolicitante()
      .then(res => {
         let relaciones = []
         res.data?.responseData?.map(item => {
            if (item.activo) {
                  relaciones.push({
                     value: item.id,
                     label: item.nombre
                  })
            }
         });
         setRelacionSolicitante(relaciones)
      })
   }

   useEffect(()=>{
      HandleGetEstadoMx();
      HandleGetAllRelacionSolicitante();
   },[])

   return{
      relacionSolicitante,
      dataEstado,
   }
}

export default useReferenciasAgregar