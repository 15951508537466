import React from 'react'
import './ProgressBar.css'
import { formatearMonto } from '../../Utils/functions'

const ProgressBar = ({ progress = 0, primary, secondProgress, active }) => {
    const colorBar = primary ? `primary` : `secondary`
    const progressBigger = (Number(progress.porcentajeActivos) > Number(secondProgress.porcentajeActivos)) ? true : false
    const completeProgressBar =  Number(progress.porcentajeActivos) + Number(secondProgress.porcentajeActivos)

    return (
        <>
            {progressBigger ?
                <div className={`progress-bar-container`}>
                    <div
                        className={"progress-bar-fill"}
                        style={{ width: `${progress.porcentajeActivos}%` }}
                    >
                        <div className={`progress-bar-lines ${colorBar}`}></div>
                    </div>
                    <div className={`progress-bar-tooltip-top ${colorBar}`} style={{left: `${(Number(progress.porcentajeActivos) + 10 )}%`}}>{formatearMonto(progress.sumaTotalDinero)} <br /> {progress.idsDiferentes} Promesas <br />  {`${progress.porcentajeActivos}%`}</div>
                    {active && (
                        <>
                            <div
                                className={`progress-secondbar progress-bar-negative`}
                                style={{ width: `${completeProgressBar}%` }}
                            >
                            </div>
                            <div className="progress-bar-tooltip-bottom" style={{left: `${(Number(completeProgressBar) + 10 )}%` }}>{formatearMonto(secondProgress.sumaTotalDinero)} <br /> {secondProgress.idsDiferentes} Promesas <br /> {`${secondProgress.porcentajeActivos}%`}</div>
                        </>
                    )}

                </div>
                :
                <div className={`progress-bar-container`}>
                    <div
                        className={"progress-bar-fill"}
                        style={{ width: `${progress.porcentajeActivos}%` }}
                    >
                        <div className={`progress-bar-lines progress-secondbar ${colorBar}`}></div>
                    </div>
                    <div className={`progress-bar-tooltip-top ${colorBar}`} style={{left: `${(Number(progress.porcentajeActivos) + 10 )}%`}}> {formatearMonto(progress.sumaTotalDinero)} <br /> {progress.idsDiferentes} Promesas <br />  {`${progress.porcentajeActivos}%`}</div>
                    {active && (
                        <>
                            <div
                                className={`progress-bar-lines progress-bar-negative`}
                                style={{ width: `${completeProgressBar}%`}}
                            >
                            </div>
                            <div className={"progress-bar-tooltip-bottom"} style={{left: `${(Number(completeProgressBar) + 10 )}%` }}>{formatearMonto(secondProgress.sumaTotalDinero)} <br /> {secondProgress.idsDiferentes} Promesas <br /> {`${secondProgress.porcentajeActivos}%`}</div>
                        </>
                    )}

                </div>}
        </>
    )
}


export default ProgressBar
