import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { TextField } from "../../../../Components/TextField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { useSelector } from "react-redux";
import { FormatFecha } from "../../../../Utils/functions";

const AutorizacionDeValorDeUnidadInventarioCard = React.forwardRef(({  ...props}, ref) => {
   const {itemUnidadesRemate, nombreUsuario} = useSelector((state)=> state.UnidadesEnRemateSlice)
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const [loading, setLoading] = useState(false);
   const [precioVenta, setPrecioVenta]=useState(0)
   const [precioCompra, setPrecioCompra] = useState(0)
   const [modalMessage, setModalMessage] = useState({
      isOpen: false,
      type: 1,
      title: "",
      message: "",
      data: [],
      authorizationType: 2,
      Origin: "Listado",
   });

   useEffect(()=>{
      itemUnidadesRemate?.etapa?.map(etapa=>{
         if(etapa?.nombreEtapa.toLowerCase() === "enganche" && etapa?.enganche !== null){
            if(etapa?.enganche?.costo !== null){
               setPrecioCompra(etapa?.enganche?.costo)
            }
            if(itemUnidadesRemate?.valoracionUnidad !== null && itemUnidadesRemate?.valoracionUnidad?.valorMercado !== null){
               setPrecioVenta(itemUnidadesRemate?.valoracionUnidad?.valorMercado)
            }
         }
      })
   },[])

   let initial = {
      valor_unidad: itemUnidadesRemate?.valoracionUnidad === null ? 0 : itemUnidadesRemate?.valoracionUnidad?.valorUnidad === null ? 0 : itemUnidadesRemate?.valoracionUnidad?.valorUnidad,
      unidad_inventario: itemUnidadesRemate?.valoracionUnidad?.unidadParaInventario === null ? "No" : "Si",
      // usuario: itemUnidadesRemate.valoracionUnidad.usuario,
      fecha_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaOperacion === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaOperacion),
      hora_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion === null ? '--:--' :itemUnidadesRemate?.valoracionUnidad?.horaOperacion,
      comentario: itemUnidadesRemate?.valoracionUnidad === null ? '' : itemUnidadesRemate?.valoracionUnidad?.comentarios !== null ? itemUnidadesRemate?.valoracionUnidad?.comentarios : '',
      numero_solicitud: itemUnidadesRemate?.general?.numSolicitud === null ? " " : itemUnidadesRemate?.general?.numSolicitud,
      fecha_pago: itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaUpd === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaUpd),
   };
   const [initialValue, setInitialValue] = useState(initial);

   return (
      <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
         {modalMessage.type === 1 && (
            <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            />
         )}
         { modalMessage.type === 2 && ( <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} /> )}
      </Modal>
      <Toaster
         position="top-right"
         toastOptions={{
            success: {
            style: {
               background: "#47a066",
               color: "#FFFF",
               borderLeft: "10px solid #2f7246",
            },
            },
            error: {
            style: {
               background: "#d53f3f",
               color: "#FFFF",
               borderLeft: "10px solid #ac241a",
            },
            },
         }}
      />
      <div className="row wrapper-vehiculo">
         <div className="col col-12">
            <div className="row mx-md-4 my-4">
            <div className="col-12 px-md-3">
               <div className="p-4 white-wrapper mb-2">
                  <div
                     className="row my-2 px-4 "
                     data-bs-toggle="collapse"
                     href="#collapseAutorizacionValor"
                     role="button"
                     aria-expanded="false"
                     aria-controls="collapseRegistroTramite"
                     onClick={() => setCollpsableGrua(!collpsableGrua)}
                  >
                  <div className="col-10">
                     <h6>
                        <span className={`semaforo semaforo-${initialValue.color}`} ></span>{" "}
                        <strong>Autorización de Valor de Unidad</strong>
                     </h6>
                  </div>
                  <div className="col-1 col-md-1 unidades-remate-icon btn btn-light secondary" style={{ textAlign: "center", alignContent:"center"}} >
                     <span style={{fontSize:"12px"}}><i className="icon-gray bx bx-hide"> Solo lectura</i> </span>
                  </div>
                  <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                     <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                  </div>
                  </div>
                  <div className="collapse" id="collapseAutorizacionValor">
                  <Formik
                     enableReinitialize={true}
                     initialValues={initialValue}
                     >
                     {({ isValid, values, setFieldValue}) => (
                        <>
                        <Form>
                           <div className="separator"></div>
                           <div className="my-3 row px-4" >
                              <div className="col-12 col-lg-3">
                                 <TextField label="Valoración de la unidad ($)" name="valor_unidad" type="text" disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Unidad para inventario" name="unidad_inventario" type="text" disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Usuario" name="usuario" type="text" holder={nombreUsuario} disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Fecha de la operación" name="fecha_operacion" type="text" disabled={true} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4" >
                              <div className="col-12 col-lg-3">
                                 <TextField label="Hora de la operación" name="hora_operacion" type="text" disabled={true} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4">
                              <div className="col-6">
                                 <TextAreaField
                                    label="Comentarios"
                                    className="col-8 mt-2 form-control"
                                    rows="4"
                                    name="comentario"
                                    type="text"
                                    holder=""
                                    disabled={true}
                                 />
                              </div>
                           </div>
                           <div className="row mt-2 px-4">
                              <div className="col-2 mt-4">
                                 <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Datos de compra</strong></span></p>
                              </div>
                           </div>
                           <div className="my-3 row px-4" >
                              <div className="col-12 col-lg-3">
                                 <TextField label="Número de solicitud" name="numero_solicitud" type="text" disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Fecha de pago de la unidad" name="fecha_pago" type="text" disabled={true} />
                              </div>
                           </div>
                           <div className="my-3 row px-4" >
                              <table style={{border: "#000 1px solid"}} className="col-12 col-lg-3">
                                 <thead style={{border: "#000 1px solid"}}>
                                    <tr>
                                       <th>Concepto</th>
                                       <th>Monto</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>Precio de venta</td>
                                       <td>$ {precioVenta}</td>
                                    </tr>
                                    <tr>
                                       <td>Precio de compra</td>
                                       <td>$ {precioCompra}</td>
                                    </tr>
                                 </tbody>
                                 <tfoot style={{border: "#000 1px solid"}}>
                                    <tr>
                                       <td>Direfencia</td>
                                       <td>$ {precioVenta === 0 ? precioCompra : (precioVenta - precioCompra)}</td>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </Form>
                        </>
                     )}
                  </Formik>
                  </div>
               </div>
            </div>
            </div>
         </div>
      </div>
      </>
   );
});
export default AutorizacionDeValorDeUnidadInventarioCard;