import { Field, Formik } from "formik";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from '../../../Seguro/Siniestros/ContratoHeaderCard'
import ValuacionDetalle from "../ValuacionDetalle";
import { SelectField } from "../../../../Components/SelectField";
import { TextField } from "../../../../Components/TextField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import useInspeccionRapida from "../../../../Hooks/Inventario/useInspeccionRapida";
import Modal from "../../../../Components/Modal/Modal";
import { Toaster } from "react-hot-toast";
import Spinner from "../../../../Components/Loadig";
import InventarioCostosInspeccionModal from "../../../../Components/Modal/InventarioCostosInspeccionModal";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../../Components/datatable/DataTable";
import ModalConfirm from "../ModalConfirm";

const InspeccionRapidaValuacion = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        initialValue,
        refFormik,
        optionsAptoInventario,
        modalMessage,
        setModalMessage,
        loading,
        getCostosInspeccion,
        downloadPDF,
        submitForm,
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        columns,
        valuacionInspeccionUnidadId,
        costos,
        handleEdit,
        handleDelete,
        handleViewdocs,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        pageCount,
        handlePageClick,
        page
    } = useInspeccionRapida();

    const { state } = useLocation();
    
    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/inventario/valuacion/datadocs/fValuacion/eRepar/${state?.contrato}/${id}`, {
                state: { ...value, registroId: value.valuacionRegistroGastos_AdicionalesId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <InventarioCostosInspeccionModal
                        getCostosInspeccion={getCostosInspeccion}
                        valuacionInspeccionUnidadId={valuacionInspeccionUnidadId}
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <ValuacionDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={'746e27c0-e67e-45fa-aa9e-96e3324d3d43'} verClaves={false} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">
                                <div className="mt-2 px-4">
                                    <div className="col-11">
                                        <h6><strong>Inspección de unidad </strong></h6>
                                    </div>
                                </div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    // validationSchema={validate}
                                    initialValues={initialValue}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <div className="row flex align-items-center">
                                                        <div className="col-auto">
                                                            <small>Descargar formato de inspección de unidad</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' disabled={initialValue.concluido} onClick={() => downloadPDF()}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={!isValid}
                                                                            onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 px-4">
                                                <div className="col-12">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-auto">
                                                            <strong>Registro de costos de reparación</strong>
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                !initialValue.concluido &&
                                                                <button className="btn" onClick={(e) => {
                                                                    console.log("Open")
                                                                    setModalMessage({ isOpen: true, type: 1, title: "Registrar costo", valuacionInspeccionUnidadId: initialValue.valuacionInspeccionUnidadId });
                                                                }}>
                                                                    Agregar
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <DataTable
                                                        id="tablaPagos"
                                                        name="pagos"
                                                        column={columns}
                                                        data={costos}
                                                        editable={!initialValue.concluido}
                                                        handleEdit={handleEdit}
                                                        deleteable={!initialValue.concluido}
                                                        handleDelete={handleDelete}
                                                        handleDocuments={handleViewdocs}
                                                        handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                        paginate={true}
                                                        pageCount={pageCount}
                                                        handlePageClick={handlePageClick}
                                                        currentPage={page - 1}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField id="vehiculoApto"
                                                        label="¿Vehículo apto para inventario?"
                                                        disabled={initialValue.concluido}
                                                        name="vehiculoApto"
                                                        items={optionsAptoInventario}
                                                        onChange={(event) => {
                                                            setFieldValue("vehiculoApto", event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField id="observacionesUnidad"
                                                        label="Observaciones de la unidad"
                                                        disabled={initialValue.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="observacionesUnidad"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("observacionesUnidad", event.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField id="informacionAdicional"
                                                        label="Información adicional"
                                                        disabled={initialValue.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="informacionAdicional"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("informacionAdicional", event.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Inspección de unidad finalizada</label>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaInicio !== '' &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                            }
                                                        </div>

                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaFin !== '' &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Registro de gastos"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }
            </div>
        </>
    )
}

export default InspeccionRapidaValuacion;