import imagen from '../../Assets/img/LogotipoArrendadoraAzul.png';
import Footer from '../../Components/Footer';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';

import Usuario from '../../Services/Users/Usuarios';

const Password = () => {
const [username, setUsername] = useState('');
const [userId, setUserId] = useState('');
const [correoEnviado, setcorreoEnviado] = useState('');

const [etapas, setEtapas] = useState(true);
const navigate = useNavigate();
const [loading, setLoading] = useState(false);
const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });

const validateForm = Yup.object({
    email: Yup.string()
    .required("El campo es requerido.")
    .trim("El texto no debe empezar ni terminar con espacios")
    .strict(true),
});
const onSubmit = async (values)=>{
    
    setUsername(values.email);
    enviarCorreo(values.email);
    //
  }
const { values, errors, handleChange,touched, handleUsernameChange, handleSubmit  } = useFormik({
    initialValues:{
        email:"",
    },
    validationSchema:validateForm,
    onSubmit
}) 
const enviarCorreo = async (email)=>{
    let data = {
        username: email,
    }
    setEtapas(false)
    setLoading(true);
  await Usuario.userResetEmail(data)
        .then(res => {
            
            setLoading(false);
            if(res.data.data.ban == 0){
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje})
            }else{
                
                setUserId(res.data.data.userId);
                setcorreoEnviado(res.data.data.email)
                
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Se ha enviado un correo a su cuenta"})

            }

        }).catch(e => {
            setLoading(false);
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
}
const handleSubmitChange = (event) => {
    event.preventDefault();
    
    navigate("/")

};
const envioCorreo = () => {   
    
    return false
};

    return (
        <>
        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff'>
                            {
                                modalMessage.type === 1 &&
                                <ErrorModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }
                            {
                                modalMessage.type === 2 &&
                                <SuccessModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }

        </Modal>
        {etapas ? (
            <div className="contenedor">            
                    <div className="col-12 col-sm-8 col-md-6 col-xl-3 container-login">
                        <figure className="logo-container">
                            <img  className="logo" src={imagen} style={{width:'15em'}} alt="logo" />
                        </figure>
                        <div className="">
                            <h5 className="text-left fs-6">RECUPERAR CONTRASEÑA</h5>      
                            <h3 className="text-left fs-6">Te enviaremos un link a tu correo para recuperar la contraseña.</h3>                       
                            <br/>
                            <form className="mt-6" onSubmit={handleSubmit}>
                                 <div className="form-group">  
                                    <label className="form-label">Usuario</label>
                                    {/* <i className="glyphicon fas fa-user"></i> */}

                                    <input type="text" 
                                    placeholder="Escribe aquí" 
                                    name="email" id="email" 
                                    onChange={handleChange} 
                                    className="form-control mb-4 {errors.email ? 'input-error':''}" />    
                                </div>
                                {errors.email && touched.email && <p className='error'>{errors.email}</p>}
                                <br/>
                                <div className='d-grid gap-2 col-12 mx-auto'>
                                    <button type="submit" className="btn"> Enviar </button>
                                </div>                                                                                                      
                                   
                               
                            </form>                            
                        </div>
                    </div>                               
                </div>
            ):(
                <div className="contenedor">            
                    <div className="col-12 col-sm-8 col-md-6 col-xl-3 container-loginpassword">
                        <figure className="logo-container">
                            <img  className="logo" src={imagen} style={{width:'15em'}} alt="logo" />
                        </figure>
                        <div className="">
                            <h5 className="text-left fs-6">REVISA TU CORREO</h5>      
                            <h3 className="text-left fs-6">Te enviamos un link de recuperación de contraseña a la siguiente dirección: <b>{correoEnviado}</b>.</h3>                       
                            <br/>
                            <form className="mt-6" onSubmit={handleSubmitChange} >
                                <br/>
                                <div className='d-grid gap-2 col-12 mx-auto'>
                                    <button type="submit" className="btn"> Listo </button>
                                </div>                                                                                                      
                            </form>  
                            <br/>
                            <h1 className="text-left fs-6">¿No recibiste el correo?<a href="" onClick={handleSubmit}>Click para reenviar</a></h1>
                            <br/><br/>
                            <h1 className="text-left fs-6" > <NavLink to="/" >Regresar al Inicio de sessión</NavLink ></h1>                       

                        </div>
                    </div>                               
                </div>
            )
        }
                    <Footer
                    cls={'fixed'}
                    />  
        </>
      )
}
export default Password