import React, { useEffect, useState } from 'react'
import useValuacionDetalle from '../../../../Hooks/SeguimientoDeValuacion/useValuacionDetalle'
import Stepper, { status } from '../../../../Components/Stepper/Stepper';
import { useLocation, useParams } from 'react-router-dom';
import DisplayComentarios from '../../../../Components/Comentarios/DisplayComentarios';
import ListadoComentarios from '../../../../Components/Comentarios/ListadoComentarios';
import Comentarios from '../../../../Services/ProcesosCierre/Comentarios';
const data = {
    step1: status.Active,
    porcentage1: 100,
    step2: status.Inactive,
    porcentage2: 0,
    step3: status.Inactive,
    porcentage3: 0,
    step4: status.Inactive,
    porcentage4: 100,
};
const SeguimientoDetalle = ({ handleClickContinuar, dataStep = data, activeBtn = false }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const { navigate } = useValuacionDetalle();
    const handleNavigate = (step) => {
        navigate(`/procesocierre/seguimientocierre/${id}/${step.paso}`, {
            state: state
        })
    }

    // useEffect(() => {
    //     getComments();
    // }, []);


    const [showOn, setShowOn] = useState(false);
    const [modalComments, setModalComments] = useState({ isOpen: false  });
    const [comentarios, setComentarios] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        if(values.comentario.trim().length === 0) return false;

        setLoading(true);
        await Comentarios.addComment(values)
        .then(resp => {
            setComentarios(resp.data);
        })
        .catch(err => {

        });
    }

    const getComments = async () => {
        setLoading(true);
        await Comentarios.getComments(id)
        .then(resp => {
            setComentarios(resp.data);
        })
        .catch(err => {
            console.error(err);
        });
    };



    return (
        <>
            <DisplayComentarios isOpen={modalComments.isOpen} color='#fff' width={380}>
                <ListadoComentarios 
                    modalComments={modalComments}
                    setModalComments={setModalComments}
                    comentarios={comentarios}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                />
            </DisplayComentarios>
            <section className='headerTab'>
                <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                    <div>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de seguimiento de valuación
                    </div>
                    {
                        state?.estatus !== 'Concluido' &&
                        <button disabled={!activeBtn} className='btn' onClick={handleClickContinuar}>Continuar</button>
                    }
                </header>
                <div className="row mx-4 my-2">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-auto">
                                <button className='btn btn-secondary'>Solicitar Expediente</button>
                            </div>
                            <div className="col-auto">
                                <button className='btn d-flex' onClick={() => {
                                    getComments();
                                    setModalComments({ isOpen: true })
                                } }><i className='ri-chat-4-fill mx-2'></i>Comentarios</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center row'>
                    {
                        dataStep.map(x =>
                            <Stepper
                                key={x.paso}
                                status={x.pasoSubStatus}
                                module={x.pasoDescripcion}
                                nameStep={`Paso ${x.paso}`}
                                percentage={x.porcentaje}
                                item={x}
                                handleClick={() => { handleNavigate(x) }}
                            />
                        )
                    }

                </div>
            </section>
        </>
    )
}

export default SeguimientoDetalle