import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import Llamada from "../../../../Services/Cartera/Llamadas";
import useReferenciasAgregar from "../../../../Hooks/SeguimientoVisita/useReferenciasAgregar";
import toast from "react-hot-toast";
import { FormatFecha, getHourFormat, message } from "../../../../Utils/functions";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import { useSelector } from "react-redux";
import { TextAreaField } from "../../../../Components/TextAreaField";

const ReferenciasAgregar = () => {
   const refFormik = useRef();
   const navigate = useNavigate();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [dataColonia, setDataColonia] = useState([]);
   const [loading, setLoading] = useState(false);
   const [fechaInicio, setFechaInicio] = useState("")
   const [fechaFin, setFechaFin] = useState("")

   const initial = {
      sol_id: parseInt(datosConsulta?.sol_id),
      contrato: datosConsulta?.contrato,
      vin: datosConsulta?.vin,
      idreferencia: 0,
      tipo:"",
      relacion_solicitante: "",
      nombre_ref: "",
      apellido_paterno_ref: "",
      apellido_materno_ref: "",
      calle_ref: "",
      no_ext_ref: "",
      no_int_ref: "",
      colonia_ref: "",
      municipio_ref: "",
      cp_ref: "",
      estado_ref: "",
      curp: "",
      pais_ref: "México",
      referencia_ref:"",
      fecha_nacimiento: "",
      status:"",
      message:""
   }

   useEffect(()=>{
      let diaActual = new Date()
      let hour = getHourFormat(`${String(diaActual.getHours()).padStart(2, '0')}:${String(diaActual.getMinutes()).padStart(2, '0')}`)
      setFechaInicio(FormatFecha(diaActual) + ' ' + hour)
      setFechaFin(FormatFecha(diaActual) + ' ' + hour)
   },[])

   const {
      relacionSolicitante,
      dataEstado,
   } = useReferenciasAgregar();

   const HandleGetMunicpio = async (id) => {
      setDataMunicipio([]);
      await Llamada.GetMunicipio(id).then(res => {
         let Municipios = []
         res?.data?.data?.map(item => {
            Municipios.push({
               value: item?.municipioId,
               label: item?.municipio
            });
         });
         setDataMunicipio(Municipios);
      })
   }
   const HandleGetColonia = async (id) => {
      setDataColonia([])
      await Llamada.GetColonia(id).then(res => {
         let Colonias = []
         res?.data?.data?.map(item => {
            Colonias.push({
               value: item?.coloniaId,
               label: item?.colonia
            });
         });
         setDataColonia(Colonias);
      })
   }

   const [initialValue, setInitialValue] = useState(initial); 
   const validate = Yup.object({
      relacion_solicitante: Yup.string()
         .required("El campo es requerido."),
      nombre_ref: Yup.string()
         .required("El campo es requerido."),
      apellido_paterno_ref: Yup.string()
         .required("El campo es requerido."),
      apellido_materno_ref: Yup.string()
         .required("El campo es requerido."),
      // curp: Yup.string()
      //    .required("El campo es requerido."),
      fecha_nacimiento: Yup.date()
         .required("El campo es requerido.")
         .test("age", "La referencia debe ser mayor de edad", function(fecha_nacimiento) {
            const cutoff = new Date();
            cutoff.setFullYear(cutoff.getFullYear() - 18);
            return fecha_nacimiento <= cutoff;
         }),
      calle_ref: Yup.string()
         .required("El campo es requerido."),
      no_ext_ref: Yup.string()
         .required("El campo es requerido."),
      cp_ref: Yup.string()
         .required("El campo es requerido."),
      estado_ref: Yup.string()
         .required("El campo es requerido."),
      municipio_ref: Yup.string()
         .required("El campo es requerido."),
      colonia_ref: Yup.string()
         .required("El campo es requerido."),
   });

   const handlerGuardar = async (values) => {
      setLoading(true);
      await CobranzaService.nuevaReferenciaContacto(values)
         .then(res => {
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            setLoading(false);
            navigate(-1)
         }).catch(e => {
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   return (
      <>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                 <h2 className="fw-bold ">Agregar referencias</h2>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-11 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
                  <Formik
                     innerRef={refFormik}
                     enableReinitialize={true}
                     validationSchema={validate}
                     initialValues={initialValue}
                     onSubmit={(values) => {
                        handlerGuardar(values);
                  }}>
                  {({ isValid, values, setFieldValue, errors }) => (
                     <>
                        <Form>
                           <div className="px-4">
                              <div className="row mt-2 px-4">
                                 <div className="col-sm-5 col-xl-2 mt-3">
                                    <p><span style={{fontSize: '20px', fontWeight: 'bold'}}>Registro de referencias</span></p>
                                 </div>
                                 <div className="col-sm-7 col-xl-6 mt-3">
                                    <div className="row justify-content-end">
                                       <div className="col-auto">
                                          <button className='btn col-12' type='submit' disabled={!(isValid)}>Continuar</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="mb-3 row" style={{marginTop:30}}>
                                 <div className="col-lg-12 col-12 py-2"> <span><strong>1. Datos iniciales</strong></span></div>
                                 <div className="col-lg-4 col-4">
                                    <SelectField
                                       id="relacion_solicitante"
                                       label="Relación:"
                                       name="relacion_solicitante"
                                       disabled={false}
                                       items={relacionSolicitante}
                                       onChange={(event) => {
                                          setFieldValue("relacion_solicitante", event.label)
                                       }} />
                                 </div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Nombre:</label>
                                    <Field type="text" name="nombre_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="nombre_ref" component="div" className="error" />
                                 </div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Apellido paterno:</label>
                                    <Field type="text" name="apellido_paterno_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="apellido_paterno_ref" component="div" className="error" />
                                 </div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Apellido Materno:</label>
                                    <Field type="text" name="apellido_materno_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="apellido_materno_ref" component="div" className="error" />
                                 </div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Curp:</label>
                                    <Field type="text" name="curp" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="curp" component="div" className="error" />
                                 </div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Fecha de nacimiento:</label>
                                    <Field type="date" name="fecha_nacimiento" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="fecha_nacimiento" component="div" className="error" />
                                 </div>

                                 {/* DIRECCION */}
                                 <div className="col-lg-12 col-12 py-2"> <span><strong>2. Captura su dirección</strong></span></div>

                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Calle:</label>
                                    <Field type="text" name="calle_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="calle_ref" component="div" className="error" />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Numero exterior:</label>
                                    <Field type="text" name="no_ext_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="no_ext_ref" component="div" className="error" />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Numero interior:</label>
                                    <Field type="text" name="no_int_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="no_int_ref" component="div" className="error" />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Código postal:</label>
                                    <Field type="text" name="cp_ref" placeholder="Escribir"
                                       className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="cp_ref" component="div" className="error" />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <SelectField id="estado_ref" label="Estado" name="estado_ref" items={dataEstado} onChange={(event) => {
                                       setFieldValue("estado_ref", event.value === "" ? "" : event.label);
                                       HandleGetMunicpio(event.value);
                                    }} />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <SelectField id="MunicipioId" label="Municipio" name="municipio_ref" items={dataMunicipio} onChange={(event) => {
                                       setFieldValue("municipio_ref", event.value === "" ? "" : event.label);
                                       HandleGetColonia(event.value);
                                    }} />
                                 </div>
                                 <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <SelectField id="colonia_ref" label="Colonia" name="colonia_ref" items={dataColonia} onChange={(event) => {
                                       setFieldValue("colonia_ref", event.value === "" ? "" : event.label);
                                    }} />
                                 </div>
                                 <div className="mb-3 row " style={{marginTop:30}}>
                                    <div className="col-sm-8 col-xl-6 mb-3" style={{ display: 'flex', flexFlow: 'column' }}>
                                       <TextAreaField label="Comentarios:" className="form-control col-12" rows="3" name="message" type="text" holder="Escribe" />
                                    </div>
                                 </div>
                                 {/* <div className="row mb-3 px-4">
                                    <div className={width <= 1280 ? "col-sm-4 mb-3" : "col-3 mb-3"}>
                                       <span className="text-sm">Inicio de proceso: {fechaInicio} </span>
                                    </div>
                                    <div className={width <= 1280 ? "col-sm-4" : "col-3"}>
                                       <span className="text-sm">Fin de proceso: {fechaFin} </span>
                                    </div>
                                 </div> */}
                              </div>
                           </div>
                        </Form>
                     </>)}
                  </Formik>
               </div>
            </div>
         </div>
         { loading && <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> }
      </>
   );
}

export default ReferenciasAgregar;