import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate } from 'react-router-dom';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { FormatNumberMoney2, NumFolio, message } from '../../../Utils/functions';
import Spinner from '../../../Components/Loadig';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';

const RealizarPagoModal = ({ isOpen, setIsOpen, item, handleSubmit }) => {
    const refbtn = useRef();
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    let initial = {
        generalId: item?.generalId ?? "",
        gestoriaPagoRefrendoPlacasId: item?.gestoriaPagoRefrendoPlacasId ?? "",
        montoPagado: "",
        fecha: "",
        clientePagoTramite: "",
        folio: NumFolio(),
        accesorio:item?.accesorio ?? "",
        sol_id:item?.sol_id ?? "",
        vin:item?.vin ?? "",
        contrato:item?.contrato??"",
        proceso:item?.proceso ?? "",
        fechaRegistro:"",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    let clientePagoTramite = [
        { label: "SI", value: true },
        { label: "NO", value: false },
    ];

    const validate = Yup.object({
        clientePagoTramite: Yup.string()
            .required("El campo es requerido."),
        montoPagado: Yup.number()
        .when("clientePagoTramite", (clientePagoTramite) => {           
            // if (clientePagoTramite) {
                return Yup.string().test({
                    name: 'is-sku',
                    skipAbsent: true,
                    test(value, ctx) {
                        if (value === undefined)
                            return ctx.createError({ message: 'El campo es requerido' })
                        if (value < item.montoPorPagar)                            
                            return ctx.createError({ message: 'El monto debe ser mayor o igual al monto por pagar' })
                        return true
                    }
                })
            // }
        }),           
        fecha: Yup.date()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        // if (!item.subido) {
        //     toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir documento de evidencia.")}</div>);
        //     return;
        // }        
        handleSubmit(item);        
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                let date = new Date(values.fecha);
                let newDate = date.setDate(date.getDate() + item.refrendo);                                   
                handleAddEdit({...values,fechaRegistro:new Date(newDate).toISOString().split("T")[0]});
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-2 mx-4' style={{ width: '50rem' }}>
                                <Form>
                                    <span className='fs-5 d-flex'><strong>Trámite de pago</strong>
                                        <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                            navigate(`/gestoria/pagorefrendoyplacas/datadocs/fGestoria/eGestoria/${item.vin}/${item?.generalId}`, {
                                                state: item
                                            });
                                        }}><i className="icon ri-file-3-fill mx-1" ></i>Subir documentos</fieldset>
                                    </span>
                                    <div className="mb-3">
                                        <label style={{ fontSize: '13px' }} className="col-form-label">A continuación se presenta la gestión de vehículo seleccionado de: <br />
                                            <strong>Folio: {values.folio} I Número de cheque: #{item.folioGrupo} I VIN: {item.vin} I Marca: {item.nombreMarca} I Modelo: {item.nombreModelo} I Año: {item.nombreYear} <br /> Versión: {item.nombreVersion} I Color: {item.color} I Placas: {item.placas} I Tipo: {item.tipoVehiculo} I Contrato: {item.contrato} I Monto por pagar: {item.montoPorPagarStr} </strong></label>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4">
                                            <SelectField id="tipovehiculoid" label="¿Cliente pago el trámite?" name="clientePagoTramite" items={clientePagoTramite} onChange={(event) => {
                                                setFieldValue("clientePagoTramite", event.value);
                                            }} />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="presupuestoDeBolsaId" label="Monto pagado ($)" name="montoPagado" type="number" holder="Escribe" />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField id="presupuestoDeBolsaId" label="Fecha de pago" name="fecha" max={today} type="date" holder="Escribe" />
                                        </div>
                                    </div>
                                    <section className='my-3 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                                    </section>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )

            }

        </Formik>
    )
}

export default RealizarPagoModal