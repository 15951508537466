import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha, formatearMonto } from '../../Utils/functions'

const PromesaPagoHook = () => {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem('userId').trim()

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)

  let allContratos

  const columns = [
    { field: 'folio_promesa', headerName: 'Folio Promesa' },
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencidos', headerName: 'Días Vencidos' },
    { field: 'fecha_promesa', headerName: 'Fecha Promesa' },
    { field: 'fecha_creacion', headerName: 'Fecha Registro Promesa' },
    { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
    { field: 'montoPromesaFormat', headerName: 'Monto Promesa' },
    { field: 'montoRecuperadoFormat', headerName: 'Monto Recuperado' },
    { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
    { field: 'promesa_status', headerName: 'Estatus' },
    { field: 'ultimaActualizacion', headerName: 'Últ. Actualización' },
  ];

  const inicioObjeto = {
    value: "",
    label: "Seleccione"
  };

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([])

  const [currentData, setCurrentData] = useState(data)
  const [promesasStatusSelect, setPromesasStatusSelect] = useState([])
  const [promesasStatus, setPromesasStatus] = useState("")

  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    let filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (promesasStatus === "" || promesasStatus === "Seleccione" || item.promesa_status_nombre === promesasStatus)
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
    // if (currentPage > newTotalPages) {
    //   setCurrentPage(newTotalPages)
    // } else if (currentPage <= 0) {
    //   setCurrentPage(1)
    // }

  }, [currentPage, name, pageRows, data, promesasStatus])

  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: value.evento
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    GetAllData()
  }, [])


  const GetAllData = async () => {
    await handleGetSeguimientoContrato()
    await handleGetPromesasdePago()
    await HandlePromesasStatus()
  }


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase())
        allContratos = userContracts.contratos.map(obj => {
          if (obj.cartera_vencida === 1) return obj
        })

        setLoading(false);

      }).catch(e => {
        setLoading(false);
      })
  }

  const HandlePromesasStatus = async () => {
    await CobranzaService.getAllEstatusPromesa()
      .then(res => {
        let promesa = []
        res.data?.responseData?.map(item => {
          if (item.activo) {
            promesa.push({
              value: item.id,
              label: item.nombre
            });
          }
        });
        promesa.unshift(inicioObjeto)
        setPromesasStatusSelect(promesa);
      })
  }


  const handleGetPromesasdePago = async () => {
    setLoading(true);
    await CobranzaService.getAllPromesasDePago()
      .then(res => {
        const promesasFiltradas = res.data.filter(item1 => allContratos.some(item2 => item1?.contrato_id === item2?.contrato_id))

        const update = promesasFiltradas.map(item => {
          const today = new Date()
          let colorFondo
          let colorTexto

          switch (item.promesa_status_nombre) {
            case "Cumplida":
              colorFondo = "#D8F2CD"
              colorTexto = "#47A066"
              break;
            case "Rota":
              colorFondo = "#F5CECE"
              colorTexto = "#CE2222"
              break;
            case "Vigente":
              const fechaPromesa = new Date(item.fecha_compromiso);

              if (fechaPromesa < today) {
                item.promesa_status_nombre = "Rota";
                colorFondo = "#F5CECE";
                colorTexto = "#CE2222";
              } else {
                item.promesa_status_nombre = "Vigente";
                colorFondo = "#FAE7BC";
                colorTexto = "#B46B16";
              }
              break;
            default:
              break;
          }


          const nuevaFecha = new Date(item.fecha_promesa)
          const diference = today - nuevaFecha
          const daysDifference = Math.floor(diference / (1000 * 60 * 60 * 24));
          let dias_vencidos
          if (daysDifference > 0) {
            dias_vencidos = daysDifference
          } else {
            dias_vencidos = "-"
          }

          const fechaPromesa = FormatFecha(item.fecha_promesa)
          const fechaPromesaRegistro = FormatFecha(item.fecha_creacion)
          const montoVencidoFormat = formatearMonto(item.monto_vencido)
          const montoPromesaFormat = formatearMonto(item.monto_promesa)
          const montoRecuperadoFormat = formatearMonto(item.monto_recuperado)

          let ultimaActualizacion
          if (item.fecha_ultima_actualizacion) {
            ultimaActualizacion = FormatFecha(item.fecha_ultima_actualizacion)
          } else {
            ultimaActualizacion = "-"
          }

          const contratoEvent = allContratos.find(obj => obj?.contrato_id === item?.contrato_id)

          return {
            ...item,
            promesa_status: item.promesa_status_nombre,
            colorFondo: colorFondo,
            colorTexto: colorTexto,
            fecha_promesa: fechaPromesa,
            montoVencidoFormat: montoVencidoFormat,
            montoPromesaFormat: montoPromesaFormat,
            montoRecuperadoFormat: montoRecuperadoFormat,
            ultimaActualizacion: ultimaActualizacion,
            dias_vencidos: dias_vencidos,
            fecha_creacion: fechaPromesaRegistro,
            evento: contratoEvent
          }
        })



        setData(update)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    promesasStatusSelect,
    setPromesasStatus
  }
}
export default PromesaPagoHook