import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { isOpenModalCambioProd } from '../../../Redux/Slice/quickActionsSlice'
import { useDispatch } from 'react-redux';
import VehiculoConvertidoService from '../../../Services/Inventario/VehiculoConvertidoService';
import { useNavigate } from 'react-router-dom';
import { message } from '../../../Utils/functions';
import Spinner from '../../../Components/Loadig';
import { useRef } from 'react';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';

const ModalConfirmVehiculoConvertido = ({ isOpenModalClave, item, toast }) => {
    console.log(item)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refBtn = useRef();

    const [loading, setloading] = useState(false);

    const handlecambioInventario = async () => {
        refBtn.current.disabled = true;
        setloading(true);
        await VehiculoConvertidoService.SaveChangeInventario(item.generalId)
            .then((res) => {
                console.log("dara", res.data.data)
                if (res.data.data.ban === 1) {
                    toast.success(message("Correcto.!", res.data.data.mensaje));

                    setTimeout(() => {
                        setloading(false);
                        dispatch(isOpenModalCambioProd({ check: false }))
                        refBtn.current.disabled = false;
                        navigate('/inventario/vehiculosconvertidos');
                    }, 3000);
                }
                else {
                    toast.error(message("Error.!", res.data.data.mensaje));
                    setloading(false);
                }

            }).catch((e) => {
                setloading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.message));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    return (
        <>
            <Modal isOpen={isOpenModalClave} color='#fff'>
                <section className='my-2 mx-4' style={{ width: '35rem' }}>
                    <section className='py-2 d-flex flex-column'>
                        <div className='d-flex justify-content-between'>
                            <span className='fs-5'><strong>cambio de unidad</strong></span>
                        </div>
                        <span className='mt-2'>¿Seguro que deseas hacer el cambio de unidad externa a inventario?</span>
                    </section>

                    <section className='my-3 d-flex justify-content-end mt-4'>
                        <fieldset className='btn btn-outline px-5 mx-2' type='reset' onClick={() => { dispatch(isOpenModalCambioProd({ check: !isOpenModalClave })) }}>Cerrar</fieldset>
                        <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.CambioAInventarioSection, AccionesRapidas.CambioAInventario))}>
                            <fieldset className='btn px-5' ref={refBtn} onClick={handlecambioInventario}>Confirmar</fieldset>
                        </ValidatePermission>
                    </section>

                </section>
            </Modal>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
        </>
    )
}

export default ModalConfirmVehiculoConvertido