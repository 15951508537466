import React, { useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import EliminarExternoService from '../../../Services/Inventario/EliminarExternoService'
import { message } from '../../../Utils/functions'

const ModalMantenerEliminarRegistro = ({ isOpenModal, SetIsOpenModal, item, toast, handleGetAll }) => {

    console.log(item)
    const [loading, setLoading] = useState(false)
    const handleAction = async () => {
        setLoading(true);
        await EliminarExternoService.EliminarMantenerExterno(item.generalId,item.action)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setTimeout(() => {
                        setLoading(false);
                        toast.success(message("¡Correcto!", res.data.data.mensaje));
                        SetIsOpenModal(false);
                        handleGetAll(1)
                    }, 2000);
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                    SetIsOpenModal(false);
                }

            }).catch(e => {
                toast.error(message("¡Error!", e?.messaje));
            })
    }
    return (
        <>
            <Modal isOpen={isOpenModal} color='#fff'>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>
                    <section className='py-2 d-flex flex-column'>
                        <div className='d-flex justify-content-between'>
                            <span className='fs-5'><strong>{item?.action === 1 ? "Mantener Registro" : "Confirmar eliminación"}</strong></span>
                        </div>
                        <span className='mt-2'>{item?.action === 1 ? "¿Desea mantener el vehículo en inventario?" : "¿Desea elimnar el vehículo del inventario?"}</span>
                    </section>

                    <div className="mb-3 row">
                        <div className="col-sm-12 col-md-6 col-lg-8">
                            <span><strong>VIN:</strong>{item?.vin}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-sm-12 col-md-6 col-lg-12">
                            <span><strong>Marca:</strong>{item?.nombreMarca} | <strong>Modelo:</strong>{item?.nombreModelo} | <strong>Año:</strong>{item?.nombreYear}</span>
                        </div>
                    </div>
                    <section className='my-3 d-flex justify-content-end'>
                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { SetIsOpenModal(false) }} >Cancelar</button>
                        <button id="AT_btnGuardar" className='btn mx-2' type="submit" onClick={handleAction}>Confirmar</button>
                    </section>
                </section>
            </Modal>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
        </>
    )
}

export default ModalMantenerEliminarRegistro