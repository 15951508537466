import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import { getDateNow, message } from '../../Utils/functions'

const useVehiculoContrato = (toast) => {
  const navigate = useNavigate();
  const refBtn = useRef();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 180 },
    { field: 'contrato', headerName: 'Contrato', width: 180 },
    { field: 'usoUnidad', headerName: 'Uso unidad', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 250 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 250 },
    { field: 'nombreYear', headerName: 'Año', width: 100, short: false },
    { field: 'vigencia', headerName: 'Vigencia', width: 250 },
    { field: 'strFechaTerminacionContrato', headerName: 'Fin de contrato', width: 200 },
    { field: 'dia', headerName: 'Días restantes', width: 100 },
  ];
  let DataFiltroFolder = [
    { label: "Todos", value: "" },
    { label: "Vehículos con folder", value: 1 },
    { label: "Vehículos sin folder", value: 0 },
  ];
  const dataTemp = [
    {
      vin: "",
      contrato: "",
      usoUnidad: "",
      nombreMarca: "",
      nombreModelo: "",
      nombreYear: "",
      vigencia: "",
      finContrato: "",
      dia: ""
    }
  ]
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [tipoAd, setTipoAd] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [DataMarca, setDataMarca] = useState([]);
  const [DataModelo, setDataModelo] = useState([]);
  const [DataYear, setDataYear] = useState([]);
  const [DataVersion, setDataVersion] = useState([]);
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [filtroDocs, setfiltroDocs] = useState("");
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });


  //Filtro
  const [claveMarca, setClaveMarca] = useState("");
  const [claveModelo, setClaveModelo] = useState("");
  const [claveAÑo, setClaveAÑo] = useState("");
  const [claveVersion, setClaveVersion] = useState("");
  const [DataUsoUnidad, setDataUsoUnidad] = useState([])
  const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
  const [pageRows, setpageRows] = useState(10)

  const [defaultValueModelo, setDefaultValueModelo] = useState({ label: "Modelo", value: "" });
  const [defaultValueYear, setDefaultValueYear] = useState({ label: "Año", value: "" });
  const [openModalSinRenovar, setOpenModalSinRenovar] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [str, claveMarca, claveModelo, claveAÑo, claveUsoUnidad, pageRows])
  useEffect(() => {
    handleGetAllMarca();
    handleGetAllUsoUnidad()
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await SeguroCotizadoService.getAllRenovacionSeguro(page, pageRows, claveMarca, claveModelo, claveAÑo, str, claveUsoUnidad)
      .then(res => {
        let dataRes = res.data.data

        dataRes.map((item) => {
          if(item.vigencia){
            item.vigencia = item.vigencia.split(" ")[0]
          }
          // item.strFechaTerminacionContrato = getDateNow(item.strFechaTerminacionContrato)
        });

        setData(dataRes);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {

        setLoading(false);
      })
  }
  const handleCheck = (cheked, value) => {
    if (cheked) {
      setIdsEliminar(current => [...current, value.generalId]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item !== value.generalId));
    }
  }
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    setLoading(true);
    if (data.length > 0) {
      let newIds = idsEliminar.join();
      await SeguroCotizadoService.DownloadRenovacionSeguro(claveMarca, claveModelo, claveAÑo, str, claveUsoUnidad,0)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `VehiculoContrato-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

          setLoading(false);
          habdleGetAll(1);
          handleUnchekedColumns();
          refBtn.current.disabled = false;
          setIdsEliminar([]);
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtn.current.disabled = false;
      // setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "No hay registros a exportar" })
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No hay registros a exportar.")}</div>);
      setTimeout(() => {
          toast.remove();
      }, 3000);
    }

  }
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');


    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  const handleGetAllUsoUnidad = async () => {
    await SeguroSinCotizarService.getlAllUsoUnidad()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.usoUnidadId,
            label: item.usoUnidad
          })
        });
        setDataUsoUnidad(NewData);
      }).catch(e => {

      })
  }
  const handleGetAllMarca = async () => {
    await VehiculoService.getAllMarca()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveMarca,
            label: item.nombreMarca
          })
        });
        setDataMarca(NewData);
      }).catch(e => {
      })
  }
  const handleChangeMarca = async (value) => {
    setClaveMarca(value);
    setDefaultValueModelo({ label: "Modelo", value: "" })
    setDefaultValueYear({ label: "Año", value: "" })
    setDataYear([])
    await VehiculoService.getAllModeloById(value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveModelo,
            label: item.nombreModelo
          })
        });
        setDataModelo(NewData);
      }).catch(e => {
      })
  }
  const handleChangeModelo = async (value) => {
    setClaveModelo(value.value);
    setDefaultValueYear({ label: "Año", value: "" })
    setDefaultValueModelo(value)
    await VehiculoService.getAllYearById(claveMarca, value.value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveYear,
            label: item.nombreYear
          })
        });
        setDataYear(NewData);
      }).catch(e => {
      })
  }
  const handleChangeYear = async (value) => {
    setClaveAÑo(value.value);
    setDefaultValueYear(value)
    // await VehiculoService.getAllVersionById(claveMarca, claveModelo, value)
    //   .then(res => {
    //     let NewData = [];
    //     NewData.push({
    //       value: "",
    //       label: "Todos"
    //     })
    //     res.data.data?.map(item => {
    //       NewData.push({
    //         value: item.claveVersion,
    //         label: item.nombreVersion
    //       })
    //     });
    //     setDataVersion(NewData);
    //   }).catch(e => {
    //   })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleEdit = (value) => {
    navigate(`/seguro/renovacionseguro/AddEdit/${value.generalId}`, {
      state: { ...value, action: 1, utilitario: false },
    });
  }
  const handleCreateFolder = (item) => {

    setDataVehiculo(item);
    setIsOpenDocs(true)
  }
  const handleSort = (value) => {

    habdleGetAll(1, value);
  }
  return {
    columns,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    DataMarca,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    setfiltroDocs,
    habdleGetAll,
    handleChangeMarca,
    handleChangeModelo,
    handleChangeYear,
    setClaveVersion,
    DataModelo,
    DataYear,
    DataVersion,
    DataUsoUnidad,
    setClaveUsoUnidad,
    setpageRows,
    handleCheck,
    handleDownloadFile,
    refBtn,
    modalMessage,
    setModalMessage,
    defaultValueModelo,
    defaultValueYear,
    dataTemp,
    setOpenModalSinRenovar,
    openModalSinRenovar,
    setSelectedItem,
    selectedItem
  }
}

export default useVehiculoContrato