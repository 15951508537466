import React from 'react'
export const status = {
    "Inactive": 1,
    "Active": 2,
    "Completed": 3
}
const getComponentByStatus = (value, nameStep,handleClick,item) => {
    if (value === 2)
        return <button onClick={handleClick} className='btn btn me-2' style={{ borderRadius: '50px', outline: '0', boxShadow: '0 0 0 .25rem rgba(13,110,253,.25)' }}>{nameStep}</button>
    else if (value === 1)
        return <button onClick={handleClick} disabled={!item?.registro} className='btn btn me-2' style={item?.registro ? { borderRadius: '50px', outline: '0', boxShadow: '0 0 0 .25rem rgba(13,110,253,.25)' }:{ borderRadius: '50px', background: 'gray' }}>{nameStep}</button>
    else if (value === 3)
        return <fieldset className='btn p-0 d-flex justify-conten-center align-items-center' style={{background:'#fff',height:'44px'}}><i onClick={handleClick} className='fs-1 icon-green ri-checkbox-circle-fill'></i></fieldset>
    else
        return <button onClick={handleClick} disabled className='btn btn me-2' style={{ borderRadius: '50px', outline: '0', boxShadow: '0 0 0 .25rem rgba(13,110,253,.25)' }}>{nameStep}</button>
}
const getLabelByStatus = (value,item) => {
    if (value === 2)
        return <fieldset className='d-flex align-items-center justify-content-center' style={{ width: '6rem', height: '20px', backgroundColor: '#E8EAF6', borderRadius: '3px' }}>
            <label style={{ fontSize: '13px', color: '#1351A5' }}> <strong>{item?.pasoSubStatusDescripcion}</strong> </label>
        </fieldset>
    else if (value === 1)
        return <fieldset disabled className='d-flex align-items-center justify-content-center' style={{ width: '6rem', height: '20px', backgroundColor: '#E8EAF6', borderRadius: '3px' }}>
            <label style={{ fontSize: '13px', color: 'gray' }}> <strong>{item?.pasoSubStatusDescripcion}</strong> </label>
        </fieldset>
    else if (value === 3)
        return <fieldset className='d-flex align-items-center justify-content-center' style={{ width: '6rem', height: '20px', backgroundColor: '#D8F2CD', borderRadius: '3px' }}>
            <label style={{ fontSize: '13px', color: '#47A066' }}> <strong>{item?.pasoSubStatusDescripcion}</strong> </label>
        </fieldset>
}
const getProgressByStatus = (value, percentage) => {
    if (value === 2)
        return <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`, backgroundColor: '#1351A5' }} aria-valuemin={0} aria-valuemax={100}></div>
    else if (value === 1)
        return <div className="progress-bar" role="progressbar" style={{ width: `0%`, backgroundColor: '#1351A5' }} aria-valuemin={0} aria-valuemax={100}></div>
    else if (value === 3)
        return <div className="progress-bar" role="progressbar" style={{ width: `100%`, backgroundColor: '#47A066' }} aria-valuemin={0} aria-valuemax={100}></div>
}
const Stepper = ({ status = 1, module, nameStep,percentage,handleClick,item}) => {   
    console.log("pasos",item); 
    return (
        <div className='col-sm-12 col-md-6 col-lg-3'>
            <section className='d-flex flex-column justify-content-end p-4'>
                <div className={`d-flex align-items-center ${status !== 3 ? 'pb-2':''}`}>
                    {getComponentByStatus(status, nameStep,handleClick,item)}
                    <div className="progress" style={{ height: '4px', width: '10rem' }}>
                        {getProgressByStatus(status,percentage)}
                    </div>
                </div>
                <label className='pb-2' style={{ fontSize: '14px', color:`${status !== 1 ? 'gray' : 'black'}`}}><strong>{module}</strong></label>
                {getLabelByStatus(status,item)}
            </section>
        </div>
    )
}
export default Stepper