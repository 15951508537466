import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import { TextAreaField } from '../../Components/TextAreaField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import RegimenFiscal from './RegimenFiscal';
import Spinner from '../../Components/Loadig';

const UsoCFDI = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage, existingData }) => {
    let initial = {
        usoCFDIId: item?.usoCFDIId ?? "",
        clave: item?.clave ?? "",
        descripcion: item?.descripcion ?? "",
        status: item?.status ?? true,
        ids: ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataRegimenFiscal, setDataRegimenFiscal] = useState([]);
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        clave: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        descripcion: Yup.string()
            .required("El campo es requerido.")
    });

    useEffect(() => {
        handleGetAllDocumentos();
    }, [])

    const handleGetAllDocumentos = async () => {
        setLoading(true);
        await Catalogo.getAllRFbyId(item?.usoCFDIId ?? null)
            .then(res => {
                console.log(res.data.data)
                setDataRegimenFiscal(res.data.data);
                setLoading(false);
            }).catch(e => {
                setIsOpen(false);
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const [errorMessage, setErrorMessage] = useState("");
    const handleAddEdit = async (data) => {
        if (initialValue.usoCFDIId !== "") {
            await Catalogo.updateUsoCFDI(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            if (existingData.find(x=>x.clave === data.clave)) {
                setErrorMessage("El CFID ya existe.");
                setLoading(false);
                return;
            }
            await Catalogo.addUsoCFDI(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }


    }
    const handleChanagestatus = (value, item) => {
        setDataRegimenFiscal((current) =>
            current?.map((data) =>
                data.regimenFiscalId === item.regimenFiscalId
                    ? {
                        ...data,
                        isCheck: value
                    }
                    : data
            )
        );
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                // let strIds = "";
                // dataRegimenFiscal?.map(x => {
                //     strIds += `${x.regimenFiscalId},${x.isCheck}|`
                // })
                // if (cheked) {      
                //     setIdsEliminar(current => [...current, value.logSistemaId]);      
                //   } else {
                //     setIdsEliminar(idsEliminar.filter(item => item !== value.logSistemaId)); 
                //   }

                let newData = [];
                dataRegimenFiscal.map(x=>{if(x.isCheck){newData.push(x.regimenFiscalId)}});                
                handleAddEdit({ ...values, ids: newData.join() });
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="mb-3">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-4">
                                            <TextField id="mecanicoId" label="Clave" name="clave" type="text" holder="Escribe" />
                                        </div>
                                        <div className="col-sm-8">
                                            <TextAreaField label="Descripción" className="form-control col-12" rows="3" name="descripcion" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="col-12 row mb-3">
                                        {/* <label style={{ fontSize: '13px' }}><strong>2. Seleccionar las etapas destino:</strong></label> */}
                                        <div className='tbl-scroll' style={{ overflowY: 'auto', height: '300px' }}>
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr className='custom-table'>
                                                        <th scope="col">Regimen Fiscal</th>
                                                        <th scope="col">Asignar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataRegimenFiscal.length > 0 ?
                                                            dataRegimenFiscal?.map((itemx, index) => {
                                                                return <tr key={index}>
                                                                    <th scope="row">{itemx.clave} - {itemx.descripcion}</th>
                                                                    <td>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.isCheck} onChange={(e) => { handleChanagestatus(e.target.checked, itemx) }} />
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                
                                <span className="text-danger">{errorMessage}</span>
                                
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' type='submit' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}
export default UsoCFDI