import axios from '../../Utils/ApiService';

const Datamovil = {
    /* SEGUIMIENTO DATAMOVIL */
    seguimientoDatamovil: (data) => {
        return axios.post(`/cartera/getSeguimientoDatamovil`, data);
    },
    /* BUSQUEDA DE PROCESOS POR VIN */
    BusquedaVINDatamovil: (data) => {
        return axios.get(`/cartera/BusquedaVINDatamovil?buscar=${data}`);
    },
    /* CREAR DATAMOVIL */
    datamovilCrear: (data) => {
        return axios.post(`/cartera/datamovilCrear`, data);
    },
    /* ACTUALIZAR DATAMOVIL */
    datamovilActualizar: (data) => {
        return axios.put(`/cartera/datamovilActualizar`, data);
    },
    /*** ORDEN DE DESINSTALACION ***/
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilOrdenDesinstalacionCrear: (data) => {
        return axios.post(`/cartera/datamovilOrdenDesinstalacionCrear`, data);
    },
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilOrdenDesinstalacionActualizar: (data) => {
        return axios.put(`/cartera/datamovilOrdenDesinstalacionActualizar`, data);
    },
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilOrdenDesinstalacionGet: (data) => {
        return axios.get(`/cartera/datamovilOrdenDesinstalacionGet?datamovilId=${data}`);
    },
    /*** CITA DE DESINSTALACION ***/
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilCitaDesinstalacionCrear: (data) => {
        return axios.post(`/cartera/datamovilCitaDesinstalacionCrear`, data);
    },
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilCitaDesinstalacionActualizar: (data) => {
        return axios.put(`/cartera/datamovilCitaDesinstalacionActualizar`, data);
    },
    /* CREAR ORDEN DE DESINSTALACION */
    datamovilCitaDesinstalacionGet: (data) => {
        return axios.get(`/cartera/datamovilCitaDesinstalacionGet?datamovilId=${data}`);
    },
    /*** REVISION ***/
    /* CREAR REVISION */
    datamovilRevisionCrear: (data) => {
        return axios.post(`/cartera/datamovilRevisionCrear`, data);
    },
    /* ACTUALIZAR REVISION */
    datamovilRevisionActualizar: (data) => {
        return axios.put(`/cartera/datamovilRevisionActualizar`, data);
    },
    /* OBTENER REVISION */
    datamovilRevisionGet: (data) => {
        return axios.get(`/cartera/datamovilRevisionGet?datamovilId=${data}`);
    },
    /*** REVISION COSTOS ***/
    /* CREAR REVISION COSTOS */    
    datamovilRevisionCostoCrear: (data) => {
        return axios.post(`/cartera/datamovilRevisionCostoCrear`, data);
    },
    /* ACTUALIZAR REVISION COSTOS */
    datamovilRevisionCostoActualizar: (data) => {
        return axios.put(`/cartera/datamovilRevisionCostoActualizar`, data);
    },
    /* OBTENER REVISION COSTOS */
    datamovilRevisionCostoGet: (data) => {
        return axios.get(`/cartera/datamovilRevisionCostoGet?datamovilRevisionCostoId=${data}`);
    },
    /*** ADEUDOS ***/
    /* CREAR ADEUDOS */
    datamovilAdeudosCrear: (data) => {
        return axios.post(`/cartera/datamovilAdeudosCrear`, data);
    },
    /* ACTUALIZAR ADEUDOS */
    datamovilAdeudosActualizar: (data) => {
        return axios.put(`/cartera/datamovilAdeudosActualizar`, data);
    },
    /* OBTENER ADEUDOS */
    datamovilAdeudosGet: (data) => {
        return axios.get(`/cartera/datamovilAdeudosGet?datamovilId=${data}`);
    },
    /*** ADEUDOS PAGO ***/
    /* CREAR PAGO ADEUDOS */
    datamovilAdeudosPagoCrear: (data) => {
        return axios.post(`/cartera/datamovilAdeudosPagoCrear`, data);
    },
    /* ACTUALIZAR PAGO ADEUDOS */
    datamovilAdeudosPagoActualizar: (data) => {
        return axios.put(`/cartera/datamovilAdeudosPagoActualizar`, data);
    },
    /* OBTENER PAGO ADEUDOS */
    datamovilAdeudosPagoGet: (data) => {
        return axios.get(`/cartera/datamovilAdeudosPagoGet?datamovilAdeudosPagoId=${data}`);
    },

    /*Obtener Antecedentes Datamovil */
    datamovilAntecedentesGet: (data) => {
        return axios.get(`/cartera/datamovilAntecedentesGet?generalId=${data}`);
    },

    /*Obtener UnicacionUnidad Datamovil */
    datamovilUbicacionUnidadGet: (data) => {
        return axios.get(`/cartera/datamovilUbicacionUnidadGet?datamovilUbicacionUnidadId=${data}`);
    },

    datamovilUbicacionUnidadGetByDatamovilId: (data) => {
        return axios.get(`/cartera/datamovilUbicacionUnidadGetByDatamovilId?datamovilId=${data}`);
    },
    /* CREAR UBICACION UNIDAD */
    datamovilUbicacionUnidadCrear: (data) => {
        return axios.post(`/cartera/datamovilUbicacionUnidadCrear`, data);
    },
    /* ACTUALIZAR UBICACION UNIDAD */
    datamovilUbicacionUnidadActualizar: (data) => {
        return axios.put(`/cartera/datamovilUbicacionUnidadActualizar`, data);
    },

    /* CREAR REPROGRAMACION */
    datamovilReprogramacionCrear: (data) => {
        return axios.post(`/cartera/datamovilReprogramacionCrear`, data);
    },

    /* OBTENER REPROGRAMACION */
    datamovilReprogramacionGet: (data) => {
        return axios.get(`/cartera/datamovilReprogramacionGet?datamovilId=${data}`);
    },

    /* ACTUALIZAR REPROGRAMACION */
    datamovilReprogramacionActualizar: (data) => {
        return axios.put(`/cartera/datamovilReprogramacionActualizar`, data);
    },

    /* CREAR COSTO DE REPROGRAMACION */
    datamovilReprogramacionCostoCrear: (data) => {
        return axios.post(`/cartera/datamovilReprogramacionCostoCrear`, data);
    },

    /* ACTUALIZAR COSTO DE REPROGRAMACION */
    datamovilReprogramacionCostoActualizar: (data) => {
        return axios.put(`/cartera/datamovilReprogramacionCostoActualizar`, data);
    },

    /* OBTENER COSTO DE REPROGRAMACION */
    datamovilReprogramacionCostoGet: (data) => {
        return axios.get(`/cartera/datamovilReprogramacionCostoGet?DatamovilReprogramacionCostoId=${data}`);
    },

}

export default Datamovil