import axios from '../../Utils/ApiService';
import axiosFD from '../../Utils/ApiServiceFD';


const ConfigService = {
    getAllDetonantes:()=> {        
        return axios.get(`/config/Dataware/ObtenerDetonante`);
    },
    uploadFile:(formData)=>{        
        return axiosFD.post(`/config/UploadSepomex`,formData);        
    },
    SyncIntelimotor:(id)=> {        
        return axios.get(`/config/Intelimotor/SyncIntelimotor?isManual=true&UserId=${id}`);
    },
    SyncInventario: async (id)=> {        
        return await axios.get(`/config/Intelimotor/SyncInvInt?userId=${id}`);
    },
}
export default ConfigService;