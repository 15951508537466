import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from './Modal'
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import DataTable from '../datatable/DataTable';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { FormatNumberMoney2, message } from '../../Utils/functions';
import AuthLogin from '../../Utils/AuthLogin';
import Spinner from '../Loadig';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModalAddSolicitarExpedienteSiniestro from './ModalAddSolicitarExpedienteSiniestro';
import { useDispatch, useSelector } from 'react-redux';
import { increment } from '../../Redux/Slice/reloadSlice';

const auth = new AuthLogin();
const SolicitarExpedienteModal = ({ isOpen, setIsOpen, item }) => {
    let url = auth.UrlDataDocs();
    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const refbtn = useRef();
    const { count } = useSelector((state) => state.reloadSlice);
    const dispatch = useDispatch();
    let initial = {
        seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
        tipoProceso: item?.tipoProceso
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [dataRUnidad, setDataRUnidad] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRows, setPageRows] = useState(10);
    const [isOpenModalPago, setIsOpenModalPago] = useState(false);
    const columns = [
        { field: 'folio', headerName: 'Folio', width: '40' },
        { field: 'responsable', headerName: 'Persona de archivo' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'comentariosRU', headerName: 'Comentarios', width: '50' },
        { field: 'estatus', headerName: 'Estatus', width: '50' },
        { field: 'fechaUltimaActulizacion', headerName: 'Ultimo movimiento ', width: '200' },
    ]
    const validate = Yup.object({
        //Detalle de resguardo

    });

    useEffect(() => {
        GetAllProceso()
    }, [isOpen])

    const GetAllProceso = async (page = 1) => {
        setLoading(true);
        await Siniestros.GetAllProcesoDatamovil(page, pageRows, id, item?.tipoProceso)
            .then(res => {
                let data = res.data.data;
                let total = 0;
                setDataRUnidad(data);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        GetAllProceso(event.selected + 1);
    };

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    // handleAddEdit(values);
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-4 mx-4'>
                                <span className='fs-5'><strong>Solicitud de expediente</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <DataTable
                                                            data={dataRUnidad}
                                                            column={columns}
                                                            paginate={true}
                                                            pageCount={totalPages}
                                                            currentPage={currentPage - 1}
                                                            handlePageClick={handlePageClick}
                                                        />
                                                        {
                                                            // dataRUnidad?.length <= 0 &&
                                                            <div className="col-6 d-flex align-items-center">
                                                                <fieldset disabled={initialValue?.concluido} className='btn d-flex align-items-center mx-2' onClick={() => { setIsOpenModalPago(true) }}>Agregar</fieldset>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <section className='d-flex justify-content-end'>
                                                <div className="row">
                                                    <div className="col-auto d-flex">
                                                        <button
                                                            type='reset'
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-outline me-2'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={() => { setIsOpen(false);dispatch(increment({count:count}));}}
                                                        >
                                                            Cerrar
                                                        </button>
                                                        {/* <button
                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                            type='submit'
                                                            id="AT_BtnCloseModal"
                                                            className='btn'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        >
                                                            Guardar
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </section>
                                            {/* <div className="row mt-4 d-flex justify-content-between">
                                                <div className="col-auto">
                                                    <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cierre de siniestro</label>
                                                </div>
                                                <div className="col-auto flex-column align-items-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio !== "" &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaFin}</small>
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        {
                            isOpenModalPago &&
                            <ModalAddSolicitarExpedienteSiniestro
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId,
                                    siniestroProcesoDatamovilId: item?.tipoProceso,
                                }}
                                setIsOpenModalPago={setIsOpenModalPago}
                                isOpenModalPago={isOpenModalPago}
                                handleGetAll={() => { GetAllProceso(1);dispatch(increment({count:count})); }}
                            />
                        }
                    </>
                )
                }
            </Formik>
        </>
    )
}

export default SolicitarExpedienteModal