import React from 'react'
import img from '../../Assets/img/Inventario.png'
import { Zoom } from 'react-awesome-reveal'

const InventarioHome = () => {
  return (
    <>
      <Zoom>
        <section >
          <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '90vh' }}>
            <div className='row'>
              <div className='text-center'>
                <img src={img} style={{ width: '30rem' }} className='img-fluid' />

              </div>
              <div className='fs-4 text-center mb-3'>Inventario</div>
              <div className='fs-6 text-center'>Aquí podrás administrar el inventario de Datamovil, Socio Comercial e Intelimotor, <br /> dar de alta nuevos vehículos y seguir su flujo correspondiente.</div>
            </div>
          </div>
        </section>
      </Zoom>
    </>
  )
}

export default InventarioHome