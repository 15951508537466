import { Formik } from "formik"
import { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom"
import { TextField } from "../../Components/TextField";
import { SelectField } from "../../Components/SelectField";
import Catalogo from "../../Services/Catalogos/Catalogo";

const TipoServicioModal = ({ modalMessage, setModalMessage }) => {
    const refFormik = useRef();
    const initial = {
        tipo: "",
        duracionAtencionServicioId: "",
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [duraciones, setDuraciones] = useState([]);

    useEffect(() => {
        getTiempos();
    }, []);

    const getTiempos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.DuracionAtencionServicio')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.DuracionAtencionServicioId,
                        label: item.DuracionAtencion
                    }
                });
                setDuraciones(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div className='p-2'>
                                <div className='justify-content-start align-items-center'>
                                    <div className="row">
                                        <div className="col col-12">
                                            <h5><strong>Editar servicio</strong></h5>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <TextField
                                                id="tipo"
                                                label="Nombre del servicio"
                                                name="tipo"
                                                type="text"
                                                holder="Nombre del servicio"
                                                onChange={(event) => {
                                                    setFieldValue("tipo", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col col-12">
                                            <span className="fw-bold">Indica la duración del servicio</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <SelectField id="duracionAtencionServicioId"
                                                label="Duración de atención"
                                                name="duracionAtencionServicioId"
                                                items={duraciones}
                                                onChange={(event) => {
                                                    setFieldValue("duracionAtencionServicioId", event.value);
                                                }} />
                                        </div>
                                    </div>
                                    <section className='d-flex mt-4 justify-content-end gap-2'>
                                        <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Cancelar</button>
                                        <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Continuar</button>
                                    </section>
                                </div>
                            </div>

                        </Form>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default TipoServicioModal;