export default class AuthLogin {

    handleAuthentication = (resultLogin) => {
        localStorage.setItem('id', resultLogin.id);
        localStorage.setItem('email', resultLogin.email);
        localStorage.setItem('name', resultLogin.name);        
        localStorage.setItem('access_token', resultLogin.access_token);
        localStorage.setItem('token_type', resultLogin.token_type);
        localStorage.setItem('expires_at', resultLogin.expires_at);
        localStorage.setItem('permiso_qr', resultLogin.permiso_qr);
        localStorage.setItem('permiso_usuario', resultLogin.permiso_usuario);
    }
    handleAuthSesionDataDocs = (data) => {        
        localStorage.setItem('access_token_datadocs', data.token);
        localStorage.setItem('uri_datadocs', data.url);

    }
    handleAuthSesion = (resultLogin) => {        
        sessionStorage.setItem('id', resultLogin.id);
        sessionStorage.setItem('email', resultLogin.email);
        sessionStorage.setItem('name', resultLogin.name);        
        sessionStorage.setItem('access_token', resultLogin.access_token);
        sessionStorage.setItem('token_type', resultLogin.token_type);
        sessionStorage.setItem('expires_at', resultLogin.expires_at);
        sessionStorage.setItem('permiso_qr', resultLogin.permiso_qr);
        sessionStorage.setItem('permiso_usuario', resultLogin.permiso_usuario);
    }
    handleAuthRememberData = (email,passwodCrypt,expires_at) => {        
        document.cookie =`email=${email};path=/;max-age=60*60*24*15;`; // 60 => 1 minuto,60*60 -> 1 hora, 60*60*24 -> 1 dia, 60*60*24*25 -> 15 dias        
        document.cookie =`password=${passwodCrypt};path=/;max-age=60*60*24*15;`;      
    }
    handleAuthDeleteCookie = (email,passwodCrypt) => {        
        document.cookie =`email=${email};path=/;max-age=0;`; // 60 => 1 minuto,60*60 -> 1 hora, 60*60*24 -> 1 dia, 60*60*24*25 -> 15 dias        
        document.cookie =`password=${passwodCrypt};path=/;max-age=0;`;      
    }
    hangleGetCookie = (name) =>{
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    IsAutorizeQr = () =>{
        return localStorage.getItem("permiso_qr") !== "0" ? true : false;
    }

    IsAutorizeUser = () =>{
        return localStorage.getItem("permiso_usuario") !== "0" ? true : false;
    }

    logout = () => {
        // localStorage.removeItem('id');
        // localStorage.removeItem('email');
        // localStorage.removeItem('name');     
        // localStorage.removeItem('access_token');
        // localStorage.removeItem('token_type');
        // localStorage.removeItem('expires_at');
        // localStorage.removeItem('permiso_qr');
        // localStorage.removeItem('permiso_usuario');
        localStorage.clear();
    }

    // expiresAt = () =>  new Date(decodedToken().exp * 1000);
    // isExpired =  () =>  new Date() > expiresAt();
    // isValid =  () => {
    // return  !isExpired()
    // };

    Urlpath = () => {        
        return `http://localhost:3000/`;
    }


    isAuthenticated = () =>{
        if(this.expireSesion === null){
            return false;
        }
        return new Date().getTime < new Date(this.expireSesion()).getTime();
    }

    expireSesion = () => {
        let contador = 0;
        let expireSesion = null;
        while (!expireSesion && contador <= 1000) {
            expireSesion = localStorage.getItem("expires_at");
            if (expireSesion) {
                contador = 1000;
            } else {
                contador++;
            }
        }

        return expireSesion;
    }


    token = () => {    
        return sessionStorage.getItem("token");          
    }
    tokenDataDocs = () => {    
        return localStorage.getItem("access_token_datadocs");          
    }
    UrlDataDocs = () => {    
        return localStorage.getItem("uri_datadocs");          
    }
    Expire_at = () => {
        return localStorage.getItem("expires_at");  
    }
    useAuth = () =>{
        return localStorage.getItem("access_token") !== null ? true : false;
    }
    handleAuthSesionLogin = (resultLogin) => {        
        for (var [key, value] of Object.entries(resultLogin)) {
            sessionStorage.setItem(key, value);
        }
        sessionStorage.setItem('ingresarSistema', false);

    }
    logout = () =>{
        sessionStorage.clear()
    }
    getNombreUsuario = () =>{
        return sessionStorage.getItem("nombreUsuario");
    }
    getNumeroEmpleado = () =>{
        return sessionStorage.getItem("numeroEmpleado");
    }
    getUser = () =>{
        return sessionStorage.getItem("nombreUsuario") == undefined?false:true;
    }
    getSesionValue = (value) =>{
        return sessionStorage.getItem(value);
    }
    getSesionClave = (value) =>{
        return sessionStorage.getItem("claveIS")+"";
    }
    setIngresarSistema = () =>{
        sessionStorage.removeItem('ingresarSistema');
        sessionStorage.setItem("ingresarSistema",true);
    }
    getIngresarSistema = () =>{
        return sessionStorage.getItem("ingresarSistema") == undefined?false:true;
    }
    getSesionIdRole = () =>{
        return sessionStorage.getItem("idRole") == undefined?false:sessionStorage.getItem("idRole");
    }


    // REcuperar Modul, subModulo,seccion,reglas
    parseJwt=(token)=> {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    SubModulo = () => {
        var token = sessionStorage.getItem("reglaPermisos") || "";
        if (!token) return null;        
        var menuJson = this.parseJwt(token);        
        return JSON.parse(menuJson.subModulo);
    }
    Modulo = () => {
        var token = sessionStorage.getItem("reglaPermisos") || "";
        if (!token) return null;        
        var menuJson = this.parseJwt(token);        
        return JSON.parse(menuJson.modulo);
    }
    Seccion = () => {
        var token = sessionStorage.getItem("reglaPermisos") || "";
        if (!token) return null;        
        var menuJson = this.parseJwt(token);        
        return JSON.parse(menuJson.seccion);
    }
    Reglas = () => {
        var token = sessionStorage.getItem("reglaPermisos") || "";
        if (!token) return null;        
        var menuJson = this.parseJwt(token);        
        return JSON.parse(menuJson.reglas);
    }
    AccionesRapidas = () => {
        var token = sessionStorage.getItem("reglaPermisos") || "";
        if (!token) return null;        
        var menuJson = this.parseJwt(token);        
        return JSON.parse(menuJson.accionesRapidas);
    }
    getSubModule = (idSubModule) => {        
        let subModule = this.SubModulo();        
        if (!subModule) return false;
        return subModule.find(x => x.Id === idSubModule);
    }
    getModule = (idModule) => {        
        let Modulo = this.Modulo();        
        if (!Modulo) return false;
        return Modulo.find(x => x.IdModulo === idModule);
    }
    getSeccion = (idSeccion) => {        
        let Seccion = this.Seccion();        
        if (!Seccion) return false;
        return Seccion.find(x => x.Id === idSeccion);
    }
    getReglas = (SubModuloId,SeccionId) => {    
        let Reglas = this.Reglas();        
        if (!Reglas) return false;
        return Reglas.find(x => (x.IdSubModulo === SubModuloId && x.IdSeccion === SeccionId));
    }
    getAccionesRapidas = (IdAccion) => {        
        let Acciones = this.AccionesRapidas();        
        if (!Acciones) return false;
        return Acciones.find(x => x.IdSubMenu === IdAccion);
    }
    //
}