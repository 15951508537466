
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { FormatFecha } from "../../Utils/functions";
import { useSelector } from "react-redux";

const useHistorialVisitasDetalle = () => {
    const userId = sessionStorage.getItem('userId').trim()
    const {contratoActual} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

    const navigate = useNavigate();
    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([{}])
    // Datos contrato
    const [userDetail, setUserDetail] = useState([{}])
    const [promesasRotas, setPromesasRotas] = useState(0)
    //Modal
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const handleReturnPage = () => {
        navigate(`/cobranza/seguimiento-visita/historial-visitas`)
    }

    useEffect(() => {
        //Servicios
        handleGetSeguimientoContrato()
        handleGetPromesasdePago()
    }, [])

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                let datosFormateados = userContracts.contratos?.map((item) => {
                    const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                    const fechaClave = FormatFecha(item.fecha_ultima_clave)
                    return {
                        ...item,
                        fecha_ultimo_pago: fechaPago,
                        fecha_ultima_clave: fechaClave,
                    }
                })

                const contractDetail = datosFormateados.find(contract => contract.contrato_id === contratoActual.contrato_id) || [{}]

                const userDetail = userContracts.user_detail

                setCurrentDataSeguimiento(contractDetail)
                setUserDetail(userDetail)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                let promesasRotas = 0
                res.data.forEach(item => {
                    if (item.promesa_status_nombre === 'Rota')
                        promesasRotas++
                });
                setPromesasRotas(promesasRotas)
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    return {
        handleReturnPage,
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        setLoading
    }
}
export default useHistorialVisitasDetalle