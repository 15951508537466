import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveEntrega } from '../../../Redux/Slice/navigateSliceEntrega';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubMenuEntrega = () => {
  const { value } = useSelector((state) => state.tabNavigateSliceEntrega);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveEntrega(value));
  }
  const handleVigate = (value) => {    
    switch (value) {
      case (ValidatePermissionById(3, Section.VehiculosEnEntrega) && 1):
        navigate(`/inventario/entrega/vehiculosentrega`);
        break;
      case (ValidatePermissionById(3, Section.PendientesPorCancelar) && 2):
        navigate(`/inventario/entrega/cancelacionpendiente`);
        break;
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehiculosEnEntrega)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos en entrega</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PendientesPorCancelar)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pendientes por cancelar</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuEntrega