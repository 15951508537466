import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useInterfazDetail from '../../Hooks/Logs/useInterfazDetail';
import DataTable from '../../Components/datatable/DataTable';
import Select from 'react-select'
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import { Toaster, toast } from 'react-hot-toast';
import { pageRows } from '../../Utils/functions';
import RowPages from '../../Components/RowPages';

const InterfazDetail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { columns,
        data,
        pageCount,
        handlePageClick,
        dataProceso,
        dataCategoria,
        setStr,
        setDateTime,
        setCategoriaId,
        setProcesoId,
        initialPage,
        handleCheck,
        handleDownloadFile,
        refBtn,
        loading,
        setpageRows} = useInterfazDetail(toast);
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                    custom:{
                        style: {
                            background: '#f0bf52',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    }
                }}
            />
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                Regresar a Registro {state.page}
            </header>
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                        <span className='fs-5 mx-2'><strong>Registros Eliminados</strong></span>
                        <button type="button" className="btn d-flex justify-content-center align-items-center" data-bs-toggle="tooltip" data-bs-placement="top" title="Los registros serán eliminados cada año" style={{ borderRadius: '50px', width: '30px', height: '30px', background: 'gray' }}><i className='ri-information-line'></i></button>
                    </div>
                    <button className='btn px-4 me-1' ref={refBtn} onClick={handleDownloadFile}>Exportar</button>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los registros eliminados de las interacciones con Acendes, Intelimotor y Datadocs.</span>
            </section>

            <section className='mt-5 mx-4'>
                <div className='row col-12'>
                    <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDateTime(e.target.value) }} />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <Select options={dataProceso} className='col-12 mx-1' onChange={(e) => { setProcesoId(e.value) }} placeholder="Todos los procesos" />                        
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>                        
                        <Select options={dataCategoria} className='col-12 mx-1' onChange={(e) => { setCategoriaId(e.value) }} placeholder="Todas las categorias" />
                    </div>
                </div>

            </section>
            <RowPages
                setpageRows={setpageRows}
            />        
            <div className='mx-4 table-responsive'>
                <DataTable
                    column={columns}
                    data={data}
                    pageCount={pageCount}
                    paginate={true}
                    handlePageClick={handlePageClick}
                    currentPage={initialPage}
                    checkbox={true}
                    handleCheck={handleCheck}
                />
            </div>
        </>
    )
}

export default InterfazDetail