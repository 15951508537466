import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   dataVisitaDomiciliaria: [
      {
         folder: "ad352f69-34a2-4cbd-996d-5443de347d68",
         origen:`Evta` ,
         label: "Evidencia de Visita",
         value: 45
      },
      {
         folder: "916d04ce-e4ed-4a42-8e87-ac9692eade1f",
         origen: `Entdoc`,
         label: "Entrega de Documentación",
         value: 44
      }
   ],
   dataRecolecionUnidad: [
      {
         folder: "ce75afd0-3256-4e2e-88d2-2585b503607a" ,
         origen:`CRU` ,
         label: "Checklist Recuperación de Unidad",
         value: 41
      },
      {
         folder: "50b4262a-dde1-4383-b527-5e669c76a59e",
         origen: `TRC`,
         label: "Terminación de contrato",
         value: 53
      },
      {
         folder: "a6bb203d-5bf5-4c61-b70a-c187b4802844",
         origen: `ENG`,
         label: "Entrega de garantía",
         value: 50
      },
      {
         folder: "7dce5d60-c121-4f16-8fc9-c6382b0f371b",
         origen: `EVAD`,
         label: "Evidencia Adicional",
         value: 38
      },
      {
         folder: "11c8cb9a-b161-4390-958d-fa754f823fd5",
         origen: `FNC`,
         label: "Finiquito de contrato",
         value: 52
      },
      {
         folder: "232ecd08-f231-435e-b18d-fc2cab60596b",
         origen: `EDF`,
         label: "Entrega Definitivo",
         value: 51
      },
      {
         folder: "0395aa37-fcf9-4b51-a499-ff0e1a13cb6a",
         origen: `COT`,
         label: "Contrato de transacción",
         value: 55
      },
   ]
}

export const TipoDocumentoSlice = createSlice({
   name: 'TipoDocumentoSlice',
   initialState,
   reducers: {
      setDatos: (state, action) => {
         return { ...state }
      },
   },
})

export const { setDatos } = TipoDocumentoSlice.actions

export default TipoDocumentoSlice.reducer