import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceLogs = createSlice({
  name: 'logNavigate',
  initialState,
  reducers: {
    tabActiveLog: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveLog } = navigaSliceLogs.actions

export default navigaSliceLogs.reducer