import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import AgregarReparacionModal from '../../../Components/Modal/AgregarReparacionModal';
import DataTable from '../../../Components/datatable/DataTable';
import Garantias from '../../../Services/Cartera/Garantias';
import { ListaGarantiasReparacionDetalleSlice, setlistaGarantiasReparacionDetalle } from '../../../Redux/Slice/listaGarantiasReparacionDetalleSlice';
import { GarantiaReparacionSlice, setIdGarantiaReparacion } from '../../../Redux/Slice/reparacionSlice';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { GarantiaDevolucionSlice, setIdGarantiaDevolucion } from '../../../Redux/Slice/devolucionSlice';
import documentsHelper from '../../../Utils/documents';
import CardEntrega from './CardEntrega';

const CardReparacion
    = React.forwardRef(({ ...props }, ref) => {
        const { generalId, diagnostico } = props
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { GarantiaId } = useSelector((state) => state.GarantiaSlice);
        const { refFormik } = useRef();
        const { dataGarantiasReparacionDetalle } = useSelector((state) => state.ListaGarantiasReparacionDetalleSlice);
        const [motivosRechazo, setMotivosRechazo] = useState([]);
        const { idGarantiaReparacion } = useSelector((state) => state.GarantiaReparacionSlice);
        const { idGarantiaDevolucion } = useSelector((state) => state.GarantiaDevolucionSlice);

        const { fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numMotor,
            numContrato,
            producto,
            cliente,
            usoUnidad,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);


        const columMatment = [
            { field: 'fechaAlta', headerName: 'Fecha del registro', width: 150 },
            { field: 'usuarioAltaNombre', headerName: 'Técnico', width: 150 },
            { field: 'taller', headerName: 'Taller', width: 150 },
            { field: 'reparacionExterna', headerName: 'Reparación', width: 250 },
            { field: 'subtotal', headerName: 'Costo', width: 150 },
            { field: 'aplicaGarantia', headerName: 'Aplica Garantía', width: 250 },
            { field: 'tiempo', headerName: 'Tiempo', width: 80 },
            { field: ' ', headerName: 'Comentarios', width: 30 },
        ]

        let initial = {
            garantiaId: GarantiaId,
            reparacionStatusId: "",
            concluida: null,
            tiempoAmpliacionReparacion: null,
            comentariosAmpliacionReparacion: "",
            reparacionStatus: "",
            excedioTiempo: null,
            comentariosNoAceptaReparacion: null,
            garantiaMotivoNoAceptaReparacionId: null,
            notificarEjecutivoAmpliacionReparacion: null,
            clienteNotificadoConclusion: null,
            aceptaAmpliacion: null,
            fechaInicio: null,
            fechaConclusion: null
        }


        const [disabled, setDisabled] = useState(true);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const [Gasolina, setGasolina] = useState([]);
        const [concluida, setConcluida] = useState(false);
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [verReparacion, setVerReparacion] = useState(false);
        const [verEntregas, setVerEntregas] = useState(false);
        useEffect(() => {
            getReparacion()
        }, [idGarantiaReparacion]);

        React.useImperativeHandle(ref, () => ({
            getReparacion
        }));

        const getReparacion = async () => {
            setLoading(false)
            await Garantias.getReparacion(GarantiaId,vin)
                .then(resp => {
                    getMotivosRechazo()
                    if (resp.data.concluida) {
                        setVerEntregas(true);
                    }
                    setInitialValue(resp.data);
                    getListaDetalleReparacion(resp.data.garantiaReparacionId)
                    setVerReparacion(true)
                    dispatch(setIdGarantiaReparacion({ data: resp.data.garantiaReparacionId }))
                })
                .catch(err => {
                    setVerReparacion(false)
                    console.log(err, "Error al obtener reparaciones")
                })
        }

        const getMotivosRechazo = async () => {
            let tmpList = [];
            await Catalogo.getDropdownsByTable('Catalogos.GarantiaMotivoNoAceptaReparacion')
                .then(resp => {
                    resp.data.forEach(item => {
                        tmpList.push({
                            value: item.GarantiaMotivoNoAceptaReparacionId,
                            label: item.GarantiaMotivoNoAceptaReparacion
                        });
                    });
                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setMotivosRechazo(tmpList);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };

        const getListaDetalleReparacion = async (id) => {

            let data = {
                "garantiaReparacionId": id,
                "page": 1,
                "VIN":vin,
                "rows": 100
            }

            await Garantias.garantiaListaReparacionDetalle(data)
                .then(async res => {
                    let resdata = res.data.data
                    let datares = []
                    resdata.forEach(element => {
                        let total = (parseFloat(element.subtotal) * parseInt(element.iva) / 100) + parseFloat(element.subtotal)
                        datares.push({
                            fechaAlta: getDateFormat(element.fechaAlta, ""),
                            usuarioAltaNombre: element.usuarioAltaNombre,
                            taller: element.taller,
                            reparacionExterna: element.reparacionExterna ? 'Si' : 'No',
                            subtotal: total,
                            aplicaGarantia: element.aplicaGarantia ? 'Si' : 'No',
                            tiempo: element.tiempo,
                            comentarios: element.comentarios
                        })
                    });
                    dispatch(setlistaGarantiasReparacionDetalle(datares))

                }).catch(e => {
                    if (e.response !== undefined && e.response.data !== '')
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
                })

        }

        const downloadPDF = async () => {
            let data = {
                "garantiaId": GarantiaId,
                "reparacionStatusId": "10",
                "fechaInicio": new Date()
            }
            if (initialValue.reparacionStatusId === '0') {
                Garantias.reparacionActualizar(data).then(resp => {

                    console.log(resp.data, "Lo que tengo en datos")

                    setInitialValue(resp.data)

                    //No actualiza los datos de initialValue al instante, por eso se pone un timeout
                    setTimeout(() => {
                        const requestBody = {
                            nombrePlantilla: "ReparacionUnidad",
                            parametros: {
                                "<<VARIABLE_CONTRATO>>": numContrato,
                                "<<VARIABLE_VIN>>": vin,
                                "<<VARIABLE_FOLIO>>": resp.data.reparacionFolio,
                                "<<VARIABLE_DATAMOVIL>>": "",
                                "<<VARIABLE_CLIENTE>>": cliente,
                                "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + ", " + nombreModelo + ", MOD. " + nombreYear + ", " + nombreVersion + " " + color,
                                "<<VARIABLE_TRANSMI>>": "",
                                "<<VARIABLE_DIAGNOSTICO>>": diagnostico ? diagnostico : ""
                            }
                        };

                        Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                            //Pendiente de revisar por que no jala con el file-server
                            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                        }).catch(err => {
                            console.error(err);
                        });
                    }, 500);
                }).catch(err => {
                    console.log(err, "Error al actualizar una reparacion")
                })
            } else {
                console.log("pase al else", initialValue)
                const requestBody = {
                    nombrePlantilla: "ReparacionUnidad",
                    parametros: {
                        "<<VARIABLE_CONTRATO>>": numContrato,
                        "<<VARIABLE_VIN>>": vin,
                        "<<VARIABLE_FOLIO>>": initialValue.reparacionFolio,
                        "<<VARIABLE_DATAMOVIL>>": "",
                        "<<VARIABLE_CLIENTE>>": cliente,
                        "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + ", " + nombreModelo + ", MOD. " + nombreYear + ", " + nombreVersion + " " + color,
                        "<<VARIABLE_TRANSMI>>": "",
                        "<<VARIABLE_DIAGNOSTICO>>": diagnostico ? diagnostico : ""
                    }
                };

                // console.log(initialValue)
                // return false;
                await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                    //Pendiente de revisar por que no jala con el file-server
                    documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                }).catch(err => {
                    console.error(err);
                });
            }
        }

        const submitForm = (values) => {
            if (values.notificarEjecutivoAmpliacionReparacion && initialValue.reparacionStatusId === '20') {
                values.reparacionStatusId = 30
            }

            if (values.clienteNotificadoConclusion && values.notificarEjecutivoAmpliacionReparacion && values.aceptaAmpliacion && initialValue.reparacionStatusId === '30') {
                values.reparacionStatusId = 40
            }

            if (values.clienteNotificadoConclusion && values.notificarEjecutivoAmpliacionReparacion && !values.aceptaAmpliacion && initialValue.reparacionStatusId === '30') {
                values.reparacionStatusId = 80
                //Aqui mando a llamar la funcion
                devolucionCrear()
            }

            console.log(values, "Guardar")
            // return;

            // values.reparacionStatusId = 20
            if (values.concluida && initialValue.reparacionStatusId !== '80' && initialValue.reparacionStatusId !== '0') {
                values.reparacionStatusId = 70
                garantiaEntregaCrear()
            }
            Garantias.reparacionActualizar(values).then(resp => {
                setInitialValue(resp.data)
                toast.success(message("¡Operación exitosa!", "Guardado exitósamente"));
            }).catch(err => {
                toast.error(message("¡Operación fallida!", "Error al guardar reparación"));
            })
        }

        const AutorizarRechazar = (autorizar, values) => {
            if (autorizar) {
                values.reparacionStatusId = 30
            } else {
                values.reparacionStatusId = 80
            }
            Garantias.reparacionActualizar(values).then(resp => {
                setInitialValue(resp.data)
                toast.success(message("¡Operación exitosa!", `${autorizar ? 'Autorizado' : 'Rechazado'} exitósamente`));
            }).catch(err => {
                toast.error(message("¡Operación fallida!", `Error al ${autorizar ? 'Autorizar' : 'Rechazar'} la reparación`));
            })

        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear() + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const verModalReparacion = () => {
            setModalMessage({ isOpen: true, type: 5, title: 'Agregar Reparación', data: [], authorizationType: 1, Origin: "Reparacion" })
        }

        const devolucionCrear = () => {
            let data = {
                "garantiaId": GarantiaId,
                "garantiaCambioDevolucionStatusId": "0"
            }
            Garantias.garantiaCambioDevolucionCrear(data).then(resp => {
                toast.success(message("¡Operación exitosa!", "Se creo devolución"));
                dispatch(setIdGarantiaDevolucion({ data: resp.data.garantiaCambioDevolucionId }))
            }).catch(error => {
                toast.error(message("¡Operación fallida!", "Error al crear devolución"));
            })
        }

        const garantiaEntregaCrear = () => {
            let data = {
                garantiaId: GarantiaId,
                origen: "garantia",
                GarantiaEntregaStatusId: 0
            }
            Garantias.garantiaEntregaCrear(data).then(resp => {
                toast.success(message("¡Operación exitosa!", "Se creo Entrega"));
                setVerEntregas(true)
            }).catch(error => {
                toast.error(message("¡Operación fallida!", "Error al crear devolución"));
                setVerEntregas(false)
            })
        }

        const handleActualizar = () => {
            console.log();
        }

        const validate = Yup.object({});

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={960}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 5 &&
                        <AgregarReparacionModal
                            modalMessage={modalMessage}
                            handleActualizar={handleActualizar}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    verReparacion &&
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseReparacion" role="button" aria-expanded="false" aria-controls="collapseReparacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={!initialValue.concluida ? 'semaforo semaforo-yellow' : (initialValue.concluida ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Reparación</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseReparacion">

                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar Documento </strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                {/* <div className='col-auto'>
                                                                    <span className={initialValue.recepcionFolio !== 0 && !initialValue.concluida ? 'semaforo semaforo-yellow' : (initialValue.recepcionFolio !== 0 && initialValue.concluida ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Reparación</strong>
                                                                </div> */}
                                                                <div className='col-auto'>
                                                                    <button className='btn'
                                                                        disabled={initialValue.reparacionStatusId === '0'}
                                                                        onClick={() => verModalReparacion()}>
                                                                        Agregar reparación
                                                                    </button>
                                                                </div>
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button className='btn btn-gray' disabled={initialValue.reparacionStatusId === '0'}>
                                                                            <i className="icon-blue fa fa-file"></i> Subir documento
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    !initialValue.concluida &&
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid || (!isValid && disabled) || initialValue.reparacionStatusId === '0'}
                                                                            onClick={() => submitForm(values)}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                }

                                                                {/* <div className='col-auto'>
                                                                    <button
                                                                        className='btn btn-gray'
                                                                        disabled={initialValue.reparacionStatusId === '0'}
                                                                        onClick={() => AutorizarRechazar(false, values)}
                                                                    >
                                                                        Rechazar
                                                                    </button>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.reparacionStatusId === '0'}
                                                                        onClick={() => AutorizarRechazar(true, values)}
                                                                    >
                                                                        Autorizar
                                                                    </button>
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Documento de reparación</small>
                                                        </div>
                                                        <div className="col-3">
                                                            {/* <button className='btn btn-gray' onClick={() => downloadPDF()} disabled={initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}> */}
                                                            <button className='btn btn-gray' onClick={() => downloadPDF()}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className='row mt-4 px-4'>
                                                        <div className="col">
                                                            <small><strong>Detalles de reparación</strong></small>
                                                            <DataTable
                                                                column={columMatment}
                                                                data={dataGarantiasReparacionDetalle}
                                                                comments={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field
                                                                className="form-check-input"
                                                                disabled={initialValue.reparacionStatusId === '0' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                type="checkbox"
                                                                name="concluida"
                                                                onClick={(event) => {
                                                                    setFieldValue("concluida", event.target.value);
                                                                    // setConcluida(event.target.checked);
                                                                }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Reparación total</label>
                                                        </div>
                                                    </div>

                                                    {
                                                        !initialValue.excedioTiempo &&
                                                        <>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-8">
                                                                    <small><strong>Ampliación de reparación</strong></small>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-3">
                                                                    <TextField id="tiempoAmpliacionReparacion"
                                                                        disabled={initialValue.reparacionStatusId === '0' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        label="Dias de ampliación"
                                                                        name="tiempoAmpliacionReparacion"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                        onChange={(event) => {
                                                                            setFieldValue("tiempoAmpliacionReparacion", event.target.value);
                                                                        }} />
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-6">
                                                                    <TextAreaField
                                                                        id="comentariosAmpliacionReparacion"
                                                                        label="Comentarios del técnico"
                                                                        disabled={initialValue.reparacionStatusId === '0' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        className="form-control col-12"
                                                                        rows="3"
                                                                        name="comentariosAmpliacionReparacion"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                        onChange={(event) => {
                                                                            setFieldValue("comentariosAmpliacionReparacion", event.target.value);
                                                                        }} />
                                                                </div>
                                                            </div>


                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="notificarEjecutivoAmpliacionReparacion"
                                                                        disabled={initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        onClick={(event) => {
                                                                            setFieldValue("notificarEjecutivoAmpliacionReparacion", event.target.value);
                                                                            // setConcluida(event.target.checked);
                                                                        }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Notificar a ejecutivo extención de reparación</label>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-3">
                                                                    <SelectField id="aceptaAmpliacion"
                                                                        label="Cliente acepta ampliación de reparación"
                                                                        name="aceptaAmpliacion"
                                                                        disabled={initialValue.reparacionStatusId === '20' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        items={opcionesBool} onChange={(event) => {
                                                                            setFieldValue("aceptaAmpliacion", event.value);
                                                                        }} />
                                                                </div>
                                                                {
                                                                    values.aceptaAmpliacion === 0 &&
                                                                    <div className="col-3">
                                                                        <SelectField
                                                                            id="garantiaMotivoNoAceptaReparacionId"
                                                                            label="Motivo rechazo"
                                                                            name="garantiaMotivoNoAceptaReparacionId"
                                                                            disabled={initialValue.reparacionStatusId === '20' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                            items={motivosRechazo} onChange={(event) => {
                                                                                setFieldValue("garantiaMotivoNoAceptaReparacionId", event.value);
                                                                            }} />
                                                                    </div>
                                                                }

                                                            </div>

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-6">
                                                                    <TextAreaField id="comentariosNoAceptaReparacion"
                                                                        label="Comentarios" className="form-control col-12"
                                                                        rows="3" name="comentariosNoAceptaReparacion"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                        disabled={initialValue.reparacionStatusId === '20' || initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        onChange={(event) => {
                                                                            setFieldValue("comentariosNoAceptaReparacion", event.target.value);

                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input"
                                                                        type="checkbox"
                                                                        disabled={initialValue.reparacionStatusId === '70' || initialValue.reparacionStatusId === '80'}
                                                                        name="clienteNotificadoConclusion" onClick={(event) => {
                                                                            setFieldValue("clienteNotificadoConclusion", event.target.value);
                                                                            // setConcluida(event.target.checked);
                                                                        }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                                </div>
                                                            </div>


                                                        </>
                                                    }

                                                    {/*
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <small><strong>Notificar la demora de la reparación</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Usuario notificó al cliente" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Fecha notificación al cliente" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Ampliación de reparación" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Fecha ampliación" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField id="usuarioAltaNombre" disabled={true} label="Motivo rechazo" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioAltaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosDiagnostico" label="Comentarios" disabled={disabled} className="form-control col-12" rows="3" name="comentariosDiagnostico" type="text" holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosDiagnostico", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={disabled} type="checkbox" name="clienteNotificado" onClick={(event) => {
                                                                setFieldValue("clienteNotificado", event.target.value);
                                                                setConcluida(event.target.checked);
                                                            }} />
                                                            <label className="form-check-label" style={{'marginLeft':'10px'}}>Cliente notificado</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <small><strong>Detalles de reparación</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaInicio !== "" &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaConclusion !== "" &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                                    <div className="col-12 col-lg-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <small className='text-body-tertiary'>Cliente: {cliente}</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaInicio !== null &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaConclusion !== null &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            {initialValue.tiempoAmpliacionReparacion}
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    verEntregas &&
                    <CardEntrega
                        diagnostico={diagnostico}
                    />
                }
            </>
        )
    })
export default CardReparacion