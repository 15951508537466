import axios from '../../Utils/ApiService';

const ServicioExpedientes = {
    getSeguimientoExpedientes: ({ buscar, page, rows }) => {
        let query = '';
        if(buscar?.trim().length > 0) query += `buscar=${buscar}&`

        query += `page=${page}&rows=${rows}`;

        return axios.get(`/cartera/SeguimientoExpedientes?${query}`);
    },
    getDetalleExpediente: (id) => {
        return axios.get(`/cartera/Expediente?CierreContratoExpedienteId=${id}`);
    },
    ampliarExpediente: (data) => {
        return axios.put(`/cartera/AmpliarExpediente`, data);
    },
    addTramite: (data) => {
        return axios.post(`/cartera/AddTramite`, data);
    },
    getTramite: (id) => {
        return axios.get(`/cartera/GetTramite?GeneralId=${id}`);
    },
    addSolicitud: (data) => {
        return axios.post(`/cartera/AddSolicitud`, data);
    },
    getSolicitudes: (id) => {
        return axios.get(`/cartera/GetSolicitudesByTramite?GeneralId=${id}`);
    },
}

export default ServicioExpedientes;