import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentPage: 0,
    list: [],
    hasNextPage: false,
    hasPreviousPage: false,
    pageSize: 10,
    totalPages: 0,
    totalRows: 0
}

export const LlamadasSlice = createSlice({
    name: 'LlamadasSlice',
    initialState,
    reducers: {
        listaLlamadas: (state, action) => {
            console.log(action.payload)
            return {...state,
                currentPage: action.payload.currentPage,
                list: action.payload.data,
                hasNextPage: action.payload.hasNextPage,
                hasPreviousPage: action.payload.hasPreviousPage,
                pageSize: action.payload.pageSize,
                totalPages: action.payload.totalPages,
                totalRows: action.payload.totalRows
            }
        }
    }
})

export const { listaLlamadas } = LlamadasSlice.actions

export default LlamadasSlice.reducer