import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import '../datatable/DataTable.css'
import toast from 'react-hot-toast'
import { message } from '../../Utils/functions';

const DataTableCheck = ({ column, data, editable, deleteable, detailable, addtable, handleEdit, handleAdd, handleDelete, handleDetail, paginate = true, checkbox = false }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [itemPage, setTotalPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    useEffect(() => {
        setColumns(column);
        if (data === null)
            setRows([]);
        else
            setRows(data);
    }, [data])

    const handlegetPropertyValue = (object, field) => {
        try {
            return object[field]?.trim();            
        } catch (error) {
            // console.log("error",error)
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }
    const endOffset = itemOffset + itemPage;
    const currentItems = rows.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(rows.length / itemPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPage) % rows.length;      
        setItemOffset(newOffset);
    };
    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr className='custom-table'>
                        {
                            columns?.map((item, index) => {
                                return <th scope="col" style={{ width: `${item.width}px` }} key={index}>{item.headerName}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        currentItems.length > 0 ?
                            currentItems?.map((rowObj, index) => {
                                return <tr key={index}>
                                    {
                                        columns?.map((column, index) => {
                                            return <td key={index}><span  style={{ borderRadius: '5px', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' ? `${rowObj.colorTexto}` : 'none' }}>{handlegetPropertyValue(rowObj, column.field)}</span></td>
                                        })
                                    }
                                    {/* <td>
                                        {
                                            addtable &&
                                            addtable ? <button className='btn-tbl mx-1 btnAdd'><i className="bx bx-list-plus" onClick={() => { handleAdd(rowObj) }}></i></button> : null
                                        }
                                        {
                                            editable &&
                                            editable ? <button className='btn-tbl mx-1 btnEdit' onClick={() => { handleEdit(rowObj) }}><i className="bx bx-pencil"></i></button> : null
                                        }
                                        {
                                            deleteable &&
                                            deleteable ? <button className='btn-tbl mx-1 btnDelete'><i className="bx bxs-trash-alt" onClick={() => { handleDelete(rowObj) }}></i></button> : null
                                        }
                                        {
                                            detailable && 
                                            detailable ? <button className='btn-tbl mx-1 btnDetail' onClick={() => { handleDetail(rowObj) }}><i className="bx bxs-chevron-right"></i></button> : null
                                        }
                                    </td>
                                    <td>
                                        {
                                            checkbox &&
                                            checkbox ? <input type="checkbox" /> : null
                                        }
                                    </td> */}
                                </tr>
                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>

                    }
                </tbody>
            </table>
            {
                paginate &&
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            }

        </>
    )
}

export default DataTableCheck