import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { message } from '../../../Utils/functions';
import AuthLogin from '../../../Utils/AuthLogin';
import SubMenuPrealta from '../../Ventas/Prealta/subMenuPrealta';
import { TextAreaField } from '../../../Components/TextAreaField';
import DataTable from '../../../Components/datatable/DataTable';

const auth = new AuthLogin();
const AddEditRevisionDeUnidad = () => {
    const { state } = useLocation();
    const role = auth.getSesionValue("role");
    const navigate = useNavigate();
    let { id } = useParams();
    const { value } = useSelector((state) => state.navigaSlicePrealta);
    const dispatch = useDispatch();
    const refFormik = useRef();
    const columns = [
        { field: 'tecnico', headerName: 'Mecánico', width: 200 },
        { field: 'fechaRevision', headerName: 'Fecha de la revisión', width: 230 },
        { field: 'usuario', headerName: 'Usuario', width: 250 },
        { field: 'comentarios', headerName: 'Comentarios', width: 130 },
        { field: 'respuestaTecnico', headerName: 'Respuesta Mecánico', width: 150 }
    ];
    let initial = {
        vin: state?.vin ?? "",
        marca: state?.nombreMarca,
        modelo: state?.nombreModelo,
        year: state?.nombreYear,
        version: state?.nombreVersion,
        condicion: state?.tipoCondicion,
        transmision: state?.transmision ?? "",
        externoId: "",

        ventaContado: state?.ventaContado,

        generalId: id,
        fechaRevision: "",
        tecnicoId: "",
        respuestaTecnico: "",
        comentarios: "",
        vehiculoEnCredito: state?.vehiculoEnCredito
    }

    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        fechaRevision: Yup.string()
            .required("El campo es requerido."),
        tecnicoId: Yup.string()
            .required("El campo es requerido."),
        respuestaTecnico: Yup.bool()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido.")
    });
    const [dataMarca, setDataMarca] = useState([]);
    const [dataModelo, setDataModelo] = useState([]);
    const [dataYear, setDataYear] = useState([]);
    const [dataVersion, setDataVersion] = useState([]);
    const [dataSocioComercial, setDataSocioComercial] = useState([]);
    const [dataCondicion, setDataCondicion] = useState([]);
    const [loading, setLoading] = useState(false);
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveYear, setClaveYear] = useState("");
    const [activeComision, setActiveComision] = useState(false);
    const [tieneDocRevisionUnidad, setTieneDocRevisionUnidad] = useState(false);


    const [progressBar, setProgressBar] = useState(0);
    const [DataTecnico, setDataTecnico] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [dataHistorial, setDataHistorial] = useState([])
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();


    const respuestaTecnico = [
        { value: "", label: "Seleccione" },
        { value: true, label: "Si" },
        { value: false, label: "No" },
    ];
    useEffect(() => {
        handleGetAllMarca();
        // handleChangeMarca(item.claveMarca);
        // handleChangeModelo(item.claveModelo);
        // handleChangeYear(item.claveYear);
        handleGetCondicion();
        handleGetAllTecnico();
        handleGetRevisionDoc();
        // if (id != 0) {
        //     handleGetById();
        //     handleChageProgress(refFormik.current.errors);
        // }
    }, [])
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VentaVehiculoService.getAllHistory(id, page, 10)
            .then(res => {
                console.log(res.data.data)
                setDataHistorial(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetRevisionDoc = async () => {
        await Catalogo.getdocRevisionUnidad(id)
            .then(res => {

                setTieneDocRevisionUnidad(res.data.data?.docRevisionUnidad)
            }).catch(e => {

            })
    }
    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getlAllVehiculoPrealtaById(id)
            .then(res => {
                let item = res.data.data;

                let initial = {
                    generalId: id,
                    vin: item?.vin ?? "",
                    claveMarca: item?.claveMarca ?? "",
                    claveModelo: item?.claveModelo ?? "",
                    claveYear: item?.claveYear ?? "",
                    claveVersion: item?.claveVersion ?? "",
                    condicionId: item?.condicionId ?? "",
                    externoId: item?.externoId ?? "",
                    status: item?.status ?? true,
                    ventaContado: state?.ventaContado,

                    fechaRevision: "",
                    tecnicoId: "",
                    respuestaTecnico: "",
                    comentarios: ""
                }
                handleChangeMarca(item.claveMarca);
                handleChangeModelo(item.claveModelo);
                handleChangeYear(item.claveYear);
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllTecnico = async () => {
        await Catalogo.getAllCSMecanico()
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    NewData.push({
                        value: item.mecanicoId,
                        label: item.nombre
                    })
                });
                setDataTecnico(NewData);
            }).catch(e => {

            })
    }

    const handleGetAllMarca = async () => {
        await VehiculoService.getAllMarca()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveMarca,
                        label: item.nombreMarca
                    })
                });
                setDataMarca(NewData);
            }).catch(e => {
            })
    }
    const handleChangeMarca = async (value) => {
        setClaveMarca(value);
        setDataModelo([]);
        setDataYear([]);
        setDataVersion([]);
        await VehiculoService.getAllModeloById(value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveModelo,
                        label: item.nombreModelo
                    })
                });
                setDataModelo(NewData);
            }).catch(e => {
            })
    }
    const handleChangeModelo = async (value) => {
        setClaveModelo(value);
        if (value === "")
            setDataYear([]);
        await VehiculoService.getAllYearById(claveMarca, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveYear,
                        label: item.nombreYear
                    })
                });
                setDataYear(NewData);
            }).catch(e => {
            })
    }
    const handleChangeYear = async (value) => {
        setClaveYear(value);

        await VehiculoService.getAllVersionById(claveMarca, claveModelo, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveVersion,
                        label: item.nombreVersion
                    })
                });
                setDataVersion(NewData);
            }).catch(e => {
            })
    }


    const handleGetCondicion = async () => {
        await Catalogo.getdataTabla("Condicion", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.condicionId,
                            label: item.condicion
                        })
                    }
                });
                setDataCondicion(NewData);
            }).catch(e => {

            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handleAddEdit = async (value) => {
        if (!tieneDocRevisionUnidad) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "El vehiculo no cuenta con documento Checklist de Inspección.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 2000);
            return;
        }
        setLoading(true);
        await VentaVehiculoService.addRevisionUnidad(value)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                setLoading(false);
                if (state?.esSocioComercial)
                    navigate('/inventario/sociocomercial');
                else if (state?.ventaContado)
                    navigate('/inventario/venta/ventacontado');
                else
                    navigate('/inventario/vehiculo');

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
        // if (id == 0) {
        //     setLoading(true);
        //     await VentaVehiculoService.addVehiculoPrealta(value)
        //         .then(res => {
        //             toast.success(message("¡Correcto.!", res.data.data.mensaje));
        //             setLoading(false);
        //             if (state?.esSocioComercial)
        //                 navigate('/inventario/sociocomercial');
        //             else if (state?.ventaContado)
        //                 navigate('/inventario/venta/ventacontado');
        //             else
        //                 navigate('/inventario/vehiculo');

        //         }).catch(e => {
        //             setLoading(false);
        //             if (e.response !== undefined)
        //                 toast.error(message("¡Error.!", e.response.data.message));
        //             else
        //                 toast.error(message("¡Error.!", e.message));
        //         })
        // } else {
        //     setLoading(true);
        //     await VentaVehiculoService.updateVehiculoPrealt(id, value)
        //         .then(res => {
        //             toast.success(message("¡Correcto.!", res.data.data.mensaje));
        //             setLoading(false);
        //             if (state?.esSocioComercial)
        //                 navigate('/inventario/sociocomercial');
        //             else
        //                 navigate('/inventario/vehiculo');
        //         }).catch(e => {
        //             setLoading(false);
        //             if (e.response !== undefined)
        //                 toast.error(message("¡Error.!", e.response.data.message));
        //             else
        //                 toast.error(message("¡Error.!", e.message));
        //         })
        // }
    }
    const handleChange = (value, valuesState) => {
        setActiveComision(value.value !== "" ? true : false);
        if (value.label === "Monto Fijo")
            setInitialValue({ ...valuesState, isMontoFijo: true, tipoComisionId: value.value, comision: "" });
        else
            setInitialValue({ ...valuesState, isMontoFijo: false, tipoComisionId: value.value, comision: "" });


    }
    const handleChangeTipoCompra = (value, valuesState) => {
        // setActiveComision(value.value !== "" ? true : false);
        if (value.label === "Socio Comercial")
            setInitialValue({ ...valuesState, isSocioComercial: true, esSocioIndependiente: true, tipoCompraId: value.value, usrSocioComercialId: "" });
        else if (value.label === "Independiente")
            setInitialValue({ ...valuesState, isSocioComercial: false, esSocioIndependiente: true, tipoCompraId: value.value, usrSocioComercialId: "" });
        else
            setInitialValue({ ...valuesState, isSocioComercial: false, esSocioIndependiente: false, tipoCompraId: value.value, usrSocioComercialId: "" });

    }

    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 16;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);
    }

    // const message = (title, message) => <p style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></p>
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
                console.log(values)
            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial');
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <span className='fs-5'><strong>Información del vehiculo</strong></span>
                                        <div className='d-flex'>
                                            {
                                                !values.vehiculoEnCredito &&
                                                <>
                                                    <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                                        if (state.numIdFolder !== null) {
                                                            navigate(`/inventario/vehiculo/datadocs/${id}`, {
                                                                state: { ...state, revisionUnidad: true },
                                                            });
                                                        } else
                                                            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);

                                                    }}><i className="icon ri-file-3-fill mx-1"></i>Subir documentos</fieldset>
                                                    <button className='btn mx-2' type="submit" >{"Guardar"}</button>
                                                </>
                                            }
                                            {
                                                values.vehiculoEnCredito &&
                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                            }

                                        </div>

                                        {/* disabled={!(isValid)} */}
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="vin" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <div className='d-flex align-items-center'>
                                            <span><strong>Revisión de unidad</strong></span>
                                            <div className="col-sm-1 mx-2">
                                                <fieldset disabled={values.vehiculoEnCredito} className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} id="FechaRevisionId" label="Fecha de la revisión:" name="fechaRevision" type="date" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} id="MEcanidoId" label="Mecánico:" name="tecnicoId" items={DataTecnico} onChange={(event) => {
                                                    setFieldValue("tecnicoId", event.value);
                                                    handleChageProgress(errors, isValid);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">

                                                <SelectField disabled={values.vehiculoEnCredito} id="SocioComercial" label="Respuesta Mecánico:" name="respuestaTecnico" items={respuestaTecnico} onChange={(event) => {
                                                    setFieldValue("respuestaTecnico", event.value);
                                                    handleChageProgress(errors, isValid);
                                                }} />
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <TextAreaField disabled={values.vehiculoEnCredito} label="Comentarios del Mecánico:" className="form-control col-8" rows="4" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                    handleChageProgress(errors)
                                                }} />
                                            </div>

                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </section>
                    </div>

                    {
                        isOpenModal &&
                        <Modal isOpen={isOpenModal} color='#fff'>
                            <div className='d-flex'>
                                <span><strong>Historial de cambio de etapas manuales</strong></span>

                            </div>
                            <DataTable
                                column={columns}
                                data={dataHistorial}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                            </section>
                        </Modal>
                    }

                </>
            )
            }
        </Formik>
    )
}

export default AddEditRevisionDeUnidad