import { useEffect, useRef, useState } from "react";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import DSystemsServicios from "../../../Services/DatamovilSystems/ServiciosDatamovil";

const ListadoServiciosHook = () => {
    const { id } = useParams();
    const refFormik = useRef();

    const navigate = useNavigate();

    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [proceso, setProceso] = useState('');
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [procesos, setProcesos] = useState([]);
    const [busquedaAvanzada, setBusquedaAvanzada] = useState({
        buscarAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: ""
    });

    const columns = [
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'proceso', headerName: 'Proceso', widht: '150px' },
        { field: 'marca', headerName: 'Marca', widht: '150px' },
        { field: 'modelo', headerName: 'Modelo', widht: '150px' },
        { field: 'anio', headerName: 'Año', widht: '150px' },
        { field: 'version', headerName: 'Versión', widht: '150px' },
        { field: 'tipoSolicitud', headerName: 'Tipo de solicitud', widht: '150px' },
        { field: 'estatus', headerName: 'Estatus', widht: '150px' },
        { field: 'ultimoMovimiento', headerName: 'Últ. Movimiento', widht: '150px' },
    ];

    useEffect(() => {
        getProcesos();
        getListado();
    }, []);

    useEffect(() => {
        getListado();
    }, [buscar, busquedaAvanzada]);

    useEffect(() => {
        getListado();
    }, [id]);



    const getProcesos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Procesos')
            .then(resp => {
                let list = [];
                resp.data.forEach(item => {
                    list.push({
                        value: item.procesoId,
                        label: item.proceso
                    })
                });
                setProcesos(list);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const getListado = async () => {
        setLoading(true);
        const params = {
            buscar,
            procesoId: proceso,
            tipoServicioId: id,
            page,
            rows,
            buscarAvanzado: busquedaAvanzada.buscarAvanzado,
            fechaInicio: busquedaAvanzada.fechaInicio,
            fechaFin: busquedaAvanzada.fechaFin,
            concluido: busquedaAvanzada.concluido
        };
        await DSystemsServicios.getSeguimientoServiciosPorTipo(params)
            .then(resp => {
                const items = resp.data.data.map(item => {
                    item.ultimoMovimiento = item.ultimoMovimiento === '' ? '-' : item.ultimoMovimiento;
                    return item;
                })
                setData(items);
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            })
    };


    const handleEdit = (item) => {
        if (item.paso == null) {
            //toast.error(message("El registro seleccionado no contiene un paso!"));
            return;
        }
        //datamovil/5/1/A7F433E7-A58E-46F3-A17C-01A79F90C6AC/1
        navigate(`/datamovil/${item.tipoProceso}/${item.tipoServicioid}/${item.servicioId}/${item.paso}`, {
            state: item,
        })
    }


    return {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        procesos,
        handleEdit,
        busquedaAvanzada,
        setBusquedaAvanzada
    }
}

export default ListadoServiciosHook;