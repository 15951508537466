import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../Services/Catalogos/Catalogo';
const useOrdenServicio = (handleConcluido) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const initial = {
        servicioOrdenId: "",
        servicioId: "",
        departamentoId: null,
        motivoSercicioId: null,
        fechaSolicitud: "",
        horaSolicitud: "",
        comentarios: null,
        concluido: null,
        fechaConcluido: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaAlta: "",
        fechaActualiza: null
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [motivoServicio, setMotivoServicio] = useState([]);

    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const tipoUbicaciones = [
        { value: 1, label: 'Interna' },
        { value: 2, label: 'Externa' },
    ];

    useEffect(() => {
        getDataCostoAdicional();
        getDepartamentos();
        getMotivoServicio();
    }, []);

    const getDepartamentos = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.Departamentos')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.DepartamentoId,
                        label: item.Departamento
                    });
                });
                setDepartamentos(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getMotivoServicio = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.Motivo_Servicio')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.MotivoServicioId,
                        label: item.MotivoServicio
                    });
                });
                setMotivoServicio(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getDataCostoAdicional = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioOrden(id)
            .then(res => {
                let resData = res.data
                if(resData.fechaSolicitud){
                    resData.fechaSolicitud = resData.fechaSolicitud.split("T")[0]
                }
                setInitialValue({ ...initialValue, ...resData })
                handleConcluido({ concluido: resData.concluido })
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitForm = async (values) => {
        setLoading(true);
        DSystemsServicios.updateServicioOrden(values)
            .then(res => {
                handleConcluido({ concluido: values.concluido })
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm,
        departamentos,
        motivoServicio
    };
};

export default useOrdenServicio;
