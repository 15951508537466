import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import dataDocsServices from '../../Services/DataDocs/dataDocsServices';
import { convertToBase64, message, toBase64 } from '../../Utils/functions';
import axios from 'axios';
import AuthLogin from '../../Utils/AuthLogin';
import pruebaManejoService from '../../Services/Venta/Vehiculo/pruebaManejoService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import EndosoService from '../../Services/Inventario/EndosoService';
import EntregaService from '../../Services/Inventario/EntregaService';

const auth = new AuthLogin();

const useDataDocs = (toast) => {
  const fileRef = useRef();
  const refbtn = useRef();
  const { state } = useLocation();
  let { id } = useParams();
  let url = auth.UrlDataDocs();
  let RolId = auth.getSesionValue("idRole");
  const navigate = useNavigate();
  // file
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [isPruebaManejo, setIsPruebaManejo] = useState(state?.pruebaManejo ?? false);
  const [isEmisionPoliza, setIsEmisionPoliza] = useState(state?.emisionPoliza ?? false);
  const [esGestoriaAutorizado, setEsGestoriaAutorizado] = useState(state?.gestoria === 1 ? true : state?.gestoria === 0 ? false : null)
  const [esEntregaRealizada, setEntregaRealizada] = useState(state?.entregaRealizada === 1 ? true : state?.entregaRealizada === 0 ? false : null)
  const [esEndoso, setesEndoso] = useState(state?.endoso ?? false);
  const [esinstalacionDatamovil, setEsinstalacionDatamovil] = useState(state?.instalacionDatamovil ?? false);
  const [esFirmaContrato, setEsFirmaContrato] = useState(null);
  const [esfirmaContratoList, setesfirmaContratoList] = useState(state?.firmaContrato ?? false);
  const [esExpediente, setesExpediente] = useState(state?.expediente ?? false);

  const [dataDocuments, setdataDocuments] = useState([]);
  const [loading, setloading] = useState(false);
  const [existFile, setExistFile] = useState(null);
  const [openModalDocs, setOpenModalDocs] = useState(false);
  const [openModalChangeName, setopenModalChangeName] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [currentFile, setCurrentFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [estadoId, setEstadoId] = useState("");
  const [loadModalConfirm, setLoadModalConfirm] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [loadModalConfirmDelete, setloadModalConfirmDelete] = useState(false);
  const [actions, setActions] = useState({ deleted: false, downloaded: false, replazed: false, continue: false, acendes: false })
  const [cargaAcendes, setCargaAcendes] = useState(false);

  //Modal de Autorizado o rechazado Gestoria
  const [openModalGestoriaRechazada, setOpenModalGestoriaRechazada] = useState(false);
  const [openModalGestoriaAutorizada, setOpenModalGestoriaAutorizada] = useState(false);
  const [openModalEndoso, setOpenModalEndoso] = useState(false);
  const [openModalEntrega, setOpenModalEntrega] = useState(false);
  const [openModalEntregaRechazada, setOpenModalEntregaRechazada] = useState(false);
  //Expediente
  const [openModalExpediente, setOpenModalExpediente] = useState(false);
  // EmisionPoliza
  const [openModalEmisionPoliza, setOpenModalEmisionPoliza] = useState(false);
  //PruebaManejo
  const [openModalPM, setOpenModalPM] = useState(false);

  //Permisos State
  const [permisoDocumento, setPermisoDocumento] = useState();

  useEffect(() => {
    handleGetAllDoc();
  }, [])
  const handleGetAllDoc = async () => {
    setloading(true);
    let action;
    if (esGestoriaAutorizado === null && esEntregaRealizada === null)
      action = "";
    else {
      action = esGestoriaAutorizado === null ? esEntregaRealizada ? 1 : 0 : esGestoriaAutorizado ? 1 : 0;
    }

    await dataDocsServices.getListDocs(id, state?.tipoCompraId, action, state?.productoId)
      .then(res => {
        setActions({ ...actions, continue: false });
        setdataDocuments(res.data.data);
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("error.!", e.response.data.message));
        else
          toast.error(message("error.!", e.message));
      }).finally(x=>{
        setTimeout(() => {
        setloading(false);
        }, 3000);
      })

  }
  const handleAddNewFile = (etapaId, item) => {

  }
  const handleGetFileDataDocs = async (value, item) => {
    setloading(true);
    const { tipoDocumentoId, existe, documentoEnDatadocs } = item;
    setItemSelected(value);
    setselectedItem(item);
    console.log(item)
    setFileName("");
    // await handleGetFileById()
    // setSelectedDocs([])
    setExistFile(false);
    setActions({ ...actions, replazed: false, downloaded: false, deleted: false, continue: false });
    if (existe) {
      if (item.cargaAcendes === 2)
        setActions({ ...actions, replazed: true, downloaded: true, deleted: true, continue: false, acendes: false });
      else
        setActions({ ...actions, replazed: true, downloaded: true, deleted: true, continue: false, acendes: true });

      setFileName(documentoEnDatadocs);
      await handleGetTokenDatadocs();
      await handleGetFileById(item);
    } else {
      if (currentFile !== tipoDocumentoId) {
        setCurrentFile(tipoDocumentoId);
        setExistFile(existe);
        setSelectedFile();
        setFileName("");
      }
    }
    setloading(false);
  }
  const handleGetFileById = async (item) => {
    setloading(true);
    await axios.post(`${url}Documento/GetFileById/${state.numIdFolder}/${item.documentoId}/false`, {}, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      },
      responseType: "blob"
    })
      .then(res => {
        var file = new File([res.data], res.data.name, { type: res.data.type });
        setExistFile(true);
        setSelectedDocs([file])
        setSelectedFile(file)
        setloading(false);
        // toast.success(message("¡Correcto.!", "Archivo guardado correctamente."));
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.errors?.error));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handledownloadFile = async () => {
    setloading(true);
    await axios.post(`${url}Documento/GetFileById/${state.numIdFolder}/${itemSelected?.documentoId}/false`, {}, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      },
      responseType: "blob"
    })
      .then(res => {
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${itemSelected.documentoEnDatadocs}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setloading(false);
        toast.success(message("¡Correcto.!", "Archivo descargado correctamente."));
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.errors?.error));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const changeHandler = () => {
    fileRef.current.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    );
  };
  const handleChangeFile = (event) => {
    setExistFile(true);
    setActions({ ...actions, replazed: true, continue: true, acendes: false, downloaded: false, deleted: false });
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setSelectedDocs(Array.from(event.target.files))
    // setSelectedDocs(event.target.files[0])
    setOpenModalDocs(false)
  }
  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => {
        setloading(false);
        setOpenModalDocs(false);
        setLoadModalConfirm(false);
        if (e.response !== undefined && e.response.data !== "") {
          if (e.response.data?.errors.error !== undefined) {
            handleSaveLogs("Subir", "Insertar", url, e.response.data.errors.error);
            toast.error(message("Error.!", e.response.data.errors.error));
          }
          else {
            handleSaveLogs("Subir", "Insertar", url, e.response.data?.errors);
            toast.error(message("Error.!", e.response.data?.errors));
          }
        }
        else {
          handleSaveLogs("Subir", "Insertar", url, e.message);
          toast.error(message("Error.!", e.message));
        }

      })
  }
  const handleSubmitFile = async (item) => {

    if (selectedItem?.cargaAcendes === null) {
      handleSubmit(item.filename, item, false);
      return
    }

    if (!state?.utilitario && selectedItem?.cargaAcendes < 2) {
      // if (state?.tipo_docto) {    
      setloading(true);
      let data = {
        name: item.filename,//state?.nombreDocumento,
        type: selectedFile.type.split("/")[1],
        vin: state?.vin,
        sol_id: state?.solicitud || state?.sol_id || state?.numSolicitud,
        tipo_docto: selectedItem?.tipo_docto,
        documento: await convertToBase64(selectedFile).then(res => res)
      }
      await pruebaManejoService.UploadFileAscendes(data)
        .then(res => {
          if (res.data.statusAcendes === "ok") {
            toast.success(message("¡Mensaje Acendes.!", res.data.mensajeAcendes));
            setCargaAcendes(true);
            handleSubmit(item.filename, item, true);
          } else {
            toast.error(message("Error Acendes.!", res.data.mensajeAcendes));
            setloading(false);
          }
        }).catch(e => {
          setloading(false);
        })
    }
    else {
      handleSubmit(item.filename, item, false);
    }
  }

  const handleUploadFileToAcendes = async () => {
    if (selectedItem.cargaAcendes < 2) {
      setloading(true);
      let data = {
        name: fileName,//state?.nombreDocumento,
        type: selectedFile.type.split("/")[1],
        vin: state?.vin,
        sol_id: state?.solicitud || state?.sol_id || state?.numSolicitud,
        tipo_docto: itemSelected?.tipo_docto,
        documento: await convertToBase64(selectedFile).then(res => res),
        generalId: id,
        tipoDocumentoId: itemSelected.tipoDocumentoId,
        documentosDataDocsId: selectedItem.documentosDataDocsId,
        reenvioPoliza: state?.reenvioPoliza ?? false,
      }
      await pruebaManejoService.UploadFileAscendes(data)
        .then(res => {
          if (res.data.statusAcendes === "ok") {
            toast.success(message("¡Mensaje Acendes.!", res.data.mensajeAcendes));
            handleGetAllDoc();
          } else {
            toast.error(message("Error Acendes.!", res.data.mensajeAcendes));
            setloading(false);
          }
        }).catch(e => {
          setloading(false);
        })
        // .finally(res => {
        //   setloading(false);
        // })
    } else {
      toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No es posible cargar documentos a acendes .")}</div>);
    }
  }

  const handleSubmit = async (name, action = null, cargadoAcendes) => {
    setloading(true);
    await handleGetTokenDatadocs();

    const formData = new FormData();

    formData.append('TipoDocumentoId', itemSelected.dataDoctoId);
    formData.append('Nombre', name);
    formData.append('ParametroId', "1");
    formData.append('ParametroValor', "2301002");
    formData.append('File', selectedFile);
    formData.append('Folio', state.numIdFolder);
    formData.append('Activo', true);
    formData.append('Original', true);
    formData.append('RevisionCalidad', true);

    await axios.post(`${url}Documento`, formData, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        "Accept": "*/*",
      }
    })
      .then(res => {
        setActions({ ...actions, replazed: true, downloaded: true, deleted: true });
        setOpenModalDocs(false);
        setLoadModalConfirm(false);
        handleSaveInfoDataDocs(
          {
            nombreDocumento: name,
            documentoId: res.data,
            tipoDocumentoId: itemSelected.tipoDocumentoId,
            etapaId: itemSelected.etapaId,
            generalId: state.generalId,
            idFolder: state.numIdFolder,
            cargaAcendes: cargadoAcendes,
          }, action);
        // setloading(false);
        toast.success(message("¡Correcto.!", "Archivo guardado correctamente."));
      }).catch(e => {
        setloading(false);
        setOpenModalDocs(false);
        setLoadModalConfirm(false);
        if (e.response !== undefined) {
          if (e.response.data.errors.error !== undefined) {
            handleSaveLogs("Subir", "Insertar", url, e.response.data.errors.error);
            toast.error(message("Error.!", e.response.data.errors.error));
          }
          else {
            handleSaveLogs("Subir", "Insertar", url, e.response.data.errors);
            toast.error(message("Error.!", e.response.data.errors));
          }
        }
        else {
          handleSaveLogs("Subir", "Insertar", url, e.message);
          toast.error(message("Error.!", e.message));
        }
      })

  };
  const handleSubmitDelete = async () => {
    setloadModalConfirmDelete(false);
    setloading(true);
    await dataDocsServices.DeleteDocs(itemSelected.documentosDataDocsId, id)
      .then(res => {
        toast.success(message("¡Correcto.!", "Archivo eliminado correctamente."));
        setExistFile(false);
        setFileName("");
        setItemSelected();
        setSelectedDocs([]);
        handleGetAllDoc();
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined) {
          handleSaveLogs("Borrar", "Eliminar", url, e.response.data.message);
          toast.error(message("Error.!", e.response.data.message));
        }
        else {
          handleSaveLogs("Borrar", "Eliminar", url, e.message);
          toast.error(message("Error.!", e.message));
        }
      })
  }
  const handleSaveInfoDataDocs = async (data, action = null) => {
    setloading(true);
    await dataDocsServices.addInfoDataDocs(data)
      .then(res => {
        if (openModalPM) {
          handleConluirPruebaMAnejo(action.estadoId);
        } else if (openModalEmisionPoliza) {
          handleSavePoliza(action);
          // handleSaveInstalacionDatamovil(action);          
        } else if (esFirmaContrato) {
          // handleSaveFirmaContrato(action)
          handleSaveInstalacionDatamovil(action);
        } else if (openModalGestoriaAutorizada) {
          handleSaveGestoriaAutorizada(action);
        } else if (openModalGestoriaRechazada) {
          handleSaveGestoriaRechazada(action)
        } else if (openModalEndoso)
          handleSaveEndoso()
        else if (openModalEntrega) {
          handleSaveEntregaRealizada(action);
        } else if (openModalEntregaRechazada) {
          handleSaveEntregaRechazada(action);
        }
        else if (openModalExpediente) {
          handleSaveExpediente(action);
        }
        else {
          handleGetAllDoc()
          // setloading(false);
        }
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveEntregaRealizada = async (data) => {
    await EntregaService.AddEntregaRealizada(data)
      .then(res => {
        if (res.data.data.ban === 1) {
          setTimeout(() => {
            toast.success(message("Correcto.!", res.data.data.mensaje));
            setOpenModalEntrega(false);
            setloading(false);
            navigate('/inventario/entrega');
          }, 3000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }

      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveEntregaRechazada = async (data) => {
    await EntregaService.AddEntregaRechazada(data)
      .then(res => {
        if (res.data.data.ban === 1) {
          setTimeout(() => {
            toast.success(message("Correcto.!", res.data.data.mensaje));
            setOpenModalEntregaRechazada(false);
            setloading(false);
            navigate('/inventario/entrega');
          }, 2000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }

      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveExpediente = async (data) => {
    await EntregaService.ConcluirExpediente(data)
      .then(res => {
        if (res.data.data.ban === 1) {
          setTimeout(() => {
            toast.success(message("Correcto.!", res.data.data.mensaje));
            setOpenModalExpediente(false);
            setloading(false);
            navigate('/inventario/expediente');
          }, 2000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }

      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.data.data.data.mensaje));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveEndoso = async () => {
    await EndosoService.UpdateEndoso(id)
      .then(res => {
        if (res.data.data.ban === 1) {
          setTimeout(() => {
            toast.success(message("Correcto.!", res.data.data.mensaje));
            setOpenModalEndoso(false);
            setloading(false);
            navigate('/inventario/endoso/endosovalido');
          }, 2000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSavePoliza = async (action) => {
    await pruebaManejoService.saveEmisionPoliza({
      numeroPoliza: action.poliza,
      generalId: id,
      seguroFinalId: state.seguroFinalId,
      sol_id: action.sol_id
    })
      .then(res => {
        navigate('/seguro/poliza');
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveInstalacionDatamovil = async (action) => {
    await pruebaManejoService.SaveInstalacionDatamovil(action)
      .then(res => {
        navigate('/inventario/instalaciones/vehículoinventario');
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }


  const handleSaveGestoriaAutorizada = async (item) => {
    await GestoriaServices.SaveGestoriaAutorizada(item)
      .then(res => {
        if (res.data.data.ban === 1) {
          setTimeout(() => {
            toast.success(message("Correcto.!", res.data.data.mensaje));
            setOpenModalGestoriaAutorizada(false);
            setloading(false);
            navigate('/inventario/gestoria');
          }, 3000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }
        // handleGetAllDoc();

      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveGestoriaRechazada = async (item) => {
    await GestoriaServices.SaveGestoriaRechazada(item)
      .then(res => {
        if (res.data.data.ban === 1) {
          toast.success(message("Correcto.!", res.data.data.mensaje));
          setOpenModalGestoriaRechazada(false);
          setloading(false);
          setTimeout(() => {
            navigate('/inventario/gestoria');
          }, 2000);
        } else {
          toast.success(message("Error.!", res.data.data.mensaje));
        }

      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleSaveFirmaContrato = async (item) => {
    await pruebaManejoService.saveSolicitudFirma({
      generalId: id,
      numeroSerie: item?.numeroSerie,
      contrato: item?.contrato,
      tecnicoId: item?.tecnicoId,
      folioServicio: item?.folioServicio,
      vin: state?.vin,
      sol_id: state?.solicitud,
      status_datamovil: state?.statusDatamovil
    })
      .then(res => {
        toast.success(message("Correcto.!", res.response.data.message));
        handleGetAllDoc();
        // setloading(false);
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }
  const handleConluirPruebaMAnejo = async (action) => {
    await pruebaManejoService.concluirPruebaManejo({
      accion: action,
      generalId: id,
      pruebadeManejoId: state.pruebadeManejoId,
      productoId: state?.codProducto
    })
      .then(res => {
        navigate('/inventario/pruebamanejo');
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("¡Correcto.!", e.response.data.message));
        else
          toast.error(message("¡Correcto.!", e.message));
      })
  }
  const handleSaveLogs = async (modulo, categoria, descripcion, error) => {
    setloading(true);
    let data = {
      modulo: modulo,
      categoria: categoria,
      descripcion: descripcion,
      contenidoError: error
    }
    await dataDocsServices.LogsDatadocs(data)
      .then(res => {
        setloading(false);
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("¡Correcto.!", e.response.data.message));
        else
          toast.error(message("¡Correcto.!", e.message));
      })
  }

  const handlePermisosById = async (item) => {
    await dataDocsServices.getPermisoById(item.tipoDocumentoId, RolId)
      .then(res => {
        setPermisoDocumento(res.data.data);
      }).catch(e => {
      })
  }
  return {
    dataDocuments,
    handleGetFileDataDocs,
    itemSelected,
    fileName,
    setopenModalChangeName,
    handledownloadFile,
    setloadModalConfirmDelete,
    setLoadModalConfirm,
    existFile,
    selectedDocs,
    setSelectedFile,
    fileRef,
    navigate,
    actions,
    handleChangeFile,
    changeHandler,
    refbtn,
    handleSubmit,
    loadModalConfirmDelete,
    handleSubmitDelete,
    openModalChangeName,
    setFileName,
    loading,
    setOpenModalDocs,
    openModalDocs,
    selectedFile,
    loadModalConfirm,
    setItemSelected,
    isPruebaManejo,
    state,
    setEstadoId,
    estadoId,
    handleSubmitFile,
    isEmisionPoliza,
    setEsFirmaContrato,
    esFirmaContrato,
    esGestoriaAutorizado,
    setOpenModalGestoriaRechazada,
    openModalGestoriaRechazada,
    setOpenModalGestoriaAutorizada,
    openModalGestoriaAutorizada,
    setesEndoso,
    esEndoso,
    openModalEndoso,
    setOpenModalEndoso,
    esEntregaRealizada,
    openModalEntrega,
    setOpenModalEntrega,
    setOpenModalEntregaRechazada,
    openModalEntregaRechazada,
    esinstalacionDatamovil,
    setEsinstalacionDatamovil,
    esExpediente,
    openModalExpediente,
    setOpenModalExpediente,
    handleUploadFileToAcendes,
    setselectedItem,
    setOpenModalEmisionPoliza,
    openModalEmisionPoliza,
    setOpenModalPM,
    openModalPM,
    handlePermisosById,
    permisoDocumento
  }
}

export default useDataDocs