import React from "react";
import './TextoCuadro.css'

const TextoCuadros = ({
    titulo, //Label del componente
    texto,  // texto que transformar al formato entre cuadros
    active //Color Primario o Secundario.
}) => {

    return (
        <>
        <label className='text' >{titulo}</label>
        <div className="texto-cuadro">
            {texto.split('').map((letra, index) =>(
                <div key={index} className={`cuadro-clave ${active ? "cuadro-active" : "cuadro-inactive" }`}>
                    {letra}
                    </div>
            ))}
        </div>
            </>
    )
}

export default TextoCuadros

