import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { getDateNow, message,FormatNumberMoney } from '../../../Utils/functions';
import SubMenuPrealta from '../../Ventas/Prealta/subMenuPrealta';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroCotizado from '../Cotizado/SeguroCotizado';
import SeguroCotizadoService from '../../../Services/Seguros/SeguroCotizadoService';
import VisorFile from '../../../Components/VisorFile';
import axios from 'axios';
import dataDocsServices from '../../../Services/DataDocs/dataDocsServices';
import AuthLogin from '../../../Utils/AuthLogin';
import { addMonths, addYears, getDate, getMonth } from 'date-fns';
import { date } from 'yup/lib/locale';
import DataTable from '../../../Components/datatable/DataTable';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import ModalCostosSeguro from './ModalCostosSeguro';


const auth = new AuthLogin();
const RenovacionSeguroAddEdit = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const refbtn = useRef();
    let { id } = useParams();
    const { value } = useSelector((state) => state.navigaSlicePrealta);
    const dispatch = useDispatch();
    const refFormik = useRef();
    let url = auth.UrlDataDocs();
    
    const columns = [
        { field: 'agenteSeguro', headerName: 'Agente de seguros', width: 250 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 250 },
        { field: 'usoUnidad', headerName: 'Uso de unidad', width: 130 },
        { field: 'cobertura', headerName: 'Cobertura', width: 130 },
        { field: 'frecuencia', headerName: 'Frecuencia de pago', width: 160 },
        { field: 'fechaInicio', headerName: 'Fecha inicio', width: 130 },
        { field: 'poliza', headerName: 'Póliza', width: 130 },
        { field: 'costoStr', headerName: 'costos', width: 130 }
    ];
    let initial = {
        generalId: id,
        aseguradoraId: "",
        usoUnidadId: "412efdbf-52f0-41ce-b757-24fbe4e4dd73",
        numeroPoliza: "",
        numeroReferencia: "",
        agenteSeguroId: "",
        coberturaId: "",
        costo: "",
        costos: {
            primaNeta: "",
            financiamiento: "",
            gastosExpedicion: "",
            subTotal: "",
            iva: "",
            total: ""
        },
        frecuenciaPagoId: "54c29330-1997-4cde-8ca4-c3a797b731ca",
        fechaInicio: getDateNow(new Date()),
        fechaFin: getDateNow(`${new Date().getFullYear() + 1}/${new Date().getMonth() + 1}/${new Date().getDate()}`),
        comprador: "Arrendadora datamovil",

        VIN: state?.vin,
        marca: state?.nombreMarca,
        modelo: state?.nombreModelo,
        year: state?.nombreYear,
        version: state?.nombreVersion,
        condicion: state?.condicion,
        transmision: state?.transmision ?? "",

        utilitario: state?.utilitario,
        solId: state?.sol_Id,
        contrato: state?.contrato,
        seguro: state?.seguro,
        aseguradora: "",
        diasVencidosSeguro: "",
        telAseguradora: state?.telefonoAseguradora,
        seguroAutorizado: state?.seguroAutorizado,
        statusSeguro: state?.statusSeguro,
        usoUnidad: state?.usoUnidad,
        agenteSeguro: "",
        fechaCompromiso: "",
        vin: state?.vin,
    }
    const dataAplica = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        aseguradoraId: Yup.string()
            .required("El campo es requerido."),
        usoUnidadId: Yup.string()
            .required("El campo es requerido."),
        numeroPoliza: Yup.string()
            .required("El campo es requerido."),
        numeroReferencia: Yup.string()
            .required("El campo es requerido."),
        agenteSeguroId: Yup.string()
            .required("El campo es requerido."),
        frecuenciaPagoId: Yup.string()
            .required("El campo es requerido."),
        coberturaId: Yup.string()
            .required("El campo es requerido."),
        costo: Yup.string()
            .required("El campo es requerido."),
        comprador: Yup.string()
            .required("El campo es requerido."),
        fechaFin: Yup.string()
            .required("El campo es requerido."),
        fechaInicio: Yup.string()
            .required("El campo es requerido."),
        fechaCompromiso: Yup.string()
            .required("El campo es requerido."),
    });

    const [dataStatus, setDataStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [progressBar, setProgressBar] = useState(0);

    const [dataAseguradora, setDataAseguradora] = useState([]);
    const [AgenteSeguros, setAgenteSeguros] = useState([]);
    const [dataUsoUnidad, setDataUsoUnidad] = useState([]);
    const [dataCobertura, setDataCobertura] = useState([]);
    const [dataFrecuenciaPago, setDataFrecuenciaPago] = useState([]);

    //File
    const [fileName, setFileName] = useState("");
    const [openVisor, setOpenVisor] = useState(false);
    const [Documentos, setDocumentos] = useState([]);
    //
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalCostos, setIsOpenModalCostos] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [currenPage, setCurrenPage] = useState(1);
    const [dataHistorial, setDataHistorial] = useState([]);
    const [isOpenModalCofirm, setIsOpenModalCofirm] = useState(false);

    useEffect(() => {
        handleGetAllAseguradora();
        handleGetAllUsoUnidad();
        handleGetAllCobertura();
        handleGetAllFrecuanciaPago();
        handleGetAllStatusDatamovil();
        handleGetAllAgenteSeguro();
        // if (state?.action === 1)
        //     handleGetById();
    }, [])

    const handleGetById = async () => {
        setLoading(true);
        await SeguroCotizadoService.getByIdRenovacionSeguro(id)
            .then(res => {
                let item = res.data.data;

                let initial = {
                    generalId: id,
                    aseguradoraId: item?.aseguradoraId ?? "",
                    usoUnidadId: item?.usoUnidadId ?? "",
                    numeroPoliza: item?.numeroPoliza ?? "",
                    numeroReferencia: item?.numeroReferencia ?? "",
                    agenteSeguroId: item?.agenteSeguroId ?? "",
                    coberturaId: item?.coberturaId ?? "",
                    costo: item?.costo ?? "",
                    frecuenciaPagoId: item?.frecuenciaPagoId ?? "",
                    comprador: item?.comprador ?? "",
                    fechaInicio: item?.fechaInicio.split("T")[0] ?? "",
                    fechaFin: item?.fechaFin.split("T")[0] ?? "",
                    costos: {
                        primaNeta: item?.primaNeta ?? "",
                        financiamiento: item?.financiamiento ?? "",
                        gastosExpedicion: item?.gastosExpedicion ?? "",
                        subTotal: item?.subTotal ?? "",
                        iva: item?.iva ?? "",
                        total: item?.total ?? ""
                    },

                    VIN: state.vin,
                    marca: state.nombreMarca,
                    modelo: state.nombreModelo,
                    year: state.nombreYear,
                    version: state.nombreVersion,
                    condicion: state.condicion,
                    transmision: state?.transmision ?? "",
                }

                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllStatusDatamovil = async () => {
        setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let item = res.data.data;
                setDataStatus(item.statusDatamovil);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllAgenteSeguro = async () => {
        setLoading(true);
        await VentaVehiculoService.getAgenteSeguros(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.agenteSeguroId,
                        label: item.agenteSeguro
                    })

                });
                setAgenteSeguros(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllAseguradora = async () => {
        setLoading(true);
        await SeguroSinCotizarService.getlAllAseguradora()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllUsoUnidad = async () => {
        setLoading(true);
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllCobertura = async () => {
        setLoading(true);
        await SeguroSinCotizarService.getlAllCobertura()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coberturaId,
                        label: item.cobertura
                    })
                });
                setDataCobertura(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllFrecuanciaPago = async () => {
        setLoading(true);
        await SeguroSinCotizarService.getlAllFrecuenciaPago()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.frecuenciaPagoId,
                        label: item.frecuencia
                    })
                });
                setDataFrecuenciaPago(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VehiculoService.getAllHistoryRenovacionSeguro(id, page, 10)
            .then(res => {
                let dataRes = res.data.data
                //setDataHistorial(res.data.data);
                dataRes?.map(item => {
                    item.fechaFin = item.fechaFin.replace(/\//g, "-");
                    item.fechaInicio = item.fechaInicio.replace(/\//g, "-");
                });
                setDataHistorial(dataRes);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handleGetFileById = async () => {
        setLoading(true);
        setFileName(state.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${state.idFolder}/${state.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setDocumentos([file])
                setLoading(false);
                setOpenVisor(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    const handleCalcularFechaFin = async (item, value) => {
        let valueSplit = value.split("-")
        var startDateFormat = new Date(valueSplit[0], valueSplit[1], valueSplit[2]);
        let { label } = dataFrecuenciaPago?.find(x => x.value == item.frecuenciaPagoId)

        switch (label) {
            case "Anual":
                var fechaF = `${parseInt(valueSplit[0]) + 1}/${valueSplit[1]}/${valueSplit[2]}`;
                setInitialValue({ ...item, fechaInicio: value, fechaFin: getDateNow(fechaF) })
                break;
            case "Semestral":
                var addDate = startDateFormat.setMonth(startDateFormat.getMonth() + 6)
                var fechaF = `${new Date(addDate).getFullYear()}/${startDateFormat.getMonth()}/${valueSplit[2]}`                
                let dat= fechaF.split('/');                
                setInitialValue({ ...item, fechaInicio: value, fechaFin: dat[1] == 0 ?getDateNow(`${valueSplit[0]}/${12}/${dat[2]}`):getDateNow(fechaF) })
                break;
            case "Cuatrimestre":
                var addDate = startDateFormat.setMonth(startDateFormat.getMonth() + 4)
                var fechaF = `${new Date(addDate).getFullYear()}/${new Date(addDate).getMonth()}/${valueSplit[2]}`
                setInitialValue({ ...item, fechaInicio: value, fechaFin: getDateNow(fechaF) })
                break;
            case "Trimestral":
                var addDate = startDateFormat.setMonth(startDateFormat.getMonth() + 3)
                var fechaF = `${new Date(addDate).getFullYear()}/${new Date(addDate).getMonth()}/${valueSplit[2]}`
                setInitialValue({ ...item, fechaInicio: value, fechaFin: getDateNow(fechaF) })
                break;
            case "Bimestral":
                var addDate = startDateFormat.setMonth(startDateFormat.getMonth() + 2)
                var fechaF = `${new Date(addDate).getFullYear()}/${new Date(addDate).getMonth()}/${valueSplit[2]}`
                setInitialValue({ ...item, fechaInicio: value, fechaFin: getDateNow(fechaF) })
                break;
            case "Mensual":
                var addDate = startDateFormat.setMonth(startDateFormat.getMonth() + 1);
                var fechaF = `${new Date(addDate).getFullYear()}/${new Date(addDate).getMonth()}/${valueSplit[2]}`;
                setInitialValue({ ...item, fechaInicio: value, fechaFin: getDateNow(fechaF) })
                break;
            default:
                break;
        }
    }
    const handleAddEdit = async (value) => {        
        refbtn.current.disabled = true;
        setLoading(true);
        // if (state?.action === 1) {
        await SeguroCotizadoService.UpdRenovacionSeguro(value)
            .then(res => {
                setLoading(false);
                navigate('/seguro/renovacionseguro/contrato');
                toast.success(message("¡Correcto.!", res.data.data.mensaje));

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
        // } 
        // else if (state?.action === 0) {
        //     await SeguroSinCotizarService.AddSeguroInicial(value)
        //         .then(res => {
        //             setLoading(false);
        //             navigate('/seguro/sincotizar');
        //             toast.success(message("¡Correcto.!", res.data.data.mensaje));
        //         }).catch(e => {
        //             setLoading(false);
        //             if (e.response !== undefined)
        //                 toast.error(message("¡Error.!", e.response.data.message));
        //             else
        //                 toast.error(message("¡Error.!", e.message));
        //         })
        // }
    }

    const handleChageProgress = (erros) => {

        let xx = Object.keys(erros).length;
        let totalCampos = 4;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const status = () => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {                

                let fechaFin = new Date(values.fechaFin);
                let fechaInicio = new Date(values.fechaInicio);
                let diff  = fechaInicio - fechaFin;
                let diferenciaDias = Math.round(diff / (1000 * 60 * 60 * 24));

                let { primaNeta, financiamiento, gastosExpedicion, iva, subTotal, total } = values.costos;
                handleAddEdit({ ...values,diasVencidosSeguro:Math.abs(diferenciaDias), primaNeta: parseFloat(primaNeta), financiamiento: parseFloat(financiamiento), iva: parseFloat(iva), gastosExpedicion: parseFloat(gastosExpedicion), subTotal: parseFloat(subTotal), total: parseFloat(total) });
            }}
        >
            {({ isValid, dirty, values, setFieldValue, submitForm }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            state?.utilitario ? navigate('/seguro/renovacionseguro/utilitario')
                                : navigate('/seguro/renovacionseguro/contrato')
                        }}></i>
                        Regresar a Listado general de Vehículo
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        {/* <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        /> */}
                        <section className='col-12'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehiculo</strong></span>
                                            <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset>
                                        </div>
                                        <div className='d-flex'>
                                            <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => { handleGetFileById() }}><i className="icon ri-file-3-fill mx-1" ></i>Ver documento</fieldset>
                                            {/* <button className='btn mx-2' type="submit" >{state.action === 1 ? "Editar" : "Guardar"}</button> */}
                                            <fieldset className='btn mx-2 px-5' disabled={!(isValid)} onClick={() => { setIsOpenModalCofirm(true) }}>Guardar</fieldset>
                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2 mt-5'>
                                        <div className='d-flex'>
                                            <p><strong>Datos del seguro</strong></p>
                                            <div className="col-sm-1 mx-2">
                                                <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Aseguradora" required={true} name="aseguradoraId" items={dataAseguradora} onChange={(event) => {
                                                    setFieldValue("aseguradoraId", event.value);
                                                    setFieldValue("aseguradora", event.label);
                                                    // handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Agente de seguros" required={true} name="agenteSeguroId" items={AgenteSeguros} onChange={(event) => {
                                                    setFieldValue("agenteSeguroId", event.value);
                                                    setFieldValue("agenteSeguro", event.label);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Comprador" required={true} name="comprador" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("comprador", event.target.value);
                                                    // handleChageProgress(errors);
                                                }} />
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled label="Uso de unidad" required={true} name="usoUnidadId" items={dataUsoUnidad} onChange={(event) => {
                                                    setFieldValue("usoUnidadId", event.value);
                                                    setFieldValue("usoUnidad", event.label);
                                                    // handleChageProgress(errors);
                                                }} />

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <div className='d-flex'>
                                                <TextField icon={"bi bi-currency-dollar"} disabled label="Precio" required={true} name="costo" type="text" isMoney={true} holder="Escribe" onChange={(event) => {
                                                        setFieldValue("costo", FormatNumberMoney(event.target.value));
                                                        // handleChageProgress(errors);
                                                    }} />
                                                    <div className='d-flex flex-column'>
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <label className='text'></label>
                                                            <fieldset className='btn' onClick={() => { setIsOpenModalCostos(true) }} >Registrar</fieldset>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Número de referencia" required={true} name="numeroReferencia" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("numeroReferencia", event.target.value);
                                                    // handleChageProgress(errors);
                                                }} />

                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Cobertura" required={true} name="coberturaId" items={dataCobertura} onChange={(event) => {
                                                    setFieldValue("coberturaId", event.value);
                                                    // handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Número de poliza" required={true} name="numeroPoliza" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("numeroPoliza", event.target.value);
                                                    // handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Frecuencia de pago" required={true} name="frecuenciaPagoId" items={dataFrecuenciaPago} onChange={(event) => {
                                                    setFieldValue("frecuenciaPagoId", event.value);
                                                    handleCalcularFechaFin({ ...values, frecuenciaPagoId: event.value }, values.fechaInicio)
                                                    // handleChageProgress(errors);
                                                }} />
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Fecha de inicio" required={true} name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaInicio", event.target.value);
                                                    handleCalcularFechaFin(values, event.target.value)
                                                    // handleChageProgress(errors);
                                                }} />

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Fecha fin" required={true} name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaFin", event.target.value);
                                                    // handleChageProgress(errors);
                                                }} />

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Fecha compromiso" required={true} name="fechaCompromiso" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaCompromiso", event.target.value);                                                    
                                                }} />

                                            </div>
                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </section>
                    </div>
                    {
                        openVisor &&
                        <VisorFile
                            isOpen={openVisor}
                            setIsOIpen={setOpenVisor}
                            fileName={fileName}
                            doc={Documentos}
                        />
                    }
                    {
                        isOpenModal &&
                        <Modal isOpen={isOpenModal} color='#fff'>
                            <div className='d-flex'>
                                <span><strong>Historial de Seguros</strong></span>

                            </div>
                            <DataTable
                                column={columns}
                                data={dataHistorial}
                                pageCount={pageCount}
                                handlePageClick={handlePageClick}
                                currentPage={currenPage - 1}
                            />
                            <section className='my-3 d-flex justify-content-end mt-4'>
                                <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                            </section>
                        </Modal>
                    }
                    {
                        isOpenModalCostos &&
                        <Modal isOpen={isOpenModalCostos} color='#fff'>
                            <ModalCostosSeguro
                                setisOpenModalcostos={setIsOpenModalCostos}
                                isOpen={isOpenModalCostos}
                                setValue={setInitialValue}
                                value={values}
                                editable={false}
                            />
                        </Modal>
                    }
                    {
                        isOpenModalCofirm &&
                        <Modal isOpen={isOpenModalCofirm} color='#fff'>
                            <section className='m-4' style={{ width: '20rem' }}>
                                <div className='d-flex flex-column fs-6'>
                                    <span className='fs-5 mb-2'><strong>Guardar cambios</strong></span>
                                    <span style={{ fontSize: '12px' }}>La información del seguro quedara como la definitiva. ¿Esta seguro desea continuar con el proceso?</span>
                                </div>
                                <section className='my-3 d-flex justify-content-betwen mt-4'>
                                    <button className='btn btn-outline mx-1 px-5' type='reset' onClick={() => { setIsOpenModalCofirm(false) }}>Cancelar</button>
                                    <button ref={refbtn} className='btn mx-1 px-5' onClick={submitForm}>Aceptar</button>
                                </section>
                            </section>
                        </Modal>
                    }
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default RenovacionSeguroAddEdit