import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import { message } from "../../../Utils/functions";
import toast from "react-hot-toast";

const DSAsignacionServiciosHook = () => {
    const refFormik = useRef();
    const selectRef = useRef();
    let initial = {};
    const navigate = useNavigate();
    const { folioId } = useParams();

    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [tipoServicios, setTipoServicios] = useState([]);

    const [asignacion, setAsignacion] = useState({});



    useEffect(() => {
        getTipoServicios();
        getAsignacion();
    }, []);

    const getTipoServicios = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Servicios')
        .then(resp => {
            let items = [];
            resp.data.forEach(item =>  items.push({ value: item.TipoServicioId, label: item.TipoServicio }));
            setTipoServicios(items);
        })
        .catch(err => {

        })
    };

    const getAsignacion = async () => {
        setLoading(true);
        await DSAsignaciones.getAsignacion(folioId)
        .then(resp => {
            setAsignacion(resp.data);
        })
        .catch(err => {
            toast.error(message('Error al intentar obtener los datos de la asignación'));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleCheckAsignados = (evecheck, item, int) => {

    }


    return {
        refFormik,
        navigate,
        folioId,
        initialValue,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        buscar,
        setBuscar,
        tipoServicios,
        selectRef,
        handleCheckAsignados,
        asignacion,
    };
};

export default DSAsignacionServiciosHook;