import axios from '../../../Utils/ApiService';

const ValuacionRegistroCostoService = {
    /*
    getAllRegistroCostos: (page,rows,id) => {
        return axios.get(`/functions/GetAllCostosAdicionales?page=${page}&Rows=${rows}&valuacionId=${id}`);
    },
    addCostosAdicionales: (data) => {
        return axios.post(`/functions/AddCostosAdicionales`, data);
    },   
    UpdCostosAdicionales: (data) => {
        return axios.put(`/functions/UpdCostosAdicionales`, data);
    },   
    DeleteCostosAdicionales: (id) => {
        return axios.delete(`/functions/DeleteCostosAdicionales?id=${id}`);
    },
    //Card
    SaveRegistroCostosCard : (data) =>{
        return axios.put(`/functions/SaveRegistroCostos`, data);
    },
    */
    getdataTipoUbicacion: (catubicacion = null) => {
        return axios.get(`/catalogs/GetCSUbicacion?CategoriaTipoUbicacion=${catubicacion}`);
    },
    getEmpresa: () => {
        return axios.get(`/catalogs/GetCSEmpresa`);
    },
    getOrigenGrua: async () => {
        return await axios.get(`/catalogs/GetOrigenGrua`);
    },
    GetRecoleccionVehiculo: (valuacionId) => {
        return axios.get(`/functions/GetRecoleccionVehiculo?valuacionId=${valuacionId}`);
    },
    UpdCostosAdicionales: (data) => {
        return axios.post(`/functions/UpdateRecoleccion`, data);
    },
    GetUbicacionVehiculoCostos: (page, rows, valuacionInicioId) => {
        return axios.get(`/functions/GetUbicacionVehiculoCostos?ValuacionInicioId=${valuacionInicioId}`);
    },
    UpdateUbicacionVehiculoCostos: (data) => {
        return axios.get(`/functions/GetUbicacionVehiculoCostos`, data);
    },
    addCostosAdicionales: (item) => {
        return axios.post(`/functions/AddUbicacionVehiculoCostos`,item);
    },
    updateUbicacion: (data) => {
        return axios.post(`/functions/UpdateUbicacion`, data);
    },
    getUbicacionVehiculo: (valuacionId) => {
        return axios.get(`/functions/GetUbicacionVehiculo?valuacionId=${valuacionId}`);
    },
    getAllStepe: (valuacionId) => {
        return axios.get(`/functions/GetAllStep?valuacionId=${valuacionId}`);
    },
    updStep: (data) => {
        return axios.put(`/functions/UpdStep`, data);
    },
    DeleteUbicacionVehiculoCostos: (id) => {
        return axios.delete(`/functions/DeleteUbicacionVehiculoCostos?id=${id}`);
    }
}

export default ValuacionRegistroCostoService