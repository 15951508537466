import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceSyncInv = createSlice({
  name: 'catNavigateSyncInv',
  initialState,
  reducers: {
    tabActiveSyncInv: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveSyncInv } = navigaSliceSyncInv.actions

export default navigaSliceSyncInv.reducer