import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveSyncInv } from '../../Redux/Slice/navigateSliceSyncInv';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section } from '../../Auth/Authorization';

const SubmenuSync = () => {
  const { value } = useSelector((state) => state.tabNavigateSyncInv);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [defaultRegla, setDefaultRegla] = useState(1);

  useEffect(() => {
    handleVigate(value);
  }, [value])

  let Reglas = [
    { value: 2, label: "Alta Intelimotor", active:ValidatePermissionById(3, Section.AltaIntelimotor)},
    { value: 3, label: "Eliminar Intelimotor",active:ValidatePermissionById(3, Section.EliminarIntelimotor)},
  ];

  const handleSetStateTab = (value) => {
    dispatch(tabActiveSyncInv(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.DataWare) && value === 1) {
      navigate(`/admin/inventario/dataware`);
    }
    if (ValidatePermissionById(3, Section.AltaIntelimotor) && value === 2) {
      navigate(`/admin/inventario/intelimotor`);
    }
    if (ValidatePermissionById(3, Section.EliminarIntelimotor) && value === 3) {
      navigate(`/admin/inventario/eliminarintelimotor`);
    }
  }

  return (
    <>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.DataWare)}>
          <li className="nav-item" role="presentation">
            <button className={`text-dark nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" aria-controls="intelimotor" onClick={(e) => { handleSetStateTab(e.target.value) }}>DataWare</button>
          </li>
        </ValidatePermission>

        {/* <li className="nav-item" role="presentation">
          <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" aria-controls="intelimotor" onClick={(e) => { handleSetStateTab(e.target.value) }}>Intelimotor</button>
        </li> */}
        <li className="nav-item" role="presentation">
          <select className={`text-dark select-custome ${value == 2 || value == 3 ? "nav-link tab-active" : ''}`} style={{ height: '42px' }} value={value} onChange={(e) => { handleSetStateTab(e.target.value); setDefaultRegla(e.target.value) }}>
            <option value="1">Seleccione</option>
            {
              Reglas?.map((item, index) => {
                if(item.active)
                 return <option key={index} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </li>
      </ul>
    </>
  )
}

export default SubmenuSync