import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Cierres from '../../../Services/Resguardos/Cierres';
import DataTable from '../../../Components/datatable/DataTable';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AdeudosModal from '../../../Components/Modal/AdeudosModal';
import NotificarClienteCard from './NotificarClienteCard';

const AdeudosCard
    = React.forwardRef(({ ...props }, ref) => {
        const { refFormik } = useRef();
        const location = useLocation();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();
        
        let initial = {
            cierreContratoAdeudoId: "",
            cierreContratoId: cierreid,
            cierreContratoAdeudoStatusId: 0,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            fechaInicio: "",
            fechaConclusion: "",
            concluido: false,
            status: false,
            cierreContratoAdeudoStatus: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            detalle: []
        }
        
        const [total, setTotal] = useState(0);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [data, setData] = useState({
            totalRows: 0,
            pageSize: 0,
            currentPage: 1,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            data: []
        });
        const [currenPage, setCurrenPage] = useState(1);
        const [pagina, setPagina] = useState(1);
        
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const columns = [
            { field: 'idx', headerName: 'No.'},
            { field: 'adeudo', headerName: 'Adeudo'},
            { field: 'monto', headerName: 'Monto de adeudo'},
            { field: 'fecha', headerName: 'Fecha de registro del adeudo'}
        ];

        useEffect(() => {
            getAdeudos();
        }, []);

        /* CONSULTAS */
        const getAdeudos = async () => {
            await Cierres.cierreContratoAdeudoGet(cierreid)
            .then(resp => {
                let detalles = [];
                let totalAdeudos = 0;

                resp.data.detalle.sort((a,b) => {
                    return new Date(a.fechaAlta) - new Date(b.fechaAlta);
                });

                resp.data.detalle.forEach((item, idx) => {
                    totalAdeudos += item.monto;
                    let monto = formatMoney(item.monto);
                    detalles.push({
                        idx: (idx + 1),
                        cierreContratoAdeudoDetalleId: item.cierreContratoAdeudoDetalleId,
                        adeudo: item.cierreContratoAdeudoTipo,
                        monto: monto,
                        fecha: item.fechaAlta.split("T")[0]
                    });
                });

                setTotal(totalAdeudos);
                setData(detalles);
                setInitialValue(resp.data);
            })
            .catch(err => {

            });
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async () => {
            let params = {
                cierreContratoAdeudoId: initialValue.cierreContratoAdeudoId,
                cierreContratoId: initialValue.cierreContratoId,
                cierreContratoAdeudoStatusId: 30,
                concluido: true
            }

            setLoading(true);
            await Cierres.cierreContratoAdeudoActualizar(params)
            .then(resp => {
                let detalles = [];
                let totalAdeudos = 0;

                resp.data.detalle.sort((a,b) => {
                    return new Date(a.fechaAlta) - new Date(b.fechaAlta);
                });

                resp.data.detalle.forEach((item, idx) => {
                    totalAdeudos += item.monto;
                    let monto = formatMoney(item.monto);
                    detalles.push({
                        idx: (idx + 1),
                        cierreContratoAdeudoDetalleId: item.cierreContratoAdeudoDetalleId,
                        adeudo: item.cierreContratoAdeudoTipo,
                        monto: monto,
                        fecha: item.fechaAlta.split("T")[0]
                    });
                });



                setTotal(totalAdeudos);
                setData(detalles);
                setInitialValue(resp.data);
                cierreContratoNotificacionCrear();
                toast.success(message("Se actualizó el Adeudo correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar el Adeudo!"));
            })
            .finally(() => setLoading(false));

        }

        const cierreContratoNotificacionCrear = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoNotificacionStatusId: 10
            }

            await Cierres.cierreContratoNotificacionCrear(params)
            .then(resp => {
                toast.success(message("Se inició el proceso de notificación correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el proceso de notificación!"));
            })
        }

        const handleEdit = (item) => {
            const dataUrl = {
                url: location.pathname
            }
            dispatch(setUrlSlice(dataUrl));
            navigate(`/procesocierre/resguardos/resguardo/${item.vin}/${item.generalId}`);
        }        
        
        const handlePageClick = (event) => {
            let page = event.selected + 1;
            setInitialValue({
                ...initialValue,
                page
            });
            setCurrenPage(page);
            setPagina(page);
            // SeguimientoResguardos(initialValue, page);
        };

        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }


        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <AdeudosModal
                            cierreContratoAdeudoId={initialValue.cierreContratoAdeudoId}
                            modalMessage={modalMessage}
                            getAdeudos={getAdeudos}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAdeudos" role="button" aria-expanded="false" aria-controls="collapseAdeudos" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.cierreContratoAdeudoStatusId === null ? 'semaforo semaforo-default' : ( initialValue.cierreContratoAdeudoStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Adeudos</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.cierreContratoAdeudoStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseAdeudos">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Listado de adeudos</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn px-4'
                                                                        disabled={false}
                                                                        onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Registro de adeudo' })}>
                                                                        Agregar adeudo
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn px-4'
                                                                        disabled={!isValid || initialValue.cierreContratoAdeudoStatusId === 30}
                                                                        onClick={() => submitForm()}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <DataTable
                                                                column={columns}
                                                                data={data}
                                                                // editable={true}
                                                                // handleEdit={handleEdit}
                                                                // pageCount={data?.totalPages}
                                                                // currentPage={currenPage - 1}
                                                                // handlePageClick={handlePageClick}
                                                                // pageCount={0}
                                                                // currentPage={1 - 1}
                                                            />
                                                        </div>
                                                        <div className="col-12 alert alert-secondary" role="alert">
                                                            <div className='row'>
                                                                <div className="col-md-6 col-lg-auto">
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div className="col-md-6 col-lg-3">
                                                                    <strong>{ formatMoney(total) }</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.cierreContratoAdeudoStatusId === 30 &&
                    <NotificarClienteCard />
                }
            </>
        )
    })
export default AdeudosCard