import { useEffect, useRef, useState } from "react";
import Solicitudes from "../../Services/ProcesosCierre/Solicitudes";
import Catalogo from "../../Services/Catalogos/Catalogo";

const MontoDevolverHook = () => {
    const refFormik = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [procesos, setProcesos] = useState([]);

    const columns = [
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'proceso', headerName: 'Proceso', widht: '150px' },
        { field: 'subproceso', headerName: 'Subproceso', widht: '150px' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', widht: '150px' },
        { field: 'montoADevolver', headerName: 'Monto a devolver', widht: '150px' },
        { field: 'fechaAlta', headerName: 'Fecha y hora de solicitud', widht: '150px' },
        { field: 'comentariosTooltip2', headerName: 'Comentarios', widht: '150px' },
    ];

    useEffect(() => {
        getProcesos();
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar]);

    const getListado = async () => {
        setLoading(true);

        const params = {
            buscar,
            procesoId: '',
            fechaInicio: '',
            fechaFin: '',
            concluido: false,
            page,
            rows
        }
        await Solicitudes.getDevolucionesSeguimiento(params)
            .then(resp => {
                let items = resp.data.data.map(item => {
                    item.comentariosTooltip2 = item.comentarios;
                    return item;
                });
                setData(items);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getProcesos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ValuacionProceso')
            .then(resp => {
                let list = [];
                resp.data.forEach(item => {
                    list.push({
                        value: item.procesoId,
                        label: item.proceso
                    });
                });
                setProcesos(list);
            })
            .catch(err => {

            });
    }

    const handleDetail = (item) => {
        console.log('Detail:', item);
        setModalMessage({ isOpen: true, title: 'Monto a devolver', type: 1, id: item.cierreContratoDevolucionesId });
    }

    return {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail,
        procesos,
        getListado
    }
}

export default MontoDevolverHook;