import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import ConfigDataDocsService from '../../Services/ConfigDataDocs/ConfigDataDocsService';

const useConfigDataDocs = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'dataDocsId', headerName: 'ID Datadocs', width: 150 },
        { field: 'binario', headerName: 'Documento', width: 150 },
        { field: 'prefijo', headerName: 'Prefijo', width: 100 },
        { field: 'tipoCargaStr', headerName: 'Tipo caga', width: 150 },
        { field: 'requeridoStr', headerName: 'Requerido', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 150 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [loading, setLoading] = useState(false);
    const [ClaveEtapa, setClaveEtapa] = useState("")
    const [openModal, setOpenModal] = useState(false);
    //Filtro
    const [DataFichero, setDataFichero] = useState([])
    const [DataEtapa, setDataEtapa] = useState([])
    const [ClaveFichero, setClaveFichero] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [SelectedFichero, setSelectedFichero] = useState();
    const [SelectedEtapa, setSelectedEtapa] = useState();
    const [reload, setReload] = useState(false);
    const [Str, setStr] = useState("");


    useEffect(() => {
        if (ClaveFichero !== "" && ClaveEtapa !== "") {
            habdleGetAll(currenPage);
            setCurrenPage(1);
        } else
            setData([])
    }, [pageRows, ClaveFichero, ClaveEtapa,Str])
    useEffect(() => {
        handleGetAllFichero();
    }, [])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await ConfigDataDocsService.getAllConfigBinarios(page, pageRows, ClaveFichero, ClaveEtapa,Str)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllFichero = async () => {
        setLoading(true)
        await Catalogo.getDropdownsByTable('Binarios.ConfigFichero')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Seleccione"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.FicheroId,
                        label: item.Nombre,
                        clave: item.FicheroId,
                        dataDocsID: item.DataDocsID,
                        dataDocsDesc: item.DataDocsDesc,
                        prefijo: item.Prefijo
                    })
                });
                setDataFichero(NewData);
            }).catch(e => {
                setLoading(false)
            }).finally(() => { setLoading(false) })
    }
    const handleGetAllEtapaPorFichero = async (value) => {
        setSelectedEtapa({ value: "", label: "Seleccione" })
        setClaveEtapa("")
        setClaveFichero(value)
        if (value == "")
            return true;
        await Catalogo.getAllEtapaPorFichero(value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Seleccione"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaId,
                        label: item.etapa
                    })
                });
                setDataEtapa(NewData);
            }).catch(e => {

            })
    }


    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {
        setItem({ ...value, action: "Edit", ficheroId: ClaveFichero, etapaId: ClaveEtapa })
        setOpenModal(true)
    }

    const handleDelete = async (event,item) => {        
        setLoading(true);
        await ConfigDataDocsService.DeleteConfigBinario(item.binarioId,event)
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("!Correcto¡", res.data.data.mensaje));
                    habdleGetAll(1);
                } else {
                    toast.error(message("!Error¡", res.data.data.mensaje));
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        DataFichero,
        setClaveFichero,
        setpageRows,
        setClaveEtapa,
        DataEtapa,
        handleGetAllEtapaPorFichero,
        setOpenModal,
        openModal,
        habdleGetAll,
        ClaveFichero,
        ClaveEtapa,
        setSelectedFichero,
        SelectedFichero,
        setSelectedEtapa,
        SelectedEtapa,
        item,
        setItem,
        handleDelete,
        setReload,
        setStr
    }
}

export default useConfigDataDocs