import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import { FormatFecha, formatearMonto } from '../../Utils/functions'
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import CobranzaService from '../../Services/Cobranza/Cobranza';

const useCarteraGeneral = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'contrato_id', headerName: 'Contrato', width: 150 },
        { field: 'zona', headerName: 'Zona', width: 150 },
        { field: 'periodo', headerName: 'Periodo', width: 150 },
        { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
        { field: 'saldo_credito', headerName: 'Monto insoluto', width: 150 },
        { field: 'monto_Adeudo', headerName: 'Monto adeudo', width: 150 },
        { field: 'fecha_ultimo_pago', headerName: 'Fecha últ. pago', width: 150 },
        { field: 'fecha_ultima_clave', headerName: 'Fecha ultima clave', width: 150 },
        { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar', width: 150 },
        { field: 'visitas', headerName: 'Visitas', width: 150 },
        { field: 'fecha_ultimo_calculo', headerName: 'Últ. Actualización', width: 150 },
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [zonaSeleccionada, setZonaSeleccionada] = useState("")
    //Filtro
    const [data, setData] = useState([]);
    const [pageRows, setpageRows] = useState(10);
    const [currentData, setCurrentData] = useState(data);
    const userId = sessionStorage.getItem('userId').trim();
    const userName = sessionStorage.getItem('usuario')
    const [percentage, setPercentage] = useState(0)

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    let allContratos
    const [firstProgressVigente, setFirstProgressVigente] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0,
        totalMontoPromesas: 0,
        totalContratoPromesas: 0
    })
    const [secondProgressVigentes, setSecondProgressVigentes] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0,
        tooltip: 0
    })

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState()
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)
    const dropdownRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")
        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0)
        const fechaLimite = new Date(hoy);

        //mismo estado
        //ultimos 15 dias
        let datosFiltrados = datos.filter(item => {
            const fechaTotal = fechaLimite.setDate(hoy.getDate() - 15)
            let fechaCreacion = new Date(item.fecha_creacion)
            return (item.promesa_status_nombre === estado) && fechaCreacion >= fechaTotal
        })
        const total = datosFiltrados.length;

        fechaLimite.setDate(hoy.getDate() - dias);  // Restar días
        let datosFiltradosFecha = datos.filter(item => {
            let fechaCreacion = new Date(item.fecha_creacion)
            return fechaCreacion >= fechaLimite
        })

        // Suma total de dinero
        const activos = datosFiltradosFecha.filter(obj => obj.promesa_status_nombre === estado);

        const sumaTotalDinero = activos.length > 0 ?
            activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        const sumaTotal = datosFiltrados.length > 0 ?
            datosFiltrados.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        // Número de IDs diferentes
        const idsDiferentes = activos.length

        const contratosDiferentes = datosFiltrados.length > 0 ? new Set(datosFiltrados.map(obj => obj.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        //10 para acomodar el tooltip
        const tooltipPercent = Number(porcentajeActivos.toFixed(2)) + 10

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2),
            tooltip: tooltipPercent,
            totalMontoPromesas: sumaTotal,
            totalContratoPromesas: contratosDiferentes
        };
    }

    useEffect(() => {
        habdleGetAll();
    }, [])

    useEffect(() => {
        let montovencido = 0;
        let montocobrado = 0;
        // Filtra los datos según los filtros seleccionados
        // debugger
        const filteredData = data.filter(item => {
            const nameMatches = textoBusqueda === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));
            return nameMatches &&
                (zonaSeleccionada === "" || zonaSeleccionada === "Zonas" || item.zona === zonaSeleccionada);
        });

        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)

        filteredData.map(m => {
            montocobrado += m?.pago_periodo ?? 0;
            montovencido += m?.monto_adeudo != null ? (typeof m?.monto_adeudo === "string" ? parseFloat(m?.monto_adeudo.replace(/[$,]/g, '')) : m?.monto_adeudo) : 0;
        })

        filteredData.forEach((item) => {
            item.monto_adeudo = typeof item.monto_adeudo === "string" ? item.monto_adeudo : formatearMonto(item.monto_adeudo);
        });

        setTotalMontoCobrado(Number(montocobrado.toFixed(2)))
        setTotalMontoVencido(Number(montovencido.toFixed(2)))
    }, [data, textoBusqueda, pageRows, zonaSeleccionada])

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])

    const habdleGetAll = async () => {
        setLoading(true);
        let datosFormateados = []
        let newDataZonas = [{ value: 0, label: "Zonas" }];
        let counterZonas = 1;
        let filtrarZonas = new Set()
        await SeguimientoVisitaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                if (res.data.callSucceded) {
                    const userContracts = res.data?.responseData?.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                    // const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === 'feb42264-1529-49ad-8473-29e5e61d9402'); //Solo para pruebas  
                    // Validar y filtrar si el listado de contratos no es null o vacío
                    if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
                        userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2");
                    }

                    if (userContracts !== undefined && userContracts !== null) {
                        if (userContracts?.contratos) {
                            allContratos = userContracts.contratos.map(obj => obj.contrato_id)
                            datosFormateados = userContracts?.contratos?.map((item) => {
                                // const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                                // const fechaClave = FormatFecha(item.fecha_ultima_clave)
                                const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
                                const fechaClave = (item.fecha_consulta_clave === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
                                const fechaUltActualizacion = FormatFecha(item.fecha_ultimo_calculo)
                                const saldoCredito = formatearMonto(item.saldo_credito);
                                // const montoAdeudo = formatearMonto(item.monto_adeudo);
                                const montoFormateado = formatearMonto(item.monto_adeudo);
                                const montoAdeudo = item.monto_adeudo;

                                return {
                                    ...item,
                                    fecha_ultimo_pago: fechaPago,
                                    fecha_ultima_clave: fechaClave,
                                    fecha_ultimo_calculo: fechaUltActualizacion,
                                    monto_adeudo: montoAdeudo,
                                    monto_Adeudo: montoFormateado,
                                    saldo_credito: saldoCredito,
                                }
                            });

                            let contratosFiltrador = userContracts?.contratos?.filter(item => item.estatus === 'Activo')
                            let length = userContracts?.contratos?.length
                            let percentage = contratosFiltrador?.length > 0 ? userContracts?.contratos?.length / contratosFiltrador?.length : 0

                            setTotalContratos(length)
                            setPercentage(percentage)

                            datosFormateados.map(z => {
                                filtrarZonas.add(z?.zona)
                            })
                        }
                    }

                    // newDataZonas = [...new Map(datosFormateados.map(x => [`${x.zonaid}-${x.zona}`, { value: x.zonaid, label: x.zona }])).values()];
                    // setzonas(newDataZonas);
                }
                for (const value of filtrarZonas) {
                    newDataZonas.push({ value: counterZonas, label: value })
                    counterZonas++
                }
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
        setzonas(newDataZonas);
        setData(datosFormateados);
        handleGetPromesasdePago()
        // setLoading(false);
    }

    const handleDownloadFile = async (cu) => {
        setLoading(true);

        let datosAExportar = []
        let nombreArchivo = `CarteraGeneral`

        currentData.map(item => {
            datosAExportar.push({
                "id_usuario": userId,
                "nombrecobrador": userName,
                "contrato": item?.contrato_id === null ? "-" : item.contrato_id,
                "zona": item?.zona === null ? "-" : item?.zona,
                "periodo": item?.periodo === null ? "-" : item.periodo,
                "dias_vencido": item?.dias_vencido === null ? "-" : item.dias_vencido,
                "monto_adeudo": item?.monto_adeudo === null ? "-" : item.monto_adeudo,
                "fecha_ultimo_pago": item?.fecha_ultimo_pago === null ? "-" : item.fecha_ultimo_pago,
                "fecha_ultima_clave": item?.fecha_ultima_clave === null ? "-" : item.fecha_ultima_clave,
                "dias_sin_consultar_clave": item?.dias_sin_consultar_clave === null ? "-" : item.dias_sin_consultar_clave,
                "visitas": item?.visitas === null ? "-" : item.visitas,
                "fecha_ultimo_calculo": item?.fecha_ultimo_calculo === null ? "-" : item.fecha_ultimo_calculo,
            })
        })

        let reporte = {
            "verb": "string",
            "id": nombreArchivo,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": datosAExportar,
            "kvp": {}
        }

        setLoading(true);
        await CobranzaService.ExportReport(reporte)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })

    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleDetails = (value) => {
        // Movimiento a Detalles del Job con el invocador
        navigate(`/cobranza/seguimiento-telefonico/detalles`, {
            state: {
                ...value, // Incluye las propiedades del objeto `value`
                urlRegreso: '/cobranza/seguimiento-visita/cartera-general'  // Agrega el parámetro del invocador
            }
        });
    };


    // const handleGetPromesasdePago = async () => {
    //     // setLoading(true);
    //     await CobranzaService.getAllPromesasDePago()
    //         .then(res => {
    //             const vigente = analizarDatos(res.data, 0, "Vigente")
    //             const cumplidas = analizarDatos(res.data, 0, "Cumplidas")

    //             const suma = res.data.reduce((total, obj) => {
    //                 return {
    //                     montoVencido: total.montoVencido + obj.monto_vencido,
    //                     montoCobrado: total.montoCobrado + obj.monto_recuperado
    //                 };
    //             }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

    //             setAllPromesas(res.data)
    //             // setTotalMontoCobrado(suma.montoCobrado)
    //             // setTotalMontoVencido(suma.montoVencido)
    //             setFirstProgressVigente(vigente)
    //             setFirstProgressCumplidas(cumplidas)

    //             setLoading(false);
    //         }).catch(e => {
    //             setLoading(false);
    //         })
    // }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                // debugger
                const promesasFiltradas = res.data.filter(obj => allContratos.includes(obj.contrato_id))

                const vigente = analizarDatos(promesasFiltradas, 0, "Vigente")
                const cumplidas = analizarDatos(promesasFiltradas, 0, "Cumplida")

                const suma = promesasFiltradas.reduce((total, obj) => {
                    return {
                        montoVencido: total.montoVencido + obj.monto_vencido,
                        montoCobrado: total.montoCobrado + obj.monto_recuperado
                    };
                }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(promesasFiltradas)
                setTotalMontoCobrado(suma.montoCobrado)
                setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        setTextoBusqueda,
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        loading,
        zonas,
        setpageRows,
        setZonaSeleccionada,
        handleDownloadFile,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        active7,
        active15,
        totalContratos,
        percentage,
        isVisible,
        toggleActive7,
        toggleActive15,
        isOpen
    }
}

export default useCarteraGeneral