import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSlicePrealta = createSlice({
  name: 'prealtaNavigate',
  initialState,
  reducers: {
    tabActivePrealta: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActivePrealta } = navigaSlicePrealta.actions

export default navigaSlicePrealta.reducer