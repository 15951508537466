import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import { FormatFecha } from "../../../../Utils/functions";

const ContactoDetalle = () => {
   const navigate = useNavigate();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([])

   useEffect(()=>{
      HandleGetInformacionContactos()
   },[])

   const columns = [
      { field: 'tipo_contacto', headerName: 'Tipo de contacto', width: 210 },
      { field: 'subtipo_contacto', headerName: 'Subtipo', width: 150 },
      { field: 'dato', headerName: 'Valor', width: 180 },
      { field: 'fecha_operacion', headerName: 'Última interacción', width: 200 },
      { field: 'interacciones', headerName: 'Interacciones', width: 180 },
      { field: 'validacion', headerName: 'Exitoso', width: 100 },
      { field: 'status', headerName: 'Estatus', width: 180 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 }
   ]

   const HandleGetInformacionContactos = async () => {
      let NewData = []
      const data = {
         sol_id: datosConsulta?.sol_id,
         contrato: datosConsulta?.contrato,
         vin: datosConsulta?.vin,
         idpersona_acendes: datosConsulta?.persona.value,
         tipo_persona: datosConsulta?.persona.item.tipo,
         curp: datosConsulta?.persona?.item?.curp_ref,
      }

      setLoading(true)

      await CobranzaService.getDatosdeContactos(data)
         .then(res => {
               if(res.data.callSucceded){
                  if(res?.data?.responseData?.datos_contacto !== null && res?.data?.responseData?.datos_contacto?.length !== 0){
                     NewData = res?.data?.responseData?.datos_contacto?.map((item) => {
                        let comentariosToolTip = item?.comentario
                        let status = item?.activo ? "Activo" : "Inactivo"
                        let fechaOperacion = FormatFecha(item?.fecha_operacion)
                        return{
                           ...item,
                           fecha_operacion: fechaOperacion,
                           comentariosTooltip:comentariosToolTip,
                           status: status,
                        }
                     })
                  }
               }
         })
         .catch(e=>{
            setLoading(false)
            console.log(e)
         })

         if (NewData.length === 0) {
            setData((prevArray) => {
               const updatedArray = [...prevArray];
               updatedArray[0] = data;
               return updatedArray;
            });
         }
         for (const newItem of NewData) {
            const interaccion = await AddInteracciones(newItem.idcontacto_acendes)
            const validacion = await AddValidation(newItem.idcontacto_acendes)

            newItem.interacciones = interaccion
            newItem.validacion = validacion
         }

         setData(NewData)
         setLoading(false)
   }

   const AddInteracciones = async (idcontacto_acendes) => {
      let data = {
         field: "id_contacto_ascendes",
         value: idcontacto_acendes
      }
      const response = await CobranzaService.getRowsIteractions(data)
      return response?.data?.responseData;
   };

   const AddValidation = async (idcontacto_acendes) => {
      let data = {
         contrato: datosConsulta?.contrato,
         id_contacto_ascendes: idcontacto_acendes
      }
      const response = await CobranzaService.getValidacionTipoResultado(data)
      const validado = response?.data?.validacion? "Si" : "No"
      return validado;
   };

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                    <h2 className="fw-bold ">Consulta de contacto</h2>
                              </div>
                           </div>
                           <div className="row gap-2">
                              <div className="col col-12">
                                    <div className="row mt-4">
                                       <div className="col-sm-4 col-xl-3">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.item.tipo}</span>
                                                </div>
                                          </div>
                                       </div>
                                       <div className="col-sm-5 col-xl-3">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                                </div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                  <div className="px-4">
                     <p className="pt-2" >Detalle de Contacto</p>
                     <DataTable
                        column={columns}
                        data={data}
                     />
                  </div>
                  <div className="mt-3 mb-3 row"><span> </span></div>
               </div>
            </div>
         </div>
      </>
   );
}

export default ContactoDetalle;