import React, { useEffect } from 'react'
import { useState } from 'react'

const FileComponent = ({ list, handleClick }) => {
    const [data, setdata] = useState([]);
    useEffect(() => {
        setdata(list)
    }, [list])

    // onClick={value.reemplazar ? () => { handleGetFileDataDocs(value) } : undefined}
    return (
        <>
            {
                data?.map((item, index) => {
                    return (
                        <section className='ms-4 d-flex align-items-center justify-content-between' key={index} onClick={() => { handleClick(item) }}>
                            <div className='d-flex align-items-center justify-content-between px-2 my-2'>
                                {
                                    item.existe ?
                                        <i className={`ri-file-upload-fill`} style={{ fontSize: '2rem', color: '#47a066' }}></i>
                                        : <span style={{ backgroundColor: 'transparent', height: '2.5rem', width: '2rem', borderStyle: 'dashed', borderWidth: '.4px', borderColor: '#dee2e6' }}></span>
                                }
                                <div className='ms-2'>
                                    <p style={{ fontSize: '13px' }} className='my-0'>{item.nombreDocumento}</p>
                                    <p style={{ fontSize: '12px' }} className='my-0'>{item.documentoEnDatadocs}</p>
                                </div>
                            </div>
                            {
                                item.cargaAcendes === 1 ?
                                    <span className='me-4'><i className='icon-green ri-checkbox-circle-fill'></i></span>
                                    : item.cargaAcendes === 0 ? <span className='me-4'><i className='icon-gray ri-checkbox-blank-circle-fill'></i></span> : null                                                               

                            }
                        </section>
                    )
                })
            }
        </>
    )
}

export default FileComponent