import React, { useEffect, useRef, useState } from 'react'
import DataTable from '../../Components/datatable/DataTable'
import Select from 'react-select'
import Loadig from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import ColumsModal from '../../Hooks/ColumsModal/ColumsModal';
import useTablasSecundarias from '../../Hooks/TablasSecundarias/useTablasSecundarias';
import Catalogo from '../../Services/Catalogos/Catalogo';
import Aseguradora from '../Catalogos/Aseguradora';
import Banco from '../Catalogos/Banco';
import CambioEtapa from '../Catalogos/cambioEtapa';
import CatColonia from '../Catalogos/CatColonia';
import CatCosto from '../Catalogos/CatCosto';
import Cobertura from '../Catalogos/Cobertura';
import Condicion from '../Catalogos/Condicion';
import ConfigFichero from '../Catalogos/ConfigFichero';
import EdoMexico from '../Catalogos/EdoMexico';
import Empresa from '../Catalogos/Empresa';
import Etapa from '../Catalogos/Etapa';
import Extension from '../Catalogos/Extension';
import FrecuenciaPago from '../Catalogos/FrecuenciaPago';
import Interfaz from '../Catalogos/Interfaz';
import InvExterno from '../Catalogos/InveExterno';
import MotivoCambio from '../Catalogos/MotivoCambio';
import MotivoContrato from '../Catalogos/MotivoContrato';
import MotivoCS from '../Catalogos/MotivoCS';
import MotivoGarantia from '../Catalogos/MotivoGarantia';
import MotivoUb from '../Catalogos/MotivoUb';
import Producto from '../Catalogos/Producto';
import ReglaNegocio from '../Catalogos/ReglaNegocio';
import RepresentanteLegal from '../Catalogos/RepresentanteLegal';
import SocioComercial from '../Catalogos/SocioComercial';
import StatusDatamovil from '../Catalogos/StatusDatamovil';
import StatusGarantia from '../Catalogos/StatusGarantia';
import StatusSeguro from '../Catalogos/StatusSeguro';
import TipoAdquisicion from '../Catalogos/TipoAdquisicion';
import TipoArchivo from '../Catalogos/TipoArchivo';
import TipoComision from '../Catalogos/TipoComision';
import TipoCompra from '../Catalogos/TipoCompra';
import TipoCosto from '../Catalogos/TipoCosto';
import TipoDocumento from '../Catalogos/TipoDocumento';
import TipoDocumentoUsuario from '../Catalogos/TipoDocumentoUsuario';
import TipoExterno from '../Catalogos/TipoExterno';
import TipoFactura from '../Catalogos/TipoFactura';
import TipoPago from '../Catalogos/TipoPago';
import TipoResponsiva from '../Catalogos/TipoResponsiva';
import TipoUb from '../Catalogos/TipoUb';
import Transmision from '../Catalogos/Transmision';
import Tribunal from '../Catalogos/Tribunal';
import Ubicacion from '../Catalogos/Ubicacion';
import UsoUnidad from '../Catalogos/UsoUnidad';
import '../TablasSecundarias/app.scss'
import Marca from './ModalsTs/Marca';
import Modelo from './ModalsTs/Modelo';
import CatMunicipio from '../Catalogos/CatMunicipio';
import Version from './ModalsTs/Version';
import Vehiculo from './ModalsTs/vehiculo';
import AgenteSeguro from '../Catalogos/AgenteSeguro';
import Sucursal from '../Catalogos/Sucursal';
import Vendedor from '../Catalogos/Vendedor';
import MotivoMantener from '../Catalogos/MotivoMantener';
import MotivoLiberacion from '../Catalogos/MotivoLiberacion';
import TipoEjecutivo from '../Catalogos/TipoEjecutivo';
import toast, { Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions';
import TipoPromotor from '../Catalogos/TipoPromotor';
import ProcedenciaCliente from '../Catalogos/ProcedenciaCliente';
import Ejecutivo from '../Catalogos/Ejecutivo';
import MotivoLiberacionEnganche from '../Catalogos/MotivoLiberacionEnganche';
import OperacionPlacas from '../Catalogos/OperacionPlacas';
import Tecnico from '../Catalogos/Tecnico';
import MotivoBajaPlacas from '../Catalogos/MotivoBajaPlaca';
import MotivoBajaPruebaManejo from '../Catalogos/MotivoBajaPruebaManejo';
import ANombreDe from '../Catalogos/ANombreDe';
import MotivoCancelacionGestoria from '../Catalogos/MotivoCancelacionGestoria';
import MotivoCancelacionEntrega from '../Catalogos/MotivoCancelacionEntrega';
import TipoExpediente from '../Catalogos/TipoExpediente';
import MotivoCambioProducto from '../Catalogos/MotivoCambioProducto';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import MotivoNoRenovacionSeguro from '../Catalogos/MotivoNoRenovacionSeguro';
import RevisionDeUnidad from '../Catalogos/RevisionDeUnidad';
import Submenu from './Submenu';
import Mecanico from '../Catalogos/Mecanico';
import ConfigService from '../../Services/config/configService';
import RegimenFiscal from '../Catalogos/RegimenFiscal';
import UsoCFDI from '../Catalogos/UsoCFDI';
import TipoCalculo from '../Catalogos/TipoCalculo';
import ClasificacionVendedor from '../Catalogos/ClasificacionVendedor';
import SucursalVendedor from '../Catalogos/SucursalVendedor';


const TablasSecundarias = () => {
    const listInnerRef = useRef();
    const fileRef = useRef();

    const { columnsMarca,
        columnsModelo,
        columnsVersion,
        columnsYears,
        columnsAseguradora,
        columnsBanco,
        columnsCatCosto,
        columnsCobertura,
        columnsEtapa,
        columnsExtension,
        columsMotivoCS,
        columsMotivoUb,
        columsProducto,
        columsStatusSeguro,
        columsTipoAd,
        columsTipoCompra,
        columsTipoExterno,
        columsTipoPago,
        columsResponsiva,
        columsTipoUb,
        columsTransmision,
        columsUsoUnidad,
        columsEdoMexico,
        columsCondicion,
        columsFrecuenciaP,
        columsInterfaz,
        columsTipoFactura,
        columsStatusGarantia,
        columsMotivoGarantia,
        columsMotivoContrato,
        columsTipoComision,
        columsMotivoCambio,
        columsStatusDatamovil,
        columsTipoCosto,
        columsParametros,
        columsColonia,
        columsInvExterno,
        columsTipoDocumento,
        columsTipoDocumentoUsuario,
        columsTipoArchivo,
        columsTribunal,
        columsReglaNegocio,
        columsEmpresa,
        columsRLegal,
        columsConfigFichero,
        columsSocioComercial,
        columsCambioEtapa,
        columsUbicacion,
        columsMunicipio,
        columsVehiculo,
        columsIntelimotor,
        columsAgenteSeguro,
        columsSucursal,
        columsVendedor,
        columsMotivoMantener,
        columsMotivoLiberacionSeparado,
        columsTipoEjecutivo,
        columsTipoPromotor,
        columsProcedenciaCliente,
        columsEjecutivo,
        columsLiberacionEnganche,
        columsOperacionPlacas,
        columsTecnico,
        columsMotivoBaja,
        columsMotivoPM,
        columsANombreDe,
        columsMotivoCancelacionGestoria,
        columsMotivoCancelacionEntrega,
        columsTipoExpediente,
        columsMotivoCambioProd,
        columsMotivoNoRenovacionSeguro,
        columsRevisionUnidad,
        columsMecanicos,
        columsUsoCFDI,
        columsRegimenFiscal,
        columsTipoCalculo,
        columsClasificacionVendedor,
        columsSucursalVendedor
    } = ColumsModal();

    const [dataModal, setDataModal] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [currentPageModal, setcurrentPageModal] = useState(1);
    const [nextPage, setNextPage] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [totalRows, setTotalRows] = useState();

    //fileState
    const [selectedFile, setSelectedFile] = useState()
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const [uploaded, setUploaded] = useState();
    const [filtroTexto, setFiltroTexto] = useState("");

    const [isOpenMarca, setIsOpenMarca] = useState(false);
    const [isOpenModelo, setIsOpenModelo] = useState(false);
    const [isOpenVersion, setIsOpenVersion] = useState(false);
    const [isOpenYear, setIsOpenYear] = useState(false);
    const [isOpenAseguradora, setIsOpenAseguradora] = useState(false);
    const [isOpenBanco, setIsOpenBanco] = useState(false);
    const [isOpencatCosto, setIsOpenCatCosto] = useState(false);
    const [isOpenCobertura, setIsOpenCobertura] = useState(false);
    const [isOpenEtapa, setIsOpenEtapa] = useState(false);
    const [isOpenExtension, setIsOpenExtension] = useState(false);
    const [isOpenMotivoCS, setIsOpenMotivoCS] = useState(false);
    const [isOpenMotivoUb, setIsOpenMotivoUb] = useState(false);
    const [isOpenProducto, setIsOpenProducto] = useState(false);
    const [isOpenStatusSeguro, setIsOpenStatusSeguro] = useState(false);
    const [isOpenTipoAd, setIsOpenTipoAd] = useState(false);
    const [isOpenTipoCompra, setIsOpenTipoCompra] = useState(false);
    const [isOpenTipoExterno, setIsOpenTipoExterno] = useState(false);
    const [isOpenTipoPago, setIsOpenTipoPago] = useState(false);
    const [isOpenTipoResp, setIsOpenTipoResp] = useState(false);
    const [isOpenTipoUb, setIsOpenTipoUb] = useState(false);
    const [isOpenTransmmision, setIsOpenTransmmision] = useState(false);
    const [isOpenUsoUnidad, setIsOpenUsoUnidad] = useState(false);
    const [isOpenEdoMex, setIsOpenEdoMex] = useState(false);
    const [isOpenCondicion, setIsOpenCondicion] = useState(false);
    const [isOpenFrecuenciaP, setIsOpenFrecuenciaP] = useState(false);
    const [isOpenInterfaz, setIsOpenInterfaz] = useState(false);
    const [isOpenTipoFactura, setIsOpenTipoFactura] = useState(false);
    const [isOpenStatusGarantia, setIsOpenStatusGarantia] = useState(false);
    const [isOpenMotivoGarantia, setIsOpenMotivoGarantia] = useState(false);
    const [isOpenMotivoContrato, setIsOpenMotivoContrato] = useState(false);
    const [isOpenTipoComision, setIsOpenTipoComision] = useState(false);
    const [isOpenMotivoCambio, setIsOpenMotivoCambio] = useState(false);
    const [isOpenStatusDatamovil, setIsOpenStatusDatamovil] = useState(false);
    const [isOpenTipoCosto, setIsOpenTipoCosto] = useState(false);
    const [isOpenParametro, setIsOpenParametro] = useState(false);
    const [isOpenColonia, setIsOpenColonia] = useState(false);
    const [isOpenInvExterno, setIsOpenInvExterno] = useState(false);
    const [isOpenTipoDoc, setIsOpenTipoDoc] = useState(false);
    const [isOpenTipoDocUsuario, setIsOpenTipoDocUsuario] = useState(false);
    const [isOpenTipoArchivo, setIsOpenTipoArchivo] = useState(false);
    const [isOpenTtribunal, setIsOpenTtribunal] = useState(false);
    const [isOpenReglaN, setIsOpenReglaN] = useState(false);
    const [isOpenEmpresa, setIsOpenEmpresa] = useState(false);
    const [isOpenRLegal, setIsOpenRLegal] = useState(false);
    const [isOpenConfigFichero, setIsOpenConfigFichero] = useState(false);
    const [isOpenSocioComercial, setIsOpenSocioComercial] = useState(false);
    const [isOpencambioEtapa, setIsOpencambioEtapa] = useState(false);
    const [isOpenUbicacion, setIsOpenUbicacion] = useState(false);
    const [isOpenMunicipio, setIsOpenMunicipio] = useState(false);
    const [isOpenVehiculo, setIsOpenVehiculo] = useState(false);
    const [isOpenIntelimotor, setIsOpenIntelimotor] = useState(false);
    const [isOpenAgenteSeguro, setisOpenAgenteSeguro] = useState(false);
    const [isOpenSucursal, setIsOpenSucursal] = useState(false);
    const [isOpenVendedor, setIsOpenVendedor] = useState(false);
    const [isOpenMotivoMantener, setIsOpenMotivoMantener] = useState(false);
    const [isOpenMotivoLiberacion, setIsOpenMotivoLiberacion] = useState(false);
    const [isOpenTipoEjecutivo, setIsOpenTipoEjecutivo] = useState(false);
    const [isOpenTipoPromotor, setIsOpenTipoPromotor] = useState(false);
    const [isOpenPCliente, setIsOpenPCliente] = useState(false);
    const [isOpenEjecutivo, setIsOpenEjecutivo] = useState(false);
    const [isOpenLiberacionEnganche, setIsOpenLiberacionEnganche] = useState(false);
    const [isOpenOperacionPlacas, setIsOpenOperacionPlacas] = useState(false);
    const [isOpenOTecnico, setIsOpenOTecnico] = useState(false);
    const [isOpenMotivoBaja, setIsOpenMotivoBaja] = useState(false);
    const [isOpenMotivoPM, setIsOpenMotivoPM] = useState(false);
    const [isOpenANombreDe, setIsOpenANombreDe] = useState(false);
    const [isOpenMotivoCancelacionGestoria, setIsOpenMotivoCancelacionGestoria] = useState(false);
    const [isOpenMotivoCancelacionEntrega, setIsOpenMotivoCancelacionEntrega] = useState(false);
    const [isOpenTipoExpediente, setIsOpenTipoExpediente] = useState(false);
    const [isOpenMotivoCambioProd, setIsOpenMotivoCambioProd] = useState(false);
    const [isOpenMotivoNoRenovacionSeguro, setIsOpenMotivoNoRenovacionSeguro] = useState(false);
    const [isOpenRevisionUnidad, setIsOpenRevisionUnidad] = useState(false);
    const [isOpenMecanico, setIsOpenMecanico] = useState(false);
    const [isOpenUsoCFDI, setIsOpenUsoCFDI] = useState(false);
    const [isOpenRegimenFiscal, setIsOpenRegimenFiscal] = useState(false);
    const [isOpenTipoCalculo, setIsOpenTipoCalculo] = useState(false);
    const [isOpenClasificacionVendedor, setIsOpenClasificacionVendedor] = useState(false);
    const [isOpenSucursalVendedor, setIsOpenSucursalVendedor] = useState(false);


    const [item, setItem] = useState(null);
    const [itemTbl, setItemTbl] = useState(null);
    const [actionModal, setActionModal] = useState('');
    const [tableName, setTableName] = useState(null);
    const [columModal, setColumModal] = useState({});
    const [activeBtn, setActiveBtn] = useState(true);
    const [activeBtnEcel, setActiveBtnExcel] = useState(false);
    const [openModalExcel, setOpenModalExcel] = useState(false);

    const { columns,
        data,
        pageCount,
        dataList,
        handleChangeList,
        handleChange,
        handlePageClick,
        loading,
        setLoading,
        currentPage,
        setpageRows
    } = useTablasSecundarias();
    useEffect(() => {
        if (itemTbl !== null && isOpen)
            handleGetlist(itemTbl, 1);
    }, [filtroTexto])


    const handleEdit = async (item) => {
        console.log(item)
        setActiveBtnExcel(false);
        setItemTbl(item);

        switch (item.nombreTablaBd) {
            case "MarcaTblSec":
                setColumModal(columnsMarca);
                setActiveBtn(false);
                break;
            case "ModeloTblSec":
                setColumModal(columnsModelo);
                setActiveBtn(false);
                break;
            case "VersionTblSec":
                setColumModal(columnsVersion)
                setActiveBtn(false);
                break;
            case "YearTblSec":
                setColumModal(columnsYears);
                setActiveBtn(false);
                break;
            case "Aseguradora":
                setColumModal(columnsAseguradora);
                setActiveBtn(true);
                break;
            case "Banco":
                setColumModal(columnsBanco);
                setActiveBtn(true);
                break;
            case "CategoriaCosto":
                setColumModal(columnsCatCosto);
                setActiveBtn(true);
                break;
            case "Cobertura":
                setColumModal(columnsCobertura);
                setActiveBtn(true);
                break;
            case "Etapa":
                setColumModal(columnsEtapa);
                setActiveBtn(true);
                break;
            case "Extension":
                setColumModal(columnsExtension);
                setActiveBtn(true);
                break;
            case "MotivoCancelacionSeguro":
                setColumModal(columsMotivoCS);
                setActiveBtn(true);
                break;
            case "MotivoUbicacion":
                setColumModal(columsMotivoUb);
                setActiveBtn(true);
                break;
            case "Producto":
                setColumModal(columsProducto);
                setActiveBtn(true);
                break;
            case "StatusSeguro":
                setColumModal(columsStatusSeguro);
                setActiveBtn(true);
                break;
            case "TipoAdquisicion":
                setColumModal(columsTipoAd);
                setActiveBtn(true);
                break;
            case "TipoCompra":
                setColumModal(columsTipoCompra);
                setActiveBtn(true);
                break;
            case "TipoExterno":
                setColumModal(columsTipoExterno);
                setActiveBtn(true);
                break;
            case "TipoPago":
                setColumModal(columsTipoPago);
                setActiveBtn(true);
                break;
            case "TipoResponsiva":
                setColumModal(columsResponsiva);
                setActiveBtn(true);
                break;
            case "TipoUbicacion":
                setColumModal(columsTipoUb);
                setActiveBtn(true);
                break;
            case "Transmision":
                setColumModal(columsTransmision);
                setActiveBtn(true);
                break;
            case "UsoUnidad":
                setColumModal(columsUsoUnidad);
                setActiveBtn(true);
                break;
            case "EdoMexico":
                setColumModal(columsEdoMexico);
                setActiveBtn(true);
                break;
            case "Condicion":
                setColumModal(columsCondicion);
                setActiveBtn(true);
                break;
            case "FrecuenciaPago":
                setColumModal(columsFrecuenciaP);
                setActiveBtn(true);
                break;
            case "Interfaz":
                setColumModal(columsInterfaz);
                setActiveBtn(true);
                break;
            case "TipoFactura":
                setColumModal(columsTipoFactura);
                setActiveBtn(true);
                break;
            case "StatusGarantia":
                setColumModal(columsStatusGarantia);
                setActiveBtn(true);
                break;
            case "MotivoGarantia":
                setColumModal(columsMotivoGarantia);
                setActiveBtn(true);
                break;
            case "MotivoContrato":
                setColumModal(columsMotivoContrato);
                setActiveBtn(true);
                break;
            case "TipoComision":
                setColumModal(columsTipoComision);
                setActiveBtn(true);
                break;
            case "MotivoCambio":
                setColumModal(columsMotivoCambio);
                setActiveBtn(true);
                break;
            case "StatusDatamovil":
                setColumModal(columsStatusDatamovil);
                setActiveBtn(true);
                break;
            case "TipoCosto":
                setColumModal(columsTipoCosto);
                setActiveBtn(true);
                break;
            case "Parametro":
                setColumModal(columsParametros);
                setActiveBtn(true);
                break;
            case "Colonia":
                setColumModal(columsColonia);
                setActiveBtn(true);
                setActiveBtnExcel(true);
                break;
            case "InventarioExterno":
                setColumModal(columsInvExterno);
                setActiveBtn(true);
                break;
            case "TipoDocumento":
                setColumModal(columsTipoDocumento);
                setActiveBtn(true);
                break;
            case "TipoDocumentoUsuario":
                setColumModal(columsTipoDocumentoUsuario);
                setActiveBtn(true);
                break;
            case "TipoArchivo":
                setColumModal(columsTipoArchivo);
                setActiveBtn(true);
                break;
            case "Tribunal":
                setColumModal(columsTribunal);
                setActiveBtn(true);
                break;
            case "ReglaNegocio":
                setColumModal(columsReglaNegocio);
                setActiveBtn(true);
                break;
            case "Empresa":
                setColumModal(columsEmpresa);
                setActiveBtn(true);
                break;
            case "RepresentanteLegal":
                setColumModal(columsRLegal);
                setActiveBtn(true);
                break;
            case "ConfiguracionFichero":
                setColumModal(columsConfigFichero);
                setActiveBtn(true);
                break;
            case "SocioComercial":
                setColumModal(columsSocioComercial);
                setActiveBtn(true);
                break;
            case "CambioEtapa":
                setColumModal(columsCambioEtapa);
                setActiveBtn(true);
                break;
            case "Ubicacion":
                setColumModal(columsUbicacion);
                setActiveBtn(true);
                break;
            case "Municipio":
                setColumModal(columsMunicipio);
                setActiveBtn(false);
                break;
            case "Vehiculos":
                setColumModal(columsVehiculo);
                setActiveBtn(true);
                break;
            case "InvCatAutos":
                setColumModal(columsIntelimotor);
                setActiveBtn(false);
                break;
            case "AgenteSeguro":
                setColumModal(columsAgenteSeguro);
                setActiveBtn(true);
                break;
            case "Sucursales":
                setColumModal(columsSucursal);
                setActiveBtn(true);
                break;
            case "Vendedor":
                setColumModal(columsVendedor);
                setActiveBtn(true);
                break;
            case "MotivoMantener":
                setColumModal(columsMotivoMantener);
                setActiveBtn(true);
                break;
            case "MotivoLiberacionSeparado":
                setColumModal(columsMotivoLiberacionSeparado);
                setActiveBtn(true);
                break;
            case "TipoEjecutivo":
                setColumModal(columsTipoEjecutivo);
                setActiveBtn(true);
                break;
            case "TipoPromotor":
                setColumModal(columsTipoPromotor);
                setActiveBtn(true);
                break;
            case "ProcedenciaCliente":
                setColumModal(columsProcedenciaCliente);
                setActiveBtn(true);
                break;
            case "Ejecutivo":
                setColumModal(columsEjecutivo);
                setActiveBtn(true);
                break;
            case "MotivoLiberacionEnganche":
                setColumModal(columsLiberacionEnganche);
                setActiveBtn(true);
                break;
            case "OperacionPlacas":
                setColumModal(columsOperacionPlacas);
                setActiveBtn(true);
                break;
            case "Tecnico":
                setColumModal(columsTecnico);
                setActiveBtn(true);
                break;
            case "MotivoBajaPlacas":
                setColumModal(columsMotivoBaja);
                setActiveBtn(true);
                break;
            case "MotivoLiberacionPruebaManejo":
                setColumModal(columsMotivoPM);
                setActiveBtn(true);
                break;
            case "ANombreDe":
                setColumModal(columsANombreDe);
                setActiveBtn(true);
                break;
            case "MotivoCancelacionGestoria":
                setColumModal(columsMotivoCancelacionGestoria);
                setActiveBtn(true);
                break;
            case "MotivoCancelacionEntrega":
                setColumModal(columsMotivoCancelacionEntrega);
                setActiveBtn(true);
                break;
            case "TipoExpediente":
                setColumModal(columsTipoExpediente);
                setActiveBtn(true);
                break;
            case "MotivoCambioProducto":
                setColumModal(columsMotivoCambioProd);
                setActiveBtn(true);
                break;
            case "MotivoNoRenovacionSeguro":
                setColumModal(columsMotivoNoRenovacionSeguro);
                setActiveBtn(true);
                break;
            case "RevisionDeUnidad":
                setColumModal(columsRevisionUnidad);
                setActiveBtn(true);
                break;
            case "Mecanico":
                setColumModal(columsMecanicos);
                setActiveBtn(true);
                break;
            case "UsoCFDI":
                setColumModal(columsUsoCFDI);
                setActiveBtn(true);
                break;
            case "RegimenFiscal":
                setColumModal(columsRegimenFiscal);
                setActiveBtn(true);
                break;
            case "TipoCalculo":
                setColumModal(columsTipoCalculo);
                setActiveBtn(true);
                break;
            case "ClasificacionVendedor":
                setColumModal(columsClasificacionVendedor);
                setActiveBtn(true);
                break;
            case "SucursalVendedor":
                setColumModal(columsSucursalVendedor);
                setActiveBtn(true);
                break;
        }
        setTableName(item.nombreTablaBd);
        setcurrentPageModal(1);
        handleGetlist(item);
    }
    const handleGetlist = async (item, page = 1) => {

        setLoading(true);
        await Catalogo.getdataTabla(item?.nombreTablaBd, page, 5, filtroTexto)
            .then(res => {
                setDataModal(res.data.data);
                setIsOpen(true);
                setNextPage(res.data.hasNextPage)
                setTotalPage(res.data.totalPages);
                setTotalRows(res.data.totalRows);
                setLoading(false);
            }).catch(e => {
                toast.error(message("¡Error.!", e.message));
                setLoading(false);
            })
    }
    const handleGetListModalScroll = async (page) => {
        if (nextPage) {
            await Catalogo.getdataTabla(tableName, page, 10, filtroTexto)
                .then(res => {
                    setDataModal([...dataModal, ...res.data.data]);
                    setIsOpen(true);
                    setNextPage(res.data.hasNextPage);
                    setTotalPage(res.data.totalPages);
                }).catch(e => {

                })
        }
    }
    const handleGetListModal = async (page) => {
        listInnerRef.current.scrollTop = 0;
        // scrollTop = ;
        await Catalogo.getdataTabla(tableName, page, 10, filtroTexto)
            .then(res => {
                setDataModal(res.data.data);
                setIsOpen(true);

                setcurrentPageModal(1);
                setNextPage(res.data.hasNextPage);
                setTotalPage(res.data.totalPages);
                setTotalRows(res.data.totalRows);
            }).catch(e => {

            })
    }
    const handlePageClickModal = (event) => {
        // setcurrentPageModal(event.selected + 1);      
        handleGetlist(itemTbl, event.selected + 1);
    };
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

            if (totalPage >= currentPageModal) {
                if (scrollTop + clientHeight >= scrollHeight) {
                    setcurrentPageModal(currentPageModal + 1);
                    handleGetListModalScroll(currentPageModal + 1);
                }
            }

        }
    };

    const handleEditModal = (item) => {
        setItem(item);
        setActionModal("edit");
        handleOpenModal(true, true);
    }

    const handleOpenModal = (value, isEdit) => {
        if (!isEdit)
            setItem(null);

        switch (itemTbl.nombreTablaBd) {
            case "MarcaTblSec":
                setIsOpenMarca(value);
                break;
            case "ModeloTblSec":
                setIsOpenModelo(value);
                break;
            case "VersionTblSec":
                setIsOpenVersion(value);
                break;
            case "YearTblSec":
                setIsOpenYear(value);
                break;
            case "Aseguradora":
                setIsOpenAseguradora(value);
                break;
            case "Banco":
                setIsOpenBanco(value);
                break;
            case "CategoriaCosto":
                setIsOpenCatCosto(value);
                break;
            case "Cobertura":
                setIsOpenCobertura(value);
                break;
            case "Etapa":
                setIsOpenEtapa(value);
                break;
            case "Extension":
                setIsOpenExtension(value);
                break;
            case "MotivoCancelacionSeguro":
                setIsOpenMotivoCS(value);
                break;
            case "Producto":
                setIsOpenProducto(value);
                break;
            case "StatusSeguro":
                setIsOpenStatusSeguro(value);
                break;
            case "TipoAdquisicion":
                setIsOpenTipoAd(value);
                break;
            case "TipoCompra":
                setIsOpenTipoCompra(value);
                break;
            case "TipoExterno":
                setIsOpenTipoExterno(value);
                break;
            case "TipoPago":
                setIsOpenTipoPago(value);
                break;
            case "TipoResponsiva":
                setIsOpenTipoResp(value);
                break;
            case "TipoUbicacion":
                setIsOpenTipoUb(value);
                break;
            case "Transmision":
                setIsOpenTransmmision(value);
                break;
            case "UsoUnidad":
                setIsOpenUsoUnidad(value);
                break;
            case "EdoMexico":
                setIsOpenEdoMex(value);
                break;
            case "Condicion":
                setIsOpenCondicion(value);
                break;
            case "FrecuenciaPago":
                setIsOpenFrecuenciaP(value);
                break;
            case "Interfaz":
                setIsOpenInterfaz(value);
                break;
            case "TipoFactura":
                setIsOpenTipoFactura(value);
                break;
            case "StatusGarantia":
                setIsOpenStatusGarantia(value);
                break;
            case "MotivoGarantia":
                setIsOpenMotivoGarantia(value);
                break;
            case "MotivoContrato":
                setIsOpenMotivoContrato(value);
                break;
            case "TipoComision":
                setIsOpenTipoComision(value);
                break;
            case "MotivoCambio":
                setIsOpenMotivoCambio(value);
                break;
            case "StatusDatamovil":
                setIsOpenStatusDatamovil(value);
                break;
            case "TipoCosto":
                setIsOpenTipoCosto(value);
                break;
            case "Colonia":
                setIsOpenColonia(value);
                break;
            case "InventarioExterno":
                setIsOpenInvExterno(value);
                break;
            case "TipoDocumento":
                setIsOpenTipoDoc(value);
                break;
            case "TipoDocumentoUsuario":
                setIsOpenTipoDocUsuario(value);
                break;
            case "TipoArchivo":
                setIsOpenTipoArchivo(value);
                break;
            case "Tribunal":
                setIsOpenTtribunal(value);
                break;
            case "ReglaNegocio":
                setIsOpenReglaN(value);
                break;
            case "Empresa":
                setIsOpenEmpresa(value);
                break;
            case "RepresentanteLegal":
                setIsOpenRLegal(value);
                break;
            case "ConfiguracionFichero":
                setIsOpenConfigFichero(value);
                break;
            case "SocioComercial":
                setIsOpenSocioComercial(value);
                break;
            case "CambioEtapa":
                setIsOpencambioEtapa(value);
                break;
            case "Ubicacion":
                setIsOpenUbicacion(value);
                break;
            case "MotivoUbicacion":
                setIsOpenMotivoUb(value);
                break;
            case "Municipio":
                setIsOpenMunicipio(value);
                break;
            case "Vehiculos":
                setIsOpenVehiculo(value);
                break;
            case "InvCatAutos":
                setIsOpenIntelimotor(value);
                break;
            case "AgenteSeguro":
                setisOpenAgenteSeguro(value);
                break;
            case "Sucursales":
                setIsOpenSucursal(value);
                break;
            case "Vendedor":
                setIsOpenVendedor(value);
                break;
            case "MotivoMantener":
                setIsOpenMotivoMantener(value);
                break;
            case "MotivoLiberacionSeparado":
                setIsOpenMotivoLiberacion(value);
                break;
            case "TipoEjecutivo":
                setIsOpenTipoEjecutivo(value);
                break;
            case "TipoPromotor":
                setIsOpenTipoPromotor(value);
                break;
            case "ProcedenciaCliente":
                setIsOpenPCliente(value);
                break;
            case "Ejecutivo":
                setIsOpenEjecutivo(value);
                break;
            case "MotivoLiberacionEnganche":
                setIsOpenLiberacionEnganche(value);
                break;
            case "OperacionPlacas":
                setIsOpenOperacionPlacas(value);
                break;
            case "Tecnico":
                setIsOpenOTecnico(value);
                break;
            case "MotivoBajaPlacas":
                setIsOpenMotivoBaja(value);
                break;
            case "MotivoLiberacionPruebaManejo":
                setIsOpenMotivoPM(value);
                break;
            case "ANombreDe":
                setIsOpenANombreDe(value);
                break;
            case "MotivoCancelacionGestoria":
                setIsOpenMotivoCancelacionGestoria(value);
                break;
            case "MotivoCancelacionEntrega":
                setIsOpenMotivoCancelacionEntrega(value);
                break;
            case "TipoExpediente":
                setIsOpenTipoExpediente(value);
                break;
            case "MotivoCambioProducto":
                setIsOpenMotivoCambioProd(value);
                break;
            case "MotivoNoRenovacionSeguro":
                setIsOpenMotivoNoRenovacionSeguro(value);
                break;
            case "RevisionDeUnidad":
                setIsOpenRevisionUnidad(value);
                break;
            case "Mecanico":
                setIsOpenMecanico(value);
                break;
            case "UsoCFDI":
                setIsOpenUsoCFDI(value);
                break;
            case "RegimenFiscal":
                setIsOpenRegimenFiscal(value);
                break;
            case "TipoCalculo":
                setIsOpenTipoCalculo(value);
                break;
            case "ClasificacionVendedor":
                setIsOpenClasificacionVendedor(value);
                break;
            case "SucursalVendedor":
                setIsOpenSucursalVendedor(value);
                break;
        }
    }

    const handleChangeStatus = async (event, item) => {

        let id;
        switch (itemTbl.nombreTablaBd) {
            case "MarcaTblSec":
                id = item.marcaId;
                break;
            case "ModeloTblSec":
                id = item.modeloId;
                break;
            case "VersionTblSec":
                id = item.versionId;
                break;
            case "YearTblSec":
                id = item.yearId
                break;
            case "Aseguradora":
                id = item.aseguradoraId;
                break;
            case "Banco":
                id = item.bancoId;
                break;
            case "CategoriaCosto":
                id = item.categoriaCostoId;
                break;
            case "Cobertura":
                id = item.coberturaId;
                break;
            case "Etapa":
                id = item.etapaId;
                break;
            case "Extension":
                id = item.extensionId;
                break;
            case "MotivoCancelacionSeguro":
                id = item.motivoCancelacionSeguroId;
                break;
            case "MotivoUbicacion":
                id = item.motivoUbicacionId;
                break;
            case "Producto":
                id = item.productoId;
                break;
            case "StatusSeguro":
                id = item.statusSeguroId;
                break;
            case "TipoAdquisicion":
                id = item.tipoAdquisicionId;
                break;
            case "TipoCompra":
                id = item.tipoCompraId;
                break;
            case "TipoExterno":
                id = item.tipoExternoId;
                break;
            case "TipoPago":
                id = item.tipoPagoId;
                break;
            case "TipoResponsiva":
                id = item.tipoResponsivaId;
                break;
            case "TipoUbicacion":
                id = item.tipoUbicacionId;
                break;
            case "Transmision":
                id = item.transmisionId;
                break;
            case "UsoUnidad":
                id = item.usoUnidadId;
                break;
            case "EdoMexico":
                id = item.edoMexicoId;
                break;
            case "Condicion":
                id = item.condicionId;
                break;
            case "FrecuenciaPago":
                id = item.frecuenciaPagoId;
                break;
            case "Interfaz":
                id = item.interfazId;
                break;
            case "TipoFactura":
                id = item.tipoFacturaId;
                break;
            case "StatusGarantia":
                id = item.statusGarantiaId;
                break;
            case "MotivoGarantia":
                id = item.motivoGarantiaId;
                break;
            case "MotivoContrato":
                id = item.motivoContratoId;
                break;
            case "TipoComision":
                id = item.tipoComisionId;
                break;
            case "MotivoCambio":
                id = item.motivoCambioId;
                break;
            case "StatusDatamovil":
                id = item.statusDatamovilId;
                break;
            case "TipoCosto":
                id = item.tipoCostoId;
                break;
            case "Colonia":
                id = item.coloniaId;
                break;
            case "InventarioExterno":
                id = item.inventarioExternoId;
                break;
            case "TipoDocumento":
                id = item.tipoDocumentoId;
                break;
            case "TipoDocumentoUsuario":
                id = item.tipoDocumentoUsuarioId;
                break;
            case "TipoArchivo":
                id = item.tipoArchivoId;
                break;
            case "Tribunal":
                id = item.tribunalId;
                break;
            case "ReglaNegocio":
                id = item.reglaNegocioId;
                break;
            case "Empresa":
                id = item.empresaId;
                break;
            case "RepresentanteLegal":
                id = item.representanteLegalId;
                break;
            case "ConfiguracionFichero":
                id = item.configuracionFicheroId;
                break;
            case "SocioComercial":
                id = item.socioComercialId;
                break;
            case "CambioEtapa":
                id = item.cambioEtapaId;
                break;
            case "Ubicacion":
                id = item.ubicacionId;
                break;
            case "Municipio":
                id = item.municipioId;
                break;
            case "Vehiculos":
                id = item.vehiculosId;
                break;
            case "InvCatAutos":
                id = item.procesoId;
                break;
            case "AgenteSeguro":
                id = item.agenteSeguroId;
                break;
            case "Sucursales":
                id = item.sucursalId;
                break;
            case "Vendedor":
                id = item.vendedorId;
                break;
            case "MotivoMantener":
                id = item.motivoMantenerId;
                break;
            case "MotivoLiberacionSeparado":
                id = item.motivoLiberacionSeparadoId;
                break;
            case "TipoEjecutivo":
                id = item.tipo_EjecutivoId;
                break;
            case "TipoPromotor":
                id = item.tipo_PromotorId;
                break;
            case "ProcedenciaCliente":
                id = item.procedencia_ClienteId;
                break;
            case "Ejecutivo":
                id = item.ejecutivoId;
                break;
            case "MotivoLiberacionEnganche":
                id = item.motivoLiberacionEngancheId;
                break;
            case "OperacionPlacas":
                id = item.operacion_PlacasId;
                break;
            case "Tecnico":
                id = item.tecnicoId;
                break;
            case "MotivoBajaPlacas":
                id = item.motivoBajaPlacasId;
                break;
            case "MotivoLiberacionPruebaManejo":
                id = item.motivoLiberacionPruebaManejoId;
                break;
            case "ANombreDe":
                id = item.nombreId;
                break;
            case "MotivoCancelacionGestoria":
                id = item.motivoCancelacionGestoriaId;
                break;
            case "MotivoCancelacionEntrega":
                id = item.motivoCancelacionEntregaId;
                break;
            case "TipoExpediente":
                id = item.tipoExpedienteId;
                break;
            case "MotivoCambioProducto":
                id = item.motivoCambioProductoId;
                break;
            case "MotivoNoRenovacionSeguro":
                id = item.motivoNoRenovacionSeguroId;
                break;
            case "RevisionDeUnidad":
                id = item.revisionUnidadId;
                break;
            case "Mecanico":
                id = item.mecanicosId;
                break;
            case "UsoCFDI":
                id = item.usoCFDIId;
                break;
            case "RegimenFiscal":
                id = item.regimenFiscalId;
                break;
            case "TipoCalculo":
                id = item.tipoCalculoId;
                break;
            case "ClasificacionVendedor":
                id = item.clasificacionVendedorId;
                break;
            case "SucursalVendedor":
                id = item.sucursalVendedorId;
                break;
        }

        await Catalogo.updateStatus(tableName, id, event)
            .then(res => {

                handleEdit(itemTbl);
                if (res.data.message === "OK") {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Status modificado correctamente." })
                }
            }).catch(e => {

                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const changeHandler = () => {
        fileRef.current.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        );
    };
    const handleChangeFile = (event) => {

        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    }
    const handleSubmit = async () => {

        setLoading(true);
        const formData = new FormData();

        formData.append('File', selectedFile);

        await ConfigService.uploadFile(formData)
            .then(res => {

                setOpenModalExcel(false);
                setIsFilePicked(false);
                setLoading(false);
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.message })
                setSelectedFile(null);
            }).catch(e => {

                setLoading(false);
                setOpenModalExcel(false);
                setSelectedFile(null);
                setIsFilePicked(false);
                if (e.response !== undefined) {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                }
                else {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })

                }
            })
    };

    return (
      <>
        <Toaster
          position="top-right"
          toastOptions={{
            success: {
              style: {
                background: "#47a066",
                color: "#FFFF",
                borderLeft: "10px solid #2f7246",
              },
            },
            error: {
              style: {
                background: "#d53f3f",
                color: "#FFFF",
                borderLeft: "10px solid #ac241a",
              },
            },
          }}
        />
        {isOpen && (
          <Modal isOpen={isOpen} color="#fff">
            <section className="d-flex justify-content-between">
              <span className="">
                <strong>
                  Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla}{" "}
                  {itemTbl.nombreProceso}
                </strong>
              </span>
              <div className="d-flex">
                {activeBtnEcel && (
                  <button
                    className="btn btn-outline mx-4 d-flex align-items-center"
                    onClick={() => {
                      setOpenModalExcel(true);
                    }}
                  >
                    <i
                      className="ri-file-excel-2-line mx-2"
                      style={{ color: "green", fontSize: "1.3rem" }}
                    ></i>{" "}
                    Carga Masiva
                  </button>
                )}
                <i
                  id="AT_btnClose"
                  className="icon ri-close-circle-line fs-3"
                  onClick={() => {
                    setFiltroTexto("");
                    setIsOpen(false);
                  }}
                ></i>
              </div>
              {/* <button onClick={()=>{setIsOpen(false)}}>cerrar</button> */}
            </section>
            <section>
              <span style={{ color: "#004c97" }}>
                {" "}
                <strong>Total registros: {totalRows}</strong>{" "}
              </span>
            </section>
            <div className="col-4 mx-1 form-group inner-addon right-addon mt-3">
              <i className="glyphicon fas fa-search"></i>
              <input
                type="text"
                className="form-control mb-4"
                placeholder="Buscar"
                onChange={(e) => {
                  setFiltroTexto(e.target.value);
                }}
              />
            </div>
            <section className="my-2 table-responsive">
              {/* <ListingPageComponent
                            onScroll={onScroll}
                            listInnerRef={listInnerRef}
                            data={dataModal}
                            columns={columModal}
                            height={300}
                            editable={activeBtn}
                            handleEdit={handleEditModal}
                            isStatus={activeBtn}
                            handleChange={handleChangeStatus}
                        /> */}
              <DataTable
                column={columModal}
                data={dataModal}
                isStatus={activeBtn}
                handleChange={handleChangeStatus}
                editable={activeBtn}
                handleEdit={handleEditModal}
                pageCount={totalPage}
                handlePageClick={handlePageClickModal}
                currentPage={currentPageModal - 1}
              />
            </section>
            <section className="my-3">
              <button
                id="AT_btnAgregarValor"
                className="btn"
                onClick={() => {
                  setActionModal("add");
                  handleOpenModal(true, false);
                }}
                disabled={!activeBtn}
              >
                <i className="bi bi-plus"></i>Agregar Valor
              </button>
            </section>
          </Modal>
        )}
        {openModalExcel && (
          <Modal isOpen={openModalExcel} color="#fff">
            <div className="container-buttons">
              <div className="d-flex justify-content-end">
                <i
                  className="icon ri-close-circle-line fs-3"
                  onClick={() => {
                    setIsFilePicked(false);
                    setOpenModalExcel(false);
                    setSelectedFile(null);
                  }}
                ></i>
              </div>
              <h4>
                <strong>Subir Archivos</strong>
              </h4>
              <span>Selecciona el archivo que desees subir</span>
              <div className="mt-5">
                <div className="my-3">
                  <input
                    className="form-control d-none"
                    type="file"
                    id="formFile"
                    ref={fileRef}
                    onChange={handleChangeFile}
                  />
                  <section
                    className="section-file d-flex flex-column"
                    onClick={changeHandler}
                  >
                    <i className="icon-up ri-upload-cloud-2-line"></i>
                    <span>{selectedFile?.name}</span>
                  </section>
                </div>
              </div>
              <div className="d-flex justify-content-around mt-5">
                {/* <button className="btn btn-outline col-5" type="button" onClick={() => { setIsFilePicked(false); setOpenModalExcel(false) }}>Cancelar</button> */}
                <button
                  className="btn col-5"
                  type="button"
                  disabled={!isFilePicked}
                  onClick={handleSubmit}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Modal isOpen={modalMessage.isOpen} color="#fff">
          {modalMessage.type === 1 && (
            <ErrorModal
              modalMessage={modalMessage}
              setModalMessage={setModalMessage}
            />
          )}
          {modalMessage.type === 2 && (
            <SuccessModal
              modalMessage={modalMessage}
              setModalMessage={setModalMessage}
            />
          )}
        </Modal>
        {loading && (
          <Modal isOpen={loading} color="transparent">
            <Loadig message={"Procesando información..."} />
          </Modal>
        )}
        {isOpenMarca && (
          <Marca
            isOpen01={isOpenMarca}
            setIsOpen={setIsOpenMarca}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
          />
        )}
        {isOpenModelo && (
          <Modelo
            isOpen={isOpenModelo}
            setIsOpen={setIsOpenModelo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
          />
        )}
        {isOpenVersion && (
          <Version
            isOpen={isOpenVersion}
            setIsOpen={setIsOpenVersion}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
          />
        )}
        {isOpenYear && (
          <Version
            isOpen={isOpenYear}
            setIsOpen={setIsOpenYear}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
          />
        )}
        {isOpenAseguradora && (
          <Aseguradora
            isOpen={isOpenAseguradora}
            setIsOpen={setIsOpenAseguradora}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenBanco && (
          <Banco
            isOpen={isOpenBanco}
            setIsOpen={setIsOpenBanco}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpencatCosto && (
          <CatCosto
            isOpen={isOpencatCosto}
            setIsOpen={setIsOpenCatCosto}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenCobertura && (
          <Cobertura
            isOpen={isOpenCobertura}
            setIsOpen={setIsOpenCobertura}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenEtapa && (
          <Etapa
            isOpen={isOpenEtapa}
            setIsOpen={setIsOpenEtapa}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenExtension && (
          <Extension
            isOpen={isOpenExtension}
            setIsOpen={setIsOpenExtension}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoCS && (
          <MotivoCS
            isOpen={isOpenMotivoCS}
            setIsOpen={setIsOpenMotivoCS}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoUb && (
          <MotivoUb
            isOpen={isOpenMotivoUb}
            setIsOpen={setIsOpenMotivoUb}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenProducto && (
          <Producto
            isOpen={isOpenProducto}
            setIsOpen={setIsOpenProducto}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenStatusSeguro && (
          <StatusSeguro
            isOpen={isOpenStatusSeguro}
            setIsOpen={setIsOpenStatusSeguro}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoAd && (
          <TipoAdquisicion
            isOpen={isOpenTipoAd}
            setIsOpen={setIsOpenTipoAd}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoCompra && (
          <TipoCompra
            isOpen={isOpenTipoCompra}
            setIsOpen={setIsOpenTipoCompra}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoExterno && (
          <TipoExterno
            isOpen={isOpenTipoExterno}
            setIsOpen={setIsOpenTipoExterno}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoPago && (
          <TipoPago
            isOpen={isOpenTipoPago}
            setIsOpen={setIsOpenTipoPago}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoResp && (
          <TipoResponsiva
            isOpen={isOpenTipoResp}
            setIsOpen={setIsOpenTipoResp}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoUb && (
          <TipoUb
            isOpen={isOpenTipoUb}
            setIsOpen={setIsOpenTipoUb}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTransmmision && (
          <Transmision
            isOpen={isOpenTransmmision}
            setIsOpen={setIsOpenTransmmision}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenUsoUnidad && (
          <UsoUnidad
            isOpen={isOpenUsoUnidad}
            setIsOpen={setIsOpenUsoUnidad}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenEdoMex && (
          <EdoMexico
            isOpen={isOpenEdoMex}
            setIsOpen={setIsOpenEdoMex}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenCondicion && (
          <Condicion
            isOpen={isOpenCondicion}
            setIsOpen={setIsOpenCondicion}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenFrecuenciaP && (
          <FrecuenciaPago
            isOpen={isOpenFrecuenciaP}
            setIsOpen={setIsOpenFrecuenciaP}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenInterfaz && (
          <Interfaz
            isOpen={isOpenInterfaz}
            setIsOpen={setIsOpenInterfaz}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoFactura && (
          <TipoFactura
            isOpen={isOpenTipoFactura}
            setIsOpen={setIsOpenTipoFactura}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenStatusGarantia && (
          <StatusGarantia
            isOpen={isOpenStatusGarantia}
            setIsOpen={setIsOpenStatusGarantia}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoGarantia && (
          <MotivoGarantia
            isOpen={isOpenMotivoGarantia}
            setIsOpen={setIsOpenMotivoGarantia}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoContrato && (
          <MotivoContrato
            isOpen={isOpenMotivoContrato}
            setIsOpen={setIsOpenMotivoContrato}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoComision && (
          <TipoComision
            isOpen={isOpenTipoComision}
            setIsOpen={setIsOpenTipoComision}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoCambio && (
          <MotivoCambio
            isOpen={isOpenMotivoCambio}
            setIsOpen={setIsOpenMotivoCambio}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenStatusDatamovil && (
          <StatusDatamovil
            isOpen={isOpenStatusDatamovil}
            setIsOpen={setIsOpenStatusDatamovil}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoCosto && (
          <TipoCosto
            isOpen={isOpenTipoCosto}
            setIsOpen={setIsOpenTipoCosto}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenColonia && (
          <CatColonia
            isOpen={isOpenColonia}
            setIsOpen={setIsOpenColonia}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMunicipio && (
          <CatMunicipio
            isOpen={isOpenMunicipio}
            setIsOpen={setIsOpenMunicipio}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenInvExterno && (
          <InvExterno
            isOpen={isOpenInvExterno}
            setIsOpen={setIsOpenInvExterno}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoDoc && (
          <TipoDocumento
            isOpen={isOpenTipoDoc}
            setIsOpen={setIsOpenTipoDoc}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoDocUsuario && (
          <TipoDocumentoUsuario
            isOpen={isOpenTipoDocUsuario}
            setIsOpen={setIsOpenTipoDocUsuario}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoArchivo && (
          <TipoArchivo
            isOpen={isOpenTipoArchivo}
            setIsOpen={setIsOpenTipoArchivo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTtribunal && (
          <Tribunal
            isOpen={isOpenTtribunal}
            setIsOpen={setIsOpenTtribunal}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenReglaN && (
          <ReglaNegocio
            isOpen={isOpenReglaN}
            setIsOpen={setIsOpenReglaN}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenEmpresa && (
          <Empresa
            isOpen={isOpenEmpresa}
            setIsOpen={setIsOpenEmpresa}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenRLegal && (
          <RepresentanteLegal
            isOpen={isOpenRLegal}
            setIsOpen={setIsOpenRLegal}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenConfigFichero && (
          <ConfigFichero
            isOpen={isOpenConfigFichero}
            setIsOpen={setIsOpenConfigFichero}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenSocioComercial && (
          <SocioComercial
            isOpen={isOpenSocioComercial}
            setIsOpen={setIsOpenSocioComercial}
            item={item}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            existingData={dataModal}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpencambioEtapa && (
          <CambioEtapa
            isOpen={isOpencambioEtapa}
            setIsOpen={setIsOpencambioEtapa}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenUbicacion && (
          <Ubicacion
            isOpen={isOpenUbicacion}
            setIsOpen={setIsOpenUbicacion}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenVehiculo && (
          <Vehiculo
            isOpen={isOpenVehiculo}
            setIsOpen={setIsOpenVehiculo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage} 
          />
        )}
        {isOpenAgenteSeguro && (
          <AgenteSeguro
            isOpen={isOpenAgenteSeguro}
            setIsOpen={setisOpenAgenteSeguro}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenSucursal && (
          <Sucursal
            isOpen={isOpenSucursal}
            setIsOpen={setIsOpenSucursal}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenVendedor && (
          <Vendedor
            isOpen={isOpenVendedor}
            setIsOpen={setIsOpenVendedor}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoMantener && (
          <MotivoMantener
            isOpen={isOpenMotivoMantener}
            setIsOpen={setIsOpenMotivoMantener}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoLiberacion && (
          <MotivoLiberacion
            isOpen={isOpenMotivoLiberacion}
            setIsOpen={setIsOpenMotivoLiberacion}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoEjecutivo && (
          <TipoEjecutivo
            isOpen={isOpenTipoEjecutivo}
            setIsOpen={setIsOpenTipoEjecutivo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoPromotor && (
          <TipoPromotor
            isOpen={isOpenTipoPromotor}
            setIsOpen={setIsOpenTipoPromotor}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenPCliente && (
          <ProcedenciaCliente
            isOpen={isOpenPCliente}
            setIsOpen={setIsOpenPCliente}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenEjecutivo && (
          <Ejecutivo
            isOpen={isOpenEjecutivo}
            setIsOpen={setIsOpenEjecutivo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenLiberacionEnganche && (
          <MotivoLiberacionEnganche
            isOpen={isOpenLiberacionEnganche}
            setIsOpen={setIsOpenLiberacionEnganche}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenOperacionPlacas && (
          <OperacionPlacas
            isOpen={isOpenOperacionPlacas}
            setIsOpen={setIsOpenOperacionPlacas}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenOTecnico && (
          <Tecnico
            isOpen={isOpenOTecnico}
            setIsOpen={setIsOpenOTecnico}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoBaja && (
          <MotivoBajaPlacas
            isOpen={isOpenMotivoBaja}
            setIsOpen={setIsOpenMotivoBaja}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoPM && (
          <MotivoBajaPruebaManejo
            isOpen={isOpenMotivoPM}
            setIsOpen={setIsOpenMotivoPM}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenANombreDe && (
          <ANombreDe
            isOpen={isOpenANombreDe}
            setIsOpen={setIsOpenANombreDe}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoCancelacionGestoria && (
          <MotivoCancelacionGestoria
            isOpen={isOpenMotivoCancelacionGestoria}
            setIsOpen={setIsOpenMotivoCancelacionGestoria}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoCancelacionEntrega && (
          <MotivoCancelacionEntrega
            isOpen={isOpenMotivoCancelacionEntrega}
            setIsOpen={setIsOpenMotivoCancelacionEntrega}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoExpediente && (
          <TipoExpediente
            isOpen={isOpenTipoExpediente}
            setIsOpen={setIsOpenTipoExpediente}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoCambioProd && (
          <MotivoCambioProducto
            isOpen={isOpenMotivoCambioProd}
            setIsOpen={setIsOpenMotivoCambioProd}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMotivoNoRenovacionSeguro && (
          <MotivoNoRenovacionSeguro
            isOpen={isOpenMotivoNoRenovacionSeguro}
            setIsOpen={setIsOpenMotivoNoRenovacionSeguro}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenRevisionUnidad && (
          <RevisionDeUnidad
            isOpen={isOpenRevisionUnidad}
            setIsOpen={setIsOpenRevisionUnidad}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenMecanico && (
          <Mecanico
            isOpen={isOpenMecanico}
            setIsOpen={setIsOpenMecanico}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenRegimenFiscal && (
          <RegimenFiscal
            isOpen={isOpenRegimenFiscal}
            setIsOpen={setIsOpenRegimenFiscal}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenUsoCFDI && (
          <UsoCFDI
            isOpen={isOpenUsoCFDI}
            setIsOpen={setIsOpenUsoCFDI}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenTipoCalculo && (
          <TipoCalculo
            isOpen={isOpenTipoCalculo}
            setIsOpen={setIsOpenTipoCalculo}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenClasificacionVendedor && (
          <ClasificacionVendedor
            isOpen={isOpenClasificacionVendedor}
            setIsOpen={setIsOpenClasificacionVendedor}
            existingData={dataModal}
            item={item}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {isOpenSucursalVendedor && (
          <SucursalVendedor
            isOpen={isOpenSucursalVendedor}
            setIsOpen={setIsOpenSucursalVendedor}
            item={item}
            existingData={dataModal}
            handleGetAll={() => {
              handleGetlist(itemTbl);
            }}
            action={actionModal}
            itemTbl={itemTbl}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        <Submenu />
        <div className="title fs-5 mx-4 pt-3">
          <strong>Configuración tablas</strong>
        </div>
        <section className="mx-4">
          A continuación, se presentará un listado con las tablas que son
          indispensables para realizar uno o varios procesos del DataWare.
        </section>
        <div className="mx-4 my-5">
          {/* <section className='row'> */}
          <div className="row col-12">
            <div className="col-lg-4 col-md-6 col-sm-12 form-group inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              {/* <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={handleChange} /> */}
              <input
                type="text"
                className="form-control mb-4"
                placeholder="Buscar"
                onKeyDown={handleChange}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <select
                className="form-select"
                onChange={(e) => handleChangeList(e.target.value)}
                placeholder="Proceso"
              >
                {/* <option value="" disabled selected={true}>Proceso</option> */}
                <option value="0">Todos los procesos</option>
                {dataList?.map((item, index) => {
                  return (
                    <option key={index} value={item.procesoId}>
                      {item.nombreProceso}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <ValidatePermission
            isActive={
              ValidatePermissionById(
                4,
                Section.TablasSecundariasOriginacion,
                SubModule.TablasSecundarias
              ).Ver
            }
          >
            <div className="d-flex justify-content-between align-items-center me-4">
              <div className="fs-5 mt-3">Tablas Secundarias</div>
              <div className="mx-1 col-lg-4 col-md-6 col-sm-12">
                <div className="d-flex justify-content-end align-items-center">
                  <span className="me-1" style={{ fontSize: "12px" }}>
                    Resultado por pagina
                  </span>
                  <Select
                    options={pageRows()}
                    className="col-2"
                    defaultValue={{ value: 10, label: 10 }}
                    onChange={(e) => {
                      setpageRows(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                column={columns}
                data={data}
                editable={
                  ValidatePermissionById(
                    4,
                    Section.TablasSecundariasOriginacion,
                    SubModule.TablasSecundarias
                  ).Actualizar
                }
                handleEdit={handleEdit}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                currentPage={currentPage}
              />
            </div>
          </ValidatePermission>
        </div>
      </>
    );
}

export default TablasSecundarias

/*
existingData={dataModal}



console.log(existingData);
     let cleanCondition = item.condicion
       .replace(/[^a-zA-Z0-9]/g, "")
       .toLowerCase()
       .trim();

     if (existingData?.find?.((x) => x?.condicion.includes(cleanCondition))) {
       setModalMessage({
         ...modalMessage,
         isOpen: true,
         type: 1,
         message: "La condición ya existe.",
       });
       refbtn.current.disabled = false;
       return;
     }
        
        return;
*/