import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import DataTable from '../../../Components/datatable/DataTable';
import ReparacionUnidadModal from '../../../Components/Modal/ReparacionUnidadModal';
import { TextField } from '../../../Components/TextField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import PagoTramitesGestoriaCard from './PagoTramitesGestoriaCard';
import CambioPlacasCard from './CambioPlacasCard';
import ReactivacionAltaPlacasCard from './ReactivacionAltaPlacasCard';
import TarjetaCirculacionCard from './TarjetaCirculacionCard';
import EntregaGestoriaCard from './EntregaGestoriaCard';
const RegistroTramiteGestoriaCard
    = React.forwardRef(({ ...props }, ref) => {
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { vin, id } = useParams();
        let initial = {
            gestoriaRegistroInicialId: null,
            gestoriaRegistroInicialStatusId: 0,
            gestoriaRegistroInicialStatus: 'Pendiente',
            color: 'default',
            generalId: null,
            vin: null,
            numContrato: null,
            folioId: null,
            tipoTramiteId: null,
            origen: null,
            origenId: null,
            nombreId: null,
            personaSolicita: null,
            telefonoFijo: null,
            telefonoMovil: null,
            telefonoTrabajo: null,
            concluido: null,
            usuarioAlta: null,
            usuarioActualiza: null,
            usuarioAltaNombre: null,
            usuarioActualizaNombre: null,
            fechaAlta: null,
            fechaActualiza: null,
            fechaConclusion: null
        }
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [tipoTramites, setTipoTramites] = useState([]);
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [showCard, setShowCard] = useState(false);

        useEffect(() => {
            getTipoTramite();
            getData();
        }, []);
        
        /* CONSULTAS */
        const getTipoTramite = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.TipoTramiteId,
                        label: item.TipoTramite
                    })
                });

                setTipoTramites(items);
            })
            .catch(err => {
                console.error(err);
            });
        }

        const getData = async () => {
            await Gestoria.RegistroTramiteInicialGet(id)
            .then(resp => {
                setInitialValue(resp.data);
                if(resp.data.concluido){
                    setShowCard(true);
                }
            })
            .catch(err => {
                console.error(err);
            })
        }
        /*  TERMINA CONSULTAS */


        /* CATALOGOS */
        /* TERMINA CATALOGOS */
        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }
        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        const submitForm = async (values) => {
            let tramite = ""
            if(!values.concluido){
                values.gestoriaRegistroInicialStatusId = 20;
            }else{
                values.gestoriaRegistroInicialStatusId = 30;
            }
            setLoading(true);
            await Gestoria.RegistroTramiteInicialActualizar(values)
            .then(resp => {
                toast.success(message("Se Actualizó el Registro Inicial correctamente"));
                setInitialValue(resp.data);
                
                tramite = resp.data.tipoTramiteId;
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar Actualizar el Registro Inicial!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false);

                
                if(values.concluido){
                    if(tramite === 'b69b0d46-4b86-4115-b0b8-76d7d6b20e9a'){
                        // CambioPlacasCard 
                        CrearCambioPlacas();
                    }
                    if(tramite === '71b357a6-0fcd-4058-bc16-32002a6c63fb'){
                        // ReactivacionAltaPlacasCard
                        CrearAltaPlacas();
                    }
                    if(tramite === '140f43da-d8ae-40f7-9215-ced1f2a4333c'){
                        // TarjetaCirculacionCard 
                        CrearTarjetaCirculacion();
                    }
                }
            });
        }

        const CrearCambioPlacas = async () => {
            let params = {
                gestoriaCambioPlacasStatusId: 10,
                gestoriaRegistroInicialId: id
            };

            setLoading(true);
            await Gestoria.CambioPlacasCrear(params)
            .then(resp => {
                toast.success(message("Se creó el registro de cambio de placas correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el registro de cambio de placas!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    setShowCard(true);
                }, 1000);
            });

        }

        const CrearAltaPlacas = async () => {
            let params = {
                gestoriaReactivacionAltaPlacasStatusId: 10,
                gestoriaRegistroInicialId: id
            }

            setLoading(true);
            await Gestoria.AltaPlacasCrear(params)
            .then(resp => {
                toast.success(message("Se creó el registro de alta de placas correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el registro de alta de placas!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    setShowCard(true);
                }, 1000);
            });
        }

        const CrearTarjetaCirculacion = async () => {
            let params = {
                gestoriaTarjetaCirculacionStatusId: 10,
                gestoriaRegistroInicialId: id
            }

            setLoading(true);
            await Gestoria.TarjetaCirculacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó el registro de tarjeta de circulación correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear el registro de tarjeta de circulación!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    setShowCard(true);
                }, 1000);
            });

        }

        const setFormatPhone = (field, value, fn) => {
            let prevPhone = value.replace(/[()-]/g,'');
                let formatted = "";
            
            if(prevPhone.length < 3){
                formatted = prevPhone;  
            }else if(prevPhone.length >= 3 && prevPhone.length <= 6){
                let lada = prevPhone.substr(0,2);
                let firstSegment = prevPhone.substr(2,4);
                formatted = "("+lada+")-"+firstSegment;  
            }else if(prevPhone.length > 6){
                let lada = prevPhone.substr(0,2);
                let firstSegment = prevPhone.substr(2,4);
                let secondSegment = prevPhone.substr(6,4);
                formatted = "("+lada+")-"+firstSegment+"-"+secondSegment;  
            }

            fn(field, formatted);
        };


        const validate = Yup.object({});

        

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">
                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRegistroTramite" role="button" aria-expanded="false" aria-controls="collapseRegistroTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Registro inicial de trámite</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.gestoriaRegistroInicialStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseRegistroTramite">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de trámite</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.gestoriaRegistroInicialStatusId === 30}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="tipoTramiteId"
                                                            label="Tipo de trámite"
                                                            name="tipoTramiteId"
                                                            disabled={initialValue.gestoriaRegistroInicialStatusId === 30}
                                                            items={tipoTramites}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoTramiteId", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Datos de contacto</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="personaSolicita" disabled={initialValue.gestoriaRegistroInicialStatusId === 30} label="Persona que solicita trámite" holder="" name="personaSolicita" type="text" onChange={(event) => {
                                                            setFieldValue("personaSolicita", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoMovil" disabled={initialValue.gestoriaRegistroInicialStatusId === 30} label="Teléfono móvil" holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoMovil', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoFijo" disabled={initialValue.gestoriaRegistroInicialStatusId === 30} label="Teléfono fijo" holder="" name="telefonoFijo" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoFijo', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoTrabajo" disabled={initialValue.gestoriaRegistroInicialStatusId === 30} label="Teléfono de trabajo" holder="" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoTrabajo', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.gestoriaRegistroInicialStatusId === 30} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registrar trámite gestoría</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PagoTramitesGestoriaCard />
                {
                    initialValue.tipoTramiteId  !== null &&
                    initialValue.concluido &&
                    initialValue.tipoTramiteId === 'b69b0d46-4b86-4115-b0b8-76d7d6b20e9a' &&
                    showCard &&
                    <CambioPlacasCard />
                }
                {
                    initialValue.tipoTramiteId  !== null &&
                    initialValue.concluido &&
                    initialValue.tipoTramiteId === '71b357a6-0fcd-4058-bc16-32002a6c63fb' &&
                    showCard &&
                    <ReactivacionAltaPlacasCard />
                }
                {
                    initialValue.tipoTramiteId  !== null &&
                    initialValue.concluido &&
                    initialValue.tipoTramiteId === '140f43da-d8ae-40f7-9215-ced1f2a4333c' &&
                    showCard &&
                    <TarjetaCirculacionCard />
                }
                <EntregaGestoriaCard />
            </>
        )
    })
export default RegistroTramiteGestoriaCard