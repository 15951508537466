import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { getDateFormat, message, } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../Components/SelectField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import ModalMessage from '../../../../Components/Message/ModalMessage';
import AuthLogin from '../../../../Utils/AuthLogin';

const auth = new AuthLogin();
const SiniestroUbicacionVehiculoRoboCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();

    let initial = {
        siniestroId: id,
        ubicacionVehiculoId: "",
        unidadEncontrada: "",
        nombreUbicacion: "",
        comentarios: "",
        fechaUnicioUbicacion: "",
        horaInicio: "",
        usuario: auth.getNombreUsuario(),
        ubicacionDesignada: "",
        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 25,
        status: 2,
        procesoId: state?.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    const [modalMessage, setModalMessage] = useState(false);

    useEffect(() => {
        handleGetAllUbicacionById();
    }, []);


    /* CONSULTAS */
    const handleGetAllUbicacionById = async () => {
        setLoading(true);
        await Siniestros.GetSiniestroUbicacionById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: item?.siniestroId ?? id,
                    ubicacionVehiculoId: item?.ubicacionVehiculoId ?? "",
                    unidadEncontrada: item?.unidadEncontrada ?? "",
                    nombreUbicacion: item?.nombreUbicacion ?? "",
                    comentarios: item?.comentarios ?? "",
                    fechaInicioUbicacion: item?.fechaInicioUbicacion !== null ? item?.fechaInicioUbicacion.split("T")[0] : "",
                    horaInicio: item?.horaInicio ?? "",
                    usuario: item?.usuario ?? auth.getNombreUsuario(),
                    ubicacionDesignada: item?.ubicacionDesignada ?? "",
                    concluido: item?.concluido ?? false,
                    fechaInicio: item.fechaInicio ?? "",
                    fechaFin: item.fechaFin ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 25,
                    status: 2,
                    procesoId: state?.procesoId
                }
                setInitialValue(newData);
                handleConcluido({ tipo: 1, concluido: false, unidadEncontrada: item?.unidadEncontrada, ubicacionvehiculo: item?.concluido, requiereGrua: false, recoleccionconcluido: false, resguardoConcluido: false });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */


    /* TERMINA CATALOGOS */

    const submitForm = async (values) => {
        // if (values.dictamenRegistrado) {
        //     if (!values.subioDictamen) {
        //         toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario cargar la evidencia de Dictamen de siniestro.")}</div>);
        //         return;
        //     }
        // }
        setLoading(true);
        await Siniestros.UpdateUbicacionVehiculo(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllUbicacionById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        // ubicacionDesignada: Yup.bool()
        //     .when("concluido", {
        //         is: true,
        //         then: Yup.bool()
        //             .required("El campo es requerido.")
        //     }),
        unidadEncontrada: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        // unidadEncontrada: Yup.bool()
        //     .when(["concluido", "ubicacionDesignada"], {
        //         is: (concluido, ubicacionDesignada) => {
        //             return (concluido && ubicacionDesignada);
        //         },
        //         then: Yup.bool()
        //             .required("El campo es requerido.")
        //     }),
        // unidadEncontrada: Yup.string()
        //     .when("ubicacionDesignada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // nombreUbicacion: Yup.string()
        //     .when("unidadEncontrada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        nombreUbicacion: Yup.string()
            .when(["concluido", "unidadEncontrada"], {
                is: (concluido, unidadEncontrada) => {
                    return (concluido && unidadEncontrada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when(["concluido", "unidadEncontrada"], {
                is: (concluido, unidadEncontrada) => {
                    return (concluido && unidadEncontrada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // comentarios: Yup.string()
        //     .required("El campo es requerido."),
        // fechaInicioUbicacion: Yup.string()
        //     .when("ubicacionDesignada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        fechaInicioUbicacion: Yup.string()
            .when(["concluido", "unidadEncontrada"], {
                is: (concluido, unidadEncontrada) => {
                    return (concluido && unidadEncontrada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        horaInicio: Yup.string()
            .when(["concluido", "unidadEncontrada"], {
                is: (concluido, unidadEncontrada) => {
                    return (concluido && unidadEncontrada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        usuario: Yup.string()
            .when(["concluido", "unidadEncontrada"], {
                is: (concluido, unidadEncontrada) => {
                    return (concluido && unidadEncontrada);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // horaInicio: Yup.string()
        //     .when("ubicacionDesignada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // usuario: Yup.string()
        //     .when("ubicacionDesignada", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
    });

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eDictam/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.ubicacionVehiculoId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                modalMessage &&
                <ModalMessage
                    setIsOpen={setModalMessage}
                    isOpen={modalMessage}
                />
            }
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseLiberacionUnidad" role="button" aria-expanded="false" aria-controls="collapseLiberacionUnidad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Ubicación del Vehiculo</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseLiberacionUnidad">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        values.concluido ? values.porcentaje = 25 : values.porcentaje = 0;
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">

                                                    <div className="col-3">
                                                        <small><strong>Detalle de la ubicación</strong></small>
                                                    </div>

                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {/* <fieldset className='btn d-flex align-items-center mx-2 col-auto' onClick={() => { setModalMessage(true) }}><i className="bx bxs-message-alt me-1" ></i>Comentarios</fieldset> */}
                                                            <div className="col-auto">
                                                                <fieldset
                                                                    disabled={false}
                                                                    onClick={handleUploadFile}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Vehículo estaba en la ubicación designada?"
                                                            disabled={initialValue?.concluido}
                                                            name="ubicacionDesignada"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("ubicacionDesignada", event.value);
                                                            }} />
                                                    </div>
                                                </div> */}

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Unidad encontrada?"
                                                            disabled={initialValue?.concluido}
                                                            name="unidadEncontrada"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadEncontrada", event.value);
                                                            }} />
                                                    </div>
                                                    {
                                                        values.unidadEncontrada === true &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField disabled={initialValue?.concluido} label="Nombre de la ubicación" holder="Escribir" name="nombreUbicacion" type="text" />
                                                        </div>
                                                    }

                                                </div>

                                                {
                                                    values.unidadEncontrada &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios"
                                                                disabled={initialValue?.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    values.unidadEncontrada &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <TextField disabled={initialValue?.concluido} label="Fecha inicio" holder="Escribir" name="fechaInicioUbicacion" type="date" />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField disabled={initialValue?.concluido} label="Hora inicio" holder="Escribir" name="horaInicio" type="time" />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField disabled={true} label="Usuario" holder="Escribir" name="usuario" type="text" />
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Concluir registro</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 d-flex justify-content-end">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row flex-column align-items-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue?.fechaInicio !== "" &&
                                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue?.concluido &&
                                                                    <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SiniestroUbicacionVehiculoRoboCard