import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import InicioDeValuacionService from '../../../../Services/Inventario/Valuacion/InicioDeValuacionService'
import { toast } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import useRegistroDeGastos from '../../../../Hooks/Inventario/Valuacion/useRegistroDeGastos'
import ModalCostoGrua from './ModalCostoGrua';
import { setSchedule } from '../../../../Redux/Slice/calendarSlice';

const RecoleccionDeUnidad = ({ handleConcluido }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        handleChangeStatusBtn
    } = useRegistroDeGastos(toast);

    let initial = {
        valuacionInicio_RecoleccionUnidadId: "",
        valuacionId: "",
        folio: "",
        usuarioProgramo: null,
        grua: null,
        empresaId: "",
        empresa: null,
        gruaAsignada: null,
        referenciaUbicacion: null,
        origenTipoUbicacion: "interno",
        origenUbicacion: null,
        origenFechaRecoleccion: null,
        origenHoraRecoleccion: null,
        destinoTipoUbicacion: null,
        destinoUbiccion: null,
        fechaCita: null,
        horaCita: null,
        estatusCita: null,
        autorizarCita: false,
        concluido: false,
        valuacionStatus_SubStatusId: 0,
        fechaInicio: "",
        fechaFin: null,
        iva: 0,
        subTotal: 0,
        total: 0
    };

    let _initCosto = {
        iva: 0,
        subTotal: 0,
        total: 0
    }

    const [loading, setLoading] = useState(false);
    const [collpsableRecoleccion, setCollpsableRecoleccion] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [initCosto, setInitCosto] = useState(_initCosto);
    const [OrigenGrua, setOrigenGrua] = useState([]);
    const [Dataempresa, setDataempresa] = useState([]);
    const [tipoUbicacion, setTipoUbicacion] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { dayFirma, hourFirma, sucursalId, sucursalName, calendarioIdFirma, } = useSelector((state) => state.FirmaSlice);
    const [ubicacionSelect, setubicacionSelect] = useState([{ label: "Interno", value: "interno" }, { label: "Externo", value: "externo" }]);

    const { refFormik } = useRef();

    useEffect(() => {

        HandleGetOrigenGrua();
        handleGetEmpresa();
        handleGetTipoUbicacion();
        getAll();

        if (hourFirma) {
            console.log(hourFirma)
        } else {
            console.log("Sin firma")
        }

    }, [id, hourFirma]);

    const validate = Yup.object({
        folio: Yup.string().required("Folio is required"),
        usuarioProgramo: Yup.string().required("Program user is required"),
        //grua: Yup.string().required("Grua is required"),
        //empresa: Yup.string().required("Empresa is required"),
    });


    const HandleGetOrigenGrua = async () => {
        await InicioDeValuacionService.getOrigenGrua().then(res => {
            let OrigenGruas = []
            res.data.data?.map(item => {
                OrigenGruas.push({
                    value: item.origenGruaId,
                    label: item.origenGrua,
                    Mover: item.mover
                });
            });
            setOrigenGrua(OrigenGruas);
        })
    }

    const abrirModal = async (values) =>{
        setInitialValue(values)
        setIsOpenModal(true)
    }

    const handleGetEmpresa = async () => {
        await InicioDeValuacionService.getEmpresa()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.empresaId,
                        label: item.empresa
                    })
                });
                setDataempresa(NewData);

            }).catch(e => {

            })
    }

    const handleGetTipoUbicacion = async () => {
        await InicioDeValuacionService.getdataTipoUbicacion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.ubicacion
                    })
                });
                setTipoUbicacion(NewData)
            }).catch(e => {
            })
    }

    const formatDate = (fecha) => {
        // Verifica si la fecha está en el formato correcto
        const dateParts = fecha.split(" ")[0].split("/");
        if (dateParts.length !== 3) return fecha; // Retorna la fecha original si no está en el formato esperado

        const [month, day, year] = dateParts; // Cambia el orden para que coincida con MM/DD/YYYY
        return `${year}-${month}-${day}`; // Retorna en el formato YYYY-MM-DD
    };

    const getAll = async () => {
        setLoading(true);
        await InicioDeValuacionService.GetRecoleccionVehiculo(id)
            .then((resp) => {
                let resdata = resp.data;
                console.log(resp.data);
                const _origenFechaRecoleccion = resdata.origenFechaRecoleccion
                    ? formatDate(resdata.origenFechaRecoleccion)
                    : "";
                setInitialValue({
                    ...resdata,
                    origenFechaRecoleccion: _origenFechaRecoleccion
                });
                setInitCosto({
                    ...initCosto,
                    iva: resdata.iva ?? 0,
                    subTotal: resdata.subTotal ?? 0,
                    total: resdata.total ?? 0
                })
                handleConcluido({concluido : resdata.concluido})
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }

    const submitForm = async (values) => {
        values.subTotal = values.subTotal ?? 0
        values.total = values.total ?? 0
        values.iva = values.iva ?? 0
        values.origenTipoUbicacion = values.origenTipoUbicacion ?? "interno"
        
        values.procesoId = state.procesoId
        values.paso = 1
        values.porcentaje = 100
        values.status = 2
        setLoading(true);
        await InicioDeValuacionService.UpdCostosAdicionales(values)
            .then(resp => {
                getAll();
                handleConcluido(values)
                // if (resp.data.data.ban === 1) {
                //     toast.success(message("Correcto", resp.data.data.mensaje));
                //     getAll(1);
                // } else {
                //     toast.error(message("Error", resp.data.data.mensaje));
                // }
            })
            .catch(err => {
                console.error(err);
                //toast.error(message("¡Error, al intentar actualizar el registro de pago de trámites de unidad!"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const setCosto = async (costo) => {
        setInitialValue({ ...initialValue, iva: costo?.iva, subTotal: costo?.subTotal, total: costo?.total })
        setInitCosto({ ...initCosto, iva: costo?.iva, subTotal: costo?.subTotal, total: costo?.total })
    }

    const verCalendario = async (valor, values) => {
        dispatch(setSchedule({
            url: `inventario/valuacion/${id}/1`,
            contrato: "",
            vin: "",
            modelo: "",
            ano: "",
            marca: "",
        }));
        navigate(`/garantias/citas/${valor}`)
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4' >
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">

                        <div className="row mt-2" data-bs-toggle="collapse" href="#collapseRecoleccion" role="button" aria-expanded="false" aria-controls="collapsePagoTramites" onClick={() => setCollpsableRecoleccion(!collpsableRecoleccion)}>
                            <div className="col-11">
                                <h6> <strong>Recolección de Unidad</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                <i className={!collpsableRecoleccion ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                            <div className='col-12'>
                                <small>{initialValue.siniestroPagoTramitesStatus}</small>
                            </div>
                        </div>
                        <div className="collapse" id="collapseRecoleccion">
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    submitForm({ ...values });
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <>
                                        <Form>
                                            {/* <div className="separator"></div> */}

                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    {/* <h6><strong>Recolección de Unidad</strong></h6> */}
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue.concluido}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <TextField label="Folio" name="folio" type="text" holder="Escribe" disabled onChange={(event) => {
                                                        setFieldValue("folio", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                                    <TextField label="Usuario Programó" name="usuarioProgramo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("usuarioProgramo", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="grua"
                                                        label="Grua"
                                                        name="grua"
                                                        items={OrigenGrua}
                                                        onChange={(event) => {
                                                            setFieldValue("grua", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="empresaId"
                                                        label="Empresa"
                                                        name="empresaId"
                                                        items={Dataempresa}
                                                        onChange={(event) => {
                                                            setFieldValue("empresaId", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Grua asignada" name="gruaAsignada" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("gruaAsignada", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Costo de grua" disabled name="total" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("total", event.target.value);
                                                    }} />
                                                </div>

                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <div className='pt-4'></div>
                                                    <button
                                                        className='btn'
                                                        disabled={initialValue?.concluido}
                                                        onClick={() => abrirModal(values)}
                                                    >
                                                        Registrar costo
                                                    </button>

                                                </div>

                                            </div>

                                            <div className='pt-3'>
                                                <h6><strong>Indicar ubicación de origen</strong></h6>
                                            </div>
                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="origenTipoUbicacion"
                                                        label="Tipo de ubicación"
                                                        name="origenTipoUbicacion"
                                                        disabled
                                                        items={ubicacionSelect}
                                                        onChange={(event) => {
                                                            setFieldValue("origenTipoUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="origenUbicacion"
                                                        label="Ubicación"
                                                        name="origenUbicacion"
                                                        items={tipoUbicacion}
                                                        onChange={(event) => {
                                                            setFieldValue("origenUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Fecha de recolección" name="origenFechaRecoleccion" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("origenFechaRecoleccion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Hora de recolección" name="origenHoraRecoleccion" type="time" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("origenHoraRecoleccion", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='pt-3'>
                                                <h6><strong>Indicar ubicación de destino</strong></h6>
                                            </div>
                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="destinoTipoUbicacion"
                                                        label="Tipo de ubicación"
                                                        name="destinoTipoUbicacion"
                                                        items={ubicacionSelect}
                                                        onChange={(event) => {
                                                            setFieldValue("destinoTipoUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="destinoUbiccion"
                                                        label="Ubicación"
                                                        name="destinoUbiccion"
                                                        items={tipoUbicacion}
                                                        onChange={(event) => {
                                                            setFieldValue("destinoUbiccion", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField label="Fecha y hora de cita" name="fechaHoraCita" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaHoraCita", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-auto'>
                                                    <button
                                                        className='btn mt-4'
                                                        disabled
                                                        onClick={(e) => verCalendario(6, values)}>
                                                        Ver Calendario
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                <small className='text-body-tertiary'>Fecha Inicio: {values.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido &&
                                                                <small className='text-body-tertiary'>Fecha Fin: {values.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    </>
                                )
                                }
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>

            {
                isOpenModal &&
                <ModalCostoGrua
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    initCosto={initCosto}
                    item={initialValue}
                    toast={toast}
                    setCosto={setCosto}
                />
            }
        </>


    )
}

export default RecoleccionDeUnidad;