import React, { useState, useRef, useEffect } from 'react';
import ListadoSeguimientoTable from './ListadoSeguimiento/Listado';

const ListadoSeguimientoGestoria = () => {
    
    let initial = {
        totalRows: 0,
        data: [],
        page: 1
    }

    const [data, setData] = useState(initial);


    useEffect(() => {
        //seguimientoDatamovil();
    }, []);

    return (
        <>
            <section className='pb-4 pt-2'>
                <div className='mx-4 my-4'>
                    <ListadoSeguimientoTable data={data}/>
                </div>
            </section>
        </>
    )
}

export default ListadoSeguimientoGestoria

