import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   itemUnidadesRemate:{},
   nombreUsuario: "",
   dataCobradores: []
}
export const UnidadesEnRemateSlice = createSlice({
   name: 'UnidadesEnRemateSlice',
   initialState,
   reducers: {
      setItemUnidadesRemate:(state, action)=>{
         return {
            ...state,
            itemUnidadesRemate: action.payload
         }
      },
      setLimpiarItemUnidadesRemate:(state, action) =>{
         return {
            ...state,
            itemUnidadesRemate:{}
         }
      },
      setNombreUsuario:(state, action)=>{
         return {
            ...state,
            nombreUsuario: action.payload
         }
      },
      setLimpiarNombreUsuario:(state, action) =>{
         return {
            ...state,
            nombreUsuario:''
         }
      },
      setDataCobradores:(state, action)=>{
         return {
            ...state,
            dataCobradores: action.payload
         }
      },
      setLimpiarDataCobradores:(state, action) =>{
         return {
            ...state,
            dataCobradores:[]
         }
      },
   },
})

export const {  setItemUnidadesRemate, setLimpiarItemUnidadesRemate, setNombreUsuario, setLimpiarNombreUsuario, setDataCobradores,setLimpiarDataCobradores } = UnidadesEnRemateSlice.actions

export default UnidadesEnRemateSlice.reducer