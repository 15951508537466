import './Comentarios.css'

const DisplayComentarios = ({children,isOpen,color,width,overflow}) => {

    return (
        <>
        <article className={`modal-comments ${isOpen && "is-open"}`}>
            <div
                className='modal-comments-container'
                style={{
                    backgroundColor: `${color}`,
                    width: `${width}px`,
                    minHeight: "100vh",
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden', // Evitar overflow
                }}
            >
                {children}
            </div>
        </article>
        </>
    );
}

export default DisplayComentarios;