import { useEffect, useRef, useState } from 'react'
import Catalogo from '../../Services/Catalogos/Catalogo';
import Marca from '../../Services/Catalogos/Marca';
import TblSecundariasService from '../../Services/TablasSecundarias/TblSecundariasService';
import useModal from '../Modal/useModal';

const useTablasSecundarias = () => {
  const listInnerRef = useRef();
  const columns = [
    { field: 'tablaId', headerName: '#', width: 70 },
    { field: 'nombreTabla', headerName: 'Tabla', width: 240 },
    { field: 'nombreProceso', headerName: 'Proceso', width: 330 }
  ];

  const [data, setData] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TableId, setTableId] = useState(0);
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [dataMarca, setDataMarca] = useState([]);
  const [currentPageMarca, setcurrentPageMarca] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [initialPage, setInitialPage] = useState(0);
  const [pageRows, setpageRows] = useState(10)
  useEffect(() => {
    handleGetAllTableList();
    // hangleGetAllTables(currentPage, 10, TableId, str);
  }, [])

  useEffect(() => {
    hangleGetAllTables(1, pageRows, TableId, str);
    setInitialPage(0);
  }, [str, TableId,pageRows])

  const hangleGetAllTables = async (page, rows, IdTable, str) => {
    setLoading(true);
    await TblSecundariasService.getTables(page, rows, IdTable, str)
      .then(res => {
        
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {

        setLoading(false);
      })
  }

  const handleGetAllTableList = async () => {
    setLoading(true);
    await TblSecundariasService.getTablesList()
      .then(res => {
        setDataList(res.data.data);
        setLoading(false);
      }).catch(e => {

        setLoading(false);
      })
  }

  const handleChangeList = (value) => {
    // if(value !== "")
    setTableId(value);
  }

  const handleChange = (event) => {
    if (event.key === 'Enter') {
    setStr(event.target.value);

    }
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    hangleGetAllTables(event.selected + 1, pageRows, TableId, str);
  };

  // const handleEdit = async (item) => {
  //   
  //   await Catalogo.getdataTabla(item.nombreTablaBd,currentPageMarca,10)
  //     .then(res => {
  //       
  //       // if(page != null){
  //       //   setDataMarca([]);
  //       //   setDataMarca(res.data.data);
  //       // }else{
  //       //   setDataMarca([...dataMarca,...res.data.data]);
  //       // }
  //       setDataMarca([...dataMarca,...res.data.data]);

  //       setLoading(false);
  //       setIsOpen(true);
  //       setNextPage(res.data.hasNextPage)
  //     }).catch(e => {
  //       
  //       setLoading(false);
  //     })

  //   // if(item.nombreTablaBd){  
  //   //   handleGetListMarca();
  //   // }

  // }

  // metodos de marca
  const handleGetListMarca = async (page = null) => {

    // setLoading(true);
    if (nextPage) {
      await Marca.getdata(page === null ? currentPageMarca : page, 10)
        .then(res => {

          if (page != null) {
            setDataMarca([]);
            setDataMarca(res.data.data);
          } else {
            setDataMarca([...dataMarca, ...res.data.data]);
          }
          setLoading(false);
          setIsOpen(true);
          setNextPage(res.data.hasNextPage)
        }).catch(e => {

          setLoading(false);
        })
    }
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        setcurrentPageMarca(currentPageMarca + 1);
        handleGetListMarca();
      }
    }
  };

  return {
    columns,
    data,
    pageCount,
    dataList,
    handleChangeList,
    handleChange,
    handlePageClick,
    // handleEdit,
    loading,
    isOpen,
    setIsOpen,
    dataMarca,
    onScroll,
    listInnerRef,
    handleGetListMarca,
    setcurrentPageMarca,
    setLoading,
    currentPage: initialPage,
    setpageRows
  }
}

export default useTablasSecundarias