import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Citas from '../../../Services/Cartera/Citas';
// import SubmenuCitasAgendadas from './SubmenuCitasAgendadas';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Field, Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import Garantias from '../../../Services/Cartera/Garantias';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import BuscadorModal from '../../../Components/Modal/BuscadorModal';
import Cierres from '../../../Services/Resguardos/Cierres';

const Resguardos = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [ubicacionId, setUbicacionId] = useState("");
    const [sucursales, setSucursales] = useState([]);
    let initial = {
        buscar: "",
        fechaInicio: "",
        fechaFin: "",
        resguardoProcesoId: "",
        concluido: "",
        page: 1,
        rows: 10
    }

    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [options, setOptions] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'vin', headerName: 'VIN'},
        { field: 'contrato', headerName: 'Contrato'},
        { field: 'marca', headerName: 'Marca'},
        { field: 'modelo', headerName: 'Modelo'},
        { field: 'año', headerName: 'Año'},
        { field: 'proceso', headerName: 'Proceso'},
        { field: 'estatus', headerName: 'Estatus', width: 350 },
        { field: 'subestatus', headerName: 'Subestatus'},
        { field: 'fecha', headerName: 'Fecha'},
    ];

    useEffect(() => {
        
    }, []);

    const SeguimientoResguardos = async (values = initialValue, page = 1) => {
        values.page = page;
        if(!refFormik.current.values.concluido){
            values.concluido = false;
        }

        
        setLoading(true);

        await Cierres.seguimientoResguardos(values)
        .then(resp => {
            let tmpData = [];
            let tabsName = ['Todo'];
            resp.data.data.forEach(data => {
                if(!tabsName.includes(data.resguardoProceso) && data.resguardoProceso !== ''){
                    tabsName.push(data.resguardoProceso);
                }
                tmpData.push({
                    generalId: data.generalId,
                    resguardoId: data.resguardoId,
                    vin: data.vin,
                    contrato: data.contrato,
                    marca: data.marca,
                    modelo: data.modelo,
                    año: data.año,
                    proceso: data.resguardoProceso,
                    estatus: data.estatus,
                    subestatus: data.subestatus,
                    fecha: data.fecha,
                })
            });
            setTabs(tabsName);
            resp.data.data = tmpData;
            setData(resp.data);
            setDataToShow(resp.data);
            
            let filter = [];
            tabsName.forEach(tab => {
                if(tab.toLowerCase() === 'todo'){
                    filter['Todo'] = resp.data.data;
                }else{
                    if(!filter[tab]){
                        filter[tab] = resp.data.data.filter((item) => item.proceso === tab);
                    }
                }
            })
            setTabInfo(filter);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const handleEdit = (item) => {
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));
        // procesocierre/resguardos/resguardo/:id/:resguardo
        navigate(`/procesocierre/resguardos/${item.vin}/${item.generalId}/${item.resguardoId}`);
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        SeguimientoResguardos(initialValue, page);
    };

    const getCounter = (tab) => {
        return tabInfo[tab]?.length || 0;
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BuscadorModal 
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        {/* <SubmenuCitasAgendadas /> */}
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Seguimiento a resguardo</strong>
                                        </div>
                                        <div className="col-auto">
                                            <button className='btn' onClick={(e) => {setModalMessage({ isOpen: true, type: 3, title: 'Buscador de VIN'})}}>
                                                Registrar resguardo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-3 mr-1">
                                            <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                                setFieldValue("concluido", event.target.value);
                                            }} />
                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Incluir finalizados</label>
                                        </div>
                                        <div className="col-12 col-md-auto mt-2 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => SeguimientoResguardos(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {
                                                tabs.map((tab, idx) => {
                                                    return <li className="nav-item" key={'tab_'+idx} role="presentation">
                                                        <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        {
                                            tabIndex !== 0 && dataToShow.length > 0 &&
                                            <DataTable
                                                column={columns}
                                                data={dataToShow}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                        {
                                            tabIndex === 0 &&
                                            <DataTable
                                                column={columns}
                                                data={data?.data}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default Resguardos;
