import React, { useState } from 'react'
import Logs from '../../Services/Logs/Logs';
import { useEffect } from 'react';
import { useRef } from 'react';
import { getHourFormat } from '../../Utils/functions';

const useSistema = () => {
  const refBtn = useRef();
  const refBtnExp = useRef();
  let ids = [];
  const columns = [
    { field: 'logSistemaId', headerName: 'Id', width: 150 },
    { field: 'proceso', headerName: 'Proceso', width: 160 },
    { field: 'dateOnly', headerName: 'Fecha', width: 200 },
    { field: 'hora', headerName: 'Hora', width: 200 },
    { field: 'resultado', headerName: 'Resultado', width: 200 },
    { field: 'contenido', headerName: 'Contenido', width: 250 },
    { field: 'categoria', headerName: 'Categoria', width: 200 },
  ];

  const [data, setData] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [moduloId, setModuloId] = useState("");
  const [procesoId, setProcesoId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [isenablebtn, setIsenablebtn] = useState(true);
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [pageRows, setpageRows] = useState(10)
  useEffect(() => {
    // handleGetModulo();
    handleGetProceso();
    handleGetCategoria()
  }, [])

  useEffect(() => {
    handleGetAllSistema(1);
    setInitialPage(0);
  }, [Str, dateTime, procesoId, categoriaId,pageRows])

  //selectList
  const handleGetModulo = async () => {
    setLoading(true);
    await Logs.getList("modulo")
      .then(res => {
        
        setDataModulo(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetProceso = async () => {
    setLoading(true);
    await Logs.getList("proceso")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos los procesos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataProceso(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetCategoria = async () => {
    setLoading(true);
    await Logs.getList("categoria")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todas las categorias"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataCategoria(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllSistema = async (page) => {
    setLoading(true);
    await Logs.getLogSistema(page, pageRows, procesoId, categoriaId, Str, dateTime)
      .then(res => {
        let result = res.data.data.map(item => {
          item.hora = getHourFormat(item.hora);
          return item;
        });

        // setData(res.data.data);
        setData(result);
        setPageCount(res.data.totalPages)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetAllSistema(event.selected + 1);
  };

  const handleCheck = (cheked, value) => {
    
    if (cheked) {      
      setIdsEliminar(current => [...current, value.logSistemaId]);      
    } else {
      setIdsEliminar(idsEliminar.filter(item => item !== value.logSistemaId)); 
    }

  }
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    if (idsEliminar.length > 0) {
      let newIds = idsEliminar.join();
      await Logs.downloadFileSistema(newIds)
        .then(res => {
          var FileSaver = require('file-saver');          
          FileSaver.saveAs(res.data, `LogSistema-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

          // create file link in browser's memory
          // const href = URL.createObjectURL(res.data);

          // // create "a" HTML element with href to file & click
          // const link = document.createElement('a');
          // link.href = href;
          // link.setAttribute('download', `LogSistema-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`); //or any other extension
          // document.body.appendChild(link);
          // link.click();

          // // clean up "a" element & remove ObjectURL
          // document.body.removeChild(link);
          // URL.revokeObjectURL(href);
          setLoading(false);
          handleGetAllSistema(1);
          handleUnchekedColumns();
          refBtn.current.disabled = false;
          setIdsEliminar([])
        }).catch(e => {
          refBtn.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtn.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  const handleExportdFile = async () => {
    refBtnExp.current.disabled = true;
    if (idsEliminar.length > 0) {
      let newIds = idsEliminar.join();
      await Logs.exportFileSistema(newIds)
        .then(res => {
          var FileSaver = require('file-saver');          
          FileSaver.saveAs(res.data, `LogSistema-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
          // create file link in browser's memory
          // const href = URL.createObjectURL(res.data);

          // // create "a" HTML element with href to file & click
          // const link = document.createElement('a');
          // link.href = href;
          // link.setAttribute('download', `LogSistema-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`); //or any other extension
          // document.body.appendChild(link);
          // link.click();

          // // clean up "a" element & remove ObjectURL
          // document.body.removeChild(link);
          // URL.revokeObjectURL(href);
          setLoading(false);
          handleGetAllSistema(1);
          handleUnchekedColumns();
          refBtnExp.current.disabled = false;
          setIdsEliminar([])
        }).catch(e => {
          refBtnExp.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtnExp.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  const handleUnchekedColumns = () =>{
    let table = document.querySelectorAll('.tbl-custome >tr');
    

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  return {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleCheck,
    pageCount,
    handlePageClick,
    dataModulo,
    dataProceso,
    dataCategoria,
    setStr,
    setDateTime,
    setCategoriaId,
    setProcesoId,
    handleDownloadFile,
    isenablebtn,
    initialPage,
    refBtn,
    refBtnExp,
    handleExportdFile,
    setpageRows
  }
}

export default useSistema