import axios from '../../Utils/ApiService';

const PagoRefrendoPlacasService = {
    getAllPagoRefrendoPlacas: (page, rows, string, tipoTramiteId, statusTramiteId) => {
        return axios.get(`/gestoria/GetAllPagoRefrendoPlacas?page=${page}&Rows=${rows}&Buscar=${string}&TipoTramiteId=${tipoTramiteId}&EstatusTramiteId=${statusTramiteId}`);
    },
    addTramite: (data) => {
        return axios.post('/gestoria/AddAsyncTramite', data);
    },
    getAllPagoRefrendoPlacasSeguimiento: (page, rows, string, tipoVehiculoId, statusSeguimientoId,PagoRefrendoId) => {
        return axios.get(`/gestoria/GetAllPagoRefrendoPlacasSeguimiento?page=${page}&Rows=${rows}&Buscar=${string}&TipoVehiculoId=${tipoVehiculoId}&EstatusSeguimiendoId=${statusSeguimientoId}&GestoriaPagoRefrendoPlacasId=${PagoRefrendoId}`);
    },
    ProcesarExcel: (data) => {
        return axios.post('/gestoria/ProcersarExcelGestoria', data);
    },
    CrearGrupoPagoRefrendoPlacas: (data) => {
        return axios.post('/gestoria/CrearGrupoPagoRefrendo', data);
    },
    DesagruparPagoRefrendoPlacas: (pagoRefrendoId,vin,grupoId) => {
        return axios.delete(`/gestoria/DesagruparPagoRefrendoPlacas?GestoriaPagoRefrendoPlacasId=${pagoRefrendoId}&VIN=${vin}&GestoriaPagoRefrendoPlacasGrupoId=${grupoId}`);
    },
    ExportarPagoRefrendoPlacasSeguimiento: (string, tipoVehiculoId, statusSeguimientoId,PagoRefrendoId) => {
        return axios.get(`/gestoria/ExportarPagoRefrendoPlacasSeguimiento?Buscar=${string}&TipoVehiculoId=${tipoVehiculoId}&EstatusSeguimiendoId=${statusSeguimientoId}&GestoriaPagoRefrendoPlacasId=${PagoRefrendoId}`,{ responseType: 'blob' });
    },
    GetAllPagoRefrendoPlacasSeguimientoSeguimientoGrupo: (string, tipoVehiculoId, statusSeguimientoId,PagoRefrendoId) => {
        return axios.get(`/gestoria/GetAllPagoRefrendoPlacasSeguimientoSeguimientoGrupo?Buscar=${string}&TipoVehiculoId=${tipoVehiculoId}&EstatusSeguimiendoId=${statusSeguimientoId}&GestoriaPagoRefrendoPlacasId=${PagoRefrendoId}`);
    },   
    RealizarPagoRefrendoPlacas: (data) => {
        return axios.post('/gestoria/AddPagoRefrendoPlacas', data);
    }, 
}
export default PagoRefrendoPlacasService;