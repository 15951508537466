import React, { useEffect, useState } from 'react';
import Llamadas from '../../Services/Cartera/Llamadas';
import Garantias from '../../Services/Garantias/Garantias';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { setInfoVin } from '../../Redux/Slice/IncidenciasSlice/dataVinSlice';
import { setInfoLlamada } from '../../Redux/Slice/IncidenciasSlice/llamadaSlice';
import { setGarantiaSlice } from '../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { setTallerSlice } from '../../Redux/Slice/IncidenciasSlice/tallerSliceCatalog';
import useGarantia_ from '../../Hooks/Incidencias/useGarantia';
import Llamada from '../../Services/Cartera/Llamadas';
import { setEncabezado } from '../../Redux/Slice/Gestoria/gestoriaEncabezadoSlice';

const useGarantia = () => {
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [infoTabs, setInfoTabs] = useState(false);
    const [busquedaRealizada, setBusquedaRealizada] = useState(false);

    const { id } = useParams();
    const { idllamada } = useParams();
    const dispatch = useDispatch();
    const Garantias_ = useGarantia_();

    useEffect(() => {
        // handleGetContract(id);
        if (idllamada !== "0") {

        } else {
            dispatch(setInfoLlamada({
                Nombre: "",
                Telefono: "",
                Correo: "",
                MotivoLlamadaId: "",
                ComentariosCliente: "",
                ComentariosGarantia: "",
                FolioLlamada: ""
            }))
        }
    }, [id, idllamada])

    useEffect(() => {
        const fetchData = async () => {
                await handleGetContract(id);
                await GetGarantiaByLlamadaIdasync();
                await handlerGetLlamadaId();
                //console.log(color,"-------------------------------------")
            
        };
        fetchData();
    }, [])

    const handleGetContract = async (str) => {
        setLoading(true);
        //Llama a los servicios para la busqueda
        
        await Garantias.getContract(str?.trim())
            .then(async res => {
                if (res.data.statusCode === 204) {
                    const msgNotVin = "El VIN ingresado no se encuentra relacionado a ninguna incidencia.||Revisa el VIN o No. de contrato de tu vehículo e intenta reingresarlo nuevamente para tener acceso a las incidencias registradas.";
                    setModalMessage({ ...modalMessage, isOpen: true, type: 3, title: 'No se encontró el vehículo', message: msgNotVin });
                    return false;
                }                        
                var result = {
                    generalId: res.data.data.generalId,
                    fechaEntrega: res.data.data.fechaEntrega,
                    vin: res.data.data.vin,
                    nombreMarca: res.data.data.nombreMarca,
                    nombreModelo: res.data.data.nombreModelo,
                    nombreVersion: res.data.data.nombreVersion,
                    nombreYear: res.data.data.nombreYear,
                    color: res.data.data.color,
                    kilometraje: res.data.data.kilometraje,
                    procedenciaAuto: res.data.data.procedenciaAuto,
                    numMotor: res.data.data.numMotor,
                    numContrato: res.data.data.numContrato,
                    numSolicitud: res.data.data.numSolicitud,
                    cliente: res.data.data.cliente,
                    fechaUltimoPago: res.data.data.fechaUltimoPago,
                    producto: res.data.data.producto,
                    usoUnidad: res.data.data.usoUnidad,
                    TallerAutorizado: res.data.data.taller,
                    FolioCarnet: res.data.data.folioCarnet,
                    Expediente: res.data.data.expediente,
                    serieDatamovil : res.data.data.serieDatamovil,
                    transmision : res.data.data.transmision,
                    nombre:res.data.data.nombre,
                }        

                dispatch(setInfoVin(result))
            
                setLoading(false);
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })
    }

    const handlerGetLlamadaId = async () => {
        if (idllamada === undefined) {
            return false;
        }
        await Llamadas.getLlamadaById(idllamada)
            .then(res => {
                dispatch(setInfoLlamada({
                    Nombre: res.data.data.nombre ? res.data.data.nombre : "",
                    Telefono: res.data.data.telefono ? res.data.data.telefono : "",
                    Correo: res.data.data.correo ? res.data.data.correo : "",
                    MotivoLlamadaId: res.data.data.motivoLlamadaId ? res.data.data.motivoLlamadaId : "",
                    ComentariosCliente: res.data.data.comentariosCliente ? res.data.data.comentariosCliente : "",
                    ComentariosGarantia: res.data.data.comentariosGarantia ? res.data.data.comentariosGarantia : "",
                    FolioLlamada: res.data.data.folioLlamada
                }))
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const GetGarantiaByLlamadaIdasync = async () => {
        await Llamadas.GetGarantiaByLlamadaId(idllamada)
            .then(res => {
                if (res.data.data === null) {
                    //setContinuarGarantia(false) //Validacion del boton continuar en falso ya que aun no hay garantia
                } else {
                    //setContinuarGarantia(true) //Pone en verdadero para continuar a la pantalla de guardar garantia
                    let dataRes = res.data.data
                    const _origenGruaId = dataRes.origenGruaId


                    let data = {
                        KilometrajeActual: dataRes.kilometrajeActual,
                        CarnetServicioId: dataRes.carnetServicioId,
                        FolioCarnet: dataRes.folioCarnet,
                        ClasificacionFallaId: dataRes.clasificacionFallaId,
                        Gravedad: dataRes.gravedad,
                        TipoReporteId: dataRes.tipoReporteId,
                        FolioGarantia: dataRes.folioGarantia,
                        GeneralId: dataRes.generalId,
                        LlamadaId: dataRes.llamadaId,
                        FechaReporte: dataRes.fechaReporte,
                        Ejecutivo: dataRes.ejecutivo,
                        TipoGarantiaId: dataRes.tipoGarantiaId,
                        TipoGarantia: dataRes.tipoGarantia,
                        TipoReporte: dataRes.tipoReporte,
                        ReparacionUnidad: dataRes.reparacionUnidad,
                        StatusGarantiaId: dataRes.statusGarantiaId,
                        EstatusGarantia: dataRes.estatusGarantia,
                        UltimaActualizacion: dataRes.ultimaActualizacion,
                        EstatusIncidencia: dataRes.estatusIncidencia,
                        StatusKilometraje: dataRes.statusKilometraje,
                        Carnet: dataRes.carnet,
                        StatusCarnet: dataRes.statusCarnet,
                        Falla: dataRes.falla,
                        GravedadId: dataRes.gravedadId,
                        TallerAutorizadoId: dataRes.tallerAutorizadoId ? dataRes.tallerAutorizadoId : "",
                        FechaCita: dataRes.fechaCita ? dataRes.fechaCita : "",
                        EstatusCita: dataRes.estatusCita,
                        OrigenGruaId: _origenGruaId,
                        TallerAutorizado: dataRes.tallerAutorizado,
                        VehiculoMover: dataRes.vehiculoMover,
                        GarantiaId: dataRes.garantiaId,
                        GarantiaId: dataRes.garantiaId,
                        BloqueoId: dataRes.bloqueoId
                    }

                    dispatch(setGarantiaSlice(data))

                    Garantias_.handlerGetGruas(dataRes.garantiaId)
                }
            })
            .catch(e => {
                console.log(e)
                // if (e.response !== undefined) {
                //     //toast.error(message("¡Error.!", e.response.data.message));
                //     // console.log(e.response);
                // } else {
                //     console.log(e.response)
                //     // toast.error(message("¡Error.!", e.message));
                // }
            })
            .finally(() => {
                // if (initialValueGrua.GarantiaId !== "") {
                //handleGetGruabyBarantiaId(initialValueGrua.GarantiaId)
                // }
            });
    }

    return {
        loading,
        setLoading,
        setModalMessage,
        modalMessage,
        busquedaRealizada,
        infoTabs,
        GetGarantiaByLlamadaIdasync
    }

}

export default useGarantia