import { Formik, Form } from 'formik'
import React, { useRef, useState } from 'react'
import Modal from '../../../../Components/Modal/Modal';
import { FormatNumberMoney2, NumFolio, handleUnchekedColumns, message } from '../../../../Utils/functions';
import Spinner from '../../../../Components/Loadig';
import ComisionesService from '../../../../Services/Seguros/ComisionesService';
import ComisionesEmpresaService from '../../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
import ComisionesExternoService from '../../../../Services/Inventario/Comisiones/ComisionesExternoService';

const TotalPPComisionExternoModal = ({ isOpen, setIsOpen, handleGetAll, toast, item }) => {
    const refbtn = useRef();
    let initial = {
        folio: NumFolio(),
        totalAPagar:"",
        ids: "",
        totalVehiculos: "",
        RazonSocial:item?.[0].razonSocial ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const handleCostos = (item) => {
        let total = 0;
        item?.map(x => {
            total = total + x.calculoComision
        })
        return total;
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        setLoading(true)
        await ComisionesExternoService.ExportPendientesDePagoComisionExterno(value)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `ComisionExterno-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                handleUnchekedColumns();
                setLoading(false)
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", "Registros exportados correctamente."));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) => {
                let newId = item.map((x) => x.generalId);                    
                handleAddEdit({ ...values,totalAPagar:handleCostos(item), ids: newId.join(), totalVehiculos: item.length });                
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Generar reporte de comisiones</strong></span>
                            <span style={{ fontSize: '13px' }}>¿Estás seguro de que deseas generar el siguiente reporte de comisiones <br /> para: <strong>{values.RazonSocial}</strong>?</span>
                        </div>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    {/* <span style={{ fontSize: '14px' }}>Se creo el folio <strong>{values.folio}</strong></span> */}
                                    <span style={{ fontSize: '14px' }} className='py-3'><strong>El total de vehículos:</strong> {item.length} </span>
                                    <span style={{ fontSize: '14px' }}><strong>Monto total de comisiones calculadas:</strong> ${FormatNumberMoney2(handleCostos(item))}</span>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Generar reporte</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default TotalPPComisionExternoModal