import { useEffect, useRef, useState } from "react";
import ServicioExpedientes from "../../Services/ProcesosCierre/Expedientes";
import toast from "react-hot-toast";
import { getOnlyDateFormat, message } from "../../Utils/functions";
import { useNavigate } from "react-router-dom";

const SeguimientoExpedienteHook = () => {
    const navigate = useNavigate();
    const refFormik = useRef();
    const initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState("");
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const columns = [
        { field: 'folio', headerName: 'Folio' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'usuarioSolicito', headerName: 'Usuario Solicito' },
        { field: 'motivoAmpliacion', headerName: 'Motivo' },
        { field: 'fechaSolicitud', headerName: 'Fecha Solicitud' },
        { field: 'fechaRecuperacion', headerName: 'Fecha Recuperación' },
        { field: 'vigencia', headerName: 'Días de Vigencia' },
        { field: 'EstatusUI', headerName: 'Estatus' },
        { field: 'fechaActualiza', headerName: 'Último Movimiento' },
    ];

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [page, rows, buscar]);


    const getData = async (values) => {
        const params = {
            buscar,
            page,
            rows
        }

        setLoading(true);
        await ServicioExpedientes.getSeguimientoExpedientes(params)
        .then(resp => {
            const items = resp.data.data.map(item => {
                item.EstatusUI = {
                    text: item.cierreContratoExpedienteStatus,
                    backgroundColor: item.fondoHex,
                    textColor: item.textoHex
                }
                item.fechaSolicitud = item.fechaSolicitud ? getOnlyDateFormat(item.fechaSolicitud) : '-';
                item.fechaRecuperacion = item.fechaRecuperacion ? getOnlyDateFormat(item.fechaRecuperacion) : '-';
                item.fechaActualiza = item.fechaActualiza ? getOnlyDateFormat(item.fechaActualiza) : '-';
                item.vigencia = '∞';
                return item;
            });
            setData(items);
            setTotalRows(resp.data.totalRows);
        })
        .catch(err => {
            toast.error(message('Error al obtener el listado'));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getOnlyDateFormat = (date) => {
        if(date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    };

    const handlePageClick = (numPage) => {
        setPage(numPage.selected + 1);
    }

    const handleDetail = (item) => {
        navigate(`/procesocierre/expediente/${item.cierreContratoExpedienteId}`, {state: item});
    }

    return {
        refFormik,
        initialValue,
        loading, 
        setLoading,
        columns,
        data,
        page,
        totalRows,
        rows,
        setRows,
        setBuscar,
        handlePageClick,
        handleDetail
    }
}

export default SeguimientoExpedienteHook;