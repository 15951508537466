import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   idDataDocs: "",
   archivosDataDocs:[]
}
export const ElementosDataDocsSlice = createSlice({
   name: 'ElementosDataDocsSlice',
   initialState,
   reducers: {
      setIdDataDocs:(state, action)=>{
         return{
            ...state,
            idDataDocs: action.payload
         }
      },
      setLimpiarIdDataDocs:(state, action)=>{
         return{
            ...state,
            idDataDocs: ""
         }
      }, 
      setArchivosDataDocs:(state, action)=>{
         return{
            ...state,
            archivosDataDocs: action.payload
         }
      },
      setLimpiarArchivosDataDocs:(state, action)=>{
         return{
            ...state,
            archivosDataDocs: action.payload
         }
      }
   },
})

export const { setIdDataDocs, setLimpiarIdDataDocs, setArchivosDataDocs, setLimpiarArchivosDataDocs } = ElementosDataDocsSlice.actions

export default ElementosDataDocsSlice.reducer