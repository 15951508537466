import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Webcam from 'react-webcam';
import { Form, Formik } from 'formik';
import { TextField } from '../../../../Components/TextField';
import dataDocsServices from '../../../../Services/DataDocs/dataDocsServices';
import AuthLogin from '../../../../Utils/AuthLogin';
import toast from 'react-hot-toast';
import { message } from '../../../../Utils/functions';
import axios from 'axios';
import ConfigDataDocsService from '../../../../Services/ConfigDataDocs/ConfigDataDocsService';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { useSelector } from 'react-redux';

const auth = new AuthLogin();
const SubirDocumentoModal = ({ contrato_id, archivoId, isOpen, setIsOpen, cardId, setConfirmarOperacion, setNombreDocumento }) =>
{
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [foto, setFoto] = useState(false);
  const [textoOpcion, setTextoOpcion] = useState("Tomar Foto")
  const [loading, setloading] = useState(false);
  // const [actions, setActions] = useState({ deleted: false, downloaded: false, replazed: false, continue: false, acendes: false })
  let url = auth.UrlDataDocs();
  // const [tipoSeleccionado, settipoSeleccionado] = useState({})
  const [tipoDocumento, setTipoDocumento] = useState({})
  const [dataTiposDocumento, setDataTiposDocumento] = useState([])
  const { dataVisitaDomiciliaria, dataRecolecionUnidad  } = useSelector((state) => state.TipoDocumentoSlice)

  useEffect(()=>{
    if(cardId===1){
      setDataTiposDocumento(dataVisitaDomiciliaria)
    }
    else if(cardId===2){
      setDataTiposDocumento(dataRecolecionUnidad)
    }
  },[])
  
  let initial = {
    nombre_archivo: "",
    tipoDocumento: ""
  }

  const [initialValue, setInitialValue] = useState(initial); 
  const validate = Yup.object({
    nombre_archivo: Yup.string()
      .required("El campo es requerido."),
    tipoDocumento: Yup.string()
      .required("El campo es requerido."),
  })

  const capture = () =>
  {
    if (webcamRef.current)
    {
      setFoto(true);
      setTextoOpcion("Intentar de nuevo")
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      setIsPreview(true); // Set preview mode
    }
  };

  const confirmImage = (values) =>
  {
    if (capturedImage)
    {
      // Convert base64 image to byte array
      /*const byteArray = base64ToByteArray(capturedImage);
      let bytes = new Uint8Array(byteArray.length);

      for (let i = 0; i < bytes.length; i++) {
        bytes[i] = byteArray.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: 'image/jpeg' });
      const captura = new File([blob], `${values.nombre_archivo}.jpg`, { type: 'image/jpeg' });*/
      const captura = base64ToFile(capturedImage, `${values.nombre_archivo}.jpg`);

      const item = {
        dataDocsId: 6,
        ficheroId: "fCobranza",
        filename: `${values.nombre_archivo}.jpg`,
        origen: `${tipoDocumento.origen}-${contrato_id}`,
        origenId: archivoId
      }
      handleGetFolderDataDocs(`${values.nombre_archivo}.jpg`, item, captura)
    }
  };

  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined && e.response.data !== "") {
          if (e.response.data?.errors.error !== undefined) {
            handleSaveLogs("Subir", "Insertar", url, e.response.data.errors.error);
            toast.error(message("Error.!", e.response.data.errors.error));
          }
          else {
            handleSaveLogs("Subir", "Insertar", url, e.response.data?.errors);
            toast.error(message("Error.!", e.response.data?.errors));
          }
        }
        else {
          handleSaveLogs("Subir", "Insertar", url, e.message);
          toast.error(message("Error.!", e.message));
        }
      })
  }

  const handleGetFolderDataDocs = async ( filename, item, captura) => {
    console.log(captura)
    await handleGetTokenDatadocs();
    await ConfigDataDocsService.CreateFolderDataDocs(item)
      .then((res) => {
        if (res.data.data.ban === 1) {
          handleSubmit(filename, captura, res.data.data.mensaje, item.origenId, item);
        } else {
          toast.error(message("Error DataDocs!", "Error al crear el Folder."));
        }
      }).catch((e) => {
        console.log(e)
      })
  }

  const handleSaveLogs = async (modulo, categoria, descripcion, error) => {
    setloading(true);
    let data = {
      modulo: modulo,
      categoria: categoria,
      descripcion: descripcion,
      contenidoError: error
    }
    await dataDocsServices.LogsDatadocs(data)
      .then(res => {
        setloading(false);
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("¡Correcto.!", e.response.data.message));
        else
          toast.error(message("¡Correcto.!", e.message));
      })
  }

  const handleSubmit = async (name, captura, foliocarpeta, estadoid, action) => {
    setNombreDocumento(name);
    setloading(true);
    await handleGetTokenDatadocs();
    const formData = new FormData();
    formData.append('TipoDocumentoId', tipoDocumento.value);
    formData.append('Nombre', name);
    formData.append('ParametroId', "1");
    formData.append('ParametroValor', contrato_id);
    formData.append('File', captura);
    formData.append('Folio', foliocarpeta);
    formData.append('Activo', true);
    formData.append('Original', true);
    formData.append('RevisionCalidad', true);
    
    await axios.post(`${url}Documento`, formData, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        "Accept": "*/*",
      }
    }) 
      .then(res => {
        handleSaveInfoDataDocs(
        {
          nombreDocumento: name,
          binarioId: tipoDocumento.folder,
          documentoId: res.data,
          dataDocsArchivoId: tipoDocumento.value,
          idFolder: foliocarpeta,
          idArchivo: estadoid
        }, action);
        toast.success(message("¡Correcto.!", "Archivo guardado correctamente."));
        console.log(res)
        setIsOpen(false)
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined) {
          if (e.response.data?.errors?.error !== undefined) {
            handleSaveLogs("Subir", "Insertar", url, e.response?.data.errors.error);
            toast.error(message("Error.!", e.response?.data?.errors.error));
          }
          else {
            handleSaveLogs("Subir", "Insertar", url, e.response?.data.errors);
            toast.error(message("Error.!", e.response?.data.errors));
          }
        }
        else {
          handleSaveLogs("Subir", "Insertar", url, e.message);
          toast.error(message("Error.!", e.message));
        }
      })
  };

  const handleSaveInfoDataDocs = async (data, action = null) => {
    // setloading(true);
    await ConfigDataDocsService.SaveInfoDataDocs(data)
      .then(res => {
        if (res.data.data.ban === 1) {
          setConfirmarOperacion("Completado")
            setloading(false);
        } else {
          toast.error(message("Error!", res.data.data.mensaje));
          setloading(false);
        }
      }).catch(e => {
        setloading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.message));
        else
          toast.error(message("Error.!", e.message));
      })
  }

  const base64ToByteArray = (base64) =>
  {
    console.log(base64);
    const base64String = base64.split(',')[1]; // Remove metadata
    return base64String; // Return the base64 string instead of a byte array for JSON
  };

  const base64ToFile = (dataurl, filename) => {
    var arr = dataurl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[arr.length - 1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  const retakeImage = () =>
  {
    setFoto(false)
    setTextoOpcion("Tomar Foto")
    setCapturedImage(null);
    setIsPreview(false); // Exit preview mode
  };

  return (
    <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={isOpen} color='#fff'>
      <div>
        <div className="d-flex justify-content-end" >
          <i className="icon-black ri-close-fill" onClick={()=>setIsOpen(false)}></i>
        </div>
        {!isPreview ? (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={400}
              />
            </>
        ) : (
            <>
              <img src={capturedImage} alt="Captured Preview" style={{ marginTop: '10px' }} />
            </>
        )}
    </div>
    <Formik
      enableReinitialize={true}
      validationSchema={validate}
      initialValues={initialValue}
      onSubmit={(values) => {
        confirmImage(values);
    }}>
      {({ isValid, values, setFieldValue, errors }) => (
        <>
        <div style={{width: "500px", height:"auto"}}>
          <Form>
            <TextField label="Descripción" type="text" name="nombre_archivo" holder="Nombre del archivo" onChange={(e)=>{setFieldValue("nombre_archivo",e.target.value)}}/>
            <div className="mb-3 row" >
              <div className="col-12">
                <SelectField
                  id="tipoDocumento"
                  label="Resultado"
                  name="tipoDocumento"
                  items={dataTiposDocumento}
                  onChange={(event) => {
                    setFieldValue("tipoDocumento", event.value === "" ? "" : event.label);
                    console.log(event)
                    if(event.value === ""){
                      setTipoDocumento({})
                    }
                    else{
                      setTipoDocumento(event)
                    }
                }}/>
              </div>
            </div>
            <section className='d-flex justify-content-center mt-3'>
              <button id="AT_btn" className='btn btn-outline col-6 mw-100' type='button' onClick={() => { 
                if(foto){
                  retakeImage()
                }
                else{
                  capture()
                }
              }}>{textoOpcion}</button>
              <button id="AT_btnGuardar" className='btn mx-2 col-6 mw-100' type="submit" disabled={!(isValid) || !(foto)}>Confirmar</button>
            </section>
          </Form>
        </div>
        </>
      )}
    </Formik>
    </Modal>
    </>
  );
};

export default SubirDocumentoModal;