import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const NavigateCobradoresSlice = createSlice({
  name: 'NavigateCobradoresSlice',
  initialState,
  reducers: {
    tabCobranzaMenu: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }
  },
})

export const { tabCobranzaMenu } = NavigateCobradoresSlice.actions

export default NavigateCobradoresSlice.reducer