import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import toast from "react-hot-toast";
import { FormatFecha, message } from "../../../../Utils/functions";

const ReferenciasDetalle = () => {
   const navigate = useNavigate();
   const {contratoActual} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([])
   const [currentData, setCurrentData] = useState([])
   const [disableInteractions, setDisableInteractions] = useState(false)

   const columns = [
      { field: 'relacion_solicitante', headerName: 'Relación con solicitante', width: 210 },
      { field: 'nombre_ref', headerName: 'Nombre', width: 150 },
      { field: 'apellido', headerName: 'Apellido', width: 150 },
      { field: 'direccion', headerName: 'Domicilio principal', width: 550 },
      { field: 'direccionadicional', headerName: 'Direcciones adicionales', width: 100 },
      { field: 'interacciones', headerName: 'Interacciones', width: 100 },
      { field: 'ultimainteraccion', headerName: 'Última interacción', width: 150 }
   ]

   const columnsDetail = [
      { field: 'tipo_contacto', headerName: 'Tipo de contacto', width: 210 },
      { field: 'subtipo_contacto', headerName: 'Subtipo', width: 150 },
      { field: 'dato', headerName: 'Valor', width: 180 },
      { field: 'fecha_operacion', headerName: 'Última interacción', width: 200 },
      { field: 'interacciones', headerName: 'Interacciones', width: 180 },
      { field: 'validacion', headerName: 'Exitoso', width: 100 },
      { field: 'status', headerName: 'Estatus', width: 180 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 }
   ]

   useEffect(()=>{
      HandleGetInformacionReferencias()
   },[])

   useEffect(()=>{
      if(data.length > 0){
         getAllDataContactos()
      }
   },[data])

   const HandleGetInformacionReferencias = async () => {
      const data = await GetInformacionContactos()
      const promesasRotasField =
      {
         FIELD: "ID",
         VALUE: "LLAMADAS_CONSECUTIVAS_NIVEL_1"
      }

      const responseLlamadasConsecutivas = await CobranzaService.getAllParametrosByField(promesasRotasField)
      const maxIterations = Number(responseLlamadasConsecutivas.data.responseData[0].valorpublico)

      const updateResults = await Promise.all(
         data.map(async (item) => {
            const updateItem = await AddInteraccionesReferencia(item)
            if ((updateItem > maxIterations) && (item.tipo === "Cliente")){
               setDisableInteractions(true)
            } 
            item.interacciones = updateItem
            let nombre = `${item.apellido_paterno_ref} ${item.apellido_materno_ref}`

            return {
               ...item,
               nombre: nombre,
               apellido: `${item?.apellido_paterno_ref} ${item?.apellido_materno_ref}`,
               direccion: `Calle ${item?.calle_ref} No.Ext ${item?.no_ext_ref} No.Int ${item?.no_int_ref}, Estado ${item?.estado_ref} - Municipio ${item?.municipio_ref} - Colonia ${item?.colonia_ref}`,
            }
         })
      )
      setData(updateResults)
   }

   const GetInformacionContactos = async () => {
      const data = {
         sol_id: contratoActual?.sol_id,
         contrato: contratoActual?.contrato_id,
         vin: contratoActual?.vin
      }

      let result = await CobranzaService.getInformacionContactos(data)
         .then(res => {
            let datosFormateados = res?.data?.responseData?.datos_referencia?.map((item) => {
                  if (item) {
                     let domicilio = `${item?.calle_ref} ${item?.no_ext_ref} ${item?.colonia_ref}`
                     return {
                        ...item,
                        domicilio_ref: domicilio,
                        filter: false
                     }
                  }
            })
            return datosFormateados
         }).catch(e => {
            setLoading(false);
            if (e.response !== undefined)
                  toast.error(message("Error.!", e.response.data.errors?.error));
            else
                  toast.error(message("Error.!", e.message));
         })

      return result
   }

   const getAllDataContactos = async () =>{
      setLoading(true)
      let FilterData = []
      let updateData = await Promise.all( data.map(async(item)=>{
         let details = await HandleGetInformacionContactos(item)
         if(details.length > 0){
            for (const newItem of details) {
               const interaccion = await AddInteracciones(newItem.idcontacto_acendes)
               const validacion = await AddValidation(newItem.idcontacto_acendes)
               newItem.interacciones = interaccion
               newItem.validacion = validacion
            }
         }
         return{
            ...item,
            detail:details
         }
      }))

      updateData?.map(item => {
         if(item?.tipo?.toLowerCase() ===  "referencia"){
            FilterData.push(item)
         }
      })

      setCurrentData(FilterData)
      setLoading(false)
   }

   const HandleGetInformacionContactos = async (persona) => {
      const data = {
         sol_id: contratoActual?.sol_id,
         contrato: contratoActual?.contrato_id,
         vin: contratoActual?.vin,
         idpersona_acendes: persona?.idreferencia,
         tipo_persona: persona?.tipo,
         curp: persona?.curp_ref,
      }

      let NewData = await CobranzaService.getDatosdeContactos(data)
         .then(res => {
               if(res.data.callSucceded){
                  let data = res?.data?.responseData?.datos_contacto || []
                  let dataFormato = data?.map((item) => {
                     if (item) {
                        let comentariosToolTip = item.comentario
                        let status = item?.activo ? "Activo" : "Inactivo"
                        let fechaOperacion = FormatFecha(item.fecha_operacion)
                        return{
                           ...item,
                           fecha_operacion: fechaOperacion,
                           comentariosTooltip:comentariosToolTip,
                           status: status,
                        }
                     }
                  })
                  return dataFormato
               }
         })
         .catch(e=>{
            setLoading(false)
            console.log(e)
         })
         return NewData
   }


   const AddInteraccionesReferencia = async (item) => {
      let data = {
         field: "id_referencia_ascendes",
         value: item.idreferencia
      }
      try {
         const response = await CobranzaService.getRowsIteractions(data)
         return response?.data?.responseData; // Devuelve los datos de la respuesta
      } catch (e) {
         setLoading(false);
         if (e.response !== undefined)
            toast.error(message("Error.!", e.response.data.errors?.error));
         else
            toast.error(message("Error.!", e.message));
      }
   };

   const AddInteracciones = async (idcontacto_acendes) => {
      let cantidad = 0
      let data = {
         field: "id_contacto_ascendes",
         value: idcontacto_acendes
      }
      await CobranzaService.getRowsIteractions(data)
         .then(res=>{
            if(res?.data?.responseData !== null){
               cantidad = res?.data?.responseData
            }
         })
         .catch(e=>{
            setLoading(false)
            console.log(e)
         })
      
      return cantidad
   };

   const AddValidation = async (idcontacto_acendes) => {
      let validado = "No"
      let data = {
         contrato: contratoActual?.contrato_id,
         id_contacto_ascendes: idcontacto_acendes
      }
      await CobranzaService.getValidacionTipoResultado(data)
      .then(res=>{
         if(res?.data?.validacion && res?.data?.validacion !== null){
            validado = res?.data?.validacion? "Si" : "No"
         }
      })
      .catch(e=>{
         setLoading(false)
         console.log(e)
      })

      return validado;
   };


   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                    <h2 className="fw-bold ">Consulta de referencias</h2>
                              </div>
                           </div>
                           {/* <div className="row gap-2">
                              <div className="col col-12">
                                    <div className="row mt-4">
                                       <div className="col col-3">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                                </div>
                                          </div>
                                       </div>
                                       <div className="col col-3">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                                </div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                  <div className="px-4">
                     <p className="pt-2" >Detalle de las referencias registradas</p>
                     <DataTable
                        column={columns}
                        data={currentData}
                        masterDetail={true}
                        columnDetail={columnsDetail}
                     />
                  </div>
                  <div className="mt-3 mb-3 row"><span> </span></div>
               </div>
            </div>
         </div>
      </>
   );
}

export default ReferenciasDetalle;