import React from 'react'
import Garantias from '../../Services/Garantias/Garantias';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setInfoVin } from '../../Redux/Slice/IncidenciasSlice/dataVinSlice';
import { setGarantiaSlice } from '../../Redux/Slice/IncidenciasSlice/garantiaSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Garantia from '../../Services/Garantias/Garantias';
import Llamada from '../../Services/Cartera/Llamadas';


const useCambioDevolucion = () => {
    const [str, setStr] = useState("");
    const [vehiculo, setVehiculo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    
    const { id, idllamada } = useParams();
    const datos = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        getDataVehicle();
        getGarantia();
    }, []);

    const getDataVehicle = async () => {
        await Garantia.getContract(id)
        .then(resp => {
            const data = resp.data.data;
            dispatch(setInfoVin(data));
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    };

    const getGarantia = async () => {
        if(idllamada === undefined){
            return false;
        }
        
        await Llamada.GetGarantiaByLlamadaId(idllamada)
        .then(resp => {
            const data = resp.data.data;
            dispatch(setGarantiaSlice({
                GarantiaId: data.garantiaId,
                FolioGarantia: data.folioGarantia,
                Gravedad: data.gravedad,
                TipoReporte: data.tipoReporte,
                Falla: data.falla
            }));
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));

    }
    

    return {
        loading,
        setModalMessage,
        modalMessage,
    }

}

export default useCambioDevolucion