import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroSinRenovarService from '../../Services/Seguros/SeguroSinRenovarService';

const useVehiculosSeguroRenovado = () => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'tipo', headerName: 'Tipo', width: 110 },
    { field: 'numeroPoliza', headerName: 'Póliza', width: 110 },
    { field: 'strFechaCompromiso', headerName: 'Fecha Compromiso', width: 150 },
    { field: 'usoUnidad', headerName: 'Uso Unidad', width: 110 },
    { field: 'nombreMarca', headerName: 'Marca', width: 150 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'usuario', headerName: 'Usuario', width: 150 },
    { field: 'strFechaRegistro', headerName: 'Fecha de registro', width: 170 },
  ];
  const tipo = [
    { label: "Todos", value: "" },
    { label: "Contrato", value: "Contrato" },
    { label: "Utilitario", value: "Utilitario" },
  ];
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  //Filtro
  const [DataTipo, setDataTipo] = useState(tipo);
  const [tipoCompra, settipoCompra] = useState([]);
  const [DataUsoUnidad, setDataUsoUnidad] = useState([])
  const [DataSocioComercial, setDataSocioComercial] = useState([])
  const [claveTipoCompra, setclaveTipoCompra] = useState("");
  const [claveVendedor, setClaveVendedor] = useState("");
  const [pageRows, setpageRows] = useState(10);
  const [fechInicio, setFechInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [ClaveUsoUnidad, setClaveUsoUnidad] = useState("")
  const [Tipo, setTipo] = useState("")
  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [pageRows, claveTipoCompra, ClaveUsoUnidad, str, Tipo, fechInicio, fechaFin])
  useEffect(() => {
    handleGetAllSelects();
  }, [])

  const handleGetAllSelects = async () => {
    setLoading(true)
    const [] = await Promise.all([handleGetAllUsoUnidad(), handleGetTipoCompra()]).finally(() => {
      setLoading(false)
    });
  }
  const habdleGetAll = async (page) => {
    setLoading(true);
    await SeguroSinRenovarService.GetAllVehiculosSeguroRenovado(page, pageRows, claveTipoCompra, ClaveUsoUnidad, fechInicio, fechaFin, str, Tipo)
      .then(res => {
        console.log(res.data.data)
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  const handleGetTipoCompra = async () => {
    await SeguroPolizaService.getAllTipocompra()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        settipoCompra(NewData);
      }).catch(e => {
      })
  }
  const handleGetAllUsoUnidad = async () => {
    await SeguroSinCotizarService.getlAllUsoUnidad()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.usoUnidadId,
            label: item.usoUnidad
          })
        });
        setDataUsoUnidad(NewData);
      }).catch(e => {

      })
  }
  const habdleDownloadFile = async () => {
    setLoading(true);
    await SeguroSinRenovarService.ExportVehiculosSeguroRenovado(claveTipoCompra, ClaveUsoUnidad, fechInicio, fechaFin, str, Tipo)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `VehiculosRenovados-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        habdleGetAll(1);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleDetail = (value) => {
    navigate(`/seguro/renovacionseguro/segurofinal/${value.generalId}`, {
      state: { ...value, action: 2,editable:false },
    });

  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };

  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    setpageRows,
    habdleDownloadFile,
    DataSocioComercial,
    tipoCompra,
    setclaveTipoCompra,
    setClaveVendedor,
    setFechInicio,
    setFechaFin,
    DataTipo,
    DataUsoUnidad,
    setClaveUsoUnidad,
    setTipo,
    handleDetail
  }
}

export default useVehiculosSeguroRenovado