import axios from '../../Utils/ApiService';

const ClavesServices = {
    // ClaveManual
    sendClaveManual: (data) => {
        return axios.post(`/cartera/ClaveManual`, data);
    },
    getSeguimientoClaves: ({ buscar, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `buscar=${buscar}&`;

        query += `page=${page}&rows=${rows}`;
        return axios.get(`/cartera/seguimientoLecturaClaves?${query}`);
    },
    updateClaveManual: (data) => {
        return axios.put(`/cartera/ClaveManual`, data);
    }
};

export default ClavesServices;