import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../Utils/functions';
import CancelacionSeguroService from '../../Services/Seguros/CancelacionSeguroService';

const useSeguroSinPago = (toast) => {
    const navigate = useNavigate();
    const refBtn = useRef();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'numeroPoliza', headerName: 'Póliza', width: 150 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 150 },
        { field: 'estatus', headerName: 'Estatus', width: 150 },
        { field: 'precioStr', headerName: 'Precio', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso Unidad', width: 100 },
        { field: 'nombreMarca', headerName: 'Marca', width: 100 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'strFechaInicio', headerName: 'Fecha Inicio', width: 100 },
        { field: 'etapaCancelacion', headerName: 'Etapa cancelación', width: 100 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [idsEliminar, setIdsEliminar] = useState([])
    //DataDocs
    const [isOpenModalMotivo, setIsOpenModalMotivo] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
        setIdsEliminar([])
    }, [str, TipoCompra, claveUsoUnidad, pageRows, Aseguradora])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();
        handleGetAllAseguradora();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await CancelacionSeguroService.getAllSeguroSinPago(page, pageRows, str, claveUsoUnidad, TipoCompra, Aseguradora ?? "")
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            }).finally(() => {
                setTimeout(() => {
                    if ((Aseguradora ?? "") !== "") {
                        handleIsActiveCheck(true);
                    } else {
                        handleIsActiveCheck(false);
                    }
                }, 500);

            })
    }
    const handleUploadFile = async () => {
        if (item !== null) {
            navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
                state: { ...item, emisionPoliza: true },
            });
        } else {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione un registro para continuar.")}</div>);
        }

    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {
        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });
    }

    const handlechekedItem = (cheked, value, i) => {
        if (cheked) {
            setIdsEliminar(current => [...current, value.generalId]);
        } else {
            setIdsEliminar(idsEliminar.filter(item => item !== value.generalId));
        }
    }
    const handleProcesarSSP = () => {
        // refBtn.current.disabled = true;
        if (idsEliminar.length > 0) {
            setIsOpenModalMotivo(true);
        } else {
            //     refBtn.current.disabled = false;
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros a eliminar")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }

    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenModalMotivo,
        isOpenModalMotivo,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        setAseguradora,
        DataAseguradora,
        handleProcesarSSP,
        refBtn,
        Aseguradora,
        idsEliminar,
        selectRef
    }
}

export default useSeguroSinPago