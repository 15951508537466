import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useSeguimientosiniestro from '../../../Hooks/Seguros/useSeguimientoSiniestro'
import ModalBusquedaSiniestro from './modalBusquedaSiniestro'
import RowPages from '../../../Components/RowPages'
import ErrorModal from '../../../Components/Modal/ErrorModal'
import SuccessModal from '../../../Components/Modal/SuccessModal'
import BuscadorSiniestroVINModal from '../../../Components/Modal/BuscadorSiniestroVINModal'
const SeguimientoSiniestrosList = () => {

    const [isOpenModal, setIsOpenModal] = useState(false);

    const {
        columns,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        selectRef,
        handleDetail,
        dataToShow,
        setOpenModalAvanzado,
        openModalAvanzado,
        setDataFiltro,
        dataFiltro,
        dataTipoSiniestro,
        filtroStatus,
        setModalMessage,
        modalMessage,
        seguimientoSiniestros,
        selectRefStatus
    } = useSeguimientosiniestro(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Seguimiento a siniestros</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro).Agregar}>
                        <div className="col-auto">
                            <button className='btn' onClick={(e) => { setIsOpenModal(true) }}>
                                Registrar siniestro
                            </button>
                        </div>
                    </ValidatePermission>
                </div>

            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" value={dataFiltro.buscar} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDataFiltro((prev)=>({ ...prev, buscar: e.target.value })) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={(el) => (selectRefStatus.current.select1 = el)} options={dataTipoSiniestro} className='col-12' onChange={(e) => { setDataFiltro({ ...dataFiltro, tipoSiniestroId: e.value }) }} placeholder="tipo de siniestro" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={(el) => (selectRefStatus.current.select2 = el)} options={filtroStatus} className='col-12' onChange={(e) => { setDataFiltro({ ...dataFiltro, estatus: e.value }) }} placeholder="Estatus" />
                        </div>
                        <div className="col-12 col-md-auto my-1 px-1">
                            <button className='btn btn-blue me-2' onClick={() => { setOpenModalAvanzado(true) }}>
                                Búsqueda Avanzada
                            </button>
                            <button className='btn btn-blue' onClick={() => {
                                Object.values(selectRefStatus.current).forEach(select => {                                    
                                    if (select) {                                        
                                        select?.setValue(""); // Limpiar el valor solo si clearValue está disponible
                                    }
                                });                                                          
                                setDataFiltro((prev) =>({        
                                    ...prev,  
                                    buscar:"",
                                    page: 1,
                                    rows: 10,
                                    tipoSiniestroId: "",
                                    estatus: "",
                                    //Filtor vanzado
                                    strAvanzado: "",
                                    fechaInicio: "",
                                    fechaFin: "",
                                    concluido: false,
                                    busquedaAvanzada: false
                                }));                                
                            }}>
                                Limpiar filtro
                            </button>
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={(value) => { setDataFiltro({ ...dataFiltro, rows: value }) }}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        iswidth={true}
                        data={dataToShow}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        detailable={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro)?.Ver}
                        handleDetail={handleDetail}
                    />
                </div>
            </ValidatePermission>
            {
                openModalAvanzado &&
                <ModalBusquedaSiniestro
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
                />
            }
            {
                isOpenModal &&
                <BuscadorSiniestroVINModal
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                //handleGetAll={() => { seguimientoSiniestros() }}
                />
            }
        </>
    )
}

export default SeguimientoSiniestrosList