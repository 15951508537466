import React from 'react'
import Garantias from '../../Services/Cartera/Garantias';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { setBoletinadoSlice } from '../../Redux/Slice/Devoluciones/boletinadoSlice';
import Catalogo from '../../Services/Catalogos/Catalogo';


const useBoletinado = () => {
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const { cambioDevolucionId } = useSelector((state) => state.DevolucionesSlice);
    
    
    const tipoOperacion = [{ value: 0, label: 'Cambio Unidad' }, { value: 1, label: 'Devolucion' }];
    const [Motivos, setMotivos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getMotivosBoletinado();
    }, []);

    useEffect(() => {
        getBoletinado();
    }, [cambioDevolucionId]);

    const getBoletinado = async () => {
        if(cambioDevolucionId === undefined || cambioDevolucionId === ""){
            return false;
        }
        await Garantias.boletinadoGet(cambioDevolucionId)
        .then(resp => {
            dispatch(setBoletinadoSlice(resp.data))
        })
        .catch(err => {
            console.error(err);
        });
    }

    const getMotivosBoletinado = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CambioDevolucionBoletinadoMotivo')
        .then(resp => {
            let items = [];
            resp.data.forEach(item => {
                items.push({
                    value: item.CambioDevolucionBoletinadoMotivoId,
                    label: item.CambioDevolucionBoletinadoMotivo
                })
            })
            setMotivos(items);
        })
        .catch(err => {

        });
    }

    return {
        Motivos, 
        getBoletinado
    }

}

export default useBoletinado