import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import ExpedienteCard from './ExpedienteCard';
import { clearHour } from '../../../Redux/Slice/firmaSlice';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';

const ProcesoFirmaCard
    = React.forwardRef(({ ...props }, ref) => {
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const { id, generalid, cierreid } = useParams();

        
        let initial = {
            cierreContratoFirmaId: "",
            cierreContratoId: cierreid,
            cierreContratoFirmaStatusId: 0,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            entregaPapeleria: false,
            comentarios: "",
            fechaActualiza: "",
            firmado: false,
            fechaFirma: "",
            fechaConclusion: "",
            concluido: false,
            ine: null,
            origen: "",
            origenId: "",
            status: false,
            contratoDescargado: false,
            terminacionDescargado: false,
            reciboDescargado: false,
            cierreContratoFirmaStatus: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [showExpediente, setShowExpediente] = useState(false);

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getProcesoFirma();
        }, []);

        /* CONSULTAS */
        const getProcesoFirma = async () => {
            await Cierres.cierreContratoFirmaGet(cierreid)
            .then(resp => {
                resp.data.entregaPapeleria = resp.data.entregaPapeleria === null ? null : (resp.data.entregaPapeleria ? 1 : 0)
                resp.data.ine = resp.data.ine === null ? null : (resp.data.ine ? 1 : 0)
                if(resp.data.cierreContratoFirmaStatusId === 30){
                    setShowExpediente(true);
                }
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            setLoading(true);
            if(values.firmado){
                values.concluido = true;
                values.cierreContratoFirmaStatusId = 30;
            }

            await Cierres.cierreContratoFirmaActualizar(values)
            .then(resp => {
                resp.data.entregaPapeleria = resp.data.entregaPapeleria ? 1 : 0;
                setInitialValue(resp.data);
                toast.success(message("Se actualizó el Proceso de firma correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar el Proceso de firma!"));
            }).finally(() => {
                if(values.firmado){
                    if(values.ine === 0 || values.entregaPapeleria === 0){
                        dispatch(clearHour());
                        cierreContratoFirmaCancelar();
                        // clearHour
                    }else{
                        crearExpediente();
                        crearValuacion();
                    }

                }
                setLoading(false);
            });
        }
        const cierreContratoFirmaCancelar = async () => {
            await Cierres.cierreContratoFirmaCancelar(cierreid)
            .then(resp => {
                toast.success(message("Se regresó al proceso de cita de firma correctamente"));
                setTimeout(() => {
                    props.getContratoCita()
                }, 100);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar regresar al proceso de cita de firma !"));
            });
        }

        const crearExpediente = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoExpedienteStatusId: 10,
                status: true
            }

            await Cierres.cierreContratoExpedienteCrear(params)
            .then(resp => {
                toast.success(message("Se inició el Proceso de Expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el Proceso de Expediente!"));
            }).finally(() => {
                setShowExpediente(true);
            });
        };

        const crearValuacion = async () => {
            let params = {
                generalId: generalid,
                procesoId : 20,
                origen: initialValue.origen,
                origenId: initialValue.origenId
            }
            await Valuaciones.crearValuaciones(params)
            .then(resp => {
                toast.success(message("Se inició el Proceso de Valuación correctamente"));
                crearInspeccionUnidad(resp.data.valuacionId);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el Proceso de Valuación!"));
            });
        }

        const crearInspeccionUnidad = async (valuacionId) => {
            let params = {
                valuacionId: valuacionId,
                valuacionInspeccionUnidadStatusId: 10,
                status: true
            }
            await Valuaciones.valuacionInspeccionUnidadCrear(params)
            .then(resp => {
                toast.success(message("Se inició el Proceso de Inspección de Unidad correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el Proceso de Inspección de Unidad!"));
            });
        }


        const downloadContratoPDF = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoFirmaStatusId: 20,
                contratoDescargado: true
            }

            // setTimeout(() => {
            //     const requestBody = {
            //         nombrePlantilla: "ConvenioTransaccionTerminacionContrato",
            //         parametros: {
            //             "<<VARIABLE_FECHA>>" : ,
            //             "<<VARIABLE_CLIENTE>>" : ,
            //             "<<VARIABLE_DOMICILIO_REPRESENTADA>>" : ,
            //             "<<VARIABLE_DOMICILIO_CLIENTE>>" : ,
            //             "<<VARIABLE_FECHA_ENDOSO>>" : ,
            //             "<<VARIABLE_MARCA>>" : ,
            //             "<<VARIABLE_TIPO>>" : ,
            //             "<<VARIABLE_MODELO>>" : ,
            //             "<<VARIABLE_SERIE>>" : ,
            //             "<<VARIABLE_MOTOR>>" : ,
            //             "<<VARIABLE_COLOR>>" : ,
            //             "<<VARIABLE_FECHA_CONTRATO>>" : ,
            //             "<<VARIABLE_ANTICIPO>>" : ,
            //             "<<VARIABLE_RESTANTE>>" : ,
            //             "<<VARIABLE_RESTANTE_LETRA>>" : ,
            //             "<<VARIABLE_DOMICILIO_REPRESENTADA>>" : ,
            //             "<<VARIABLE_DOMICILIO_CLIENTE>>" : ,
            //             "<<VARIABLE_FECHA_CONTRATO_LETRA>>" : ,
            //         }
            //     };

            //     Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            //         //Pendiente de revisar por que no jala con el file-server
            //         documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

            //     }).catch(err => {
            //         console.error(err);
            //     });
            // }, 500);

            await Cierres.cierreContratoFirmaActualizar(params)
            .then(resp => {
                resp.data.entregaPapeleria = resp.data.entregaPapeleria ? 1 : 0
                setInitialValue(resp.data);
                toast.success(message("Se generó el archivo PDF correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar generar el archivo PDF!"));
            });
        }
        
        const downloadCancelacionPDF = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoFirmaStatusId: 20,
                terminacionDescargado: true
            }

            await Cierres.cierreContratoFirmaActualizar(params)
            .then(resp => {
                resp.data.entregaPapeleria = resp.data.entregaPapeleria ? 1 : 0
                setInitialValue(resp.data);
                toast.success(message("Se generó el archivo PDF correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar generar el archivo PDF!"));
            });
        }
        
        const downloadAdeudosPDF = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoFirmaStatusId: 20,
                reciboDescargado: true
            }

            await Cierres.cierreContratoFirmaActualizar(params)
            .then(resp => {
                resp.data.entregaPapeleria = resp.data.entregaPapeleria ? 1 : 0
                setInitialValue(resp.data);
                toast.success(message("Se generó el archivo PDF correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar generar el archivo PDF!"));
            });
        }
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseProceso" role="button" aria-expanded="false" aria-controls="collapseProceso" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.cierreContratoFirmaStatusId === null ? 'semaforo semaforo-default' : ( initialValue.cierreContratoFirmaStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Proceso de firma</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.cierreContratoFirmaStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseProceso">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Generar Documento: Fallecimiento del titular</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray'>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.cierreContratoFirmaStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Contrato de transacción para terminación de contrato</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Cancelación de terminación de contrato</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' disabled={!initialValue.contratoDescargado} onClick={(e) => {downloadCancelacionPDF();}}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-auto">
                                                            <small>Recibo de adeudos</small>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className='btn btn-gray' disabled={!initialValue.terminacionDescargado} onClick={(e) => {downloadAdeudosPDF();}}>
                                                                <i className="icon-blue bx bx-download"></i>
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-md-9">
                                                            <table className="table" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                <thead style={{"backgroundColor":"#E0E0E0"}}>
                                                                    <tr>
                                                                        <th scope="col">Documento</th>
                                                                        <th scope="col" style={{"textAlign":"center"}}>Persona Física Nuevo León</th>
                                                                        <th scope="col" style={{"textAlign":"center"}}>Persona Física Saltillo</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Terminación de contrato</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Solicitud de desinstalación</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Recibo</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Carta de entrega de papelería</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>La autorización con constancia</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>La autorización sin constancia</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Carta de baja</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Carta vehículo en garantía</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Carta vehículo en garantía Aval</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Convenio de terminación por incapacidad de pago</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>Convenio de terminación por incapacidad de pago Aval</th>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{"textAlign":"center", "border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                                            <button className='btn btn-gray' onClick={(e) => {downloadContratoPDF();}}>
                                                                                <i className="icon-blue bx bx-download"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {
                                                        !initialValue.ineNotificacion &&
                                                        <>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-3">
                                                                    <small><strong>Detalle documentación</strong></small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-3">
                                                                    <SelectField id="ine"
                                                                        label="INE"
                                                                        disabled={initialValue.cierreContratoFirmaStatusId === 30}
                                                                        name="ine"
                                                                        items={opcionesBool}
                                                                        onChange={(event) => {
                                                                            setFieldValue("ine", event.value);
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Proceso de firma y entrega de papelería</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="entregaPapeleria"
                                                                label="Entregó papelería completa"
                                                                disabled={initialValue.cierreContratoFirmaStatusId === 30}
                                                                name="entregaPapeleria"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("entregaPapeleria", event.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios"
                                                                disabled={initialValue.cierreContratoFirmaStatusId === 30}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 alert alert-primary" role="alert">
                                                            <div className="row">
                                                                <div className="col-1 text-center">
                                                                    <i className="icon-dark bx bx-info-circle bx-sm"></i>
                                                                </div>
                                                                <div className="col-11">
                                                                    Notificar al cliente: Debe agendar cita para la desintalación del datamovil. Contactar departamento de Servicio técnico: 8112309380
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.cierreContratoFirmaStatusId === 30} type="checkbox" name="firmado" onClick={(event) => {
                                                                setFieldValue("firmado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Firma realizada</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (initialValue.cierreContratoFirmaStatusId === 30 &&
                    showExpediente) &&
                    <ExpedienteCard />
                }
            </>
        )
    })
export default ProcesoFirmaCard