import axios from '../../Utils/ApiService';

const TblSecundariasService = {
    getTables:(page,rows,tableId,str)=> {        
        return axios.get(`/functions/GetAllAsyc?page=${page}&rows=${rows}&tableId=${tableId}&name=${str}`);
    },   
    getTablesList:()=> {        
        return axios.get(`/functions/GetAllAsycList`);
    },  
}

export default TblSecundariasService