import { Formik } from "formik";
import { SelectField } from "../SelectField";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import Spinner from "../Loadig";
import { TextField } from "../TextField";
import Catalogo from "../../Services/Catalogos/Catalogo";

const SolicitudAmpliacionExpedienteModal = ({ modalMessage, setModalMessage }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    let initial = {
        resultado: null,
        motivo: null,
        comentariosSolicitudAmpliacion: ''
    }

    const [initialValue, setInitialValue] = useState(initial);

    const resultados = [
        { value: '0', label: 'Rechazar' },
        { value: '1', label: 'Aprobar' },
    ]
    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        getMotivos();
    }, []);

    const getMotivos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Motivo_Ampliacion')
        .then(resp => {
            let list = [];
            resp.data.forEach(item => {
                list.push({
                    value: item.Id,
                    label: item.nombre
                });
            });
            setMotivos(list);
        })
        .catch(err => {
            console.error(err);
        });
    }

    const handleRegresar = () => {
        setModalMessage({ isOpen: false });
    }

    const handleGuardar = () => {

    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                // validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    concepto: true,
                    metodoPago: true,
                    monto: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="resultado"
                                                label="Resultado"
                                                name="resultado"
                                                items={resultados}
                                                onChange={(event) => {
                                                    setFieldValue("resultado", event.value);
                                                }}
                                            />
                                        </div>
                                        {
                                            values.resultado === '0' &&
                                            <div className="col-lg-6 col-12">
                                                <SelectField
                                                    id="motivo"
                                                    label="Motivo"
                                                    name="motivo"
                                                    items={motivos}
                                                    onChange={(event) => {
                                                        setFieldValue("motivo", event.value);
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="monto"
                                                disabled={false}
                                                label="Monto"
                                                name="monto"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("monto", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handleGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default SolicitudAmpliacionExpedienteModal;