import React, { useState } from 'react';
import useValuacionDetalle from '../../../../Hooks/SeguimientoDeValuacion/useValuacionDetalle';
import Stepper, { status } from '../../../../Components/Stepper/Stepper';
import { useLocation, useParams } from 'react-router-dom';
import Comentarios from '../../../../Services/ProcesosCierre/Comentarios';

const initialData = {
    step1: status.Active,
    porcentage1: 100,
    step2: status.Inactive,
    porcentage2: 0,
    step3: status.Inactive,
    porcentage3: 0,
    step4: status.Inactive,
    porcentage4: 100,
};

const Revisionstepper = ({ handleClickContinuar, dataStep = initialData, activeBtn = false }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const { navigate } = useValuacionDetalle();
    const [comentarios, setComentarios] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleNavigate = (step) => {
        navigate(`/datamovil/${state.tipoProceso}/${state.tipoServicioid}/${id}/${step.paso}`, {
            state: { ...state }
        });
    };

    const handleSubmit = async (values) => {
        if (values.comentario.trim().length === 0) return;

        setLoading(true);
        try {
            const resp = await Comentarios.addComment(values);
            setComentarios(resp.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getComments = async () => {
        setLoading(true);
        try {
            const resp = await Comentarios.getComments(id);
            setComentarios(resp.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className='headerTab'>
            <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                <div>
                    <i className="bx bxs-chevron-left mx-2" onClick={() => navigate(-1)}></i>
                    Regresar a listado de seguimiento de valuación
                </div>
                {state?.estatus !== 'Concluido' && (
                    <button disabled={!activeBtn} className='btn' onClick={handleClickContinuar}>
                        Continuar
                    </button>
                )}
            </header>
            <div className='d-flex align-items-center row'>
                {dataStep.map((x) => (
                    <Stepper
                        key={x.paso}
                        status={x.pasoSubStatus}
                        module={x.pasoDescripcion}
                        nameStep={`Paso ${x.paso}`}
                        percentage={x.porcentaje}
                        item={x}
                        handleClick={() => handleNavigate(x)}
                    />
                ))}
            </div>
        </section>
    );
};

export default Revisionstepper;
