import axios from '../../../Utils/ApiService';

const VentaVehiculoService = {
    addVehiculoPrealta:(data)=>{
        return axios.post(`/functions/AddPrealta`,data);
    },
    updateVehiculoPrealt:(id,data)=>{
        return axios.put(`/functions/UpdPrealta?GeneralId=${id}`,data);
    },
    updateVehiculoAlta:(data)=>{
        return axios.put(`/functions/UpdAlta`,data);
    },
    AddVehiculoAlta:(data)=>{
        return axios.post(`/functions/AddAlta`,data);
    },
    getlAllVehiculoPrealta:()=>{
        return axios.get(`/functions/AddPrealta`);
    },
    getlAllVehiculoPrealtaById:(id)=>{
        return axios.get(`/functions/GetVehiculoPrealta?GeneralId=${id}`);
    },
    getlAllVehiculoAltaById:(id)=>{
        return axios.get(`/functions/GetVehiculoAlta?GeneralId=${id}`);
    },
    getlAllVehiculo:(page,rows,filtroTA,text,filtroDoc,utilitario,orden="",etapaId = "")=>{
        return axios.get(`/functions/GetAllVehiculosEnProceso?page=${page}&Rows=${rows}&FiltroTA=${filtroTA}&Buscar=${text}&FiltroFolder=${filtroDoc}&FiltroUtilitario=${utilitario}&Orden=${orden}&EtapaId=${etapaId}`);        
    }, 
    getlAllVehiculoVentaContado:(page,rows,etapaId,text)=>{
        return axios.get(`/functions/GetVentaVehiculosContadoSeguimiento?page=${page}&Rows=${rows}&EtapaId=${etapaId}&Buscar=${text}`);        
    },    
    getlAllVehiculoVentaContadoSeguimiento:(page,rows,etapaId,text)=>{
        return axios.get(`/functions/GetVentaVehiculosContadoSeguimientoSEG?page=${page}&Rows=${rows}&EtapaId=${etapaId}&Buscar=${text}`);        
    },   
    getlAllVehiculoVentaContadoConcluido:(page,rows,etapaId,text)=>{
        return axios.get(`/functions/GetVentaVehiculosContadoConcluido?page=${page}&Rows=${rows}&EtapaId=${etapaId}&Buscar=${text}`);        
    },      
    getlAllVehiculoUtilitario:(page,rows,etapaId,text)=>{
        return axios.get(`/functions/GetAllVehiculoUtilitario?page=${page}&Rows=${rows}&EtapaId=${etapaId}&Buscar=${text}`);        
    },
    getlAllDocUsuario:(page,rows,string,rolId)=>{
        return axios.get(`/functions/GetTipoDocumentoPorRolTD?page=${page}&Rows=${rows}&RolId=${rolId}&Buscar=${string}`);        
    },    
    addStatusDatamovil:(data)=>{
        return axios.put(`/functions/Datamovil?GeneralId=${data.generalId}&NumSerie=${data.serieDatamovil}&StatusDatamovilId=${data.statusDatamovilId}`);
    },
    getStatusDatamovil:(id)=>{
        return axios.get(`/functions/GetDatosDatamovil?GeneralId=${id}`);
    },
    getAgenteSeguros:async()=>{
        return await axios.get(`/functions/GetCSAgenteSeguroSI`);
    },
    addVehiculoValoracion:(data)=>{
        return axios.post(`/functions/AddValoracion`,data);
    },   
    UpdVehiculoValoracion:(data)=>{
        return axios.put(`/functions/UpdValoracion`,data);
    },  
    getlAllVehiculoValoracionById:(id)=>{
        return axios.get(`/functions/GetVehiculoValoracion?GeneralId=${id}`);
    },
    getAllColores : () =>{
        return axios.get(`/functions/GetCSColor`);
    },
    getCostoVehiculo : (Id) =>{
        return axios.get(`/functions/GetValorCosto?GeneralId=${Id}`);
    },
    addCambioUbicacion:(data)=>{
        return axios.post(`/functions/CambioUbicacion`,data);
    },
    putIdFolder:(vin,folderId,generalId)=>{
        return axios.put(`/functions/AddFolderManual?vin=${vin}&folderId=${folderId}&GeneralId=${generalId}`);
    },
    getAllTipoDocEtapa:(productoId,tipocompraId,page,rows)=>{
        return axios.get(`/functions/GetTipoDocumentoPorEtapaTD?page=${page}&Rows=${rows}&ProductoId=${productoId}&TipoCompraId=${tipocompraId}`);
    },
    addDocumentoEtapa:(data)=>{
        return axios.post(`/functions/AddTipoDocumentoPorEtapaTD`,data);
    },
    UpdDocumentoEtapa:(data)=>{
        return axios.put(`/functions/UpdTipoDocumentoPorEtapaTD`,data);
    },
    AddDocumentoRol:(data)=>{
        return axios.post(`/functions/AddTipoDocumentoPorRolTD`,data);
    },
    UpdDocumentoRol:(data)=>{
        return axios.put(`/functions/UpdTipoDocumentoPorRolTD`,data);
    },
    getAllVehiculoClave:(id)=>{
        return axios.get(`/functions/ConsultaClaveByGId?GeneralId=${id}`);
    },
    //Venta Contado
    addRevisionUnidad:(data)=>{
        return axios.post(`/functions/AddRevisionDeUnidad`,data);
    },
    getAllHistory:(generalId,page,rows)=>{
        return axios.get(`/functions/GetHistorialRevisionUnidad?GeneralId=${generalId}&Page=${page}&Rows=${rows}`);
    }, 
    addAutorizacionVentaContado:(data)=>{
        return axios.post(`/functions/AddVentaAutorizacion`,data);
    },
    //Datos Generales SC
    addDatosGeneralesSC:(data)=>{
        return axios.post(`/functions/DatosGeneralesSCAdd`,data);
    },
    UpdDatosGeneralesSC:(data)=>{
        return axios.put(`/functions/DatosGeneralesUpdate`,data);
    },
    getByIdDatosGenerales:(generalId)=>{
        return axios.get(`/functions/DatosGeneralesById?GeneralId=${generalId}`);
    },
    BuscarVINPreAlta: (vin) => {
        return axios.get(`/functions/BuscarVINPreAlta?vin=${vin}`);
    }
}

export default VentaVehiculoService