import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import Llamada from '../../../Services/Cartera/Llamadas';
import { message, pageRows } from '../../../Utils/functions'
import { setGruaSlice } from '../../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import DataTable from '../../../Components/datatable/DataTable';
import ConfirmResponsabilidadModal from '../../../Components/Modal/ConfirmResponsabilidadModal';
import Garantias from '../../../Services/Cartera/Garantias';
import CardDiagnostico from './CardDiagnostico';
import documentsHelper from '../../../Utils/documents';
// import downloadBase64File from '../../../Utils/documents';


const CardRecepcion
    = React.forwardRef(({ ...props }, ref) => {
        const { generalId } = props

        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { FolioGarantia, GarantiaId, GeneralId } = useSelector((state) => state.GarantiaSlice);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const myRef = useRef();
        let { idllamada } = useParams();
        const { fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numMotor,
            numContrato,
            producto,
            cliente,
            transmision,
            usoUnidad,
            serieDatamovil,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);



        let initial = {
            garantiaId: GarantiaId,
            garantiaRecepcionId: "",
            recepcionFolio: 0,
            fechaRecepcion: "",
            fechaConclusion: "",
            km: null,
            recepcionGasolinaId: "",
            detalleEstetico: null,
            comentariosDetalleEstetico: "",
            concluida: false,
            usuarioIdTecnico: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            recepcionStatusId: ""
        }


        const columns = [
            { field: 'GarantiaValida', headerName: '', width: 150 },
        ];


        const [disabled, setDisabled] = useState(true);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const [Clasificaciones, setClasificaciones] = useState([]);
        const [Gasolina, setGasolina] = useState([]);
        const [concluida, setConcluida] = useState(false);
        const [statusDiagnosticoId, setStatusDiagnosticoId] = useState('');
        const [recepcionStatusName, setRecepcionStatusName] = useState('');
        const opcionesBool = [
            {
                value: 1,
                label: 'Si'
            },
            {
                value: 0,
                label: 'No'
            }
        ];

        useEffect(() => {
            getRecepcion();
            getGasolinas();
            // getStatusDiagnostico();
        }, []);

        React.useImperativeHandle(ref, () => ({
            getRecepcion
        }));

        const downloadPDF = async () => {
            if (initialValue.recepcionFolio === 0) {
                setLoading(true);
                const initialSave = {};
                initialSave.GarantiaId = initial.garantiaId;
                initialSave.recepcionStatusId = 20;

                await Garantias.crearRecepcion(initialSave)
                    .then(resp => {
                        setDisabled(false);
                        setInitialValue(resp.data);

                        setRecepcionStatusName(resp.data.recepcionStatus);

                        toast.success(message("¡Operación exitosa!", "Se inició la recepción correctamente"));

                        setTimeout(async () => {
                            const requestBody = {
                                nombrePlantilla: "RecepcionUnidad",
                                parametros: {
                                    "<<VARIABLE_VIN>>": vin,
                                    "<<VARIABLE_CONTRATO>>": numContrato,
                                    "<<VARIABLE_FECHA>>": getDateFormat(initialValue.fechaRecepcion, ''),
                                    "<<VARIABLE_FOLIO>>": initialValue.recepcionFolio,
                                    "<<VARIABLE_CLIENTE>>": cliente,
                                    "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                                    "<<VARIABLE_TRANSMISION>>": transmision,
                                    "<<VARIABLE_DATAMOVIL>>": serieDatamovil,
                                    "<<VARIABLE_DIRECCION_UBICACION>>": ""
                                }
                            };

                            Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
                            }).catch(err => {
                                console.error(err);
                            });
                        }, 1000);
                    })
                    .catch(err => {
                        toast.error(message("¡Operación fallida!", "No se pudo iniciar la recepción"));
                        console.error(err);
                    })
                    .finally(() => setLoading(false));
            } else {
                const requestBody = {
                    nombrePlantilla: "RecepcionUnidad",
                    parametros: {
                        "<<VARIABLE_VIN>>": vin,
                        "<<VARIABLE_CONTRATO>>": numContrato,
                        "<<VARIABLE_FECHA>>": getDateFormat(initialValue.fechaRecepcion, ''),
                        "<<VARIABLE_FOLIO>>": initialValue.recepcionFolio,
                        "<<VARIABLE_CLIENTE>>": cliente,
                        "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                        "<<VARIABLE_TRANSMISION>>": transmision,
                        "<<VARIABLE_DATAMOVIL>>": serieDatamovil,
                        "<<VARIABLE_DIRECCION_UBICACION>>": "NO DISPONIBLE"
                    }
                };

                await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                    //Pendiente de revisar por que no jala con el file-server
                    documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);

                }).catch(err => {
                    console.error(err);
                });
            }

        }

        const setDetalleEstetico = (values) => {
            if (values.detalleEstetico) {
                setInitialValue({ ...values, detalleEstetico: 1 })
            } else {
                setInitialValue({ ...values, detalleEstetico: 0 })
            }

        }

        const getRecepcion = async () => {
            setLoading(true)
            const initialSave = {};
            await Garantias.getRecepcion(GarantiaId)
                .then(resp => {
                    if (resp.status === 200) {
                        setRecepcionStatusName(resp.data.recepcionStatus);

                        if (resp.data.detalleEstetico) {
                            resp.data.detalleEstetico = 1
                        } else {
                            resp.data.detalleEstetico = 0
                        }

                        setInitialValue(resp.data);
                        if (resp.data.recepcionFolio !== 0 && !resp.data.concluida) {
                            setDisabled(false);
                        }

                        if (resp.data.concluida) {
                            setConcluida(true);
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }

        const getGasolinas = async () => {
            let tmpList = [];
            await Catalogo.getDropdownsByTable('Catalogos.RecepcionGasolina')
                .then(resp => {
                    resp.data.forEach(item => {
                        tmpList.push({
                            value: item.RecepcionGasolinaId,
                            label: item.RecepcionGasolina
                        });
                    });
                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }
                    tmpList.sort(compare);
                    setGasolina(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }


        const submitForm = async (values) => {
            setLoading(true);
            if (values.km && typeof values.km === 'string') {
                values.km = values.km.replace(/,/g, "").replace("Km", "").trim();
                console.log(values.km);
            }

            //values.km = values.km.replace(/,/g, "").replace("Km", "").trim();
            if (values.concluida !== null) {
                values.recepcionStatusId = 30;
            }
            await Garantias.updateRecepcion(values)
                .then(resp => {
                    const data = resp.data;
                    setRecepcionStatusName(data.recepcionStatus);

                    if (data.detalleEstetico) {
                        data.detalleEstetico = 1
                    } else {
                        data.detalleEstetico = 0
                    }

                    setInitialValue(data);
                    if (resp.data.concluida) {
                        insertDiagnostico(data);
                    }
                    toast.success(message("¡Operación exitosa!", "Se actualizó la recepción correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "No se pudo actualizar la recepción"));
                })
                .finally(() => setLoading(false));

        }

        const insertDiagnostico = async (data) => {
            let initial = {
                garantiaId: GarantiaId,
                garantiaRecepcionId: data.garantiaRecepcionId,
                diagnosticoStatusId: "0",
            }

            await Garantias.crearDiagnostico(initial)
                .then(resp => {
                    myRef.current?.getDiagnostico();
                    toast.success(message("¡Operación exitosa!", "Se inició el diagnóstico correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "No se pudo crear el diagnóstico"));
                });

        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const setKmFormat = (value, values) => {
            let dato = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                currencyDisplay: "code"
            })
                .format(value)
                .replace("MXN", "")
                .split(".")[0]
                .trim();

            if (value.trim().length !== 0) {
                setInitialValue({
                    ...values,
                    km: dato + " Km"
                });
            } else {
                setInitialValue({
                    ...values,
                    km: ""
                });
            }
        }

        const setInputFormat = (value, values) => {
            let dato = value.replaceAll(",", "")
                .replaceAll("Km", "")
                .trim();

            setInitialValue({
                ...values,
                km: dato
            });
        }


        const validate = Yup.object({
            km: Yup.number()
                .typeError('Debe contener solo números y no exceder los 10 caracteres')
                .integer()
                .min(kilometraje, `El kilometraje no debe ser menor a ${kilometraje}`)
                .required("El campo es requerido.")
        });

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <ConfirmResponsabilidadModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>


                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />

                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        validationSchema={validate}
                        initialValues={initialValue}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <>
                                <div className='row mx-md-4 my-4' >
                                    <div className="col-12 px-md-3">
                                        <div className="p-4 white-wrapper mb-2">
                                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRescepcion" role="button" aria-expanded="false" aria-controls="collapseRescepcion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                <div className="col-11">
                                                    <h6><span className={initialValue.recepcionFolio !== 0 && !initialValue.concluida ? 'semaforo semaforo-yellow' : (initialValue.recepcionFolio !== 0 && initialValue.concluida ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Recepción</strong></h6>
                                                </div>
                                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                </div>
                                                <div className="col-12">
                                                    <small>{recepcionStatusName}</small>
                                                </div>
                                            </div>
                                            <div className="collapse" id="collapseRescepcion">
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-8">
                                                        <small><strong>Generar Documento</strong></small>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="row justify-content-end">
                                                            {
                                                                !initialValue.concluida &&
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray' disabled={disabled}>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                !initialValue.concluida &&
                                                                <div className="col-auto" >
                                                                    <button className='btn' disabled={disabled} onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small>Recepción de unidad</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className='btn btn-gray' onClick={() => downloadPDF()}>
                                                            <i className="icon-blue bx bx-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-8">
                                                        <small><strong>Detalle de la recepción</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="usuarioAltaNombre" disabled={true} label="Técnico" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioAltaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField
                                                            id="km"
                                                            disabled={disabled || initialValue.concluida}
                                                            // onFocus={(event) => setInputFormat(event.target.value, values)}
                                                            // onBlur={(event) => setKmFormat(event.target.value, values)}
                                                            label="KM"
                                                            name="km"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("km", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="recepcionGasolinaId"
                                                            label="Gasolina"
                                                            disabled={disabled || initialValue.concluida}
                                                            name="recepcionGasolinaId"
                                                            items={Gasolina}
                                                            onChange={(event) => {
                                                                setFieldValue("recepcionGasolinaId", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField
                                                            id="detalleEstetico"
                                                            label="¿Detalle Estético?"
                                                            disabled={disabled || initialValue.concluida}
                                                            name="detalleEstetico"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("detalleEstetico", event.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    values.detalleEstetico === 1 &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <TextAreaField id="comentariosDetalleEstetico"
                                                                label="Comentarios estéticos"
                                                                disabled={disabled || initialValue.concluida}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosDetalleEstetico"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosDetalleEstetico", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>

                                                }
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={disabled || initialValue.concluida} type="checkbox" name="concluida" onClick={(event) => {
                                                            setFieldValue("concluida", event.target.value);
                                                            setConcluida(event.target.checked);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Recepción concluida</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaRecepcion !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaRecepcion, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaConclusion !== "" && initialValue.concluida &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    concluida &&
                                    <CardDiagnostico ref={myRef} />
                                }
                            </>
                        )
                        }
                    </Formik>
                </div>

            </>
        )
    })
export default CardRecepcion