import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Cierres from '../../../Services/Resguardos/Cierres';
import RequisitosModal from '../../../Components/Modal/RequisitosModal';
import CitaFirmaCard from './CitaFirmaCard';
import { SelectField } from '../../../Components/SelectField';

const NotificarClienteCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();

        let initial = {
            cierreContratoNotificacionId: "",
            cierreContratoId: cierreid,
            cierreContratoNotificacionStatusId: 0,
            fechaAlta: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            fechaActualiza: "",
            fechaInicio: "",
            usuarioNotifica: "",
            notificado: "",
            documentacionEndosada: null,
            ine: null,
            fechaHoraNotificacion: "",
            comentariosNotifiacion: "",
            fechaConclusion: "",
            concluido: "",
            status: false,
            cierreContratoNotificacionStatus: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            usuarioNotificaNombre: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [habitual, setHabitual] = useState("");
        const [fisica, setFisica] = useState("");
        const [moral, setMoral] = useState("");

        useEffect(() => {
            getNotifiacion();
            getRequisitos('req_pf_cond');
            getRequisitos('req_pfisica');
            getRequisitos('req_pmoral');
        }, []);


        /* CONSULTAS */
        const getNotifiacion = async () => {
            setLoading(true);
            await Cierres.cierreContratoNotificacionGet(cierreid)
            .then(resp => {
                if(resp.data.fechaHoraNotificacion !== null && resp.data.fechaHoraNotificacion !== ""){
                    let fechaHr = new Date(resp.data?.fechaHoraNotificacion+"Z");
                    resp.data.fechaHoraNotificacion = fechaHr.getFullYear()+"-"+("0"+(fechaHr.getMonth()+1)).slice(-2)+"-"+("0"+fechaHr.getDate()).slice(-2)+" "+fechaHr.getHours()+":"+("0"+fechaHr.getMinutes()).slice(-2)+":"+fechaHr.getSeconds();
                }
                resp.data.documentacionEndosada = resp.data.documentacionEndosada === null ? null : (resp.data.documentacionEndosada ? 1 : 0);
                resp.data.ine = resp.data.ine === null ? null : (resp.data.ine ? 1 : 0);
                setInitialValue(resp.data);

            })
            .catch(err => {

            })
            .finally(() => setLoading(false));
        }

        const getRequisitos = async (clave) => {
            await Cierres.parametroCarteraByClaveGet(clave)
            .then(resp => {
                if(clave === 'req_pf_cond'){
                    setHabitual(resp.data.valor);
                }else if(clave === 'req_pfisica'){
                    setFisica(resp.data.valor);
                }else if(clave === 'req_pmoral'){
                    setMoral(resp.data.valor);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }



        const submitForm = async (values) => {
            if(values.notificado){
                values.concluido = true;
                values.cierreContratoNotificacionStatusId = 30;
            }else{
                values.cierreContratoNotificacionStatusId = 20;
            }

            setLoading(true);
            await Cierres.cierreContratoNotificacionActualizar(values)
            .then(resp => {

                
                if(resp.data.fechaHoraNotificacion !== null && resp.data.fechaHoraNotificacion !== ""){
                    let fechaHr = new Date(resp.data?.fechaHoraNotificacion+"Z");
                    resp.data.fechaHoraNotificacion = fechaHr.getFullYear()+"-"+("0"+(fechaHr.getMonth()+1)).slice(-2)+"-"+("0"+fechaHr.getDate()).slice(-2)+" "+fechaHr.getHours()+":"+("0"+fechaHr.getMinutes()).slice(-2)+":"+fechaHr.getSeconds();
                }
                resp.data.documentacionEndosada = resp.data.documentacionEndosada === null ? null : (resp.data.documentacionEndosada ? 1 : 0);
                resp.data.ine = resp.data.ine === null ? null : (resp.data.ine ? 1 : 0);
                
                setInitialValue(resp.data);
                if(values.cierreContratoNotificacionStatusId === 30){
                    iniciarCitaFirma();
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));

        }

        const iniciarCitaFirma = async () => {
            let params = {
                cierreContratoId: cierreid,
                cierreContratoCitaStatusId: 10
            }
            await Cierres.cierreContratoCitaCrear(params)
            .then(resp => {
                toast.success(message("Se inició el proceso de cita de firma correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar iniciar el proceso de cita de firma!"));
            })
        }

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#e9ecef',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={860}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <RequisitosModal
                            habitual={habitual}
                            fisica={fisica}
                            moral={moral}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseNotificacion" role="button" aria-expanded="false" aria-controls="collapseNotificacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={initialValue.cierreContratoNotificacionStatusId === null ? 'semaforo semaforo-default' : ( initialValue.cierreContratoNotificacionStatusId === 30 ? 'semaforo semaforo-green' : 'semaforo semaforo-yellow')}></span> <strong>Notificar cliente</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.cierreContratoNotificacionStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapseNotificacion">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Notificar al cliente</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn px-4'
                                                                        disabled={!isValid || initialValue.cierreContratoNotificacionStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioNotificaNombre" disabled={true} label="Usuario notifica" name="usuarioNotificaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioNotificaNombre", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <span>Fecha y hora cliente notificado</span>
                                                            <div className='pt-1' style={containerStyle}>
                                                                <span>{initialValue.fechaHoraNotificacion}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <button
                                                                className='btn mt-4 px-4'
                                                                disabled={false}
                                                                onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Requisitos de trámite' })}>
                                                                Ver requisitos
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosNotifiacion"
                                                                label="Comentarios"
                                                                disabled={initialValue.cierreContratoNotificacionStatusId === 30}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosNotifiacion"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosNotifiacion", event.target.value);
                                                                }} />
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Detalle documentación</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="documentacionEndosada"
                                                                label="Documentación Endosada"
                                                                disabled={initialValue.cierreContratoNotificacionStatusId === 30}
                                                                name="documentacionEndosada"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("documentacionEndosada", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="ine"
                                                                label="INE"
                                                                disabled={initialValue.cierreContratoNotificacionStatusId === 30}
                                                                name="ine"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("ine", event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.cierreContratoNotificacionStatusId === 30} type="checkbox" name="notificado" onClick={(event) => {
                                                                setFieldValue("notificado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    initialValue.cierreContratoNotificacionStatusId === 30 &&
                    <CitaFirmaCard />
                }
            </>
        )
    })
export default NotificarClienteCard