import React from 'react'
import img from '../../Assets/img/DatamovilSystems.png';


const DatamovilSystems = () => {
    return (
        <>
            <section >
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div className='row'>
                        <div className='text-center'>
                            <img src={img} style={{ width: '20rem' }} className='img-fluid' />
                            <h5 className='mt-4 mb-4'>Datamovil Systems</h5>
                            <p>Aquí podrás darle seguimiento a los servicios Datamovil.<br /> Para comenazar, selecciona una opción del menú.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DatamovilSystems;