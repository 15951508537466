import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { FormatNumberMoney, FormatNumberMoney2, message } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroAutorizadoPoliza from '../../../Hooks/Seguros/useSeguroAutorizadoPoliza'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import SubMenuPagoDesembolso from './SuMenuPagoDesembolso'
import usePendientePagoPagoDesembolso from '../../../Hooks/Seguros/usePendientePagoPagoDesembolso'
import TotalPendientesDePagoModal from './TotalPendientesDePagoModal'
const PendientesPago = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar
    } = usePendientePagoPagoDesembolso(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuPagoDesembolso />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Seguros pendientes de pago</strong></span>
                    <div>
                    <span className='px-2' style={IdsExportar.length > 0 ? {fontSize:'13px'}:{color:'red',fontSize:'13px'}}><strong>Unidades Seleccionadas:</strong> {IdsExportar.length > 0 ? IdsExportar.length : 0}</span>
                        <span className='px-2' style={IdsExportar.length > 0 ? {fontSize:'13px'}:{color:'red',fontSize:'13px'}}><strong>Monto total por asegurar:</strong> {IdsExportar.length > 0 ? `$${FormatNumberMoney2(handleCostos())}` : "no hay seleccion"}</span>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesDePago, SubModule.PagoYDesembolso).Ver}>
                            <button className='btn' onClick={handleDownloadExcel}>Generar reporte de pago</button>
                        </ValidatePermission>
                    </div>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a la gestión de los seguros pendientes de pago.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesDePago, SubModule.PagoYDesembolso).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Aseguradora</label> */}
                            <Select ref={selectRef} options={DataAseguradora} className='col-12' onChange={(e) => { setAseguradora(e.value) }} placeholder="Aseguradora" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Uso de unidad</label> */}
                            <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label>Tipo</label>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div> */}
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        // editable={true}
                        // handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        checkbox={true}
                        handleCheck={handlechekedItem}
                    />
                </div>
            </ValidatePermission>   
            {
                openModalTotales &&
                <TotalPendientesDePagoModal
                    isOpen={openModalTotales}
                    setIsOpen={setopenModalTotales}
                    item={IdsExportar}
                    handleGetAll={()=>{selectRef.current.setValue([], 'clear');setIdsExportar([])}}
                    toast={toast}                  
                />
            }        
        </>
    )
}

export default PendientesPago