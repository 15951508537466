import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import Modal from '../../../../Components/Modal/Modal';

const ModalDetalleComisionesPagadasExterno = ({ isOpen, setIsOpen, item }) => {     
    let initial = {
        folio: item?.folio ?? "",
        fechaEntrega:item?.strFechaEntrega ?? "",
        razonSocial:item?.razonSocial ??"",
        tipoCompra:item?.tipoCompra ?? "",
        procedenciaCliente:item?.procedenciaCliente ?? "",
        precioUnidad:item?.precioStr ?? "",
        montoFinanciar:item?.montoFinanciarStr ?? "",
        calculoComision:item?.calculoComisionStr ?? "",
        pagoComision:item?.totalPagoStr ?? "",
        usuarioPP:item?.usuarioPagado ?? "",
        fechaPP: item?.strFechaProcesamientoPago ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);    

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Detalle de folio {values.folio}</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '35rem' }}>
                            <Form>
                                <span><strong>Información del vehículo</strong></span>
                                <div className="mb-3 row my-2">
                                    <span style={{ fontSize: '14px' }}>Fecha de entrega: {values.fechaEntrega} | Razón Social: {values.razonSocial}</span>
                                    <span style={{ fontSize: '14px' }}>Tipo de compra: {values.tipoCompra} | Procedencia del cliente: {values.procedenciaCliente}</span>
                                    <span style={{ fontSize: '14px' }}>Precio de la unidad: {values.precioUnidad}</span>
                                </div>
                                <span><strong>Información de crédito</strong></span>
                                <div className="mb-3 row my-2">
                                    <span style={{ fontSize: '14px' }}>Monto a financiar: {values.montoFinanciar} | Cálculo de la comisión: {values.calculoComision}</span>
                                    <span style={{ fontSize: '14px' }}>Pago de la comisión: {values.pagoComision} | Usuario procesamiento de pago: {values.usuarioPP}</span>
                                    <span style={{ fontSize: '14px' }}>Fecha procesamiento de pago: {values.fechaPP}</span>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn col-4' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalDetalleComisionesPagadasExterno