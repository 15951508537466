import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Cierres from '../../Services/Resguardos/Cierres';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import Datamovil from '../../Services/Resguardos/Datamovil';

const BuscadorDatamovilModal = ({ modalMessage, setModalMessage }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        buscar: ""
    };
    


    const [busquedaRealizada, setBusquedaRealizada] = useState(false);
    const [data, setData] = useState({});
    const [initialValue, setInitialValue] = useState(initial);

    useEffect(() => {
        setInitialValue(initialValue);
        setData({});
        setBusquedaRealizada(false);
    }, []);

    const validate = Yup.object().shape({});

    const handleBuscarVIN = async (values) => {

        if(values.buscar.trim().length === 0) {
            return false;
        }

        setLoading(true);

        await Datamovil.BusquedaVINDatamovil(values.buscar)
        .then(async resp => {
            console.log(resp.data)
            setBusquedaRealizada(true);
            if(resp.data !== null) {
                setData(resp.data);
            }else{
                setData({});
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const goToPrealta = (values) => {
        navigate(`/procesocierre/prealta/${values.buscar.trim()}/datamovil`);
    };

    const goToDesinstalacion = async (data) => {
        let params = {
            generalId: data.generalId,
            procesoId: 150,
            autorizada: false,
            concluida: false
        }

        await Datamovil.datamovilCrear(params)
        .then(resp => {
            toast.success(message("Se creó la desinstalación datamovil correctamente"));
            crearOrden(resp.data.datamovilId, data);
        })
        .catch(err => {
            toast.error(message("¡Error, al intentar crear la desinstalación datamovil!"));
        });
    }

    const crearOrden = async (datamovilId, data) => {
        let params = {
            datamovilId: datamovilId,
            datamovilOrdenDesinstalacionStatusId: 20,
            tipoOrden: true,
            status: true
        }

        await Datamovil.datamovilOrdenDesinstalacionCrear(params)
        .then(resp => {
            toast.success(message("Se creó la orden de desinstalación datamovil correctamente"));
            setTimeout(() => {
                navigate(`/procesocierre/datamovil/${data.vin}/${data.generalId}/${datamovilId}`);
            }, 1000);
        })
        .catch(err => {
            console.error(err);
            toast.error(message("¡Error, al intentar crear la orden de desinstalación datamovil!"));
        })
    }

    const goToProceso = async (datos) => {
        navigate(`/procesocierre/datamovil/${datos.vin}/${datos.generalId}/${datos.datamovilId}`);
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            setData({});
                            setBusquedaRealizada(false);
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación ingresa el VIN de la unidad que deseas registrar su resguardo:
                                    </small>
                                </div>
                                {
                                    !busquedaRealizada && 
                                    <div className="row mt-3 justify-content-between">
                                        <div className="col-8">
                                            <TextField id="buscar" label="VIN" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-auto mt-4">
                                            <button
                                                id="AT_BtnCloseModal"
                                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => {
                                                    handleBuscarVIN(values);
                                                }}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    busquedaRealizada &&
                                    Object.keys(data).length > 0 &&
                                    <>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>VIN ingresado: {values.buscar}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12 mb-2">
                                                <span>{data.message}</span>
                                            </div>
                                            {
                                                data.error !== 1 &&
                                                <div className="col-12">
                                                    <strong>Marca: {data.nombreMarca} | Modelo: {data.nombreModelo} | Año: {data.nombreYear}</strong>
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-gray d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => { 
                                                        resetForm();
                                                        setData({});
                                                        setBusquedaRealizada(false);
                                                        }}
                                                >
                                                    Regresar
                                                </button>
                                            </div>
                                            {
                                                data.error === 0 &&
                                                <div className="col-auto">
                                                    <button
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            goToDesinstalacion(data);
                                                        }}
                                                    >
                                                        Continuar
                                                    </button>
                                                </div>
                                            }
                                            {
                                                data.error === 1 &&
                                                <div className="col-auto">
                                                    <button
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            goToPrealta(values);
                                                        }}
                                                    >
                                                        Continuar
                                                    </button>
                                                </div>
                                            }
                                            {
                                                data.error === 2 &&
                                                <div className="col-auto">
                                                    <button
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-blue d-flex justify-content-center align-items-center'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => {
                                                            goToProceso(data);
                                                        }}
                                                    >
                                                        Continuar
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default BuscadorDatamovilModal;
