import { useEffect, useState } from 'react'
import Cobranza from '../../Services/Cobranza/Cobranza';

const useBusquedaCodigoPostal = () => {
    const columns = [
        { field: 'municipio', headerName: 'Municipio', width: 200 },
        { field: 'cp', headerName: 'Código postal', width: 150 },
        { field: 'colonia', headerName: 'Colonias', width: 300 },
        { field: 'promesa_status', headerName: 'Zona', width: 350 }
    ]

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [dataEstado, setDataEstado] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [defaultValueMunicipio, setDefaulValudeModelo] = useState({})
    const [zonaValue, setzonaValue] = useState('');
    const [todasZonas, setTodasZonas] = useState([]);
    const [dataZonas, setdataZonas] = useState([]);
    const [currentdataZonas, setcurrentdataZonas] = useState([]);
    const [sinZonasSeleccionadas, setSinZonasSeleccionadas] = useState(false);
    const [coloniaAsignada, setColoniaAsignada] = useState(false);
    const [msjColoniaAsignada, setMsjColoniaAsignada] = useState('');
    const [textoBusqueda, setTextoBusqueda] = useState("")

    useEffect(() => {
        handleGetEstados();
    }, []);

    useEffect(() => {
        // Filtra los datos según los filtros seleccionado
        const filteredData = dataZonas.filter(x => {
            return (textoBusqueda === '' || (x.colonia.toLowerCase().includes(textoBusqueda.toLowerCase()))) ||
                (textoBusqueda === '' || (x.cp.toLowerCase().includes(textoBusqueda.toLowerCase())));
        });

        setcurrentdataZonas(filteredData);
    }, [textoBusqueda])

    const handleGetEstados = async () => {
        setLoading(true);
        await Cobranza.getAllEstados()
            .then(res => {
                let NewData = [];
                res.data.responseData?.map(element => {
                    NewData.push({
                        value: element.cpId,
                        label: element.d_estado
                    })
                });
                setDataEstado(NewData);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const handleGetMunucipios = async (value) => {
        let data = {
            "verb": "string",
            "id": value,
            "field": "string",
            "value": "string",
            "flag": true,
            "anyObject": {}
        }
        setLoading(true);
        await Cobranza.getMunicipios(data)
            .then(res => {
                let NewData = [];
                res.data.responseData?.map(element => {
                    NewData.push({
                        value: element.cpId,
                        label: element.d_mnpio
                    })
                });
                setDataMunicipio(NewData);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const handleGetdataZonas = async (nombreEstado, nombreMunicipio, zonaItems = []) => {
        let data = {
            "verb": "string",
            "id": nombreEstado,
            "field": "string",
            // "value": "string",
            "value": nombreMunicipio,
            "flag": true,
            "anyObject": {}
        }
        setLoading(true);
        await Cobranza.getColonias(data)
            .then(res => {
                let NewData = [];
                res.data.responseData?.map(element => {
                    let registroZona = zonaItems.find(obj => obj.sepomexid === element.cpId);

                    if (registroZona) {
                        NewData.push({
                            item: element,
                            statusCheckBox: false,
                            idrow: element.cpId,
                            municipio: element.d_mnpio,
                            colonia: element.d_asenta,
                            cp: element.d_codigo,
                            status: true,
                            promesa_status: registroZona.zona,
                            colorFondo: "var(--bs-table-bg)",
                            colorTexto: "#000000",
                        })
                    } else {
                        NewData.push({
                            item: element,
                            statusCheckBox: false,
                            idrow: element.cpId,
                            municipio: element.d_mnpio,
                            colonia: element.d_asenta,
                            cp: element.d_codigo,
                            status: false,
                            promesa_status: "Disponible",
                            colorFondo: "#D8F2CD",
                            colorTexto: "#000000",
                        })
                    }
                });

                NewData = NewData.sort((a, b) => {
                    return a.cp.localeCompare(b.cp, undefined, { numeric: true });
                });

                setdataZonas(NewData);
                setcurrentdataZonas(NewData);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
    }

    const handleGetzonas = async (nombreEstado, nombreMunicipio) => {
        // setLoading(true);
        await Cobranza.getAllZonaHead()
            .then(res => {
                let NewData2 = [];
                res.data.responseData.filter(x => x.estado == nombreEstado && x.municipio == nombreMunicipio)?.map(element => {
                    // res.data.responseData.filter(x => x.estado == nombreEstado).map(element => {
                    NewData2.push({
                        value: element.id,
                        label: element.descripcion
                    })
                });
                setTodasZonas(NewData2);
                // setLoading(false);
            }).catch(() => {
                // setLoading(false);
            })
    }

    const handleChangeMunicipio = async (item, zonaItems = []) => {
        setDefaulValudeModelo(item);
        handleGetzonas(item.value, item.label);
        handleGetdataZonas(item.value, item.label, zonaItems);
    }

    const handleChangeMunicipio2 = async (nombreEstado, municipio, zonaItems = []) => {
        setDefaulValudeModelo(municipio);
        handleGetzonas(nombreEstado, municipio.label);
        handleGetdataZonas(nombreEstado, municipio.label, zonaItems);
    }

    const handleChangeStatus = (checked, row) => {
        row.status = checked;
        const newStatusList = [...currentdataZonas];
        newStatusList.forEach(item => {
            if (item == row.idrow)
                item.status = checked;
        });
        setcurrentdataZonas(newStatusList);

        if (currentdataZonas.length > 0)
            setSinZonasSeleccionadas(false);
        console.log(`${checked} - ${row.idrow} - ${row.municipio} - ${row.colonia}`);
    }

    return {
        columns,
        dataEstado,
        dataMunicipio,
        defaultValueMunicipio,
        handleGetMunucipios,
        handleChangeMunicipio,
        handleChangeMunicipio2,
        handleChangeStatus,
        currentdataZonas,
        openModal,
        setOpenModal,
        zonaValue,
        loading,
        setLoading,
        sinZonasSeleccionadas,
        setSinZonasSeleccionadas,
        setzonaValue,
        todasZonas,
        coloniaAsignada,
        msjColoniaAsignada,
        setColoniaAsignada,
        setMsjColoniaAsignada,
        setTextoBusqueda,
        setDataMunicipio
    }
}

export default useBusquedaCodigoPostal