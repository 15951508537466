import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { getDateFormat, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import { useParams } from 'react-router-dom';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import AuthLogin from '../../../Utils/AuthLogin';

const auth = new AuthLogin();
const BoletinarClienteCard
    = React.forwardRef(({ ...props }, ref) => {        
        const { numContrato,fechaUltimoPagoAcendes,usoUnidad,
            fechaUltimoPagAcendes,
            clienteAcendes,
            fechaLiquidacionAcendes} = useSelector((state) => state.DataVinSlice);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, siniestroid } = useParams();        
        const role = auth.getSesionValue("role");
        let initial = {
            siniestroClienteBoletinarStatus: 'Pendiente',
            color: "default",
            proceso: "",
            siniestroClienteBoletinarId: "",
            siniestroId:siniestroid ?? "",
            siniestroClienteBoletinarStatusId: "",
            siniestroClienteBoletinarMotivoId: "",
            usuarioBoletina: "",
            comentariosEjecutivo: "",
            comentariosSupervisor: "",
            clienteBoletinado: "",
            fechaAlta: "",
            fechaActualiza: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            concluido: "",
            fechaConclusion: "",
            siniestroClienteBoletinarStatus: "",
            siniestroClienteBoletinarMotivo: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            usuarioBoletinaNombre: ""

        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [disabled, setDisabled] = useState(false);
        const [dataClienteBoletinarMotivo, setDataClienteBoletinarMotivo] = useState([]);
        const [esAutorizado, setEsAutorizado] = useState(0); //1= autorizado , 2 = rechazado


        useEffect(() => {
            getSiniestroClienteBoletinarMotivo();
            getSiniestroClienteBoletinar();
        }, []);


        /* CONSULTAS */
        const getSiniestroClienteBoletinar = async () => {
            setLoading(true);
            await Siniestros.siniestroClienteBoletinarGet(siniestroid)
                .then(resp => {                                   
                    resp.data.fechaAlta = resp.data.fechaAlta?.split("T")[0];
                    resp.data.fechaActualiza = resp.data.fechaActualiza?.split("T")[0];
                    //resp.data.fechaInicio = resp.data.fechaInicio?.split("T")[0];
                    resp.data.fechaConclusion = resp.data.fechaConclusion?.split("T")[0];
                    resp.data.siniestroClienteBoletinarMotivoId = resp.data.siniestroClienteBoletinarMotivoId ?? "";
                    resp.data.color = resp.data?.color ?? "default";
                    setInitialValue(resp.data)
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getSiniestroClienteBoletinarMotivo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.SiniestroClienteBoletinarMotivo')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.SiniestroClienteBoletinarMotivoId, label: item.SiniestroClienteBoletinarMotivo })
                    });
                    setDataClienteBoletinarMotivo(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };
        /* TERMINA CATALOGOS */     

        const submitForm = async (values) => {
            if (values.concluido) {
                values.siniestroClienteBoletinarStatusId = 30;
            }

            if (values.siniestroClienteBoletinarId !== "") {
                setLoading(true);
                await Siniestros.siniestroClienteBoletinarActualizar(values)
                    .then(resp => {
                        if (resp.data.data.ban === 1) {
                            toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                            getSiniestroClienteBoletinar();
                        } else {
                            toast.error(message("¡Error.!", resp.data.data.mensaje));
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
                    })
                    .finally(() => {
                        if (values.concluido) {
                            //crearReprogramacion();
                        }
                        setLoading(false);
                    });
            } else {
                setLoading(true);
                await Siniestros.siniestroClienteBoletinarCrear(values)
                    .then(resp => {
                        if (resp.data.data.ban === 1) {
                            toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                            getSiniestroClienteBoletinar();
                        } else {
                            toast.error(message("¡Error.!", resp.data.data.mensaje));
                        }

                    })
                    .catch(err => {
                        console.error(err);
                        toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
                    })
                    .finally(() => {

                        if (values.concluido) {
                            //crearReprogramacion();
                        }
                        setLoading(false);
                    });
            }

        }

        const validate = Yup.object({
            siniestroClienteBoletinarMotivoId: Yup.string()
                .required("El campo es requerido."),
            // comentariosEjecutivo: Yup.string()
            //     .required("El campo es requerido."),
            // comentariosSupervisor: Yup.string()
            //     .required("El campo es requerido."),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseBoletinarClienteSiniestro" role="button" aria-expanded="false" aria-controls="collapseBoletinarClienteSiniestro" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">                                      
                                        <h6><span className={`semaforo semaforo-${initialValue?.color.toLocaleLowerCase()}`}></span> <strong>Boletinar a cliente</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.siniestroClienteBoletinarStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseBoletinarClienteSiniestro">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        onSubmit={(values) => {
                                            let data = { ...values, autorizado: false, rechazado: false, nombreCliente: clienteAcendes };
                                            if (esAutorizado === 1) {
                                                data.autorizado = true;
                                            } else if (esAutorizado === 2) {
                                                data.rechazado = true;
                                            }
                                            submitForm(data);
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <Form>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-3">
                                                            <small><strong>Detalle del boletinado</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        type='submit'
                                                                        // disabled={initialValue.valuacionValoracionUnidadStatusId === 40}
                                                                        disabled={values?.concluido ? true :!(isValid)}
                                                                    >
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                                {
                                                                    (initialValue.clienteBoletinado) &&
                                                                    <>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn btn-gray'
                                                                                type='submit'
                                                                                disabled={values?.concluido ? true : initialValue.valuacionValoracionUnidadStatusId === 40}
                                                                                onClick={() => setEsAutorizado(2)}>
                                                                                Rechazar
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className='btn'
                                                                                type='submit'
                                                                                disabled={values?.concluido ? true : initialValue.valuacionValoracionUnidadStatusId === 40}
                                                                                onClick={() => setEsAutorizado(1)}>
                                                                                Autorizar
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="siniestroClienteBoletinarMotivoId"
                                                                label="Motivo boletinado"
                                                                disabled={initialValue?.concluido}
                                                                name="siniestroClienteBoletinarMotivoId"
                                                                items={dataClienteBoletinarMotivo}
                                                                onChange={(event) => {
                                                                    setFieldValue("siniestroClienteBoletinarMotivoId", event.value);
                                                                    setFieldValue("siniestroClienteBoletinarMotivo", event.label);

                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="usuarioBoletinaNombre"
                                                                disabled
                                                                label="Usuario"
                                                                holder="" name="usuarioBoletinaNombre"
                                                                type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="fechaAlta" disabled label="Fecha y hora de la solicitud" holder="" name="fechaAltaStr" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <TextAreaField id="comentariosEjecutivo"
                                                                label="Comentarios del ejecutivo"
                                                                disabled={initialValue?.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosEjecutivo"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosEjecutivo", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="clienteBoletinado" onClick={(event) => {
                                                                setFieldValue("clienteBoletinado", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente boletinado</label>
                                                        </div>
                                                    </div>

                                                    {
                                                        initialValue.clienteBoletinado &&
                                                        <>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <TextAreaField id="comentariosSupervisor"
                                                                        label="Comentarios del supervisor"
                                                                        disabled={initialValue?.concluido}
                                                                        className="form-control col-12"
                                                                        rows="3"
                                                                        name="comentariosSupervisor"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                        onChange={(event) => {
                                                                            setFieldValue("comentariosSupervisor", event.target.value);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                                        setFieldValue("concluido", event.target.value);
                                                                    }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Proceso concluido</label>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="row justify-content-end">
                                                                        <div className="col-auto">
                                                                            {
                                                                                initialValue.fechaAlta !== "" &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                        <div className="col-auto">                                                                         
                                                                            {
                                                                                initialValue?.concluido &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </Form>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default BoletinarClienteCard