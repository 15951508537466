import React, { useEffect, useState } from "react";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "./Componentes/ContratoHeaderCard";
//import InformacionSeguroCard from "../InformacionSeguroCard";
//import SegumientoProcesoDatamovil from "./SeguimientoProcesoDatamovil";
import { useLocation, useParams } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from './Componentes/ModalConfirm'
import useSeguimientoCierrePasos from "../../../Hooks/Cierre/useSeguimientoCierrePasos";
import SeguimientoStepper from "./Componentes/SeguimientoStepper";
import SeguimientoProcesoDatamovil from "./AgendarFirma/SeguimientoProcesoDatamovil";
import CitaFirma from "./AgendarFirma/CitaFirma";
import ProcesoFirmaDetalle from "./ProcesoFirma/ProcesoFirma";

const ProcesoFirma = React.forwardRef(({ ...props }, ref) => {
    const { id } = useParams();

    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useSeguimientoCierrePasos(toast);


    return (
        <>
            <SeguimientoStepper
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />

            <div className='d-flex flex-column background-grey' style={{ height: '120vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={""} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard/>
                    </div>
                </div>
                
                <SeguimientoProcesoDatamovil />
                <ProcesoFirmaDetalle handleConcluido={handleChangeStatusBtn} />
            </div>
            {
                modalConfirm &&
                <ModalConfirm
                    isOpen={modalConfirm}
                    setIsOpen={setModalConfirm}
                    title={"Registro de gastos"}
                    handleConfirm={handleClickTerminarProceso}
                />
            }
        </>
    )
});

export default ProcesoFirma;