import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActivePoliza } from '../../../Redux/Slice/navigateSlicePoliza';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubMenuPoliza = () => {
  const { value } = useSelector((state) => state.tabNavigateSlicePoliza);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActivePoliza(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.SeguroAutorizadoPoliza) && value === 1) {
      navigate(`/seguro/poliza/seguroautorizado`);
    }
    if (ValidatePermissionById(3, Section.SeguroRenovado) && value === 2) {
      navigate(`/seguro/poliza/segurorenovado`);
    }
    if (ValidatePermissionById(3, Section.VehiculoUtilitarioPoliza) && value === 3) {
      navigate(`/seguro/poliza/vehiculoutilitario`);
    }
    if (ValidatePermissionById(3, Section.ReenvioPolizas) && value === 4) {
      navigate(`/seguro/poliza/reenviopolizas`);
    }

  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.SeguroAutorizadoPoliza)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguro autorizado</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.SeguroRenovado)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguro renovado</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehiculoUtilitarioPoliza)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículo utilitario</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ReenvioPolizas)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 4 && "tab-active"}`} value={4} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Reenvío de Póliza</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuPoliza