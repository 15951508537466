import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import RegistroTramiteGestoriaCard from './RegistroInicialCard';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import { setEncabezado, setContrato, clearEncabezado } from '../../../Redux/Slice/Gestoria/gestoriaEncabezadoSlice';
import VehiculoGestoriaCard from './VehiculoGestoriaCard';
import ContratoGestoriaCard from './ContratoGestoriaCard';
import Garantia from '../../../Services/Garantias/Garantias';
import Llamada from '../../../Services/Cartera/Llamadas';

const GestoriaCard = ({}) => {
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const dispatch = useDispatch();
    const { vin, id } = useParams();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    
    let UrlPath = Url;
    if(Url === location.pathname || Url.trim().length === 0){
        UrlPath = '/gestoria/seguimiento';
    }
    useEffect(() => {
        getEncabezado();
        getContract();
    },[]);


    const getContract = async () => {
        Garantia.getContract(vin)
        .then(resp => {
            let resultSet = resp.data.data;
            
            let rs = {
                procedencia: resultSet.procedenciaAuto,
                fechaUltimoPago: resultSet.procedenciaAuto,
                cliente: resultSet.cliente
            }

            dispatch(setContrato(rs));
        })
        .catch(err => {
            console.error(err);
        });
    };

    const getEncabezado = async () => {
        let rs = {}
        await Gestoria.GestoriaEncabezadoGet(id)
        .then(async resp => {
            let resultSet = resp.data;
            rs = {
                vin: resultSet.vin,
                anombre: resultSet.nombre,
                marca: resultSet.nombreMarca,
                modelo: resultSet.nombreModelo,
                version: resultSet.nombreVersion,
                anio: resultSet.nombreYear,
                color: resultSet.color,
                kilometraje: resultSet.kilometraje,
                numMotor: resultSet.numMotor,
                fechaEntrega: resultSet.fechaEntrega.split(" ")[0].replaceAll("/","-"),
                numContrato: resultSet.numContrato,
                producto: resultSet.producto,
                usoUnidad: resultSet.usoUnidad,
                placas: resultSet.numPlaca,
                numSolicitud: resultSet.numSolicitud
            }


            
            await Llamada.getAdeudoCredito({
                numeroSolicitud: resultSet.numSolicitud,
                vin: resultSet.vin,
                contrato: resultSet.numContrato
            }).then(res => {
                let resp = res.data.data
                if (resp.status == 'proceso_correcto') {
                    rs.fechaUltimoPago = resp.ultimo_pago.split("-").reverse().join("-");
                    rs.cliente = resp.cliente;
                    rs.fechaLiquidacion = resp.termino_contrato.split("-").reverse().join("-");
                }
            })

            dispatch(setEncabezado(rs));
        })
        .catch(err => {
            console.log(err);
        });
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de gestoría</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <VehiculoGestoriaCard  claves={true} vin={vin}/>
                        </div>
                        <div className="col-6 px-md-3">
                            <ContratoGestoriaCard />
                        </div>
                    </div>
                </div>
            </section>
            <RegistroTramiteGestoriaCard />
        </>)
}

export default GestoriaCard