import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveEtapa } from '../../Redux/Slice/navigateSliceEtapa';
import { ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section } from '../../Auth/Authorization';

const SubmenuCambioEtapa = ({ handleSetStateTab }) => {
  let Reglas = [
    { value: 1, label: "Regla", active: ValidatePermissionById(3, Section.ConfiguraciónDeReglas) },
    { value: 2, label: "Historial", active: ValidatePermissionById(3, Section.HistorialDeReglas) },
  ];
  let Etapas = [
    { value: 4, label: "Orden de etapas", active: ValidatePermissionById(3, Section.ConfiguraciónDeEtapas) },
    { value: 5, label: "Historial", active: ValidatePermissionById(3, Section.HistorialDeEtapas) },
  ];

  const [defaultRegla, setDefaultRegla] = useState(1);
  const [defaultEtapa, setDefaultEtapa] = useState(0);

  const handleChangeRegla = (e) => {
    setDefaultEtapa(0);
    handleSetStateTab(e);
  }
  const handleChangeEtapa = (e) => {
    setDefaultRegla(0);
    handleSetStateTab(e);
  }
  return (
    <>
      <section className="nav nav-tabs">
        <div className='col-lg-4 col-sm-12 d-flex'>
          <select className="select-custome" placeholder="Proceso" value={defaultRegla} onChange={(e) => { handleChangeRegla(e); setDefaultRegla(e.target.value) }}>
            <option value="0">Seleccione</option>
            {
              Reglas?.map((item, index) => {
                if (item.active)
                  return <option key={index} value={item.value}>{item.label}</option>
              })
            }
          </select>
          <select className="select-custome" placeholder="Proceso" value={defaultEtapa} onChange={(e) => { handleChangeEtapa(e); setDefaultEtapa(e.target.value) }}>
            <option value="3">Seleccione</option>
            {
              Etapas?.map((item, index) => {
                return <option key={index} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </div>
      </section>
    </>
  )
}

export default SubmenuCambioEtapa