import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    idGarantiaDevolucion: "",
}

export const GarantiaDevolucionSlice = createSlice({
    name: 'GarantiaDevolucionSlice',
    initialState,
    reducers: {
        setIdGarantiaDevolucion: (state, action) => {
            return {...state,
                idGarantiaDevolucion: action.payload.data,
            }
        }
    }
})

export const { setIdGarantiaDevolucion } = GarantiaDevolucionSlice.actions
export default GarantiaDevolucionSlice.reducer