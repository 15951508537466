import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigateSliceSeguimientoTelefonico = createSlice({
  name: 'logNavigate',
  initialState,
  reducers: {
    tabActiveLog: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveLog } = navigateSliceSeguimientoTelefonico.actions

export default navigateSliceSeguimientoTelefonico.reducer