import axios from 'axios';
import AuthLogin from './AuthLogin';

const auth = new AuthLogin();

const ApiServiceFD = axios.create({
  baseURL: process.env.REACT_APP_API_AZURE,
  headers: {
    // 'Authorization': 'Bearer ' + auth.getSesionValue("token"),
    'Content-Type': 'multipart/form-data',
    // "Accept":"*/*",
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Accept-Encoding':'gzip, deflate, br',
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'credentials': 'same-origin'
  }
});
ApiServiceFD.interceptors.request.use(function (config) {
  // Do something before request is sent
  // config.headers.Authorization = 'Bearer ' + auth.getSesionValue("token");
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default ApiServiceFD;