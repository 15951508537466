import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveLog } from '../../../../Redux/Slice/NavigateSliceLogs';

const SubmenuCitasAgendadas = () => {
  const {value} = useSelector((state) => state.tabNavigateLogs);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  
  useEffect(() => {
    handleVigate(value);
  }, [value])
  

  const handleSetStateTab = (value) =>{
    dispatch(tabActiveLog(value));   
  }
  const handleVigate = (value) =>{
    if(value === 1){
      navigate(`/garantias/citas/agenda/garantias`);
    }
    if(value === 2){
      navigate(`/garantias/citas/agenda/gruas`);
    }
  }

  return (
    <>    
    <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab"  onClick={(e)=>{handleSetStateTab(e.target.value)}}>Citas de garantías</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab"  onClick={(e)=>{handleSetStateTab(e.target.value)}}>Citas de grúas</button>
                </li>            
            </ul>
    </>
  )
}

export default SubmenuCitasAgendadas