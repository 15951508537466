import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import Llamada from '../../../Services/Cartera/Llamadas';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import RevisionDatamovilCard from './RevisionDatamovilCard';

const CitaDesinstalacionCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, datamovilid } = useParams();


        let initial = {
            datamovilCitaDesinstalacionId: "",
            datamovilId: "",
            datamovilCitaDesinstalacionStatusId: 0,
            datamovilCitaDesinstalacionStatus: "",
            color: "default",
            fechaCita: null,
            horaCita: null,
            bloqueoId: null,
            sucursalName: null,
            autorizada: null,
            clienteNotificado: null,
            usuarioNotificado: null,
            fechaNotificado: null,
            concluido: null,
            fechaConclusion: null,
            usuarioAlta: "",
            usuarioAltaNombre: "",
            usuarioActualiza: null,
            usuarioActualizaNombre: null,
            status: false,
            fechaAlta: "",
            fechaActualiza: null
        }

        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const { dayCitaDatamovil, hourCitaDatamovil, sucursalId, sucursalName, calendarioIdCitaDatamovil  } = useSelector((state) => state.CitaDatamovilSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [cita, setCita] = useState('');

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getCitaDesinstalacion();
        }, []);
        
        useEffect(() => {
            if(hourCitaDatamovil){
                const formattedDate = `${dayCitaDatamovil.split("T")[0].split("-").reverse().join('-')} ${hourCitaDatamovil}`;
                setInitialValue({
                    ...initialValue,
                    fechaCita: `${dayCitaDatamovil.split("T")[0].split("-").reverse().join('-')}`,
                    horaCita: hourCitaDatamovil,
                    sucursalName: sucursalName
                });
                setCita(formattedDate);
            }
        },[hourCitaDatamovil]);
        

        /* CONSULTAS */
        const getCitaDesinstalacion = async () => {
            await Datamovil.datamovilCitaDesinstalacionGet(datamovilid)
            .then(resp => {
                setInitialValue(resp.data);
                if(resp.data.fechaCita !== null){
                    const formattedDate = `${resp.data.fechaCita?.split("T")[0].split("-").reverse().join('-')} ${resp.data.horaCita}`;
                    setCita(formattedDate);
                }

                if(resp.data.fechaNotificado){
                    resp.data.fechaNotificado = getDateFormat(resp.data.fechaNotificado, "");
                    
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            if(initialValue.datamovilCitaDesinstalacionStatusId === 10){
                if((hourCitaDatamovil !== null && hourCitaDatamovil !== undefined) && initialValue.fechaCita === null){
                    const dBloqueo = {
                        ubicacionid: sucursalId,
                        fecha: `${dayCitaDatamovil.split("T")[0]}`,
                        calendarioid: calendarioIdCitaDatamovil,
                        calendariotipoid: 8,
                        relacionid: datamovilid
                    }
                    await Llamada.crearCita(dBloqueo)
                    .then(resp => {
                        const data = resp.data.data;
                        setInitialValue({
                            ...initialValue,
                            fechaCita: dayCitaDatamovil.split("T")[0],
                            horaCita: hourCitaDatamovil,
                        });
                        
                        // const contenidoEmail = {
                        //     VARIABLE_EJECUTIVO: sessionStorage.nombreUsuario,
                        //     VARIABLE_CONTRATO : numContrato,
                        //     VARIABLE_MARCA : nombreMarca,
                        //     VARIABLE_MODELO : nombreModelo,
                        //     VARIABLE_AÑO : nombreYear,
                        //     VARIABLE_VERSION: nombreYear,
                        //     VARIABLE_VIN : vin,
                        // }
                        
                        // Garantias.creardatosenviar({
                        //     modulo: 'Aprobación Cita Entrega Documentos',
                        //     tipoCorreoId: 47,
                        //     contenido: JSON.stringify(contenidoEmail)
                        // }).catch(err => {
                        //     console.log("Error al enviar correo Aprobación Cita Entrega Documentos:", err);
                        // })

                        toast.success(message("Se agendó la cita correctamente"));
                        actualizarCita(data.bloqueoId, values)
                    })
                    .catch(err => {
                        toast.error(message("¡Error, al intentar agendar la cita!"));
                        console.error(err);
                    });
                }
            }else{
                if(values.clienteNotificado){
                    values.datamovilCitaDesinstalacionStatusId = 60
                }

                if(values.concluido){
                    values.datamovilCitaDesinstalacionStatusId = 70
                }

                await Datamovil.datamovilCitaDesinstalacionActualizar(values)
                .then(resp => {
                    setInitialValue(resp.data);
                    toast.success(message("Se actualizó la cita de desinstalación de datamovil correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar actualizar la cita de desinstalación de datamovil!"));
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                    if(values.clienteNotificado){
                        crearRevision();
                    }
                });
            }
        }

        const actualizarCita = async (bloqueoId, values) => {
            setLoading(true);
            let params = {
                sucursalName: sucursalName,
                bloqueoId: bloqueoId,
                datamovilCitaDesinstalacionStatusId: 20,
                datamovilId: datamovilid,
                fechaCita: `${dayCitaDatamovil.split("T")[0]}`,
                horaCita: hourCitaDatamovil
            }

            await Datamovil.datamovilCitaDesinstalacionActualizar(params)
            .then(resp => {
                setInitialValue(resp.data);
                toast.success(message("Se actualizó la cita de desinstalación de datamovil correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la cita de desinstalación de datamovil!"));
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }

        const crearRevision = async () => {
            let params = {
                datamovilId: datamovilid,
                DatamovilRevisionStatusId: 20,
                status: true
            }

            await Datamovil.datamovilRevisionCrear(params)
            .then(resp => {
                console.log(resp);
            })
            .catch(err => {
                console.error(err);
            })
        }

        const verCalendario = async (valor, values) => {
            dispatch(setSchedule({
                url: `procesocierre/datamovil/${id}/${generalid}/${datamovilid}`,
                contrato: numContrato,
                vin: vin,
                modelo: nombreModelo,
                ano: nombreYear,
                marca: nombreMarca,
            }));
            navigate(`/garantias/citas/${valor}`)
        }

        
        const verModalAutorizarCita = async (values) => {
            let formattedDate = "";
            if(initialValue.fechaCita === null || initialValue.fechaCita === undefined || initialValue.fechaCita === "") {
                formattedDate = `${dayCitaDatamovil.split("T")[0].split("-").reverse().join('-')} ${hourCitaDatamovil}`;
            }else{
                formattedDate = `${initialValue.fechaCita.split("T")[0].split("-").reverse().join('-')} ${initialValue.horaCita}`;
            }

            let data = {
                "generalId": generalid,
                "garantiaId": datamovilid,
                "folioCita": "",
                "numContrato": null,
                "vin": vin,
                "claveMarca": "",
                "nombreMarca": nombreMarca,
                "claveModelo": nombreModelo,
                "nombreModelo": nombreModelo,
                "claveYear": nombreYear,
                "nombreYear": nombreYear,
                "ubicacionId": sucursalId,
                "ubicacion": sucursalName,
                "tipoGarantiaId": null,
                "clasificacionFallaId": null,
                "telefono": "",
                "fechaCita": formattedDate,
                "_bloqueoId": initialValue.bloqueoId,
            }
    
            setModalMessage({ isOpen: true, type: 3, title: 'Detalle de cita', data: data, authorizationType: 8, Origin: "Listado" })
        }
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const resetForm = () => {
            refFormik.current.resetForm();
            setCita("");
            setInitialValue({
                ...initialValue,
                sucursalName: "",
            });
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            getCitaDesinstalacion={getCitaDesinstalacion}
                            reset={resetForm}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            modalMessage={modalMessage}
                            getCitaDesinstalacion={getCitaDesinstalacion}
                            reset={resetForm}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCitaExpediente" role="button" aria-expanded="false" aria-controls="collapseCitaExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cita desinstalación datamovil</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilCitaDesinstalacionStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseCitaExpediente">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Programar entrega</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.datamovilCitaDesinstalacionStatusId === 70}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <span>Fecha y hora de cita</span>
                                                        <div className='pt-1' style={containerStyle}>
                                                            <span>{cita}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn mt-4'
                                                            disabled={initialValue.datamovilCitaDesinstalacionStatusId !== 10 || initialValue.fechaCita !== null}
                                                            onClick={(e) => verCalendario(8, values)}>
                                                            Ver Calendario
                                                        </button>
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="sucursalName" disabled={true} label="Sucursal" name="sucursalName" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("sucursalName", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-2">
                                                        <button className='btn mt-4 col-12' disabled={initialValue.BloqueoId === null || initialValue.datamovilCitaDesinstalacionStatusId !== 20} onClick={(e) => verModalAutorizarCita(values)}>Autorizar cita</button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Notificar al cliente</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.datamovilCitaDesinstalacionStatusId === 70} type="checkbox" name="clienteNotificado" onClick={(event) => {
                                                            setFieldValue("clienteNotificado", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente notificado</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="usuarioNotificadoNombre" disabled={true} label="Usuario notificó al cliente" name="usuarioNotificadoNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioNotificadoNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="fechaNotificado" disabled={true} label="Fecha notificó al cliente" name="fechaNotificado" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("fechaNotificado", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Cliente arribo a cita</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.datamovilCitaDesinstalacionStatusId < 60 || initialValue.datamovilCitaDesinstalacionStatusId === 70} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente arribó a cita</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    initialValue.concluido &&
                    <RevisionDatamovilCard />
                }
            </>
        )
    })
export default CitaDesinstalacionCard