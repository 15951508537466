import axios from '../../Utils/ApiService';

const DiasFestivosService = {
    getDiaFestivos: async(data) => {
        return (await axios.get(`/Cartera/GetDiasFestivos`)).data;
    },
    SaveDiaFestivo: async(data) => {
        return (await axios.post(`/Cartera/SaveDiaFestivo`, data)).data;
    },
    DeleteDiaFestivo: async(data) => {
        return (await axios.post(`/Cartera/DeleteDiaFestivo`, data)).data;
    },

    //obtener dias habiles por año
    GetAnySemanaHabil: (data) => {
        return axios.post(`/cartera/GetAnySemanaHabil`, data);
    },
    UpsertSemanaHabil: (data) => {
        return axios.post(`/cartera/UpsertSemanaHabil`, data);
    }, 
}

export default DiasFestivosService