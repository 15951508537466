import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message, Normalize } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import SeguroSiniestrosService from '../../../../Services/Seguros/SeguroSiniestrosService';
import { selectScheduleByTipoCalendario } from '../../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useDispatch, useSelector } from 'react-redux';
import SeguimientoGestoriaService from '../../../../Services/Gestoria/SeguimientoGestoria';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import { concluirTramiteGestria } from '../../../../Redux/Slice/reloadSlice';

const auth = new AuthLogin();
const GestoriaProcesoDeTramiteCard = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);

    const { value } = useSelector((state) => state.reloadSlice);
    const dispatch = useDispatch();

    let initial = {
        gestoriaRegistroInicialId: id,
        gestoriaProcesoTramiteId: "",
        tipoTramiteId: "",
        tipoTramite: "",
        tipoContacto: "",
        fechaRealizacionTramite: "",
        placasDadasDeBaja: "",
        codEstatus: "",
        estatusTramite: "",
        motivoRechazoId: "",
        motivoRechazo: "",
        aNombreDeId: "",
        aNombreDe: "",
        aseguradora: "",
        comentarios: "",
        concluido: false,
        subido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 100,
        status: 2,
        procesoId: state?.procesoId
    }
    const TipoContacto = [{ value: 'virtual', label: 'Virtual' }, { value: 'presencial', label: 'Presencial' }];
    const opcionesBool = [{ value: 1, label: 'Aprobado' }, { value: 0, label: 'Rechazado' }];
    const [initialValue, setInitialValue] = useState(initial);
    const [motivoRechazo, setMotivoRechazo] = useState([]);
    const [NombresList, setNombresList] = useState([]);
    useEffect(() => {
        getData();
        handleGetAllMotivoRechazoDictamen();
        getNombres();
    }, []);

    const getData = async () => {
        setLoading(true)
        await SeguimientoGestoriaService.GetProcesoTramiteById(id)
            .then(resp => {
                let item = resp?.data.data;
                let newData = {
                    gestoriaRegistroInicialId: id,
                    gestoriaProcesoTramiteId: item?.gestoriaProcesoTramiteId ?? "",
                    tipoTramiteId: item?.tipoTramiteId ?? "",
                    tipoTramite: item?.tipoTramite ?? "",
                    // tipoTramite: "Cambio de propietario",
                    tipoContacto: item?.tipoContacto ?? "",
                    fechaRealizacionTramite: item?.fechaRealizacionTramite !== null ? item?.fechaRealizacionTramite?.split("T")[0] : "",
                    placasDadasDeBaja: item?.placasDadasDeBaja ?? "",
                    codEstatus: item?.codEstatus ?? "",
                    estatusTramite: item?.estatusTramite ?? "",
                    motivoRechazoId: item?.motivoRechazoId ?? "",
                    motivoRechazo: item?.motivoRechazo ?? "",
                    aNombreDeId: item?.aNombreDeId ?? "",
                    aNombreDe: item?.aNombreDe ?? "",
                    aseguradora: item?.aseguradora ?? "",
                    comentarios: item?.comentarios ?? "",
                    concluido: item?.concluido ?? false,
                    subido: item?.subido ?? false,
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state?.procesoId
                }
                setInitialValue(newData);
                if(item?.politicaGestoria)
                    dispatch(concluirTramiteGestria({ finalizarTramite: true }));
                handleConcluido({paso:3, concluido: item?.politicaGestoria ?item?.concluido ?? false : false, reload: Math.random(1, 10) });
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const validate = Yup.object().shape({
        tipoContacto: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
        fechaRealizacionTramite: Yup.string()
            .required("El campo es requerido."),
        // placasDadasDeBaja: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "interno",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        placasDadasDeBaja: Yup.string()
            .when(["tipoTramite"], (tipoTramite) => {
                if (Normalize(tipoTramite) === "bajadeplacas") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {
                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            return true
                        }
                    })
                }
            }),
        motivoRechazoId: Yup.string()
            .when(["tipoTramite", "tipoContacto"], (tipoTramite, tipoContacto) => {
                if (tipoContacto === "virtual") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {
                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            return true
                        }
                    })
                }
            }),
        estatusTramite: Yup.string()
            .when(["tipoTramite", "tipoContacto"], (tipoTramite, tipoContacto) => {
                if (tipoContacto === "virtual") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {
                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            return true
                        }
                    })
                }
            }),
        aNombreDeId: Yup.string()
            .when(["tipoTramite", "tipoContacto"], (tipoTramite, tipoContacto) => {
                if (Normalize(tipoTramite) === "cambiodepropietario" && tipoContacto === "presencial") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {
                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            return true
                        }
                    })
                }
            }),
        aseguradora: Yup.string()
            .when(["tipoTramite", "tipoContacto"], (tipoTramite, tipoContacto) => {
                if (Normalize(tipoTramite) === "cambiodepropietario" && tipoContacto === "presencial") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {
                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            return true
                        }
                    })
                }
            }),
    });
    const handleGetAllMotivoRechazoDictamen = async () => {
        setLoading(true)
        await Siniestros.GetMotivosById(0, state?.procesoId)
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.CitaMotivoRechazoId,
                        label: item.CitaMotivoRechazo
                    });
                });

                setMotivoRechazo(newData);
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            });
    }
    const submitForm = async (values) => {
        setLoading(true);
        await SeguimientoGestoriaService.UpdProcesoTramiteGestoria(values)
            .then(res => {
                if (res?.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res?.data.data.mensaje));
                    setInitialValue(initial);
                    getData();
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res?.data.data.mensaje));
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error(message("¡Error!", error.message));
                console.log(error)
            })
    }
    const getNombres = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.Nombre')
        .then(resp => {
            resp.data.forEach(item => {
                items.push({
                    value: item.NombreId,
                    label: item.Nombre
                });
            });

            setNombresList(items);
        })
        .catch(err => {
            console.error(err);
        });
    }
    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eDictRbo/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.valuacionInicio_RecoleccionUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseProcesoTramite" role="button" aria-expanded="false" aria-controls="collapseProcesoTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Proceso de Tramite</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseProcesoTramite">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-3'>
                                                    <h6>
                                                        <strong>Detalle de tramite</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField disabled={true} label="Tipo de trámite" name="tipoTramite" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Tipo de contacto"
                                                        disabled={initialValue?.concluido}
                                                        name="tipoContacto"
                                                        items={TipoContacto}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoContacto", event.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField disabled={initialValue?.concluido} label="Fecha de realización del tramite" name="fechaRealizacionTramite" type="date" holder="Escribe" />
                                                </div>
                                                {
                                                    (Normalize(values.tipoTramite) === "bajadeplacas" && values.tipoContacto !== "") &&
                                                    <div className="col-3">
                                                        <TextField disabled={initialValue?.concluido} label="Placas dadas de baja" name="placasDadasDeBaja" type="text" holder="Escribe" />
                                                    </div>
                                                }

                                            </div>
                                            {
                                                (values.tipoContacto === "virtual") &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField
                                                                label="Estatus"
                                                                disabled={initialValue?.concluido}
                                                                name="estatusTramite"
                                                                items={opcionesBool}
                                                                onChange={(event) => {
                                                                    setFieldValue("estatusTramite", event.value);
                                                                    setFieldValue("codEstatus", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField
                                                                label="Motivo rechazado"
                                                                disabled={initialValue?.concluido}
                                                                name="motivoRechazoId"
                                                                items={motivoRechazo}
                                                                onChange={(event) => {
                                                                    setFieldValue("motivoRechazoId", event.value);
                                                                }} />
                                                        </div>                                                        
                                                    </div>
                                                </>
                                            }
                                            {
                                                (Normalize(values.tipoTramite) === "cambiodepropietario" && values.tipoContacto === "presencial") &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField
                                                                label="Vehículo a nombre de"
                                                                disabled={initialValue?.concluido}
                                                                name="aNombreDeId"
                                                                items={NombresList}
                                                                onChange={(event) => {
                                                                    setFieldValue("aNombreDeId", event.value);
                                                                }} />
                                                        </div>                                                    
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} 
                                                            label="Aseguradora" 
                                                            name="aseguradora" 
                                                            type="text" holder="Escribe" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="row mt-2 px-4">
                                                <div className="col-8">
                                                    <TextAreaField
                                                        label="Comentarios"
                                                        disabled={initialValue?.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>

                                            {
                                                values.tipoContacto === "presencial" &&
                                                <div className='row mt-2 px-4'>
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Trámite concluido</label>
                                                    </div>
                                                </div>
                                            }

                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-12 col-lg-6">
                                                    <div className="row flex-column align-items-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaInicio !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.concluido &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GestoriaProcesoDeTramiteCard