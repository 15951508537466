import { Formik, Form, Field, FieldArray } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import CorreosService from '../../Services/Correos/Correos';
import { TextAreaField } from '../../Components/TextAreaField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { TextField } from '../../Components/TextField';
import SelectComponent from '../../Components/SelectComponent';
import CondicionComponent from './condicionComponent';
import DetonanteComponent from './DetonanteComponent';
import Spinner from '../../Components/Loadig';

const ModalReglaNegocio = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage }) => {
    const refbtn = useRef();
    let initial = {
        tipoReglaId: item?.tipoReglaId ?? "",
        etapaOrigenId: "",
        productoId: item?.productoId ?? "",
        tipoCompraId: item?.tipoCompraId ?? "",
        descripcion: "",
        etapaDestinoId: "",
        condiciones: [{ tipoCondicionId: "", condicionalId: "", catEstatusId: "" }],
        detonantes: [{ tipoDetonanteId: "", reglasNegocioPlazoId: "", momento: "", horario: "", horarioFin: "" }],
        status: true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataEtapa, setDataEtapa] = useState([]);
    const [dataTipoParametro, setDataTipoParametro] = useState([]);
    const [dataLapso, setDataLapso] = useState([]);
    const [dataDocumento, setDataDocumento] = useState([]);
    const [dataAccion, setDataAccion] = useState([]);
    const [dataCondiccion, setDataCondiccion] = useState([]);
    const [condicion, setCondicion] = useState([{ value: "", label: "" }]);
    const [loading, setLoading] = useState(false);

    const dataTipo = [
        { value: 1, label: "Documentos" },
        { value: 2, label: "Campos" },
    ];

    const dataCondicionalTipo1 = [
        { value: 1, label: "Doc 1", tipo: 1 },
        { value: 2, label: "Doc 2", tipo: 1 },
        { value: 3, label: "Doc 3", tipo: 1 },
        { value: 4, label: "Doc 4", tipo: 1 },
        { value: 5, label: "Doc 5", tipo: 1 },
    ];
    const dataCondicionalTipo2 = [
        { value: 1, label: "Campo 1" },
        { value: 2, label: "Campo 2" },
        { value: 3, label: "Campo 3" },
        { value: 4, label: "Campo 4" },
        { value: 5, label: "Campo 5" },
    ];

    useEffect(() => {
        handleGetAllEtapa();
        handleGetAllTipoParametro();
        handleGetAllLapso();
        handleGetAllAccion();

        if (item?.reglasNegocioId !== undefined)
            handleGetById(item?.reglasNegocioId);

    }, [])
    useEffect(() => {
        setDataCondiccion(dataTipo);
    }, [])

    const validate = Yup.object({
        descripcion: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        etapaOrigenId: Yup.string()
            .required("El campo es requerido."),
        etapaDestinoId: Yup.string()
            .required("El campo es requerido."),
        condiciones: Yup.array().of(
            Yup.object().shape({
                tipoCondicionId: Yup.string()
                    .required("El campo es requerido."),
                condicionalId: Yup.string()
                    .required("El campo es requerido."),
                catEstatusId: Yup.string()
                    .required("El campo es requerido."),
            })
        ),
        detonantes: Yup.array().of(
            Yup.object().shape({
                tipoDetonanteId: Yup.string()
                    .required("El campo es requerido."),
                reglasNegocioPlazoId: Yup.string()
                    .required("El campo es requerido."),
                momento: Yup.string()
                    .required("El campo es requerido."),
                horario: Yup.string()
                    .required("El campo es requerido."),
                horarioFin: Yup.string()
                .when("tipoDetonanteId",(tipoDetonanteId) => {
                    if (tipoDetonanteId == 1) {
                        return Yup.string()
                            .required("El campo es requerido.");
                }}),
            })
        )
    });
    const handleGetById = async (id) => {
        setLoading(true);
        await EtapaService.getAllReglaAutomaticaById(id)
            .then(res => {
                let item = res.data.data;
                let initial = {
                    reglaNegocioId: item?.reglasNegocioId,
                    tipoReglaId: item?.tipoReglaId ?? "",
                    etapaOrigenId: item?.etapaActualId ?? "",
                    productoId: item?.productoId ?? "",
                    tipoCompraId: item?.tipoCompraId ?? "",
                    descripcion: item?.descripcion ?? "",
                    etapaDestinoId: item?.etapaDestinoId ?? "",
                    condiciones: item?.condiciones ?? [{ tipoCondicionId: "", condicionalId: "", catEstatusId: "" }],
                    detonantes: item?.detonantes ?? [{ tipoDetonanteId: "", reglasNegocioPlazoId: "", momento: "", horario: "", horarioFin: "" }],
                    status: item?.status ?? true
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleChangeTipo = (value, index) => {
        if (value === 1)
            setDataDocumento(dataCondicionalTipo1);
        else if (value === 2)
            setDataDocumento(dataCondicionalTipo2);
    }

    const handleGetAllEtapa = async () => {
        await EtapaService.getAllCSEtapa()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.etapaId,
                        label: item.etapa
                    })
                });
                setDataEtapa(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }


    const handleGetAllTipoParametro = async () => {
        await EtapaService.getAllTipoParametro()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.parametroId,
                        label: item.parametro
                    })
                });
                setDataTipoParametro(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllLapso = async () => {
        await EtapaService.getAllLapso()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.lapsoId,
                        label: item.lapso
                    })
                });
                setDataLapso(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllDocumento = async () => {
        await EtapaService.getAllDocumento()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.documentoId,
                        label: item.documento
                    })
                });
                setDataDocumento(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllCondicion = async () => {
        await EtapaService.getAllCondicion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.condicionId,
                        label: item.condicion
                    })
                });
                setDataCondiccion(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetAllAccion = async () => {
        await EtapaService.getAllAccion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.accionId,
                        label: item.accion
                    })
                });
                setDataAccion(NewData);
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        if (item.action === "edit") {
            await EtapaService.updateReglaAutomatica(value)
                .then(res => {
                    if(res.data.data.ban === 1){
                        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje });
                        handleGetAll(1);    
                    }else{
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje });
                    }
                    setIsOpen(false);
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            await EtapaService.addReglaNegocioAutomatica(value)
                .then(res => {
                    if(res.data.data.ban === 1){
                        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje });
                        handleGetAll(1);    
                    }else{
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje });
                    }
                    setIsOpen(false);
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    const handleAddCondicion = (values, index, valuesConditional) => {
        valuesConditional[index] = values
    }
    const handleAddDetonante = (values, index, valuesDetonante) => {
        valuesDetonante[index] = values
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);                
            }}
        >
            {({ errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4 tbl-scroll' style={{ width: '50rem', height: '90vh', overflowY: 'auto' }}>
                            <Form style={{ width: '48rem' }}>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fs-5 mb-3'><strong>{item.action === "edit" ? 'Editar Regla' : 'Nueva Regla'}</strong></span>
                                        <div className="mb-3">
                                            {/* <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label> */}
                                            <div className="col-ms-6 form-check form-switch">
                                                <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                                <Field className="form-check-input" type="checkbox" name="status" />
                                            </div>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: '12px' }}>A continuación se puede configurar una nueva regla asignando una categoría y sus condiciones correspondientes.</span>
                                </div>
                                <div className="mb-3 row my-4">
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>1. Seleccionar la etapa correspondiente:</strong></label>
                                        <div className="col-sm-5 mb-2">
                                            <SelectField className="col-12" label="" name="etapaOrigenId" items={dataEtapa} onChange={(event) => {
                                                setFieldValue("etapaOrigenId", event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>2. Etapa destino:</strong></label>
                                        <div className="col-sm-5 mb-2">
                                            <SelectField className="col-12" label="" name="etapaDestinoId" items={dataEtapa} onChange={(event) => {
                                                setFieldValue("etapaDestinoId", event.value);
                                            }} />
                                            {/* <SelectField className="col-12" label="" name="parametroId" items={dataTipoParametro} onChange={(event) => {
                                                setFieldValue("parametroId", event.value);
                                            }} /> */}
                                        </div>

                                    </div>
                                    <div className="col-12 row  mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>3. Agregar las condiciones:</strong></label>
                                        <FieldArray
                                            name='condiciones'
                                            render={(arrayHelpers) => {
                                                return <div>
                                                    {
                                                        values.condiciones?.map((item, index) => {
                                                            return (
                                                                <CondicionComponent
                                                                    key={index}
                                                                    initialDet={item}
                                                                    index={index}
                                                                    handleClick={() => { arrayHelpers.remove(index) }}
                                                                    handleValue={(item) => { handleAddCondicion(item, index, values.condiciones) }}
                                                                    item={values}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    <label style={{ fontSize: '13px' }} onClick={() => { arrayHelpers.insert(values.condiciones.length + 1, {}) }}><strong>+ agregar condición</strong></label>
                                                </div>
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>4. Detonamiento:</strong></label>
                                        <FieldArray
                                            name='detonantes'
                                            render={(arrayHelpers) => {
                                                return <div>
                                                    {
                                                        values.detonantes?.map((item, index) => {
                                                            return (
                                                                <DetonanteComponent
                                                                    key={index}
                                                                    initialDet={item}
                                                                    index={index}
                                                                    handleClick={() => { arrayHelpers.remove(index) }}
                                                                    handleValue={(item) => { handleAddDetonante({ ...item }, index, values.detonantes) }}
                                                                    item={values}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    <label style={{ fontSize: '13px' }} onClick={() => { arrayHelpers.insert(values.detonantes.length + 1, {}) }}><strong>+ agregar detonante</strong></label>
                                                </div>
                                            }}
                                        />

                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>5. Descripción:</strong></label>
                                        <div className="col-sm-12">
                                            <TextAreaField label="" className="form-control col-12" rows="2" name="descripcion" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end row'>
                                    <button className='btn btn-outline px-5 col-sm-12 col-md-6 col-lg-3 mb-2' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5 col-sm-12 col-md-6 col-lg-3 mb-2' ref={refbtn} type="submit" >{item.action === "add" ? "Guardar" : "Editar"}</button>
                                    {/* disabled={!(isValid)} */}
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                </>
            )
            }
        </Formik>
    )
}

export default ModalReglaNegocio