import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import { TextAreaField } from '../../Components/TextAreaField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import RegimenFiscal from './RegimenFiscal';
import Spinner from '../../Components/Loadig';
import { SelectField } from '../../Components/SelectField';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';

const ClasificacionVendedor = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage,   existingData}) => {
    let initial = {
        clasificacionVendedorId: item?.clasificacionVendedorId ?? "",
        clasificacion: item?.clasificacion ?? "",
        porcentaje: item?.porcentaje ?? "",
        cuotaPorLead: item?.cuotaPorLead ?? "",
        status: item?.status ?? true
    }
    console.log(action)
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        clasificacion: Yup.string()
            .required("El campo es requerido."),
        cuotaPorLead: Yup.number()
            .required("El campo es requerido."),
        porcentaje: Yup.number().test({
            name: 'is-sku',
            skipAbsent: true,
            test(value, ctx) {

                if (value < 0) {
                    return ctx.createError({ message: 'ingrese un valor positivo ' })
                }
                if (value > 100) {
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 100' })
                }
                return true
            }
        })
    });

    const [errorMessage, setErrorMessage] = useState("");

    const handleAddEdit = async (data, initialValue ) => {
        if (action === "edit") {
            setLoading(true);
            await Catalogo.updateClasificacionVendedor(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setLoading(false);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (action === 'add') {
            setLoading(true);
            if (existingData.find(x=>x.clasificacion?.toLowerCase() === data.clasificacion?.toLowerCase())) {
                setErrorMessage("La clasificación ya existe.");
                setLoading(false);
                return;
            }
            await Catalogo.addClasificacionVendedor(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setLoading(false);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span
                                            className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="mb-3">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label
                                                className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox"
                                                   name="status"/>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-6">
                                            <TextField id="clasificacionId" label="Clasificación" name="clasificacion"
                                                       type="text" holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-6">
                                            <TextField id="porcentajeId" label="Porcentaje" name="porcentaje"
                                                       type="number" holder="Escribe"/>
                                        </div>
                                        <div className="col-sm-6">
                                            <TextField id="cuotaPorLeadId" label="Cuota por Lead" name="cuotaPorLead"
                                                       type="number" holder="Escribe"/>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-danger">{errorMessage}</span>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => {
                                        setIsOpen(false)
                                    }}>Cancelar
                                    </button>
                                    <button className='btn mx-2 px-5' type='submit' disabled={!(isValid)}>Continuar
                                    </button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}
export default ClasificacionVendedor