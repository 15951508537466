import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigateSliceAutorizacion = createSlice({
  name: 'autorizacionNavigate',
  initialState,
  reducers: {
    tabActiveAutorizacionSolicitudes: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveAutorizacionSolicitudes } = navigateSliceAutorizacion.actions

export default navigateSliceAutorizacion.reducer