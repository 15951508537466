import axios from '../../../Utils/ApiService';

const CobroComisionesExternoService = {
    getAllPendientesCobroComisionExterno: async (page, rows, string,TipoCompraId,RazonSocial,ProcedenciaClienteId,fechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllCobroComisionExterno?page=${page}&Rows=${rows}&TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&ProcedenciaClienteId=${ProcedenciaClienteId}&FechaInicio=${fechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    },  
    ExportPendientesCobroComisionExterno: async (data) => {
        return await axios.get(`/functions/ExportarPendientesCobroComisionExterno?Ids=${data.ids}&Folio=${data.folio}&TotalVehiculos=${data.totalVehiculos}&TotalAPagar=${data.totalAPagar}`, { responseType: 'blob' });
    },
    getAllProcesoCobroComisionExterno: async (page,rows,FechaInicio,FechaFin,str) => {
        return await axios.get(`/functions/GetAllProcesoCobroComisionExterno?page=${page}&Rows=${rows}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`);
    },
    SaveProcesoCobroComisionExterno:async(data)=>{
        return axios.post('/functions/SaveProcesoCobroComisionExterno',data);
    },
    getAllCobroComisionesExternoPagada: async (page, rows, string,TipoCompraId,RazonSocial,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllCobroComisionExternoPagada?page=${page}&Rows=${rows}&TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    }, 
    ExportCobroComisionesExternoPagada: async (TipoCompraId,RazonSocial,str,FechaInicio,FechaFin) => {
        return await axios.get(`/functions/ExportCobroComisionExternoPagada?TipoCompraId=${TipoCompraId}&RazonSocial=${RazonSocial}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`,{ responseType: 'blob' });
    }, 
    SaveProcesoCobroComisionExternoDetalle:async(data)=>{
        return axios.post('/functions/SaveProcesoCobroComisionExternoDetalle',data);
    },
    ReemprimirPendientesDePagoComisionExterno: async (folio) => {
        return await axios.get(`/functions/ReExportarPendientesCobroComisionExterno?Folio=${folio}`, { responseType: 'blob' });
    },
    
}

export default CobroComisionesExternoService