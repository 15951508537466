import React from 'react'
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import SubmenuCat from '../../Components/SubmenuCat'

const Datamovil = () => {

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % 100;
    
    // setItemOffset(newOffset);
    
  };
  return (    
    <>
    <SubmenuCat/>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Fecha y hora</th>
            <th scope="col">Tiempo de sincronización</th>
            <th scope="col">Cant. Actualizaciones</th>
            <th scope="col">Registros nuevos</th>
            <th scope="col">Resultados de ejecucion</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>
              <button className='btn'>ADD</button>
              <button className='btn'>Edit</button>
              <button className='btn'>Delete</button>
              <button className='btn'>Detail</button>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
            <td>@fat</td>
            <td>
              <button className='btn'>ADD</button>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
            <td>@fat</td>
            <td>
              <button className='btn'>ADD</button>
            </td>
          </tr>          
        </tbody>
      </table>
     
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={20}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          />
    </>
  )
}

export default Datamovil