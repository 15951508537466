import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HorariosCalendarioService from "../../Services/ConfigCalendar/HorariosCalendarioService";

const SeguimientoServiciosDatamovil = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const initial = {
        buscar: ''
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState("");


    const columns = [
        { field: 'tipo', headerName: 'Servicio', width: '340' },
        { field: 'duracionAtencion', headerName: 'Duración', width: '140' },
        { field: 'ubicacionesConcatenadas', headerName: 'Ubicación' },
    ];

    useEffect(() => {
        getData();
    }, []);

    
    useEffect(() => {
        getData();
    }, [buscar, page, rows]);

    

    const getData = async () => {
        setLoading(true);
        const params = {
            buscar,
            page,
            rows
        };
        await HorariosCalendarioService.getSeguimiento(params)
            .then(resp => {
                setData(resp.data.data);
                setTotalPages(resp.data.totalPages);
                setTotalRows(resp.data.totalRows);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    
    const handlePageClick = (numPage) => {
        setPage(numPage.selected + 1);
    }

    const handleDetail = (item) => {
        navigate(`/admin/servicios-datamovil/${item.calendarioTipoId}`, { state: item });
    }


    return {
        refFormik,
        initialValue,
        page, 
        setPage,
        rows,
        setRows,
        data,
        loading,
        handlePageClick,
        setBuscar,
        columns,
        handleDetail,
        totalPages,
        totalRows,
        getData,
        modalMessage,
        setModalMessage
    }
}

export default SeguimientoServiciosDatamovil;