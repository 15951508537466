import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';
import { tabActiveCancelacionSeguro } from '../../../Redux/Slice/navigateSliceCancelacionSeguro';

const SubMenuCancelacionSeguro = () => {
  const { value } = useSelector((state) => state.tabCancelacionSeguro);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveCancelacionSeguro(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.PolizaEmitida) && value === 1) {
      navigate(`/seguro/cancelacion/polizaemitida`);
    }
    else if (ValidatePermissionById(3, Section.PolizaPagada) && value === 2) {
      navigate(`/seguro/cancelacion/polizapagada`);
    }
    else if(ValidatePermissionById(3, Section.PendientesDeDesembolsoDePago) && value === 3){
      navigate(`/seguro/cancelacion/pendientedesembolsopago`);
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PolizaEmitida)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Póliza emitida</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PolizaPagada)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Póliza pagada</button>
          </li>
        </ValidatePermission>    
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PendientesDeDesembolsoDePago)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pendientes de desembolso del pago</button>
          </li>
        </ValidatePermission>    
      </ul>
    </>
  )
}

export default SubMenuCancelacionSeguro