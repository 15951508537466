import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate } from 'react-router-dom';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { FormatNumberMoney2, message } from '../../../Utils/functions';
import Spinner from '../../../Components/Loadig';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';

const CrearGrupoModal = ({ isOpen, setIsOpen, tipoVehiculo,handleSubmit }) => {
    const refbtn = useRef();
    const navigate = useNavigate();
    let initial = {
        tipoVehiculoId: "",
        ordenId: "",
        presupuestoDeBolsa:""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [datTipoVehiculo, setdatTipoVehiculo] = useState([]);
    const [MontoMinimoCheque, setMontoMinimoCheque] = useState("");
    const [MontoMaximoCheque, setMontoMaximoCheque] = useState("");
    const [loading, setLoading] = useState(false);
    let dataOrden = [
        { label: "Monto", value: 1 },
        { label: "Días sin consultar clave", value: 2 },
        { label: "Días vencidos", value: 3 },
    ];
    useEffect(() => {
        setdatTipoVehiculo(tipoVehiculo.filter(x => x.value !== ""));
        handleGetAllSelects();
    }, [tipoVehiculo])

    const validate = Yup.object({
        tipoVehiculoId: Yup.number()
            .required("El campo es requerido."),
        ordenId: Yup.number()
            .required("El campo es requerido."),
        presupuestoDeBolsa: Yup.number()
            .required("El campo es requerido.")
    });
    const handleGetMontoMinimoCheque = async () => {
        await IntelimotorAPI.getValorParametro("MontoMinimoCheque")
            .then(res => {
                setMontoMinimoCheque(res.data.data.valor);
            }).catch(e => {
            })
    }
    const handleGetMontoMaximoCheque = async () => {
        await IntelimotorAPI.getValorParametro("MontoMaximoCheque")
            .then(res => {
                setMontoMaximoCheque(res.data.data.valor);
            }).catch(e => {
            })
    }
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetMontoMinimoCheque(), handleGetMontoMaximoCheque()]).finally(() => {
            setLoading(false)
        });
    }
    const handleAddEdit = async (item) => {
        handleSubmit(item)
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit({...values,MontoMinimoCheque,MontoMaximoCheque});
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-2 mx-4' style={{ width: '30rem' }}>
                                <Form>
                                    <span className='fs-5'><strong>Crear grupos</strong></span>
                                    <div className="mb-3">
                                        <label style={{ fontSize: '13px' }} className="col-form-label"><strong>Rango: ${FormatNumberMoney2(MontoMinimoCheque)} - ${FormatNumberMoney2(MontoMaximoCheque)}</strong></label>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8">
                                            <TextField id="presupuestoDeBolsaId" label="Presupuesto de bolsa($)" name="presupuestoDeBolsa" type="number" holder="Escribe" />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-6">
                                            <SelectField id="tipovehiculoid" label="Tipo de vehículo" name="tipoVehiculoId" items={datTipoVehiculo} onChange={(event) => {
                                                setFieldValue("tipoVehiculoId", event.value);
                                            }} />
                                        </div>
                                        <div className="col-sm-6">
                                            <SelectField id="ordenId" label="Ordenar por" name="ordenId" items={dataOrden} onChange={(event) => {
                                                setFieldValue("ordenId", event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <section className='my-3 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2 col-3' ref={refbtn} type="submit" disabled={!(isValid)}>Crear</button>
                                    </section>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )

            }

        </Formik>
    )
}

export default CrearGrupoModal