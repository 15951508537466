import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { validateManySubModule, ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { SubModule } from '../Auth/Authorization';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch, useSelector } from 'react-redux';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();
const SeguroSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { visible } = useSelector((state) => state.changeMenu);

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }
    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }

    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Seguros"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.PolizasVigentes),
                        ValidatePermissionById(2, SubModule.PolizasVigentes)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GS" : "Gestión de seguros"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SeguimientoSiniestro)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/siniestros" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Seguimiento de siniestros">
                                <i className="icon-dark bx bx-shield"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento de siniestros</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PolizasVigentes)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/polizasvigentes" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Pólizas vigentes">
                                <i className="icon-dark bx bxs-message-alt-check"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pólizas vigentes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.VehículosCotizados),
                        ValidatePermissionById(2, SubModule.VehículosSinCotización),
                        ValidatePermissionById(2, SubModule.CotizaciónMensual),
                        ValidatePermissionById(2, SubModule.SeguroAutorizado),
                        ValidatePermissionById(2, SubModule.RenovaciónDeSeguros),
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "CS" : "Cotización Seguro"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosCotizados)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/cotizado" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos cotizados">
                                <i className="icon-dark bx bxs-message-alt-check"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos cotizados</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosSinCotización)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/sincotizar" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos sin cotización">
                                <i className="icon-dark bx bxs-message-alt-x"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos sin cotización</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.CotizaciónMensual)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/cotizacionmensual" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cotización mensual">
                                <i className="icon-dark bx bxs-calculator"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cotización mensual</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SeguroAutorizado)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/autorizado" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Seguro autorizado">
                                <i className="icon-dark ri-shield-cross-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguro autorizado</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.RenovaciónDeSeguros)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/renovacionseguro" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Renovación de seguros">
                                <i className="icon-dark bx bx-revision"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Renovación de seguros</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.EmisiónDePolizas),
                        ValidatePermissionById(2, SubModule.PagoYDesembolso),
                        ValidatePermissionById(2, SubModule.Comisiones)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GP" : "Gestión de Pago"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.EmisiónDePolizas)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/poliza" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Emisión de póliza">
                                <i className="icon-dark ri-file-list-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Emisión de póliza</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PagoYDesembolso)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/pagodesembolso" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Pago de Poliza">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pago de Poliza</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Comisiones)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/comisiones" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Comisiones">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Comisiones</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.CancelacionDeSeguro),
                        ValidatePermissionById(2, SubModule.PolizasCanceladas),
                        ValidatePermissionById(2, SubModule.SegurosSinRenovar)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "SC" : "Seguros Cancelados"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.CancelacionDeSeguro)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/cancelacion" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cancelación de seguro">
                                <i className="icon-dark bx bx-x-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cancelación de seguro</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PolizasCanceladas)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/polizacancelada" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Pólizas canceladas">
                                <i className="icon-dark bx bx-x-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pólizas canceladas</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SegurosSinRenovar)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/vehiculosinrenovar" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Seguros sin renovar">
                                <i className="icon-dark bi bi-shield-x"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguros sin renovar</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.AutorizacionDeSolicitudes),
                        ValidatePermissionById(2, SubModule.SeguimientoBoletinadoSeguro),
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GS" : "Gestión de solicitudes"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.AutorizacionDeSolicitudes)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/autorizacion" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Autorización de solicitudes">
                                <i className="icon-dark ri-file-list-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Autorización de solicitudes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SeguimientoBoletinadoSeguro)}
                    >
                        <li className="nav-item">
                            <NavLink to="/seguro/seguimientoboletinado" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Autorización de solicitudes">
                                <i className="icon-dark ri-shield-user-fill"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento boletínado</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                </ul>
            </aside>
        </>
    )
}

export default SeguroSidebar