import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleRadioCheckItem, handleUnchekedColumns, message } from '../../Utils/functions';
import vehiculoSeparadoService from '../../Services/Venta/Vehiculo/vehiculoSeparadoService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';

const useVehiculoEnganche = (toast) => {
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },    
    { field: 'nombreMarca', headerName: 'Marca', width: 130 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'numSolicitud', headerName: 'No. Solicitud', width: 150 },
    { field: 'tipoCompra', headerName: 'Tipo compra', width: 130 },
    { field: 'producto', headerName: 'Producto', width: 200 },
    { field: 'fechaEnganche', headerName: 'Fecha enganche', width: 200 },
  ];
   

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [claveTipocompra, setclaveTipocompra] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [TipoCompra, setTipoCompra] = useState([]);
  //DataDocs
  const [pageRows, setpageRows] = useState(10)
  const [item, setItem] = useState(null);
  const [isOpenModalLiberar, setIsOpenModalLiberar] = useState(false);
  //
  useEffect(() => {
    handleUnchekedColumns()
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [str, pageRows,claveTipocompra])
  useEffect(() => {
    handleGetTipoCompra();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await vehiculoSeparadoService.GetAllVehiculoEnganche(page, pageRows, str,claveTipocompra)
      .then(res => {        
        setData(res.data.data);
        
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetTipoCompra = async () => {
    await SeguroPolizaService.getAllTipocompra()
        .then(res => {
            let NewData = [];
            NewData.push({
                value: "",
                label: "Todos"
            })
            res.data.data?.map(item => {
                NewData.push({
                    value: item.tipoCompraId,
                    label: item.tipoCompra
                })
            });
            setTipoCompra(NewData);
        }).catch(e => {
        })
}
  const handlechekedItem = (cheked, value,i) => {    
    if(value.isLiberar === 0){
      handleUnchekedColumns()
      toast.custom( <div className='warnin-toast'>{message("¡Alerta.!", "El tiempo de liberación de enganche ha pasado.")}</div>);   
      setItem(null);
      return;       
    }

    handleRadioCheckItem(i);

    if (cheked) {
        setItem(value);
    } else {
        setItem(null);
    }
   
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };


  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    handlechekedItem,
    setpageRows,
    setIsOpenModalLiberar,
    isOpenModalLiberar,
    habdleGetAll,
    item,
    TipoCompra,
    setclaveTipocompra
  }
}

export default useVehiculoEnganche