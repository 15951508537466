import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';

const useSeguroSinCotizar = () => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 150 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'nombreYear', headerName: 'Año', width: 100 },
    { field: 'nombreVersion', headerName: 'Versión', width: 260 },
    // { field: 'tipoCompra', headerName: 'Tipo de compra', width: 250, short: false },
    { field: 'fechaAdquisicionStr', headerName: 'Fecha adquisición', width: 250 },
  ];
  let DataFiltroFolder = [
    { label: "Todos", value: "" },
    { label: "Vehículos con folder", value: 1 },
    { label: "Vehículos sin folder", value: 0 },
  ];
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [tipoAd, setTipoAd] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [DataMarca, setDataMarca] = useState([]);
  const [DataModelo, setDataModelo] = useState([]);
  const [DataYear, setDataYear] = useState([]);
  const [DataVersion, setDataVersion] = useState([]);
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [filtroDocs, setfiltroDocs] = useState("");
  //Filtro
  const [pageRows, setpageRows] = useState(10);

  const [claveMarca, setClaveMarca] = useState("");
  const [claveModelo, setClaveModelo] = useState("");
  const [claveAÑo, setClaveAÑo] = useState("");
  const [claveVersion, setClaveVersion] = useState("");
  const [defaultValueModelo, setDefaultValueModelo] = useState({label:"Modelo",value:""});
  const [defaultValueYear, setDefaultValueYear] = useState({label:"Año",value:""});
  const [defaultValueVersion, setDefaultValueVersion] = useState({label:"Versión",value:""});

  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [str, claveMarca, claveModelo, claveAÑo, claveVersion,pageRows])
  useEffect(() => {
    handleGetAllMarca();
  }, [])


  const habdleGetAll = async (page) => {
    setLoading(true);
    await SeguroSinCotizarService.getlAllVehiculoSinCotizacion(page, pageRows, claveMarca, claveModelo, claveAÑo, claveVersion, str)
      .then(res => {
        
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetAllMarca = async () => {
    await VehiculoService.getAllMarca()
      .then(res => {
        let NewData = [];        
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveMarca,
            label: item.nombreMarca
          })
        });
        setDataMarca(NewData);
      }).catch(e => {
      })
  }
  const handleChangeMarca = async (value) => {
    setClaveMarca(value);
    setDefaultValueModelo({ label: "Modelo", value: "" })
    setDefaultValueYear({ label: "Año", value: "" })
    setDefaultValueVersion({ label: "Versión", value: "" });

    setDataYear([]);
    setDataVersion([])

    await VehiculoService.getAllModeloById(value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveModelo,
            label: item.nombreModelo
          })
        });
        setDataModelo(NewData);
      }).catch(e => {
      })
  }
  const handleChangeModelo = async (value) => {
    setClaveModelo(value.value);
    setDefaultValueYear({label:"Año",value:""});
    setDefaultValueVersion({ label: "Versión", value: "" })
    setDefaultValueModelo(value);

    setDataVersion([])
    await VehiculoService.getAllYearById(claveMarca, value.value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveYear,
            label: item.nombreYear
          })
        });
        setDataYear(NewData);
      }).catch(e => {
      })
  }
  const handleChangeYear = async (value) => {
    setClaveAÑo(value.value);
    setDefaultValueVersion({ label: "Versión", value: "" })
    setDefaultValueYear(value)    
    await VehiculoService.getAllVersionById(claveMarca, claveModelo, value.value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveVersion,
            label: item.nombreVersion
          })
        });
        setDataVersion(NewData);
      }).catch(e => {
      })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleEdit = (value) => {
    navigate(`/seguro/sincotizar/seguro/${value.generalId}`, {
      state: {...value,action:0,disableSelect:true}
    });
  }
  const handleCreateFolder = (item) => {
    
    setDataVehiculo(item);
    setIsOpenDocs(true)
  }
  const handleSort = (value) => {
    
    habdleGetAll(1, value);
  }
  return {
    columns,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    DataMarca,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    setfiltroDocs,
    habdleGetAll,
    handleChangeMarca,
    handleChangeModelo,
    handleChangeYear,
    setClaveVersion,
    DataModelo,
    DataYear,
    DataVersion,
    defaultValueModelo,
    defaultValueYear,
    defaultValueVersion,
    setDefaultValueVersion,
    setpageRows
  }
}

export default useSeguroSinCotizar