import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import Spinner from '../../../Components/Loadig';
import { SelectField } from '../../../Components/SelectField';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import { v4 as uuidv4 } from 'uuid';
import handlePhonNumberFormatter, { FormatFecha } from '../../../Utils/functions';
import { Exception } from 'sass';

const EditarCobradorModal = ({ isOpen, setIsOpen, setAction=null, item, nivel }) => {
   const refbtn = useRef();
   const [fechaInicio, setFechaInicio] = useState("01/01/1970");
   const [fechaFin, setFechaFin] = useState("01/01/1970");
   const [opcionDisponibilidad, setOpcionDisponibilidad] = useState(0);
   const [fechaValida, setFechaValida] = useState(false);
   const username = sessionStorage.getItem('usuario')
   const userId = sessionStorage.getItem('userId').trim()
   const [currentDate, setCurrentDate] = useState("")
   const [date, setDate] = useState("")
   let initial = {
      nombrecobrador: item?.nombrecobrador,
      usuariosistema: item?.usuariosistema,
      telefono: handlePhonNumberFormatter(item?.telefono),
      estatussistema: "",
      nivelcobranza: nivel === 1 ? 1 : (nivel === 2 ? 2 : ""),
      labelnivel: item?.nivel_cobrador?.label
   }

   useEffect(()=>{
      let fechaActual = new Date()
      setDate(FormatFecha(fechaActual))
      setCurrentDate(`${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}-${String(fechaActual.getDate()).padStart(2, '0')}`)
   },[])

   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const dataNiveles = [
      { value: 1, label: "Nivel 1"},
      { value: 2, label: "Nivel 2"},
      { value: 3, label: "Nivel 3"},
      { value: 4, label: "Nivel 4"},
   ]

   const dataDisponibilidad= [
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Vacaciones' },
      { value: 3, label: 'Permisos' },
      { value: 4, label: 'Enfermedad' },
      { value: 5, label: 'Baja' }
   ];

   const validate = Yup.object({
      nivelcobranza: Yup.string()
         .required("El campo es requerido."),
      telefono: Yup.string()
         .required("El campo es requerido."),
      estatussistema: Yup.string()
         .required("El campo es requerido."),
   });

   const handleEditar = async (values) => {
      setLoading(true);
      let diaActualEnRango = false

      const id = uuidv4();
      let fechaActual = new Date()
      let DiasInhabiles = []

      if(values.estatussistema === "Vacaciones" || values.estatussistema === "Permisos" || values.estatussistema === "Enfermedad"){
         let dias = ((new Date(`${fechaFin} 00:00`) - new Date(`${fechaInicio} 00:00`))/(24 * 60 * 60 * 1000)) + 1
         let fechaIni = new Date(`${fechaInicio} 00:00`)

         for (var x = 0; x < dias; x++) {
            let fecha = new Date()
            if(x === 0){
               fecha = new Date(fechaIni.setDate(fechaIni.getDate()))
            }
            else{
               fecha = new Date(fechaIni.setDate(fechaIni.getDate() + 1))
            }

            if(FormatFecha(fecha) === date){
               diaActualEnRango = true;
            }

            DiasInhabiles.push({
               "id":`${item.userid}_${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, '0')}-${String(fecha.getDate()).padStart(2, '0')}`,//id +_+yyyy-MM-dd
               "userid":item.userid,
               "fecha":fecha, //fecha inhabil
               "enabled":0
            })
         }
      }

      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "level": 1,
         "flag": true,
         "anyObject": {
               "userid": item?.userid,
               "nivel_cobrador": values.nivelcobranza.toString(),
               "zona":"Por asignar",
               "activo": values.estatussistema !== "Baja" ? 1 : 0,
               "numTelefono":values.telefono,
               "user_detail": null,
               "contratos":null,
               "disponible": values.estatussistema,
               "status": values.estatussistema,
               "rangoFechas": null,
               "diasInhabiles": DiasInhabiles.length > 0 ? DiasInhabiles : null ,
               "historialStatus": [
                  {
                     "id": id,
                     "user_id":item?.userid,
                     "status": values.estatussistema,
                     "fecha_inicio": DiasInhabiles.length > 0 ? new Date(`${fechaInicio} 00:00`) : null,
                     "fecha_fin": DiasInhabiles.length > 0 ? new Date(`${fechaFin} 00:00`) : null,
                     "user_register": userId,
                     "fecha_hora_registro": fechaActual,
                  }
               ],
         }
      }

      console.log(data)
      
      await CobranzaService.createEjecutivoCobranza(data)
         .then(res => {
            if(res.data.callSucceded){
               if((values.estatussistema === "Baja" && item?.contratos !== null && item?.contratos.length > 0) 
                  || (values.nivelcobranza.toString() !== item?.nivel_cobrador.value.toString() && item?.contratos !== null && item?.contratos.length > 0)
                  || (diaActualEnRango && item?.contratos !== null && item?.contratos.length > 0)){
                     distribucionDeCarga().then(resp => {
                        // toast.success(message("¡Operación exitosa!", "Distribución de carga completada"));
                        if(setAction !== null){
                           setAction("update");
                        }
                        setIsOpen(false);
                        setLoading(false);
                        navigate(-1)
                     }).catch(error => {
                        // toast.error(message("¡Operación fallida!", "Error al procesar"));
                        console.log(error);
                     })
               }
               else{
                  if(setAction !== null){
                     setAction("update");
                  }
                  setIsOpen(false);
                  setLoading(false);
               }
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
   }

   const distribucionDeCarga = async () => {
      await CobranzaService.distribucionDeCarga({})
         .then(res => {
            if(!res.data.callSucceded){
               throw Exception(res.data.message);
            }
         })
         .catch(e => {
            console.log(`ERROR -- ${e}`)
            setLoading(false);
         });
   }

   const handleValidarFechas = (tipoFecha, fecha) => {
      if(tipoFecha === 1){
         let validarFecha = fechaFin >= fecha;
         if(validarFecha){
            setFechaInicio(fecha);
            setFechaValida(true);
         }
         else{
            setFechaInicio(fecha);
            setFechaValida(false);
         }
      }
      if(tipoFecha === 2){
         let validarFecha = fecha >= fechaInicio;
         if(validarFecha && fechaInicio !== "01/01/1970"){
            setFechaFin(fecha);
            setFechaValida(true);
         }
         else{
            setFechaFin(fecha);
            setFechaValida(false);
         }
      }
   }

   const handleDisponibilidad = (value) => {
      setOpcionDisponibilidad(value);
      if(value === 0 || value === 2 || value === 3 || value === 4){
         setFechaInicio("01/01/1970");
         setFechaFin("01/01/1970");
         setFechaValida(false);
      }
      else{
         setFechaInicio("NA");
         setFechaFin("NA");
         setFechaValida(true);
      }
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
            handleEditar(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
               <Modal isOpen={isOpen} color='#fff'>
               <section className='my-2 mx-4' style={{ width: '40rem', height: (opcionDisponibilidad === 2) || (opcionDisponibilidad === 3) || (opcionDisponibilidad === 4) ? '40rem' : '35rem' }}>
                     <Form>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Editar cobrador </strong></span>
                        <div className="mb-3 row" style={{marginTop:30}}>
                              <div className="col-sm-6">
                                 <TextField label="Nombre" name="nombrecobrador" type="text" holder={values.nombrecobrador} disabled={true} />
                              </div>
                              <div className="col-sm-6">
                                 <TextField label="Usuario sistema" name="usuariosistema" type="text" holder={username} disabled={true} />
                              </div>
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <div className="col-sm-6">
                              {
                                 nivel === 1 || nivel === 2
                                 ?<TextField label="Nivel de cobranza" name="labelnivel" type="text" disabled={true} />
                                 :<SelectField label="Nivel de cobranza" items={dataNiveles} name="nivelcobranza"  onChange={(e) => {
                                    setFieldValue("nivelcobranza", e.value !== 0 ? e.value : "");
                                 }} />
                              }
                           </div>
                           <div className="col-sm-6">
                              <TextField label="Número de teléfono" min={0} name="telefono" type="text" holder="Escribe"
                                 onChange={(event) => {setFieldValue("telefono", handlePhonNumberFormatter(event.target.value));}} />
                           </div>
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <span className='fs-6' style={{fontWeight:'bold', color:"#000"}}><strong>Estatus de sistema</strong></span>
                           <p>El administrador puede modificar el estatus cuando lo vea necesario, en caso de que el cobrador solicite algún permiso o vacaciones el administrador deberá seleccionar el periodo solicitado.</p>
                           <div className="col-sm-6">
                              <SelectField name="estatussistema" items={dataDisponibilidad} onChange={(e) => {
                                 handleDisponibilidad(e.value);
                                 setFieldValue("estatussistema", e.value !== "" ? e.label : "");
                              }} />
                           </div>
                        </div>
                        {
                           ((opcionDisponibilidad === 2) || (opcionDisponibilidad === 3) || (opcionDisponibilidad === 4)) && (
                              <div className="mb-3 row" style={{marginTop:30}}>
                                 <span className='mx-1 mb-3'>Agrega las fechas</span>
                                 <div className="col-sm-6">
                                    <input type="date" min={currentDate} defaultValue={''} className="form-control mb-4" placeholder="Fecha inicio" onChange={(e) => { handleValidarFechas(1, e.target.value); }} />
                                 </div>
                                 <div className="col-sm-6">
                                    <input type="date" min={currentDate} className="form-control mb-4" placeholder="Fecha fin" onChange={(e) => { setFechaFin(e.target.value); handleValidarFechas(2, e.target.value); }} />
                                 </div>
                              </div>)
                        }
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)|| !(fechaValida) || (fechaInicio < currentDate) || (fechaFin < currentDate)}>Guardar</button>
                        </section>
                     </Form>
                  </section>
               </Modal>
               {
                  loading &&
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
               }
            </>
         )
      }
   </Formik>
   )
}

export default EditarCobradorModal