import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Logs from '../../Services/Logs/Logs';
import { getHourFormat } from '../../Utils/functions';

const useUsuario = () => {
  let ids = [];
  const refBtn = useRef();
  const refBtnExp = useRef();
  const columns = [
    { field: "logUsuarioId", headerName: "Id", width: 100 },
    { field: "vin", headerName: "VIN", width: 200 },
    { field: "nombreUsuario", headerName: "Usuario", width: 200 },
    { field: "modulo", headerName: "Modulo", width: 150 },
    { field: "dateOnly", headerName: "Fecha", width: 160 },
    { field: "hora", headerName: "Hora", width: 150 },
    { field: "categoria", headerName: "Categoria", width: 200 },
    { field: "resultado", headerName: "Resultado", width: 200 },
    { field: "contenido", headerName: "contenido", width: 350 },
  ];

  const [data, setData] = useState([]);
  const [dataModulo, setDataModulo] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTime, setDateTime] = useState("");
  const [Str, setStr] = useState("");
  const [moduloId, setModuloId] = useState("");
  const [procesoId, setProcesoId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [isenablebtn, setIsenablebtn] = useState(true);
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [pageRows, setpageRows] = useState(10)
  useEffect(() => {
    handleGetModulo();
    // handleGetProceso();
    handleGetCategoria()
  }, [])

  useEffect(() => {
    handleGetAllUsuario(1);
    setInitialPage(0);
  }, [Str, dateTime, moduloId, categoriaId,pageRows])

  //selectList
  const handleGetModulo = async () => {
    // setLoading(true);
    await Logs.getList("modulo")
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos los Modulos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataModulo(NewData);
        // setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetProceso = async () => {
    setLoading(true);
    await Logs.getList("proceso")
      .then(res => {

        setDataProceso(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetCategoria = async () => {
    // setLoading(true);
    await Logs.getList("categoria")
      .then(res => {

        let NewData = [];
        NewData.push({
          value: "",
          label: "Todas las categorias"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.registroId,
            label: item.nombre
          })
        });
        setDataCategoria(NewData);
        // setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  //
  const handleGetAllUsuario = async (page) => {
    setLoading(true);
    await Logs.getLogsUsuario(page, pageRows, moduloId, categoriaId, Str, dateTime)
      .then(res => {
        let result = res.data.data.map(item => {
          item.hora = getHourFormat(item.hora);
          return item;
        });

        // setData(res.data.data);
        setData(result);
        setPageCount(res.data.totalPages)
        
        setLoading(false);
      }).catch(e => {        
        setLoading(false);        
        if (!e.response?.data?.message){
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Error de conexión con el servidor" })
        }
        if (e.response !== undefined){
          if(e.response.data !== ''){
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          }else{
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.statusText })
          }
        }
         
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handleCheck = (cheked, value) => {

    if (cheked) {      
      setIdsEliminar((current) => [...current, value.logUsuarioId]);      
    } else {
      setIdsEliminar(idsEliminar.filter((item) => item !== value.logUsuarioId)); 
    }
      

    // setIdsEliminar(ids);    
  }
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');


    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetAllUsuario(event.selected + 1);
  };
  const handleDownloadFile = async () => {
    refBtn.current.disabled = true;
    if (idsEliminar.length > 0) {
      //Aqui se descarga
      setLoading(true);
      let newIds = idsEliminar.join();
      await Logs.downloadFileUsuario(newIds)
        .then(res => {        
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `LogUsuario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
            
          setLoading(false);
          handleGetAllUsuario(1);
          handleUnchekedColumns();
          setIdsEliminar([]);
          refBtn.current.disabled = false;
        }).catch(e => {
          setLoading(false);
          refBtn.current.disabled = false;
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      refBtn.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  const handleExportFile = async () => {
    // refBtnExp.current.disabled = true;
    //Aqui se elimina
    if (idsEliminar.length > 0) {
      setLoading(true);
      let newIds = idsEliminar.join();
      await Logs.exportFileUsuario(newIds)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `LogUsuario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

          setLoading(false);
          handleGetAllUsuario(1);
          handleUnchekedColumns();
          setIdsEliminar([]);
          // refBtnExp.current.disabled = false;
        }).catch(e => {
          setLoading(false);
          // refBtnExp.current.disabled = false;
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    } else {
      setLoading(false);
      // refBtnExp.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  return {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleCheck,
    pageCount,
    handlePageClick,
    dataModulo,
    dataProceso,
    dataCategoria,
    setStr,
    setDateTime,
    setCategoriaId,
    setModuloId,
    isenablebtn,
    handleDownloadFile,
    initialPage,
    refBtn,
    refBtnExp,
    handleExportFile,
    setpageRows
  }
}

export default useUsuario