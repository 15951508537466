import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import AuthLogin from '../../Utils/AuthLogin';
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom'
import Usuario from '../../Services/Users/Usuarios';
import { loginSession } from '../../Redux/Slice/loginSlice';
import { AuthExpireSesion } from '../../Utils/functions';
import useClave from './useClave';

const auth = new AuthLogin();

const useLogin = (toast) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {validateHeader}= useClave()
    const handelSession = (res) => {
        // const nombreUsuario = res.nombreUsuario
        // const numeroEmpleado = res.numeroEmpleado
        // const userId = res.userId
        // const idRole = res.idRole
        // const role = res.role
        dispatch(loginSession(res));
    }
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });


    useEffect(() => {
        if (!AuthExpireSesion()) {
            console.log("login")
            validateHeader()
            return;
        }
    }, [])

    const validateForm = Yup.object({
        email: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        password: Yup.string()
            .required("El campo es requerido."),
    });
    const onSubmit = async (values) => {

        setUsername(values.email);
        setPassword(values.password);
        loginGo(values.email, values.password);
    }

    const { values, errors, handleChange, touched, handleUsernameChange, handleSubmit } = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: validateForm,
        onSubmit
    })

    const loginGo = async (email, pass) => {
        let data = {
            username: email,
            password: pass
        }
        setLoading(true);
        await Usuario.userLogin(data)
            .then(res => {
                
                setLoading(false);
                if (res.data.data.ban == 0) {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje })
                } else {
                    handelSession(res.data.data)
                    auth.handleAuthSesionLogin(res.data.data)
                    navigate("/clave", {
                        state: { esLogin: true },
                    })
                }

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
        // 
    };


    return {
        loading,
        modalMessage,
        setModalMessage,
        validateForm,
        navigate,
        values,
        errors,
        handleChange,
        touched,
        handleUsernameChange,
        handleSubmit,
        loginGo,
        dispatch,
        handelSession
    }


}
export default useLogin