import React from 'react';
import { useNavigate, useRouteError } from "react-router-dom";
import img from '../Assets/img/not-found.svg';

const NotFound = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>404</h1>
          <h2>The page you are looking for doesn't exist.</h2>
          <button className="btn" onClick={()=>{ navigate(-1);}}>Back to home</button> 
          <img src={img} className="img-fluid py-5" alt="Page Not Found"/>
        </section>
      </div>
    </>
  )
}

export default NotFound