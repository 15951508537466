import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../Components/datatable/DataTable";

const HistorialExpediente = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [collpsableGrua, setCollpsableGrua] = useState(false);

    const columns = [
        { field: 'as', headerName: 'Folio' },
        { field: 'as', headerName: 'Solicitado por' },
        { field: 'as', headerName: 'Fecha de Solicitud' },
        { field: 'as', headerName: 'Proceso' },
        { field: 'as', headerName: 'Motivo' },
        { field: 'as', headerName: 'Fecha de Resguardo' },
        { field: 'as', headerName: 'Acciones' },
        { field: 'as', headerName: 'Historial' },
    ];

    return (
        <>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseHistorialExpediente" role="button" aria-expanded="false" aria-controls="collapseHistorialExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Historial de préstamos de expedientes</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseHistorialExpediente">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12">
                                        <DataTable
                                            column={columns}
                                            data={[]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HistorialExpediente;