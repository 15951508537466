import { Formik,  } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import DataTable from '../../../Components/datatable/DataTable';
import { FormatFecha, getHourFormat } from '../../../Utils/functions';
import RowPages from '../../../Components/RowPages';

const HistorialDeEstatusDeSistema = ({ isOpen, setIsOpen, item }) => {
   const [historial, setHistorial] = useState([]);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10)
   const [str, setStr] = useState("");
   const [data, setData] = useState([])
   const [currentPage, setCurrentPage] = useState(1);
   const handlePageClick = (event) => {
      setCurrentPage(event.selected + 1)
   };

   useEffect(()=>{
      let NewData = [];
      item?.historialStatus?.map(element => {
         let fechaRegistro = new Date(element?.fecha_hora_registro)
         let hour = getHourFormat(`${String(fechaRegistro.getHours()).padStart(2, '0')}:${String(fechaRegistro.getMinutes()).padStart(2, '0')}`)
         NewData.push(
            {
               usuariosistema: item?.nombrecobrador, 
               estatussistema: element?.status, 
               fechainicio: new Date(element?.fecha_inicio) <= new Date("1970-01-01") ? "" : FormatFecha(element?.fecha_inicio),
               fechafin: new Date(element?.fecha_inicio) <= new Date("1970-01-01") ? "" : FormatFecha(element?.fecha_fin),
               fecharegistro: `${FormatFecha(fechaRegistro)} ${hour}`,
               fechasistema: fechaRegistro
            })
      });
      let arregloOrdenado = NewData.sort(function (a, b) {
         if (a.fechasistema > b.fechasistema) {
            return 1;
         }
         if (a.fechasistema < b.fechasistema) {
            return -1;
         }
         return 0;
      });
      setData(arregloOrdenado.reverse())
   },[]);


   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      let filteredData = data.filter(item => {
         const nameMatches = str === '' || (item?.usuariosistema && item?.usuariosistema.toLowerCase().includes(str.toLowerCase())) 
            || (item?.estatussistema && item?.estatussistema.toLowerCase().includes(str.toLowerCase()));

         return (nameMatches)
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setHistorial(newData)
  
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
  
   }, [currentPage, str, pageRows, data])

   const columns = [
      { field: 'usuariosistema', headerName: 'Usuario', width: 200 },
      { field: 'estatussistema', headerName: 'Estatus de sistema', width: 200 },
      { field: 'fechainicio', headerName: 'Fecha inicio', width: 170 },
      { field: 'fechafin', headerName: 'Fecha fin', width: 170 },
      { field: 'fecharegistro', headerName: 'Fecha de registro', width: 210 },
   ]

   return (
   <Formik
      enableReinitialize={true}
   >{
      ({ isValid, values, setFieldValue }) => (
         <>
         <Modal isOpen={isOpen} color='#fff'>
            <section className='my-2 mx-4'>
               <span className='fs-5' style={{fontWeight:'bold', color:"#000"}}><strong>Historial de Estatus de sistema</strong></span>
            </section>
            <section className='my-2 mx-4' >
                  {/* <ValidatePermission
                  isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                  > */}
                  <div className="mx-4  inner-addon right-addon">
                     <i className="glyphicon fas fa-search"></i>
                     <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                  </div>
                  <div >
                     <RowPages expand_col_size={true} setpageRows={setpageRows} className="mr-5" />
                  </div>
                  <div className='mx-4 table-responsive' style={{ width: '50rem', height: '25rem', overflowY: "scroll"}}>
                     <DataTable
                        column={columns}
                        data={historial}
                        pageCount={pageCount}
                        paginate={true}
                        currentPage={currentPage - 1}
                        handlePageClick={handlePageClick}
                        placeholderSelectField = {"Seleccionar"}
                     />
                  </div>
                  {/* </ValidatePermission> */}
            </section>
            <section className='my-4 d-flex justify-content-end'>
               <button id="AT_btnCancelar" className='btn col-2 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
            </section>
         </Modal>
         </>
      )
   }
   </Formik>
   )
}

export default HistorialDeEstatusDeSistema