import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import Datamovil from '../../Services/Resguardos/Datamovil';

const CobroDatamovilModal = ({ modalMessage, setModalMessage, handleActualizar, revisionId, origen = 'revision' }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const initial = {
        datamovilRevisionId: revisionId,
        tipoCostoId: null,
        concepto: "",
        subtotal: "",
        iva: "",
    }

    const [initialValue, setInitialValue] = useState(initial)
    const [total, setTotal] = useState(0);
    const [tiposDeCosto, setTiposDeCosto] = useState([]);

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object({
        tipoCostoId: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        concepto: Yup.string()
            .typeError('Campo requerido')
            .required('Campo requerido'),
        subtotal: Yup.string()
            .trim()
            .typeError('Campo requerido')
            .required('Campo requerido')
    });

    useEffect(() => {
        getCategoriaCosto();
    }, []);

    const handlerGuardar = async (values) => {
        if(origen.toLowerCase() === 'reprogramacion'){
            let params = {
                datamovilReprogramacionId: revisionId,
                datamovilReprogramacionTipoCostoId: values.tipoCostoId,
                concepto: values.concepto,
                subtotal: values.subtotal,
                iva: values.iva,
                usuarioEjecutivoId: sessionStorage.getItem('userId'),
                status: true,
            }

            await Datamovil.datamovilReprogramacionCostoCrear(params)
            .then(resp => {
                handleActualizar();
                setModalMessage({ isOpen: false })
            })
            .catch(err => {
                console.error(err);
            });
        }else{
            await Datamovil.datamovilRevisionCostoCrear(values)
            .then(resp => {
                handleActualizar();
                setModalMessage({ isOpen: false })
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    const getCategoriaCosto = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CategoriaCosto')
        .then(resp => {
            const categoriaId = resp.data.filter(item => item.CategoriaCosto.replaceAll(" ", "").toLowerCase() === 'reparacióndatamovil')[0].CategoriaCostoId;
            if(categoriaId !== undefined){
                getTipoCostos(categoriaId);
            }
        })
        .catch(err => {
            console.error(err);
        });
    };

    const getTipoCostos = async (categoriaId) => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoCosto')
        .then(resp => {
            const tipoCostos = resp.data.filter(item => item.CategoriaCostoId === categoriaId);
            let items = [];
            tipoCostos.forEach(item => {
                items.push({
                    value: item.TipoCostoId,
                    label: item.Nombre
                });
            });

            setTiposDeCosto(items);
        })
        .catch(err => {
            console.error(err);
        });
    }

    const calculateTotal = (values) => {
        if(values.subtotal !== "" && values.iva !== ""){
            let totalTmp = parseFloat(values.subtotal) + parseFloat(values.iva);
            setTotal(totalTmp);
        }else if(values.subtotal !== ""){
            let totalTmp = parseFloat(values.subtotal);
            setTotal(totalTmp);
        }else if(values.iva !== ""){            
            let totalTmp = parseFloat(values.iva);
            setTotal(totalTmp);
        }else if(values.subtotal === "" || values.iva === ""){
            let totalTmp = parseFloat(values.subtotal) + parseFloat(values.iva);
            if(isNaN(totalTmp)){
                totalTmp = 0;
            }
            setTotal(totalTmp);
        }
    };

    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }

    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const onlyNumbers = (field, value, fn) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            fn(field, value);
        }
    }

    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }


    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                initialTouched={{
                    tipoCostoId: true,
                    concepto: true,
                    subtotal: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación completa todos los campos para el registro del costo.
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="tipoCostoId"
                                                label="Tipo de costo"
                                                name="tipoCostoId"
                                                items={tiposDeCosto}
                                                onChange={(event) => {
                                                    setFieldValue("tipoCostoId", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="concepto"
                                                disabled={false}
                                                label="Concepto"
                                                name="concepto"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("concepto", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="subtotal"
                                                disabled={false}
                                                label="Subtotal"
                                                name="subtotal"
                                                type="text"
                                                holder="0"
                                                onBlur={(event) => {
                                                    calculateTotal(values);
                                                }}
                                                onChange={(event) => {
                                                    onlyNumbers("subtotal", event.target.value, setFieldValue);
                                                }} />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="iva"
                                                disabled={false}
                                                label="IVA"
                                                name="iva"
                                                type="text"
                                                holder="0"
                                                onBlur={(event) => {
                                                    calculateTotal(values);
                                                }}
                                                onChange={(event) => {
                                                    onlyNumbers("iva", event.target.value, setFieldValue);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-2 justify-content-end">
                                        <div className="col-auto">
                                            <strong>Total: {formatMoney(total)}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            disabled={!isValid}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default CobroDatamovilModal;
