import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayCitaCambioPlacas: "",
    hourCitaCambioPlacas: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdCitaCambioPlacas: "",
}

export const CambioPlacasSlice = createSlice({
    name: 'CambioPlacasSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayCitaCambioPlacas: action.payload.day,
                hourCitaCambioPlacas: action.payload.hour,
                calendarioIdCitaCambioPlacas: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            return {
                ...state,
                dayCitaCambioPlacas: "",
                hourCitaCambioPlacas: "",
                calendarioIdCitaCambioPlacas: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = CambioPlacasSlice.actions

export default CambioPlacasSlice.reducer