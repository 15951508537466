import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../../../Components/Calendar/Calendar';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setTipoCalendario } from '../../../Redux/Slice/calendarSlice';
import CalendarDinamical from '../../../Components/Calendar/CalendarDynamical';

const CitasDinamicas = () => {
    const dispatch = useDispatch();

    const { url } = useSelector((state) => state.CalendarSlice);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(setTipoCalendario(id));
    }, []);
    
    return (
        <>
            <section className='mb-4'>
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                            <span className={'text-black'} style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>
                                <small><i className="glyphicon fas fa-angle-left"></i> Regresar al apartado anterior</small>
                            </span>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <CalendarDinamical type={id} sundayOn={true}/>
        </>
    )
}

export default CitasDinamicas