import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveUser } from '../../Redux/Slice/navigateSliceUser';
import Usuario from '../../Services/Users/Usuarios';

const SubmenuUser = () => {
    const { value } = useSelector((state) => state.tabNavigateUser);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    useEffect(() => {
        handleVigate(value);
    }, [value])

    useEffect(() => {
        handleGetModules();
    }, [])


    const handleSetStateTab = (value) => {
        dispatch(tabActiveUser(value));
    }
    const handleVigate = (value) => {
        // if (value === 1) {
        //   navigate(`/parametros/dataware`);
        // }
        // if (value === 2) {
        //   navigate(`/parametros/acendes`);
        // }
        // if (value === 3) {
        //   navigate(`/parametros/intelimotor`);
        // }
        // if (value === 4) {
        //   navigate(`/parametros/datadocs`);
        // }

    }

    const handleGetModules = async () => {
        await Usuario.getAllModules()
            .then(res => {
                setData(res.data.data);                
                // setLoading(false);
            }).catch(e => {
                // setLoading(false);
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    return (
        <>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {
                    data?.map((item,index) => {
                        return <li className="nav-item" role="presentation" key={index}>
                                    <button className={`nav-link ${value == item.idModulo && "tab-active"}`} value={item.idModulo} name={item.modulo} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>{item.modulo}</button>
                                </li>
                    })
                }
                {/* <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value == 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Inventario</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Ventas</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Panel Administrativo</button>
                </li> */}
            </ul>
        </>
    )
}

export default SubmenuUser