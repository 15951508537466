import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import AuthLogin from '../../../Utils/AuthLogin';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';
import { number } from 'yup';
import { NumFolio, message } from '../../../Utils/functions';
import { da } from 'date-fns/locale';

const auth = new AuthLogin();
const useSeguimientoProcesoPagoPR = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    let { id } = useParams();
    const { state } = useLocation();
    const role = auth.getSesionValue("role");
    const columns = [
        { field: 'folioGrupo', headerName: 'Grupo', width: 70 },
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'placas', headerName: 'Placas', width: 150 },
        { field: 'tipoVehiculo', headerName: 'Tipo de vehículo', width: 250 },
        { field: 'estatus', headerName: 'Estatus', width: 250 },
        { field: 'diasVencidosSinConsultaClave', headerName: 'Dias vencidos-Sin consulta clave', width: 700 },
        { field: 'montoPorPagarStr', headerName: 'Monto por pagar', width: 350 },
        { field: 'montoPadoStr', headerName: 'Monto pagado', width: 350 },
        { field: 'strFechaPago', headerName: 'Fecha Pago', width: 350 },
        { field: 'usuarioPago', headerName: 'Usuario pago', width: 250 },
        { field: 'nombreMarca', headerName: 'Marca', width: 200 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 200 },
        { field: 'nombreYear', headerName: 'Año', width: 200 },
    ];
    let estatusVehiculoData = [
        { label: "Todos", value: "" },
        { label: "Vehículos sin grupo", value: 1 },
        { label: "Vehículos agrupados", value: 2 },
        { label: "Vehículos pagados sin evidencia", value: 3 },
        { label: "Vehículos pagados con evidencia", value: 4 },
    ];
    let tipoVehiculoData = [
        { label: "Todos", value: "" },
        { label: "Vehículos en credito", value: 1 },
        { label: "Vehículos de inventario", value: 2 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [isOpenModalFile, setIsOpenModalFile] = useState(false);
    const [pageRows, setpageRows] = useState(10);
    const [newState, setNewState] = useState(state);
    const [claveTipoVehiculo, setClaveTipoVehiculo] = useState("");
    const [claveStatusSeguimiento, setClaveStatusSeguimiento] = useState("");
    const [infoData, setInfoData] = useState();
    const [openModalGrupo, setOpenModalGrupo] = useState(false);
    const [openModalDesagrupar, setOpenModalDesagrupar] = useState(false);
    const [openModalPago, setOpenModalPago] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [openModalNotificacion, setOpenModalNotificacion] = useState(false);
    const [dataNotificacion, setDataNotificacion] = useState();
    useEffect(() => {
        if (newState.estatusId !== 1) {
            if (str !== "" || claveTipoVehiculo !== "" || claveStatusSeguimiento !== "") {
                setCurrenPage(1);
                habdleGetAll(1);
            } else {
                habdleGetAll(currenPage);
                setCurrenPage(1);
            }
        }
    }, [str, pageRows, claveTipoVehiculo, claveStatusSeguimiento])

    const habdleGetAll = async (page) => {
        setLoading(true);
        await PagoRefrendoPlacasService.getAllPagoRefrendoPlacasSeguimiento(page, pageRows, str, claveTipoVehiculo, claveStatusSeguimiento, id)
            .then(res => {
                setData(res.data.data.pagoRefrendoSeguimientoList);
                setInfoData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleDetail = (value) => {
        navigate(`/seguro/seguro/segurofinal/${value.generalId}`, {
            state: { ...value, action: 2, polizavigente: true, desgloce: true },
        });
    }

    const handleDownloadExcel = async () => {
        setLoading(true);
        await PagoRefrendoPlacasService.ExportarPagoRefrendoPlacasSeguimiento(str, claveTipoVehiculo, claveStatusSeguimiento, id)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `SeguimientoProcesoPago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAlllist = async (item) => {
        setLoading(true);
        let { jsonData } = item;
        let firstObject = jsonData[0];

        if (firstObject["monto"] && firstObject["placas"] && firstObject["vin"]) {
            await PagoRefrendoPlacasService.ProcesarExcel({
                pagoRefrendoPlacasId: id,
                listVins: jsonData
            })
                .then(res => {
                    if (res.data.data.ban === 1) {
                        setNewState({ ...newState, estatusId: 2 });
                        habdleGetAll(currenPage);
                    }
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                })
        } else {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `El excel presenta un error en la estructura del archivo, Recuerda que las columnas selo deben ser : placas,vin y monto.`)}</div>);
            setLoading(false);
        }
    }

    const handleCrearGrupo = async (value) => {
        setOpenModalGrupo(false);
        await handleSubmitCrearGrupo({ ...value, gestoriaPagoRefrendoPlacasId: id });
    }


    // const handleCrearGrupo = async (value) => {
    //     // setOpenModalGrupo(false);
    //     console.log("value",value)
    //     let Grupos = [];
    //     let numGrupo = value.presupuestoDeBolsa / Number(value.MontoMinimoCheque);
    //     console.log("ModalGrupo", value, Math.trunc(numGrupo));

    //     // crear grupos con el folio
    //     for (let index = 1; index <= Math.trunc(numGrupo); index++) {
    //         Grupos.push(
    //             {
    //                 folioGrupo: `${index}`,
    //                 vehiculos: [],
    //                 cadena: []
    //             }
    //         );

    //     }

    //     let newDataGrupo = data.map(x => {
    //         return { ...x, aplicado: false };
    //     })
    //     // let newDataGrupo = [
    //     //     {vin:"asdasd",montoPorPagar:30000,aplicado:false},-->1
    //     //     {vin:"asdasd",montoPorPagar:25000,aplicado:false},-->2
    //     //     {vin:"asdasd",montoPorPagar:20000,aplicado:false},-->1
    //     //     {vin:"asdasd",montoPorPagar:15000,aplicado:false},
    //     //     {vin:"asdasd",montoPorPagar:15000,aplicado:false},
    //     //     {vin:"asdasd",montoPorPagar:10000,aplicado:false},
    //     //     {vin:"asdasd",montoPorPagar:10000,aplicado:false},
    //     //     {vin:"asdasd",montoPorPagar:10000,aplicado:false},
    //     //     {vin:"asdasd",montoPorPagar:20000,aplicado:false},
    //     // ];
    //     //Iteramos el grupo
    //     Grupos.map((grupo, i) => {
    //         var total = 0;
    //         //obtenemos el total del monto por cada grupo
    //         total = handleSumArray(grupo.vehiculos);

    //         //iteramos el array de datos
    //         newDataGrupo.map((item, index) => {
    //             // si el item ya se agrego a un grupo, retornamos para seguirn con el siguiente item
    //             if (item.aplicado)
    //                 return;
    //             // let totalRestante = handleSumArray(newDataGrupo.filter(x=>!x.aplicado));
    //             // console.log("resto valor item",totalRestante);
    //             // if(totalRestante < Number(value.MontoMinimoCheque))
    //             //     return;
    //             //validar que el total de monto del grupo sea menor al montomaxinocheque
    //             if ((total <= Number(value.MontoMaximoCheque))) {

    //                 ///falta agregar una validacion para obtener siempre el item con el monto mas cercano al restante.
    //                 ///
    //                 if (item.montoPorPagar <= (Number(value.MontoMaximoCheque) - total)) {
    //                     grupo.vehiculos.push({ vin: item.vin, montoPorPagar: item.montoPorPagar });
    //                     grupo.cadena.push(item.vin);
    //                     total += item.montoPorPagar;
    //                     newDataGrupo[index] = { ...item, aplicado: true }
    //                 }
    //             }
    //         })
    //         grupo.cadena.unshift(grupo.folioGrupo);
    //     })

    //     let array = []
    //     Grupos.map(x => {
    //         if (x.vehiculos.length > 0)
    //             array.push(x.cadena.join());
    //     });
    //     console.log("grupos",{ ...value, gestoriaPagoRefrendoPlacasId: id, cadenaGrupo: array.join('|') })
    //     // await handleSubmitCrearGrupo({ ...value, gestoriaPagoRefrendoPlacasId: id, cadenaGrupo: array.join('|') });
    // }

    const handleSubmitCrearGrupo = async (data) => {
        setLoading(true);
        await PagoRefrendoPlacasService.CrearGrupoPagoRefrendoPlacas(data)
            .then(res => {
                if (res.data.data.Ban === 1) {
                    // toast.success(message("¡Correcto!", res.data.data.mensaje));
                    habdleGetAll(1);
                    setLoading(false);
                    setOpenModalNotificacion(true);
                    setDataNotificacion(res.data.data);
                } else {
                    toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", res.data.data.Mensaje)}</div>);
                    setLoading(false);
                }
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleSumArray = (array) => {
        const total = array.reduce((previous, current) => {
            return previous + current.montoPorPagar; // sumar el valor de una propiedad
        }, 0);
        return total;
    }
    const handleActionGestoria = async (item, action) => {
        console.log(item, action)
        if (action === 0) {
            if (item.pagado) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `El vehiculo ya esta pagado, no es posible desagruparlo.`)}</div>);
                return;
            }
            setItemSelected(item);
            setOpenModalDesagrupar(true);
        }
        else if (action === 1) {
            if (item.pagado) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `El Vin ${item.vin} ya se encuentra pagado.`)}</div>);
                return;
            }
            setItemSelected({ ...item, gestoriaPagoRefrendoPlacasId: id });
            setOpenModalPago(true);
        }
    }

    const handleRealizarPago = async (data) => {
        setOpenModalPago(false);
        setLoading(true);
        await PagoRefrendoPlacasService.RealizarPagoRefrendoPlacas(data)
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    habdleGetAll(1);
                    setLoading(false);
                } else {
                    toast.error(message("¡Error!", res.data.data.mensaje))
                    setLoading(false);
                }
            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        handleDownloadExcel,
        selectRef,
        navigate,
        setIsOpenModalFile,
        isOpenModalFile,
        handleGetAlllist,
        estatusVehiculoData,
        tipoVehiculoData,
        setClaveStatusSeguimiento,
        setClaveTipoVehiculo,
        newState,
        infoData,
        setOpenModalGrupo,
        openModalGrupo,
        handleCrearGrupo,
        handleActionGestoria,
        setOpenModalDesagrupar,
        openModalDesagrupar,
        itemSelected,
        setOpenModalPago,
        openModalPago,
        habdleGetAll,
        setOpenModalNotificacion,
        openModalNotificacion,
        dataNotificacion,
        handleRealizarPago,
        role
    }
}

export default useSeguimientoProcesoPagoPR