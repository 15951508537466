import React from 'react'
import './ModalComments.css'

const ModalComments = ({children,isOpen,closeModal,color,width,height=null, overflow}) => {
  return (
    <>
    <article className={`modal comments ${isOpen && "is-open"}`} >
        <div className='modal-container comments' style={
          overflow
          ? {backgroundColor:`${color}`,width:`${width}px`, overflowY: 'auto' , maxHeight:"80%", overflowX: "hidden"}
          : {backgroundColor:`${color}`,width:`${width}px` }
        }
          >
            {children}
        </div>
    </article>
    </>
  )
}

export default ModalComments