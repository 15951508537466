import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { validateManySubModule, ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { SubModule } from '../Auth/Authorization';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch, useSelector } from 'react-redux';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();

const GestoriaSidebar = () => {
    const { visible } = useSelector((state) => state.changeMenu);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }
    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }
    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div id="lbl_Seguros_Sidebar" className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Gestoría"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.PendienteEnviarAcendes),
                        ValidatePermissionById(2, SubModule.SeguimientoGestoria)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GDG" : "Gestión de gestoría"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SeguimientoGestoria)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/seguimiento" className="nav-link collapsed text-dark">
                                <i className="icon-dark bi bi-list-task"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento de gestoría</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PagoRefrendoPlacas)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/pagorefrendoyplacas" className="nav-link collapsed text-dark">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pago refrendo y placas</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.AuthSolicitudGestoria)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GDS" : "Gestión de solicitudes"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.AuthSolicitudGestoria)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/autorizaciondesolicitudes" className="nav-link collapsed text-dark">
                                <i className="icon-dark ri-file-list-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Autorización de solicitudes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.PendienteEnviarAcendes)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "EDI" : "Envío de información"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PendienteEnviarAcendes)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/pendienteenviaracendes" className="nav-link collapsed text-dark">
                                <i className="icon-dark bx bx-send"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pendiente enviar Acendes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                </ul>
            </aside>
        </>
    )
}

export default GestoriaSidebar