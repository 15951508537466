import React from 'react';
import img from '../../../../Assets/img/configDatadocs.png'
import HeaderComisionEmpresa from './HeaderComisionEmpresa';
import SubMenuComisionesInventario from '../SubMenuComisionesInventario';

const ComisionesEmpresaHome = () => {
    return (
        <>
            <SubMenuComisionesInventario />
            <HeaderComisionEmpresa />
            <section className='d-flex flex-column align-items-center mt-5'>
                <img src={img} style={{ width: '23rem' }} />
            </section>
        </>
    )
}

export default ComisionesEmpresaHome