const EstadisticaCard = ({title, servicios=0, sinAsignar = 0, asignados=0}) => {
    return (
        <>
            <div className="row px-2">
                <div className="col-12">
                    <span className="title">{ title }</span>
                </div>
                <div className="col-12">
                    <h2 className="fw-bold quantity">{ servicios }</h2>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-auto">
                            <i className="ri-subtract-line not-assigned me-1 mt-3"></i>
                            <span>{ sinAsignar }</span>
                        </div>
                        <div className="col-auto">
                            <i className="ri-add-line assigned me-1"></i>
                            <span>{ asignados }</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EstadisticaCard;