import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dayFirma: "",
    hourFirma: "",
    sucursalId: "",
    sucursalName: "",
    calendarioIdFirma: "",
}

export const FirmaSlice = createSlice({
    name: 'FirmaSlice',
    initialState,
    reducers: {
        setHour: (state, action) => {
            return {
                ...state,
                dayFirma: action.payload.day,
                hourFirma: action.payload.hour,
                calendarioIdFirma: action.payload.calendarioId,
            }
        },
        setSucursal: (state, action) => {
            return {
                ...state,
                sucursalId: action.payload.sucursalId,
                sucursalName: action.payload.sucursalName,
            }
        },
        clearHour: (state) => {
            console.log("Clearing...")
            return {
                ...state,
                dayFirma: "",
                hourFirma: "",
                calendarioIdFirma: ""
            }
        }
    }
})

export const { setHour, setSucursal, clearHour } = FirmaSlice.actions

export default FirmaSlice.reducer