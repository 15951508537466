import axios from '../../Utils/ApiService';

const CambioDevoluciones = {
    cambioDevolucionAdeudosCrear: (data) => {
        return axios.post(`/cartera/cambioDevolucionAdeudosCrear`, data);
    },
    cambioDevolucionAdeudosActualizar: (data) => {
        return axios.put(`/cartera/cambioDevolucionAdeudosActualizar`, data);
    },
    cambioDevolucionAdeudosGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionAdeudosGet?cambioDevolucionAdeudosId=${data}`);
    },
    cambioDevolucionAdeudosListaGet: (data) => {
        return axios.get(`/cartera/cambioDevolucionAdeudosListaGet?cambioDevolucionId=${data}`);
    },
}

export default CambioDevoluciones