import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import handlePhonNumberFormatter, { message } from '../../../Utils/functions';
import SubMenuPrealta from '../../Ventas/Prealta/subMenuPrealta';

const DatosGenerales = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const { value } = useSelector((state) => state.navigaSlicePrealta);
    const dispatch = useDispatch();
    const refFormik = useRef();
    let initial = {
        generalId: id,
        datosGeneralesId: "",
        regimenFiscalId: "",
        claveRF: "",
        descripcionRF: "",
        usoCFDIId: "",
        claveUC: "",
        descripcionUC: "",
        nombreFacturar: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        referencia: "",
        codigoPostal: "",
        coloniaId: "",
        colonia: "",
        municipioId: "",
        municipio: "",
        edoMexicoId: "",
        edoMexico: "",
        paisId: "",
        pais: "",
        ventaContado: state?.ventaContado
    }
    const dataAplica = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        regimenFiscalId: Yup.string()
            .required("El campo es requerido."),
        usoCFDIId: Yup.string()
            .required("El campo es requerido."),
        nombreFacturar: Yup.string()
            .required("El campo es requerido."),
        calle: Yup.string()
            .required("El campo es requerido."),
        numeroExterior: Yup.string()
            .required("El campo es requerido."),
        referencia: Yup.string()
            .required("El campo es requerido."),
        // numInterior: Yup.string()
        //     .when("aplicaIntermediario", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        codigoPostal: Yup.string()
            .required("El campo es requerido."),
        coloniaId: Yup.string()
            .required("El campo es requerido."),
        municipioId: Yup.string()
            .required("El campo es requerido."),
        edoMexicoId: Yup.string()
            .required("El campo es requerido."),
    });
    const [dataEstado, setDataEstado] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);
    const [dataStatus, setDataStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [DataRegimenFiscal, setDataRegimenFiscal] = useState([]);
    const [DataUsoCFDI, setDataUsoCFDI] = useState([]);
    const [progressBar, setProgressBar] = useState(0);

    useEffect(() => {
        handleGetAllEdoMexico();
        handleRegimenFiscal();
        if (state.datosGenerales) {
            handleGetById();
            handleChageProgress(refFormik.current.errors);
        }
    }, [])

    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getByIdDatosGenerales(id)
            .then(res => {
                let item = res.data.data;
                let initial = {
                    generalId: id,
                    datosGeneralesId: item?.datosGeneralesId,
                    regimenFiscalId: item?.regimenFiscalId,
                    claveRF: item?.claveRF,
                    descripcionRF: item?.descripcionRF,
                    usoCFDIId: item?.usoCFDIId,
                    claveUC: item?.claveUC,
                    descripcionUC: item?.descripcionUC,
                    nombreFacturar: item?.nombreFacturar,
                    calle: item?.calle,
                    numeroExterior: item?.numeroExterior,
                    numeroInterior: item?.numeroInterior,
                    referencia: item?.referencia,
                    codigoPostal: item?.codigoPostal,
                    coloniaId: item?.coloniaId,
                    colonia: item?.colonia,
                    municipioId: item?.municipioId,
                    municipio: item?.municipio,
                    edoMexicoId: item?.edoMexicoId,
                    edoMexico: item?.edoMexico,
                    paisId: item?.paisId ?? "",
                    pais: item?.pais ?? "",
                }
                setInitialValue(initial);
                handleUsoCFDI(item.regimenFiscalId);
                handleGetAllMunicipioById(item.edoMexicoId);
                handleGetAllColoniaById(item.municipioId);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleRegimenFiscal = async () => {
        setLoading(true)
        await Catalogo.getDropdownsByTable('Catalogos.RegimenFiscal')
            .then(res => {
                let NewData = [];
                res.data?.map(item => {
                    NewData.push({
                        value: item.RegimenFiscalId,
                        label: `${item.Clave} - ${item.Descripcion}`,
                        clave: item.clave,
                        desc: item.Descripcion
                    })
                });
                setDataRegimenFiscal(NewData);
            }).catch(e => {
                setLoading(false)
            }).finally(() => { setLoading(false) })
    }
    const handleUsoCFDI = async (id) => {
        setLoading(true)
        await Catalogo.getAllUsoCFDI(id)
            .then(res => {
                let NewData = [];
                console.log(res.data.data)
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoCFDIId,
                        label: `${item.clave} - ${item.descripcion}`,
                        clave: item.clave,
                        desc: item.descripcion
                    })
                });
                setDataUsoCFDI(NewData);
            }).catch(e => {
                setLoading(false)
            }).finally(() => { setLoading(false) })
    }
    const handleGetAllStatusDatamovil = async () => {
        setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let item = res.data.data;

                setDataStatus(item.statusDatamovil);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllEdoMexico = async () => {
        await Catalogo.getdataTabla("EdoMExico", 1, 1000)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.edoMexicoId,
                            label: item.estado
                        })
                    }
                });
                setDataEstado(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllMunicipioById = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.municipioId,
                            label: item.municipio
                        })
                    }
                });
                setDataMunicipio(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllColoniaById = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.coloniaId,
                            label: item.colonia
                        })
                    }
                });
                setDataColonia(NewData);
            }).catch(e => {

            })
    }
    const handleAddEdit = async (value) => {
        // refbtn.current.disabled = true;
        if (!state.datosGenerales) {
            setLoading(true);
            await VentaVehiculoService.addDatosGeneralesSC(value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", res.data.data.mensaje));
                        setTimeout(() => {
                            setLoading(false);
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial')
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }, 2000);
                    } else {
                        toast.error(message("¡Error.!", res.data.data.mensaje));
                        setLoading(false);
                    }
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else if (state.datosGenerales) {
            setLoading(true);
            await VentaVehiculoService.UpdDatosGeneralesSC(value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", res.data.data.mensaje));
                        setTimeout(() => {
                            setLoading(false);
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial')
                            else
                                navigate('/inventario/vehiculo')
                        }, 2000);

                    } else {
                        toast.error(message("¡Error.!", res.data.data.mensaje));
                        setLoading(false);
                    }
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }

    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 14;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const status = () => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);                
            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial')
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehiculo</strong></span>
                                            {/* <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset> */}
                                        </div>
                                        <div className='d-flex'>
                                            <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                                if (state.numIdFolder !== null) {
                                                    navigate(`/inventario/sociocomercial/datadocs/fSocioC/eDatosGen/${state.vin}/${state?.generalId}`, {
                                                        state: state
                                                    });
                                                } else
                                                    toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);

                                            }}><i className="icon ri-file-3-fill mx-1" ></i>Subir documentos</fieldset>
                                            <button className='btn mx-2' type="submit" >{state.datosGenerales ? "Editar" : "Guardar"}</button>
                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <p><strong>Gastos Generales</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <SelectField label="Régimen Fiscal" name="regimenFiscalId" items={DataRegimenFiscal} onChange={(event) => {
                                                    setFieldValue("regimenFiscalId", event.value);
                                                    setFieldValue("claveRF", event.clave);
                                                    setFieldValue("descripcionRF", event.desc);
                                                    handleUsoCFDI(event.value);
                                                    handleChageProgress(errors);
                                                    setFieldValue("usoCFDIId", "");
                                                    setFieldValue("usoCFDI", "");
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Uso de CFDI" name="usoCFDIId" items={DataUsoCFDI} onChange={(event) => {
                                                    setFieldValue("usoCFDIId", event.value);
                                                    setFieldValue("claveUC", event.clave);
                                                    setFieldValue("descripcionUC", event.desc);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Domicilio</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Nombre para facturar" name="nombreFacturar" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("nombreFacturar", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Calle" name="calle" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("calle", event.target.value);
                                                    handleChageProgress(errors);

                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Número exterior" name="numeroExterior" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("numeroExterior", event.target.value);
                                                    handleChageProgress(errors);

                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Número interior" name="numeroInterior" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("numeroInterior", event.targetvalue);
                                                    handleChageProgress(errors);

                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Referencia" name="referencia" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("referencia", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Código Postal" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("codigoPostal", event.target.value);
                                                    handleChageProgress(errors);

                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">

                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Estado:" name="edoMexicoId" items={dataEstado} onChange={(event) => {
                                                    setFieldValue("edoMexicoId", event.value);
                                                    setFieldValue("edoMexico", event.label);
                                                    setFieldValue("municipioId", "");
                                                    setFieldValue("coloniaId", "");
                                                    handleGetAllMunicipioById(event.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Municipio:" name="municipioId" items={dataMunicipio} onChange={(event) => {
                                                    setFieldValue("municipioId", event.value);
                                                    setFieldValue("municipio", event.label);
                                                    setFieldValue("coloniaId", "");
                                                    handleGetAllColoniaById(event.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField label="Colonia:" name="coloniaId" items={dataColonia} onChange={(event) => {
                                                    setFieldValue("coloniaId", event.value);
                                                    setFieldValue("colonia", event.label);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default DatosGenerales