import React from 'react'
import img from '../../Assets/img/Seguro.png'
import { Zoom } from 'react-awesome-reveal'

const SeguroHome = () => {
  return (
    <>
      <Zoom>
        <section >
          <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '90vh' }}>
            <div className='row'>
              <div className='text-center'>
                <img src={img} style={{ width: '30rem' }} className='img-fluid' />
              </div>
              <div className='fs-4 text-center mb-3'>Seguro</div>
              <div className='fs-6 text-center'>Aquí podrás ver todos los vehículos que cuentan con seguro, así como <br /> actualizar su información. También tendrás la opción de asegurar todos <br /> aquellos vehículos que aún no tienen seguro.</div>
            </div>
          </div>
        </section>
      </Zoom>
    </>
  )
}

export default SeguroHome