import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import jsonData from '../../Utils/data.json';
import { FormatFecha, FormatNumberMoney } from '../../Utils/functions';
import { chekItem } from '../../Redux/Slice/quickActionsSlice';
import PagoUnidad from '../../Services/PagoUnidad/PagoUnidad';

const UnidadesPendientesHooks = () => {
  const dispatch = useDispatch();
  const columns = [
    { field: 'vin', headerName: 'VIN' },
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'fechaEntregaFormat', headerName: 'Fecha entrega' },
    { field: 'tipoCompra', headerName: 'Tipo de compra' },
    { field: 'razonSocial', headerName: 'Razón social' },
    { field: 'valorUnidadFormat', headerName: 'Valor unidad' },
  ];

  const [pageRows, setPageRows] = useState(10);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentChecked, setCurrentChecked] = useState([]);
  const [total, setTotal] = useState(0);

  const [isOpenModalInfo, setisOpenModalInfo] = useState(false)
  const [currentPago, setCurrentPago] = useState()
  const [isOpenBusquedaAvanzada, setIsOpenBusquedaAvanzada] = useState(false);
  const [name, setName] = useState('')
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState('');

  //Filtro Avanzado
  const [dataFiltro, setDataFiltro] = useState({
    //Filtor vanzado
    estatus: "",
    strAvanzado: "",
    fechaInicio: "",
    fechaFin: "",
    concluido: false,
    busquedaAvanzada: false
  });


  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      let fechaFiltrada = true
      let tipoCobrador = true
      if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {

        const FechaVencimiento = new Date(dataFiltro.fechaFin)
        const FechaInicio = new Date(dataFiltro.fechaInicio)

        const fechaItem = new Date(item.fechaEntrega)
        fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
      }

      const nameMatches = name === '' || (item.vin && item.vin.toLowerCase().includes(name.toLowerCase()));
      const contratoMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));
      const razonSocialMatches = name  === '' || (item.razonSocial && item.razonSocial.toLowerCase().includes(name.toLowerCase()));
      
      if (cobradorSeleccionado === 1) {
        tipoCobrador = (item.tipoCompra === "Inventario") || (item.razonSocial === "AUTOS DATAMOVIL")
      }
      if (cobradorSeleccionado === 2) {
        tipoCobrador = (item.tipoCompra === "Socio Comercial") || (item.tipoCompra === "Consignacion") || (item.tipoCompra === "Independiente")
      }

      return (
        (nameMatches || contratoMatches || razonSocialMatches) &&
        (dataFiltro.busquedaAvanzada === false || fechaFiltrada) &&
        tipoCobrador
        // &&
        // (proceso === '' || item.proceso === proceso) &&
        // (frecuencia === '' || item.frecuenciaFormateada === frecuencia)
      )
    })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)

    //Arreglar los checbox?
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      if (newData[index]) {
        if (newData[index].statusCheckBox) {
          element.firstChild.firstChild.checked = true;
        } else {
          element.firstChild.firstChild.checked = false;
        }
      }
    }

  }, [currentPage, name, cobradorSeleccionado, pageRows, data, dataFiltro])

  // Configura la página inicial al cargar el componente
  useEffect(() => {
    setCurrentPage(0);
  }, [pageRows, name]);

  useEffect(() => {
    setName(dataFiltro.strAvanzado)
    setIsOpenBusquedaAvanzada(false)
  }, [dataFiltro])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handlechekedItem = (check, item, i) => {
    item.statusCheckBox = check

    if (check) {
      setTotal((prevTotal) => prevTotal + item.precioUnidad); // Suma si está seleccionado
    } else {
      setTotal((prevTotal) => prevTotal - item.precioUnidad); // Resta si se deselecciona
    }
    // check ? dispatch(chekItem({ check, item: { ...item, ventaContado: true } })) : dispatch(chekItem({ check, item: null }));
    let table = document.querySelectorAll('.tbl-custome >tr');
    // setCurrentChecked(...currentChecked, item)
    for (let index = 0; index < table.length; index++) {
      const element = table[index];
    }
  }


  useEffect(() => {
    getAllData()
  }, [])



  const getAllData = async () => {
    setLoading(true)
    let frecuencias = await PagoUnidad.getAllReportePagoUnidad()
      .then(res => {

        let response = res.data
        .filter(item => item.pagounidadestatus < 2) 
        .map(item => {

          const fechaFormat = FormatFecha(item.fechaEntrega)
          const valorUnidadFormat = FormatNumberMoney(item.precioUnidad)

          return {
            ...item,
            fechaEntregaFormat: fechaFormat,
            valorUnidadFormat: `$${valorUnidadFormat}`
          }
        })
        setLoading(false)
        return response
      }).catch(e => {
        setLoading(false)
      })

    setData(frecuencias)
  }

  const getReporteExcel = async () => {
    // Your Excel export logic
    // Lógica para exportar el reporte en Excel
    setLoading(true);
    await PagoUnidad.exportarReportePagoUnidad()
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `UnidadesPendientesDePago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  };


  const handleInfo = async (obj) => {
    setCurrentPago(obj)
    setisOpenModalInfo(true)
  }


  return {
    setPageRows,
    columns,
    currentData,
    loading,
    pageCount,
    currentPage,
    handlePageClick,
    handlechekedItem,
    isOpenModalInfo,
    setisOpenModalInfo,
    handleInfo,
    currentPago,
    total,
    name,
    setName,
    setCobradorSeleccionado,
    dataFiltro,
    setDataFiltro,
    getReporteExcel,
    isOpenBusquedaAvanzada,
    setIsOpenBusquedaAvanzada,
  };
};

export default UnidadesPendientesHooks;
