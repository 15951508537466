import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import SubMenuAutorizacionDeSolicitudes from '../SubMenuAutorizacionDeSolicitudes'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../../Auth/Authorization'
import RowPages from '../../../../Components/RowPages'
import DataTable from '../../../../Components/datatable/DataTable'
import useAutorizarDictamen from '../../../../Hooks/Seguros/AutorizacionDeSolicitudes/useAutorizarDictamen'
import ModalAprobarRechazar from './ModalAprobarRechazar'
const AutorizacionDictamenListado = () => {
    const { columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        setOpenModal,
        openModal,
        habdleGetAll,
        setItemSelected,
        itemSelected
    } = useAutorizarDictamen(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuAutorizacionDeSolicitudes />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.AutorizarDictamen, SubModule.AutorizacionDeSolicitudes)?.Ver}>
                <section className='mx-3 py-4 d-flex flex-column'>
                    <div className='d-flex justify-content-between'>
                        <span className='fs-5'><strong>Autorizar dictamen</strong></span>
                    </div>
                    {/* <span className='mt-2'>El siguiente listado corresponde a la gestión de todos los seguros sin pago.</span> */}
                </section>

                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        iswidth={true}
                        detailable={true}
                        handleDetail={(item)=>{setItemSelected(item);setOpenModal(true)}}
                    />
                </div>
            </ValidatePermission>
            {
                openModal &&
                <ModalAprobarRechazar
                isOpen={openModal}
                setIsOpen={setOpenModal}
                item={itemSelected}
                handleGetAll={habdleGetAll}
                />
            }
        </>
    )
}

export default AutorizacionDictamenListado