import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../../Services/Seguros/SeguroPolizaService';
import { handleIsActiveCheck, message } from '../../../Utils/functions';
import ComisionesService from '../../../Services/Seguros/ComisionesService';
import ComisionesEmpresaService from '../../../Services/Inventario/Comisiones/ComisionesEmpresaService';
const useComisionesDeEmpresaPendientePago = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: "Contrato", width: 150 },
        { field: 'strFechaEntrega', headerName: 'Fecha de entrega', width: 250 },
        { field: 'diasTranscurridos', headerName: 'Dias transcurridos', width: 250 },
        { field: 'razonSocial', headerName: 'Razón social', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'procedenciaCliente', headerName: 'Procedencia del cliente', width: 250 },
        { field: 'precioStr', headerName: 'Precio unidad', width: 150 },
        { field: 'montoFinanciarStr', headerName: 'Monto a financiar', width: 250 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
    ];
    
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [IdsExportar, setIdsExportar] = useState([]);
    const [openModalTotales, setopenModalTotales] = useState(false);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechaFin, setFechaFin] = useState("");

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows, FechInicio, FechaFin])
    // useEffect(() => {
    //     handleGetTipoCompra();
    //     handleGetAllUsoUnidad();
    //     handleGetAllAseguradora();
    // }, [])

    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await ComisionesEmpresaService.getAllPendientesDePagoComisionEmpresa(page, pageRows,str, FechInicio, FechaFin)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
                setIdsExportar([]);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDownloadExcel = () => {
        // refBtn.current.disabled = true;
        if (IdsExportar.length > 0) {
            setopenModalTotales(true);
        } else {
            //     refBtn.current.disabled = false;
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione algunos registros  para exportar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsExportar(current => [...current, value]);
        } else {
            setIdsExportar(IdsExportar.filter(item => item.generalId !== value.generalId));
        }        
    }
    const handleCostos = () => {
        let total = 0;
        IdsExportar?.map(x => {
            total = total + x.calculoComision
        })
        return total;
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        DataAseguradora,
        selectRef,
        setAseguradora,
        setFechInicio,
        setFechaFin,
        IdsExportar,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        handleCostos,
        setIdsExportar
    }
}

export default useComisionesDeEmpresaPendientePago