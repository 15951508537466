import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate } from 'react-router-dom';
import { FormatNumberMoney2, message } from '../../../Utils/functions';
import Spinner from '../../../Components/Loadig';
import PagoRefrendoPlacasService from '../../../Services/Gestoria/PagoRefrendoPlacasService';

const DesagruparModalNotificacion = ({ isOpen, setIsOpen, item }) => {

    return (
        <Modal isOpen={isOpen} color='#fff'>
            <section className='my-2 mx-4' style={{ width: '18rem' }}>
                <span className='fs-5'><strong>Grupos creados</strong> <i style={{ color: 'green' }} className='ms-2 bi bi-check-circle-fill'></i></span>
                <div className="mb-3">
                    <label style={{ fontSize: '13px' }} className="col-form-label fw-bold">Rango: ${FormatNumberMoney2(item.RangoMenor)} - ${FormatNumberMoney2(item.RangoMayor)}</label> <br />
                    <label style={{ fontSize: '13px' }} className="col-form-label">Se crearon exitsamente los grupos</label> <br />
                    <label style={{ fontSize: '13px' }} className="col-form-label fw-bold">Grupos creados: {item.GruposCreados}</label>
                </div>
                <section className='my-3 d-flex justify-content-end'>
                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
                </section>
            </section>
        </Modal>
    )
}

export default DesagruparModalNotificacion