import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SubMenuPagoUnidad = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Obtener la ruta actual

  const menuItems = [
    { id: 1, label: 'Seguimiento pago de unidades', path: '/inventario/pagoUnidad/seguimiento-pago-unidades' },
    { id: 2, label: 'Unidades pendientes de pago', path: '/inventario/pagoUnidad/unidades-pendientes' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {menuItems.map((item) => (
        <li className="nav-item" role="presentation" key={item.id}>
          <button
            className={`nav-link ${location.pathname === item.path ? 'active' : ''}`} // Verificar si la ruta actual coincide con el path
            type="button"
            role="tab"
            onClick={() => handleNavigation(item.path)}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default SubMenuPagoUnidad;
