import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { chekItem, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';
import AuthLogin from '../Utils/AuthLogin';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();
const DatamovilSidebar = () => {    
    const { visible } = useSelector((state) => state.changeMenu);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }

    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }
    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div id="lbl_Inventario_Sidebar" className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Datamovil Systems"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Servicios Datamovil</strong></li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/servicio/1" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-search-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Revisión</span>
                        </NavLink >
                    </li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/servicio/2" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-settings-2-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Demo</span>
                        </NavLink >
                    </li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/servicio/3" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-tools-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Desinstalación</span>
                        </NavLink >
                    </li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/servicio/4" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-refresh-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Reinstalación</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Seguimiento de servicios</strong></li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/asignaciones" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-arrow-left-right-fill"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Asignación de servicios</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Gestión de solicitudes</strong></li>
                    <li className="nav-item">
                        <NavLink to="/datamovil/solicitudes" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-file-list-2-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Autorización de solicitudes</span>
                        </NavLink >
                    </li>
                </ul>
            </aside>
        </>
    )
}

export default DatamovilSidebar