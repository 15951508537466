import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ValuacionRegistroCostoService from '../../../Services/Inventario/Valuacion/ValuacionRegistroCostoService';

const useRevisionPasos = (toast) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [activebtncontinuar, setActiveBtnContinuar] = useState(false);
    const [stepe, setStepe] = useState([]);
    const [stepSelected, setStepSelected] = useState();
    const [loading, setLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [codStatus, setCodStatus] = useState(0);

    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar]);

    const handleGetAllStep = async () => {
        setLoading(true);
        try {
            const res = await ValuacionRegistroCostoService.getAllStepe(id, state.tipoProceso);
            let step = res.data.data;
            step.forEach(x => {
                if (x.pasoSubStatus === 2) {
                    setStepSelected(x);
                }
            });
            setStepe(step);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClickTerminar = () => {
        setModalConfirm(true);
    };

    const handleClickTerminarProceso = async () => {
        setModalConfirm(false);
        setLoading(true);
        const data = {
            id,
            paso: stepSelected.paso,
            porcentaje: 100,
            pasoSubStatus: 3,
            procesoId: state.tipoProceso
        };
        try {
            const res = await ValuacionRegistroCostoService.updStep(data);
            if (res.data.data.ban === 1) {
                toast.success(`¡Correcto! ${res.data.data.mensaje}`);
                if (stepSelected.paso !== 4) {
                    //Cambiar a paso 2,3,4
                    //datamovil/5/1/c3cc687f-cf8f-465e-af77-63497d3c567a/1
                    navigate(`/datamovil/${state.tipoProceso}/${state.tipoServicioid}/${id}/${stepSelected.paso + 1}`, {
                        state: { ...state }
                    });
                } else {
                    //Cambiar a listado de revision
                    navigate(`/datamovil/servicio/${state.tipoServicioid}`)
                }
            } else {
                toast.error(`¡Operación fallida! ${res.data.data.mensaje}`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatusBtn = (item) => {
        console.log(item,"Me llego en el item")
        if (item.concluido) {
            setActiveBtnContinuar(true);
        }
        setCodStatus(item?.codStatus);
    };

    return {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        codStatus
    };
};

export default useRevisionPasos;
