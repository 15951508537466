import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Llamada from "../../Services/Cartera/Llamadas";
import CobranzaService from "../../Services/Cobranza/Cobranza";
// import { useLocalStorage } from "./useLocalStorage";

const useCobradoresDetalle = (infoContratos) => {
   const { usuario, ZonasCobranza } = useSelector((state) => state.UsuarioSeleccionadoSlice);
   const [datosUsuario, setDatosUsuario] = useState({})
   // const [data, setData] = useState(datosUsuario.contratos)
   // const allUsuariosData = infoContratos;
   // const contratos = infoContratos.flatMap(usuario => usuario.contratos)
   const [data, setData] = useState(infoContratos.flatMap(usuario => usuario.contratos))
   // const [dataZonasCobranza, setDataZonasCobranza] = useState(ZonasCobranza);
   const [btnEditConfirm, setBtnEditConfirm] = useState(1);
   const [isOpen, setIsOpen] = useState(false);
   const [dataEstado, setDataEstado] = useState([]);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [loading, setLoading] = useState(false);
   const [openModalHistorial, setOpenModalHistorial] = useState(false);
   // const userName = sessionStorage.getItem('usuario')
   const userId = sessionStorage.getItem('userId').trim()
   const [currentData, setCurrentData] = useState([])
   const [zonasTotalesActuales, setZonasTotalesActuales] = useState([])
   const [ zonasTotales, setZonasTotales ] = useState([])

   const [zonasSeleccionadas, setZonasSeleccionadas] = useState([])
   // const [dataZonas, setDataZonas] = useState([])
   const [dataZonasTotales, setDataZonasTotales] = useState([])
   const [filtroEstado, setFiltroEstado] = useState('')
   const [filtroMunicipio, setFiltroMunicipio] = useState('')
   // const [currentPage, setCurrentPage] = useState(1);
   const [pageRows, setpageRows] = useState(10)
   const [pageCount, setPageCount] = useState();
   const [actualizar, setActualizar] = useState("");
   // const [ dataContratos, setContratos ] = useState([]) 

   useEffect(() => {
      setDatosUsuario(JSON.parse(sessionStorage.getItem('UsuarioSeleccionadoSlice')))
      handleGetzonas()
      setActualizar("");
      setInitialValue({
         userid: usuario?.userid,
         nombrecobrador: usuario?.nombrecobrador,
         usuariosistema: usuario?.usuariocobrador === "" ? "" : usuario?.usuariocobrador?.split('@')[0],
         nivel: !usuario?.nivel_cobrador ? "" : usuario?.nivel_cobrador?.value,
         telefono: usuario?.telefono,
         status: usuario?.status,
         estatusdesistema: usuario?.estatusdesistema,
         statussistem: usuario?.estatusdesistema === null ? false : usuario?.estatusdesistema?.toLowerCase() === "disponible" ? true : false,
         estado: "",
         municipio: ""
      })
      HandleGetEstadoMx()
   }, [actualizar])

   const [initialValue, setInitialValue] = useState({});
   const navigate = useNavigate();
   const [str, setStr] = useState("");
   const [OpenModalEditar, setIsOpenModalEditar] = useState(false);
   const [OpenModalReasignacion, setIsOpenModalReasignacion] = useState(false);

   const HandleGetEstadoMx = async () => {
      setLoading(true);
      await Llamada.GetEstadoMx().then(res => {
         let Estados = []
         res?.data?.data?.map(item => {
            Estados.push({
               value: item?.edoMexicoId,
               label: item?.estado
            });
         });
         setDataEstado(Estados);
      })
      setLoading(false);
   }

   const HandleGetMunicpio = async (id) => {
      setDataMunicipio([]);
      await Llamada.GetMunicipio(id).then(res => {
         let Municipios = []
         res?.data?.data?.map(item => {
            Municipios.push({
               value: item?.municipioId,
               label: item?.municipio
            });
         });
         setDataMunicipio(Municipios);
      })
   }

   useEffect(()=>{
      if(zonasTotales.length > 0){
         handleGetAllData()
      }
   },[zonasTotales])

   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      let filteredData = data.filter(item => {
         const nameMatches = str === '' || (item?.zona && item?.zona.toLowerCase().includes(str.toLowerCase()));
         return nameMatches
      })

      let filterZonas = dataZonasTotales.filter(item => {
         const nameMatches = str === '' || (item?.zona && item?.zona.toLowerCase().includes(str.toLowerCase()));
         return nameMatches
      })

      setCurrentData(filteredData)

      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)

      setZonasTotalesActuales(filteredData)

      const newTotalPagesZ = Math.ceil(filterZonas.length / pageRows)
      setPageCount(newTotalPagesZ)

   }, [str, data, filtroEstado, filtroMunicipio])

   // const handleCargarZonasCobranza = () => {
   //    setLoading(true)
   //    let zonasCobranzaUsuario = []
   //    let listaZonas = new Set()
   //    dataContratos?.map(contrato => {
   //       listaZonas.add(contrato?.zona)
   //    })

   //    // let zonasTotalesCobranza = []
   //    // let listaZonasTotales = new Set()
   //    // dataZonasCobranza?.map(contrato => {
   //    //    listaZonasTotales.add(contrato?.zona)
   //    // })

   //    for (const zona of listaZonas) {
   //       let num = 0
   //       let counter = 0
   //       let totalZonas = []
   //       let newData = [{}]
   //       data?.map(contrato => {
   //          if (contrato?.zona === zona && (filtroEstado === '' || contrato?.estado === filtroEstado) && (filtroMunicipio === '' || contrato?.municipio === filtroMunicipio)) {
   //             counter++
   //             const index = totalZonas.findIndex(obj => obj?.estado === contrato?.estado && obj?.municipio === contrato?.municipio && obj?.colonia === contrato?.colonia);
   //             if (index !== -1) {
   //                totalZonas[index].contratos++;
   //             } else {
   //                num++
   //                totalZonas.push({
   //                   numero: num,
   //                   estado: contrato?.estado,
   //                   municipio: contrato?.municipio,
   //                   colonia: contrato?.colonia,
   //                   contratos: 1,
   //                   // personaasignada: allUsuariosData.find(usuario => usuario.contratos.some(c => c.id === contrato?.id))?.nombrecobrador
   //                   // id: contrato?.zona
   //                })
   //             }
   //          }
   //          else {
   //             newData.push(contrato)
   //          }
   //       })
   //       let zonasFiltradas = removeDuplicates(totalZonas, "colonia");

   //       if (zonasFiltradas.length > 0) {
   //          zonasCobranzaUsuario.push({
   //             zonacobranza: zona,
   //             constratosasignados: counter,
   //             detail: zonasFiltradas,
   //             // personaasignada: [...new Set(zonasFiltradas.map(item => item.personaasignada))].join(', ')
   //          })
   //       }
   //       setData(newData)
   //    }

   //    // for (const zona of listaZonasTotales) {
   //    //    let num = 0
   //    //    let counter = 0
   //    //    let totalZonas = []
   //    //    let newData = [{}]
   //    //    dataZonasCobranza?.map(contrato => {
   //    //       if (contrato?.zona === zona && (filtroEstado === '' || contrato?.estado === filtroEstado) && (filtroMunicipio === '' || contrato?.municipio === filtroMunicipio)) {
   //    //          counter++
   //    //          const index = totalZonas.findIndex(obj => obj?.estado === contrato?.estado && obj?.municipio === contrato?.municipio && obj?.colonia === contrato?.colonia);
   //    //          if (index !== -1) {
   //    //             totalZonas[index].contratos++;
   //    //          } else {
   //    //             num++
   //    //             totalZonas.push({
   //    //                numero: num,
   //    //                estado: contrato?.estado,
   //    //                municipio: contrato?.municipio,
   //    //                colonia: contrato?.colonia,
   //    //                contratos: 1
   //    //             })
   //    //          }
   //    //       }
   //    //       else {
   //    //          newData.push(contrato)
   //    //       }
   //    //    })
   //    //    let zonasFiltradas = removeDuplicates(totalZonas, "colonia");
   //    //    if (zonasFiltradas.length > 0) {
   //    //       zonasTotalesCobranza.push({
   //    //          zonacobranza: zona,
   //    //          constratosasignados: counter,
   //    //          detail: zonasFiltradas
   //    //       })
   //    //    }
   //    //    setDataZonasCobranza(newData)
   //    // }

   //    // setDataZonasCobranza(ZonasCobranza)
   //    // setData(datosUsuario?.contratos)
   //    // setDataZonasTotales(zonasTotalesCobranza)
   //    setDataZonas(zonasCobranzaUsuario)
   //    setLoading(false)
   // }

   // function removeDuplicates(originalArray, prop) {
   //    var newArray = [];
   //    var lookupObject = {};
   //    let counter = 0

   //    for (var i in originalArray) {
   //       lookupObject[originalArray[i][prop]] = originalArray[i];
   //    }

   //    for (i in lookupObject) {
   //       newArray.push(lookupObject[i]);
   //    }

   //    let arregloFormateado = newArray?.map(z => {
   //       counter++
   //       return {
   //          indice: counter,
   //          ...z
   //       }
   //    })
   //    return arregloFormateado;
   // }

   const handleGetAllData = async () => {
      setLoading(true)
      let dataContratos = [];
      // await CobranzaService.getEjecutivosFullDetail()
      await CobranzaService.getAllCobranzaEventoItem()
         .then(res => {
            if (res?.data?.callSucceded) {
               res?.data?.responseData?.map(item => {
                  // if (item?.userid === "3ee191ea-91fa-4a2d-9a26-1b5add6aecd2") {
                  if (item?.cartera_vencida === 1 && 
                      item?.nivel_cobranza === "2" && 
                      item?.zonaid !== "" && 
                      item?.zonaid !== null && 
                      item?.zonaid !== undefined &&
                      (item?.userid?.toLowerCase() === datosUsuario?.usuario?.userid?.toLowerCase() || item?.userid?.toLowerCase() === "")) {
                     item.contratos = 1;
                     item.statusCheckBox = item?.userid?.toLowerCase() === datosUsuario?.usuario?.userid?.toLowerCase();
                     item.disableCheckBox = item.statusCheckBox;
                     if (item.statusCheckBox) {
                        item.usuario = usuario?.nombrecobrador?.toUpperCase();
                     } else {
                        item.usuario = "POR ASIGNAR";
                     }
                     let filterZona = zonasTotales?.filter(z=>{
                        let zona =  z?.id === item?.zonaid;
                        // let zona =  z?.id === "09543db1-fb63-47b5-beed-2833ad0518d6"
                        return zona;
                     });
                     item.zona = filterZona[0]?.zona;
                     let found = false;
                     for (const data of dataContratos) {
                        if (data.zonaid === item.zonaid) {
                           data.contratos++;
                           data.contratoList.push(item);
                           found = true;
                        }
                     }
                     if (!found) {
                        item.contratoList = [item];
                        dataContratos.push(item);
                     }
                  }
               })
               dataContratos = dataContratos?.filter(z => z.contratos > 0);
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      setData(dataContratos)
      // setContratos(dataContratos)
      setLoading(false);
   }

   const columns = [
      { field: 'zona', headerName: 'Zona de cobranza' },
      { field: 'contratos', headerName: 'Contratos' },
      { field: 'usuario', headerName: 'Persona Asignada' },
   ]

   const handleCheck = (checked, value) => {
      let actualizarZonas = []
      if (checked) {
         zonasSeleccionadas.map(item => {
            actualizarZonas.push(item)
         })
         actualizarZonas.push(value)
      }
      else {
         zonasSeleccionadas.map(item => {
            if (item.indice !== value.indice) {
               actualizarZonas.push(item)
            }
         })
      }
      setZonasSeleccionadas(actualizarZonas)
   }

   const handleGetzonas = async () => {
      setLoading(true);
      await CobranzaService.getAllZonaHead()
         .then(res => {
            setZonasTotales(res?.data?.responseData);
         }).catch(() => {
            setLoading(false);
         })
         setLoading(false);
   }

   const handleConfirmar = async () => {
      setIsOpen(false);
      setLoading(true);
      setIsOpen(false);
      let dataContratos = []

      zonasSeleccionadas.map(z => {
         z.contratoList.map(i => {
            dataContratos.push({ "id": i.id, "cartera_vencida": 1 });
         });
      });

      console.log(dataContratos);

      let data = {
         "verb": "string",
         "id": userId.toLowerCase(),
         "field": "string",
         "value": datosUsuario?.usuario?.userid?.toLowerCase(),
         "level": 0,
         "flag": true,
         "anyObject": dataContratos,
         "kvp": {}
      }

      console.log(data)
 
      await CobranzaService.reAsignarCarga(data)
         .then(res => {
            console.log(res)
            setLoading(false);
            navigate(-1)
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR ${e}`);
         })

      setBtnEditConfirm(1);
      setZonasSeleccionadas([])
   }

   const handleHistorial = () => {
      setOpenModalHistorial(true);
   }

   const handleCancelar = () =>{
      setZonasSeleccionadas([])
      setBtnEditConfirm(1); 
      setIsOpen(false)
   }

   return {
      loading,
      initialValue,
      setIsOpenModalEditar,
      handleHistorial,
      btnEditConfirm,
      setBtnEditConfirm,
      setIsOpen,
      columns,
      zonasTotalesActuales,
      handleCheck,
      // columnsDetail,
      currentData,
      OpenModalEditar,
      datosUsuario,
      setFiltroMunicipio,
      setFiltroEstado,
      HandleGetMunicpio,
      dataEstado,
      dataMunicipio,
      setStr,
      setActualizar,
      OpenModalReasignacion,
      setIsOpenModalReasignacion,
      openModalHistorial,
      setOpenModalHistorial,
      isOpen,
      handleConfirmar,
      handleCancelar
   }
}

export default useCobradoresDetalle