import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useUnidadesEnRemateDetalleInventario = ()  =>{
    const {itemUnidadesRemate} = useSelector((state) => state.UnidadesEnRemateSlice);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [vehiculo, setVehiculo]=useState({})
    const [contrato, setContrato] = useState({})

    useEffect(()=>{
        setVehiculo({
            vin : itemUnidadesRemate.vehiculo.vin,
            placas : itemUnidadesRemate.general.numPlaca,
            nombre : itemUnidadesRemate.nombre, // No hay campo nombre
            colorVehiculo : itemUnidadesRemate.vehiculo.colorVehiculo,
            marca : itemUnidadesRemate.vehiculo.marca, // No hay campo marca
            kilometraje : itemUnidadesRemate.vehiculo.kilometraje, // No hay campo kilometraje
            nombreModelo : itemUnidadesRemate.vehiculo.nombreModelo,
            procedenciaAuto : itemUnidadesRemate.vehiculo.procedenciaAuto, // No hay campo procedenciaAuto
            nombreVersion : itemUnidadesRemate.vehiculo.nombreVersion, 
            numMotor : itemUnidadesRemate.vehiculo.numMotor, // No hay campo numMotor
            nombreYear : itemUnidadesRemate.vehiculo.nombreYear,
            fechaEntrega : itemUnidadesRemate.vehiculo.fechaEntrega // No hay campo fechaEntrega
        })
        setContrato({
            estatus : itemUnidadesRemate.estatus, // No hay campo estatus
            contrato_id: itemUnidadesRemate.general.numContrato,
            nombre: itemUnidadesRemate.nombre, // No hay campo nombre
            apellido_paterno: itemUnidadesRemate.apellido_paterno, // No hay campo apellido_paterno
            pago_periodo: itemUnidadesRemate.pago_periodo, // No hay campo pago_periodo
            usoUnidad: itemUnidadesRemate.usoUnidad, // No hay campo usoUnidad
            frecuencia_pago: itemUnidadesRemate.frecuencia_pago, // No hay campo frecuencia_pago
            saldo_credito: itemUnidadesRemate.saldo_credito, // No hay campo saldo_credito
            fideicomiso: itemUnidadesRemate.fideicomiso, // No hay campo fideicomiso
            fecha_ultimo_pago: itemUnidadesRemate.fecha_ultimo_pago // No hay campo fecha_ultimo_pago
        })
    },[])

    const handleReturnPage = () => {
        navigate(`/inventario/unidades-en-remate`);
    }

    return{
        vehiculo,
        contrato,
        loading,
        handleReturnPage
    }
}

export default useUnidadesEnRemateDetalleInventario;