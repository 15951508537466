import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   value: 1
}
export const NavigateSupervisionCargaSlice = createSlice({
   name: 'NavigateSupervisionCargaSlice',
   initialState,
   reducers: {
      tabSupervisionCargaMenu: (state, action) => {
         return { ...state, value: parseInt(action.payload) }
      }
   },
})

export const { tabSupervisionCargaMenu } = NavigateSupervisionCargaSlice.actions

export default NavigateSupervisionCargaSlice.reducer