import React from 'react'
import '../Pages/Login/Login.css'
const Footer = ({ cls = "" }) => {
  let year = new Date().getFullYear();
  return (
    <>
         <footer className={cls}>
                <div className="row mt-5">               
                    <div className="col-12 mt-5">
                        <h5 className='text-white fs-6'>Copyright © {year} Dataware</h5>
                    </div>
                </div>
            </footer>
    </>
  )
}

export default Footer