import React, { useState } from 'react'
import { useEffect } from 'react'
import EtapaService from '../../Services/ControlEtapa/confEtapa';

const useHistorialEtapa = () => {

  const [dataProd, setDataProd] = useState([]);
  const [dataTipoCompra, setDataTipoCompra] = useState([]);
  const [dataEtapa, setDataEtapa] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [selectedValue, setSelectedValue] = useState(1);
  const [defArea, setDefaultArea] = useState(1);
  const [defEtapa, setdefaultEtapa] = useState(1);

  //Etapa
  const [productValue, setProductValue] = useState("");
  const [tipoCompraValue, setTipoCompraValue] = useState("");
  const [isEnabledTipoCompra, setisEnabledTipoCompra] = useState(false);
  const [dataGroups, setDataGroups] = useState([]);
  const [dataESA, setDataESA] = useState([]);
  const [isOpenModalEtapa, setIsOpenModalEtapa] = useState(false);
  const [dataGroupSelect, setDataGroupSelect] = useState([]);
  const [isOpenModalGroup, setIsOpenModalGroup] = useState(false);
  const [nameGroup, setNameGroup] = useState("");
  const [isOpenModalCambioEtapa, setIsOpenModalCambioEtapa] = useState(false);
  const [dataEtapaSA, setDataEtapaSA] = useState({grupoId:"",etapaId:""});
  const [grupoValue, setGrupoValue] = useState("");
  const [etapaValue, setetapaValue] = useState("");
  const [dataTipoRegla, setDataTipoRegla] = useState([]);
  const [dataGrupo, setDataGrupo] = useState(null);
  //

  // let dataTipoRegla = [
  //   { value: 1, label: "Automáticas" },
  //   { value: 2, label: "Descriptivos" },
  //   { value: 3, label: "Reversión" },
  //   { value: 4, label: "Bloqueo" },
  // ];
  useEffect(() => {
    handleGetAllTipoRegla();
    handleGetProducto();
    handleGetAllTipoCompra();
    handleGetAllEtapa();
  }, [selectedValue])

  useEffect(() =>{    
    if(tipoCompraValue !== "" && productValue !== ""){
      handleChangeTipocompra();
      handleGetEtapasSinAsignar();
    }    
  }, [tipoCompraValue,productValue])
  
  const handleGetAllTipoRegla = async () =>{
    setLoading(true);
    await EtapaService.getAllTipoRegla()
      .then(res => {
        let NewData = [];
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoReglaId,
            label: item.nombre
          })
        });
        setDataTipoRegla(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handleGetProducto = async () => {
    setLoading(true);
    await EtapaService.getAllProducto()
      .then(res => {
        let NewData = [];
        res.data.data?.map(item => {
          NewData.push({
            value: item.productoId,
            label: item.producto
          })
        });
        setDataProd(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetAllTipoCompra = async () => {
    setLoading(true);
    await EtapaService.getAllTipoCompra()
      .then(res => {
        let NewData = [];
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        setDataTipoCompra(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetAllEtapa = async () => {
    setLoading(true);
    await EtapaService.getAllCSEtapa()
      .then(res => {
        let NewData = [];   
        NewData.push({
          value: "",
          label: "Todos"
        })  
        res.data.data?.map(item => {
          NewData.push({
            value: item.etapaId,
            label: item.etapa
          })
        });
        setDataEtapa(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handleChange = (value) => {    
    setSelectedValue(value);
    if (value < 4)
      setdefaultEtapa(value);
    else
      setDefaultArea(value);

    if(value == 3){      
      setDataGroups([]);
    }
  }
  const handleChangeProduct = (value) => {    
    setProductValue(value);
    // setisEnabledTipoCompra(true);
  }
  const handleChangeTipocompra = async () => {
    setLoading(true);
    // setTipoCompraValue(value);
    await EtapaService.getAllGroups(productValue, tipoCompraValue)
      .then(res => {        
        let data = res.data.data;        
        setDataGroups(data)
        let NewData = [];
        data?.map((item) => {
          if(!item.fijo){
            NewData.push({
              value: item.grupoId,
              label: item.grupo
            })
          }
        
          // if(data.length>0)
          //   handleGetEtapasGroup(item);          
        })
        if(data.length===0)
          setDataGroups([]);
        setDataGroupSelect(NewData);
        setLoading(false);
        // handleGetEtapasSinAsignar();
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetEtapasSinAsignar = async () => {
    setLoading(true);
    await EtapaService.getAllEtapasSinAsignar(productValue, tipoCompraValue)
      .then(res => {                
        setDataESA(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  let newData = [];

  const handleGetEtapasGroup = async (item) => {
    setLoading(true);
    await EtapaService.getAllEtapasbyGroup(item.grupoId)
      .then(res => {        
        if (res.data.data.length > 0)
          newData.push({
            ...item,
            data: res.data.data
          });
        else{
          newData.push({
            ...item,
            data: []
          });
        }
        setDataGroups(newData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleAddEtapaGrup = async (event,item) =>{
    setGrupoValue("");
    setetapaValue("");
    setIsOpenModalCambioEtapa(true);
    let newItemEtapa = {grupoId:event,etapaId:item.etapaId}
    setDataEtapaSA(newItemEtapa);
  
  }
  const handleAddEtapa = async () =>{    
    setLoading(true);
    await EtapaService.addEtapaGrupo(dataEtapaSA.grupoId,dataEtapaSA.etapaId)
      .then(res => {
        handleChangeTipocompra(tipoCompraValue);
        handleGetEtapasSinAsignar(tipoCompraValue);
        setIsOpenModalCambioEtapa(false);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })    
  }
  const handleNewGroup = async () =>{    
    setLoading(true);
    await EtapaService.AddNewGroup(productValue,tipoCompraValue,nameGroup,dataGrupo.grupoId)
      .then(res => {
        handleChangeTipocompra(tipoCompraValue);
        handleGetEtapasSinAsignar(tipoCompraValue);
        setNameGroup("");
        setIsOpenModalGroup(false);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })    
  }
  const handleDelGrupo = async () =>{
    setLoading(true);    
    await EtapaService.DeleteGrupo(productValue,tipoCompraValue,grupoValue)
      .then(res => {
        handleChangeTipocompra(tipoCompraValue);
        handleGetEtapasSinAsignar(tipoCompraValue);
        setIsOpenModalCambioEtapa(false);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })    
  }
  const handleDelEtapaGrupo = async () =>{        
    await EtapaService.DeleteEtapaGrupo(grupoValue,etapaValue)
      .then(res => {
        handleChangeTipocompra(tipoCompraValue);
        handleGetEtapasSinAsignar(tipoCompraValue);
        setIsOpenModalCambioEtapa(false);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })    
  }
  return {
    Loading,
    setLoading,
    dataProd,
    dataTipoCompra,
    modalMessage,
    setModalMessage,
    handleChange,
    setSelectedValue,
    selectedValue,
    defArea,
    dataEtapa,
    dataTipoRegla,
    handleChangeProduct,
    isEnabledTipoCompra,
    handleChangeTipocompra,
    dataGroups,
    handleGetEtapasSinAsignar,
    dataESA,
    setIsOpenModalEtapa,
    isOpenModalEtapa,
    dataGroupSelect,
    handleAddEtapaGrup,
    isOpenModalGroup,
    setIsOpenModalGroup,
    handleNewGroup,
    nameGroup,
    setNameGroup,
    setIsOpenModalCambioEtapa,
    isOpenModalCambioEtapa,
    handleAddEtapa,
    setGrupoValue,
    grupoValue,
    handleDelGrupo,
    handleDelEtapaGrupo,
    setetapaValue,
    etapaValue,
    setDataESA,
    setDataGroups,
    productValue,
    tipoCompraValue,
    setTipoCompraValue,
    setProductValue,
    setDataGrupo
  }
}

export default useHistorialEtapa