import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik'
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../Components/SelectField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import { setDatosConsulta } from '../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const SeguimientoVisitaCards = React.forwardRef(({ ...props }, ref) => {
   const {itemGeneral, contratoActual} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const [selectedOption, setSelectedOption] = useState(0);
   const [ruta, setRuta] = useState("")
   const [dataPersona, setDataPersona] = useState([]);
   const [personaSeleccionada, setPersonaSeleccionada] = useState("");
   const [consultaSeleccionada, setConsultaSeleccionada] = useState(0)
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [dataTipoPersona, setDataTipoPersona] = useState([])

   useEffect(()=>{
      GetInformacionContactos()
   },[])

   const GetInformacionContactos = async () => {
      let NewData = [{value: 1, label: "Cliente"}]
      let counter = 2
      const data = {
         sol_id: contratoActual?.sol_id,
         contrato: contratoActual?.contrato_id,
         vin: contratoActual?.vin
      }

      await CobranzaService.getInformacionContactos(data)
         .then(res => {
            if(res?.data?.responseData?.cont_aval > 0){
               NewData.push({value: counter, label: "Aval"})
               counter++
            }
            if(res?.data?.responseData?.cont_apoderado > 0){
               NewData.push({value: counter, label: "Apoderado"})
               counter++
            }
            if(res?.data?.responseData?.cont_coacreditado > 0){
               NewData.push({value: counter, label: "Coacreditado"})
               counter++
            }
            if(res?.data?.responseData?.cont_referencia > 0){
               NewData.push({value: counter, label: "Referencia"})
            }
         }).catch(e => {
            setLoading(false);
            console.log(e)
         })
         setDataTipoPersona(NewData)
   }

   let initial = {
      tipoPersona: "",
      nombre: "",
      tipoConsulta: ""
   }
   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

   const dataTipoConsulta = [
      {value: 1, label: "Ver"},
      {value: 2, label: "Agregar"},
   ]

   const validate = Yup.object({
      tipoPersona: Yup.string()
         .required("El campo es requerido."),
      tipoConsulta: Yup.string()
         .required("El campo es requerido."),
      nombre: Yup.string()
         .required("El campo es requerido."),
   });

   const handleContinue = () => {
      handleSetDatosConsulta();

      if(consultaSeleccionada === 1){
         navigate(`/cobranza/seguimiento-visita/${ruta}-detalle`)
      }

      if(consultaSeleccionada === 2){
         navigate(`/cobranza/seguimiento-visita/${ruta}-agregar`)
      }
   }

   const handleGeolocalizacion = () =>{
      handleSetDatosConsulta();
      navigate("/cobranza/seguimiento-visita/geolocalizacion-detalle")
   }

   const handleSetDatosConsulta = () =>{
      dispatch(setDatosConsulta({
         persona: personaSeleccionada,
         sol_id: contratoActual?.sol_id,
         contrato: contratoActual?.contrato_id,
         vin: contratoActual?.vin,
         nombe_cliente: contratoActual?.nombre,
         ruta_item_id: itemGeneral?.id,
         usuario_id: itemGeneral?.userid
      }))
   }

   const HandleGetInformacionContactos = async (value) => {
      let NewData = []
      const data = {
         sol_id: contratoActual?.sol_id,
         contrato: contratoActual?.contrato_id,
         vin: contratoActual?.vin
      }
      setLoading(true)

      await CobranzaService.getInformacionContactos(data)
         .then(res => {
               if(res?.data?.callSucceded){
                  res?.data?.responseData?.datos_referencia.map((item) => {
                     if(value.toLowerCase() === item.tipo.toLowerCase()){
                        NewData.push({
                           label: `${item?.nombre_ref} ${item?.apellido_paterno_ref} ${item?.apellido_materno_ref}`,
                           value: item?.idreferencia,
                           item:item
                        })
                     }
                  })
               }
            setDataPersona(NewData)
            setLoading(false)
         })
         .catch(e=>{
            setLoading(false)
            console.log(e)
         })
   }

   return (
      <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
               {
                  modalMessage.type === 1 &&
                  <ErrorModal
                     modalMessage={modalMessage}
                     setModalMessage={setModalMessage}
                  />
               }
               {
                  modalMessage.type === 2 &&
                  <SuccessModal
                     modalMessage={modalMessage}
                     setModalMessage={setModalMessage}
                  />
               }
            </Modal>
            <Toaster
               position="top-right"
               toastOptions={{
                  success: {
                     style: {
                        background: '#47a066',
                        color: '#FFFF',
                        borderLeft: '10px solid #2f7246'
                     },
                  },
                  error: {
                     style: {
                        background: '#d53f3f',
                        color: '#FFFF',
                        borderLeft: '10px solid #ac241a'
                     },
                  },
               }}
            />
            <div className="row wrapper-vehiculo">
               <div className="col-12">
                  <div className='row mx-md-4' >
                     <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                           <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRegistroTramite" role="button" aria-expanded="false" aria-controls="collapseRegistroTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                              <div className="col-11">
                                 <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Consulta de información</strong></h6>
                              </div>
                              <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                 <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                              </div>
                           </div>
                           <div className="collapse" id="collapseRegistroTramite">
                              <Formik
                                 enableReinitialize={true}
                                 validationSchema={validate}
                                 initialValues={initialValue}
                                 onSubmit={(values) => {
                                 handleContinue(values);
                              }}>
                              {({ isValid, values, setFieldValue, errors }) => (
                                 <>
                                 <Form>
                                    <div className="separator"></div>
                                    <div className="row mt-2 justify-content-between">
                                       <div className="col-sm-8 col-xl-6">
                                          <small><strong>Seleccione una categoría para visualisar la información</strong></small>
                                       </div>
                                       <div className="col-sm-2 col-xl-1">
                                          <div className="row justify-content-end">
                                             <div className="col-auto">
                                             <i className={(values.nombre !== "" &&  values.tipoPersona !== "") ? "icon-blue ri-map-pin-add-line" : "icon-black ri-map-pin-add-line"}
                                                style={{fontSize: "30px"}}
                                                onClick={() => {
                                                   if(values.nombre !== "" &&  values.tipoPersona !== ""){
                                                      handleGeolocalizacion(values);
                                                   }
                                                }}></i>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row mt-2">
                                       <div className="col-sm-6 col-xl-3">
                                          <section className='d-flex justify-content-center mt-4'>
                                             <button
                                                className={selectedOption === 1 ? 'btn col-12' : "btn btn-outline col-12"}
                                                type='button'
                                                onClick={() =>{
                                                   setSelectedOption(1)
                                                   setRuta(`boletinar`)
                                                   setFieldValue("nombre", "");
                                                   setFieldValue("tipoPersona", "");
                                                }}>
                                                Boletinar
                                             </button>
                                          </section>
                                       </div>
                                       <div className="col-sm-6 col-xl-3">
                                          <section className='d-flex justify-content-center mt-4'>
                                             <button
                                                className={selectedOption === 2 ? 'btn col-12' : "btn btn-outline col-12"}
                                                type='button'
                                                onClick={() =>{
                                                   setSelectedOption(2)
                                                   setRuta(`contacto`)
                                                   setFieldValue("nombre", "");
                                                   setFieldValue("tipoPersona", "");
                                                }}>
                                                Contacto
                                             </button>
                                          </section>
                                       </div>
                                       <div className="col-sm-6 col-xl-3">
                                          <section className='d-flex justify-content-center mt-4'>
                                             <button
                                                className={selectedOption === 3 ? 'btn col-12' : "btn btn-outline col-12"}
                                                type='button'
                                                onClick={() =>{
                                                   setSelectedOption(3)
                                                   setRuta(`direccion`)
                                                   setFieldValue("nombre", "");
                                                   setFieldValue("tipoPersona", "");
                                                }}>
                                                Direcciones
                                             </button>
                                          </section>
                                       </div>
                                       <div className="col-sm-6 col-xl-3">
                                          <section className='d-flex justify-content-center mt-4'>
                                             <button
                                                className={selectedOption === 4 ? 'btn col-12' : "btn btn-outline col-12"}
                                                type='button'
                                                onClick={() => {
                                                   setSelectedOption(4)
                                                   setRuta(`referencias`)
                                                   setFieldValue("nombre", "-");
                                                   setFieldValue("tipoPersona", "-");
                                             }}>
                                                Referencias
                                             </button>
                                          </section>
                                       </div>
                                    </div>
                                    <div className="row mt-2 ">
                                       {
                                          selectedOption !== 4 && (
                                             <>
                                                <div className="col-sm-6 col-xl-3">
                                                <SelectField id="tipoPersona"
                                                   label="Tipo de persona"
                                                   name="tipoPersona"
                                                   items={dataTipoPersona}
                                                   onChange={(event) => {
                                                      HandleGetInformacionContactos(event.label)
                                                      if(event.value === ""){
                                                         setFieldValue("nombre", "");
                                                         setFieldValue("tipoPersona", "");
                                                      }
                                                      else{
                                                         setFieldValue("tipoPersona", event.label);
                                                      }
                                                }} />
                                                </div>
                                                <div className="col-sm-6 col-xl-3">
                                                <SelectField id="nombre"
                                                   label="Nombre"
                                                   name="nombre"
                                                   disabled={values.tipoPersona===""}
                                                   items={dataPersona}
                                                   onChange={(event) => {
                                                      setPersonaSeleccionada(event);
                                                      if(event.value === ""){
                                                         setFieldValue("nombre", "");
                                                      }
                                                      else{
                                                         setFieldValue("nombre", event.label);
                                                      }
                                                }} />
                                                </div>
                                             </>
                                          )
                                       }
                                       <div className="col-sm-6 col-xl-3">
                                       <SelectField id="tipoConsulta"
                                             label="Tipo de consulta"
                                             name="tipoConsulta"
                                             items={dataTipoConsulta}
                                             onChange={(event) => {
                                                if(event.value === ""){
                                                   setFieldValue("tipoConsulta", "");
                                                   setConsultaSeleccionada(0);
                                                }
                                                else{
                                                   setConsultaSeleccionada(event.value);
                                                   setFieldValue("tipoConsulta", event.label);
                                                }
                                          }} />
                                       </div>
                                       <div className="col-sm-6 col-xl-3">
                                          <section className='d-flex justify-content-center mt-4'>
                                             <button
                                                className='btn col-12'
                                                disabled={!(isValid) || selectedOption === 0}
                                                ref={refFormik}
                                                type="submit">
                                                Continuar
                                             </button>
                                          </section>
                                       </div>
                                    </div>
                                 </Form>
                                 </>
                              )}
                              </Formik>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
      </>
   )
})
export default SeguimientoVisitaCards