import React from 'react'
import Garantias from '../../Services/Garantias/Garantias';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';

const useGarantia = () => {
    const [str, setStr] = useState("");
    const [llamadasInfo, setLlamadasInfo] = useState([]);
    const [mantenimientos, setMantenimientos] = useState([]);
    const [garantias, setGarantias] = useState([]);
    const [dataVin, setDataVin] = useState({ generalId: "", serieDatamovil:"",fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "", numSolicitud: "", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" });
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [infoTabs, setInfoTabs] = useState(false);
    const [busquedaRealizada, setBusquedaRealizada] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            setStr(id);
        }
    }, []);

    useEffect(() => {
        setLlamadasInfo([]);
        setMantenimientos([]);
        setGarantias([]);
        handleGetContract(str);
    }, [str])

    const handleGetContract = async (str) => {
        //Validacion de espaciado
        if (str.trim().length == 0) {
            //No se realiza busqueda
            setBusquedaRealizada(false);
            setLoading(false);
            return false;
        }

        setLoading(true);
        setBusquedaRealizada(true);

        //Llama a los servicios para la busqueda
        await Garantias.getContract(str?.trim())
            .then(async res => {
                if (res.data.statusCode === 204) {
                    const msgNotVin = "El VIN ingresado no se encuentra relacionado a ninguna incidencia.||Revisa el VIN o No. de contrato de tu vehículo e intenta reingresarlo nuevamente para tener acceso a las incidencias registradas.";
                    // setStr("");
                    setLoading(false);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 3, title: 'No se encontró el vehículo', message: msgNotVin });
                    setDataVin({ ...dataVin,serieDatamovil:"", generalId: "", fechaEntrega: "", vin: "", nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "", color: "", kilometraje: "", procedenciaAuto: "", numMotor: "", numContrato: "", numSolicitud: "", cliente: "", fechaUltimoPago: "", producto: "", usoUnidad: "" })
                    return false;
                }
                
                console.log("UseLLamada 2")

                setDataVin({
                    ...dataVin,
                    generalId: res.data.data.generalId,
                    fechaEntrega: res.data.data.fechaEntrega,
                    vin: res.data.data.vin,
                    nombreMarca: res.data.data.nombreMarca,
                    nombreModelo: res.data.data.nombreModelo,
                    nombreVersion: res.data.data.nombreVersion,
                    nombreYear: res.data.data.nombreYear,
                    color: res.data.data.color,
                    kilometraje: res.data.data.kilometraje,
                    procedenciaAuto: res.data.data.procedenciaAuto,
                    numMotor: res.data.data.numMotor,
                    numContrato: res.data.data.numContrato,
                    numSolicitud: res.data.data.numSolicitud,
                    cliente: res.data.data.cliente,
                    fechaUltimoPago: res.data.data.fechaUltimoPago,
                    producto: res.data.data.producto,
                    usoUnidad: res.data.data.usoUnidad,
                    TallerAutorizado: res.data.data.taller,
                    FolioCarnet: res.data.data.folioCarnet,
                    Expediente: res.data.data.expediente,
                    serieDatamovil : res.data.data.serieDatamovil
                    // Falta agregar
                })
                handleGetLlamadas(res.data.data.generalId)
                handleGetGarantias(res.data.data.generalId)
                handleGetMantenimientos(res.data.data.generalId)

                setLoading(false);
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })
    }

    const handleGetLlamadas = async (id) => {
        return await Garantias.getLlamadas(id)
            .then(res => {
                const data = res.data.data;
                const datai = []

                data.map(element => {
                    datai.push({
                        llamadaId: element.llamadaId,
                        folioLlamada: element.folioLlamada,
                        fechaReporte: cambiarFormatoFecha(element.fechaReporte),
                        ejecutivo: element.ejecutivo,
                        motivo: element.motivo,
                        comentariosCliente: element.comentariosCliente,
                    })
                });

                setLlamadasInfo(datai);
                // console.log(datai,".-...................")
            })
            .catch(err => {
                // Manejo de errores
                console.log(err)
            });
    }

    const handleGetGarantias = async (id) => {
        await Garantias.getGarantias(id)
            .then(res => {
                const data = res.data.data;
                let datar = []
                data.forEach(element => {
                    datar.push(
                        {
                            llamadaId: element.llamadaId,
                            folioGarantia: element.folioGarantia,
                            fechaReporte: cambiarFormatoFecha(element.fechaReporte),
                            ejecutivo: element.ejecutivo,
                            tipoGarantia: element.tipoGarantia !== null ? element.tipoGarantia : 'Sin Garantía',
                            tipoReporte: element.tipoReporte,
                            reparacionUnidad: element.reparacionUnidad,
                            estatusGarantia: element.estatusGarantia,
                            ultimaActualizacion: element.ultimaActualizacion ? cambiarFormatoFecha(element.ultimaActualizacion) : "Sin Fecha",
                            estatusIncidencia: element.estatusIncidencia,
                            statusGarantiaId: element.statusGarantiaId
                        }
                    )
                });
                setGarantias(datar);
            }).catch(err => {
                return [];
            });
    }
    const handleGetMantenimientos = async (id) => {
        await Garantias.getMantenimientos(id)
            .then(res => {
                const data = res.data.data;
                let datar = []
                data.forEach(element => {
                    datar.push(
                        {
                            totalRows: element.totalRows,
                            generalId: element.generalId,
                            mantenimientoId: element.mantenimientoId,
                            fechaMantenimiento: cambiarFormatoFecha(element.fechaMantenimiento),
                            usuario: element.usuario,
                            tallerId: element.tallerId,
                            taller: element.taller,
                            kilometraje: element.kilometraje,
                            fechaHoraRegistro: element.fechaHoraRegistro,
                            supervisor: element.supervisor,
                            fechaHoraAutorizacion: element.fechaHoraAutorizacion,
                            statusMantenimiento: element.statusMantenimiento,
                            autorizado: element.autorizado,

                        }
                    )
                });
                setMantenimientos(datar);

            }).catch(err => {
                return [];
            });
    }

    const cambiarFormatoFecha = (fechaOriginal) => {
        if (fechaOriginal) {
            var partesFecha = fechaOriginal.split('/');
            var dia = partesFecha[0];
            var mes = partesFecha[1];
            var anio = partesFecha[2];
            var fechaFormateada = dia + '-' + mes + '-' + anio;
            return fechaFormateada;
        } else {
            return "";
        }

    }


    return {
        dataVin,
        setDataVin,
        loading,
        setModalMessage,
        modalMessage,
        setStr,
        str,
        busquedaRealizada,
        infoTabs,
        llamadasInfo,
        setLlamadasInfo,
        garantias,
        setGarantias,
        mantenimientos,
        setMantenimientos
    }

}

export default useGarantia