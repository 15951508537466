import React, { useEffect, useState } from 'react'
import Parametros from '../../Services/Parametros/Parametros';
import RowPages from '../../Components/RowPages';

const DatawareCus = () => {
  const columns = [
    { field: 'parametro', headerName: 'Párametro', width: 220 },
    { field: 'proceso', headerName: 'Proceso', width: 200 },
    { field: 'valor', headerName: 'Valor', width: 100 },
    { field: 'ultimoUpd', headerName: 'Ultima Modificación', width: 200 },
  ];
  const columnsH = [
    // { field: 'parametrosId', headerName: 'Id', width: 220 },
    { field: 'parametro', headerName: 'Parámetro', width: 280 },
    { field: 'valorAnterior', headerName: 'Valor Anterior', width: 200 },
    { field: 'valorNuevo', headerName: 'Valor Nuevo', width: 200 },
    { field: 'nombreUsuario', headerName: 'Usuario', width: 300 },
    { field: 'fechaString', headerName: 'Fecha', width: 250 },

  ];

  const [data, setData] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [str, setStr] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [initialPage, setInitialPage] = useState(0);

  const [strDataware, setStrDataware] = useState("")
  const [pageRowsData, setpageRowsData] = useState(10)
  const [initialPageData, setInitialPageData] = useState(0);
  const [totalPageData, setTotalPageData] = useState();
  const [currentPageData, setCurrentPageData] = useState(1);

  // stateModal
  const [isOpenModalParams, setIsOpenModalParams] = useState();
  const [pageRows, setpageRows] = useState(10)

  useEffect(() => {
    handleGetDataware(1);
    setInitialPageData(0);
  }, [strDataware,pageRowsData])

  useEffect(() => {
    handleGetHistory(1);
    setInitialPage(0);
  }, [str, dateTime, pageRows])


  const handleGetDataware = async (page) => {
    setLoading(true);
    await Parametros.getTablesParamsList("dataware",page,pageRowsData,strDataware)
      .then(res => {
        setTotalPageData(res.data.totalPages);

        const data = res.data.data.map(item => {
          if(item.parametroBd === "HorarioReenvioDePoliza"){
            const valores = item.valor.split("|");
            item.valor = valores[0];
            item.valorDos = valores[1];
          }
          return item;
        })

        setData(data);
        setLoading(false);
        handleGetHistory(1);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetHistory = async (page) => {
    setLoading(true);
    await Parametros.getHistoy(page, pageRows, str, dateTime, "dataware")
      .then(res => {
        setTotalPage(res.data.totalPages);
        setDataHistory(res.data.data);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleEdit = (item) => {
    setItem(item)
    console.log(item)
    setIsOpenModalParams(true);
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setInitialPage(event.selected);
    handleGetHistory(event.selected + 1);
  };
  const handlePageClickDataware = (event) => {
    // setCurrentPageData(event.selected + 1);
    setInitialPageData(event.selected);
    handleGetDataware(event.selected + 1);
  };
  return {
    columns,
    loading,
    modalMessage,
    setModalMessage,
    data,
    handleEdit,
    handleGetDataware,
    isOpenModalParams,
    setIsOpenModalParams,
    item,
    columnsH,
    dataHistory,
    totalPage,
    handlePageClick,
    setStr,
    setDateTime,
    initialPage,
    setpageRows,
    setStrDataware,
    setpageRowsData,
    handlePageClickDataware,
    initialPageData,
    totalPageData
  }
}

export default DatawareCus