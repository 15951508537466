import React, { useEffect, useRef, useState } from 'react'
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import ErrorModal from '../Modal/ErrorModal';
import BasicModal from '../Modal/BasicModal';
import SuccessModal from '../Modal/SuccessModal';
import { Formik } from 'formik'
import { SelectField } from '../SelectField';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import CalendarService from '../../Services/Calendar/Calendar';
import ScheduleModal from './ScheduleModal';
import DetailScheduleModal from './DetailScheduleModal';
import { useSelector, useDispatch } from 'react-redux'
import { setSucursal as setSucursalGarantia, setHour as setHourGarantia } from '../../Redux/Slice/garantiasSlice';
import { setSucursal as setSucursalGrua, setHour as setHourGrua } from '../../Redux/Slice/gruasSlice';
import { setSucursal as setSucursalEntrega, setHour as setHourEntrega } from '../../Redux/Slice/entregaSlice';
import { setSucursal as setSucursalFirma, setHour as setHourFirma } from '../../Redux/Slice/firmaSlice';
import { setSucursal as setSucursalEntregaExpediente, setHour as setHourEntregaExpediente } from '../../Redux/Slice/entregaExpedienteSlice';
import { setSucursal as setSucursalCitaDatamovil, setHour as setHourCitaDatamovil } from '../../Redux/Slice/citaDatamovilSlice';
import { setSucursal as setSucrusalCitaCambioPlacas, setHour as setHourCitaCambioPlacas } from '../../Redux/Slice/Gestoria/cambioPlacasSlice';
import { setSucursal as setSucrusalCitaAltaPlacas, setHour as setHourCitaAltaPlacas } from '../../Redux/Slice/Gestoria/altaPlacasSlice';
import { setSucursal as setSucrusalCitaTarjetaCirculacion, setHour as setHourCitaTarjetaCirculacion } from '../../Redux/Slice/Gestoria/tarjetaCirculacionSlice';
import { setSucursal as setSucrusalCitaGestoriaEntrega, setHour as setHourGestoriaEntrega } from '../../Redux/Slice/Gestoria/gestoriaEntregaSlice';

import { useParams } from 'react-router-dom';

const Calendar = ({sundayOn = false, type = 1, halfDay = 12, hourClose = 20}) => {
    const { tipoCalendario } = useSelector((state) => state.CalendarSlice);

    const { id } = useParams();
    const dispatch = useDispatch();
    const refFormik = useRef();
    const parentDiv = useRef(null);    
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' }); 
    let initial = {
        sucursalId: null,
        vista: 1
    }
    
    const Week = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    const [initialValue, setInitialValue] = useState(initial);

    useEffect(() => {
        setLoading(true);
        handleGetCalendar();
        handleGetSucursal();
        handleGetWidth(initialValue.vista);
    }, []);

    useEffect(() => {
        handleGetCalendar();
    }, [initialValue.sucursalId !== null]);

    

    const [sucursales, setSucursales] = useState([]);
    const [vista, setVista] = useState([
        {value: 1, label: "Mes"},
        {value: 2, label: "Semana"}
    ]);
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const [tamanoCasilla, setTamanoCasilla] = useState(0);
    const [today, setToday] = useState(initialDate);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [dataScheduleDay, setDataScheduleDay] = useState([]);
    const [minHour, setMinHour] = useState(0);
    const [maxHour, setMaxHour] = useState(0);
    const [schedulesWeek, setSchedulesWeek] = useState([]);

    useEffect(() => {
        handleGetCalendar();
    }, [currentDate]);

    
    const validate = Yup.object({});

    // Función para redimensionar el tamaño del calendario dependiendo de la vista, 1 = Mensual, 2 = Semanal
    const handleGetWidth = (vista) => {
        let cells = vista === 1 ? 7 : 8;
        const width = parentDiv.current ? (parentDiv.current.offsetWidth - 40) : 0;
        const widthDiv = Math.ceil((width /cells)-10);
        setTamanoCasilla(widthDiv);
    };

    const handleGetSucursal = async () => {
        await Catalogo.getAllUbicacion(1, 100)
        .then(resp => {
            let listSucursales = [];
            resp.data?.data.forEach(item => {
                listSucursales.push({
                    value: item.ubicacionId,
                    label: item.nombre
                });
            });
            setSucursales(listSucursales);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const handleGetCalendar = async () => {
        if(initialValue.sucursalId === null || (initialValue.sucursalId !== null && initialValue.sucursalId.trim() === "")){
            return false;
        }
        setLoading(true);
        const sucursalId = initialValue.sucursalId;

        let  inicial, final;
        if(initialValue.vista === 1){
            inicial = localeStringDate(new Date(currentDate.getFullYear(),currentDate.getMonth(),1));
            final = localeStringDate(new Date(currentDate.getFullYear(),currentDate.getMonth()+1,0));
        }else{
            const dayIn = currentDate.getDay();
            const dayEnd = 6 - dayIn;
            inicial = localeStringDate(new Date(currentDate.getFullYear(),currentDate.getMonth(), currentDate.getDate() - dayIn));
            final = localeStringDate(new Date(currentDate.getFullYear(),currentDate.getMonth(), currentDate.getDate() + dayEnd));
        }
        
        await CalendarService.getCalendario(type, inicial, final, sucursalId)
        .then(resp => {
            setData(resp.data.data);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    const getDataForDay = (day, type) => {
        let today = new Date();

        let fecha = today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0"+today.getDate()).slice(-2);
        const currentHour = ("0"+today.getHours()).slice(-2)+":"+("0"+today.getMinutes()).slice(-2);
        let items = data.filter((item) => item.fecha === day+"T00:00:00");

        let disponibles = 0;

        if(fecha === day){
            if(type === 1){
                items.forEach((item) => {
                    if(item.disponible === 1){
                        let hourInCourse = new Date()
                        let horario = item.horario.split(" ")[0];
                        hourInCourse.setHours(horario.split(":")[0], horario.split(":")[1],0,0);
                        let tmpHour = ("0"+hourInCourse.getHours()).slice(-2)+":"+("0"+hourInCourse.getMinutes()).slice(-2);
    
                        if(currentHour < tmpHour){
                            disponibles++;
                        }
                    }
                });
            }

            if(type === 2){                
                items.forEach((item) => {
                    if(item.disponible === 1){
                        let hourInCourse = new Date()
                        let horario = item.horario.split(" ")[0];
                        hourInCourse.setHours(horario.split(":")[0], horario.split(":")[1],0,0);
                        let tmpHour = ("0"+hourInCourse.getHours()).slice(-2)+":"+("0"+hourInCourse.getMinutes()).slice(-2);
    
                        if(currentHour >= tmpHour){
                            disponibles++;
                        }
                    }else if(item.disponible === 2){
                        disponibles++;
                    }
                });
            }

            
            if(type === 1 && disponibles > 0) {
                return  <span className="col-12 badge badge-success" onClick={(e) => handleOpenScheduleModal(day)}>Disponibles: {disponibles}</span> 
            }else if(type === 1 && disponibles === 0) {
                return  <span className="col-12 badge badge-danger">Disponibles: {disponibles}</span> 
            }else if(type === 2) {
                return  <span className="col-12 badge badge-secondary">Agendadas: {disponibles}</span> 
            }
        }else{
            disponibles = items.filter(item => item.disponible === type).length;
            if(type === 1 && disponibles > 0) {
                return  <span className="col-12 badge badge-success" onClick={(e) => handleOpenScheduleModal(day)}>Disponibles: {disponibles}</span> 
            }else if(type === 1 && disponibles === 0) {
                return  <span className="col-12 badge badge-danger">Disponibles: {disponibles}</span> 
            }else if(type === 2) {
                return  <span className="col-12 badge badge-secondary">Agendadas: {disponibles}</span> 
            }
        }
    }

    const getDisabled = (day) => {
        let total = data.filter((item) => item.fecha === day+"T00:00:00");
        let ocupados = data.filter((item) => item.fecha === day+"T00:00:00" && item.disponible === 2);
        let nodisponibles = data.filter((item) => item.fecha === day+"T00:00:00" && item.disponible === 0);
        if(ocupados.length === total.length || nodisponibles.length === total.length){
            return true;
        }
        return false;
    }

    // Función para obtener los días del mes
    const daysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        return new Date(year, month, 0).getDate();
    };

    // Función para armar el calendario mensual
    const generateCalendar = () => {
        const days = [];
        const daysCount = daysInMonth(currentDate);
        const initialDay = new Date(currentDate.getFullYear(),currentDate.getMonth(),1);
        const firstDay = initialDay.getDay();

        if(firstDay > 0){
            for (let i = 1; i <= firstDay; i++) {
                days.push(
                <div key={'daysoff_'+i} className="calendar-day  cell-calendar cell-calendar-disabled"  style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                    
                </div>
                );
            }
        }
    
        const todayStr = localeStringDate(today);
        for (let i = 1; i <= daysCount; i++) {
            const dayInProgress = new Date(currentDate.getFullYear(),currentDate.getMonth(),i);
            const day = localeStringDate(dayInProgress);
            if(dayInProgress.getTime() < today.getTime()){
                days.push(
                    <div key={'dayson_'+i} className="calendar-day cell-calendar cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:tamanoCasilla, lineHeight:'20px'}}>
                        <div className="row justify-content-end m-0 mt-2 mb-2">
                            <div className="col-2 px-2">
                                <small className={todayStr === day ? 'today' : ''}>{ ("0"+i).slice(-2) }</small>
                            </div>
                        </div>
                    </div>
                );
            }else{
                days.push(
                    <div key={'dayson_'+i} className={(dayInProgress.getDay() === 0 && !sundayOn) || getDisabled(day) ? 'calendar-day cell-calendar cell-calendar-disabled' : 'calendar-day cell-calendar'}  style={{maxWidth:tamanoCasilla, minHeight:tamanoCasilla, lineHeight:'20px'}}>
                        <div className="row justify-content-end m-0 mt-2 mb-2">
                            <div className="col-2 px-2">
                                <small className={todayStr === day ? 'today' : ''}>{ ("0"+i).slice(-2) }</small>
                            </div>
                        </div>
                        {
                            initialValue.sucursalId !== null && 
                            <div className="row justify-content-center mt-4 mb-1">
                                <div className="col-12 px-2">
                                    {getDataForDay(day,2)}
                                </div>
                            </div>
                        }
                        {
                            initialValue.sucursalId !== null && 
                            <div className="row justify-content-center mt-2 mb-1">
                                <div className="col-12 px-2">
                                    {getDataForDay(day,1)}
                                </div>
                            </div>
                        }
                    </div>
                );
            }
        }
    
        return days;
    };

    // Función para comparar las fechas en formato string
    const localeStringDate = (date) => {
        return date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2);
    }
    
    // Función para cambiar el tipo de calendario
    const handleVista = (value, valueState) => {
        setInitialValue({...valueState, vista: Number(value)});
    }

    useEffect(() => {
        setCurrentDate(new Date());
        handleGetCalendar();
    }, [initialValue.vista]);

    const handleSucursalId = (value, valueState) => {
        const name = sucursales.filter(sucursal => sucursal.value === value)[0];
        const dataSucursal = {
            sucursalId: value,
            sucursalName: name.label
        };
        if(tipoCalendario === 1){
            dispatch(setSucursalGarantia(dataSucursal));
        }else if(tipoCalendario === 2){
            dispatch(setSucursalGrua(dataSucursal));
        }else if(tipoCalendario === 3) {
            dispatch(setSucursalEntrega(dataSucursal));
        }else if(tipoCalendario === 6) {
            dispatch(setSucursalFirma(dataSucursal));
        }else if(tipoCalendario === 7) {
            dispatch(setSucursalEntregaExpediente(dataSucursal));
        }else if(tipoCalendario === 8) {
            dispatch(setSucursalCitaDatamovil(dataSucursal));
        }else if(tipoCalendario === 9) {
            dispatch(setSucrusalCitaCambioPlacas(dataSucursal));
        }else if(tipoCalendario === 10) {
            dispatch(setSucrusalCitaAltaPlacas(dataSucursal));
        }else if(tipoCalendario === 11) {
            dispatch(setSucrusalCitaTarjetaCirculacion(dataSucursal));
        }else if(tipoCalendario === 12) {
            dispatch(setSucrusalCitaGestoriaEntrega(dataSucursal));
        }
        setInitialValue({...valueState, sucursalId: value});
    }

    const handleOpenScheduleModal = (day) => {
        let disponibles = data.filter((item) => item.fecha === day+"T00:00:00");
        setDataScheduleDay(disponibles);
        setModalMessage({ ...modalMessage, isOpen: true, type: 4 });
    }


    const daysInWeek = 7;

    // Función para construir los encabezados de 
    const generateDaysOfWeek = () => {
        const days = [];
        const weekStart = new Date(currentDate);
        const todayStr = localeStringDate(today);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay());
        console.clear();

        
        const dayIn = currentDate.getDay();
        const inicial = new Date(currentDate.getFullYear(),currentDate.getMonth(), currentDate.getDate() - dayIn);
    
        // Celda sola para la columna de horarios
        days.push(
            <div key={'schedule_cell'} className="cell-calendar calendar-header-cell" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                
            </div>
        );
        for (let i = 0; i < daysInWeek; i++) {
            const dayInProgress = new Date(inicial.getFullYear(),(inicial.getMonth()),weekStart.getDate() + i);
            const dayInProgressToCompare = new Date(inicial.getFullYear(),(inicial.getMonth()),weekStart.getDate()+(i+1));
            const dayInProgressStr = localeStringDate(dayInProgress);
            const dayInProgressToCompareStr = localeStringDate(dayInProgressToCompare);
            const disponibilidad = getAvailabilityDayWeekly(dayInProgressStr);

            const day = new Date(weekStart);
            day.setDate(weekStart.getDate() + i);
            days.push(
                <div key={i} className="cell-calendar calendar-header-cell text-center" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                    {
                        todayStr < dayInProgressToCompareStr && dayInProgress.getDay() !== 0  && <span className={disponibilidad > 5 ? 'availability availability-success mx-2' : (disponibilidad === 0 ? 'availability availability-danger mx-2' : 'availability availability-warning mx-2' )}></span>
                    }
                    <strong className={todayStr === dayInProgressStr ? 'today' : ''}>{ ("0"+day.getDate()).slice(-2) }</strong> <small>{new Intl.DateTimeFormat('es-MX', { weekday: 'short' }).format(day)}</small>
                </div>
            );
        }
    
        return days;
        };


        const getAvailabilityWeekly = (day, hour) => {
            let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.horario.split(" ")[0] === hour)[0];

            if(rs !== undefined) {
                return rs.disponible;
            }else{
                return 0;
            }
        };
        
        const getAvailabilityDayWeekly = (day) => {
            let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.disponible === 1);
            if(rs !== undefined) {
                return rs.length;
            }else{
                return 0;
            }
        };

        const handleDetail = (day, hour) => {
            let rs = data.filter(item => item.fecha === day+"T00:00:00" && item.horario.split(" ")[0] === hour)[0];
            let date = {
                day,
                hour,
                calendarioId: rs.id
            }

            if(tipoCalendario === 1){
                dispatch(setHourGarantia(date));
            }else if(tipoCalendario === 2){
                dispatch(setHourGrua(date));
            }else if(tipoCalendario === 3){
                dispatch(setHourEntrega(date));
            }else if(tipoCalendario === 6){
                dispatch(setHourFirma(date));
            }else if(tipoCalendario === 7){
                dispatch(setHourEntregaExpediente(date));
            }else if(tipoCalendario === 8){
                dispatch(setHourCitaDatamovil(date));
            }else if(tipoCalendario === 9){
                dispatch(setHourCitaCambioPlacas(date));
            }else if(tipoCalendario === 10){
                dispatch(setHourCitaAltaPlacas(date));
            }else if(tipoCalendario === 11){
                dispatch(setHourCitaTarjetaCirculacion(date));
            }else if(tipoCalendario === 12){
                dispatch(setHourGestoriaEntrega(date));
            }
            setModalMessage({ ...modalMessage, isOpen: true, type: 5 });
            
        }

        // Función para construir los horarios del calendario semanal
        const generateScheduleRows = () => {
            const rows = [];
            let schedulesPerDay = [];
            let endTime = 22;
            

            if(data.length === 0){
                return false;
            }

            // Preparación de horarios en base a los horarios proporcionados por la configuración
            data.forEach(item => {
                let hour = item.horario.split(" ")[0];
                if(!schedulesPerDay.includes(hour)){
                    schedulesPerDay.push(hour);
                }
            });

            const last = schedulesPerDay[schedulesPerDay.length - 1].split(":");
            setMaxHour(Number(last[0]));
            const differnce = endTime - Number(last[0]);
            if(last[1] === "30"){
                for(let i = 1; i <= differnce; i++) {
                    if((Number(last[0])+i) < endTime){
                        schedulesPerDay.push((Number(last[0])+i)+":00");
                        schedulesPerDay.push((Number(last[0])+i)+":30");
                    }else{
                        schedulesPerDay.push((Number(last[0])+i)+":00");
                    }
                }
            }else{
                for(let i = 1; i <= differnce; i++) {
                    schedulesPerDay.push((Number(last[0])+i)+":00");
                }
            }
            // Preparación de horarios en base a los horarios proporcionados por la configuración

            
            const weekStart = new Date(currentDate);
            weekStart.setDate(currentDate.getDate() - currentDate.getDay());
            
            for (let hour = 0; hour <= schedulesPerDay.length-1; hour++) {
                const cells = [];
                for (let day = 0; day < daysInWeek; day++) {
                    const dayInProgress = new Date(weekStart.getFullYear(),(weekStart.getMonth()),weekStart.getDate() + day);
                    const todayStr = localeStringDate(today);
                    const dayInProgressStr = localeStringDate(dayInProgress);
                    const availability = getAvailabilityWeekly(dayInProgressStr, schedulesPerDay[hour]);
        
                    let cellDay;
                    
                    if(day === 0 && !sundayOn){
                        cellDay =  <div key={day} className="cell-calendar schedule-cell cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                            {/* You can add your schedule details here */}
                        </div>
                    }else if(day === 6 && Number(schedulesPerDay[hour].split(":")[0]) > halfDay){
                        cellDay =  <div key={day} className="cell-calendar schedule-cell cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                            {/* You can add your schedule details here */}
                        </div>
                    }else if(Number(schedulesPerDay[hour].split(":")[0]) > maxHour) {
                        cellDay = <div key={day} className="cell-calendar schedule-cell cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                            {/* You can add your schedule details here */}
                        </div>
                    }else if(todayStr > dayInProgressStr){
                        cellDay = <div key={day} className="cell-calendar schedule-cell cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                            {/* You can add your schedule details here */}
                        </div>
                    }else if(dayInProgressStr === todayStr && Number(schedulesPerDay[hour].split(":")[0]) < new Date().getHours()){
                        cellDay = <div key={day} className="cell-calendar schedule-cell cell-calendar-disabled" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}>
                            {/* You can add your schedule details here */}
                        </div>
                    }else if(availability !== 1){
                        cellDay = <div key={day} className={availability === 0 ? 'cell-calendar schedule-cell cell-calendar-disabled' : 'cell-calendar schedule-cell'} style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}} >
                            {/* You can add your schedule details here */}
                            {
                                availability === 2 && 
                                <span className='badge badge-secondary col-12 py-2'>Ocupado</span>
                            }
                        </div>
                    }else{
                        cellDay = <div key={day} className={availability === 1 ? "cell-calendar schedule-cell" : "cell-calendar schedule-cell cell-calendar-disabled"} style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px', cursor:'pointer'}} onClick={() =>  handleDetail(dayInProgressStr, schedulesPerDay[hour])}>
                        
                        </div>
                    }
        
                    cells.push(cellDay);
                }
        
                rows.push(
                <div key={hour} className='row'>
                    <div className="cell-calendar hour-cell text-center" style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}><small className='font-weight-bold'>{schedulesPerDay[hour]}</small></div>
                    {cells}
                </div>
                );
            }
            return rows;
        }

        const getTypeCalendar = (id) => {
            switch(Number(id)) {
                case 1: 
                    return 'Garantias';
                    break;
                case 2:
                case 5: 
                    return 'Grúas';
                    break;
                case 3: 
                case 4:
                    return 'Entregas';
                    break;
                case 6: 
                    return 'Cita de firma'
                    break;
                case 7:
                    return 'Cita de entrega de expediente'
                    break;
                case 9:
                    return 'Cita de trámite de cambio de placas'
                    break;
                case 10:
                    return 'Cita de trámite de alta de placas'
                    break;
                case 11:
                    return 'Cita de trámite de tarjeta de circulación'
                    break;
                case 12:
                    return 'Cita de entrega de gestoria'
                    break;
            }            
        }

  return (
    <Formik
        innerRef={refFormik}
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validate}
    >
        {({ isValid, values, setFieldValue, errors }) => (
            <>    
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <BasicModal
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 4 &&
                    <ScheduleModal
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    schedules={dataScheduleDay}
                    />
                }
                {
                    modalMessage.type === 5 &&
                    <DetailScheduleModal
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    schedules={dataScheduleDay}
                    />
                }
                </Modal>
                <section className='mb-4'>
                    <div className='d-flex flex-column'  style={{minHeight:'88vh', height: 'auto'}}>
                        {/* Titulo del calendario */}
                        <div className='row mx-md-4 my-4'>
                            <div className="col-12 px-md-3">
                            {/* <h4><strong>Calendario para agendar cita - {Number(id) === 1 ? 'Garantías' : 'Grúas'}</strong></h4> */}
                            <h4><strong>Calendario para agendar cita - {getTypeCalendar(id)}</strong></h4>
                            </div>
                        </div>
                        {/*  Header del calendario con botonera de acciones, llevar al día de hoy, cambiar de mes o semana, seleccionar la sucursal o el tipo de calendario (Mensual, Semanal) */}
                        <div className='row mx-md-4 my-4'>
                            <div className="col-12 col-lg-4 px-md-3">
                                <button className='btn btn-blue'>Hoy</button>
                            </div>
                            {/*  Botonera para cambiar entre meses o semanas */}
                            <div className="col-12 col-lg-4 px-md-3">
                                <div className="row">
                                    {/*  Boton para ir un mes o una semana atrás, dependiendo de la vista seleccionada */}
                                    {
                                        initialValue.vista === 1 && 
                                        <div className="col-1 px-md-2">
                                            <button className='btn btn-indicator' onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))}><i className="glyphicon fas fa-angle-left"></i></button>
                                        </div>
                                    }
                                    {
                                        initialValue.vista === 2 && 
                                        <div className="col-1 px-md-2">
                                            <button className='btn btn-indicator' onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - daysInWeek))}><i className="glyphicon fas fa-angle-left"></i></button>
                                        </div>
                                    }
                                    <div className="col-10 text-center px-md-2 py-1">
                                        <h4><strong className='text-capitalize'>{new Intl.DateTimeFormat('es-MX', { month: 'long' }).format(currentDate)+" "+currentDate.getFullYear()}</strong></h4>
                                    </div>
                                    {/*  Boton para ir un mes o una semana atrás, dependiendo de la vista seleccionada */}
                                    {
                                        initialValue.vista === 1 &&  
                                        <div className="col-1 px-md-2">
                                            <button className='btn btn-indicator' onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))}><i className="glyphicon fas fa-angle-right"></i></button>
                                        </div>
                                    }
                                    {
                                        initialValue.vista === 2 &&     
                                        <div className="col-1 px-md-2">
                                            <button className='btn btn-indicator' onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysInWeek))}><i className="glyphicon fas fa-angle-right"></i></button>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/*  Combos de selección Sucursal y Tipo de Calendario (Mensual, Semanal) */}
                            <div className="col-12 col-lg-4 px-md-3">
                                <div className="row justify-content-end">
                                    <div className="col-12 col-lg-7">
                                        <SelectField id="sucursalId" name="sucursalId" items={sucursales} onChange={(event) => {
                                            setFieldValue("sucursalId", event.value);
                                            handleSucursalId(event.value, values);
                                            // handleGetCalendar();
                                        }} />
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <SelectField id="vista" name="vista" items={vista} onChange={(event) => {
                                            setFieldValue("vista", event.value);
                                            handleGetWidth(event.value);
                                            handleVista(event.value, values);
                                            // handleGetCalendar();
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  Vista mensual */}
                        {
                            initialValue.vista === 1 && <div className='row mx-md-4 my-4 px-5' ref={parentDiv}>
                            {
                                Week.map((i, idx) => {
                                    return <div key={'dia_'+idx} className='text-center cell-calendar' style={{maxWidth:tamanoCasilla, minHeight:'40px', lineHeight:'40px'}}><small><strong>{i}</strong></small></div>
                                })
                            }
                            {generateCalendar()}
                            </div>
                        }
                        {/*  Vista semanal */}
                        {
                             initialValue.vista === 2 && <div className='row mx-md-4 my-4 px-4 mb-4' ref={parentDiv}>
                                {generateDaysOfWeek()}
                                {generateScheduleRows()}
                            </div>
                        }
                    </div>
                </section>
            </>
            )
            }
        </Formik>
  )
}

export default Calendar