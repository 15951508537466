import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import { SelectField } from '../../../../Components/SelectField';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { message } from '../../../../Utils/functions';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../Services/Catalogos/Catalogo';



const ModalCostosAdicionales = ({ item, setIsOpenModalAdeudo, isOpenModalAdeudo, handleGetAll }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const inicial = {
        seguroSeguimientoSiniestroAseguradoraId: item?.seguroSeguimientoSiniestroAseguradoraId ?? "",
        seguroSeguimientoSiniestroAseguradoraCostosId: item?.seguroSeguimientoSiniestroAseguradoraCostosId ?? "",
        cargoAdicionalId: item?.cargoAdicionalId ?? "",
        subTotal: item?.subTotal ?? "",
        iva: item?.iva ?? "",
        total: item?.total ?? ""
    };

    const [initialValue, setInitialValue] = useState(inicial);

    const [dataSelect, setDataSelect] = useState([]);

    useEffect(() => {
        getAllCargoAdicional();
    }, [])
    const getAllCargoAdicional = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CargoAdicional')
            .then(resp => {
                let tmpList = [];
                resp.data.forEach(item => {
                    tmpList.push({ value: item.CargoAdicionalId, label: item.CargoAdicional });
                });
                setDataSelect(tmpList)
            })
            .catch(err => {
                console.error(err);
            })
    }
    const validate = Yup.object().shape({
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        subTotal: Yup.number().required("El campo es requerido.")
            .positive("ingrese un valor mayor a cero."),
        cargoAdicionalId: Yup.string().required("El campo es requerido.")
    });



    const handlerGuardar = (values) => {
        if (values.seguroSeguimientoSiniestroAseguradoraCostosId === "") {
            setLoading(true);
            Siniestros.AddCostosAseguradora(values)
                .then(resp => {
                    let { ban, mensaje } = resp.data.data;
                    if (ban === 1) {
                        setLoading(false);
                        toast.success(message("Correcto!", mensaje));
                        setIsOpenModalAdeudo(false);
                        handleGetAll(1)
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error!", mensaje));
                    }
                }).catch(error => {
                    setLoading(false);
                    toast.error(message("¡Operación fallida!", "Error al agregar un adeudo"));
                    console.log(error)
                })
        } else {
            setLoading(true);
            Siniestros.UpdCostosAseguradora(values)
                .then(resp => {
                    let { ban, mensaje } = resp.data.data;
                    if (ban === 1) {
                        setLoading(false);
                        toast.success(message("Correcto!", mensaje));
                        setIsOpenModalAdeudo(false);
                        handleGetAll(1)
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error!", mensaje));
                    }
                }).catch(error => {
                    setLoading(false);
                    toast.error(message("¡Operación fallida!", "Error al actualizado un adeudo"));
                    console.log(error)
                })
        }

    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handlerGuardar(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpenModalAdeudo} color='#fff'>
                            <Form>

                                <section className='d-flex justify-content-end'>
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => setIsOpenModalAdeudo(false)}><i className="ri-close-fill"></i></button>
                                </section>
                                <div className='justify-content-start p-2 align-items-center'>
                                    <div className="row">
                                        <div className="col col-12">
                                            <h5><strong>Costos Adicionales</strong></h5>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    A continuación completa todos los campos para el registro solicitado
                                                </small>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-lg-6 col-12">
                                                    {/* <TextField
                                                        label="Tipo de gasto"
                                                        name="concepto"
                                                        type="text"
                                                        holder="Escribe" /> */}
                                                    <SelectField
                                                        className="col-12"
                                                        label="Tipo:"
                                                        name={`cargoAdicionalId`}
                                                        items={dataSelect}
                                                        onChange={(event) => {
                                                            setFieldValue(`cargoAdicionalId`, event.value);
                                                        }} />
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <TextField
                                                        icon={"bi bi-currency-dollar"}
                                                        isMoney={true}
                                                        label="Subtotal"
                                                        name="subTotal"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("subTotal", event.target.value);
                                                            let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                            setFieldValue("total", total);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-lg-6 col-12">
                                                    <TextField
                                                        id="iva"
                                                        label="I.V.A."
                                                        name="iva"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("iva", event.target.value);
                                                            let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                                            setFieldValue("total", total);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <TextField
                                                        icon={"bi bi-currency-dollar"}
                                                        isMoney={true}
                                                        disabled={true}
                                                        label="Total"
                                                        name="total"
                                                        type="text"
                                                        holder="Escribe" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setIsOpenModalAdeudo(false)
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    disabled={!(isValid)}
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    type="submit"
                                                >
                                                    Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>

                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default ModalCostosAdicionales;
