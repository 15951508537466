import axios from '../../Utils/ApiService';

const IntelimotorAPI = {
    getSyncHistory:(page,rows)=> {        
        return axios.get(`/catalogs/getAllSyncHistory?page=${page}&rows=${rows}`);
    },   
    getSyncHistoryById:(Id,page,rows)=> {        
        return axios.get(`/catalogs/getByIdSyncHistory?id=${Id}&page=${page}&rows=${rows}`);
    },    
    downloadCatalog:()=> {     
        return axios.get(`/config/getIntelimotor`);
    },       
    getModifyList:(page,rows,id,string)=> {     
        return axios.get(`/catalogs/TblModificadas?page=${page}&rows=${rows}&sid=${id}&txtf=${string}`);       
    },     
    getParameterIntelimotor:async (section)=> {     
        return await axios.get(`/functions/Parametros?seccion=${section}`);       
    }, 
    updateParameterIntelimotor:(value)=> {     
        return axios.put(`/functions/DescargaCatIntelimotor?value=${value}`);       
    }, 
    getModifyTA:(page,rows,id,string)=> {     
        return axios.get(`/catalogs/TblModificadasTA?page=${page}&rows=${rows}&sid=${id}&txtf=${string}`);       
    }, 
    getValorParametro:async(param)=>{
        return await axios.get(`/functions/GetValorParametro?Parametro=${param}`);
    }
}

export default IntelimotorAPI