import React, { useEffect, useState } from 'react'
import DataTable from '../../Components/datatable/DataTable'
import Loadig from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import Select from 'react-select';
import SubmenuSync from './SubmenuSync';
import useSyncInventario from '../../Hooks/SyncInventario/useSynInvetario';
import useSyncInventarioDataWare from '../../Hooks/SyncInventario/useSyncInvDataWare';
import { pageRows } from '../../Utils/functions';
import RowPages from '../../Components/RowPages';
import { Section, SubModule } from '../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';

const InvDatamovil = () => {

    const { columns,
        data,
        pageCount,
        loading,
        handlePageClick,
        handleDetail,
        handleClick,
        isOpenEdit,
        closeModalEdit,
        message,
        loadingReq,
        modalMessage,
        setModalMessage,
        handleUpdateParameters,
        handleChangeValueParam,
        valueIntelimotor1,
        filtro,
        setTxt,
        setFecha,
        DataMarca,
        DataModelo,
        handleChangeMarca,
        setClaveModelo,
        handleCheck,
        refBtnExp,
        setResultado,
        setpageRows
    } = useSyncInventarioDataWare();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Loadig message={"Sincronizando..."} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <Modal isOpen={loadingReq} color='transparent'><Loadig message={message} /></Modal>
            <SubmenuSync />
            <section className='mx-4 my-4 d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                    <span className='fs-5'><strong>Inventario DataWare</strong></span>
                    <span className='mt-2 fs-6'>A continuación se muestra el listado de vehículos del dataware que no pudieron sincronizarse a Intelimotor.</span>
                </div>
                <ValidatePermission isActive={ValidatePermissionById(4, Section.DataWare, SubModule.ConfiguracionDeInventario).Ver}>
                    <div>
                        <button className='btn' ref={refBtnExp} onClick={handleClick}>Sincronizar</button>
                    </div>
                </ValidatePermission>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.DataWare, SubModule.ConfiguracionDeInventario).Ver}>
                <section className='mx-4 my-5'>
                    <div className='row co-12'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-2" placeholder="Buscar" onKeyUp={(e) => { setTxt(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <input type="date" className="form-control mb-2" placeholder="Buscar" onChange={(e) => { setFecha(e.target.value) }} />
                        </div>
                        {/* <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <Select options={filtro} className='col-12 mb-2' placeholder="Resultado" onChange={(e)=>{setResultado(e.value)}}/>
                        </div> */}
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <Select options={DataMarca} className='col-12 mb-2' placeholder="Marca" onChange={(e) => { handleChangeMarca(e.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <Select options={DataModelo} className='col-12 mb-2' placeholder="Modelo" onChange={(e) => { setClaveModelo(e.value) }} />
                        </div>
                    </div>

                </section>

                <div className='d-flex justify-content-between align-items-center me-4'>
                    <div className='mx-4 title fs-5'>
                        Historial de sincronizaciones
                    </div>
                    <div className='mx-1 col-lg-4 col-md-6 col-sm-12'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="me-1" style={{ fontSize: '12px' }}>Resultado por pagina</span>
                            <Select options={pageRows()} className='col-2' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} />
                        </div>

                    </div>
                </div>
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        checkbox={true}
                        handleCheck={handleCheck}
                    />
                </div>
            </ValidatePermission>

            <Modal isOpen={isOpenEdit} closeModal={closeModalEdit} color='#fff'>
                <form className='container-buttons'>
                    <h4>Programar Sincronización</h4>
                    <span className='' style={{ fontSize: '14px' }}>A continuación se puede modificar el horario en el que se realiza la sincronización automática todos los días.</span>
                    <div className="mt-5">
                        <h6 className="form-label">En los siguientes horarios</h6>
                        <div className='col-12'>
                            <input className='form-control mb-4' label="Hora" name="hora" value={valueIntelimotor1} type="time" step="1" onChange={(e) => { handleChangeValueParam(e.target.value) }} />
                        </div>
                        <span style={{ fontSize: '12px' }}>Los cambios realizados en el horario afectarán a la sincronización de catálogos a Intelimotor.</span>
                    </div>
                    <div className='d-flex justify-content-around mt-5'>
                        <button className="btn btn-outline col-5" type="button" onClick={closeModalEdit}>Cancelar</button>
                        <button className="btn col-5" type="button" onClick={handleUpdateParameters}>Aceptar</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default InvDatamovil