import axios from '../../Utils/ApiService';

const ComisionesService = {
    getAllPendientesDePagoComision: async (page, rows, string, usoUnidadId, tipoCompraId, AseguradoraId,fechaInicio,FechaFin) => {
        return await axios.get(`/functions/GetAllPendientesPagoComision?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&Tipo=${tipoCompraId}&AseguradoraId=${AseguradoraId}&FechaInicio=${fechaInicio}&FechaFin=${FechaFin}&Buscar=${string}`);
    },  
    ExportPendientesDePagoComision: async (data) => {
        return await axios.get(`/functions/ExportarPendientesPagoComisiones?Ids=${data.ids}&Folio=${data.folio}&TotalVehiculos=${data.totalVehiculos}&TotalAPagar=${data.totalAPagar}&AseguradoraId=${data.aseguradoraId}&Aseguradora=${data.aseguradora}`, { responseType: 'blob' });
    },
    getAllProcesoPagoComision: async (page,rows,UsoUnidadId,TipoCompraId,AseguradoraId,FechaInicio,FechaFin,str) => {
        return await axios.get(`/functions/GetAllProcesoPagoComision?page=${page}&Rows=${rows}&UsoUnidadId=${UsoUnidadId}&TipoCompraId=${TipoCompraId}&AseguradoraId=${AseguradoraId}&FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&Buscar=${str}`);
    },
    SaveProcesoPagoComision:async(data)=>{
        return axios.post('/functions/SaveProcesoDePagoComision',data);
    },
    getAllPolizasVigentesComision: async (page, rows, string, usoUnidadId, condicion, AseguradoraId,tipo,estatus) => {
        return await axios.get(`/functions/GetAllPolizasVigentesComision?page=${page}&Rows=${rows}&UsoUnidadId=${usoUnidadId}&Tipo=${tipo}&AseguradoraId=${AseguradoraId}&CondicionPoliza=${condicion}&Buscar=${string}&Estatus=${estatus}`);
    }, 
    ExportPolizasVigentesComision: async (string, usoUnidadId, condicion, AseguradoraId,tipo,estatus) => {
        return await axios.get(`/functions/ExportPolizasVigentesComision?UsoUnidadId=${usoUnidadId}&Tipo=${tipo}&AseguradoraId=${AseguradoraId}&CondicionPoliza=${condicion}&Buscar=${string}&Estatus=${estatus}`,{ responseType: 'blob' });
    }, 
    ReExportPendientesDePagoComision: async (folio) => {
        return await axios.get(`/functions/ReExportarPendientesPagoComisiones?Folio=${folio}`, { responseType: 'blob' });
    },
}

export default ComisionesService