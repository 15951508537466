import axios from '../../Utils/ApiService';

const CambioEtapaInventarioService = {
    getAllListaEtapas:(generalId,etapaId,productoId)=> {        
        return axios.get(`/functions/GetCEListaEtapas?GeneralId=${generalId}&EtapaActualId=${etapaId}&ProductoId=${productoId}`);
    },   
    getAllHistory:(generalId,page,rows)=>{
        return axios.get(`/functions/GetCEHistorialEtapas?GeneralId=${generalId}&Page=${page}&Rows=${rows}`);
    },
    getAllMotivoCambio:()=>{
        return axios.get(`/catalogs/GetCSMotivoCambio`);
    },
    AddCambioEtapaInventario:(data)=>{
        return axios.post(`/functions/AddCambioEtapas`,data);
    },
    getAllHistoryCambios:(generalId,page,rows)=>{
        return axios.get(`/functions/GetCELogHistorialEtapas?GeneralId=${generalId}&Page=${page}&Rows=${rows}`);
    },
    //Costos
    getAllCostosById:(generalId)=> {        
        return axios.get(`/functions/GetAllCostosGenerales?GeneralId=${generalId}`);
    },   
    SavecostosGenerales : (data) =>{
        return axios.post(`/functions/AddCostoGeneral`,data);
    },
    DeleteCostosGeneral : (data) =>{
        return axios.put(`/functions/DelCostoGeneral`,data);
    },
    //cambioProducto
    getAllcambioProducto:(page,rows,id,str)=> {        
        return axios.get(`/functions/GetAllVehiculosCambioProducto?page=${page}&Rows=${rows}&GeneralId=${id}&Buscar=${str}`);
    }, 
    AddCambioProducto:(data)=>{
        return axios.post(`/functions/SaveCambioProducto`,data);
    },
}
export default CambioEtapaInventarioService;