import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import SubHeaderGestoria from './SubHeaderGestoria';
import { FormatNumberMoney } from '../../../Utils/functions';
import GestoriaServices from '../../../Services/Inventario/GestoriaServices'
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { set } from 'date-fns';


const GestoriaCanceladaCaptura = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const refbtn = useRef();
    const refbtnDownload = useRef();
    const refFormik = useRef();
    let { id } = useParams();

    let informacionGeneal = {
        vin: state?.vin,
        sol_id: state?.sol_id,
        id_datamovil: state?.id_datamovil,
        status_datamovil: state?.status_datamovil,
        contrato: state?.contrato,
        nombreMarca: state?.nombreMarca,
        nombreModelo: state?.nombreModelo,
        nombreYear: state?.nombreYear,
        nombreVersion: state?.nombreVersion,
        desembolso_inicial: state?.desembolso_inicial
    }

    //Valores iniciales para el formulario
    let initial = {
        generalId: id,
        motivoCancelacionId: "",
        comentarios: "",
        cambioPropietario: "",
        aplicaDescuento: "",
        costoCambioPropietario: "",
        clientePagoCambioPropietario: "",
        costoDescuento: "",
    }

    let cambioPropietario = [
        { value: "", label: "Seleccione" },
        { value: "si", label: "Si" },
        { value: "no", label: "No" },
    ]

    let aplicaDescuento = [
        { value: "", label: "Seleccione" },
        { value: "si", label: "Si" },
        { value: "no", label: "No" },
    ]

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [dataMotivosCancelacion, setDataMotivosCancelacion] = useState([]);
    const [idPaso, setIdPaso] = useState(1);
    const [soloLectura, setSoloLectura] = useState(false);

    useEffect(() => {
        handleGetMotivosCancelacion();
        if (state?.estatus == "Capturar información") {
            setIdPaso(1);
        } else if (state?.estatus == "En espera acendes") {
            setIdPaso(2);
            setSoloLectura(true);
            if (state.esCancelacionGestoria === true)
                handleGetCanceacionById(); // cambiar por el nuevo endpoint
            else
                handleGetById();
        } else if (state?.estatus == "Carga de evidencia") {
            setIdPaso(3);
            setSoloLectura(true);
        }
    }, []);

    const validate = Yup.object({
        motivoCancelacionId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
        cambioPropietario: Yup.string().required("El campo es requerido."),
        clientePagoCambioPropietario: Yup.string()
            .when('cambioPropietario', {
                is: "si",
                then: Yup.string().required("El campo es requerido."),
            }),
        costoCambioPropietario: Yup.string()
            .when('cambioPropietario', {
                is: "si",
                then: Yup.string().required("El campo es requerido."),
            }),
        aplicaDescuento: Yup.string().when('cambioPropietario', {
            is: "si",
            then: Yup.string().required("El campo es requerido."),
        }),
        costoDescuento: Yup.string()
            .when(["aplicaDescuento", "costoCambioPropietario"], (aplicaDescuento, costoCambioPropietario) => {
                if (aplicaDescuento === "si") {
                    return Yup.string().test({
                        name: 'is-sku',
                        skipAbsent: true,
                        test(value, ctx) {

                            if (value === undefined)
                                return ctx.createError({ message: 'El campo es requerido' })
                            if (costoCambioPropietario === undefined)
                                return true
                            if (Number(value.replace(/[$,]/g, "")) > Number(costoCambioPropietario?.replace(/[$,]/g, ""))) {
                                return ctx.createError({ message: 'EL valor debe ser menor o igual al Costo cambio de propietario' })
                            }
                            return true
                        }
                    })
                }
            }),
    });

    const handleAddEdit = async (values) => {
        setLoading(true);
        let payload = {
            generalId: id,
            motivoCancelacionGestoriaId: values.motivoCancelacionId,
            motivo: dataMotivosCancelacion.find(item => item.value == values.motivoCancelacionId)?.label,
            comentarios: values.comentarios,
            vin: state?.vin,
            sol_id: state?.sol_id,
            id_datamovil: state?.id_datamovil,
            status_datamovil: state?.status_datamovil,
            contrato: state?.contrato,
            nombreMarca: state?.nombreMarca,
            nombreModelo: state?.nombreModelo,
            nombreYear: state?.nombreYear,
            nombreVersion: state?.nombreVersion,
            cambioPropietario: values.cambioPropietario == "si" ? true : false,
            aplicaDescuento: values.aplicaDescuento == "si" ? true : false,
            costoCambioPropietario: Number(values.costoCambioPropietario.replace(/[$,]/g, "")),
            clientePagoCambioPropietario: values?.clientePagoCambioPropietario == "si" ? true : false,
            montoPorDescuentoCliente: Number(values.costoDescuento.replace(/[$,]/g, "")),
            desembolso_inicial: state?.desembolso_inicial,
            total_devolver: handleTotalDevolver(values), //state?.desembolso_inicial == 0 ? 0 : state?.desembolso_inicial - handleTotalDescontar(values),//Number(values.costoDescuento.replace(/[$,]/g, "")),            
        }                 
        await GestoriaServices.SaveGestoriaAcendes(payload)
            .then(result => {
                if (result.data?.data?.ban == 1) {
                    toast.success(result.data?.data?.mensaje);
                    navigate('/inventario/gestoria/vehiculoengestoria');
                } else {
                    toast.error(result.data.message);
                }
            }).catch(err => {
                toast.error(err.response.data.data.mensaje);
            }).finally(x => { setLoading(false) });
    }
    const handleTotalDevolver = (item) => {
        // if (!state?.moral && !state?.esforaneo)
        //     return (state?.montoEnganche + (Number(item.costoCambioPropietario.replace(/[$,]/g, "")) - Number(item.costoDescuento.replace(/[$,]/g, ""))))
        // else
        //     return (state?.montoEnganche - Number(item.costoDescuento.replace(/[$,]/g, "")))
        
        if(item.cambioPropietario === 'no'){ // caso 1
            return state?.montoEnganche;
        }else if(item.cambioPropietario === 'si' && item.clientePagoCambioPropietario === 'no' && item.aplicaDescuento === 'no'){ // caso 2
            return state?.montoEnganche;
        }else if(item.cambioPropietario === 'si' && item.clientePagoCambioPropietario === 'si' && item.aplicaDescuento === 'si'){ // caso 3            
            return (state?.montoEnganche + (Number(item.costoCambioPropietario.replace(/[$,]/g, "")) - Number(item.costoDescuento.replace(/[$,]/g, "")))).toFixed(2)
        }else if(item.cambioPropietario === 'si' && item.clientePagoCambioPropietario === 'no' && item.aplicaDescuento === 'si'){ //caso 4
            return (state?.montoEnganche - Number(item.costoDescuento.replace(/[$,]/g, ""))).toFixed(2);
        }else if(item.cambioPropietario === 'si' && item.clientePagoCambioPropietario === 'si' && item.aplicaDescuento === 'no'){ //caso 5            
            return (state?.montoEnganche + Number(item.costoCambioPropietario.replace(/[$,]/g, ""))).toFixed(2)
        }
    }
    const handleGetMotivosCancelacion = async () => {
        await Catalogo.getAllCSMotivoCancelacionGestoria().then(result => {
            console.log(result.data);
            let NewData = [];
            NewData.push({
                value: "",
                label: "Seleccione"
            })
            result.data.data?.map(item => {
                NewData.push({
                    value: item.motivoCancelacionGestoriaId,
                    label: item.motivo
                })
            });
            setDataMotivosCancelacion(NewData);
        }).catch(err => { console.log(err); });
    }
    const handleGetById = async () => {
        setLoading(true);
        await GestoriaServices.GetByIdGestoria(id)
            .then(res => {
                let item = res.data.data;
                let initial = {
                    generalId: id,
                    motivoCancelacionId: item?.motivoCancelacionGestoriaId ?? '',
                    comentarios: item?.comentarios ?? '',
                    cambioPropietario: item?.cambioPropietario ? 'si' : 'no',
                    aplicaDescuento: item?.aplicaDescuento ? 'si' : 'no',
                    costoCambioPropietario: item?.costoCambioPropietario ?? '',
                    clientePagoCambioPropietario: item?.clientePagoCambioPropietario ? "si" : "no",
                    costoDescuento: item?.montoPorDescontarCliente ?? "",
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(err => {
                toast.error(err.response.data.data.mensaje);
                setLoading(false);
            });
    }
    const handleGetCanceacionById = async () => {
        setLoading(true);
        await GestoriaServices.GetByIdGestoriaCancelacion(id)
            .then(res => {
                let item = res.data.data;
                let initial = {
                    generalId: id,
                    motivoCancelacionId: item?.motivoCancelacionGestoriaId ?? '',
                    comentarios: item?.comentarios ?? '',
                    cambioPropietario: item?.cambioPropietario ? 'si' : 'no',
                    aplicaDescuento: item?.aplicaDescuento ? 'si' : 'no',
                    costoCambioPropietario: item?.costoCambioPropietario ?? '',
                    clientePagoCambioPropietario: item?.clientePagoCambioPropietario ? "si" : "no",
                    costoDescuento: item?.montoPorDescontarCliente ?? "",
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(err => {
                toast.error(err.response.data.data.mensaje);
                setLoading(false);
            });
    }
    

    const handleChangeStatus = async () => {
        setLoading(true)
        await GestoriaServices.cambioStatusCargaEvidencia({ generalId: id })
            .then(res => {
                navigate(`/inventario/vehiculo/datadocs/${id}`, {
                    state: { ...state, gestoria: 0 },
                });
                setLoading(false);
            }).catch(err => {
                toast.error(err.response.data.data.mensaje);
                setLoading(false);
            });
    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ height: '50px' }}>
                        <div style={{ width: '100%' }}>
                            <div className="row" style={{}}>
                                <div className="col-auto col-md-2 col-lg-3" style={{ fontSize: '10pt', padding: '0px 0px' }}>
                                    <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate('/inventario/gestoria/vehiculoengestoria') }}></i>
                                    Regresar a listado general
                                    {/* <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span> */}
                                </div>
                                <div className="col-auto col-md-2 col-lg-3" style={{ fontSize: '10pt', padding: '0px 0px' }}>
                                    <span style={{ marginRight: '10px', fontWeight: 'bold' }}> Vehiculo: </span>
                                    <span style={{ marginRight: '20px' }}>{state?.vin} - {state?.nombreMarca} {state?.nombreModelo}</span>
                                </div>
                                <div className="col-auto col-md-8 col-lg-6" style={{ fontSize: '10pt', padding: '0px 0px' }}>
                                    <SubHeaderGestoria idPaso={idPaso} />
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className='row m-0 py-2' style={{ height: '70vh' }}>
                        <section className='col-12'>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Gestoría cancelada</strong></span>
                                        </div>
                                        {
                                            soloLectura == false &&
                                            <div className='d-flex'>
                                                <button ref={refbtn} className='btn mx-2' type="submit" onClick={() => { console.log(errors) }} >{"Guardar"}</button>
                                            </div>
                                        }
                                        {
                                            soloLectura == true &&
                                            <div className='d-flex'>
                                                <div className='d-flex'>
                                                    <button className='btn mx-2' style={{ background: '#F5F5F5', color: 'Black' }} disabled ><i className="glyphicon fa fa-eye" style={{ color: 'black' }}></i> {"Modo lectura"}</button>
                                                </div>
                                                <div className='d-flex'>
                                                    <fieldset className='btn mx-2' onClick={() => { handleChangeStatus() }} >{"Continuar"}</fieldset>
                                                </div>
                                            </div>
                                        }
                                    </section>
                                    <span className='mt-2'>A continuación debes capturar los siguientes datos de la gestoría: </span>
                                    <div className='my-2 mt-4'>
                                        <p><strong>1. Detalle de cancelación de gestoría</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={soloLectura} label="Motivo de cancelación" name="motivoCancelacionId" items={dataMotivosCancelacion} onChange={(event) => {
                                                    setFieldValue("motivoCancelacionId", event.value);
                                                }} placeholder="Seleccione" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <TextAreaField disabled={soloLectura} label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={soloLectura} label="Cambio de propietario" name="cambioPropietario" items={cambioPropietario} onChange={(event) => {
                                                    setFieldValue("cambioPropietario", event.value);
                                                    setFieldValue("aplicaDescuento", "");
                                                    setFieldValue("costoCambioPropietario", "");
                                                }} placeholder="Seleccione" />
                                            </div>
                                            {
                                                values.cambioPropietario == "si" &&
                                                <>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled={soloLectura} icon={"bi bi-currency-dollar"} label="Costo cambio de propietario ($)" name="costoCambioPropietario" type="text" holder="Escribe" onKeyUp={(event) => {
                                                            setFieldValue("costoCambioPropietario", FormatNumberMoney(event.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={soloLectura || state.entrega === "Cancelada"}
                                                            label="¿Cliente pago cambio propietario?" name="clientePagoCambioPropietario" items={cambioPropietario} onChange={(event) => {
                                                                setFieldValue(
                                                                    "clientePagoCambioPropietario",
                                                                    event.value
                                                                );
                                                            }}
                                                            placeholder="Seleccione"
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={soloLectura} label="Aplica descuento" name="aplicaDescuento" items={aplicaDescuento} onChange={(event) => {
                                                            setFieldValue("aplicaDescuento", event.value);
                                                            setFieldValue("costoDescuento", "");
                                                        }} placeholder="Seleccione" />
                                                    </div>
                                                    {
                                                        values.aplicaDescuento == "si" &&
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <TextField disabled={soloLectura} icon={"bi bi-currency-dollar"} label="Monto por descuento a cliente ($)" name="costoDescuento" type="text" holder="Escribe" onKeyUp={(event) => {
                                                                setFieldValue("costoDescuento", FormatNumberMoney(event.target.value));
                                                            }} />
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    </div>
                </>
            )}
        </Formik>
    )
}

export default GestoriaCanceladaCaptura