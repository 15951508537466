import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useRevisionUnidadRechazada from '../../../Hooks/Inventario/useRevisionUnidadRechazada'
import DeleteModalConfirm from './DeleteModalConfirm'

const RevisionUnidadRechazada = () => {
    const { columns,
        data,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        dataTipoAdquisicion,
        setpageRows,
        setClaveSocioComercial,
        razonSocial,
        selectRef,
        handleGetCSRazonSocial,
        setOpenModalDelete,
        openModalDelete,
        setItemSelected,
        itemSelected,
        habdleGetAll,
        handleDownloadExcel,
        refBtn
    } = useRevisionUnidadRechazada(toast);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos rechazados revisión de unidad</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.RevisionUnidadRechazadaSection, SubModule.RevisionUnidadRechazada).Descarga}>
                        <div>
                            <button className='btn px-4' ref={refBtn} onClick={handleDownloadExcel}>Exportar</button>
                        </div>
                    </ValidatePermission>
                </div>
                {/* <span className='mt-2'>El siguiente listado corresponde a los vehículos registrados y su etapa actual.</span> */}
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.RevisionUnidadRechazadaSection, SubModule.RevisionUnidadRechazada).Ver}>
                <section className='mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-3 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select options={dataTipoAdquisicion} className='col-12' onChange={(e) => { setTipoAd(e.value); handleGetCSRazonSocial(e.value); }} placeholder="Tipo Compra" />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select ref={selectRef} options={razonSocial} className='col-12' onChange={(e) => { setClaveSocioComercial(e.value) }} placeholder="Razon social" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />

                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick} z
                        currentPage={currenPage - 1}
                        deleteable={true}
                        handleDelete={(item) => { setItemSelected(item);setOpenModalDelete(true);}}
                    />
                </div>
            </ValidatePermission>
            {
                openModalDelete &&
                <DeleteModalConfirm
                    isOpen={openModalDelete}
                    setIsOpen={setOpenModalDelete}
                    item={itemSelected}
                    toast={toast}
                    handleGetAll={habdleGetAll}
                />
            }
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }

        </>
    )
}

export default RevisionUnidadRechazada