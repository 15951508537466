import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';
import pruebaManejoService from '../../../Services/Venta/Vehiculo/pruebaManejoService';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';
import { AddDays_CurrentDate, handleUnchekedColumns, message } from '../../../Utils/functions';
import CancelacionSeguroService from '../../../Services/Seguros/CancelacionSeguroService';
import Spinner from '../../../Components/Loadig';

const CancelarSeguroModal = ({ isOpen, setIsOpen, handleGetAll, toast, item, aseguradora }) => {
    const refbtn = useRef();

    let initial = {
        motivo: "",
        comentarios: "",
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [dataMotivo, setdataMotivo] = useState([]);
    const validate = Yup.object({
        // diasMantenerPruebaManejo: Yup.string()
        //     .required("El campo es requerido."),
        motivo: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+(?: [a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
    });

    useEffect(() => {
        handleGetAllMotivoCancelacionSeguro();
    }, [])



    const handleGetAllMotivoCancelacionSeguro = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoCancelacionSeguro')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Seleccione"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.MotivoCancelacionSeguroId,
                        label: item.MotivoCancelacionSeguro
                    })
                });
                setdataMotivo(NewData);
            }).catch(e => {
            })
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        setLoading(true)
        await CancelacionSeguroService.DeleteExportSinPago(item.join(), value.motivo, value.comentarios, aseguradora)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `SeguroSinPago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                handleUnchekedColumns();
                setLoading(false);
                setIsOpen(false);
                handleGetAll(1);
                toast.success(message("¡Correcto.!", "Registros procesados correctamente."));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));

            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Cancelar Seguro</strong></span>
                            <span style={{ fontSize: '13px' }}>A continuación debes capturar el detalle de la cancelación.</span>
                        </div>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <SelectField label="Motivo cancelación" name="motivo" items={dataMotivo} onChange={(event) => {
                                            setFieldValue("motivo", event.value);

                                        }} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default CancelarSeguroModal