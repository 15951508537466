import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../Components/datatable/DataTable';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import GestoriaAdeudosModal from '../../../Components/Modal/GestoriaAdeudosModal';
import GestoriaPagosModal from '../../../Components/Modal/GestoriaPagosModal';
import { setBalance, clearBalance } from '../../../Redux/Slice/Gestoria/balanceGestoriaSlice';
import Garantias from '../../../Services/Cartera/Garantias';
import documentsHelper from '../../../Utils/documents';

const PagoTramitesGestoriaCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { vin, id } = useParams();
        
        const { 
            anombre, 
            marca, 
            modelo, 
            version, 
            anio, 
            placas, 
            color, 
            kilometraje, 
            procedencia, 
            numMotor, 
            fechaEntrega, 
            numContrato,
            cliente, 
            telefono, 
            correo, 
            producto, 
            usoUnidad, 
            fechaUltimoPago, 
            fechaLiquidacion 
        } = useSelector((state) => state.GestoriaEncabezadoSlice);

        let initial = {
            gestoriaPagoTramitesId: "",
            gestoriaPagoTramitesStatusId: 0,
            gestoriaPagoTramitesStatus: "Pendiente",
            color: "default",
            gestoriaRegistroInicialId: "",
            concluido: false,
            usuarioAlta: "",
            usuarioAltaNombre: "",
            usuarioActualiza: "",
            usuarioActualizaNombre: "",
            fechaAlta: "",
            fechaActualiza: "",
            fechaConclusion: "",
            entregaConcluido: null,
            adeudos: [],
            pagos: []
        }

        const { EntregaConcluida } = useSelector((state) => state.GestoriaEntregaSlice);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [disabled, setDisabled] = useState(false);
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        
        const metodoPago = { 
            1: "Efectivo",
            2: "Depósito",
            3: "Transferencia" 
        };
        
        const columnsAdeudos = [
            { field: 'orden', headerName: 'No. Orden'},
            { field: 'usuarioAltaNombre', headerName: 'Ejecutivo'},
            { field: 'adeudoTipo', headerName: 'Adeudo'},
            { field: 'fechaAdeudo', headerName: 'Fecha de adeudo'},
            { field: 'subtotal', headerName: 'Subtotal ($)'},
            { field: 'ivaCalculado', headerName: 'IVA ($)'},
            { field: 'total', headerName: 'Total ($)'}
        ];
        
        const columnsPagos = [
            { field: 'folioId', headerName: 'Folio de pago'},
            { field: 'fechaAlta', headerName: 'Fecha y hora'},
            { field: 'usuarioAltaNombre', headerName: 'Ejecutivo'},
            { field: 'notas', headerName: 'Notas'},
            { field: 'metodoPago', headerName: 'Método de pago'},
            { field: 'monto', headerName: 'Monto ($)'}
        ];
        const data = {}
        const [adeudos, setAdeudos] = useState([]);
        const [pagos, setPagos] = useState([]);
        const [totalAdeudos, setTotalAdeudos] = useState(0);
        const [totalPagos, setTotalPagos] = useState(0);

        useEffect(() => {
            getTramite();
            dispatch(clearBalance());
        }, []);
        
        useEffect(() => {
            getTramite();
        }, [EntregaConcluida]);
        
        /* CONSULTAS */
        const getTramite = async () => {
            await Gestoria.PagoTramitesGet(id)
            .then(resp => {
                setInitialValue(resp.data);
                let adeudosList = [];
                let adeudosCalculados = 0;
                let pagosList = [];
                let pagosCalculados = 0;
                resp.data.adeudos.forEach((adeudo, idx) => {
                    let iva = (adeudo.subtotal * (adeudo.iva/100))
                    let total = adeudo.subtotal + iva;
                    adeudosList.push({
                        orden: (idx+1),
                        usuarioAltaNombre: adeudo.usuarioAltaNombre,
                        adeudoTipo: adeudo.adeudoTipo,
                        fechaAdeudo: getDateFormat(adeudo.fechaAdeudo, ''),
                        subtotal: formatMoney(adeudo.subtotal),
                        ivaCalculado: formatMoney(iva),
                        total: formatMoney(total)
                    });

                    adeudosCalculados += total;
                });

                resp.data.pagos.forEach(pago => {
                    console.log(pago);
                    pagosList.push({
                        folioId: pago.folioId,
                        fechaAlta: getDateFormat(pago.fechaAlta, ''),
                        usuarioAltaNombre: pago.usuarioAltaNombre,
                        notas: pago.notas,
                        metodoPago: metodoPago[pago.metodoPagoId],
                        descargable: pago.metodoPagoId === 1 ? 1 : 0,
                        monto: formatMoney(pago.monto)
                    });

                    pagosCalculados += pago.monto;
                });

                setAdeudos(adeudosList);
                setPagos(pagosList);
                setTotalAdeudos(adeudosCalculados);
                setTotalPagos(pagosCalculados);

                let params = {
                    adeudos: adeudosCalculados,
                    pagos: pagosCalculados,
                    pendiente: (adeudosCalculados - pagosCalculados)
                }

                dispatch(setBalance(params));
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */
        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }
        const submitForm = async (values) => {
            if(values.concluido){
                values.gestoriaPagoTramitesStatusId = 30;
            }
            setLoading(true);
            await Gestoria.PagoTramitesActualizar(values)
            .then(resp => {
                console.log(resp.data);
                setInitialValue(resp.data);
                let adeudosList = [];
                let adeudosCalculados = 0;
                let pagosList = [];
                let pagosCalculados = 0;
                resp.data.adeudos.forEach((adeudo, idx) => {
                    let iva = (adeudo.subtotal * (adeudo.iva/100))
                    let total = adeudo.subtotal + iva;
                    adeudosList.push({
                        orden: (idx+1),
                        usuarioAltaNombre: adeudo.usuarioAltaNombre,
                        adeudoTipo: adeudo.adeudoTipo,
                        fechaAdeudo: getDateFormat(adeudo.fechaAdeudo, ''),
                        subtotal: formatMoney(adeudo.subtotal),
                        ivaCalculado: formatMoney(iva),
                        total: formatMoney(total)
                    });

                    adeudosCalculados += total;
                });

                resp.data.pagos.forEach(pago => {
                    pagosList.push({
                        folioId: pago.folioId,
                        fechaAlta: getDateFormat(pago.fechaAlta, ''),
                        usuarioAltaNombre: pago.usuarioAltaNombre,
                        notas: pago.notas,
                        metodoPago: metodoPago[pago.metodoPagoId],
                        descargable: pago.metodoPagoId === 1 ? 1 : 0,
                        monto: formatMoney(pago.monto)
                    });

                    pagosCalculados += pago.monto;
                });

                setAdeudos(adeudosList);
                setPagos(pagosList);
                setTotalAdeudos(adeudosCalculados);
                setTotalPagos(pagosCalculados);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));

        }
        const validate = Yup.object({});
        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };
        
    const formatMoney = (value) => {
        if(value === undefined){
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    
    const handleDownload = async (data) => {
        const requestBody = {
            nombrePlantilla: "Recibo",
            parametros: {
                "<<VARIABLE_FECHA>>": data.fechaAlta,
                "<<VARIABLE_NOMBRE>>": data.ejecutivo === null ? "" : data.ejecutivo,
                "<<VARIABLE_CONTRATO>": numContrato,
                "<<VARIABLE_CANTIDAD>>": data.monto,
                "<<VARIABLE_CONCEPTO>>": data.concepto,
                "<<VARIABLE_TELEFONO>>": "",
                "<<VARIABLE_DIRECCION_UBICACION>>": "",
            }
        };

        await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
        }).catch(err => {
            console.error(err);
        });
    };
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <GestoriaAdeudosModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            getTramite={getTramite}
                            gestoriaPagoTramitesId={initialValue.gestoriaPagoTramitesId}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <GestoriaPagosModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            getTramite={getTramite}
                            totalPagar={totalAdeudos - totalPagos}
                            gestoriaPagoTramitesId={initialValue.gestoriaPagoTramitesId}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">
                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapsePagoTramitesGestoria" role="button" aria-expanded="false" aria-controls="collapsePagoTramitesGestoria" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Pago de trámites</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.gestoriaPagoTramitesStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapsePagoTramitesGestoria">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.valuacionValoracionUnidadStatusId === 40}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small><strong>Adeudos</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn'
                                                            disabled={initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            onClick={() => setModalMessage({ isOpen: true, title: 'Registrar adeudo', type: 3 })}>
                                                            Registrar adeudo
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-12">
                                                        <DataTable 
                                                            column={columnsAdeudos}
                                                            data={adeudos}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2 justify-content-end">
                                                    <div className="col-auto">
                                                        <small><strong>Total: { formatMoney(totalAdeudos) }</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small><strong>Pagos</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn'
                                                            disabled={initialValue.valuacionValoracionUnidadStatusId === 40}
                                                            onClick={() => setModalMessage({ isOpen: true, title: 'Registrar pago', type: 4 })}>
                                                            Registrar pago
                                                        </button>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn btn-gray'
                                                            onClick={() => {
                                                                navigate(`/garantias/datadocs/fGestoria/ePaGestor/${vin}/${id}`, {
                                                                    state: values,
                                                                });
                                                            }}>
                                                            <i className="icon-blue fa fa-file"></i> Subir documento
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-12">
                                                        <DataTable 
                                                            column={columnsPagos}
                                                            data={pagos}
                                                            handleDownload={handleDownload}
                                                            downloable={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2 justify-content-end">
                                                    <div className="col-auto">
                                                        <small><strong>Total: { formatMoney(totalPagos) }</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small><strong>Balance</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-4 px-4">
                                                    <div className="col-3">
                                                        <table className="table" style={{"border":"solid thin", "borderColor":"#E0E0E0"}}>
                                                            <thead style={{"backgroundColor":"#E0E0E0"}}>
                                                                <tr>
                                                                    <th scope="col">Concepto</th>
                                                                    <th scope="col">Monto ($)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Adeudos</th>
                                                                    <td>{ formatMoney(totalAdeudos) }</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Pagos cliente</th>
                                                                    <td>{ formatMoney(totalPagos) }</td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot style={{"backgroundColor":"#E0E0E0"}}>
                                                                <tr>
                                                                    <th scope="col">Pendiente</th>
                                                                    <th scope="col">{ formatMoney(totalAdeudos - totalPagos) }</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                                
                                                
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={!initialValue.entregaConcluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Pagos concluidos</label>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default PagoTramitesGestoriaCard