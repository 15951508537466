import { Formik } from "formik";
import MontoDevolverHook from "../../../Hooks/ProcesosCierre/MontoDevolverHook";
import DataTable from "../../../Components/datatable/DataTable";
import { TextField } from "../../../Components/TextField";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { SelectField } from "../../../Components/SelectField";
import MontoDevolverModal from "./MontoDevolverModal";

const ListadoMontoADevolver = () => {

    const {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail,
        procesos,
        getListado
    } = MontoDevolverHook();

    return (<Formik
        innerRef={refFormik}
        enableReinitialize={true}
        initialValues={initialValue}
    // validationSchema={validate}
    >
        {({ isValid, values, setFieldValue, errors }) => (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={500}>
                    {
                        modalMessage.type === 1 &&
                        <>
                            <MontoDevolverModal 
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                getListado={getListado}
                            />
                        </>
                    }
                </Modal>
                <section className="mt-2 mx-2">
                    <div className='pb-4'>
                        <section className='mx-4 my-4 d-flex flex-column'>
                            <div className="col-12 mt-2">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <strong className='mt-2'>Solicitud de monto a devolver</strong>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="row">
                                    <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                            setFieldValue("buscar", event.target.value);
                                            setBuscar(event.target.value)
                                        }} />
                                    </div>
                                    <div className="col-12 col-md-2 mt-2">
                                        <SelectField
                                            id="resultado"
                                            name="resultado"
                                            items={procesos}
                                            placeholder={'Proceso'}
                                            onChange={(event) => {
                                                setFieldValue("resultado", event.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            detailable={true}
                                            handleDetail={handleDetail}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </>
        )
        }
    </Formik >
    );
}

export default ListadoMontoADevolver;