import React from 'react'
import img from '../../Assets/img/Cobranza.png';

const CobranzaHome = () => {

  return (
    <>
    <section >
        <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'70vh'}}>
            <div className='row'>
                <div className='text-center'>
                  <img src={img} style={{width:'20rem'}} className='img-fluid'/>
                  <h5 className='mt-4 mb-4'>Cobranza</h5>
                  <p>Aquí podrás darle seguimiento a cada una de las tareas de tu día a día. Para comenzar<br/> selecciona una opción del menú.</p>
                </div>
            </div>       
        </div>
    </section>
    </>
  )
}

export default CobranzaHome