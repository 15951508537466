import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useRef } from 'react';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { SelectField } from '../../Components/SelectField';
import { TextField } from '../../Components/TextField';

const DetonanteComponent = ({ item, initialDet, index, handleClick, handleValue }) => {
    let initial = {
        tipoDetonanteId: initialDet?.tipoDetonanteId ?? "",
        plazoId: initialDet?.plazoId ?? "",
        momento: "",
        diaMes: "",
        hora: initialDet?.hora,
        esRepeticion: initialDet?.tipoDetonanteId == 1 ? true : false,
        esTemporizador: initialDet?.tipoDetonanteId == 2 ? true : false,
        esHorario: false,
        momentoHora: ""
    }

    const validate = Yup.object({
        tipoDetonanteId: Yup.string()
            .required("El campo es requerido."),
        reglasNegocioPlazoId: Yup.string()
            .required("El campo es requerido."),
        momento: Yup.string()
            .required("El campo es requerido."),
        diaMes: Yup.string()
            .required("El campo es requerido."),
        horario: Yup.string()
            .required("El campo es requerido."),
        horarioFin: Yup.string()
            .when("esRepeticion", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        /////////////
        // momentoHora: Yup.string()
        //     .when("esHorario", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        // reglasNegocioPlazoId: Yup.string()
        //     .when("esRepeticion", {
        //         is: false,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        // momento: Yup.string()
        //     .when("esRepeticion", {
        //         is: false,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        // diaMes: Yup.string()
        //     .when("esRepeticion", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        // hora: Yup.string()
        //     .when("esRepeticion", {
        //         is: false,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
        // momentoHora: Yup.string()
        //     .when("esHorario", {
        //         is: true,
        //         then: Yup.string().required("El campo es requerido.")
        //     }),
    });

    const dataTipoDetonante = [
        { label: "Repetición", value: 1 },
        { label: "Temporizador", value: 2 },
        // { label: "Horario", value: 3 }
    ];
    const dataPlazo = [
        { label: "Minutos", value: 1 },
        { label: "Dias", value: 2 },
        { label: "Semanas", value: 3 },
        { label: "Mes", value: 4 }
    ];

    const [initialValue, setInitialValue] = useState(initial)
    const [dataDocumento, setDataDocumento] = useState([]);
    const [dataDetonante, setDataDetonante] = useState([]);
    const [typeValue, setTypeValue] = useState("");
    const [isRepeticion, setIsRepeticion] = useState(false);
    useEffect(() => {
        handleGetAllTipo();
        // sendValue();
    }, [])
    useEffect(() => {
        // handleChangeTipo();
    }, [typeValue, item.etapaOrigenId])

    useEffect(() => {
        handleSetInitalState();
    }, [initialDet])

    const handleSetInitalState = () => {
        let initial = {
            tipoDetonanteId: initialDet?.tipoDetonanteId ?? "",
            reglasNegocioPlazoId: initialDet?.reglasNegocioPlazoId ?? "",
            momento: initialDet?.momento ?? "",
            diaMes: "",
            hora: initialDet?.hora ?? "",
            esRepeticion: initialDet?.tipoDetonanteId == 1 ? true : false,
            esTemporizador: initialDet?.tipoDetonanteId == 2 ? true : false,
            esHorario: false,
            horario: initialDet?.horario ?? "",
            horarioFin: initialDet?.horarioFin ?? "",

        }
        setInitialValue(initial);
    }
    const handleGetAllTipo = async () => {
        await EtapaService.getAllTipoDetonante()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoDetonanteId,
                        label: item.nombre
                    })
                });
                setDataDetonante(NewData);
            }).catch(e => {
                // if (e.response !== undefined)
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                // else
                //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const sendValue = (values, tipo, val) => {
        // valuesss(values);
        
        if (tipo === 1)
            handleValue({ ...values, tipoDetonanteId: val });
        if (tipo === 2)
            handleValue({ ...values, reglasNegocioPlazoId: val });
        if (tipo === 3)
            handleValue({ ...values, momento: val });
        if (tipo === 4)
            handleValue({ ...values, horario: val });
        if (tipo === 5)
            handleValue({ ...values, horarioFin: val });


    }
    const handleChangeTipo = async (value) => {
        
        if (item.etapaOrigenId !== "") {
            await EtapaService.getAllDetonanteById(typeValue, item.etapaOrigenId)
                .then(res => {
                    let NewData = [];
                    res.data.data?.map(item => {
                        NewData.push({
                            value: item.plazoId,
                            label: item.nombre
                        })
                    });
                    setDataDocumento(NewData);
                }).catch(e => {
                    // if (e.response !== undefined)
                    //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    // else
                    //     setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
        // else if (value == 2)
        //     setDataDocumento(dataCondicionalTipo2);        
    }
    const handleChangeDetonante = (value) => {
        
        if (value === 1) {
            setIsRepeticion(true);
            setInitialValue({ ...initialValue, esRepeticion: true, tipoDetonanteId: value, esTemporizador: false, esHorario: false });
        } else if (value === 2) {
            setIsRepeticion(false);
            setInitialValue({ ...initialValue, esRepeticion: false, tipoDetonanteId: value, esTemporizador: true, esHorario: false });
        } else if (value === 3) {
            setIsRepeticion(false);
            setInitialValue({ ...initialValue, esRepeticion: false, tipoDetonanteId: value, esTemporizador: false, esHorario: true });
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <div className='col-12 row d-flex align-items-top'>
                        <div className="col-sm-4 mb-2">

                            <SelectField className="col-12" label="Tipo de detonante:" name={`tipoDetonanteId`} items={dataTipoDetonante} onChange={(event) => {
                                setFieldValue(`tipoDetonanteId`, event.value);
                                setTypeValue(event.value);
                                sendValue(values, 1, event.value);
                                handleChangeDetonante(event.value);
                            }} />
                        </div>
                        {
                            values.esRepeticion &&
                            <>
                                <div className="col-sm-4 mb-2">
                                    <SelectField className="col-12" label="Plazo:" name={`reglasNegocioPlazoId`} items={dataPlazo} onChange={(event) => {
                                        setFieldValue(`reglasNegocioPlazoId`, event.value);
                                        sendValue(values, 2, event.value);
                                    }} />
                                </div>
                                <div className="col-sm-4 mb-2">
                                    <TextField label="Momento" name="momento" type="text" holder="Escribe" onChange={(event) => {
                                        setFieldValue(`momento`, event.target.value);
                                        sendValue(values, 3, event.target.value);
                                    }} />
                                </div>
                                <div className="col-sm-4 mb-2">
                                    <TextField label="Hora inicio:" name="horario" type="time" holder="Escribe" onChange={(event) => {
                                        setFieldValue(`horario`, event.target.value);
                                        sendValue(values, 4, event.target.value);
                                    }} />
                                </div>
                                <div className="col-sm-4 mb-2">
                                    <TextField label="Hora Fin:" name="horarioFin" type="time" holder="Escribe" onChange={(event) => {
                                        setFieldValue(`horarioFin`, event.target.value);
                                        sendValue(values, 5, event.target.value);
                                    }} />
                                </div>
                            </>
                        }

                        {
                            values.esTemporizador &&
                            <>
                                <div className="col-sm-4 mb-2">

                                    <SelectField className="col-12" label="Plazo:" name={`reglasNegocioPlazoId`} items={dataPlazo} onChange={(event) => {
                                        setFieldValue(`reglasNegocioPlazoId`, event.value);
                                        sendValue(values, 2, event.value);
                                    }} />
                                </div>
                                <div className="col-sm-4 mb-2">
                                    <TextField label="Momento" name="momento" type="text" holder="Escribe" onChange={(event) => {
                                        setFieldValue(`momento`, event.target.value);
                                        sendValue(values, 3, event.target.value);
                                    }} />
                                </div>
                                <div className="col-sm-4 mb-2">
                                    <TextField label="Horario:" name="horario" type="time" holder="Escribe" onChange={(event) => {
                                        setFieldValue(`horario`, event.target.value);
                                        sendValue(values, 4, event.target.value);
                                    }} />
                                </div>
                            </>
                        }
                        {
                            values.esHorario &&
                            <div className="col-sm-4 mb-2">
                                <TextField label="Horario:" name="momentoHora" type="time" holder="Escribe" />
                            </div>
                        }
                        {
                            index > 0 &&
                            <div style={{ position: 'relative', right: '-99px', top: `${Object.keys(errors).length > 0 ? '-7px' : '-55px'}` }}>
                                <span style={{ position: 'absolute', right: '70px', width: '40px', backgroundColor: 'transparent', color: 'gray' }} className="btn col-1 d-flex justify-content-center" onClick={handleClick} ><i className="bi bi-trash"></i></span>
                            </div>
                        }
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default DetonanteComponent