import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Formik, useFormikContext } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Datamovil from '../../../Services/Resguardos/Datamovil';

const AntecedentesCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, datamovilid } = useParams();


        let initial = {
            generalId: "",
            garantiaId: "",
            folioGarantia: "",
            clasificacionFalla: "",
            gravedad: "",
            tipoReporte: "",
            valorMercado: "",
            confirmaUnidadParaInventario: "",
            comentarios: "",
            valuacionId: "",
            valuacionValoracionUnidadId: "",
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }
        
        useEffect(() => {
            //getMotivosDesinstalacion();
            getAntecedentes();
        }, []);


        /* CONSULTAS */
        const getAntecedentes = async () => {
            setLoading(true);
            await Datamovil.datamovilAntecedentesGet(generalid)
                .then(resp => {
                    resp.data.confirmaUnidadParaInventario = resp.data.confirmaUnidadParaInventario === null ? null : (resp.data.confirmaUnidadParaInventario ? "Sí" : "No");
                    resp.data.valorMercado = resp.data.valorMercado === null ? null : formatMoney(resp.data.valorMercado);
                    setInitialValue(resp.data);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */


        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAntecedentes" role="button" aria-expanded="false" aria-controls="collapseAntecedentes" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><strong>
                                            Antecedentes
                                            
                                        </strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilAntecedentesStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseAntecedentes">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        isInitialValid={false}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Garantía</strong></small>
                                                    </div>
                                                    
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="folioGarantia" disabled={true} label="Folio de Garantía" 
                                                        value={initialValue.folioGarantia}
                                                        holder="" name="folioGarantia" type="text"  />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="clasificacionFalla" disabled={true} label="Clasificación de la falla" holder="" name="clasificacionFalla" type="text"  />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="gravedad" disabled={true} label="Gravedad" holder="" name="gravedad" type="text"  />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="tipoReporte" disabled={true} label="Tipo de reporte" holder="" name="tipoReporte" type="text"  />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Valoracion</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="valorMercado" disabled={true} label="Valor de mercado ($)" holder="" name="valorMercado" type="text"  />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="confirmaUnidadParaInventario" disabled={true} label="Unidad para inventario" holder="" name="confirmaUnidadParaInventario" type="text"  />
                                                    </div>
                                                </div>
                                                
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={true}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="" />
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        )
    })
export default AntecedentesCard