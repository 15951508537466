import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    EmpresaId: "",
    GruaAsignada: "",
    costoGrua: 0,
    EstadoId: "",
    MunicipioId: "",
    ColoniaId: "",
    Calle: "",
    Numero: "",
    TallerAutorizado: "",
    ReferenciaUbicacion: "",
    FechaHoraCita: "",
    StatusCita: "",
    GarantiaId: "",
    GruasId: "",
    IVA: 0,
    CostoInicial: 0,
    Subtotal: 0,
    Total: 0,
    BloqueoIdG: "",
    FechaCitaCalendario: "",
    FolioGrua: "",
    EjecutivoGarantia : "",
    GruaStatusId : null
}

export const GruaSlice = createSlice({
    name: 'GruaSlice',
    initialState,
    reducers: {
        setGruaSlice: (state, action) => {
            return {
                ...state,
                GruasId: action.payload.GruasId,
                EmpresaId: action.payload.EmpresaId,
                GruaAsignada: action.payload.GruaAsignada,
                costoGrua: action.payload.costoGrua,
                EstadoId: action.payload.EstadoId,
                MunicipioId: action.payload.MunicipioId,
                ColoniaId: action.payload.ColoniaId,
                Calle: action.payload.Calle,
                Numero: action.payload.Numero,
                TallerAutorizado: action.payload.TallerAutorizado,
                ReferenciaUbicacion: action.payload.ReferenciaUbicacion,
                FechaCitaCalendario: action.payload.FechaCitaCalendario,
                StatusCita: action.payload.StatusCita,
                GarantiaId: action.payload.GarantiaId,
                IVA: action.payload.IVA,
                CostoInicial: action.payload.CostoInicial,
                Subtotal: action.payload.Subtotal,
                Total: action.payload.Total,
                BloqueoIdG: action.payload.BloqueoIdG,
                FolioGrua: action.payload.FolioGrua,
                EjecutivoGarantia: action.payload.EjecutivoGarantia,
                GruaStatusId : action.payload.GruaStatusId
            }
        },
        setEditable: (state, action) => {
            return {
                ...state,
                editable: action.payload
            }
        },
        clearGrua: (state) => {
            return {
                ...state,
                EmpresaId: "",
                GruaAsignada: "",
                costoGrua: 0,
                EstadoId: "",
                MunicipioId: "",
                ColoniaId: "",
                Calle: "",
                Numero: "",
                TallerAutorizado: "",
                ReferenciaUbicacion: "",
                FechaHoraCita: "",
                StatusCita: "",
                GarantiaId: "",
                GruasId: "",
                IVA: 0,
                CostoInicial: 0,
                Subtotal: 0,
                Total: 0,
                BloqueoIdG: "",
                FechaCitaCalendario: "",
                FolioGrua: "",
                EjecutivoGarantia : "",
                GruaStatusId : null
            }
        }
    }
})

export const { setGruaSlice, setEditable,clearGrua } = GruaSlice.actions

export default GruaSlice.reducer