import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import DataTable from '../../../Components/datatable/DataTable';
import ReparacionUnidadModal from '../../../Components/Modal/ReparacionUnidadModal';
import ValoracionUnidadCard from './ValoracionUnidadCard';

const InspeccionUnidadCard
    = React.forwardRef(({ ...props }, ref) => {
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, valuacionid } = useParams();


        let initial = {
                valuacionInspeccionUnidadId: "",
                valuacionId: "",
                valuacionInspeccionUnidadStatusId: 0,
                valuacionInspeccionUnidadStatus: "",
                color: "default",
                documentoDescargado: false,
                vehiculoApto: null,
                comentarios: "",
                concluido: null,
                fechaConclusion: "",
                usuarioAlta: "",
                usuarioActualiza: "",
                usuarioAltaNombre: null,
                usuarioActualizaNombre: null,
                status: null,
                fechaAlta: "",
                fechaActualiza: "",
                reparaciones: []
            }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [total, setTotal] = useState(0);
        const [reparaciones, setReparaciones] = useState({
            totalRows: 0,
            pageSize: 0,
            currentPage: 1,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            data: [
                { id: 1, fechaMovimiento: "23-02-2024", ejecutivo: "César Ortiz", tipoCosto: "Reparación", concepto: "-",  subtotal: "1000", iva: "150", total: "1150"}
            ]
        });

        const columns = [
            { field: 'id', headerName: '#'},
            { field: 'fechaCosto', headerName: 'Fecha y hora'},
            { field: 'ejecutivo', headerName: 'Ejecutivo'},
            { field: 'concepto', headerName: 'Concepto'},
            { field: 'subtotal', headerName: 'Subtotal'},
            { field: 'iva', headerName: 'IVA'},
            { field: 'total', headerName: 'Total'},
        ];


        useEffect(() => {
            getInspeccionUnidad();
        }, []);
        

        /* CONSULTAS */
        const getInspeccionUnidad = async () => {
            await Valuaciones.valuacionInspeccionUnidadGet(valuacionid)
            .then(resp => {
                resp.data.vehiculoApto = resp.data.vehiculoApto === null ? null : (resp.data.vehiculoApto ? 1 : 0);

                let reparacionesTmp = [];
                let totalTmp = 0;
                resp.data.reparaciones.forEach((item, idx) => {
                    let total = item.subtotal + item.iva;
                    totalTmp += total;
                    reparacionesTmp.push({
                        id: idx+1,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total),
                    })
                });

                setTotal(totalTmp);

                setReparaciones({
                    ...reparaciones,
                    data: reparacionesTmp
                })
                setInitialValue(resp.data);
            })
            .catch(err => {

            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }
        
        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            values.valuacionInspeccionUnidadStatusId = 20;

            if(values.concluido){
                values.valuacionInspeccionUnidadStatusId = 30;
            }
            await Valuaciones.valuacionInspeccionUnidadActualizar(values)
            .then(resp => {
                resp.data.vehiculoApto = resp.data.vehiculoApto === null ? null : (resp.data.vehiculoApto ? 1 : 0);

                let reparacionesTmp = [];
                resp.data.reparaciones.forEach((item, idx) => {
                    let total = item.subtotal + item.iva;
                    reparacionesTmp.push({
                        id: idx+1,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total),
                    })
                });

                setReparaciones({
                    ...reparaciones,
                    data: reparacionesTmp
                })
                setInitialValue(resp.data);
                toast.success(message("Se actualizó la inspección de unidad correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar la inspección de unidad!"));
                console.error(err);
            })
            .finally(() => {
                if(values.concluido){
                    crearValoracion();
                }
            });
        }

        const crearValoracion = async () => {
            let params = {
                valuacionId: valuacionid,
                ValuacionValoracionUnidadStatusId: 10,
                status: true,
            }
            await Valuaciones.valuacionValoracionUnidadCrear(params)
            .then(resp => {

            })
            .catch(err => {

            });
        }

        const validate = Yup.object({});

        const downloadPDF = async (values) => {
            values.valuacionInspeccionUnidadStatusId = 20;
            values.documentoDescargado = true;

            await Valuaciones.valuacionInspeccionUnidadActualizar(values)
            .then(resp => {
                resp.data.vehiculoApto = resp.data.vehiculoApto === null ? null : (resp.data.vehiculoApto ? 1 : 0);

                let reparacionesTmp = [];
                resp.data.reparaciones.forEach((item, idx) => {
                    let total = item.subtotal + item.iva;
                    reparacionesTmp.push({
                        id: idx+1,
                        fechaCosto: getDateFormat(item.fechaAlta, ""),
                        ejecutivo: item.usuarioAltaNombre,
                        concepto: item.concepto,
                        subtotal: formatMoney(item.subtotal),
                        iva: formatMoney(item.iva),
                        total: formatMoney(total),
                    })
                });

                setReparaciones({
                    ...reparaciones,
                    data: reparacionesTmp
                })
                setInitialValue(resp.data);
                toast.success(message("Se generó el archivo PDF correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar generar el archivo PDF!"));
                console.error(err);
            });
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <ReparacionUnidadModal
                            getInspeccionUnidad={getInspeccionUnidad}
                            valuacionInspeccionUnidadId={initialValue.valuacionInspeccionUnidadId}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCitaExpediente" role="button" aria-expanded="false" aria-controls="collapseCitaExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Inspección de unidad</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.valuacionInspeccionUnidadStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseCitaExpediente">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Generar documento</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button 
                                                                    disabled={!initialValue.documentoDescargado}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={!initialValue.documentoDescargado || initialValue.valuacionInspeccionUnidadStatusId === 30}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small>Documento de inspección de unidad</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className='btn btn-gray' onClick={() => downloadPDF(values)}>
                                                            <i className="icon-blue bx bx-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Vehículo apto para inventario?"
                                                            disabled={!initialValue.documentoDescargado || initialValue.valuacionInspeccionUnidadStatusId === 30}
                                                            name="vehiculoApto"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("vehiculoApto", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Observaciones de la unidad"
                                                            disabled={!initialValue.documentoDescargado || initialValue.valuacionInspeccionUnidadStatusId === 30}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Costo de reparaciones</strong></small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button
                                                            className='btn'
                                                            disabled={!initialValue.documentoDescargado}
                                                            onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Registrar reparación' })}>
                                                            Agregar
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <DataTable
                                                            column={columns}
                                                            data={reparaciones.data}
                                                            // editable={true}
                                                            // deleteable={true}
                                                            // pageCount={0}
                                                            // currentPage={1 - 1}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 justify-content-end">
                                                    <div className="col-auto">
                                                        <strong>Total: { formatMoney(total) }</strong>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={!initialValue.documentoDescargado || initialValue.valuacionInspeccionUnidadStatusId === 30} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Inspección de unidad finalizada</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    initialValue.valuacionInspeccionUnidadStatusId === 30 &&
                    <ValoracionUnidadCard />
                }
            </>
        )
    })
export default InspeccionUnidadCard