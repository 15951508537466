import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import SolicitarTramiteModal from "./SolicitarTramiteModal";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import { message } from "../../../Utils/functions";

const AutorizarSolicitudExpediente = () => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [collpsableGrua, setCollpsableGrua] = useState(false);

    const initial = {
        TramiteId: '',
        OperacionTramiteid : '',
        Comentarios: ''
    };


    const [initialValue, setInitialValue] = useState(initial);
    const [tipoOperaciones, setTipoOperaciones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, width: '' });
    const [openmodalMessage, setOpenModalMessage] = useState(false);
    const [tramite, setTramite] = useState([]);


    useEffect(() => {
        getTipoOperaciones();
        getTramite();
    }, []);

    const getTipoOperaciones = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.OperacionesExpediente')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.OperacionesExpedienteId.toString(),
                        label: item.OperacionesExpediente
                    }
                });
                setTipoOperaciones(items);
            })
            .catch(err => {

            });
    };


    const submitForm = async (values) => {
        values.TramiteId = tramite.tramiteId;

        if(tramite.tramiteId === undefined){
            toast.error(message('Aún no hay un trámite iniciado, inicia uno para poder crear una solicitud'));
            return false;
        }

        setLoading(true);
        await ServicioExpedientes.addSolicitud(values)
        .then(resp => {
            if(resp.data.ban === 1)
            {
                toast.success(message(resp.data.message));
            }else{
                toast.error(message(resp.data.message));
            }
        })
        .catch(err => {
            toast.error(message('Error al intentar crear la solicitud'));
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const downloadPDF = () => {

    }

    const getTramite = async () => {
        if(state.generalId === undefined) return false;

        setLoading(true);
        await ServicioExpedientes.getTramite(state?.generalId)
        .then(resp => {
            setTramite(resp.data);
        })
        .catch(err => {
            toast.error(message('Error al intentar obtener el trámite'));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={520}>
                {
                    modalMessage.type === 1 &&
                    <SolicitarTramiteModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        getTramite={getTramite}
                    />
                }
            </Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAutorizarSolicitud" role="button" aria-expanded="false" aria-controls="collapseAutorizarSolicitud" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Autorizar solicitud</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAutorizarSolicitud">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    // validationSchema={validate}
                                    initialValues={initialValue}
                                >
                                    {({ isValid, values, setFieldValue, errors, resetForm }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 justify-content-between">
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Operación"
                                                        disabled={false}
                                                        name="OperacionTramiteid"
                                                        items={tipoOperaciones}
                                                        onChange={(event) => {
                                                            setFieldValue("OperacionTramiteid", event.value);
                                                        }} />
                                                </div>
                                                <div className="col-5">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <button className="btn" disabled={values.OperacionTramiteid !== '10'}
                                                                onClick={() => {
                                                                    setModalMessage({ isOpen: true, type: 1, title: 'Solicitar Trámite', data: state });
                                                                }}
                                                            >
                                                                <i className="icon-light fa fa-file me-2"></i>
                                                                Solicitar trámite
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button className="btn btn-secondary">
                                                                <i className="icon-light fa fa-file me-2"></i>
                                                                Subir documento
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button className="btn"
                                                            onClick={() => {
                                                                submitForm(values);
                                                                resetForm();
                                                            }}>
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-auto">
                                                    <small>Checklist de expediente</small>
                                                </div>
                                                <div className="col-3">
                                                    <button className='btn btn-gray' onClick={() => downloadPDF(1)}>
                                                        <i className="icon-blue bx bx-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-8">
                                                    <TextAreaField
                                                        label="Comentarios"
                                                        disabled={false}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="Comentarios"
                                                        id="Comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AutorizarSolicitudExpediente;