import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import * as Yup from 'yup';
import { SelectField } from '../../Components/SelectField';
import CorreosService from '../../Services/Correos/Correos';
import { TextAreaField } from '../../Components/TextAreaField';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { TextField } from '../../Components/TextField';

const ModalReglaTipoBloqueo = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage }) => {
    
    const refbtn = useRef();
    let initial = {
        reglasNegocioId: item?.reglasNegocioId ?? "",
        tipoReglaId:item?.tipoReglaId ?? "",
        etapaOrigenId: item?.etapaOrigenId ?? "",     
        productoId: item?.productoId ?? "",
        tipoCompraId: item?.tipoCompraId ?? "",   
        status: item?.status ?? true,
        camposObligatorios:[],
        tiposDocumentos:[]
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataEtapa, setDataEtapa] = useState([]);
    const [dataTableDoc, setDataTableDoc] = useState([]);
    const [dataTableCampos, setDataTableCampos] = useState([]);
    const [etapaActualId, setEtapaActualId] = useState("");
    const validate = Yup.object({      
        etapaOrigenId: Yup.string()
            .required("El campo es requerido.")       

    });

    useEffect(() => {
        if (item.action === "edit"){
            setEtapaActualId(item.etapaOrigenId);
        }
        handleGetAllEtapa();
    }, [])

    useEffect(() => {        
        if(etapaActualId !== ""){
            handleGetAllDoc();
            handleGetAllCampos();
        }      
    }, [etapaActualId])
    
    const handleGetAllDoc = async () =>{                 
        if(etapaActualId == ""){
            setDataTableDoc([]);            
            return
        }        
        await EtapaService.getAllEtapaDoc(etapaActualId !== item.etapaOrigenId ? "" : item.reglasNegocioId,etapaActualId,item.productoId,item.tipoCompraId)
        .then(res => {                        
            setDataTableDoc(res.data.data);  
            setInitialValue({...initialValue,etapaOrigenId:etapaActualId,tiposDocumentos:dataTableDoc});          
        }).catch(e => {            
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    }
    const handleGetAllCampos = async () =>{                 
        if(etapaActualId == ""){
            setDataTableCampos([]);            
            return
        }        
        await EtapaService.getAllEtapaCampo(etapaActualId !== item.etapaOrigenId ? "" : item.reglasNegocioId,etapaActualId)
        .then(res => {                        
            setDataTableCampos(res.data.data);  
            setInitialValue({...initialValue,etapaOrigenId:etapaActualId,camposObligatorios:dataTableCampos});          
        }).catch(e => {            
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    }
    const handleGetAllEtapa = async () => {
        await EtapaService.getAllEtapaById(item.productoId,item.tipoCompraId)
        .then(res => {
            
            let NewData = [];
            res.data.data?.map(item => {
                NewData.push({
                    value: item.etapaDestinoId,
                    label: item.nombre
                })
            });
            setDataEtapa(NewData);                
            // setDataTable(res.data.data); 
        }).catch(e => {
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    }

    const handleChanagestatusCamp = (value, item) => {        
        setDataTableCampos((current) =>
            current?.map((data) =>
                data.campoObligatorioId === item.campoObligatorioId
                    ? {
                        ...data,
                        check: value
                    }
                    : data
            )
        );
    }
    const handleChanagestatusDoc = (value, item) => {        
        setDataTableDoc((current) =>
            current?.map((data) =>
                data.tipoDocumentoId === item.tipoDocumentoId
                    ? {
                        ...data,
                        check: value
                    }
                    : data
            )
        );
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        if (item.action === "edit") {
            await EtapaService.UpdTipoBloqueo(value)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            await EtapaService.AddTipoBloqueo(value)
                .then(res => {                    
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.data.mensaje })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })

        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit({...values,
                    camposObligatorios:dataTableCampos,
                    tiposDocumentos:dataTableDoc
                });
                
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4 tbl-scroll' style={{ width: '40rem',height:'90vh',overflow:'auto' }}>
                            <Form style={{width:'38rem'}}>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='fs-5 mb-3'><strong>{item.action === "edit" ? 'Editar Regla' : 'Nueva Regla'}</strong></span>
                                        <div className="mb-3">
                                            {/* <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label> */}
                                            <div className="col-ms-6 form-check form-switch">
                                                <label className="form-check-label">{values.status ? "Activo" : "Inactivo"}</label>
                                                <Field className="form-check-input" type="checkbox" name="status" />
                                            </div>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: '12px' }}>A continuación se puede configurar la etapa destino.</span>
                                </div>
                                <div className="mb-3 row my-4">
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>1. Seleccionar la etapa correspondiente:</strong></label>
                                        <div className="col-sm-6 mb-2">
                                            <SelectField className="col-12" label="" name="etapaOrigenId" items={dataEtapa} onChange={(event) => {
                                                setEtapaActualId(event.value);
                                                setFieldValue("etapaOrigenId", event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>2. Seleccionar los campos obligatorios:</strong></label>
                                        <div className='tbl-scroll' style={{ overflowY: 'auto', height: '250px' }}>
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr className='custom-table'>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataTableCampos.length > 0 ?
                                                            dataTableCampos?.map((itemx, index) => {
                                                                return <tr key={index}>
                                                                    <td style={{width:'50px'}}>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.check} onChange={(e) => { handleChanagestatusCamp(e.target.checked, itemx) }} />
                                                                        </div>
                                                                    </td>
                                                                    <th scope="row">{itemx.nombre}</th>

                                                                </tr>
                                                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 row mb-3">
                                        <label style={{ fontSize: '13px' }}><strong>3. Seleccionar los documentos obligatorios:</strong></label>
                                        <div className='tbl-scroll' style={{ overflowY: 'auto', height: '250px' }}>
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr className='custom-table'>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataTableDoc.length > 0 ?
                                                            dataTableDoc?.map((itemx, index) => {
                                                                return <tr key={index}>
                                                                    <td style={{width:'50px'}}>
                                                                        <div className="form-check mx-1">
                                                                            <input className="form-check-input" type="checkbox" checked={itemx.check} onChange={(e) => { handleChanagestatusDoc(e.target.checked, itemx) }} />
                                                                        </div>
                                                                    </td>
                                                                    <th scope="row">{itemx.nombre}</th>

                                                                </tr>
                                                            }) : <tr><td colSpan="10" className="text-center">No se encontrarón registros</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5'  ref={refbtn} type="submit" disabled={!(isValid)}>{item.action === "add" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalReglaTipoBloqueo
