import AuthLogin from "../Utils/AuthLogin"

// const auth = new AuthLogin();
// 1-> Modulo
// 2-> SubModulo
// 3-> Seccion
// 4-> Reglas
// 5-> Acciones Rapidas
export const ValidatePermissionById = (type, Id, IdSubModulo) => {
    const auth = new AuthLogin();
    let validate = false;
    switch (type) {
        case 1:
            validate = auth.getModule(Id);
            break;
        case 2:
            validate = auth.getSubModule(Id);
            break;
        case 3:
            validate = auth.getSeccion(Id);
            break;
        case 4:
            validate = auth.getReglas(IdSubModulo, Id);
            break;
        case 5:
            validate = auth.getAccionesRapidas(Id);
            break;
        default:
            validate = false
            break;
    }
    return validate;
};

// export const ValidatePermission = ({ children, type, Id, IdSubModulo = null }) => {
//     let validate = ValidatePermissionById(type, Id, IdSubModulo)
//     return (
//         <>
//             {validate && children}
//         </>
//     )
// };

export const ValidatePermission = ({ children, isActive }) => {
    return (
        <>
            {isActive && children}
        </>
    )
};

export const validateManyPermissions = (keys, obj) => {
    let valueDefault = false;

    Object.keys(obj).forEach(function (key) {
        keys.map((key) => {
            if (obj[key])
                valueDefault = obj[key];
        })
    });

    return valueDefault;
}
export const validateManySubModule = (permision) => {
    let valueDefault = false;
    permision.map((key) => {
        if (key){
            valueDefault = true;
            // throw "";
        }
    });
    return valueDefault;
}
// export default ValidatePermission;