import React from 'react'
import Select from 'react-select'
import DataTable from '../../Components/datatable/DataTable';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import Parametros from '../../Services/Parametros/Parametros';
import SubmenuParam from './SubmenuParam'
import TenenciaModal from './ParametrosModal';
import IntelimotorCus from '../../Hooks/Parametros/Intelimotor';
import { pageRows } from '../../Utils/functions';
import RowPages from '../../Components/RowPages';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';

const IntelimotorParam = () => {
  let {
    columns,
    data,
    loading,
    modalMessage,
    setModalMessage,
    handleEdit,
    handleGetAcendes,
    isOpenModalParams,
    setIsOpenModalParams,
    item,
    columnsH,
    dataHistory,
    totalPage,
    handlePageClick,
    setStr,
    setDateTime,
    currentPage,
    setpageRows,
    setStrFiltro,
    totalPageData,
    handlePageClickData,
    initialPageData,
    setpageRowsData
  } = IntelimotorCus();
  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <Modal isOpen={modalMessage.isOpen} color='#fff'>
        {
          modalMessage.type === 1 &&
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }
        {
          modalMessage.type === 2 &&
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }

      </Modal>
      <SubmenuParam />
      <section className='mx-5 my-4 d-flex flex-column'>
        <span className='fs-5'><strong>Parámetros Intelimotor</strong></span>
        <span className='mt-2'>A continuación, se presentará un listado de los parámetros con los que cuenta el sistema.</span>
      </section>
      {
        isOpenModalParams &&
        <TenenciaModal
          isOpen={isOpenModalParams}
          setIsOpen={setIsOpenModalParams}
          item={item}
          handleGetAll={handleGetAcendes}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      }
        <section className='mt-3 mx-4'>
        <div className='col-lg-4 col-sm-12 d-flex'>
          <div className="col-6 mx-1  inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStrFiltro(e.target.value) }} />
          </div>
        </div>
      </section>
      <ValidatePermission
        isActive={ValidatePermissionById(4, Section.ParametrosIntelimotor, SubModule.Parametros).Ver}
      >
        <RowPages
          setpageRows={setpageRowsData}
        />
        <div className='mx-4 table-responsive'>
          <DataTable
            column={columns}
            data={data}
            editable={ValidatePermissionById(4, Section.ParametrosIntelimotor, SubModule.Parametros).Actualizar}
            handleEdit={handleEdit}
            pageCount={totalPageData}
            handlePageClick={handlePageClickData}
            paginate={true}
            currentPage={initialPageData}
          />
        </div>
      </ValidatePermission>
      <ValidatePermission
        isActive={ValidatePermissionById(3, Section.HistorialIntelimotor)}>
        <section className=''>
          <section className='mt-5 mx-4'>
            <span className='fs-5'><strong>Historial</strong></span>

            <div className='row col-12'>
              <div className="col-lg-3 col-md-6 col-sm-12 form-group inner-addon right-addon">
                <i className="glyphicon fas fa-search"></i>
                <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 form-group">
                <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDateTime(e.target.value) }} />
              </div>
            </div>

          </section>
          <RowPages
            setpageRows={setpageRows}
          />
          <div className='mx-4 table-responsive'>
            <DataTable
              column={columnsH}
              data={dataHistory}
              pageCount={totalPage}
              handlePageClick={handlePageClick}
              paginate={true}
              currentPage={currentPage - 1}
            />
          </div>
        </section>
      </ValidatePermission>
    </>
  )
}

export default IntelimotorParam