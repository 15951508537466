import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { FormatNumberMoney2, FormatNumberToDecimal, message } from '../../Utils/functions';
import CancelacionSeguroService from '../../Services/Seguros/CancelacionSeguroService';
import { parse } from 'date-fns/esm';
import PagoDesembolsoService from '../../Services/Seguros/PagoDesembolsoService';
import AuthLogin from '../../Utils/AuthLogin';

const auth = new AuthLogin();
const useProcesoDePagoPD = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'folio', headerName: 'Folio', width: 150 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 150 },
        { field: 'cantidadVehiculos', headerName: 'Cant. Vehículos', width: 150 },
        { field: 'costoStr', headerName: 'Costo', width: 150 },
        { field: 'strFechaSolicitud', headerName: 'Fecha Solicitud', width: 150 },
        // { field: '', headerName: 'Descargar', width: 150 },
        // { field: 'fechaUltimaCotizacion', headerName: 'Fecha ultima cotización', width: 250 },
    ];
    const columnsDetail = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'numeroPoliza', headerName: 'Póliza', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso de Unidad', width: 250 },
        { field: 'precioStr', headerName: 'Precio', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'strFechaInicio', headerName: 'Fecha Inicio', width: 250 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    const [Aseguradora, setAseguradora] = useState("");
    //DataDocs
    const [isOpenModalFile, setIsOpenModalFile] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);
    const [FechInicio, setFechInicio] = useState("");
    const [FechFin, setFechFin] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [dataPD, setDataPD] = useState();

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, TipoCompra, claveUsoUnidad, pageRows, Aseguradora, FechInicio, FechFin])

    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad();
        handleGetAllAseguradora();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await PagoDesembolsoService.getAllProcesoPago(page, pageRows, claveUsoUnidad, TipoCompra, Aseguradora, FechInicio, FechFin, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleUploadFile = async () => {

    }

    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }
    const handlePocesarInfo = (data) => {
        try {
            let { jsonData } = data;

            if (jsonData.length <= 0) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }            

            if (!jsonData[0]?.hasOwnProperty('Monto_pagado')) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.`)}</div>);                
                return;
            }            
            let dataValida = [];
            let dataNoValida = [];
            let Data = [];
            jsonData.filter((x) => {
                if (Number(x.Monto_pagado) > 0) {
                    let newData = {
                        ...x,
                        Precio: FormatNumberToDecimal(x.Precio.replace("$", " ")),
                        monto_pagadoStr: `$${FormatNumberMoney2(x.Monto_pagado)}`,
                        isValid: true,
                        usuario: auth.getNombreUsuario()
                    };
                    dataValida.push(newData);
                    Data.push(newData);
                } else {
                    let newData = {
                        ...x,
                        Precio: FormatNumberToDecimal(x.Precio.replace("$", " ")),
                        isValid: false,
                        usuario: auth.getNombreUsuario()
                    };
                    dataNoValida.push(newData);
                    Data.push(newData);
                }
            })

            setDataPD({ ...data, jsonData: Data, totalRegistro: jsonData.length, totalAProcesar: dataValida.length, totalSinProcesar: dataNoValida.length, dataAProcesar: dataValida });
            setOpenModalConfirm(true);
        } catch (error) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Existe un error en la estructura del archivo de Excel favor de comunicarte con el administrador.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    }

    const handleExport = async (obj) => {
        setLoading(true);
        await SeguroPolizaService.getDocumentExport(obj.folio)
        .then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `PendientesPago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        }).catch(e => {
        }).finally(() => {
            setLoading(false);
        })
    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        columnsDetail,
        DataAseguradora,
        setAseguradora,
        setFechInicio,
        setFechFin,
        setIsOpenModalFile,
        isOpenModalFile,
        handlePocesarInfo,
        openModalConfirm,
        setOpenModalConfirm,
        dataPD,
        handleExport
    }
}

export default useProcesoDePagoPD