import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Url: ""
}

export const UrlSlice = createSlice({
    name: 'UrlSlice',
    initialState,
    reducers: {
        setUrlSlice: (state, action) => {
            return {
                ...state,
                Url: action.payload.url,
            }
        },
        clearUrl: (state) => {
            return {
                ...state,
                Url: ""
            }
        }
    }
})

export const { setUrlSlice } = UrlSlice.actions

export default UrlSlice.reducer

