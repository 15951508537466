import axios from '../../Utils/ApiService';

const SeguroSinCotizarService = {

    getlAllVehiculoSinCotizacion: async (page, rows, marca, modelo, año, version, string) => {
        return await axios.get(`/functions/GetAllVehiculosSeguroInicial?page=${page}&Rows=${rows}&Marca=${marca}&Modelo=${modelo}&Year=${año}&Version=${version}&Buscar=${string}`);
    },
    getlAllAseguradora: async() => {
        return await axios.get(`/functions/GetCSAseguradoraSI`);
    },
    getlAllUsoUnidad: async () => {
        return await axios.get(`/functions/GetCSUsoUnidadSI`);
    },
    getlAllCobertura: async () => {
        return await axios.get(`/functions/GetCSCoberturaSI`);
    },
    getlAllFrecuenciaPago: async () => {
        return await axios.get(`/functions/GetCSFrecuenciaPagoSI`);
    },    
    AddSeguroInicial: async (data) => {
        return await axios.post(`/functions/AddSeguroInicial`,data);
    },  
    getlAllUbicacion: async () => {
        return await axios.get(`/catalogs/GetCSUbicacion`);
    },
    getlAllStatusDatamovil: async () => {
        return await axios.get(`/catalogs/GetCSStatusDatamovil`);
    },  
}

export default SeguroSinCotizarService