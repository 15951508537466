import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { clearHour as clearHourGrua } from '../../Redux/Slice/gruasSlice';
import { clearHour as clearHourGarantia } from '../../Redux/Slice/garantiasSlice';
import { clearHour as clearHourEntrega } from '../../Redux/Slice/entregaSlice';
import { clearHour as clearHourFirma } from '../../Redux/Slice/firmaSlice';
import { clearHour as clearHourEntregaExpediente } from '../../Redux/Slice/entregaExpedienteSlice';
import { clearHour as clearHourCitaDesinstalacion } from '../../Redux/Slice/citaDatamovilSlice';
import { clearHour as clearHourCitaCambioPlacas } from '../../Redux/Slice/citaDatamovilSlice';
import { clearHour as clearHourCitaAltaPlacas } from '../../Redux/Slice/citaDatamovilSlice';
import { clearHour as clearHourCitaTarjetaCirculacion } from '../../Redux/Slice/citaDatamovilSlice';
import { clearHour as clearHourCitaEntregaGestoria } from '../../Redux/Slice/citaDatamovilSlice';
import { useNavigate } from 'react-router-dom';

const DetailScheduleModal = ({ modalMessage, setModalMessage, schedules, columns = 4 }) => {
    const { url, contrato, folioLlamada, vin, modelo, ano, marca, sucursalName, tipoCalendario } = useSelector((state) => state.CalendarSlice);
    const { dayGrua, hourGrua, CalendarioIdGrua } = useSelector((state) => state.GruasSlice);
    const { dayGarantia, hourGarantia, CalendarioIdGarantia } = useSelector((state) => state.GarantiasSlice);
    const { dayEntrega, hourEntrega, CalendarioIdEntrega } = useSelector((state) => state.EntregaSlice);
    const { dayFirma, hourFirma, calendarioIdFirma } = useSelector((state) => state.FirmaSlice);
    const { dayEntregaExpediente, hourEntregaExpediente, CalendarioIdEntregaExpediente } = useSelector((state) => state.EntregaExpedienteSlice);
    const { dayCitaDatamovil, hourCitaDatamovil, calendarioIdCitaDatamovil } = useSelector((state) => state.CitaDatamovilSlice);
    const { dayCitaCambioPlacas, hourCitaCambioPlacas } = useSelector((state) => state.CambioPlacasSlice)
    const { dayCitaAltaPlacas, hourCitaAltaPlacas } = useSelector((state) => state.AltaPlacasSlice)
    const { dayCitaTarjetaCirculacion, hourCitaTarjetaCirculacion } = useSelector((state) => state.TarjetaCirculacionSlice)
    const { dayCitaGestoriaEntrega, hourCitaGestoriaEntrega } = useSelector((state) => state.GestoriaEntregaSlice)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (tipoCalendario === 1) {
            setHour(hourGarantia);
            setDay(dayGarantia)
        } else if (tipoCalendario === 2) {
            setHour(hourGrua);
            setDay(dayGrua);
        } else if (tipoCalendario === 3) {
            setHour(hourEntrega);
            setDay(dayEntrega)
        } else if (tipoCalendario === 6) {
            setHour(hourFirma);
            setDay(dayFirma);
        } else if (tipoCalendario === 7) {
            setHour(hourEntregaExpediente);
            setDay(dayEntregaExpediente);
        } else if (tipoCalendario === 8) {
            setHour(hourCitaDatamovil);
            setDay(dayCitaDatamovil);
        } else if (tipoCalendario === 9) {
            setHour(hourCitaCambioPlacas);
            setDay(dayCitaCambioPlacas);
        } else if (tipoCalendario === 10) {
            setHour(hourCitaAltaPlacas);
            setDay(dayCitaAltaPlacas);
        } else if (tipoCalendario === 11) {
            setHour(hourCitaTarjetaCirculacion);
            setDay(dayCitaTarjetaCirculacion);
        } else if (tipoCalendario === 12) {
            setHour(hourCitaGestoriaEntrega);
            setDay(dayCitaGestoriaEntrega);
        }
    }, []);
    const [daySelected, setDaySelected] = useState("");
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const [hour, setHour] = useState("");
    const [day, setDay] = useState("");

    const getDate = () => {
        const date = new Date(day.split("T")[0].replace('-', '/'));
        const strDate = days[date.getDay()] + ", " + ("0" + date.getDate()).slice(-2) + " de " + months[date.getMonth()];
        return strDate;
    }

    // setModalMessage({ ...modalMessage, isOpen: false })
    const handleCloseAndCancel = () => {
        if (tipoCalendario === 1) {
            dispatch(clearHourGarantia());
        } else if (tipoCalendario === 2) {
            dispatch(clearHourGrua());
        } else if (tipoCalendario === 3) {
            dispatch(clearHourEntrega());
        } else if (tipoCalendario === 6) {
            dispatch(clearHourFirma());
        } else if (tipoCalendario === 7) {
            dispatch(clearHourEntregaExpediente());
        } else if (tipoCalendario === 8) {
            dispatch(clearHourCitaDesinstalacion());
        } else if (tipoCalendario === 9) {
            dispatch(clearHourCitaCambioPlacas());
        } else if (tipoCalendario === 10) {
            dispatch(clearHourCitaAltaPlacas());
        } else if (tipoCalendario === 11) {
            dispatch(clearHourCitaTarjetaCirculacion());
        } else if (tipoCalendario === 12) {
            dispatch(clearHourCitaEntregaGestoria());
        }
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const handleCloseAndRedirect = () => {
        navigate("/" + url);
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    return (
        <div className='p-2'>
            <div className='justify-content-start align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>Detalle de cita</strong></h6>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-12">
                        <small>A continuación se muestra el detalle de la cita agendada para el vehículo:</small>
                    </div>
                    <div className="col col-12">
                        <small>
                            <span className='px-0'><strong>Contrato: </strong>{contrato}</span>
                            <span className='px-4'><strong>Folio llamada: </strong>{folioLlamada}</span></small>
                    </div>
                </div>
                <div className="row mt-4 mb-2">
                    <div className="col col-1">
                        <i className="icon-dark bx bx-car"></i>
                    </div>
                    <div className="col col-11">
                        <div className="row">
                            <div className="col-auto">
                                <span>VIN: {vin}</span>
                            </div>
                            <div className="col-auto">
                                <span>Marca: {marca}</span>
                            </div>
                            <div className="col-auto">
                                <span>Modelo: {modelo}</span>
                            </div>
                            <div className="col-auto">
                                <span>Año: {ano}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-1">
                        <i className="icon-dark bx bx-time-five"></i>
                    </div>
                    <div className="col col-11">
                        <div className='row'>
                            <div className="col-auto">
                                <span>{getDate()}</span>
                            </div>
                            <div className="col-auto">
                                <strong>{hour}</strong>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-auto">
                                <strong>{sucursalName}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleCloseAndCancel() }}>Cancelar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleCloseAndRedirect() }}>Agendar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default DetailScheduleModal