import axios from '../../Utils/ApiService';

const EndosoService = {
    getAllListaEndosoValidado:(page,rows,tipoCompraId,string)=> {        
        return axios.get(`/functions/GetAllVehiculosEndosoValidado?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    },
    getAllListaPendienteEndoso:(page,rows,tipoCompraId,string)=> {        
        return axios.get(`/functions/GetAllVehiculosEndosoPendiente?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    },
    UpdateEndoso:(generalId)=>{
        return axios.put(`/functions/SaveValidaEndoso?GeneralId=${generalId}`);
    }
}
export default EndosoService;