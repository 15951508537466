import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import Llamada from '../../../Services/Cartera/Llamadas';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import DataTable from '../../../Components/datatable/DataTable';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import PagoDatamovilModal from '../../../Components/Modal/PagoDatamovilModal';
import documentsHelper from '../../../Utils/documents';

const PagoAdeudosCard
    = React.forwardRef(({ ...props }, ref) => {
        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, datamovilid } = useParams();


        let initial = {
            datamovilAdeudosId: "",
            datamovilId: "",
            datamovilAdeudosStatusId: 0,
            datamovilAdeudosStatus: "",
            color: "default",
            adeudo: 0,
            boletinado: false,
            comentariosBoletinado: "",
            fechaBoletinado: "",
            concluido: false,
            fechaConclusion: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            status: false,
            fechaAlta: "",
            fechaActualiza: "",
            pagos: []
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const [cita, setCita] = useState('');

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [pagado, setPagado] = useState(0);
        const [pendiente, setPendiente] = useState(0);

        const [pagos, setPagos] = useState({
            totalRows: 0,
            pageSize: 0,
            currentPage: 1,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            data: [
                { id: 1, fechaMovimiento: "23-02-2024", ejecutivo: "César Ortiz", concepto: "Reparación", metodoPago: "Efectivo",  monto: "1000", recibo: "150" }
            ]
        });

        const metodosPagos = {
            1: "Efectivo",
            2: "Depósito",
            3: "Transferencia"
        }



        
        const columns = [
            { field: 'id', headerName: '#'},
            { field: 'fechaAlta', headerName: 'Fecha y hora'},
            { field: 'ejecutivo', headerName: 'Ejecutivo'},
            { field: 'concepto', headerName: 'Concepto'},
            { field: 'metodoPago', headerName: 'Método de pago' },
            { field: 'monto', headerName: 'Monto ($)'},
        ];

        useEffect(() => {
            getAdeudos();
        }, []);
        

        /* CONSULTAS */
        const getAdeudos = async () => {
            await Datamovil.datamovilAdeudosGet(datamovilid)
            .then(resp => {
                resp.data.boletinado = resp.data.boletinado === null ? null : (resp.data.boletinado ? 1 : 0);
                setInitialValue(resp.data);
                let items = [];
                let pagos = 0;
                let restante = 0; 
                if(resp.data.pagos.length === 0){
                    setPagado(0);
                    setPendiente(resp.data.adeudo)
                }else{
                    resp.data.pagos.forEach((item, idx) => {
                        items.push({
                            id: (idx+1),
                            datamovilAdeudosPagoId: item.datamovilAdeudosPagoId,
                            fechaAlta: getDateFormat(item.fechaAlta, ""),
                            ejecutivo: item.usuarioAltaNombre,
                            concepto: item.concepto,
                            metodoPago: metodosPagos[item.metodoPago],
                            descargable: item.metodoPago === 1 ? 1 : 0,
                            monto: formatMoney(item.monto),
                        })
                        pagos += parseFloat(item.monto);
                    });
                }

                restante = parseFloat(resp.data.adeudo) - parseFloat(pagos);
                setPagado(pagos);
                setPendiente(restante);

                setPagos({
                    ...pagos,
                    data: items
                })
            })
            .catch(err => {
                console.error(err);
            });
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            if(values.concluido){
                if(values.boletinado){
                    values.datamovilAdeudosStatusId = 30;
                }else{
                    values.datamovilAdeudosStatusId = 40;
                }
            }

            await Datamovil.datamovilAdeudosActualizar(values)
            .then(resp => {
                resp.data.boletinado = resp.data.boletinado === null ? null : (resp.data.boletinado ? 1 : 0);
                setInitialValue(resp.data);
                let items = [];
                let pagos = 0;
                let restante = 0; 
                if(resp.data.pagos.length === 0){
                    setPagado(0);
                    setPendiente(resp.data.adeudo)
                }else{
                    resp.data.pagos.forEach((item, idx) => {
                        items.push({
                            id: (idx+1),
                            datamovilAdeudosPagoId: item.datamovilAdeudosPagoId,
                            fechaAlta: getDateFormat(item.fechaAlta, ""),
                            ejecutivo: item.usuarioAltaNombre,
                            concepto: item.concepto,
                            metodoPago: metodosPagos[item.metodoPago],
                            monto: formatMoney(item.monto),
                        })
                        pagos += parseFloat(item.monto);
                    });
                }

                restante = parseFloat(resp.data.adeudo) - parseFloat(pagos);
                setPagado(pagos);
                setPendiente(restante);

                setPagos({
                    ...pagos,
                    data: items
                })
            })
            .catch(err => {
                console.error(err);
            });
        }

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        
        const formatMoney = (value) => {
            if(value === undefined){
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const resetForm = () => {
            refFormik.current.resetForm();
            setCita("");
            setInitialValue({
                ...initialValue,
                sucursalName: "",
            });
        }

        const handleDelete = async (data) => {
            let params = {
                datamovilAdeudosPagoId: data.datamovilAdeudosPagoId,
                status: 0,
            };

            await Datamovil.datamovilAdeudosPagoActualizar(params)
            .then(resp => {
                getAdeudos();
            })
            .catch(err => {

            });
        }

        const handleDownload = async (data) => {
            // - <<VARIABLE_FECHA>>
            // - <<VARIABLE_NOMBRE>>
            // - <<VARIABLE_CONTRATO>>
            // - <<VARIABLE_CANTIDAD>>
            // - <<VARIABLE_CONCEPTO>>
            // - <<VARIABLE_TELEFONO>>
            // - <<VARIABLE_DIRECCION_UBICACION>>


            // console.log(data);
            // return false;
            const requestBody = {
                nombrePlantilla: "Recibo",
                parametros: {
                    "<<VARIABLE_FECHA>>": data.fechaAlta,
                    "<<VARIABLE_NOMBRE>>": data.ejecutivo === null ? "" : data.ejecutivo,
                    "<<VARIABLE_NOMBRE_USUARIO>>": data.ejecutivo === null ? "" : data.ejecutivo,
                    "<<VARIABLE_CONTRATO>": numContrato,
                    "<<VARIABLE_CANTIDAD>>": data.monto,
                    "<<VARIABLE_CONCEPTO>>": data.concepto,
                    "<<VARIABLE_TELEFONO>>": "",
                    "<<VARIABLE_DIRECCION_UBICACION>>": "",
                }
            };

            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            }).catch(err => {
                console.error(err);
            });
        };

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <PagoDatamovilModal
                            modalMessage={modalMessage}
                            handleActualizar={getAdeudos}
                            status={initialValue.datamovilAdeudosStatusId}
                            datamovilId={initialValue.datamovilId}
                            adeudo={pendiente}
                            adeudoId={initialValue.datamovilAdeudosId}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">
                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapsePagoAdeudos" role="button" aria-expanded="false" aria-controls="collapsePagoAdeudos" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Pago de adeudos</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilAdeudosStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapsePagoAdeudos">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Seguimiento de pagos</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button 
                                                                    className='btn'
                                                                    disabled={pendiente === 0}
                                                                    onClick={() => setModalMessage({ isOpen: true, type: 3, title: 'Registrar pago' })}
                                                                    >
                                                                    Registrar pago
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button className='btn btn-gray'>
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    disabled={initialValue.DatamovilAdeudosStatusId > 20}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-5" style={{'backgroundColor': '#F5F5F5'}}>
                                                        <div className="row">
                                                            <div className="col-4 py-2">
                                                                <small className='mb-4'><strong>Adeudo ($)</strong></small>
                                                                <h4 className='mt-2'>{formatMoney(initialValue.adeudo)}</h4>
                                                            </div>
                                                            <div className="col-4 py-2" style={{'color': '#47A066'}}>
                                                                <small className='mb-4'><strong>Pagado ($)</strong></small>
                                                                <h4 className='mt-2'>{formatMoney(pagado)}</h4>
                                                            </div>
                                                            <div className="col-4 py-2" style={{'color': '#CE2222'}}>
                                                                <small className='mb-4'><strong>Pendiente ($)</strong></small>
                                                                <h4 className='mt-2'>{formatMoney(pendiente)}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                                <div className="row px-4">
                                                    {/* {
                                                        initialValue.datamovilAdeudosStatusId === 20 &&
                                                        <div className="col-12">
                                                            <DataTable
                                                                column={columns}
                                                                data={pagos.data}
                                                                handleDownload={handleDownload}
                                                                downloable={true}
                                                                // editable={true}
                                                                deleteable={true}
                                                                handleDelete={handleDelete}
                                                                // pageCount={0}
                                                                // currentPage={1 - 1}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        initialValue.datamovilAdeudosStatusId > 20 && */}
                                                        <div className="col-12">
                                                            <DataTable
                                                                column={columns}
                                                                data={pagos.data}
                                                                handleDownload={handleDownload}
                                                                downloable={true}
                                                                // editable={true}
                                                                // deleteable={true}
                                                                // pageCount={0}
                                                                // currentPage={1 - 1}
                                                            />
                                                        </div>
                                                    {/* } */}
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Boletinar cliente</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="boletinado"
                                                            label="¿Boletinar cliente?"
                                                            disabled={initialValue.datamovilAdeudosStatusId > 20}
                                                            name="boletinado"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("boletinado", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                {
                                                    (initialValue.boletinado === 1 || values.boletinado === 1) &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentariosBoletinado"
                                                                label="Comentarios ejecutivo"
                                                                disabled={initialValue.datamovilAdeudosStatusId > 20}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentariosBoletinado"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentariosBoletinado", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.datamovilAdeudosStatusId > 20} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Proceso concluido</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default PagoAdeudosCard