import { useEffect, useRef, useState } from "react";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";

const DSSinAsignarHook = ()  => {
    const refFormik = useRef();
    const selectRef = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [proceso, setProceso] = useState('');
    const [tipoSolicitud, setTipoSolicitud] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [procesos, setProcesos] = useState([]);
    const [tipoSolicitudes, setTipoSolicitudes] = useState([]);

    const columns = [
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'proceso', headerName: 'Proceso', widht: '150px' },
        { field: 'ejecutivo', headerName: 'Subproceso', widht: '150px' },
        { field: 'tipoSolicitud', headerName: 'Tipo de solicitud', widht: '150px' },
        { field: 'tipoUbicacion', headerName: 'Tipo de Ubicación', widht: '150px' },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', widht: '150px' },
        { field: 'ubicacion', headerName: 'Ubicación', widht: '150px' },
    ];

    const tipoUbicaciones = [
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' }
    ];

    useEffect(() => {
        getProcesos();
        getTipoSolicitudes();
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar]);

    const getProcesos = async () => {
        // await Catalogo.getDropdownsByTable('Catalogos.Procesos')
        // .then(resp => {
        //     let items = resp.data.map(item => {
        //         return {
        //             value: item.procesoId,
        //             label: item.proceso
        //         }
        //     });
        //     setProcesos(items); 
        // })
        // .catch(err => {
        //     console.error(err);
        // });
    }

    const getTipoSolicitudes = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoSolicitud')
        .then(resp => {
            let items = resp.data.map(item => {
                return {
                    value: item.TipoSolicitudId,
                    label: item.TipoSolicitud
                }
            });
            setTipoSolicitudes(items); 
        })
        .catch(err => {
            console.error(err);
        });
    }

    const getListado = async () => {
        setLoading(true);
        const params = { 
            buscar, 
            proceso, 
            tipoSolicitud, 
            tipoUbicacion, 
            page, 
            rows 
        };
        
        await DSAsignaciones.getSeguimientoSinAsignar(params)
        .then(resp => {
            let items = [];
            resp.data.data.forEach(item => {
                item.fechaHoraCita = `${getFormatDate(item.fechaCita)} ${item.horaCita}`;
                items.push(item);
            });
            setData(items);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getFormatDate = (fecha) => {
        return fecha.split("T")[0].split("-").reverse().join("-");
    }

    return {
        refFormik,
        selectRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setProceso,
        setTipoSolicitud,
        setTipoUbicacion,
        procesos,
        tipoSolicitudes,
        tipoUbicaciones,
        getListado
    }
}

export default DSSinAsignarHook;