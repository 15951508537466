import axios from '../../Utils/ApiService';

const SocioComercialServices = {
    getAllSocioComercial:(page,rows,etapaId,socioId,string)=> {        
        return axios.get(`/functions/GetAllVehiculosEnProcesoSC?page=${page}&Rows=${rows}&EtapaId=${etapaId}&SocioComercialId=${socioId}&Buscar=${string}`);        
    },   
    getAllSocioComercialList:()=> {        
        return axios.get(`/catalogs/GetCSSocioComercial`);
    },  
}
export default SocioComercialServices;