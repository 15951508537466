import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import { TextAreaField } from '../../Components/TextAreaField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import RegimenFiscal from './RegimenFiscal';
import Spinner from '../../Components/Loadig';
import { SelectField } from '../../Components/SelectField';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';

const TipoCalculo = ({ isOpen, setIsOpen, item, handleGetAll, action, itemTbl, modalMessage, setModalMessage, existingData }) => {
    let initial = {
        tipoCalculoId: item?.tipoCalculoId ?? "",
        tipo_calculoId: item?.tipo_calculoId ?? "",
        aseguradoraId: item?.aseguradoraId ?? "",
        aseguradora: item?.aseguradora ?? "",
        usoUnidadId: item?.usoUnidadId ?? "",
        usoUnidad: item?.usoUnidad ?? "",
        porcentaje: item?.porcentaje ?? "",
        monto: item?.monto ?? "",
        status: item?.status ?? true
    }
    console.log(action)
    const [initialValue, setInitialValue] = useState(initial);
    const [DataAseguradora, setDataAseguradora] = useState([]);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([]);
    const [TipoCalculo, setTipoCalculo] = useState([]);
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        tipo_calculoId: Yup.string()
            .required("El campo es requerido."),
        aseguradoraId: Yup.string()
            .required("El campo es requerido."),
        usoUnidadId: Yup.string()
            .required("El campo es requerido."),
        monto: Yup.number()
            .required("El campo es requerido."),
        porcentaje: Yup.number().test({
            name: 'is-sku',
            skipAbsent: true,
            test(value, ctx) {

                if (value < 0) {
                    return ctx.createError({ message: 'ingrese un valor positivo ' })
                }
                if (value > 100) {
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 100' })
                }
                return true
            }
        })
    });

    useEffect(() => {
        handleGetAllTipoCalculo();
        handleGetAllAseguradora();
        handleGetAllUsoUnidad();
    }, [])

    const handleGetAllAseguradora = async () => {
        await SeguroPolizaService.getAllAseguradora()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.aseguradoraId,
                        label: item.aseguradora
                    })
                });
                setDataAseguradora(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllTipoCalculo = async () => {        
        await Catalogo.getDropdownsByTable('Catalogos.TiposDeCalculos')
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Seleccione"
                })
                res.data?.map(item => {
                    NewData.push({
                        value: item.Tipo_calculoId,
                        label: item.Nombre                  
                    })
                });
                setTipoCalculo(NewData);
            }).catch(e => {                
            })
    }
    const [errorMessage, setErrorMessage] = useState("");

    const handleAddEdit = async (data) => {
        if (action === "edit") {
            await Catalogo.updateTipoCalculo(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else if (action === 'add') {
            if (existingData.find(x=>
                x.tipo_calculoId === data.tipo_calculoId &&
                x.aseguradoraId === data.aseguradoraId &&
                x.usoUnidadId === data.usoUnidadId &&
                x.porcentaje === data.porcentaje &&
                x.monto === data.monto)) {
                setErrorMessage("El tipo de cálculo ya existe.");
                return;
            }
            
            await Catalogo.addTipoCalculo(data)
                .then(res => {
                    setIsOpen(false);
                    handleGetAll(1);
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }
    }

    // const removeMoneyFormat = (value, fn) => {
    //     let monto = value.replace(/[$,]/g, '');
    //     fn('montoId', monto);
    // }

    // const setMoneyFormat = (value, fn) => {
    //     if(value === undefined || value === '' || value === null){
    //         value = 0;
    //     }
    //     let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    //     console.log(money);
    //     fn('montoId', money);
    // }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, errors, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className='d-flex flex-column fs-6'>
                                    <div className='d-flex justify-content-between'>
                                        <span className=''><strong>Tabla #{itemTbl.tablaId} {itemTbl.nombreTabla} {itemTbl.nombreProceso}</strong></span>
                                    </div>
                                </div>
                                <div className="mb-3 row my-4 d-flex">
                                    <div className="mb-3">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Estatus</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="status" />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-6">
                                            <SelectField id="tipo_calculoId" label="Tipo calculo" name="tipo_calculoId" items={TipoCalculo} onChange={(event) => {
                                                setFieldValue("tipo_calculoId", event.value);
                                            }} />
                                        </div>
                                        <div className="col-sm-6">
                                            <SelectField id="AseguradoraId" label="Aseguradora" name="aseguradoraId" items={DataAseguradora} onChange={(event) => {
                                                setFieldValue("aseguradoraId", event.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-6">
                                            <SelectField id="usoUnidadId" label="Uso de unidad" name="usoUnidadId" items={DataUsoUnidad} onChange={(event) => {
                                                setFieldValue("usoUnidadId", event.value);
                                            }} />
                                        </div>
                                        <div className="col-sm-6">
                                            <TextField id="porcentajeId" label="Porcentaje" name="porcentaje" type="number" holder="Escribe" />
                                        </div>
                                        <div className="col-sm-6">
                                            <TextField id="montoId" label="Monto" name="monto" type="number" holder="Escribe" 
                                            // onClick={(event) => removeMoneyFormat(event.target.value, setFieldValue)} 
                                            // onBlur={(event) => setMoneyFormat(event.target.value, setFieldValue)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <span className="text-danger">{errorMessage}</span>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button className='btn mx-2 px-5' type='submit' disabled={!(isValid)}>Continuar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}
export default TipoCalculo