import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';

const useSeguroAutorizadoPoliza = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'solicitud', headerName: 'No. Solicitud', width: 150 },
        { field: 'usoUnidad', headerName: 'Uso unidad', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        // { field: 'fechaUltimaCotizacion', headerName: 'Fecha ultima cotización', width: 250 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoCompra, settipoCompra] = useState([]);
    const [TipoCompra, setTipoCompra] = useState("")
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    //Filtro
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveAÑo, setClaveAÑo] = useState("");
    const [DataUsoUnidad, setDataUsoUnidad] = useState([])
    const [claveUsoUnidad, setClaveUsoUnidad] = useState("");
    const [pageRows, setpageRows] = useState(10);
    const [IdsEliminar, setIdsEliminar] = useState([])
    const [item, setItem] = useState(null);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, TipoCompra, claveUsoUnidad, pageRows])
    useEffect(() => {
        handleGetTipoCompra();
        handleGetAllUsoUnidad()
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await SeguroPolizaService.getAllPolizaSeguroAutorizado(page, pageRows, str, claveUsoUnidad, TipoCompra)
            .then(res => {
                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleUploadFile = async () => {
        if (item !== null) {
            navigate(`/seguro/vehiculo/datadocs/${item.generalId}`, {
                state: { ...item, emisionPoliza: true },
            });
          } else {                        
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Seleccione un registro para continuar.")}</div>);
          }
       
    }
    const handleGetAllUsoUnidad = async () => {
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {

        navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra
    }
}

export default useSeguroAutorizadoPoliza