import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Citas from '../../../Services/Cartera/Citas';
// import SubmenuCitasAgendadas from './SubmenuCitasAgendadas';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Formik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import Garantias from '../../../Services/Cartera/Garantias';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';

const Devoluciones = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [ubicacionId, setUbicacionId] = useState("");
    const [sucursales, setSucursales] = useState([]);
    let initial = {
        ubicacionId: "",
        buscar: "",
        fechaInicio: "",
        fechaFin: "",
        page: 1,
        rows: 10
    }

    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'folioGarantia', headerName: 'Folio Garantia', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'marca', headerName: 'Marca', width: 150 },
        { field: 'modelo', headerName: 'Modelo', width: 150 },
        { field: 'año', headerName: 'Año', width: 150 },
        { field: 'estatus', headerName: 'Estatus', width: 350 },
        { field: 'subestatus', headerName: 'Subestatus', width: 150 },
        { field: 'fechaAlta', headerName: 'Fecha', width: 150 },
    ];

    useEffect(() => {
        handleGetSucursal();
    }, []);


    const [currenPage, setCurrenPage] = useState(1);

    const handleGetSucursal = async () => {
        await Catalogo.getAllUbicacion(data?.currentPage, 10)
            .then(resp => {
                let listSucursales = [];
                resp.data?.data.forEach(item => {
                    listSucursales.push({
                        value: item.ubicacionId,
                        label: item.nombre
                    });
                });

                // setCurrenPage(data?.currentPage - 1);
                setSucursales(listSucursales);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }

    const SeguimientoGarantias = async (values = initialValue, page = 1) => {
        values.page = page;
        setLoading(true);
        await Garantias.seguimientoCambioDevoluciones(values)
        .then(resp => {
            let tmpData = [];
            let tabsName = ['Todo'];
            resp.data.data.forEach(data => {
                if(!tabsName.includes(data.estatus) && data.estatus !== ''){
                    tabsName.push(data.estatus);
                }
                
                tmpData.push({
                    totalRows: data.totalRows,
                    folioGarantia: data.folioGarantia,
                    contrato: data.contrato,
                    vin: data.vin,
                    marca: data.marca,
                    modelo: data.modelo,
                    año: data.año,
                    llamadaId: data.llamadaId,
                    estatus: data.estatus,
                    subestatus: data.subestatus,
                    fechaAlta: dateFormat(data.fechaAlta)
                })
            });
            setTabs(tabsName);
            resp.data.data = tmpData;
            setData(resp.data);
            setDataToShow(resp.data);
            

            let filter = [];
            tabsName.forEach(tab => {
                if(tab.toLowerCase() === 'todo'){
                    filter['Todo'] = resp.data.data;
                }else{
                    if(!filter[tab]){
                        filter[tab] = resp.data.data.filter((item) => item.estatus === tab);
                    }
                }
            })
            setTabInfo(filter);

        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => setLoading(false));
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear()+" "+("0"+dateBD.getHours()).slice(-2)+":"+("0"+dateBD.getMinutes()).slice(-2)+" hrs.";
        return formattedDate;
    }
    
    const handleEdit = (item) => {
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));
        navigate(`/garantias/devolucioninfo/${item.vin}/${item.llamadaId}`);
    }

    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page
        });
        setCurrenPage(page);
        setPagina(page);
        SeguimientoGarantias(initialValue, page);
    };

    const getCounter = (tab) => {
        return tabInfo[tab]?.length || 0;
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BasicModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        {/* <SubmenuCitasAgendadas /> */}
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <strong className='mt-2'>Pendiente de cambio o devolución</strong>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-3 mt-2 mr-1 inner-addon right-addon">
                                            {/* <i className="glyphicon fas fa-search"></i> */}
                                            {/* <input type="text" className="form-control mb-4" id="buscar" name="buscar" placeholder="Buscar" onChange={(event) => {
                                                setFieldValue("buscar", event.value);
                                                // handleBuscar(event.value, values);
                                            }}/> */}
                                            <TextField id="buscar" label="Buscar:" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        {/* <div className="col-12 col-md-3 mt-2 mr-1">
                                            <SelectField id="ubicacionId" label="Tipo:" name="ubicacionId" items={sucursales} onChange={(event) => {
                                                setFieldValue("ubicacionId", event.value);
                                            }} />
                                        </div> */}
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaInicio" label="Fecha inicial:" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2 mt-2 mr-1">
                                            <TextField id="fechaFin" label="Fecha final:" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-auto mt-4 mr-1">
                                            <button className='btn btn-blue' onClick={(e) => SeguimientoGarantias(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {
                                                tabs.map((tab, idx) => {
                                                    return <li className="nav-item" key={'tab_'+idx} role="presentation">
                                                        <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        {
                                            tabIndex !== 0 && dataToShow.length > 0 &&
                                            <DataTable
                                                column={columns}
                                                data={dataToShow}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                        {
                                            tabIndex === 0 &&
                                            <DataTable
                                                column={columns}
                                                data={data?.data}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default Devoluciones;
