import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { tabActivePrealta } from '../../../Redux/Slice/navigateSlicePrealta';
import DataTable from '../../../Components/datatable/DataTable';
import SubMenuPrealta from '../Prealta/subMenuPrealta';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';
import { Normalize } from '../../../Utils/functions';

const VehiculoNumSerie = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);
    const dispatch = useDispatch();
    const refFormik = useRef();
    let initial = {
        VIN: item?.vin,
        marca: item?.nombreMarca,
        modelo: item?.nombreModelo,
        year: item?.nombreYear,
        version: item?.nombreVersion,
        condicion: item?.tipoCondicion,
        transmision: item?.transmision ?? "",

        generalId: id,
        statusDatamovilId: "",
        serieDatamovil: "",
        status: true,
        statusDatamovil: ""
    }
    const columns = [
        { field: 'statusDatamovil', headerName: 'Status', width: 200 },
        { field: 'numSerie', headerName: 'No. Serie', width: 200 },
        { field: 'fecha', headerName: 'Fecha', width: 240 },
        { field: 'nombreUsuario', headerName: 'Modificó', width: 130 }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        statusDatamovilId: Yup.string()
            .required("El campo es requerido."),
        serieDatamovil: Yup.string()
            .required("El campo es requerido.")
            .min(8, "el campo debe contener 8 caracteres.")
            .max(8, "el campo debe contener 8 caracteres."),
    });

    const [dataStatus, setDataStatus] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [progressBar, setProgressBar] = useState(0);
    const [dataModal, setDataModal] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();

    useEffect(() => {
        handleGetAllStatusDatamovil();
        handleGetById();
        // handleChageProgress(refFormik.current.errors);
    }, [])


    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let data = res.data.data;
                let initial = {
                    VIN: item?.vin,
                    marca: item?.nombreMarca,
                    modelo: item?.nombreModelo,
                    year: item?.nombreYear,
                    version: item?.nombreVersion,
                    condicion: item?.tipoCondicion,
                    transmision: item?.transmision ?? "",

                    generalId: id,
                    statusDatamovilId: data?.statusDatamovilId ?? "",
                    serieDatamovil: data?.serieDatamovil ?? "",
                    statusDatamovil: data?.statusDatamovil ?? ""
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllStatusDatamovil = async () => {
        await VehiculoService.getAllStatusDatamovil()
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    if (item.status && Normalize(item.statusDatamovil) !== "contrato") {
                        NewData.push({
                            value: item.statusDatamovilId,
                            label: item.statusDatamovil
                        })
                    }

                });
                setDataStatus(NewData);
            }).catch(e => {
            })
    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VehiculoService.getAllHistoryDataMovil(id, page, 10)
            .then(res => {

                setDataModal(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleAddEdit = async (value) => {
        setLoading(true);
        await VentaVehiculoService.addStatusDatamovil(value)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                handleGetById();
                setLoading(false);
                setTimeout(() => {
                    navigate("/inventario/vehiculo");
                }, 2000);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 2;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const message = (title, message) => <p style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></p>
    const status = (dataStatus) => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    {/* <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate('/inventario/vehiculo') }}></i>
                        Regresar a Listado general de Vehículo
                    </header> */}
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        {/* <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        /> */}

                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <span className='fs-5'><strong>Información del vehiculo</strong></span>
                                        <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.NumSerieDatamovilSection, AccionesRapidas.NumSerieDatamovil))}>
                                            <div className='d-flex'>
                                                <fieldset type="button" className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status(values.statusDatamovil)}{values.statusDatamovil === "" ? "Sin dispositivo" : values.statusDatamovil}</fieldset>
                                                <button className='btn mx-2' type="submit" >{id == 0 ? "Guardar" : "Editar"}</button>
                                            </div>
                                        </ValidatePermission>
                                        {/* disabled={!(isValid)} */}
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.NumSerieDatamovilSection, AccionesRapidas.NumSerieDatamovil).Ver}>
                                        <div className='my-2 mt-4'>
                                            <p> <strong>Número datamovil</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Numero de serie:" name="serieDatamovil" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("serieDatamovil", event.target.value);
                                                        // handleChageProgress(errors)
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Estatus:" name="statusDatamovilId" items={dataStatus} onChange={(event) => {
                                                        setFieldValue("statusDatamovilId", event.value);
                                                        // handleChageProgress(errors)
                                                    }} />
                                                </div>
                                                <ValidatePermission isActive={ValidatePermissionById(4, Section.NumSerieDatamovilSection, AccionesRapidas.NumSerieDatamovil).Historial}>
                                                    <div className="col-sm-1">
                                                        <label className='text'></label>
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                                    </div>
                                                </ValidatePermission>

                                            </div>
                                        </div>
                                    </ValidatePermission>
                                </Form>

                            </div>

                        </section>
                    </div>
                    <Modal isOpen={isOpenModal} color='#fff'>
                        <div className='d-flex justify-content-center'>
                            <span>  <strong>Historico de status numero datamovil</strong></span>

                        </div>
                        <DataTable
                            column={columns}
                            data={dataModal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            currentPage={currenPage - 1}
                        />
                        <section className='my-3 d-flex justify-content-end mt-4'>
                            <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false); }}>Cancelar</fieldset>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                </>
            )
            }
        </Formik>
    )
}

export default VehiculoNumSerie