import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import IntelimotorAPI from '../../Services/Catalogos/Intelimotor';
import useModal from '../Modal/useModal';
import SyncInventarioService from '../../Services/SyncInventario/SyncInventarioService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import { useRef } from 'react';
import { current } from '@reduxjs/toolkit';

const useSyncInventarioDataWare = () => {
  let ids = [];
  const refBtnExp = useRef();
  const navigate = useNavigate();
  const [isOpen, openModal, closeModal] = useModal();
  const [isOpenEdit, openModaEdit, closeModalEdit] = useModal();
  const columns = [
    { field: 'fechaAlta', headerName: 'Fecha y hora', width: 230 },
    { field: 'vin', headerName: 'VIN', width: 230 },
    { field: 'nombreMarca', headerName: 'Marca', width: 200 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 200 },
    { field: 'nombreVersion', headerName: 'Versión', width: 230 },
    // { field: 'resultado', headerName: 'Resultado', width: 230 },
    { field: 'descripcion', headerName: 'Error registrado', width: 330 },
  ];
  const filtro = [
    { label: "Todos", value: "" },
    { label: "Completado con exito", value: 1 },
    { label: "Completado con errores", value: 0 },
  ]
 
  let text = ["Iniciando proceso descarga...", "Seguimos trabajando...", "Espera un poco, un poquito mas...", "no actualices la pagina...", "ya casi esta listo..."]
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [message, setMessage] = useState("");
  const [initial, setInitial] = useState(0);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
  const [valueIntelimotor, setValueIntelimotor] = useState("");
  const [valueIntelimotor1, setValueIntelimotor1] = useState("");
  const [newParamValue, setNewParamValue] = useState("");

  const [txt, setTxt] = useState("");
  const [fecha, setFecha] = useState("");
  const [filtroStatus, setFiltroStatus] = useState("");
  const [idsEliminar, setIdsEliminar] = useState([]);
  const [resultado, setResultado] = useState("")
  const [pageRows, setpageRows] = useState(10)

  //Filtro
  const [DataMarca, setDataMarca] = useState([])
  const [DataModelo, setDataModelo] = useState([])
  const [claveMarca, setClaveMarca] = useState("");
  const [claveModelo, setClaveModelo] = useState("");

  useEffect(() => {
    handleGetAll(currentPage, pageRows);
  }, [claveMarca, claveModelo, txt, fecha, resultado,pageRows])

  useEffect(() => {
    handleGetAllMarca()
  }, [])

  useEffect(() => {
    setMessage(text[initial]);
  }, [initial])
  const handleclickEvent = () => {
    // 
    // setMessage(text[initial]);
    setInitial(prevCount => prevCount + 1);
  }
  const handleGetParameters = async () => {
    await IntelimotorAPI.getParameterIntelimotor("intelimotor")
      .then(res => {
        setValueIntelimotor(res.data.data[1].valor);
        setValueIntelimotor1(res.data.data[1].valor);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetAll = async (page, rows) => {
    setLoading(true);
    await SyncInventarioService.getAllDataware(page, rows, fecha, filtroStatus, claveMarca, claveModelo, txt)
      .then(res => {        
        setData(res.data.data);
        // setData(dataS);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleGetAllMarca = async () => {
    await VehiculoService.getAllMarca()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveMarca,
            label: item.nombreMarca
          })
        });
        setDataMarca(NewData);
      }).catch(e => {
      })
  }
  const handleChangeMarca = async (value) => {
    setClaveMarca(value);
    await VehiculoService.getAllModeloById(value)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.claveModelo,
            label: item.nombreModelo
          })
        });
        setDataModelo(NewData);
      }).catch(e => {
      })
  }

  const handleDetail = (item) => {
    navigate(`/admin/inventario/intelimotor/${item.syncsId}`, {
      state: {
        page: 'intelimotor',
        title: "title"
      },
    });
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    handleGetAll(event.selected + 1, pageRows);
  };

  const handleUpdateParameters = async () => {
    await IntelimotorAPI.updateParameterIntelimotor(newParamValue)
      .then(res => {
        closeModalEdit();
        setValueIntelimotor(newParamValue);
        setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "la hora se a actualizado con éxito." })
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }
  const handleChangeValueParam = (value) => {
    setNewParamValue(value);
    setValueIntelimotor1(value)
  }
  const handleClick = async () => {
    refBtnExp.current.disabled = true;
    setLoading(true);
    const inter = setInterval(() => {
      setInitial((prevCounter) => prevCounter + 1);
    }, 120000);    
    if (idsEliminar.length > 0) {
      await SyncInventarioService.saveIntelimotor({ ids: idsEliminar })
        .then(res => {
          handleUnchekedColumns();
          handleGetAll(1,10);
          setLoading(false);
          refBtnExp.current.disabled = false;
        }).catch(e => {
          refBtnExp.current.disabled = false;
          setLoading(false);
          if (e.response !== undefined)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
          else
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })

    } else {
      setLoading(false);
      refBtnExp.current.disabled = false;
      setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: "Seleccione algunos registros a exportar" })
    }

  }
  const handleUnchekedColumns = () => {
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      element.firstChild.firstChild.checked = false;
    }
  }
  const handleCheck = (cheked, value) => {
    if (cheked) {
      ids.push({ generalId: value.generalId });
      setIdsEliminar(current => [...current, { generalId: value.generalId }]);
    } else {
      setIdsEliminar(idsEliminar.filter(item => item.generalId !== value.generalId));
    }
  }

  return {
    columns,
    data,
    pageCount,
    loading,
    handlePageClick,
    handleDetail,
    handleClick,
    isOpenEdit,
    closeModalEdit,
    message,
    loadingReq,
    modalMessage,
    setModalMessage,
    handleUpdateParameters,
    handleChangeValueParam,
    valueIntelimotor1,
    filtro,
    setTxt,
    setFecha,
    DataMarca,
    DataModelo,
    handleChangeMarca,
    setClaveModelo,
    handleCheck,
    refBtnExp,
    setResultado,
    setpageRows
  }
}

export default useSyncInventarioDataWare