import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';

const useDataDocs = () => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'nombreMarca', headerName: 'Marca', width: 160 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
    { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
    { field: 'etapaActual', headerName: 'Etapa', width: 150 },
    { field: 'fecha', headerName: 'Fecha', width: 250,short:true},
    { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 320 },
  ];
  let DataFiltroFolder = [
    {label:"Todos",value:""},
    {label:"Vehículos con folder",value:1},
    {label:"Vehículos sin folder",value:0},
  ];
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [tipoAd, setTipoAd] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
  //DataDocs
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [dataVehiculo, setDataVehiculo] = useState(null);
  const [filtroDocs, setfiltroDocs] = useState("");
  const [pageRows, setpageRows] = useState(10)
  //
  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [tipoAd, str,filtroDocs,pageRows])
  useEffect(() => {
    handleGetTipoAdquisicio();
  }, [])


  const habdleGetAll = async (page,orden = "") => {
    setLoading(true);
    await VentaVehiculoService.getlAllVehiculo(page, pageRows, tipoAd, str,filtroDocs,"",orden)
      .then(res => {
        
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetTipoAdquisicio = async () => {
    await Catalogo.getdataTabla("TipoCompra", 1, 100)
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        setDataTipoAdquisicion(NewData);
      }).catch(e => {

      })
  }
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleEdit = (value) => {
    if (value.etapaId === 1) {
      navigate(`/venta/vehiculo/prealta/${value.generalId}`, {
        state: {
          page: 'Vehiculo',
          title: "editar vehiculo",
          etapa: value.etapaId
        },
      });
    } else if (value.etapaId === 2) {
      navigate(`/venta/vehiculo/alta/${value.generalId}`, {
        state: {
          page: 'Vehiculo',
          title: "edit",
          etapa: value.etapaId
        },
      });
    } else if (value.etapaId === 3) {
      navigate(`/venta/vehiculo/valoracion/${value.generalId}`, {
        state: {
          page: 'Vehiculo',
          title: "edit",
          etapa: value.etapaId
        },
      });
    }
  }
  const handleCreateFolder = (item) => {
    
    setDataVehiculo(item);
    setIsOpenDocs(true)
  }
  const handleSort = (value) =>{
    
    habdleGetAll(1,value);
  }
  return {
    columns,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    DataFiltroFolder,
    setfiltroDocs,
    habdleGetAll,
    handleSort,
    setpageRows
  }
}

export default useDataDocs