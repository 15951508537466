import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const navigaSliceParam = createSlice({
  name: 'paramNavigate',
  initialState,
  reducers: {
    tabActiveParam: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveParam } = navigaSliceParam.actions

export default navigaSliceParam.reducer