import React from "react"
import SubMenuSeguimientoTelefonico from "./SubMenuSeguimiento"
import PromesaPagoHook from "../../../Hooks/SeguimientoTelefonico/PromesasPagoHook"
import RowPages from '../../../Components/RowPages'
import DataTable from "../../../Components/datatable/DataTable"
import Select from 'react-select'

import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermissionById } from '../../../Auth/ValidatePermission'

const PromesasPago = () => {

  const {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    promesasStatusSelect,
    setPromesasStatus
  } = PromesaPagoHook()

  return (
    <>
      <SubMenuSeguimientoTelefonico />
      <div className="d-flex mt-4" >
        <div className="col-lg-2 col-md-6 col-sm-12  mx-4 inner-addon right-addon">
          <i className="glyphicon fas fa-search"></i>
          <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setName(e.target.value) }} style={{ width: '250px' }} />
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12" style={{ width: '250px' }}>
          <Select 
          options={promesasStatusSelect} 
          className='col-12' 
          onChange={(e) => { setPromesasStatus(e.label) }} 
          placeholder="Estatus Promesa" />
        </div>
      </div>
      <div className='mx-4'>
        <DataTable
          column={columns}
          data={currentData}
          detailable={ValidatePermissionById(4, Section.Seguimientotelefonico, SubModule.SeguimientoTelefonico).Historial}
          handleDetail={handleDetails}
          paginate={true}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage - 1}
          paginateResult={true}
          setpageRows={setpageRows}
        />
      </div>
    </>
  )
}

export default PromesasPago