import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveEndoso } from '../../../Redux/Slice/navigateSliceEndoso';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubMenuEndoso = () => {
  const { value } = useSelector((state) => state.tabNavigateSliceEndoso);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveEndoso(value));
  }
  const handleVigate = (value) => {
    switch (value) {
      case (ValidatePermissionById(3, Section.PendientesDeEndoso) && 1):
        navigate(`/inventario/endoso/pendienteendoso`);
        break;
      case (ValidatePermissionById(3, Section.EndosoValidado) && 2):
        navigate(`/inventario/endoso/endosovalido`);
        break;
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.PendientesDeEndoso)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Pendientes de endoso</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.EndosoValidado)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Endoso validado</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuEndoso