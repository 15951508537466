import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevolucion from '../../../Hooks/Garantias/useDevolucion';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Garantias from '../../../Services/Cartera/Garantias';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Cierres from '../../../Services/Resguardos/Cierres';
import ArmadoExpedienteCard from './ArmadoExpedienteCard';
import CitaExpedienteCard from './CitaExpedienteCard';

const CambioPropietarioCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, cierreid } = useParams();

        let initial = {
            cierreContratoCambioPropietarioId: "",
            cierreContratoId: "",
            cierreContratoCambioPropietarioStatusId: 0,
            cierreContratoCambioPropietarioStatus: "",
            color: "default",
            usuarioCambio: "",
            comentarios: "",
            concluido: false,
            usuarioAlta: "",
            usuarioUpd: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            status: true,
            fechaAlta: "",
            fechaActualiza: "",
            fechaConclusion: null
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        useEffect(() => {
            getCambioPropietario();
        }, []);

        /* CONSULTAS */
        const getCambioPropietario = async () => {
            await Cierres.cierreContratoCambioPropietarioGet(cierreid)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values) => {
            if(values.concluido){
                values.cierreContratoCambioPropietarioStatusId = 30;
            }else{
                values.cierreContratoCambioPropietarioStatusId = 20;
            }

            await Cierres.cierreContratoCambioPropietarioActualizar(values)
            .then(resp => {
                setInitialValue(resp.data);
                toast.success(message("Se actualizó el cambio de propietario correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar actualizar el cambio de propietario!"));
            })
            .finally(() => {
                // if(values.concluido){
                //     crearCitaEntrega();
                // }
                setLoading(false);
            });
        }

        const crearCitaEntrega = async () => {
            let params = {
                cierreContratoId : cierreid,
                cierreContratoCitaEntregaExpedienteStatusId: 10,
                status: true
            }

            await Cierres.cierreContatoCitaEntregaExpedienteCrear(params)
            .then(resp => {
                toast.success(message("Se inició la cita de entrega de expediente correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la cita de entrega de expediente!"));
                console.error(err);
            });
        };
        

        const validate = Yup.object({});

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                {
                    <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                        <div className='row mx-md-4 my-4' >
                            <div className="col-12 px-md-3">
                                <div className="p-4 white-wrapper mb-2">

                                    <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapsePropietario" role="button" aria-expanded="false" aria-controls="collapsePropietario" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                        <div className="col-11">
                                            <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cambio de propietario</strong></h6>
                                        </div>
                                        <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                            <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                        </div>
                                        <div className='col-12'>
                                            {/* <small style={{ marginLeft: '17px', marginTop: '0px' }}>{initialValue.reparacionStatus}</small> */}
                                            <small>{initialValue.cierreContratoCambioPropietarioStatus}</small>
                                        </div>
                                    </div>
                                    <div className="collapse" id="collapsePropietario">
                                        <Formik
                                            innerRef={refFormik}
                                            enableReinitialize={true}
                                            validationSchema={validate}
                                            initialValues={initialValue}
                                        >
                                            {({ isValid, values, setFieldValue, errors }) => (
                                                <>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <small><strong>Cambio de propietario</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray'>
                                                                        <i className="icon-blue fa fa-file"></i> Subir documento
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.cierreContratoCambioPropietarioStatusId === 30}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField id="usuarioCambio" disabled={initialValue.cierreContratoCambioPropietarioStatusId === 30} label="Usuario" name="usuarioCambio" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("usuarioCambio", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios"
                                                                disabled={initialValue.cierreContratoCambioPropietarioStatusId === 30}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.cierreContratoCambioPropietarioStatusId === 30} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cambio de propietario y documentación finalizados</label>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaAlta !== "" &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaConclusion !== "" && initialValue.concluido &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    })
export default CambioPropietarioCard