import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../Components/datatable/DataTable";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";

const SolicitudTramiteExpediente = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [solicitudes, setSolicitudes] = useState([]);

    const columns = [
        { field: 'area', headerName: 'Área' },
        { field: 'usuarioSolicito', headerName: 'Solicitado por' },
        { field: 'fechaSolicito', headerName: 'Fecha de Solicitud' },
        { field: 'operacion', headerName: 'Estatus' },
        { field: 'as', headerName: 'Acciones' },
    ];

    useEffect(() => {
        getSolicitudes();
    }, []);

    const getSolicitudes = async () => {
        await ServicioExpedientes.getSolicitudes(state?.generalId)
        .then(resp => {
            const items = resp.data.map(item => {
                item.fechaSolicito = getOnlyDateFormat(item.fechaSolicito);
                return item;
            })

            setSolicitudes(items);
        })
        .catch(err => {
            
        });
    }

    const getOnlyDateFormat = (date) => {
        if(date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseSolicitudTramite" role="button" aria-expanded="false" aria-controls="collapseSolicitudTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Solicitud de trámite</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseSolicitudTramite">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12">
                                        <DataTable
                                            column={columns}
                                            data={solicitudes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SolicitudTramiteExpediente;