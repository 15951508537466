import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import Llamadas from '../../../Services/Cartera/Llamadas'
import { SelectField } from '../../../Components/SelectField';
import { AddDays_CurrentDate } from '../../../Utils/functions';
import IntelimotorAPI from '../../../Services/Catalogos/Intelimotor';

const ModalConfirmEntrega = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();
    let initial = {
        filename: item.filename,
        legibilidad: false,
        generalId: item?.generalId ?? "",
        kilometraje: "",
        folioCarnet: "",
        folioGarantia: "",
        folioCartaEntrega: "",
        fechaEntrega: AddDays_CurrentDate(1),
        tallerAutorizadoId: "",
        contrato:item.contrato ?? "",
        sol_id: item?.sol_id ?? "",
        vin: item?.vin ?? "",
        id_datamovil: item?.id_datamovil ?? "",
        status_datamovil: item?.status_datamovil ?? "",        
        intelimotorId: item?.intelimotorId,
        ventaContado:item?.contado
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataTalllerAutorizado, setDataTalllerAutorizado] = useState([]);
    const [maxDate, setMaxDate] = useState("")

    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
        kilometraje: Yup.string()
            .required("El campo es requerido."),
        folioGarantia: Yup.string()
            .required("El campo es requerido."),
        folioCarnet: Yup.string()
            .required("El campo es requerido."),
        folioCartaEntrega: Yup.string()
            .required("El campo es requerido."),
        tallerAutorizadoId: Yup.string()
            .required("El campo es requerido."),
        fechaEntrega: Yup.date().test({
            name: 'is-Sunday',
            skipAbsent: true,
            test(value, ctx) {
                if (value.getDay() === 0) {
                    return ctx.createError({ message: 'Seleccione una fecha diferente al dia domingo.' })
                }
                return true
            }
        })
    });

    useEffect(() => {
        handleGetAllCSTallerAutorizado();
        handleGetParameters();
    }, [])

    const handleGetParameters = async () => {
        await IntelimotorAPI.getValorParametro("DiasFechaEntrega")
            .then(res => {
                let date = AddDays_CurrentDate(res.data.data.valor);
                setMaxDate(date);
            }).catch(e => {
            })
    }
    const handleGetAllCSTallerAutorizado = async () => {
        await Llamadas.getTalleresSlice()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tallerId,
                        label: item.taller
                    })
                });
                setDataTalllerAutorizado(NewData);
            })
            .catch(e => {
                console.log(e)
            })

    }
    const handleAddEdit = async (item) => {
        submit(item);

        // setIsOpen(false);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);                
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons' style={{ width: '40rem' }}>
                        <h5><strong>Completa la carga de archivo</strong></h5>

                        <Form>
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2 d-flex justify-content-between">
                                    <div>
                                        <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                        <span className='ms-2'>  El documento es legible</span>
                                    </div>
                                </div>
                                <label style={{ fontSize: '14px' }}><strong>2. Detallle de entrega</strong></label>
                                <span style={{ fontSize: '13px' }}>
                                    Captura los siguientes datos y recuerda validar la fecha de entrega.
                                </span>
                                <div className="mb-3 row col-12 my-2">
                                    <div className="col-md-6 col-sm-12">
                                        <TextField id="tarjetaCirculacion" label="Kilometraje de Entrega" name="kilometraje" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <TextField id="tarjetaCirculacion" label="Folio de Carnet" name="folioCarnet" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row my-2">
                                    <div className="col-md-6 col-sm-12">
                                        <TextField id="tarjetaCirculacion" label="Folio de Garantia" name="folioGarantia" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <TextField id="tarjetaCirculacion" label="Folio de Carta de Entrega" name="folioCartaEntrega" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row my-2">
                                    <div className="col-md-6 col-sm-12">
                                        {/* <TextField id="tarjetaCirculacion" label="Fecha de entrega" min={AddDays_CurrentDate(1)} max={maxDate} name="fechaEntrega" type="date" holder="Escribe" /> */}
                                        <TextField id="tarjetaCirculacion" label="Fecha de entrega" name="fechaEntrega" type="date" holder="Escribe" />
                                        <span style={{ fontSize: '13px', color: '#9B2D2D' }} className='d-flex align-items-center ms-1'><i className="bx bxs-error"></i><strong>Validar fecha de entrega</strong></span>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <SelectField id="tecnicoID" label="Taller Autorizado" name="tallerAutorizadoId" items={dataTalllerAutorizado} onChange={(event) => {
                                            setFieldValue("tallerAutorizadoId", event.value);
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={(!values.legibilidad)}>Confirmar</button>
                            </section>
                        </Form>
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalConfirmEntrega