import { createSlice } from '@reduxjs/toolkit'

const initialState = JSON.parse(sessionStorage.getItem('UsuarioSeleccionadoSlice')) || {
   usuario: {},
   ZonasCobranza: []
 };
 
 export const UsuarioSeleccionadoSlice = createSlice({
   name: 'UsuarioSeleccionadoSlice',
   initialState,
   reducers: {
     setUsuario: (state, action) => {
       state.usuario = action.payload;
       sessionStorage.setItem('UsuarioSeleccionadoSlice', JSON.stringify(state));
     },
     setLimpiarUsuario: (state) => {
       state.usuario = {};
       sessionStorage.setItem('UsuarioSeleccionadoSlice', JSON.stringify(state));
     },
     setZonasCobranza: (state, action) => {
       state.ZonasCobranza = action.payload;
       sessionStorage.setItem('UsuarioSeleccionadoSlice', JSON.stringify(state));
     },
     setLimpiarZonasCobranza: (state) => {
       state.ZonasCobranza = [];
       sessionStorage.setItem('UsuarioSeleccionadoSlice', JSON.stringify(state));
     }
   },
 });
 
 export const {
   setUsuario,
   setLimpiarUsuario,
   setZonasCobranza,
   setLimpiarZonasCobranza
 } = UsuarioSeleccionadoSlice.actions;
 
 export default UsuarioSeleccionadoSlice.reducer;
 