import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik, useFormikContext } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import CitaDesinstalacionCard from './CitaDesinstalacionCard';
import RevisionDatamovilCard from './RevisionDatamovilCard';
import OrdenDesinstalacionModal from '../../../Components/Modal/OrdenDesinstalacionModal';

const OrdenDesinstalacionCard
    = React.forwardRef(({ ...props }, ref) => {
        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, generalid, datamovilid } = useParams();


        let initial = {
            datamovilOrdenDesinstalacionId: "",
            datamovilId: "",
            datamovilOrdenDesinstalacionStatusId: 0,
            datamovilOrdenDesinstalacionStatus: "",
            color: "default",
            autorizada: null,
            folioId: "",
            tipoOrden: "",
            motivosDesinstalacionId: null,
            motivosDesinstalacion: "",
            usuarioSolicita: "",
            tipoContacto: 0,
            agendarCita: false,
            telefonoFijo: "",
            telefonoMovil: "",
            telefonoTrabajo: "",
            comentariosEjecutivo: "",
            clienteBoletinado: false,
            comentariosBoletinado: "",
            ejecutivo: "",
            nombreEjecutivo: "",
            fechaBoletinado: "",
            concluido: false,
            fechaConclusion: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            status: false,
            fechaAlta: "",
            fechaActualiza: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const tipoContacto = [{ value: 1, label: 'Presencial' }, { value: 0, label: 'Llamada' }];
        const [motivosDesinstalacion, setMotivosDesinstalacion] = useState([]);
        const [disabledAtencion, setDisabledAtencion] = useState(false);

        useEffect(() => {
            getMotivosDesinstalacion();
            getOrdenDesinstalacion();
        }, []);
        

        /* CONSULTAS */
        const getOrdenDesinstalacion = async () => {
            setLoading(true);
            await Datamovil.datamovilOrdenDesinstalacionGet(datamovilid)
            .then(resp => {
                resp.data.fechaAlta = resp.data.fechaAlta;
                resp.data.fechaRegistro = getDateFormat(resp.data.fechaAlta, "");
                resp.data.tipoOrdenTexto = resp.data.tipoOrden ? 'Manual' : 'Automática';
                resp.data.tipoContacto = resp.data.tipoContacto === null ? null : (resp.data.tipoContacto ? 1 : 0);
                resp.data.agendarCita = resp.data.agendarCita === null ? null : (resp.data.agendarCita ? 1 : 0);
                resp.data.autorizada = resp.data.autorizada === null ? null : (resp.data.autorizada ? 1 : 0);
                resp.data.clienteBoletinado = resp.data.clienteBoletinado === null ? null : (resp.data.clienteBoletinado ? 1 : 0);
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getMotivosDesinstalacion = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.MotivosDesinstalacion')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.MotivosDesinstalacionId, label: item.MotivosDesinstalacion })
                });
                setMotivosDesinstalacion(items);
            })
            .catch(err => {
                console.error(err);
            });
        };
        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                // format = d.getFullYear()+"-"+("0"+d.getMonth()+1).slice(-2)+"-"+("0"+d.getDate()).slice(-2)+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear()+" "+("0"+d.getHours()).slice(-2)+":"+("0"+d.getMinutes()).slice(-2)+":"+("0"+d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const submitForm = async (values, modal) => {
            if(initialValue.autorizada !== null && values.concluido && values.clienteBoletinado === 1){
                values.datamovilOrdenDesinstalacionStatusId = 60;
            }

            if(initialValue.autorizada !== null && values.concluido && values.clienteBoletinado === 0){
                values.datamovilOrdenDesinstalacionStatusId = 50;
            }

            setLoading(true);
            await Datamovil.datamovilOrdenDesinstalacionActualizar(values)
            .then(resp => {
                resp.data.fechaAlta = resp.data.fechaAlta;
                resp.data.fechaRegistro = getDateFormat(resp.data.fechaAlta, "");
                resp.data.tipoOrdenTexto = resp.data.tipoOrden ? 'Manual' : 'Automática';
                resp.data.tipoContacto = resp.data.tipoContacto === null ? null : (resp.data.tipoContacto ? 1 : 0);
                resp.data.agendarCita = resp.data.agendarCita === null ? null : (resp.data.agendarCita ? 1 : 0);
                resp.data.clienteBoletinado = resp.data.clienteBoletinado === null ? null : (resp.data.clienteBoletinado ? 1 : 0);
                setInitialValue(resp.data);
                toast.success(message("Se actualizó la orden de desinstalación correctamente"));

                if(modal === 3){
                    setModalMessage({ isOpen: true, type: 3, title: 'Cliente cumple requisitos para desinstalación' });
                }else if(modal === 4){
                    setModalMessage({ isOpen: true, type: 4, title: 'Cliente no cumple requisitos para desinstalación' });
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar la orden de desinstalación!"));
            })
            .finally(() => {
                setLoading(false);
            });
        }



        const validate = Yup.object({
            tipoContacto: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido."),
            // agendarCita: Yup.number()
            //     .when('tipoContacto', {
            //         is: 1,
            //         then: Yup.number()
            //         .typeError("El campo es requerido.")
            //         .required("El campo es requerido.")
            //     }),
            telefonoFijo: Yup.string()
                .typeError("El campo es requerido.")
                .required("El campo es requerido."),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        const setFormatPhone = (field, value, fn) => {
            let prevPhone = value.replace(/[()-]/g,'');
                let formatted = "";
            
            if(prevPhone.length < 3){
                formatted = prevPhone;  
            }else if(prevPhone.length >= 3 && prevPhone.length <= 6){
                let lada = prevPhone.substr(0,2);
                let firstSegment = prevPhone.substr(2,4);
                formatted = "("+lada+")-"+firstSegment;  
            }else if(prevPhone.length > 6){
                let lada = prevPhone.substr(0,2);
                let firstSegment = prevPhone.substr(2,4);
                let secondSegment = prevPhone.substr(6,4);
                formatted = "("+lada+")-"+firstSegment+"-"+secondSegment;  
            }

            fn(field, formatted);
        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        (modalMessage.type === 3 ||
                        modalMessage.type === 4) &&
                        <OrdenDesinstalacionModal 
                            modalMessage={modalMessage}
                            getOrdenDesinstalacion={getOrdenDesinstalacion}
                            datamovilId={datamovilid}
                            tipoOrden={initialValue.tipoOrden}
                            generalid={generalid}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseOrdenDesinstalacion" role="button" aria-expanded="false" aria-controls="collapseOrdenDesinstalacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>
                                            Orden de desinstalación 
                                            {
                                                initialValue.folioId !== "" &&
                                                <span> - {initialValue.folioId}</span>
                                            }
                                        </strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilOrdenDesinstalacionStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseOrdenDesinstalacion">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        isInitialValid={false}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        initialTouched={{
                                            tipoContacto: true,
                                            telefonoFijo: true,
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Antecedente</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {
                                                                initialValue.autorizada === null &&
                                                                <>
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn btn-gray'
                                                                            disabled={!isValid && (initialValue.tipoContacto === null && initialValue.telefonoFijo === null )}
                                                                            onClick={() => {
                                                                                submitForm(values, 4);
                                                                            }}>
                                                                            Rechazar
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid && (initialValue.tipoContacto === null && initialValue.telefonoFijo === null )}
                                                                            onClick={() => {
                                                                                submitForm(values, 3);
                                                                            }}>
                                                                            Autorizar
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/* {
                                                                (initialValue.autorizada !== null &&
                                                                initialValue.autorizada === 0) &&
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50 || (initialValue.datamovilOrdenDesinstalacionStatusId === 40 && initialValue.clienteBoletinado !== null)}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            } */}
                                                            {
                                                                (initialValue.autorizada !== null &&
                                                                initialValue.autorizada === 0) || 
                                                                values.tipoContacto === 0 && 
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50 || (initialValue.datamovilOrdenDesinstalacionStatusId === 40 && initialValue.clienteBoletinado !== null)}
                                                                        onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="tipoOrdenTexto" disabled={true} label="Tipo de orden" holder="" name="tipoOrdenTexto" type="text" onChange={(event) => {
                                                            setFieldValue("tipoOrdenTexto", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="motivosDesinstalacionId"
                                                            label="Motivo de Desinstalación"
                                                            disabled={!initialValue.tipoOrden || (initialValue.tipoOrden && initialValue.datamovilOrdenDesinstalacionStatusId >= 50)}
                                                            name="motivosDesinstalacionId"
                                                            items={motivosDesinstalacion}
                                                            onChange={(event) => {
                                                                setFieldValue("motivosDesinstalacionId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="usuarioSolicita" disabled={!initialValue.tipoOrden || (initialValue.tipoOrden && initialValue.datamovilOrdenDesinstalacionStatusId >= 50)} label="Persona que solicita la desinstalación" holder="" name="usuarioSolicita" type="text" onChange={(event) => {
                                                            setFieldValue("usuarioSolicita", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de contacto</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="tipoContacto"
                                                            label="Tipo de contacto"
                                                            disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50}
                                                            name="tipoContacto"
                                                            items={tipoContacto}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoContacto", event.value);
                                                                // forzarAtencion('agendarCita', event.value, setFieldValue);
                                                                if(event.value === 0){
                                                                    setDisabledAtencion(true);
                                                                    setFieldValue("agendarCita", 0);
                                                                }else{
                                                                    setDisabledAtencion(false);
                                                                    setFieldValue("agendarCita", null);
                                                                }
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="agendarCita"
                                                            label="¿Atención inmediata?"
                                                            disabled={disabledAtencion || initialValue.datamovilOrdenDesinstalacionStatusId >= 50}
                                                            name="agendarCita"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("agendarCita", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="telefonoFijo" disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50} maxLenght={14} label="Teléfono fijo" holder="" name="telefonoFijo" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoFijo', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="telefonoMovil" disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50} max={14} label="Teléfono móvil" holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoMovil', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="telefonoTrabajo" disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50} max={14} label="Teléfono de trabajo" holder="" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                            setFormatPhone('telefonoTrabajo', event.target.value, setFieldValue);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosEjecutivo"
                                                            label="Comentarios ejecutivo"
                                                            disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosEjecutivo"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosEjecutivo", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="usuarioAltaNombre" disabled={true} label="Ejecutivo" holder="" name="usuarioAltaNombre" type="text" onChange={(event) => {
                                                            setFieldValue("usuarioAltaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="fechaRegistro" disabled={true} label="Fecha y hora de registro" holder="" name="fechaRegistro" type="text" onChange={(event) => {
                                                            setFieldValue("fechaRegistro", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                {
                                                    (initialValue.autorizada !== null && 
                                                    !initialValue.autorizada) &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-3">
                                                                <small><strong>Boletinar cliente</strong></small>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-3">
                                                                <SelectField id="clienteBoletinado"
                                                                    label="¿Boletinar cliente?"
                                                                    disabled={initialValue.datamovilOrdenDesinstalacionStatusId >= 50}
                                                                    name="clienteBoletinado"
                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("clienteBoletinado", event.value);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-6">
                                                                <TextAreaField id="comentariosBoletinado"
                                                                    label="Comentarios ejecutivo"
                                                                    disabled={initialValue.cierreContratoCambioPropietarioStatusId >= 50}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentariosBoletinado"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentariosBoletinado", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }                                                
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 alert alert-primary" role="alert">
                                                        <div className="row">
                                                            <div className="col-1 text-center">
                                                                <i className="icon-dark bx bx-info-circle bx-sm"></i>
                                                            </div>
                                                            <div className="col-11">
                                                                Notificar al cliente: Se va a confirmar la autorización de la desinstalación y agendar la cita
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={true} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Captura concluida</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (initialValue.datamovilOrdenDesinstalacionStatusId === 70 &&
                    initialValue.agendarCita === 0) && // Atención inmediata en false para hacer cita
                    <CitaDesinstalacionCard />
                }
                {
                    (initialValue.datamovilOrdenDesinstalacionStatusId === 70 &&
                    initialValue.agendarCita === 1) && // Atención inmediata en true para pasara a revisión
                    <RevisionDatamovilCard />
                }
            </>
        )
    })
export default OrdenDesinstalacionCard