import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import ConfirmResponsabilidadModal from '../../../Components/Modal/ConfirmResponsabilidadModal';
import Garantias from '../../../Services/Cartera/Garantias';


const CardResponsabilidadCliente
    = (props) => {
        const { generalId } = props

        const { BloqueoId, FechaCita, EstatusCitaGarantia, FolioGarantia, LlamadaId, KilometrajeActual, CarnetServicioId, FolioCarnet, ClasificacionFallaId, Gravedad, GarantiaId, VehiculoMover, TipoReporteId, OrigenGruaId, TallerAutorizado, GeneralId, TallerAutorizadoId, Ejecutivo } = useSelector((state) => state.GarantiaSlice);
        const { fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, procedenciaAuto, nombreVersion, numMotor, numContrato, producto, cliente, usoUnidad, fechaUltimoPago } = useSelector((state) => state.DataVinSlice);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        let { idllamada } = useParams();

        const dateFormatNow = () => {
            let d = new Date();
            let format = "";
            format = d.getFullYear() + "-" + ("0" + d.getMonth() + 1).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            return format;
        }

        let initial = {
            garantiaId: GarantiaId,
            motivoResponsabilidadId: null,
            clasificacionFallaIdCliente: null,
            comentariosTecnico: '',
            garantiaResponsabilidadClienteId: '',
            clasificacionFallaIdAutorizar: '',
            comentariosSupervidor: '',
            comentariosReactivacion: '',
            usuarioAlta: '',
            usuarioAltaNombre: Ejecutivo,
            usuarioAutoriza: '',
            usuarioAutorizaNombre: '',
            usuarioReactiva: '',
            usuarioReactivaNombre: '',
            fechaAlta: '',
            fechaAutorizacion: '',
            fechaReactivacion: '',
            status: false
        }

        const columns = [
            { field: 'GarantiaValida', headerName: '', width: 150 },
        ];

        let ids = [];
        const [idsDeshabilitar, setIdsDeshabilitar] = useState([]);
        const handleCheck = (cheked, value) => {
            if (cheked) {
                ids.push(value.GarantiaValidaId);
                setIdsDeshabilitar(current => [...current, value.GarantiaValidaId]);
            } else {
                setIdsDeshabilitar(idsDeshabilitar.filter(item => item !== value.GarantiaValidaId));
            }
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const [Clasificaciones, setClasificaciones] = useState([]);
        const [MotivoResponsabilidad, setMotivoResponsabilidad] = useState([]);
        const [garantiasDeshabilitadoras, setGarantiasDeshabilitadoras] = useState([]);
        const [garantiasDeshabilitadas, setGarantiasDeshabilitadas] = useState([]);
        const [statusResponsabilidad, setStatusResponsabilidad] = useState(null);

        useEffect(() => {
            getResponsabilidadCliente();
            getGarantiasDeshabilitables();
            getClasificacionFalla();
            getMotivoResponsabilidad();
        }, []);


        const getResponsabilidadCliente = async () => {
            setLoading(true);
            await Garantias.getResponsabilidadCliente(GarantiaId)
                .then(resp => {
                    let responsabilidad = resp.data;
                    let idsDeshabilitadas = [];
                    responsabilidad.garantiasADesabilitar.forEach(item => {
                        idsDeshabilitadas.push(item.garantiaValidaId);
                    });
                    setGarantiasDeshabilitadas(idsDeshabilitadas);

                    setInitialValue({
                        garantiaId: responsabilidad.garantiaId,
                        motivoResponsabilidadId: responsabilidad.motivoResponsabilidadId !== null ? responsabilidad.motivoResponsabilidadId : null,
                        clasificacionFallaIdCliente: responsabilidad.clasificacionFallaIdCliente !== null ? responsabilidad.clasificacionFallaIdCliente : null,
                        comentariosTecnico: responsabilidad.comentariosTecnico ? responsabilidad.comentariosTecnico : '',
                        garantiaResponsabilidadClienteId: responsabilidad.garantiaResponsabilidadClienteId ? responsabilidad.garantiaResponsabilidadClienteId : '',
                        clasificacionFallaIdAutorizar: responsabilidad.clasificacionFallaIdAutorizar ? responsabilidad.clasificacionFallaIdAutorizar : '',
                        comentariosSupervidor: responsabilidad.comentariosSupervidor ? responsabilidad.comentariosSupervidor : '',
                        comentariosReactivacion: responsabilidad.comentariosReactivacion ? responsabilidad.comentariosReactivacion : '',
                        usuarioAlta: responsabilidad.usuarioAlta ? responsabilidad.usuarioAlta : '',
                        usuarioAltaNombre: responsabilidad.usuarioAltaNombre ? responsabilidad.usuarioAltaNombre : '',
                        usuarioAutoriza: responsabilidad.usuarioAutoriza ? responsabilidad.usuarioAutoriza : '',
                        usuarioAutorizaNombre: responsabilidad.usuarioAutorizaNombre ? responsabilidad.usuarioAutorizaNombre : '',
                        usuarioReactiva: responsabilidad.usuarioReactiva ? responsabilidad.usuarioReactiva : '',
                        usuarioReactivaNombre: responsabilidad.usuarioReactivaNombre ? responsabilidad.usuarioReactivaNombre : '',
                        fechaAlta: responsabilidad.fechaAlta ? _getDateFormat(responsabilidad.fechaAlta) : '',
                        fechaAutorizacion: responsabilidad.fechaAutorizacion ? _getDateFormat(responsabilidad.fechaAutorizacion) : '',
                        fechaReactivacion: responsabilidad.fechaReactivacion ? _getDateFormat(responsabilidad.fechaReactivacion) : '',
                        status: responsabilidad.status ? responsabilidad.status : false
                    });
                    setStatusResponsabilidad(responsabilidad.status);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const getGarantiasDeshabilitables = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.GarantiaValida')
                .then(resp => {
                    const result = resp.data;
                    setGarantiasDeshabilitadoras(result);
                })
                .catch(err => {
                    console.error(err)
                });
        }

        const getClasificacionFalla = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.ClasificacionFalla')
                .then(res => {
                    let tmpList = [];
                    res.data.forEach(item => {
                        tmpList.push({ value: item.ClasificacionFallaId, label: item.Falla });
                    });

                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setClasificaciones(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const getMotivoResponsabilidad = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.MotivoResponsabilidad')
                .then(res => {
                    let tmpList = [];
                    res.data.forEach(item => {
                        tmpList.push({ value: item.MotivoResponsabilidadId, label: item.MotivoResponsabilidad });
                    });

                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setMotivoResponsabilidad(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const getDateFormat = (fecha) => {
            if (fecha !== undefined) {
                let dateWFormat = fecha.split("T");
                return dateWFormat[0] + " " + dateWFormat[1].substring(0, 8);
            }
        }

        const _getDateFormat = (fecha) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = ("0" + d.getDate()).slice(-2) + "-" +("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2)  + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return format;
            }
        }

        const guardarResponsabilidad = async (value) => {
            setLoading(true);
            let data = {
                garantiaId: GarantiaId,
                motivoResponsabilidadId: value.motivoResponsabilidadId,
                clasificacionFallaIdCliente: value.clasificacionFallaIdCliente,
                comentariosTecnico: value.comentariosTecnico,
                status: 1
            }

            await Garantias.crearResponsabilidadCliente(data)
                .then(resp => {
                    let responsabilidad = resp.data;
                    setInitialValue({
                        garantiaId: responsabilidad.garantiaId,
                        motivoResponsabilidadId: responsabilidad.motivoResponsabilidadId !== null ? responsabilidad.motivoResponsabilidadId : null,
                        clasificacionFallaIdCliente: responsabilidad.clasificacionFallaIdCliente !== null ? responsabilidad.clasificacionFallaIdCliente : null,
                        comentariosTecnico: responsabilidad.comentariosTecnico ? responsabilidad.comentariosTecnico : '',
                        garantiaResponsabilidadClienteId: responsabilidad.garantiaResponsabilidadClienteId ? responsabilidad.garantiaResponsabilidadClienteId : '',
                        clasificacionFallaIdAutorizar: responsabilidad.clasificacionFallaIdAutorizar ? responsabilidad.clasificacionFallaIdAutorizar : '',
                        comentariosSupervidor: responsabilidad.comentariosSupervidor ? responsabilidad.comentariosSupervidor : '',
                        comentariosReactivacion: responsabilidad.comentariosReactivacion ? responsabilidad.comentariosReactivacion : '',
                        usuarioAlta: responsabilidad.usuarioAlta ? responsabilidad.usuarioAlta : '',
                        usuarioAltaNombre: responsabilidad.usuarioAltaNombre ? responsabilidad.usuarioAltaNombre : '',
                        usuarioAutoriza: responsabilidad.usuarioAutoriza ? responsabilidad.usuarioAutoriza : '',
                        usuarioAutorizaNombre: responsabilidad.usuarioAutorizaNombre ? responsabilidad.usuarioAutorizaNombre : '',
                        usuarioReactiva: responsabilidad.usuarioReactiva ? responsabilidad.usuarioReactiva : '',
                        usuarioReactivaNombre: responsabilidad.usuarioReactivaNombre ? responsabilidad.usuarioReactivaNombre : '',
                        fechaAlta: responsabilidad.fechaAlta ? _getDateFormat(responsabilidad.fechaAlta) : '',
                        fechaAutorizacion: responsabilidad.fechaAutorizacion ? _getDateFormat(responsabilidad.fechaAutorizacion) : '',
                        fechaReactivacion: responsabilidad.fechaReactivacion ? _getDateFormat(responsabilidad.fechaReactivacion) : '',
                        status: responsabilidad.status ? responsabilidad.status : false
                    });
                    setStatusResponsabilidad(responsabilidad.status);
                    toast.success(message("¡Operación exitosa!", "Se guardó la responsabilidad del cliente correctamente"));

                    const contenido = {
                        VARIABLE_EJECUTIVO: Ejecutivo,
                        TIPO_GARANTIA: '',
                        CLASIFICACION_FALLA: value.clasificacionFallaIdCliente,
                        VARIABLE_MOTIVO: value.motivoResponsabilidadId,
                        VARIABLE_MARCA: nombreMarca,
                        VARIABLE_MODELO: nombreModelo,
                        VARIABLE_AÑO: nombreYear,
                        VARIABLE_VERSION: nombreVersion,
                        VARIABLE_VIN: vin,
                        VARIABLE_CONTRATO: numContrato,
                    }

                    Garantias.creardatosenviar({
                        modulo: 'Solicitud Perdida de Garantía',
                        tipoCorreoId: 23,
                        contenido: JSON.stringify(contenido)
                    }).catch(err => {
                        console.log("Error al enviar correo Solicitud Perdida de Garantía: ", err);
                    })
                })
                .catch(err => {

                    toast.error(message("¡Operación exitosa!", "Oops..., ocurrió un error al guardar la responsabilidad del cliente"));
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const [paramsAuth, setParamsAuth] = useState({});

        const autorizarResponsabilidad = (values, actualizarrechazar) => {
            setParamsAuth({
                garantiaId: GarantiaId,
                clasificacionFallaIdAutorizar: values.clasificacionFallaIdAutorizar,
                comentariosSupervidor: values.comentariosSupervidor,
                garantiasADeshabilitar: idsDeshabilitar
            });
            setModalMessage({ isOpen: true, type: 4, title: 'Confirmar autorización', message: '', data: values, actualizarrechazar: actualizarrechazar })
        }

        const handleActualizarRechazar = async () => {
            // console.log("Rechazar")
            handleActualizar(false)
        }

        const handleActualizarAutorizar = async () => {
            // console.log("Autorizar")
            handleActualizar(true)
        }

        const handleActualizar = async (autorizar) => {
            setModalMessage({ isOpen: false, type: 4, title: '', message: '' });
            setLoading(true);

            await Garantias.autorizarResponsabilidadCliente(paramsAuth)
                .then(resp => {
                    let responsabilidad = resp.data;
                    setInitialValue({
                        garantiaId: responsabilidad.garantiaId,
                        motivoResponsabilidadId: responsabilidad.motivoResponsabilidadId !== null ? responsabilidad.motivoResponsabilidadId : null,
                        clasificacionFallaIdCliente: responsabilidad.clasificacionFallaIdCliente !== null ? responsabilidad.clasificacionFallaIdCliente : null,
                        comentariosTecnico: responsabilidad.comentariosTecnico ? responsabilidad.comentariosTecnico : '',
                        garantiaResponsabilidadClienteId: responsabilidad.garantiaResponsabilidadClienteId ? responsabilidad.garantiaResponsabilidadClienteId : '',
                        clasificacionFallaIdAutorizar: responsabilidad.clasificacionFallaIdAutorizar ? responsabilidad.clasificacionFallaIdAutorizar : '',
                        comentariosSupervidor: responsabilidad.comentariosSupervidor ? responsabilidad.comentariosSupervidor : '',
                        comentariosReactivacion: responsabilidad.comentariosReactivacion ? responsabilidad.comentariosReactivacion : '',
                        usuarioAlta: responsabilidad.usuarioAlta ? responsabilidad.usuarioAlta : '',
                        usuarioAltaNombre: responsabilidad.usuarioAltaNombre ? responsabilidad.usuarioAltaNombre : '',
                        usuarioAutoriza: responsabilidad.usuarioAutoriza ? responsabilidad.usuarioAutoriza : '',
                        usuarioAutorizaNombre: responsabilidad.usuarioAutorizaNombre ? responsabilidad.usuarioAutorizaNombre : '',
                        usuarioReactiva: responsabilidad.usuarioReactiva ? responsabilidad.usuarioReactiva : '',
                        usuarioReactivaNombre: responsabilidad.usuarioReactivaNombre ? responsabilidad.usuarioReactivaNombre : '',
                        fechaAlta: responsabilidad.fechaAlta ? getDateFormat(responsabilidad.fechaAlta) : '',
                        fechaAutorizacion: responsabilidad.fechaAutorizacion ? _getDateFormat(responsabilidad.fechaAutorizacion) : '',
                        fechaReactivacion: responsabilidad.fechaReactivacion ? _getDateFormat(responsabilidad.fechaReactivacion) : '',
                        status: responsabilidad.status ? responsabilidad.status : false
                    });
                    setStatusResponsabilidad(responsabilidad.status);
                    toast.success(message("¡Operación exitosa!", "Se autorizó la aprobación del supervisor correctamente"));

                    const contenido = {
                        VARIABLE_EJECUTIVO: Ejecutivo,
                        VARIABLE_APROBACION: 'Autorizado',
                        TIPO_GARANTIA: '',
                        CLASIFICACION_FALLA: paramsAuth.clasificacionFallaIdAutorizar,
                        VARIABLE_MOTIVO: paramsAuth.comentariosSupervidor,
                        VARIABLE_MARCA: nombreMarca,
                        VARIABLE_MODELO: nombreModelo,
                        VARIABLE_AÑO: nombreYear,
                        VARIABLE_VERSION: nombreVersion,
                        VARIABLE_VIN: vin,
                        VARIABLE_CONTRATO: numContrato,
                    }

                    Garantias.creardatosenviar({
                        modulo: 'Responsabilidad del Cliente',
                        tipoCorreoId: 24,
                        contenido: JSON.stringify(contenido)
                    }).catch(err => {
                        console.log("Error al enviar correo Responsabilidad del Cliente: ", err);
                    })
                })
                .catch(err => {
                    setLoading(false);
                    toast.error(message("¡Operación exitosa!", "Oops..., ocurrió un error al autorizar la aprobación del supervisor"));
                })
                .finally(() => {
                    setLoading(false);
                });



        }

        const reactivarGarantia = async (values) => {
            setModalMessage({ isOpen: true, type: 5, title: 'Confirmar reactivación de garantía', message: '', data: values })
        }

        const handleAutorizarDato = async (values) => {
            setLoading(true);
            let params = {
                garantiaId: GarantiaId,
                comentariosReactivacion: values.comentariosReactivacion
            }
            await Garantias.reactivarResponsabilidadCliente(params)
                .then(resp => {
                    let responsabilidad = resp.data;
                    setInitialValue({
                        garantiaId: responsabilidad.garantiaId,
                        motivoResponsabilidadId: responsabilidad.motivoResponsabilidadId !== null ? responsabilidad.motivoResponsabilidadId : null,
                        clasificacionFallaIdCliente: responsabilidad.clasificacionFallaIdCliente !== null ? responsabilidad.clasificacionFallaIdCliente : null,
                        comentariosTecnico: responsabilidad.comentariosTecnico ? responsabilidad.comentariosTecnico : '',
                        garantiaResponsabilidadClienteId: responsabilidad.garantiaResponsabilidadClienteId ? responsabilidad.garantiaResponsabilidadClienteId : '',
                        clasificacionFallaIdAutorizar: responsabilidad.clasificacionFallaIdAutorizar ? responsabilidad.clasificacionFallaIdAutorizar : '',
                        comentariosSupervidor: responsabilidad.comentariosSupervidor ? responsabilidad.comentariosSupervidor : '',
                        comentariosReactivacion: responsabilidad.comentariosReactivacion ? responsabilidad.comentariosReactivacion : '',
                        usuarioAlta: responsabilidad.usuarioAlta ? responsabilidad.usuarioAlta : '',
                        usuarioAltaNombre: responsabilidad.usuarioAltaNombre ? responsabilidad.usuarioAltaNombre : '',
                        usuarioAutoriza: responsabilidad.usuarioAutoriza ? responsabilidad.usuarioAutoriza : '',
                        usuarioAutorizaNombre: responsabilidad.usuarioAutorizaNombre ? responsabilidad.usuarioAutorizaNombre : '',
                        usuarioReactiva: responsabilidad.usuarioReactiva ? responsabilidad.usuarioReactiva : '',
                        usuarioReactivaNombre: responsabilidad.usuarioReactivaNombre ? responsabilidad.usuarioReactivaNombre : '',
                        fechaAlta: responsabilidad.fechaAlta ? getDateFormat(responsabilidad.fechaAlta) : '',
                        fechaAutorizacion: responsabilidad.fechaAutorizacion ? getDateFormat(responsabilidad.fechaAutorizacion) : '',
                        fechaReactivacion: responsabilidad.fechaReactivacion ? getDateFormat(responsabilidad.fechaReactivacion) : '',
                        status: responsabilidad.status ? responsabilidad.status : false
                    });
                    setStatusResponsabilidad(responsabilidad.status);
                    toast.success(message("¡Operación exitosa!", "Se reactivó la garantía correctamente"));
                })
                .catch(err => {
                    setLoading(false);
                    toast.error(message("¡Operación exitosa!", "Oops..., ocurrió un error al reactivar la garantía"));
                })
                .finally(() => {
                    setLoading(false);
                    setModalMessage({ isOpen: false, type: 5, title: 'Confirmar autorización', message: '', data: values })
                });
        }


        let validate = Yup.object({});

        if (statusResponsabilidad === null) {
            validate = Yup.object({
                clasificacionFallaIdCliente: Yup.mixed()
                    .required("El campo es requerido"),
                motivoResponsabilidadId: Yup.mixed()
                    .required("El campo es requerido"),
                comentariosTecnico: Yup.string()
                    .trim()
                    .required("El campo es requerido")
            });
        } else if (statusResponsabilidad === 0) {
            validate = Yup.object({
                clasificacionFallaIdAutorizar: Yup.mixed()
                    .required("El campo es requerido"),
                comentariosSupervidor: Yup.string()
                    .trim()
                    .required("El campo es requerido")
            });
        } else if (statusResponsabilidad === 1) {
            validate = Yup.object({
                comentariosReactivacion: Yup.string()
                    .trim()
                    .required("El campo es requerido")
            });
        }
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <ConfirmResponsabilidadModal
                            handleActualizarAutorizar={handleActualizarAutorizar}
                            handleActualizarRechazar={handleActualizarRechazar}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 5 &&
                        <>
                            <section className='d-flex justify-content-end'>
                                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}><i className="ri-close-fill"></i></button>
                            </section>
                            <div className='justify-content-start p-2 align-items-center'>
                                <div className="row">
                                    <div className="col col-12">
                                        <h6><strong>{modalMessage.title}</strong></h6>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-2">
                                    <div className="col col-12">
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">¿Estas seguro de reactivar las siguientes garantías? Garantía satisfacción, Garantía transmisión. Este cambio impactará en el flujo de garantías de la unidad.</small>
                                        </div>
                                    </div>
                                    <div className="col col-12">
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">Este cambio impactará en el flujo de garantías de la unidad</small>
                                        </div>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto">
                                            <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ isOpen: false, type: 5, title: 'Confirmar reactivación de garantía', message: '', data: [] }) }}>Cancelar</button>
                                        </div>
                                        <div className="col-auto">
                                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { handleAutorizarDato(modalMessage.data) }}>Autorizar</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </>
                    }
                </Modal>


                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />

                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        validationSchema={validate}
                        initialValues={initialValue}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <>
                                <div className='row mx-md-4 my-4' >
                                    <div className="col-12 px-md-3">
                                        <div className="p-4 white-wrapper mb-2">

                                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseResponsabilidadCliente" role="button" aria-expanded="false" aria-controls="collapseResponsabilidadCliente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                <div className="col-11">
                                                    <h6><span className={statusResponsabilidad === null ? 'semaforo semaforo-default' : (statusResponsabilidad < 2 ? 'semaforo semaforo-yellow' : 'semaforo semaforo-green')}></span> <strong>Responsabilidad del cliente</strong></h6>
                                                </div>
                                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                </div>
                                            </div>
                                            <div className="collapse" id="collapseResponsabilidadCliente">
                                                <div className="separator"></div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-9">
                                                        <small><strong>Boletinar al cliente</strong></small>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    statusResponsabilidad === null &&
                                                                    <button className='btn' onClick={() => guardarResponsabilidad(values)}>
                                                                        Guardar
                                                                    </button>
                                                                }
                                                                {
                                                                    statusResponsabilidad === 0 &&
                                                                    <>
                                                                        <button className='btn' style={{ marginRight: '10px' }} onClick={() => autorizarResponsabilidad(values, 0)}>
                                                                            Rechazar
                                                                        </button>
                                                                        <button className='btn' onClick={() => autorizarResponsabilidad(values, 1)}>
                                                                            Autorizar
                                                                        </button>
                                                                    </>
                                                                }
                                                                {
                                                                    statusResponsabilidad === 1 &&
                                                                    <button className='btn' onClick={() => reactivarGarantia(values)}>
                                                                        Reactivar
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="clasificacionFallaIdCliente" label="Clasificación de la falla" disabled={statusResponsabilidad !== null ? true : false} name="clasificacionFallaIdCliente" items={Clasificaciones} onChange={(event) => {
                                                            setFieldValue("clasificacionFallaIdCliente", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="motivoResponsabilidadId" label="Motivo de responsabilidad" disabled={statusResponsabilidad !== null ? true : false} name="motivoResponsabilidadId" items={MotivoResponsabilidad} onChange={(event) => {
                                                            setFieldValue("motivoResponsabilidadId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="usuarioAltaNombre" disabled={true} label="Usuario" name="usuarioAltaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioAltaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="fechaAlta" disabled={true} label="Fecha y hora de la solicitud" name="fechaAlta" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("fechaAlta", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosTecnico" label="Comentarios técnico" disabled={statusResponsabilidad !== null ? true : false} className="form-control col-12" rows="3" name="comentariosTecnico" type="text" holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosTecnico", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <small><strong>Aprobación de supervisor</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <span>Garantías a deshabilitar: </span>
                                                        <table className="table table-striped">
                                                            <tbody>
                                                                {
                                                                    garantiasDeshabilitadoras.map((rowObj, index) => {
                                                                        return <tr key={index}>
                                                                            <td>
                                                                                {/* <input id={rowObj.logUsuarioId} disabled={ statusResponsabilidad !== 0 ? true : false } className='form-check-input chk' type="checkbox" checked={garantiasDeshabilitadas.includes(rowObj.GarantiaValidaId) ? true : false} onChange={(e) => { handleCheck(e.target.checked, rowObj, index) }} /> */}
                                                                                <input id={rowObj.logUsuarioId} disabled={statusResponsabilidad !== 0 ? true : false} className='form-check-input chk' type="checkbox" onChange={(e) => { handleCheck(e.target.checked, rowObj, index) }} />
                                                                            </td>
                                                                            <td>
                                                                                {rowObj.GarantiaValida}
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="usuarioAutorizaNombre" disabled={true} label="Usuario Autorizó" name="usuarioAutorizaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioAutorizaNombre", event.target.value);
                                                        }} />
                                                    </div>

                                                    <div className="col-3">
                                                        <TextField id="fechaAutorizacion" disabled={true} label="Fecha y Hora de Autorización" name="fechaAutorizacion" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("fechaAutorizacion", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField id="clasificacionFallaIdAutorizar" label="Clasificación de la falla" disabled={statusResponsabilidad !== 0 ? true : false} name="clasificacionFallaIdAutorizar" items={Clasificaciones} onChange={(event) => {
                                                            setFieldValue("clasificacionFallaIdAutorizar", event.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosSupervidor" label="Comentarios supervisor" disabled={statusResponsabilidad !== 0 ? true : false} className="form-control col-12" rows="3" name="comentariosSupervidor" type="text" holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosSupervidor", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-10">
                                                        <small><strong>Reactivar garantía</strong></small>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <TextField id="usuarioReactivaNombre" disabled={true} label="Usuario" name="usuarioReactivaNombre" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("usuarioReactivaNombre", event.target.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField id="fechaReactivacion" label="Fecha y hora de reactivación" disabled={true} name="fechaReactivacion" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("fechaReactivacion", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosReactivacion" label="Comentarios de reactivación" disabled={statusResponsabilidad !== 1 ? true : false} className="form-control col-12" rows="3" name="comentariosReactivacion" type="text" holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosReactivacion", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-12">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {/*  */}
                                                            {
                                                                initialValue.fechaAlta !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso {initialValue.fechaAlta}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {

                                                                initialValue.fechaAutorizacion !== "" &&
                                                                <small className='text-body-tertiary'>Fin de proceso {initialValue.fechaAutorizacion}</small>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </Formik>
                </div>

            </>
        )
    }
export default CardResponsabilidadCliente