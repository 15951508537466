import axios from '../../Utils/ApiService';

const GestoriaServices = {
    getAllListaVehiculloEnGestoria:(page,rows,tipoCompraId,string)=> {        
        return axios.get(`/functions/GetAllVehiculoGestoria?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&Buscar=${string}`);
    },
    getAllGestoriaRealizada:(page,rows,tipoCompraId,string)=>{
        return axios.get(`/functions/GetAllVehiculoGestoriaRealizada?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&Buscar=${string}`)
    },
    getAllGestoriaCancelacion:(page,rows,tipoCompraId,string)=>{
        return axios.get(`/functions/GetAllVehiculoGestoriaCancelacion?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&Buscar=${string}`)
    },
    SaveGestoriaAutorizada:(data)=>{
        return axios.post(`/functions/SaveGestoriaAutorizada`,data)
    },
    SaveGestoriaRechazada:(data)=>{
        return axios.post(`/functions/SaveGestoriaRechazada`,data)
    },
    SaveGestoriaAcendes:(data)=>{
        return axios.post(`/functions/SaveGestoriaAcendes`,data)
    },
    SaveGestoriaRealizada:(data)=>{
        return axios.post(`/functions/SaveGestoriaRealizada`,data)
    },
    SaveGestoriaCancelada:(data)=>{
        return axios.post(`/functions/SaveGestoriaCancelada`,data)
    },
    GetByIdGestoria:(generalId)=>{
        return axios.get(`/functions/GetVehiculoGestoriaById?GeneralId=${generalId}`)
    },
    GetByIdGestoriaCancelacion:(generalId)=>{
        return axios.get(`/functions/GetVehiculoGestoriaCancelacionById?GeneralId=${generalId}`)
    },
    cambioStatusCargaEvidencia:(data)=>{
        return axios.post(`/functions/CambioEstatusGestoriaCargaEvidencia`,data)
    },
}
export default GestoriaServices;