import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useSelector } from 'react-redux';
import { message, FormatNumberMoney } from '../../../Utils/functions';
import AuthLogin from '../../../Utils/AuthLogin';
import SubMenuResponsiva from './SubMenuResponsiva';
import ResponsivaService from '../../../Services/Seguros/ResponsivaService';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../../../Components/TextAreaField';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section } from '../../../Auth/Authorization';


const auth = new AuthLogin();
const Responsiva = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const refbtn = useRef();
    const refbtnDownload = useRef();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);
    const refFormik = useRef();

    let initial = {
        VIN: item?.vin,
        marca: item?.nombreMarca,
        modelo: item?.nombreModelo,
        year: item?.nombreYear,
        version: item?.nombreVersion,
        condicion: item?.tipoCondicion,
        transmision: item?.transmision ?? "",

        responsiva: item?.responsiva ?? false,
        generalId: id,
        responsivaId: "",
        tipoResponsivaId: "",
        fechaRegistro: "",
        noCaptura: "",
        fechaFactura: "",
        empresaFactura: "",
        segundaClausula: "",
        empresaId: "",
        representanteLegalId: "",
        bancoId: "",
        tipoPagoId: "",
        referenciaPago: "",
        tribunalId: "",
        nombreVendedor: "",
        domicilioVendedor: "",
        representanteLegalVendedor: "",
        valorVehiculo: "",
        poseedorVehiculo: "",
        status: true,
        idEstado: "",
        idCiudad: "",
        idColonia: "",
        codigoPostal: "",
        calle: "",
        numeroInterior: "",
        numeroExterior: "",
    }
    const dataAplica = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        tipoResponsivaId: Yup.string()
            .required("El campo es requerido."),
        fechaRegistro: Yup.string()
            .required("El campo es requerido."),
        noCaptura: Yup.number()
            .required("El campo es requerido."),
        fechaFactura: Yup.string()
            .required("El campo es requerido."),
        empresaFactura: Yup.string()
            .required("El campo es requerido."),
        // segundaClausula: Yup.string()
        //     .required("El campo es requerido."),
        empresaId: Yup.string()
            .required("El campo es requerido."),
        representanteLegalId: Yup.string()
            .required("El campo es requerido."),
        bancoId: Yup.string()
            .required("El campo es requerido."),
        tipoPagoId: Yup.string()
            .required("El campo es requerido."),
        referenciaPago: Yup.string()
            .required("El campo es requerido."),
        tribunalId: Yup.string()
            .required("El campo es requerido."),

        // nombreVendedor: Yup.string()
        //     .required("El campo es requerido."),
        // representanteLegalVendedor: Yup.string()
        //     .required("El campo es requerido."),
        // valorVehiculo: Yup.string()
        //     .required("El campo es requerido."),
        // poseedorVehiculo: Yup.string()
        //     .required("El campo es requerido."),
        // idEstado: Yup.string()
        //     .required("El campo es requerido."),
        // idCiudad: Yup.string()
        //     .required("El campo es requerido."),
        // idColonia: Yup.string()
        //     .required("El campo es requerido."),
        // codigoPostal: Yup.string()
        //     .required("El campo es requerido."),
        // calle: Yup.string()
        //     .required("El campo es requerido."),
        // numeroExterior: Yup.number()
        //     .required("El campo es requerido.")

        nombreVendedor: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        representanteLegalVendedor: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        valorVehiculo: Yup.string()
            .nullable()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        poseedorVehiculo: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        idEstado: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        idCiudad: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        idColonia: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        codigoPostal: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        calle: Yup.string()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
        numeroExterior: Yup.number()
            .when('tipoResponsivaId', (tipoResponsivaId, schema) => {
                return (tipoResponsivaId && ['ed531e64-e05e-45da-a313-1499b38265fd', '100e00a4-27aa-4fe2-a5ff-8e4a4df23211'].includes(tipoResponsivaId))
                    ? schema.required("El campo es requerido.")
                    : schema;
            }),
    });

    const [loading, setLoading] = useState(false);

    const [TipoResponsiva, setTipoResponsiva] = useState([]);
    const [DataTipoPago, setDataTipoPago] = useState([]);
    const [DataEstado, setDataEstado] = useState([]);
    const [DataMunicipio, setDataMunicipio] = useState([]);
    const [DataColonia, setDataColonia] = useState([]);
    const [DataBanco, setDataBanco] = useState([]);
    const [DataEmpresa, setDataEmpresa] = useState([]);
    const [DataRLegal, setDataRLegal] = useState([]);
    const [DataTribunal, setDataTribunal] = useState([]);

    useEffect(() => {
        handleGetTipoResponsiva();
        handleGetTipoPago();
        handleGetAllEdoMexico();
        handleGetBanco();
        handleGetEmpresa();
        handleGetTribunal();
        if (item?.responsiva) {

            handleGetById();
        }

    }, [])

    const handleGetById = async () => {
        setLoading(true);
        await ResponsivaService.getResponsivaById(id)
            .then(res => {
                let item = res.data.data;

                let initial = {
                    VIN: item?.vin,
                    marca: item?.nombreMarca,
                    modelo: item?.nombreModelo,
                    year: item?.nombreYear,
                    version: item?.nombreVersion,
                    condicion: item?.tipoCondicion,
                    transmision: item?.transmision ?? "",

                    generalId: id,
                    responsiva: item?.responsiva ?? false,
                    responsivaId: item?.responsivaId ?? "",
                    tipoResponsivaId: item?.tipoResponsivaId ?? "",
                    fechaRegistro: item?.fechaRegistroSF.split("T")[0] ?? "",
                    noCaptura: item?.noCaptura ?? "",
                    fechaFactura: item?.fechaFactura.split("T")[0] ?? "",
                    empresaFactura: item?.empresaFactura ?? "",
                    segundaClausula: item?.segundaClausula ?? "",
                    empresaId: item?.empresaId,
                    representanteLegalId: item?.representanteLegalId ?? "",
                    bancoId: item?.bancoId ?? "",
                    tipoPagoId: item?.tipoPagoId ?? "",
                    referenciaPago: item?.referenciaPago ?? "",
                    tribunalId: item?.tribunalId ?? "",
                    nombreVendedor: item?.nombreVendedor ?? "",
                    representanteLegalVendedor: item?.representanteLegalVendedor ?? "",
                    valorVehiculo: item?.valorVehiculo === '' ? '' : FormatNumberMoney(item.valorVehiculo),
                    poseedorVehiculo: item?.poseedorVehiculo ?? "",
                    status: true,
                    idEstado: item?.idEstado ?? "",
                    idCiudad: item?.idCiudad ?? "",
                    idColonia: item?.idColonia ?? "",
                    codigoPostal: item?.codigoPostal ?? "",
                    calle: item?.calle ?? "",
                    numeroInterior: item?.numeroInterior ?? "",
                    numeroExterior: item?.numeroExterior ?? "",
                }
                setInitialValue(initial);
                handleGetRepresentanteLegal(item.empresaId);
                handleGetAllMunicipioById(item.idEstado);
                handleGetAllColoniaById(item.idCiudad);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoResponsiva = async () => {
        setLoading(true);
        await ResponsivaService.getTipoResponsiva()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoResponsivaId,
                        label: item.tipoResponsiva
                    })
                });

                setTipoResponsiva(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetBanco = async () => {
        setLoading(true);
        await ResponsivaService.getBanco()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.bancoId,
                        label: item.banco
                    })
                });

                setDataBanco(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetTipoPago = async () => {
        await ResponsivaService.getTipoPago()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoPagoId,
                        label: item.tipoPago
                    })
                });
                setDataTipoPago(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllEdoMexico = async () => {
        await Catalogo.getdataTabla("EdoMExico", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.edoMexicoId,
                            label: item.estado
                        })
                    }
                });
                setDataEstado(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllMunicipioById = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.municipioId,
                            label: item.municipio
                        })
                    }
                });
                setDataMunicipio(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllColoniaById = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.coloniaId,
                            label: item.colonia
                        })
                    }
                });
                setDataColonia(NewData);
            }).catch(e => {

            })
    }
    const handleGetEmpresa = async () => {
        setLoading(true);
        await ResponsivaService.getEmpresa()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.empresaId,
                        label: item.empresa
                    })
                });
                setDataEmpresa(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetTribunal = async () => {
        setLoading(true);
        await ResponsivaService.getLugarTribunal()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tribunalId,
                        label: item.tribunal
                    })
                });
                setDataTribunal(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetRepresentanteLegal = async (id) => {
        setLoading(true);
        await ResponsivaService.getRepresentanteLegal(id)
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    NewData.push({
                        value: item.representanteLegalId,
                        label: item.representanteLegal
                    })
                });
                setDataRLegal(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const downloadFileResponsiva = async () => {
        setLoading(true);
        refbtnDownload.current.disabled = true;
        await ResponsivaService.downloadFileResponsiva(id)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `Responsiva.pdf`);
                setLoading(false);
                refbtnDownload.current.disabled = false;
            }).catch(e => {
                refbtnDownload.current.disabled = false;
                setLoading(false);
            })
    }
    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        const newValue = value.valorVehiculo !== null && value.valorVehiculo !== "" ? value.valorVehiculo.replaceAll(',', '') : '';
        value.valorVehiculo = newValue;
        setLoading(true);
        if (initialValue.responsiva) {
            await ResponsivaService.UpdResponsiva(value)
                .then(res => {
                    setLoading(false);
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    handleGetById();
                    refbtn.current.disabled = false;
                    setTimeout(() => {
                        navigate("/inventario/vehiculo");
                    }, 2000);
                }).catch(e => {
                    setLoading(false);
                    refbtn.current.disabled = false;
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else if (!initialValue.responsiva) {
            await ResponsivaService.addResponsiva(value)
                .then(res => {
                    setLoading(false);
                    handleGetById();
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    refbtn.current.disabled = false;
                    setTimeout(() => {
                        navigate("/inventario/vehiculo");
                    }, 2000);
                }).catch(e => {
                    setLoading(false);
                    refbtn.current.disabled = false;
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                    <SubMenuResponsiva />
                    <div className='m-0 mt-4' style={{ height: '89.5vh' }}>
                        <section>
                            <div className='mx-4'>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 me-2'><strong>Información del vehiculo</strong></span>
                                            {/* <fieldset className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status()} {dataStatus ?? "Sin dispositivo"}</fieldset> */}
                                        </div>
                                        <div className='d-flex'>
                                            {
                                                values.responsiva ?
                                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ResponsivaSection, AccionesRapidas.Responsiva).Descarga}>
                                                        <fieldset ref={refbtnDownload} className='btn btn-outline mx-2 d-flex align-items-center' onClick={downloadFileResponsiva} ><i className="icon ri-download-line me-2"></i>Descargar Reporte</fieldset>
                                                    </ValidatePermission>
                                                    : null
                                            }
                                            <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.ResponsivaSection, AccionesRapidas.Responsiva))}>
                                                <button ref={refbtn} className='btn mx-2' type="submit" >{values.responsiva ? "Editar" : "Guardar"}</button>
                                            </ValidatePermission>
                                        </div>
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ResponsivaSection, AccionesRapidas.Responsiva).Ver}>
                                        <div className='my-2 mt-5'>
                                            <p><strong>Responsiva</strong></p>
                                            <div className="mb-3 row">
                                                {/* {values.tipoResponsivaId} --------- */}
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Tipo de responsiva" name="tipoResponsivaId" items={TipoResponsiva} onChange={(event) => {
                                                        setFieldValue("tipoResponsivaId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Fecha de registro" name="fechaRegistro" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaRegistro", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="No Captura" name="noCaptura" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("noCaptura", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Fecha de captura" name="fechaFactura" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaFactura", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Empresa que factura" name="empresaFactura" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("empresaFactura", event.target.value);
                                                    }} />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Empresa" name="empresaId" items={DataEmpresa} onChange={(event) => {
                                                        setFieldValue("empresaId", event.value);
                                                        handleGetRepresentanteLegal(event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Representante legal" name="representanteLegalId" items={DataRLegal} onChange={(event) => {
                                                        setFieldValue("representanteLegalId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Banco" name="bancoId" items={DataBanco} onChange={(event) => {
                                                        setFieldValue("bancoId", event.value);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Tipo de pago" name="tipoPagoId" items={DataTipoPago} onChange={(event) => {
                                                        setFieldValue("tipoPagoId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Referencia de pago" name="referenciaPago" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("referenciaPago", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Lugar de tribunal" name="tribunalId" items={DataTribunal} onChange={(event) => {
                                                        setFieldValue("tribunalId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <TextAreaField label="Segunda clausula" className="form-control col-12" rows="4" name="segundaClausula" type="text" holder="Escribe" onKeyUp={(event) => {
                                                        setFieldValue("segundaClausula", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2 mt-4 mb-5'>
                                            <p><strong>Datos del vendedor</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Nombre del vendedor" name="nombreVendedor" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("nombreVendedor", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Estado" name="idEstado" items={DataEstado} onChange={(event) => {
                                                        setFieldValue("idEstado", event.value);
                                                        setFieldValue("idCiudad", "");
                                                        handleGetAllMunicipioById(event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Municipio" name="idCiudad" items={DataMunicipio} onChange={(event) => {
                                                        setFieldValue("idCiudad", event.value);
                                                        setFieldValue("idColonia", "");
                                                        handleGetAllColoniaById(event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Colonia" name="idColonia" items={DataColonia} onChange={(event) => {
                                                        setFieldValue("idColonia", event.value);
                                                    }} />
                                                </div>

                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Codigo Postal" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("codigoPostal", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Calle" name="calle" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("calle", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="N° Exterior" name="numeroExterior" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("numeroExterior", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="N° Interior" name="numeroInterior" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("numeroInterior", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Representante legal vendedor" name="representanteLegalVendedor" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("representanteLegalVendedor", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField icon={"bi bi-currency-dollar"} label="Valor del vehiculo" name="valorVehiculo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("valorVehiculo", FormatNumberMoney(event.target.value));
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Poseedor del vehiculo" name="poseedorVehiculo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("poseedorVehiculo", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </ValidatePermission>
                                </Form>
                            </div>
                        </section>
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default Responsiva