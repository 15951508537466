import axios from '../../Utils/ApiService';

const RevisionUnidadRechazadaService = {
    GetallRevisionUnidadRechazada:(page,rows,tipoCompraId,razonSocial,str)=>{
        return axios.get(`/functions/GetAllRevisionUnidadRechazada?page=${page}&Rows=${rows}&TipoCompraId=${tipoCompraId}&RazonSocial=${razonSocial}&Buscar=${str}`);
    },
    ExportRevisionUnidadRechazada:(tipoCompraId,razonSocial,str)=>{
        return axios.get(`/functions/ExportarRevisionUnidadRechazada?TipoCompraId=${tipoCompraId}&RazonSocial=${razonSocial}&Buscar=${str}`,{ responseType: 'blob' });
    },
    DeleteRevisionUnidadRechazada:(id)=>{
        return axios.delete(`/functions/DeleteRevisionUnidadRechazadaById?generalId=${id}`);
    },
    getAllCSRazonSocialById: async (Id) => {
        return await axios.get(`/functions/GetAllCSRazonSocialByIdRU?TipoCompraId=${Id}`);
    },
}
export default RevisionUnidadRechazadaService;