import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveInstalaciones } from '../../../Redux/Slice/navigateSliceInstalacion';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubMenuInstalacion = () => {
  const { value } = useSelector((state) => state.tabNavigateInstalaciones);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveInstalaciones(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.VehículosDeInventario) && value === 1) {
      navigate(`/inventario/instalaciones/vehículoinventario`);
    }
    if (ValidatePermissionById(3, Section.VehículosDeSocioComercial) && value === 2) {
      navigate(`/inventario/instalaciones/vehículosociocomercial`);
    }
    if (ValidatePermissionById(3, Section.ProgramadoEnContrato) && value === 3) {
      navigate(`/inventario/instalaciones/programadocontrato`);
    }

  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehículosDeInventario)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos de inventario</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.VehículosDeSocioComercial)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Vehículos de Socio Comercial</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.ProgramadoEnContrato)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Programado en contrato</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuInstalacion