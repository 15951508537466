import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik, useFormikContext } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import Spinner from '../../../Components/Loadig';
import Datamovil from '../../../Services/Resguardos/Datamovil';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { SelectField } from '../../../Components/SelectField';
import { message } from '../../../Utils/functions';
import ReprogramacionCard from './ReprogramacionCard';
import Garantias from '../../../Services/Cartera/Garantias';

const UbicacionUnidadCard
    = React.forwardRef(({ ...props }, ref) => {
        const { fechaEntrega,nombreYear,vin,color,nombreMarca,kilometraje,nombreModelo,procedenciaAuto,nombreVersion,numMotor,numContrato,producto,cliente,usoUnidad,fechaUltimoPago} = useSelector((state) => state.DataVinSlice);

        const dispatch = useDispatch();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const navigate = useNavigate();
        const { id, datamovilid, generalid } = useParams();


        let initial = {
            datamovilUbicacionUnidad: "",
            datamovilId: "",
            garantiaId: "",
            color: "default",
            proveedorUnidad: "",
            telefonoFijo: "",
            telefonoMovil: "",
            telefonoTrabajo: "",
            calleNumero: "",
            codigoPostal: "",
            pais: "",
            estado: "",
            municioio: "",
            colonia: "",
            latitud: "",
            longitud: "",
            unidadEnUbicacion: false,
            tipoUbidacionId: "",
            tipoUbicacion: "",
            ubicacionId: "",
            ubicacion: "",
            fechaCita: "",
            ubicacionCitaId: "",
            ubicacionCita: "",
            fechaInicio: "",
            fechaConclusion: "",
            concluido: false,
            datamovilUbicacionUnidadStatusId: 0,
            datamovilUbicacionUnidadStatus: "En proceso",
            proceso: "",
            usuarioAlta: "",
            usuarioActualiza: ""

        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [dataTipoUbicacion, setDataTipoUbicacion] = useState([]);
        const [dataUbicacion, setDataUbicacion] = useState([]);
        const [dataUbicacionCita, setDataUbicacionCita] = useState([]);

        useEffect(() => {
            getTipoUbicacion();
            getUbicacion();
            getUbicacionCita();
            getUbicacionUnidad();
        }, []);



        const getUbicacionUnidad = async () => {
            setLoading(true);
            await Datamovil.datamovilUbicacionUnidadGetByDatamovilId(datamovilid)
                .then(resp => {
                    // resp.data.unidadEnUbicacion = resp.data.unidadEnUbicacion === null ? null : (resp.data.unidadEnUbicacion ? "Sí" : "No");
                    resp.data.unidadEnUbicacion = resp.data.unidadEnUbicacion === null ? null : (resp.data.unidadEnUbicacion ? 1 : 0);
                    resp.data.fechaCita = resp.data.fechaCita?.split("T")[0];
                    resp.data.telefonoFijo = setFormatPhone(resp.data.telefonoFijo);
                    resp.data.telefonoMovil = setFormatPhone(resp.data.telefonoMovil);
                    resp.data.telefonoTrabajo = setFormatPhone(resp.data.telefonoTrabajo);
                    setInitialValue(resp.data);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };

        /* CATALOGOS */
        const getTipoUbicacion = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.TipoUbicacion')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.TipoUbicacionId, label: item.TipoUbicacion })
                    });
                    setDataTipoUbicacion(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        const getTipoUbicacionCita = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.TipoUbicacion')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.TipoUbicacionId, label: item.TipoUbicacion })
                    });
                    setDataUbicacionCita(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        const getUbicacion = async () => {
            await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.UbicacionId, label: item.Nombre })
                    });
                    setDataUbicacion(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        const getUbicacionCita = async () => {
            await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.UbicacionId, label: item.Nombre })
                    });
                    setDataUbicacionCita(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        /* TERMINA CATALOGOS */

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const crearReprogramacion = async () => {
            let params = {
                datamovilId: datamovilid,
                datamovilReprogramacionStatusId: 10,
                status: true
                
            }

            await Datamovil.datamovilReprogramacionCrear(params)
            .then(resp => {
                toast.success(message("Se creó la reprogramación de datamovil correctamente"));
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar crear la repogramación de datamovil!"));
                console.error(err);
            })
            .finally(() => {
                //setLoading(false);
            });

        }

        const submitForm = async (values, modal) => {

            if(values.concluido){
                values.datamovilUbicacionUnidadStatusId = 30;
            }

            setLoading(true);
            await Datamovil.datamovilUbicacionUnidadActualizar(values)
                .then(resp => {
                    resp.data.unidadEnUbicacion = resp.data.unidadEnUbicacion === null ? null : (resp.data.unidadEnUbicacion ? 1 : 0);
                    resp.data.fechaCita = resp.data.fechaCita?.split("T")[0];
                    resp.data.telefonoFijo = setFormatPhone(resp.data.telefonoFijo);
                    resp.data.telefonoMovil = setFormatPhone(resp.data.telefonoMovil);
                    resp.data.telefonoTrabajo = setFormatPhone(resp.data.telefonoTrabajo);
                    setInitialValue(resp.data);

                    toast.success(message("Se actualizó la ubicación correctamente"));


                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error, al intentar actualizar la ubicación!"));
                })
                .finally(() => {
                    
                    if(values.concluido && values.fechaCita) {
                        const date = new Date();
                        const localDate = date.toLocaleString();
                        const dateFormat = localDate.split(",")[0];
                        const hourFormat = localDate.split(",")[1].trim();


                        const contenidoEmail = {
                            VARIABLE_FECHA: ("0"+dateFormat[0]).slice(-2)+"-"+("0"+dateFormat[1]).slice(-2)+"-"+dateFormat[2],
                            VARIABLE_HORA: ("0"+hourFormat[0]).slice(-2)+":"+("0"+hourFormat[1]).slice(-2)+":"+("0"+hourFormat[2]).slice(-2),
                            VARIABLE_MARCA: nombreMarca,
                            VARIABLE_MODELO: nombreModelo,
                            VARIABLE_AÑO: nombreYear,
                            VARIABLE_VERSION: nombreVersion,
                            VARIABLE_VIN: vin,
                            VARIABLE_CONTRATO: numContrato,
                            VARIABLE_COMENTARIO: "",
                        }
                        Garantias.creardatosenviar({
                            modulo: 'Aprobación de la cita de Reprogramación',
                            tipoCorreoId: 59,
                            contenido: JSON.stringify(contenidoEmail)
                        }).catch(err => {
                            console.log("Error al enviar correo Aprobación de la cita de Reprogramación:", err);
                        })
                    }

                    if(values.concluido){
                        crearReprogramacion();
                    }
                    setLoading(false);
                });
        }


        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        const setFormatPhone = (value) => {
            let prevPhone = value.replace(/[()-]/g, '');
            let formatted = "";

            if (prevPhone.length < 3) {
                formatted = prevPhone;
            } else if (prevPhone.length >= 3 && prevPhone.length <= 6) {
                let lada = prevPhone.substr(0, 2);
                let firstSegment = prevPhone.substr(2, 4);
                formatted = "(" + lada + ")-" + firstSegment;
            } else if (prevPhone.length > 6) {
                let lada = prevPhone.substr(0, 2);
                let firstSegment = prevPhone.substr(2, 4);
                let secondSegment = prevPhone.substr(6, 4);
                formatted = "(" + lada + ")-" + firstSegment + "-" + secondSegment;
            }

            return formatted;

        };


        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }

                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseUbicacionUnidad" role="button" aria-expanded="false" aria-controls="collapseUbicacionUnidad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>
                                            Ubicacion de unidad

                                        </strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.datamovilUbicacionUnidadStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseUbicacionUnidad">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        isInitialValid={false}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Ubicación de Unidad</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {
                                                                initialValue.concluido === null &&
                                                                <>
                                                                    <div className="col-auto">
                                                                        <button
                                                                            className='btn'
                                                                            disabled={!isValid && (initialValue.fechaCita === null)}
                                                                            onClick={() => {
                                                                                submitForm(values, 3);
                                                                            }}>
                                                                            Guardar
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <TextField id="proveedorUnidad"
                                                            disabled={true}
                                                            label="Poseedor de la unidad"
                                                            holder=""
                                                            name="proveedorUnidad"
                                                            type="text" />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="telefonoFijo"
                                                            disabled={true}
                                                            label="Teléfono fijo"
                                                            holder=""
                                                            name="telefonoFijo"
                                                            type="text"
                                                            
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="telefonoMovil"
                                                            disabled={true}
                                                            label="Teléfono movil"
                                                            holder=""
                                                            name="telefonoMovil"
                                                            type="text"
                                                            
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="telefonoTrabajo"
                                                            disabled={true}
                                                            label="Teléfono trabajo"
                                                            holder=""
                                                            name="telefonoTrabajo"
                                                            type="text"
                                                            
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <TextField id="calleNumero"
                                                            disabled={true}
                                                            label="Calle y número"
                                                            holder=""
                                                            name="calleNumero"
                                                            type="text" />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="codigoPostal"
                                                            disabled={true}
                                                            label="Código postal"
                                                            holder=""
                                                            name="codigoPostal"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="pais"
                                                            disabled={true}
                                                            label="País"
                                                            holder=""
                                                            name="pais"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="estado"
                                                            disabled={true}
                                                            label="Estado"
                                                            holder=""
                                                            name="estado"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="municipio"
                                                            disabled={true}
                                                            label="Municipio"
                                                            holder=""
                                                            name="municipio"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <TextField id="colonia"
                                                            disabled={true}
                                                            label="Colonia"
                                                            holder=""
                                                            name="colonia"
                                                            type="text" />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="latitud"
                                                            disabled={true}
                                                            label="Latitud"
                                                            holder=""
                                                            name="latitud"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField id="longitud"
                                                            disabled={true}
                                                            label="Longitud"
                                                            holder=""
                                                            name="longitud"
                                                            type="text"
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Confirmación de la ubicación</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <SelectField id="unidadEnUbicacion"
                                                            disabled={false}
                                                            label="Unidad se encuentra en ubicación"
                                                            holder=""
                                                            name="unidadEnUbicacion"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadEnUbicacion", event.value);
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                                {
                                                    values.unidadEnUbicacion === 0 &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-3">
                                                                <small><strong>Ubicación actualizada</strong></small>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-2">
                                                                <SelectField id="tipoUbicacionId"
                                                                    disabled={false}
                                                                    label="Tipo de ubicación"
                                                                    holder=""
                                                                    name="tipoUbicacionId"
                                                                    items={dataTipoUbicacion}
                                                                    onChange={(event) => {
                                                                        setFieldValue("tipoUbicacionId", event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <SelectField id="ubicacionId"
                                                                    disabled={false}
                                                                    label="Ubicación"
                                                                    holder=""
                                                                    name="ubicacionId"
                                                                    items={dataUbicacion}
                                                                    onChange={(event) => {
                                                                        setFieldValue("ubicacionId", event.value);
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                    </>

                                                }

                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Agendar cita</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-2">
                                                        <TextField
                                                            id="fechaCita"
                                                            label="Fecha"
                                                            name="fechaCita"
                                                            type="date"
                                                            holder="Seleccione"
                                                            onChange={(event) => {
                                                                setFieldValue("fechaCita", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-2">
                                                        <SelectField id="ubicacionCitaId"
                                                            disabled={false}
                                                            label="Ubicación"
                                                            holder=""
                                                            name="ubicacionCitaId"
                                                            items={dataUbicacionCita}
                                                            onChange={(event) => {
                                                                setFieldValue("ubicacionCitaId", event.value);
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Proceso concluido</label>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    initialValue.datamovilUbicacionUnidadStatusId === 30 &&
                    <ReprogramacionCard />
                }
            </>
        )
    })
export default UbicacionUnidadCard