import axios from '../../Utils/ApiService';

const HistorialService = {
    getHistoryOrdenEtapa:(page,rows,productoId,tipoCompraId,fecha)=> {        
        return axios.get(`/functions/HistorialMotivo?Page=${page}&Rows=${rows}&ProductoId=${productoId}&TipoCompraId=${tipoCompraId}&Fecha=${fecha}`);
    }, 
    getAllTipoCompra:()=> {        
        return axios.get(`/catalogs/ComboSelectTipoCompra`);
    },        
}

export default HistorialService