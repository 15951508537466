import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../../Components/datatable/DataTable'
import UploadFile from '../../../../Components/UploadFile'
import RowPages from '../../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { Section, SubModule } from '../../../../Auth/Authorization'
import SubMenuComisionesInventario from '../SubMenuComisionesInventario'
import useComisionesExternoProcesoPago from '../../../../Hooks/Inventario/Comisiones/useComisionesExternoProcesoPago'
import HeaderCobroComisionExterno from './HeaderCobroComisionExterno'
import ModalConfirmPPCobroComisionExterno from './ModalConfirmPPCobroComisionExterno'
import useCobroComisionesExternoProcesoPago from '../../../../Hooks/Inventario/Comisiones/useCobroComisionesExternoProcesoPago'
import UploadFileDetail from './UploadFileDetail'
const CobroComisionExternoProcesoPago = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        columnsDetail,
        DataAseguradora,
        setAseguradora,
        setFechInicio,
        setFechFin,
        setIsOpenModalFile,
        isOpenModalFile,
        handlePocesarInfo,
        openModalConfirm,
        setOpenModalConfirm,
        dataPD,        
        setValueProcedCliente,
        ProcedCliente,
        setClaveRazonSocial,
        razonSocial,
        selectRef,
        handleGetCSRazonSocial,
        handleBtnDetail,
        setIsOpenModalFileDetail,
        isOpenModalFileDetail,
        item,
        handleReimprimirReporte
    } = useCobroComisionesExternoProcesoPago(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
           <SubMenuComisionesInventario/>
            <HeaderCobroComisionExterno/>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Cobro de comisiones en proceso</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesProcesoDePago, SubModule.ComisionesInventario).Descarga}>
                        <button className='btn' onClick={() => { setIsOpenModalFile(true) }}>Folio Pagado</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El módulo corresponde al registro de pagos de comisiones de los intermediarios y proveedores externos.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ComisionesProcesoDePago, SubModule.ComisionesInventario).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>      
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label>Tipo de compra</label>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value);handleGetCSRazonSocial(e.value) }} placeholder="Tipo compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label>Razon social</label>
                            <Select ref={selectRef} options={razonSocial} className='col-12' onChange={(e) => {setClaveRazonSocial(e.value)}} placeholder="Razon Social" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label>Procedencia del cliente</label>
                            <Select options={ProcedCliente} className='col-12' onChange={(e) => { setValueProcedCliente(e.value) }} placeholder="Procedencia del cliente" />
                        </div>                                                              */}
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        masterDetail={true}
                        btnPagadoDetail={true}
                        handlePagadoDetail={handleBtnDetail}
                        columnDetail={columnsDetail}
                        downtable={true}    
                        labelAutorizar={"Descargar Reporte Pendiente de pago"}  
                        handleDownload={handleReimprimirReporte}       
                    />
                </div>
            </ValidatePermission>
            {
                isOpenModalFile &&
                <UploadFile
                    isOpenModal={isOpenModalFile}
                    setIsOpenModal={setIsOpenModalFile}
                    handleSubmit={handlePocesarInfo}
                />
            }
            {
                isOpenModalFileDetail &&
                <UploadFileDetail
                    isOpenModal={isOpenModalFileDetail}
                    setIsOpenModal={setIsOpenModalFileDetail}
                    handleSubmit={()=>{habdleGetAll(1)}}
                    item={item}
                    toast={toast}
                />
            }
            
            {
                openModalConfirm &&
                <ModalConfirmPPCobroComisionExterno
                    isOpen = {openModalConfirm}
                    setIsOpen = {setOpenModalConfirm}
                    handleGetAll = {habdleGetAll}
                    toast = {toast}
                    item = {dataPD}
                />
            }
        </>
    )
}

export default CobroComisionExternoProcesoPago