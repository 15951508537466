import { React, useEffect, useState } from "react";

function ListingPageComponent({ onScroll, listInnerRef, data, columns, height, editable, handleEdit, isStatus, handleChange }) {

  const handlegetPropertyValue = (object, field) => {
     let value = object[field];
     if(field === 'status'){
      value = value ? 'Activo' : 'Inactivo'
    }
    if (field === 'interfaz'){
      value = value ? 'SI' : 'NO'
  }
     return value
  }
  
  return (
    <div>
      <div className="tbl-scroll"
        onScroll={onScroll}
        ref={listInnerRef}
        style={{ height: `${height}px`, overflowY: "auto"}}
      >
        <table className="table table-striped table-hover">
          <thead>
            <tr className='custom-table'>
              {
                columns?.map((item, index) => {
                  return <th scope="col" style={{ width: `${item.width}px` }} key={index}>{item.headerName}</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {
             
                data?.map((rowObj, index) => {
                  return <tr key={index}>
                    {
                      columns?.map((column, index) => {
                        return <td key={index}><span>{handlegetPropertyValue(rowObj, column.field) != null ? handlegetPropertyValue(rowObj, column.field).toString() : ""}</span></td>
                      })
                    }
                    <td className="">
                      <div className="d-flex">
                        {
                          isStatus ? <div className="form-check form-switch mx-1">
                            <input className="form-check-input" type="checkbox" checked={rowObj.status} onChange={(e) => { handleChange(e.target.checked, rowObj) }} />
                          </div> : null
                        }
                        {/* {
                      addtable ? <button className='btn mx-1 btnAdd'><i className="bx bx-list-plus" onClick={() => { handleAdd(rowObj) }}></i></button> : null
                    } */}
                        {
                          editable ? <button className='btn-tbl mx-1 btnEdit' onClick={() => { handleEdit(rowObj) }}><i className="bx bx-pencil"></i></button> : null
                        }
                        {/* {
                      deleteable ? <button className='btn mx-1 btnDelete'><i className="bx bxs-trash-alt" onClick={() => { handleDelete(rowObj) }}></i></button> : null
                    }
                    {
                      detailable ? <button className='btn mx-1 btnDetail' onClick={() => { handleDetail(rowObj) }}><i className="bx bxs-chevron-right"></i></button> : null
                    } */}
                      </div>
                    </td>
                  </tr>
                }) 
                
            }
            
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListingPageComponent;
