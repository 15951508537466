import { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';
import DiasFestivosService from "../../Services/DiasFestivos/DiasFestivosService";


const ConfiguracionDiasHabiles = ({ modalMessage, setModalMessage, setSelectedDates, selectedDates, year, parametroYear, establecerDiasNoHabiles }) => {

    const actualYear = new Date().getFullYear()

    let initialArray = [
        { active: false, name: 'D' },
        { active: false, name: 'L' },
        { active: false, name: 'Ma' },
        { active: false, name: 'Mi' },
        { active: false, name: 'J' },
        { active: false, name: 'V' },
        { active: false, name: 'S' },
    ]
    const [loading, setLoading] = useState(false);
    const [weeklyDays, setWeeklyDays] = useState(initialArray);

    useEffect(() => {
        if (modalMessage.isOpen)
            GetAnySemanaHabil(year);
    }, [modalMessage.isOpen]);

    function filterAndFormatDates(dates) {
        const currentDate = new Date(); // Fecha actual
        const endOfYear = new Date(currentDate.getFullYear(), 11, 31); // 31 de diciembre del año actual

        return dates
            .map(dateStr => new Date(dateStr)) // Convertir a objetos Date
            .filter(date => date >= currentDate && date < endOfYear) // Filtrar según las reglas
            .sort((a, b) => a - b) // Ordenar ascendentemente
            .map(date => {
                const day = String(date.getDate()).padStart(2, '0'); // Día con dos dígitos
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con dos dígitos
                const year = date.getFullYear(); // Año
                return `${day}/${month}/${year}`; // Formato dd/MM/yyyy
            });
    }

    //funcion foreach que vaya por cada 
    // function getAllWeeklyDayInYear() {
    //     let alldays = [];

    //     console.log(selectedDates);

    //     const result = filterAndFormatDates(selectedDates);
    //     console.log(result);

    //     weeklyDays.map((data, index) => {

    //         if (!data.active) return null
    //         const days = [];
    //         const date = new Date(actualYear, 0, 1); // Empieza el 1 de enero del año

    //         for (let x = 0; x <= parametroYear; x++) {
    //             // Recorrer todos los días del año
    //             while (date.getFullYear() === (actualYear + x)) {
    //                 if (date.getDay() === index) { // 1 es Lunes (0 es Domingo)
    //                     alldays.push(new Date(date)); // Agregar el lunes al arreglo
    //                 }
    //                 // else
    //                 // {
    //                 //     for (let i = fechas.length - 1; i >= 0; i--) {
    //                 //         if (fechasAEliminar.includes(fechas[i])) {
    //                 //             fechas.splice(i, 1); // Eliminar el elemento del índice actual
    //                 //         }
    //                 //     }

    //                 // }
    //                 date.setDate(date.getDate() + 1); // Avanza al siguiente día
    //             }
    //         }
    //         return days;
    //     })
    //     setSelectedDates(prevData => [...prevData, ...alldays])
    //     establecerDiasNoHabiles(weeklyDays);
    //     setModalMessage({ isOpen: false, title: '', message: '' })
    // }


    function getAllWeeklyDayInYear() {
        const alldays = []; // Lista para acumular todos los días seleccionados

        //este es el arrglo inicial
        const arregloInicial = filterAndFormatDates(selectedDates);
        console.log(arregloInicial);

        const date = new Date(actualYear, 0, 1); // Empieza el 1 de enero del año actual

        // Iterar sobre los años configurados
        for (let yearOffset = 0; yearOffset <= parametroYear; yearOffset++) {
            const currentYear = actualYear + yearOffset;
            date.setFullYear(currentYear);

            // Iterar sobre los días de la semana seleccionados
            weeklyDays.forEach((day, index) => {
                if (day.active) { // Si el día está activo (seleccionado)
                    while (date.getFullYear() === currentYear) {
                        if (date.getDay() === index) { // Si es el día de la semana deseado
                            alldays.push(new Date(date)); // Agregarlo a la lista
                        }
                        date.setDate(date.getDate() + 1); // Avanzar al siguiente día
                    }
                    // Reiniciar la fecha al inicio del año para el siguiente ciclo
                    date.setFullYear(currentYear);
                    date.setMonth(0);
                    date.setDate(1);
                }
            });
        }

        //esto es lo que se va a agregar, lo nuevo seleccionado
        const nuevoArregloFechas = filterAndFormatDates(alldays);
        console.log(nuevoArregloFechas);

        // Filtrar para evitar duplicados y actualizar el estado

        setSelectedDates(alldays);

        // setSelectedDates((prevData) => {
        //     // const newDates = [...prevData, ...alldays];
        //     const newDates = [...alldays];
        //     return Array.from(new Set(newDates.map(date => date.toISOString()))).map(iso => new Date(iso));
        // });

        const nuevasFechas = filterAndFormatDates(selectedDates);
        console.log(nuevasFechas);

        // Actualizar la configuración visual
        establecerDiasNoHabiles(weeklyDays);

        // Cerrar el modal
        setModalMessage({ isOpen: false, title: '', message: '' });
    }


    const toogleDate = (name) => {
        setWeeklyDays(prevItems =>
            prevItems.map(item =>
                item.name === name ? { ...item, active: !item.active } : item
            )
        );
    }

    const GetAnySemanaHabil = async (anio) => {
        setLoading(true);

        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {},
            "kvp": { "anio": anio }
        };

        const dayAbbreviations = {
            lunes: "L",
            martes: "Ma",
            miercoles: "Mi",
            jueves: "J",
            viernes: "V",
            sabado: "S",
            domingo: "D"
        };

        if(weeklyDays.some(item => item.active))
        {
            setLoading(false);
            return;
        }

        await DiasFestivosService.GetAnySemanaHabil(data)
            .then(res => {
                let diasHabiles = res.data.responseData;

                const diasHabilesMapeados = diasHabiles.map(item => {
                    return Object.keys(item)
                        .filter(key => key !== "anio")
                        .map(day => ({
                            name: dayAbbreviations[day],
                            isActive: item[day]
                        }));
                });

                if (diasHabilesMapeados && diasHabilesMapeados.length === 0) {
                    // if (diasHabilesMapeados[0].length === 0) {
                    setWeeklyDays(prevItems => prevItems.map(item => ({ ...item, active: false })));
                } else {
                    diasHabilesMapeados[0].forEach(diaHabil => {
                        setWeeklyDays(prevItems =>
                            prevItems.map(item =>
                                item.name === diaHabil.name ? { ...item, active: !diaHabil.isActive } : item
                            )
                        );
                    });
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
    }

    const handleRechazar = () => {
        setModalMessage({ isOpen: false, type: 4, title: '', message: '' })
    }

    return (
        <div>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                        <div className='d-flex align-items-center dias-festivos-modal-icon'>
                            <i className='bx bx-md bxs-calendar-edit' ></i>
                        </div>
                        <div className='px-4'>
                            <h4><strong>{modalMessage.title}</strong></h4>
                            <small>Programa los dias de descanso</small>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <div className="row">
                            <p>
                                Fines de semana / Días de descanso
                            </p>
                        </div>
                    </div>


                    <div className="col col-12 d-flex">
                        {weeklyDays.map(item => (
                            <div className={`d-flex  dias-festivos-modal-icon-days ${item.active ? "active-day" : "inactive-day"}`}
                                onClick={() => toogleDate(item.name)}>
                                <p>{item.name.charAt(0)}</p>
                            </div>
                        ))}
                    </div>


                    <div className="col col-12">
                        <div className="row">
                            <p>
                                <small className='dias-festivos-modal-smalltext'>Los cambios realizados en la configuración afectarán en todo el sistema.</small>
                            </p>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal"
                                className='btn btn-outline d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => { handleRechazar() }}>
                                Cancelar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal"
                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => { getAllWeeklyDayInYear(year) }}>
                                Confirmar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ConfiguracionDiasHabiles