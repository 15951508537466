import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../../../Components/Modal/Modal';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import Spinner from '../../../../Components/Loadig';

const ContratoHeaderCard = () => {
    const { numContrato, fechaUltimoPagAcendes, usoUnidad, clienteAcendes, fechaLiquidacionAcendes } = useSelector((state) => state.DataVinSlice);

    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const datos = {
        contratoStatus: true
    };

    const styleBadge = {
        backgroundColor: "#D8F2CD",
        color: "#47A066",
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={780}>
                {modalMessage.type === 1 && (
                    <ErrorModal modalMessage={modalMessage} setModalMessage={setModalMessage} />
                )}
                {modalMessage.type === 2 && (
                    <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} />
                )}
            </Modal>
            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                <div className="row px-4">
                    <div className="col-12 underline-border">
                        <div className="row">
                            <div className="col-auto" style={{ marginTop: '5px' }}>
                                <h6><strong>Contrato</strong></h6>
                            </div>
                            <div className="col-auto" style={{ marginBottom: '5px' }}>
                                <span className='px-3 py-1' style={styleBadge}>
                                    <small>{datos.contratoStatus ? 'Activo' : 'Cerrado'}</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>No. de contrato:</strong> {numContrato}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Fecha de último pago:</strong> {fechaUltimoPagAcendes}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Cliente:</strong> {clienteAcendes}</small>
                    </div>
                    <div className="col-6">
                        <small><strong>Término del crédito:</strong> {fechaLiquidacionAcendes}</small>
                    </div>
                </div>
                <div className="row mt-2 px-4">
                    <div className="col-6">
                        <small><strong>Uso unidad:</strong> {usoUnidad}</small>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContratoHeaderCard;
