import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const NavigaSliceEnganche = createSlice({
  name: 'navigateEnganche',
  initialState,
  reducers: {
    ActiveEnganche: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { ActiveEnganche } = NavigaSliceEnganche.actions

export default NavigaSliceEnganche.reducer