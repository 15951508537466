import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { tabActiveLog } from '../../../Redux/Slice/navigateSliceSeguimientoTelefonico'
import { tabActiveLog } from '../../../Redux/Slice/NavigateSliceLogs'
import SeguimientoTelefonicoHook from '../../../Hooks/SeguimientoTelefonico/SubMenuSeguimientoTelefonico'
import ProgressBarHalfCircle from '../../../Components/ProgressBarHalfCircle/ProgressBarHalfCircle'
import ProgressBar from '../../../Components/ProgressBar/ProgressBar'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import './SeguimientoTelefonico.css'
import { formatearMonto } from '../../../Utils/functions'

const SubMenuSeguimientoTelefonico = () => {
    const { value } = useSelector((state) => state.tabNavigateLogs)

    //Prueba ProgressBAR
    const [progress, setProgress] = useState(0)

    // Simulate progress change for demonstration
    const increaseProgress = () => {
        setProgress(prev => Math.min(prev + 10, 100))
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        handleVigate(value)
    }, [value])

    const handleSetStateTab = (value) => {
        dispatch(tabActiveLog(value))
    }

    const handleVigate = (value) => {
        if (value === 1) {
            navigate(`/cobranza/seguimiento-telefonico/seguimiento-cobranza`)
        }
        if (value === 2) {
            navigate(`/cobranza/seguimiento-telefonico/promesas-de-pago`)
        }
        if (value === 3) {
            navigate(`/cobranza/seguimiento-telefonico/seguimiento-visita-nivel-2`)
        }
        if (value === 4) {
            navigate(`/cobranza/seguimiento-telefonico/seguimiento-cobranza-juridica-nivel-3`)
        }
        if (value === 5) {
            navigate(`/cobranza/seguimiento-telefonico/seguimiento-cobranza-juridica-nivel-4`)
        }
    }

    const {
        userName,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        loading,
        totalContratos,
        rangoFechas,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        setGeneralSearch,
        uniqueIds,
    } = SeguimientoTelefonicoHook()

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <section className='pt-4 px-4'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div>
                            <span className='fs-5'><strong>{`¡Buen día, ${userName}!`}</strong></span>
                            <span className='seguimiennto-telfonico-calendar'> <i className='bx bx-calendar' ></i>{rangoFechas}</span>
                        </div>
                        {/* <div className="">
                            <i className="glyphicon fas fa-search"></i>
                            <p></p>
                        </div> */}
                    </div>
                    <div className='seguimiennto-telfonico-busqueda'>
                        <div className="inner-addon right-addon seguimiennto-telfonico-busqueda-bar">
                            <i className="glyphicon fas fa-search icon"></i>
                            <input type="text" className="form-control mb-4 small-input" placeholder="Busqueda General de contratos" onKeyUp={(e) => { setGeneralSearch(e.target.value) }} />
                        </div>
                        <div
                            className='seguimiento-telefonico-dropdown'
                            ref={dropdownRef}
                        >
                            <button
                                className='seguimiento-telefonico-dropdown-button'
                                onClick={toggleDropdown}
                            >
                                <i className='bx bx-dots-vertical-rounded x2 icon'></i>
                            </button>
                            {isOpen && (
                                <div className="seguimiento-telefonico-dropdown-content">
                                    <a
                                        onClick={(e) => toggleVisible(true)}
                                        className='seguimiento-telefonico-dropdown-content-link'>Ver todo</a>
                                    <a
                                        onClick={(e) => toggleVisible(false)}
                                        className='seguimiento-telefonico-dropdown-content-link'>Listado de contratos</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {isVisible && (
                <div className='d-flex'>
                    <div className='col-12 col-lg-4'>
                        <div className='estadistica-proceso-bar'>
                            <span className='col-12 text-center py-2'><strong>Estadísticas de progreso</strong> </span>
                            <span className='col-12 text-center py-2'>Asignados <strong>{totalContratos}</strong></span>
                            <ProgressBarHalfCircle initialPercentage={percentage} />
                        </div>
                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='estadistica-promesas-de-pago'>
                            <span className='d-flex justify-content-center icon'><strong>Promesa de pago</strong></span>
                            <div className='d-flex justify-content-center py-2'>
                                <div className='seguimiennto-telfonico-promesa-pago-dias-hoy'>Hoy</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active7 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive7()}
                                >-7 días</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active15 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive15()}
                                >-15 días</div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    {/* pROGRESS BAR */}
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressVigente} primary={true} secondProgress={secondProgressVigentes} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas vigentes</strong></span>
                                            <span className='col-12'>{formatearMonto(firstProgressVigente.totalMontoPromesas)}</span>
                                            <span className='col-12'>{firstProgressVigente.totalContratoPromesas} Contratos</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressCumplidas} secondProgress={secondProgressCumplidas} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas cumplidas</strong></span>
                                            <span className='col-12'>{formatearMonto(firstProgressCumplidas.totalMontoPromesas)}</span>
                                            <span className='col-12'>{firstProgressCumplidas.totalContratoPromesas} Contratos</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <button onClick={increaseProgress}>Increase Progress</button> */}
                        </div>
                    </div>
                    <div className='col-12 col-lg-3'>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-final-texto'>Monto vencido</span>
                            <span className='estadistica-monto-final-numero'><strong>{formatearMonto(totalMontoVencido)}</strong></span>
                            <span>{totalContratos} contratos</span>
                        </div>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-cobrado-texto'>Monto cobrado</span>
                            <span className='estadistica-monto-cobrado-numero'> <strong>{formatearMonto(totalMontoCobrado)}</strong></span>
                            <span>{totalContratos} contratos</span>
                        </div>
                    </div>
                </div>
            )}

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation" id='1'>
                    <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguimiento Cobranza 
                        {uniqueIds.includes("1") ? <i class='bx bxs-circle mx-2' style={{ fontSize: "12px" }}> </i>: null}
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Promesas de Pago 
                    </button>
                </li>
                <li className="nav-item" role="presentation" id='2'>
                    <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguimiento de Visita Nivel 2 
                    {uniqueIds.includes("2") ? <i class='bx bxs-circle mx-2' style={{ fontSize: "12px" }}></i>: null}
                    </button>
                </li>
                <li className="nav-item" role="presentation" id='3'>
                    <button className={`nav-link ${value === 4 && "tab-active"}`} value={4} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguimiento Cobranza Jurídico Nivel 3 
                    {uniqueIds.includes("3") ? <i class='bx bxs-circle mx-2' style={{ fontSize: "12px" }}></i>: null}
                    </button>
                </li>
                <li className="nav-item" role="presentation" id='4'>
                    <button className={`nav-link ${value === 5 && "tab-active"}`} value={5} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguimiento Cobranza Jurídico Nivel 4 
                    {uniqueIds.includes("4") ? <i class='bx bxs-circle mx-2' style={{ fontSize: "12px" }}></i>: null}
                    </button>
                </li>
            </ul>

        </>
    )
}

export default SubMenuSeguimientoTelefonico