import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ListingPageComponent from '../../Components/InfinitScrollTable/ListingPageComponent';
import Select from 'react-select';
import Loadig from '../../Components/Loadig';
import Modal from '../../Components/Modal/Modal';
import IntelimotorAPI from '../../Services/Catalogos/Intelimotor';
import SyncInventarioService from '../../Services/SyncInventario/SyncInventarioService';
import DataTable from '../../Components/datatable/DataTable';
import MultiSelect from '../../Components/DropDown';

const InvIntelimitorDetail = () => {
  const listInnerRef = useRef();

  const { state } = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [Str, setStr] = useState("");
  const [statusValue, setstatusValue] = useState("");
  const [filtros, setFiltros] = useState("");
  const [tiempoProces, setTiempoProces] = useState("");
  const [pageCount, setPageCount] = useState(null)
  const [stateMulti, setState] = useState();

  const columns = [
    { field: 'vin', headerName: 'VIN', width: 230 },
    { field: 'nombreMarca', headerName: 'Marca', width: 230 },
    { field: 'nombreModelo', headerName: 'Modelo', width: 200 },
    { field: 'nombreYear', headerName: 'Año', width: 230 },
    { field: 'nombreVersion', headerName: 'Versión', width: 230 },
    { field: 'nombreColor', headerName: 'Color', width: 230 },
    // { field: 'syncsDetailId', headerName: 'Ubicación', width: 230 },
    { field: 'nombreTipoAdquisicion', headerName: 'Tipo Adquisición', width: 230 },
    { field: 'precioCompra', headerName: 'Precio Compra', width: 230 },
    { field: 'precioVenta', headerName: 'Precio Venta', width: 230 },
    { field: 'estatus_Reg', headerName: 'Estatus', width: 230 },
  ];
  const dataFiltro = [
    { label: "Marca", value: "Marca" },
    { label: "Modelo", value: "Modelo" },
    { label: "Año", value: "Año" },
    { label: "Version", value: "Version" },
  ]
  const dataFiltroStatus = [
    { label: "Todos", value: "" },
    { label: "Registrado", value: 1 },
    { label: "No registrado", value: 0 },
    { label: "Actualizado", value: 2 },
    { label: "Sin Cambios", value: 3 }
  ]
  useEffect(() => {
    handleGetHistoryById(id, currentPage, 10);
    setCurrentPage(1);
  }, [Str, filtros,statusValue])


  const handleGetHistoryById = async (Id, page, rows) => {
    // if (nextPage) {
    setLoading(true);
    await SyncInventarioService.getSyncHistoryById(Id, page, rows, filtros, Str,statusValue)
      .then(res => {
        let dat = res.data.data;
        
        setTiempoProces(dat[0]?.tiempoProceso)

        setData(dat)

        // setData([...data, ...dat]);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
    // }

  }
  const handledownloadFile = async () => {
    setLoading(true);
    await SyncInventarioService.DownloadSyncinv(id, filtros, Str,statusValue)
      .then(res => {
        // create file link in browser's memory
        // const href = URL.createObjectURL(res.data);

        // // create "a" HTML element with href to file & click
        // const link = document.createElement('a');
        // link.href = href;
        // link.setAttribute('download', `SyncInventario-${id}.xlsx`); //or any other extension
        // document.body.appendChild(link);
        // link.click();

        // // clean up "a" element & remove ObjectURL
        // document.body.removeChild(link);
        // URL.revokeObjectURL(href);
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `SyncInventario-${id}.xlsx`);
        setLoading(false);

      }).catch(e => {
        setLoading(false);
      })
    // }

  }

  // const handleChange = async (event) => {
  //   setStr(event.target.value);
  //   if (nextPage) {
  //     setLoading(true);
  //     await IntelimotorAPI.getModifyList(1, 10, id, Str)
  //       .then(res => {
  //         let dat = res.data.data;
  //         setData(dat);
  //         setNextPage(res.data.hasNextPage);
  //         setLoading(false);
  //       }).catch(e => {

  //         setLoading(false);
  //       })
  //   }
  // }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    handleGetHistoryById(id, event.selected + 1, 10);
  };
  const handleChange = value => {
    setState(value);
    let stringValue = [];
    value?.map(x => {
      stringValue.push(x.value);
    })
    setFiltros(stringValue.join());
  }

  return (
    <>
      <Modal isOpen={loading} color='transparent'><Loadig message={"Procesando información..."} /></Modal>
      <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-4'>
        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
        Regresar a Inventario intelimotor {state.page}
      </header>
      <section className='mx-4 my-4 d-flex justify-content-between'>
        <div>
          <span className='fs-5'><strong>Detalles de ejecución</strong></span>
          <i className="icon bi bi-clock" style={{ marginLeft: '30px' }}></i>
          <span>Tiempo de procesamiento: {tiempoProces}</span>
        </div>
        <button className='btn' onClick={handledownloadFile}>Exportar Excel</button>
      </section>
      <section className='mx-4'>
        <div className='row col-12'>
          <div className="col-lg-3 col-md-6 col-sm-12 my-2 form-group">
            <MultiSelect
              holder={"Seleccione"}
              className={'col-12'}
              value={stateMulti}
              options={dataFiltro}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 my-2 form-group inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 my-2'>
            <Select options={dataFiltroStatus} className='col-12' onChange={(e) => { setstatusValue(e.value) }} placeholder="Estatus" />
          </div>
        </div>

      </section>
      <div className='mx-4 table-responsive'>
        {/* <ListingPageComponent
          onScroll={onScroll}
          listInnerRef={listInnerRef}
          data={data}
          columns={columns}
          height={380}
        /> */}
        <DataTable
          column={columns}
          data={data}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage - 1}
        />
      </div>

    </>
  )
}

export default InvIntelimitorDetail