import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { message } from '../../Utils/functions';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import { chekItem } from '../../Redux/Slice/quickActionsSlice';

const useVehiculoVentaContadoConcluido = (toast) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'folio', headerName: 'Folio', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 160 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 150 },
        { field: 'tipoCompra', headerName: 'Tipo de Compra', width: 200 },
        { field: 'ubicacion', headerName: 'Ubicación', width: 180 },
        { field: 'etapa', headerName: 'Etapa', width: 150 },
        { field: 'aptoPM', headerName: 'Apto PM', width: 150 },
        { field: 'strFechaUltimoMovimiento', headerName: 'Fecha de último movimiento', width: 250 },
        { field: 'strFechaInicioVenta', headerName: 'Fecha Inicio Venta', width: 250 },
        // { field: '', headerName: 'Documentos', width: 80 },
        // { field: 'numIdFolder', headerName: 'ID Folder DataDocs', width: 400 },
    ];
    const columnsH = [
        { field: 'fechaHora', headerName: 'Fecha Inicio', width: 400 },
        { field: 'fechaFin', headerName: 'Fecha Fin', width: 400 },
        { field: 'nombreEmpleado', headerName: 'Nombre del empleado', width: 300 },
        { field: 'comentarios', headerName: 'Comentarios', width: 340 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: true },
        { label: "Vehículos sin folder", value: false },
    ];
    let dataUtilitario = [
        { label: "Todos", value: "" },
        { label: "Vehículos utilitarios", value: 1 },
        { label: "Vehículos no utilitarios", value: 0 },
    ];
    let dataStatus = [
        {value:1,label:"Seleccione"}
    ]
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [tipoAd, setTipoAd] = useState("");
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
    const [FechaInicio, setFechaInicio] = useState('');
    const [FechaFin, setFechaFin] = useState('');
    const [ClaveStatus, setClaveStatus] = useState('');
    const [dataEtapa, setDataEtapa] = useState([])
    const [ClaveEtapa, setClaveEtapa] = useState("");
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [dataModal, setDataModal] = useState([]);
    const [IsOpenModal, setIsOpenModal] = useState(false)
    const [currenPageH, setCurrenPageH] = useState(1);
    const [utilitarioId, setUtilitarioId] = useState("");
    const [pageRows, setpageRows] = useState(10);
    //
    useEffect(() => {
        if (str !== "" || ClaveEtapa !== "") {
            setCurrenPage(1);
            habdleGetAll(1);
        }
        else {
            habdleGetAll(currenPage);
            setCurrenPage(1);
        }
    }, [ClaveEtapa, str, pageRows])
    useEffect(() => {
        // handleGetTipoAdquisicio();
        handleGetAllEtapa();
    }, [])


    const habdleGetAll = async (page) => {
        setLoading(true);
        await VentaVehiculoService.getlAllVehiculoVentaContadoConcluido(page, pageRows, ClaveEtapa, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetTipoAdquisicio = async () => {
        await Catalogo.getdataTabla("TipoCompra", 1, 100, "")
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                setDataTipoAdquisicion(NewData);
            }).catch(e => {

            })
    }
    const handleGetAllEtapa = async () => {
        setLoading(true);
        await EtapaService.getAllEtapa()
          .then(res => {
            let NewData = [];   
            NewData.push({
              value: "",
              label: "Todos"
            })  
            res.data.data?.map(item => {
              NewData.push({
                value: item.etapaId,
                label: item.etapa
              })
            });
            setDataEtapa(NewData);
            setLoading(false);
          }).catch(e => {
            setLoading(false);       
          })
      }
      const handlechekedItem = (check, item, i) => {
        check ? dispatch(chekItem({ check, item })) : dispatch(chekItem({ check, item: null }));

        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
          const element = table[index];
          if (index !== i)
            element.firstChild.firstChild.checked = false;
        }
      }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleDataHistory = async (page, id) => {

        setLoading(true);
        await VehiculoService.getAllHistoryVehiculoUtilitario(id, page, 10)
            .then(res => {

                setDataModal(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                // if (e.response !== undefined)
                //     toast.error(message("¡Error.!", e.response.data.message));
                // else
                //     toast.error(message("¡Error.!", e.message));
            })
    }
    const handlePageClickH = (event) => {
        setCurrenPageH(event.selected + 1);
        handleDataHistory(event.selected + 1, dataVehiculo.generalId);
    };
    const handleEdit = (value) => {

        if (value.etapaId === 1) {
            navigate(`/inventario/venta/prealta/${value.generalId}`, {
                state: {...value,ventaContado:true}
            });
        } else if (value.etapaId === 2) {
            navigate(`/inventario/venta/alta/${value.generalId}`, {
                state: {...value,ventaContado:true}
            });
        } else if (value.etapaId === 3 || value.etapaId === 11 || value.etapaId === 5 || value.etapaId === 15) {
            navigate(`/inventario/venta/valoracion/${value.generalId}`, {
                state: {...value,ventaContado:true}
            });
        } else {
            navigate(`/inventario/venta/prealta/${value.generalId}`, {
                state: {...value,ventaContado:true}
            });
        }
        // else if (value.etapaId === 5) {
        //   navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
        //     state: {...value,action:1},      
        //   });
        // }
    }
    const handleViewdocs = (item) => {
        if (item.numIdFolder !== null) {
            navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
                state: item,
            });
        } else {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);
        }

    }
    const handleCreateFolder = (item, tipo) => {
        setDataVehiculo(item);
        if (tipo === 1) {
            setIsOpenDocs(true)
        } else if (tipo === 2) {
            handleDataHistory(1, item.generalId);
            setIsOpenModal(true)
        } else if (tipo === 3) {

            handleDeleteUtilitario(item.generalId);
        }
    }
    const handleDeleteUtilitario = async (id) => {
        await VehiculoService.updDesvincularVehiculo(id)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                habdleGetAll(1);
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }

    return {
        columns,
        columnsH,
        data,
        handleEdit,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        dataTipoAdquisicion,
        handleCreateFolder,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        DataFiltroFolder,
        habdleGetAll,
        setIsOpenModal,
        dataModal,
        IsOpenModal,
        handlePageClickH,
        currenPageH,
        dataUtilitario,
        setUtilitarioId,
        handleViewdocs,
        handlechekedItem,
        setpageRows,
        setFechaInicio,
        setFechaFin,
        setClaveStatus,
        dataStatus,
        dataEtapa,
        setClaveEtapa
    }
}

export default useVehiculoVentaContadoConcluido