import { Formik, Form } from 'formik'
import React, { useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { message } from '../../../Utils/functions';
import RevisionUnidadRechazadaService from '../../../Services/Inventario/RevisionUnidadRechazadaService';

const DeleteModalConfirm = ({ isOpen, setIsOpen, item, toast, handleGetAll }) => {
    console.log("item",item)
    const refbtn = useRef();
    let initial = {
        vin: item?.vin ?? "",
        generalId:item.generalId
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const handleDelete = async (item) => {
        setLoading(true);
        refbtn.current.disabled = true;

        await RevisionUnidadRechazadaService.DeleteRevisionUnidadRechazada(item.generalId)
            .then(res => {
                if (res.data.data.ban === 1) {
                    // setTimeout(() => {
                        setIsOpen(false);
                        setLoading(false)
                        toast.success(message("¡Operación exitosa!", res.data.data.mensaje));
                    // }, 3000);
                    handleGetAll(1);
                } else {
                    toast.error(message("¡Error!", res.data.data.mensaje));
                    setIsOpen(false);
                    setLoading(false)
                }
                setLoading(false);
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));

            })

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(values) => {
                handleDelete(values);
            }}
        >
            {({ isValid, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>Confirmar Eliminación</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '28rem' }}>
                            <Form>
                                <span style={{ fontSize: '13px' }}>¿Estas seguro que deseas eliminar el vehículo <strong>{values.vin}</strong>?</span>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit">Eliminar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default DeleteModalConfirm